import React, { useEffect, useState } from 'react';
import { Form, DatePicker, SubmitButton } from 'formik-antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Select } from 'antd';
import moment from 'moment';
import { modalPartnerSchemaEnd, modalPartnerSchemaStart } from 'libs/validations';
import { SEARCH_PARTNER_OPTION } from 'constant/select.constants';
import { Modal, SelectField, SpinLoading } from 'components';
import { authSelector } from 'containers/Auth/selectors';
import { updateProviderInformation } from '../thunk';
import { managementSelector } from '../selectors';
import { ModalDetailStyled } from './styles';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';

interface Props {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  detailSubmit: () => Promise<void>;
  statusSelect?: Types.StatusType;
  visibleDetail?: boolean;
}

const { Option } = Select;

const ModalDetail: React.FC<Props> = ({
  setVisible,
  detailSubmit,
  statusSelect,
  visibleDetail,
}) => {
  const [selectValidate, setSelectValidate] = useState('');

  const { dataDetail } = useSelector(managementSelector);
  const { userInfo } = useSelector(authSelector);
  const { loading } = useSelector(settingSelector);
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      select_status: statusSelect?.status,
      start_date: statusSelect?.contract_start_date,
      end_date: statusSelect?.contract_end_date,
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema:
      statusSelect?.status === '1' || selectValidate === '1'
        ? modalPartnerSchemaStart
        : modalPartnerSchemaEnd,
    onSubmit: async (values, formikHelpers) => {
      if (
        values.select_status == statusSelect?.status &&
        values.start_date == statusSelect?.contract_start_date &&
        values.end_date == statusSelect?.contract_end_date
      ) {
        return;
      }
      if (!userInfo) return;
      dispatch(startLoading());
      let contract_start_date;
      let contract_end_date;
      let deletedat;
      switch (values.select_status) {
        case '0': {
          contract_start_date = values.start_date;
          contract_end_date = '';
          deletedat = '';
          break;
        }
        case '1': {
          contract_start_date = values.start_date;
          deletedat = '';
          contract_end_date = new Date(new Date().setDate(new Date().getDate() + 1));
          break;
        }
        case '2': {
          contract_start_date = values.start_date;
          deletedat = new Date();
          contract_end_date = new Date(new Date().setDate(new Date().getDate() - 1));
          break;
        }
      }

      const actionResult = await dispatch(
        updateProviderInformation({
          id:
            dataDetail?.item_ref?.contract_start_date?.i_id! ||
            dataDetail?.item_ref?.contract_end_date?.i_id! ||
            dataDetail?.i_id!,
          data: {
            item: {
              contract_start_date: values.start_date,
              contract_end_date: values.select_status === '0' ? null : values.end_date,
              deletedat: values.select_status === '0' ? null : new Date(),
              // contract_start_date: contract_start_date,
              // contract_end_date: contract_end_date,
              // deletedat: deletedat,
              url_issue_date: new Date(),
              account_registration_status: 0,
              updatedby: userInfo.login_id,
              updatedat: new Date(),
            },
            is_force_update: true,
          },
        })
      );
      if (updateProviderInformation.fulfilled.match(actionResult)) {
        await detailSubmit();
        formikHelpers.resetForm();
      }
      dispatch(stopLoading());
    },
  });

  const handleCancelModalDetail = () => {
    setVisible(false);
    formik.resetForm();
  };

  const disableButton = () => {
    if (formik.values.select_status === '0') {
      return false;
    } else {
      const end_date = formik.values?.end_date;
      if (formik.values.select_status === '1') {
        if (!end_date) {
          return true;
        } else {
          return new Date().getTime() > new Date(end_date).getTime();
        }
      }
      if (formik.values.select_status === '2') {
        if (!end_date) {
          return true;
        } else {
          return new Date().getTime() > new Date(end_date).getTime();
        }
      }
    }
  };

  return (
    <Modal
      title="パートナー情報 詳細"
      width={720}
      visible={visibleDetail}
      onCancel={handleCancelModalDetail}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <ModalDetailStyled>
        <span className="title-detail-momo">{dataDetail?.provider_name}</span>
        <span className="title-modal">{`(${dataDetail?.provider_furigana})`}</span>
        <FormikProvider value={formik}>
          <Form layout="vertical" className="form">
            <div className="card-select-top">
              <Form.Item name="select_status">
                <div className="status-partner">
                  <div className="select-item-left">契約状況</div>
                  <div className="select-item-right">
                    <SelectField
                      name="select_status"
                      size="large"
                      className={`${
                        formik.values.select_status === '0'
                          ? 'status-background-green'
                          : formik.values.select_status === '1'
                          ? 'status-background-orange'
                          : 'status-background-grey'
                      }`}
                      onChange={(value) => {
                        setSelectValidate(value);
                      }}
                      value={
                        SEARCH_PARTNER_OPTION.find((e) => e.value === formik.values.select_status)
                          ?.label
                      }
                      disabled={statusSelect?.status === '2'}
                    >
                      {SEARCH_PARTNER_OPTION.map((i, index: number) => (
                        <Option key={index} value={i.value}>
                          <div className="icon-edit">
                            <span>{i.label}</span>
                          </div>
                        </Option>
                      ))}
                    </SelectField>
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="start_date">
                <div className="status-partner">
                  <div className="select-item-left">
                    契約開始日
                    {formik.values.select_status !== '2' && <span className="require">*</span>}
                  </div>
                  <div className="select-item-right">
                    <DatePicker
                      allowClear
                      name="start_date"
                      disabled={formik.values.select_status === '2'}
                      className="date_input"
                      placeholder=""
                      format="YYYY/MM/DD"
                      getPopupContainer={(triggerNode) => triggerNode.parentElement!}
                    />
                    {formik.errors.start_date && formik.values.select_status !== '2' && (
                      <div className="style-errors">値を入力してください</div>
                    )}
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="end_date">
                <div className="status-partner">
                  <div className="select-item-left-top">
                    契約終了日（予定）
                    {formik.values.select_status === '1' && <span className="require">*</span>}
                  </div>
                  <div className="select-item-right-top">
                    <DatePicker
                      allowClear
                      name="end_date"
                      disabled={formik.values.select_status === '0'}
                      className="date_input"
                      placeholder=""
                      format="YYYY/MM/DD"
                      disabledDate={(current) => {
                        if (formik.values.select_status === '1') {
                          const tomorrow = moment().add(1, 'days').startOf('day');
                          return current && current <= tomorrow;
                        }
                        return false;
                      }}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement!}
                    />
                    {formik.errors.end_date && formik.values.select_status === '1' && (
                      <div className="style-errors">値を入力してください</div>
                    )}
                  </div>
                </div>
              </Form.Item>
            </div>
            <div>
              {dataDetail?.addressError && (
                <div className="style-errors">
                  <ExclamationCircleFilled className="icon" />
                  {dataDetail?.addressError}
                </div>
              )}
            </div>
            <div className="card-select-top">
              <Form.Item name="partner">
                <div className="status-partner">
                  <div className="select-item-left">利用ユーザー数</div>
                  <div className="select-item-right">{dataDetail?.rev_no}人</div>
                </div>
              </Form.Item>
              <Form.Item name="partner">
                <div className="status-partner">
                  <div className="select-item-left">住所</div>
                  <div className="select-item-right">
                    <div>
                      <span className="style-text">〒</span> {dataDetail?.postal_code}
                    </div>
                    <div>{dataDetail?.address}</div>
                    <div>
                      {dataDetail?.plot_number} {dataDetail?.building_name}
                    </div>
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="partner">
                <div className="status-partner">
                  <div className="select-item-left-bottom">管理者情報</div>
                  {!dataDetail?.addressError && (
                    <div className="select-item-right-bottom">
                      <div className="style-name">
                        {dataDetail?.admin_name}
                        {`（${dataDetail?.admin_name_furigana}）`}
                      </div>
                      <div>
                        <span className="style-text">所属 ：</span>
                        {dataDetail?.admin_department}
                      </div>
                      <div>
                        <span className="style-text">役職：</span>
                        {dataDetail?.admin_position}
                      </div>
                      <div>
                        <span className="style-text">電話番号：</span>
                        {dataDetail?.admin_phone}
                      </div>
                      <div>
                        <span className="style-text">メールアドレス：</span>
                        <span className="style-email">{dataDetail?.admin_email}</span>
                      </div>
                    </div>
                  )}
                </div>
              </Form.Item>
            </div>
            <div className="wrap-submit-search">
              <SubmitButton
                className={`btn-search ${disableButton() ? 'btn-disable' : ''} `}
                loading={false}
                disabled={disableButton()}
              >
                更新
              </SubmitButton>
              <button type="button" className="label-reset" onClick={handleCancelModalDetail}>
                閉じる
              </button>
            </div>
          </Form>
        </FormikProvider>
      </ModalDetailStyled>
    </Modal>
  );
};

export default ModalDetail;
