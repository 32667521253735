import styled from 'styled-components';

export default styled.section`
  margin-bottom: 45px;

  .body-info {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    display: flex;
    .title {
      font-size: 16px;
      color: #2a2a2a;
    }

    .right-side {
      padding: 40px;
      width: 100%;
      position: relative;
      .wrap-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        .wrap-action {
          display: flex;
          width: 60%;
          justify-content: end;
          flex: 1;
        }
        .wrap-select {
          display: flex;
          align-items: center;
          justify-content: end;
          gap: 8px;
          .ant-select-selector {
            height: 36px;
          }
          .ant-select {
            min-width: 150px;
          }
          label {
            color: #777777;
            font-size: 12px;
            white-space: nowrap;
          }
        }

        .checkbox {
          display: flex;
          height: 36px;
          align-items: center;
          padding: 0 8px;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 3px;
          .text {
            padding-left: 12px;
          }
        }

        .legend {
          display: flex;
          align-items: center;

          .sort-column {
            display: flex;
            align-items: center;
            padding: 0 10px;
            margin-left: 20px;
            .legend-filter {
              width: 14px;
              height: 14px;
              display: flex;
              align-items: center;
            }
            .purple {
              position: relative;
              .circle {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #72b0e9;
              }
              .line {
                width: 20px;
                height: 2px;
                background: #72b0e9;
                position: absolute;
                top: 44%;
                right: 10%;
              }
            }
            .green {
              position: relative;
              .circle {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #8dc968;
              }
              .line {
                width: 20px;
                height: 2px;
                background: #8dc968;
                position: absolute;
                top: 44%;
                right: 10%;
              }
            }
            .blue {
              background: #08a3a5;
            }
            .yellow {
              background: #f6ac00;
            }

            .green {
              background: #c3dc7b;
            }

            .legend-name {
              font-size: 12px;
              color: #424242;
              margin: 0 5px;
              white-space: nowrap;
            }
          }
        }
      }
      .chart {
        width: 98%;
        position: relative;
        .unit {
          //position: absolute;
          //right: 30px;
          //top: 115px;
          width: 100%;
          display: flex;
          justify-content: end;
          font-size: 11px;
          color: #999999;
        }
        .line-chart {
          height: 20vh !important;
        }
      }
    }
  }
`;
