import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'formik-antd';
import { useSelector } from 'react-redux';
import { get, unionBy } from 'lodash';
import zipcodeJa from 'zipcode-ja';
import { Select } from 'antd';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { AdministratorMasterFormik, AffiliationAssignRole } from 'types';
import { settingSelector } from 'containers/AppSettings/selectors';
import { administratorMasterSchema } from 'libs/validations';
import { administratorMasterSelector } from './selectors';
import { authSelector } from 'containers/Auth/selectors';
import { Header, Input, SelectField } from 'components';
import { useAppDispatch, usePermission } from 'hooks';
import { getCompanies, getUserSearch } from './thunk';
import ConfirmAdministratorMaster from './Confirm';
import { setInformationRegister } from './slice';
import CreateUserModal from './Modal';
import { Wrapper } from './styles';
import useCheckModifiedPage from 'hooks/useCheckModifiedPage';
import useModifiedPage from '../../../hooks/useModifiedPage';
import { setModified } from 'slice/slice';

const { Option } = Select;

interface ComponentHandle {
  handleSubmit: () => void;
}

const AdministratorMaster: React.FC = () => {
  const childRef = useRef<ComponentHandle>(null);

  const [nextScreen, setNextScreen] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);
  const [method, setMethod] = useState<string | undefined>();
  const [email, setEmail] = useState<string>('');
  const [initValue, setInitValue] = useState<AdministratorMasterFormik>();
  const { setModifiedPage } = useModifiedPage();
  const { information, employees } = useSelector(administratorMasterSelector);
  const { userInfo } = useSelector(authSelector);
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const [isModifiedValue, setIsModifiedValue] = useState<boolean>(false);
  const { permissionNumber } = usePermission();

  const dispatch = useAppDispatch();

  const informationDynamic = useMemo(() => {
    let mail = email || information.admin_email;

    const item = employees.find((e) => e.email === mail);
    return {
      ...information,
      admin_name: item?.name ?? information.admin_name,
      admin_email: item?.email ?? information.admin_email,
      admin_name_furigana: item?.kana ?? information.admin_name_furigana,
      admin_department: item?.lookup_items?.department_name?.name ?? information.admin_department,
      admin_position: item?.lookup_items?.position_code.name ?? information.admin_position_name,
    };
  }, [employees, email, information]);

  const formik = useFormik<AdministratorMasterFormik>({
    initialValues: informationDynamic ?? information,
    validationSchema: administratorMasterSchema,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(setInformationRegister(values));
      toggleScreen();
      setSubmitting(false);
      setIsModifiedValue(true);
    },
  });

  const convertZipCodeToAddress = () => {
    if (formik.values.postal_code) {
      const address = zipcodeJa[formik.values.postal_code as number]?.address;
      if (address?.[0]) {
        formik.setFieldValue('prefecture', address[0], true);
        formik.setErrors({ prefecture: undefined });
        formik.setFieldTouched('prefecture', false);
      }
      if (address?.[1] || address?.[2]) {
        formik.setFieldValue('address', [address[1] ?? '', address[2] ?? ''].join(' '), true);
        formik.setErrors({ address: undefined });
        formik.setFieldTouched('address', false);
      }
    }
  };

  const fetchDataUserInformation = useCallback(async () => {
    if (!userInfo) {
      return;
    }

    dispatch(startLoading());

    await Promise.all([
      dispatch(
        getUserSearch({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getCompanies({
          conditions: [
            {
              id: 'id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 1,
        })
      ),
    ]);

    dispatch(stopLoading());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const toggleScreen = () => {
    setNextScreen((prevState) => !prevState);
    dispatch(setModified(false));
  };

  useEffect(() => {
    fetchDataUserInformation();
  }, [fetchDataUserInformation]);

  const handleConfirmSubmit = () => {
    if (childRef.current) {
      childRef.current.handleSubmit();
    }
  };
  useEffect(() => {
    setInitValue(informationDynamic ?? information);
  }, [informationDynamic, information]);

  useEffect(() => {
    if (isModifiedValue) {
      setModifiedPage(isModifiedValue);
    } else {
      const initValueString = JSON.stringify(initValue);
      const currentValueString = JSON.stringify(formik.values);
      setModifiedPage(!(initValueString === currentValueString));
    }
  }, [initValue, formik.values, isModifiedValue]);
  return (
    <Wrapper collapsedMenu={collapsedMenu}>
      <Header title={headerTitle} />
      {nextScreen ? (
        <ConfirmAdministratorMaster
          goBack={toggleScreen}
          checkData={!information}
          method={method}
          ref={childRef}
          setIsModifiedValue={setIsModifiedValue}
        />
      ) : (
        <div className="container">
          <div className="description">
            <p>
              管理者情報の確認・編集を行う画面です。
              <br />
              編集完了後に確認画面へボタンをクリックしてください。
            </p>
          </div>
          <FormikProvider value={formik}>
            <Form
              layout="vertical"
              labelCol={{
                flex: '25%',
              }}
              colon={false}
            >
              <div className="form">
                <Form.Item
                  name="name"
                  label={
                    <span className="text-label">
                      法人名
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="name"
                    className="input"
                    type="text"
                    placeholder="最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="name_furigana"
                  label={
                    <span className="text-label">
                      法人名（フリガナ）
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="name_furigana"
                    className="input"
                    type="text"
                    placeholder="最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="postal_code"
                  label={
                    <span className="text-label">
                      郵便番号
                      <span className="require">*</span>
                    </span>
                  }
                  className={`form-input wrap-input-zip-code ${
                    formik.touched.postal_code && formik.errors.postal_code
                      ? 'error-input-zip-code'
                      : ''
                  }`}
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="postal_code"
                    className="input"
                    type="text"
                    placeholder="7文字"
                    onKeyPress={(e) => {
                      if (
                        isNaN(parseInt(e.key)) ||
                        formik.values.postal_code!.toString().length > 6
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="btn-check-zipCode"
                    onClick={convertZipCodeToAddress}
                  >
                    郵便番号から住所検索
                  </button>
                </Form.Item>
                <Form.Item
                  name="prefecture"
                  label={
                    <span className="text-label">
                      都道府県
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="prefecture"
                    type="text"
                    placeholder="3文字以上4文字以下"
                    className="input"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="address"
                  label={
                    <span className="text-label">
                      市区町村
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="address"
                    className="input"
                    type="text"
                    placeholder="最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="plot_number"
                  label={
                    <span className="text-label">
                      番地
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="plot_number"
                    className="input"
                    type="text"
                    placeholder="最大100文字"
                    maxLength={100}
                  />
                </Form.Item>

                <Form.Item
                  name="building_name"
                  label={<span className="text-label">建物名・部屋番号</span>}
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="building_name"
                    className="input"
                    type="text"
                    placeholder="最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="method_selection"
                  label={<span className="text-label">管理者変更方法選択</span>}
                  className="form-input method-selection"
                >
                  <SelectField
                    value={method}
                    name="method_selection"
                    onChange={(e) => {
                      setMethod(e);
                      setOpen(e === '2');
                    }}
                    placeholder="選択してください"
                  >
                    <Option key={1} value={'1'}>
                      社内ユーザーマスタから選択
                    </Option>
                    <Option key={2} value={'2'}>
                      社内ユーザーを新規作成
                    </Option>
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="admin_email"
                  label={
                    <span className="text-label">
                      管理者メールアドレス
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <SelectField
                    disabled={method !== '1'}
                    name="admin_email"
                    className="input"
                    onChange={(e) => setEmail(e)}
                  >
                    {unionBy(employees, 'email').map((e, index) => (
                      <Option key={index} value={e.email}>
                        {e.email}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="admin_name"
                  label={
                    <span className="text-label">
                      管理者氏名
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled
                    name="admin_name"
                    className="input"
                    type="text"
                    placeholder="最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="admin_name_furigana"
                  label={
                    <span className="text-label">
                      管理者氏名 （フリガナ）
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled
                    name="admin_name_furigana"
                    className="input"
                    type="text"
                    placeholder="最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="admin_department"
                  label={
                    <span className="text-label">
                      所属
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled
                    name="admin_department"
                    className="input"
                    type="text"
                    placeholder="最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="admin_position"
                  label={
                    <span className="text-label">
                      役職
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled
                    name="admin_position"
                    className="input"
                    type="text"
                    placeholder="最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="admin_phone"
                  label={
                    <span className="text-label">
                      電話番号
                      <span className="require">*</span>
                    </span>
                  }
                  className={`form-input wrap-input-zip-code ${
                    formik.touched.postal_code && formik.errors.postal_code
                      ? 'error-input-zip-code'
                      : ''
                  }`}
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="admin_phone"
                    className="input-phone"
                    type="text"
                    placeholder="10文字以上15文字以下（ハイフン無し）"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const filteredValue = inputValue.replace(/[^0-9]/g, '');
                      formik.setFieldValue('admin_phone', filteredValue);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === '-') return;
                      if (
                        isNaN(parseInt(e.key)) ||
                        formik.values.admin_phone!.toString().replaceAll('-', '').length > 14
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="fax"
                  label={
                    <span className="text-label">
                      FAX
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="fax"
                    className="input"
                    type="text"
                    placeholder="10文字以上20文字以下（ハイフンあり・無しどちらでも可）"
                    maxLength={20}
                  />
                </Form.Item>
                <Form.Item
                  name="business_registration_number"
                  label={
                    <span className="text-label">
                      登録番号（インボイス）
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="business_registration_number"
                    className="input"
                    type="text"
                    placeholder="最大14文字（「T」+「法人番号（13文字）」）"
                    maxLength={14}
                  />
                </Form.Item>
                <Form.Item
                  name="bank_name"
                  label={
                    <span className="text-label">
                      銀行名（請求書記載振込先）
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="bank_name"
                    className="input"
                    type="text"
                    placeholder="最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="bank_code"
                  label={
                    <span className="text-label">
                      銀行コード（請求書記載振込先）
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="bank_code"
                    className="input"
                    type="text"
                    placeholder="最大4文字"
                    maxLength={4}
                  />
                </Form.Item>
                <Form.Item
                  name="branch_name"
                  label={
                    <span className="text-label">
                      支店名（請求書記載振込先）
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="branch_name"
                    className="input"
                    type="text"
                    placeholder="最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="branch_code"
                  label={
                    <span className="text-label">
                      支店コード（請求書記載振込先）
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="branch_code"
                    className="input"
                    type="text"
                    placeholder="最大3文字"
                    maxLength={3}
                  />
                </Form.Item>
                <Form.Item
                  name="bank_account_type"
                  label={
                    <span className="text-label">
                      口座種別（請求書記載振込先）
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="bank_account_type"
                    className="input"
                    type="text"
                    placeholder="最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="bank_account_number"
                  label={
                    <span className="text-label">
                      口座番号（請求書記載振込先）
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="bank_account_number"
                    className="input"
                    type="text"
                    placeholder="7文字"
                    maxLength={7}
                  />
                </Form.Item>
              </div>
            </Form>
          </FormikProvider>
          <CreateUserModal
            open={open}
            setOpen={setOpen}
            setMethod={setMethod}
            setEmail={setEmail}
          />
        </div>
      )}
      <div className={`wrap-button ${nextScreen ? 'wrap-button-confirm' : ''}`}>
        {nextScreen ? (
          <>
            <button className="btn-submit" onClick={handleConfirmSubmit}>
              更新
            </button>
            <button className="cancel-btn" onClick={toggleScreen}>
              戻る
            </button>
          </>
        ) : (
          <button
            onClick={() => formik.handleSubmit()}
            disabled={permissionNumber === 1}
            className={permissionNumber === 1 ? 'disabled' : 'btn-submit'}
          >
            確認画面へ
          </button>
        )}
      </div>
    </Wrapper>
  );
};

export default AdministratorMaster;
