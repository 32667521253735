import React, { useEffect, useState } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { SubmitButton, Form } from 'formik-antd';
import { useNavigate } from 'react-router-dom';
import { Table, Select, Popover } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { filter, find } from 'lodash';
import saveAs from 'file-saver';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import {
  QuestionCircleOutlined,
  CheckSquareOutlined,
  CheckSquareFilled,
  FileTextOutlined,
  SearchOutlined,
  RightOutlined,
  MailOutlined,
} from '@ant-design/icons';

import { HEADER_COMPANY_USER_CSV } from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { SelectField, PagingNumber, PerPageSelect } from 'components';
import { settingSelector } from 'containers/AppSettings/selectors';
import CooperateUserManagementDocument from './PDFExportDocument';
import { corporateUserInformationSelector } from './selector';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import CorporateUserInformationListStyled from './styles';
import { authSelector } from 'containers/Auth/selectors';
import ModalCorporateUserManagementDetail from './Modal';
import Completed from 'components/Modal/Completed';
import { filterDataCorporateUsers } from './slice';
import { exportCsv } from 'libs/utils/exportCsv';
import HeaderDashboard from 'components/Header';
import { PopoverStyled } from './Modal/styles';
import { routes } from 'navigations/routes';
import { pdf } from '@react-pdf/renderer';
import * as Types from 'types';
import {
  executeFunctionDeleteMailBatch,
  executeFunctionCreateBatchID,
  getCorporateUserInformation,
  updateHistoryOfUsing,
  getHistoryOfUsing,
  updateCompanyUser,
  executeAction,
} from './thunk';
import { SELECT_RECORD } from 'constant/select.constants';

const { Option } = Select;

const formikDetailSchema = Yup.object().shape({
  suspension_mail_send_date: Yup.date().when('suspension_mail_send_flg', {
    is: true,
    then: Yup.date().required(),
    otherwise: Yup.date(),
  }),
  unpaid_mail_send_date: Yup.date().when('unpaid_mail_send_flg', {
    is: true,
    then: Yup.date().required(),
    otherwise: Yup.date(),
  }),
  forced_withdrawal_mail_send_date: Yup.date().when('forced_withdrawal_mail_send_flg', {
    is: true,
    then: Yup.date().required(),
    otherwise: Yup.date(),
  }),
});

const CorporateUserInformation: React.FC = () => {
  const [visibleExportFile, setVisibleExportFile] = useState(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [openMail, setOpenMail] = useState<number>(-1);
  const [perPage, setPerPage] = useState<number>(100);
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [formValue, setFormValue] = useState({
    contract_status: '',
    company_id: '',
    company_name: '',
    payment_method_name: '',
  });
  const [corporateUserDetail, setCorporateUserDetail] =
    useState<Types.CorporateUserInformationType>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    corporateUsersFilled,
    selectPaymentMethod,
    selectCompanyName,
    selectCompanyId,
    corporateUsers,
    historyOfUsing,
  } = useSelector(corporateUserInformationSelector);

  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const fetchDataCorporateUserInformation = async () => {
    if (!userInfo) return;
    dispatch(startLoading());

    await Promise.all([
      dispatch(
        getCorporateUserInformation({
          sort_fields: [
            {
              id: 'id',
              order: 'asc',
            },
          ],
          use_display_id: true,
          include_item_ref: true,
          include_lookups: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getHistoryOfUsing({
          conditions: [
            {
              id: 'contract_date',
              search_value: [dayjs().format('YYYYMM')],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
    ]);

    dispatch(stopLoading());
  };

  const filterDataDataTable = (values: Types.CorporateUserFormSearchFormik) => {
    const { contract_status, company_id, company_name, payment_method_name } = values;

    const dataFilled = filter(corporateUsers, (item) => {
      return (
        (contract_status === '' ||
          contract_status == null ||
          contract_status == 'ALL' ||
          item.contract_status === Number(contract_status)) &&
        (company_id === '' || company_id == null || item.company_id === company_id) &&
        (company_name === '' ||
          company_name == null ||
          item.company_name === (company_name === 'empty' ? '' : company_name)) &&
        (payment_method_name === '' ||
          payment_method_name == null ||
          item.payment_method_name === payment_method_name)
      );
    });

    dispatch(
      filterDataCorporateUsers({
        items: dataFilled,
      })
    );
  };

  const formik = useFormik<Types.CorporateUserFormSearchFormik>({
    initialValues: {
      contract_status: '',
      company_id: '',
      company_name: '',
      payment_method_name: '',
    },
    onSubmit: (values) => {
      setFormValue(values);
      filterDataDataTable(values);
      setPage(1);
    },
    onReset: () => {
      dispatch(
        filterDataCorporateUsers({
          items: corporateUsers,
        })
      );
      setPage(1);
    },
  });

  const formikDetail = useFormik<Types.UpdateCompanyUserDetailFormik>({
    initialValues: {
      forced_withdrawal_mail_send_flg: false,
      suspension_mail_send_flg: false,
      forced_withdrawal_delete: false,
      forced_withdrawal_mail_send_date: undefined,
      suspension_mail_send_date: undefined,
      unpaid_mail_send_date: undefined,
      unpaid_mail_send_flg: false,
      unpaid_alert_delete: false,
      suspension_delete: false,
      invoice_exception: false,
      memo: '',
    },
    validationSchema: formikDetailSchema,
    onSubmit: () => {},
  });

  const handleUpdateInvoiceDetail = async () => {
    dispatch(startLoading());
    const itemUpdate = {
      payment_method_cd: formikDetail.values.invoice_exception ? 2 : 1,
    };

    if (corporateUserDetail?.unpaid_mail_batch_id) {
      await dispatch(
        executeFunctionDeleteMailBatch({
          data: {
            params: {
              batch_id: corporateUserDetail.unpaid_mail_batch_id,
            },
          },
        })
      );
    }

    if (
      formikDetail.values.unpaid_alert_delete ||
      formikDetail.values.suspension_delete ||
      formikDetail.values.forced_withdrawal_delete
    ) {
      Object.assign(itemUpdate, {
        unpaid_alert_flg: 0,
        unpaid_alert_date: null,
        unpaid_mail_send_flg: 0,
        unpaid_mail_batch_id: null,
        unpaid_mail_send_date: null,

        suspension_flg: 0,
        suspension_date: null,
        suspension_mail_send_flg: 0,
        suspension_mail_batch_id: null,
        suspension_mail_send_date: null,

        forced_withdrawal_flg: 0,
        forced_withdrawal_date: null,
        forced_withdrawal_mail_send_flg: 0,
        forced_withdrawal_mail_batch_id: null,
        forced_withdrawal_mail_send_date: null,
        deletedat: null,
      });
    }

    if (
      !formikDetail.values.unpaid_alert_delete &&
      formikDetail.values.unpaid_mail_send_date &&
      !corporateUserDetail?.unpaid_mail_send_date
    ) {
      let batchIdUnpaid = '';

      const resultUnpaidCreateBatchId = await dispatch(executeFunctionCreateBatchID({ data: {} }));

      if (executeFunctionCreateBatchID.fulfilled.match(resultUnpaidCreateBatchId)) {
        batchIdUnpaid = resultUnpaidCreateBatchId.payload.data as string;
      }

      formikDetail.values.unpaid_mail_send_flg &&
        (await dispatch(
          executeAction({
            actionId: 'unpaid_mail_send',
            itemId: corporateUserDetail?.i_id || '',
            data: {
              item: {
                unpaid_mail_send_flg: formikDetail.values.unpaid_mail_send_flg ? 1 : 0,
                unpaid_mail_batch_id: batchIdUnpaid,
              },
              ...(formikDetail.values.unpaid_mail_send_date
                ? {
                    as_params: {
                      payment_method: corporateUserDetail?.payment_method_cd,
                      provided_date: dayjs().subtract(1, 'month').format('YYYY年MM月'),
                      unpaid_mail_batch_id: batchIdUnpaid,
                      unpaid_mail_send_date: dayjs(
                        formikDetail.values.unpaid_mail_send_date
                      ).unix(),
                    },
                  }
                : {}),
              is_force_update: true,
            },
          })
        ));

      if (formikDetail.values.unpaid_mail_send_date) {
        Object.assign(itemUpdate, {
          unpaid_alert_flg: 1,
          unpaid_alert_date: formikDetail.values.unpaid_mail_send_date,
          unpaid_mail_send_flg: formikDetail.values.unpaid_mail_send_flg ? 1 : 0,
          unpaid_mail_send_date: formikDetail.values.unpaid_mail_send_date,
        });
      }
    }

    if (corporateUserDetail?.suspension_mail_batch_id) {
      await dispatch(
        executeFunctionDeleteMailBatch({
          data: {
            params: {
              batch_id: corporateUserDetail.suspension_mail_batch_id,
            },
          },
        })
      );
    }

    if (
      !formikDetail.values.suspension_delete &&
      formikDetail.values.suspension_mail_send_date &&
      !corporateUserDetail?.suspension_mail_send_date
    ) {
      let batchIdSuspension = '';

      const resultSuspensionCreateBatchId = await dispatch(
        executeFunctionCreateBatchID({ data: {} })
      );

      if (executeFunctionCreateBatchID.fulfilled.match(resultSuspensionCreateBatchId)) {
        batchIdSuspension = resultSuspensionCreateBatchId.payload.data as string;
      }

      formikDetail.values.suspension_mail_send_flg &&
        (await dispatch(
          executeAction({
            actionId: 'suspension_mail_send',
            itemId: corporateUserDetail?.i_id || '',
            data: {
              item: {
                suspension_mail_send_flg: formikDetail.values.suspension_mail_send_flg ? 1 : 0,
                suspension_mail_batch_id: batchIdSuspension,
              },
              ...(formikDetail.values.suspension_mail_send_date
                ? {
                    as_params: {
                      payment_method: corporateUserDetail?.payment_method_cd,
                      provided_date: dayjs().subtract(1, 'month').format('YYYY年MM月'),
                      suspension_mail_batch_id: batchIdSuspension,
                      suspension_mail_send_date: dayjs(
                        formikDetail.values.suspension_mail_send_date
                      ).unix(),
                    },
                  }
                : {}),
              is_force_update: true,
            },
          })
        ));

      if (formikDetail.values.suspension_mail_send_date) {
        Object.assign(itemUpdate, {
          suspension_flg: 1,
          suspension_date: formikDetail.values.suspension_mail_send_date,
          suspension_mail_send_flg: formikDetail.values.suspension_mail_send_flg ? 1 : 0,
          suspension_mail_send_date: formikDetail.values.suspension_mail_send_date,
        });
      }
    }

    if (corporateUserDetail?.forced_withdrawal_mail_batch_id) {
      await dispatch(
        executeFunctionDeleteMailBatch({
          data: {
            params: {
              batch_id: corporateUserDetail.forced_withdrawal_mail_batch_id,
            },
          },
        })
      );
    }

    if (
      !formikDetail.values.forced_withdrawal_delete &&
      formikDetail.values.forced_withdrawal_mail_send_date &&
      !corporateUserDetail?.forced_withdrawal_mail_send_date
    ) {
      let batchIdWithdrawal = '';

      const resultWithdrawalCreateBatchId = await dispatch(
        executeFunctionCreateBatchID({ data: {} })
      );

      if (executeFunctionCreateBatchID.fulfilled.match(resultWithdrawalCreateBatchId)) {
        batchIdWithdrawal = resultWithdrawalCreateBatchId.payload.data as string;
      }

      formikDetail.values.forced_withdrawal_mail_send_flg &&
        (await dispatch(
          executeAction({
            actionId: 'forced_withdrawal_mail_send',
            itemId: corporateUserDetail?.i_id || '',
            data: {
              item: {
                forced_withdrawal_mail_send_flg: formikDetail.values.forced_withdrawal_mail_send_flg
                  ? 1
                  : 0,
                forced_withdrawal_mail_batch_id: batchIdWithdrawal,
              },
              ...(formikDetail.values.forced_withdrawal_mail_send_date
                ? {
                    as_params: {
                      forced_withdrawal_mail_batch_id: batchIdWithdrawal,
                      forced_withdrawal_mail_send_date: dayjs(
                        formikDetail.values.forced_withdrawal_mail_send_date
                      ).unix(),
                    },
                  }
                : {}),
              is_force_update: true,
            },
          })
        ));

      if (formikDetail.values.forced_withdrawal_mail_send_date) {
        Object.assign(itemUpdate, {
          forced_withdrawal_flg: 1,
          forced_withdrawal_date: formikDetail.values.forced_withdrawal_mail_send_date,
          forced_withdrawal_mail_send_flg: formikDetail.values.forced_withdrawal_mail_send_flg
            ? 1
            : 0,
          forced_withdrawal_mail_send_date: formikDetail.values.forced_withdrawal_mail_send_date,
          deletedat:
            formikDetail.values.unpaid_alert_delete ||
            formikDetail.values.suspension_delete ||
            formikDetail.values.forced_withdrawal_delete
              ? null
              : formikDetail.values.forced_withdrawal_mail_send_date,
        });
      }
    }

    if (formikDetail.values.memo) {
      Object.assign(itemUpdate, {
        memo: formikDetail.values.memo,
      });
    }

    const actionResult = await dispatch(
      updateCompanyUser({
        id: corporateUserDetail?.i_id || '',
        data: {
          item: itemUpdate,
          is_force_update: true,
        },
      })
    );
    if (updateCompanyUser.fulfilled.match(actionResult)) {
      const idHistoryOfUsing =
        find(historyOfUsing, {
          company_id: corporateUserDetail?.company_id || '',
          contract_date: dayjs().format('YYYYMM'),
        })?.i_id || '';

      if (idHistoryOfUsing) {
        await dispatch(
          updateHistoryOfUsing({
            id: idHistoryOfUsing,
            data: {
              item: {
                payment_method_cd: formikDetail.values.invoice_exception ? '2' : '1',
              },
              is_force_update: true,
            },
          })
        );
      }

      await fetchDataCorporateUserInformation();

      setCompleted(true);
    }
    dispatch(stopLoading());
  };

  useEffect(() => {
    if (!corporateUserDetail) return;

    const record = find(corporateUsers, { company_id: corporateUserDetail.company_id });

    if (record) {
      setCorporateUserDetail(record);
      formikDetail.setFieldValue('memo', record.memo);
      formikDetail.setValues({
        ...formikDetail.initialValues,
        forced_withdrawal_mail_send_flg: !!record.forced_withdrawal_mail_send_flg,
        suspension_mail_send_flg: !!record.suspension_mail_send_flg,
        suspension_mail_send_date: record.suspension_mail_send_date
          ? new Date(record.suspension_mail_send_date)
          : undefined,
        unpaid_mail_send_flg: !!record.unpaid_mail_send_flg,
        unpaid_mail_send_date: record.unpaid_mail_send_date
          ? new Date(record.unpaid_mail_send_date)
          : undefined,
        forced_withdrawal_mail_send_date: record.forced_withdrawal_mail_send_date
          ? new Date(record.forced_withdrawal_mail_send_date)
          : undefined,
        memo: record.memo,
        invoice_exception: !!record.payment_method_cd && Number(record.payment_method_cd) === 2,
      });
    }
  }, [corporateUsers, corporateUserDetail]);

  useEffect(() => {
    filterDataDataTable(formik.values);
  }, [corporateUsers]);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerPage(corporateUsersFilled.length);
    }
  }, [corporateUsersFilled]);

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const listCsv = corporateUsersFilled.map((i) => ({
        contract_status: i.deletedat ? '退会' : '契約中',
        company_id: `${i.company_id}`,
        company_name: `${i.company_name}`,
        payment_method_name: i.payment_method_name ? `${i.payment_method_name}` : '-',
        month_end_users: `${i.month_end_users}`,
        skillcheck_trans_nums: `${i.skillcheck_trans_nums}`,
        admin_department_code: '',
        admin_department: `${i.admin_department}`,
        admin_position_code: '',
        admin_position: `${i.admin_position}`,
        admin_name: `${i.admin_name}`,
        admin_last_login_at: dayjs(i.admin_last_login_at).isValid()
          ? `${dayjs(i.admin_last_login_at).format('YYYY/MM/DD')}`
          : '-',
        invoice_exception:
          i.month_end_users > 500 ? '実行可能' : i.payment_method_cd === 2 ? '実行中' : '-',
        unpaid_alert_flg:
          i.unpaid_alert_flg === 1 ? '実行中' : i.unpaid_alert_flg === 0 ? '実行可能' : '-',
        suspension_flg:
          i.suspension_flg === 1 ? '実行中' : i.suspension_flg === 0 ? '実行可能' : '-',
        forced_withdrawal_flg:
          i.forced_withdrawal_flg === 1
            ? '実行中'
            : i.forced_withdrawal_flg === 0
            ? '実行可能'
            : '-',
      }));

      exportCsv(listCsv, HEADER_COMPANY_USER_CSV, '法人ユーザー情報一覧.csv');
    } else {
      const dataPDF = corporateUsersFilled;
      const blob = await pdf(
        <CooperateUserManagementDocument formValue={formValue} dataSource={dataPDF} />
      ).toBlob();
      saveAs(blob, '法人ユーザー情報一覧.pdf');
    }
    setVisibleExportFile(false);
  };

  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  const columnsSecond: ColumnsType<Types.CorporateUserInformationType> = [
    {
      title: '契約状況',
      dataIndex: 'contractStatus',
      key: 'contractStatus',
      width: '6%',
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <div className="wrap-contract-status">
          <span>{item.deletedat ? '退会' : '契約中'}</span>
        </div>
      ),
    },
    {
      title: (
        <>
          <span>法人ID</span>
          <br />
          <span>法人名</span>
        </>
      ),
      dataIndex: 'companyIdCompanyName',
      key: 'company_id_company_name',
      className: 'name',
      width: '13%',
      ellipsis: true,
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <span className="wrap-content-id">
          <span className="fax-id">{item.company_id}</span>
          <br />
          <span className="content-id">{item.company_name}</span>
        </span>
      ),
    },
    {
      title: '請求方法',
      dataIndex: 'payment_method_name',
      key: 'payment_method_name',
      width: '8%',
      render: (value: string, _item: Types.CorporateUserInformationType) => (
        <span>{value || '-'}</span>
      ),
    },
    {
      title: (
        <>
          <span>ユーザー数</span>
          <br />
          <span>スキルチェック数</span>
        </>
      ),

      dataIndex: 'month_end_user',
      key: 'month_end_user',
      width: '10%',
      align: 'right',
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <span className="wrap-content-id">
          <span>{item.month_end_users}</span>
          <br />
          <span>{item.skillcheck_trans_nums}</span>
        </span>
      ),
    },
    {
      title: (
        <>
          <span>所属 / 役職</span>
          <br />
          <span>管理者氏名</span>
        </>
      ),
      dataIndex: 'positionManagerName',
      key: 'positionManagerName',
      className: 'position-name',
      width: '15%',
      ellipsis: true,
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <span className="wrap-content-id">
          <span className="fax-id">
            {item.admin_department} / {item.admin_position}
          </span>
          <br />
          <span className="content-id">{item.admin_name}</span>
        </span>
      ),
    },
    {
      title: (
        <>
          <span>メール</span>
          <br />
          <span>送信</span>
        </>
      ),
      dataIndex: 'sendEmail',
      key: 'sendEmail',
      width: '5%',
      render: (_: any, item, index: number) => (
        <div className="wrap-icon-row">
          <MailOutlined
            onClick={(v) => {
              window.location.href = `mailto:${item.admin_email}`;
              setOpenMail((prevState) => (v && prevState !== index ? index : -1));
            }}
            className="icon"
            // style={{ color: openMail === index ? '#00a3a5' : '#c4c4c4' }}
          />
        </div>
      ),
    },
    {
      title: '最終ログイン',
      dataIndex: 'admin_last_login_at',
      key: 'admin_last_login_at',
      className: 'date',
      width: '8%',
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <span>
          {dayjs(item.admin_last_login_at).isValid()
            ? dayjs(item.admin_last_login_at).format('YYYY/MM/DD')
            : '-'}
        </span>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <span>請求書</span>
            <br />
            <span>特例</span>
          </div>

          <Popover
            overlayStyle={{ width: '33%', zIndex: 1000 }}
            content={
              <PopoverStyled>
                <span>請求書特例について</span>
                <div className="main-content">
                  <span>
                    請求書特例は通常請求書（銀行振込）でのお支払いは500アカウント以上の場合のみ
                    選択可能なところ、特別な理由によって請求書支払を認める場合に使用する機能です。
                    例）
                  </span>
                  <br />
                  <span>・利用開始後すぐに500アカウントに到達見込み</span>
                  <br />
                  <span>・クレジットを利用しない会社だがアカウント数が300予定</span>
                  <br />
                  <span>基本的にはクレジットだが、柔軟に対応</span>
                  <br />
                  <br />
                  <span>請求書特例の実行は詳細ボタンから各法人ごとに実行することができます。</span>
                </div>
              </PopoverStyled>
            }
            trigger="click"
          >
            <QuestionCircleOutlined className="icon-question" />
          </Popover>
        </div>
      ),
      dataIndex: 'payment_method_cd',
      key: 'payment_method_cd',
      width: '8%',
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <div className="wrap-check-box">
          {item.month_end_users > 500 ? (
            <CheckSquareOutlined className="check-invoice-outlined" />
          ) : item.payment_method_cd === 2 ? (
            <CheckSquareFilled className="check-invoice-filled" />
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <span>未払い</span>
            <br />
            <span>アラート</span>
          </div>
          <Popover
            overlayStyle={{ width: '17%', zIndex: 1000 }}
            content={
              <PopoverStyled>
                <span>未払いアラートについて</span>
                <div className="main-content">
                  <span>未払いアラートは未払いになった法人ユーザーの管理者のHOME画面上に</span>
                  <br />
                  <span>
                    警告のモーダルを展開する機能です。未払いのお知らせとお支払いの督促を表示します。
                  </span>
                  <br />
                  <span>実行は未払いになった際に速やかに実行します。</span>
                  <br />
                  <br />
                  <span>
                    未払いアラートの実行は詳細ボタンから各法人ごとに実行することができます。
                  </span>
                </div>
              </PopoverStyled>
            }
            trigger="click"
          >
            <QuestionCircleOutlined className="icon-question" />
          </Popover>
        </div>
      ),
      dataIndex: 'unpaid_alert_flg',
      key: 'unpaid_alert_flg',
      width: '8%',
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <div className="wrap-check-box">
          {item.unpaid_alert_flg === 0 ? (
            <CheckSquareOutlined className="check-withdrawal-outlined" />
          ) : item.unpaid_alert_flg === 1 ? (
            <CheckSquareFilled className="check-withdrawal-filled" />
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      title: (
        <div>
          利用停止
          <Popover
            overlayStyle={{ width: '17%', zIndex: 1000 }}
            content={
              <PopoverStyled>
                <span>利用停止について</span>
                <div className="main-content">
                  <span>
                    利用停止は未払い状態が一定期間継続した場合にSKILL
                    FAMILIARの利用を停止する機能です。（社内的な目安は未払い発生から60日（61日目）
                  </span>
                  <br />
                  <span>【実施後の状態】</span>
                  <br />
                  <span>・一般ユーザーのログイン停止（利用停止）</span>
                  <br />
                  <span>・管理者のHOME画面上に警告のモーダル表示</span>
                  <br />
                  <span>・管理者の利用機能の制限（HOME,ご利用状況画面のみ）</span>
                  <br />
                  <br />
                  <span>利用停止の実行は詳細ボタンから各法人ごとに実行することができます。</span>
                </div>
              </PopoverStyled>
            }
            trigger="click"
          >
            <QuestionCircleOutlined className="icon-question" />
          </Popover>
        </div>
      ),
      dataIndex: 'suspension_flg',
      key: 'suspension_flg',
      width: '8%',
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <div className="wrap-check-box">
          {item.suspension_flg === 0 ? (
            <CheckSquareOutlined className="check-withdrawal-outlined" />
          ) : item.suspension_flg === 1 ? (
            <CheckSquareFilled className="check-withdrawal-filled" />
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <span>強制</span>
            <br />
            <span>退会</span>
          </div>
          <Popover
            overlayStyle={{ width: '17%', zIndex: 1000 }}
            content={
              <PopoverStyled>
                <span>強制退会について</span>
                <div className="main-content">
                  <span>
                    請求方法が請求書のユーザーについて、お支払いの確認が取れない状態が90日間続いた
                  </span>
                  <br />
                  <span>
                    請求方法が請求書のユーザーについて、お支払いの確認が取れない状態が90日間続いた場合に強制的に退会していただくためのチェックボックスです。
                  </span>
                  <br />
                  <span>
                    強制退会までの連絡や強制退会の実行は責任者に必ず確認をとってから実行してください。
                  </span>
                  <br />
                  <span>
                    チェックを付ける場合は、詳細ボタンから強制退会日を入力・更新を行ってください。
                  </span>
                </div>
              </PopoverStyled>
            }
            trigger="click"
          >
            <QuestionCircleOutlined className="icon-question" />
          </Popover>
        </div>
      ),
      dataIndex: 'forcedWithdrawal',
      key: 'forcedWithdrawal',
      width: '6%',
      render: (_: string, item: Types.CorporateUserInformationType) => (
        <div className="wrap-check-box">
          {item.forced_withdrawal_flg === 0 ? (
            <CheckSquareOutlined className="check-withdrawal-outlined" />
          ) : item.forced_withdrawal_flg === 1 ? (
            <CheckSquareFilled className="check-withdrawal-filled" />
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      title: '詳細',
      dataIndex: 'detailed',
      key: 'detailed',
      width: '5%',
      render: (_: string, record: Types.CorporateUser.CorporateUserInformationType) => (
        <div className="wrap-icon-row">
          <FileTextOutlined
            onClick={() => {
              setVisible(true);
              setCorporateUserDetail(record);
              formikDetail.setFieldValue('memo', record.memo);
              formikDetail.setValues({
                ...formikDetail.initialValues,
                forced_withdrawal_mail_send_flg: record.forced_withdrawal_mail_send_flg
                  ? true
                  : false,
                suspension_mail_send_flg: record.suspension_mail_send_flg ? true : false,
                suspension_mail_send_date: record.suspension_mail_send_date
                  ? new Date(record.suspension_mail_send_date)
                  : undefined,
                unpaid_mail_send_flg: record.unpaid_mail_send_flg ? true : false,
                unpaid_mail_send_date: record.unpaid_mail_send_date
                  ? new Date(record.unpaid_mail_send_date)
                  : undefined,
                forced_withdrawal_mail_send_date: record.forced_withdrawal_mail_send_date
                  ? new Date(record.forced_withdrawal_mail_send_date)
                  : undefined,
                memo: record.memo,
                invoice_exception:
                  !!record.payment_method_cd && Number(record.payment_method_cd) === 2
                    ? true
                    : false,
              });
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    userInfo && isUserInfoChanged && fetchDataCorporateUserInformation();
  }, [userInfo, isUserInfoChanged]);

  return (
    <>
      <HeaderDashboard title={headerTitle} />
      <CorporateUserInformationListStyled checkTableNoData={!corporateUsersFilled.length}>
        <div className="wrap-nav">
          <p className="text-note">
            法人ユーザーの登録情報の確認・管理を行う画面です。
            <br />
            請求書対応の場合のURL発行もこの画面から行います。
          </p>
          <div className="wrap-button">
            <button onClick={() => navigate(routes.InvoiceCorrespondence.path)}>
              <span className="btn-text">
                請求書対応管理 <RightOutlined className="icon-right-outline" />
              </span>
            </button>
          </div>
        </div>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical" colon={false}>
            <div className="form-search">
              <Form.Item
                name="contract_status"
                className="item"
                label={<span className="text-label">契約状況</span>}
              >
                <SelectField
                  data-testid="cui-contract-status"
                  name="contract_status"
                  placeholder="指定なし"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children)?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  <Option value="ALL">ALL</Option>
                  <Option value="0">契約中</Option>
                  <Option value="1">退会</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="company_id"
                className="item"
                label={<span className="text-label">法人ID</span>}
              >
                <SelectField
                  data-testid="cui-company-id"
                  name="company_id"
                  placeholder="指定なし"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children)?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {selectCompanyId?.map(({ company_id }) => (
                    <Option key={company_id} value={company_id}>
                      {company_id}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="company_name"
                className="item"
                label={<span className="text-label">法人名</span>}
              >
                <SelectField
                  data-testid="cui-company-name"
                  name="company_name"
                  placeholder="指定なし"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children)?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {/* <Option key="empty" value="empty">
                    （空白）
                  </Option> */}
                  {selectCompanyName?.map(({ company_name }, index) => (
                    <Option key={index} value={company_name}>
                      {company_name || '（空白）'}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="payment_method_name"
                className="item"
                label={<span className="text-label">請求方法</span>}
              >
                <SelectField
                  data-testid="cui-payment-method"
                  name="payment_method_name"
                  placeholder="指定なし"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children)?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {selectPaymentMethod?.map((e) => (
                    <Option key={e.payment_method_name} value={e.payment_method_name}>
                      {e.payment_method_name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="wrap-body">
          <div className="text-count">
            <PagingNumber
              startItem={corporateUsersFilled.length ? `${(page - 1) * perPage + 1}` : ''}
              endItem={
                page * perPage > corporateUsersFilled.length
                  ? corporateUsersFilled.length
                  : page * perPage
              }
              totalItem={corporateUsersFilled.length}
            />
            <PerPageSelect
              data={corporateUsersFilled}
              perPage={perPage}
              setPage={setPage}
              setPerPage={setPerPage}
            />
          </div>
          <div className="btn-div">
            <button
              className="btn-control-number"
              onClick={() => setVisibleExportFile(!visibleExportFile)}
            >
              <span className="text">エクスポート</span>
            </button>
          </div>
          <div className="wrap-table">
            <Table
              className="table"
              rowClassName="border-hight-light"
              dataSource={corporateUsersFilled}
              columns={columnsSecond}
              pagination={{
                pageSize: perPage,
                current: page,
                onChange: setPage,
                showSizeChanger: false,
                position: ['topCenter', 'bottomCenter'],
              }}
              rowKey={(record) => JSON.stringify(record)}
              onRow={(data) => ({
                className: data.deletedat ? 'row-table-deleted' : 'row-table',
              })}
            />
          </div>
        </div>
        <ModalCorporateUserManagementDetail
          visible={visible}
          setVisible={setVisible}
          corporateUserDetail={corporateUserDetail}
          onSubmit={handleUpdateInvoiceDetail}
          formik={formikDetail}
        />
        <PopupConfirmExportFile
          title="エクスポートを実行します。"
          visible={visibleExportFile}
          setVisible={setVisibleExportFile}
          onSubmit={handleExportCSV}
        />
        <Completed title="更新が完了しました" visible={completed} setVisible={setCompleted} />
      </CorporateUserInformationListStyled>
    </>
  );
};

export default CorporateUserInformation;
