import styled from 'styled-components';

export default styled.div`
  padding: 0;
  .wrap-submit {
    position: sticky !important;
    width: 1244px !important;
    margin-top: 80px;
    transform: translate(-30px, 5px);
  }
  .header__right {
    display: none;
  }
`;
