import { Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const ChartInactive = ({ style }: { style?: Style | Style[] }) => (
  <Svg width="8" height="8" viewBox="0 0 18 18" fill="none" style={style}>
    <Path
      d="M16.5569 14.6261H2.73549V2.0904C2.73549 2.00201 2.66317 1.92969 2.57478 1.92969H1.44978C1.36138 1.92969 1.28906 2.00201 1.28906 2.0904V15.9118C1.28906 16.0002 1.36138 16.0725 1.44978 16.0725H16.5569C16.6453 16.0725 16.7176 16.0002 16.7176 15.9118V14.7868C16.7176 14.6984 16.6453 14.6261 16.5569 14.6261ZM4.50335 13.019H5.62835C5.71674 13.019 5.78906 12.9467 5.78906 12.8583V9.9654C5.78906 9.87701 5.71674 9.80469 5.62835 9.80469H4.50335C4.41496 9.80469 4.34263 9.87701 4.34263 9.9654V12.8583C4.34263 12.9467 4.41496 13.019 4.50335 13.019ZM7.55692 13.019H8.68192C8.77031 13.019 8.84263 12.9467 8.84263 12.8583V6.42969C8.84263 6.34129 8.77031 6.26897 8.68192 6.26897H7.55692C7.46853 6.26897 7.39621 6.34129 7.39621 6.42969V12.8583C7.39621 12.9467 7.46853 13.019 7.55692 13.019ZM10.6105 13.019H11.7355C11.8239 13.019 11.8962 12.9467 11.8962 12.8583V7.99665C11.8962 7.90826 11.8239 7.83594 11.7355 7.83594H10.6105C10.5221 7.83594 10.4498 7.90826 10.4498 7.99665V12.8583C10.4498 12.9467 10.5221 13.019 10.6105 13.019ZM13.6641 13.019H14.7891C14.8775 13.019 14.9498 12.9467 14.9498 12.8583V4.82254C14.9498 4.73415 14.8775 4.66183 14.7891 4.66183H13.6641C13.5757 4.66183 13.5033 4.73415 13.5033 4.82254V12.8583C13.5033 12.9467 13.5757 13.019 13.6641 13.019Z"
      fill="#C4C4C4"
      fill-opacity="0.4"
    />
  </Svg>
);

export default ChartInactive;
