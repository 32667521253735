import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  USERS,
  POSITIONS,
  DEPARTMENTS,
  SELECT_USERS,
  USER_ASSIGN_REQUIRED_CURRICULUM,
} from 'configs';
import { browserLogger } from 'libs/logger';

export const getDataSearchUsers = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('menuRightUser/thunk/getDataSearchUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.name, req);
    browserLogger.info('menuRightUser/thunk/getDataSearchUsers', USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.Selects.SelectUserResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('menuRightUser/thunk/getSelectUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.Selects.SelectUserResponseType>(
      SELECT_USERS.name,
      req
    );
    browserLogger.info('menuRightUser/thunk/getSelectUser', SELECT_USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPositionUser = createAsyncThunk<
  Types.GetItemResponseType<Types.Positions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('menuRightUser/thunk/getPositionUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(POSITIONS.name, req);
    browserLogger.info('menuRightUser/thunk/getPositionUser', POSITIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDepartmentUser = createAsyncThunk<
  Types.GetItemResponseType<Types.Departments.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('menuRightUser/thunk/getDepartmentUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(DEPARTMENTS.name, req);
    browserLogger.info('menuRightUser/thunk/getDepartmentUser', DEPARTMENTS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createUserAssignCurriculum = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.UserAssignRequiredCurriculum.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('menuRightUser/thunk/createUserAssignCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USER_ASSIGN_REQUIRED_CURRICULUM.name, req);
    browserLogger.info(
      'menuRightUser/thunk/createUserAssignCurriculum',
      USER_ASSIGN_REQUIRED_CURRICULUM.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
