import styled from 'styled-components';

interface Props {
  tabButton: boolean;
}

export default styled.div<Props>`
  .container {
    padding: 5px;
    .group-btn-tab {
      display: flex;
      justify-content: center;
      .btn {
        width: 100px;
        border: none;
        padding: 5px 0px;
        background: red;
        background-color: #ffffff;
      }
      .btn-active {
        background-color: #f9f8f8;
      }
    }
    .body-item {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 11px;
        color: #a5a5a5;
      }
      .input {
        margin: 5px 0px;
        padding: 5px 5px;
        border: 1px solid #dadada;
      }
    }
    .ant-upload-list.ant-upload-list-text {
      display: none !important;
    }
  }
`;
