import React, { useCallback, useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Form } from 'formik-antd';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { createEditInvoiceCorrespondenceSchema } from 'libs/validations';
import { invoiceCorrespondenceSelector } from '../../selector';
import { Input, TextArea } from 'components';
import { authSelector } from 'containers/Auth/selectors';
import { ModalStyled, Styled } from './styles';
import ErrorSignUpModal from '../ErrorSignUp';
import { useAppDispatch } from 'hooks';
import { config, DISPLAY_ID_KEY } from 'configs';
import * as Types from 'types';
import {
  createInvoiceCorrespondence,
  getInvoiceCorrespondenceDetail,
  getSingUp,
  inviteUser,
  postUnauthorizedCall,
  updateCompany,
  updateInvoiceCorrespondence,
} from '../../thunk';
import { browserLogger } from 'libs/logger';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleComplete: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'create' | 'edit';
  fetchInvoiceCorrespondence?: (conditions?: Array<Types.ConditionsType>) => void;
  idSelected?: string;
}

const ModalCreateEditInfoInvoice: React.FC<Props> = ({
  visible,
  setVisible,
  type,
  setVisibleComplete,
  fetchInvoiceCorrespondence,
  idSelected,
}) => {
  const [openError, setOpenError] = useState<boolean>(false);

  const { invoiceCorrespondenceDetail } = useSelector(invoiceCorrespondenceSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik<Types.InvoiceCorrespondenceFormEditFormik>({
    initialValues:
      type === 'edit'
        ? invoiceCorrespondenceDetail!
        : {
            company_name: '',
            manager_name: '',
            email: '',
            memo: '',
          },
    validationSchema: createEditInvoiceCorrespondenceSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!userInfo) return;
      dispatch(startLoading());

      if (type === 'create') {
        // サインアップ登録
        const actionResult2 = await dispatch(
          postUnauthorizedCall({
            workspace_id: 'skillfamiliarproduct',
            url: '/api/v0/applications/skillfamiliar/datastores/signup/items/new',
            method: 'POST',
            params: {
              action_id: 'signup',
              item: {
                name: values.email,
              },
              as_params: {
                email: values.email,
                username: values.email,
              },
              is_force_update: true,
            },
          })
        );

        if (postUnauthorizedCall.fulfilled.match(actionResult2)) {
          // ユーザー招待
          const actionResult3 = await dispatch(
            inviteUser({
              workspace_id: 'skillfamiliarproduct',
              url: `/api/v0/applications/skillfamiliar/datastores/signup/items/action/${actionResult2.payload?.item_id}/userinvite`,
              method: 'POST',
              params: {
                item: {
                  name: values.email,
                },
                as_params: {
                  users: [
                    {
                      email: values.email,
                      user_code: values.email,
                      exclusive_w_id: DISPLAY_ID_KEY.exclusive_w_id.name,
                    },
                  ],
                  email_templates_id: config.CONFIRM_TEMPLATES_ID,
                  domain: 'stg-rsweb.hexabase.com',
                  invitation_path: 'confirm_email',
                },
                is_force_update: true,
              },
            })
          );
          browserLogger.info(
            'page/InvoiceCorrespondence/getDataUser',
            DISPLAY_ID_KEY.exclusive_w_id.name,
            actionResult3
          );

          if (inviteUser.fulfilled.match(actionResult3)) {
            const actionResult0 = await dispatch(
              getSingUp({
                conditions: [
                  {
                    id: 'i_id',
                    search_value: [actionResult2.payload.item_id],
                  },
                ],
                page: 1,
                per_page: 1,
              })
            );
            if (!getSingUp.fulfilled.match(actionResult0)) {
              throw new Error('Failed to get sign up');
            }

            // 会社情報登録
            const actionResult1 = await dispatch(
              postUnauthorizedCall({
                workspace_id: 'skillfamiliarproduct',
                url: '/api/v0/applications/skillfamiliar/datastores/companies/items/new',
                method: 'POST',
                params: {
                  item: {
                    admin_email: values.email,
                    plan_id: config.PREMIUM_PLAN_ID,
                    payment_method_cd: '2',
                    month_end_users: 1,
                    name: values.company_name,
                    group_id: actionResult0.payload.items[0].group_id || undefined,
                  },
                  realtime_auto_link: true,
                  return_display_id: true,
                  return_item_result: true,
                  access_key_updates: {
                    roles_to_publish: ['ADMIN', 'MEMBER'],
                  },
                },
              })
            );

            if (postUnauthorizedCall.fulfilled.match(actionResult1)) {
              // 請求書対応用URL発行
              const createResult = await dispatch(
                createInvoiceCorrespondence({
                  item: {
                    company_id: actionResult1.payload.item.id,
                    manager_name: values.manager_name,
                    email: values.email,
                    memo: values.memo,
                    createdby: userInfo.login_id,
                    createdat: new Date(),
                    url_issue_date: new Date(),
                    company_name: values.company_name,
                  },
                  realtime_auto_link: true,
                  access_key_updates: {
                    roles_to_publish: ['MEMBER'],
                  },
                })
              );
              if (createInvoiceCorrespondence.fulfilled.match(createResult)) {
                fetchInvoiceCorrespondence && (await fetchInvoiceCorrespondence());
                setVisibleComplete(true);
                setVisible(false);
              } else {
                setOpenError(true);
              }
            }
          }
        } else {
          setOpenError(true);
        }
      } else {
        const actionResult = await Promise.all([
          dispatch(
            updateInvoiceCorrespondence({
              id: invoiceCorrespondenceDetail?.item_ref?.email.i_id!,
              data: {
                item: {
                  company_name: values.company_name,
                  manager_name: values.manager_name,
                  email: values.email,
                  memo: values.memo,
                  url_issue_date: new Date(),
                  account_registration_status: 0,
                  updatedby: userInfo.login_id,
                  updatedat: new Date(),
                },
                is_force_update: true,
                realtime_auto_link: true,
              },
            })
          ),
          ...(invoiceCorrespondenceDetail?.i_id
            ? [
                dispatch(
                  updateCompany({
                    id: invoiceCorrespondenceDetail?.i_id,
                    data: {
                      item: {
                        name: formik.values.company_name,
                      },
                      is_force_update: true,
                      realtime_auto_link: true,
                    },
                  })
                ),
              ]
            : []),
        ]);
        if (actionResult.every((action) => action)) {
          setVisibleComplete(true);
        }
      }
      dispatch(stopLoading());
    },
  });

  const fetchDataDetail = useCallback(async () => {
    if (!idSelected || !userInfo) {
      return;
    }

    dispatch(startLoading());

    await dispatch(
      getInvoiceCorrespondenceDetail({
        conditions: [
          {
            id: 'company_id',
            search_value: [idSelected],
          },
        ],
        page: 1,
        per_page: 0,
        include_item_ref: true,
        include_lookups: true,
      })
    );

    dispatch(stopLoading());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelected, dispatch]);

  useEffect(() => {
    if (visible) {
      fetchDataDetail();
    } else {
      formik.resetForm();
    }
  }, [fetchDataDetail, visible]);

  return (
    <ModalStyled
      open={visible}
      width={720}
      okButton={{
        text: type === 'create' ? 'URL発行' : '更新',
        onClick: formik.handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: () => {
          setVisible(false);
        },
      }}
      onCancel={() => setVisible(false)}
      type={type}
      title={type === 'create' ? '請求書対応用URL発行' : '請求書対管理情報 編集'}
    >
      <Styled>
        <FormikProvider value={formik}>
          <Form layout="horizontal" colon={false}>
            <div className="form-edit">
              <Form.Item
                name="company_name"
                className="item"
                label={
                  <span className="text-label">
                    法人名 <span className="sub-text-label">*</span>
                  </span>
                }
              >
                <Input name="company_name" showCount maxLength={100} placeholder="最大100文字" />
              </Form.Item>
              <Form.Item
                name="manager_name"
                className="item"
                label={
                  <span className="text-label">
                    ご担当者氏名 <span className="sub-text-label">*</span>
                  </span>
                }
              >
                <Input name="manager_name" showCount maxLength={100} placeholder="最大100文字" />
              </Form.Item>
              <Form.Item
                name="email"
                className="item"
                label={
                  <span className="text-label">
                    メールアドレス <span className="sub-text-label">*</span>
                  </span>
                }
              >
                {<Input name="email" showCount maxLength={254} placeholder="最大254文字" />}
              </Form.Item>
              <Form.Item
                name="memo"
                className="item"
                label={<span className="text-label">memo</span>}
              >
                <TextArea
                  name="memo"
                  showCount
                  maxLength={1080}
                  style={{ maxHeight: 100, marginBottom: 24, resize: 'vertical' }}
                  placeholder="最大1080文字"
                />
              </Form.Item>
            </div>
          </Form>
        </FormikProvider>
      </Styled>
      <ErrorSignUpModal open={openError} setOpen={setOpenError} />
    </ModalStyled>
  );
};

export default ModalCreateEditInfoInvoice;
