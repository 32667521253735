import { Radio, RadioChangeEvent } from 'antd';
import React, { useState } from 'react';

import { SectionStyled } from './styles';
import Modal from 'components/Modal';

interface Props {
  visible: boolean;
  disablePdf?: boolean;
  disableCsv?: boolean;
  onSubmit?: (value: string) => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  customTitle?: React.ReactNode;
  exportPdfDescription?: React.ReactNode;
  isLoadingButtonOk?: boolean;
}

const ModalConfirmExport: React.FC<Props> = ({
  visible,
  disablePdf,
  disableCsv,
  setVisible,
  onSubmit,
  title,
  customTitle,
  exportPdfDescription,
  isLoadingButtonOk,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [value, setValue] = useState<'csv' | 'pdf'>(disableCsv ? 'pdf' : 'csv');

  const handleToggleModal = () => {
    setVisible((prevState) => !prevState);
  };

  const onChange = (e: RadioChangeEvent): void => {
    setValue(e.target.value);
  };

  return (
    <Modal
      title={
        <h3
          style={{
            display: 'flex',
            backgroundColor: '#ffffff',
            fontFamily: 'Noto Sans Javanese',
            fontSize: 18,
            justifyContent: 'center',
            alignItems: 'center',
            color: '#2a2a2a',
            margin: '0',
          }}
        >
          エクスポート
        </h3>
      }
      open={visible}
      width={720}
      onCancel={handleToggleModal}
      okButton={{
        text: 'OK',
        onClick: async () => {
          setSubmitting(true);
          onSubmit && (await onSubmit(value));
          setSubmitting(false);
        },
        loading:
          isLoadingButtonOk === true || isLoadingButtonOk === undefined
            ? submitting
            : isLoadingButtonOk,
        style: {
          width: 140,
          height: 40,
        },
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleToggleModal,
        style: {
          width: 140,
          height: 40,
        },
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <div className="content">
          <p className="text-content">
            {customTitle ? (
              customTitle
            ) : (
              <span style={{ color: '#424242' }}>
                {title}
                <br />
                出力形式を選択して、OKボタンをクリックしてください。
                {value === 'pdf' && exportPdfDescription && (
                  <>
                    <br />
                    {exportPdfDescription}
                  </>
                )}
              </span>
            )}
          </p>
          <Radio.Group onChange={onChange} value={value} className="radio-group">
            <div
              style={{ justifyContent: 'left', width: '50%', display: 'flex', paddingLeft: '15px' }}
            >
              <Radio value="csv" className="radio-item" disabled={disableCsv}>
                CSV出力
              </Radio>
            </div>

            <div className="border" />
            <div
              style={{ justifyContent: 'left', width: '50%', display: 'flex', paddingLeft: '15px' }}
            >
              <Radio value="pdf" className="radio-item" disabled={disablePdf}>
                PDF出力
              </Radio>
            </div>
          </Radio.Group>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default ModalConfirmExport;
