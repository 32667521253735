import React, { useMemo } from 'react';
import { Table } from 'antd';

import TableDevelopmentStyled from './styles';
import * as Types from 'types';
import { uniq } from 'lodash';
import { formatComma } from 'libs/utils/format';
import dayjs from 'dayjs';
import { groupSaleReportData } from 'libs/utils/report';
import { useSelector } from 'react-redux';
import { reportSaleInformationSelector } from 'pages/Report/SaleInformation/selectors';
import { EXPORT_TARGET_MONTH_COUNT } from 'constant';
import { v4 as uuidv4 } from 'uuid';
import { AnyObject } from 'types';
import { ColumnType } from 'antd/lib/table';

interface Props {
  visibleExpansionOfSale: boolean;
  visibleCountUsage: boolean;
  data: Array<Types.SaleReportInformation.SaleReportInformation>;
  exportPdf?: boolean;
}

const USER_TYPE = {
  ALL: '全ユーザー',
  CORPORATE: '法人ユーザー',
  PERSONAL: '個人ユーザー',
};

const SALE_TYPE = {
  ALL: 'ALL',
  USER_FEE: 'ユーザー利用料',
  SKILL_CHECK_USAGE_FEE: 'スキルチェック利用料',
  STORAGE_USAGE_FEE: 'ストレージ利用料',
};

const TableDevelopment: React.FC<Props> = ({
  visibleExpansionOfSale,
  visibleCountUsage,
  data,
  exportPdf,
}) => {
  const { dateRanger, userType, saleType } = useSelector(reportSaleInformationSelector);

  const filterUsers = useMemo(() => {
    if (!userType || userType === USER_TYPE.ALL) {
      return Object.values(USER_TYPE);
    }
    return Object.values(USER_TYPE).filter((user) => user === userType);
  }, [userType]);

  const dates = useMemo(() => {
    let dateData =
      dateRanger && dateRanger.length ? dateRanger : uniq(data.map((obj) => obj.target_month));
    if (exportPdf) {
      dateData = dateData.slice(0, EXPORT_TARGET_MONTH_COUNT);
    }
    return dateData;
  }, [data, dateRanger, exportPdf]);

  const DEFAULT_COLUMNS: ColumnType<any>[] = [
    {
      title: 'ユーザー種類',
      dataIndex: 'user_type',
      key: 'user_type',
      fixed: 'left',
      width: 100,
    },
    {
      title: '売上種類',
      dataIndex: 'sales_type',
      key: 'sales_type',
      fixed: 'left',
      width: visibleExpansionOfSale ? 180 : 150,
    },
  ];

  const columns = useMemo(() => {
    const dateColumns = dates.map((item, index) => ({
      title: dayjs(item, 'YYYYMM').format('YYYY/MM'),
      dataIndex: item,
      key: item,
      width: 95,
      className: 'text-center',
      render: (text: string) => (text ? <span>{formatComma(text)}</span> : <span>-</span>),
      onCell: () => ({
        className: 'text-right',
      }),
    }));
    return [...DEFAULT_COLUMNS, ...dateColumns];
  }, [DEFAULT_COLUMNS, data, dates, visibleExpansionOfSale]);

  const result = useMemo(() => groupSaleReportData(data, dates), [data, dates]);

  const dataSource = useMemo(() => {
    const filterSaleType = saleType || SALE_TYPE.ALL;
    if (visibleExpansionOfSale && visibleCountUsage) {
      const corporateChild = [];
      const personalChild = [];
      if ([SALE_TYPE.ALL, SALE_TYPE.USER_FEE].includes(filterSaleType)) {
        corporateChild.push({
          id: uuidv4(),
          sales_type: SALE_TYPE.USER_FEE,
          ...result[USER_TYPE.CORPORATE][SALE_TYPE.USER_FEE].data,
          children: [
            {
              id: uuidv4(),
              sales_type: 'ユーザー数（人）',
              ...result[USER_TYPE.CORPORATE][SALE_TYPE.USER_FEE].child,
            },
          ],
        });
        personalChild.push({
          id: uuidv4(),
          sales_type: SALE_TYPE.USER_FEE,
          ...result[USER_TYPE.PERSONAL][SALE_TYPE.USER_FEE].data,
          children: [
            {
              id: uuidv4(),
              sales_type: 'ユーザー数（人）',
              ...result[USER_TYPE.PERSONAL][SALE_TYPE.USER_FEE].child,
            },
          ],
        });
      }
      if ([SALE_TYPE.ALL, SALE_TYPE.SKILL_CHECK_USAGE_FEE].includes(filterSaleType)) {
        corporateChild.push({
          id: uuidv4(),
          sales_type: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
          ...result[USER_TYPE.CORPORATE][SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
          children: [
            {
              id: uuidv4(),
              sales_type: 'スキルチェック利用数（回）',
              ...result[USER_TYPE.CORPORATE][SALE_TYPE.SKILL_CHECK_USAGE_FEE].child,
            },
          ],
        });
        personalChild.push({
          id: uuidv4(),
          sales_type: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
          ...result[USER_TYPE.PERSONAL][SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
          children: [
            {
              id: uuidv4(),
              sales_type: 'スキルチェック利用数（回）',
              ...result[USER_TYPE.PERSONAL][SALE_TYPE.SKILL_CHECK_USAGE_FEE].child,
            },
          ],
        });
      }
      if ([SALE_TYPE.ALL, SALE_TYPE.STORAGE_USAGE_FEE].includes(filterSaleType)) {
        corporateChild.push({
          id: uuidv4(),
          sales_type: SALE_TYPE.STORAGE_USAGE_FEE,
          ...result[USER_TYPE.CORPORATE][SALE_TYPE.STORAGE_USAGE_FEE].data,
          children: [
            {
              id: uuidv4(),
              sales_type: 'ストレージ利用量（GB）',
              ...result[USER_TYPE.CORPORATE][SALE_TYPE.STORAGE_USAGE_FEE].child,
            },
          ],
        });
      }
      return [
        {
          id: uuidv4(),
          user_type: USER_TYPE.ALL,
          sales_type: SALE_TYPE.ALL,
          ...result[USER_TYPE.ALL][SALE_TYPE.ALL],
          children: [
            {
              id: uuidv4(),
              sales_type: USER_TYPE.CORPORATE,
              ...result[USER_TYPE.CORPORATE][SALE_TYPE.ALL],
            },
            {
              id: uuidv4(),
              sales_type: USER_TYPE.PERSONAL,
              ...result[USER_TYPE.PERSONAL][SALE_TYPE.ALL],
            },
          ],
        },
        {
          id: uuidv4(),
          user_type: USER_TYPE.CORPORATE,
          sales_type: SALE_TYPE.ALL,
          ...result[USER_TYPE.CORPORATE][SALE_TYPE.ALL],
          children: [...corporateChild],
        },
        {
          id: uuidv4(),
          user_type: USER_TYPE.PERSONAL,
          sales_type: SALE_TYPE.ALL,
          ...result[USER_TYPE.PERSONAL][SALE_TYPE.ALL],
          children: [...personalChild],
        },
      ].filter((obj) => filterUsers.includes(obj.user_type));
    }
    if (visibleExpansionOfSale) {
      const corporateChild = [];
      const personalChild = [];
      if ([SALE_TYPE.ALL, SALE_TYPE.USER_FEE].includes(filterSaleType)) {
        corporateChild.push({
          id: uuidv4(),
          sales_type: SALE_TYPE.USER_FEE,
          ...result[USER_TYPE.CORPORATE][SALE_TYPE.USER_FEE].data,
        });
        personalChild.push({
          id: uuidv4(),
          sales_type: SALE_TYPE.USER_FEE,
          ...result[USER_TYPE.PERSONAL][SALE_TYPE.USER_FEE].data,
        });
      }
      if ([SALE_TYPE.ALL, SALE_TYPE.SKILL_CHECK_USAGE_FEE].includes(filterSaleType)) {
        corporateChild.push({
          id: uuidv4(),
          sales_type: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
          ...result[USER_TYPE.CORPORATE][SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
        });
        personalChild.push({
          id: uuidv4(),
          sales_type: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
          ...result[USER_TYPE.PERSONAL][SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
        });
      }
      if ([SALE_TYPE.ALL, SALE_TYPE.STORAGE_USAGE_FEE].includes(filterSaleType)) {
        corporateChild.push({
          id: uuidv4(),
          sales_type: SALE_TYPE.STORAGE_USAGE_FEE,
          ...result[USER_TYPE.CORPORATE][SALE_TYPE.STORAGE_USAGE_FEE].data,
        });
      }
      return [
        {
          id: uuidv4(),
          user_type: USER_TYPE.ALL,
          sales_type: SALE_TYPE.ALL,
          ...result[USER_TYPE.ALL][SALE_TYPE.ALL],
          children: [
            {
              id: uuidv4(),
              sales_type: USER_TYPE.CORPORATE,
              ...result[USER_TYPE.CORPORATE][SALE_TYPE.ALL],
            },
            {
              id: uuidv4(),
              sales_type: USER_TYPE.PERSONAL,
              ...result[USER_TYPE.PERSONAL][SALE_TYPE.ALL],
            },
          ],
        },
        {
          id: uuidv4(),
          user_type: USER_TYPE.CORPORATE,
          sales_type: SALE_TYPE.ALL,
          ...result[USER_TYPE.CORPORATE][SALE_TYPE.ALL],
          children: [...corporateChild],
        },
        {
          id: uuidv4(),
          user_type: USER_TYPE.PERSONAL,
          sales_type: SALE_TYPE.ALL,
          ...result[USER_TYPE.PERSONAL][SALE_TYPE.ALL],
          children: [...personalChild],
        },
      ].filter((obj) => filterUsers.includes(obj.user_type));
    }

    return filterUsers.map((userType1) => {
      return {
        id: uuidv4(),
        user_type: userType1,
        sales_type: filterSaleType,
        ...(result[userType1][filterSaleType]?.data || result[userType1][filterSaleType]),
      };
    });
  }, [result, visibleExpansionOfSale, visibleCountUsage, filterUsers, saleType]);

  const expandRowKeys = useMemo(() => {
    const rowKeys: string[] = [];
    const getRowKeys = (items: AnyObject[]) => {
      for (const item of items) {
        if (item.children) {
          rowKeys.push(item.id);
          if (item.children.length) getRowKeys(item.children);
        }
      }
    };
    getRowKeys(dataSource);

    return rowKeys;
  }, [dataSource]);

  return (
    <TableDevelopmentStyled>
      <Table
        key={dataSource.length}
        className="table-development"
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        expandable={{
          defaultExpandAllRows: true,
          expandedRowKeys: expandRowKeys,
        }}
        pagination={false}
        scroll={{
          x: 1440,
        }}
      />
    </TableDevelopmentStyled>
  );
};

export default TableDevelopment;
