import yup from '../yup';

const regex = {
  halfwidth: /^[0-9]+(\.[0-9]+)*$/,
  version: /^\d{1,2}\.\d{1,2}\.\d{1,2}$/,
};

export const publicManagement = yup.object().shape({
  title: yup.string(),
  description: yup.string(),
  version: yup
    .string()
    .required()
    .matches(regex.version, 'バージョンは正しいフォーマットで入力してください（1.2.3など）'),
  folder_id: yup.string(),
  sections: yup.array().of(
    yup.object().shape({
      section_name: yup.string().trim().required(),
      text: yup.string(),
    })
  ),
});

export const informationSchema = yup.object().shape({
  creator: yup.string().required('制作者は必須項目です。'),
});

export const createEditReleaseNote = yup.object().shape({
  version: yup
    .string()
    .required()
    .matches(regex.version, 'バージョンは正しいフォーマットで入力してください（1.2.3など）'),
});
