import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';
import { Style } from 'util';

const IconLocked = ({ style }: { style?: Style }) => (
  <Svg width="16" height="16" viewBox="0 0 16 16">
    <Rect width="16" height="16" rx="2" fill="#D48AE0" />
    <Path
      d="M12.5258 7.48307H11.5908V4.40307C11.5908 3.43094 10.8029 2.64307 9.83077 2.64307H6.42077C5.44864 2.64307 4.66077 3.43094 4.66077 4.40307V7.48307H3.72577C3.48239 7.48307 3.28577 7.67969 3.28577 7.92307V13.2031C3.28577 13.4464 3.48239 13.6431 3.72577 13.6431H12.5258C12.7691 13.6431 12.9658 13.4464 12.9658 13.2031V7.92307C12.9658 7.67969 12.7691 7.48307 12.5258 7.48307ZM8.51077 10.7418V11.4706C8.51077 11.5311 8.46127 11.5806 8.40077 11.5806H7.85077C7.79027 11.5806 7.74077 11.5311 7.74077 11.4706V10.7418C7.62727 10.6603 7.54255 10.5449 7.49881 10.4122C7.45507 10.2795 7.45456 10.1364 7.49737 10.0034C7.54017 9.87034 7.62407 9.75435 7.737 9.67206C7.84992 9.58977 7.98604 9.54544 8.12577 9.54544C8.26549 9.54544 8.40161 9.58977 8.51454 9.67206C8.62746 9.75435 8.71137 9.87034 8.75417 10.0034C8.79697 10.1364 8.79647 10.2795 8.75273 10.4122C8.70899 10.5449 8.62427 10.6603 8.51077 10.7418V10.7418ZM10.6008 7.48307H5.65077V4.40307C5.65077 3.97819 5.99589 3.63307 6.42077 3.63307H9.83077C10.2556 3.63307 10.6008 3.97819 10.6008 4.40307V7.48307Z"
      fill="white"
    />
  </Svg>
);

export default IconLocked;
