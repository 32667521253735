import { Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const FileOutlined = ({ style }: { style?: Style | Style[] }) => (
  <Svg width="16" height="19" viewBox="0 0 16 19" style={style}>
    <Path
      d="M14.8825 4.9479L10.5593 0.551415C10.4387 0.428836 10.276 0.359375 10.1053 0.359375H1.57132C1.21574 0.359375 0.928467 0.651521 0.928467 1.01313V18.0107C0.928467 18.3723 1.21574 18.6645 1.57132 18.6645H14.4285C14.784 18.6645 15.0713 18.3723 15.0713 18.0107V5.41166C15.0713 5.23801 15.003 5.07048 14.8825 4.9479ZM13.5887 5.71198H9.80793V1.86709L13.5887 5.71198ZM13.6249 17.1935H2.3749V1.83032H8.44186V6.24315C8.44186 6.47072 8.53076 6.68897 8.68899 6.84989C8.84722 7.0108 9.06183 7.1012 9.28561 7.1012H13.6249V17.1935ZM7.83918 11.6775H4.14275C4.05436 11.6775 3.98204 11.751 3.98204 11.8409V12.8215C3.98204 12.9114 4.05436 12.985 4.14275 12.985H7.83918C7.92757 12.985 7.9999 12.9114 7.9999 12.8215V11.8409C7.9999 11.751 7.92757 11.6775 7.83918 11.6775ZM3.98204 9.06246V10.0431C3.98204 10.133 4.05436 10.2065 4.14275 10.2065H11.857C11.9454 10.2065 12.0178 10.133 12.0178 10.0431V9.06246C12.0178 8.97257 11.9454 8.89902 11.857 8.89902H4.14275C4.05436 8.89902 3.98204 8.97257 3.98204 9.06246Z"
      fill="#C4C4C4"
    />
  </Svg>
);

export default FileOutlined;
