import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';

import * as Types from 'types';
import styles from './styles';
import _ from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import { DownIconPdf, FileDonePdf, FileOutlinedPdf } from 'assets/svg/svgPDF';
import dayjs from 'dayjs';
import SelectBox from 'components/ReactPDF/SelectBox';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface PartnerPDFtype {
  provider_id: string;
  provider_name: string;
  contract_start_date: string;
  contract_end_date: string | null;
  deletedat: Date | null;
  curriculum_nums: string;
  admin_department: string;
  admin_position: string;
  admin_name: string;
  contract_file_id?: string;
  last_login_date: string;
  updatedby?: string;
  updatedat?: Date;
}

interface Props {
  dataSource: PartnerPDFtype[];
  search_status?: string;
  provider_id?: string;
  provider_name?: string;
}

const FIRST_PAGE_ROW_COUNT = 12;
const PAGE_ROW_COUNT = 16;

const PartnerManagementTable = ({ dataSource }: { dataSource: PartnerPDFtype[] }) => {
  const columns: { title: string | string[]; width: string | number; key: string }[] = [
    {
      title: '契約状況',
      width: 70,
      key: 'name',
    },
    {
      title: ['ID', 'パートナー名'],
      width: 200,
      key: 'question_code',
    },
    {
      title: '契約開始日',
      width: 80,
      key: 'contract_start_date',
    },
    {
      title: ['契約終了日', '（予定）'],
      width: 80,
      key: 'contract_end_date',
    },
    {
      title: ['カリキュラム', '制作数'],
      width: 80,
      key: 'produced',
    },
    {
      title: ['所属 / 役職', '管理者氏名'],
      width: 150,
      key: 'admin_name',
    },
    {
      title: '最終ログイン',
      width: 90,
      key: 'last_login_date',
    },
    {
      title: '詳細',
      width: 40,
      key: 'question_description',
    },
    {
      title: '契約書',
      width: 40,
      key: 'operation',
    },
  ];

  const isStringArray = (val: any) => {
    if (Array.isArray(val)) {
      return true;
    } else {
      return false;
    }
  };
  const formatDateData = (val: string | null) => {
    if (val && val !== '-') {
      return dayjs(val).format('YYYY/MM/DD HH:mm');
    }
    return '-';
  };
  const getFieldName = (contract_end_date: string | null, deletedat: Date | null) => {
    if (!contract_end_date && !deletedat) {
      return '契約中';
    } else if (contract_end_date && dayjs().isBefore(dayjs(contract_end_date))) {
      return '契約終了予定';
    } else {
      return '契約終了';
    }
  };

  const backgroundColorStyle = (contract_end_date: string | null, deletedat: Date | null) => {
    if (!contract_end_date && !deletedat) {
      return '#08a3a5';
    } else if (contract_end_date && dayjs().isBefore(dayjs(contract_end_date))) {
      return '#f6ac00';
    } else {
      return '#c4c4c4';
    }
  };
  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow, { height: '26.728px' }]}>
        {columns.map((column, index) =>
          isStringArray(column.title) ? (
            <View
              key={index}
              style={[
                styles.wrapCell,
                { width: column.width, display: 'flex', flexDirection: 'column' },
              ]}
            >
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title[0]}</Text>
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title[1]}</Text>
            </View>
          ) : (
            <View key={index} style={[styles.wrapCell, { width: column.width }]}>
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
            </View>
          )
        )}
      </View>

      {dataSource.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5',
            },
          ]}
        >
          <View
            style={[
              styles.wrapCell,
              { width: columns[0].width, display: 'flex', flexDirection: 'row' },
            ]}
          >
            <View
              style={[
                styles.verticalBar,
                { backgroundColor: backgroundColorStyle(row.contract_end_date, row.deletedat) },
              ]}
            ></View>
            <Text style={[styles.tableCell, { marginLeft: '6px' }]}>
              {getFieldName(row.contract_end_date, row.deletedat)}
            </Text>
          </View>
          <View
            style={[
              styles.wrapCell,
              { width: columns[1].width, display: 'flex', flexDirection: 'column' },
            ]}
          >
            <Text style={styles.tableCell}>{row.provider_id}</Text>
            <Text style={styles.tableCell}>{row.provider_name}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[2].width }]}>
            <Text style={[styles.tableCell]}>{formatDateData(row.contract_start_date)}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[3].width }]}>
            <Text style={styles.tableCell}>{formatDateData(row.contract_end_date)}</Text>
          </View>
          <View
            style={[
              styles.wrapCell,
              { width: columns[4].width, color: '#159193', textDecoration: 'underline' },
            ]}
          >
            <Text style={styles.tableCell}>{row.curriculum_nums}</Text>
          </View>
          <View
            style={[
              styles.wrapCell,
              { width: columns[5].width, display: 'flex', flexDirection: 'column' },
            ]}
          >
            <Text style={styles.tableCell}>
              {row.admin_department} / {row.admin_position}
            </Text>
            <Text style={styles.tableCell}>{row.admin_name}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[6].width }]}>
            <Text style={styles.tableCell}>{row.last_login_date}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[7].width, textAlign: 'right' }]}>
            <FileOutlinedPdf style={{ transform: 'scale(0.7)' }} />
          </View>
          <View style={[styles.wrapCell, { width: columns[8].width }]}>
            <FileDonePdf style={{ transform: 'scale(0.7)' }} />
          </View>
        </View>
      ))}
    </View>
  );
};

const PDFPartnerManagement = (props: Props) => {
  const { dataSource, search_status, provider_id, provider_name } = props;
  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap>
        <FullPage>
          <View style={styles.header}>
            <Text style={styles.textHeader}>パートナー管理</Text>
          </View>
          <View style={styles.subscription}>
            <View>
              <Text style={styles.textSubscription}>
                パートナーの新規登録・登録情報の確認・管理を行う画面です。
              </Text>
            </View>
          </View>
          <View style={styles.divider}></View>
          <View style={styles.searchPanel}>
            <SelectBox label="契約状況" value={search_status} placeholder="指定なし" />
            <SelectBox label="ID" value={provider_id} placeholder="指定なし" />
            <SelectBox label="パートナー名" value={provider_name} placeholder="指定なし" />
          </View>
          <View style={styles.body}>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>1</Text>
              <Text style={styles.textCountNumber}>-</Text>
              <Text style={styles.textCountNumber}>{dataSource.length}</Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{dataSource.length}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
            <PartnerManagementTable dataSource={dataSource.slice(0, FIRST_PAGE_ROW_COUNT)} />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {dataSource.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(dataSource.slice(FIRST_PAGE_ROW_COUNT, dataSource.length), PAGE_ROW_COUNT).map(
          (page, index) => (
            <Page
              key={index}
              size={pageSize}
              orientation={pageOrientation}
              style={[styles.page, styles.pagePadding]}
              wrap={false}
            >
              <FullPage>
                <PartnerManagementTable dataSource={page} />
              </FullPage>
              <PageNumber />
            </Page>
          )
        )}
    </Document>
  );
};

export default PDFPartnerManagement;
