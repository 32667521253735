import { ConfigType, DataStoreType } from 'types/config';

export const config: ConfigType = {
  API_URL: process.env.REACT_APP_API_URL ?? '',
  TEMPLATES_ID: process.env.REACT_APP_TEMPLATE_ID ?? '',
  CONFIRM_TEMPLATES_ID: process.env.REACT_APP_CONFIRM_TEMPLATES_ID ?? '',
  APP_ID: process.env.REACT_APP_ID ?? '',
  APP_ID_PARTNER: process.env.REACT_APP_ID_PARTNER ?? '',
  WORKSPACE: 'SKILL FAMILIAR product',
  USER_G_ID: '61ecaeaf94d4b2fcdf59bfe9',
  TRIAL_PLAN_ID: process.env.REACT_APP_TRIAL_PLAN_ID ?? '',
  PREMIUM_PLAN_ID: process.env.REACT_APP_PREMIUM_PLAN_ID ?? '',
};

export const USERS: DataStoreType = {
  //id: '655986a5baeaf8d6328c9762',
  id: process.env.REACT_APP_USERS_ID ?? '',
  name: 'users',
};

export const CURRICULUMS: DataStoreType = {
  //id: '655986e3baeaf8d6328c9898',
  id: process.env.REACT_APP_CURRICULUMS_ID ?? '',
  name: 'curricullums',
};

export const LEVEL_1: DataStoreType = {
  //id: '655986ddbaeaf8d6328c9884',
  id: process.env.REACT_APP_LEVEL_1_ID ?? '',
  name: 'level1s',
};

export const LEVEL_2: DataStoreType = {
  //id: '655986dabaeaf8d6328c9870',
  id: process.env.REACT_APP_LEVEL_2_ID ?? '',
  name: 'level2s',
};

export const LEVEL_3: DataStoreType = {
  //id: '655986d6baeaf8d6328c985c',
  id: process.env.REACT_APP_LEVEL_3_ID ?? '',
  name: 'level3s',
};

export const LEVEL_4: DataStoreType = {
  //id: '655986d2baeaf8d6328c9848',
  id: process.env.REACT_APP_LEVEL_4_ID ?? '',
  name: 'level4s',
};

export const QUESTIONS: DataStoreType = {
  //id: '65598664baeaf8d6328c95ea',
  id: process.env.REACT_APP_QUESTIONS_ID ?? '',
  name: 'questions',
};

export const QUESTIONS_PARTNER: DataStoreType = {
  id: '6571ec8b8c988396fd721394',
  name: 'questions',
};

export const ATTACH: DataStoreType = {
  //id: '65598674baeaf8d6328c966e',
  id: process.env.REACT_APP_ATTACH_ID ?? '',
  name: 'attach',
};

export const QUESTION_ASSIGN_LEVEL: DataStoreType = {
  //id: '655986ccbaeaf8d6328c9835',
  id: process.env.REACT_APP_QUESTION_ASSIGN_LEVEL_ID ?? '',
  name: 'question_assign_level',
};

export const DEPARTMENTS: DataStoreType = {
  //id: '655986c5baeaf8d6328c9809',
  id: process.env.REACT_APP_DEPARTMENTS_ID ?? '',
  name: 'departments',
};

export const POSITIONS: DataStoreType = {
  //id: '655986c1baeaf8d6328c97f8',
  id: process.env.REACT_APP_POSITIONS_ID ?? '',
  name: 'positions',
};

export const USER_ASSIGN_REQUIRED_CURRICULUM: DataStoreType = {
  //id: '6559869bbaeaf8d6328c972d',
  id: process.env.REACT_APP_USER_ASSIGN_REQUIRED_CURRICULUM_ID ?? '',
  name: 'user_assign_required_curriculum',
};

export const ROLES: DataStoreType = {
  //id: '655986babaeaf8d6328c97d2',
  id: process.env.REACT_APP_ROLES_ID ?? '',
  name: 'roles',
};

export const PLANS: DataStoreType = {
  //id: '655986c7baeaf8d6328c981a',
  id: process.env.REACT_APP_PLANS_ID ?? '',
  name: 'plans',
};

export const AFFILIATION_LEVEL: DataStoreType = {
  //id: '6559865abaeaf8d6328c95c2',
  id: process.env.REACT_APP_AFFILIATION_LEVEL_ID ?? '',
  name: 'affiliation_level',
};

export const AFFILIATION_ASSIGN_ROLE: DataStoreType = {
  //id: '65598652baeaf8d6328c959d',
  id: process.env.REACT_APP_AFFILIATION_ASSIGN_ROLE_ID ?? '',
  name: 'affiliation_assign_role',
};

export const AFFILIATION_ASSIGN_LEVEL: DataStoreType = {
  //id: '6559865fbaeaf8d6328c95d7',
  id: process.env.REACT_APP_AFFILIATION_ASSIGN_LEVEL_ID ?? '',
  name: 'affiliation_assign_level',
};

export const CURRICULUM_HIERARCHY: DataStoreType = {
  //id: '655986f9baeaf8d6328cad59',
  id: process.env.REACT_APP_CURRICULUM_HIERARCHY_ID ?? '',
  name: 'curriculum_hierarchy',
};

export const UNRELATED_QUESTIONS: DataStoreType = {
  //id: '655986f9baeaf8d6328cad5a',
  id: process.env.REACT_APP_UNRELATED_QUESTIONS_ID ?? '',
  name: 'unrelated_questions',
};

export const CURRICULUM_EXPORT: DataStoreType = {
  //id: '655986f9baeaf8d6328cad5b',
  id: process.env.REACT_APP_CURRICULUM_EXPORT_ID ?? '',
  name: 'curriculum_export',
};

export const REQUIRED_CURRICULUM_USERS: DataStoreType = {
  //id: '655986f9baeaf8d6328cad5c',
  id: process.env.REACT_APP_REQUIRED_CURRICULUM_USERS_ID ?? '',
  name: 'required_curriculum_users',
};

export const QUESTION_SEARCH: DataStoreType = {
  //id: '655986f9baeaf8d6328cad5d',
  id: process.env.REACT_APP_QUESTION_SEARCH_ID ?? '',
  name: 'question_search',
};

export const AFFILIATION_HIERARCHY: DataStoreType = {
  //id: '655986f9baeaf8d6328cad5e',
  id: process.env.REACT_APP_AFFILIATION_HIERARCHY_ID ?? '',
  name: 'affiliation_hierarchy',
};

export const EMPLOYEE_USER: DataStoreType = {
  //id: '655986f9baeaf8d6328cad61',
  id: process.env.REACT_APP_EMPLOYEE_USER_ID ?? '',
  name: 'employee_user',
};

export const SELECT_AFFILIATION_USER_PERMISSIONS: DataStoreType = {
  //id: '655986fabaeaf8d6328cada3',
  id: process.env.REACT_APP_SELECT_AFFILIATION_USER_PERMISSIONS_ID ?? '',
  name: 'select_affiliation_user_permissions',
};

export const SELECT_USERS: DataStoreType = {
  //id: '655986f8baeaf8d6328cad32',
  id: process.env.REACT_APP_SELECT_USERS_ID ?? '',
  name: 'select_users',
};

export const SELECT_QUESTION_CREATOR: DataStoreType = {
  //id: '655986f8baeaf8d6328cad34',
  id: process.env.REACT_APP_SELECT_QUESTION_CREATOR_ID ?? '',
  name: 'select_question_creator',
};

export const SELECT_CURRICULUMS: DataStoreType = {
  //id: '655986f8baeaf8d6328cad35',
  id: process.env.REACT_APP_SELECT_CURRICULUMS_ID ?? '',
  name: 'select_curriculums',
};

export const SELECT_LEVEL1S: DataStoreType = {
  //id: '655986f8baeaf8d6328cad37',
  id: process.env.REACT_APP_SELECT_LEVEL1S_ID ?? '',
  name: 'select_level1s',
};

export const SELECT_LEVEL2S: DataStoreType = {
  //id: '655986f8baeaf8d6328cad38',
  id: process.env.REACT_APP_SELECT_LEVEL2S_ID ?? '',
  name: 'select_level2s',
};

export const SELECT_LEVEL3S: DataStoreType = {
  //id: '655986f8baeaf8d6328cad39',
  id: process.env.REACT_APP_SELECT_LEVEL3S_ID ?? '',
  name: 'select_level3s',
};

export const SELECT_LEVEL4S: DataStoreType = {
  //id: '655986f8baeaf8d6328cad3a',
  id: process.env.REACT_APP_SELECT_LEVEL4S_ID ?? '',
  name: 'select_level4s',
};

export const SELECT_DEPARTMENT: DataStoreType = {
  //id: '655986f8baeaf8d6328cad3b',
  id: process.env.REACT_APP_SELECT_DEPARTMENT_ID ?? '',
  name: 'select_department',
};

export const SELECT_POSITION: DataStoreType = {
  //id: '655986f8baeaf8d6328cad3c',
  id: process.env.REACT_APP_SELECT_POSITION_ID ?? '',
  name: 'select_position',
};

export const SELECT_ROLE: DataStoreType = {
  //id: '655986f8baeaf8d6328cad3d',
  id: process.env.REACT_APP_SELECT_ROLE_ID ?? '',
  name: 'select_role',
};

export const GMO: DataStoreType = {
  //id: '6559867bbaeaf8d6328c9683',
  id: process.env.REACT_APP_GMO_ID ?? '',
  name: 'gmo',
};

export const COMPANIES: DataStoreType = {
  //id: '655986aebaeaf8d6328c9791',
  id: process.env.REACT_APP_COMPANIES_ID ?? '',
  name: 'companies',
};

export const EMPLOYEE_USER_EXPORT: DataStoreType = {
  //id: '655986f9baeaf8d6328cad5f',
  id: process.env.REACT_APP_EMPLOYEE_USER_EXPORT_ID ?? '',
  name: 'employee_user_export',
};

export const REQUIRED_CURRICULUM_SKILL_CHECK: DataStoreType = {
  //id: '655986f9baeaf8d6328cad65',
  id: process.env.REACT_APP_REQUIRED_CURRICULUM_SKILL_CHECK_ID ?? '',
  name: 'required_curriculum_skill_check',
};

export const MANUAL_FOLDER_LIST: DataStoreType = {
  //id: '655986f9baeaf8d6328cad79',
  id: process.env.REACT_APP_MANUAL_FOLDER_LIST_ID ?? '',
  name: 'manual_folder_list',
};

export const MANUAL_LIST: DataStoreType = {
  //id: '655986f9baeaf8d6328cad7a',
  id: process.env.REACT_APP_MANUAL_LIST_ID ?? '',
  name: 'manual_list',
};

export const MANUAL_FILE_LIST: DataStoreType = {
  //id: '655986f9baeaf8d6328cad7b',
  id: process.env.REACT_APP_MANUAL_FILE_LIST_ID ?? '',
  name: 'manual_file_list',
};

export const MANUAL_FOLDER: DataStoreType = {
  //id: '6559863cbaeaf8d6328c9539',
  id: process.env.REACT_APP_MANUAL_FOLDER_ID ?? '',
  name: 'manual_folder',
};

export const MANUAL_FILE: DataStoreType = {
  //id: '65598630baeaf8d6328c94f8',
  id: process.env.REACT_APP_MANUAL_FILE_ID ?? '',
  name: 'manual_file',
};

export const MANUAL: DataStoreType = {
  //id: '65598639baeaf8d6328c9523',
  id: process.env.REACT_APP_MANUAL_ID ?? '',
  name: 'manual',
};

export const MANUAL_SECTION: DataStoreType = {
  //id: '65598635baeaf8d6328c950f',
  id: process.env.REACT_APP_MANUAL_SECTION_ID ?? '',
  name: 'manual_section',
};

export const MANUAL_DETAIL: DataStoreType = {
  //id: '655986f9baeaf8d6328cad7c',
  id: process.env.REACT_APP_MANUAL_DETAIL_ID ?? '',
  name: 'manual_detail',
};

export const SELECT_EMPLOYEE_USER: DataStoreType = {
  //id: '655986f9baeaf8d6328cad47',
  id: process.env.REACT_APP_SELECT_EMPLOYEE_USER_ID ?? '',
  name: 'select_employee_user',
};

export const SELECT_REQUIRED_CURRICULUM: DataStoreType = {
  //id: '655986f8baeaf8d6328cad3e',
  id: process.env.REACT_APP_SELECT_REQUIRED_CURRICULUM_ID ?? '',
  name: 'select_required_curriculum',
};

export const SELECT_REQUIRED_CURRICULUM_CREATOR: DataStoreType = {
  //id: '655986f8baeaf8d6328cad3f',
  id: process.env.REACT_APP_SELECT_REQUIRED_CURRICULUM_CREATOR_ID ?? '',
  name: 'select_required_curriculum_creator',
};

export const SELECT_SKILL_CHECK: DataStoreType = {
  //id: '655986f9baeaf8d6328cad40',
  id: process.env.REACT_APP_SELECT_SKILL_CHECK_ID ?? '',
  name: 'select_skill_check',
};

export const SELECT_SKILL_CHECK_START_PERIOD: DataStoreType = {
  //id: '655986f9baeaf8d6328cad41',
  id: process.env.REACT_APP_SELECT_SKILL_CHECK_START_PERIOD_ID ?? '',
  name: 'select_skill_check_start_period',
};

export const SELECT_SKILL_CHECK_CATEGORY: DataStoreType = {
  //id: '655986f9baeaf8d6328cad42',
  id: process.env.REACT_APP_SELECT_SKILL_CHECK_CATEGORY_ID ?? '',
  name: 'select_skill_check_category',
};

export const SELECT_SKILL_CHECK_GROUPING_CODE: DataStoreType = {
  //id: '655986f9baeaf8d6328cad43',
  id: process.env.REACT_APP_SELECT_SKILL_CHECK_GROUPING_CODE_ID ?? '',
  name: 'select_skill_check_grouping_code',
};

export const SELECT_SKILL_CHECK_USE_STATUS: DataStoreType = {
  //id: '655986f9baeaf8d6328cad44',
  id: process.env.REACT_APP_SELECT_SKILL_CHECK_USE_STATUS_ID ?? '',
  name: 'select_skill_check_use_status',
};

export const SELECT_SKILL_CHECK_CREATOR: DataStoreType = {
  //id: '655986f9baeaf8d6328cad45',
  id: process.env.REACT_APP_SELECT_SKILL_CHECK_CREATOR_ID ?? '',
  name: 'select_skill_check_creator',
};

export const SKILL_CHECK: DataStoreType = {
  //id: '65598690baeaf8d6328c96f7',
  id: process.env.REACT_APP_SKILL_CHECK_ID ?? '',
  name: 'skill_check',
};

export const USER_ASSIGN_SKILL_CHECK: DataStoreType = {
  //id: '65598696baeaf8d6328c9719',
  id: process.env.REACT_APP_USER_ASSIGN_SKILL_CHECK_ID ?? '',
  name: 'user_assign_skill_check',
};

export const EMPLOYEE_USER_DETAIL: DataStoreType = {
  //id: '655986f9baeaf8d6328cad63',
  id: process.env.REACT_APP_EMPLOYEE_USER_DETAIL_ID ?? '',
  name: 'employee_user_detail',
};

export const GROUPS: DataStoreType = {
  //id: '6559868abaeaf8d6328c96d6',
  id: process.env.REACT_APP_GROUPS_ID ?? '',
  name: 'groups',
};

export const INTERVIEW_USER: DataStoreType = {
  //id: '655986f9baeaf8d6328cad62',
  id: process.env.REACT_APP_INTERVIEW_USER_ID ?? '',
  name: 'interview_user',
};

export const INTERVIEW_USER_EXPORT: DataStoreType = {
  //id: '655986f9baeaf8d6328cad60',
  id: process.env.REACT_APP_INTERVIEW_USER_EXPORT_ID ?? '',
  name: 'interview_user_export',
};

export const SKILL_CHECK_HIERARCHY: DataStoreType = {
  //id: '655986f9baeaf8d6328cad67',
  id: process.env.REACT_APP_SKILL_CHECK_HIERARCHY_ID ?? '',
  name: 'skill_check_hierarchy',
};

export const SELECT_TYPES: DataStoreType = {
  //id: '65598646baeaf8d6328c9578',
  id: process.env.REACT_APP_SELECT_TYPES_ID ?? '',
  name: 'select_types',
};

export const SKILL_CHECK_ASSIGN_QUESTION: DataStoreType = {
  //id: '6559864abaeaf8d6328c9589',
  id: process.env.REACT_APP_SKILL_CHECK_ASSIGN_QUESTION_ID ?? '',
  name: 'skill_check_assign_question',
};

export const INTERVIEW_USER_DETAIL: DataStoreType = {
  //id: '655986f9baeaf8d6328cad64',
  id: process.env.REACT_APP_INTERVIEW_USER_DETAIL_ID ?? '',
  name: 'interview_user_detail',
};

export const SKILL_CHECK_USERS: DataStoreType = {
  //id: '655986f9baeaf8d6328cad68',
  id: process.env.REACT_APP_SKILL_CHECK_USERS_ID ?? '',
  name: 'skill_check_users',
};

export const INTERVIEW_SKILL_CHECK: DataStoreType = {
  //id: '655986f9baeaf8d6328cad66',
  id: process.env.REACT_APP_INTERVIEW_SKILL_CHECK_ID ?? '',
  name: 'interview_skill_check',
};

export const SELECT_INTERVIEW_USER: DataStoreType = {
  //id: '655986f9baeaf8d6328cad48',
  id: process.env.REACT_APP_SELECT_INTERVIEW_USER_ID ?? '',
  name: 'select_interview_user',
};

export const SELECT_HIRE_DATE: DataStoreType = {
  //id: '655986f9baeaf8d6328cad49',
  id: process.env.REACT_APP_SELECT_HIRE_DATE_ID ?? '',
  name: 'select_hire_date',
};

export const SELECT_CURRICULUM_CREATOR: DataStoreType = {
  //id: '655986f8baeaf8d6328cad36',
  id: process.env.REACT_APP_SELECT_CURRICULUM_CREATOR_ID ?? '',
  name: 'select_curriculum_creator',
};

export const SELECT_QUESTION: DataStoreType = {
  //id: '655986f8baeaf8d6328cad33',
  id: process.env.REACT_APP_SELECT_QUESTION_ID ?? '',
  name: 'select_question',
};

export const SKILL_CHECK_IMPLE: DataStoreType = {
  //id: '655986f9baeaf8d6328cad6a',
  id: process.env.REACT_APP_SKILL_CHECK_IMPLE_ID ?? '',
  name: 'skill_check_imple',
};

export const SKILL_CHECK_TRANS2: DataStoreType = {
  //id: '65598615baeaf8d6328c94bf',
  id: process.env.REACT_APP_SKILL_CHECK_TRANS2_ID ?? '',
  name: 'skill_check_trans2',
};

export const SKILL_CHECK_QUESTION: DataStoreType = {
  //id: '655986f9baeaf8d6328cad69',
  id: process.env.REACT_APP_SKILL_CHECK_QUESTION_ID ?? '',
  name: 'skill_check_question',
};

export const CURRICULUM_HIERARCHY_TRAN: DataStoreType = {
  //id: '655986f9baeaf8d6328cad6e',
  id: process.env.REACT_APP_CURRICULUM_HIERARCHY_TRAN_ID ?? '',
  name: 'curriculum_hierarchy_tran',
};

export const CURRICULUM_TRANS: DataStoreType = {
  //id: '65598683baeaf8d6328c96ba',
  id: process.env.REACT_APP_CURRICULUM_TRANS_ID ?? '',
  name: 'curriculum_trans',
};

export const QUESTION_TRANS2: DataStoreType = {
  //id: '6559861bbaeaf8d6328c94dc',
  id: process.env.REACT_APP_QUESTION_TRANS2_ID ?? '',
  name: 'question_trans2',
};

export const QUESTION_TRANS: DataStoreType = {
  //id: '65598640baeaf8d6328c954c',
  id: process.env.REACT_APP_QUESTION_TRANS_ID ?? '',
  name: 'question_trans',
};

export const PROFILE: DataStoreType = {
  //id: '655986f9baeaf8d6328cad7f',
  id: process.env.REACT_APP_PROFILE_ID ?? '',
  name: 'profile',
};

export const SKILL_CHECK_IMPLE_STATUS: DataStoreType = {
  //id: '655986f9baeaf8d6328cad6b',
  id: process.env.REACT_APP_SKILL_CHECK_IMPLE_STATUS_ID ?? '',
  name: 'skill_check_imple_status',
};

export const SELECT_ANALYSIS_GROUP: DataStoreType = {
  //id: '655986f9baeaf8d6328cad4a',
  id: process.env.REACT_APP_SELECT_ANALYSIS_GROUP_ID ?? '',
  name: 'select_analysis_group',
};

export const SELECT_SKILL_CHECK_QUESTION: DataStoreType = {
  //id: '655986f9baeaf8d6328cad46',
  id: process.env.REACT_APP_SELECT_SKILL_CHECK_QUESTION_ID ?? '',
  name: 'select_skill_check_question',
};

export const SELECT_CURRICULUM_QUESTION: DataStoreType = {
  //id: '655986f9baeaf8d6328cad4b',
  id: process.env.REACT_APP_SELECT_CURRICULUM_QUESTION_ID ?? '',
  name: 'select_curriculum_question',
};

export const REPORT_CURRICULUM_USER: DataStoreType = {
  //id: '655986f9baeaf8d6328cad70',
  id: process.env.REACT_APP_REPORT_CURRICULUM_USER_ID ?? '',
  name: 'report_curriculum_user',
};

export const REPORT_SKILL_CHECK_USER_TRANS: DataStoreType = {
  //id: '655986f9baeaf8d6328cad75',
  id: process.env.REACT_APP_REPORT_SKILL_CHECK_USER_TRANS_ID ?? '',
  name: 'report_skill_check_user_trans',
};

export const DETAIL_OF_SKILL_CHECK_RESULTS: DataStoreType = {
  //id: '655986f9baeaf8d6328cad85',
  id: process.env.REACT_APP_DETAIL_OF_SKILL_CHECK_RESULTS_ID ?? '',
  name: 'detail_of_skill_check_results',
};

export const DETAIL_OF_CURRICULUM_RESULTS: DataStoreType = {
  //id: '655986f9baeaf8d6328cad86',
  id: process.env.REACT_APP_DETAIL_OF_CURRICULUM_RESULTS_ID ?? '',
  name: 'detail_of_curriculum_results',
};

export const MANUAL_FOLDER_PERMISSIONS: DataStoreType = {
  //id: '6559860dbaeaf8d6328c948a',
  id: process.env.REACT_APP_MANUAL_FOLDER_PERMISSIONS_ID ?? '',
  name: 'manual_folder_permissions',
};

export const USAGE_LIST: DataStoreType = {
  //id: '655986f9baeaf8d6328cad83',
  id: process.env.REACT_APP_USAGE_LIST_ID ?? '',
  name: 'usage_list',
};

export const USER_FEE_DETAIL: DataStoreType = {
  //id: '655986f9baeaf8d6328cad88',
  id: process.env.REACT_APP_USER_FEE_DETAIL_ID ?? '',
  name: 'user_fee_details',
};

export const SKILL_CHECK_FEE_DETAIL: DataStoreType = {
  //id: '655986f9baeaf8d6328cad87',
  id: process.env.REACT_APP_SKILL_CHECK_FEE_DETAIL_ID ?? '',
  name: 'skill_check_fee_details',
};

export const PAYMENT_STATUS: DataStoreType = {
  //id: '655986f9baeaf8d6328cad8b',
  id: process.env.REACT_APP_PAYMENT_STATUS_ID ?? '',
  name: 'payment_status',
};

export const OFFICIAL_CURRICULUM_LIST2: DataStoreType = {
  //id: '655986f9baeaf8d6328cad81',
  id: process.env.REACT_APP_OFFICIAL_CURRICULUM_LIST2_ID ?? '',
  name: 'official_curriculum_list2',
};

export const OFFICIAL_CURRICULUM_LIST: DataStoreType = {
  id: '6571ed0e8c988396fd721c7d',
  name: 'official_curriculum_list',
};

export const SELECT_OFFICIAL_CURRICULUM_NAME: DataStoreType = {
  //id: '655986f9baeaf8d6328cad4c',
  id: process.env.REACT_APP_SELECT_OFFICIAL_CURRICULUM_NAME_ID ?? '',
  name: 'select_official_curriculum_name',
};

export const OFFICIAL_CURRICULUM_HIERARCHY_LIST: DataStoreType = {
  //id: '655986f9baeaf8d6328cad82',
  id: process.env.REACT_APP_OFFICIAL_CURRICULUM_HIERARCHY_LIST_ID ?? '',
  name: 'official_curriculum_hierarchy_list',
};

export const OFFICIAL_CURRICULUM_MASTER: DataStoreType = {
  //id: '655985fabaeaf8d6328c9418',
  id: process.env.REACT_APP_OFFICIAL_CURRICULUM_MASTER_ID ?? '',
  name: 'official_curriculum_master',
};

export const SELECT_OFFICIAL_CURRICULUM_PROVIDER: DataStoreType = {
  //id: '655986f9baeaf8d6328cad4e',
  id: process.env.REACT_APP_SELECT_OFFICIAL_CURRICULUM_PROVIDER_ID ?? '',
  name: 'select_official_curriculum_provider',
};

export const RELEASE_NOTE_MANAGEMENT: DataStoreType = {
  //id: '655986f9baeaf8d6328cad8d',
  id: process.env.REACT_APP_RELEASE_NOTE_MANAGEMENT_ID ?? '',
  name: 'release_note_management',
};

export const RELEASE_NOTE_DETAIL: DataStoreType = {
  //id: '655986f9baeaf8d6328cad8e',
  id: process.env.REACT_APP_RELEASE_NOTE_DETAIL_ID ?? '',
  name: 'release_note_detail',
};

export const REPORT_SKILL_CHECK: DataStoreType = {
  //id: '655986f9baeaf8d6328cad74',
  id: process.env.REACT_APP_REPORT_SKILL_CHECK_ID ?? '',
  name: 'report_skill_check',
};

export const KNOWLEDGE_LIST: DataStoreType = {
  //id: '655986f9baeaf8d6328cad92',
  id: process.env.REACT_APP_KNOWLEDGE_LIST_ID ?? '',
  name: 'knowledge_list',
};

export const RANKING_LIKES_KNOWLEDGE_ALL: DataStoreType = {
  //id: '655986f9baeaf8d6328cad94',
  id: process.env.REACT_APP_RANKING_LIKES_KNOWLEDGE_ALL_ID ?? '',
  name: 'ranking_likes_knowledge_all',
};

export const RANKING_LIKES_KNOWLEDGE_MONTH: DataStoreType = {
  //id: '655986f9baeaf8d6328cad95',
  id: process.env.REACT_APP_RANKING_LIKES_KNOWLEDGE_MONTH_ID ?? '',
  name: 'ranking_likes_knowledge_month',
};

export const RANKING_LIKES_USER_ALL: DataStoreType = {
  //id: '655986f9baeaf8d6328cad93',
  id: process.env.REACT_APP_RANKING_LIKES_USER_ALL_ID ?? '',
  name: 'ranking_likes_user_all',
};

export const RANKING_LIKES_USER_MONTH: DataStoreType = {
  //id: '655986f9baeaf8d6328cad96',
  id: process.env.REACT_APP_RANKING_LIKES_USER_MONTH_ID ?? '',
  name: 'ranking_likes_user_month',
};

export const FAVORITE_KNOWLEDGE: DataStoreType = {
  //id: '655985cdbaeaf8d6328c9319',
  id: process.env.REACT_APP_FAVORITE_KNOWLEDGE_ID ?? '',
  name: 'favorite_knowledge',
};

export const KNOWLEDGE_LIKE_HISTORY: DataStoreType = {
  //id: '655985cbbaeaf8d6328c930c',
  id: process.env.REACT_APP_KNOWLEDGE_LIKE_HISTORY_ID ?? '',
  name: 'knowledge_like_history',
};

export const KNOWLEDGE: DataStoreType = {
  //id: '655985debaeaf8d6328c937c',
  id: process.env.REACT_APP_KNOWLEDGE_ID ?? '',
  name: 'knowledge',
};

export const KNOWLEDGE_TO: DataStoreType = {
  //id: '655985d0baeaf8d6328c9327',
  id: process.env.REACT_APP_KNOWLEDGE_TO_ID ?? '',
  name: 'knowledge_to',
};

export const KNOWLEDGE_QA_DETAIL: DataStoreType = {
  //id: '655986f9baeaf8d6328cad97',
  id: process.env.REACT_APP_KNOWLEDGE_QA_DETAIL_ID ?? '',
  name: 'knowledge_qa_detail',
};

export const KNOWLEDGE_QA_ADDITIONAL_QUESTION = {
  //id: '655985d7baeaf8d6328c9354',
  id: process.env.REACT_APP_KNOWLEDGE_QA_ADDITIONAL_QUESTION_ID ?? '',
  name: 'knowledge_qa_additional_question',
};

export const KNOWLEDGE_QA_ADDITIONAL_QUESTION_ANSWER = {
  //id: '655985d4baeaf8d6328c933e',
  id: process.env.REACT_APP_KNOWLEDGE_QA_ADDITIONAL_QUESTION_ANSWER_ID ?? '',
  name: 'knowledge_qa_additional_question_answer',
};

export const KNOWLEDGE_LIKES_HISTORY = {
  //id: '655986fabaeaf8d6328cad9c',
  id: process.env.REACT_APP_KNOWLEDGE_LIKES_HISTORY_ID ?? '',
  name: 'knowledge_likes_history',
};

export const KNOWLEDGE_RECOMMEND_DETAIL = {
  //id: '655986fabaeaf8d6328cad98',
  id: process.env.REACT_APP_KNOWLEDGE_RECOMMEND_DETAIL_ID ?? '',
  name: 'knowledge_recommend_detail',
};

export const KNOWLEDGE_RE_QUESTION_ANSWER = {
  //id: '655985c2baeaf8d6328c92d7',
  id: process.env.REACT_APP_KNOWLEDGE_RE_QUESTION_ANSWER_ID ?? '',
  name: 'knowledge_re_question_answer',
};

export const KNOWLEDGE_RE_QUESTION = {
  //id: '655985c7baeaf8d6328c92ed',
  id: process.env.REACT_APP_KNOWLEDGE_RE_QUESTION_ID ?? '',
  name: 'knowledge_re_question',
};

export const SELECT_DEPARTMENT_USER: DataStoreType = {
  //id: '655986fabaeaf8d6328cada0',
  id: process.env.REACT_APP_SELECT_DEPARTMENT_USER_ID ?? '',
  name: 'Select_department_user',
};

export const KNOWLEDGE_QA_ANSWERS: DataStoreType = {
  //id: '655985dbbaeaf8d6328c9369',
  id: process.env.REACT_APP_KNOWLEDGE_QA_ANSWERS_ID ?? '',
  name: 'knowledge_qa_answers',
};

export const REPORT_CURRICULUM: DataStoreType = {
  //id: '655986f9baeaf8d6328cad71',
  id: process.env.REACT_APP_REPORT_CURRICULUM_ID ?? '',
  name: 'report_curriculum',
};

export const KNOWLEDGE_FAVORITE_HISTORY: DataStoreType = {
  //id: '655986fabaeaf8d6328cad9b',
  id: process.env.REACT_APP_KNOWLEDGE_FAVORITE_HISTORY_ID ?? '',
  name: 'knowledge_favorite_history',
};

export const TOP_CURRICULUM_ACHIEVED_STATUS: DataStoreType = {
  //id: '655986f9baeaf8d6328cad91',
  id: process.env.REACT_APP_TOP_CURRICULUM_ACHIEVED_STATUS_ID ?? '',
  name: 'top_curriculum_achieved_status',
};

export const TOP_SKILL_CHECK_IMPLEMENTATION_STATUS: DataStoreType = {
  //id: '655986f9baeaf8d6328cad90',
  id: process.env.REACT_APP_TOP_SKILL_CHECK_IMPLEMENTATION_STATUS_ID ?? '',
  name: 'top_skill_check_implementation_status',
};

export const KNOWLEDGE_CURRICULUM_DETAIL: DataStoreType = {
  //id: '655986fabaeaf8d6328cad9a',
  id: process.env.REACT_APP_KNOWLEDGE_CURRICULUM_DETAIL_ID ?? '',
  name: 'knowledge_curriculum_detail',
};

export const CURRICULUM_GRAPH: DataStoreType = {
  //id: '655986f9baeaf8d6328cad6d',
  id: process.env.REACT_APP_CURRICULUM_GRAPH_ID ?? '',
  name: 'curriculum_graph',
};

export const SKILL_CHECK_GRAPH: DataStoreType = {
  //id: '655986f9baeaf8d6328cad6c',
  id: process.env.REACT_APP_SKILL_CHECK_GRAPH_ID ?? '',
  name: 'skill_check_graph',
};

export const DIRECTED_KNOWLEDGE: DataStoreType = {
  //id: '655986fabaeaf8d6328cada2',
  id: process.env.REACT_APP_DIRECTED_KNOWLEDGE_ID ?? '',
  name: 'directed_knowledge',
};

export const MAIN_AFFILIATION_LIST: DataStoreType = {
  id: '655986fabaeaf8d6328cadaf',
  name: 'main_affiliation_list',
};

export const CURRICULUM_IMPLE: DataStoreType = {
  id: '655986fabaeaf8d6328cadbb',
  name: 'curriculum_imple',
};

export const CURRICULUM_IMPLEMENTATION_STATUS: DataStoreType = {
  //id: '655986fabaeaf8d6328cadc3',
  id: process.env.REACT_APP_CURRICULUM_IMPLEMENTATION_STATUS_ID ?? '',
  name: 'curriculum_implementation_status',
};

export const CURRICULUM_HIERARCHY_TRANS_ANSWER_STATUS2: DataStoreType = {
  id: '655986fabaeaf8d6328cadca',
  name: 'curriculum_hierarchy_trans_answer_status2',
};

export const REQUIRED_CURRICULUM_HIERARCHY_TRANS: DataStoreType = {
  id: '655986fabaeaf8d6328cadc4',
  name: 'required_curriculum_hierarchy_trans',
};

export const COMPULSORY_CURRICULUM_IMPLEMENTATION_LIST: DataStoreType = {
  //id: '655986f9baeaf8d6328cad6f',
  id: process.env.REACT_APP_COMPULSORY_CURRICULUM_IMPLEMENTATION_LIST_ID ?? '',
  name: 'compulsory_curriculum_implementation_list',
};

export const OPTION_AFFILIATION: DataStoreType = {
  id: '655986f8baeaf8d6328cad3b',
  name: 'option_affiliation',
};

export const REQUIRED_CURRICULUM_SETTINGS: DataStoreType = {
  //id: '655986fabaeaf8d6328cadcb',
  id: process.env.REACT_APP_REQUIRED_CURRICULUM_SETTINGS_ID ?? '',
  name: 'required_curriculum_settings',
};

export const REQUIRED_SKILLCHECK_SETTINGS: DataStoreType = {
  //id: '655986fabaeaf8d6328cadcc',
  id: process.env.REACT_APP_REQUIRED_SKILLCHECK_SETTINGS_ID ?? '',
  name: 'skillcheck_settings',
};

export const PAYMENT_MASTER: DataStoreType = {
  //id: '655986fabaeaf8d6328cadc8',
  id: process.env.REACT_APP_PAYMENT_MASTER_ID ?? '',
  name: 'payment_master',
};

export const CARD_COMPANY_NAME: DataStoreType = {
  //id: '6559854bbaeaf8d6328c910a',
  id: process.env.REACT_APP_CARD_COMPANY_NAME_ID ?? '',
  name: 'card_company_name',
};

export const KNOWLEDGE_RECOMMEND_DETAIL_ORIGINAL: DataStoreType = {
  //id: '655986fabaeaf8d6328cadd0',
  id: process.env.REACT_APP_KNOWLEDGE_RECOMMEND_DETAIL_ORIGINAL_ID ?? '',
  name: 'knowledge_recommend_detail_original',
};

export const KNOWLEDGE_RECOMMEND_DETAIL_ADDITIONAL: DataStoreType = {
  //id: '655986fabaeaf8d6328cadd2',
  id: process.env.REACT_APP_KNOWLEDGE_QA_DETAIL_ADDITIONAL_ID ?? '',
  name: 'knowledge_recommend_detail_addtional',
};

export const KNOWLEDGE_QA_DETAIL_ORIGINAL: DataStoreType = {
  //id: '655986fabaeaf8d6328cadd3',
  id: process.env.REACT_APP_KNOWLEDGE_QA_DETAIL_ORIGINAL_ID ?? '',
  name: 'knowledge_qa_detail_original',
};

export const KNOWLEDGE_QA_DETAIL_ADDITIONAL: DataStoreType = {
  //id: '655986fabaeaf8d6328cadd1',
  id: process.env.REACT_APP_KNOWLEDGE_QA_DETAIL_ADDITIONAL_ID ?? '',
  name: 'knowledge_qa_detail_addtional',
};

export const PROFILE_CURRICULUMS: DataStoreType = {
  //id: '655986fabaeaf8d6328cadd9',
  id: process.env.REACT_APP_PROFILE_CURRICULUMS_ID ?? '',
  name: 'profile_curriculums',
};

export const OPTIONS_SKILL_CHECK_IMPLEMENT: DataStoreType = {
  id: '655986fabaeaf8d6328caddb',
  name: 'options_skill_check_implements',
};

export const OPTIONS_SKILL_CHECK_ANALYSIS_GROUP_IMPLEMENT: DataStoreType = {
  id: '655986fabaeaf8d6328caddc',
  name: 'options_skill_check_analysis_group_implemented',
};

export const STORAGE_MANAGEMENT: DataStoreType = {
  //id: '655986fabaeaf8d6328cadde',
  id: process.env.REACT_APP_STORAGE_MANAGEMENT_ID ?? '',
  name: 'storage_management',
};

export const ADMIN_USERS: DataStoreType = {
  //id: '655985abbaeaf8d6328c9269',
  id: process.env.REACT_APP_ADMIN_USERS_ID ?? '',
  name: 'admin_users',
};

export const ADMIN_USER_EXPORT: DataStoreType = {
  //id: '655986fabaeaf8d6328cad9d',
  id: process.env.REACT_APP_ADMIN_USER_EXPORT_ID ?? '',
  name: 'admin_user_export',
};

export const ADMIN_USER_DETAIL: DataStoreType = {
  //id: '655986fabaeaf8d6328cad9e',
  id: process.env.REACT_APP_ADMIN_USER_DETAIL_ID ?? '',
  name: 'admin_user_detail',
};

export const QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM: DataStoreType = {
  //id: '655985e8baeaf8d6328c93b0',
  id: process.env.REACT_APP_QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM_ID ?? '',
  name: 'question_assign_level_official_curriculum',
};

export const BILLING_DATA_MANAGEMENT: DataStoreType = {
  //id: '655986fabaeaf8d6328cadb3',
  id: process.env.REACT_APP_BILLING_DATA_MANAGEMENT_ID ?? '',
  name: 'billing_data_management',
};

export const BILLING_DATA_DETAIL: DataStoreType = {
  //id: '655986fabaeaf8d6328cadb5',
  id: process.env.REACT_APP_BILLING_DATA_DETAIL_ID ?? '',
  name: 'billing_data_detail',
};

export const BILLING_DETAILS: DataStoreType = {
  id: '6559858bbaeaf8d6328c91da',
  name: 'billing_details',
};

export const SELECT_ERROR: DataStoreType = {
  //id: '655986fabaeaf8d6328cadb8',
  id: process.env.REACT_APP_SELECT_ERROR_ID ?? '',
  name: 'select_error',
};

export const SELECT_ORDER_ID: DataStoreType = {
  //id: '655986fabaeaf8d6328cadb6',
  id: process.env.REACT_APP_SELECT_ORDER_ID_ID ?? '',
  name: 'select_order_id',
};

export const SELECT_USER_BILLING: DataStoreType = {
  //id: '655986fabaeaf8d6328cadb7',
  id: process.env.REACT_APP_SELECT_USER_BILLING_ID ?? '',
  name: 'select_user_billing',
};

export const COMPANY_USER: DataStoreType = {
  //id: '655986fabaeaf8d6328cada1',
  id: process.env.REACT_APP_COMPANY_USER_ID ?? '',
  name: 'company_user',
};

export const COMPANY_USER_DETAIL: DataStoreType = {
  //id: '655986fabaeaf8d6328cada4',
  id: process.env.REACT_APP_COMPANY_USER_DETAIL_ID ?? '',
  name: 'company_user_detail',
};

export const SELECT_COMPANY: DataStoreType = {
  //id: '655986f9baeaf8d6328cad52',
  id: process.env.REACT_APP_SELECT_COMPANY_ID ?? '',
  name: 'select_company',
};

export const SELECT_PAYMENT_METHOD: DataStoreType = {
  //id: '655986f9baeaf8d6328cad53',
  id: process.env.REACT_APP_SELECT_PAYMENT_METHOD_ID ?? '',
  name: 'select_payment_method',
};

export const OFFICIAL_CURRICULUM_EXPORT: DataStoreType = {
  //id: '655986fabaeaf8d6328cadae',
  id: process.env.REACT_APP_OFFICIAL_CURRICULUM_EXPORT_ID ?? '',
  name: 'official_curriculum_export',
};

export const AGGREGATION_CHURN_RATE: DataStoreType = {
  //id: '655986fabaeaf8d6328cadbc',
  id: process.env.REACT_APP_AGGREGATION_CHURN_RATE_ID ?? '',
  name: 'aggregation_churn_rate',
};

export const USER_REGISTRATION_HISTORY: DataStoreType = {
  id: '667a6d24a8840e9c3d2e3123',
  name: 'user_registration_history',
};

export const CHANGES_IN_USAGE_OF_OFFICIAL_CURRICULUM: DataStoreType = {
  //id: '65598577baeaf8d6328c919b',
  id: process.env.REACT_APP_CHANGES_IN_USAGE_OF_OFFICIAL_CURRICULUM_ID ?? '',
  name: 'changes_in_usage_of_official_curriculum',
};

export const NUMBER_OF_SKILL_CHECKS_PERFORMED: DataStoreType = {
  id: '655986fabaeaf8d6328cadbd',
  name: 'number_of_skill_checks_performed',
};

export const NUMBER_OF_SKILL_CHECKS_PERFORMED_MONTH: DataStoreType = {
  //id: '655986fabaeaf8d6328cadbe',
  id: process.env.REACT_APP_NUMBER_OF_SKILL_CHECKS_PERFORMED_MONTH_ID ?? '',
  name: 'number_of_skill_checks_performed_month',
};

export const ADMIN_DASHBORD_SALE_INFORMATION: DataStoreType = {
  id: '667a830fa525d8b21f869279',
  name: 'admin_dashbord_sales_information',
};

export const OFFICIAL_CURRICULUM_USERS: DataStoreType = {
  //id: '655986fabaeaf8d6328cadbf',
  id: process.env.REACT_APP_OFFICIAL_CURRICULUM_USERS_ID ?? '',
  name: 'official_curriculum_users',
};

export const INVOICE_CORRESPONDENCE_LIST: DataStoreType = {
  //id: '655986fabaeaf8d6328cada5',
  id: process.env.REACT_APP_INVOICE_CORRESPONDENCE_LIST_ID ?? '',
  name: 'invoice_correspondence_list',
};

export const INVOICE_CORRESPONDENCE: DataStoreType = {
  //id: '6559859abaeaf8d6328c921f',
  id: process.env.REACT_APP_INVOICE_CORRESPONDENCE_ID ?? '',
  name: 'invoice_correspondence',
};

export const SELECT_MANAGER: DataStoreType = {
  //id: '655986f9baeaf8d6328cad54',
  id: process.env.REACT_APP_SELECT_MANAGER_ID ?? '',
  name: 'select_manager',
};

export const SELECT_PROVIDER: DataStoreType = {
  id: process.env.REACT_APP_SELECT_PROVIDER_ID ?? '',
  name: 'select_provider',
};

export const PARTNER_CURRICULUM_CONTRACT_STATUS: DataStoreType = {
  id: '6666c42b6c4e8ab07b0d9257',
  name: 'partner_curriculum_contract_status',
};

export const PARTNER_CURRICULUM_NUM_OF_COMPANIES_USING: DataStoreType = {
  id: '66669bc25bea15d6814b0c05',
  name: 'partner_curriculum_num_of_companies_using',
};

export const PARTNER_DETAIL: DataStoreType = {
  id: process.env.REACT_APP_PARTNER_DETAIL_ID ?? '',
  name: 'partner_detail',
};

export const PARTNER_MANAGEMENT: DataStoreType = {
  id: '655986fabaeaf8d6328cada8',
  name: 'partner_management',
};

export const CURRICULUM_PUBLISHED_HISTORY: DataStoreType = {
  id: process.env.REACT_APP_CURRICULUM_PUBLISHED_HISTORY_ID ?? '',
  name: 'curriculum_published_history',
};

export const OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS: DataStoreType = {
  id: process.env.REACT_APP_OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS_ID ?? '',
  name: 'official_curriculum_publishing_setttings',
};

export const RELEASE_NOTE: DataStoreType = {
  id: process.env.REACT_APP_RELEASE_NOTE_ID ?? '',
  name: 'release_note',
};

export const RELEASE_NOTE_SECTION: DataStoreType = {
  id: process.env.REACT_APP_RELEASE_NOTE_SECTION_ID ?? '',
  name: 'release_note_section',
};

export const ADMIN_RELEASE_NOTE_MANAGEMENT: DataStoreType = {
  id: process.env.REACT_APP_ADMIN_RELEASE_NOTE_MANAGEMENT_ID ?? '',
  name: 'admin_release_note_management',
};

export const ADMIN_AFFILIATION_HIERARCHY: DataStoreType = {
  id: process.env.REACT_APP_ADMIN_AFFILIATION_HIERARCHY_ID ?? '',
  name: 'admin_affiliation_hierarchy',
};

export const ADMIN_AFFILIATION_LEVEL: DataStoreType = {
  id: process.env.REACT_APP_ADMIN_AFFILIATION_LEVEL_ID ?? '',
  name: 'admin_affiliation_level',
};

export const ADMIN_ROLES: DataStoreType = {
  id: process.env.REACT_APP_ADMIN_ROLES_ID ?? '',
  name: 'admin_roles',
};

export const ADMIN_ROLE_LIST: DataStoreType = {
  id: process.env.REACT_APP_ADMIN_ROLE_LIST_ID ?? '',
  name: 'admin_role_list',
};

export const SELECT_ADMIN_ROLE: DataStoreType = {
  id: process.env.REACT_APP_SELECT_ADMIN_ROLE_ID ?? '',
  name: 'select_admin_role',
};

export const ADMIN_POSITIONS: DataStoreType = {
  id: process.env.REACT_APP_ADMIN_POSITIONS_ID ?? '',
  name: 'admin_position',
};

export const SELECT_ADMIN_DEPARTMENT: DataStoreType = {
  id: process.env.REACT_APP_SELECT_ADMIN_DEPARTMENT_ID ?? '',
  name: 'select_admin_department',
};

export const SELECT_ADMIN_POSITION: DataStoreType = {
  id: process.env.REACT_APP_SELECT_ADMIN_POSITION_ID ?? '',
  name: 'select_admin_position',
};

export const SELECT_ADMIN_USERS: DataStoreType = {
  id: process.env.REACT_APP_SELECT_ADMIN_USERS_ID ?? '',
  name: 'select_admin_users',
};

export const OFFICIAL_CURRICULUM_COUNT_OF_STOPS: DataStoreType = {
  id: process.env.REACT_APP_OFFICIAL_CURRICULUM_COUNT_OF_STOPS_ID ?? '',
  name: 'official_curriculum_count_of_stops',
};

export const OFFICIAL_CURRICULUM_USER_AGGREGATION: DataStoreType = {
  id: '65598568baeaf8d6328c9154',
  name: 'official_curriculum_user_aggregation',
};

export const SYSTEMMASTER: DataStoreType = {
  id: process.env.REACT_APP_SYSTEMMASTER_ID ?? '',
  name: 'systemmaster',
};

export const PROVIDER_INFORMATION: DataStoreType = {
  id: process.env.REACT_APP_PROVIDER_INFORMATION_ID ?? '',
  name: 'provider_information',
};

export const ADMIN_AFFILIATION_ASSIGN_ROLE: DataStoreType = {
  id: process.env.REACT_APP_ADMIN_AFFILIATION_ASSIGN_ROLE_ID ?? '',
  name: 'admin_affiliation_assign_role',
};

export const INDIVIDUAL_USER_MANAGERMENT: DataStoreType = {
  id: process.env.REACT_APP_INDIVIDUAL_USER_MANAGERMENT_ID ?? '',
  name: 'individual_user_management',
};

export const PARTNER_CURRICULUM_NUM_OF_USER_USING: DataStoreType = {
  id: '6666a04cb4e4bf89358418ed',
  name: 'partner_curriculum_num_of_user_using',
};

export const SELECT_PROVIDER_DATE: DataStoreType = {
  id: process.env.REACT_APP_SELECT_PROVIDER_DATE_ID ?? '',
  name: 'select_provider_date',
};

export const OFFICIAL_CURRICULUM_QUESTION_SEARCH: DataStoreType = {
  id: process.env.REACT_APP_OFFICIAL_CURRICULUM_QUESTION_SEARCH_ID ?? '',
  name: 'official_curriculum_question_search',
};

export const LEVEL1S_OFFICIAL_CURRICULUM: DataStoreType = {
  id: '655985f6baeaf8d6328c9403',
  name: 'level1s_official_curriculum',
};

export const LEVEL2S_OFFICIAL_CURRICULUM: DataStoreType = {
  id: process.env.REACT_APP_LEVEL2S_OFFICIAL_CURRICULUM_ID ?? '',
  name: 'level2s_official_curriculum',
};

export const LEVEL3S_OFFICIAL_CURRICULUM: DataStoreType = {
  id: process.env.REACT_APP_LEVEL3S_OFFICIAL_CURRICULUM_ID ?? '',
  name: 'level3s_official_curriculum',
};

export const LEVEL4S_OFFICIAL_CURRICULUM: DataStoreType = {
  id: process.env.REACT_APP_LEVEL4S_OFFICIAL_CURRICULUM_ID ?? '',
  name: 'level4s_official_curriculum',
};

export const SALES_TRENDS: DataStoreType = {
  id: process.env.REACT_APP_SALES_TRENDS_ID ?? '',
  name: 'sales_trends',
};

export const SALES_INFORMATION: DataStoreType = {
  id: '6559855fbaeaf8d6328c912e',
  name: 'sales_information',
};

export const ADMIN_REPORT_STORAGE_MANAGEMENT: DataStoreType = {
  id: process.env.REACT_APP_ADMIN_REPORT_STORAGE_MANAGEMENT_ID ?? '',
  name: 'admin_report_storage_management',
};

export const OFFICIAL_QUESTION_SEARCH: DataStoreType = {
  id: '665019b7fff78362405ac6a2',
  name: 'official_question_search',
};

export const HISTORY_OF_USING: DataStoreType = {
  id: '65598611baeaf8d6328c94ac',
  name: 'history_of_using',
};

export const PUBLISHED_RELEASE_NOTE: DataStoreType = {
  id: '6674dedf3e224f94f288fa92',
  name: 'published_release_note',
};

export const PUBLISHED_RELEASE_NOTE_SECTION: DataStoreType = {
  id: '6674e293f367c957e4df7fe2',
  name: 'published_release_note_section',
};

export const ADMIN_REPORT_SALE_INFORMATION: DataStoreType = {
  id: '',
  name: 'admin_report_sales_infomation',
};

export const ADMIN_REPORT_SALE_INFORMATION_BREAKDOWN: DataStoreType = {
  id: '664454d55c7ba62b599615db',
  name: 'admin_report_sales_infomation_breakdown',
};

export const ADMIN_REPORT_SALE_INFORMATION_BREAKDOWN_DETAILS: DataStoreType = {
  id: '65d2f9535167c09517a52fc0',
  name: 'admin_report_sales_infomation_breakdown_detail',
};

export const SALES_INFORMATION_BREAKDOWN: DataStoreType = {
  id: '65598550baeaf8d6328c9114',
  name: 'sales_infomation_breakdown',
};

export const PAYMENT_METHOD: DataStoreType = {
  id: '65598610baeaf8d6328c949e',
  name: 'payment_method',
};

export const COMPANIES_PARTNER: DataStoreType = {
  id: '6571ecd48c988396fd72161c',
  name: 'companies',
};

export const REPORT_USER_INFORMATION_PARTNER: DataStoreType = {
  id: '6571ecc68c988396fd72159f',
  name: 'report_user_information_partner',
};

export const REPORT_USER_INFORMATION_COMPANY: DataStoreType = {
  id: '6559869fbaeaf8d6328c9741',
  name: 'report_user_information_company',
};

export const LEVEL1S_OFFICIAL_CURRICULUM_HIERARCHY: DataStoreType = {
  id: '6571ecfe8c988396fd721798',
  name: 'level1s_official_curriculum_hierarchy',
};

export const LEVEL2S_OFFICIAL_CURRICULUM_HIERARCHY: DataStoreType = {
  id: '6571ecfa8c988396fd721773',
  name: 'level2s_official_curriculum_hierarchy',
};

export const LEVEL3S_OFFICIAL_CURRICULUM_HIERARCHY: DataStoreType = {
  id: '6571ecf68c988396fd72174e',
  name: 'level3s_official_curriculum_hierarchy',
};

export const LEVEL4S_OFFICIAL_CURRICULUM_HIERARCHY: DataStoreType = {
  id: '6571ecf28c988396fd721729',
  name: 'level4s_official_curriculum_hierarchy',
};

export const QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM_HIERARCHY: DataStoreType = {
  id: '6571ecef8c988396fd721706',
  name: 'question_assign_level_official_curriculum_hierarchy',
};

export const QUESTIONS_HIERARCHY: DataStoreType = {
  id: '6571ec8b8c988396fd721394',
  name: 'questions_HIERARCHY',
};

export const OFFICIAL_CURRICULUM_HIERARCHY: DataStoreType = {
  id: '6571ed028c988396fd7217bd',
  name: 'official_curriculum_master_hierarchy',
};

export const NUMBER_TREND_COMPANIES: DataStoreType = {
  id: '66826b82be8c1253e370b466',
  name: 'number_trend_companies',
};

export const NUMBER_REGISTERED_COMPANIES: DataStoreType = {
  id: '66826c98c90151c68b0af7b8',
  name: 'number_registered_companies',
};

export const USER_PARTNER: DataStoreType = {
  id: '6571eccc8c988396fd7215d0',
  name: 'user_partner',
};

export const SIGNUP: DataStoreType = {
  id: '65598657baeaf8d6328c95b1',
  name: 'signup',
};

export const DISPLAY_ID_KEY = {
  TIME_LIMIT: {
    id: '65598664baeaf8d6328c95f3',
    name: 'time_limit',
  },
  problems1_attach: {
    id: '65598664baeaf8d6328c95fb',
    name: 'problems1_attach',
  },
  problems2_attach: {
    id: '65598664baeaf8d6328c95ff',
    name: 'problems2_attach',
  },
  problems3_attach: {
    id: '65598664baeaf8d6328c9603',
    name: 'problems3_attach',
  },
  attach1: {
    id: '65598664baeaf8d6328c95ef',
    name: 'attach1',
  },
  attach2: {
    id: '65598664baeaf8d6328c9615',
    name: 'attach2',
  },
  attach3: {
    id: '65598664baeaf8d6328c9618',
    name: 'attach3',
  },
  comment_attach1: {
    id: '65598664baeaf8d6328c960c',
    name: 'comment_attach1',
  },
  comment_attach2: {
    id: '65598664baeaf8d6328c960f',
    name: 'comment_attach2',
  },
  comment_attach3: {
    id: '65598664baeaf8d6328c9612',
    name: 'comment_attach3',
  },
  ALL_COMPANY_TYPE_ID: {
    id: process.env.REACT_APP_ALL_COMPANY || '6559856bbaeaf8d6328c9162',
    name: 'ALL_COMPANY_TYPE_ID',
  },
  exclusive_w_id: {
    id: process.env.REACT_APP_EXCLUSIVE_W_ID || '6763b353ad9a4ea2334fe85b',
    name: process.env.REACT_APP_EXCLUSIVE_W_ID || '6763b353ad9a4ea2334fe85b',
  },
  aggregate_user_type: {
    id: '65598573baeaf8d6328c9188',
    name: 'aggregate_user_type',
  },
  executeActionUploadFile: {
    id: '63884a903f959f2d1b8b4c7e',
    name: 'executeActionUploadFile',
  },
};
