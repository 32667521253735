import { CaretLeftOutlined, CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Radio } from 'antd';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { Form } from 'formik-antd';
import JoditEditor from 'jodit-pro-react';
import { maxBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { TickComplete, UnPublishJodit, UnPublished } from 'assets';
import { Input, TextArea } from 'components';
import { loadingRef } from 'components/Loading';
import Completed from 'components/Modal/Completed';
import DeleteCompleted from 'components/Modal/DeleteCompleted';
import { PUBLISHED_RELEASE_NOTE_SECTION, RELEASE_NOTE_SECTION } from 'configs';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { authSelector } from 'containers/Auth/selectors';
import { useAppDispatch } from 'hooks';
import { compareVersion } from 'libs';
import {
  JODIT_CUSTOM_LOCALIZATION,
  useJoditTranslateMutationObserver,
} from 'libs/utils/translateJodit';
import { createEditReleaseNote } from 'libs/validations';
import { routes } from 'navigations/routes';
import { getPresignedUrlByFileID, uploadFileToR2 } from 'services/r2Service';
import * as Types from 'types';
import { publicManagerSelector } from '../../selectors';
import {
  createFileAttach,
  createPublishedReleaseNote,
  createReleaseNote,
  executeBatchAction,
  getDataAdminReleaseNoteManagement,
  getDataReleaseNoteID,
  getReleaseNoteSection,
  updateOfficialCurriculumMaster,
} from '../../thunk';
import ConfirmCreate from '../ConfirmCreate';
import ExecuteDelete from '../ExecuteDelete';
import Published from '../Published';
import UpdateStatusPublic from '../UpdateStatusPublich';
import VideoPopup from '../UploadVideo';
import ManageReleaseStyled from './styles';
import { browserLogger } from 'libs/logger';

interface Props {
  selected?: Types.AdminReleaseNoteManagement.ResponseType;
  selectedOfficialSetting?: Types.OfficialCurriculumPublishingSettings.ResponseType;
  publish: number;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoteReleaseNew: React.FC<Props> = ({
  visible,
  setVisible,
  selected,
  selectedOfficialSetting,
}) => {
  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState<boolean>(false);
  const [visiblePublished, setVisiblePublished] = useState<boolean>(false);
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [visibleDeleted, setVisibleDeleted] = useState<boolean>(false);
  const [confirmCreate, setConfirmCreate] = useState<boolean>(false);
  const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
  const [indexSection, setIndexSection] = useState<number>(0);
  const [showPopup, setShowPopup] = useState(false);

  const { userInfo } = useSelector(authSelector);

  const { releaseNoteDetail, loading, dataReleaseNoteID } = useSelector(publicManagerSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fetchDataReleaseNoteID = useCallback(() => {
    if (visible && userInfo) {
      dispatch(
        getDataReleaseNoteID({
          conditions: [
            {
              id: 'curriculum_code',
              search_value: [
                selected?.official_curriculum_code ??
                  selectedOfficialSetting?.official_curriculum_code,
              ],
            },
          ],
          sort_fields: [
            {
              id: 'version',
              order: 'asc',
            },
          ],
          page: 1,
          per_page: 0,
          omit_total_items: false,
          include_item_ref: true,
          omit_fields_data: true,
          use_display_id: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, selectedOfficialSetting?.official_curriculum_code, dispatch]);

  const fetchDataReleaseNoteManagement = () => {
    if (!selected) return;
    dispatch(
      getDataAdminReleaseNoteManagement({
        conditions: [
          {
            id: 'provider_id',
            search_value: [selected.provider_id ?? userInfo?.company_id],
          },
          {
            id: 'official_curriculum_code',
            search_value: [
              selected.official_curriculum_code ??
                selectedOfficialSetting?.official_curriculum_code,
            ],
          },
        ],
        sort_fields: [
          {
            id: 'publish_start_date',
            order: 'asc',
          },
        ],
        page: 1,
        per_page: 0,
        omit_total_items: false,
        include_item_ref: true,
      })
    );
  };

  const updateOfficialCurriculum = async () => {
    if (!selectedOfficialSetting?.i_id_curriculum_master) return;

    await dispatch(
      updateOfficialCurriculumMaster({
        id: selectedOfficialSetting?.i_id_curriculum_master,
        data: {
          item: {
            updatedat: new Date().toDateString(),
          },
        },
      })
    );
  };

  const formik = useFormik<Types.RelaseNoteFormik>({
    initialValues: {
      title: `${
        selected?.official_curriculum_name || selectedOfficialSetting?.name
          ? `${
              selected?.official_curriculum_name || selectedOfficialSetting?.name
            } - リリースノート`
          : ''
      }`,
      description:
        selected?.official_curriculum_name || selectedOfficialSetting?.name
          ? `${
              selected?.official_curriculum_name || selectedOfficialSetting?.name
            }の説明を記載しています。`
          : '',
      release_schedule_information: '',
      version: '' as unknown as number,
      official_curriculum_code: '',
      release_note_id: '',
      sections: [
        {
          section_name: 'カリキュラム説明',
          text: selectedOfficialSetting?.description || '',
        },
      ],
    },
    validationSchema: createEditReleaseNote,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (
        userInfo &&
        (selected || selectedOfficialSetting) &&
        selectedOfficialSetting?.i_id_curriculum_master
      ) {
        setConfirmCreate(false);
        const resultAction = await dispatch(
          createReleaseNote({
            item: {
              company_id: userInfo.company_id,
              release_note_title: values.title,
              curriculum_code:
                selected?.official_curriculum_code ||
                selectedOfficialSetting?.official_curriculum_code,
              publish_status: 1,
              version: formik.values.version.toString(),
              description: values.description,
              createdby: userInfo.login_id,
              createdat: new Date(),
            },
            return_item_result: true,
            return_display_id: true,
            realtime_auto_link: true,
          })
        );

        if (createReleaseNote.fulfilled.match(resultAction)) {
          await updateOfficialCurriculum();

          dispatch(
            executeBatchAction({
              ensure_transaction: true,
              item_actions: {
                [RELEASE_NOTE_SECTION.name]: await Promise.all(
                  values.sections.map(async (value, index) => {
                    const newContent = await handleFormatContent(value.text);
                    return {
                      operation: 1,
                      item: {
                        company_id: userInfo.company_id,
                        section_name: value.section_name,
                        release_note_id: resultAction.payload?.item?.release_note_id,
                        text: newContent,
                        display_order: index,
                        createdby: userInfo.login_id,
                        createdat: new Date(),
                      },
                      access_key_updates: {
                        roles_to_publish: ['MEMBER'],
                      },
                      realtime_auto_link: true,
                    };
                  })
                ),
              },
              realtime_auto_link: true,
            })
          );
          browserLogger.info(
            'pages/PublicManagement/Modal',
            RELEASE_NOTE_SECTION.name,
            executeBatchAction
          );
        }

        await fetchDataReleaseNoteManagement();
        await fetchDataReleaseNoteID();
        setTimeout(() => {
          setVisibleComplete(true);
        }, 1500);
      }
    },
  });

  const handleToggleModal = () => {
    setVisible(false);
  };
  const handleExtraButtonClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const defaultConfig = useMemo(
    () => ({
      limitChars: 99900,
      // limitHTML: true,
      showCharsCounter: true,
      license: process.env.REACT_APP_JODIT_EDITOR_KEY ?? '',
      language: 'ja',
      i18n: { ja: JODIT_CUSTOM_LOCALIZATION.ja },
      placeholder: '本文を入力...',
      height: 550,
      statusbar: false,
      addNewLine: false,
      allowResizeX: false,
      allowResizeY: false,
      defaultLineHeight: 1,
      disablePlugins: ['tune-block'],
      buttons,
      buttonsMD: buttons,
      buttonsSM: buttons,
      buttonsXS: buttons,
      uploader: {
        url: 'https://xdsoft.net/jodit/finder/files/',
        insertImageAsBase64URI: true,
        insertVideoAsBase64URI: true,
        withCredentials: false,
        format: 'json',
        data: {
          dir: '',
        },
        filesVariableName: function (t: any) {
          return 'files[' + t + ']';
        },

        process: function (resp: any) {
          //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
          console.log('resp', resp);
          let files = [];
          files.unshift(resp.data);
          return { files: resp.data, error: resp.msg, msg: resp.msg };
        },

        isSuccess: () => {
          return true;
        },
        getMessage: function (e: any) {
          return void 0 !== e.data.messages && Array.isArray(e.data.messages)
            ? e.data.messages.join('')
            : '';
        },
        pathVariableName: 'path',
      },
      preview: true,
      readonly: false,
      extraButtons: {
        name: 'insertVideo',
        icon: 'video',
        tooltip: '動画挿入',
        exec: handleExtraButtonClick,
      },
    }),
    []
  );

  useJoditTranslateMutationObserver();

  const handleUpdateStatus = async () => {
    if (!userInfo || (!selected && !selectedOfficialSetting?.i_id_curriculum_master)) return;

    dispatch(startLoading());

    await updateOfficialCurriculum();

    const resultAction = await dispatch(
      createReleaseNote({
        item: {
          company_id: userInfo.company_id,
          release_note_title: formik.values.title,
          curriculum_code:
            selected?.official_curriculum_code ?? selectedOfficialSetting?.official_curriculum_code,
          publish_status: 2,
          version: formik.values.version.toString(),
          description: formik.values.description,
          release_schedule_information: formik.values.release_schedule_information,
          createdby: userInfo.login_id,
          createdat: new Date(),
        },
        return_item_result: true,
        return_display_id: true,
        realtime_auto_link: true,
      })
    );

    if (createReleaseNote.fulfilled.match(resultAction)) {
      dispatch(
        createPublishedReleaseNote({
          item: resultAction.payload?.item,
          access_key_updates: {
            roles_to_publish: ['MEMBER'],
          },
          realtime_auto_link: true,
        })
      );

      const resultCreateSection = await dispatch(
        executeBatchAction({
          ensure_transaction: true,
          item_actions: {
            [RELEASE_NOTE_SECTION.name]: await Promise.all(
              formik.values.sections.map(async (value, index) => {
                const newContent = await handleFormatContent(value.text);
                return {
                  operation: 1,
                  item: {
                    company_id: userInfo.company_id,
                    section_name: value.section_name,
                    release_note_id: resultAction?.payload?.item?.release_note_id,
                    text: newContent,
                    display_order: index,
                    createdby: userInfo.login_id,
                    createdat: new Date(),
                  },
                  access_key_updates: {
                    roles_to_publish: ['MEMBER'],
                  },
                  realtime_auto_link: true,
                };
              })
            ),
          },
          realtime_auto_link: true,
        })
      );
      browserLogger.info(
        'pages/PublicManagement/Modal',
        RELEASE_NOTE_SECTION.name,
        resultCreateSection
      );
      if (executeBatchAction.fulfilled.match(resultCreateSection)) {
        const getReleaseNoteSectionResult = await dispatch(
          getReleaseNoteSection({
            conditions: [
              {
                id: 'curriculum_code',
                search_value: [selectedOfficialSetting?.official_curriculum_code],
              },
              {
                id: 'release_note_id',
                search_value: [resultAction.payload.item.release_note_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        );

        if (
          getReleaseNoteSection.fulfilled.match(getReleaseNoteSectionResult) &&
          getReleaseNoteSectionResult.payload.items.length
        ) {
          dispatch(
            executeBatchAction({
              ensure_transaction: true,
              item_actions: {
                [PUBLISHED_RELEASE_NOTE_SECTION.name]:
                  getReleaseNoteSectionResult?.payload?.items.map((e) => ({
                    operation: 1,
                    item: e,
                    access_key_updates: {
                      roles_to_publish: ['MEMBER'],
                    },
                    realtime_auto_link: true,
                  })),
              },
              realtime_auto_link: true,
            })
          );
          browserLogger.info(
            'pages/PublicManagement/Modal',
            PUBLISHED_RELEASE_NOTE_SECTION.name,
            executeBatchAction
          );
        }
      }
    }

    await fetchDataReleaseNoteID();
    await setVisiblePublished(true);

    dispatch(stopLoading());
  };
  // useEffect(fetchDataReleaseNoteID, [fetchDataReleaseNoteID]);

  const parser = new DOMParser();
  const doc = parser.parseFromString(formik.values.sections[indexSection]?.text, 'text/html');

  const imgElements = doc.querySelectorAll('img');
  const base64Array = Array.from(imgElements).reduce((acc: Array<string>, img) => {
    if (img.src.startsWith('data:image')) {
      acc.push(img.src);
    }
    return acc;
  }, []);

  const convertBase64ToFile = async (base64Data: string, fileName: string): Promise<File> => {
    const response = await fetch(base64Data);
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: 'image/png' });
    return file;
  };

  const handleFormatContent = useCallback(
    async (content: string) => {
      if (!userInfo?.i_id) return content;
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, 'text/html');
      const imgElements = doc.querySelectorAll('img');

      const base64Array = Array.from(imgElements)
        .map((img) => (img.src.startsWith('data:image') ? img.src : null))
        .filter(Boolean) as string[];

      if (base64Array.length) {
        dispatch(startLoading());

        const result = await Promise.all(
          base64Array
            .map(async (item) => {
              const imageSrc = await convertBase64ToFile(item, `file${uuidv4()}`);
              const uploadFileToMinio = await uploadFileToR2(imageSrc);
              if (uploadFileToMinio) {
                await dispatch(
                  createFileAttach({
                    item: {
                      fileID: uploadFileToMinio,
                      filename: `file${uuidv4()}`,
                      file_location: '6',
                      file_extension: imageSrc.type,
                      file_size: `${imageSrc.size}`,
                      company_id: userInfo.company_id,
                      createdat: new Date(),
                      createdby: userInfo.login_id,
                    },
                  })
                );

                const srcImage = (await getPresignedUrlByFileID(uploadFileToMinio)) as string;

                content = content.replace(item, srcImage);

                return { src: srcImage, base64: item };
              }
            })
            .filter(Boolean)
        );

        for (let index = 0; index < result.length; index++) {
          const element = result[index];
          if (!element) continue;
          const { src, base64 } = element;
          content = content.replace(base64, src);
        }
        dispatch(stopLoading());
        return content;
      } else {
        return content;
      }
    },
    [dispatch, userInfo?.i_id, userInfo?.company_id, userInfo?.login_id]
  );

  useEffect(() => {
    (async () => {
      let fileUpload: Array<string | undefined> = [];

      if (base64Array.length) {
        fileUpload = await Promise.all(
          base64Array
            .map(async (item, i) => {
              const imageSrc = await convertBase64ToFile(item, `file${i}`);
              const uploadFileToMinio = await uploadFileToR2(imageSrc);
              if (uploadFileToMinio) {
                await dispatch(
                  createFileAttach({
                    item: {
                      fileID: uploadFileToMinio,
                      filename: `file${i}`,
                    },
                  })
                );

                return (await getPresignedUrlByFileID(uploadFileToMinio)) as string;
              }
            })
            .filter(Boolean)
        );
      }

      const replacedHTML = formik.values.sections[indexSection]?.text?.replace(
        /src="data:image\/(png|jpg|jpeg|gif);base64,([^"]+)"/g,
        (match, base64) => {
          const index = Math.floor(Math.random() * fileUpload.length);
          return `src="${fileUpload[index]}"`;
        }
      );
    })();
  }, [dispatch, base64Array.length]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!visible) {
      formik.resetForm();
      setIndexSection(0);
    }
  }, [visible]);
  const id =
    selected?.official_curriculum_code ?? selectedOfficialSetting?.official_curriculum_code;

  return (
    <ManageReleaseStyled
      keyboard={!formik.dirty}
      isEmptyData={!releaseNoteDetail?.sections.length}
      title={<span className="title">リリースノート 新規作成</span>}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      width={1400}
      open={visible}
    >
      <FormikProvider value={formik}>
        <Form layout="vertical">
          <div className="information">
            <div>
              <span className="content">
                {selected?.official_curriculum_name || selectedOfficialSetting?.name}
              </span>{' '}
              {''}
              <span className="id">
                (ID：
                {id ?? ''})
              </span>
            </div>
            <div className="version">
              <div className="wrap-label-required">
                <span className="label-required">*</span>
                <span className="label">OFFICIALカリキュラム＆リリースノート公開バージョン :</span>
              </div>
              <div className="wrap-label">
                <Form.Item name="version" className="version-required">
                  <Input
                    name="version"
                    placeholder="例）1.2.3"
                    onChange={({ target }) => {
                      if (target.value) {
                        const compareResult = compareVersion(
                          String(maxBy(dataReleaseNoteID, 'version')?.version),
                          target.value
                        );
                        compareResult <= 0 &&
                          formik.setFieldError(
                            'version',
                            '前のバージョンより高いバージョン入力してください。'
                          );
                      }
                    }}
                    validate={(value) => {
                      if (value !== '') {
                        const compareResult = compareVersion(
                          String(maxBy(dataReleaseNoteID, 'version')?.version),
                          value
                        );
                        return compareResult > 0
                          ? ''
                          : '前のバージョンより高いバージョン入力してください。';
                      }
                      return;
                    }}
                    maxLength={30}
                  />
                  {formik.errors.version ? (
                    <div
                      className="version-errors"
                      style={{
                        right: formik.errors.version.length > 20 ? '0' : 'auto',
                        left: formik.errors.version.length <= 20 ? '0' : 'auto',
                      }}
                    >
                      {formik.errors.version}
                    </div>
                  ) : null}
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="while-editing">
            <img src={UnPublishJodit} alt="" />
            <div className="wrap-input">
              <div className="wrap-input-version">
                <Form.Item className="item" name="title">
                  <Input name="title" placeholder="【タイトル】最大60文字" maxLength={60} />
                </Form.Item>
              </div>
            </div>
          </div>
          <FieldArray
            name="sections"
            render={({ remove, push }) => (
              <div className="wrap-editor">
                <div className="left-side">
                  <div className="title-editor">
                    <div className="no-editor">{indexSection + 1}</div>
                    <TextArea
                      name={`sections.${indexSection}.section_name`}
                      className="input"
                      placeholder="セクション名を入力..."
                      value={(formik.values.sections[indexSection]?.section_name ?? '').replaceAll(
                        '\n',
                        ''
                      )}
                      autoSize={{ minRows: 1, maxRows: 2 }}
                      maxLength={61}
                    />
                  </div>
                  <JoditEditor
                    value={formik.values.sections[indexSection]?.text}
                    config={defaultConfig}
                    onBlur={(newContent) =>
                      formik.setFieldValue(`sections.${indexSection}.text`, newContent)
                    }
                    onChange={(newValue) => {
                      formik.setFieldValue(`sections.${indexSection}.text`, newValue);
                    }}
                  />
                  <div className="wrap-bottom-editor">
                    <div className="wrap-button-editor">
                      <Button
                        className="btn button-prev"
                        disabled={indexSection === 0}
                        onClick={() => setIndexSection((prevState) => prevState - 1)}
                      >
                        <CaretLeftOutlined />
                        <span>前のセクション</span>
                      </Button>
                      <Button
                        className="btn button-next"
                        disabled={indexSection === formik.values.sections.length - 1}
                        onClick={() => setIndexSection(indexSection + 1)}
                      >
                        <span>次のセクション</span>
                        <CaretRightOutlined />
                      </Button>
                    </div>
                    <div
                      className="delete-editor"
                      onClick={() => {
                        setVisibleConfirmDelete(true);
                      }}
                    >
                      <DeleteOutlined
                        className="icon-delete"
                        style={{
                          color: '#00989A',
                        }}
                      />
                      <span>このセクションを削除</span>
                    </div>
                  </div>
                  <DeleteCompleted
                    onSubmit={() => {
                      setIndexSection((prevState) => (prevState > 0 ? prevState - 1 : 0));
                      remove(indexSection);
                    }}
                    visible={visibleDeleted}
                    setVisible={setVisibleDeleted}
                  />
                </div>
                {showPopup && (
                  <Modal
                    open={showPopup}
                    closable={false}
                    onOk={handlePopupClose}
                    onCancel={handlePopupClose}
                    footer={null}
                  >
                    <VideoPopup
                      indexSection={indexSection}
                      formik={formik}
                      setOpen={setShowPopup}
                    />
                  </Modal>
                )}
                <div className="right-side">
                  <Form.Item name="description" label="概要：" className="text-area">
                    <TextArea
                      name="description"
                      rows={5}
                      placeholder="最大480文字"
                      maxLength={480}
                    />
                  </Form.Item>
                  <div className="flex-div">
                    <span className="title-right">セクション</span>
                    <div className="wrap-button-add">
                      {formik.values.sections.length < 6 && (
                        <Button
                          className="add-section"
                          style={{ fontSize: '12px' }}
                          onClick={() =>
                            push({
                              section_name: '',
                              text: '',
                            })
                          }
                        >
                          ＋ セクションの追加
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="dashed-line"></div>
                  <div className="wrap-radio">
                    <Radio.Group
                      value={indexSection}
                      onChange={(e) => setIndexSection(e.target.value)}
                    >
                      {formik.values.sections.map((_, index) => (
                        <div
                          className={`input-radio ${
                            formik.errors.sections && formik.errors.sections[index] ? 'error' : ''
                          } ${index === indexSection && 'section-checked'}`}
                          key={index}
                          onClick={() => setIndexSection(index)}
                        >
                          <span className="label-radio">{index + 1}</span>
                          <div className="wrap-section-input">
                            <TextArea
                              className="input-textArea"
                              placeholder="セクション名を入力..."
                              bordered={false}
                              value={formik.values.sections[index]?.section_name
                                .replaceAll('\n', '')
                                .match(/.{1,20}/g)
                                ?.join('\n')
                                .slice(0, 62)}
                              name={`sections.${index}.section_name`}
                              maxLength={62}
                              autoSize={{ minRows: 1, maxRows: 4 }}
                            />
                            <Radio value={index} />
                          </div>
                        </div>
                      ))}
                    </Radio.Group>
                  </div>
                </div>
              </div>
            )}
          />
          <div className="wrap-submit">
            <div className="wrap-submit">
              <div className="wrap-button">
                <Button
                  className="btn-outline"
                  onClick={() => {
                    formik.validateForm().then((res) => {
                      if (!res.version) {
                        setConfirmCreate(true);
                      }
                    });
                  }}
                >
                  一時保存
                </Button>
                <Button
                  className="btn btn_submit"
                  onClick={() => {
                    formik.validateForm().then((res) => {
                      if (!res.version) {
                        setVisibleUpdate(true);
                      }
                    });
                  }}
                >
                  公開
                </Button>
                <Button className="btn btn_close" onClick={() => setVisible(false)}>
                  キャンセル
                </Button>
              </div>
            </div>
          </div>
        </Form>
        <Completed
          title="リリースノートを保存しました。"
          visible={visibleComplete}
          setVisible={setVisibleComplete}
          onSubmit={async () => {
            navigate(routes.PublicManagement.path);
            await setVisibleComplete(false);
            setVisible(false);
          }}
          subTitle={
            <span
              style={{
                color: '#424242',
                fontSize: 13,
              }}
            >
              公開ステータスは未公開として表示されます。
            </span>
          }
        />
        <UpdateStatusPublic
          status="publish"
          title="リリースノートを公開します"
          from={UnPublished}
          to={TickComplete}
          formValue={formik.values}
          description={
            <span>
              リリースノートを公開すると、
              <br /> 一般のユーザーが閲覧できる状態になります。
            </span>
          }
          visible={visibleUpdate}
          setVisible={setVisibleUpdate}
          onSubmit={handleUpdateStatus}
        >
          <div className="wrap-form">
            <Form.Item className="form-release" name="release_schedule_information">
              <span className="lable">
                アップデート公開予定情報 <span className="required">*</span>
              </span>
              <Input name="release_schedule_information" placeholder="最大30文字" maxLength={30} />
            </Form.Item>
          </div>
        </UpdateStatusPublic>
        <Published
          visible={visiblePublished}
          setVisible={setVisiblePublished}
          onSubmit={() => navigate(generatePath(routes.PublicManagement.path))}
          setVisibleCreated={setVisible}
        />
        <ExecuteDelete
          visible={visibleConfirmDelete}
          setVisible={setVisibleConfirmDelete}
          onSubmit={() => setVisibleDeleted(true)}
        />
        <ConfirmCreate
          open={confirmCreate}
          setOpen={setConfirmCreate}
          onSubmit={formik.handleSubmit}
        />
      </FormikProvider>
    </ManageReleaseStyled>
  );
};

export default NoteReleaseNew;

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
];
