import React, { useMemo } from 'react';
import { maxBy } from 'lodash';

import { CurriculumItemType, TreeItem } from 'types';
import NodeContentRenderer from '../NodeRenderer';
import { Wrapper } from './styles';
import * as Types from 'types';

interface Props {
  accept: string;
  tabActive: number;
  isPublish: boolean;
  nodeHeight: number;
  isIndex: number;
  columnIndex: number;
  maxSortOrder?: number;
  treeViewIndex: number;
  column: Types.TreeItem<Types.CurriculumItemType>[];
  itemMoveCopySelected?: Types.ItemMoveCopySelectedType;
  setItemMoveCopySelected?: React.Dispatch<
    React.SetStateAction<Types.ItemMoveCopySelectedType | undefined>
  >;
  onDrop: (
    type: 'move' | 'copy',
    currentItem: Types.TreeItem<Types.CurriculumItemType>,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => void;
  onDropQuestion: (
    currentItem: Types.DropQuestionType,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => void;
  isExport?: boolean;
  isPartners?: boolean;
  rootNode: Types.TreeItem<Types.CurriculumItemType>;
}

const ColumnRenderer: React.FC<Props> = ({
  column,
  accept,
  onDrop,
  isPublish,
  tabActive,
  nodeHeight,
  columnIndex,
  maxSortOrder,
  isIndex,
  treeViewIndex,
  onDropQuestion,
  itemMoveCopySelected,
  setItemMoveCopySelected,
  isExport,
  isPartners,
  rootNode,
}) => {
  const publishActive = useMemo<TreeItem<CurriculumItemType> | undefined>(() => {
    let row = undefined;
    Array.from({
      length: maxBy(column, 'lineIndex')?.lineIndex || 0,
    }).map((__, index) => {
      row = column.find((r) => r.lineIndex! - 1 === index);
    });
    return row;
  }, [column]);

  const items = useMemo(() => {
    return Array.from({
      length: maxBy(column, 'lineIndex')?.lineIndex || 0,
    });
  }, [column]);

  return (
    <div
      style={{ flexGrow: columnIndex === 5 ? 1 : 0 }}
      className={`column ${
        itemMoveCopySelected?.node.columnIndex! - 1 === columnIndex &&
        publishActive?.node?.publish !== 2
          ? 'column-active'
          : ''
      }`}
    >
      {items.map((__, index) => (
        <ColumnItemRenderer
          rootNode={rootNode}
          key={index}
          index={index}
          accept={accept}
          onDrop={onDrop}
          isIndex={isIndex}
          isPublish={isPublish}
          tabActive={tabActive}
          nodeHeight={nodeHeight}
          maxSortOrder={maxSortOrder}
          treeViewIndex={treeViewIndex}
          onDropQuestion={onDropQuestion}
          itemMoveCopySelected={itemMoveCopySelected}
          setItemMoveCopySelected={setItemMoveCopySelected}
          isExport={isExport}
          column={column}
          columnIndex={columnIndex}
          isPartners={isPartners}
        />
      ))}
    </div>
  );
};

const ColumnItemRenderer: React.FC<Props & { index: number }> = ({
  index,
  column,
  accept,
  onDrop,
  isIndex,
  isPublish,
  tabActive,
  nodeHeight,
  columnIndex,
  maxSortOrder,
  treeViewIndex,
  onDropQuestion,
  itemMoveCopySelected,
  setItemMoveCopySelected,
  isExport,
  isPartners,
  rootNode,
}) => {
  const row = useMemo(() => column.find((r) => r.lineIndex! - 1 === index), [column, index]);
  if (!row) {
    return <Wrapper style={{ height: nodeHeight }} key={index} className="rst__node" />;
  }
  return (
    <NodeContentRenderer
      node={row}
      key={index}
      index={index}
      accept={accept}
      onDrop={onDrop}
      isIndex={isIndex}
      isPublish={isPublish}
      tabActive={tabActive}
      nodeHeight={nodeHeight}
      maxSortOrder={maxSortOrder}
      treeViewIndex={treeViewIndex}
      onDropQuestion={onDropQuestion}
      itemMoveCopySelected={itemMoveCopySelected}
      setItemMoveCopySelected={setItemMoveCopySelected}
      isExport={isExport}
      isPartners={isPartners}
      rootNode={rootNode}
    />
  );
};

export default ColumnRenderer;
