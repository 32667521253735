import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  textBox: {
    width: '100%',
    marginBottom: '6px',
  },
  valueBox: {
    border: '1px solid #CCCCCC',
    width: '100%',
    fontSize: 8,
    padding: '5px 10px',
    color: '#2A2A2A',
    borderRadius: '3px',
    backgroundColor: 'white',
  },
  textCount: {
    fontSize: 6,
    color: '#BBBBBB',
    textAlign: 'right',
    width: '100%',
  },
});
