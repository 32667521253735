import React, { useEffect, useState, useRef } from 'react';

import { Modal } from 'components';
import { Button } from 'antd';
import { useAppDispatch } from 'hooks';
import { pdfjs } from 'react-pdf';

import './style.css';
import { getPresignedUrlByFileID } from 'services/r2Service';
import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import DocViewer, { DocViewerRenderers } from 'components/PartnerViewer';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  visibleContract: boolean;
  setVisibleContract: React.Dispatch<React.SetStateAction<boolean>>;
  contractFileId: string;
  setContractFileId: React.Dispatch<React.SetStateAction<string>>;
}

const ModalContract: React.FC<Props> = ({
  visibleContract,
  setVisibleContract,
  contractFileId,
  setContractFileId,
}) => {
  const [scale, setScale] = useState(1);
  const [file, setFile] = useState<File>();
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [renderedScale, setRenderedScale] = useState(scale);
  const [documents, setDocuments] = useState<
    { uri: string; fileName?: string; fileType?: string }[]
  >([]);

  useEffect(() => {
    (async () => {
      if (visibleContract && contractFileId) {
        const fileAction = await getPresignedUrlByFileID(contractFileId);

        if (!fileAction) return;

        const fileName = extractFileName(contractFileId);
        const fileFromUrl = await getFileFromUrl(fileAction as string, fileName);

        setFile(fileFromUrl);
        const newDocuments = [
          {
            uri: fileAction as string,
            fileName: fileName,
            fileType: fileFromUrl.type,
          },
        ];
        setDocuments(newDocuments);
      }
    })();
  }, [dispatch, contractFileId, visibleContract]);

  const handleExport = () => {
    if (file) {
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  useEffect(() => {
    if (!visibleContract) {
      setScale(1);
      setDocuments([]);
      setFile(undefined);
    }
  }, [visibleContract]);

  return visibleContract ? (
    <Modal
      destroyOnClose
      title={file?.name}
      width={720}
      open={visibleContract}
      onCancel={() => {
        setVisibleContract(false);
        setContractFileId('');
        setDocuments([]);
        setFile(undefined);
      }}
      cancelButton={{
        text: '閉じる',
        onClick: () => setVisibleContract(false),
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
        padding: 0,
      }}
      afterClose={() => {
        setDocuments([]);
        setFile(undefined);
      }}
      footer={[
        <Button key="export" onClick={handleExport} type="primary">
          エクスポート
        </Button>,
        <Button key="close" onClick={() => setVisibleContract(false)}>
          閉じる
        </Button>,
      ]}
      footerStyle={{ backgroundColor: '#f9f8f8' }}
      headerStyle={{ borderBottom: '1px solid #CCCCCC' }}
    >
      <div
        className="pdf-container"
        style={{ justifyItems: 'center', backgroundColor: 'rgb(170, 170, 170)' }}
      >
        <div className="pdf-container-render" ref={containerRef}>
          {file?.type.startsWith('image') ? (
            <img src={documents[0]?.uri} />
          ) : (
            !!documents.length && (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                }}
              >
                <DocViewer pluginRenderers={DocViewerRenderers} documents={documents} />
              </div>
            )
          )}
        </div>
      </div>
    </Modal>
  ) : null;
};

export default ModalContract;
