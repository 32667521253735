import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
const IconCheckSquareThin = ({
  style,
  isInvoice,
}: {
  style?: Style | Style[];
  isInvoice?: boolean;
}) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" style={style}>
    <G clipPath="url(#clip0_71_92152)">
      <Path
        d="M6.59186 10.6012C6.64442 10.6746 6.71371 10.7344 6.79399 10.7756C6.87426 10.8168 6.96321 10.8384 7.05346 10.8384C7.14371 10.8384 7.23266 10.8168 7.31294 10.7756C7.39322 10.7344 7.46251 10.6746 7.51507 10.6012L11.2758 5.38694C11.3436 5.2923 11.2758 5.16016 11.1597 5.16016H10.3222C10.1401 5.16016 9.96686 5.24766 9.85971 5.39766L7.05436 9.29051L5.78293 7.52623C5.67578 7.37801 5.50436 7.28873 5.32043 7.28873H4.48293C4.36686 7.28873 4.299 7.42087 4.36686 7.51551L6.59186 10.6012Z"
        fill={isInvoice ? '#4B83D7' : '#8B62E2'}
        fillOpacity={0.4}
      />
      <Path
        d="M14.5698 0.857422H1.4269C1.11083 0.857422 0.855469 1.11278 0.855469 1.42885V14.5717C0.855469 14.8878 1.11083 15.1431 1.4269 15.1431H14.5698C14.8858 15.1431 15.1412 14.8878 15.1412 14.5717V1.42885C15.1412 1.11278 14.8858 0.857422 14.5698 0.857422ZM13.8555 13.8574H2.14118V2.14314H13.8555V13.8574Z"
        fill={isInvoice ? '#4B83D7' : '#8B62E2'}
        fillOpacity={0.4}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_71_92152">
        <Rect width="16" height="16" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default IconCheckSquareThin;
