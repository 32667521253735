import React from 'react';
import { BarChartOutlined, TableOutlined } from '@ant-design/icons';
import { FormikProvider } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Card, Checkbox, Select } from 'antd';
import OfficialCurriculumInformationStyled from './../styles';
import { DatePicker, SelectField } from 'components';
import ChartOfficialCurriculum from './../Chart';
import TableOfficialCurriculum, { tableObject } from './../Table';

import { Pagination } from '../../slice';
import { uniqBy } from 'lodash';
import * as Types from 'types';
import { Conditions } from '..';
const { Option } = Select;
const { RangePicker } = DatePicker;

interface Props {
  listCompany: Array<Types.OfficialCurriculumnAggregationData>;
  openConfirmExport: boolean;
  visibleCurriculumDevelopment: boolean;
  visibleProducerExpand: boolean;
  activeTable: boolean;
  formik: any;
  data: tableObject;
  conditions: Conditions;
  segmented: number;
  selectedDates: any;
  pagination: Pagination;
}

const PDFComponent = ({
  listCompany,
  openConfirmExport,
  visibleCurriculumDevelopment,
  visibleProducerExpand,
  activeTable,
  formik,
  selectedDates,
  data,
  conditions,
  segmented,
  pagination,
}: Props) => {
  return (
    <OfficialCurriculumInformationStyled>
      <div id="official-curriculumn" className="container">
        <div className="wrap-filter">
          <span className="label">集計条件</span>
          <div className="aggregation-conditions">
            <FormikProvider value={formik}>
              <Form layout="vertical">
                <div className="form-search">
                  <Form.Item
                    requiredMark
                    name="active_number_of_usage"
                    className="item"
                    label={
                      <span className="text-label">
                        集計方法選択 <span className="required">*</span>
                      </span>
                    }
                  >
                    <SelectField
                      popupClassName="select-field"
                      name="active_number_of_usage"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value={'0'}>OFFICIALカリキュラム利用者数推移</Option>
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="target_month"
                    className="item"
                    label={
                      <span className="text-label">
                        集計期間 <span className="required">*</span>
                      </span>
                    }
                  >
                    <RangePicker
                      allowClear
                      value={selectedDates}
                      className="date"
                      name="target_month"
                      picker="month"
                      format="YYYY/MM"
                      placeholder={['集計開始日', '集計終了日']}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement!}
                    />
                  </Form.Item>
                  <Form.Item
                    name="company_type"
                    className="item"
                    label={<span className="text-label">企業種類選択</span>}
                  >
                    <SelectField
                      name="company_type"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="ALL">ALL</Option>
                      <Option value="6559856bbaeaf8d6328c9162">全企業</Option>
                      <Option value="6559856bbaeaf8d6328c9161">RSTANDARD</Option>
                      <Option value="6559856bbaeaf8d6328c9163">パートナー企業</Option>
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="company_name"
                    className="item"
                    label={<span className="text-label">制作者検索</span>}
                  >
                    <SelectField
                      name="company_name"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="ALL">ALL</Option>
                      {uniqBy(listCompany, 'company_name')
                        .filter((i) => (i.company_name ? i.company_name.trim() : false))
                        .map((item) => {
                          return <Option value={item.company_name}>{item.company_name}</Option>;
                        })}
                    </SelectField>
                  </Form.Item>
                  <div className="group-btn">
                    <span className="label-reset">リセット</span>
                    <SubmitButton className="btn-search" loading={false}>
                      表示
                    </SubmitButton>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </div>
        </div>
        <div className="sub-container">
          <div className="wrap-segmented">
            <div className="left-side">
              <span className="label">レポートタイプ：</span>
              <div className="segmented">
                <div
                  className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                >
                  <TableOutlined className="icon" />
                  <span>表</span>
                </div>
                <div
                  className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                >
                  <BarChartOutlined className="icon" />
                  <span>グラフ</span>
                </div>
              </div>
              <div className="item-checkbox">
                <Checkbox checked={visibleProducerExpand && !segmented} disabled={!!segmented}>
                  制作者展開
                </Checkbox>
              </div>
              <div className="item-checkbox">
                <Checkbox
                  disabled={!visibleProducerExpand || !!segmented}
                  checked={visibleCurriculumDevelopment && visibleProducerExpand && !segmented}
                >
                  カリキュラム展開
                </Checkbox>
              </div>
              <div className="item-checkbox">
                <Checkbox checked={activeTable}>アクティブ</Checkbox>
              </div>
            </div>
          </div>
          {!formik.values.active_number_of_usage &&
          !formik.values.author &&
          !formik.values.target_month &&
          !formik.values.company_type ? (
            <Card
              bodyStyle={{
                height: '250px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#777777',
              }}
            >
              集計条件を選択してください
            </Card>
          ) : segmented === 0 ? (
            <>
              <TableOfficialCurriculum
                data={data}
                pagination={pagination}
                activeTable={activeTable}
                visibleProducerExpand={visibleProducerExpand}
                visibleCurriculumDevelopment={visibleCurriculumDevelopment}
                conditions={conditions}
              />
            </>
          ) : (
            <>
              <ChartOfficialCurriculum
                data={data}
                openConfirmExport={openConfirmExport}
                activeTable={activeTable}
              />
            </>
          )}
        </div>
      </div>
    </OfficialCurriculumInformationStyled>
  );
};
export default PDFComponent;
