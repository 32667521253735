import { Path, Rect, Svg } from '@react-pdf/renderer';

const WhiteEditing = () => (
  <Svg width="16" height="16" viewBox="0 0 16 16">
    <Rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="1.5"
      ry="1.5"
      fill="#71C131"
      stroke="#71C131"
    />
    <Path
      d="M8.08265 2.5C5.02966 2.5 2.5542 4.96272 2.5542 8C2.5542 11.0373 5.02966 13.5 8.08265 13.5C11.1356 13.5 13.6111 11.0373 13.6111 8C13.6111 4.96272 11.1356 2.5 8.08265 2.5ZM10.4705 6.20391L7.87163 9.78873C7.83531 9.83916 7.78742 9.88026 7.73195 9.90861C7.67647 9.93696 7.615 9.95175 7.55263 9.95175C7.49026 9.95175 7.4288 9.93696 7.37332 9.90861C7.31784 9.88026 7.26996 9.83916 7.23364 9.78873L5.6948 7.6673C5.64791 7.60223 5.6948 7.51138 5.77501 7.51138H6.35377C6.47964 7.51138 6.59935 7.57154 6.67339 7.67466L7.55202 8.88761L9.49191 6.21127C9.56595 6.10937 9.68442 6.04799 9.81152 6.04799H10.3903C10.4705 6.04799 10.5174 6.13884 10.4705 6.20391Z"
      fill="white"
    />
    <Rect width="16" height="16" rx="2" fill="#D48AE0" />
    <Path
      d="M12.632 7.48307H11.6922V4.40307C11.6922 3.43094 10.9002 2.64307 9.92309 2.64307H6.49545C5.5183 2.64307 4.72635 3.43094 4.72635 4.40307V7.48307H3.78651C3.54188 7.48307 3.34424 7.67969 3.34424 7.92307V13.2031C3.34424 13.4464 3.54188 13.6431 3.78651 13.6431H12.632C12.8767 13.6431 13.0743 13.4464 13.0743 13.2031V7.92307C13.0743 7.67969 12.8767 7.48307 12.632 7.48307ZM8.59626 10.7418V11.4706C8.59626 11.5311 8.54651 11.5806 8.4857 11.5806H7.93285C7.87204 11.5806 7.82228 11.5311 7.82228 11.4706V10.7418C7.70819 10.6603 7.62304 10.5449 7.57907 10.4122C7.53511 10.2795 7.5346 10.1364 7.57762 10.0034C7.62065 9.87034 7.70498 9.75435 7.81849 9.67206C7.932 9.58977 8.06883 9.54544 8.20927 9.54544C8.34972 9.54544 8.48655 9.58977 8.60006 9.67206C8.71356 9.75435 8.7979 9.87034 8.84092 10.0034C8.88395 10.1364 8.88344 10.2795 8.83948 10.4122C8.79551 10.5449 8.71035 10.6603 8.59626 10.7418ZM10.6971 7.48307H5.72147V4.40307C5.72147 3.97819 6.06838 3.63307 6.49545 3.63307H9.92309C10.3502 3.63307 10.6971 3.97819 10.6971 4.40307V7.48307Z"
      fill="white"
    />
  </Svg>
);

export default WhiteEditing;
