import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  checkBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 4,
    gap: 4,
  },
  label: {
    color: '#424242',
    fontSize: 8,
  },
  icon: {
    width: 8,
    height: 8,
  },
});
