import React, { useMemo } from 'react';
import { Table } from 'antd';

import BreakdownOfCompaniesStyled from './styles';
import { ColumnType } from 'antd/lib/table';
import { AnyObject } from 'types';
import { uniq } from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { reportUserInformationSelector } from 'pages/Report/UserInformation/selectors';
import { formatComma } from 'libs/utils/format';
import { EXPORT_TARGET_MONTH_COUNT } from 'constant';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  visibleExpansionOfSale: boolean;
  exportPdf?: boolean;
}

const DEFAULT_COLUMNS: ColumnType<any>[] = [
  {
    title: '企業種類',
    dataIndex: 'company_type',
    key: 'company_type',
    fixed: 'left',
    align: 'left',
    width: 150,
  },
  {
    title: '登録情報',
    dataIndex: 'registration_information',
    key: 'registration_information',
    align: 'left',
    fixed: 'left',
    width: 150,
  },
];

const BreakdownOfCompanies: React.FC<Props> = ({ visibleExpansionOfSale, exportPdf }) => {
  const { dateRanger, companyReports, calculatedCompanyReports, calculatedPartnerReports } =
    useSelector(reportUserInformationSelector);

  const dates = useMemo(() => {
    let dateData =
      dateRanger && dateRanger.length
        ? dateRanger
        : uniq(companyReports.map((obj) => obj.target_month));
    if (exportPdf) {
      dateData = dateData.slice(0, EXPORT_TARGET_MONTH_COUNT);
    }
    return dateData;
  }, [companyReports, dateRanger, exportPdf]);

  const columns = useMemo(() => {
    const dateColumns = dates.map((item, index) => ({
      title: dayjs(item, 'YYYYMM').format('YYYY/MM'),
      dataIndex: item,
      key: item,
      width: 95,
      className: 'text-center',
      render: (text: string | number) =>
        text || `${text}` === '0' ? <span>{formatComma(text)}</span> : <span>-</span>,
      onCell: () => ({
        className: 'text-right',
      }),
    }));
    return [...DEFAULT_COLUMNS, ...dateColumns];
  }, [DEFAULT_COLUMNS, dates, visibleExpansionOfSale]);

  const dataSource = useMemo(() => {
    if (visibleExpansionOfSale) {
      return [
        {
          id: uuidv4(),
          company_type: '一般企業',
          registration_information: '新規登録企業数',
          ...(calculatedCompanyReports || {}).company_added,
          children: [
            {
              id: uuidv4(),
              registration_information: '退会企業数',
              ...(calculatedCompanyReports || {}).company_deleted,
            },
            {
              id: uuidv4(),
              registration_information: '月末登録企業数',
              ...(calculatedCompanyReports || {}).month_end_company,
            },
          ],
        },
        {
          id: uuidv4(),
          company_type: 'パートナー企業',
          registration_information: '新規登録企業数',
          ...(calculatedPartnerReports || {}).company_added,
          children: [
            {
              id: uuidv4(),
              registration_information: '退会企業数',
              ...(calculatedPartnerReports || {}).company_deleted,
            },
            {
              id: uuidv4(),
              registration_information: '月末登録企業数',
              ...(calculatedPartnerReports || {}).month_end_company,
            },
          ],
        },
      ];
    }

    return [
      {
        id: uuidv4(),
        company_type: '一般企業',
        registration_information: '月末登録企業数',
        ...(calculatedCompanyReports || {}).month_end_company,
      },
      {
        id: uuidv4(),
        company_type: 'パートナー企業',
        registration_information: '月末登録企業数',
        ...(calculatedPartnerReports || {}).month_end_company,
      },
    ];
  }, [calculatedCompanyReports, calculatedPartnerReports, visibleExpansionOfSale]);

  const expandRowKeys = useMemo(() => {
    const rowKeys: string[] = [];
    const getRowKeys = (items: AnyObject[]) => {
      for (const item of items) {
        if (item.children) {
          rowKeys.push(item.id);
          if (item.children.length) getRowKeys(item.children);
        }
      }
    };
    getRowKeys(dataSource);

    return rowKeys;
  }, [dataSource]);

  return (
    <BreakdownOfCompaniesStyled visibleExpansionOfSale={visibleExpansionOfSale}>
      <Table
        className="table"
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        expandable={{
          defaultExpandAllRows: true,
          expandedRowKeys: expandRowKeys,
        }}
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
        pagination={false}
        scroll={{
          x: 1440,
        }}
      />
    </BreakdownOfCompaniesStyled>
  );
};

export default BreakdownOfCompanies;
