import React, { useCallback, useEffect, useState } from 'react';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { generatePath, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Button, Select, Table } from 'antd';
import { find, get, unionBy } from 'lodash';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import {
  CloudDownloadOutlined,
  SearchOutlined,
  DeleteOutlined,
  FormOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { HEADER_ADMIN_ROLES_EXPORT_CSV } from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PDFRolesMasterTableDocument from './PDFRolesMasterTableDocument';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { Header, PagingNumber, SelectField } from 'components';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { authorityMasterSelector } from './selectors';
import AuthorityMasterStyled from './styles';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import { deleteAdminRole, getAdminRoleList, getSelectAdminRole, getUsers } from './thunk';
import PopupConfirmExportFile from './ConfirmExportFile';
import { exportCsv } from 'libs/utils/exportCsv';
import PerPageSelect from 'components/PerPageSelect';
import { SELECT_RECORD } from 'constant/select.constants';

const { Option } = Select;

const AuthorityMaster: React.FC = () => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [showWarningDeleteModal, setShowWarningDeleteModal] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [deleteItemId, setDeleteItemId] = useState<string>();
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);

  const { listAuthority, userRole, users } = useSelector(authorityMasterSelector);
  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const columns: ColumnsType<Types.Roles.ResponseType> = [
    {
      key: 'code',
      className: 'column',
      width: '10%',
      align: 'center',
      title: 'コード',
      dataIndex: 'code',
    },
    {
      key: 'name',
      className: 'column',
      width: '74%',
      title: '名称',
      dataIndex: 'name',
    },
    {
      key: 'edit',
      className: 'column',
      width: '8%',
      title: '編集',
      render: (_: string, item: Types.Roles.ResponseType) => (
        <FormOutlined className="icon" onClick={() => handleEditItem(item)} />
      ),
    },
    {
      key: 'delete',
      className: 'column',
      width: '8%',
      title: '削除',
      align: 'center',
      render: (item: Types.UserMasterRole) => (
        <DeleteOutlined className="icon" onClick={() => handleDeleteItem(item)} />
      ),
    },
  ];

  const fetchDataAdminRoleList = useCallback(() => {
    if (!userInfo) return;
    dispatch(
      getAdminRoleList({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        include_lookups: true,
        use_display_id: true,
        include_item_ref: true,
        page: 1,
        per_page: 0,
      })
    );
    setPage(1);
  }, [dispatch, userInfo]);

  const fetchDataSelectAdminRole = useCallback(() => {
    if (!userInfo) return;
    dispatch(
      getSelectAdminRole({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        include_lookups: true,
        use_display_id: true,
        include_item_ref: true,
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo]);

  const fetchUsers = useCallback(async () => {
    if (!userInfo) return;

    dispatch(startLoading());

    await dispatch(
      getUsers({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
        include_lookups: true,
      })
    );
    dispatch(stopLoading());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const searchFormik = useFormik<Types.SearchAuthorityMasterFormik>({
    initialValues: {
      name: '',
    },
    onSubmit: async (values) => {
      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo?.company_id],
        },
      ];
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value) {
          conditions.push({
            id: key,
            search_value: [value],
            exact_match: true,
          });
        }
      });
      await dispatch(
        getAdminRoleList({
          conditions,
          include_lookups: true,
          use_display_id: true,
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      );
      setPage(1);
    },
    onReset: () => {
      fetchDataAdminRoleList();
      setPage(1);
    },
  });

  const handleDeleteItem = (item: Types.UserMasterRole) => {
    const roleFound = find(users, (user) => get(user, 'lookup_items.role_code.i_id') === item.i_id);
    setDeleteItemId(!roleFound ? item.i_id : '');
    setOpenModalConfirmDeleteItem(true);
  };

  const handleEditItem = (item: Types.Roles.ResponseType) => {
    navigate(generatePath(routes.AuthorityMasterEdit.path, { id: item.i_id }), {
      state: item,
    });
  };

  const handleAddItem = () => {
    navigate(generatePath(routes.AuthorityMasterCreate.path));
  };

  const handleSearch = (value: string) => {};

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const listCsv = listAuthority.map((item) => ({
        code: item.code || '',
        name: item.name || '',
        admin_dashboard_permission: mapPermision(item.admin_dashboard_permission?.toString()),
        sales_report_permission: mapPermision(item.sales_report_permission?.toString()),
        user_report_permission: mapPermision(item.user_report_permission?.toString()),
        official_curriculum_report_permission: mapPermision(
          item.official_curriculum_report_permission?.toString()
        ),
        skill_check_implementation_report_permission: mapPermision(
          item.skill_check_implementation_report_permission?.toString()
        ),
        billing_management_permission: mapPermision(item.billing_management_permission?.toString()),
        official_curriculum_publish_permission: mapPermision(
          item.official_curriculum_publish_permission?.toString()
        ),
        curricullum_master_permission: mapPermision(item.curricullum_master_permission?.toString()),
        question_master_permission: mapPermision(item.question_master_permission?.toString()),
        users_master_permission: mapPermision(item.users_master_permission?.toString()),
        roles_master_permission: mapPermision(item.roles_master_permission?.toString()),
        departments_master_permission: mapPermision(item.departments_master_permission?.toString()),
        positions_master_permission: mapPermision(item.positions_master_permission?.toString()),
        admin_master_permission: mapPermision(item.admin_master_permission?.toString()),
        admin_storage_permission: mapPermision(item.admin_storage_permission?.toString()),
        partner_management_permission: mapPermision(item.partner_management_permission?.toString()),
        curriculum_tree_permission: mapPermision(item.curriculum_tree_permission?.toString()),
        corporate_user_permission: mapPermision(item.corporate_user_permission?.toString()),
        invoice_correspondence_permission: mapPermision(
          item.invoice_correspondence_permission?.toString()
        ),
        individual_user_management_permission: mapPermision(
          item.individual_user_management_permission?.toString()
        ),
      }));

      exportCsv(listCsv, HEADER_ADMIN_ROLES_EXPORT_CSV, '権限マスタ.csv');
      setShowConfirmExportFileModal(false);
    } else {
      const blob = await pdf(<PDFRolesMasterTableDocument roles={listAuthority} />).toBlob();
      saveAs(blob, '権限マスタ.pdf');
      setShowConfirmExportFileModal(false);
    }
  };

  const handleDeleteAdminRole = useCallback(async () => {
    if (!deleteItemId) return;
    const resultAction = await dispatch(deleteAdminRole({ id: deleteItemId }));
    if (deleteAdminRole.fulfilled.match(resultAction)) {
      setOpenModalConfirmDeleteItem(false);
      setShowCompleteModal(true);
    } else {
      setShowWarningDeleteModal(true);
    }
    fetchDataAdminRoleList();
    fetchDataSelectAdminRole();
    setDeleteItemId(undefined);
  }, [deleteItemId, dispatch, fetchDataAdminRoleList, fetchDataSelectAdminRole]);

  useEffect(() => {
    fetchDataAdminRoleList();
    fetchDataSelectAdminRole();
    fetchUsers();
  }, [fetchDataAdminRoleList, fetchDataSelectAdminRole, fetchUsers]);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerpage(listAuthority.length);
    }
  }, [listAuthority]);

  const mapPermision = (permission: string | undefined) => {
    if (permission?.trim().includes('0')) return '非表示';
    if (permission?.trim().includes('1')) return '閲覧権限';
    if (permission?.trim().includes('2')) return '編集権限';
    return '';
  };
  return (
    <AuthorityMasterStyled isEmptyData={!listAuthority.length}>
      <Header title={headerTitle} className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            権限マスタの作成・管理を行う画面です。 権限マスタはSKILL
            FAMILIAR上で社内ユーザーが使用できる機能の範囲を設定します。
            <br />
            作成した権限マスタをユーザーに設定することで、機能ごとの権限がユーザーに設定されます。
          </p>
          <div className="border" />
          <FormikProvider value={searchFormik}>
            <Form
              layout="vertical"
              labelCol={{
                flex: '10%',
              }}
              colon={false}
              className="form-search"
            >
              <Form.Item
                name="name"
                label={<span className="label"> 権限</span>}
                className="form-input"
              >
                <SelectField
                  showSearch
                  onSearch={handleSearch}
                  className="select-input"
                  placeholder="選択してください"
                  name="name"
                  filterOption={(input, option) =>
                    option!.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {unionBy(userRole, 'name').map(
                    ({ name }, index) =>
                      name && (
                        <Option value={name} key={index}>
                          {name}
                        </Option>
                      )
                  )}
                </SelectField>
              </Form.Item>
              <div className="wrap-btn">
                <SubmitButton className="btn-search">
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <ResetButton className="label-reset">
                  <span className="label-text"> リセット</span>
                </ResetButton>
              </div>
            </Form>
          </FormikProvider>
          <div className="wrap-table">
            <div className="wrap-button">
              <Button
                className="btn btn-active"
                disabled={!listAuthority.length}
                onClick={() => setShowConfirmExportFileModal(true)}
              >
                <CloudDownloadOutlined className="size-icon-20" />
                エクスポート
              </Button>
              <Button className="btn btn-active" onClick={handleAddItem}>
                <PlusOutlined className="size-icon-14" />
                新規登録
              </Button>
            </div>

            <Table
              rowKey="code"
              dataSource={listAuthority}
              columns={columns}
              className={listAuthority.length ? 'table' : 'table_mr'}
              pagination={{
                pageSize: perPage,
                showSizeChanger: false,
                total: listAuthority.length,
                current: page,
                onChange: setPage,
                position: ['topCenter'],
                showTotal: () => (
                  <div className="wrap-select-record">
                    <PagingNumber
                      startItem={listAuthority.length ? `${(page - 1) * perPage + 1}` : ''}
                      endItem={
                        page * perPage > listAuthority.length
                          ? listAuthority.length
                          : page * perPage
                      }
                      totalItem={listAuthority.length}
                    />
                    <PerPageSelect
                      data={listAuthority}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerpage}
                    />
                  </div>
                ),
              }}
            />
          </div>
        </div>
      </div>
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle="検索するマスタが未選択です"
        description={
          <p className="text-content">
            検索する権限を選択後、
            <br />
            「検索」をクリックしてください。
          </p>
        }
      />
      <ActionErrorModal
        visible={showWarningDeleteModal}
        setVisible={setShowWarningDeleteModal}
        subTitle="削除に失敗しました"
        description="マスタの削除に失敗しました。 再度お試しください。"
      />
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="削除が完了しました"
      />
      <ConfirmDeleteModal
        visible={openModalConfirmDeleteItem}
        title="削除確認"
        subTitle={deleteItemId ? 'データの削除を実行します' : 'データの削除は実行できません。'}
        description={
          deleteItemId
            ? 'データの削除を実行すると、復元できませんのでご注意ください。'
            : '選択したマスタは社内ユーザーに設定されているため、削除できません。'
        }
        onSubmit={deleteItemId ? handleDeleteAdminRole : undefined}
        onCancel={() => setDeleteItemId('')}
        setVisible={setOpenModalConfirmDeleteItem}
        itemDeleted={!!deleteItemId}
      />
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
    </AuthorityMasterStyled>
  );
};

export default AuthorityMaster;
