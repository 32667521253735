import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ZoomInPDFIcon, ZoomOutPDFIcon } from './icons';
import { Button } from '../../../components/common';
import { setZoomLevel } from '../state/actions';
import PDFPagination from './PDFPagination';
import { PDFContext } from '../state';
import { IMainState } from 'components/PartnerViewer/store/mainStateReducer';

interface Props {
  mainState: IMainState;
}

const PDFControls: React.FC<Props> = ({ mainState }) => {
  const {
    state: { paginated, zoomLevel, numPages },
    dispatch,
  } = useContext(PDFContext);

  return (
    <Container id="pdf-controls">
      {/* <div className="name">{mainState.currentDocument?.fileName}</div> */}
      <div className="button">
        {paginated && numPages >= 1 && <PDFPagination />}
        <ControlButton
          id="pdf-zoom-out"
          onMouseDown={() => dispatch(setZoomLevel(Math.max(zoomLevel - 0.06, 0.06)))}
        >
          <ZoomOutPDFIcon color="#000" size="80%" />
        </ControlButton>

        <ControlButton
          id="pdf-zoom-in"
          onMouseDown={() => dispatch(setZoomLevel(zoomLevel + 0.06))}
        >
          <ZoomInPDFIcon color="#000" size="80%" />
        </ControlButton>
      </div>
    </Container>
  );
};

export default PDFControls;

const Container = styled.div`
  display: grid;
  // grid-template-columns: 2fr 1fr 2fr;
  gap: 10px;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 1;
  padding: 8px;
  background-color: #f1f1f1;
  box-shadow: 0 2px 3px #00000033;
  .name {
    color: #777777;
    font-size: 12px;
    margin-left: 12px;
  }
  .version {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
    .text-version {
      min-width: 230px;
      color: #777777;
      font-size: 10px;
    }
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    padding: 6px;
  }
`;

const ControlButton = styled(Button)`
  width: 30px;
  height: 30px;
  border: none;
  background-color: #f1f1f1;
  box-shadow: none;
  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
  }
`;
