import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  section: {
    paddingBottom: 6,
    borderBottom: '1px dashed #CCCCCC',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '16px',
  },

  textHeader: {
    fontSize: 8,
    color: '#424242',
    flex: 1,
  },

  noSection: {
    borderRadius: '50%',
    backgroundColor: '#424242',
    width: '19px',
    height: '19px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px',
  },

  no: {
    color: '#DDDDDD',
    fontSize: 8,
  },
});
