import styled from 'styled-components';

export const SectionStyled = styled.div`
  .sub-title {
    font-size: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #424242;
    .icon {
      color: #bbbbbb;
      font-size: 28px;
      margin-right: 10px;
    }
  }
  .input-TextArea {
    position: relative;
    .ant-input {
      resize: vertical !important;
      height: 500px;
    }
    &::after {
      float: unset;
      position: absolute;
      right: 0;
      top: 100%;
    }
  }
  .text-content {
    font-size: 13px;
    text-align: center;
    color: #777777;
  }
`;
