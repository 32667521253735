import { REGEX_HALF_WIDTH_LATIN } from 'constant';
import yup from '../yup';

export const loginSchema = yup.object().shape({
  email: yup.string().required().email().max(254),
  password: yup.string().required().max(30),
});

export const resetPasswordSchema = yup.object().shape({
  email: yup.string().required().email().max(254),
});

export const registerSchema = yup.object().shape({
  email: yup
    .string()
    .email('メールアドレスは＠を含む半角英数字で入力してください。')
    .max(254, 'メールアドレスは254文字以内で入力してください。')
    .required('メールアドレスは必須入力項目です。')
    .matches(/^\S+@\S+\.\S+$/, 'メールアドレスは＠を含む半角英数字で入力してください。'),
  date: yup.string().nullable().required('契約開始日は必須入力項目です。'),
});

export const registerFormSchema = yup.object().shape({
  password: yup.string().required().max(30),
  rePassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'パスワードが一致しません')
    .max(30),
});

export const changeEmailSchema = yup.object().shape({
  email: yup.string().required().email().max(254),
  confirm_email: yup
    .string()
    .required()
    .oneOf([yup.ref('email'), null], 'メールアドレスが一致しません')
    .max(254),
});

export const changePasswordSchema = yup.object().shape({
  current_password: yup
    .string()
    .required('現在のパスワードは必須入力項目です。')
    .matches(REGEX_HALF_WIDTH_LATIN, {
      message: '現在のパスワードは半角英数字、記号で入力してください。',
    })
    .max(30)
    .min(4),
  new_password: yup
    .string()
    .required('新しいパスワードは必須入力項目です。')
    .matches(REGEX_HALF_WIDTH_LATIN, {
      message: '新しいパスワードは半角英数字、記号で入力してください。',
    })
    .max(30)
    .min(4),
  confirm_password: yup
    .string()
    .required('新しいパスワード（確認）は必須入力項目です。')
    .matches(REGEX_HALF_WIDTH_LATIN, {
      message: '新しいパスワード（確認）は半角英数字、記号で入力してください。',
    })
    .oneOf([yup.ref('new_password'), null], 'パスワードが一致しません')
    .max(30)
    .min(4),
});
