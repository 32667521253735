import { createSlice } from '@reduxjs/toolkit';

import * as Types from 'types';
import {
  getOptionLimit,
  deleteQuestion,
  getQuestionDetail,
  createLinkQuestionAssignLevel,
  createNewFile,
  createQuestion,
  deleteFile,
  deleteLinkQuestion,
  editAttachment,
  editQuestion,
  executeActionUploadFile,
  getFileInQuestion,
  updateAttachmentTable,
  uploadFile,
  createLinkSkillCheckAssignQuestion,
} from './thunk';
import { getTimeLimitOption } from 'libs/utils/question';

type InitialState = {
  loading: boolean;
  time_limit: Types.TimeLimitType[];
};

const initialState: InitialState = {
  loading: false,
  time_limit: [],
};

const questionSlice = createSlice({
  name: 'question-Slice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getOptionLimit.pending, startLoading)
      .addCase(createLinkSkillCheckAssignQuestion.pending, startLoading)
      .addCase(deleteQuestion.pending, startLoading)
      .addCase(getQuestionDetail.pending, startLoading)
      .addCase(createLinkQuestionAssignLevel.pending, startLoading)
      .addCase(createNewFile.pending, startLoading)
      .addCase(createQuestion.pending, startLoading)
      .addCase(deleteFile.pending, startLoading)
      .addCase(deleteLinkQuestion.pending, startLoading)
      .addCase(editAttachment.pending, startLoading)
      .addCase(editQuestion.pending, startLoading)
      .addCase(executeActionUploadFile.pending, startLoading)
      .addCase(getFileInQuestion.pending, startLoading)
      .addCase(updateAttachmentTable.pending, startLoading)
      .addCase(uploadFile.pending, startLoading);

    builder.addCase(getOptionLimit.fulfilled, (state, action) => {
      state.time_limit = getTimeLimitOption(action.payload);
      stopLoading(state);
    });

    builder
      .addCase(deleteQuestion.fulfilled, stopLoading)
      .addCase(getQuestionDetail.fulfilled, stopLoading)
      .addCase(createLinkQuestionAssignLevel.fulfilled, stopLoading)
      .addCase(createNewFile.fulfilled, stopLoading)
      .addCase(createQuestion.fulfilled, stopLoading)
      .addCase(deleteFile.fulfilled, stopLoading)
      .addCase(deleteLinkQuestion.fulfilled, stopLoading)
      .addCase(createLinkSkillCheckAssignQuestion.fulfilled, stopLoading)
      .addCase(editAttachment.fulfilled, stopLoading)
      .addCase(editQuestion.fulfilled, stopLoading)
      .addCase(executeActionUploadFile.fulfilled, stopLoading)
      .addCase(getFileInQuestion.fulfilled, stopLoading)
      .addCase(updateAttachmentTable.fulfilled, stopLoading)
      .addCase(uploadFile.fulfilled, stopLoading);

    builder
      .addCase(getOptionLimit.rejected, stopLoading)
      .addCase(deleteQuestion.rejected, stopLoading)
      .addCase(getQuestionDetail.rejected, stopLoading)
      .addCase(createLinkQuestionAssignLevel.rejected, stopLoading)
      .addCase(createNewFile.rejected, stopLoading)
      .addCase(createQuestion.rejected, stopLoading)
      .addCase(deleteFile.rejected, stopLoading)
      .addCase(deleteLinkQuestion.rejected, stopLoading)
      .addCase(createLinkSkillCheckAssignQuestion.rejected, stopLoading)
      .addCase(editAttachment.rejected, stopLoading)
      .addCase(editQuestion.rejected, stopLoading)
      .addCase(executeActionUploadFile.rejected, stopLoading)
      .addCase(getFileInQuestion.rejected, stopLoading)
      .addCase(updateAttachmentTable.rejected, stopLoading)
      .addCase(uploadFile.rejected, stopLoading);
  },
});

// export const {} = questionSlice.actions;

export default questionSlice.reducer;
