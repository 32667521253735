import React from 'react';
import { View } from '@react-pdf/renderer';
import styles from './styles';

interface FullPageProps {
  children?: React.ReactNode;
}
const FullPage = (props: FullPageProps) => <View style={styles.fullPage}>{props.children}</View>;

export default FullPage;
