import React from 'react';

import useConfrimExportModal, { Props, ReceivedProps } from './hook';
import { Modal } from 'components';
import Styled from './styes';

const ExportPDFlayout: React.FC<Props> = (props) => {
  const { isOpen, handleSubmit, handleClose } = props;

  return (
    <Modal
      open={isOpen}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleClose,
      }}
      title="エクスポート"
      headerStyle={{
        borderBottom: '1px solid #cccccc',
        color: '#2a2a2a',
      }}
    >
      <Styled>
        <p className="content">「社内ユーザー」をPDFでエクスポートします。</p>
      </Styled>
    </Modal>
  );
};

const ConfirmExportPDFModal: React.FC<ReceivedProps> = (props) => {
  return <ExportPDFlayout {...useConfrimExportModal(props)} />;
};

export default ConfirmExportPDFModal;
