import React, { useEffect, useMemo, useState } from 'react';
import { Column, G2 } from '@ant-design/plots';

import Styled from './styles';
import { formatComma } from 'libs/utils/format';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { reportUserInformationSelector } from 'pages/Report/UserInformation/selectors';
import { uniq } from 'lodash';
import { EXPORT_TARGET_MONTH_COUNT } from 'constant';
import { AnyObject } from 'types';
import dayjs from 'dayjs';
import { setChartBusinessType } from 'pages/Report/UserInformation/slice';
import { useAppDispatch } from 'hooks';

const COMPANY_TYPE: AnyObject = {
  corporateUsers: {
    key: 'corporateUsers',
    label: '法人ユーザー',
    title: '法人ユー',
    color: '#4365BD',
  },
  personalUser: {
    key: 'personalUser',
    label: '個人ユーザー',
    title: '個人ユー',
    color: '#E5C82D',
  },
  partners: {
    key: 'partners',
    label: 'パートナーユーザー',
    title: 'パートナー',
    color: '#F0995A',
  },
};

interface IProps {
  exportPdf?: boolean;
}

const Development: React.FC<IProps> = ({ exportPdf }) => {
  const [type, setType] = useState<string | undefined>();
  const {
    chartBusinessType,
    dateRanger,
    companyReports,
    calculatedIndividualUsers,
    calculatedCorporateUsers,
    calculatedPartnerReports,
  } = useSelector(reportUserInformationSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (exportPdf) {
      setType(chartBusinessType || undefined);
    }
  }, [exportPdf, chartBusinessType]);

  useEffect(() => {
    if (exportPdf) {
      dispatch(setChartBusinessType(undefined));
    }
  }, [dispatch, exportPdf]);

  const dates = useMemo(() => {
    let dateData =
      dateRanger && dateRanger.length
        ? dateRanger
        : uniq(companyReports.map((obj) => obj.target_month));
    if (exportPdf) {
      dateData = dateData.slice(0, EXPORT_TARGET_MONTH_COUNT);
    }
    return dateData;
  }, [companyReports, dateRanger, exportPdf]);

  const G = G2.getEngine('canvas');
  const companiesData = useMemo(() => {
    let res: AnyObject[] = [];
    const monthEndUserIndividualUser = calculatedIndividualUsers?.month_end_users || {};
    const monthEndUserCorporateUser = calculatedCorporateUsers?.month_end_users || {};
    dates.forEach((date) => {
      res = [
        ...res,
        {
          month: dayjs(date, 'YYYYMM').format('YYYY/MM'),
          name: '個人ユーザー',
          value: monthEndUserIndividualUser[date] || 0,
        },
        {
          month: dayjs(date, 'YYYYMM').format('YYYY/MM'),
          name: '法人ユーザー',
          value: monthEndUserCorporateUser[date] || 0,
        },
      ];
    });
    return res;
  }, [dates, calculatedCorporateUsers, calculatedIndividualUsers]);

  const reportData = useMemo(() => {
    const reportType = type || 'partners';
    const calculator =
      reportType === COMPANY_TYPE.personalUser.key
        ? calculatedIndividualUsers
        : type === COMPANY_TYPE.corporateUsers.key
        ? calculatedCorporateUsers
        : calculatedPartnerReports;
    let res: AnyObject[] = [];
    const monthEndUser = calculator?.month_end_users || {};
    dates.forEach((date) => {
      res = [
        ...res,
        {
          month: dayjs(date, 'YYYYMM').format('YYYY/MM'),
          name: COMPANY_TYPE[reportType]?.label,
          value: monthEndUser[date] || 0,
        },
      ];
    });
    return res;
  }, [dates, calculatedCorporateUsers, calculatedIndividualUsers, type, calculatedPartnerReports]);

  return (
    <Styled>
      <div className="user-info">
        <div className="body-info">
          <div className="right-side">
            <div className="wrap-header">
              <div className="wrap-action">
                <div className="wrap-select">
                  <label>企業種類 : </label>
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    onChange={(v) => {
                      setType(v);
                      dispatch(setChartBusinessType(v));
                    }}
                    value={type}
                    placeholder="指定なし"
                    allowClear
                  >
                    {Object.keys(COMPANY_TYPE).map((item) => (
                      <Select.Option key={item} value={item}>
                        {COMPANY_TYPE[item].label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter blue" />
                    <span className="legend-name">法人ユーザー</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter yellow" />
                    <span className="legend-name">個人ユーザー</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter orange" />
                    <span className="legend-name">パートナーユーザー</span>
                  </div>
                </div>
              </div>
            </div>
            {!type && (
              <div className="chart">
                <div className="title">ユーザー数推移【法人・個人】</div>
                <div className="column">
                  <span className="unit">単位：人</span>
                  <Column
                    {...config1}
                    height={550}
                    data={companiesData}
                    legend={false}
                    yAxis={{
                      grid: {
                        line: {
                          style: {
                            lineDash: [2, 2],
                            stroke: '#ddd',
                          },
                        },
                      },
                      label: {
                        formatter: formatComma,
                      },
                    }}
                    xAxis={{
                      tickLine: null,
                    }}
                    label={{
                      position: 'top',
                      content: (v, mappingData) => {
                        const group = new G.Group({});
                        group.addShape({
                          type: 'text',
                          attrs: {
                            x: 0,
                            y: 0,
                            text: formatComma(v.value),
                            textAlign: 'left',
                            textBaseline: 'top',
                            fill: mappingData.color,
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            shadowColor: '#FFFFFF',
                          },
                        });
                        return group;
                      },
                      style: {
                        shadowOffsetX: 1,
                        shadowOffsetY: 1,
                        shadowColor: '#FFFFFF',
                      },
                    }}
                    scrollbar={{
                      type: 'horizontal',
                      width: 300,
                      categorySize: 70,
                    }}
                  />
                </div>
              </div>
            )}
            <span className="title">
              ユーザー数推移【{COMPANY_TYPE[type || 'partners']?.title || 'パートナー'}】
            </span>
            <div className="chart">
              <div className="column">
                <span className="unit">単位：人</span>
                <Column
                  className="column-chart"
                  {...configColumn}
                  data={reportData}
                  color={COMPANY_TYPE[type || 'partners'].color}
                  legend={false}
                  height={550}
                  yAxis={{
                    grid: {
                      line: {
                        style: {
                          lineDash: [2, 2],
                          stroke: '#ddd',
                        },
                      },
                    },
                    label: {
                      formatter: formatComma,
                    },
                  }}
                  xAxis={{
                    tickLine: null,
                  }}
                  label={{
                    position: 'top',
                    style: {
                      fill: COMPANY_TYPE[type || 'partners'].color,
                      opacity: 0.6,
                      fontSize: 13,
                    },
                  }}
                  scrollbar={{
                    type: 'horizontal',
                    width: 300,
                    categorySize: 70,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default Development;

const configColumn = {
  data: [],
  xField: 'month',
  yField: 'value',
  seriesField: 'name',
  maxColumnWidth: 25,
  dodgePadding: 4,
  color: '#F0995A',
};

const config1 = {
  data: [],
  isGroup: true,
  xField: 'month',
  yField: 'value',
  seriesField: 'name',
  maxColumnWidth: 25,
  dodgePadding: 4,
  color: ['#E5C82D', '#4365BD'],
};
