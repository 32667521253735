import React, { memo, useCallback, useEffect, useMemo } from 'react';
import * as Types from 'types';
import { AnyObject, OfficialCurriculumnAggregationData } from 'types';
import TableOfficialCurriculumStyled from './styles';
import { useSelector } from 'react-redux';
import { reportSelector } from 'pages/Report/selectors';
import { SpinLoading } from 'components';
import { unionBy } from 'lodash';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { nanoid } from '@reduxjs/toolkit';
import { ColumnDef } from '@tanstack/react-table';
import VirtualizedTable from 'components/VirtualizedTable';
import { Conditions } from '..';
import { COMPANY_TYPES_OBJECT } from 'constant';

interface Props {
  activeTable: boolean;
  pagination: Types.IPagination;
  data: tableObject;
  visibleProducerExpand: boolean;
  visibleCurriculumDevelopment: boolean;
  conditions: Conditions;
}

export interface Group {
  RSTANDARD: Array<OfficialCurriculumnAggregationData>;
  全企業: Array<OfficialCurriculumnAggregationData>;
  パートナー企業: Array<OfficialCurriculumnAggregationData>;
}

export interface dataDate {
  date: string;
  title: string;
}

export const convertToFormattedDate = (input: string): string => {
  const year = input.slice(0, 4);
  const month = input.slice(4, 6);
  return `${year}/${month}`;
};

export const convertToFormattedDateForField = (input: string): string => {
  const year = input.slice(0, 4);
  const month = input.slice(4, 6);
  return `${year}${month}`;
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dddddd;
  background: #ffffff;
  width: 100%;
  height: 250px;
  color: #777777;
  font-size: 16px;
  border-radius: 3px;
`;

export interface tableObject {
  RSTANDARD: Array<Types.OfficialCurriculumnAggregationData>;
  パートナー企業: Array<Types.OfficialCurriculumnAggregationData>;
}

const TableOfficialCurriculum: React.FC<Props> = ({
  visibleProducerExpand,
  visibleCurriculumDevelopment,
  activeTable,
  pagination,
  data,
  conditions,
}) => {
  const { loading, dataOfficialCurriculumUserAggregation, listMonths } =
    useSelector(reportSelector);
  const dataBeforSetPage = useMemo(() => {
    let arrayBeforeCaculation = [...data.RSTANDARD, ...data.パートナー企業];
    if (visibleProducerExpand) {
      arrayBeforeCaculation = [...data.パートナー企業, ...data.RSTANDARD];

      if (visibleCurriculumDevelopment) {
        arrayBeforeCaculation = [...data.RSTANDARD, ...data.パートナー企業];
      }
    }

    return arrayBeforeCaculation;
  }, [data, visibleProducerExpand, visibleCurriculumDevelopment]);

  const filterConditions = useCallback(
    (data: any[]) => {
      // 全企業
      if (conditions.company_type === COMPANY_TYPES_OBJECT.all_company.id) {
        return data.filter((item) => item.company_type === '全企業');
      }
      // RSTANDARD
      if (conditions.company_type === COMPANY_TYPES_OBJECT.rstandard.id) {
        return data.filter((item) => item.company_type === 'RSTANDARD');
      }
      // パートナー企業
      if (conditions.company_type === COMPANY_TYPES_OBJECT.partner.id) {
        return data.filter((item) => item.company_type === 'パートナー企業');
      }

      return data;
    },
    [conditions]
  );

  const dataColumn: ColumnDef<AnyObject>[] = useMemo(() => {
    return listMonths.map((date) => {
      return {
        header: date.title,
        accessorKey: date.date,
        size: 100,
        className: 'text-center',
        cell: (cell) => {
          const rowData = cell.row.original;
          return (
            <div className="column-text">
              <div className="text-right font-lato">{rowData?.[date.date] ?? 0}</div>
            </div>
          );
        },
      };
    });
  }, [listMonths]);

  useEffect(() => {
    const list = document.querySelectorAll('.ant-table-row-level-0');
    list.forEach((item: any, index: number) => {
      item.style.top = index * 40 + 'px';
    });
  }, [activeTable, visibleProducerExpand, visibleCurriculumDevelopment, data]);

  const DEFAULT_COLMUMNS: ColumnDef<AnyObject>[] = [
    {
      header: '企業種類',
      accessorKey: 'company_type',
      size: visibleCurriculumDevelopment ? 270 : 170,
      cell: (cell) => {
        const rowData = cell.row.original;
        return (
          <div className="column-text">
            {rowData.index ? <p className="index">{rowData.index}</p> : null}
            <div className="company_type">{rowData.company_type}</div>
          </div>
        );
      },
    },
    {
      header: 'カリキュラム数',
      accessorKey: 'open_curriculum',
      size: 130,
      cell: (cell) => {
        const rowData = cell.row.original;
        return (
          <div className="column-text">
            <div className="font-lato text-right">{rowData.open_curriculum}</div>
          </div>
        );
      },
    },
  ];

  const columns = [...DEFAULT_COLMUMNS, ...dataColumn];

  const showCurriculumn = (
    array: OfficialCurriculumnAggregationData[],
    field: 'active_number_of_usage' | 'number_of_usage',
    originArray: OfficialCurriculumnAggregationData[]
  ) => {
    const RSTANDARD = array
      .filter((item) => item.company_type === 'RSTANDARD')
      .map((item, index) => {
        const children = originArray
          .filter((item) => {
            return item.company_type === 'RSTANDARD' && item.publish === 2;
          })
          .map((item, index) => {
            const dateField = convertToFormattedDateForField(item.target_month);
            const value = item[field];
            return {
              ...item,
              company_type: item.curriculum_name,
              open_curriculum: null,
              index: index + 1,
              [dateField]: value,
            };
          });
        return {
          ...item,
          i_id: v4(),
          open_curriculum: data.RSTANDARD.length,
          children: children,
        };
      });

    const パートナー企業 = array
      .filter((item) => item.company_type === 'パートナー企業')
      .map((item, index) => {
        const children = originArray
          .filter((item) => {
            return item.company_type === 'パートナー企業' && item.publish === 2;
          })
          .map((item) => {
            const dateField = convertToFormattedDateForField(item.target_month);
            const value = item[field];
            return {
              ...item,
              company_type: item.company_name,
              [dateField]: value,
            };
          });

        const unionChild = unionBy(children, 'company_id')
          .map((item) => {
            const getList = children.filter((i) => i.company_id === item.company_id);
            const result = getList.reduce((acc: any, item: OfficialCurriculumnAggregationData) => {
              const dateField = convertToFormattedDateForField(item.target_month);
              const other = acc[`${dateField}`] ?? 0;
              const value = item[field] + other;
              return {
                ...item,
                ...acc,
                open_curriculum: getList.length,
                i_id: v4(),
                [dateField]: value,
              };
            }, {});
            return result;
          })
          .map((item, index) => {
            const child = children
              .filter((i) => {
                return i.company_id === item.company_id;
              })
              .map((i, index) => {
                return {
                  ...i,
                  index: index + 1,
                  company_type: i.curriculum_name ?? '',
                };
              });
            return {
              ...item,
              index: index + 1 + '.',
              children: child,
            };
          });

        return {
          ...item,
          open_curriculum: data.パートナー企業.length,
          i_id: v4(),
          children: unionChild,
        };
      });
    if (pagination.current > 1) {
      let arr: any[] = [];
      if (RSTANDARD[0]?.children?.length) {
        arr = [...RSTANDARD[0].children];
      }
      if (パートナー企業[0]?.children?.length) {
        arr = [...パートナー企業[0].children];
      }
      return arr;
    } else {
      const result = [
        ...array.filter((i) => i.company_type === '全企業'),
        ...RSTANDARD,
        ...パートナー企業,
      ];
      return result;
    }
  };

  const showingCompanyName = (
    array: OfficialCurriculumnAggregationData[],
    field: 'active_number_of_usage' | 'number_of_usage',
    originArray: OfficialCurriculumnAggregationData[]
  ) => {
    const RSTANDARD = array.filter((item) => item.company_type === 'RSTANDARD');
    const パートナー企業 = array
      .filter((item) => item.company_type === 'パートナー企業')
      .map((item) => {
        const children = originArray
          .filter((item) => {
            return item.company_type === 'パートナー企業' && item.publish === 2;
          })
          .map((item) => {
            const dateField = convertToFormattedDateForField(item.target_month);
            const value = item[field];
            return {
              ...item,
              company_type: item.company_name,
              [dateField]: value,
            };
          });

        const unionChild = unionBy(children, 'company_id').map((item, index) => {
          const getList = children.filter((i) => i.company_id === item.company_id);
          const result = getList.reduce((acc: any, item: OfficialCurriculumnAggregationData) => {
            const dateField = convertToFormattedDateForField(item.target_month);
            const other = acc[`${dateField}`] ?? 0;
            const value = item[field] + other;
            return {
              ...item,
              ...acc,
              open_curriculum: getList.length,
              index: index + 1,
              [dateField]: value,
            };
          }, {});
          return result;
        });

        return {
          ...item,
          open_curriculum: unionChild.reduce((acc, item) => acc + item.open_curriculum, 0),
          i_id: v4(),
          children: unionChild,
        };
      });
    if (pagination.current > 1) {
      if (パートナー企業[0]?.children) {
        const result = [...パートナー企業[0]?.children];
        return result;
      } else {
        return [];
      }
    } else {
      const result = [...RSTANDARD, ...パートナー企業];
      return result;
    }
  };

  const dataRender = (field: 'active_number_of_usage' | 'number_of_usage') => {
    const dataShow = [...dataBeforSetPage];

    let arrayBeforeCaculation = [...data.RSTANDARD, ...data.パートナー企業];
    if (visibleProducerExpand) {
      arrayBeforeCaculation = [...data.パートナー企業, ...data.RSTANDARD];

      if (visibleCurriculumDevelopment) {
        arrayBeforeCaculation = [...data.RSTANDARD, ...data.パートナー企業];
      }
    }

    const groupArray = arrayBeforeCaculation.reduce(
      (acc: Group, item) => {
        switch (item.company_type) {
          case 'RSTANDARD': {
            return {
              ...acc,
              RSTANDARD: [
                ...acc.RSTANDARD,
                {
                  ...item,
                  [convertToFormattedDateForField(item.target_month)]: item[field],
                },
              ],
              全企業: [
                ...acc.全企業,
                {
                  ...item,
                  i_id: nanoid(),
                  company_type: '全企業',
                  [convertToFormattedDateForField(item.target_month)]: item[field],
                },
              ],
            };
          }
          case '全企業': {
            return {
              ...acc,
              全企業: [
                ...acc.全企業,
                {
                  ...item,
                  [convertToFormattedDateForField(item.target_month)]: item[field],
                },
              ],
            };
          }
          default: {
            return {
              ...acc,
              パートナー企業: [
                ...acc.パートナー企業,
                {
                  ...item,
                  [convertToFormattedDateForField(item.target_month)]: item[field],
                },
              ],
              全企業: [
                ...acc.全企業,
                {
                  ...item,
                  i_id: nanoid(),
                  company_type: '全企業',
                  [convertToFormattedDateForField(item.target_month)]: item[field],
                },
              ],
            };
          }
        }
      },
      {
        RSTANDARD: [],
        全企業: [],
        パートナー企業: [],
      }
    );

    // case 1 group by "company_type"
    const RSTANDARD = groupArray.RSTANDARD.reduce(
      (acc: any, item: OfficialCurriculumnAggregationData) => {
        const dateField = convertToFormattedDateForField(item.target_month);
        const other = acc[`${dateField}`] ?? 0;
        const value = item[field] + other;
        return {
          ...item,
          ...acc,
          [dateField]: value,
        };
      },
      {}
    );

    const 全企業 = groupArray.全企業.reduce(
      (acc: any, item: OfficialCurriculumnAggregationData) => {
        const dateField = convertToFormattedDateForField(item.target_month);
        const other = acc[`${dateField}`] ?? 0;
        const value = item[field] + other;
        return {
          ...item,
          ...acc,
          company_type: '全企業',
          [dateField]: value,
        };
      },
      {}
    );

    const パートナー企業 = groupArray.パートナー企業.reduce(
      (acc: any, item: OfficialCurriculumnAggregationData) => {
        const dateField = convertToFormattedDateForField(item.target_month);
        const other = acc[`${dateField}`] ?? 0;
        const value = item[field] + other;
        return {
          ...item,
          ...acc,
          [dateField]: value,
        };
      },
      {}
    );

    let result: any = [];
    if (groupArray.RSTANDARD.length && 全企業) {
      result = [
        ...result,
        {
          ...全企業,
          open_curriculum: (data.RSTANDARD.length ?? 0) + (data.パートナー企業.length ?? 0),
        },
      ];
    }

    if (groupArray.RSTANDARD.length) {
      result = [
        ...result,
        {
          ...RSTANDARD,
          open_curriculum: data.RSTANDARD.length,
        },
      ];
    }

    if (groupArray.パートナー企業.length) {
      result = [
        ...result,
        {
          ...パートナー企業,
          open_curriculum: data.パートナー企業.length,
        },
      ];
    }

    if (visibleProducerExpand) {
      if (visibleCurriculumDevelopment) {
        const GetDataShowCurriculumn = showCurriculumn(result, field, dataShow);
        return filterConditions(GetDataShowCurriculumn);
      } else {
        const activeExpand = showingCompanyName(result, field, dataShow);
        const results = [
          {
            ...全企業,
            open_curriculum: data.RSTANDARD.length + data.パートナー企業.length,
          },
          ...activeExpand,
        ];
        return filterConditions(results);
      }
    } else {
      return filterConditions(result);
    }
  };

  if (
    !dataBeforSetPage.length &&
    !dataOfficialCurriculumUserAggregation.filter((i) => i.company_type === '全企業')
  ) {
    return (
      <>
        <Container>集計条件を選択してください</Container>
      </>
    );
  }

  return (
    <SpinLoading loading={loading}>
      <TableOfficialCurriculumStyled visibleCurriculumDevelopment={visibleCurriculumDevelopment}>
        <div className={`${visibleCurriculumDevelopment ? 'interleaved' : 'white'}`}>
          <VirtualizedTable
            data={
              activeTable ? dataRender('active_number_of_usage') : dataRender('number_of_usage')
            }
            columns={columns}
          />
        </div>
      </TableOfficialCurriculumStyled>
    </SpinLoading>
  );
};

export default memo(TableOfficialCurriculum);
