import React, { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { settingSelector } from 'containers/AppSettings/selectors';
import AuthorityMasterFrom from 'containers/AuthorityMasterForm';
import { CreateAuthorityMasterSchema } from 'libs/validations';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import AuthorityMasterCreateStyled from './styles';
import { routes } from 'navigations/routes';
import { createAdminRole } from '../thunk';
import { useAppDispatch } from 'hooks';
import { Header } from 'components';
import * as Types from 'types';
import useCheckModifiedPage from 'hooks/useCheckModifiedPage';

const AuthorityMasterCreate: React.FC = () => {
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);

  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const formik = useFormik<Types.CreateRolesMasterFormik>({
    initialValues: {
      name: '',
      admin_dashboard_permission: 1,
      sales_report_permission: 1,
      user_report_permission: 1,
      official_curriculum_report_permission: 1,
      skill_check_implementation_report_permission: 1,
      billing_management_permission: 1,
      official_curriculum_publish_permission: 1,
      curricullum_master_permission: 1,
      question_master_permission: 1,
      users_master_permission: 1,
      roles_master_permission: 1,
      departments_master_permission: 1,
      positions_master_permission: 1,
      admin_master_permission: 1,
      partner_management_permission: 1,
      curriculum_tree_permission: 1,
      corporate_user_permission: 1,
      invoice_correspondence_permission: 1,
      individual_user_management_permission: 1,
      admin_storage_permission: 1,
    },
    validationSchema: CreateAuthorityMasterSchema,
    validateOnBlur: false,
    onSubmit: async (values, { resetForm }) => {
      const resultAction = await dispatch(
        createAdminRole({
          item: {
            company_id: userInfo?.company_id,
            createdby: userInfo?.login_id,
            createdat: new Date(),
            ...values,
          },
        })
      );
      createAdminRole.fulfilled.match(resultAction)
        ? setShowCompleteModal(true)
        : setShowActionErrorModal(true);

      resetForm();
    },
  });
  useCheckModifiedPage(formik);
  return (
    <AuthorityMasterCreateStyled>
      <Header title={headerTitle} className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            権限マスタの新規登録を行う画面です。
            <br />
            権限名称を入力し、機能ごとに権限範囲を設定してください。
          </p>
        </div>
        <AuthorityMasterFrom formik={formik} />
      </div>
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="登録が完了しました"
        onSubmit={() =>
          navigate(generatePath(routes.AuthorityMaster.path, { entity: 'receiving' }))
        }
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle="新規権限の登録に失敗しました"
        description={
          <p className="text-content">
            新規権限の登録に失敗しました。
            <br />
            もう一度情報を入力し、再度お試しください。
          </p>
        }
      />
    </AuthorityMasterCreateStyled>
  );
};

export default AuthorityMasterCreate;
