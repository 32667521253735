import { Modal } from 'components';
import styled from 'styled-components';

export default styled(Modal)`
  border-radius: 4px;
  overflow: hidden;
  .title,
  .subtitle {
    text-align: center;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 29px;
    span {
      font-size: 18px;
      padding-left: 10px;
    }
    .icon {
      font-size: 27px;
      color: #bbbbbb;
    }
  }
  .subtitle {
    font-size: 13px;
  }
`;
