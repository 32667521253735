import React, { useState } from 'react';
import { Column, Line, G2 } from '@ant-design/plots';
import { Select } from 'antd';

import Styled from './styles';
import { useSelector } from 'react-redux';
import { reportSelector } from 'pages/Report/selectors';
import { sortBy, sumBy, uniq, uniqBy } from 'lodash';
import Item from 'containers/AuthorityMasterForm/Item';
import { SkillCheckInformationData } from 'types';

const { Option } = Select;

const convertToFormattedDate = (input: string): string => {
  const year = input.slice(0, 4);
  const month = input.slice(4, 6);
  return `${year}/${month}`;
};

interface Props {
  exportPdf: boolean;
}

const Corresponding: React.FC<Props> = ({ exportPdf }) => {
  const G = G2.getEngine('canvas');
  const { dataSkillCheckInformation, listMonths } = useSelector(reportSelector);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const data = dataSkillCheckInformation.filter((i) => i.user_type.includes(selectedValue));
  const dataLine = sortBy(
    data.map((item) => {
      return {
        year: item.target_month.slice(0, 4),
        value: item.number_of_skill_checks_performed,
      };
    }),
    (i) => {
      return parseFloat(i.year);
    }
  );
  const listYear = uniq(dataLine.map((item) => item.year));

  const dataLineApi = listYear.map((item) => {
    return {
      year: item,
      value: dataLine
        .filter((i) => i.year === item)
        .reduce((acc, item) => {
          return acc + item.value;
        }, 0),
    };
  });

  const newLines = [...listMonths]
    .map((item) => {
      return {
        year: item.title.slice(0, 4),
        value: 0,
      };
    })
    .filter((item) => {
      const check = listYear.every((i) => {
        return i !== item.year;
      });
      return !!check;
    });

  const config = {
    data: [...newLines, ...dataLineApi],
    xField: 'year',
    yField: 'value',
    legend: true,
    point: {
      size: 5,
      shape: 'circle',
      style: {
        fill: '#08A3A5',
        stroke: '#08A3A5',
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  };

  const dataColumn = sortBy(
    data.map((i: SkillCheckInformationData) => {
      return {
        name: i.user_type,
        month: convertToFormattedDate(i.target_month),
        value: i.number_of_skill_checks_performed,
        target_month: i.target_month,
      };
    }),
    (i) => {
      return parseFloat(i.target_month);
    }
  );

  const getDateOfDataColumn = uniq(
    dataColumn.map((item) => {
      return item.month;
    })
  );

  const newList = [...listMonths]
    .filter((item) => {
      const check = getDateOfDataColumn.every((i) => {
        return i !== item.title;
      });
      return !!check;
    })
    .map((item) => {
      return [
        {
          name: '社内ユーザー',
          month: item.title,
          value: 0,
          target_month: item.title,
        },
        {
          name: '面接ユーザー',
          month: item.title,
          value: 0,
          target_month: item.title,
        },
        {
          name: '全ユーザー',
          month: item.title,
          value: 0,
          target_month: item.title,
        },
      ];
    })
    .reduce((acc, item) => {
      return [...acc, ...item];
    }, []);

  const result = [...newList, ...dataColumn];
  const listDate = sortBy(
    uniqBy(result, (i) => {
      return i.month;
    }).map((item) => {
      return {
        month: item.month,
        value: sumBy(
          result.filter((r) => r.month === item.month),
          (i) => {
            return i.value;
          }
        ),
      };
    }),
    (i) => parseFloat(i.month.replace('/', ''))
  );

  const configColumn = {
    data: listDate,
    xField: 'month',
    yField: 'value',
    maxColumnWidth: 25,
    dodgePadding: 4,
    color: '#F6AC00',
  };

  return (
    <Styled>
      <div className="user-info">
        <div className="body-info">
          <div className="right-side">
            <div className="wrap-header">
              <div className="wrap-action">
                <div className="wrap-select">
                  <label>ユーザー種類選択: </label>
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    defaultValue=""
                    onChange={(e) => {
                      setSelectedValue(e);
                    }}
                  >
                    <Option value="">ALL</Option>
                    <Option value="全ユ"> 全ユーザー</Option>
                    <Option value="社内"> 社内ユーザー</Option>
                    <Option value="面接ユーザー"> 面接ユーザー</Option>
                  </Select>
                </div>
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter blue">
                      <div className="circle" />
                      <div className="line" />
                    </div>
                    <span className="legend-name">売上</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter yellow" />
                    <span className="legend-name">実施回数</span>
                  </div>
                </div>
              </div>
            </div>
            <span className="title">売上推移</span>
            <div className="chart">
              <div className="line">
                <span className="unit">単位：円</span>
                <Line
                  className="line-chart"
                  {...config}
                  height={exportPdf ? 400 : 200}
                  label={{
                    position: 'top',
                    style: {
                      fill: '#08A3A5',
                    },
                  }}
                  lineStyle={{
                    stroke: '#08A3A5',
                  }}
                  legend={{
                    layout: 'horizontal',
                    position: 'top-right',
                  }}
                  yAxis={{
                    grid: {
                      line: {
                        style: {
                          stroke: '#ddd',
                          lineDash: [2, 2],
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="chart">
              <span className="title">実施回数推移</span>
              <div className="column">
                <span className="unit">単位：回</span>
                <Column
                  className="column-chart"
                  {...configColumn}
                  legend={false}
                  yAxis={{
                    grid: {
                      line: {
                        style: {
                          lineDash: [2, 2],
                          stroke: '#ddd',
                        },
                      },
                    },
                  }}
                  xAxis={{
                    tickLine: null,
                  }}
                  label={{
                    position: 'top',
                    style: {
                      fill: '#F6AC00',
                      opacity: 0.6,
                      fontSize: 13,
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default Corresponding;
