import React, { useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { SectionStyled } from './styles';
import { Modal } from 'components';

interface Props {
  visible: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  description?: React.ReactNode;
  onCancel?: () => void | Promise<void>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmDeleteModal: React.FC<Props> = ({
  title,
  visible,
  subTitle,
  onCancel,
  setVisible,
  description,
}) => {
  const [, setLoading] = useState<boolean>(false);

  const handleCloseModal = async () => {
    setLoading(true);
    onCancel && (await onCancel());
    setLoading(false);
    setVisible(false);
  };

  return (
    <Modal
      title={title}
      visible={visible}
      width={720}
      onCancel={handleCloseModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
        color: '#2a2a2a',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      cancelButton={{
        text: '閉じる',
        onClick: handleCloseModal,
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          <ExclamationCircleOutlined className="icon" />
          {subTitle}
        </p>
        <p className="text-content">{description}</p>
      </SectionStyled>
    </Modal>
  );
};

export default ConfirmDeleteModal;
