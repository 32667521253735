import React, { useEffect, useRef, useState } from 'react';
import { UploadChangeParam, UploadFile as UploadFileAntd } from 'antd/lib/upload/interface';
import { Image as ImageAntd, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import {
  CloudUploadOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';

import { UploadFileFieldType } from 'types/services/curriculum';
import { FileType } from 'constant/enum.constant';
import { SectionStyled } from './styles';
import { Modal } from 'components';
import { NoImage } from 'assets';

interface Props {
  visible: {
    show: boolean;
    data: Array<UploadFileAntd<File>>;
    type: UploadFileFieldType;
  };
  setVisible: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      data: Array<UploadFileAntd<File>>;
      type: UploadFileFieldType;
    }>
  >;
  onSubmit: (data: Array<UploadFileAntd<File>>, fieldType: UploadFileFieldType) => void;
}

const { Dragger } = Upload;

const UploadImageVideo: React.FC<Props> = ({ visible, setVisible, onSubmit }) => {
  const [files, setFiles] = useState<Array<UploadFileAntd<File>>>([]);
  const [isShowImage, setIsShowImage] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);

  const vidRef = useRef<HTMLVideoElement>(null);

  const handleClose = () => {
    setVisible({ ...visible, show: false });
    vidRef.current?.pause();
  };

  const handleDelete = () => {
    setFiles((prevState) => prevState.filter((_item, i) => i !== index));
    setIndex((prevState) => (prevState > 0 ? prevState - 1 : 0));
  };

  const handleSubmit = () => {
    handleClose();
    onSubmit(files, visible.type);
  };

  const beforeUpload = async (_: RcFile, FileList: RcFile[]) => {
    for (const fileElement of FileList) {
      const file = fileElement;

      const isJpgOrPngOrMp4 =
        file.type === FileType.PNG || file.type === FileType.JPEG || file.type === FileType.MP4;

      const isAccept = await new Promise<boolean>((resolve) => {
        const src = URL.createObjectURL(new Blob([file]));
        if (file.type === FileType.PNG || file.type === FileType.JPEG) {
          const image = new Image();
          image.src = src;
          image.onload = function () {
            URL.revokeObjectURL(src);
            resolve(true);
          };
        } else {
          const video = document.createElement('video');
          video.onloadedmetadata = () => {
            URL.revokeObjectURL(src);
            resolve(true);
          };
          video.src = src;
          video.load();
        }
      });

      if (!isJpgOrPngOrMp4 || !isAccept) {
        alert(`※画像はjpegまたはpngである必要があり。
ビデオはmp4である必要があり。`);
      }

      return isJpgOrPngOrMp4 && isAccept ? false : Upload.LIST_IGNORE;
    }
  };

  useEffect(() => {
    if (visible.show && visible.type !== 'problems2_attach') {
      setFiles(visible.data);
    }
    return () => {
      setFiles([]);
      setIsShowImage(false);
      setIndex(0);
    };
  }, [visible]);

  return (
    <Modal
      title="画像・動画アップロード"
      width={720}
      open={visible.show && visible.type !== 'problems2_attach'}
      onCancel={handleClose}
      okButton={{
        text: '登録',
        onClick: handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleClose,
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        <div className="form-upload">
          {files && files.length > 1 ? (
            <>
              <LeftOutlined
                className="icon-prev"
                onClick={() => {
                  setIndex((prevState) => (prevState === 0 ? files.length - 1 : prevState - 1));
                  vidRef.current?.pause();
                }}
              />
              <RightOutlined
                className="icon-next"
                onClick={() => {
                  setIndex((prevState) => (prevState === files.length - 1 ? 0 : prevState + 1));
                  vidRef.current?.pause();
                }}
              />
            </>
          ) : null}
          <div className="file-upload">
            <div className="flex">
              <Dragger
                name="file"
                accept=".jpeg, .png, .mp4"
                multiple
                maxCount={visible.type?.includes('option') ? 1 : 3}
                beforeUpload={beforeUpload}
                fileList={files}
                onChange={(info: UploadChangeParam<UploadFileAntd<File>>) => {
                  setFiles(info.fileList);
                }}
              >
                {files.length > 0 ? (
                  <div className="info-file" onClick={(e) => e.stopPropagation()}>
                    <p className="name-image">{files[index].name.split(',')[index]}</p>
                    {files[index].type === FileType.MP4 ? (
                      <video ref={vidRef} width="100%" height={338} controls>
                        <source
                          src={URL.createObjectURL(new Blob([files[index].originFileObj as Blob]))}
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      <ImageAntd
                        onClick={(e) => e.stopPropagation()}
                        className="image-file"
                        src={URL.createObjectURL(new Blob([files[index].originFileObj as Blob]))}
                        alt={files[index].name}
                        preview={{
                          visible: isShowImage,
                          src: URL.createObjectURL(new Blob([files[index].originFileObj as Blob])),
                          onVisibleChange: (value) => {
                            setIsShowImage(value);
                          },
                          mask: (
                            <ZoomInOutlined
                              className="zoom-image"
                              onClick={() => setIsShowImage(!isShowImage)}
                            />
                          ),
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    <CloudUploadOutlined className="icon" />
                    <p className="ant-upload-text">
                      アップロードするファイルをここにドロップ
                      <br />
                      または
                    </p>
                  </>
                )}
                <div className="wrap-button-upload">
                  <button type="button" className="btn-upload">
                    {files[index] ? 'ファイルを変更' : 'ファイルを選択'}
                  </button>
                  {files.length > 0 ? (
                    <button
                      className="btn-delete"
                      type="button"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDelete();
                      }}
                    >
                      <DeleteOutlined className="icon-delete-outlined" />
                      <span className="text-delete-outlined">ファイルを削除</span>
                    </button>
                  ) : null}
                </div>
              </Dragger>
            </div>
          </div>
        </div>
        <div className="flex-image-small">
          {files &&
            files.length > 1 &&
            files.map((item, i) => (
              <div
                key={i}
                className={`image ${i === index ? 'image_active' : ''}`}
                onClick={() => setIndex(i)}
              >
                {item.type === FileType.MP4 ? (
                  <div className="no-image">
                    <img src={NoImage} className="icon" alt="NoImage" />
                    <span className="text-no-image">No Image</span>
                  </div>
                ) : (
                  <img
                    src={URL.createObjectURL(new Blob([item.originFileObj as Blob]))}
                    alt={item.name}
                    className="image-small"
                  />
                )}
              </div>
            ))}
        </div>
        <p className="text-content">
          ※ファイルはまとめて３つまでアップロード可能です。
          <br />
          ※画像は16:9でjpegまたはpngファイルがアップロードできます。
          <br />
          ※動画は16:9でmp4ファイルがアップロードできます。
          <br />
        </p>
      </SectionStyled>
    </Modal>
  );
};

export default UploadImageVideo;
