import React, { memo, useEffect, useMemo } from 'react';
import { DualAxes, G2 } from '@ant-design/plots';
import { PayCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { formatNumberNoDecimal } from 'libs/numberFormatter';
import { dashboardSelector } from '../selectors';
import { getDataSalesInformation } from '../thunk';
import { USER_TYPE } from 'constant/enum.constant';
import { COORDINATELINEPATH } from 'constant';
import { useAppDispatch } from 'hooks';
import Styled from './styles';
import { isEmpty } from 'lodash';
import { IGroup } from '@ant-design/charts';

const SaleInfomation: React.FC<{ isRenderPdf?: boolean }> = ({ isRenderPdf }) => {
  const { dataSalesTrendsDual, dataSalesTrendsLine, dataCountUser } =
    useSelector(dashboardSelector);
  const dispatch = useAppDispatch();

  const G = G2.getEngine('canvas');

  const filterDataTrendLine = useMemo(() => {
    const twelveMonthsAgo = dayjs().subtract(11, 'months').format('YYYYMM');
    const currentMonth = dayjs().format('YYYYMM');
    return dataSalesTrendsLine.filter(
      (item) => item.time >= twelveMonthsAgo && item.time <= currentMonth
    );
  }, [dataSalesTrendsLine]);

  const filterDataTrendDual = useMemo(() => {
    const twelveMonthsAgo = dayjs().subtract(11, 'months').format('YYYYMM');
    const currentMonth = dayjs().format('YYYYMM');
    return dataSalesTrendsDual.filter(
      (item) => item.time >= twelveMonthsAgo && item.time <= currentMonth
    );
  }, [dataSalesTrendsDual]);

  const {
    dataMonthlyUsageFee,
    dataUsage,
    dataSkillCheckUsageFee,
    dataPreviosMonthSale,
    dataSaleForSameMonthLastYear,
  } = useMemo(() => {
    const dataMonthlyUsageFee = dataSalesTrendsDual.find(
      ({ time, type }) => dayjs().format('YYYYMM') === time && type === 'ユーザー利用料'
    );
    const dataUsage = dataSalesTrendsDual.find(
      ({ time, type }) => dayjs().format('YYYYMM') === time && type === 'ストレージ利用料'
    );
    const dataSkillCheckUsageFee = dataSalesTrendsDual.find(
      ({ time, type }) => dayjs().format('YYYYMM') === time && type === 'スキルチェック利用料'
    );
    const dataPreviosMonthSale = dataSalesTrendsDual.find(
      ({ time, type }) =>
        dayjs().subtract(1, 'month').format('YYYYMM') === time && type === '合計売上'
    );
    const dataSaleForSameMonthLastYear = dataSalesTrendsDual.find(
      ({ time, type }) =>
        dayjs().subtract(1, 'year').format('YYYYMM') === time && type === '合計売上'
    );
    return {
      dataMonthlyUsageFee: dataMonthlyUsageFee?.value ?? 0,
      dataUsage: dataUsage?.value ?? 0,
      dataSkillCheckUsageFee: dataSkillCheckUsageFee?.value ?? 0,
      dataPreviosMonthSale: dataPreviosMonthSale?.value ?? 0,
      dataSaleForSameMonthLastYear: dataSaleForSameMonthLastYear?.value ?? 0,
    };
  }, [dataSalesTrendsDual]);

  const dataTotalSale = dataMonthlyUsageFee + dataSkillCheckUsageFee + dataUsage || 0;

  useEffect(() => {
    if (isRenderPdf) return;
    dispatch(
      getDataSalesInformation({
        conditions: [
          {
            id: 'user_type',
            search_value: [USER_TYPE['全ユーザー']],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, isRenderPdf]);

  const config = {
    data: [filterDataTrendLine, filterDataTrendDual],
    xField: 'time',
    yField: ['count', 'value'],
  };

  return (
    <Styled>
      <div className="user-info">
        <div className="wrap-icon">
          <PayCircleOutlined className="icon" /> 売上情報
        </div>
        <div className="body-info">
          <div className="top-info">
            <div className="top-detail wrap-column-1">
              <span className="title-info">リアルタイム売上</span>
              <div className="wrap-number">
                <span className="number">{formatNumberNoDecimal(dataTotalSale)}</span>
                <span className="unit">円</span>
              </div>
              <div className="wrap-bottom-detail">
                <div>
                  <span className="label">前月比：</span>
                  <span className="percent">
                    {!!(dataPreviosMonthSale && dataTotalSale) ? (
                      <>{Math.floor((dataTotalSale / (dataPreviosMonthSale || 1)) * 100)}%</>
                    ) : (
                      '-'
                    )}
                  </span>
                </div>
                <div>
                  <span className="label">前年比：</span>
                  <span className="percent">
                    {!!(dataSaleForSameMonthLastYear && dataTotalSale) ? (
                      <>
                        {Math.floor((dataTotalSale / (dataSaleForSameMonthLastYear || 1)) * 100)}%
                      </>
                    ) : (
                      '-'
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="top-detail wrap-column-2">
              <div className="title-column-2">売上内訳</div>
              <div className="content-column-2">
                <div className="left-side-2">
                  <div>
                    <span className="title-info">ユーザー利用料</span>
                    <div className="wrap-number">
                      <span className="number">
                        {formatNumberNoDecimal(dataMonthlyUsageFee || 0)}
                      </span>
                      <span className="unit">円</span>
                    </div>
                  </div>
                  <div>
                    <span className="title-info">ストレージ利用料</span>
                    <div className="wrap-number">
                      <span className="number">{formatNumberNoDecimal(dataUsage || 0)}</span>
                      <span className="unit">円</span>
                    </div>
                  </div>
                </div>
                <div className="divide" />
                <div className="right-side-2">
                  <span className="title-info">スキルチェック利用料</span>
                  <div className="wrap-number">
                    <span className="number">
                      {formatNumberNoDecimal(dataSkillCheckUsageFee || 0)}
                    </span>
                    <span className="unit">円</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrap-column-3">
              <div className="top-detail">
                <span className="title-info">前月売上</span>
                <div className="wrap-number">
                  <span className="number">{formatNumberNoDecimal(dataPreviosMonthSale)}</span>
                  <span className="unit">円</span>
                </div>
              </div>
              <div className="top-detail">
                <span className="title-info">前年同月売上</span>
                <div className="wrap-number">
                  <span className="number">
                    {formatNumberNoDecimal(dataSaleForSameMonthLastYear)}
                  </span>
                  <span className="unit">円</span>
                </div>
              </div>
            </div>
            <div className="top-detail wrap-column-4">
              <span className="title-info">
                ARPU<span className="note-title">（１人あたり平均売上）</span>
              </span>
              <div className="wrap-number">
                <span className="number">
                  {formatNumberNoDecimal(dataTotalSale / (dataCountUser || 1))}
                </span>
                <span className="unit">円</span>
              </div>
            </div>
          </div>
          <div className="column" />
          {!isEmpty(...dataSalesTrendsLine, ...dataSalesTrendsDual) && (
            <div className="right-side">
              <div className="wrap-header">
                <span className="title">売上推移 </span>
              </div>
              <div className="chart">
                <span className="unit">単位：千円</span>
                <DualAxes
                  className="dual-chart"
                  {...config}
                  tooltip={{
                    title: (text: string) => {
                      return text.slice(0, 4) + '/' + text.slice(4, 6);
                    },
                    customItems: (originalItems) => {
                      return originalItems.map((item) => ({
                        ...item,
                        value: formatNumberNoDecimal(Number(item.value)),
                      }));
                    },
                  }}
                  legend={{
                    position: 'top-right',
                    offsetY: 0,
                    itemHeight: 10,
                    itemName: {
                      style(item) {
                        return {
                          fill: '#424242',
                          fontSize: 12,
                          fontWeight: 400,
                          fontFamily: 'Noto Sans Javanese',
                          x: item?.id === '前年比' ? 23 : 22,
                        };
                      },
                    },
                  }}
                  yAxis={[
                    {
                      label: {
                        formatter: (val: string) => `${val}%`,
                      },
                      grid: {
                        line: {
                          style: (_item, index, items) => {
                            const midIndex = Math.floor(items.length / 2);
                            if (midIndex === index) {
                              return {
                                stroke: '#ddd',
                              };
                            }
                            return {
                              lineDash: [4, 4],
                              stroke: '#ddd',
                            };
                          },
                        },
                      },
                      position: 'right',
                      nice: true,
                      tickCount: 11,
                    },
                    {
                      nice: true,
                      label: {
                        formatter: (text: string) => formatNumberNoDecimal(Number(text)),
                      },
                      position: 'left',
                      tickCount: 11,
                    },
                  ]}
                  xAxis={{
                    tickLine: null,
                    label: {
                      formatter: (text: string) => {
                        return text.slice(0, 4) + '/' + text.slice(4, 6);
                      },
                    },
                  }}
                  // color={['#EC4D7C', '#C38BC2', '#4E83D3', '#1AC199']}
                  label={{
                    position: 'top',
                  }}
                  height={550}
                  // padding={[50, 20, 10, 35]}

                  geometryOptions={[
                    {
                      geometry: 'line',
                      seriesField: 'name',
                      lineStyle: {
                        lineWidth: 2,
                      },
                      point: {},
                      isStack: true,
                      color: '#669CCE',
                      legend: {
                        marker: {
                          symbol: () => COORDINATELINEPATH,
                          style(style) {
                            return {
                              ...style,
                              fill: style.stroke,
                              y: 70,
                              lineWidth: 0.75,
                            };
                          },
                        },
                      },
                    },
                    {
                      geometry: 'column',
                      isGroup: true,
                      seriesField: 'type',
                      columnWidthRatio: 0.4,
                      marginRatio: 0.2,
                      color: ['#08A3A5', '#F6BEED', '#F6AC00', '#C3DC7B'],
                      label: {
                        position: 'top',
                        offsetY: 0,
                        offsetX: 0,
                        style: {
                          transform: 'rotate(12deg)',
                        },
                        // autoRotate: true,
                        formatter: (data) =>
                          !data.value ? '' : formatNumberNoDecimal(Number(data.value)),
                        content: (v) => {
                          const group = new G.Group({}) as IGroup;
                          group.addShape({
                            type: 'text',
                            attrs: {
                              text: !v?.value ? '' : formatNumberNoDecimal(Number(v?.value || 0)),
                              autoRotate: true,
                              fill:
                                v?.type === 'ユーザー利用料'
                                  ? '#08A3A5'
                                  : v?.type === 'ストレージ利用料'
                                  ? '#F6BEED'
                                  : v?.type === 'スキルチェック利用料'
                                  ? '#F6AC00'
                                  : '#C3DC7B',
                            },
                          });
                          const renderTranslate = (type?: string) =>
                            type === 'ユーザー利用料'
                              ? 20
                              : type === 'ストレージ利用料'
                              ? 15
                              : type === 'スキルチェック利用料'
                              ? 10
                              : 0;
                          group.translate(0, +v?.value > 1000000 ? 20 : renderTranslate(v?.type));
                          group.rotate(-0.2);
                          return group;
                        },
                      },
                      legend: {
                        marker: () => {
                          return {
                            symbol: 'square',
                            style(style) {
                              return {
                                ...style,
                                r: 7,
                                // y: 36,
                              };
                            },
                          };
                        },
                        radio: {
                          style: {
                            fill: '#ffffff',
                            stroke: '#ffffff',
                          },
                        },
                      },
                    },
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Styled>
  );
};

export default memo(SaleInfomation);
