import React from 'react';

import RegisteredUsersPerMonth from './RegisteredUsersPerMonth';
import GraphNonExpansion from './GraphNonExpansion';
import GraphExpansion from './GraphExpansion';
import Development from './Development';
import styled from 'styled-components';

interface Props {
  exportPdf?: boolean;
  visibleExpansionOfSale: boolean;
  valueSelect?: number;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dddddd;
  background: #ffffff;
  width: 100%;
  height: 250px;
  color: #777777;
  font-size: 16px;
  border-radius: 3px;
`;

const Chart: React.FC<Props> = ({ visibleExpansionOfSale, valueSelect, exportPdf }) => {
  if (valueSelect === undefined) {
    return <Container>集計条件を選択してください</Container>;
  }
  return visibleExpansionOfSale && [0, 1].includes(valueSelect) ? (
    <GraphExpansion valueSelect={valueSelect} exportPdf={exportPdf} />
  ) : valueSelect === 0 ? (
    <Development exportPdf={exportPdf} />
  ) : valueSelect === 1 ? (
    <GraphNonExpansion exportPdf={exportPdf} />
  ) : valueSelect === 2 ? (
    <RegisteredUsersPerMonth exportPdf={exportPdf} />
  ) : (
    <Container>集計条件を選択してください</Container>
  );
};

export default Chart;
