import { View, Text } from '@react-pdf/renderer';
import styles from './styles';
import React from 'react';
import { tab } from '@testing-library/user-event/dist/tab';

interface ITextBoxProps {
  tabs: { key: string; label: string }[];
  activeKey: string;
}

const Tabs = (props: ITextBoxProps) => {
  const { tabs, activeKey } = props;

  return (
    <View style={[styles.tabs]} wrap={false}>
      {tabs.map(({ key, label }) => (
        <View key={key} style={[styles.tab, key === activeKey ? styles.active : {}]}>
          <Text style={[styles.labelText, key === activeKey ? styles.labelActive : {}]}>
            {label}
          </Text>
        </View>
      ))}
    </View>
  );
};

export default Tabs;
