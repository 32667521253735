import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CaretLeftOutlined, CaretRightOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Form } from 'formik-antd';
import { Button } from 'antd';
import { asyncPDFGenerator } from 'libs/utils/exportPDF';
import {
  useJoditTranslateMutationObserver,
  JODIT_CUSTOM_LOCALIZATION,
} from 'libs/utils/translateJodit';
import { StopPublic, TickComplete, UnPublished, WhiteEditing } from 'assets';
import { authSelector } from 'containers/Auth/selectors';
import Completed from 'components/Modal/Completed';
import useImagePreview from 'hooks/usePreview';
import { Input, TextArea } from 'components';
import { routes } from 'navigations/routes';
import UpdateStatus from '../UpdateStatus';
import ReleaseNoteStyled from './styles';
import { useAppDispatch } from 'hooks';
import Published from '../Published';
import { createMarkup } from 'libs';
import * as Types from 'types';
import PopupConfirmExportFile from '../ConfirmExportFile';
import { nanoid } from '@reduxjs/toolkit';
import { convertBreakTwoColumn } from 'libs/utils/convertBreakTwoColumn';
import { loadingRef } from 'components/Loading';

interface Props {
  selected?: Types.AdminReleaseNoteManagement.ResponseType;
  publish: number;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  publishOfficial: Types.OfficialCurriculumPublishingSettings.ResponseType;
  releaseNoteDetail: Types.RelaseNoteFormik | undefined;
}

const ReleaseNote: React.FC<Props> = ({
  visible,
  setVisible,
  publish,
  selected,
  publishOfficial,
  releaseNoteDetail,
}) => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [visiblePublished, setVisiblePublished] = useState<boolean>(false);
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
  const [indexSection, setIndexSection] = useState<number>(0);
  const { userInfo } = useSelector(authSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let ref = useRef<any | null>(null);
  const { handlePreviewImage, ImagePreview } = useImagePreview();

  const converSource = (str: string) => {
    const newBaseURL = `https://${process.env.REACT_APP_MINIO_END_POINT}/skillfamiliar/`;
    const updatedStr = str.replace(
      /https:\/\/api\.hexabase\.com\/api\/v0\/applications\/skillfamiliar\/functions\/presignedurl\?param=/g,
      newBaseURL
    );

    return updatedStr;
  };

  const convertData: Types.RelaseNoteFormik | undefined = useMemo(() => {
    if (releaseNoteDetail) {
      const sections = releaseNoteDetail.sections;
      const mapSection: Array<{
        i_id?: string;
        section_name: string;
        text: string;
      }> = sections.map((section: { i_id?: string; section_name: string; text: string }) => {
        return {
          ...section,
          text: converSource(section.text),
        };
      });

      return {
        ...releaseNoteDetail,
        sections: mapSection,
      };
    } else {
      return releaseNoteDetail;
    }
  }, [releaseNoteDetail]);

  const formik = useFormik<Types.RelaseNoteFormik>({
    initialValues: convertData ?? {
      title: '',
      description: '',
      version: 1.0,
      official_curriculum_code: '',
      release_note_id: '',
      release_schedule_information: '',
      sections: [
        {
          section_name: '',
          text: '',
        },
      ],
    },
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const extractContent = (s: string) => {
    var span = document.createElement('p');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  const handleToggleModal = () => {
    setVisible(false);
  };

  // useEffect(() => {
  //   if (userInfo && visible) {
  //     dispatch(
  //       getReleaseNoteDetail({
  //         conditions: [
  //           {
  //             id: 'company_id',
  //             search_value: [userInfo.company_id],
  //           },
  //           {
  //             id: 'release_note_id',
  //             search_value: [selected?.release_note_id],
  //           },
  //         ],
  //         page: 1,
  //         per_page: 0,
  //       })
  //     );

  //     console.log('_________________');
  //   }
  // }, [dispatch, visible, userInfo, selected]);

  const array = formik.values.sections ?? [
    {
      text: '',
    },
  ];

  const handleExportCSV = async (value: string) => {
    if (ref.current) {
      loadingRef.current?.isLoading(true);
      await new Promise(async () => {
        const syncFunc = async () => {
          const token = localStorage.getItem('sk_access_token');
          const imgElements = Array.from(ref.current.querySelectorAll('img')) as HTMLImageElement[];
          const imgProcessingPromises = imgElements.map(async (imgElement) => {
            const imgSrc = imgElement.getAttribute('src');
            if (imgSrc && imgSrc.startsWith('https://api.hexabase.com/api/')) {
              try {
                const response = await fetch(imgSrc, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
                if (!response.ok) {
                  throw new Error('Error fetching image');
                }

                const blob = await response.blob();
                const reader = new FileReader();

                const base64Image = await new Promise<string>((resolve, reject) => {
                  reader.onloadend = () => resolve(reader.result as string);
                  reader.onerror = reject;
                  reader.readAsDataURL(blob);
                });

                imgElement.src = base64Image;
                imgElement.width = 300;
              } catch (error) {
                console.error('Error loading image:', error);
              }
            }
          });

          const videoElements = Array.from(
            ref.current.querySelectorAll('video')
          ) as HTMLVideoElement[];

          const videoProcessingPromises = videoElements.map(async (videoElement) => {
            const sourceElement = videoElement.querySelector('source');

            if (sourceElement) {
              const videoSrc = sourceElement.getAttribute('src');

              if (videoSrc) {
                try {
                  const response = await fetch(videoSrc, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
                  if (!response.ok) {
                    throw new Error('Error fetching video');
                  }

                  const videoBlobUrl = response.url;
                  const video = document.createElement('video');
                  const canvas = document.createElement('canvas');
                  const context = canvas.getContext('2d');

                  if (!context) {
                    throw new Error('Unable to get canvas context');
                  }

                  video.src = videoBlobUrl;
                  video.crossOrigin = 'anonymous';
                  video.muted = true;
                  var isPlaying =
                    video.currentTime > 0 &&
                    !video.paused &&
                    !video.ended &&
                    video.readyState > video.HAVE_CURRENT_DATA;

                  if (!isPlaying) {
                    video.play();
                  }

                  await new Promise<void>((resolve) => {
                    video.addEventListener('loadeddata', () => {
                      canvas.width = video.videoWidth;
                      canvas.height = video.videoHeight;

                      context.drawImage(video, 0, 0, canvas.width, canvas.height);

                      const playButtonRadius = Math.min(canvas.width, canvas.height) / 12;
                      const centerX = canvas.width / 2;
                      const centerY = canvas.height / 2;

                      context.fillStyle = 'rgba(255, 255, 255, 0.8)';
                      context.beginPath();
                      context.arc(centerX, centerY, playButtonRadius, 0, Math.PI * 2);
                      context.fill();

                      context.fillStyle = 'rgba(0, 0, 0, 0.8)';
                      context.beginPath();
                      const triangleHeight = (playButtonRadius * Math.sqrt(3)) / 2;
                      context.moveTo(centerX - playButtonRadius / 4, centerY - triangleHeight / 2);
                      context.lineTo(centerX - playButtonRadius / 4, centerY + triangleHeight / 2);
                      context.lineTo(centerX + playButtonRadius / 2, centerY);
                      context.closePath();
                      context.fill();

                      const base64Image = canvas.toDataURL('image/png');
                      const imgElement = document.createElement('img');
                      imgElement.src = base64Image;
                      imgElement.width = 400;
                      imgElement.height = 345;

                      videoElement.replaceWith(imgElement);

                      video.pause();
                      URL.revokeObjectURL(video.src);

                      resolve();
                    });
                  });
                } catch (error) {
                  console.error('Error loading video:', error);
                }
              }
            }
          });
          await Promise.all([...videoProcessingPromises, ...imgProcessingPromises]);

          const content = Array.from(ref.current.children)
            .map((child) => {
              const node = child as HTMLElement;
              const height = node.offsetHeight;
              const sort = Number(node.id?.split('-')?.[0] || 0);
              return { node, height, sort };
            })
            .sort((a, b) => a.sort - b.sort);
          await asyncPDFGenerator(content, '公開管理_リリースノート詳細');
          return true;
        };

        const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));
        await sleep(200);
        await syncFunc();
        setShowConfirmExportFileModal(false);
        loadingRef.current?.isLoading(false);
        return true;
      });
      loadingRef.current?.isLoading(false);
    }
  };

  useEffect(() => {
    if (!visible) {
      formik.resetForm();
      setIndexSection(0);
    }
  }, [visible]);
  useJoditTranslateMutationObserver();

  return (
    <>
      <ReleaseNoteStyled
        className="ReleaseNotePopup ReleaseNotePopup-notshow"
        isEmptyData={!releaseNoteDetail?.sections.length}
        title={<span className="title">リリースノート</span>}
        headerStyle={{
          borderBottom: '1px solid #CCCCCC',
        }}
        onCancel={handleToggleModal}
        style={{
          right: '999999px',
        }}
        width={1400}
        visible={visible}
      >
        <FormikProvider value={formik}>
          <Form>
            <div ref={ref}>
              {array.map((item, index) => {
                if (!index) {
                  return (
                    <div>
                      <div
                        style={{
                          padding: '16px 24px',
                          textAlign: 'center',
                          borderBottom: '1px solid #ccc',
                        }}
                      >
                        <div
                          className="title"
                          style={{
                            fontSize: '18px',
                            textAlign: 'center',
                            color: '#424242',
                            fontWeight: 400,
                          }}
                        >
                          <span>リリースノート</span>
                        </div>
                      </div>
                      <div className="information">
                        <div>
                          <span className="content">{publishOfficial?.name}</span>
                          <span className="id">
                            （ID：
                            {publishOfficial?.official_curriculum_code})
                          </span>
                        </div>
                        <div className="version">
                          <div className="wrap-label">
                            <label className="label">
                              OFFICIALカリキュラム＆リリースノート公開バージョン
                              <span className="required"> *</span> ：
                            </label>
                            <Form.Item name="version" className="version-required">
                              <Input name="version" readOnly={true} />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="while-editing">
                        <div>
                          <img
                            src={
                              publish === 0 || !publish
                                ? WhiteEditing
                                : publish === 2
                                ? TickComplete
                                : publish === 1
                                ? UnPublished
                                : StopPublic
                            }
                            alt=""
                          />
                          <span className="content">{formik.values.title}</span>
                        </div>
                      </div>
                      <div
                        key={nanoid()}
                        className={`wrap-editor wrap-editor-shadow wrap-editor-shadow-${index}`}
                      >
                        <div className="left-side">
                          <div>
                            <div className="title-editor">
                              <div className="no-editor">{index + 1}</div>
                              <Form.Item name="section_name" className="item input-title">
                                <p className="text-area-custom" style={{ padding: 19 }}>
                                  {formik.values.sections[index]?.section_name ??
                                    'セクション名を入力...'}
                                </p>
                              </Form.Item>
                            </div>
                            <div className="container-content">
                              <div
                                className="content"
                                dangerouslySetInnerHTML={createMarkup(item.text)}
                                onClick={handlePreviewImage}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="right-side">
                          <Form.Item name="section_name" label="概要：" className="text-area">
                            <div className="box-field-text-area">
                              <p className="text-area-custom">
                                {formik.values.description ?? '最大480文字'}
                              </p>
                            </div>
                          </Form.Item>
                          <span className="title-right">セクション</span>
                          <div className="dashed-line"></div>
                          <div className="wrap-radio">
                            {formik.values.sections.map(({ section_name }, i) => (
                              <div
                                key={i}
                                className={`section ${index === i && 'section-checked'}`}
                              >
                                <span className="no-number">{i + 1}</span>
                                <span className="section-name">{section_name}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return (
                  <div
                    key={nanoid()}
                    className={`wrap-editor wrap-editor-shadow wrap-editor-shadow-${index}`}
                  >
                    <div className="left-side">
                      <div>
                        <div className="title-editor">
                          <div className="no-editor">{index + 1}</div>
                          <Form.Item name="section_name" className="item input-title">
                            <p className="text-area-custom" style={{ padding: 19 }}>
                              {formik.values.sections[index]?.section_name ??
                                'セクション名を入力...'}
                            </p>
                          </Form.Item>
                        </div>
                        {/* <JoditEditor config={defaultConfig} value={item.text} /> */}
                        <div className="container-content">
                          <div
                            className="content"
                            dangerouslySetInnerHTML={createMarkup(item.text)}
                            onClick={handlePreviewImage}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="right-side">
                      <Form.Item name="section_name" label="概要：" className="text-area">
                        <div className="box-field-text-area">
                          <p className="text-area-custom">
                            {formik.values.description ?? '最大480文字'}
                          </p>
                        </div>
                      </Form.Item>
                      <span className="title-right">セクション</span>
                      <div className="dashed-line"></div>
                      <div className="wrap-radio">
                        {formik.values.sections.map(({ section_name }, i) => (
                          <div key={i} className={`section ${index === i && 'section-checked'}`}>
                            <span className="no-number">{i + 1}</span>
                            <span className="section-name">{section_name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Form>
        </FormikProvider>
      </ReleaseNoteStyled>
      <ReleaseNoteStyled
        className="ReleaseNotePopup"
        isEmptyData={!releaseNoteDetail?.sections.length}
        title={<span className="title">リリースノート</span>}
        headerStyle={{
          borderBottom: '1px solid #CCCCCC',
        }}
        width={1400}
        visible={visible}
        onCancel={handleToggleModal}
      >
        <FormikProvider value={formik}>
          <Form>
            <div className="information">
              <div>
                <span className="content">{publishOfficial?.name}</span>
                <span className="id">（ID：{publishOfficial?.official_curriculum_code})</span>
              </div>
              <div className="version">
                <div className="wrap-label">
                  <label className="label">
                    OFFICIALカリキュラム＆リリースノート公開バージョン
                    <span className="required"> *</span> ：
                  </label>
                  <Form.Item name="version" className="version-required">
                    <Input name="version" readOnly={true} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="while-editing">
              <div>
                <img
                  src={
                    publish === 0 || !publish
                      ? WhiteEditing
                      : publish === 2
                      ? TickComplete
                      : publish === 1
                      ? UnPublished
                      : StopPublic
                  }
                  alt=""
                />
                <span className="content">{formik.values.title}</span>
              </div>
              <Button className=" btn-outline" onClick={() => setShowConfirmExportFileModal(true)}>
                <CloudDownloadOutlined
                  style={{
                    fontSize: 18,
                  }}
                />
                <span> エクスポート</span>
              </Button>
            </div>
            <div className="wrap-editor">
              <div className="left-side">
                <div className="title-editor">
                  <div className="no-editor">{indexSection + 1}</div>
                  <Form.Item name="section_name" className="item input-title">
                    <TextArea
                      disabled
                      className="text-area-section_name"
                      name={`sections.${indexSection}.section_name`}
                      value={(formik.values.sections[indexSection]?.section_name ?? '').replaceAll(
                        '\n',
                        ''
                      )}
                      autoSize={{ minRows: 1, maxRows: 2 }}
                      placeholder="セクション名を入力..."
                      maxLength={61}
                    />
                  </Form.Item>
                </div>
                <div className="wrap-view-jodit">
                  <div
                    className="content"
                    dangerouslySetInnerHTML={createMarkup(
                      formik.values.sections[indexSection]?.text
                    )}
                    onClick={handlePreviewImage}
                  />
                </div>

                <ImagePreview />

                <div className="wrap-bottom-editor">
                  <div className="wrap-button-editor">
                    <Button
                      className="btn button-prev"
                      disabled={indexSection === 0}
                      onClick={() => setIndexSection((prevState) => prevState - 1)}
                    >
                      <CaretLeftOutlined />
                      <span>前のセクション</span>
                    </Button>
                    <Button
                      className="btn button-next"
                      disabled={indexSection === formik.values.sections.length - 1}
                      onClick={() => setIndexSection(indexSection + 1)}
                    >
                      <span>次のセクション</span>
                      <CaretRightOutlined />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="right-side">
                <Form.Item name="section_name" label="概要：" className="text-area">
                  <TextArea
                    disabled
                    value={formik.values.description}
                    name="section_name"
                    rows={5}
                    placeholder="最大480文字"
                  />
                </Form.Item>
                <span className="title-right">セクション</span>
                <div className="dashed-line"></div>
                <div className="wrap-radio">
                  {formik.values.sections.map(({ section_name }, index) => (
                    <div
                      key={index}
                      onClick={() => setIndexSection(index)}
                      className={`section ${index === indexSection && 'section-checked'}`}
                    >
                      <span className="no-number">{index + 1}</span>
                      <span className="section-name">{section_name}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="wrap-submit">
              <div className="wrap-submit">
                <div className="wrap-button">
                  <Button className="btn btn_close" onClick={() => setVisible(false)}>
                    閉じる
                  </Button>
                </div>
              </div>
            </div>
            <Completed
              title="リリースノートを保存しました。"
              visible={visibleComplete}
              setVisible={setVisibleComplete}
              onSubmit={() => navigate(routes.PublicManagement.path)}
            />
            <UpdateStatus
              selectedRows={[]}
              publish={publish}
              status="unPublished"
              title="リリースノートを公開します"
              from={UnPublished}
              to={TickComplete}
              description={
                <span>
                  リリースノートを公開すると、
                  <br /> SKILL FAMILIARで一般のユーザー様が閲覧できる状態になります。
                </span>
              }
              visible={visibleUpdate}
              setVisible={setVisibleUpdate}
              onSubmit={() => setVisiblePublished(true)}
            />
            <Published
              visible={visiblePublished}
              setVisible={setVisiblePublished}
              onSubmit={() => navigate(generatePath(routes.PublicManagement.path))}
            />
            <PopupConfirmExportFile
              customTitle={
                <div
                  style={{
                    textAlign: 'center',
                  }}
                >
                  リリースノートをエクスポートします。 <br />
                  出力はPDFで実行されます。
                </div>
              }
              visible={showConfirmExportFileModal}
              setVisible={setShowConfirmExportFileModal}
              onSubmit={handleExportCSV}
            />
          </Form>
        </FormikProvider>
      </ReleaseNoteStyled>
    </>
  );
};

export default ReleaseNote;

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
];

const defaultConfig = {
  license: process.env.REACT_APP_JODIT_EDITOR_KEY ?? '',
  language: 'ja',
  i18n: { ja: JODIT_CUSTOM_LOCALIZATION.ja },
  placeholder: '本文を入力...',
  readonly: true,
  enableDragAndDropFileToEditor: true,
  buttons,
  buttonsMD: buttons,
  buttonsSM: buttons,
  buttonsXS: buttons,
  uploader: {
    url: 'https://xdsoft.net/jodit/finder/files/',
    baseUrl: 'sdadafaf',
    insertImageAsBase64URI: true,
    withCredentials: false,
    format: 'json',
    data: {
      dir: '',
    },
    filesVariableName: function (t: any) {
      return 'files[' + t + ']';
    },

    process: function (resp: any) {
      //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
      let files = [];
      files.unshift(resp.data);
      return { files: resp.data, error: resp.msg, msg: resp.msg };
    },

    isSuccess: () => {
      return true;
    },
    getMessage: function (e: any) {
      return void 0 !== e.data.messages && Array.isArray(e.data.messages)
        ? e.data.messages.join('')
        : '';
    },
    pathVariableName: 'path',
  },
  filebrowser: {
    ajax: {
      url: 'url',
    },
  },
};
