import React from 'react';
import { BarChartOutlined, TableOutlined } from '@ant-design/icons';
import { FormikProvider } from 'formik';
import { Form } from 'formik-antd';
import { Checkbox, Select, Tabs } from 'antd';

import { DatePicker, Header, SelectField } from 'components';
import SaleInformationStyled from './styles';
import Chart from '../Chart';
import Table from '../Table';
import { useSelector } from 'react-redux';
import { reportSaleInformationSelector } from 'pages/Report/SaleInformation/selectors';
import Label from 'components/Label';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const USER_TYPE = {
  ALL: '全ユーザー',
  CORPORATE: '法人ユーザー',
  PERSONAL: '個人ユーザー',
};

const SALE_TYPE = {
  ALL: 'ALL',
  USER_FEE: 'ユーザー利用料',
  SKILL_CHECK_USAGE_FEE: 'スキルチェック利用料',
  STORAGE_USAGE_FEE: 'ストレージ利用料',
};

interface PDFSaleInformationProps {
  formik: any;
  visibleExpansionOfSale: boolean;
  visibleCountUsage: boolean;
  segmented: number;
  valueSelect: number | undefined;
  selectedDates: any;
}

const PDFSaleInformation: React.FC<PDFSaleInformationProps> = (props) => {
  const {
    formik,
    valueSelect,
    selectedDates,
    visibleCountUsage,
    visibleExpansionOfSale,
    segmented,
  } = props;

  const { reportSaleInformationList, listSelectUsers } = useSelector(reportSaleInformationSelector);

  return (
    <SaleInformationStyled>
      <Header title="レポート" exportPDF />
      <div className="wrap-tab">
        <Tabs className="tab-container" activeKey="0">
          <TabPane tab="売上情報" key="0" />
          <TabPane tab="ユーザー情報" key="1" />
          <TabPane tab="OFFICIALカリキュラム情報" key="2" />
          <TabPane tab="スキルチェック実施情報" key="3" />
        </Tabs>
      </div>
      <div className="container">
        <div className="wrap-filter">
          <span className="label">集計条件</span>
          <div className="aggregation-conditions">
            <FormikProvider value={formik}>
              <Form layout="vertical">
                <div className="form-search">
                  <Form.Item
                    name="aggregation_method"
                    className="item"
                    label={<Label required>集計方法選択</Label>}
                  >
                    <Select
                      value={valueSelect}
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    >
                      <Option value={0}>単月推移</Option>
                      <Option value={1}>累計推移</Option>
                      <Option value={2}>ユーザー別単月推移</Option>
                      <Option value={3}>ユーザー別累計推移</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="target_month"
                    className="item date"
                    label={<Label required>集計期間</Label>}
                  >
                    <RangePicker
                      value={selectedDates}
                      allowClear
                      name="date-item"
                      format="YYYY/MM"
                      picker="month"
                      placeholder={['集計開始日', '集計終了日']}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement!}
                    />
                  </Form.Item>
                  <Form.Item
                    name="user_type"
                    className="item"
                    label={<span className="text-label">ユーザー種類選択</span>}
                  >
                    <SelectField
                      name="user_type"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value={USER_TYPE.ALL}>ALL</Option>
                      <Option value={USER_TYPE.CORPORATE}>法人ユーザー</Option>
                      <Option value={USER_TYPE.PERSONAL}>個人ユーザー</Option>
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="sale_type"
                    className="item"
                    label={<span className="text-label">売上種類選択</span>}
                  >
                    <SelectField
                      name="sale_type"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value={SALE_TYPE.ALL}>ALL</Option>
                      <Option value={SALE_TYPE.USER_FEE}>ユーザー利用料</Option>
                      <Option value={SALE_TYPE.SKILL_CHECK_USAGE_FEE}>スキルチェック利用料</Option>
                      <Option value={SALE_TYPE.STORAGE_USAGE_FEE}>ストレージ利用料</Option>
                    </SelectField>
                  </Form.Item>
                  {!!formik.values.aggregation_method && (
                    <Form.Item
                      name="name"
                      className="item date"
                      label={<span className="text-label">ユーザー検索</span>}
                    >
                      <SelectField
                        name="name"
                        showSearch
                        allowClear
                        placeholder="指定なし"
                        filterOption={(input, option) =>
                          JSON.stringify(option?.children)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {listSelectUsers &&
                          listSelectUsers.map((value) => (
                            <Option value={value.name} key={value.i_id}>
                              {value.name}
                            </Option>
                          ))}
                      </SelectField>
                    </Form.Item>
                  )}
                </div>
              </Form>
            </FormikProvider>
          </div>
        </div>
        <div className="sub-container">
          <div className="wrap-segmented">
            <div className="left-side">
              <span className="label">レポートタイプ：</span>
              <div className="segmented">
                <div
                  className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                >
                  <TableOutlined className="icon" />
                  <span>表</span>
                </div>
                <div
                  className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}${
                    valueSelect === 2 ? ' btn-disable' : ''
                  }`}
                >
                  <BarChartOutlined className="icon" />
                  <span>グラフ</span>
                </div>
              </div>
              <div className="item-checkbox">
                <Checkbox checked={visibleExpansionOfSale}>売上種類展開</Checkbox>
              </div>
              <div className="item-checkbox">
                <Checkbox
                  disabled={!visibleExpansionOfSale}
                  checked={visibleCountUsage && visibleExpansionOfSale}
                >
                  利用件数表示
                </Checkbox>
              </div>
            </div>
            <div className="show-count-paging"></div>
          </div>
          {segmented === 0 ? (
            <Table
              data={reportSaleInformationList}
              visibleExpansionOfSale={visibleExpansionOfSale}
              visibleCountUsage={visibleCountUsage}
              valueSelect={valueSelect}
              exportPdf
            />
          ) : (
            <Chart
              visibleExpansionOfSale={visibleExpansionOfSale}
              visibleCountUsage={visibleCountUsage}
              data={reportSaleInformationList}
              exportPdf
            />
          )}
        </div>
      </div>
    </SaleInformationStyled>
  );
};

export default PDFSaleInformation;
