import { BarChartOutlined, TableOutlined } from '@ant-design/icons';
import { Checkbox, Select, Tabs } from 'antd';
// import { authSelector } from 'containers/Auth/selectors';
import { DatePicker, Header, SelectField } from 'components';
import SkillCheckInformationStyled from './../styles';
import TableSkillCheckInformation from './../Table';
// import { useAppDispatch } from 'hooks';
import { Form, SubmitButton } from 'formik-antd';

import Chart from './../Chart';
import TabPane from 'antd/lib/tabs/TabPane';
import { FormikProvider } from 'formik';

interface Props {
  selectedDates: any | undefined;
  visibleCorrespondingSale: boolean;
  openConfirmExport: boolean;
  segmented: number;
  formik: any;
}

const { Option } = Select;
const { RangePicker } = DatePicker;
const PDFComponent = ({
  selectedDates,
  openConfirmExport,
  visibleCorrespondingSale,
  segmented,
  formik,
}: Props) => {
  return (
    <SkillCheckInformationStyled>
      <Header title="レポート" exportPDF />
      <div className="wrap-tab">
        <Tabs className="tab-container" activeKey="3">
          <TabPane tab="売上情報" key="0" />
          <TabPane tab="ユーザー情報" key="1" />
          <TabPane tab="OFFICIALカリキュラム情報" key="2" />
          <TabPane tab="スキルチェック実施情報" key="3" />
        </Tabs>
      </div>
      <div className="container">
        <div>
          <div className="wrap-filter">
            <span className="label">集計条件</span>
            <div className="aggregation-conditions">
              <FormikProvider value={formik}>
                <Form layout="vertical">
                  <div className="form-search">
                    <div className="form-search-search form-search">
                      <Form.Item
                        name="aggregation_method"
                        className="item"
                        requiredMark
                        label={
                          <span className="text-label">
                            集計方法選択 <span className="required">*</span>
                          </span>
                        }
                      >
                        <SelectField
                          name="aggregation_method"
                          showSearch
                          allowClear
                          placeholder="指定なし"
                          filterOption={(input, option) =>
                            JSON.stringify(option?.children)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value={'スキルチェック実施回数【単月推移】'}>
                            スキルチェック実施回数【単月推移】
                          </Option>
                          <Option value={'スキルチェック実施回数【累計推移】'}>
                            スキルチェック実施回数【累計推移】
                          </Option>
                        </SelectField>
                      </Form.Item>
                      <Form.Item
                        name="target_month"
                        className="item"
                        label={
                          <span className="text-label">
                            集計期間 <span className="required">*</span>
                          </span>
                        }
                      >
                        <RangePicker
                          value={selectedDates}
                          allowClear
                          className="date"
                          name="target_month"
                          picker="month"
                          format="YYYY/MM"
                          placeholder={['集計開始日', '集計終了日']}
                          getPopupContainer={(triggerNode) => triggerNode.parentElement!}
                        />
                      </Form.Item>
                      <Form.Item
                        name="user_type"
                        className="item"
                        requiredMark
                        label={<span className="text-label">ユーザー種類選択</span>}
                      >
                        <SelectField
                          name="user_type"
                          showSearch
                          allowClear
                          placeholder="指定なし"
                          filterOption={(input, option) =>
                            JSON.stringify(option?.children)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="ALL"> ALL</Option>
                          <Option value="65598568baeaf8d6328c9150"> 全ユーザー</Option>
                          <Option value="65598568baeaf8d6328c9151"> 社内ユーザー</Option>
                          <Option value="65598568baeaf8d6328c9152"> 面接ユーザー</Option>
                        </SelectField>
                      </Form.Item>
                    </div>
                    <div>
                      <div className="group-btn">
                        <span className="label-reset">リセット</span>
                        <SubmitButton className="btn-search" loading={false}>
                          表示
                        </SubmitButton>
                      </div>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
          <div className="sub-container">
            <div className="wrap-segmented">
              <div className="left-side">
                <span className="label">レポートタイプ：</span>
                <div className="segmented">
                  <div
                    className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                  >
                    <TableOutlined className="icon" />
                    <span>表</span>
                  </div>
                  <div
                    className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                  >
                    <BarChartOutlined className="icon" />
                    <span>グラフ</span>
                  </div>
                </div>
                <div className="item-checkbox">
                  <Checkbox checked={visibleCorrespondingSale}>対応売上表示</Checkbox>
                </div>
              </div>
            </div>
            {segmented === 0 ? (
              <TableSkillCheckInformation visibleCorrespondingSale={visibleCorrespondingSale} />
            ) : (
              <Chart
                exportPdf={openConfirmExport}
                visibleCorrespondingSale={visibleCorrespondingSale}
              />
            )}
          </div>
        </div>
      </div>
    </SkillCheckInformationStyled>
  );
};
export default PDFComponent;
