import React, {
  FC,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { DownOutlined, FileTextOutlined, FormOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Select, Table, Tooltip } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { filter, get, isEqual, unionBy } from 'lodash';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';

import { StopPublic, TickComplete, UnPublished, WhiteEditing } from 'assets';
import { HEADER_CURRICULUM_CSV } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { PUBLISH, SELECT_RECORD } from 'constant/select.constants';
import ActionErrorModal from 'components/Modal/ActionError';
import PdfCurriculumDetail from './PDFCurriculumDetail';
import { resetFilterChanged, setDataCurriculumTableFilled } from '../slice';
import { getStatus } from 'pages/PublicManagement';
import { curriculumSelector } from '../selectors';
import { Header, SelectField } from 'components';
import { exportCsv } from 'libs/utils/exportCsv';
import { pdf } from '@react-pdf/renderer';
import { useAppDispatch, usePermission } from 'hooks';
import EditModal from './Edit';
import * as Types from 'types';
import Wrapper from './styles';

const { Option } = Select;

type Props = {
  setOpenCurriculumMaster: React.Dispatch<React.SetStateAction<boolean>>;
  fetchDataCurriculum: () => Promise<void>;
};

export type VisiblePopupEditType = {
  visible: boolean;
  publish?: boolean;
  rowKey: string;
};

const EditContext = createContext<{
  visiblePopupEdit: VisiblePopupEditType;
  setVisiblePopupEdit: React.Dispatch<React.SetStateAction<VisiblePopupEditType>>;
}>({
  visiblePopupEdit: { visible: false, rowKey: '' },
  setVisiblePopupEdit: () => null,
});

const TYPE_PUBLISHED = 2;

const TableMemo = memo(function TableFunc(props: TableProps<Types.CurriculumTable.ResponseType>) {
  const { dataSource, columns, rowSelection, pagination } = props;
  return (
    <Table
      rowKey="rowKey"
      className="table"
      dataSource={dataSource}
      columns={columns}
      rowSelection={rowSelection}
      pagination={pagination}
    />
  );
}, isEqual);

const CusTooltip: FC<{ text: string }> = ({ text }) => {
  const [visible, setVisible] = useState(false);
  return (
    <Tooltip
      trigger={['click']}
      title={text ? <p style={{ color: '#FFFFFF', fontSize: 13 }}>{text}</p> : null}
      color="#404040"
      onVisibleChange={(v) => setVisible(v)}
      placement="topLeft"
      getPopupContainer={(parent) => parent.parentElement!}
      align={{
        offset: [-15, 0],
      }}
    >
      <FileTextOutlined className="icon" style={{ color: visible ? '#00a3a5' : '#c4c4c4' }} />
    </Tooltip>
  );
};

const CusButtonEdit: FC<{
  item: Types.CurriculumTable.ResponseType;
  disabled?: boolean;
}> = ({ item, disabled }) => {
  const { visiblePopupEdit, setVisiblePopupEdit } = useContext(EditContext);
  const showModal = visiblePopupEdit.rowKey === item.rowKey;

  return (
    <FormOutlined
      disabled={disabled}
      className="icon"
      onClick={() => {
        !disabled &&
          setVisiblePopupEdit({
            visible: true,
            publish: Number(item.curriculum_publish) === 2,
            rowKey: item.rowKey,
          });
      }}
      style={{ color: showModal ? '#00a3a5' : '#c4c4c4' }}
    />
  );
};

const CurriculumMasterLayout: React.FC<Props> = ({
  fetchDataCurriculum,
  setOpenCurriculumMaster,
}) => {
  const [selectedRows, setSelectedRows] = useState<Types.CurriculumTable.ResponseType[]>([]);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const { visiblePopupEdit, setVisiblePopupEdit } = useContext(EditContext);

  const {
    dataCurriculumTableFilled,
    dataCurriculumTable,
    filterChanged,
    curriculums,
    questions,
    level1s,
    level2s,
    level3s,
    level4s,
  } = useSelector(curriculumSelector);

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);

  const dispatch = useAppDispatch();
  const { permissionNumber } = usePermission();

  const dataSource = useMemo(
    () =>
      visible
        ? unionBy(dataCurriculumTableFilled, 'curriculum_code').map((item, index) => ({
            ...item,
          }))
        : dataCurriculumTableFilled.map((item, index) => ({
            ...item,
          })),
    [dataCurriculumTableFilled, visible]
  );

  const columns: ColumnsType<Types.CurriculumTable.ResponseType> = useMemo(
    () => [
      {
        title: <p className="title-table">ステータス</p>,
        dataIndex: 'curriculum_publish',
        key: 'curriculum_publish',
        className: 'curriculum_publish',
        width: '7%',
        render: (_text, item) => (
          <>
            {!Number(item.curriculum_publish) ? (
              <img src={WhiteEditing} className="icon-small" alt="edit-icon" />
            ) : Number(item.curriculum_publish) === 1 ? (
              <img src={UnPublished} className="icon-small" alt="publish-icon" />
            ) : Number(item.curriculum_publish) === 2 ? (
              <img src={TickComplete} className="icon-small" alt="publish-icon" />
            ) : (
              <img src={StopPublic} className="icon-small" alt="publish-icon" />
            )}
          </>
        ),
      },
      {
        title: (
          <>
            <p className="title-table">コード</p>
            <span>カリキュラム名</span>
          </>
        ),
        dataIndex: 'operation',
        width: '23%',
        render: (_text, item) => (
          <>
            <p className="text-code">{item.curriculum_code}</p>
            <span className="text-title">{item.curriculum_name}</span>
          </>
        ),
      },
      {
        title: '説明',
        dataIndex: 'curriculum_description',
        key: 'curriculum_description',
        width: '5%',
        render: (text) => <CusTooltip text={text} />,
      },

      {
        title: !visible ? (
          <>
            <p className="title-table">第１階層コード</p>
            <span>第１階層名</span>
          </>
        ) : (
          ''
        ),
        dataIndex: 'level1_code',
        width: '12%',
        render: (_text, { level1_code, level1_name }) => (
          <>
            {!visible && level1_code ? (
              <>
                <p className="text-code">{level1_code}</p>
                <p className="text-title">{level1_name || '（空白）'}</p>
              </>
            ) : null}
          </>
        ),
      },
      {
        title: !visible ? (
          <>
            <p className="title-table">第２階層コード</p>
            <span>第２階層名</span>
          </>
        ) : (
          ''
        ),
        dataIndex: 'level2_code',
        width: '12%',
        render: (_text, { level2_name, level2_code }) => (
          <>
            {!visible && level2_code ? (
              <>
                <p className="text-code">{level2_code}</p>
                <span className="text-title">{level2_name || '（空白）'}</span>
              </>
            ) : null}
          </>
        ),
      },
      {
        title: !visible ? (
          <>
            <p className="title-table">第３階層コード</p>
            <span>第３階層名</span>
          </>
        ) : (
          ''
        ),
        dataIndex: 'level3_code',
        width: '12%',
        render: (_text: string, { level3_code, level3_name }) => (
          <>
            {!visible && level3_code ? (
              <>
                <p className="text-code">{level3_code}</p>
                <span className="text-title">{level3_name || '（空白）'}</span>
              </>
            ) : null}
          </>
        ),
      },
      {
        title: !visible ? (
          <>
            <p className="title-table">第４階層コード</p>
            <span>第４階層名</span>
          </>
        ) : (
          ''
        ),
        dataIndex: 'level4_code',
        width: '12%',
        render: (_text, { level4_code, level4_name }) => (
          <>
            {!visible && level4_code ? (
              <>
                <p className="text-code">{level4_code}</p>
                <span className="text-title">{level4_name || '（空白）'}</span>
              </>
            ) : null}
          </>
        ),
      },
      {
        title: !visible ? (
          <>
            <p className="title-table">設問コード</p>
            <span>設問名</span>
          </>
        ) : (
          ''
        ),
        dataIndex: 'question_code',
        width: '12%',
        render: (_text, { question_code, question_name }) => {
          return (
            <>
              {!visible && question_code ? (
                <>
                  <p className="text-code">{question_code}</p>
                  <span className="text-title">{question_name || '（空白）'}</span>
                </>
              ) : null}
            </>
          );
        },
      },
      {
        title: !visible ? '編集' : '',
        dataIndex: 'operation',
        width: '5%',
        render: (_text, item, _index) => (
          <>
            {!visible && Number(item.curriculum_publish) !== TYPE_PUBLISHED ? (
              <CusButtonEdit disabled={permissionNumber !== 2} item={item} />
            ) : null}
          </>
        ),
      },
    ],
    [visible, permissionNumber]
  );

  // TODO: data has not been converted - not resolved
  const handleExportCSV = async (value: string) => {
    const filename = 'カリキュラムマスタ_階層リスト';
    if (value === 'csv') {
      const listCsv: Array<Types.CurriculumMasterTableCheckType> =
        selectedRows.length > 0
          ? selectedRows.map((item: Types.CurriculumTable.ResponseType) => ({
              flag: get(item, 'rev_no', 0),
              curriculum_name: item.curriculum_name,
              curriculum_code: item.curriculum_code,
              curriculum_type: item.required_curriculum === 1 ? '必修カリキュラム' : 'カリキュラム',
              curriculum_status:
                Number(item.curriculum_code) === 1
                  ? '未公開'
                  : Number(item.curriculum_code) === 2
                  ? '公開中'
                  : Number(item.curriculum_code) === 3
                  ? '公開停止中'
                  : '編集中',
              level1_name: item.level1_name || '（空白）',
              level1_code: item.level1_code || '（空白）',
              level2_name: item.level2_name || '（空白）',
              level2_code: item.level2_code || '（空白）',
              level3_name: item.level3_name || '（空白）',
              level3_code: item.level3_code || '（空白）',
              level4_name: item.level4_name || '（空白）',
              level4_code: item.level4_code || '（空白）',
              question_code: item.question_code || '（空白）',
              question_name: item.question_name || '（空白）',
              description: item.curriculum_description,
            }))
          : [];
      exportCsv(listCsv, HEADER_CURRICULUM_CSV, `${filename}.csv`);
    } else {
      const blob = await pdf(
        <PdfCurriculumDetail
          visibe={visible}
          filter={formik.values}
          curriculumList={selectedRows}
          curriculumNumber={dataSource.length}
        />
      ).toBlob();
      saveAs(blob, `${filename}.pdf`);
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const handleButtonExport = () => {
    if (!selectedRows.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const handleGetDataFilledTable = useCallback(
    (values: Types.CurriculumMasterSearchFormik) => {
      const {
        publish,
        curriculum_name,
        level1_name,
        level2_name,
        level3_name,
        level4_name,
        question_name,
      } = values;

      setPage(1);

      const dataFilled = filter(dataCurriculumTable, (item) => {
        return (
          (publish === '' ||
            publish === 'none' ||
            publish == null ||
            item.curriculum_publish === Number(publish)) &&
          (curriculum_name === '' ||
            curriculum_name === 'none' ||
            curriculum_name == null ||
            item.curriculum_name === curriculum_name) &&
          (level1_name === '' ||
            level1_name === 'none' ||
            level1_name == null ||
            item.level1_name === (level1_name === 'empty' ? '' : level1_name)) &&
          (level2_name === '' ||
            level2_name === 'none' ||
            level2_name == null ||
            item.level2_name === (level2_name === 'empty' ? '' : level2_name)) &&
          (level3_name === '' ||
            level3_name === 'none' ||
            level3_name == null ||
            item.level3_name === (level3_name === 'empty' ? '' : level3_name)) &&
          (level4_name === '' ||
            level4_name === 'none' ||
            level4_name == null ||
            item.level4_name === (level4_name === 'empty' ? '' : level4_name)) &&
          (question_name === '' ||
            question_name === 'none' ||
            question_name == null ||
            item.question_name === question_name)
        );
      });

      dispatch(setDataCurriculumTableFilled({ items: dataFilled }));
    },
    [dataCurriculumTable, dispatch]
  );

  const showTotal = useCallback(() => {
    return (
      <div className="wrap-select-record">
        <div className="text-count">
          {dataSource.length ? `${(page - 1) * perPage + 1} - ` : ''}
          {page * perPage > dataSource.length ? dataSource.length : page * perPage}
          <span className="text-static"> </span> /{dataSource.length}
          <span className="text-static"> 件</span>
        </div>
        <div>
          <span className="label-select">表示件数：</span>
          <Select
            className="select-option"
            onChange={(e) => {
              setPerpage(e);
              setPage(1);
            }}
            value={perPage}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
          >
            {SELECT_RECORD.map((e) => (
              <Option value={e !== '全て' ? e : dataSource.length} key={e}>
                {e}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    );
  }, [dataSource.length, page, perPage]);

  const pagination: TableProps<Types.CurriculumTable.ResponseType>['pagination'] = useMemo(() => {
    return {
      pageSize: perPage,
      total: dataSource.length,
      current: page,
      onChange: (pageChange) => {
        setPage(pageChange);
        setSelectedRows([]);
      },
      showSizeChanger: false,
      position: ['topCenter'],
      showTotal: showTotal,
    };
  }, [dataSource.length, page, perPage, showTotal]);

  const rowSelection: TableProps<Types.CurriculumTable.ResponseType>['rowSelection'] = useMemo(
    () => ({
      onChange: (_, record) => setSelectedRows(record),
    }),
    []
  );

  const formik = useFormik<Types.CurriculumMasterSearchFormik>({
    initialValues: {
      publish: 'none',
      curriculum_name: null,
      level1_name: null,
      level2_name: null,
      level3_name: null,
      level4_name: null,
      question_name: null,
    },
    onSubmit: (values) => {
      handleGetDataFilledTable(values);
      setPage(1);
    },
    onReset: () => {
      dispatch(setDataCurriculumTableFilled({ items: dataCurriculumTable }));
      setPage(1);
    },
  });

  useEffect(() => {
    dispatch(resetFilterChanged());
  }, []);

  useEffect(() => {
    if (
      !filterChanged ||
      !formik.values[filterChanged.type] ||
      formik.values[filterChanged.type] === 'none' ||
      formik.values[filterChanged.type] === 'empty' ||
      formik.values[filterChanged.type] === filterChanged.value
    )
      return;

    formik.setFieldValue(filterChanged.type, filterChanged.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterChanged]);

  useEffect(() => {
    handleGetDataFilledTable(formik.values);
  }, [dataCurriculumTable, handleGetDataFilledTable]);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerpage(dataSource.length);
    }
  }, [dataSource]);

  return (
    <Wrapper collapsedMenu={collapsedMenu}>
      <Header title={headerTitle} />
      <div className="container">
        <p className="text-note">
          カリキュラムマスタの階層リストです。階層の確認と編集が可能です。編集は編集マークの行に設定されている各階層を編集できます。
        </p>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="publish"
                className="item"
                label={<span className="text-label">公開ステータス</span>}
              >
                <SelectField name="publish" defaultValue="none">
                  <Option value="none">指定なし</Option>
                  {PUBLISH.map(({ value }, index) => (
                    <Option key={index} value={value}>
                      {getStatus(Number(value))}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="curriculum_name"
                className="item"
                label={<span className="text-label">カリキュラム名</span>}
              >
                <SelectField
                  name="curriculum_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="none">指定なし</Option>
                  {curriculums.map((value, index) => (
                    <Option key={index} value={value.name}>
                      {value.name}
                    </Option>
                  ))}
                  <Option value={'test'}>curriculum_name</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level1_name"
                className="item"
                label={<span className="text-label">第１階層名</span>}
              >
                <SelectField
                  name="level1_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="none">指定なし</Option>
                  <Option value="empty">（空白）</Option>
                  {level1s.map((value, index) => (
                    <Option key={index} value={value.name}>
                      {value.name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level2_name"
                className="item"
                label={<span className="text-label">第２階層名</span>}
              >
                <SelectField
                  name="level2_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="none">指定なし</Option>
                  <Option value="empty">（空白）</Option>
                  {level2s.map((value, index) => (
                    <Option key={index} value={value.name}>
                      {value.name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level3_name"
                className="item"
                label={<span className="text-label">第３階層名</span>}
              >
                <SelectField
                  name="level3_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="none">指定なし</Option>
                  <Option value="empty">（空白）</Option>
                  {level3s.map((value, index) => (
                    <Option key={index} value={value.name}>
                      {value.name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level4_name"
                className="item"
                label={<span className="text-label">第４階層名</span>}
              >
                <SelectField
                  name="level4_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="none">指定なし</Option>
                  <Option value="empty">（空白）</Option>
                  {level4s.map((value, index) => (
                    <Option key={index} value={value.name}>
                      {value.name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="question_name"
                className="item"
                label={<span className="text-label">設問名</span>}
              >
                <SelectField
                  name="question_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children)?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  <Option value="none">指定なし</Option>
                  {questions.map((e, index) => (
                    <Option key={index} value={e.name}>
                      {e.name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <div className="wrap-submit-search">
                <SubmitButton className="btn-search" loading={false}>
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <span className="label-reset" onClick={() => formik.resetForm()}>
                  リセット
                </span>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <div className="item-table">
          <div className="item-checkbox">
            <Checkbox checked={visible} onClick={() => setVisible(!visible)}>
              階層を折りたたむ
            </Checkbox>
          </div>
          <TableMemo
            rowKey="rowKey"
            className="table"
            dataSource={dataSource}
            columns={columns}
            rowSelection={rowSelection}
            pagination={pagination}
          />
        </div>
        <PopupConfirmExportFile
          customTitle={
            <>
              出力形式を選択して、OKボタンをクリックしてください。
              <br />
              <br />
            </>
          }
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="階層リストが選択されていません"
          description={
            <>
              エクスポートを実行する
              <br />
              対象の階層リストを選択し、再度実行してください。
            </>
          }
        />
        <EditModal
          visible={visiblePopupEdit}
          setVisible={setVisiblePopupEdit}
          handleFetchData={fetchDataCurriculum}
        />
      </div>
      <div className="wrap-bottom">
        <div className="flex">
          <div className="text-label">
            選択した階層リストを処理：
            <Button className="btn btn-active" onClick={handleButtonExport}>
              エクスポート
            </Button>
          </div>
          <Button className="btn btn-outline" onClick={() => setOpenCurriculumMaster(false)}>
            <span>カリキュラムツリー</span>
            <DownOutlined className="icon" />
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

const CurriculumMaster: React.FC<Props> = (props) => {
  const [visiblePopupEdit, setVisiblePopupEdit] = useState<VisiblePopupEditType>({
    visible: false,
    rowKey: '',
  });

  return (
    <EditContext.Provider value={{ visiblePopupEdit, setVisiblePopupEdit }}>
      <CurriculumMasterLayout {...props} />
    </EditContext.Provider>
  );
};

export default CurriculumMaster;
