import React from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { useDrag } from 'react-dnd';

import * as Types from 'types';

import { ItemWrapper } from './styles';

interface PropsItemUserResult {
  setSelectedQuestion: React.Dispatch<React.SetStateAction<Array<Types.Questions.ResponseType>>>;
  selectedQuestion: Array<Types.Questions.ResponseType>;
  item: Types.Questions.ResponseType;
  onSubmit: () => void;
}

const Item: React.FC<PropsItemUserResult> = ({
  setSelectedQuestion,
  selectedQuestion,
  onSubmit,
  item,
}) => {
  const handleSelect = () => {
    setSelectedQuestion((prevState) => {
      if (prevState.some((i) => i.i_id === item.i_id)) {
        return prevState.filter((i) => i.i_id !== item.i_id);
      } else {
        return [...prevState, item];
      }
    });
  };

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'move-question',
      item,
      onSubmit,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <ItemWrapper
      ref={drag}
      isDragging={isDragging}
      isChecked={selectedQuestion.some((i) => i.i_id === item.i_id)}
      onClick={handleSelect}
    >
      {selectedQuestion.some((i) => i.i_id === item.i_id) ? (
        <CheckOutlined className="icon" />
      ) : (
        <div className="icon" />
      )}
      <span className="text-bold">{item.name}</span>
    </ItemWrapper>
  );
};

export default Item;
