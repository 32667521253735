import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .wrap-content {
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
    .content {
      font-size: 18px;
      color: #424242;
    }
  }
  .description {
    font-size: 13px;
    color: #424242;
    text-align: center;
  }
  .icon {
    font-size: 28px;
    margin-right: 10px;
    color: #bbbbbb;
  }
`;
