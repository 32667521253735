import { createSlice } from '@reduxjs/toolkit';
import * as Types from 'types';
import {
  getDataReportSaleInformation,
  getDataReportSaleInformationBreakdown,
  getDataReportSaleInformationBreakdownDetail,
  getSelectUser,
} from 'pages/Report/SaleInformation/thunk';
import { getMonthListFromDateRanger } from 'libs/utils/calculate';
import { uniqBy } from 'lodash';

export interface InitialState {
  loading: boolean;
  total: number;
  reportSaleInformationList: Array<Types.SaleReportInformation.SaleReportInformation>;
  reportSaleInformationBreakdownList: Array<Types.SaleReportInformation.SaleReportInformationBreakdown>;
  reportSaleInformationBreakdownDetailList: Array<Types.SaleReportInformation.SaleReportInformationBreakdownDetail>;
  listSelectUsers: Types.Selects.SelectUserResponseType[];
  selectedDateRanger?: string[];
  dateRanger?: string[];
  chartUserType: string;
  chartSaleType: string;
  userType: string;
  saleType: string;
  filterUser: string;
  userOptions: Types.IOption[];
}

const initialState: InitialState = {
  loading: false,
  total: 0,
  reportSaleInformationList: [],
  reportSaleInformationBreakdownList: [],
  reportSaleInformationBreakdownDetailList: [],
  listSelectUsers: [],
  selectedDateRanger: undefined,
  dateRanger: [],
  chartUserType: '',
  chartSaleType: '',
  userType: '全ユーザー',
  saleType: 'ALL',
  filterUser: '',
  userOptions: [],
};
export const reportSaleInformationSlice = createSlice({
  name: 'sale-information-report-slice',
  initialState,
  reducers: {
    setDateRanger(state, action) {
      const values = action.payload;
      state.selectedDateRanger = values;
      state.dateRanger = getMonthListFromDateRanger(values?.[0], values?.[1]);
    },
    setChartUserType(state, action) {
      state.chartUserType = action.payload;
    },
    setChartSaleType(state, action) {
      state.chartSaleType = action.payload;
    },
    setUserType(state, action) {
      state.userType = action.payload;
    },
    setSaleType(state, action) {
      state.saleType = action.payload;
    },
    setFilterUser(state, action) {
      state.filterUser = action.payload;
    },
  },
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getDataReportSaleInformation.pending, startLoading)
      .addCase(getDataReportSaleInformationBreakdown.pending, startLoading)
      .addCase(getDataReportSaleInformationBreakdownDetail.pending, startLoading);

    builder.addCase(getDataReportSaleInformation.fulfilled, (state, action) => {
      state.reportSaleInformationList = action.payload.report_results || [];
      stopLoading(state);
    });
    builder.addCase(getDataReportSaleInformationBreakdown.fulfilled, (state, action) => {
      state.reportSaleInformationBreakdownList = action.payload.report_results || [];
      state.userOptions = uniqBy(
        (action.payload.report_results || []).map((obj) => ({
          value: obj.company_name || obj.user_name || '',
          label: obj.company_name || obj.user_name || '',
        })),
        'value'
      );
      stopLoading(state);
    });
    builder.addCase(getDataReportSaleInformationBreakdownDetail.fulfilled, (state, action) => {
      state.reportSaleInformationBreakdownDetailList = action.payload.report_results || [];
      stopLoading(state);
    });
    builder.addCase(getSelectUser.fulfilled, (state, action) => {
      state.listSelectUsers = action.payload.report_results || [];
    });
  },
});

export const {
  setDateRanger,
  setChartUserType,
  setChartSaleType,
  setUserType,
  setSaleType,
  setFilterUser,
} = reportSaleInformationSlice.actions;

export default reportSaleInformationSlice.reducer;
