import styled from 'styled-components';

export default styled.div`
  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #777777;
  }
  .content {
    width: 100%;
    padding: 15px;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
  }
  .creator {
    margin-bottom: 20px;
    .content {
      height: 40px;
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #424242;
        margin: 0;

        a {
          color: #08a3a5;
          text-decoration: underline;
        }
      }
    }
  }
  .basic_info {
    margin-bottom: 31px;
    .content {
      p,
      a {
        font-size: 14px;
        font-weight: 400;
        line-height: 22.4px;
        color: #424242;
        margin: 0;
      }
      a {
        color: #08a3a5;
        text-decoration: underline;
      }
    }
  }
  .wrap-title-table {
    display: flex;
    margin-top: 32px;
    margin-bottom: 9px;
    img {
      margin-right: 4px;
    }
  }
`;
