import styled from 'styled-components';

export default styled.div`
  overflow: auto;
  position: relative;
  max-height: 686px;
  background-color: #ffffff;
  .virtual-table {
    display: grid;
    position: relative;
    background-color: #ffffff;
    .virtual-table-head {
      //display: grid;
      position: sticky;
      top: 0;
      z-index: 2;

      .virtual-table-header-row {
        border-radius: 2px 2px 0 0;
        display: flex;
        width: 100%;
        background-color: #ebebeb;
        border: 1px solid #dddddd;
        .virtual-table-header-cell {
          :not(:last-child) {
            border-right: 1px solid #dddddd;
          }
          padding: 10px 14px;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
    .virtual-table-body {
      .virtual-table-row {
        .virtual-table-cell {
          background: #ffffff;
          :first-child {
            border-left: 1px solid #dddddd;
          }
          :not(:last-child) {
            border-right: 1px solid #dddddd;
          }

          padding: 10px 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .level-space {
            height: 100%;
          }
        }
      }
      .virtual-table-row-level-0 {
        border-bottom: 1px solid #dddddd;
      }
      .virtual-table-row-level-1 {
        .level-space {
          padding-left: 16px;
        }
      }
      .virtual-table-row-level-2 {
        .level-space {
          padding-left: 32px;
        }
      }
      .virtual-table-row-level-1,
      .virtual-table-row-level-2 {
        .virtual-table-cell {
          :first-child {
            padding: 0;
          }
          .virtual-table-first-cell-content {
            width: 100%;
            height: 100%;
            border-bottom: 1px dashed #dddddd;
            padding: 10px 14px;
            border-left: 1px solid #dddddd;
          }
          :not(:first-child) {
            border-bottom: 1px dashed #dddddd;
          }
        }
      }
      .virtual-table-row-last-child {
        border-bottom: 1px solid #dddddd;
        .virtual-table-cell {
          border-bottom: none !important;
          .virtual-table-first-cell-content {
            border-bottom: none !important;
          }
        }
      }
    }
  }
`;
