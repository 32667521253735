import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep, differenceWith, filter, intersectionWith, orderBy, uniq, uniqBy } from 'lodash';

import { CurriculumStatus } from 'constant/enum.constant';
import * as Types from 'types';
import {
  getQuestionAssignedToCurriculum,
  getQuestionAssignedLevel,
  curriculumExportDataCSV,
  updateLevelCurriculum,
  setPublishCurriculum,
  getDataCurriculum,
  createCurriculum,
  deleteCurriculum,
  updateCurriculum,
} from './thunk';

interface TFilterChanged {
  type:
    | 'curriculum_name'
    | 'level1_name'
    | 'level2_name'
    | 'level3_name'
    | 'level4_name'
    | 'question_name';
  value: string;
}

export type InitialState = {
  questionAssignLevelOfficialCurriculum: Array<Types.QuestionAssignLevelOfficialCurriculum.ResponseType>;
  conditions?: {
    name: string;
    status: 'publish' | 'edit' | 'all' | 'unPublished' | 'suspended' | undefined;
  };
  dataCurriculumTreeFilleted: Array<Types.TreeItem<Types.CurriculumItemType>>;
  dataCurriculumTableFilled: Array<Types.CurriculumTable.ResponseType>;
  dataCurriculumTree: Array<Types.TreeItem<Types.CurriculumItemType>>;
  questionAssignLevel: Array<Types.QuestionAssignLevel.ResponseType>;
  dataCurriculumTable: Array<Types.CurriculumTable.ResponseType>;
  nodeLevel4Selected?: Types.TreeItem<Types.CurriculumItemType>;
  unrelatedQuestionsFilled: Array<Types.Questions.ResponseType>;
  questionSearchFilled: Array<Types.Questions.ResponseType>;
  unrelatedQuestions: Array<Types.Questions.ResponseType>;
  creatorQuestions: Array<Types.Questions.ResponseType>;
  questionSearch: Array<Types.Questions.ResponseType>;
  curriculums: Array<Types.Curriculum.ResponseType>;
  data_curricullum_export: Array<Types.ItemExport>;
  questions: Array<Types.Questions.ResponseType>;
  level1s: Array<Types.Level.ResponseType>;
  level2s: Array<Types.Level.ResponseType>;
  level3s: Array<Types.Level.ResponseType>;
  level4s: Array<Types.Level.ResponseType>;
  filterChanged?: TFilterChanged;
  loading: boolean;
  questionKeys: string[];
  dataKeyDontHaveQuestion: string[];
};

const initialState: InitialState = {
  questionAssignLevelOfficialCurriculum: [],
  dataCurriculumTreeFilleted: [],
  dataCurriculumTableFilled: [],
  unrelatedQuestionsFilled: [],
  data_curricullum_export: [],
  questionSearchFilled: [],
  questionAssignLevel: [],
  questionKeys: [],
  dataCurriculumTable: [],
  unrelatedQuestions: [],
  dataCurriculumTree: [],
  creatorQuestions: [],
  questionSearch: [],
  curriculums: [],
  loading: false,
  questions: [],
  level1s: [],
  level2s: [],
  level3s: [],
  level4s: [],
  dataKeyDontHaveQuestion: [],
};

export const curriculumSlice = createSlice({
  name: 'curricullum-page',
  initialState,
  reducers: {
    setConditions: (state, action) => {
      state.conditions = action.payload;
    },
    setFilterByStatus(
      state,
      action: PayloadAction<{
        status?: keyof typeof CurriculumStatus;
      }>
    ) {
      const { status } = action.payload;

      const data = cloneDeep(state.dataCurriculumTree);

      const statusNumber = (() => {
        switch (status) {
          case 'publish':
            return 2;
          case 'edit':
            return 0;
          case 'unPublished':
            return 1;
          case 'suspended':
            return 3;
          default:
            return undefined;
        }
      })();

      state.dataCurriculumTreeFilleted =
        statusNumber !== undefined ? filter(data, { publish: statusNumber }) : data;
    },
    setFilterByCurriculum(
      state,
      action: PayloadAction<{
        name?: string;
      }>
    ) {
      const { name } = action.payload;

      const data = cloneDeep(state.dataCurriculumTreeFilleted);

      if (name) {
        state.dataCurriculumTreeFilleted = orderBy(data, [
          (curriculum) => (curriculum.name === name ? 0 : 1),
        ]);
      }
    },

    clearFilterConditions(state) {
      state.dataCurriculumTreeFilleted = [...state.dataCurriculumTree];
    },

    setDataCurriculumTableFilled(
      state,
      action: PayloadAction<{
        items: Types.CurriculumTable.ResponseType[];
      }>
    ) {
      state.dataCurriculumTableFilled = action.payload.items;
    },

    setQuestionSearchFilled(
      state,
      action: PayloadAction<{
        items: Types.Questions.ResponseType[];
      }>
    ) {
      state.questionSearchFilled = action.payload.items;
    },

    setUnrelatedQuestionFilled(
      state,
      action: PayloadAction<{
        items: Types.Questions.ResponseType[];
      }>
    ) {
      state.unrelatedQuestionsFilled = action.payload.items;
    },

    setFilterChanged(
      state,
      action: PayloadAction<{
        items: TFilterChanged;
      }>
    ) {
      state.filterChanged = action.payload.items;
    },

    resetFilterChanged: (state) => {
      state.filterChanged = undefined;
    },

    selectNodeLevel4(
      state,
      action: PayloadAction<Types.CurriculumItemType & { maxSortOrder?: number }>
    ) {
      state.nodeLevel4Selected = action.payload;
    },
    removeNodeLevel4Selected(state) {
      state.nodeLevel4Selected = undefined;
    },
  },
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(setPublishCurriculum.pending, startLoading)
      .addCase(deleteCurriculum.pending, startLoading)
      .addCase(updateLevelCurriculum.pending, startLoading)
      .addCase(curriculumExportDataCSV.pending, startLoading);

    builder.addCase(createCurriculum.fulfilled, stopLoading);
    builder.addCase(setPublishCurriculum.fulfilled, (state, action) => {
      const curriculumIndex = state.dataCurriculumTree.findIndex(
        (c) => c.i_id === action.payload.item.i_id
      );
      state.dataCurriculumTree[curriculumIndex] = {
        ...state.dataCurriculumTree[curriculumIndex],
        publish: action.payload.item.publish,
      };
      stopLoading(state);
    });
    builder.addCase(updateCurriculum.fulfilled, (state, action) => {
      const curriculumIndex = state.dataCurriculumTree.findIndex(
        (c) => c.i_id === action.payload.item.i_id
      );
      state.dataCurriculumTree[curriculumIndex] = {
        ...state.dataCurriculumTree[curriculumIndex],
        name: action.payload.item.name,
        description: action.payload.item.description,
      };
    });
    builder.addCase(deleteCurriculum.fulfilled, (state, action) => {
      const curriculumIndex = state.dataCurriculumTree.findIndex(
        (c) => c.i_id === action.payload.item.i_id
      );
      state.dataCurriculumTree.splice(curriculumIndex, 1);
      stopLoading(state);
    });

    builder.addCase(curriculumExportDataCSV.fulfilled, (state, action) => {
      state.data_curricullum_export = action.payload.report_results;
      const allCode = uniq(state.data_curricullum_export.map((value) => value.curriculum_code));
      state.data_curricullum_export = state.data_curricullum_export.map((value) => {
        const indexCode = allCode.findIndex((code) => value.curriculum_code === code);
        return { flag: indexCode + 1, ...value };
      });

      stopLoading(state);
    });

    builder.addCase(getQuestionAssignedLevel.fulfilled, (state, action) => {
      state.questionAssignLevel = action.payload.items;
    });

    builder.addCase(getQuestionAssignedToCurriculum.fulfilled, (state, action) => {
      state.questionAssignLevelOfficialCurriculum = action.payload.items;
    });

    builder.addCase(getDataCurriculum.fulfilled, (state, action) => {
      state.dataCurriculumTable = action.payload.items;
      state.dataCurriculumTree = action.payload.dataCurriculumTree;
      state.questionKeys = action.payload.questionKeys;
      state.dataCurriculumTreeFilleted = action.payload.dataCurriculumTree;
      state.dataKeyDontHaveQuestion = action.payload.dataKeyDontHaveQuestion;
      state.curriculums = uniqBy(
        filter(action.payload.dataCurriculums, (item) => !!item.name),
        'name'
      );
      state.questionSearch = action.payload.dataQuestions;
      state.creatorQuestions = uniqBy(
        filter(action.payload.dataQuestions, (item) => !!item.code && !!item.creator),
        'creator'
      );
      state.unrelatedQuestions = differenceWith(
        action.payload.dataQuestions,
        action.payload.items,
        (a, b) => a.code === b.question_code
      );
      state.unrelatedQuestionsFilled = differenceWith(
        action.payload.dataQuestions,
        action.payload.items,
        (a, b) => a.code === b.question_code
      );
      state.questions = uniqBy(
        intersectionWith(
          action.payload.dataQuestions,
          action.payload.items,
          (a, b) => !!a.name && a.name === b.question_name
        ),
        'name'
      );
      state.level1s = uniqBy(
        intersectionWith(
          action.payload.dataLevel1s,
          action.payload.items,
          (a, b) => !!a.name && a.name === b.level1_name
        ),
        'name'
      );
      state.level2s = uniqBy(
        intersectionWith(
          action.payload.dataLevel2s,
          action.payload.items,
          (a, b) => !!a.name && a.name === b.level2_name
        ),
        'name'
      );
      state.level3s = uniqBy(
        intersectionWith(
          action.payload.dataLevel3s,
          action.payload.items,
          (a, b) => !!a.name && a.name === b.level3_name
        ),
        'name'
      );
      state.level4s = uniqBy(
        intersectionWith(
          action.payload.dataLevel4s,
          action.payload.items,
          (a, b) => !!a.name && a.name === b.level4_name
        ),
        'name'
      );
    });

    builder.addCase(updateLevelCurriculum.fulfilled, stopLoading);
    builder
      .addCase(setPublishCurriculum.rejected, stopLoading)
      .addCase(deleteCurriculum.rejected, stopLoading)
      .addCase(updateLevelCurriculum.rejected, stopLoading)
      .addCase(curriculumExportDataCSV.rejected, stopLoading);
  },
});
export const {
  setDataCurriculumTableFilled,
  setUnrelatedQuestionFilled,
  removeNodeLevel4Selected,
  setQuestionSearchFilled,
  setFilterByCurriculum,
  clearFilterConditions,
  resetFilterChanged,
  setFilterByStatus,
  selectNodeLevel4,
  setFilterChanged,
  setConditions,
} = curriculumSlice.actions;

export default curriculumSlice.reducer;
