import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

import { getCorporateUserInformation, getHistoryOfUsing } from './thunk';
import * as Types from 'types';

export type InitialState = {
  corporateUsersFilled: Array<Types.CorporateUser.CorporateUserInformationType>;
  selectCompanyName: Array<Types.CorporateUser.CorporateUserInformationType>;
  selectCompanyId: Array<Types.CorporateUser.CorporateUserInformationType>;
  corporateUsers: Array<Types.CorporateUser.CorporateUserInformationType>;
  selectPaymentMethod: Array<Types.PaymentMethod.ResponseType>;
  historyOfUsing: Array<Types.HistoryOfUsing.ResponseType>;
};

const initialState: InitialState = {
  corporateUsersFilled: [],
  selectPaymentMethod: [],
  selectCompanyName: [],
  selectCompanyId: [],
  corporateUsers: [],
  historyOfUsing: [],
};

export const corporateSlice = createSlice({
  name: 'corporate-page',
  initialState,
  reducers: {
    filterDataCorporateUsers: (
      state,
      action: PayloadAction<{
        items: Array<Types.CorporateUser.CorporateUserInformationType>;
      }>
    ) => {
      state.corporateUsersFilled = action.payload.items;
    },
  },
  extraReducers(builder) {
    builder.addCase(getCorporateUserInformation.fulfilled, (state, action) => {
      state.corporateUsers = action.payload.items;
      state.selectCompanyId = uniqBy(action.payload.items, 'company_id');
      state.selectCompanyName = uniqBy(
        action.payload.items.filter((item) => !!item.company_name),
        'company_name'
      );
      state.selectPaymentMethod = action.payload.dataPaymentMethod;
    });

    builder.addCase(getHistoryOfUsing.fulfilled, (state, action) => {
      state.historyOfUsing = action.payload.items;
    });
  },
});

export const { filterDataCorporateUsers } = corporateSlice.actions;

export default corporateSlice.reducer;
