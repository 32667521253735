import { Modal } from 'components';
import styled from 'styled-components';

export const PopoverStyled = styled('div')`
  padding: 24px 12px;
  .main-content {
    margin-top: 24px;
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-header .ant-modal-title {
    color: #2a2a2a;
  }
  @media (max-width: 1440px) {
    .ant-modal-footer {
      padding: 0 20px 40px 20px !important;
      .button-secondary,
      .button-primary {
        width: 160px;
        height: 48px;
      }
    }
  }
  .ant-modal-body {
    padding: 40px;
    background-color: #f9f8f8;
  }
  .ant-modal-footer {
    padding: 0 20px 20px 20px;
    background-color: #f9f8f8;
  }
  .ant-modal-content {
    width: 759px;
    height: 840px;
  }
`;

export const Styled = styled.div`
  @media (max-width: 1440px) {
    .header-modal .under-contract .under-contract-1 {
      min-width: 100px;
    }
  }
  .address-second {
    padding-left: 8px;
  }
  .header-modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    .sub-text {
      color: rgba(119, 119, 119, 1);
      font-size: 12px;
    }
    .under-contract {
      min-width: 100px;
      background: #08a3a5;
      border-radius: 2px;
      border: none;
      font-weight: 700;
      font-size: 13px;
      color: #ffffff;
      height: 28px;
      padding: 0 30px;
      cursor: pointer;
    }

    .under-contract-1 {
      min-width: 100px;
      background: #a6abab;
      border-radius: 2px;
      border: none;
      font-weight: 700;
      font-size: 13px;
      color: #ffffff;
      height: 28px;
      padding: 0 30px;
      cursor: pointer;
    }
  }
  .checkbox {
    .text-checkbox {
      font-size: 13px;
    }
  }
  .table-above {
    margin-top: 20px;
    .contract-start-date {
      display: flex;
      align-items: center;
      border: 1px solid rgba(204, 204, 204, 1);
      div {
        padding: 11px 0;
      }
      span {
        margin-left: 8px;
      }
      .contract-first,
      .contract-third {
        width: 24%;
        background-color: #ebebeb;
        color: rgba(66, 66, 66, 1);
      }
      .contract-third {
        border-left: 1px solid rgba(204, 204, 204, 1);
      }
      .contract-first > span,
      .contract-third > span {
        font-size: 12px;
      }
      .contract-second,
      .contract-fourth {
        font-size: 14px;
        color: rgba(42, 42, 42, 1);
      }
      .contract-second {
        width: 32%;
      }
    }
    .usage-plan {
      display: flex;
      align-items: center;
      border: 1px solid rgba(204, 204, 204, 1);
      border-top: none;
      div {
        padding: 11px 0;
      }
      span {
        margin-left: 8px;
      }
      .usage-first {
        width: 24%;
        background-color: #ebebeb;
      }
      .usage-first > span {
        font-size: 12px;
      }
      .usage-second {
        width: auto;
      }
      .usage-second > span {
        font-size: 14px;
      }
    }
    .request-method {
      display: flex;
      align-items: center;
      border: 1px solid rgba(204, 204, 204, 1);
      border-top: none;

      .method-second {
        margin-left: 8px;
      }
      .method-first {
        width: 24%;
        background-color: #ebebeb;
        padding: 19px 8px;
      }
      .method-first > span {
        font-size: 12px;
      }
      .method-second {
        width: 12%;
      }
      .method-third {
        width: 22%;
      }
      .method-fourth {
        width: auto;
      }
    }
  }
  .table-below {
    margin-top: 24px;
    .address {
      display: flex;
      border: 1px solid rgba(204, 204, 204, 1);
      height: 90px;
      .address-first {
        width: 24%;
        background-color: rgba(235, 235, 235, 1);
        padding: 16px 0 0 8px;
        color: #424242;
        font-size: 12px;
      }

      .text-area {
        width: 77%;
        border: none;
      }
    }
    .manager-intelligence {
      border: 1px solid rgba(204, 204, 204, 1);
      border-top: none;
      display: flex;
      .intelligence-first {
        width: 24%;
        background-color: rgba(235, 235, 235, 1);
        padding: 16px 0 0 8px;
        color: #424242;
        font-size: 12px;
      }
      .intelligence-second {
        display: flex;
        height: 136px;
        flex-direction: column;
        padding: 16px 0 0 8px;
        .intelligence-title {
          font-size: 14px;
        }
        .intelligence-text {
          color: rgba(153, 153, 153, 1);
          font-size: 13px;
          .intelligence-sub-text {
            color: rgba(42, 42, 42, 1);
          }
          .intelligence-email {
            color: rgba(8, 163, 165, 1);
            text-decoration: underline;
          }
        }
      }
    }
  }
`;
