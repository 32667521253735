import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';

import styles from './styles';
import _ from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import { DownIconPdf } from 'assets/svg/svgPDF';
import dayjs from 'dayjs';
import * as Types from 'types';
import { StopPublicPdf, TickCompletePdf, UnPublishedPdf, WhiteEditingPdf } from 'assets/svgPDF';
import IconDoc from 'assets/svgPDF/IconDocument';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface Props {
  filter: { [key: string]: any };
  visibe: boolean;
  curriculumList: Array<Types.CurriculumTable.ResponseType>;
  curriculumNumber: number;
}

const FIRST_PAGE_ROW_COUNT = 13;
const PAGE_ROW_COUNT = 18;

const DataTable = ({
  visibe,
  dataSource,
}: {
  visibe: boolean;
  dataSource: Array<Types.CurriculumTable.ResponseType>;
}) => {
  const columns: { title: string | string[]; width: string | number; key: string }[] = [
    {
      title: [],
      width: 100,
      key: 'checkbox',
    },
    {
      title: ['ステータス'],
      width: 100,
      key: 'publish',
    },
    {
      title: ['カリキュラムコード', 'カリキュラム名'],
      width: 150,
      key: 'official_curriculum_code',
    },
    {
      title: !visibe ? ['第１階層コード', '第１階層名'] : ['説明'],
      width: 140,
      key: 'level1_code',
    },
    {
      title: !visibe ? ['第２階層コード', '第２階層名'] : [''],
      width: 140,
      key: 'level2_code',
    },
    {
      title: !visibe ? ['第３階層コード', '第３階層名'] : [''],
      width: 140,
      key: 'level3_code',
    },
    {
      title: !visibe ? ['第４階層コード', '第４階層名'] : [''],
      width: 140,
      key: 'level4_code',
    },
    {
      title: !visibe ? ['設問コード', '設問名'] : [''],
      width: 140,
      key: 'question_code',
    },
  ];

  const isStringArray = (val: any) => {
    if (Array.isArray(val)) {
      return true;
    } else {
      return false;
    }
  };
  const formatDateData = (val: string, format = 'YYYY/MM') => {
    if (val) {
      return dayjs(val).format(format);
    }
    return '-';
  };

  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow, { height: '26.728px' }]}>
        {columns.map((column, index) =>
          index === 0 ? (
            <>
              <View
                key={index}
                style={[
                  styles.wrapCell,
                  {
                    width: column.width,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  },
                ]}
              >
                <Text style={(styles.tableCell, styles.headerCell)}>ALL</Text>
                <View style={styles.checkbox}></View>
              </View>
            </>
          ) : isStringArray(column.title) ? (
            <View
              key={index}
              style={[
                styles.wrapCell,
                { width: column.width, display: 'flex', flexDirection: 'column' },
              ]}
            >
              <Text
                style={[styles.tableCell, styles.headerCell, { color: '#777777', fontWeight: 400 }]}
              >
                {column.title[0]}
              </Text>
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title[1]}</Text>
            </View>
          ) : (
            <View key={index} style={[styles.wrapCell, { width: column.width }]}>
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
            </View>
          )
        )}
      </View>

      {dataSource.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5',
            },
          ]}
        >
          <View
            style={[
              styles.wrapCell,
              {
                width: columns[0].width,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              },
            ]}
          >
            <View style={[styles.checkbox]}></View>
          </View>
          <View style={[styles.wrapCell, { width: columns[1].width }]}>
            <View style={{ transform: 'scale(0.7)' }}>
              {Number(row.curriculum_publish) === 1 ? (
                <UnPublishedPdf />
              ) : Number(row.curriculum_publish) === 2 ? (
                <TickCompletePdf />
              ) : Number(row.curriculum_publish) === 3 ? (
                <StopPublicPdf />
              ) : (
                <WhiteEditingPdf />
              )}
            </View>
          </View>
          <View style={[styles.wrapCell, { width: columns[2].width }]}>
            <Text style={styles.tableCellfirst}>{row.curriculum_code}</Text>
            <Text style={styles.tableCell}>{row.curriculum_name}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[3].width }]}>
            {!visibe ? (
              <View>
                <Text style={styles.tableCellfirst}>{row.level1_code}</Text>
                <Text style={styles.tableCell}>{row.level1_name}</Text>
              </View>
            ) : (
              <IconDoc style={{ transform: 'scale(0.7)' }} />
            )}
          </View>
          <View style={[styles.wrapCell, { width: columns[4].width }]}>
            {!visibe ? (
              <View>
                <Text style={styles.tableCellfirst}>{row.level2_code}</Text>
                <Text style={styles.tableCell}>{row.level2_name}</Text>
              </View>
            ) : (
              <View></View>
            )}
          </View>
          <View style={[styles.wrapCell, { width: columns[5].width }]}>
            {!visibe ? (
              <View>
                <Text style={styles.tableCellfirst}>{row.level3_code}</Text>
                <Text style={styles.tableCell}>{row.level3_name}</Text>
              </View>
            ) : (
              <View></View>
            )}
          </View>
          <View style={[styles.wrapCell, { width: columns[6].width }]}>
            {!visibe ? (
              <View>
                <Text style={styles.tableCellfirst}>{row.level4_code}</Text>
                <Text style={styles.tableCell}>{row.level4_name}</Text>
              </View>
            ) : (
              <View></View>
            )}
          </View>
          <View style={[styles.wrapCell, { width: columns[7].width }]}>
            {!visibe ? (
              <View>
                <Text style={styles.tableCellfirst}>{row.question_code}</Text>
                <Text style={styles.tableCell}>{row.question_name}</Text>
              </View>
            ) : (
              <View></View>
            )}
          </View>
        </View>
      ))}
    </View>
  );
};

const PdfCurriculumDetail = ({ visibe, filter, curriculumList, curriculumNumber }: Props) => {
  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap>
        <FullPage>
          <View style={styles.header}>
            <Text style={styles.textHeader}>カリキュラムマスタ　階層リスト</Text>
          </View>
          <View style={styles.subscription}>
            <View>
              <Text style={styles.textSubscription}>
                カリキュラムマスタの階層リストです。階層の確認と編集が可能です。編集は編集マークの行に設定されている各階層を編集できます。
              </Text>
            </View>
          </View>
          <View style={styles.divider}></View>
          <View style={styles.searchPanel}>
            <View style={styles.searchItem}>
              <Text style={styles.label}>公開ステータス</Text>
              <View style={styles.selectBox}>
                {Number(filter.publish) === 1 ? (
                  <View
                    style={{ ...styles.selectText, flexDirection: 'row', alignItems: 'center' }}
                  >
                    <View style={{ transform: 'scale(0.7)' }}>
                      <UnPublishedPdf />
                    </View>
                    <Text style={{ ...styles.selectText, marginLeft: 3 }}>未公開</Text>
                  </View>
                ) : Number(filter.publish) === 2 ? (
                  <View
                    style={{ ...styles.selectText, flexDirection: 'row', alignItems: 'center' }}
                  >
                    <View style={{ transform: 'scale(0.7)' }}>
                      <TickCompletePdf />
                    </View>
                    <Text style={{ marginLeft: 3 }}>公開中</Text>
                  </View>
                ) : Number(filter.publish) === 3 ? (
                  <View
                    style={{ ...styles.selectText, flexDirection: 'row', alignItems: 'center' }}
                  >
                    <View style={{ transform: 'scale(0.7)' }}>
                      <StopPublicPdf />
                    </View>
                    <Text style={{ marginLeft: 3 }}>公開停止中</Text>
                  </View>
                ) : filter.publish === 'none' ? (
                  <Text style={{ ...styles.selectText, marginLeft: 3 }}>指定なし</Text>
                ) : (
                  <View
                    style={{ ...styles.selectText, flexDirection: 'row', alignItems: 'center' }}
                  >
                    <View style={{ transform: 'scale(0.7)' }}>
                      <WhiteEditingPdf />
                    </View>
                    <Text style={{ marginLeft: 3 }}>編集中</Text>
                  </View>
                )}
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>カリキュラム名</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>
                  {filter.curriculum_name == 'none' ? '指定なし' : filter.curriculum_name || ''}
                </Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>第１階層名</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>
                  {filter.level1_name == 'none'
                    ? '指定なし'
                    : filter.level1_name == 'empty'
                    ? '（空白）'
                    : filter.level1_name || ''}
                </Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>第２階層名</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>
                  {filter.level2_name == 'none'
                    ? '指定なし'
                    : filter.level2_name == 'empty'
                    ? '（空白）'
                    : filter.level2_name || ''}
                </Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>第３階層名</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>
                  {filter.level3_name == 'none'
                    ? '指定なし'
                    : filter.level3_name == 'empty'
                    ? '（空白）'
                    : filter.level3_name || ''}
                </Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>第４階層名</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>
                  {filter.level4_name == 'none'
                    ? '指定なし'
                    : filter.level4_name == 'empty'
                    ? '（空白）'
                    : filter.level4_name || ''}
                </Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>設問名</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>
                  {filter.question_name == 'none' ? '指定なし' : filter.question_name || ''}
                </Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
          </View>
          <View style={styles.body}>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>1</Text>
              <Text style={styles.textCountNumber}>-</Text>
              <Text style={styles.textCountNumber}>{curriculumList.length}</Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{curriculumNumber}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
            <DataTable visibe={visibe} dataSource={curriculumList.slice(0, FIRST_PAGE_ROW_COUNT)} />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {curriculumList.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(
          curriculumList.slice(FIRST_PAGE_ROW_COUNT, curriculumList.length),
          PAGE_ROW_COUNT
        ).map((page, index) => (
          <Page
            key={index}
            size={pageSize}
            orientation={pageOrientation}
            style={[styles.page, styles.pagePadding]}
            wrap={false}
          >
            <FullPage>
              <DataTable visibe={visibe} dataSource={page} />
            </FullPage>
            <PageNumber />
          </Page>
        ))}
    </Document>
  );
};

export default PdfCurriculumDetail;
