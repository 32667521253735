import { Text, View } from '@react-pdf/renderer';
import styles from './styles';
import React from 'react';
import Label from 'components/ReactPDF/Label';
import { EllipsePdf, RadioCheckedPdf } from 'assets/svgPDF';
import { IOption } from 'types/config';

interface ITextBoxProps {
  label?: string;
  required?: boolean;
  value?: string | number;
  options?: IOption[];
}

const TextBox = (props: ITextBoxProps) => {
  const { label, required, value, options } = props;
  return (
    <View style={styles.radioGroup} wrap={false}>
      <Label label={label} required={required} />
      <View style={[styles.groupBox, { width: 150 * (options?.length || 1) + 1 }]}>
        {(options || []).map((option, index) => (
          <View
            key={option.value}
            style={[
              styles.groupItem,
              {
                borderRight: index === (options || []).length - 1 ? 'none' : '1px solid #E2E2E2',
              },
            ]}
          >
            {value === option.value ? <RadioCheckedPdf /> : <EllipsePdf />}
            <Text style={styles.value}>{option.label}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

export default TextBox;
