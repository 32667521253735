import React from 'react';
import { Image } from 'antd';

import { SectionStyled } from './styles';
import { Modal } from 'components';

interface Props {
  index?: number;
  item?: string;
  isPreviewVisible?: number;
  onSubmit?: () => Promise<void> | void;
  setPreviewImageAnswer: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const ModalPreviewImage: React.FC<Props> = ({
  item,
  index,
  isPreviewVisible,
  setPreviewImageAnswer,
}) => {
  const handleToggleModal = () => {
    setPreviewImageAnswer(undefined);
  };

  return (
    <Modal
      centered
      width={800}
      open={isPreviewVisible === index}
      footer={null}
      onCancel={() => handleToggleModal()}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'unset',
      }}
      destroyOnClose
      closable={false}
      className="modal-preview-img"
    >
      <SectionStyled>
        <Image className="file-attach" src={item} preview={false} />
      </SectionStyled>

      <button
        onClick={() => {
          setPreviewImageAnswer(undefined);
        }}
        className="close-img"
      >
        閉じる
      </button>
    </Modal>
  );
};

export default ModalPreviewImage;
