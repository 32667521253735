import React, { useMemo, useState } from 'react';

import { FormOutlined } from '@ant-design/icons';
import { Col, Row, UploadFile } from 'antd';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { CreateEditCurriculum, EditLevelCurriculum } from '../../Modal';
import CreateQuestion from 'pages/QuestionMaster/Modal/CreateQuestion';
import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import { curriculumSelector } from 'pages/Curriculum/selectors';
import { convertFileResponse } from 'libs/utils/question';
import { getPresignedUrlByFileID } from 'services/r2Service';
import CompletedModal from 'components/Modal/Completed';
import { ErrorBoundary, Modal } from 'components';
import { VisiblePopupEditType } from '..';
import { useAppDispatch } from 'hooks';
import SectionStyled from './styles';

interface Props {
  setVisible: React.Dispatch<React.SetStateAction<VisiblePopupEditType>>;
  handleFetchData: () => Promise<void>;
  visible: VisiblePopupEditType;
}

const EditModal: React.FC<Props> = ({
  visible: { visible, rowKey, publish },
  handleFetchData,
  setVisible,
}) => {
  const [isOpenModalEditCurriculum, setOpenModalEditCurriculum] = useState<boolean>(false);
  const [isOpenModalEditLevel, setOpenModalEditLevel] = useState<boolean>(false);
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);
  const [files, setFiles] = useState<UploadFile<File>>();

  const [isOpenModalEditQuestion, setOpenModalEditQuestion] = useState<{
    question_id?: string;
    visible: boolean;
    type: 'create' | 'edit';
  }>({
    visible: false,
    type: 'edit',
  });
  const [itemEdit, setItemEdit] = useState<{
    id: string;
    name?: string;
    level?: number;
    description?: string;
  }>();

  const { dataCurriculumTableFilled } = useSelector(curriculumSelector);

  const dispatch = useAppDispatch();

  const curriculumDetail = useMemo(() => {
    return find(dataCurriculumTableFilled, {
      rowKey: rowKey,
    });
  }, [dataCurriculumTableFilled, rowKey]);

  const handleGetFileCurriculum = async () => {
    if (!visible) return;
    const fileQuestion: UploadFile<File> = { uid: '', name: '' };
    dispatch(startLoading());
    if (curriculumDetail?.curriculum_fileID) {
      const fileName = extractFileName(curriculumDetail?.curriculum_fileID);
      const nodeFileUrl = (await getPresignedUrlByFileID(
        curriculumDetail?.curriculum_fileID
      )) as string;
      const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
      Object.assign(
        fileQuestion,
        convertFileResponse({
          file: fileFromUrl,
          fileID: curriculumDetail.curriculum_fileID,
          fileName: fileName,
        })
      );
      setFiles(fileQuestion);
    }
    dispatch(stopLoading());
  };

  return (
    <Modal
      title={<h3 className="title">カリキュラムマスタ階層編集</h3>}
      visible={visible}
      width={860}
      onCancel={() => {
        setVisible({
          visible: false,
          rowKey: '',
        });
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      cancelButton={{
        text: '閉じる',
        onClick: () => {
          setVisible({
            visible: false,
            rowKey: '',
          });
        },
      }}
    >
      <SectionStyled publish={publish}>
        <p className="sub-title">編集したい階層の編集ボタンをボタンをクリックしてください。</p>
        {curriculumDetail?.curriculum_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              カリキュラム名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curriculumDetail?.curriculum_name}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    disabled={publish}
                    onClick={() => {
                      if (!curriculumDetail?.curriculum_id || publish) return;
                      setItemEdit({
                        id: curriculumDetail.curriculum_id,
                        name: curriculumDetail?.curriculum_name,
                        description: curriculumDetail?.curriculum_description,
                      });
                      handleGetFileCurriculum();
                      setOpenModalEditCurriculum(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {curriculumDetail?.level1_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              第１階層名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curriculumDetail?.level1_name || '(空白)'}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    disabled={publish}
                    onClick={() => {
                      if (!curriculumDetail?.level1_i_id || publish) return;
                      setItemEdit({
                        id: curriculumDetail?.level1_i_id,
                        level: 1,
                        name: curriculumDetail?.level1_name,
                      });
                      setOpenModalEditLevel(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {curriculumDetail?.level2_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              第２階層名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curriculumDetail?.level2_name || '(空白)'}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    disabled={publish}
                    onClick={() => {
                      if (!curriculumDetail?.level2_i_id || publish) return;
                      setItemEdit({
                        id: curriculumDetail?.level2_i_id,
                        level: 2,
                        name: curriculumDetail?.level2_name,
                      });
                      setOpenModalEditLevel(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {curriculumDetail?.level3_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              第３階層名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curriculumDetail?.level3_name || '(空白)'}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    disabled={publish}
                    onClick={() => {
                      if (!curriculumDetail?.level3_i_id || publish) return;
                      setItemEdit({
                        id: curriculumDetail?.level3_i_id,
                        level: 3,
                        name: curriculumDetail?.level3_name,
                      });
                      setOpenModalEditLevel(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {curriculumDetail?.level4_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              第４階層名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curriculumDetail?.level4_name || '(空白)'}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    disabled={publish}
                    onClick={() => {
                      if (!curriculumDetail?.level4_i_id || publish) return;
                      setItemEdit({
                        id: curriculumDetail?.level4_i_id,
                        level: 4,
                        name: curriculumDetail?.level4_name,
                      });
                      setOpenModalEditLevel(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {curriculumDetail?.question_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              設問名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curriculumDetail?.question_name || '(空白)'}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    disabled={publish}
                    onClick={() => {
                      if (!curriculumDetail?.question_code_i_id || publish) return;
                      setItemEdit({
                        id: curriculumDetail?.question_code_i_id,
                      });
                      setOpenModalEditQuestion({
                        question_id: curriculumDetail?.question_code_i_id,
                        visible: true,
                        type: 'edit',
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </SectionStyled>
      <CreateEditCurriculum
        type="edit"
        textSubmit="更新"
        id={itemEdit?.id}
        title="カリキュラム編集"
        name={itemEdit?.name}
        setVisibleSuccess={setVisibleSuccess}
        visible={isOpenModalEditCurriculum}
        description={itemEdit?.description}
        setVisible={setOpenModalEditCurriculum}
        subTitle="カリキュラム名・説明の編集が可能です。編集後に更新ボタンをクリックしてください。"
        fileCurriculum={files}
      />
      <EditLevelCurriculum
        node={{ node: { i_id: itemEdit?.id, name: itemEdit?.name }, columnIndex: itemEdit?.level }}
        setVisible={setOpenModalEditLevel}
        visible={isOpenModalEditLevel}
        rootNode={curriculumDetail!}
      />
      <ErrorBoundary>
        <CreateQuestion
          page={1}
          setVisibleSuccess={setVisibleSuccess}
          openModalCreateEditQuestion={isOpenModalEditQuestion}
          setOpenModalCreateEditQuestion={setOpenModalEditQuestion}
          fetchData={handleFetchData}
        />
      </ErrorBoundary>
      <CompletedModal
        visible={visibleSuccess}
        setVisible={setVisibleSuccess}
        title="登録が完了しました"
        onSubmit={() => {
          setVisibleSuccess(!visibleSuccess);
        }}
      />
    </Modal>
  );
};

export default EditModal;
