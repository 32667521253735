import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Types from 'types';
import { AnyObject } from 'types';
import { services } from 'services';
import {
  COMPANIES,
  COMPANIES_PARTNER,
  REPORT_USER_INFORMATION_COMPANY,
  REPORT_USER_INFORMATION_PARTNER,
} from 'configs';
import { toObject } from 'libs';
import {
  CalculatedReport,
  CompanySelect,
  TargetMonthReport,
} from 'types/datastores/user_report_information';
import { Calculate } from '@syncfusion/ej2-react-spreadsheet';
import {
  calculateCountTotalCompany,
  calculateDataReport,
} from 'libs/utils/report/user-information';
import { browserLogger } from 'libs/logger';

export const getCompanySelect = createAsyncThunk<
  Types.UserReportInformation.CompanySelect,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getCompanySelectReportUserInformation', async (req, { rejectWithValue }) => {
  try {
    const [companies, partners] = await Promise.all([
      services.search<Types.CorporateUserInformationType>(COMPANIES.name, {
        ...req,
      }),
      services.searchPartner<Types.CorporateUserInformationType>(COMPANIES_PARTNER.name, {
        ...req,
      }),
    ]);
    browserLogger.info(
      'report/thunk/getCompanySelectReportUserInformation',
      COMPANIES.name,
      companies
    );
    browserLogger.info(
      'report/thunk/getCompanySelectReportUserInformation',
      COMPANIES_PARTNER.name,
      partners
    );
    return {
      companies: companies.data.items || [],
      partners: partners.data.items || [],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getReportUserInformation = createAsyncThunk<
  Types.UserReportInformation.UserReportInformation,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getReportUserInformation', async (req, { rejectWithValue }) => {
  try {
    const [companyReports, companies, partnerReports, partners] = await Promise.all([
      services.search<Types.UserReportInformation.UserReportInformationResponse>(
        REPORT_USER_INFORMATION_COMPANY.name,
        req
      ),
      services.search<Types.CorporateUserInformationType>(COMPANIES.name, {
        page: 1,
        per_page: 0,
      }),
      services.searchPartner<Types.UserReportInformation.UserReportInformationResponse>(
        REPORT_USER_INFORMATION_PARTNER.name,
        req
      ),
      services.searchPartner<Types.CorporateUserInformationType>(COMPANIES_PARTNER.name, {
        page: 1,
        per_page: 0,
      }),
    ]);
    browserLogger.info(
      'report/thunk/getReportUserInformation',
      REPORT_USER_INFORMATION_COMPANY.name,
      companyReports
    );
    browserLogger.info('report/thunk/getReportUserInformation', COMPANIES.name, companies);
    browserLogger.info(
      'report/thunk/getReportUserInformation',
      REPORT_USER_INFORMATION_PARTNER.name,
      partnerReports
    );
    browserLogger.info('report/thunk/getReportUserInformation', COMPANIES_PARTNER.name, partners);
    const dateCondition = req.conditions?.find((obj) => obj.id === 'target_month');
    const dates = dateCondition ? dateCondition.search_value : [];
    const calculatedPartnerReport = calculateDataReport(
      partnerReports.data.items,
      partners.data.items,
      dates
    );
    const calculatedCountPartnerReport = calculateCountTotalCompany(
      partnerReports.data.items,
      dates
    );
    const companyReportItems = companyReports.data.items;
    const individualUsers = companyReportItems.filter((obj) => obj.company_id === '000000000');
    const corporateUsers = companyReportItems.filter((obj) => obj.company_id !== '000000000');
    const calculatedIndividualUsers = calculateDataReport(
      individualUsers,
      companies.data.items,
      dates
    );
    const calculatedCorporateUsers = calculateDataReport(
      corporateUsers,
      companies.data.items,
      dates
    );

    const calculatedCountIndividualUsers = calculateCountTotalCompany(individualUsers, dates);
    const calculatedCountICorporateUsers = calculateCountTotalCompany(corporateUsers, dates);

    const totalCompanyAdded = dates.reduce(
      (acc: TargetMonthReport, date: string) => ({
        ...acc,
        [date]:
          calculatedCountIndividualUsers.company_added[date] +
          calculatedCountICorporateUsers.company_added[date],
      }),
      {}
    );

    const companyEmpAdded = dates.reduce(
      (acc: TargetMonthReport, date: string) => ({
        ...acc,
        [date]:
          calculatedIndividualUsers.emp_user_added[date] +
          calculatedCorporateUsers.emp_user_added[date],
      }),
      {}
    );

    const totalCompanyDeleted = dates.reduce(
      (acc: TargetMonthReport, date: string) => ({
        ...acc,
        [date]:
          calculatedCountIndividualUsers.company_deleted[date] +
          calculatedCountICorporateUsers.company_deleted[date],
      }),
      {}
    );

    const companyEmpDeleted = dates.reduce(
      (acc: TargetMonthReport, date: string) => ({
        ...acc,
        [date]:
          calculatedIndividualUsers.emp_user_deleted[date] +
          calculatedCorporateUsers.emp_user_deleted[date],
      }),
      {}
    );

    const totalCompanyMonthEnd = dates.reduce(
      (acc: TargetMonthReport, date: string) => ({
        ...acc,
        [date]:
          calculatedCountIndividualUsers.month_end_company[date] +
          calculatedCountICorporateUsers.month_end_company[date],
      }),
      {}
    );

    const calculatedCompanies: CalculatedReport = {
      emp_user_added: companyEmpAdded,
      emp_user_deleted: companyEmpDeleted,
      month_end_users: dates.reduce(
        (acc: TargetMonthReport, date: string) => ({
          ...acc,
          [date]:
            calculatedIndividualUsers.month_end_users[date] +
            calculatedCorporateUsers.month_end_users[date],
        }),
        {}
      ),
      max_users: dates.reduce(
        (acc: TargetMonthReport, date: string) => ({
          ...acc,
          [date]:
            calculatedIndividualUsers.max_users[date] + calculatedCorporateUsers.max_users[date],
        }),
        {}
      ),
      churn_rate: dates.reduce(
        (acc: TargetMonthReport, date: string) => ({
          ...acc,
          [date]: (companyEmpDeleted[date] / (companyEmpAdded[date] || 1)) * 100,
        }),
        {}
      ),
      company_deleted: totalCompanyDeleted,
      company_added: totalCompanyAdded,
      month_end_company: totalCompanyMonthEnd,
    };

    const totalEmpAdded = dates.reduce(
      (acc: TargetMonthReport, date: string) => ({
        ...acc,
        [date]:
          calculatedCompanies.emp_user_added[date] + calculatedPartnerReport.emp_user_added[date],
      }),
      {}
    );

    const totalEmpDeleted = dates.reduce(
      (acc: TargetMonthReport, date: string) => ({
        ...acc,
        [date]:
          calculatedCompanies.emp_user_deleted[date] +
          calculatedPartnerReport.emp_user_deleted[date],
      }),
      {}
    );

    const calculatedTotalAll: CalculatedReport = {
      emp_user_added: totalEmpAdded,
      emp_user_deleted: totalEmpDeleted,
      month_end_users: dates.reduce(
        (acc: TargetMonthReport, date: string) => ({
          ...acc,
          [date]:
            calculatedCompanies.month_end_users[date] +
            calculatedPartnerReport.month_end_users[date],
        }),
        {}
      ),
      max_users: dates.reduce(
        (acc: TargetMonthReport, date: string) => ({
          ...acc,
          [date]: calculatedCompanies.max_users[date] + calculatedPartnerReport.max_users[date],
        }),
        {}
      ),
      churn_rate: dates.reduce(
        (acc: TargetMonthReport, date: string) => ({
          ...acc,
          [date]: (totalEmpDeleted[date] / (totalEmpAdded[date] || 1)) * 100,
        }),
        {}
      ),
    };

    return {
      companies: companyReports.data.items,
      partners: partnerReports.data.items,
      calculateData: {
        total: calculatedTotalAll,
        companies: calculatedCompanies,
        partners: {
          ...calculatedPartnerReport,
          ...calculatedCountPartnerReport,
        },
        individualUsers: {
          ...calculatedIndividualUsers,
          ...calculatedCountIndividualUsers,
        },
        corporateUsers: {
          ...calculatedCorporateUsers,
          ...calculatedCountICorporateUsers,
        },
      },
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});
