import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

import { HEADER_ADMIN_RELEASE_NOTE_MANAGEMENT } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import ManageReleasePDF from './ManageReleasePDF/ManageReleasePDF';
import { publicManagerSelector } from '../../selectors';
import ReleaseNoteEditing from '../ReleaseNoteEditing';
import Completed from 'components/Modal/Completed';
import { loadingRef } from 'components/Loading';
import ReleaseNewNote from '../ReleaseNewNote';
import ManageReleaseStyled from './styles';
import ReleaseNote from '../ReleaseNote';
import { useAppDispatch } from 'hooks';
import { getStatus } from '../../';
import * as Types from 'types';
import {
  getDataReleaseNoteID,
  getCurriculumPublishedHistory,
  getReleaseNoteDetail,
} from '../../thunk';
import { pdf } from '@react-pdf/renderer';
import PDFManageReleaseDocument from './PDFManageRelease';
import { PagingNumber } from 'components';
import PerPageSelect from 'components/PerPageSelect';
import { SELECT_RECORD } from 'constant/select.constants';
import { authSelector } from 'containers/Auth/selectors';
import { exportCsv } from 'libs/utils/exportCsv';

interface Props {
  visible: boolean;
  selectedRows: Array<Types.OfficialCurriculumPublishingSettings.ResponseType>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  permissionNumber?: number;
  selectedCurriculum: Types.OfficialCurriculumPublishingSettings.ResponseType;
}

const { Option } = Select;

const Index: React.FC<Props> = ({
  visible,
  setVisible,
  selectedRows,
  permissionNumber,
  selectedCurriculum,
}) => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [visibleReleaseNote, setVisibleReleaseNote] = useState<boolean>(false);
  const [visibleReleaseEditing, setVisibleEditing] = useState<boolean>(false);
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [visibleNewNote, setVisibleNewNote] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [publish, setPublish] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const { releaseNoteDetail } = useSelector(publicManagerSelector);
  const [selectedRelease, setSelectedRelease] =
    useState<Types.AdminReleaseNoteManagement.ResponseType>();
  const { userInfo } = useSelector(authSelector);

  const ref = useRef(null);

  const { loading, dataReleaseNoteID, curriculumPublishedHistory } =
    useSelector(publicManagerSelector);
  const dispatch = useAppDispatch();
  const [releaseNoteData, setReleaseNoteData] = useState<any>([]);
  const [missingHistoryId, setMissingHistoryId] = useState<boolean>(false);

  useEffect(() => {
    const index = dataReleaseNoteID.findIndex((item) => !item.curriculum_publish_history_id);
    if (index < 0) setMissingHistoryId(true);
    const data = dataReleaseNoteID.map((rawReleaseNote) => {
      const published = curriculumPublishedHistory?.find(
        (history) => history.history_id === rawReleaseNote.curriculum_publish_history_id
      );
      return {
        ...rawReleaseNote,
        publish_start_date: published?.publish_start_date || '',
        release_version: published?.version || '',
        publish_type: '',
        release_note_id: rawReleaseNote.release_note_id,
        release_note_title: rawReleaseNote.release_note_title,
        updatedat: rawReleaseNote.updatedat,
        createdby: rawReleaseNote.createdby,
        release_note_publish_status: Number(rawReleaseNote.publish_status),
        official_curriculum_name: '',
      };
    });
    setReleaseNoteData([...data, ...(index < 0 || !data.length ? [{}] : [])]);
  }, [dataReleaseNoteID, curriculumPublishedHistory]);

  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleExportCSV = async (value: string) => {
    // const resultAction = await dispatch(
    //   getDataAdminReleaseNoteManagementExport({
    //     conditions: [
    //       {
    //         id: 'provider_id',
    //         search_value: [selectedRows[0]?.provider_id],
    //       },
    //       {
    //         id: 'official_curriculum_code',
    //         search_value: [selectedRows[0]?.official_curriculum_code],
    //       },
    //     ],
    //     page: 1,
    //     per_page: 0,
    //   })
    // );
    if (value === 'csv') {
      const listCsv = releaseNoteData.map((item: any, index: number) => ({
        index: index + 1,
        publish_start_date: item.publish_start_date
          ? `${dayjs(item.publish_start_date).locale('ja').format('YYYY/MM/DD HH:mm')}`
          : '',
        version: item.version || '-',
        publish_type: !index
          ? ' 初回公開'
          : index === releaseNoteData.length - 1
          ? item.release_note_id && missingHistoryId
            ? '更新'
            : '未更新'
          : '更新',
        release_note_id: !item.release_note_id ? '未作成' : '作成済',
        release_note_title: !item.release_note_title ? '-' : item.release_note_title,
        updateat:
          !item.createdat && !item.updatedat
            ? '-'
            : !item.updatedat
            ? `${dayjs(item.createdat).locale('ja').format('YYYY/MM/DD HH:mm')}`
            : `${dayjs(item.updatedat).locale('ja').format('YYYY/MM/DD HH:mm')}`,
        createdby: item.createdby || '-',
        release_note_publish_status:
          item.release_note_publish_status === 0
            ? '編集中'
            : item.release_note_publish_status === 1
            ? '未公開'
            : item.release_note_publish_status === 2
            ? '公開中'
            : item.release_note_publish_status === 3
            ? '公開停止中'
            : '-',
      }));
      // const csvString = [
      //   HEADER_ADMIN_RELEASE_NOTE_MANAGEMENT.map(({ label }) => label),
      //   ...listCsv.map((item: any) => Object.values(item)),
      // ]
      //   .map((e) => e.join(','))
      //   .join('\n');
      // const bom = '';
      // const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      // saveAs(file, 'OFFICIALカリキュラム公開管理.csv');
      exportCsv(listCsv, HEADER_ADMIN_RELEASE_NOTE_MANAGEMENT, 'OFFICIALカリキュラム公開管理.csv');
    } else {
      const blob = await pdf(
        <PDFManageReleaseDocument
          dataSource={releaseNoteData}
          official_curriculum_name={selectedCurriculum?.name}
          official_curriculum_code={selectedCurriculum?.official_curriculum_code}
          creator={selectedCurriculum?.creator || ''}
          publish={selectedRows[0]?.publish.toString()}
        />
      ).toBlob();
      saveAs(blob, 'OFFICIALカリキュラム公開管理.pdf');
    }
    setShowConfirmExportFileModal(false);
  };

  const fetchDataAdminReleaseNoteManagement = useCallback(() => {
    if (visible && selectedRows.length) {
      Promise.all([
        dispatch(
          getDataReleaseNoteID({
            conditions: [
              {
                id: 'curriculum_code',
                search_value: [selectedRows[0]?.official_curriculum_code],
              },
            ],
            sort_fields: [
              {
                id: 'version',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
            include_item_ref: true,
            omit_fields_data: true,
            use_display_id: true,
          })
        ),
        dispatch(
          getCurriculumPublishedHistory({
            conditions: [
              {
                id: 'official_curriculum_code',
                search_value: [selectedRows[0]?.official_curriculum_code],
              },
            ],
            sort_fields: [
              {
                id: 'version',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
            include_item_ref: true,
            omit_fields_data: true,
            use_display_id: true,
          })
        ),
      ]);
    }
  }, [visible, selectedRows, dispatch]);

  const columns: ColumnsType<Types.AdminReleaseNoteManagement.ResponseType> = [
    {
      title: 'No.',
      dataIndex: '',
      key: 'No',
      width: '3.5%',
      render: (_value, _record, index) => <span className="no">{index + 1}</span>,
    },
    {
      title: (
        <span>
          OFFICIALカリキュラム
          <br />
          公開日時（更新日時）
        </span>
      ),
      width: '15%',
      dataIndex: 'publish_start_date',
      key: 'publish_start_date',
      render: (record) => record && dayjs(record).locale('ja').format('YYYY/MM/DD HH:mm'),
    },
    {
      title: 'バージョン',
      dataIndex: 'version',
      align: 'center',
      width: '7%',
    },
    {
      title: '公開種類',
      dataIndex: 'publish_type',
      key: 'publish_type',
      align: 'center',
      width: '6%',
      render: (item, record, index) =>
        !index
          ? ' 初回公開'
          : index === releaseNoteData.length - 1
          ? record.release_note_id && missingHistoryId
            ? '更新'
            : '未更新'
          : '更新',
    },
    {
      title: (
        <span>
          リリースノート
          <br />
          作成状況
        </span>
      ),
      dataIndex: 'release_note_id',
      key: 'release_note_id',
      align: 'center',
      width: '9%',
      render: (record) => (!record ? '未作成' : '作成済'),
    },
    {
      title: 'リリースノートタイトル',
      dataIndex: 'release_note_title',
      key: 'release_note_title',
      render: (record) => (!record ? '_' : <div className="release_note_title">{record}</div>),
      align: 'left',
      width: '18%',
    },
    {
      title: (
        <span>
          リリースノート
          <br />
          作成・更新日時
        </span>
      ),
      dataIndex: 'updatedat',
      key: 'updatedat',
      align: 'center',
      render: (item, record) =>
        !record.createdat && !record.updatedat
          ? '_'
          : !record.updatedat
          ? dayjs(record.createdat).format('YYYY/MM/DD  HH:mm')
          : dayjs(record.updatedat).format('YYYY/MM/DD  HH:mm'),
    },
    {
      title: '作成者',
      dataIndex: 'createdby',
      key: 'createdby',
      align: 'center',
      width: '7%',
      render: (record) => (!record ? '_' : record),
    },
    {
      title: (
        <span>
          リリースノート
          <br /> 公開ステータス
        </span>
      ),
      dataIndex: 'release_note_publish_status',
      key: 'release_note_publish_status',
      align: 'left',
      width: '10%',
      render: (record) => (record === undefined ? '_' : getStatus(record)),
    },
    {
      title: 'アクション',
      dataIndex: 'release_note_id',
      key: 'release_note_id',
      align: 'center',
      render: (_text, record) =>
        !record?.release_note_id && missingHistoryId ? (
          <Button
            disabled={permissionNumber !== 2}
            className={`list-release create-new ${permissionNumber !== 2 ? 'disabled' : ''}`}
            onClick={() => {
              setPublish(Number(record?.release_note_publish_status));
              setVisibleNewNote(true);
              setSelectedRelease(record);
            }}
          >
            新規作成
          </Button>
        ) : (
          <div className="wrap-button-table">
            <Button
              className="list-release release-left"
              onClick={() => {
                setPublish(Number(record?.release_note_publish_status));
                // setVisibleReleaseNote(true);
                // setSelectedRelease(record);
                handleDetail(record);
              }}
            >
              閲覧
            </Button>
            <Button
              disabled={permissionNumber === 1}
              className={`list-release release-right ${permissionNumber === 1 ? 'disabled' : ''}`}
              onClick={() => {
                setPublish(Number(record?.release_note_publish_status));
                setVisibleEditing(true);
                setSelectedRelease(record);
              }}
            >
              編集・削除
            </Button>
          </div>
        ),
    },
  ];

  const component = useMemo(() => {
    return (
      <div
        ref={ref}
        style={{
          width: '100%',
          position: 'absolute',
          right: '9999px',
        }}
      >
        <ManageReleasePDF
          selectedRows={selectedRows}
          permissionNumber={permissionNumber}
          visible={visible}
        />
      </div>
    );
  }, [selectedRows, permissionNumber, visible]);

  useEffect(fetchDataAdminReleaseNoteManagement, [fetchDataAdminReleaseNoteManagement]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerpage(releaseNoteData.length);
    }
  }, [releaseNoteData]);

  const handleDetail = async (record: Types.AdminReleaseNoteManagement.ResponseType) => {
    if (record.release_note_id && userInfo) {
      await Promise.all([
        dispatch(
          getReleaseNoteDetail({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'release_note_id',
                search_value: [record?.release_note_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]).then((resp) => {
        console.log('resp', resp);
        setVisibleReleaseNote(true);
        setSelectedRelease(record);
      });
    }
  };

  return (
    <ManageReleaseStyled
      isEmptyData={!dataReleaseNoteID.length}
      title={<span className="title">リリースノート管理</span>}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      max-width={1600}
      width={'85%'}
      visible={visible}
      cancelButton={{
        text: '閉じる',
        onClick: handleToggleModal,
      }}
      onCancel={handleToggleModal}
    >
      {component}
      <p className="sub-title">
        OFFICIALカリキュラムの公開・編集日時別のリリースノート作成状況の一覧画面です。
      </p>
      <div>
        <label>対象OFFICIALカリキュラム： </label>
        <div className="wrap-targer-official">
          <div className="wrap-left-side">
            <div className="text-left">
              <span>{selectedCurriculum?.name}</span>
              <span>（ID：{selectedCurriculum?.official_curriculum_code})</span>
            </div>
            <div className="wrap-creator">
              <span className="label-creator">作成者：</span>
              <span>{selectedCurriculum?.creator}</span>
            </div>
          </div>
          <div className="wrap-right-side">{getStatus(selectedRows[0]?.publish)}</div>
        </div>
      </div>
      <div className="wrap-button">
        <Button className="btn-active" onClick={() => setShowConfirmExportFileModal(true)}>
          <CloudDownloadOutlined
            style={{
              fontSize: 20,
            }}
          />
          <span>エクスポート</span>
        </Button>
      </div>
      <Table
        key="index"
        pagination={{
          pageSize: perPage,
          position: ['topCenter'],
          total: releaseNoteData.length,
          showSizeChanger: false,
          onChange: setPage,
          showTotal: () => (
            <div className="text-count">
              <PagingNumber
                startItem={releaseNoteData.length ? `${(page - 1) * perPage + 1}` : ''}
                endItem={
                  page * perPage > releaseNoteData.length ? releaseNoteData.length : page * perPage
                }
                totalItem={releaseNoteData.length}
              />
              <PerPageSelect
                data={releaseNoteData}
                perPage={perPage}
                setPage={setPage}
                setPerPage={setPerpage}
              />
            </div>
          ),
        }}
        className="table"
        columns={columns}
        dataSource={releaseNoteData.map((item: any, index: number) => ({ ...item, index }))}
        scroll={{
          y: '35vh',
        }}
      />
      <ReleaseNewNote
        publish={publish}
        visible={visibleNewNote}
        selectedOfficialSetting={selectedCurriculum}
        setVisible={setVisibleNewNote}
        selected={selectedRelease}
      />
      <ReleaseNote
        publish={publish}
        visible={visibleReleaseNote}
        releaseNoteDetail={releaseNoteDetail}
        setVisible={setVisibleReleaseNote}
        selected={selectedRelease}
        publishOfficial={selectedCurriculum}
      />
      <ReleaseNoteEditing
        publish={publish}
        visible={visibleReleaseEditing}
        setVisible={setVisibleEditing}
        selected={selectedRelease}
        setVisibleComplete={setVisibleComplete}
        page={page}
        selectedCurriculum={selectedCurriculum}
      />

      <Completed
        title="リリースノートを保存しました。"
        visible={visibleComplete}
        setVisible={setVisibleComplete}
        onSubmit={() => {
          setVisibleComplete(false);
          setVisibleEditing(false);
          fetchDataAdminReleaseNoteManagement();
        }}
        subTitle="公開ステータスは編集中として表示されます。"
      />

      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
    </ManageReleaseStyled>
  );
};

export default Index;
