export const USER_TYPE = {
  ALL: '全ユーザー',
  CORPORATE: '法人ユーザー',
  PERSONAL: '個人ユーザー',
};

export const SALE_TYPE = {
  ALL: 'ALL',
  USER_FEE: 'ユーザー利用料',
  SKILL_CHECK_USAGE_FEE: 'スキルチェック利用料',
  STORAGE_USAGE_FEE: 'ストレージ利用料',
};

export const SALE_TYPE_USER_FEE_API_KEY = '月額利用料';

export const USER_INFORMATION_COMPANY_TYPE = {
  corporateUsers: '法人ユーザー',
  personalUser: '個人ユーザー',
  partners: 'パートナーユーザー',
};
