import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import { Button } from 'antd';

import { HEADER_AGGREGATION_CHURN_RATE } from 'constant/header.export.constant';
import { settingSelector } from 'containers/AppSettings/selectors';
import { getDataAggregationChurnRateExport } from './thunk';
import SkillCheckImplement from './SkillCheckImplement';
import OfficialCurriculum from './OfficialCurriculum';
import { pdfGenerator } from 'libs/utils/exportPDF';
import { loadingRef } from 'components/Loading';
import UserInfomation from './UserInfomation';
import SaleInfomation from './SaleInfomation';
import { ConfirmExport } from './Modal';
import { useAppDispatch } from 'hooks';
import { Header } from 'components';
import Styled from './styles';
import { exportCsv } from 'libs/utils/exportCsv';

const Dashboard: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleChart, setVisibleChart] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const { headerTitle, loading } = useSelector(settingSelector);

  const dispatch = useAppDispatch();

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getDataAggregationChurnRateExport({
          page: 1,
          per_page: 0,
          use_display_id: true,
          include_item_ref: true,
          use_or_condition: true,
        })
      );
      if (getDataAggregationChurnRateExport.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          i_id: item.i_id,
          target_month: item.target_month,
          number_of_members_beginning_of_month: item.number_of_members_beginning_of_month,
          number_of_members_end_of_month: item.number_of_members_end_of_month,
          churn_rate: item.churn_rate,
          number_of_monthly_enrollments: item.number_of_monthly_enrollments,
          number_of_monthly_withdrawals: item.number_of_monthly_withdrawals,
          active_number_of_members_beginning_of_month:
            item.active_number_of_members_beginning_of_month,
          active_number_of_members_end_of_month: item.active_number_of_members_end_of_month,
          active_churn_rate: item.active_churn_rate,
          active_number_of_monthly_enrollments: item.active_number_of_monthly_enrollments,
          active_number_of_monthly_withdrawals: item.active_number_of_monthly_withdrawals,
          aggregate_user_type: item.aggregate_user_type,
        }));

        // const csvString = [
        //   HEADER_AGGREGATION_CHURN_RATE.map(({ label }) => label),
        //   ...listCsv.map((item) => Object.values(item)),
        // ]
        //   .map((e) => e.join(','))
        //   .join('\n');
        // const bom = '\uFEFF';
        // const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        // saveAs(file, 'ダッシュボード.csv');
        exportCsv(listCsv, HEADER_AGGREGATION_CHURN_RATE, 'ダッシュボード.csv');
      }
    }
    if (value === 'pdf') {
      const content = ref.current
        ? Array.from(ref.current!.children || '')
            .map((child) => {
              const node = child as HTMLElement;
              const height = node.offsetHeight;
              const sort = Number(node.id?.split('-')?.[0] || 0);
              return { node, height, sort };
            })
            .sort((a, b) => a.sort - b.sort)
        : [];
      pdfGenerator(content, 'ダッシュボード');
    }
    setVisible(false);
  };

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      {!visible && <Header title={headerTitle} exportPDF={visible} />}
      <Styled>
        <div className="wrap-button">
          <Button onClick={() => setVisible(true)} className="btn-submit" hidden={visible}>
            エクスポート
          </Button>
        </div>

        <div ref={ref}>
          <div id="0-pdf-wrapper">
            {visible && <Header title="HOME / Dashboard" exportPDF />}
            <div
              style={{
                padding: visible ? '94px 30px 0px 30px' : 0,
                height: visible ? '100vh' : 'auto',
              }}
            >
              <UserInfomation
                visibleChart={visibleChart}
                setVisibleChart={setVisibleChart}
                checked={checked}
                setChecked={setChecked}
              />
            </div>
          </div>
          <div
            id="1-pdf-wrapper"
            style={{
              padding: visible ? '94px 30px 0px 30px' : 0,
              height: visible ? '100vh' : 'auto',
            }}
          >
            <SkillCheckImplement />
          </div>
          <div
            id="2-pdf-wrapper"
            style={{
              padding: visible ? '94px 30px 0px 30px' : 0,
              height: visible ? '100vh' : 'auto',
            }}
          >
            <OfficialCurriculum />
          </div>
          <div
            id="3-pdf-wrapper"
            style={{
              padding: visible ? '94px 30px 0px 30px' : 0,
              height: visible ? '100vh' : 'auto',
            }}
          >
            <SaleInfomation />
          </div>
        </div>
        <ConfirmExport visible={visible} setVisible={setVisible} onSubmit={handleExportCSV} />
      </Styled>
    </>
  );
};

export default Dashboard;
