import React, { useCallback, useEffect, useState } from 'react';
import { FormOutlined, SearchOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Button, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { unionBy } from 'lodash';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

import {
  getIndividualUserManagement,
  getIndividualUserManagementExport,
  getSelectIndividual,
} from './thunk';
import { HEADER_INDIVIDUAL_USER_MANAGEMENT } from 'constant/header.export.constant';
import { Header, PagingNumber, PerPageSelect, SelectField } from 'components';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { individualUserManagementSelect } from './selectors';
import { authSelector } from 'containers/Auth/selectors';
import { filterIndividualByDeleted } from './slice';
import Completed from 'components/Modal/Completed';
import { loadingRef } from 'components/Loading';
import { UserMasterStyled } from './styles';
import { useAppDispatch, usePermission } from 'hooks';
import ModalMemo from './Modal';
import * as Types from 'types';
import { AnyObject } from 'types';
import { formatDate } from 'libs/utils/format';
import { DATE_FORMAT } from 'constant';
import { exportCsv } from 'libs/utils/exportCsv';
import { pdf } from '@react-pdf/renderer';
import PDFIndividualUserManagementTable from 'pages/IndividualUserManagement/PDFIndividualUserManagementTable';
import ActionErrorModal from 'components/Modal/ActionError';
import { SELECT_RECORD } from 'constant/select.constants';

const { Option } = Select;

const DEFAULT_FILTERS = { id: '', user_name: '', deletedat: '', os: '' };

const IndividualUserManagement: React.FC = () => {
  const [selectedRow, setSelectedRow] = useState<Types.IndividualUserManagement[]>([]);
  const [selected, setSelected] = useState<Types.IndividualUserManagement>();
  const [visibleModalMemo, setVisibleModalMemo] = useState(false);
  const [visibleSuccess, setVisibleSuccess] = useState(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const { userInfo } = useSelector(authSelector);
  const { dataIndividualUserManagement, loading, dataSelectInvidual } = useSelector(
    individualUserManagementSelect
  );
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { permissionNumber } = usePermission();

  const dispatch = useAppDispatch();

  const columns: ColumnsType<Types.IndividualUserManagement> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      align: 'left',
      render: (id: string) => <div className="style-code">{id}</div>,
    },
    {
      title: 'ユーザー名',
      dataIndex: 'user_name',
      key: 'user_name',
      width: '10%',
      align: 'left',
    },
    {
      title: 'メールアドレス',
      dataIndex: 'email',
      key: 'email',
      width: '10%',
      align: 'left',
      render: (email: string) => (
        <div
          onClick={(v) => {
            window.location.href = `mailto:${email}`;
          }}
          className="style-email"
        >
          {email}
        </div>
      ),
    },
    {
      title: '契約状況',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      // align: 'left',
      render: (_: string, record: Types.IndividualUserManagement) => (
        <div className="flex-center style-text-status">
          <div
            className={`${record.deletedat ? 'disable-status' : 'active-status'}`}
            style={{
              width: '48px',
              position: 'relative',
              paddingLeft: '8px',
            }}
          >
            {record.deletedat ? '解約' : '契約中'}
          </div>
        </div>
      ),
    },
    {
      title: '初回登録日',
      dataIndex: 'createdat',
      key: 'createdat',
      width: '10%',
      align: 'center',
      render: (record: string) => (!!record ? dayjs(record).format('YYYY/MM/DD') : '-'),
    },
    {
      title: '継続期間',
      dataIndex: 'period',
      key: 'period',
      width: '10%',
      align: 'right',
      render: (_: string, record: Types.IndividualUserManagement) => {
        const years = (record.deletedat ? dayjs(record.deletedat) : dayjs()).diff(
          dayjs(record.createdat),
          'years'
        );
        const months = (record.deletedat ? dayjs(record.deletedat) : dayjs()).diff(
          dayjs(record.createdat),
          'months'
        );
        return `${months === 12 ? years + 1 : years}年${months === 12 ? 0 : months}ヶ月`;
      },
    },
    {
      title: '解約日',
      dataIndex: 'deletedat',
      key: 'end_date',
      width: '10%',
      render: (record: string) => (!!record ? dayjs(record).format('YYYY/MM/DD') : '-'),
    },
    {
      title: 'OS',
      dataIndex: 'os',
      key: 'os',
      width: '10%',
      align: 'center',
      render: (record: number) => (record === 1 ? 'iOS' : 'Android'),
    },
    {
      title: <div className="title-memo">memo</div>,
      dataIndex: 'comment',
      key: 'comment',
      width: 200,
      align: 'left',
      render: (text: string) => (
        <div>
          <div className="style-memo">{text ? text : '-'}</div>
        </div>
      ),
    },
    {
      title: '編集',
      dataIndex: 'operation',
      width: '5%',
      render: (_: string, record: Types.IndividualUserManagement) => (
        <FormOutlined
          onClick={() => {
            if (permissionNumber !== 2) return;
            setSelected(record);
            setVisibleModalMemo(true);
          }}
          className={permissionNumber === 2 ? 'icon' : 'icon-disabled'}
        />
      ),
    },
  ];

  const formik = useFormik({
    initialValues: {
      ...DEFAULT_FILTERS,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setFilters(values);
      const conditions = getConditions(values);
      await fetchIndividualManagement(conditions);
      if (values.deletedat) {
        await dispatch(filterIndividualByDeleted(values.deletedat));
      }
      setPage(1);
    },
    onReset: () => {
      setFilters(DEFAULT_FILTERS);
      fetchIndividualManagement();
      setPage(1);
    },
  });

  const getConditions = (values: AnyObject) => {
    const conditions: Types.ConditionsType[] = [];
    Object.keys(values)
      .filter((e) => e !== 'deletedat')
      .forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value) {
          conditions.push({
            id: key,
            search_value: [value],
            exact_match: true,
          });
        }
      });
    return conditions;
  };

  const fetchIndividualManagement = useCallback(
    async (conditions?: Types.ConditionsType[]) => {
      if (!userInfo) return;
      await dispatch(
        getIndividualUserManagement({
          conditions,
          page: 1,
          per_page: 0,
          include_lookups: true,
          include_item_ref: true,
          use_display_id: true,
          omit_total_items: false,
        })
      );
    },
    [dispatch, userInfo]
  );

  const handleExportCSV = async (value: string) => {
    const resultAction = await dispatch(
      getIndividualUserManagementExport({
        conditions: [
          ...getConditions(filters),
          ...(selectedRow && selectedRow.length
            ? [
                {
                  id: 'id',
                  search_value: [selectedRow.map((row) => row.id).join('|')],
                },
              ]
            : []),
        ],
        page: 1,
        per_page: 0,
        use_display_id: true,
        include_item_ref: true,
        use_or_condition: true,
      })
    );
    if (getIndividualUserManagementExport.fulfilled.match(resultAction)) {
      const items = resultAction.payload.report_results;
      if (value === 'csv') {
        const listCsv = items.map((item) => ({
          id: item.id,
          user_name: item.user_name,
          kana: item.name_furigana,
          email: item.email,
          status: item.deletedat ? '解約' : '契約中',
          createdat: formatDate(item.createdat, DATE_FORMAT),
          period: getUserContractPeriod(item),
          deletedat: formatDate(item.deletedat, DATE_FORMAT),
          comment: item.comment,
          os: `${item.os}` === '1' ? 'iOS' : 'Android',
        }));
        exportCsv(listCsv, HEADER_INDIVIDUAL_USER_MANAGEMENT, '個人ユーザ管理.csv');
      } else {
        const blob = await pdf(
          <PDFIndividualUserManagementTable dataSource={items} filters={filters} />
        ).toBlob();
        saveAs(blob, '個人ユーザ管理.pdf');
      }
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const PopupConfirmExportFileActive = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchIndividualManagement();
    })();
  }, [fetchIndividualManagement]);

  useEffect(() => {
    dispatch(
      getSelectIndividual({
        page: 1,
        per_page: 0,
        include_lookups: true,
        include_item_ref: true,
        use_display_id: true,
        omit_total_items: false,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerpage(dataIndividualUserManagement.length);
    }
  }, [dataIndividualUserManagement]);

  return (
    <UserMasterStyled collapsedMenu={collapsedMenu} isEmptyData={false}>
      <Header title={headerTitle} />
      <div className="container">
        <p className="text-note">個人ユーザーの登録情報の確認・管理を行う画面です。</p>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item name="id" className="item" label={<span className="text-label">ID</span>}>
                <SelectField
                  data-testid="ium-user-id"
                  name="id"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {unionBy(dataSelectInvidual, 'id').map(({ id }, index) => (
                    <Option value={id} key={index}>
                      {id}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="user_name"
                className="item"
                label={<span className="text-label">ユーザー名</span>}
              >
                <SelectField
                  data-testid="ium-user-name"
                  placeholder="指定なし"
                  name="user_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {unionBy(dataSelectInvidual, 'user_name').map(({ user_name }, index) => (
                    <Option value={user_name} key={index}>
                      {user_name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="deletedat"
                className="item"
                label={<span className="text-label">契約状況</span>}
              >
                <SelectField
                  data-testid="ium-contract-status"
                  placeholder="指定なし"
                  name="deletedat"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="1">契約中</Option>
                  <Option value="0">解約</Option>
                </SelectField>
              </Form.Item>
              <Form.Item name="os" className="item" label={<span className="text-label">OS</span>}>
                <SelectField
                  data-testid="ium-os"
                  placeholder="指定なし"
                  name="os"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="1">iOS</Option>
                  <Option value="0">Android</Option>
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <Table
          rowKey="i_id"
          className="table"
          dataSource={dataIndividualUserManagement}
          columns={columns}
          rowSelection={{
            onChange: (_, selectedRows: Types.IndividualUserManagement[]) =>
              setSelectedRow(selectedRows),
          }}
          pagination={{
            pageSize: perPage,
            total: dataIndividualUserManagement.length,
            current: page,
            onChange: setPage,
            showSizeChanger: false,
            position: ['topCenter'],
            showTotal: () => (
              <div className="wrap-select-record">
                <PagingNumber
                  startItem={
                    dataIndividualUserManagement.length ? `${(page - 1) * perPage + 1}` : ''
                  }
                  endItem={
                    page * perPage > dataIndividualUserManagement.length
                      ? dataIndividualUserManagement.length
                      : page * perPage
                  }
                  totalItem={dataIndividualUserManagement.length}
                />
                <PerPageSelect
                  data={dataIndividualUserManagement}
                  perPage={perPage}
                  setPage={setPage}
                  setPerPage={setPerpage}
                />
              </div>
            ),
          }}
        />
        <PopupConfirmExportFile
          isLoadingButtonOk={false}
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ModalMemo
          visible={visibleModalMemo}
          setVisible={setVisibleModalMemo}
          selected={selected}
          setVisibleSuccess={setVisibleSuccess}
        />
        <Completed
          style={{ borderRadius: 4 }}
          bodyStyle={{ padding: 10, borderRadius: 4 }}
          className="success-modal"
          title="更新が完了しました"
          visible={visibleSuccess}
          setVisible={setVisibleSuccess}
          onSubmit={() => {
            fetchIndividualManagement();
          }}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="ユーザーが選択されていません"
          description={
            <p style={{ marginTop: 32, marginBottom: 0 }}>
              ユーザーを選択し、再度実行してください。
            </p>
          }
        />
      </div>
      <div className="wrap-bottom">
        <div className="flex">
          <div className="text-label">
            選択した請求データを処理：
            <Button className="btn btn-active" onClick={PopupConfirmExportFileActive}>
              エクスポート
            </Button>
          </div>
        </div>
      </div>
    </UserMasterStyled>
  );
};

const getUserContractPeriod = (record: AnyObject) => {
  const years = (record.deletedat ? dayjs(record.deletedat) : dayjs()).diff(
    dayjs(record.createdat),
    'years'
  );
  const months = (record.deletedat ? dayjs(record.deletedat) : dayjs()).diff(
    dayjs(record.createdat),
    'months'
  );
  return `${months === 12 ? years + 1 : years}年${months === 12 ? 0 : months}ヶ月`;
};

export default IndividualUserManagement;
