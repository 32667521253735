import React, { MouseEvent, useRef, useState } from 'react';
import { CheckCircleOutlined, CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { UploadFile as UploadFileAntd, UploadChangeParam } from 'antd/lib/upload/interface';
import { message, Upload } from 'antd';

import { SectionStyled } from './styles';
import { Modal } from 'components';
import { useAppDispatch } from 'hooks';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { RcFile } from 'antd/es/upload';
import { VALID_PARTNER_TYPES } from 'constant';
import { includes } from 'lodash';

interface Props {
  visible: boolean;
  onSubmit: (
    file: File,
    setUploadSuccessfully: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadPDF: React.FC<Props> = ({ visible, onSubmit, setVisible }) => {
  const [isUploadSuccessfully, setUploadSuccessfully] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const buttonRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const uploadRef = useRef<any>(null);
  const onClose = () => {
    setVisible(false);
    setFile(undefined);
    setUploadSuccessfully(false);
    setLoading(false);
  };

  const handleDelete = (e: MouseEvent) => {
    e.stopPropagation();
    setFile(undefined);
    return false;
  };

  const handleSubmit = async () => {
    if (!file) return;
    // setLoading(true);
    await onSubmit(file, setUploadSuccessfully);
    setUploadSuccessfully(true);
    setFile(undefined);
    // setLoading(false);
  };

  const afterClose = () => {
    setUploadSuccessfully(false);
    setFile(undefined);
    setLoading(false);
  };

  const beforeUpload = (file: RcFile) => {
    if (!VALID_PARTNER_TYPES.includes(file?.type || '')) {
      message.open({
        type: 'error',
        content: 'このファイル形式は対応しておりません。',
        duration: 3,
        style: {
          color: 'red',
        },
      });
    }
    return false;
  };

  return (
    <Modal
      title="契約書ファイル インポート"
      width={720}
      open={visible}
      maskClosable={false}
      okButton={
        file || isUploadSuccessfully
          ? {
              text: isUploadSuccessfully ? 'OK' : 'インポート',
              onClick: isUploadSuccessfully
                ? onClose
                : async () => {
                    dispatch(startLoading());
                    await handleSubmit();
                    dispatch(stopLoading());
                  },
              loading,
            }
          : undefined
      }
      cancelButton={
        !isUploadSuccessfully
          ? {
              text: 'キャンセル',
              onClick: onClose,
              loading,
            }
          : undefined
      }
      onCancel={onClose}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      afterClose={afterClose}
      // maskClosable={!loading}
    >
      <SectionStyled>
        {!isUploadSuccessfully ? (
          <div className="form-upload">
            <div className="form-upload-border">
              <Upload.Dragger
                ref={uploadRef}
                className="file-upload"
                beforeUpload={beforeUpload}
                onChange={(info: UploadChangeParam<UploadFileAntd<File>>) => {
                  if (!includes(VALID_PARTNER_TYPES, info.file.type)) return;

                  setFile(info.file as unknown as File);
                }}
              >
                {file ? (
                  <div className="info-file">
                    <p className="file-name">{file.name} </p>
                  </div>
                ) : (
                  <>
                    <CloudUploadOutlined className="icon" />
                    <p className="ant-upload-text">
                      インポートするファイルをここにドロップ
                      <br />
                      または
                    </p>
                  </>
                )}
                <div className="flex">
                  <button ref={buttonRef} type="button" className="btn-upload">
                    {file ? 'ファイルを変更' : 'ファイルを選択'}
                  </button>
                  {file && (
                    <button className="btn-delete" type="button" onClick={handleDelete}>
                      <DeleteOutlined className="icon-delete-outlined" />
                      <span className="label-delete">ファイルを削除</span>
                    </button>
                  )}
                </div>
              </Upload.Dragger>
            </div>
          </div>
        ) : (
          <p className="text-successful">
            <CheckCircleOutlined className="icon" />
            インポートが完了しました！
          </p>
        )}
      </SectionStyled>
    </Modal>
  );
};

export default UploadPDF;
