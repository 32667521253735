import styled from 'styled-components';

interface Props {
  visibleCurriculumDevelopment: boolean;
}

export default styled.section<Props>`
  overflow: auto;
  .column-text {
    font-size: 13px;
    display: flex;
    align-items: center;
  }
  .table-row-level-0 {
  }
  .virtual-table-cell {
    // border-bottom: none !important;
  }

  .index {
    margin-right: 10px;
    margin-bottom: 0px;
    font-size: 11px;
    color: #aaaaaa;
  }

  .virtual-table-row-level-1 {
    .level-space {
      padding-left: 24px !important;
    }
    .virtual-table-first-cell-content {
      // border-left: none !important;
    }
  }

  .virtual-table-row-level-1 {
  }

  .font-lato {
    font-family: Lato;
  }

  @media (max-width: 1440px) {
    .column-text {
      font-size: 12px;
    }
  }

  .name {
    text-align: left;
    .number-label {
      padding-right: 15px;
      font-size: 11px;
      color: #aaaaaa;
    }
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  [data-theme='dark'] .virtual-table-cell {
    box-sizing: border-box;
    padding: 16px;
    border: 1px solid #303030;
    background: #141414;
  }

  .empty-data {
    height: 700px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }
  .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: ew-resize;
    display: grid;
    place-content: center;
  }

  .virtual-table-row-level-0 {
    .virtual-table-cell {
      background: #f9f9f9 !important;
    }
  }

  .virtual-table .virtual-table-body .row-total-child .virtual-table-cell {
    background: #fff !important;
  }

  .virtual-table .virtual-table-body .row-total-child-detail .virtual-table-cell {
    background: rgb(249, 249, 249) !important;
  }

  .virtual-table-row.virtual-table-row-level-1:nth-of-type(2n + 1) {
    .virtual-table-first-cell-content,
    .virtual-table-cell {
      background: #f9f9f9 !important;
    }
  }

  .virtual-table-row.virtual-table-row-level-2:nth-of-type(2n + 1) {
    .virtual-table-first-cell-content,
    .virtual-table-cell {
      background: #f9f9f9 !important;
    }
  }

  .level-space {
    background: #fff;
  }

  .virtual-table-row.virtual-table-row-level-1.virtual-table-row-last-child {
    .virtual-table-first-cell-content,
    .virtual-table-cell {
      background: #f9f9f9 !important;
    }

    .level-space {
      background: #f9f9f9 !important;
    }
  }

  .white {
    .virtual-table-row-level-0 .virtual-table-cell {
      background-color: #fff !important;
    }

    .virtual-table
      .virtual-table-body
      .virtual-table-row-level-1
      .virtual-table-cell
      .virtual-table-first-cell-content {
      background-color: #fff !important;
    }

    .virtual-table-row.virtual-table-row-level-1:nth-of-type(2n + 1)
      .virtual-table-first-cell-content {
      background-color: #fff !important;
    }

    .virtual-table-row.virtual-table-row-level-1:nth-of-type(2n + 1) .virtual-table-cell {
      background-color: #fff !important;
    }
  }

  .interleaved {
    .virtual-table-row-level-0:nth-of-type(2n) {
      .virtual-table-cell {
        background-color: #fff !important;
      }
    }
  }
`;
