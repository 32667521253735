import { useSelector, useDispatch } from 'react-redux';
import { modifiedPageSelector } from '../slice/selectors';
import { resetInitialState, setModified, setResetForm } from '../slice/slice';

const useModifiedPage = () => {
  const dispatch = useDispatch();
  const { isModified, resetForm } = useSelector(modifiedPageSelector);

  const setModifiedPage = (modified: boolean) => {
    dispatch(setModified(modified));
  };

  const setResetFormState = (isReset: boolean) => {
    dispatch(setResetForm(isReset));
  };

  const resetState = () => {
    dispatch(resetInitialState());
  };

  return {
    isModified,
    resetForm,
    setModifiedPage,
    resetState,
    setResetFormState,
  };
};

export default useModifiedPage;
