import { AnyObject } from 'types';
import { unparse } from 'papaparse';
import saveAs from 'file-saver';

// export const exportCsv = (dataExport: AnyObject[], headerCsv: AnyObject[], fileName: string) => {
//   const csvHeader = unparse({
//     fields: [...headerCsv.map((item) => item.label)],
//     data: [],
//   });
//   const csvVal = unparse(dataExport, {
//     header: false,
//     columns: [...headerCsv.map((item) => item.key)],
//   });
//   const csvString = csvHeader + csvVal;

//   const bom = '\uFEFF';
//   const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
//   saveAs(file, fileName);
// };

export const exportCsv = (dataExport: AnyObject[], headerCsv: AnyObject[], fileName: string) => {
  const convertHeader = headerCsv.map((item) => {
    return {
      ...item,
      label: formatTextCsv(item.label),
    };
  });

  const convertData = dataExport.map((item) => {
    for (const key in item) {
      item[key] = formatTextCsv(item[key]);
    }
    return item;
  });

  const csvHeader = unparse({
    fields: [...convertHeader.map((item) => item.label)],
    data: [],
  });

  const csvVal = unparse(convertData, {
    header: false,
    columns: [...headerCsv.map((item) => item.key)],
    escapeFormulae: true,
  });

  const csvString = csvHeader + csvVal;

  const bom = '\uFEFF';
  const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
  saveAs(file, fileName);
};

export const formatTextCsv = (text: string) => `\u200B${text || ''}`;
