import { Document, Font, Page, View } from '@react-pdf/renderer';
import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';
import styles from './styles';
import Header from 'components/ReactPDF/Header';
import Section from 'components/ReactPDF/Section';
import TextBox from 'components/ReactPDF/TextBox';
import * as Types from 'types';
import RadioGroup from 'components/ReactPDF/RadioGroup';
import { useMemo } from 'react';
import SelectBox from 'components/ReactPDF/SelectBox';
import AttachPreview from 'components/ReactPDF/AttachPreview';
import { IImageThumbnail } from 'types/config';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple@15.0.1/img/apple/64/',
});

const pageSize = 'A4';
const pageOrientation = 'portrait';

interface QuestionMasterDetailProps {
  values: Types.CreateEditQuestionFormik;
  timeLimit: Types.TimeLimitType[];
  imageAttachThumbnails?: IImageThumbnail[];
  imageCommentThumbnails?: IImageThumbnail[];
  imageProblemThumbnails?: IImageThumbnail[];
}

const ANSWER_OPTIONS = [
  {
    value: 'A',
    label: '選択肢A',
  },
  {
    value: 'B',
    label: '選択肢B',
  },
  {
    value: 'C',
    label: '選択肢C',
  },
];

const SCORE_OPTIONS = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
];

const PDFQuestionMasterDetailPage = (props: QuestionMasterDetailProps) => {
  const {
    values,
    timeLimit,
    imageProblemThumbnails,
    imageCommentThumbnails,
    imageAttachThumbnails,
  } = props;
  const timeLimitOptions = useMemo(
    () =>
      (timeLimit || []).map((obj) => ({
        value: obj.option_id,
        label: obj.value,
      })),
    [timeLimit]
  );
  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap={false}>
        <Header title="設問マスタ" />
        <View style={styles.container}>
          <Section no="1" title="設問内容" />
          <TextBox label="設問名" required maxLength={30} value={values.name} />
          <TextBox label="設問内容" required maxLength={480} value={values.description} />
          <AttachPreview
            label="設問画像・動画"
            subLabel="設定状況："
            thumbnails={imageAttachThumbnails || [{}, {}, {}]}
          />
          <Section no="2" title="解答選択肢" />
          <TextBox label="選択肢A" required maxLength={60} value={values.problems1} />
          <TextBox label="選択肢B" required maxLength={60} value={values.problems2} />
          <TextBox label="選択肢C" required maxLength={60} value={values.problems3} />
          <AttachPreview
            label="解答選択肢画像・動画"
            subLabel="設定状況："
            thumbnails={
              imageProblemThumbnails || [
                { label: '選択肢A' },
                { label: '選択肢B' },
                { label: '選択肢C' },
              ]
            }
          />
          <RadioGroup label="解答" required options={ANSWER_OPTIONS} value={values.answer} />
          <View style={{ marginTop: 4 }} />
          <Section no="3" title="解説・その他設定" />
          <TextBox label="解説" required maxLength={1050} value={values.comment} />
          <AttachPreview
            label="解説画像・動画"
            subLabel="設定状況："
            thumbnails={imageCommentThumbnails || [{}, {}, {}]}
          />
          <SelectBox
            label="制限時間"
            required
            value={values.time_limit}
            options={timeLimitOptions}
          />
          <RadioGroup
            label="スコア設定（設問難易度の設定）"
            required
            options={SCORE_OPTIONS}
            value={values.score}
          />
        </View>
      </Page>
    </Document>
  );
};

export default PDFQuestionMasterDetailPage;
