import { createSlice } from '@reduxjs/toolkit';
import { filter, sortBy, unionBy } from 'lodash';

import { getOptionLimit } from 'containers/CreateEditQuestion/thunk';
import * as Types from 'types';
import {
  getSelectCurriculumCreator,
  getSelectCurriculum,
  getSelectQuestion,
  getSystemmaster,
  getSelectTypes,
  getCompany,
  getDataAllQuestion,
  getDataTableQuestion,
  getDataQues,
} from './thunk';
import { getTimeLimitOption } from 'libs/utils/question';

export interface InitialState {
  curriculumNameSelect: Array<Types.SelectCurriculums.ResponseSelectType>;
  curriculumTypeSelect: Array<Types.CreateEditSkillCheckSelectType>;
  curriculumCreatorSelect: Array<Types.CurriculumCreatorSelect>;
  scoreSelect: Array<Types.CreateEditSkillCheckSelectType>;
  dataAllQuestion: Array<Types.Questions.ResponseType>;
  dataSelectCreator: Array<Types.TreeTraining>;
  questionSelect: Array<Types.QuestionSelect>;
  systemmaster: Types.GetSystemmasterType[];
  dataQuestion: Array<Types.TreeTraining>;
  timeLimit: Types.TimeLimitType[];
  defaultTimeLimit: number;
  loading: boolean;
  total: number;
}

const initialState: InitialState = {
  curriculumCreatorSelect: [],
  curriculumNameSelect: [],
  curriculumTypeSelect: [],
  dataSelectCreator: [],
  dataAllQuestion: [],
  defaultTimeLimit: 0,
  questionSelect: [],
  dataQuestion: [],
  systemmaster: [],
  scoreSelect: [],
  loading: false,
  timeLimit: [],
  total: 0,
};

export const questionMasterSlice = createSlice({
  name: 'questionMaster-page',
  initialState,
  reducers: {
    filterCurriculum: (state, action) => {
      if (action.payload) {
        state.dataQuestion = filter(
          state.dataQuestion,
          (e) => e.curriculum_name === action.payload
        );
      }
    },
    filterCreator: (state, action) => {
      if (action.payload) {
        state.dataQuestion = filter(state.dataQuestion, (e) => e.creator === action.payload);
      }
    },
    filterQuestion: (state, action) => {
      if (action.payload) {
        state.dataQuestion = filter(state.dataQuestion, (e) => e.question_name === action.payload);
      }
    },
    filterScore: (state, action) => {
      if (action.payload) {
        state.dataQuestion = filter(state.dataQuestion, (e) => e.score === action.payload);
      }
    },
  },
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getSelectCurriculum.pending, startLoading)
      .addCase(getSelectCurriculumCreator.pending, startLoading)
      .addCase(getSelectQuestion.pending, startLoading)
      .addCase(getSelectTypes.pending, startLoading)
      .addCase(getOptionLimit.pending, startLoading)
      .addCase(getSystemmaster.pending, startLoading);

    builder.addCase(getDataAllQuestion.fulfilled, (state, action) => {
      state.dataAllQuestion = action.payload.items;
      stopLoading(state);
    });

    builder.addCase(getDataTableQuestion.fulfilled, (state, action) => {
      state.dataQuestion = sortBy(action.payload.items, ['code']);
      state.dataSelectCreator = sortBy(unionBy(action.payload.items, 'creator'), ['creator']);
    });

    builder.addCase(getSelectCurriculum.fulfilled, (state, action) => {
      state.curriculumNameSelect = unionBy(
        action.payload.report_results,
        'official_curriculum_name'
      );
      stopLoading(state);
    });
    builder.addCase(getSelectCurriculumCreator.fulfilled, (state, action) => {
      state.curriculumCreatorSelect = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getDataQues.fulfilled, (state, action) => {
      state.questionSelect = action.payload.items;
      stopLoading(state);
    });
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.defaultTimeLimit = Number(action.payload.items[0].time_limit) || 0;
    });
    builder.addCase(getSystemmaster.fulfilled, (state, action) => {
      state.systemmaster = action.payload.items.filter(({ type }) => type === 'initial_time_limit');
      stopLoading(state);
    });
    builder.addCase(getSelectTypes.fulfilled, (state, action) => {
      action.payload.items.forEach((item) => {
        if (item.type === 'required_curriculum') {
          state.curriculumTypeSelect.push(item);
        } else if (item.type === 'score') {
          state.scoreSelect.push(item);
        }
      });
      stopLoading(state);
    });
    builder.addCase(getOptionLimit.fulfilled, (state, action) => {
      state.timeLimit = getTimeLimitOption(action.payload);
      stopLoading(state);
    });

    builder
      .addCase(getSelectCurriculum.rejected, stopLoading)
      .addCase(getSelectCurriculumCreator.rejected, stopLoading)
      .addCase(getSelectQuestion.rejected, stopLoading)
      .addCase(getSelectTypes.rejected, stopLoading)
      .addCase(getOptionLimit.rejected, stopLoading)
      .addCase(getSystemmaster.rejected, stopLoading);
  },
});

export const { filterCurriculum, filterCreator, filterQuestion, filterScore } =
  questionMasterSlice.actions;

export default questionMasterSlice.reducer;
