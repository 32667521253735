import React, { useEffect, useMemo, useState } from 'react';
import { Column, Line, G2 } from '@ant-design/plots';
import { Select } from 'antd';

import Styled from './styles';
import { LineConfig } from '@ant-design/plots/es/components/line';
import { formatComma } from 'libs/utils/format';
import * as Types from 'types';
import { SALE_TYPE, SALE_TYPE_USER_FEE_API_KEY, USER_TYPE } from 'constant/report';
import { useSelector } from 'react-redux';
import { reportSaleInformationSelector } from 'pages/Report/SaleInformation/selectors';
import { uniq } from 'lodash';
import dayjs from 'dayjs';
import { AnyObject } from 'types';
import { useAppDispatch } from 'hooks';
import { setChartSaleType, setChartUserType } from 'pages/Report/SaleInformation/slice';
import { EXPORT_TARGET_MONTH_COUNT } from 'constant';

const { Option } = Select;

type Props = {
  data: Array<Types.SaleReportInformation.SaleReportInformation>;
  exportPdf?: boolean;
};

const TEXT_MAPPING = {
  [SALE_TYPE_USER_FEE_API_KEY]: {
    FEE: 'ユーザー利用料',
    NUMBER: 'ユーザー数',
    UNIT_NUMBER: '人',
  },
  [SALE_TYPE.SKILL_CHECK_USAGE_FEE]: {
    FEE: 'スキルチェック利用料',
    NUMBER: 'スキルチェック利用数',
    UNIT_NUMBER: '回',
  },
  [SALE_TYPE.STORAGE_USAGE_FEE]: {
    FEE: 'ストレージ利用料',
    NUMBER: 'ストレージ利用量',
    UNIT_NUMBER: 'GB',
  },
};

const Development: React.FC<Props> = (props) => {
  const { data, exportPdf } = props;
  const { dateRanger, chartSaleType, chartUserType } = useSelector(reportSaleInformationSelector);

  const [userType, setUserType] = useState(USER_TYPE.CORPORATE);
  const [salesType, setSalesType] = useState(SALE_TYPE_USER_FEE_API_KEY);
  const [dataFee, setDataFee] = useState<AnyObject[]>([]);
  const [dataNumbers, setDataNumbers] = useState<AnyObject[]>([]);

  const dispatch = useAppDispatch();

  const textTitle = useMemo(() => {
    return TEXT_MAPPING[salesType];
  }, [salesType]);

  useEffect(() => {
    if (exportPdf) {
      setUserType(chartUserType || USER_TYPE.CORPORATE);
      setSalesType(chartSaleType || SALE_TYPE_USER_FEE_API_KEY);
    }
  }, [exportPdf, chartSaleType, chartUserType]);

  useEffect(() => {
    if (exportPdf) {
      dispatch(setChartUserType(''));
      dispatch(setChartSaleType(''));
    }
  }, [dispatch, exportPdf]);

  useEffect(() => {
    let dates =
      dateRanger && dateRanger.length ? dateRanger : uniq(data.map((obj) => obj.target_month));
    if (exportPdf) {
      dates = dates.slice(0, EXPORT_TARGET_MONTH_COUNT);
    }
    const dataFees: AnyObject[] = [];
    const dataCountNumbers: AnyObject[] = [];

    dates.forEach((date) => {
      const dataDate = data.find(
        (obj) =>
          obj.target_month === date && obj.user_type === userType && obj.sales_type === salesType
      );
      let numbers = 0;
      if (salesType === SALE_TYPE_USER_FEE_API_KEY) {
        numbers = dataDate?.max_users || 0;
      }
      if (salesType === SALE_TYPE.SKILL_CHECK_USAGE_FEE) {
        numbers =
          dataDate?.inter_skill_check_count && dataDate?.emp_skill_check_count
            ? dataDate?.inter_skill_check_count + dataDate?.emp_skill_check_count
            : dataDate?.emp_skill_check_count || dataDate?.inter_skill_check_count || 0;
      }
      if (salesType === SALE_TYPE.STORAGE_USAGE_FEE) {
        numbers = dataDate?.usage_storage || 0;
      }
      dataFees.push({
        name: TEXT_MAPPING[salesType].FEE,
        year: dayjs(date, 'YYYYMM').format('YYYY/MM'),
        value: dataDate?.monthly_sales || 0,
      });
      dataCountNumbers.push({
        name: TEXT_MAPPING[salesType].NUMBER,
        month: dayjs(date, 'YYYYMM').format('YYYY/MM'),
        value: numbers,
      });
    });
    setDataFee(dataFees);
    setDataNumbers(dataCountNumbers);
  }, [data, userType, dateRanger, salesType]);

  const onSelectUserType = (value: string) => {
    setUserType(value);
    dispatch(setChartUserType(value));
  };

  const onSelectSalesType = (value: string) => {
    setSalesType(value);
    dispatch(setChartSaleType(value));
  };

  return (
    <Styled>
      <div className="user-info">
        <div className="body-info">
          <div className="right-side">
            <div className="wrap-header">
              <div className="wrap-action">
                <div className="wrap-select">
                  <label>ユーザー種類選択：</label>
                  <Select
                    value={userType}
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    onChange={onSelectUserType}
                  >
                    <Option value={USER_TYPE.CORPORATE}>{USER_TYPE.CORPORATE}</Option>
                    <Option value={USER_TYPE.PERSONAL}>{USER_TYPE.PERSONAL}</Option>
                  </Select>
                </div>
                <div className="wrap-select">
                  <label>売上種類選択：</label>
                  <Select
                    value={salesType}
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    onChange={onSelectSalesType}
                  >
                    <Option value={SALE_TYPE_USER_FEE_API_KEY}>{SALE_TYPE.USER_FEE}</Option>
                    <Option value={SALE_TYPE.SKILL_CHECK_USAGE_FEE}>
                      {SALE_TYPE.SKILL_CHECK_USAGE_FEE}
                    </Option>
                    <Option value={SALE_TYPE.STORAGE_USAGE_FEE}>
                      {SALE_TYPE.STORAGE_USAGE_FEE}
                    </Option>
                  </Select>
                </div>
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter blue">
                      <div className="circle" />
                      <div className="line" />
                    </div>
                    <span className="legend-name">ユーザー利用料</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter yellow" />
                    <span className="legend-name">ユーザー数</span>
                  </div>
                </div>
              </div>
            </div>
            <span className="title">{textTitle.FEE}</span>
            <div className="chart">
              <div className="line">
                <span className="unit">単位：千円</span>
                <Line
                  className="line-chart"
                  {...config}
                  data={dataFee}
                  label={{
                    position: 'top',
                    style: {
                      fill: '#08A3A5',
                    },
                    formatter: (v) => formatComma(v.value),
                  }}
                  lineStyle={{
                    stroke: '#08A3A5',
                  }}
                  legend={false}
                  yAxis={{
                    grid: {
                      line: {
                        style: {
                          stroke: '#ddd',
                          lineDash: [2, 2],
                        },
                      },
                    },
                    label: {
                      formatter: formatComma,
                    },
                  }}
                  xAxis={{
                    label: { autoRotate: false },
                  }}
                  scrollbar={{
                    type: 'horizontal',
                    width: 300,
                    categorySize: 70,
                  }}
                  tooltip={{
                    formatter: (datum) => ({
                      name: datum.name,
                      value: formatComma(datum.value),
                    }),
                  }}
                />
              </div>
              <span className="title">{textTitle.NUMBER}</span>
              <div className="column">
                <span className="unit">単位：{textTitle.UNIT_NUMBER}</span>
                <Column
                  className="column-chart"
                  {...configColumn}
                  data={dataNumbers}
                  legend={false}
                  yAxis={{
                    grid: {
                      line: {
                        style: {
                          lineDash: [2, 2],
                          stroke: '#ddd',
                        },
                      },
                    },
                    label: {
                      formatter: formatComma,
                    },
                  }}
                  xAxis={{
                    tickLine: null,
                    label: { autoRotate: false },
                  }}
                  scrollbar={{
                    type: 'horizontal',
                    width: 300,
                    categorySize: 70,
                  }}
                  label={{
                    position: 'top',
                    formatter: (v) => formatComma(v.value),
                  }}
                  tooltip={{
                    formatter: (datum) => ({
                      name: datum.name,
                      value: formatComma(datum.value),
                    }),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default Development;

const config: LineConfig = {
  data: [],
  xField: 'year',
  yField: 'value',
  seriesField: 'name',
  point: {
    size: 5,
    shape: 'circle',
    style: {
      fill: '#08A3A5',
      stroke: '#08A3A5',
      lineWidth: 2,
    },
  },
  scrollbar: {
    width: 1080,
  },
  tooltip: {
    showMarkers: false,
  },
  state: {
    active: {
      style: {
        shadowBlur: 4,
        stroke: '#000',
        fill: 'red',
      },
    },
  },
  interactions: [
    {
      type: 'marker-active',
    },
  ],
};

const configColumn = {
  data: [],
  xField: 'month',
  yField: 'value',
  seriesField: 'name',
  maxColumnWidth: 25,
  dodgePadding: 4,
  color: '#F6AC00',
};
