import dayjs from 'dayjs';
import { DATETIME_FORMAT } from 'constant';

export const formatNumber = (num: number | undefined, digit?: number) => {
  if (isNaN(num as number) || typeof num !== 'number') return '0';
  return Number(num.toFixed(digit ?? 2).replace(/\.00$/, '')).toString();
};

export const formatNumberToHoursMinutes = (value: string | undefined) => {
  if (value === undefined) return '00:00';
  const [hours, minutes] = value.split('.');
  return `${hours || '00'}:${minutes || '00'}`;
};

export const formatNumberComma = (num: number | undefined) => {
  if (num === undefined) return '0';
  return new Intl.NumberFormat('ja-JP').format(num);
};

export const getFileFromUrl = async (url: string, name: string, defaultType = 'image/jpeg') => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
};

export const extractFileName = (fileNameUuid?: string) => {
  if (fileNameUuid) {
    if (RegExp(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}-/).exec(fileNameUuid)) {
      return fileNameUuid.replace(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}-/, '');
    }

    return fileNameUuid;
  }

  return '';
};

export const formatComma = (value: string | number) =>
  `${value}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`);

export const formatDate = (date?: string | Date, dateFormat = DATETIME_FORMAT) =>
  date && dayjs(date).isValid() ? dayjs(date).format(dateFormat) : '';

export const formatRemainTime = (millis: number) => {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(2);
  return seconds === '60.00' ? `${minutes + 1}分:00秒` : `${minutes}分${seconds}秒`;
};

export const formatNumberToLocaleString = (number: number) => number.toLocaleString();

/**
 *
 * @param val
 * @param defaultValue
 */
export const toNumber = <T>(val: T, defaultValue = 0): number => {
  const num = +val;
  return isNaN(num) ? defaultValue : num;
};
