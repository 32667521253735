import { Modal } from 'components';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import useDisableAction, { Props, ReceivedProps } from './hook';
import Styled from './styles';

const DisabledLayout = (props: Props) => {
  const { handleClose, open } = props;

  return (
    <Modal
      width={617}
      open={open}
      okButton={{
        text: 'OK',
        onClick: handleClose,
      }}
      style={{
        borderRadius: 5,
        overflow: 'hidden',
      }}
      bodyStyle={{
        paddingBottom: 4,
      }}
    >
      <Styled>
        <div className="wrap-content">
          <ExclamationCircleOutlined className="icon" />
          <span className="content">OFFICIALカリキュラムが選択されていません</span>
        </div>
        <div className="description">
          処理を実行する対象のOFFICIALカリキュラムを選択し、再度実行してください。
        </div>
      </Styled>
    </Modal>
  );
};

const DisabledActionModal = (props: ReceivedProps) => {
  return <DisabledLayout {...useDisableAction(props)} />;
};

export default DisabledActionModal;
