import styled from 'styled-components';

interface Props {
  visibleExpansionOfSale: boolean;
}

export default styled.section<Props>`
  position: relative;
  .text-count {
    position: absolute;
    top: -30px;
    right: 0;
  }
  .right-data {
    text-align: right;
    display: block;
    width: 100%;
  }
  .table {
    .custom-empty-text {
      padding: 50px 0;
    }
    border: 1px solid #dddddd;
    .ant-table-row.ant-table-row-level-2 {
      background: #f9f9f9;
      border-bottom: 1px dashed #eeeeee;
    }
    .ant-table-tbody .ant-table-row .ant-table-cell {
      border-left: 1px solid rgba(85, 85, 85, 0.08);
    }
    .ant-table-cell.ant-table-cell-with-append {
      background: #ffffff;
    }
    .ant-table-tbody > tr.ant-table-row-level-2 > td {
      &:not(:first-child) {
        border-bottom: 1px dashed #eeeeee;
      }
    }
    .ant-table-tbody > tr.ant-table-row-level-1:not(:last-child) > td {
      &:not(:first-child) {
        border-bottom: 1px dashed #eeeeee;
      }
    }
    .ant-table-tbody > tr.ant-table-row-level-0:not(:last-child) > td {
      &:not(:first-child) {
        border-bottom: 1px dashed #eeeeee;
      }
    }
    .ant-table-tbody > tr.ant-table-row-level-0 > td {
      &:first-child {
        border-bottom: none;
      }
    }
    .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
      display: none;
    }
    .ant-table-tbody > tr.ant-table-row-level-0 > td {
      border-top: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
    }
    .ant-table-tbody > tr > td {
      border: none;
      padding: 8px;
    }
    .ant-table-cell {
      white-space: nowrap;
      font-size: 13px;
      background: #ffffff;
    }
    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      .ant-table-cell {
        background-color: #ebebeb;
        font-weight: 700;
        font-size: 12px;
        color: #424242;
        margin: 0 3px;
        &:before {
          height: 100% !important;
        }
      }
    }
    .ant-table-cell-fix-left {
      background: #ffffff;
    }
  }
  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .virtual-table {
    .virtual-table-head {
      .virtual-table-header-cell {
        :first-child {
          text-align: left;
        }
      }
    }
    .virtual-table-body {
      .total-all {
        background-color: #f4fbfb;
        .virtual-table-cell {
          background: #f4fbfb !important;
        }
      }
      .last-child-company-row {
        .virtual-table-cell {
          :not(:first-child) {
            border-bottom: 1px solid #dddddd !important;
          }
          .virtual-table-first-cell-content {
            border-bottom: 1px solid #dddddd !important;
          }
        }
      }
      .virtual-table-row {
        .virtual-table-cell {
          :not(:first-child) {
            text-align: right;
          }

          .company-detail {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            .company-id {
              font-size: 11px;
              color: #999999;
            }
            .company-name {
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
`;
