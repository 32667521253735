import styled from 'styled-components';

interface Props {
  collapsedMenu: boolean;
  isEmptyData: boolean;
  isExport?: boolean;
}

export default styled.section<Props>`
  .component {
    position: absolute;
    right: 9999px;
    width: 100%;
  }
  .btn-hide-search-box {
    color: #00a3a5;
    border: 1px solid #00a3a5;
    background: #ffffff;
    border-radius: 5px;
    height: 21px;
    width: 50px;
  }
  .ant-table-pagination.ant-pagination {
    width: 85%;
  }

  .flex {
    display: flex;
    alignitems: center;
  }

  @media (max-width: 1440px) {
    height: auto;
    .container {
      height: auto !important;
      .wrap-button {
        .btn {
          width: 160px;
        }
      }
      .border-line {
        margin: 30px 0 20px 0 !important;
      }
      .select-time-limit {
        height: 48px;
        .btn-select-submit {
          width: 50px;
          margin-left: 4px;
          padding: 0 !important;
        }
      }
      .form-search {
        justify-content: flex-start !important;
        .curriculum_name,
        .question_name {
          width: 250px !important;
          margin-right: 8px !important;
        }
        .createdby,
        .required_curriculum {
          width: 160px !important;
          margin-right: 8px !important;
        }
        .score {
          width: 132px !important;
          margin-right: 32px;
        }
        .ant-form-item-label > label {
          font-size: 12px !important;
        }
        .btn-search {
          width: 100px;
          margin-right: 8px;
        }
      }

      .table {
        .ant-table-thead .ant-table-cell {
          height: 44px;
          padding: 4px 8px;
          .ant-checkbox {
            padding-top: 6px;
          }
        }
        .ant-table-tbody {
          .ant-table-cell {
            height: 44px;
            padding: 4px 8px;
          }
        }
      }
      .table .ant-table .ant-table-container::before {
        margin-top: 4px !important;
      }
    }
    .wrap-bottom {
      .btn {
        padding: 19px 0 !important;
      }
    }
  }
  .ant-table {
    border: 1px solid #e5e5e5;
    overflow: hidden;
    border-radius: 3px 0px 3px 3px;
    .ant-table-row:last-child {
      td {
        border: none;
      }
    }
    .ant-table-placeholder {
      .ant-table-cell {
        border: none;
      }
    }
  }
  .container {
    background-color: #f9f8f8;
    width: 100%;
    padding: 30px;
    min-height: calc(100vh - 151px);
    position: relative;
    .header-container {
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 12px;
        color: #424242;
        margin: 0;
      }
      .wrap-notify-successful {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        font-size: 12px;
        padding: 10px 12px;
        color: #08a3a5;
        .icon-successful {
          font-size: 21px;
          color: #08a3a5;
          margin-right: 5px;
        }
      }
      .select-time-limit {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
        background-color: #ebebeb;
        border: 1px solid #e5e5e5;
        border-radius: 2px;
        .label {
          display: flex;
          font-size: 12px;
          color: #424242;
          .question-mark-icon {
            color: #08a3a5;
            font-size: 18px;
            cursor: pointer;
            margin-left: 5px;
          }
        }
        .select-limit {
          width: 80px;
          margin-bottom: 0;
        }
        .btn-search {
          background-color: #f6ac00;
          border: 1px solid #f6ac00;
          box-shadow: 1px 1px 4px rgb(68 68 68 / 20%);
          border-radius: 5px;
          color: #ffffff;
          margin-left: 10px;
        }
        .btn-select-submit {
          border: 1px solid #00a3a5;
          box-shadow: 1px 1px 4px rgb(68 68 68 / 20%);
          border-radius: 5px;
          color: #00a3a5;
          margin-left: 10px;
          font-weight: 700;
          span {
            display: flex;
            justify-content: center;
            height: 100%;
            align-items: center;
          }
        }
      }
    }
    .border-line {
      border-top: 1px solid #dddddd;
      width: 40px;
      margin: 30px 0;
    }
    .form-search,
    .form-search-pdf {
      display: flex;
      align-items: center;
      column-gap: 8px;
      .curriculum_name {
        width: 20%;
      }
      .required_curriculum {
        width: 15%;
      }
      .createdby {
        width: 15%;
      }
      .question_name {
        width: 20%;
      }
      .score {
        width: 15%;
      }
      .label-reset {
        font-size: 12px;
        cursor: pointer;
        text-decoration-line: underline;
        color: #00a3a5;
      }
      .btn-search {
        background: #ffffff;
        border: 1px solid #00a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        font-size: 14px;
        color: #00a3a5;
        cursor: pointer;
        margin-left: 16px;
        .icon-search {
          font-size: 18px;
          margin-right: 12px;
        }
      }
    }
    .form-search-pdf {
      display: flex;
      justify-content: flex-start;
      column-gap: 10px;
    }
    .table,
    .table-pdf {
      margin-top: ${(props) => (props.isEmptyData ? 64 : 0)}px;

      .text-count {
        font-family: Lato;
        font-size: 14px;
        color: #424242;
        display: flex;
        .text-static {
          font-size: 11px;
          padding-left: 3px;
        }
      }
      .ant-table .ant-table-container::before {
        content: ${(props) => (props.isExport ? '' : `'ALL'`)};
        margin-left: 7.5px;
        margin-top: 9px;
        font-size: 11px;
      }
      .ant-table-thead .ant-checkbox-wrapper {
        padding-top: 10px;
      }
      .ant-table-thead {
        border-radius: 2px 2px 0 0;
        .ant-table-cell {
          background-color: #ebebeb;
          font-weight: 700;
          font-size: 12px;
          color: #2a2a2a;
          margin: 0 3px;
          &:nth-child(7),
          &:nth-child(8) {
            text-align: center;
          }
          &:before {
            display: none;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          font-family: Lato;
          font-size: 13px;
          color: #424242;
          word-wrap: break-word;
          &:nth-child(7),
          &:nth-child(8) {
            text-align: center;
          }
        }
        .icon {
          color: #c4c4c4;
          font-size: 20px;
          cursor: pointer;
        }
        & > tr.ant-table-row-selected > td {
          background-color: #fdf7ea;
        }
      }
    }
    .table-pdf {
      margin-top: 20px;
      padding-bottom: 95px;
    }
    .wrap-button {
      position: absolute;
      right: 0;
      margin: 12px 30px;
      z-index: 9;
      .btn {
        padding: 0 16px;
        height: 40px;
        cursor: pointer;
        font-size: 13px;
        font-weight: 700;
        margin-left: 8px;
      }
      .btn-outline {
        background-color: #ffffff;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: #f1a900;
      }
      .btn-active {
        background-color: #f6ac00;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: #ffffff;
      }
      .disabled {
        background: #cccccc !important;
        border-color: #cccccc !important;
        border-radius: 5px !important;
        color: #777777 !important;
      }
    }
    .icon {
      color: #ffffff;
      font-weight: 700;
      font-size: 20px;
      cursor: pointer;
    }
    .iconDisabled {
      color: #777777 !important;
    }
  }

  .customizeIcon {
    span {
      line-height: 18px;
    }
    .anticon-cloud-upload {
      font-size: 20px;
    }
    .anticon-plus.icon {
      height: 18px;
      font-size: 18px;
      margin-top: 1px;
      transform: translateY(2px);
    }
  }
  .wrap-bottom {
    height: 95px;
    width: 100%;
    transition: width 0.3s;
    background-color: #ffffff;
    position: sticky;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    .flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .text-label {
      font-size: 13px;
      color: #777777;
      display: flex;
      align-items: center;
    }
    .btn {
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      width: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      margin-right: 8px;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      .icon {
        font-size: 18px;
      }
    }
    .disabled {
      background: #cccccc !important;
      border-color: #cccccc !important;
      border-radius: 5px !important;
      color: #777777 !important;
    }
  }
`;
