import { createAsyncThunk } from '@reduxjs/toolkit';

import { ADMIN_USER_DETAIL, COMPANIES, EMPLOYEE_USER, PROVIDER_INFORMATION, USERS } from 'configs';
import { browserLogger } from 'libs/logger';
import { services } from 'services';
import * as Types from 'types';

export const getDataAdminDetails = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/getDataAdminDetails', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(ADMIN_USER_DETAIL.name, req);
    browserLogger.info(
      'AdministratorMaster/thunk/getDataAdminDetails',
      ADMIN_USER_DETAIL.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateInformation = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AdministratorMasterFormik>,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/updateInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(COMPANIES.name, req);
    browserLogger.info('AdministratorMaster/thunk/updateInformation', COMPANIES.name, data);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateProviderInformation = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.ProviderInformation.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/updateProviderInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(PROVIDER_INFORMATION.name, req);
    browserLogger.info(
      'AdministratorMaster/thunk/updateProviderInformation',
      PROVIDER_INFORMATION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateUser = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/updateUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.name, req);
    browserLogger.info('AdministratorMaster/thunk/updateUser', USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createCompanies = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Companies.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/createCompanies', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(COMPANIES.name, req);
    browserLogger.info('AdministratorMaster/createCompanies', COMPANIES.name, data);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createProviderInformation = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.ProviderInformation.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/createProviderInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(PROVIDER_INFORMATION.name, req);
    browserLogger.info(
      'AdministratorMaster/createProviderInformation',
      PROVIDER_INFORMATION.name,
      data
    );
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createUser = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/createUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USERS.name, req);
    browserLogger.info('AdministratorMaster/createUser', USERS.name, data);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDataSearchUsers = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/getDataSearchUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.name, req);
    browserLogger.info('dAdministratorMaster/thunk/getDataSearchUsers', USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getEmployeeUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.EmployeeUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/getEmployeeUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.EmployeeUser.ResponseType>(
      EMPLOYEE_USER.name,
      req
    );
    browserLogger.info('AdministratorMaster/thunk/getEmployeeUser', EMPLOYEE_USER.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCompanies = createAsyncThunk<
  Types.GetItemResponseType<Types.Companies.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'AdministratorMaster/thunk/getCompanies',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      const { authContainer } = getState() as Types.RootState;

      const { data } = await services.search<Types.Companies.ResponseType>(COMPANIES.name, req);
      browserLogger.info('AdministratorMaster/thunk/getCompanies', COMPANIES.name, data);
      const getEmployeeResult = await dispatch(
        getEmployeeUser({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer.userInfo?.company_id],
            },
            {
              id: 'position_company_code',
              search_value: [authContainer.userInfo?.company_id],
            },
          ],
          sort_fields: [
            {
              id: 'employee_code',
              order: 'asc',
            },
          ],
          page: 1,
          per_page: 0,
          include_lookups: true,
          include_item_ref: true,
          omit_total_items: false,
        })
      );

      const newInformation: Types.Companies.ResponseType[] = [];

      if (getEmployeeUser.fulfilled.match(getEmployeeResult)) {
        const index = getEmployeeResult.payload.report_results.findIndex(
          (e) => e.user_type === 'admin'
        );
        if (index > -1) {
          const item = getEmployeeResult.payload.report_results[index];
          newInformation.push({
            ...data.items[0],
            admin_email: item.email,
            admin_name_furigana: item.kana,
            admin_department: item.role_code,
            admin_position: item.position_code,
            admin_position_name: item.lookup_items?.position_code?.name,
          });
        }
      }
      return { ...data, items: newInformation.length ? newInformation : data.items };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserSearch = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/getUserSearch', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.name, req);
    browserLogger.info('AdministratorMaster/thunk/getUserSearch', USERS.name, data);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
