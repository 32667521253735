import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F9F8F8',
    fontFamily: 'NotosanJP',
    position: 'relative',
    paddingBottom: 32,
  },

  container: {
    paddingTop: 11.7,
    paddingLeft: 18,
    paddingRight: 18,
  },
});
