import { Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const EditSimpleIcon = ({ style }: { style?: Style | Style[] }) => (
  <Svg width="18" height="19" viewBox="0 0 18 19" fill="none" style={style}>
    <Path
      d="M16.8756 9.49959H15.7506C15.6622 9.49959 15.5898 9.57356 15.5898 9.66396V16.2386H2.41127V2.76055H8.83985C8.92824 2.76055 9.00056 2.68659 9.00056 2.59619V1.44562C9.00056 1.35522 8.92824 1.28125 8.83985 1.28125H1.6077C1.25212 1.28125 0.964844 1.57506 0.964844 1.93872V17.0605C0.964844 17.4241 1.25212 17.7179 1.6077 17.7179H16.3934C16.749 17.7179 17.0363 17.4241 17.0363 17.0605V9.66396C17.0363 9.57356 16.964 9.49959 16.8756 9.49959Z"
      fill="#C4C4C4"
    />
    <Path
      d="M5.86436 9.97009L5.82619 12.413C5.82418 12.5959 5.96883 12.7458 6.14762 12.7458H6.15566L8.52619 12.6863C8.56637 12.6842 8.60655 12.6678 8.63467 12.639L16.9898 4.11247C17.0521 4.04878 17.0521 3.94399 16.9898 3.8803L14.4927 1.32851C14.4606 1.29563 14.4204 1.28125 14.3782 1.28125C14.336 1.28125 14.2958 1.29769 14.2637 1.32851L5.91057 9.85504C5.88155 9.88607 5.86505 9.92716 5.86436 9.97009ZM7.14003 10.455L14.3782 3.06874L15.2862 3.99536L8.04405 11.3857L7.12597 11.4083L7.14003 10.455Z"
      fill="#C4C4C4"
    />
  </Svg>
);

export default EditSimpleIcon;
