import { useCallback, useEffect, useMemo, useState } from 'react';

const ITEM_PER_PAGE = 10;

export const usePagination = <T>({ data }: { data: T[] }) => {
  const [page, setPage] = useState<number>(1);
  const totalItem = useMemo(() => data.length, [data]);

  const items = useMemo(() => data.slice(0, page * ITEM_PER_PAGE), [data, page]);

  const hasMore = useMemo(() => ITEM_PER_PAGE * page < totalItem, [page, totalItem]);

  const handleLoadMore = useCallback((page: number) => {
    setPage(page);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [data.length]);

  return {
    page,
    items,
    hasMore,
    handleLoadMore,
  };
};
