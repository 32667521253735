import styles from './styles';
import { Text } from '@react-pdf/renderer';
import React from 'react';

const PageNumber: React.FC = () => (
  <Text
    wrap={false}
    style={styles.currentPage}
    render={({ pageNumber }) => `- ${pageNumber} -`}
    fixed
  />
);

export default PageNumber;
