import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import { Navigations } from 'navigations';
import AppProvider from './AppProvider.provider';
import GlobalStyle from 'styles';
import { store } from 'store';
import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

// eslint-disable-next-line no-undef
console.log('Your app version is ' + VERSION);

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <Provider store={store}>
      <AppProvider>
        <Navigations />
      </AppProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
