import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';

import { ModalStyled, SectionStyled } from './styles';

interface Props {
  visible: boolean;
  onSubmit?: () => void | Promise<void>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompletedDeleteModal: React.FC<Props> = ({ visible, onSubmit, setVisible }) => {
  const handleCloseModal = () => {
    setVisible(false);
  };

  const handleSubmitModal = async () => {
    onSubmit && (await onSubmit());
    setVisible(false);
  };

  return (
    <ModalStyled
      title=""
      open={visible}
      width={416}
      onCancel={handleCloseModal}
      bodyStyle={{ padding: 10, borderRadius: 4 }}
      okButton={{
        text: 'OK',
        onClick: handleSubmitModal,
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          <CheckCircleOutlined className="icon" />
          データの削除が完了しました
        </p>
      </SectionStyled>
    </ModalStyled>
  );
};

export default CompletedDeleteModal;
