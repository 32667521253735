import styled from 'styled-components';

interface Props {
  collapsedMenu: boolean;
}

export const Wrapper = styled.div<Props>`
  .container {
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
    .ant-input-affix-wrapper {
      background: #ffffff;
      input {
        background: #ffffff;
      }
    }
    .ant-input-affix-wrapper-disabled {
      background: #f9f8f8;
      input {
        background: #f9f8f8;
      }
    }
    .ant-select-disabled {
      background: #f9f8f8 !important;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: #f9f8f8;
    }
    .ant-select-arrow {
      top: 54% !important;
    }
    .ant-select-selection-item {
      display: inline-flex;
      align-items: center;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      align-self: center;
    }
    .ant-select-selector {
      height: 40px;
      border-radius: 3px;
      border-color: #ccc;
    }
    padding: 30px;
    background-color: #f9f8f8;
    .method-selection {
      width: 35%;
    }
    .description {
      margin-bottom: 30px;
      p {
        font-weight: 400;
        font-size: 12px;
        color: #424242;
        margin: 0;
        text-align: center;
      }
    }
    .form {
      width: 80%;
      background: #ffffff;
      box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      padding: 40px;
      margin: 0 auto;
      max-width: 980px;
      .wrap-number-users {
        position: relative;
        .number-users {
          width: 20%;
        }
        .suffix {
          position: absolute;
          top: 10px;
          left: 16%;
          font-weight: 400;
          font-size: 14px;
          color: #bbbbbb;
        }
      }
      .require {
        color: #f0330a;
      }
      .item {
        .input {
          height: 40px;
          box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
          border-radius: 3px;
          background-color: #ffffff;
          &__disabled {
            background-color: #f9f8f8;
          }
          ::placeholder {
            color: #cccccc;
            font-size: 14px;
          }
        }
        .text-label {
          font-size: 13px;
          display: block;
          text-align: right;
          color: #777777;
          padding-top: 10px;
          .require {
            color: #f0330a;
          }
        }
      }
      .wrap-input-zip-code {
        .ant-form-item-control-input-content {
          display: flex;
          flex-direction: row;
          .input {
            width: 35%;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          .input-phone {
            width: 100%;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          .btn-check-zipCode {
            font-size: 12px;
            width: 25%;
            color: #00a3a5;
            border-top: 1px solid #e6e6e6;
            border-right: 1px solid #e6e6e6;
            border-bottom: 1px solid #e6e6e6;
            background-color: #ffffff;
            border-left: none;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            cursor: pointer;
          }
        }
      }
      .error-input-zip-code {
        .btn-check-zipCode {
          border-color: #ff4d4f !important;
        }
      }
      .w-half {
        width: 50%;
      }
    }
  }
  .wrap-button {
    padding: 20px 0;
    width: 100%;
    transition: width 0.3s;
    background-color: #ffffff;
    position: sticky;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    .btn-submit {
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      border: none;
      height: 40px;
      padding: 0 50px;
      cursor: pointer;
    }
  }
  .wrap-button-confirm {
    flex-direction: row;
    .cancel-btn {
      background: #ffffff;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #777777;
      font-size: 15px;
      border: 1px solid #d9d9d9;
      height: 40px;
      padding: 0 50px;
      cursor: pointer;
      margin-left: 10px;
    }
  }
`;
