import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';

import { AnyObject } from 'types';
import styles from './styles';
import { ChartInactiveIconPdf, TableIconPdf } from 'assets/svgPDF';
import React, { useMemo } from 'react';
import _ from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import Tabs from 'components/ReactPDF/Tabs';
import SelectBox from 'components/ReactPDF/SelectBox';
import DateRangerpicker from 'components/ReactPDF/DateRangerpicker';
import dayjs from 'dayjs';
import { formatComma } from 'libs/utils/format';
import { EXPORT_TARGET_MONTH_COUNT } from 'constant';
import { CalculatedReport } from 'types/datastores/user_report_information';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';

const reportTypeOptions = [
  {
    value: 0,
    label: '登録企業数推移',
  },
  {
    value: 1,
    label: 'ユーザー数推移',
  },
  {
    value: 2,
    label: '月間最大登録ユーザー数推移',
  },
];

interface PDFInformationUserProps {
  calculatedData?: CalculatedReport;
  filters?: AnyObject;
  dateRanger?: string[];
  listSelectUsers: (string | undefined)[];
  reportType?: number;
  rangerDate?: string[];
}

const FIRST_PAGE_ROW_COUNT = 13;
const PAGE_ROW_COUNT = 20;

const TABS_ARRAY = [
  { key: '売上情報', label: '売上情報' },
  { key: 'ユーザー情報', label: 'ユーザー情報' },
  { key: 'OFFICIALカリキュラム情報', label: 'OFFICIALカリキュラム情報' },
  { key: 'スキルチェック実施情報', label: 'スキルチェック実施情報' },
];

const InformationUserTable = ({
  dataSource,
  dates,
}: {
  dataSource: AnyObject[];
  dates: string[];
}) => {
  const columns = useMemo(() => {
    const dateColumns = dates.map((item, index) => ({
      title: dayjs(item, 'YYYYMM').format('YYYY/MM'),
      key: item,
      width: Math.floor(730 / dates.length),
    }));
    return [
      {
        width: 150,
        title: 'ID / ユーザー名',
        key: 'name',
      },
      ...dateColumns,
    ];
  }, [dates]);

  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow]}>
        {columns.map((column, index) => (
          <View
            key={index}
            style={[
              styles.wrapCell,
              { width: column.width, textAlign: index === 0 ? 'left' : 'center' },
            ]}
          >
            <Text
              style={[
                styles.tableCell,
                styles.headerCell,
                { width: column.width, textAlign: index === 0 ? 'left' : 'center' },
              ]}
            >
              {column.title}
            </Text>
          </View>
        ))}
      </View>

      {dataSource.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5',
            },
            row.styleRow,
          ]}
        >
          {columns.map((column, index) =>
            index === 0 ? (
              <View
                key={index}
                style={[styles.wrapCell, styles.cellTitle, { width: columns[index].width }]}
              >
                <View style={[styles.level, row.styleLevel]} />
                <View
                  style={[
                    styles.tableCell,
                    styles.cellTitleValue,
                    { borderLeft: 0 },
                    row.styleTitle,
                  ]}
                >
                  <View style={[styles.itemName]}>
                    {row.code && <Text style={styles.code}>{row.code || '-'}</Text>}
                    <Text style={styles.name}>{row.name || '-'}</Text>
                  </View>
                </View>
              </View>
            ) : (
              <View style={[styles.wrapCell, { width: columns[index].width }]}>
                <Text style={[styles.tableCell, styles.textRight, row.styleValue]}>
                  {row[column.key] || row[column.key] === 0 ? formatComma(row[column.key]) : '-'}
                </Text>
              </View>
            )
          )}
        </View>
      ))}
    </View>
  );
};

const PDFInformationUser = (props: PDFInformationUserProps) => {
  const { calculatedData, reportType, filters = {}, dateRanger, listSelectUsers } = props;

  const dates = useMemo(
    () => (dateRanger && dateRanger.length ? dateRanger : []).slice(0, EXPORT_TARGET_MONTH_COUNT),
    [dateRanger]
  );

  const selectUserOptions = listSelectUsers
    ? listSelectUsers.map((obj) => ({
        label: obj || '',
        value: obj || '',
      }))
    : [];

  const allData = useMemo(() => {
    if (!calculatedData) {
      return [];
    }
    const result: AnyObject[] = [
      {
        name: '法人ユーザー',
        styleRow: styles.rowTotal,
        ...(calculatedData.max_users || {}),
      },
    ];
    if (calculatedData.children) {
      const filterCompanyName = filters.companyName;
      Object.keys(calculatedData.children).forEach((companyId) => {
        const company: AnyObject = calculatedData.children
          ? calculatedData.children[companyId] || {}
          : {};
        if (!filterCompanyName || (company.name || '').includes(filterCompanyName)) {
          result.push({
            name: company.name,
            code: companyId,
            styleLevel: { ...styles.secondChildLevel },
            styleTitle: { ...styles.borderBottomDash },
            styleValue: { ...styles.borderBottomDash },
            ...(company.max_users || {}),
          });
        }
      });
    }
    return result;
  }, [filters, calculatedData]);

  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page}>
        <FullPage>
          <View style={styles.header}>
            <Text style={styles.textHeader}>レポート</Text>
          </View>
          <Tabs tabs={TABS_ARRAY} activeKey="ユーザー情報" />
          <Text style={styles.conditionText}>集計条件</Text>
          <View style={styles.searchPanel}>
            <SelectBox
              label="集計方法選択"
              placeholder="指定なし"
              options={reportTypeOptions}
              value={reportType}
              selectBoxStyle={{ width: 150 }}
            />
            <DateRangerpicker
              label="集計期間"
              placeholder="指定なし"
              value={filters.target_month}
            />
            <SelectBox
              label="ユーザー検索"
              placeholder="指定なし"
              options={selectUserOptions}
              value={filters.companyName || 'ALL'}
              selectBoxStyle={{ width: 150 }}
            />
          </View>
          <View style={styles.reportTypeGroups}>
            <View style={styles.reportTypeGroup}>
              <View style={styles.reportTypeGroup}>
                <Text style={styles.reportTypeLabel}>レポートタイプ：</Text>
                <View style={styles.reportTable}>
                  <TableIconPdf />
                  <Text style={styles.reportTableText}>表</Text>
                </View>
                <View style={styles.reportChart}>
                  <ChartInactiveIconPdf />
                  <Text style={styles.reportChartText}>グラフ</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.body}>
            <InformationUserTable
              dataSource={allData.slice(0, FIRST_PAGE_ROW_COUNT)}
              dates={dates}
            />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {allData.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(allData.slice(FIRST_PAGE_ROW_COUNT, allData.length), PAGE_ROW_COUNT).map(
          (page, index) => (
            <Page
              key={index}
              size={pageSize}
              orientation={pageOrientation}
              style={[styles.page, styles.pagePadding]}
              wrap={false}
            >
              <FullPage>
                <InformationUserTable dataSource={page} dates={dates} />
              </FullPage>
              <PageNumber />
            </Page>
          )
        )}
    </Document>
  );
};

export default PDFInformationUser;
