import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { LockOutlined } from '@ant-design/icons';
import { Form } from 'formik-antd';

import { InputPassword, Modal, SpinLoading } from 'components';
import { ModalContent } from 'components/Header/styles';
import { changePasswordSchema } from 'libs/validations';
import { changePassword } from '../Auth/thunk';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import Styled from './styles';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setVisiblelComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangePassword: React.FC<Props> = ({ visible, setVisible, setVisiblelComplete }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const formik = useFormik<Types.ChangePasswordFormik>({
    initialValues: {
      current_password: '',
      new_password: '',
      confirm_password: '',
    },
    validationSchema: changePasswordSchema,
    validateOnBlur: false,
    onSubmit: async (values, { setErrors }) => {
      setLoading(true);
      const resultAction = await dispatch(
        changePassword({
          old_password: values.current_password,
          new_password: values.new_password,
          confirm_password: values.confirm_password,
        })
      );
      if (changePassword.fulfilled.match(resultAction) && !resultAction.payload.error) {
        setVisiblelComplete(true);
        setVisible(false);
      } else {
        setErrors({ error: '現在のパスワードが正しくありません' });
      }
      setLoading(false);
    },
  });

  const handleToggleChangeEmail = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="パスワード変更"
      visible={visible}
      width={720}
      onCancel={handleToggleChangeEmail}
      okButton={{
        text: '送信する',
        onClick: formik.handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: () => handleToggleChangeEmail(),
      }}
    >
      <SpinLoading loading={loading}>
        <ModalContent>
          <Styled>
            <FormikProvider value={formik}>
              <p className="guide">
                パスワードを変更します。
                <br />
                変更すると、自動でログアウトされます。
              </p>
              <Form className="form" layout="vertical">
                <Form.Item
                  name="current_password"
                  className="input"
                  label={
                    <div>
                      <span className="label">現在のパスワード</span>
                      <span className="require">*</span>
                    </div>
                  }
                >
                  <InputPassword
                    name="current_password"
                    showCount
                    maxLength={30}
                    className="input"
                    prefix={
                      <LockOutlined
                        style={{
                          color: '#bbbbbb',
                          fontSize: 20,
                        }}
                      />
                    }
                    placeholder="現在のパスワード"
                  />
                </Form.Item>
                <Form.Item
                  name="new_password"
                  className="input"
                  label={
                    <div>
                      <span className="label">新しいパスワード</span>
                      <span className="require">*</span>
                    </div>
                  }
                >
                  <InputPassword
                    showCount
                    maxLength={30}
                    className="input"
                    name="new_password"
                    prefix={
                      <LockOutlined
                        style={{
                          color: '#bbbbbb',
                          fontSize: 20,
                        }}
                      />
                    }
                    placeholder="新しいパスワード"
                  />
                </Form.Item>
                <Form.Item
                  name="confirm_password"
                  label={
                    <div>
                      <span className="label">新しいパスワード（確認）</span>
                      <span className="require">*</span>
                    </div>
                  }
                >
                  <InputPassword
                    showCount
                    maxLength={30}
                    name="confirm_password"
                    className="input"
                    prefix={
                      <LockOutlined
                        style={{
                          color: '#bbbbbb',
                          fontSize: 20,
                        }}
                      />
                    }
                    placeholder="確認のため新しいパスワードを再度入力"
                  />
                </Form.Item>
                {formik.errors && <div className="error">{formik.errors?.error}</div>}
              </Form>
            </FormikProvider>
          </Styled>
        </ModalContent>
      </SpinLoading>
    </Modal>
  );
};

export default ChangePassword;
