import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { SearchOutlined } from '@ant-design/icons';
import { Form, SubmitButton } from 'formik-antd';
import { Button, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

import { filterErrorCode, resetInitialState } from 'pages/BillingDataManagement/slice';
import { billingDataManagementSelector } from 'pages/BillingDataManagement/selectors';
import { HEADER_BILLING_DATA_DETAIL } from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import ActionErrorModal from 'components/Modal/ActionError';
import { SELECT_RECORD } from 'constant/select.constants';
import { Modal, SelectField } from 'components';
import { loadingRef } from 'components/Loading';
import { ERROR_DETAIL_CODE } from 'constant';
import { ModalExportBillingData } from '..';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  getBillingDataDetailExport,
  getBillingDataDetail,
  getSelectBillingDataDetail,
} from 'pages/BillingDataManagement/thunk';
import { pdf } from '@react-pdf/renderer';
import PDFDetail from './PDF';
import { exportCsv } from '../../../../libs/utils/exportCsv';

interface Props {
  visible: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  onSubmit?: () => Promise<void> | void;
  dataRecord?: Types.BillingDataManagement.ResponseType;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Option } = Select;

export interface FormilkProperty {
  provided_date: string;
  order_id: string;
  company_id: string;
  companyID: string;
  company_name: string;
  error: string;
  error_code: string;
  error_name: string;
}

const DetailCorporateCreateCardErr: React.FC<Props> = ({ visible, setVisible, dataRecord }) => {
  const [selectedRow, setSelectedRow] = useState<Types.BillingDataDetail.ResponseType[]>([]);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);
  const [formValue, setFormValue] = useState<FormilkProperty>({
    provided_date: '',
    order_id: '',
    company_id: '',
    companyID: '',
    company_name: '',
    error: '',
    error_code: '',
    error_name: '',
  });
  const {
    dataSelectUserBilling,
    dataSelectUserBillingUniq,
    dataSelectDateDetail,
    dataSelectOrderID,
    dataBillingDetail,
    dataSelectError,
    loading,
  } = useSelector(billingDataManagementSelector);
  const dispatch = useAppDispatch();

  const columns: ColumnsType<Types.BillingDataDetail.ResponseType> = [
    {
      title: () => {
        return (
          <div>
            役務提供年月・ 請求データ番号
            <br />
            請求データ名称
          </div>
        );
      },
      dataIndex: 'provided_date',
      key: 'provided_date',
      width: '24%',
      align: 'left',
      render: (text: string, item) =>
        text ? (
          <div className="item-date">
            <span className="date">{dayjs(text).format('YYYY/MM')}</span>{' '}
            <span className="date">{item.billing_id}</span>
            <br />
            <span className="item-billing">{item.billing_data_name}</span>
          </div>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: (
        <span className="text-left">
          オーダーID
          <br />
          会員ID
        </span>
      ),
      dataIndex: 'company_id',
      key: 'company_id',
      width: '15%',
      align: 'left',
      render: (text: string, item) =>
        text ? (
          <div>
            <span className="date">{item.order_id}</span>
            <br />
            <span className="item-billing">{text}</span>
          </div>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: () => {
        return (
          <div>
            ユーザーID
            <br />
            ユーザー名
          </div>
        );
      },
      dataIndex: 'company_id',
      key: 'company_id',
      width: '11%',
      align: 'left',
      render: (text: string, item) =>
        text ? (
          <div className="item-date">
            <span className="date">{text}</span>
            <br />
            <span className="item-billing">{item.company_name}</span>
          </div>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: '請求種類',
      dataIndex: 'billing_type',
      key: 'billing_type',
      width: '7%',
      align: 'center',
      render: (billing_type: number) =>
        billing_type === 1 ? (
          <span className="item-billing">通常請求</span>
        ) : (
          <span className="text-active">エラー請求</span>
        ),
    },
    {
      title: '決済結果',
      dataIndex: 'error_code',
      key: 'error_code',
      width: '7%',
      render: (error_code) =>
        error_code ? (
          <span className="item-billing">失敗</span>
        ) : (
          <span className="item-billing">成功</span>
        ),
      align: 'center',
    },
    {
      title: (
        <span className="text-left">
          初回請求日
          <br />
          請求日
        </span>
      ),
      dataIndex: 'billing_date',
      key: 'billing_date',
      width: '10%',
      render: (text: string) =>
        text ? (
          <span className="item-billing">{dayjs(text).format('YYYY/MM/DD')}</span>
        ) : (
          <span>-</span>
        ),
      align: 'left',
    },
    {
      title: 'アカウント数',
      dataIndex: 'num_of_accounts',
      key: 'num_of_accounts',
      width: '9%',
      align: 'right',
      render: (num_of_accounts) => (
        <span className="item-billing">{Number(num_of_accounts || 0).toLocaleString()}</span>
      ),
    },
    {
      title: '金額',
      dataIndex: 'amount',
      key: 'amount',
      width: '7%',
      align: 'right',
      render: (amount) => (
        <span className="item-billing">{Number(amount || 0).toLocaleString()}</span>
      ),
    },
    {
      title: (
        <span>
          エラーコード
          <br />
          エラー詳細
        </span>
      ),
      dataIndex: 'error_code',
      key: 'error_code',
      width: '10%',
      align: 'left',
      render: (text: string, item) =>
        text ? (
          <div className="item-date">
            <span className="date">{item.error_code}</span>
            <br />
            <span className="item-billing">
              {ERROR_DETAIL_CODE.find((e) => e.code === item.error_code)?.message}
            </span>
          </div>
        ) : (
          <span>-</span>
        ),
    },
  ];

  const formik = useFormik({
    initialValues: {
      provided_date: '',
      order_id: '',
      company_id: '',
      companyID: '',
      company_name: '',
      error: '',
      error_code: '',
      error_name: '',
    },
    onSubmit: async (values) => {
      dispatch(startLoading());
      setFormValue(values);
      const conditions: Array<Types.ConditionsType> = [];
      Object.keys(values)
        .filter((e) => e !== 'error_name' && e !== 'companyID' && e !== 'error')
        .forEach((key) => {
          if (values[key as keyof typeof values]) {
            conditions.push({
              id: key,
              search_value: [String(values[key as keyof typeof values])],
              exact_match: true,
            });
          }
        });
      await dispatch(
        getBillingDataDetail({
          conditions: [
            {
              id: 'billing_id',
              search_value: [dataRecord?.billing_id],
              exact_match: true,
            },
            ...(values.companyID
              ? [
                  {
                    id: 'company_id',
                    search_value: [values.companyID],
                    exact_match: true,
                  },
                ]
              : []),
            ...(values.error_name
              ? [
                  {
                    id: 'error_code',
                    search_value: [values.error_name],
                    exact_match: true,
                  },
                ]
              : []),
            ...conditions,
          ],
          page: 1,
          per_page: 0,
          use_display_id: true,
          include_item_ref: true,
        })
      );
      setPage(1);
      if (values.error) {
        dispatch(filterErrorCode(values.error));
      }
      dispatch(stopLoading());
    },
    onReset: () => {
      dispatch(
        getBillingDataDetail({
          conditions: [
            {
              id: 'billing_id',
              search_value: [dataRecord?.billing_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      setPage(1);
    },
  });

  const handleExportCSV = async (value: string, type?: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getBillingDataDetailExport({
          conditions: [
            {
              id: 'billing_id',
              search_value: [selectedRow.map((row) => row.billing_id).join('|')],
            },
          ],
          page: 1,
          per_page: 0,
          use_display_id: true,
          include_item_ref: true,
          use_or_condition: true,
        })
      );
      if (getBillingDataDetailExport.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          provided_date: item.provided_date,
          billing_detail_id: item.billing_detail_id,
          billing_data_name: item.billing_data_name,
          order_id: item.order_id,
          billing_id: item.billing_id, //
          company_id: item.company_id,
          user_name: item.company_name,
          billing_type: item.billing_type === 1 ? '通常請求' : 'エラー請求',
          payment_result:
            item.payment_method === 1
              ? 'クレジット'
              : item.payment_method === 2
              ? '請求書'
              : 'App内課金',
          billing_date: dayjs(item.billing_date).format('YYYY/MM/DD hh:mm') ?? '',
          num_of_accounts: item.num_of_accounts ?? '0',
          amount: item.amount,
          error_code: item.error_code,
          error_title: ERROR_DETAIL_CODE.find((e) => e.code === item.error_code)?.message ?? '',
          // os: (item.os),
          // gmo_resend: (item.gmo_resend === '1' ? '未送信' : '再送信済'),
        }));

        // const csvString = [
        //   HEADER_BILLING_DATA_DETAIL.map(({ label }) => label),
        //   ...listCsv.map((item) => Object.values(item)),
        // ]
        //   .map((e) => e.join(','))
        //   .join('\n');
        // const bom = '\uFEFF';
        // const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        // saveAs(file, '請求データ管理画面 ●明細モーダル【法人・請求書.csv');
        exportCsv(
          listCsv,
          HEADER_BILLING_DATA_DETAIL,
          '請求データ管理画面 ●明細モーダル【法人・請求書.csv'
        );
      }
    } else {
      const blob = await pdf(<PDFDetail formValue={formValue} dataErr={selectedRow} />).toBlob();
      saveAs(blob, '請求データ明細【法人・クレジット・エラー】.pdf');
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const handleToggleModal = () => {
    formik.resetForm();
    setVisible(false);
    dispatch(resetInitialState());
  };

  useEffect(() => {
    if (!visible) return;
    dispatch(startLoading());
    dispatch(
      getBillingDataDetail({
        conditions: [
          {
            id: 'billing_id',
            search_value: [dataRecord?.billing_id],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(
      getSelectBillingDataDetail({
        conditions: [
          {
            id: 'billing_id',
            search_value: [dataRecord?.billing_id],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
  }, [dataRecord, dispatch, visible]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerPage(dataBillingDetail.length);
    }
  }, [dataBillingDetail]);

  return (
    <Modal
      title="請求データ明細【法人・クレジット・エラー】"
      visible={visible}
      width={1300}
      onCancel={handleToggleModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
        padding: 0,
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled isEmptyData={!dataBillingDetail.length}>
        <div className="container">
          <FormikProvider value={formik}>
            <Form layout="vertical">
              <div className="form-search">
                <Form.Item
                  name="provided_date"
                  className="item"
                  label={<span className="text-label">役務提供年月</span>}
                >
                  <SelectField
                    name="provided_date"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectDateDetail?.map(
                      (item, index) =>
                        item.provided_date && (
                          <Option key={index} value={item.provided_date}>
                            {item.provided_date}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="order_id"
                  className="item"
                  label={<span className="text-label">オーダーID</span>}
                >
                  <SelectField
                    name="order_id"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectOrderID?.map(
                      (item, index) =>
                        item.order_id && (
                          <Option key={index} value={item.order_id}>
                            {item.order_id}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="company_id"
                  className="item"
                  label={<span className="text-label">会員ID</span>}
                >
                  <SelectField
                    name="company_id"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectUserBillingUniq
                      ?.filter((e) => e.error_code)
                      ?.map(
                        (item, index) =>
                          item.company_id && (
                            <Option key={index} value={item.company_id}>
                              {item.company_id}
                            </Option>
                          )
                      )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="companyID"
                  className="item"
                  label={<span className="text-label">ユーザーID</span>}
                >
                  <SelectField
                    name="companyID"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectUserBillingUniq
                      ?.filter((e) => e.error_code)
                      ?.map(
                        (item, index) =>
                          item.company_id && (
                            <Option key={index} value={item.company_id}>
                              {item.company_id}
                            </Option>
                          )
                      )}
                  </SelectField>
                </Form.Item>
              </div>
              <div className="wrap-search">
                <Form.Item
                  name="company_name"
                  className="item"
                  label={<span className="text-label">ユーザー名</span>}
                >
                  <SelectField
                    name="company_name"
                    showSearch
                    placeholder="指定なし"
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectUserBilling?.map(
                      (item, index) =>
                        item.company_name && (
                          <Option key={index} value={item.company_name}>
                            {item.company_name}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="error"
                  className="item"
                  label={<span className="text-label">決済結果</span>}
                >
                  <SelectField
                    name="error"
                    showSearch
                    placeholder="指定なし"
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    <Option value="fail">失敗</Option>
                    <Option value="success">成功</Option>
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="error_code"
                  className="item"
                  label={<span className="text-label">エラーコード</span>}
                >
                  <SelectField
                    name="error_code"
                    showSearch
                    placeholder="指定なし"
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectError?.map(
                      (item, index) =>
                        item.error_code && (
                          <Option key={index} value={item.error_code}>
                            {item.error_code}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="error_name"
                  className="item"
                  label={<span className="text-label">エラー詳細</span>}
                >
                  <SelectField
                    name="error_name"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectError?.map(
                      (item, index) =>
                        item.error_code && (
                          <Option key={index} value={item.error_code}>
                            {ERROR_DETAIL_CODE.find((e) => e.code === item.error_code)?.message}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <SubmitButton className="btn-search" loading={false}>
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <span className="label-reset" onClick={() => formik.resetForm()}>
                  リセット
                </span>
              </div>
            </Form>
          </FormikProvider>
          <Table
            rowKey="index"
            className="table"
            dataSource={dataBillingDetail.map((item, index) => ({ ...item, index }))}
            columns={columns}
            scroll={{ x: 900, y: 400 }}
            rowSelection={{
              onChange: (_, selectedRows: Types.BillingDataDetail.ResponseType[]) =>
                setSelectedRow(selectedRows),
            }}
            pagination={{
              pageSize: perPage,
              total: dataBillingDetail.length,
              current: page,
              onChange: setPage,
              showSizeChanger: false,
              position: ['topRight'],
              showTotal: () => (
                <div className="wrap-select-record">
                  <div className="text-count">
                    {dataBillingDetail.length ? `${(page - 1) * perPage + 1} - ` : ''}
                    {page * perPage > dataBillingDetail.length
                      ? dataBillingDetail.length
                      : page * perPage}
                    <span className="text-static"> </span> / {dataBillingDetail.length}
                    <span className="text-static"> 件</span>
                  </div>

                  <div>
                    <span className="label-select">表示件数：</span>
                    <Select
                      className="select-option"
                      onChange={(e) => {
                        setPerPage(e);
                        setPage(1);
                      }}
                      value={perPage}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    >
                      {SELECT_RECORD.map((e) => (
                        <Option value={e !== '全て' ? e : dataBillingDetail.length}>{e}</Option>
                      ))}
                    </Select>
                  </div>
                </div>
              ),
            }}
          />
        </div>
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択した請求データを処理：
              <Button className="btn btn-active" onClick={handleButtonExport}>
                エクスポート
              </Button>
            </div>
            <Button className="btn btn-outline" onClick={handleToggleModal}>
              閉じる
            </Button>
          </div>
        </div>
        <ModalExportBillingData
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="ユーザーが選択されていません"
          description={
            <>
              エクスポートを実行する請求データを選択して、
              <br />
              再度実行してください。
            </>
          }
        />
      </SectionStyled>
    </Modal>
  );
};

export default DetailCorporateCreateCardErr;
