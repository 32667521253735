import { Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const FileDone = ({ style }: { style?: Style | Style[] }) => (
  <Svg width="16" height="18" viewBox="0 0 16 18" style={style}>
    <Path
      d="M11.5366 4.98326V4.01897C11.5366 3.93058 11.4642 3.85826 11.3758 3.85826H3.66155C3.57316 3.85826 3.50084 3.93058 3.50084 4.01897V4.98326C3.50084 5.07165 3.57316 5.14397 3.66155 5.14397H11.3758C11.4642 5.14397 11.5366 5.07165 11.5366 4.98326ZM3.66155 6.75112C3.57316 6.75112 3.50084 6.82344 3.50084 6.91183V7.87612C3.50084 7.96451 3.57316 8.03683 3.66155 8.03683H7.35798C7.44637 8.03683 7.51869 7.96451 7.51869 7.87612V6.91183C7.51869 6.82344 7.44637 6.75112 7.35798 6.75112H3.66155ZM11.2151 9.08147C8.81847 9.08147 6.87584 11.0241 6.87584 13.4208C6.87584 15.8174 8.81847 17.76 11.2151 17.76C13.6118 17.76 15.5544 15.8174 15.5544 13.4208C15.5544 11.0241 13.6118 9.08147 11.2151 9.08147ZM13.3747 15.5804C12.7982 16.1569 12.0307 16.4743 11.2151 16.4743C10.3995 16.4743 9.63209 16.1569 9.05552 15.5804C8.47896 15.0038 8.16155 14.2364 8.16155 13.4208C8.16155 12.6051 8.47896 11.8377 9.05552 11.2612C9.63209 10.6846 10.3995 10.3672 11.2151 10.3672C12.0307 10.3672 12.7982 10.6846 13.3747 11.2612C13.9513 11.8377 14.2687 12.6051 14.2687 13.4208C14.2687 14.2364 13.9513 15.0038 13.3747 15.5804ZM13.0031 11.894H12.1131C12.0609 11.894 12.0127 11.9181 11.9825 11.9603L10.7069 13.7241L10.2428 13.0833C10.2279 13.0626 10.2083 13.0457 10.1856 13.0342C10.1629 13.0227 10.1377 13.0168 10.1122 13.017H9.22628C9.0957 13.017 9.01936 13.1656 9.0957 13.2721L10.5783 15.3232C10.6426 15.4116 10.7732 15.4116 10.8374 15.3232L13.1316 12.1491C13.21 12.0426 13.1337 11.894 13.0031 11.894ZM6.55441 15.8315H1.89369V1.68862H13.1437V8.59933C13.1437 8.68772 13.216 8.76005 13.3044 8.76005H14.4294C14.5178 8.76005 14.5901 8.68772 14.5901 8.59933V0.885045C14.5901 0.529464 14.3028 0.242188 13.9473 0.242188H1.09012C0.734542 0.242188 0.447266 0.529464 0.447266 0.885045V16.635C0.447266 16.9906 0.734542 17.2779 1.09012 17.2779H6.55441C6.6428 17.2779 6.71512 17.2056 6.71512 17.1172V15.9922C6.71512 15.9038 6.6428 15.8315 6.55441 15.8315Z"
      fill="#C4C4C4"
    />
  </Svg>
);

export default FileDone;
