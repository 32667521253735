import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';

import * as Types from 'types';
import styles from './styles';
import _ from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import dayjs from 'dayjs';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface Props {
  employeeSelected: Types.EmployeeUser.ResponseType[];
}

const FIRST_PAGE_ROW_COUNT = 13;
const PAGE_ROW_COUNT = 18;

const UserDataTable = ({
  employeeSelected,
}: {
  employeeSelected: Types.EmployeeUser.ResponseType[];
}) => {
  const columns: { title: string | string[]; width: string | number; key: string }[] = [
    {
      title: '社員番号',
      width: 90,
      key: 'employee_code',
    },
    {
      title: '氏名',
      width: 120,
      key: 'name',
    },
    {
      title: 'フリガナ',
      width: 100,
      key: 'kana',
    },
    {
      title: '所属',
      width: 100,
      key: 'department_name',
    },
    {
      title: '役職',
      width: 100,
      key: 'position_name',
    },
    {
      title: '年齢',
      width: 30,
      key: 'birthday',
    },
    {
      title: '入社年月日',
      width: 90,
      key: 'hire_date',
    },
    {
      title: '最終ログイン',
      width: 90,
      key: 'last_login_date',
    },
    {
      title: '権限',
      width: 90,
      key: 'role_code',
    },
  ];

  const formatStringData = (val: string) => {
    if (val) {
      return val.replace(/^\d+ /, '');
    }
    return '';
  };
  const formatDateData = (val: Date, format: string) => {
    if (val) {
      return dayjs(val).format(format);
    }
    return '';
  };
  const getAge = (val: Date) => {
    if (val) {
      const diffInYear = dayjs().diff(dayjs(val), 'years');
      return Math.floor(diffInYear);
    }
    return '';
  };
  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow, { height: '26.728px' }]}>
        {columns.map((column, index) => (
          <View key={index} style={[styles.wrapCell, { width: column.width }]}>
            <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
          </View>
        ))}
      </View>

      {employeeSelected.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: '1px solid #e5e5e5',
            },
          ]}
        >
          <View style={[styles.wrapCell, { width: columns[0].width }]}>
            <Text style={styles.tableCell}>{row.employee_code}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[1].width }]}>
            <Text style={styles.tableCell}>{row.name}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[2].width }]}>
            <Text style={[styles.tableCell]}>{row.kana}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[3].width }]}>
            <Text style={styles.tableCell}>{formatStringData(row.department_name)}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[4].width }]}>
            <Text style={styles.tableCell}>{formatStringData(row.positions_name)}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[5].width }]}>
            <Text style={styles.tableCell}>{`${
              row.birthday ? getAge(row.birthday) + '歳' : '-'
            }`}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[6].width }]}>
            <Text style={styles.tableCell}>{formatDateData(row.hire_date, 'YYYY/MM')}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[7].width }]}>
            <Text style={styles.tableCell}>
              {formatDateData(row.last_login_date, 'YYYY/MM/DD HH:mm')}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[8].width }]}>
            <Text style={styles.tableCell}>{row.role_code}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const PDFUsersDocument = (props: Props) => {
  const { employeeSelected } = props;
  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap>
        <FullPage>
          <View style={styles.header}>
            <Text style={styles.textHeader}>社内ユーザーマスタ</Text>
          </View>
          <View style={styles.subscription}>
            <View>
              <Text style={styles.textSubscription}>
                管理コンソールを使用するユーザーの作成・管理を行う画面です。
              </Text>
            </View>
          </View>
          <View style={styles.divider}></View>
          <View style={styles.body}>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>1</Text>
              <Text style={styles.textCountNumber}>-</Text>
              <Text style={styles.textCountNumber}>{employeeSelected.length}</Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{employeeSelected.length}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
            <UserDataTable employeeSelected={employeeSelected.slice(0, FIRST_PAGE_ROW_COUNT)} />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {employeeSelected.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(
          employeeSelected.slice(FIRST_PAGE_ROW_COUNT, employeeSelected.length),
          PAGE_ROW_COUNT
        ).map((page, index) => (
          <Page
            key={index}
            size={pageSize}
            orientation={pageOrientation}
            style={[styles.page, styles.pagePadding]}
            wrap={false}
          >
            <FullPage>
              <UserDataTable employeeSelected={page} />
            </FullPage>
            <PageNumber />
          </Page>
        ))}
    </Document>
  );
};

export default PDFUsersDocument;
