import { createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  isModified: boolean;
  resetForm: boolean;
}

const initialState: InitialState = {
  isModified: false,
  resetForm: false,
};

export const modifiedPage = createSlice({
  name: 'modified-page',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
    setModified: (state, action) => {
      state.isModified = action.payload;
    },
    setResetForm: (state, action) => {
      state.resetForm = action.payload;
    },
  },
});

export const { setModified, setResetForm, resetInitialState } = modifiedPage.actions;

export default modifiedPage.reducer;
