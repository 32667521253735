import { Text, View } from '@react-pdf/renderer';
import styles from './styles';
import React, { useMemo } from 'react';
import Label from 'components/ReactPDF/Label';
import { IOption } from 'types/config';
import { DownIconPdf } from 'assets/svgPDF';
import { Style } from '@react-pdf/types';

interface ITextBoxProps {
  label?: string;
  required?: boolean;
  value?: string | number;
  placeholder?: string;
  options?: IOption[];
  selectBoxStyle?: Style;
  style?: Style;
  showPlaceholder?: boolean;
  icon?: JSX.Element;
}

const SelectBox = (props: ITextBoxProps) => {
  const {
    label,
    required,
    value,
    options,
    placeholder,
    selectBoxStyle,
    style,
    showPlaceholder = true,
    icon,
  } = props;
  const selected = useMemo(
    () => (options ? options.find((obj) => obj.value === value) : null),
    [options, value]
  );
  const placeholderText = useMemo(
    () => (placeholder ? placeholder : `${label || ''}で検索`),
    [label, placeholder]
  );

  return (
    <View style={[styles.selectGroup, style ? style : {}]} wrap={false}>
      {label && <Label label={label} required={required} />}
      <View style={[styles.selectBox, selectBoxStyle ? selectBoxStyle : {}]}>
        {(value || value === 0) && (
          <View style={styles.selectBoxSelected}>
            {selected?.icon && selected.icon(styles.selectedIcon)}
            {icon && <View style={styles.selectedIcon}>{icon}</View>}
            <Text style={styles.selectText}>{selected?.label || value}</Text>
          </View>
        )}
        {showPlaceholder && !(value || value === 0) && (
          <Text style={[styles.selectText, styles.placeholder]}>{placeholderText || ''}</Text>
        )}
        <DownIconPdf style={styles.selectIcon} />
      </View>
    </View>
  );
};

export default SelectBox;
