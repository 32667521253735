import { createSlice } from '@reduxjs/toolkit';

import * as Types from 'types';
import {
  createInvoiceCorrespondence,
  getInvoiceCorrespondence,
  getSelectManager,
  getSelectCompany,
  getInvoiceCorrespondenceDetail,
  getDataSelect,
} from './thunk';

export type InitialState = {
  loading: boolean;
  invoiceCorrespondence: Array<Types.InvoiceCorrespondenceType>;
  selectCompany: Array<Types.Selects.SelectCompanyResType>;
  selectManager: Array<Types.Selects.SelectManagerResType>;
  invoiceCorrespondenceDetail?: Types.InvoiceCorrespondenceType;
  dataSelect: Array<Types.InvoiceCorrespondenceType>;
  total: number;
};

const initialState: InitialState = {
  loading: false,
  invoiceCorrespondence: [],
  selectCompany: [],
  selectManager: [],
  dataSelect: [],
  total: 0,
};

export const invoiceCorrespondenceSlice = createSlice({
  name: 'invoice-correspondence-page',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(createInvoiceCorrespondence.pending, startLoading)
      .addCase(getInvoiceCorrespondence.pending, startLoading)
      .addCase(getSelectManager.pending, startLoading)
      .addCase(getSelectCompany.pending, startLoading);

    builder.addCase(createInvoiceCorrespondence.fulfilled, (state) => {
      stopLoading(state);
    });

    builder.addCase(getInvoiceCorrespondence.fulfilled, (state, action) => {
      state.invoiceCorrespondence = action.payload.report_results.filter((e) => !!e.email);
      state.total = action.payload.totalItems;
      stopLoading(state);
    });

    builder.addCase(getDataSelect.fulfilled, (state, action) => {
      state.dataSelect = action.payload.report_results.filter((e) => !!e.email);
    });

    builder.addCase(getInvoiceCorrespondenceDetail.fulfilled, (state, action) => {
      state.invoiceCorrespondenceDetail = action.payload.report_results[0];
    });

    builder.addCase(getSelectCompany.fulfilled, (state, action) => {
      state.selectCompany = action.payload.report_results;
      stopLoading(state);
    });

    builder.addCase(getSelectManager.fulfilled, (state, action) => {
      state.selectManager = action.payload.report_results;
      stopLoading(state);
    });

    builder
      .addCase(createInvoiceCorrespondence.rejected, stopLoading)
      .addCase(getInvoiceCorrespondence.rejected, stopLoading)
      .addCase(getSelectCompany.rejected, stopLoading)
      .addCase(getSelectManager.rejected, stopLoading);
  },
});

export default invoiceCorrespondenceSlice.reducer;
