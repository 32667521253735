import React from 'react';
import { Button, Input } from 'antd';

import useCurriculumContent, { Props, ReceivedProps } from './hook';
import InformationReleaseNote from '../InformationReleaseNote';
import { NoImageOfficial } from 'assets';
import { Modal } from 'components';
import Styled from './styles';

const { TextArea } = Input;

const CurriculumContentLayout: React.FC<Props> = (props) => {
  const {
    isOpen,
    handleClose,
    dataDetail,
    creator,
    isOpenReleaseNote,
    setIsOpenReleaseNote,
    officialCurriculumMaster,
    providerInformation,
    loading,
  } = props;

  return (
    <Modal
      width={720}
      title="カリキュラム内容"
      open={isOpen}
      onCancel={handleClose}
      bodyStyle={{
        padding: 0,
      }}
      confirmLoading={loading}
    >
      <Styled>
        <div className="wrap-image">
          <img src={dataDetail?.fileID || NoImageOfficial} alt="" className="image" />
        </div>
        <div className="wrap-content">
          <div className="producer">
            <span className="label-producer">制作者：</span>
            <span className="name-producer"> {creator}</span>
          </div>
          <div className="wrap-common">
            <div className="common">
              <span>{dataDetail.name || ''}</span>
              <span className="version">{dataDetail.published_version}</span>
            </div>
          </div>
          <div className="wrap-description">
            <div className="wrap-button">
              <Button className="button" onClick={() => setIsOpenReleaseNote(true)}>
                リリース情報
              </Button>
            </div>
            <div className="wrap-text-area">
              <p className="label-area">説明：</p>
              <TextArea
                value={dataDetail.description || ''}
                className="text-area"
                disabled={true}
              />
            </div>
          </div>
        </div>
        <InformationReleaseNote
          visible={isOpenReleaseNote}
          setVisible={setIsOpenReleaseNote}
          selected={dataDetail}
          dataOfficialCurriculum={officialCurriculumMaster}
          providerInformation={providerInformation}
        />
      </Styled>
    </Modal>
  );
};

const CurriculumContentModal = (props: ReceivedProps) => {
  return <CurriculumContentLayout {...useCurriculumContent(props)} />;
};

export default CurriculumContentModal;
