import { createAsyncThunk } from '@reduxjs/toolkit';

import { ROLES, SELECT_ROLE, USERS } from 'configs';
import { browserLogger } from 'libs/logger';
import { services } from 'services';
import * as Types from 'types';

export const getAdminRoleList = createAsyncThunk<
  Types.GetItemResponseType<Types.Roles.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/getAdminRoleList', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Roles.ResponseType>(ROLES.name, req);
    browserLogger.info('AuthorityMaster/thunk/getAdminRoleList', ROLES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectAdminRole = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/getSelectAdminRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_ROLE.name, req);
    browserLogger.info('AuthorityMaster/thunk/getSelectAdminRole', SELECT_ROLE.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAdminRoleDetail = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/getAdminRoleDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(ROLES.name, req);
    browserLogger.info('AuthorityMaster/thunk/getAdminRoleDetail', ROLES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAdminRole = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/deleteAdminRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(ROLES.name, req);
    browserLogger.info('AuthorityMaster/thunk/deleteAdminRole', ROLES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAdminRole = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Roles.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/createAdminRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ROLES.name, req);
    browserLogger.info('AuthorityMaster/thunk/createAdminRole', ROLES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAdminRole = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Roles.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/updateAdminRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(ROLES.name, req);
    browserLogger.info('AuthorityMaster/thunk/updateAdminRole', ROLES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAdminRolesExportCSV = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/getDataAdminRolesExportCSV', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(ROLES.name, req);
    browserLogger.info('AuthorityMaster/thunk/getDataAdminRolesExportCSV', ROLES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getsearchExportCSV = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/getsearchExportCSV', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.searchExportCSV(ROLES.name, req);
    browserLogger.info('AuthorityMaster/thunk/getsearchExportCSV', ROLES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUsers = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AuthorityMaster/thunk/getUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.name, req);
    browserLogger.info('AuthorityMaster/thunk/getUsers', USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
