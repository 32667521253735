import React from 'react';
import { FormikProps, FormikProvider } from 'formik';
import { Input } from 'antd';
import dayjs from 'dayjs';

import { Styled, ModalStyled } from './styles';
import * as Types from 'types';

interface Props {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  dataCompanyDetail?: Types.Companies.ResponseType;
  formik: FormikProps<{ memo: string }>;
  onSubmit: () => Promise<void>;
  visible: boolean;
}

const ModalFreeTrialManagementDetail: React.FC<Props> = ({
  dataCompanyDetail,
  setVisible,
  onSubmit,
  visible,
  formik,
}) => {
  const handleSubmit = async () => {
    onSubmit && (await onSubmit());
    setVisible(false);
  };

  return (
    <ModalStyled
      open={visible}
      okButton={{
        text: '更新',
        onClick: handleSubmit,
      }}
      cancelButton={{
        text: '閉じる',
        onClick: () => {
          setVisible(false);
        },
      }}
      width={759}
      onCancel={() => setVisible(false)}
      title="無料トライアルユーザー情報 詳細"
    >
      <Styled>
        <FormikProvider value={formik}>
          <div className="header-modal">
            <span>
              {dataCompanyDetail?.name}
              <span className="sub-text"> ( {dataCompanyDetail?.name_furigana}) </span>
            </span>
            <button
              className={
                dayjs().isBefore(dayjs(dataCompanyDetail?.trial_end_date))
                  ? 'under-contract'
                  : 'under-contract-1'
              }
            >
              {dayjs().isBefore(dayjs(dataCompanyDetail?.trial_end_date)) ? 'トライアル中' : '終了'}
            </button>
          </div>
          <div className="table-above">
            <div className="contract-start-date">
              <div className="contract-first">
                <span>開始日時</span>
              </div>
              <div className="contract-second">
                <span>
                  {dataCompanyDetail &&
                    dayjs(dataCompanyDetail.trial_start_date).format('YYYY/MM/DD HH:mm')}
                </span>
              </div>
              <div className="contract-third">
                <span>終了日時</span>
              </div>
              <div className="contract-fourth">
                <span>
                  {dataCompanyDetail &&
                    dayjs(dataCompanyDetail?.trial_end_date).format('YYYY/MM/DD HH:mm')}
                </span>
              </div>
            </div>
            <div className="usage-plan">
              <div className="usage-first">
                <span>残日数</span>
              </div>
              <div className="usage-second">
                <span>
                  {dataCompanyDetail &&
                  dayjs(dataCompanyDetail.trial_end_date).diff(dayjs(), 'day') > 0
                    ? dayjs(dataCompanyDetail.trial_end_date).diff(dayjs(), 'day')
                    : 0}
                </span>
              </div>
            </div>
            <div className="usage-plan">
              <div className="usage-first">
                <span>契約状況</span>
              </div>
              <div className="usage-second">
                <span>
                  {dataCompanyDetail?.trial_completed_flag
                    ? '契約済み'
                    : dayjs().isBefore(dayjs(dataCompanyDetail?.trial_end_date))
                    ? 'トライアル中'
                    : '未契約'}
                </span>
              </div>
            </div>
            <div className="request-method">
              <div className="method-first">
                <span>データ保持日数</span>
              </div>
              <div className="method-second">
                <span>
                  {!dataCompanyDetail?.trial_completed_flag &&
                  dayjs(dataCompanyDetail?.trial_end_date).add(10, 'day').diff(dayjs(), 'day') >
                    0 &&
                  dayjs(dataCompanyDetail?.trial_end_date).add(10, 'day').diff(dayjs(), 'day') < 11
                    ? dayjs(dataCompanyDetail?.trial_end_date).add(10, 'day').diff(dayjs(), 'day')
                    : 0}
                </span>
              </div>
            </div>
          </div>
          <div className="table-below">
            <div className="address">
              <div className="address-first">
                <span>住所</span>
              </div>
              <div className="address-second">
                <span className="address-text">
                  〒 <span className="address-number">{dataCompanyDetail?.postal_code}</span>
                </span>
                <br />
                <span>{dataCompanyDetail?.prefecture}</span>
                &nbsp;
                <span>{dataCompanyDetail?.address}</span>
                &nbsp;
                <span>{dataCompanyDetail?.plot_number}</span>
                <br />
                <span>{dataCompanyDetail?.building_name}</span>
              </div>
            </div>
            <div className="manager-intelligence">
              <div className="intelligence-first">
                <span>管理者情報</span>
              </div>
              <div className="intelligence-second">
                <span className="intelligence-title">
                  {dataCompanyDetail?.admin_name}({dataCompanyDetail?.admin_name_furigana})
                </span>
                <span className="intelligence-text">
                  所属：
                  <span className="intelligence-sub-text">
                    {dataCompanyDetail?.admin_department}
                  </span>
                </span>
                <span className="intelligence-text">
                  役職：
                  <span className="intelligence-sub-text">{dataCompanyDetail?.admin_position}</span>
                </span>
                <span className="intelligence-text">
                  電話番号：<span className="intelligence-sub-text">031234567</span>
                </span>
                <span className="intelligence-text">
                  メールアドレス：
                  <span className="intelligence-email">{dataCompanyDetail?.admin_email}</span>
                </span>
              </div>
            </div>
          </div>
          <div className="table-below">
            <div className="address">
              <div className="address-first">
                <span>メモ</span>
              </div>
              <Input.TextArea
                className="text-area"
                name="memo"
                id="memo"
                value={formik.values.memo}
                onChange={(e) => formik.setFieldValue('memo', e.target.value)}
              />
              {/* <div className="address-second">
                <Input.TextArea
                  className="text-area"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div> */}
            </div>
          </div>
        </FormikProvider>
      </Styled>
    </ModalStyled>
  );
};

export default ModalFreeTrialManagementDetail;
