import { routes } from 'navigations/routes';
import { Roles } from 'types';

type RouterWithScreenPermissionsType = {
  [key in keyof Omit<
    Roles.ResponseType,
    | 'i_id'
    | 'company_id'
    | 'code'
    | 'name'
    | 'deletedat'
    | 'updatedat'
    | 'updatedby'
    | 'createdat'
    | 'createdby'
    | 'payment_master_permission'
    | 'timer_master_permission'
    | 'bundle_master_permission'
  >]: string[];
};

export const ROUTER_WITH_SCREEN_PERMISSIONS: RouterWithScreenPermissionsType = {
  admin_dashboard_permission: [routes.Dashboard.path],
  billing_management_permission: [routes.BillingDataManagement.path],
  official_curriculum_publish_permission: [routes.PublicManagement.path],
  curricullum_master_permission: [routes.CurriculumMaster.path],
  question_master_permission: [routes.QuestionMasterPage.path],
  users_master_permission: [
    routes.UserMaster.path,
    routes.EditUserMaster.path,
    routes.CreateUserMaster.path,
  ],
  roles_master_permission: [routes.AuthorityMaster.path],
  departments_master_permission: [
    routes.AffiliationMaster.path,
    routes.AuthorityMasterEdit.path,
    routes.AuthorityMasterCreate.path,
  ],
  positions_master_permission: [routes.JobTitleMaster.path],
  admin_master_permission: [routes.AdministratorMaster.path],
  partner_management_permission: [routes.PartnerManagement.path],
  curriculum_tree_permission: [routes.CurriculumTree.path, routes.OfficialCurriculumList.path],
  corporate_user_permission: [
    routes.CorporateUserInformationList.path,
    routes.FreeTrialManagement.path,
  ],
  invoice_correspondence_permission: [routes.InvoiceCorrespondence.path],
  admin_storage_permission: [routes.StorageManagement.path],
  individual_user_management_permission: [routes.IndividualUserManagement.path],
  // payment_master_permission: [],
  // timer_master_permission: [],
  groups_master_permission: [],
  skill_check_tree_permission: [],
  skill_check_master_permission: [],
  inter_users_master_permission: [],
  // bundle_master_permission: [],
};

export const REPORT_PERMISSION = [
  'sales_report_permission',
  'user_report_permission',
  'official_curriculum_report_permission',
  'skill_check_implementation_report_permission',
];
