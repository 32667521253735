import React, { useCallback, useEffect } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { Popover, Select } from 'antd';
import { filter } from 'lodash';
import {
  DeleteOutlined,
  DoubleLeftOutlined,
  LeftOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { setUnrelatedQuestionFilled } from 'pages/Curriculum/slice';
import { curriculumSelector } from 'pages/Curriculum/selectors';
import { handleCheckExistQuestion } from 'libs/utils/question';
import { Input, SelectField } from 'components';
import QuestionPopup from '../QuestionPopup';
import { useAppDispatch, usePermission } from 'hooks';
import { Button } from '../styles';
import * as Types from 'types';
import Item from '../Item';

const { Option } = Select;

type Props = {
  setSelectedQuestion: React.Dispatch<React.SetStateAction<Array<Types.Questions.ResponseType>>>;
  setOpenDeleteQuestion: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenNotDelete: React.Dispatch<React.SetStateAction<boolean>>;
  selectedQuestion: Array<Types.Questions.ResponseType>;
  handleLinkQuestion: (type?: 'all') => Promise<void>;
  showModalCreateEditQuestion: () => void;
};

const UnrelatedQuestionSearch: React.FC<Props> = ({
  showModalCreateEditQuestion,
  setOpenDeleteQuestion,
  setSelectedQuestion,
  handleLinkQuestion,
  setOpenNotDelete,
  selectedQuestion,
}) => {
  const { unrelatedQuestionsFilled, unrelatedQuestions, creatorQuestions, nodeLevel4Selected } =
    useSelector(curriculumSelector);

  const dispatch = useAppDispatch();
  const { permissionNumber } = usePermission();

  const handleGetUnrelatedQuestionFilled = useCallback(
    (values: Types.UnrelatedQuestionSearchFormik) => {
      const { creator, question_name } = values;

      const dataFilled = filter(unrelatedQuestions, (item) => {
        return (
          (creator === '' || creator === 'none' || creator == null || item.creator === creator) &&
          (question_name === '' ||
            question_name === 'none' ||
            question_name == null ||
            !!item.name?.includes(question_name))
        );
      });
      dispatch(setUnrelatedQuestionFilled({ items: dataFilled }));
    },
    [unrelatedQuestions, dispatch]
  );

  const formik = useFormik<Types.UnrelatedQuestionSearchFormik>({
    initialValues: {
      creator: '',
      question_name: '',
    },
    onSubmit: async (values) => {
      const hasNonEmptyValue = Object.values(values).some((value) => value);
      if (hasNonEmptyValue) {
        handleGetUnrelatedQuestionFilled(values);
      } else {
        dispatch(setUnrelatedQuestionFilled({ items: unrelatedQuestions }));
      }
    },
    onReset: () => {
      dispatch(setUnrelatedQuestionFilled({ items: unrelatedQuestions }));
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [unrelatedQuestions]);

  return (
    <FormikProvider value={formik}>
      <Form
        labelCol={{
          flex: '27%',
        }}
        colon={false}
        className="form"
      >
        <p className="title">未設定の設問を検索できます。</p>
        <Form.Item
          name="creator"
          label={<span className="label">作成者</span>}
          className="form-input"
        >
          <SelectField
            showSearch
            allowClear
            className="select-input"
            name="creator"
            filterOption={(input, option) =>
              JSON.stringify(option?.children)?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
            }
          >
            {creatorQuestions.map(
              ({ creator }, index) =>
                creator && (
                  <Option key={index} value={creator}>
                    {creator}
                  </Option>
                )
            )}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="question_name"
          label={<span className="label">フリーワード</span>}
          className="form-input"
        >
          <Input className="select-input" name="question_name" />
        </Form.Item>
        <div className="wrap-center">
          <SubmitButton className="btn-search">
            <SearchOutlined className="icon-search" />
            検索
          </SubmitButton>
          <span className="label-reset-2" onClick={() => formik.resetForm()}>
            リセット
          </span>
        </div>
      </Form>
      <div className="result-search">
        <div className="left-side">
          <Button
            active={
              !!unrelatedQuestionsFilled?.length &&
              !!nodeLevel4Selected &&
              !selectedQuestion.length &&
              permissionNumber === 2
            }
            disabled={
              !unrelatedQuestionsFilled.length ||
              !nodeLevel4Selected ||
              !!selectedQuestion.length ||
              permissionNumber !== 2
            }
            onClick={() => handleLinkQuestion('all')}
          >
            <DoubleLeftOutlined className="label-icon" />
          </Button>
          <Button
            active={!!selectedQuestion.length && !!nodeLevel4Selected && permissionNumber === 2}
            disabled={!selectedQuestion.length || !nodeLevel4Selected || permissionNumber !== 2}
            onClick={() => handleLinkQuestion()}
          >
            <LeftOutlined className="label-icon" />
          </Button>
          <Popover
            overlayClassName="tooltip-QA"
            content={QuestionPopup}
            overlayStyle={{
              width: 300,
            }}
            trigger="click"
            placement="bottom"
          >
            <QuestionCircleOutlined className="imageQA" />
          </Popover>
        </div>
        <div className="right-side">
          <div className="result">
            {!!unrelatedQuestionsFilled.length ? (
              unrelatedQuestionsFilled.map((item, index) => (
                <Item
                  key={index}
                  item={item}
                  onSubmit={formik.handleSubmit}
                  selectedQuestion={selectedQuestion}
                  setSelectedQuestion={setSelectedQuestion}
                />
              ))
            ) : (
              <p className="text-result">検索してください</p>
            )}
          </div>
          <div className="footer">
            <Button
              className="btn"
              active={permissionNumber === 2}
              disabled={permissionNumber !== 2}
              onClick={showModalCreateEditQuestion}
            >
              <PlusOutlined className="icon" />
              設問新規作成
            </Button>
            <Button
              active={!!selectedQuestion.length && permissionNumber === 2}
              disabled={!selectedQuestion.length || permissionNumber !== 2}
              className="btn"
              onClick={() =>
                handleCheckExistQuestion({
                  selectedQuestion,
                  dispatch,
                  setOpenDeleteQuestion,
                  setOpenNotDelete,
                })
              }
            >
              <DeleteOutlined className="icon" />
              設問削除
            </Button>
          </div>
        </div>
      </div>
    </FormikProvider>
  );
};

export default UnrelatedQuestionSearch;
