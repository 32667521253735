import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import { config, DISPLAY_ID_KEY, USER_REGISTRATION_HISTORY, USERS } from 'configs';
import { convertFileResponse } from 'libs/utils/question';
import { getPresignedUrlByFileID } from 'services/r2Service';
import { RoleToPublish } from 'constant/enum.constant';
import { services, userService } from 'services';
import * as Types from 'types';
import { browserLogger } from 'libs/logger';

export const signIn = createAsyncThunk<
  Types.SignInRes,
  Types.SignInReq,
  Types.ThunkAPI<Types.requestError>
>('auth/signIn', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.signIn(req);
    localStorage.setItem('sk_access_token', data.token);

    const cookieName = 'auth-token';
    const currentCookie = Cookies.get(cookieName);

    if (!currentCookie || currentCookie !== data.token) {
      Cookies.set(cookieName, data.token, { domain: '.hexabase.com' });
    }

    return { ...data, signInUrl: req.user_code };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const signUp = createAsyncThunk<
  Types.SignUpRes,
  Types.SignUpReq,
  Types.ThunkAPI<Types.requestError>
>('auth/signUp', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.signUp(req);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
export const signUpUser = createAsyncThunk<
  Types.SignUpUserResponse,
  Types.SignUpUserRequest,
  Types.ThunkAPI<Types.requestError>
>('auth/signUpUser', async (req, { rejectWithValue }) => {
  try {
    const { data, status } = await userService.signUpUser(req);
    if (status === 200) {
      const dataInviteUser: any = {
        workspace_id: 'skillfamiliarproduct',
        url: `/api/v0/applications/skillfamiliarpartner/datastores/signup/items/action/${data.item_id}/userinvite`,
        method: 'POST',
        params: {
          item: req.params.item,
          as_params: {
            users: [
              {
                email: req.params.item.name,
                exclusive_w_id: DISPLAY_ID_KEY.exclusive_w_id.name,
              },
            ],
            email_templates_id: config.TEMPLATES_ID,
            domain: 'rsweb-partner.hexabase.com',
            invitation_path: 'confirm_email',
          },
          is_force_update: true,
        },
      };
      browserLogger.info('auth/signUpUser signUpUser', DISPLAY_ID_KEY.exclusive_w_id.name, data);
      await userService.inviteUser(dataInviteUser, data.item_id);
    }

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const resetPassword = createAsyncThunk<
  Types.ResetPasswordRes,
  Types.ResetPasswordReq,
  Types.ThunkAPI<Types.requestError>
>('auth/resetPassword', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.resetPassword(req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const isFirstLogin = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/isFirstLogin', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.name, req);
    browserLogger.info('auth/isFirstLogin', USERS.name, data);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const setNewPassword = createAsyncThunk<
  undefined,
  Types.SetNewPasswordReq,
  Types.ThunkAPI<Types.requestError>
>('auth/setNewPassword', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.setNewPassword(req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getConfirmRegistration = createAsyncThunk<
  Types.GetConfirmRegistrationRes,
  Types.GetConfirmIdReq,
  Types.ThunkAPI<Types.requestError>
>('auth/getConfirmRegistration', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.confirmRegistration(req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateLoginTime = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('auth/updateLoginTime', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.name, req);
    browserLogger.info('auth/updateLoginTime', USERS.name, data);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getUserDetail = createAsyncThunk<
  Types.GetItemDetailResponseType,
  Types.GetItemDetailRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/getUserDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.detail(USERS.name, req);
    browserLogger.info('auth/getUserDetail', USERS.name, data);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getAdminUser = createAsyncThunk<
  Types.GetItemDetailResponseType,
  Types.GetItemDetailRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/getAdminUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.detail(USERS.name, req);
    browserLogger.info('auth/getAdminUser', USERS.name, data);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const changePassword = createAsyncThunk<Types.ChangePasswordRes, Types.ChangePasswordReq>(
  'auth/thunk/changePassword',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await userService.changePassword(req);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeEmail = createAsyncThunk<
  Types.ChangeEmailRes,
  Types.ChangeEmailReq,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/changeEmail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.changeEmail(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserInfo = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/getUserInfo', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.name, req);
    browserLogger.info('auth/getUserInfo', USERS.name, data);
    if (data.items[0]?.icon_fileID) {
      const fileName = extractFileName(data.items[0]?.icon_fileID);
      const nodeFileUrl = (await getPresignedUrlByFileID(data.items[0]?.icon_fileID)) as string;
      const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);

      data.items[0].avatar = convertFileResponse({
        file: fileFromUrl,
        fileID: data.items[0].icon_fileID,
        fileName: fileName,
      });
    }

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUserRegistrationHistory = createAsyncThunk<
  Types.GetItemResponseType<Types.UserRegistrationHistory.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/getDataUserRegistrationHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.UserRegistrationHistory.ResponseType>(
      USER_REGISTRATION_HISTORY.name,
      req
    );
    browserLogger.info(
      'auth/thunk/getDataUserRegistrationHistory',
      USER_REGISTRATION_HISTORY.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateUserRegistrationHistory = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.UserRegistrationHistory.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/updateUserRegistrationHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USER_REGISTRATION_HISTORY.name, req);
    browserLogger.info(
      'auth/thunk/updateUserRegistrationHistory',
      USER_REGISTRATION_HISTORY.name,
      data
    );
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createUserRegistrationHistory = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.UserRegistrationHistory.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/createUserRegistrationHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USER_REGISTRATION_HISTORY.name, {
      ...req,
      return_item_result: true,
      realtime_auto_link: true,
      return_display_id: true,
      access_key_updates: {
        roles_to_publish: [RoleToPublish.admin, RoleToPublish.member],
      },
    });
    browserLogger.info(
      'auth/thunk/createUserRegistrationHistory',
      USER_REGISTRATION_HISTORY.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
