import React from 'react';

const MenuUnfoldOutlined = ({ toggle }) => {
  return (
    <span
      role="img"
      aria-label="menu-unfold"
      tabindex="-1"
      className="anticon anticon-menu-unfold trigger"
      onClick={toggle}
    >
      <svg width="18" height="16" viewBox="0 0 18 16" fill="none">
        <path
          d="M11.2503 9.56473L0.535994 9.56473C0.437779 9.56473 0.357422 9.64509 0.357422 9.7433L0.357422 10.9933C0.357422 11.0915 0.437779 11.1719 0.535994 11.1719L11.2503 11.1719C11.3485 11.1719 11.4289 11.0915 11.4289 10.9933L11.4289 9.7433C11.4289 9.64509 11.3485 9.56473 11.2503 9.56473ZM11.4289 5.01116C11.4289 4.91294 11.3485 4.83259 11.2503 4.83259L0.535995 4.83259C0.437779 4.83259 0.357423 4.91294 0.357423 5.01116L0.357423 6.26116C0.357423 6.35937 0.437779 6.43973 0.535994 6.43973L11.2503 6.43973C11.3485 6.43973 11.4289 6.35937 11.4289 6.26116L11.4289 5.01116ZM0.17885 15.8594L17.6789 15.8594C17.7771 15.8594 17.8574 15.779 17.8574 15.6808L17.8574 14.4308C17.8574 14.3326 17.7771 14.2522 17.6789 14.2522L0.17885 14.2522C0.0806352 14.2522 0.000278606 14.3326 0.000278598 14.4308L0.000278489 15.6808C0.00027848 15.779 0.0806351 15.8594 0.17885 15.8594ZM0.178851 1.75223L17.6789 1.75223C17.7771 1.75223 17.8574 1.67187 17.8574 1.57366L17.8574 0.323659C17.8574 0.225445 17.7771 0.145087 17.6789 0.145087L0.178852 0.145086C0.0806364 0.145086 0.00027984 0.225443 0.000279831 0.323657L0.000279722 1.57366C0.000279713 1.67187 0.0806363 1.75223 0.178851 1.75223ZM17.7815 7.84821L14.2927 5.10045C14.1632 4.99777 13.9713 5.08928 13.9713 5.25446L13.9713 10.75C13.9713 10.9152 14.161 11.0067 14.2927 10.904L17.7815 8.15625C17.805 8.13801 17.824 8.11465 17.837 8.08796C17.8501 8.06127 17.8569 8.03195 17.8569 8.00223C17.8569 7.97252 17.8501 7.94319 17.837 7.9165C17.824 7.88981 17.805 7.86645 17.7815 7.84821Z"
          fill="#CCCCCC"
        />
      </svg>
    </span>
  );
};

export default MenuUnfoldOutlined;
