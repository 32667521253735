import { Text, View } from '@react-pdf/renderer';
import styles from './styles';
import React from 'react';
import { Style } from '@react-pdf/types';

interface IProps {
  label?: string;
  required?: boolean;
  style?: Style;
}

const Label = ({ label, required }: IProps) => (
  <View style={styles.labelSection}>
    <Text style={styles.label}>{label}</Text>
    {required && <Text style={styles.required}>*</Text>}
  </View>
);

export default Label;
