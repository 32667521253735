import styled from 'styled-components';

export const SectionStyled = styled.div`
  font-size: 13px;
  color: #424242;
  padding: 0 20px;
  .text-label {
    font-size: 13px;
    display: block;
    color: #424242;
  }
  .indexing {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px dashed #cccccc;
    margin-bottom: 10px;
    .index-number {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      background: #424242;
      width: 19px;
      height: 19px;
      color: #ffffff;
      margin-right: 10px;
      span {
        font-size: 11px;
        color: #dddddd;
      }
    }
    .title {
      font-size: 13px;
      color: #424242;
    }
  }
  .item {
    margin: 20px 0;
    .text-label {
      font-size: 13px;
      color: #2a2a2a;
    }
    .wrap-value {
      width: 100%;
      padding: 10px;
      background: #ffffff;
      border: 1px solid rgba(204, 204, 204, 0.4);
      box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
      border-radius: 3px;
      .value {
        font-size: 14px;
        color: #2a2a2a;
      }
    }
    .wrap-time-limit {
      display: flex;
      align-items: center;
      .note {
        margin-left: 10px;
        font-size: 12px;
        color: #777777;
      }
    }
    .small-width {
      text-align: center;
      width: 33%;
    }
    .description {
      height: 100px;
      overflow-y: auto;
    }
    .question-image {
      justify-content: space-between;
      .image-item {
        text-align: center;
        width: 33%;
        .title {
          font-size: 12px;
          color: #777777;
        }
        .image {
          background-color: #efefef;
          border: 1px solid #eeeeee;
          box-sizing: content-box;
          height: 126px;
          width: 224px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 6px;
          cursor: pointer;
          .image-question {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .answer {
      height: 120px;
      overflow-y: auto;
      justify-content: space-between;
      .value {
        width: 76%;
      }
      .image {
        background-color: #efefef;
        border: 1px solid #eeeeee;
        box-sizing: content-box;
        height: 90px;
        width: 160px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 6px;
        cursor: pointer;
        .image-question {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
    .row {
      display: flex;
    }
  }
`;
