import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getCompanies,
  getDataAdminDetails,
  getDataSearchUsers,
  getEmployeeUser,
  getUserSearch,
  updateInformation,
} from './thunk';
import { AdministratorMasterFormik } from 'types';
import * as Types from 'types';

export type InitialState = {
  information: AdministratorMasterFormik;
  dataAdminDetail: Array<any>;
  dataUser: Array<Types.Users.ResponseType>;
  loading: boolean;
  total: number;
  employees: Array<Types.EmployeeUser.ResponseType>;
};

const initialState: InitialState = {
  information: {
    plan_name: '',
    number_of_users: 0,
    name: '',
    name_furigana: '',
    postal_code: '',
    prefecture: '',
    address: '',
    plot_number: '',
    building_name: '',
    admin_name: '',
    admin_name_furigana: '',
    admin_department: '',
    admin_position: '',
    admin_position_name: '',
    admin_phone: '',
    admin_email: '',
    user_type: '',
    bank_account_number: '',
    bank_account_type: '',
    bank_code: '',
    bank_name: '',
    branch_code: '',
    branch_name: '',
    business_registration_number: '',
    fax: '',
  },
  dataAdminDetail: [],
  employees: [],
  dataUser: [],
  loading: false,
  total: 0,
};

export const administratorMasterSlice = createSlice({
  name: 'administratorMasterSlice-page',
  initialState,
  reducers: {
    setInformationRegister(state, action: PayloadAction<AdministratorMasterFormik>) {
      state.information = action.payload;
    },
  },
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getDataAdminDetails.pending, startLoading)
      .addCase(updateInformation.pending, startLoading);

    builder.addCase(getUserSearch.fulfilled, (state, action) => {
      state.information = {
        ...state.information,
        number_of_users: action.payload.totalItems,
        admin_name: action.payload.items[0]?.name,
        admin_name_furigana: action.payload.items[0]?.name_furigana,
        admin_email: action.payload.items[0]?.email,
      };
    });

    builder.addCase(getCompanies.fulfilled, (state, action) => {
      state.information = {
        ...state.information,
        i_id: action.payload.items[0]?.i_id,
        plan_name: action.payload.items[0]?.plan_id,
        name: action.payload.items[0]?.name,
        name_furigana: action.payload.items[0]?.name_furigana,
        admin_email: action.payload.items[0]?.admin_email,
        admin_name: action.payload.items[0]?.admin_name,
        admin_name_furigana: action.payload.items[0]?.admin_name_furigana,
        postal_code: action.payload.items[0]?.postal_code,
        prefecture: action.payload.items[0]?.prefecture,
        address: action.payload.items[0]?.address,
        plot_number: action.payload.items[0]?.plot_number,
        admin_position: action.payload.items[0]?.admin_position,
        admin_department: action.payload.items[0]?.admin_department,
        building_name: action.payload.items[0]?.building_name,
        admin_phone: action.payload.items[0]?.admin_phone,
        fax: action.payload.items[0]?.fax,
        business_registration_number: action.payload.items[0]?.business_registration_number,
        bank_name: action.payload.items[0]?.bank_name,
        bank_code: action.payload.items[0]?.bank_code,
        branch_name: action.payload.items[0]?.branch_name,
        branch_code: action.payload.items[0]?.branch_code,
        bank_account_number: action.payload.items[0]?.bank_account_number,
        bank_account_type: action.payload.items[0]?.bank_account_type,
        admin_position_name: action.payload.items[0]?.admin_position_name,
      };
    });

    builder.addCase(getDataSearchUsers.fulfilled, (state, action) => {
      state.dataAdminDetail = action.payload.items;
      stopLoading(state);
    });

    builder.addCase(getEmployeeUser.fulfilled, (state, action) => {
      state.employees = action.payload.report_results;
    });

    builder
      .addCase(getDataAdminDetails.rejected, stopLoading)
      .addCase(updateInformation.rejected, stopLoading);
  },
});

export const { setInformationRegister } = administratorMasterSlice.actions;

export default administratorMasterSlice.reducer;
