import styled from 'styled-components';

export default styled.div`
  text-align: center;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
  }
  .icon {
    font-size: 28px;
    color: #bbbbbb;
  }
  .text-title {
    font-size: 18px;
    color: #424242;
  }
`;
