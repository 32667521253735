import styled from 'styled-components';
import { Setting } from 'assets';

interface Props {
  isOpenMenuRight: boolean;
  collapsedMenu: boolean;
  tabActive: number;
  countLine: number;
  index?: number;
}

export default styled.section<Props>`
  .header {
    position: sticky;
    top: 0;
    right: 0;
    z-index: 1000;
    .filter-icon {
      color: #c4c4c4;
      margin-right: 5px;
    }
    .sortByDesc-icon {
      margin: 0 5px 0 20px;
    }
    .text-link {
      font-size: 14px;
      text-align: center;
      text-decoration-line: underline;
      color: #00a3a5;
      margin-left: 20px;
    }
    .form {
      display: flex;
      align-items: center;
      .form-input {
        margin-bottom: unset;
        &:nth-child(2) {
          .ant-select-selector {
            width: 154px;
          }
        }
        &:nth-child(3) {
          .ant-select-selector {
            width: 125px;
          }
        }
        &:nth-child(5) {
          .ant-select-selector {
            width: 238px;
          }
        }
      }
      .select-input {
        min-width: 200px;
      }
      .text-reset {
        border: none;
        background-color: unset;
        text-decoration-line: underline;
        color: #08a3a5;
        cursor: pointer;
      }
    }
  }
  .wrap-title {
    position: sticky;
    top: 56px;
    right: ${(props) => (props.isOpenMenuRight ? '320px' : 0)};
    width: calc(100% - ${(props) => (props.isOpenMenuRight ? 320 : 0)}px);
    z-index: 1000;
    background: #ffffff;
    border-bottom: 1px solid #ebeaea;
    .wrap-button {
      display: flex;
      justify-content: space-between;
      padding: 25px 40px 25px 25px;
    }
    .button-function {
      .btn {
        padding: 5px;
        max-width: 140px;
        min-width: 121px;
        cursor: pointer;
        font-size: 13px;
        border-radius: 5px;
        color: #f1a900;
        font-weight: bold;
        margin-left: 4px;
      }
      .btn-active {
        background: #f6ac00;
        border: 1px solid #f6ac00;
        color: #ffffff;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        text-align: start;
        .size-icon {
          font-size: 14px;
          margin-right: 20px;
        }
      }
      .btn-outline {
        background: #ffffff;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        text-align: end;
        .size-icon-down-outline {
          color: #f6ac00;
          font-size: 10px;
          margin-left: 20px;
        }
      }
    }
    .flex-label {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #bbbbbb;
      .label-text {
        font-size: 13px;
        color: #777777;
        width: 16.67%;
        cursor: pointer;
        text-align: center;
        user-select: none;
        .icon-label {
          margin-right: 7px;
        }
      }
      .active {
        color: #00a3a5;
        font-weight: 700;
      }
    }
    .title-add {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px;
      background: #f8f8f8;
      margin: 0;
      .btn-add {
        background: #f6ac00;
        font-weight: bold;
        font-size: 12px;
        color: #ffffff;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        padding: 5px 15px;
      }
      .disabled {
        font-weight: bold;
        font-size: 12px;
        color: #777777;
        border-radius: 5px;
        border: 1px solid #cccccc;
        padding: 5px 15px;
      }
    }
  }
  .on-top {
    position: fixed;
    top: 56px;
    right: ${(props) => (props.isOpenMenuRight ? '320px' : 0)};
    z-index: 999;
  }
  .setting-border {
    .setting {
      position: absolute;
      top: 7px;
      right: 1px;
      z-index: 10;
      cursor: pointer;
      .title {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 40px;
        height: 163px;
        background-image: url(${Setting});
        background-repeat: no-repeat;
        padding: 10px 0;
        font-size: 13px;
        color: #ffffff;
        .opened {
          transform: rotate(180deg);
        }
        p {
          writing-mode: tb-rl;
          font-weight: 700;
          margin: 0;
        }
      }
    }
    .border-green {
      width: 5px;
      height: 186px;
      position: absolute;
      top: 0;
      right: 0;
      background: #00a3a5;
    }
  }
  .flex {
    display: flex;
    .dashboard {
      width: calc(100% - ${(props) => (props.isOpenMenuRight ? '320px' : '0px')});
      position: relative;
      ${(props) => {
        const firstLimit = 18;
        const limit = 20;
        if (props.tabActive === 0 && props.countLine <= firstLimit && props.index === 0) {
          return 'height: 968px;';
        }
        if (props.tabActive === 0 && props.countLine <= limit && props.index !== 0) {
          return 'height: 1023px;';
        }
        if (props.tabActive === 2 && props.countLine <= 12) {
          return 'height: 965px;';
        }
        if (props.tabActive === 2 && props.countLine > 12) {
          return 'height: 2030px;';
        }
        return '';
      }}
      .wrap-body {
        .wrap-title-tab-1 {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #eff9fc;
          border: 2px solid #009acb;
          border-radius: 2px;
          margin: 20px;
          position: fixed;
          z-index: 999;
          .title {
            font-weight: 700;
            font-size: 15px;
            align-items: center;
            color: #009acb;
            margin: 10px;
          }
          .sub-title {
            font-size: 12px;
            font-weight: 400;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .wrap-tree {
          width: 100%;
          background: #f2f2f2;
          border: 1px solid rgba(0, 0, 0, 0.05);
          padding: 14px 14px 0;
        }
        .bordered {
          border-bottom: 4px solid #eeeeee;
        }
      }
    }
  }
  .page-wrapper {
    page-break-before: always;
  }
  .page-wrapper:after {
    counter-increment: page;
    content: '-' counter(page) '-';
    margin-left: 50%;
    white-space: nowrap;
    z-index: 99999;
  }
`;

interface ButtonProps {
  tabActive: number;
  index: number;
}

export const Button = styled.button<ButtonProps>`
  padding: 0 5px;
  height: 32px;
  cursor: pointer;
  font-weight: ${(props) => (props.tabActive === props.index ? 'bold' : 'unset')};
  font-size: 13px;
  box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
  border-left: none;
  background-color: ${(props) =>
    props.tabActive === props.index
      ? props.tabActive === 0
        ? '#00a3a5'
        : props.tabActive === 1
        ? '#009ACB'
        : '#EB8B55'
      : '#ffffff'};
  color: ${(props) =>
    props.tabActive === props.index
      ? '#ffffff'
      : props.tabActive === 0
      ? '#00a3a5'
      : props.tabActive === 1
      ? '#009ACB'
      : '#EB8B55'};
  border: 1px solid
    ${(props) =>
      props.tabActive === 0 ? '#00a3a5' : props.tabActive === 1 ? '#009ACB' : '#EB8B55'};
  ${(props) =>
    props.tabActive === props.index &&
    `.size-icon {
        margin-right: 12px;
      }`};
  &:nth-child(3) {
    border-radius: 0 3px 3px 0;
  }
  &:nth-child(1) {
    border-radius: 3px 0 0 3px;
  }
  @media (max-width: 1440px) {
    padding: 0 5px;
    font-size: 11px;
  }
`;
