import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';

import * as Types from 'types';
import styles from './styles';
import _ from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import { DownIconPdf, FileOutlinedPdf } from 'assets/svg/svgPDF';
import dayjs from 'dayjs';
import { formatNumberComma } from 'libs/utils/format';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface Props {
  dataBillingDetail: Types.BillingDataManagement.ResponseType[];
  provided_date?: string;
  user_type?: number | undefined;
  payment_method?: number | undefined;
  status?: string;
  billing_type?: number | undefined;
  billing_id?: string;
  total: number;
  perPage: number;
  page: number;
}

const FIRST_PAGE_ROW_COUNT = 13;
const PAGE_ROW_COUNT = 18;

const BillingDataTable = ({
  dataSource,
}: {
  dataSource: Types.BillingDataManagement.ResponseType[];
}) => {
  const columns: { title: string | string[]; width: string | number; key: string }[] = [
    {
      title: ['役務', '提供年月'],
      width: 60,
      key: 'provided_date',
    },
    {
      title: '請求データ番号',
      width: 70,
      key: 'billing_id',
    },
    {
      title: '名称',
      width: 170,
      key: 'billing_data_name',
    },
    {
      title: ['ユーザー', '種類'],
      width: 60,
      key: 'user_type',
    },
    {
      title: '決済手段',
      width: 60,
      key: 'payment_method',
    },
    {
      title: '請求種類',
      width: 60,
      key: 'billing_type',
    },
    {
      title: '請求月',
      width: 80,
      key: 'billing_month',
    },
    {
      title: ['請求件数', '請求金額'],
      width: 80,
      key: 'num_of_billings',
    },
    {
      title: ['エラー', '件数'],
      width: 60,
      key: 'num_of_errors',
    },
    {
      title: '再送信',
      width: 60,
      key: 'resend',
    },
    {
      title: '明細',
      width: 60,
      key: 'operation',
    },
  ];

  const isStringArray = (val: any) => {
    if (Array.isArray(val)) {
      return true;
    } else {
      return false;
    }
  };
  const formatDateData = (val: string) => {
    if (val) {
      return dayjs(val).format('YYYY/MM');
    }
    return '-';
  };

  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow, { height: '26.728px' }]}>
        {columns.map((column, index) =>
          isStringArray(column.title) ? (
            <View
              key={index}
              style={[
                styles.wrapCell,
                {
                  width: column.width,
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: [7, 8].includes(index)
                    ? 'right'
                    : [3].includes(index)
                    ? 'center'
                    : 'left',
                },
              ]}
            >
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title[0]}</Text>
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title[1]}</Text>
            </View>
          ) : (
            <View
              key={index}
              style={[
                styles.wrapCell,
                {
                  width: column.width,
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: [9].includes(index)
                    ? 'right'
                    : [4, 5, 6, 10].includes(index)
                    ? 'center'
                    : 'left',
                },
              ]}
            >
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
            </View>
          )
        )}
      </View>

      {dataSource.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5',
            },
          ]}
        >
          <View style={[styles.wrapCell, { width: columns[0].width }]}>
            <Text style={styles.tableCell}>{formatDateData(row.provided_date)}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[1].width, color: '#777777' }]}>
            <Text style={styles.tableCell}>{row.billing_id}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[2].width }]}>
            <Text style={[styles.tableCell]}>{row.billing_data_name}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[3].width }]}>
            <Text style={styles.tableCellCenter}>{row.user_type === 1 ? '法人' : '個人'}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[4].width }]}>
            <Text style={styles.tableCellCenter}>
              {row.payment_method === 1
                ? 'クレジット'
                : row.payment_method === 2
                ? '請求書'
                : 'App内課金'}
            </Text>
          </View>
          <View
            style={[
              styles.wrapCell,
              { width: columns[5].width, color: row.billing_type !== 1 ? '#f0330a' : '#424242' },
            ]}
          >
            <Text style={styles.tableCellCenter}>
              {row.billing_type === 1 ? '通常請求' : 'エラー請求'}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[6].width }]}>
            <Text style={styles.tableCellCenter}>{formatDateData(row.billing_month)}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[7].width, textAlign: 'right' }]}>
            <Text style={styles.tableCellStyle}>{formatNumberComma(row.num_of_billings)}件</Text>
            <Text style={styles.tableCellStyle}>{formatNumberComma(row.amount_total)}円</Text>
          </View>
          <View
            style={[
              styles.wrapCell,
              {
                width: columns[8].width,
                textDecoration:
                  (row.num_of_errors || row.num_of_errors === 0) &&
                  typeof row.num_of_errors == 'string'
                    ? 'underline'
                    : 'none',
                textAlign: 'center',
                color: '#08a3a5',
              },
            ]}
          >
            <Text style={styles.tableCellStyle}>
              {row.num_of_errors || row.num_of_errors === 0
                ? `${row.num_of_errors}件` === `0件` || `${row.num_of_errors}件` === '件'
                  ? '-'
                  : `${row.num_of_errors}件`
                : '-'}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[9].width }]}>
            <Text style={styles.tableCellStyle}>{row.resend ? `${row.resend}件` : '-'}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[10].width, alignItems: 'center' }]}>
            <FileOutlinedPdf style={{ transform: 'scale(0.7)' }} />
          </View>
        </View>
      ))}
    </View>
  );
};

const PDFBillingDataManagement = (props: Props) => {
  const {
    dataBillingDetail,
    provided_date,
    user_type,
    payment_method,
    billing_type,
    billing_id,
    total,
    perPage,
    page,
  } = props;
  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap>
        <FullPage>
          <View style={styles.header}>
            <Text style={styles.textHeader}>請求データ管理</Text>
          </View>
          <View style={styles.subscription}>
            <View>
              <Text style={styles.textSubscription}>
                請求データの管理画面です。請求データの確認・ダウンロードが可能です。
              </Text>
            </View>
          </View>
          <View style={styles.divider}></View>
          <View style={styles.searchPanel}>
            <View style={styles.searchItem}>
              <Text style={styles.label}>役務提供年月</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>{provided_date || ''}</Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>請求データ番号</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>{billing_id}</Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>ユーザー種類</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>
                  {user_type && user_type === 1 ? '法人' : user_type === 2 ? '個人' : ''}
                </Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>決済手段</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>
                  {payment_method && payment_method === 1
                    ? 'クレジット'
                    : payment_method === 2
                    ? '請求書'
                    : payment_method === 3
                    ? 'App内課金'
                    : ''}
                </Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>請求種類</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>
                  {billing_type && billing_type === 1
                    ? '通常請求'
                    : billing_type === 9
                    ? 'エラー請求'
                    : ''}
                </Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
          </View>
          <View style={styles.body}>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>{perPage * (page - 1) + 1}</Text>
              <Text style={styles.textCountNumber}>-</Text>
              <Text style={styles.textCountNumber}>
                {total < perPage * page ? total : perPage * page}
              </Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{total}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
            <BillingDataTable dataSource={dataBillingDetail.slice(0, FIRST_PAGE_ROW_COUNT)} />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {dataBillingDetail.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(
          dataBillingDetail.slice(FIRST_PAGE_ROW_COUNT, dataBillingDetail.length),
          PAGE_ROW_COUNT
        ).map((page, index) => (
          <Page
            key={index}
            size={pageSize}
            orientation={pageOrientation}
            style={[styles.page, styles.pagePadding]}
            wrap={false}
          >
            <FullPage>
              <BillingDataTable dataSource={page} />
            </FullPage>
            <PageNumber />
          </Page>
        ))}
    </Document>
  );
};

export default PDFBillingDataManagement;
