import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  radioGroup: {
    marginBottom: 2,
  },
  groupBox: {
    display: 'flex',
    border: '1px solid #D9D9D9',
    flexDirection: 'row',
    borderRadius: '3px',
    overflow: 'hidden',
  },
  groupItem: {
    width: 150,
    display: 'flex',
    padding: '6px 10px',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
  },
  value: {
    width: '100%',
    flex: 1,
    fontSize: 8,
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
