import React, { useCallback, useEffect } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { filter, uniqBy } from 'lodash';
import { Popover, Select } from 'antd';
import {
  DeleteOutlined,
  DoubleLeftOutlined,
  LeftOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { setQuestionSearchFilled } from 'pages/Curriculum/slice';
import { curriculumSelector } from 'pages/Curriculum/selectors';
import { handleCheckExistQuestion } from 'libs/utils/question';
import { Input, SelectField } from 'components';
import QuestionPopup from '../QuestionPopup';
import { useAppDispatch, usePermission } from 'hooks';
import { Button } from '../styles';
import * as Types from 'types';
import Item from '../Item';

const { Option } = Select;

type Props = {
  setSelectedQuestion: React.Dispatch<React.SetStateAction<Array<Types.Questions.ResponseType>>>;
  setOpenDeleteQuestion: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenNotDelete: React.Dispatch<React.SetStateAction<boolean>>;
  selectedQuestion: Array<Types.Questions.ResponseType>;
  handleLinkQuestion: (type?: 'all') => Promise<void>;
  showModalCreateEditQuestion: () => void;
};

const QuestionSearch: React.FC<Props> = ({
  showModalCreateEditQuestion,
  setOpenDeleteQuestion,
  setSelectedQuestion,
  handleLinkQuestion,
  setOpenNotDelete,
  selectedQuestion,
}) => {
  const {
    questionSearchFilled,
    dataCurriculumTable,
    nodeLevel4Selected,
    unrelatedQuestions,
    creatorQuestions,
    questionSearch,
    curriculums,
    level1s,
    level2s,
    level3s,
    level4s,
  } = useSelector(curriculumSelector);

  const dispatch = useAppDispatch();
  const { permissionNumber } = usePermission();

  const handleGetQuestionFilled = useCallback(
    (values: Types.QuestionSearchFormik) => {
      const {
        createdby,
        curriculum_name,
        level1_name,
        level2_name,
        level3_name,
        level4_name,
        question_name,
      } = values;

      const isSearchCurriculumTable = [
        curriculum_name,
        level1_name,
        level2_name,
        level3_name,
        level4_name,
        question_name,
      ].some((value) => value);

      const questionCreatorFilled = filter(questionSearch, {
        createdby: createdby,
      });

      const dataFilled = filter(dataCurriculumTable, (item) => {
        return (
          (createdby === '' ||
            createdby === 'none' ||
            createdby == null ||
            item.question_creator === createdby) &&
          (curriculum_name === '' ||
            curriculum_name === 'none' ||
            curriculum_name == null ||
            item.curriculum_name === curriculum_name) &&
          (level1_name === '' ||
            level1_name === 'none' ||
            level1_name == null ||
            item.level1_name === (level1_name === 'empty' ? '' : level1_name)) &&
          (level2_name === '' ||
            level2_name === 'none' ||
            level2_name == null ||
            item.level2_name === (level2_name === 'empty' ? '' : level2_name)) &&
          (level3_name === '' ||
            level3_name === 'none' ||
            level3_name == null ||
            item.level3_name === (level3_name === 'empty' ? '' : level3_name)) &&
          (level4_name === '' ||
            level4_name === 'none' ||
            level4_name == null ||
            item.level4_name === (level4_name === 'empty' ? '' : level4_name)) &&
          (question_name === '' ||
            question_name === 'none' ||
            question_name == null ||
            !!item.question_name?.includes(question_name))
        );
      });

      const unrelatedQuestionsFilled = filter(unrelatedQuestions, (item) => {
        return (
          (createdby === '' ||
            createdby === 'none' ||
            createdby == null ||
            item.createdby === createdby) &&
          (question_name === '' ||
            question_name === 'none' ||
            question_name == null ||
            !!item.name?.includes(question_name))
        );
      });

      const uniqueQuestionUnrelatedCodes = uniqBy(unrelatedQuestionsFilled, 'code')
        .filter((question) => question.code !== undefined && question.code !== null)
        .map((question) => question.code);

      const uniqueQuestionCodes = uniqBy(dataFilled, 'question_code')
        .filter(
          (question) => question.question_code !== undefined && question.question_code !== null
        )
        .map((question) => question.question_code);

      const uniqueQuestionSearch = uniqBy(questionCreatorFilled, 'code')
        .filter((question) => question.code !== undefined && question.code !== null)
        .map((question) => question.code);

      const filteredQuestions = questionSearch.filter((question) =>
        (isSearchCurriculumTable
          ? [...uniqueQuestionCodes, ...uniqueQuestionUnrelatedCodes]
          : uniqueQuestionSearch
        ).includes(question.code)
      );

      dispatch(setQuestionSearchFilled({ items: filteredQuestions }));
    },
    [dataCurriculumTable, unrelatedQuestions, dispatch, questionSearch]
  );

  const formik = useFormik<Types.QuestionSearchFormik>({
    initialValues: {
      curriculum_name: '',
      level1_name: '',
      level2_name: '',
      level3_name: '',
      level4_name: '',
      createdby: '',
      question_name: '',
    },
    onSubmit: async (values) => {
      const hasNonEmptyValue = Object.values(values).some((value) => value);
      if (hasNonEmptyValue) {
        handleGetQuestionFilled(values);
      } else {
        dispatch(setQuestionSearchFilled({ items: questionSearch }));
      }
    },
  });

  useEffect(() => {
    const hasNonEmptyValue = Object.values(formik.values).some((value) => value);
    if (hasNonEmptyValue) {
      handleGetQuestionFilled(formik.values);
    } else {
      dispatch(setQuestionSearchFilled({ items: questionSearch }));
    }
  }, [questionSearch]);

  return (
    <FormikProvider value={formik}>
      <Form
        labelCol={{
          flex: '27%',
        }}
        colon={false}
        className="form"
      >
        <p className="title">条件を絞って設問を検索できます。</p>
        <Form.Item
          name="curriculum_name"
          label={<span className="label">カリキュラム</span>}
          className="form-input"
        >
          <SelectField
            showSearch
            allowClear
            name="curriculum_name"
            className="select-input"
            filterOption={(input, option) =>
              JSON.stringify(option?.children)?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
            }
          >
            {curriculums.map((curr, index) => (
              <Option key={index} value={curr.name}>
                {curr.name}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="level1_name"
          label={<span className="label">第１階層</span>}
          className="form-input"
        >
          <SelectField
            showSearch
            allowClear
            name="level1_name"
            className="select-input"
            filterOption={(input, option) =>
              JSON.stringify(option?.children)?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="empty">（空白）</Option>
            {level1s.map((lvl, index) => (
              <Option key={index} value={lvl.name}>
                {lvl.name || '（空白）'}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="level2_name"
          label={<span className="label">第２階層</span>}
          className="form-input"
        >
          <SelectField
            showSearch
            allowClear
            name="level2_name"
            className="select-input"
            filterOption={(input, option) =>
              JSON.stringify(option?.children)?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="empty">（空白）</Option>
            {level2s.map((lvl, index) => (
              <Option key={index} value={lvl.name}>
                {lvl.name || '（空白）'}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="level3_name"
          label={<span className="label">第３階層</span>}
          className="form-input"
        >
          <SelectField
            showSearch
            allowClear
            name="level3_name"
            className="select-input"
            filterOption={(input, option) =>
              JSON.stringify(option?.children)?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="empty">（空白）</Option>
            {level3s.map((lvl, index) => (
              <Option key={index} value={lvl.name}>
                {lvl.name || '（空白）'}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="level4_name"
          label={<span className="label">第４階層</span>}
          className="form-input"
        >
          <SelectField
            showSearch
            allowClear
            name="level4_name"
            className="select-input"
            filterOption={(input, option) =>
              JSON.stringify(option?.children)?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="empty">（空白）</Option>
            {level4s.map((lvl, index) => (
              <Option key={index} value={lvl.name}>
                {lvl.name || '（空白）'}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="createdby"
          label={<span className="label">作成者</span>}
          className="form-input"
        >
          <SelectField
            showSearch
            allowClear
            className="select-input"
            name="createdby"
            filterOption={(input, option) =>
              JSON.stringify(option?.children)?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
            }
          >
            {creatorQuestions.map(
              ({ createdby, creator }, index) =>
                creator && (
                  <Option key={index} value={createdby}>
                    {creator}
                  </Option>
                )
            )}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="question_name"
          label={<span className="label">フリーワード</span>}
          className="form-input"
        >
          <Input className="select-input" name="question_name" />
        </Form.Item>
        <div className="wrap-center">
          <SubmitButton className="btn-search" loading={false}>
            <SearchOutlined className="icon-search" />
            検索
          </SubmitButton>
          <span
            className="label-reset"
            onClick={() => {
              formik.resetForm();
              handleGetQuestionFilled({
                curriculum_name: '',
                level1_name: '',
                level2_name: '',
                level3_name: '',
                level4_name: '',
                createdby: '',
                question_name: '',
              });
            }}
          >
            リセット
          </span>
        </div>
      </Form>
      <div className="result-search">
        <div className="left-side">
          <Button
            active={
              !!questionSearchFilled?.length &&
              !!nodeLevel4Selected &&
              !selectedQuestion.length &&
              permissionNumber === 2
            }
            disabled={
              !questionSearchFilled?.length ||
              !nodeLevel4Selected ||
              !!selectedQuestion.length ||
              permissionNumber !== 2
            }
            onClick={() => handleLinkQuestion('all')}
          >
            <DoubleLeftOutlined className="label-icon" />
          </Button>
          <Button
            active={!!selectedQuestion.length && !!nodeLevel4Selected && permissionNumber === 2}
            disabled={!selectedQuestion.length || !nodeLevel4Selected || permissionNumber !== 2}
            onClick={() => handleLinkQuestion()}
          >
            <LeftOutlined className="label-icon" />
          </Button>
          <Popover
            overlayClassName="tooltip-QA"
            content={QuestionPopup}
            overlayStyle={{
              width: 300,
            }}
            trigger="click"
            placement="bottom"
          >
            <QuestionCircleOutlined className="imageQA" />
          </Popover>
        </div>
        <div className="right-side">
          <div className="result">
            {questionSearchFilled && questionSearchFilled.length > 0 ? (
              questionSearchFilled.map((item, index) => (
                <Item
                  key={index}
                  onSubmit={formik.handleSubmit}
                  selectedQuestion={selectedQuestion}
                  setSelectedQuestion={setSelectedQuestion}
                  item={item}
                />
              ))
            ) : (
              <p className="text-result">検索してください</p>
            )}
          </div>
          <div className="footer">
            <Button
              disabled={permissionNumber !== 2}
              active={permissionNumber === 2}
              className="btn"
              onClick={showModalCreateEditQuestion}
            >
              <PlusOutlined className="icon" />
              設問新規作成
            </Button>
            <Button
              active={!!selectedQuestion.length && permissionNumber === 2}
              disabled={!selectedQuestion.length || permissionNumber !== 2}
              className="btn"
              onClick={() =>
                handleCheckExistQuestion({
                  selectedQuestion,
                  dispatch,
                  setOpenDeleteQuestion,
                  setOpenNotDelete,
                })
              }
            >
              <DeleteOutlined className="icon" />
              設問削除
            </Button>
          </div>
        </div>
      </div>
    </FormikProvider>
  );
};

export default QuestionSearch;
