import React from 'react';

export interface ReceivedProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useDisableAction = (props: ReceivedProps) => {
  const { setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  };
  return { ...props, handleClose };
};

export type Props = ReturnType<typeof useDisableAction>;

export default useDisableAction;
