import styled from 'styled-components';

export const Wrapper = styled.div`
  .wrap_node_tree {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;

    .column {
      width: 18.304%;
      user-select: none;
      border: 0px solid #f2f2f2;
    }

    .column-active {
      background: #eff9fc;
      border-radius: 4px;
      border-color: #ffffff;
      opacity: 1;
      .rowContents {
        opacity: 1 !important;
        .rowTitle {
          color: #2a2a2a !important;
        }
      }
    }
  }
  .rst_tree {
    .rst_node {
      justify-content: left;
    }
  }
`;
