import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const pdfGenerator = (
  content: Array<{ node: HTMLElement; height: number }>,
  name: string
) => {
  if (!content.length) return;

  const pdf = new jsPDF('l', 'px', 'a4');

  content.forEach((pageContent, index) => {
    html2canvas(pageContent.node, {
      useCORS: true,
      scale: 2.5,
      allowTaint: true,
      foreignObjectRendering: false,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      index && pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      if (index === content.length - 1) {
        pdf.save(`${name}.pdf`);
      }
    });
  });
};

const capturePDF = (
  ref: React.RefObject<HTMLElement>,
  fileName?: string,
  orientation?: 'p' | 'l',
  allowTaint?: boolean
) => {
  if (!ref.current) return;

  const name = fileName || '所属マスタ.pdf';

  html2canvas(ref.current, {
    useCORS: true,
    scale: 2.5,
    allowTaint: !allowTaint,
    foreignObjectRendering: false,
  }).then(async (canvas) => {
    const contentDataURL = await canvas.toDataURL('image/png');

    const pdf = await new jsPDF(orientation === 'p' ? 'p' : 'l', 'px', 'a4');
    let position = 0;

    const imgWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    heightLeft -= pageHeight;
    pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight + 25);
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight + 25);
      heightLeft -= pageHeight;
    }
    pdf.setFillColor('#f9f8f8');
    pdf.rect(0, pageHeight - 70, imgWidth, 70, 'F');
    pdf.save(name);
  });
};

export default capturePDF;
