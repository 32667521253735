import React from 'react';

import CreateEmployee from 'pages/UserManagement/UserMaster/Create';
import useCreateUser, { Props, ReceivedProps } from './hook';
import { Modal } from 'components';
import Styled from './styles';

const CreateUserLayout: React.FC<Props> = (props) => {
  const { open, handleCancle, setEmail, fetchEmployeeUser } = props;

  return (
    <Modal
      open={open}
      width={1244}
      bodyStyle={{
        padding: 0,
      }}
    >
      <Styled>
        <CreateEmployee
          handleCancle={handleCancle}
          setEmail={setEmail}
          isUseImport
          fetchEmployee={fetchEmployeeUser}
        />
      </Styled>
    </Modal>
  );
};

const CreateUserModal = (props: ReceivedProps) => {
  return <CreateUserLayout {...useCreateUser(props)} />;
};

export default CreateUserModal;
