import React, { useState } from 'react';
import { CalendarOutlined, MailOutlined } from '@ant-design/icons';
import { Field, FieldProps, FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';

import { startLoading, stopLoading } from '../../../containers/AppSettings/slice';
import { DatePicker, Input, Modal } from 'components';
import { signUpUser } from 'containers/Auth/thunk';
import { registerSchema } from 'libs/validations';
import { SignUpUserRequest } from 'types';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import { LogoPartner } from 'assets';
import yup from 'libs/yup';

interface Props {
  loginEmailList?: string[];
  visibleDetail?: boolean;
  success: () => void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenModalComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalRegister: React.FC<Props> = ({
  loginEmailList = [],
  setVisible,
  success,
  visibleDetail,
  setOpenModalComplete,
}) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const emailValidate = yup.object().shape({
    email: yup
      .string()
      .test(
        'unique-email',
        '入力したメールアドレスは既に登録されています。別のメールアドレスを入力してください。',
        (value: any) => !loginEmailList.includes(value)
      ),
  });

  const formik = useFormik({
    initialValues: { email: '', date: '' },
    validationSchema: registerSchema.concat(emailValidate),
    validateOnBlur: false,
    onSubmit: async (values, { setFieldError, resetForm }) => {
      dispatch(startLoading());
      const data: SignUpUserRequest = {
        workspace_id: 'skillfamiliarproduct',
        url: '/api/v0/applications/skillfamiliarpartner/datastores/signup/items/new',
        method: 'POST',
        params: {
          action_id: 'signup',
          item: {
            name: values.email,
            start_at: new Date(),
          },
          as_params: {
            email: values.email,
            username: values.email,
          },
          is_force_update: true,
          realtime_auto_link: true,
        },
      };

      const resultActionAddUser = await dispatch(signUpUser(data));
      if (signUpUser.fulfilled.match(resultActionAddUser)) {
        // await success();
        resetForm();
        setEmail('');
        dispatch(stopLoading());
        setVisible(false);
        setOpenModalComplete(true);
      } else if (signUpUser.rejected.match(resultActionAddUser)) {
        dispatch(stopLoading());
        setFieldError(
          'email',
          '入力したメールアドレスは既に登録されています。別のメールアドレスを入力してください。'
        );
      }
    },
  });

  const handleCancelModalDetail = () => {
    setVisible(false);
    formik.resetForm();
    setEmail('');
  };

  const validateEmail = (email: string) => {
    return loginEmailList.includes(email);
  };

  return (
    <Modal
      width={560}
      visible={visibleDetail}
      onCancel={handleCancelModalDetail}
      bodyStyle={{
        backgroundColor: '#FFF',
        borderRadius: '5px',
        padding: 5,
      }}
    >
      <SectionStyled>
        <div className="border-style-top" />
        <div className="content-register">
          <img src={LogoPartner} className="icon" alt="required-icon" />
          <p className="des-image">For Partners</p>
          <div className="partners-text">パートナー企業アカウント登録</div>
          <div className="partners-description">
            アカウント登録を行うパートナー企業の
            <br />
            メールアドレスと契約開始日を入力し、送信してください。
          </div>
          <FormikProvider value={formik}>
            <Form className="form">
              <Form.Item name="email">
                <Input
                  name="email"
                  type="email"
                  placeholder="メールアドレス"
                  className="input"
                  autoComplete={localStorage.getItem('remember_me') === 'saved' ? 'on' : 'off'}
                  prefix={<MailOutlined className="icon" />}
                  onChange={(v) => setEmail(v.target.value)}
                />
              </Form.Item>
              <Form.Item name="date">
                <Field name="date">
                  {({ field, form }: FieldProps) => (
                    <DatePicker
                      {...field}
                      className="input"
                      placeholder="契約開始日"
                      autoComplete={localStorage.getItem('remember_me') === 'saved' ? 'on' : 'off'}
                      // prevIcon={<CalendarOutlined className="icon" />}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement!}
                      onChange={(value) => form.setFieldValue('date', value)}
                      value={field.value}
                    />
                  )}
                </Field>
              </Form.Item>
              <SubmitButton className="btn-submit" loading={false} disabled={validateEmail(email)}>
                送信
              </SubmitButton>
            </Form>
          </FormikProvider>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default ModalRegister;
