import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';

import * as Types from 'types';
import styles from './styles';
import _ from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import { StopPublicPdf, TickCompletePdf, UnPublishedPdf, WhiteEditingPdf } from 'assets/svg/svgPDF';
import dayjs from 'dayjs';
import { StopPublic, TickComplete, UnPublished, WhiteEditing } from 'assets';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface Props {
  dataSource: Types.AdminReleaseNoteManagement.ResponseType[];
  official_curriculum_name: string;
  official_curriculum_code: string;
  creator: string;
  publish: string;
}

const FIRST_PAGE_ROW_COUNT = 13;
const PAGE_ROW_COUNT = 18;
const CURRICULUM_STATUS_MAPPING: {
  [key: string]: { icon: any; text: string; pdfIcon: any; textColor: string };
} = {
  '0': {
    icon: WhiteEditing,
    text: '編集中',
    pdfIcon: <WhiteEditingPdf />,
    textColor: '#b57fcf',
  },
  '1': {
    icon: UnPublished,
    text: '未公開',
    pdfIcon: <UnPublishedPdf />,
    textColor: '#6a6ecd',
  },
  '2': {
    icon: TickComplete,
    text: '公開中',
    pdfIcon: <TickCompletePdf />,
    textColor: '#71c131',
  },
  '3': {
    icon: StopPublic,
    text: '公開停止中',
    pdfIcon: <StopPublicPdf />,
    textColor: '#989898',
  },
};
const StatusIcon = (pbStatus: string) => CURRICULUM_STATUS_MAPPING[pbStatus]?.pdfIcon;
const StatusText = (pbStatus: string) => CURRICULUM_STATUS_MAPPING[pbStatus]?.text;
const StatusColorText = (pbStatus: string) => CURRICULUM_STATUS_MAPPING[pbStatus]?.textColor;

const ManageReleaseTable = ({
  dataSource,
}: {
  dataSource: Types.AdminReleaseNoteManagement.ResponseType[];
}) => {
  const columns: { title: string | string[]; width: string | number; key: string }[] = [
    {
      title: 'No.',
      width: 30,
      key: 'publish_start_date',
    },
    {
      title: ['OFFICIALカリキュラム', '公開日時（更新日時）'],
      width: 100,
      key: 'release_version',
    },
    {
      title: 'バージョン',
      width: 60,
      key: 'version',
    },
    {
      title: '公開種類',
      width: 50,
      key: 'release_note_id',
    },
    {
      title: ['リリースノート', '作成状況'],
      width: 100,
      key: 'release_note_title',
    },
    {
      title: 'リリースノートタイトル',
      width: 320,
      key: 'createdat',
    },
    {
      title: ['リリースノート', '作成・更新日時'],
      width: 80,
      key: 'createdby',
    },
    {
      title: '作成者',
      width: 60,
      key: 'createdby',
    },
    {
      title: ['リリースノート', '公開ステータス'],
      width: 70,
      key: 'release_note_publish_status',
    },
  ];

  const formatDateData = (val: Date | string) => {
    if (val) {
      return dayjs(val).locale('ja').format('YYYY/MM/DD HH:mm');
    }
    return '';
  };
  const isStringArray = (val: any) => {
    if (Array.isArray(val)) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow]}>
        {columns.map((column, index) =>
          isStringArray(column.title) ? (
            <View
              key={index}
              style={[
                styles.wrapCell,
                {
                  width: column.width,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                },
              ]}
            >
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title[0]}</Text>
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title[1]}</Text>
            </View>
          ) : (
            <View
              key={index}
              style={[
                styles.wrapCell,
                { width: column.width, borderRight: index === 3 ? '1px solid #e5e5e5' : 0 },
              ]}
            >
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
            </View>
          )
        )}
      </View>

      {dataSource.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5',
            },
          ]}
        >
          <View style={[styles.wrapCell, { width: columns[0].width, color: '#999999' }]}>
            <Text style={styles.tableCell}>{rowIndex + 1}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[1].width, color: '#777777' }]}>
            <Text style={styles.tableCell}>
              {row.publish_start_date ? formatDateData(row.publish_start_date) : '-'}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[2].width }]}>
            <Text style={styles.tableCell}>{row.version || '-'}</Text>
          </View>
          <View
            style={[styles.wrapCell, { width: columns[3].width, borderRight: '1px solid #e5e5e5' }]}
          >
            <Text style={[styles.tableCell]}>
              {!rowIndex
                ? ' 初回公開'
                : rowIndex === dataSource.length - 1
                ? row.release_note_id
                  ? '更新'
                  : '未更新'
                : '更新'}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[4].width }]}>
            <Text style={{ ...styles.tableCell, alignItems: 'center', textAlign: 'center' }}>
              {!row.release_note_id ? '未作成' : '作成済'}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[5].width, padding: 0 }]}>
            <Text style={styles.tableCell}>
              {!row.release_note_title ? '-' : row.release_note_title}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[6].width }]}>
            <Text style={styles.tableCell}>
              {!row.createdat && !row.updatedat
                ? '-'
                : !row.updatedat
                ? formatDateData(row.createdat)
                : formatDateData(row.updatedat)}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[7].width }]}>
            <Text style={styles.tableCell}>{row.createdby || '-'}</Text>
          </View>

          <View
            style={[
              styles.wrapCell,
              {
                width: columns[8].width,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              },
            ]}
          >
            <View style={{ transform: 'scale(0.7)' }}>
              {row.release_note_publish_status ? StatusIcon(row.release_note_publish_status) : ''}
            </View>
            <Text style={[styles.tableCell, { marginLeft: '4px' }]}>
              {row.release_note_publish_status ? StatusText(row.release_note_publish_status) : '-'}
            </Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const PDFManageReleaseDocument = (props: Props) => {
  const { dataSource, official_curriculum_name, official_curriculum_code, creator, publish } =
    props;
  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap>
        <FullPage>
          <View style={styles.header}>
            <Text style={styles.textHeader}>リリースノート管理</Text>
          </View>
          <View style={styles.subscription}>
            <View>
              <Text style={styles.textSubscription}>
                OFFICIALカリキュラムの公開・編集日時別のリリースノート作成状況の一覧画面です。
              </Text>
            </View>
          </View>
          <View style={styles.divider}></View>
          <View style={styles.searchPanel}>
            <View style={styles.searchItem}>
              <Text style={[styles.label, { color: '#999999' }]}>対象OFFICIALカリキュラム： </Text>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View
                  style={[
                    styles.selectBox,
                    {
                      display: 'flex',
                      flexDirection: 'row',
                      width: '742px',
                      borderRight: '0',
                      alignItems: 'center',
                    },
                  ]}
                >
                  <View
                    style={{
                      width: '90%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Text style={styles.selectText}>{official_curriculum_name || ''}</Text>
                    <Text
                      style={[
                        styles.selectText,
                        { color: '#999999', fontSize: '7px', marginLeft: '4px', marginTop: '1px' },
                      ]}
                    >{`(ID : ${official_curriculum_code})`}</Text>
                  </View>

                  <Text style={[styles.selectText, { color: '#999999' }]}>作成者：</Text>
                  <Text style={[styles.selectText, { marginLeft: '3px' }]}>{creator || ''}</Text>
                </View>
                <View
                  style={[
                    styles.selectBox,
                    { display: 'flex', flexDirection: 'row', width: '75px', alignItems: 'center' },
                  ]}
                >
                  <View style={{ transform: 'scale(1)', borderRadius: '2px', marginRight: '8px' }}>
                    {StatusIcon(publish)}
                  </View>
                  <Text
                    style={[
                      styles.selectText,
                      { marginLeft: '2px', color: StatusColorText(publish) },
                    ]}
                  >
                    {StatusText(publish)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.body}>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>1</Text>
              <Text style={styles.textCountNumber}>-</Text>
              <Text style={styles.textCountNumber}>{dataSource.length}</Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{dataSource.length}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
            <ManageReleaseTable dataSource={dataSource.slice(0, FIRST_PAGE_ROW_COUNT)} />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {dataSource.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(dataSource.slice(FIRST_PAGE_ROW_COUNT, dataSource.length), PAGE_ROW_COUNT).map(
          (page, index) => (
            <Page
              key={index}
              size={pageSize}
              orientation={pageOrientation}
              style={[styles.page, styles.pagePadding]}
              wrap={false}
            >
              <FullPage>
                <ManageReleaseTable dataSource={page} />
              </FullPage>
              <PageNumber />
            </Page>
          )
        )}
    </Document>
  );
};

export default PDFManageReleaseDocument;
