import { Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const Table = ({ style }: { style?: Style | Style[] }) => (
  <Svg width="8" height="8" viewBox="0 0 18 18" fill="none" style={style}>
    <Path
      d="M17.3571 1.92969H0.642857C0.287277 1.92969 0 2.21696 0 2.57254V15.4297C0 15.7853 0.287277 16.0725 0.642857 16.0725H17.3571C17.7127 16.0725 18 15.7853 18 15.4297V2.57254C18 2.21696 17.7127 1.92969 17.3571 1.92969ZM16.5536 6.10826H12.2946V3.37612H16.5536V6.10826ZM16.5536 10.6083H12.2946V7.39397H16.5536V10.6083ZM6.99107 7.39397H11.0089V10.6083H6.99107V7.39397ZM11.0089 6.10826H6.99107V3.37612H11.0089V6.10826ZM1.44643 7.39397H5.70536V10.6083H1.44643V7.39397ZM1.44643 3.37612H5.70536V6.10826H1.44643V3.37612ZM1.44643 11.894H5.70536V14.6261H1.44643V11.894ZM6.99107 11.894H11.0089V14.6261H6.99107V11.894ZM16.5536 14.6261H12.2946V11.894H16.5536V14.6261Z"
      fill="#C4C4C4"
    />
    <Path
      d="M17.3571 1.92969H0.642857C0.287277 1.92969 0 2.21696 0 2.57254V15.4297C0 15.7853 0.287277 16.0725 0.642857 16.0725H17.3571C17.7127 16.0725 18 15.7853 18 15.4297V2.57254C18 2.21696 17.7127 1.92969 17.3571 1.92969ZM16.5536 6.10826H12.2946V3.37612H16.5536V6.10826ZM16.5536 10.6083H12.2946V7.39397H16.5536V10.6083ZM6.99107 7.39397H11.0089V10.6083H6.99107V7.39397ZM11.0089 6.10826H6.99107V3.37612H11.0089V6.10826ZM1.44643 7.39397H5.70536V10.6083H1.44643V7.39397ZM1.44643 3.37612H5.70536V6.10826H1.44643V3.37612ZM1.44643 11.894H5.70536V14.6261H1.44643V11.894ZM6.99107 11.894H11.0089V14.6261H6.99107V11.894ZM16.5536 14.6261H12.2946V11.894H16.5536V14.6261Z"
      fill="#08A3A5"
    />
  </Svg>
);

export default Table;
