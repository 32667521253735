import { createAsyncThunk } from '@reduxjs/toolkit';
import { browserLogger } from 'libs/logger';
import {
  BILLING_DATA_DETAIL,
  BILLING_DATA_MANAGEMENT,
  BILLING_DETAILS,
  SKILL_CHECK_FEE_DETAIL,
  COMPANIES,
} from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getDataBillingManagement = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('BillingDataManagement/thunk/getDataBillingManagement', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(BILLING_DATA_MANAGEMENT.name, {
      ...req,
      sort_fields: [
        { id: 'billing_month', order: 'asc' },
        { id: 'billing_id', order: 'asc' },
      ],
    });
    browserLogger.info(
      'BillingDataManagement/thunk/getDataBillingManagement',
      BILLING_DATA_MANAGEMENT.name,
      data
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getBillingDataDetail = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('reports/billing_data_detail/search', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(BILLING_DATA_DETAIL.name, {
      ...req,
      sort_fields: [
        { id: 'createdAt', order: 'asc' },
        { id: 'billing_detail_id', order: 'asc' },
        { id: 'company_id', order: 'asc' },
      ],
    });
    browserLogger.info('reports/billing_data_detail/search', BILLING_DATA_DETAIL.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectBillingDataDetail = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('BillingDataManagement/thunk/getSelectBillingDataDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(BILLING_DATA_DETAIL.name, {
      ...req,
      sort_fields: [
        { id: 'createdAt', order: 'asc' },
        { id: 'billing_detail_id', order: 'asc' },
        { id: 'company_id', order: 'asc' },
      ],
    });
    browserLogger.info(
      'BillingDataManagement/thunk/getSelectBillingDataDetail',
      BILLING_DATA_DETAIL.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getBillingDataDetailExport = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('BillingDataManagement/thunk/getBillingDataDetailExport', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(BILLING_DATA_DETAIL.name, {
      ...req,
      sort_fields: [
        { id: 'createdAt', order: 'asc' },
        { id: 'billing_detail_id', order: 'asc' },
        { id: 'company_id', order: 'asc' },
      ],
    });
    browserLogger.info(
      'BillingDataManagement/thunk/getBillingDataDetailExport',
      BILLING_DATA_DETAIL.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeActionSalesRegist = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.ExecuteActionRequestType,
  Types.ThunkAPI<Types.requestError>
>('BillingDataManagement/thunk/executeActionSalesRegist', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeAction('sales_regist', BILLING_DETAILS.name, req);
    browserLogger.info(
      'BillingDataManagement/thunk/executeActionSalesRegist',
      BILLING_DETAILS.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheckFeeDetail = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckFeeDetails.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getDataSkillCheckFeeDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SkillCheckFeeDetails.ResponseType>(
      SKILL_CHECK_FEE_DETAIL.name,
      req
    );
    browserLogger.info(
      'UsageStatus/thunk/getDataSkillCheckFeeDetail',
      SKILL_CHECK_FEE_DETAIL.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getCompaniesUsageStatus = createAsyncThunk<
  Types.GetItemResponseType<Types.Companies.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getCompaniesUsageStatus', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Companies.ResponseType>(COMPANIES.name, {
      ...req,
      include_lookups: true,
    });
    browserLogger.info('UsageStatus/thunk/getCompaniesUsageStatus', COMPANIES.name, data);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
