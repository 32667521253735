import styled from 'styled-components';

export default styled.section`
  background-color: #f9f8f8;
  .wrap-tab {
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding-left: 30px;
    position: relative;
    .group-btn {
      display: flex;
      justify-content: end;
      position: absolute;
      top: 35px;
      right: 30px;
      .btn {
        padding: 0 30px;
        height: 40px;
        cursor: pointer;
        font-size: 13px;
        font-weight: 700;
        margin-left: 10px;
      }
      .btn-active {
        display: flex;
        align-items: center;
        background-color: #f6ac00;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: #ffffff;
        .icon {
          margin-right: 12px;
          font-size: 20px;
          margin-top: 4px;
        }
      }
    }
  }
  .tab-container {
    .ant-tabs-nav {
      padding-top: 30px;
      margin-left: 30px;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #08a3a5;
  }
  .ant-tabs-tab:hover {
    color: #2a2a2a;
  }
  .ant-tabs-nav::before {
    border: none;
  }
  .ant-tabs-tab {
    padding: 20px 20px;
  }
  .ant-tabs-ink-bar {
    background: #08a3a5;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }
`;
