import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';
import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';

import { StopPublic, TickComplete, UnPublished, WhiteEditing } from 'assets';
import { StopPublicPdf, TickCompletePdf, UnPublishedPdf, WhiteEditingPdf } from 'assets/svg/svgPDF';
import FullPage from 'components/ReactPDF/FullPage';
import PageNumber from 'components/ReactPDF/PageNumber';
import SelectBox from 'components/ReactPDF/SelectBox';
import { SEARCH_PUBLISH_OPTION } from 'constant/select.constants';
import dayjs from 'dayjs';
import _ from 'lodash';
import * as Types from 'types';
import styles from './styles';
Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface Props {
  dataSource: Types.OfficialCurriculumPublishingSettings.ResponseType[];
  official_curriculum_code?: string;
  official_curriculum_name?: number | undefined | string;
  publish?: number | undefined | string;
  createdby?: string;
}

const FIRST_PAGE_ROW_COUNT = 13;
const PAGE_ROW_COUNT = 18;

const OfficialCurriculumTable = ({
  dataSource,
}: {
  dataSource: Types.OfficialCurriculumPublishingSettings.ResponseType[];
}) => {
  const columns: { title: string | string[]; width: string | number; key: string }[] = [
    {
      title: 'ID',
      width: 60,
      key: 'official_curriculum_code',
    },
    {
      title: 'OFFICIALカリキュラム',
      width: 300,
      key: 'official_curriculum_name',
    },
    {
      title: '公開ステータス',
      width: 100,
      key: 'publish',
    },
    {
      title: '前回公開日時',
      width: 100,
      key: 'publish_start_date',
    },
    {
      title: '最終更新日時',
      width: 100,
      key: 'updatedat',
    },
    {
      title: '作成者',
      width: 300,
      key: 'createdby',
    },
  ];

  const formatDateData = (val: Date | undefined) => {
    if (val) {
      return dayjs(val).locale('ja').format('YYYY/MM/DD (ddd)  HH:mm');
    }
    return '';
  };
  const CURRICULUM_STATUS_MAPPING: {
    [key: number]: { icon: any; text: string; pdfIcon: any };
  } = {
    0: {
      icon: WhiteEditing,
      text: '編集中',
      pdfIcon: <WhiteEditingPdf />,
    },
    1: {
      icon: UnPublished,
      text: '未公開',
      pdfIcon: <UnPublishedPdf />,
    },
    2: {
      icon: TickComplete,
      text: '公開中',
      pdfIcon: <TickCompletePdf />,
    },
    3: {
      icon: StopPublic,
      text: '公開停止中',
      pdfIcon: <StopPublicPdf />,
    },
  };
  const StatusIcon = (pbStatus: number) => CURRICULUM_STATUS_MAPPING[pbStatus]?.pdfIcon;
  const StatusText = (pbStatus: number) => CURRICULUM_STATUS_MAPPING[pbStatus]?.text;
  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow]}>
        {columns.map((column, index) => (
          <View key={index} style={[styles.wrapCell, { width: column.width }]}>
            <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
          </View>
        ))}
      </View>

      {dataSource.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5',
            },
          ]}
        >
          <View style={[styles.wrapCell, { width: columns[0].width, color: '#999999' }]}>
            <Text style={styles.tableCell}>{row.official_curriculum_code}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[1].width }]}>
            <Text style={styles.tableCell}>{row.name}</Text>
          </View>
          <View
            style={[
              styles.wrapCell,
              {
                width: columns[2].width,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              },
            ]}
          >
            <View style={{ transform: 'scale(0.7)' }}>{StatusIcon(row.publish)}</View>
            <Text style={[styles.tableCell]}>{StatusText(row.publish)}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[3].width }]}>
            <Text style={styles.tableCell}>{formatDateData(row.publish_start_date)}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[4].width }]}>
            <Text style={styles.tableCell}>
              {row.updated_at
                ? formatDateData(new Date(row.updated_at))
                : formatDateData(new Date(row.created_at))}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[5].width }]}>
            <Text style={styles.tableCell}>{row.creator}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const PDFOfficialCurriculumDocument = (props: Props) => {
  const { dataSource, official_curriculum_code, official_curriculum_name, publish, createdby } =
    props;
  const CURRICULUM_STATUS_MAPPING: {
    [key: number]: { icon: any; pdfIcon: any };
  } = {
    0: {
      icon: WhiteEditing,
      pdfIcon: <WhiteEditingPdf />,
    },
    1: {
      icon: UnPublished,
      pdfIcon: <UnPublishedPdf />,
    },
    2: {
      icon: TickComplete,
      pdfIcon: <TickCompletePdf />,
    },
    3: {
      icon: StopPublic,
      pdfIcon: <StopPublicPdf />,
    },
  };
  const StatusIcon = (pbStatus: number) => CURRICULUM_STATUS_MAPPING[pbStatus]?.pdfIcon;

  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap>
        <FullPage>
          <View style={styles.header}>
            <Text style={styles.textHeader}>OFFICIALカリキュラム公開管理</Text>
          </View>
          <View style={styles.subscription}>
            <View>
              <Text style={styles.textSubscription}>
                作成したOFFICIALカリキュラムの公開管理を行う画面です。
              </Text>
            </View>
          </View>
          <View style={styles.divider}></View>
          <View style={styles.searchPanel}>
            <View style={styles.searchItem}>
              <Text style={styles.label}>OFFICIALカリキュラムID</Text>
              <SelectBox
                placeholder="指定なし"
                value={official_curriculum_code}
                selectBoxStyle={{ width: '201' }}
              />
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>OFFICIALカリキュラム</Text>
              <SelectBox
                placeholder="指定なし"
                value={official_curriculum_name}
                selectBoxStyle={{ width: '201' }}
              />
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>公開ステータス</Text>
              <SelectBox
                placeholder="指定なし"
                value={publish}
                selectBoxStyle={{ width: '201' }}
                options={SEARCH_PUBLISH_OPTION}
                icon={StatusIcon(publish as number)}
              />
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>作成者</Text>
              <SelectBox
                placeholder="指定なし"
                value={createdby}
                selectBoxStyle={{ width: '201' }}
              />
            </View>
          </View>
          <View style={styles.body}>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>1</Text>
              <Text style={styles.textCountNumber}>-</Text>
              <Text style={styles.textCountNumber}>{dataSource.length}</Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{dataSource.length}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
            <OfficialCurriculumTable dataSource={dataSource.slice(0, FIRST_PAGE_ROW_COUNT)} />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {dataSource.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(dataSource.slice(FIRST_PAGE_ROW_COUNT, dataSource.length), PAGE_ROW_COUNT).map(
          (page, index) => (
            <Page
              key={index}
              size={pageSize}
              orientation={pageOrientation}
              style={[styles.page, styles.pagePadding]}
              wrap={false}
            >
              <FullPage>
                <OfficialCurriculumTable dataSource={page} />
              </FullPage>
              <PageNumber />
            </Page>
          )
        )}
    </Document>
  );
};

export default PDFOfficialCurriculumDocument;
