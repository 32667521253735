import { createAsyncThunk } from '@reduxjs/toolkit';

import { services, userService } from 'services';
import * as Types from 'types';
import {
  AFFILIATION_ASSIGN_ROLE,
  SELECT_DEPARTMENT,
  SELECT_ROLE,
  EMPLOYEE_USER,
  USERS,
  SELECT_POSITION,
  EMPLOYEE_USER_EXPORT,
  ROLES,
  POSITIONS,
  AFFILIATION_LEVEL,
} from 'configs';
import { uniqBy } from 'lodash';
import { browserLogger } from 'libs/logger';

export const getDataAffiliationAssignRole = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationAssignRole.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getDataAffiliationAssignRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignRole.ResponseType>(
      AFFILIATION_ASSIGN_ROLE.name,
      req
    );
    browserLogger.info(
      'UserMaster/thunk/getDataAffiliationAssignRole',
      AFFILIATION_ASSIGN_ROLE.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListUserAdmin = createAsyncThunk<
  Types.GetItemResponseType<Types.EmployeeUser.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getListUserAdmin', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.name, {
      ...req,
      sort_fields: [{ id: 'employee_code', order: 'asc' }],
      include_lookups: true,
    });
    browserLogger.info('UserMaster/thunk/getListUserAdmin', USERS.name, data);
    const { authContainer } = getState() as Types.RootState;

    const responseStatus = await dispatch(
      getDataAffiliationAssignRole({
        conditions: [
          {
            id: 'company_id',
            search_value: [authContainer?.userInfo?.company_id],
          },
        ],
        include_lookups: true,
        page: 1,
        per_page: 0,
      })
    );

    let resultArray: Array<any> = [];
    if (getDataAffiliationAssignRole.fulfilled.match(responseStatus)) {
      const dataAffiliationAssignRole = responseStatus.payload.items;
      resultArray = await Promise.all(
        uniqBy(data.items, 'login_id').flatMap((item) => {
          const baseObject = {
            i_id: item.i_id,
            company_id: item.company_id,
            employee_code: item.employee_code,
            name: item.name,
            kana: item.name_furigana,
            birthday: item.dob,
            hire_date: item.doj,
            password: item?.password,
            last_login_date: item.last_login_at,
            role_code: item.role_code,
            icon_fileID: item.icon_fileID,
            user_type: item.user_type,
            email: item.email,
            lookup_items: item.lookup_items,
          };

          const matchAffiliationAssignRole = dataAffiliationAssignRole
            .filter(
              (a) =>
                a.login_id === item.login_id &&
                a.main_role === 'main' &&
                !!a.lookup_items &&
                !!a.lookup_items.affiliation_id &&
                !!a.lookup_items.positions_code &&
                !!a.lookup_items.affiliation_id.affiliation_id
            )
            .slice(0, 1);
          if (!matchAffiliationAssignRole.length) {
            return baseObject;
          }
          return matchAffiliationAssignRole.flatMap((itemAssignRole) => ({
            ...baseObject,
            department_name: itemAssignRole?.lookup_items?.affiliation_id.name || '（空白）',
            affiliation_id: itemAssignRole?.lookup_items?.affiliation_id.affiliation_id,
            position_code: itemAssignRole?.lookup_items?.positions_code.code,
            positions_name: itemAssignRole?.lookup_items?.positions_code.name,
            rank_order: itemAssignRole?.lookup_items?.positions_code?.rank_order,
            main: itemAssignRole.main_role,
            login_id: item.login_id,
          }));
        })
      );
    }

    return {
      ...data,
      items: [...resultArray],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectUserAdmin = createAsyncThunk<
  Types.GetItemResponseType<Types.EmployeeUser.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getSelectUserAdmin', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.name, {
      ...req,
      sort_fields: [{ id: 'employee_code', order: 'asc' }],
      include_lookups: true,
    });
    browserLogger.info('UserMaster/thunk/getSelectUserAdmin', USERS.name, data);
    const { authContainer } = getState() as Types.RootState;

    const responseStatus = await dispatch(
      getDataAffiliationAssignRole({
        conditions: [
          {
            id: 'company_id',
            search_value: [authContainer?.userInfo?.company_id],
          },
        ],
        include_lookups: true,
        page: 1,
        per_page: 0,
      })
    );

    let resultArray: Array<any> = [];
    if (getDataAffiliationAssignRole.fulfilled.match(responseStatus)) {
      const dataAffiliationAssignRole = responseStatus.payload.items;

      resultArray = await Promise.all(
        uniqBy(data.items, 'login_id').flatMap((item) => {
          const baseObject = {
            i_id: item.i_id,
            company_id: item.company_id,
            employee_code: item.employee_code,
            name: item.name,
            kana: item.name_furigana,
            birthday: item.birthplace,
            hire_date: item.doj,
            last_login_date: item.last_login_at,
            role_code: item.role_code,
            icon_fileID: item.icon_fileID,
            user_type: item.user_type,
            email: item.email,
          };

          const matchAffiliationAssignRole = dataAffiliationAssignRole
            .filter(
              (a) =>
                a.login_id === item.login_id &&
                a.main_role === 'main' &&
                !!a.lookup_items &&
                !!a.lookup_items.affiliation_id &&
                !!a.lookup_items.positions_code &&
                !!a.lookup_items.affiliation_id.affiliation_id
            )
            .slice(0, 1);
          if (!matchAffiliationAssignRole.length) {
            return baseObject;
          }
          return matchAffiliationAssignRole.flatMap((itemAssignRole) => ({
            ...baseObject,
            department_name: itemAssignRole?.lookup_items?.affiliation_id.name || '（空白）',
            affiliation_id: itemAssignRole?.lookup_items?.affiliation_id.affiliation_id,
            position_code: itemAssignRole?.lookup_items?.positions_code.code,
            positions_name: itemAssignRole?.lookup_items?.positions_code.name,
            main: itemAssignRole.main_role,
            login_id: item.login_id,
          }));
        })
      );
    }

    return {
      ...data,
      items: [...resultArray],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectAdminDepartment = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getSelectAdminDepartment', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationLevel.ResponseType>(
      AFFILIATION_LEVEL.name,
      {
        ...req,
        sort_fields: [{ id: 'createdat', order: 'asc' }],
      }
    );
    browserLogger.info('UserMaster/thunk/getSelectAdminDepartment', AFFILIATION_LEVEL.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectAdminUsers = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getSelectAdminUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(EMPLOYEE_USER.name, {
      ...req,
      include_item_ref: true,
      include_lookups: true,
      sort_fields: [
        { id: 'employee_code', order: 'asc' },
        { id: 'createAt', order: 'asc' },
      ],
    });
    browserLogger.info('UserMaster/thunk/getSelectAdminUsers', EMPLOYEE_USER.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectAdminPosition = createAsyncThunk<
  Types.GetItemResponseType<Types.AdminPositions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getSelectAdminPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AdminPositions.ResponseType>(POSITIONS.name, req);
    browserLogger.info('UserMaster/thunk/getSelectAdminPosition', POSITIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectAdminRole = createAsyncThunk<
  Types.GetItemResponseType<Types.Roles.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getSelectAdminRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Roles.ResponseType>(ROLES.name, req);
    browserLogger.info('UserMaster/thunk/getSelectAdminRole', ROLES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAdminRoleList = createAsyncThunk<
  Types.GetItemResponseType<Types.Roles.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getAdminRoleList', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Roles.ResponseType>(ROLES.name, req);
    browserLogger.info('UserMaster/thunk/getAdminRoleList', ROLES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSubPosition = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationAssignRole.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getSubPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignRole.ResponseType>(
      AFFILIATION_ASSIGN_ROLE.name,
      req
    );
    browserLogger.info('UserMaster/thunk/getSubPosition', AFFILIATION_ASSIGN_ROLE.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAdminUsers = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/createAdminUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USERS.name, req);
    browserLogger.info('UserMaster/thunk/createAdminUsers', USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAdminUsers = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AdminUsers.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/updateAdminUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.name, req);
    browserLogger.info('UserMaster/thunk/updateAdminUsers', USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAdminUsers = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/thunk/deleteAdminUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(USERS.name, req);
    browserLogger.info('UserMaster/thunk/deleteAdminUsers', USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAdminAffiliationAssignRole = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AffiliationAssignRole.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/createAdminAffiliationAssignRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(AFFILIATION_ASSIGN_ROLE.name, req);
    browserLogger.info(
      'UserMaster/thunk/createAdminAffiliationAssignRole',
      AFFILIATION_ASSIGN_ROLE.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAdminAffiliationAssignRole = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AffiliationAssignRole.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/updateAdminAffiliationAssignRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(AFFILIATION_ASSIGN_ROLE.name, req);
    browserLogger.info(
      'UserMaster/thunk/updateAdminAffiliationAssignRole',
      AFFILIATION_ASSIGN_ROLE.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAdminAffiliationRole = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/thunk/deleteAdminAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(AFFILIATION_ASSIGN_ROLE.name, req);
    browserLogger.info(
      'UserMaster/thunk/deleteAdminAffiliationRole',
      AFFILIATION_ASSIGN_ROLE.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAdminUserExport = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getDataAdminUserExport', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(EMPLOYEE_USER_EXPORT.name, req);
    browserLogger.info('UserMaster/thunk/getDataAdminUserExport', EMPLOYEE_USER_EXPORT.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addUserAdmin = createAsyncThunk<
  Types.AddUserAdminResponse,
  Types.AddUserAdminRequest,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/addUserAdmin', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.addUserAdmin(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAllGmail = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UserMaster/thunk/getDataAllGmail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.name, req);
    browserLogger.info('UserMaster/thunk/getDataAllGmail', USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteItemByConditionsAffiliationRole = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/deleteItemByConditionsAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(AFFILIATION_ASSIGN_ROLE.name, req);
    browserLogger.info(
      'Employee/thunk/deleteItemByConditionsAffiliationRole',
      AFFILIATION_ASSIGN_ROLE.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const sendUserMail = createAsyncThunk<
  Types.FunctionResponseType,
  Types.SendUserMailRequest,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/sendUserMail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.sendUserEmail(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
