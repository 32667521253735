import React, { useCallback, useMemo, useRef, useState } from 'react';
import { BarChartOutlined, TableOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import ModalConfirmExport from 'components/Modal/ConfirmExportFile';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Card, Checkbox, Pagination, Select } from 'antd';
import OfficialCurriculumInformationStyled from './styles';
import { DatePicker, SelectField } from 'components';
import ChartOfficialCurriculum from './Chart';
import saveAs from 'file-saver';
import TableOfficialCurriculum, {
  Group,
  convertToFormattedDate,
  convertToFormattedDateForField,
  tableObject,
} from './Table';
import { ConditionsType } from 'types';
import {
  getDataOfficialCurriculumUserAggregation,
  getDataOfficialCurriculumUserAggregationAdmin,
  getDataOfficialCurriculumUserAggregationPartner,
} from '../thunk';
import { useAppDispatch } from 'hooks';
import {
  setActiveTableSlice,
  setListMonths,
  setPagination,
  setValueCurriculumn,
  setVisibleCurriculumDevelopmentSlice,
  setVisibleProducerExpandSlice,
} from '../slice';
import { useSelector } from 'react-redux';
import { reportSelector } from '../selectors';
import { sortBy, unionBy, uniqBy } from 'lodash';
import * as Types from 'types';
import OfficialCurriculumInformationDocument from './PDF';
import { pdf } from '@react-pdf/renderer';
import yup from 'libs/yup';
import { COMPANY_TYPES_OBJECT } from 'constant';
import PDFComponent from './PDFComponent';
import { exportPDFFitOnePage } from 'libs/utils/exportPDF';
import { exportCsv } from 'libs/utils/exportCsv';
const { Option } = Select;
const { RangePicker } = DatePicker;

export const getMonthsBetweenDates = (startDate: Date, endDate: Date) => {
  const monthsPerYear = 12;
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const startMonth = startDate.getMonth();
  const endMonth = endDate.getMonth();

  // Calculate the difference in years and months
  const yearDiff = endYear - startYear;
  const monthDiff = endMonth - startMonth;

  // Convert years to months and add the month difference
  const totalMonths = yearDiff * monthsPerYear + monthDiff;

  // Create an array to store details for each month
  const monthDetails = [];

  // Iterate through each month
  for (let i = 0; i <= totalMonths; i++) {
    const currentMonth: Date = new Date(startYear, startMonth + i, 1);
    const str =
      currentMonth.getFullYear() +
      '' +
      (currentMonth.getMonth() + 1 < 10
        ? 0 + '' + (currentMonth.getMonth() + 1)
        : currentMonth.getMonth() + 1);
    monthDetails.push(str);
  }

  return monthDetails;
};

interface SearchConditions {
  active_number_of_usage: string;
  company_type?: string;
  author: string;
  target_month: string[];
  end_period: null;
  start_period: null;
}

interface Props {
  listCompany: Array<Types.OfficialCurriculumnAggregationData>;
  openConfirmExport: boolean;
  setOpenConfirmExport: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface Conditions {
  active_number_of_usage: string;
  company_type: string;
  author: string;
  company_name: string;
  target_month: string[];
  end_period: string | Date | null;
  start_period: string | Date | null;
  publish: string;
}

const OfficialCurriculumInformation = ({
  listCompany,
  openConfirmExport,
  setOpenConfirmExport,
}: Props) => {
  const [visibleCurriculumDevelopment, setVisibleCurriculumDevelopment] = useState<boolean>(false);
  const [visibleProducerExpand, setVisibleProducerExpand] = useState<boolean>(false);
  const [activeTable, setActiveTable] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    dataOfficialCurriculumUserAggregation,
    listMonths,
    dataOfficialCurriculumUserAggregationAdmin,
    dataOfficialCurriculumUserAggregationPartner,
  } = useSelector(reportSelector);
  const { pagination } = useSelector(reportSelector);
  const [selectedDates, setSelectedDates] = useState<any>([]);
  const [segmented, setSegmented] = useState<number>(0);
  const ref = useRef(null);
  const [conditions, setConditions] = useState<Conditions>({
    active_number_of_usage: '0',
    company_type: '',
    author: '',
    company_name: '',
    target_month: ['', ''],
    end_period: null,
    start_period: null,
    publish: '2',
  });

  const data: tableObject = useMemo(() => {
    const array: tableObject = {
      RSTANDARD: [],
      パートナー企業: [],
    };
    const partnerCodes = dataOfficialCurriculumUserAggregationPartner.map((item) => item.code);
    const userCodes = dataOfficialCurriculumUserAggregationAdmin.map(
      (item) => item.official_curriculum_code
    );

    const calculate = dataOfficialCurriculumUserAggregation
      .filter((item) => item.curriculum_code)
      .map((item) => {
        const isPartner = item.company_type === 'パートナー企業';
        const isStandard = item.company_type === 'RSTANDARD';

        if (isPartner || isStandard) {
          const codes = isPartner ? partnerCodes : userCodes;

          if (item.curriculum_code && codes.includes(item.curriculum_code)) {
            return item;
          }

          return {
            ...item,
            active_number_of_usage: 0,
            number_of_usage: 0,
          };
        }

        return item;
      });

    calculate.forEach((i: Types.OfficialCurriculumnAggregationData) => {
      if (i.company_type === 'RSTANDARD') {
        array.RSTANDARD = [...array.RSTANDARD, i];
      }
      if (i.company_type === 'パートナー企業') {
        array.パートナー企業 = [...array.パートナー企業, i];
      }
    });

    return array;
  }, [
    dataOfficialCurriculumUserAggregation,
    dataOfficialCurriculumUserAggregationPartner,
    dataOfficialCurriculumUserAggregationAdmin,
  ]);

  const fetch = async (values: SearchConditions) => {
    const conditions: ConditionsType[] = [];

    const newValues = {
      ...values,
      active_number_of_usage: '',
    };

    Object.keys(newValues).forEach((key) => {
      if (key !== 'end_period' && key !== 'start_period') {
        let search_value: any = newValues[key as keyof typeof newValues];
        if (search_value) {
          switch (key) {
            case 'target_month':
              if (newValues?.start_period && newValues?.end_period) {
                search_value = getMonthsBetweenDates(
                  newValues?.start_period,
                  newValues?.end_period
                );
              }
              break;
            default:
              break;
          }
          conditions.push({
            id: key,
            search_value: key === 'target_month' ? search_value : [search_value],
            exact_match: true,
          });
        }
      }
    });

    const payload = {
      conditions,
      use_display_id: true,
      return_number_value: true,
      page: pagination.current,
      per_page: 0,
    };

    dispatch(
      setPagination({
        ...pagination,
        current: 1,
        pageSize: 100,
      })
    );

    if (values?.start_period && values?.end_period) {
      const array = getMonthsBetweenDates(values?.start_period, values?.end_period).map((item) => {
        return {
          date: convertToFormattedDateForField(item),
          title: convertToFormattedDate(item),
        };
      });

      dispatch(setListMonths(array));
    }

    await dispatch(getDataOfficialCurriculumUserAggregation(payload));
    await dispatch(
      getDataOfficialCurriculumUserAggregationPartner({
        conditions: [
          {
            id: 'publish',
            search_value: ['2'],
            exact_match: true,
          },
        ],
        use_display_id: true,
        return_number_value: true,
        page: 1,
        per_page: 0,
      })
    );
    await dispatch(
      getDataOfficialCurriculumUserAggregationAdmin({
        conditions: [
          {
            id: 'publish',
            search_value: ['2'],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
        return_count_only: false,
        omit_total_items: true,
        include_fields_data: true,
        use_display_id: true,
      })
    );
  };

  const initialValues = {
    active_number_of_usage: '0',
    company_type: '',
    author: '',
    company_name: '',
    target_month: ['', ''],
    end_period: null,
    start_period: null,
    publish: '2',
  };

  const filterConditions = useCallback(
    (data: any[]) => {
      // 全企業
      if (conditions.company_type === COMPANY_TYPES_OBJECT.all_company.id) {
        return data.filter((item) => item.company_type === '全企業');
      }
      // RSTANDARD
      if (conditions.company_type === COMPANY_TYPES_OBJECT.rstandard.id) {
        return data.filter((item) => item.company_type === 'RSTANDARD');
      }
      // パートナー企業
      if (conditions.company_type === COMPANY_TYPES_OBJECT.partner.id) {
        return data.filter((item) => item.company_type === 'パートナー企業');
      }

      return data;
    },
    [conditions]
  );

  const handleExportCSV = async (type: string) => {
    if (type !== 'csv' && segmented === 1) {
      exportPDFFitOnePage(ref, 'OFFICIALカリキュラム利用者数推移.pdf', 'l', true);
      setOpenConfirmExport(false);
    } else {
      const listDynamicDate = sortBy(
        uniqBy(
          listMonths.map((item, index) => ({
            title: item.title,
            dataIndex: item.date,
            key: item.date,
            width: '6%',
            render: (text: string) => {
              return <span>{text ?? 0}</span>;
            },
            onCell: () => ({
              className: 'text-right',
            }),
            onHeaderCell: () => ({
              className: 'text-center',
            }),
          })),
          'key'
        ),
        (i) => {
          return parseFloat(i.dataIndex);
        }
      );

      let header = [
        {
          label: '企業種類',
          key: 'company_type',
        },
        {
          label: '公開中カリキュラム数',
          key: 'open_curriculum',
        },
        ...listDynamicDate.map((item) => {
          return {
            label: item.title,
            key: item.dataIndex,
          };
        }),
      ];

      const labelKeys = listDynamicDate.reduce((acc, item) => {
        return {
          ...acc,
          [item.key]: 0,
        };
      }, {});

      const showCurriculumn = (
        array: Array<Types.OfficialCurriculumnAggregationData>,
        field: 'active_number_of_usage' | 'number_of_usage',
        originArray: Array<Types.OfficialCurriculumnAggregationData>,
        isPDF?: boolean
      ) => {
        // const listPartnerCompanies = originArray.filter(
        //   (item) => item.company_type === 'パートナー企業'
        // );
        // const GetUniqCompanyNameByListPartner = uniqBy(listPartnerCompanies, 'company_name');

        const childrenOfRSTANDARD = originArray
          .filter((item) => {
            return item.company_type === 'RSTANDARD' && item.publish === 2;
          })
          .map((item, index) => {
            const dateField = convertToFormattedDateForField(item.target_month);
            const value = item[field];
            return {
              ...item,
              company_type: isPDF
                ? `${index + 1}.</children> ${item.curriculum_name}`
                : item.curriculum_name,
              open_curriculum: null,
              [dateField]: value,
            };
          });

        const RSTANDARD = array
          .filter((item) => item.company_type === 'RSTANDARD')
          .map((item) => {
            return {
              ...item,
              open_curriculum: childrenOfRSTANDARD.length,
            };
          });

        const 全企業 = array.filter((item) => item.company_type === '全企業');
        let childrenOfパートナー企業: any[] = [];

        const パートナー企業 = array
          .filter((item) => item.company_type === 'パートナー企業')
          .map((item, index) => {
            const children = data.パートナー企業
              .map((item) => {
                const child = dataOfficialCurriculumUserAggregation
                  .filter(
                    (i) =>
                      i.company_id === item.company_id &&
                      i.company_type === 'パートナー企業' &&
                      item.company_type === 'パートナー企業'
                  )
                  .map((j, jIndex) => {
                    const dateField = convertToFormattedDateForField(j.target_month);
                    const value = j[field];
                    return {
                      ...j,
                      company_type: isPDF
                        ? `${jIndex + 1}. ${j?.curriculum_name}`
                        : j?.curriculum_name,
                      [dateField]: value,
                    };
                  });

                const customizeItem = child
                  .reduce((acc: any, item: any) => {
                    const convertObjectToArray = Object.keys(labelKeys);
                    const newData = convertObjectToArray
                      .map((i: string) => {
                        const result = {
                          [i]: (acc[`${i}`] ?? 0) + (item[`${i}`] ?? 0),
                        };
                        return result;
                      })
                      .reduce((acc: any, item: any, index: number) => {
                        return {
                          ...acc,
                          [Object.keys(item)[0]]: Object.values(item)[0],
                        };
                      }, {});

                    return [
                      ...acc,
                      {
                        ...item,
                        open_curriculum: null,
                        ...newData,
                      },
                    ];
                  }, [])
                  .filter((i) => i.publish === 2);

                return {
                  ...item,
                  [item.target_month]: item[`${field}`],
                  company_type: item.company_name,
                  open_curriculum: customizeItem.length,
                  children: customizeItem,
                };
              })
              .filter((i) => i.children.length);

            const unionChild = unionBy(children, 'company_id').map((item) => {
              const getList = children.filter((i) => i.company_id === item.company_id);
              const result = getList.reduce(
                (acc: any, item: Types.OfficialCurriculumnAggregationData) => {
                  const dateField = convertToFormattedDateForField(item.target_month);
                  const other = acc[`${dateField}`] ?? 0;
                  const value = item[field] + other;
                  return {
                    ...item,
                    ...acc,
                    open_curriculum: getList.length,
                    [dateField]: value,
                  };
                },
                {}
              );
              return result;
            });

            childrenOfパートナー企業 = unionChild;
            const result = {
              ...item,
              open_curriculum: children.reduce((a, i) => {
                return a + i?.children.length;
              }, 0),
              [item.target_month]: item[`${field}`],
            };

            return result;
          });

        childrenOfパートナー企業 = childrenOfパートナー企業.reduce((acc, item, index) => {
          return [
            ...acc,
            {
              ...item,
              company_type: isPDF
                ? `${index + 1}.</children> ${item.company_name}`
                : item.company_name,
            },
            ...item.children.map((item: any, index: number) => {
              return {
                ...item,
                company_type: isPDF
                  ? `${index + 1}.</grandChildren> ${item.curriculum_name}`
                  : item.curriculum_name,
              };
            }),
          ];
        }, []);
        if (pagination.current > 1) {
          return [...childrenOfRSTANDARD, ...childrenOfパートナー企業];
        } else {
          const result = [
            ...全企業,
            ...RSTANDARD,
            ...childrenOfRSTANDARD,
            ...パートナー企業,
            ...childrenOfパートナー企業,
          ];

          return result;
        }
      };

      const showingCompanyName = (
        array: Array<Types.OfficialCurriculumnAggregationData>,
        field: 'active_number_of_usage' | 'number_of_usage',
        originArray: Array<Types.OfficialCurriculumnAggregationData>,
        isPDF?: boolean
      ) => {
        const RSTANDARD = array.filter((item) => item.company_type === 'RSTANDARD');
        const 全企業 = array.filter((item) => item.company_type === '全企業');
        const パートナー企業 = array.filter((item) => item.company_type === 'パートナー企業');

        const children = originArray
          .filter((item) => {
            return item.company_type === 'パートナー企業' && item.publish === 2;
          })
          .map((item, index) => {
            const dateField = convertToFormattedDateForField(item.target_month);
            const value = item[field];
            return {
              ...item,
              company_type: isPDF
                ? `${index + 1}.</children> ${item.company_name} `
                : item.company_name,
              [dateField]: value,
            };
          });

        const unionChild = unionBy(children, 'company_id').map((item) => {
          const getList = children.filter((i) => i.company_id === item.company_id);
          const result = getList.reduce(
            (acc: any, item: Types.OfficialCurriculumnAggregationData) => {
              const dateField = convertToFormattedDateForField(item.target_month);
              const other = acc[`${dateField}`] ?? 0;
              const value = item[field] + other;
              return {
                ...item,
                ...acc,
                open_curriculum: getList.length,
                [dateField]: value,
              };
            },
            {}
          );
          return result;
        });

        if (pagination.current > 1) {
          return [...unionChild];
        } else {
          const result = [...全企業, ...RSTANDARD, ...パートナー企業, ...unionChild];
          return result;
        }
      };

      const dataRender = (field: 'active_number_of_usage' | 'number_of_usage', isPDF?: boolean) => {
        let array = [...[...data.RSTANDARD, ...data.パートナー企業]];
        if (type !== 'csv') {
        }

        const groupArray = array.reduce(
          (acc: Group, item) => {
            switch (item.company_type) {
              case 'RSTANDARD': {
                return {
                  ...acc,
                  RSTANDARD: [
                    ...acc.RSTANDARD,
                    {
                      ...item,
                      [convertToFormattedDateForField(item.target_month)]: item[field],
                    },
                  ],
                  全企業: [
                    ...acc.全企業,
                    {
                      ...item,
                      company_type: '全企業',
                      [convertToFormattedDateForField(item.target_month)]: item[field],
                    },
                  ],
                };
              }
              default: {
                return {
                  ...acc,
                  パートナー企業: [
                    ...acc.パートナー企業,
                    {
                      ...item,
                      [convertToFormattedDateForField(item.target_month)]: item[field],
                    },
                  ],
                  全企業: [
                    ...acc.全企業,
                    {
                      ...item,
                      company_type: '全企業',
                      [convertToFormattedDateForField(item.target_month)]: item[field],
                    },
                  ],
                };
              }
            }
          },
          {
            RSTANDARD: [],
            全企業: [],
            パートナー企業: [],
          }
        );
        // case 1 group by "company_type"
        const RSTANDARD = groupArray.RSTANDARD.reduce(
          (acc: any, item: Types.OfficialCurriculumnAggregationData) => {
            const dateField = convertToFormattedDateForField(item.target_month);
            const other = acc[`${dateField}`] ?? 0;
            const value = item[field] + other;
            return {
              ...item,
              ...acc,
              [dateField]: value,
            };
          },
          {}
        );

        const 全企業 = groupArray.全企業.reduce(
          (acc: any, item: Types.OfficialCurriculumnAggregationData) => {
            const dateField = convertToFormattedDateForField(item.target_month);
            const other = acc[`${dateField}`] ?? 0;
            const value = item[field] + other;
            return {
              ...item,
              ...acc,
              [dateField]: value,
            };
          },
          {}
        );

        const パートナー企業 = groupArray.パートナー企業.reduce(
          (acc: any, item: Types.OfficialCurriculumnAggregationData) => {
            const dateField = convertToFormattedDateForField(item.target_month);
            const other = acc[`${dateField}`] ?? 0;
            const value = item[field] + other;
            return {
              ...item,
              ...acc,
              [dateField]: value,
            };
          },
          {}
        );

        let result: any = [];
        if (groupArray.全企業.length) {
          result = [
            ...result,
            {
              ...全企業,
              open_curriculum: groupArray.RSTANDARD.length + groupArray.パートナー企業.length,
            },
          ];
        }
        if (groupArray.RSTANDARD.length) {
          result = [
            ...result,
            {
              ...RSTANDARD,
              open_curriculum: groupArray.RSTANDARD.length,
            },
          ];
        }
        if (groupArray.パートナー企業.length) {
          result = [
            ...result,
            {
              ...パートナー企業,
              open_curriculum: groupArray.パートナー企業.length,
            },
          ];
        }

        if (visibleProducerExpand) {
          if (visibleCurriculumDevelopment) {
            header = [
              {
                label: '企業種類',
                key: 'company_type',
              },
              {
                label: '企業コード',
                key: 'company_id',
              },
              {
                label: '企業名',
                key: 'company_name',
              },
              {
                label: 'カリキュラムコード',
                key: 'curriculum_code',
              },
              {
                label: 'カリキュラム名',
                key: 'curriculum_name',
              },
              {
                label: '公開中カリキュラム数',
                key: 'open_curriculum',
              },
              ...listDynamicDate.map((item) => {
                return {
                  label: item.title,
                  key: item.dataIndex,
                };
              }),
            ];
            const GetDataShowCurriculumn = showCurriculumn(result, field, array, isPDF);
            return filterConditions(GetDataShowCurriculumn);
          } else {
            header = [
              {
                label: '企業種類',
                key: 'company_type',
              },
              {
                label: '企業コード',
                key: 'company_id',
              },
              {
                label: '企業名',
                key: 'company_name',
              },
              {
                label: '公開中カリキュラム数',
                key: 'open_curriculum',
              },
              ...listDynamicDate.map((item) => {
                return {
                  label: item.title,
                  key: item.dataIndex,
                };
              }),
            ];
            const activeExpand = showingCompanyName(result, field, array, isPDF);

            return filterConditions(activeExpand);
          }
        } else {
          return filterConditions(result);
        }
      };
      let listData = [];
      if (activeTable) {
        listData = dataRender('active_number_of_usage', type !== 'csv');
      } else {
        listData = dataRender('number_of_usage', type !== 'csv');
      }

      const keyOfHeader = header.map((item) => item.key);

      const result = listData.map((item: any) => {
        return keyOfHeader.map((key: string) => {
          if (
            key === 'company_type' ||
            key === 'company_id' ||
            key === 'company_name' ||
            key === 'curriculum_code' ||
            key === 'curriculum_name'
          ) {
            return item[key] || '';
          }
          return item[key] || '0';
        });
      });

      if (type === 'csv') {
        // const csvString = [
        //   header.map(({ label }) => `\u200B${label}`),
        //   ...result.map((item: any) => Object.values(item) ?? `\u200B${Object.values(item)}`),
        // ]
        //   .map((e) => e.join(','))
        //   .join('\n');
        // const bom = '\uFEFF';
        // const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        // saveAs(file, 'OFFICAL カリキュラム情報_表.csv');
        exportCsv(result, header, 'OFFICAL カリキュラム情報_表.csv');
      } else {
        const header = [
          {
            label: '企業種類',
            key: 'company_type',
          },
          {
            label: '公開中カリキュラム数',
            key: 'open_curriculum',
          },
          ...listDynamicDate.map((item) => {
            return {
              label: item.title,
              key: item.dataIndex,
            };
          }),
        ];
        const keyOfHeader = header.map((item) => item.key);

        const result = listData.map((item: any) => {
          return keyOfHeader.map((key: string) => {
            if (
              key === 'company_type' ||
              key === 'company_id' ||
              key === 'company_name' ||
              key === 'curriculum_code' ||
              key === 'curriculum_name'
            ) {
              return item[key] ?? '';
            }
            return item[key] ?? 0;
          });
        });

        // case pdf
        const blob = await pdf(
          <OfficialCurriculumInformationDocument
            header={header}
            result={result}
            visibleProducerExpand={visibleProducerExpand}
            activeTable={activeTable}
            visibleCurriculumDevelopment={visibleCurriculumDevelopment}
            Conditions={conditions}
          />
        ).toBlob();
        saveAs(blob, 'OFFICIALカリキュラム利用者数推移.pdf');
      }
    }

    setOpenConfirmExport(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      let newValue: {
        company_name: string;
        active_number_of_usage: string;
        company_type?: string;
        author: string;
        target_month: string[];
        end_period: null;
        start_period: null;
        publish: string;
      } = {
        ...values,
        company_name:
          values.company_name === 'ALL' || values.company_name === 'ALL '
            ? ''
            : values.company_name,
      };

      if (newValue.company_type) {
        delete newValue.company_type;
      }

      setConditions({
        ...newValue,
        company_type:
          values.company_type === 'ALL' || values.company_type === 'ALL '
            ? ''
            : values.company_type,
      });

      if (values.active_number_of_usage) {
        fetch(newValue);
      } else {
        dispatch(
          setValueCurriculumn({
            items: [],
            totalItems: 0,
          })
        );
        dispatch(setListMonths([]));
      }
    },
  });
  // loadingRef.current?.isLoading(true);
  const onChange = (e: CheckboxChangeEvent) => {
    if (!visibleProducerExpand) {
      setVisibleCurriculumDevelopment(false);
      dispatch(setVisibleCurriculumDevelopmentSlice(false));
    }
    setVisibleProducerExpand(e.target.checked);
    dispatch(setVisibleProducerExpandSlice(e.target.checked));
    dispatch(
      setPagination({
        current: 1,
        pageSize: 100,
        total: dataOfficialCurriculumUserAggregation.filter((i) => {
          return i.company_type === 'パートナー企業' && i.publish === 2;
        }).length,
      })
    );
  };
  const onChangeActive = (e: CheckboxChangeEvent) => {
    setActiveTable(e.target.checked);
    dispatch(setActiveTableSlice(e.target.checked));
  };

  return (
    <OfficialCurriculumInformationStyled>
      <div
        ref={ref}
        style={{
          position: 'absolute',
          width: 1512,
          right: 9999,
        }}
      >
        <PDFComponent
          formik={formik}
          listCompany={listCompany}
          visibleCurriculumDevelopment={visibleCurriculumDevelopment}
          segmented={segmented}
          openConfirmExport={openConfirmExport}
          selectedDates={selectedDates}
          activeTable={activeTable}
          conditions={conditions}
          data={data}
          pagination={pagination}
          visibleProducerExpand={visibleProducerExpand}
        />
      </div>
      <div id="official-curriculumn" className="container">
        <div className="wrap-filter">
          <span className="label">集計条件</span>
          <div className="aggregation-conditions">
            <FormikProvider value={formik}>
              <Form layout="vertical">
                <div className="form-search">
                  <Form.Item
                    requiredMark
                    name="active_number_of_usage"
                    className="item"
                    label={
                      <span className="text-label">
                        集計方法選択 <span className="required">*</span>
                      </span>
                    }
                  >
                    <SelectField
                      popupClassName="select-field"
                      name="active_number_of_usage"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value={'0'}>OFFICIALカリキュラム利用者数推移</Option>
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="target_month"
                    className="item"
                    label={
                      <span className="text-label">
                        集計期間 <span className="required">*</span>
                      </span>
                    }
                  >
                    <RangePicker
                      allowClear
                      value={selectedDates}
                      className="date"
                      name="target_month"
                      picker="month"
                      format="YYYY/MM"
                      placeholder={['集計開始日', '集計終了日']}
                      onChange={(dates) => {
                        formik.setFieldValue('target_month', dates);
                        if (dates) {
                          formik.setFieldValue('start_period', dates[0]?.toDate());
                          formik.setFieldValue('end_period', dates[1]?.toDate());
                          setSelectedDates(dates);
                        } else {
                          formik.setFieldValue('start_period', '');
                          formik.setFieldValue('end_period', '');
                        }
                      }}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement!}
                    />
                  </Form.Item>
                  <Form.Item
                    name="company_type"
                    className="item"
                    label={<span className="text-label">企業種類選択</span>}
                  >
                    <SelectField
                      name="company_type"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="ALL">ALL</Option>
                      <Option value={COMPANY_TYPES_OBJECT.all_company.id}>全企業</Option>
                      <Option value={COMPANY_TYPES_OBJECT.rstandard.id}>RSTANDARD</Option>
                      <Option value={COMPANY_TYPES_OBJECT.partner.id}>パートナー企業</Option>
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="company_name"
                    className="item"
                    label={<span className="text-label">制作者検索</span>}
                  >
                    <SelectField
                      name="company_name"
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="ALL">ALL</Option>
                      {uniqBy(listCompany, 'company_name')
                        .filter((i) => (i.company_name ? i.company_name.trim() : false))
                        .map((item) => {
                          return <Option value={item.company_name}>{item.company_name}</Option>;
                        })}
                    </SelectField>
                  </Form.Item>
                  <div className="group-btn">
                    <span
                      className="label-reset"
                      onClick={() => {
                        formik.resetForm({ values: initialValues });
                        setSelectedDates([]);
                        dispatch(
                          setValueCurriculumn({
                            items: [],
                            totalItems: 0,
                          })
                        );
                      }}
                    >
                      リセット
                    </span>
                    <SubmitButton className="btn-search" loading={false}>
                      表示
                    </SubmitButton>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </div>
        </div>
        <div className="sub-container">
          <div className="wrap-segmented">
            <div className="left-side">
              <span className="label">レポートタイプ：</span>
              <div className="segmented">
                <div
                  className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                  onClick={() => {
                    setSegmented(0);
                  }}
                >
                  <TableOutlined className="icon" />
                  <span>表</span>
                </div>
                <div
                  className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                  onClick={() => {
                    setSegmented(1);
                  }}
                >
                  <BarChartOutlined className="icon" />
                  <span>グラフ</span>
                </div>
              </div>
              <div className="item-checkbox">
                <Checkbox
                  checked={visibleProducerExpand && !segmented}
                  onChange={onChange}
                  disabled={!!segmented}
                >
                  制作者展開
                </Checkbox>
              </div>
              <div className="item-checkbox">
                <Checkbox
                  disabled={!visibleProducerExpand || !!segmented}
                  checked={visibleCurriculumDevelopment && visibleProducerExpand && !segmented}
                  onClick={(e: any) => {
                    setVisibleCurriculumDevelopment(!visibleCurriculumDevelopment);
                    dispatch(setVisibleCurriculumDevelopmentSlice(!visibleCurriculumDevelopment));
                    if (e.target.checked) {
                      dispatch(
                        setPagination({
                          current: 1,
                          pageSize: 100,
                          total: dataOfficialCurriculumUserAggregation.filter((i) => {
                            return (
                              (i.company_type === 'パートナー企業' ||
                                i.company_type === 'RSTANDARD') &&
                              i.publish === 2
                            );
                          }).length,
                        })
                      );
                    }
                    if (!e.target.checked) {
                      dispatch(
                        setPagination({
                          current: 1,
                          pageSize: 100,
                          total: dataOfficialCurriculumUserAggregation.filter((i) => {
                            return i.company_type === 'パートナー企業' && i.publish === 2;
                          }).length,
                        })
                      );
                    }
                  }}
                >
                  カリキュラム展開
                </Checkbox>
              </div>
              <div className="item-checkbox">
                <Checkbox checked={activeTable} onChange={onChangeActive}>
                  アクティブ
                </Checkbox>
              </div>
            </div>
          </div>
          {!formik.values.active_number_of_usage &&
          !formik.values.author &&
          !formik.values.target_month &&
          !formik.values.company_type ? (
            <Card
              bodyStyle={{
                height: '250px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#777777',
              }}
            >
              集計条件を選択してください
            </Card>
          ) : segmented === 0 ? (
            <>
              <TableOfficialCurriculum
                data={data}
                pagination={pagination}
                activeTable={activeTable}
                visibleProducerExpand={visibleProducerExpand}
                visibleCurriculumDevelopment={visibleCurriculumDevelopment}
                conditions={conditions}
              />
            </>
          ) : (
            <>
              <ChartOfficialCurriculum
                data={data}
                openConfirmExport={openConfirmExport}
                activeTable={activeTable}
              />
            </>
          )}
        </div>
      </div>

      <ModalConfirmExport
        visible={openConfirmExport}
        setVisible={setOpenConfirmExport}
        onSubmit={handleExportCSV}
      />
    </OfficialCurriculumInformationStyled>
  );
};
export default OfficialCurriculumInformation;
