import React from 'react';
import * as Types from 'types';
import { AnyObject } from 'types';
import { Document, Font, Page, Text, View } from '@react-pdf/renderer';
import styles from './styles';
import Divider from 'components/ReactPDF/Divider';
import SelectBox from 'components/ReactPDF/SelectBox';
import Header from 'components/ReactPDF/Header';
import { IOption } from 'types/config';
import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';
import { formatDate } from 'libs/utils/format';
import _ from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'constant';
import { EditSimpleIconPdf } from 'assets/svgPDF';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple@15.0.1/img/apple/64/',
});

Font.registerHyphenationCallback((word) => Array.from(word).flatMap((char) => [char, '']));

const pageSize = 'A4';
const pageOrientation = 'landscape';
const FIRST_PAGE_ROW_COUNT = 10;
const PAGE_ROW_COUNT = 16;

interface IProps {
  filters?: any;
  dataSource: Types.IndividualUserManagement[];
  total?: number;
}

const IndividualUserManagementTable = ({
  dataSource,
}: {
  dataSource: Types.IndividualUserManagement[];
}) => {
  const columns: { title: string; width: string | number; key: string }[] = [
    {
      title: 'ID',
      width: 60,
      key: 'id',
    },
    {
      title: 'ユーザー名',
      width: 70,
      key: 'user_name',
    },
    {
      title: 'メールアドレス',
      width: 140,
      key: 'email',
    },
    {
      title: '契約状況',
      width: 70,
      key: 'status',
    },
    {
      title: '初回登録日',
      width: 70,
      key: 'createdat',
    },
    {
      title: '継続期間',
      width: 70,
      key: 'period',
    },
    {
      title: '解約日',
      width: 70,
      key: 'deletedat',
    },
    {
      title: 'OS',
      width: 60,
      key: 'os',
    },
    {
      title: 'memo',
      width: 200,
      key: 'comment',
    },
    {
      title: '編集',
      width: 50,
      key: 'operation',
    },
  ];

  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow]}>
        {columns.map((column, index) => (
          <View key={index} style={[styles.wrapCell, { width: column.width }]}>
            <Text
              style={[
                styles.tableCell,
                styles.headerCell,
                { textAlign: [4, 5, 6, 7].includes(index) ? 'center' : 'left' },
              ]}
            >
              {column.title}
            </Text>
          </View>
        ))}
      </View>

      {dataSource.map((item, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5',
            },
          ]}
        >
          <View style={[styles.wrapCell, { width: columns[0].width }]}>
            <Text style={[styles.tableCell, styles.secondaryText]}>{item.id}</Text>
          </View>
          <View style={[styles.wrapCellWrap, { width: columns[1].width }]}>
            <Text style={styles.tableCellWrap}>{item.user_name}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[2].width }]}>
            <Text style={[styles.tableCell, styles.email]}>{item.email}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[3].width }]}>
            <View
              style={[
                styles.companyStatus,
                {
                  borderLeftColor: !item.deletedat ? '#08A3A5' : '#C4C4C4',
                },
              ]}
            >
              <Text style={styles.tableCell}>{item.deletedat ? '解約' : '契約中'}</Text>
            </View>
          </View>
          <View style={[styles.wrapCell, { width: columns[4].width }]}>
            <Text style={[styles.tableCell, styles.textCenter]}>
              {formatDate(item.createdat, DATE_FORMAT)}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[5].width, textAlign: 'center' }]}>
            <Text style={[styles.tableCell, styles.textCenter]}>{getUserContractPeriod(item)}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[6].width }]}>
            <Text style={[styles.tableCell, styles.textCenter]}>
              {formatDate(item.deletedat, DATE_FORMAT)}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[7].width }]}>
            <Text style={[styles.tableCell, styles.textCenter]}>
              {`${item.os}` === '1' ? 'iOS' : 'Android'}
            </Text>
          </View>
          <View style={[styles.wrapCellWrap, { width: columns[8].width, flexWrap: 'wrap' }]}>
            <Text style={[styles.tableCellWrap, { flexGrow: 1 }]}>{item.comment}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[9].width }]}>
            <EditSimpleIconPdf style={{ width: 10, height: 10 }} />
          </View>
        </View>
      ))}
    </View>
  );
};

const PDFIndividualUserManagementTable = (props: IProps) => {
  const { dataSource, total, filters } = props;

  const deletedatOptions: IOption[] = [
    {
      value: '0',
      label: '解約',
    },
    {
      value: '1',
      label: '契約中',
    },
  ];

  const osOptions: IOption[] = [
    {
      value: '0',
      label: 'Android',
    },
    {
      value: '1',
      label: 'iOS',
    },
  ];

  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap>
        <FullPage>
          <Header title="個人ユーザー管理" />
          <View style={styles.subscription}>
            <View>
              <Text style={styles.textSubscription}>
                個人ユーザーの登録情報の確認・管理を行う画面です。
              </Text>
            </View>
          </View>
          <Divider />
          <View style={styles.searchPanel}>
            <SelectBox
              label="ID"
              placeholder="指定なし"
              value={filters.id}
              selectBoxStyle={{ width: 200 }}
            />
            <SelectBox
              selectBoxStyle={{ width: 200 }}
              label="ユーザー名"
              value={filters.user_name}
              placeholder="指定なし"
            />
            <SelectBox
              selectBoxStyle={{ width: 200 }}
              label="契約状況"
              options={deletedatOptions}
              value={filters.deletedat}
              placeholder="指定なし"
            />
            <SelectBox
              selectBoxStyle={{ width: 200 }}
              label="OS"
              options={osOptions}
              value={filters.os}
              placeholder="指定なし"
            />
          </View>
          <View style={styles.body}>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>1 - {dataSource.length} </Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{total || dataSource.length}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
            <IndividualUserManagementTable dataSource={dataSource.slice(0, FIRST_PAGE_ROW_COUNT)} />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {dataSource.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(dataSource.slice(FIRST_PAGE_ROW_COUNT, dataSource.length), PAGE_ROW_COUNT).map(
          (page, index) => (
            <Page
              key={index}
              size={pageSize}
              orientation={pageOrientation}
              style={[styles.page, styles.pagePadding]}
              wrap={false}
            >
              <FullPage>
                <IndividualUserManagementTable dataSource={page} />
              </FullPage>
              <PageNumber />
            </Page>
          )
        )}
    </Document>
  );
};

const getUserContractPeriod = (record: AnyObject) => {
  const years = (record.deletedat ? dayjs(record.deletedat) : dayjs()).diff(
    dayjs(record.createdat),
    'years'
  );
  const months = (record.deletedat ? dayjs(record.deletedat) : dayjs()).diff(
    dayjs(record.createdat),
    'months'
  );
  return `${months === 12 ? years + 1 : years}年${months === 12 ? 0 : months}ヶ月`;
};

export default PDFIndividualUserManagementTable;
