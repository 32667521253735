import styled from 'styled-components';

export const SectionStyled = styled.div`
  .content {
    .ant-input-show-count-suffix {
      margin-right: 0;
    }
    background-color: #f9f8f8;
    font-size: 13px;
    color: #424242;
    text-align: center;
    padding-top: 10px;
    .sub-title {
      font-size: 13px;
      text-align: center;
      color: #424242;
      margin-bottom: 0;
    }
    .form {
      margin: 30px;
      .text-label {
        font-size: 13px;
        display: block;
        text-align: right;
        color: #424242;
      }
      .input {
        height: 40px;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        border-radius: 3px;
        background-color: #ffffff;
        color: #424242;
        .ant-input-suffix {
          margin-top: 40px;
          margin-right: -10px;
        }
        ::placeholder {
          color: #cccccc;
          font-size: 14px;
        }
      }
    }
  }
`;
