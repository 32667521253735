import { createSlice } from '@reduxjs/toolkit';
import { getMonthListFromDateRanger } from 'libs/utils/calculate';
import * as Types from 'types';
import { getCompanySelect, getReportUserInformation } from 'pages/Report/UserInformation/thunk';

export interface InitialState {
  loading: boolean;
  selectedDateRanger?: string[];
  dateRanger?: string[];
  companyReports: Types.UserReportInformation.UserReportInformationResponse[];
  partnerReports: Types.UserReportInformation.UserReportInformationResponse[];
  calculatedCompanyReports?: Types.UserReportInformation.CalculatedReport;
  calculatedPartnerReports?: Types.UserReportInformation.CalculatedReport;
  calculatedIndividualUsers?: Types.UserReportInformation.CalculatedReport;
  calculatedCorporateUsers?: Types.UserReportInformation.CalculatedReport;
  totalAll?: Types.UserReportInformation.CalculatedReport;
  filterCompanyType?: string;
  filterCompanyName?: string;
  chartBusinessType?: string;
  chartSelectedCompany?: string;
  companies: Types.CorporateUserInformationType[];
  partners: Types.CorporateUserInformationType[];
}

const initialState: InitialState = {
  loading: false,
  dateRanger: [],
  selectedDateRanger: undefined,
  companyReports: [],
  partnerReports: [],
  calculatedCompanyReports: undefined,
  calculatedPartnerReports: undefined,
  calculatedIndividualUsers: undefined,
  calculatedCorporateUsers: undefined,
  totalAll: undefined,
  filterCompanyType: undefined,
  filterCompanyName: undefined,
  chartBusinessType: undefined,
  chartSelectedCompany: undefined,
  companies: [],
  partners: [],
};

export const reportUserInformationSlice = createSlice({
  name: 'sale-information-report-slice',
  initialState,
  reducers: {
    setDateRanger(state, action) {
      const values = action.payload;
      state.selectedDateRanger = values;
      state.dateRanger = getMonthListFromDateRanger(values?.[0], values?.[1]);
    },
    setFilterCompanyType(state, action) {
      state.filterCompanyType = action.payload;
    },
    setFilterCompanyName(state, action) {
      state.filterCompanyName = action.payload;
    },
    setChartBusinessType(state, action) {
      state.chartBusinessType = action.payload;
    },
    setChartSelectedCompany(state, action) {
      state.chartSelectedCompany = action.payload;
    },
  },
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };
    builder.addCase(getReportUserInformation.pending, startLoading);
    builder.addCase(getReportUserInformation.fulfilled, (state, action) => {
      const data = action.payload;
      state.companyReports = data.companies || [];
      state.partnerReports = data.partners || [];
      state.calculatedCompanyReports = data.calculateData.companies;
      state.calculatedPartnerReports = data.calculateData.partners;
      state.calculatedIndividualUsers = data.calculateData.individualUsers;
      state.calculatedCorporateUsers = data.calculateData.corporateUsers;
      state.totalAll = data.calculateData.total;
      stopLoading(state);
    });
    builder.addCase(getCompanySelect.fulfilled, (state, action) => {
      state.companies = action.payload.companies;
      state.partners = action.payload.partners;
    });
  },
});

export const {
  setChartSelectedCompany,
  setChartBusinessType,
  setDateRanger,
  setFilterCompanyType,
  setFilterCompanyName,
} = reportUserInformationSlice.actions;

export default reportUserInformationSlice.reducer;
