import React, { useCallback } from 'react';
import styled, { keyframes } from 'styled-components';

import { useDocumentLoader } from '../hooks/useDocumentLoader';
import { useWindowSize } from '../hooks/useWindowSize';
import { getFileName } from '../utils/getFileName';
import { setRendererRect } from '../store/actions';
import { LoadingIcon } from './icons';

export const ProxyRenderer: React.FC = () => {
  const { state, dispatch, CurrentRenderer } = useDocumentLoader();
  const { documents, documentLoading, currentDocument, config } = state;
  const size = useWindowSize();

  const containerRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        dispatch(setRendererRect(node.getBoundingClientRect()));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [size]
  );

  const fileName = getFileName(currentDocument, config?.header?.retainURLParams || false);

  const Contents = () => {
    if (!documents.length) {
      return <div id="no-documents"></div>;
    } else if (documentLoading) {
      if (config && config?.loadingRenderer?.overrideComponent) {
        const OverrideComponent = config.loadingRenderer.overrideComponent;
        return <OverrideComponent document={currentDocument} fileName={fileName} />;
      }

      return (
        <LoadingContainer id="loading-renderer" data-testid="loading-renderer">
          <LoadingIconContainer>
            <LoadingIcon color="#444" size={40} />
          </LoadingIconContainer>
        </LoadingContainer>
      );
    } else {
      if (CurrentRenderer) {
        return <CurrentRenderer mainState={state} />;
      } else {
        return null;
      }
    }
  };

  return (
    <Container id="proxy-renderer" data-testid="proxy-renderer" ref={containerRef}>
      <Contents />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  overflow-y: auto;
  align-items: flex-start;
  scrollbar-width: none;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  height: 75px;
  align-items: center;
  justify-content: center;
`;

const spinAnim = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingIconContainer = styled.div`
  animation-name: ${spinAnim};
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;
