import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ApartmentOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Button, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import localeJa from 'dayjs/locale/ja';
import saveAs from 'file-saver';
import lodash, { cloneDeep, compact, get, keyBy, omit, uniq } from 'lodash';
import dayjs from 'dayjs';

import { SEARCH_PARTNER, SEARCH_PUBLISH_OPTION, SELECT_RECORD } from 'constant/select.constants';
import { setHeaderTitle, startLoading, stopLoading } from 'containers/AppSettings/slice';
import { HEADER_CURRICULUM_MASTER_TABLE_CSV } from 'constant/header.export.constant';
import { IconClosed, IconLocked, IconPublish, Unpublished } from 'assets';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import * as Types from 'types';
import { ConditionsType, OfficialCurriculumTypes } from 'types';
import { Header, PagingNumber, SelectField } from 'components';
import { getHeaderTitle } from 'libs/utils/getHeaderTitle';
import { authSelector } from 'containers/Auth/selectors';
import { curriculumListSelector } from './selectors';
import { loadingRef } from 'components/Loading';
import { ListCurriculumStyled } from './styles';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import {
  getContractStatus,
  getOfficialCurriculum,
  getPartnerCompanies,
  getPartnerUser,
} from './thunk';
import { exportCsv } from 'libs/utils/exportCsv';
import { pdf } from '@react-pdf/renderer';
import PDFOfficalCurriculumList from './PDFOffcialCurriculumList';
import { setShowRecordSelected } from 'pages/CurriculumTree/slice';
import PerPageSelect from 'components/PerPageSelect';
import { getDataOfficialCurriculum } from 'pages/Dashboard/thunk';
import { clearCompanyId } from './slice';

const { Option } = Select;

const ListManagement: React.FC = () => {
  const [visibleModalCsv, setVisibleModalCsv] = useState(false);
  const [perPage, setPerpage] = useState<number>(100);
  // const [valueOption, setValueOption] = useState<number | string>(100);
  const [page, setPage] = useState<number>(1);
  const [allCurriculumList, setAllCurriculumList] = useState<OfficialCurriculumTypes[]>([]);
  const [otherCurriculumList, setOtherCurriculumList] = useState<any[]>([]);
  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    curriculumCompanies,
    curriculumList,
    loading,
    curriculumUser,
    curriculumContractStatus,
    initSearchCompanyId,
  } = useSelector(curriculumListSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const curriculumUserKeyByCode = keyBy(curriculumUser, 'official_curriculum_code');
  const curriculumCompanyKeyByCode = keyBy(curriculumCompanies, 'official_curriculum_code');
  const curriculumContractStatusKeyByCode = keyBy(curriculumContractStatus, 'p_related_id');

  const [filter, setFilter] = useState<any>({});
  const filteredCurriculum = otherCurriculumList.filter((item) => {
    return !curriculumList.some((curriculum) => curriculum.official_curriculum_code === item.code);
  });
  const mappedCurriculum = filteredCurriculum.map((item) => {
    return {
      official_curriculum_code: item.code || '',
      curriculum_name: item.name || '',
      ...item,
    };
  });
  const newArrr = [...mappedCurriculum, ...curriculumList];

  const newCurriculumList: OfficialCurriculumTypes[] = useMemo(() => {
    return lodash([
      ...newArrr.map((item) => {
        const user = curriculumUserKeyByCode[item.official_curriculum_code];
        const company = curriculumCompanyKeyByCode[item.official_curriculum_code];
        const contractStatus = curriculumContractStatusKeyByCode[item.company_id];

        const contractStatusValue =
          !contractStatus?.contract_end_date && !contractStatus?.deletedat
            ? null
            : contractStatus?.contract_end_date &&
              dayjs().isBefore(dayjs(contractStatus?.contract_end_date))
            ? 3
            : contractStatus?.deletedat && dayjs().isAfter(dayjs(contractStatus?.contract_end_date))
            ? 5
            : 1;

        return {
          ...item,
          num_of_user: user?.login_id,
          provider_name: contractStatus?.company_name,
          contract_end_date: contractStatus?.contract_end_date,
          deletedat: contractStatus?.deletedat,
          num_of_companies: company?.num_of_companies,
          contract_status: contractStatusValue,
        };
      }),
    ])
      .groupBy(({ official_curriculum_code }) => official_curriculum_code)
      .map((values: any, key) => {
        return {
          official_curriculum_code: key,
          values: Object.assign({ provider_id: values[0].company_id }, ...values),
        };
      })
      .value();
  }, [curriculumList, otherCurriculumList]);

  const formik = useFormik({
    initialValues: {
      deletedat: '',
      curriculum_code: '',
      provider_id: initSearchCompanyId,
      provider_name: '',
      curriculum_name: '',
      publish: '',
    },
    validateOnBlur: false,
    onSubmit: (values) => {
      const conditions: ConditionsType[] = [];
      const conditionsFilter: ConditionsType[] = [];
      Object.keys(values).forEach((key) => {
        if (values[key as keyof typeof values]) {
          conditions.push({
            id: key,
            search_value: [values[key as keyof typeof values]],
            exact_match: true,
          });
        }
      });

      Object.keys(filter).forEach((key) => {
        if (filter[key] && !['contract_status', 'provider_id', 'provider_name'].includes(key)) {
          conditionsFilter.push({
            id: key,
            search_value: [filter[key]],
            exact_match: true,
          });
        }
      });
      fetchManagement(conditionsFilter || conditions);
      setPage(1);
    },
    onReset: () => {
      fetchManagement();
      setPage(1);
    },
  });

  const [dataTable, setDataTable] = useState<OfficialCurriculumTypes[]>(newCurriculumList);
  const selectCompanyIdSearchField = compact(
    uniq(allCurriculumList.map((i) => get(i, 'values.provider_id')))
  );

  useEffect(() => {
    dispatch(startLoading());
    const filtered = newCurriculumList.filter((i) => {
      const isHaveContractStatus = filter.contract_status
        ? i.values.contract_status === filter.contract_status
        : true;
      const isHaveProviderId = filter.provider_id
        ? i.values.provider_id === filter.provider_id
        : true;
      const isHaveProviderName = filter.provider_name
        ? i.values.provider_name === filter.provider_name
        : true;
      const isHaveCurriculumnName = filter.curriculum_name
        ? i.values.curriculum_name === filter.curriculum_name
        : true;
      const isHaveCurriculumCode = filter.curriculum_code
        ? i.values.official_curriculum_code === filter.curriculum_code
        : true;
      const isHavePublic = filter.publish
        ? Number(i.values.publish) === Number(filter.publish)
        : true;
      return (
        isHaveContractStatus &&
        isHaveProviderId &&
        isHaveProviderName &&
        isHaveCurriculumCode &&
        isHavePublic &&
        isHaveCurriculumnName
      );
    });
    if (otherCurriculumList.length === 0) {
      setDataTable([]);
    } else {
      setDataTable(filtered);
    }
    dispatch(stopLoading());
  }, [newCurriculumList, otherCurriculumList]);

  useEffect(() => {
    dispatch(clearCompanyId());
  }, [dispatch]);

  const columns: ColumnsType<OfficialCurriculumTypes> = [
    {
      title: 'ID',
      dataIndex: 'values',
      key: 'values',
      width: '10%',
      align: 'left',
      render: (_: string, item: OfficialCurriculumTypes) => (
        <span className="curriculum_code">{item.values.official_curriculum_code}</span>
      ),
    },
    {
      title: 'カリキュラム',
      dataIndex: 'values',
      key: 'values',
      width: '25%',
      className: 'description',
      render: (_: string, item: OfficialCurriculumTypes) => (
        <span>{item.values.curriculum_name}</span>
      ),
    },
    {
      title: '公開ステータス',
      dataIndex: 'values',
      key: 'values',
      className: 'publish',
      width: '16%',
      align: 'center',
      render: (_: string, item: OfficialCurriculumTypes) => (
        <div className="question_code_table">
          {item.values.publish.toString() === '1' ? (
            <div className="row-content">
              <img src={Unpublished} className="icon" alt="employee-icon" />
              <span className="spanText">未公開</span>
            </div>
          ) : item.values.publish.toString() === '2' ? (
            <div className="row-content">
              <img src={IconPublish} className="icon" alt="employee-icon" />
              <span className="spanText">公開中</span>
            </div>
          ) : item.values.publish.toString() === '3' ? (
            <div className="row-content pub3">
              <img src={IconClosed} className="icon" alt="employee-icon" />
              <span className="spanText">公開停止中</span>
            </div>
          ) : (
            <div className="row-content">
              <img src={IconLocked} className="icon" alt="employee-icon" />
              <span className="spanText">編集中</span>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'ID',
      dataIndex: '',
      key: '',
      width: '10%',
      align: 'left',
      render: (_: string, item: OfficialCurriculumTypes) => item.values.provider_id ?? '-',
    },
    {
      title: 'パートナー',
      dataIndex: 'values',
      key: 'values',
      className: 'description',
      width: '25%',
      align: 'left',
      render: (_: string, item: OfficialCurriculumTypes) =>
        item.values.provider_name ? <span>{item.values.provider_name}</span> : <span>-</span>,
    },
    {
      title: '契約状況',
      dataIndex: 'values',
      key: 'values',
      className: 'description',
      width: '10%',
      render: (_: string, item: OfficialCurriculumTypes) => (
        <span>
          {item.values.contract_status === 1
            ? '契約中'
            : item.values.contract_status === 3
            ? '契約終了予定'
            : item.values.contract_status === 5
            ? '契約終了'
            : '-'}
        </span>
      ),
    },
    {
      title: () => {
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <span>利用企業数</span>
              <span>利用ユーザー数</span>
            </div>
          </>
        );
      },
      dataIndex: 'values',
      key: 'values',
      width: '12%',
      align: 'right',
      render: (_: string, item: OfficialCurriculumTypes) => {
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <div>
                {item.values.num_of_companies ? (
                  <span>{item.values.num_of_companies}</span>
                ) : (
                  <span>-</span>
                )}
              </div>
              <div>
                {item.values.num_of_user ? <span>{item.values.num_of_user}</span> : <span>-</span>}
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: () => {
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>前回公開日時</span>
              <span>最終更新日時</span>
            </div>
          </>
        );
      },
      dataIndex: 'values',
      key: 'values',
      width: '15%',
      render: (_: string, item: OfficialCurriculumTypes) => {
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                {item.values.publish_start_date ? (
                  dayjs(item.values.publish_start_date).locale(localeJa).format('YYYY/MM/DD HH:mm')
                ) : (
                  <span>-</span>
                )}
              </div>
              <div>
                {item.values.updatedat ? (
                  dayjs(item.values.updatedat).locale(localeJa).format('YYYY/MM/DD HH:mm')
                ) : (
                  <span>-</span>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: () => (
        <div className="title">
          <span>カリキュラム</span>
          <br />
          <span>ツリー</span>
        </div>
      ),
      dataIndex: 'apartmentOutlined',
      width: '11%',
      render: (_: string, item: OfficialCurriculumTypes) => (
        <div className="apartment-style">
          <ApartmentOutlined
            onClick={() => {
              dispatch(setHeaderTitle(getHeaderTitle(routes.CurriculumTree.path)));
              dispatch(setShowRecordSelected(true));
              navigate(
                generatePath(routes.CurriculumTree.path, {
                  id: item.values.company_id,
                }),
                { state: item }
              );
            }}
          />
        </div>
      ),
    },
  ];

  const handleExportCSV = async (value: string) => {
    const filename = 'パートナー管理_カリキュラム一覧';
    if (value === 'csv') {
      const listCsv = dataTable?.map(({ values: item }) => ({
        official_curriculum_code: `${item.official_curriculum_code}`,
        curriculum_name: `${item.curriculum_name}`,
        publish:
          item.publish == 1
            ? '未公開'
            : item.publish == 2
            ? '公開中'
            : item.publish == 3
            ? '公開停止中'
            : '編集中',
        provider_id: `${item.provider_id}`,
        provider_name: `${item.provider_name || '-'}`,
        contract_status:
          item.contract_status === 1
            ? '契約中'
            : item.contract_status === 3
            ? '契約終了予定'
            : item.contract_status === 5
            ? '契約終了'
            : '-',
        num_of_companies: `${item?.num_of_companies || '-'}`,
        num_of_user: `${item?.num_of_user || '-'}`,
        publish_start_date: item.publish_start_date
          ? `${dayjs(item.publish_start_date).locale(localeJa).format('YYYY/MM/DD HH:mm')}`
          : '-',
        updatedat: item.updatedat
          ? `${dayjs(item.updatedat).locale(localeJa).format('YYYY/MM/DD HH:mm')}`
          : '-',
      }));

      exportCsv(listCsv, HEADER_CURRICULUM_MASTER_TABLE_CSV, `${filename}.csv`);
    } else {
      const blob = await pdf(
        <PDFOfficalCurriculumList filter={formik.values} curriculumList={dataTable} />
      ).toBlob();
      saveAs(blob, `${filename}.pdf`);
    }
    setVisibleModalCsv(false);
  };

  // const handleSelectChange = (value: number | string) => {
  //   if (typeof value === 'string') {
  //     setPerpage(newCurriculumList.length);
  //   } else {
  //     setPerpage(value);
  //   }
  //   setValueOption(value);
  //   setPage(1);
  // };

  const fetchManagement = useCallback(
    async (conditions?: Types.ConditionsType[]) => {
      if (userInfo) {
        const result = await dispatch(
          getOfficialCurriculum({
            conditions,
            page: 1,
            per_page: 0,
            include_lookups: true,
            include_item_ref: true,
          })
        );
      }
    },
    [dispatch, userInfo]
  );

  useEffect(() => {
    (async () => {
      const conditions: any[] = [];
      if (initSearchCompanyId) {
        conditions.push({
          id: 'company_id',
          search_value: [initSearchCompanyId],
          exact_match: true,
        });
      }
      const result = await dispatch(
        getDataOfficialCurriculum({
          conditions: conditions,
          page: 1,
          per_page: 0,
        })
      );
      if (getDataOfficialCurriculum.fulfilled.match(result)) {
        setOtherCurriculumList(result.payload.items);
      } else {
        console.log('error');
      }
    })();
  }, []);

  useEffect(() => {
    Promise.all([
      dispatch(
        getPartnerUser({
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getPartnerCompanies({
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(getContractStatus({ page: 1, per_page: 0 })),
    ]);
  }, [dispatch]);

  useEffect(() => {
    fetchManagement();
  }, [fetchManagement]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerpage(dataTable.length);
    }
  }, [dataTable]);

  const handleChange = (value: any, field: string) => {
    if (value === 'all' && !value) {
      setFilter(omit(cloneDeep(filter), [field]));
    } else {
      setFilter({ ...filter, [field]: value });
    }
  };
  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      setAllCurriculumList(newCurriculumList);
    }
  }, [newCurriculumList]);

  return (
    <ListCurriculumStyled>
      <Header title={headerTitle} className="header" />
      <div className="curriculum-styled">
        <div className="curriculum-partner">
          <p className="curriculum-title">
            パートナーが作成したOFFICIALカリキュラムの確認と管理を行う画面です。
          </p>
          <div>
            <button
              className="btn-list"
              onClick={() => {
                dispatch(setHeaderTitle(getHeaderTitle(routes.PartnerManagement.path)));
                navigate(generatePath(routes.PartnerManagement.path));
              }}
            >
              パートナー管理 <RightOutlined />
            </button>
          </div>
        </div>
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <div className="item-select">
                <Form.Item
                  name="contract_status"
                  className="item contract-status"
                  label={<span className="text-label">契約状況</span>}
                >
                  <SelectField
                    data-testid="ocl-contract-status"
                    placeholder="指定なし"
                    name="contract_status"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onChange={(e) => {
                      handleChange(e, 'contract_status');
                    }}
                  >
                    {SEARCH_PARTNER.map((item) => (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="provider_id"
                  className="item contract-status"
                  label={<span className="text-label">ID</span>}
                >
                  <SelectField
                    data-testid="ocl-provider-id"
                    placeholder="指定なし"
                    name="provider_id"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onChange={(e) => {
                      handleChange(e, 'provider_id');
                    }}
                  >
                    {selectCompanyIdSearchField.map((item, index) => (
                      <Option value={item} key={index}>
                        {item}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="provider_name"
                  className="item partner-name"
                  label={<span className="text-label">パートナー</span>}
                >
                  <SelectField
                    data-testid="ocl-partner-name"
                    placeholder="指定なし"
                    name="provider_name"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onChange={(e) => {
                      handleChange(e, 'provider_name');
                    }}
                  >
                    {compact(
                      uniq(allCurriculumList.map((i) => get(i, 'values.provider_name')))
                    ).map((item, index) => (
                      <Option value={item} key={index}>
                        {item}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="curriculum_code"
                  className="item contract-status"
                  label={<span className="text-label">カリキュラムID</span>}
                >
                  <SelectField
                    data-testid="ocl-curriculum-code"
                    onChange={(value) => handleChange(value, 'curriculum_code')}
                    placeholder="指定なし"
                    name="curriculum_code"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {compact(
                      uniq(allCurriculumList.map((i) => get(i, 'official_curriculum_code')))
                    ).map((item, index) => (
                      <Option value={item} key={index}>
                        {item}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="curriculum_name"
                  className="item"
                  label={<span className="text-label">カリキュラム</span>}
                >
                  <SelectField
                    data-testid="ocl-curriculum-name"
                    placeholder="指定なし"
                    name="curriculum_name"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onChange={(value) => handleChange(value, 'curriculum_name')}
                  >
                    {compact(
                      uniq(allCurriculumList.map((i) => get(i, 'values.curriculum_name')))
                    ).map((item, index) => (
                      <Option value={item} key={index}>
                        {item}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="publish"
                  className="item contract-status"
                  label={<span className="text-label">公開ステータス</span>}
                >
                  <SelectField
                    data-testid="ocl-publish-status"
                    placeholder="指定なし"
                    name="publish"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onChange={(value) => handleChange(value, 'publish')}
                  >
                    {SEARCH_PUBLISH_OPTION.map((item) => (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
              </div>
              <div className="group-btn">
                <SubmitButton className="btn-search" loading={false}>
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <span
                  className="label-reset"
                  onClick={() => {
                    formik.resetForm();
                    setFilter({});
                  }}
                >
                  リセット
                </span>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <div className="wrap-table">
          <div className={`${newCurriculumList.length ? '' : 'style-list-no-data'}`}>
            <div className={`${newCurriculumList.length ? 'wrap-button' : 'no-data'}`}>
              <Button className="btn btn-active" onClick={() => setVisibleModalCsv(true)}>
                エクスポート
              </Button>
            </div>
          </div>
          <Table
            tableLayout="fixed"
            rowKey="i_id"
            className="table"
            dataSource={dataTable}
            columns={columns}
            pagination={{
              pageSize: perPage,
              current: page,
              total: newCurriculumList.length,
              onChange: setPage,
              showSizeChanger: false,
              position: ['topCenter'],
              showTotal: () => (
                <div className="wrap-select-record">
                  <PagingNumber
                    startItem={dataTable.length ? `${(page - 1) * perPage + 1}` : ''}
                    endItem={page * perPage > dataTable.length ? dataTable.length : page * perPage}
                    totalItem={dataTable.length}
                  />
                  <PerPageSelect
                    data={dataTable}
                    perPage={perPage}
                    setPage={setPage}
                    setPerPage={setPerpage}
                  />
                </div>
              ),
            }}
          />
        </div>
      </div>
      <PopupConfirmExportFile
        visible={visibleModalCsv}
        setVisible={setVisibleModalCsv}
        onSubmit={handleExportCSV}
        customTitle={
          <>
            エクスポートを実行します。
            <br />
            出力形式を選択して、OKボタンをクリックしてください。
            <br />
            <br />
          </>
        }
      />
    </ListCurriculumStyled>
  );
};

export default ListManagement;
