import React, { useMemo } from 'react';
import { Column, Line } from '@ant-design/plots';

import Styled from './styles';
import { ReportNumberRegistedCompanies, ReportNumberTrendCompanies } from 'types';
import { groupBy, sortBy, toPairs, uniq } from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { reportUserInformationSelector } from 'pages/Report/UserInformation/selectors';
import { EXPORT_TARGET_MONTH_COUNT } from 'constant';
import { formatComma } from 'libs/utils/format';

interface Props {
  exportPdf?: boolean;
}

const titleLineChart = 'パートナー企業';
const titleColumnChart = '一般企業';

export const formatMonth = (month: string) => {
  const monthArr = month.split('');
  monthArr.splice(4, 0, '/');
  return monthArr.join('');
};

const Development = (props: Props) => {
  const { dateRanger, companyReports, calculatedCompanyReports, calculatedPartnerReports } =
    useSelector(reportUserInformationSelector);

  const { exportPdf } = props;

  const dates = useMemo(() => {
    let dateData =
      dateRanger && dateRanger.length
        ? dateRanger
        : uniq(companyReports.map((obj) => obj.target_month));
    if (exportPdf) {
      dateData = dateData.slice(0, EXPORT_TARGET_MONTH_COUNT);
    }
    return dateData;
  }, [companyReports, dateRanger, exportPdf]);

  const dataPartner = useMemo(() => {
    if (!calculatedPartnerReports) {
      return [];
    }

    const monthEndUser = calculatedPartnerReports.month_end_users;

    return dates.map((date) => ({
      target_month: dayjs(date, 'YYYYMM').format('YYYY/MM'),
      value: monthEndUser[date],
      name: titleLineChart,
    }));
  }, [calculatedPartnerReports]);

  const dataCompany = useMemo(() => {
    if (!calculatedCompanyReports) {
      return [];
    }

    const monthEndUser = calculatedCompanyReports.month_end_users;

    return dates.map((date) => ({
      target_month: dayjs(date, 'YYYYMM').format('YYYY/MM'),
      value: monthEndUser[date],
      name: titleColumnChart,
    }));
  }, [calculatedCompanyReports]);

  const configLineChart = {
    data: dataPartner,
    xField: 'target_month',
    yField: 'value',
    legend: true,
    seriesField: 'name',
    point: {
      size: 5,
      shape: 'circle',
      style: {
        fill: '#F6AC00',
        stroke: '#F6AC00',
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  };

  const configColumnChart = {
    data: dataCompany,
    xField: 'target_month',
    yField: 'value',
    seriesField: 'name',
    maxColumnWidth: 25,
    dodgePadding: 4,
    color: '#08A3A5',
  };

  return (
    <Styled>
      <div className="user-info">
        <div className="body-info">
          <div className="right-side">
            <div className="wrap-header">
              <div className="wrap-action">
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter blue">
                      <div className="circle" />
                      <div className="line" />
                    </div>
                    <span className="legend-name">パートナー企業数</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter yellow" />
                    <span className="legend-name">一般企業数</span>
                  </div>
                </div>
              </div>
            </div>
            <span className="title">パートナー企業数推移</span>
            <div className="chart">
              <div className="line">
                <span className="unit">単位：社</span>
                <Line
                  className="line-chart"
                  {...configLineChart}
                  label={{
                    position: 'top',
                    style: {
                      fill: '#F6AC00',
                    },
                    formatter: (v) => formatComma(v.value),
                  }}
                  lineStyle={{
                    stroke: '#F6AC00',
                  }}
                  xAxis={{
                    label: {
                      formatter: (text: string) => `${text}`,
                    },
                  }}
                  legend={false}
                  yAxis={{
                    label: {
                      formatter: formatComma,
                    },
                    grid: {
                      line: {
                        style: {
                          stroke: '#ddd',
                          lineDash: [2, 2],
                        },
                      },
                    },
                  }}
                  scrollbar={{
                    type: 'horizontal',
                    width: 300,
                    categorySize: 70,
                  }}
                />
              </div>
              <span className="title">一般企業数推移</span>
              <div className="column">
                <span className="unit">単位：社</span>
                <Column
                  className="column-chart"
                  {...configColumnChart}
                  legend={false}
                  yAxis={{
                    grid: {
                      line: {
                        style: {
                          lineDash: [2, 2],
                          stroke: '#ddd',
                        },
                      },
                    },
                    label: {
                      formatter: formatComma,
                    },
                  }}
                  xAxis={{
                    tickLine: null,
                  }}
                  label={{
                    position: 'top',
                    formatter: (v) => formatComma(v.value),
                  }}
                  scrollbar={{
                    type: 'horizontal',
                    width: 300,
                    categorySize: 70,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default Development;
