import React, { useEffect, useMemo, useState } from 'react';
import { SectionStyled } from './styles';
import { AnyObject, HeaderCSVs } from 'types/config';
import { Tooltip } from 'antd';
import _ from 'lodash';
import { ColumnsType } from 'antd/es/table';
import { IconCsvError } from 'assets';
import { DEFAULT_TABLE_COL_WIDTH } from 'constant';
import VirtualTable from 'components/VirtualTable';
import ResizeObserver from 'rc-resize-observer';
import { exportCsv } from 'libs/utils/exportCsv';

interface ImportedDataTableProps {
  attached?: boolean;
  headerCSVs: HeaderCSVs;
  dataImported?: AnyObject[];
  headersImported?: string[];
  errors?: AnyObject;
  warnings?: AnyObject;
  loading?: boolean;
  title?: string;
  estimateTime?: string;
}

const DEFAULT_TABLE_ITEMS = 13;

function CustomCell(props: AnyObject) {
  const { error } = props;
  if (error) {
    return (
      <Tooltip
        title={error}
        color="#ffffff"
        placement="bottomLeft"
        overlayInnerStyle={{
          backgroundColor: '#FFFFFF',
          background: '#FFFFFF',
          color: '#424242',
          padding: '8px',
        }}
      >
        <td {...props} />
      </Tooltip>
    );
  }
  return <td {...props} />;
}

const ImportedDataTable: React.FC<ImportedDataTableProps> = (props) => {
  const {
    loading,
    attached,
    headerCSVs,
    errors,
    warnings,
    dataImported,
    estimateTime,
    title,
    headersImported,
  } = props;
  const [dataSource, setDataSource] = useState<any>([]);
  const [headerErrors, setHeaderErrors] = useState<string[]>([]);
  const [showOnlyError, setShowOnlyError] = useState<boolean>(false);
  const [lineErrors, setLineErrors] = useState<number>(0);
  const [tableWidth, setTableWidth] = useState(1920);

  const columns: ColumnsType<AnyObject> = useMemo(() => {
    const totalColumns = (headerCSVs || []).length || 1;
    const calColWidth = Math.ceil((tableWidth - 70) / totalColumns);
    const colWidth = calColWidth > DEFAULT_TABLE_COL_WIDTH ? calColWidth : DEFAULT_TABLE_COL_WIDTH;
    return [
      {
        title: <div className="table-header-cell">行数</div>,
        width: 70,
        dataIndex: 'index',
        render: (value, row) => {
          return (
            <div className="rowNumber" style={{ width: '70px' }}>
              {row.hasError && (
                <Tooltip
                  title="この行にエラーがあります。"
                  color="#ffffff"
                  placement="bottomLeft"
                  overlayInnerStyle={{
                    backgroundColor: '#FFFFFF',
                    background: '#FFFFFF',
                    color: '#424242',
                    padding: '8px',
                  }}
                >
                  <img src={IconCsvError} alt="iconError" />
                </Tooltip>
              )}
              <div className="rowIndex">{value}</div>
            </div>
          );
        },
      },
      ...(headerCSVs || []).map((obj) => {
        const isErrorHeader = headerErrors.includes(obj.label);
        return {
          title: isErrorHeader ? (
            <Tooltip
              title={`${obj.label}は必須項目です。`}
              color="#ffffff"
              placement="bottomLeft"
              overlayInnerStyle={{
                backgroundColor: '#FFFFFF',
                background: '#FFFFFF',
                color: '#424242',
                padding: '8px',
              }}
            >
              <div className="table-header-cell">
                {obj.label} {obj.required && <span className="required">*</span>}
              </div>
            </Tooltip>
          ) : (
            <div className="table-header-cell">
              {obj.label} {obj.required && <span className="required">*</span>}
            </div>
          ),
          width: obj.width || colWidth || DEFAULT_TABLE_COL_WIDTH,
          dataIndex: obj.key,
          className: isErrorHeader ? 'cell-error' : undefined,
          render: (value: string, record: AnyObject) => {
            const errorKey = `${obj.key}_error`;
            const error = record[errorKey];
            return {
              props: {
                style: { background: error ? 'rgba(217, 155, 255, 0.4)' : '#FFFFFF' },
                error: error,
              },
              children: value,
            };
          },
        };
      }),
    ];
  }, [headerCSVs, headerErrors, tableWidth]);

  useEffect(() => {
    if (!attached) {
      setShowOnlyError(false);
      const items = _.range(1, DEFAULT_TABLE_ITEMS).map(
        (idx) =>
          headerCSVs.reduce((o, item) => ({ ...o, [item.key]: '' }), { index: '', rowKey: idx }),
        {}
      );
      setHeaderErrors([]);
      setDataSource(items);
    } else {
      const diffHeaders = _.difference(
        headerCSVs.map((obj) => obj.label),
        headersImported || []
      );
      setHeaderErrors(diffHeaders);
      const headerErrorObj =
        diffHeaders && diffHeaders.length
          ? headerCSVs
              .filter((obj) => diffHeaders.includes(obj.label))
              .reduce(
                (obj, item) => ({ ...obj, [`${item.key}_error`]: `${item.label}は必須項目です。` }),
                {}
              )
          : {};
      const items = (dataImported || []).map((obj, index) => {
        const err = (errors || {})[index];
        const warning = (warnings || {})[index];
        const hasError = !!err || !_.isEmpty(headerErrorObj);
        const hasWarning = !!warning;
        return {
          ...obj,
          index: index + 1,
          rowKey: index,
          hasError,
          hasWarning,
          ...(err || {}),
          ...(warning || {}),
          ...headerErrorObj,
        };
      });
      const errorDataCount = items.filter((obj) => obj.hasError || obj.hasWarning).length;
      setLineErrors(!_.isEmpty(headerErrorObj) ? errorDataCount + 1 : errorDataCount);
      setDataSource(showOnlyError ? items.filter((obj) => obj.hasError) : items);
    }
  }, [attached, headerCSVs, dataImported, headersImported, errors, showOnlyError, warnings]);

  const switchOnlyError = () => {
    setShowOnlyError((pre) => !pre);
  };

  const exportErrorFile = () => {
    exportCsv(dataSource, headerCSVs, `エラーファイル_${title}.csv`);
  };

  return (
    <SectionStyled>
      <div className="header">
        {attached && (
          <div>
            インポート予定件数：{(dataImported || []).length}件{'　'}/{'　'}エラー件数：
            <span className={lineErrors ? 'text-red' : ''}>{lineErrors}件</span>
            {estimateTime && (
              <span>
                {'　'}/{'　'}予想時間：{estimateTime}
              </span>
            )}
          </div>
        )}
        <div className="dataTitle">インポートするCSVファイルを確認します。</div>
        {attached && (
          <button type="button" className="btn-upload" onClick={switchOnlyError}>
            {showOnlyError ? '全て表示する' : 'エラーのみ表示する'}
          </button>
        )}
      </div>
      <ResizeObserver
        onResize={({ width }) => {
          setTableWidth(width);
        }}
      >
        <VirtualTable
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          rowKey="rowKey"
          scroll={{ x: 1440, y: 700 }}
          pagination={false}
          bordered
          components={{
            body: {
              cell: CustomCell,
            },
          }}
          locale={{
            emptyText:
              attached && !dataImported?.length && !loading ? (
                <span className="text-red">インポートするデータが存在しません。</span>
              ) : null,
          }}
        />
      </ResizeObserver>
      {showOnlyError && lineErrors && (
        <button className="btn-export" type="button" onClick={exportErrorFile}>
          エラーファイルをDL
        </button>
      )}
    </SectionStyled>
  );
};

export default ImportedDataTable;
