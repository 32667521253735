import React from 'react';

import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Form } from 'formik-antd';

import { getDataCurriculum, updateCurriculum, updateLevelCurriculum } from '../../thunk';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { setFilterByCurriculum, setFilterChanged } from 'pages/Curriculum/slice';
import { authSelector } from 'containers/Auth/selectors';
import { Input, Modal } from 'components';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import { curriculumSelector } from 'pages/Curriculum/selectors';

interface Props {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  node: Types.TreeItem<Types.CurriculumItemType>;
  visible: boolean;
  rootNode: Types.TreeItem<Types.CurriculumItemType>;
}

const EditLevelCurriculum: React.FC<Props> = ({ node, visible, setVisible, rootNode }) => {
  const { conditions } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik<Types.AddLowerHierarchyFormik>({
    initialValues: {
      curriculumName: node.node?.name || '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!node) return;
      dispatch(startLoading());
      handleCancel();

      const resultAction = await dispatch(
        updateLevelCurriculum({
          id: node.node?.i_id!,
          level: node.columnIndex!,
          data: {
            item: {
              name: values.curriculumName,
            },
          },
        })
      );
      if (
        updateLevelCurriculum.fulfilled.match(resultAction) &&
        (rootNode?.node?.i_id || rootNode.i_id)
      ) {
        await dispatch(
          updateCurriculum({
            id: rootNode?.node?.i_id || rootNode.i_id!,
            data: {
              item: {
                updated_at: new Date().toDateString(),
                updatedat: new Date(),
              },
              is_force_update: true,
            },
          })
        );
        await Promise.all([
          dispatch(
            setFilterChanged({
              items: {
                type:
                  node.columnIndex === 1
                    ? 'level1_name'
                    : node.columnIndex === 2
                    ? 'level2_name'
                    : node.columnIndex === 3
                    ? 'level3_name'
                    : 'level4_name',
                value: values.curriculumName,
              },
            })
          ),
          dispatch(
            getDataCurriculum({
              conditions: [
                {
                  id: 'provider_id',
                  search_value: [userInfo?.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        dispatch(
          setFilterByCurriculum({
            name: conditions?.name,
          })
        );
      }

      dispatch(stopLoading());
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setVisible(false);
  };

  return (
    <Modal
      title={`第${node.columnIndex}階層 編集`}
      width={720}
      visible={visible}
      okButton={{
        text: '更新',
        onClick: formik.handleSubmit,
        disabled: node.node?.name === formik.values.curriculumName,
      }}
      cancelButton={{
        text: '閉じる',
        onClick: handleCancel,
      }}
      onCancel={handleCancel}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #cccccc',
      }}
    >
      <SectionStyled>
        <div className="content">
          <p className="sub-title">
            第{node.columnIndex}階層の編集が可能です。
            <br />
            編集後に更新ボタンをクリックしてください。
          </p>
          <FormikProvider value={formik}>
            <Form layout="vertical" className="form">
              <Form.Item
                name="curriculumName"
                label={<span className="text-label">第{node.columnIndex}階層名</span>}
                className="form-input"
                colon={false}
              >
                <Input
                  name="curriculumName"
                  className="input"
                  type="text"
                  placeholder="最大30文字"
                  showCount
                  maxLength={30}
                />
              </Form.Item>
            </Form>
          </FormikProvider>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default EditLevelCurriculum;
