import styled from 'styled-components';

interface Props {
  checkTableNoData: boolean;
}

export default styled.section<Props>`
  position: relative;
  background-color: #f9f8f8;
  height: 100%;
  width: 100%;
  padding: 30px;
  @media (max-width: 1440px) {
    .wrap-nav {
      button {
        width: 180px;
        height: 40px;
      }
    }
    .border-line {
      margin: 30px 0 20px 0 !important;
    }
    .form-search {
      justify-content: flex-start !important;
      .btn-search {
        width: 100px;
      }
    }
    .item {
      width: 240px !important;
      margin-right: 8px;
    }
    .btn-search,
    .label-reset {
      margin-left: 12px;
    }
    .wrap-body {
      .btn-div {
        .btn-control-number {
          width: 160px;
        }
      }
      .wrap-table {
        .table {
          .ant-table-thead {
            .ant-table-cell {
              padding: 8px 10px;
            }
          }
          .ant-table-tbody {
            .ant-table-cell {
              height: 44px;
            }
          }
        }
      }
    }
  }
  .label-perpage,
  .ant-select-selection-item {
    font-size: 11px;
    font-family: Noto Sans Javanese;
  }
  .ant-select-arrow {
    font-size: 9px;
    top: 51%;
  }
  .row-table {
    position: relative;
    ::after {
      position: absolute;
      content: '';
      background: #08a3a5;
      left: 10px;
      top: 10%;
      height: 80%;
      width: 3px;
      border-radius: 2px;
    }
  }
  .row-table-deleted {
    position: relative;
    ::after {
      position: absolute;
      content: '';
      background: #c4c4c4;
      left: 10px;
      top: 10%;
      height: 80%;
      width: 3px;
      border-radius: 2px;
    }
  }
  .wrap-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text-note {
      font-size: 12px;
      color: #424242;
    }
    .wrap-button {
      display: flex;
      gap: 10px;
      button {
        background: #ffffff;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: rgba(246, 172, 0, 1);
        padding: 8px 20px;
        max-height: 40px;
        font-size: 13px;
        cursor: pointer;
        .btn-text {
          display: flex;
          align-items: center;
          justify-content: center;
          .icon-right-outline {
            padding-left: 20px;
          }
        }
      }
    }
  }
  .border-line {
    border-top: 1px solid #dddddd;
    width: 40px;
    margin: 30px 0;
    background-color: red;
    max-height: 40px;
  }
  .form-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item {
      width: 22%;
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
      .icon-search {
        font-size: 18px;
        margin-right: 12px;
      }
    }
  }
  .ant-table {
    border: 1px solid #e5e5e5;
    overflow: hidden;
    border-radius: 3px;
    .ant-table-row:last-child {
      td {
        border: none;
      }
    }
    .ant-table-placeholder {
      .ant-table-cell {
        border: none;
      }
    }
  }
  .wrap-body {
    width: 100%;
    background: #f9f8f8;

    .text-count {
      display: flex;
      align-items: center;
      position: absolute;
      margin-top: 22px;
      font-family: Lato;
      font-size: 14px;
      color: #424242;
      z-index: 9;
      .text-static {
        font-size: 11px;
        padding-left: 3px;
      }
      .select-perpage {
        display: flex;
        align-items: center;
        .label {
          font-size: 12px;
          color: #424242;
          margin: 0 5px;
        }
      }
    }
    .btn-div {
      display: flex;
      position: absolute;
      right: 30px;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      margin-top: 10px;
      z-index: 9;
      .btn-control-number {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(246, 172, 0, 1);
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: rgba(255, 255, 255, 1);
        height: 40px;
        padding: 0 37px;
        border: none;
        cursor: pointer;
        z-index: 100;
        .text {
          padding: 0 0;
          font-size: 13px;
          font-weight: 700;
        }
      }
    }

    .wrap-table {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .table {
        margin-top: ${(props) => (props.checkTableNoData ? '64px' : '0')};
        .ant-table-thead {
          border-radius: 2px 2px 0 0;
          .ant-table-cell {
            background-color: #ebebeb;
            font-weight: 700;
            font-size: 12px;
            color: #424242;
            margin: 0 3px;
            &:before {
              display: none;
            }
          }
        }
        .ant-table-cell {
          text-align: center;
          font-size: 12px;
        }
        .ant-table-cell.name,
        .position-name {
          text-align: left;
          .wrap-content-id {
            .fax-id {
              color: #777777;
              font-size: 12px;
            }
            .content-id {
              color: #2a2a2a;
              font-size: 14px;
            }
          }
        }
        .ant-table-tbody .ant-table-cell.date {
          font-size: 11px;
          color: #777777;
        }
      }
      .ant-table-wrapper {
        width: 100%;
      }
      .icon-question {
        color: rgba(8, 163, 165, 1);
        margin-left: 4px;
        cursor: pointer;
      }
      .wrap-icon-row {
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(196, 196, 196, 1);
        font-size: 16px;
        cursor: pointer;
        .icon:hover {
          color: #08a3a5;
        }
      }
      .wrap-check-box {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        .check-invoice-filled {
          color: #4b83d7;
        }
        .check-invoice-outlined {
          color: rgba(75, 131, 215, 0.4);
        }
        .check-withdrawal-filled {
          color: #8b62e2;
        }
        .check-withdrawal-outlined {
          color: rgba(139, 98, 226, 0.4);
        }
      }
      .wrap-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .wrap-contract-status {
        position: relative;
        text-align: center;
        .text-status {
          font-size: 12px;
        }
        .text-status-active {
          font-size: 12px;
          position: relative;
        }
        .wrap-content-id {
          .fax-id {
            color: #777777;
            font-size: 12px;
          }
          .content-id {
            color: #2a2a2a;
            font-size: 14px;
          }
        }
      }
    }
  }
`;
