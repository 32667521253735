import styled from 'styled-components';
import { Modal } from 'components';

type Props = {
  isEmptyData: boolean;
};
export default styled(Modal)<Props>`
  @media (max-width: 1440px) {
    .wrap-targer-official {
      height: 48px !important;
    }
    .wrap-button,
    .ant-btn {
      width: 160px !important;
    }
    .table {
      .ant-table-tbody .ant-table-cell {
        padding: 6px !important;
      }
      .list-release {
        width: 150px !important;
      }
    }
  }
  .text-count {
    display: flex;
  }
  .wrap-select {
    margin-left: 60px;
  }
  .title {
    font-size: 18px;
  }
  .sub-title {
    text-align: center;
    color: #424242;
  }
  label {
    color: #999999;
    font-size: 11px;
  }
  .wrap-targer-official {
    display: flex;
    justify-content: space-between;
    border: 1px solid #eeeeee;
    background: #ffffff;
    .wrap-left-side {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 16px;
      .text-left {
        span:nth-of-type(1) {
          font-size: 16px;
          color: #2a2a2a;
        }
        span:nth-of-type(2) {
          font-size: 12px;
          color: #999999;
        }
      }
      .wrap-creator {
        font-size: 12px;
        .label-creator {
          font-size: 12px;
          color: #999999;
        }
      }
    }
    .wrap-right-side {
      display: flex;
      align-items: center;
      border-left: 1px solid #eeeeee;
      padding: 0 20px;
      .wrap-status {
        width: max-content;
      }
      .icon-status {
        height: 24px;
        margin-right: 8px;
      }
      span {
        font-size: 13px;
      }
      .violet {
        color: #b57fcf;
      }
      .green {
        color: #71c131;
      }
      .grey {
        color: #989898;
      }
      .blue {
        color: #6a6ecd;
      }
    }
  }
  .wrap-button {
    position: absolute;
    right: 0;
    margin: 30px 30px 12px 30px;
    z-index: 99999;
    .btn-active {
      display: inline-flex;
      align-items: center;
      padding: 0 30px;
      height: 40px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      margin-left: 10px;
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
    }
  }
  .ant-table {
    border: 1px solid #e5e5e5;
    overflow: hidden;
    border-radius: 3px;
    .ant-table-row:last-child {
      td {
        border: none;
      }
    }
    .ant-table-placeholder {
      .ant-table-cell {
        border: none;
      }
    }
  }
  .table {
    margin-top: ${(props) => (props.isEmptyData ? 80 : 15)}px;
    .ant-table-cell:nth-of-type(4) {
      border-right: 1px solid rgba(85, 85, 85, 0.08);
    }
    .no {
      color: #999999;
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      height: 0;
    }
    .ant-table-thead .ant-table-cell {
      padding: 10px !important;
    }
    .ant-table-thead > tr > th {
      font-size: 11px;
      font-weight: 700;
    }
    .ant-table-tbody .ant-table-cell {
      font-size: 12px;
    }
    .wrap-button-table {
      display: flex;
      justify-content: space-between;
      gap: 4px;
      button {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        height: 28px;
        span {
          font-size: 12px;
        }
      }
      .release-left {
        width: 46px !important;
      }
      .release-right {
        width: 79px !important;
      }
    }
    .list-release {
      background: #08a3a5;
      color: #ffffff;
      font-size: 13px;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-weight: 700;
      border: none;
    }
    .disabled {
      text-align: center;
      background: #cccccc !important;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border: none !important;
      color: #ffffff !important;
    }
    .release_note_title {
      text-overflow: ellipsis;
      /* width: 250px;
      white-space: nowrap; */
      overflow: hidden;
    }
    .create-new {
      width: 100%;
      background: #f6ac00;
      border: none;
    }
    .wrap-status {
      .icon-status {
        height: 18px !important;
        margin-right: 8px;
      }
      span {
        font-size: 11px;
      }
    }
  }
`;
