import React, { CSSProperties } from 'react';
import { MessageFormatElement } from 'react-intl';
import { ModalProps } from 'antd';

import ModalStyled, { ButtonStyled } from './styles';
import SpinLoading from 'components/SpinLoading';

type ButtonProps = {
  text?: string | MessageFormatElement[] | React.ReactNode;
  onClick?: () => Promise<void> | void;
  style?: CSSProperties;
  disabled?: boolean;
  loading?: boolean;
};

type Props = ModalProps & {
  cancelButton?: ButtonProps;
  okButton?: ButtonProps;
  type: 'create' | 'edit';
  othersButton?: ButtonProps[];
  headerStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  footerStyle?: CSSProperties;
  contentStyle?: CSSProperties;
};

const Modal: React.FC<Props> = ({
  cancelButton,
  okButton,
  headerStyle,
  contentStyle,
  bodyStyle,
  footerStyle,
  othersButton,
  children,
  type,
  ...props
}) => {
  return (
    <ModalStyled
      centered
      contentStyle={contentStyle}
      headerStyle={headerStyle}
      bodyStyle={bodyStyle}
      footerStyle={footerStyle}
      footer={
        <div className="wrap-footer">
          <div className="wrap-button-footer">
            <SpinLoading key="button-ok" loading={false} size="default">
              <ButtonStyled
                type="submit"
                style={okButton?.style}
                onClick={okButton?.onClick}
                disabled={okButton?.disabled}
                className={`button-primary${okButton?.disabled ? ' disabled' : ''}`}
              >
                <span className="button-label">{okButton?.text}</span>
              </ButtonStyled>
            </SpinLoading>
            <ButtonStyled
              type="button"
              key="button-cancel"
              style={cancelButton?.style}
              className="button-secondary"
              onClick={cancelButton?.onClick}
            >
              <span className="button-label">{cancelButton?.text}</span>
            </ButtonStyled>
          </div>
          {type === 'create' && (
            <div className="wrap-sub-text-footer">
              <span>※URL発行をクリックすると、入力したメールアドレス宛に</span>
              <span>請求書対応アカウント登録用のURLが送信されます。</span>
            </div>
          )}
        </div>
      }
      closable={false}
      {...props}
    >
      {children}
    </ModalStyled>
  );
};

export default Modal;
