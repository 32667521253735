import React from 'react';

import TableMonthlyByUser from './MonthlyByUserDevelopment';
import TableDevelopment from './Development';
import * as Types from 'types';

interface Props {
  visibleExpansionOfSale: boolean;
  visibleCountUsage: boolean;
  valueSelect?: number;
  data: Array<Types.SaleReportInformation.SaleReportInformation>;
  exportPdf?: boolean;
}

const Table: React.FC<Props> = ({
  visibleExpansionOfSale,
  visibleCountUsage,
  valueSelect,
  data,
  exportPdf,
}) => {
  return !valueSelect ? (
    <TableDevelopment
      visibleExpansionOfSale={visibleExpansionOfSale}
      visibleCountUsage={visibleCountUsage}
      data={data}
      exportPdf={exportPdf}
    />
  ) : (
    <TableMonthlyByUser
      visibleExpansionOfSale={visibleExpansionOfSale}
      visibleCountUsage={visibleCountUsage}
      data={data}
    />
  );
};

export default Table;
