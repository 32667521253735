import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, groupBy, isNaN, reduce, sortBy, union } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import * as Types from 'types';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { EXTENSIONS, FILE_LOCATION } from 'constant';
import { services } from 'services';
import {
  ADMIN_REPORT_STORAGE_MANAGEMENT,
  OFFICIAL_CURRICULUM_HIERARCHY_LIST,
  STORAGE_MANAGEMENT,
} from 'configs';
import { getMonthBetween } from 'libs';
import { browserLogger } from 'libs/logger';

export const getDataTableStorage = createAsyncThunk<
  Types.ReportsItemResponseType<Types.StorageManagement.TotalStorage>,
  Types.ReportsItemRequestType & {
    type: number;
    corporateUser?: string;
    extension?: string;
    start_date: Dayjs;
    end_date: Dayjs;
  },
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getDataTableStorage', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    dispatch(startLoading());
    let data = [] as unknown as Types.ReportsItemResponseType<Types.StorageManagement.ResponseType>;
    if (req.corporateUser !== 'partner') {
      const dataAdmin = await services.filter<Types.StorageManagement.ResponseType>(
        STORAGE_MANAGEMENT.name,
        req
      );
      data = dataAdmin.data;
      browserLogger.info('public/thunk/getDataOfficialPublish', STORAGE_MANAGEMENT.name, dataAdmin);
    }

    const dataPartner = await services.filterPartner<Types.StorageManagement.ResponseType>(
      ADMIN_REPORT_STORAGE_MANAGEMENT.name,
      {
        ...req,
      }
    );
    browserLogger.info(
      'UsageStatus/thunk/getDataTableStorage',
      ADMIN_REPORT_STORAGE_MANAGEMENT.name,
      dataPartner
    );
    const dateRanges = getMonthBetween(req.start_date, req.end_date).map((d) =>
      d.format('YYYY/MM')
    );

    const objectExtension = EXTENSIONS.reduce(
      (result, item) => ({ ...result, [item.label]: [] }),
      {}
    );

    const objectLocation = FILE_LOCATION.reduce((result, item) => ({ ...result, [item]: [] }), {});

    const groupByExtension = groupBy(
      sortBy(
        [...(data.report_results ?? []), ...dataPartner.data.report_results].filter(
          (e) => e.file_size
        ),
        (a) => a.createdAt
      ),

      ({ file_extension, file_location, company_id }) => {
        if (req.type === 1) {
          const extension = EXTENSIONS.find((e) => e.extension.includes(file_extension));

          if (extension) {
            return extension.label;
          } else {
            return 'その他';
          }
        }

        if (req.type === 2) {
          return file_location === '1'
            ? 'カリキュラムマスタ'
            : file_location === '2'
            ? '設問マスタ'
            : file_location === '3'
            ? 'スキルチェックマスタ'
            : file_location === '4'
            ? 'マニュアル'
            : file_location === '5'
            ? 'ナレッジ'
            : 'プロフィール';
        }
        if (req.corporateUser !== 'ALL' && req.type === 3) {
          return req.corporateUser === 'rstandard'
            ? 'RSTANDARD'
            : req.corporateUser === 'corporate_users'
            ? '法人ユーザー'
            : 'パートナー';
        }

        if (req.type === 3) {
          if (company_id !== '000000000' && company_id !== '000000001') {
            return '法人ユーザー';
          }
          if (company_id === '000000001') {
            return 'RSTANDARD';
          }
          return 'パートナー';
        }
      }
    );

    const sortedExtensionArray = [
      { file_extension: '画像' },
      { file_extension: '動画' },
      { file_extension: 'Excel' },
      { file_extension: 'Word' },
      { file_extension: 'PDF' },
      { file_extension: 'PowerPoint' },
      { file_extension: 'その他' },
    ];

    const totalByExtension = Object.keys(
      Object.assign(
        req.type === 1 && req.extension === 'ALL'
          ? objectExtension
          : req.type === 2
          ? objectLocation
          : {},
        groupByExtension
      )
    ).map((key) => {
      const groupBydate = groupBy(groupByExtension[key], (e) =>
        e.createdAt ? dayjs(e?.createdAt).format('YYYY/MM') : undefined
      );

      const dateKeys = Object.keys(groupBydate).filter((e) => e !== 'undefined');

      let sum = 0;

      const dates = union(dateKeys, dateRanges)
        .map((date) => new Date(date))
        .sort((a, b) => a.getTime() - b.getTime())
        .map((date) => dayjs(date).format('YYYY/MM'));

      const total = dates.map((date) => {
        const totalSum = (groupBydate[date] || [{ date: date }]).reduce(
          (result, item) => {
            const total = {
              date: date,
              total: result.total + (isNaN(Number(item.file_size)) ? 0 : Number(item.file_size)),
              ...(req.type === 1
                ? { file_extension: key }
                : req.type === 2
                ? { file_location: key }
                : { user_type: key }),
            };

            return total;
          },
          {
            date: '',
            total: 0,
            ...(req.type === 1
              ? { file_extension: '' }
              : req.type === 2
              ? { file_location: '' }
              : { user_type: '' }),
          }
        );
        sum += totalSum.total;

        return { ...totalSum, total: sum };
      });

      return {
        ...(req.type === 1
          ? { file_extension: key }
          : req.type === 2
          ? { file_location: key }
          : { user_type: key }),
        ...Object.fromEntries(total.map((item) => [item.date, item.total])),
      };
    });

    const dataSortedExtension = sortedExtensionArray.map((ex) => {
      const index = totalByExtension.findIndex((s) => {
        if ('file_extension' in s) {
          return ex.file_extension === s.file_extension;
        }
        return false;
      });
      if (index > -1) {
        return { ...ex, ...totalByExtension[index] };
      }

      return { file_extension: ex.file_extension };
    });

    const filterData =
      req.type === 1 && req.extension === 'ALL'
        ? dataSortedExtension
        : totalByExtension.filter((e) => {
            if (req.type === 1 && 'file_extension' in e) return e.file_extension !== 'undefined';
            if (req.type === 2 && 'file_location' in e) return e.file_location !== 'undefined';
            if (req.type === 3 && 'user_type' in e) return e.user_type !== 'undefined';
          });

    const groupByDate = groupBy(
      [...(data.report_results ?? []), ...dataPartner.data.report_results],
      ({ createdAt }) => (createdAt ? dayjs(createdAt).format('YYYY/MM') : undefined)
    );

    const dataAllBykey = reduce(
      filterData,
      (result, item) => {
        const obj = {};
        Object.keys(item).forEach((key) => {
          if (['file_extension', 'file_location', 'user_type'].includes(key)) {
            return;
          }
          const value = get(item, `${key}`, 0);
          Object.assign(obj, { [key]: get(result, `${key}`, 0) + (isNaN(value) ? 0 : value) });
        });
        return { ...result, ...obj };
      },
      {} as Types.AnyObject
    );

    // const dataAll = Object.keys(groupByDate)
    //   .filter((date) => date !== 'undefined')
    //   .map((key) => {
    //     return groupByDate[key].reduce(
    //       (result, item) => {
    //         return { ...result, total: result.total + (Number(item.file_size) || 0) };
    //       },
    //       {
    //         date: key,
    //         total: 0,
    //       }
    //     );
    //   });

    dispatch(stopLoading());
    return {
      ...data,
      report_results: [
        {
          ...dataAllBykey,
          ...(req.type === 1
            ? { file_extension: 'ストレージ料金' }
            : req.type === 2
            ? { file_location: 'ストレージ料金' }
            : { user_type: 'ストレージ料金' }),
        },
        {
          ...dataAllBykey,
          ...(req.type === 1
            ? { file_extension: 'ALL' }
            : req.type === 2
            ? { file_location: 'ALL' }
            : { user_type: 'ALL' }),
        },
        ...filterData,
      ],
    };
  } catch (error) {
    console.log(error);
    return rejectWithValue(error);
  }
});

export const getDataTableFile = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('storageManagement/thunk/getDataTableFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICIAL_CURRICULUM_HIERARCHY_LIST.name, req);
    browserLogger.info(
      'storageManagement/thunk/getDataTableFile',
      OFFICIAL_CURRICULUM_HIERARCHY_LIST.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectStorage = createAsyncThunk<
  Types.ReportsItemResponseType<Types.StorageManagement.SelectStorage>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getSelectStorage', async (req, { rejectWithValue, dispatch }) => {
  try {
    dispatch(startLoading());
    const { data } = await services.filter<Types.StorageManagement.ResponseType>(
      STORAGE_MANAGEMENT.name,
      req
    );
    browserLogger.info('UsageStatus/thunk/getSelectStorage', STORAGE_MANAGEMENT.name, data);
    const dataPartner = await services.filterPartner<Types.StorageManagement.ResponseType>(
      ADMIN_REPORT_STORAGE_MANAGEMENT.name,
      req
    );
    browserLogger.info(
      'UsageStatus/thunk/getSelectStorage',
      ADMIN_REPORT_STORAGE_MANAGEMENT.name,
      dataPartner
    );
    const groupByLocation = groupBy(
      [...data.report_results, ...dataPartner.data.report_results].filter(
        (e) => e.file_size && e.createdAt
      ),
      'file_location'
    );
    const groupByLocationNormalCompany = groupBy(
      [...data.report_results, ...dataPartner.data.report_results].filter(
        (e) => e.file_size && e.createdAt && !['000000000', '000000001'].includes(e.company_id)
      ),
      'file_location'
    );
    const dataByLocation = (dataConvert: typeof groupByLocation) =>
      Object.keys(dataConvert)
        .filter((val) => val !== 'undefined')
        .map((key) => {
          return dataConvert[key].reduce(
            (result, item) => {
              const extension = EXTENSIONS.find((e) => e.extension.includes(item.file_extension));
              return {
                location:
                  key === '1'
                    ? 'カリキュラムマスタ'
                    : key === '2'
                    ? '設問マスタ'
                    : key === '3'
                    ? 'スキルチェックマスタ'
                    : key === '4'
                    ? 'マニュアル'
                    : key === '5'
                    ? 'ナレッジ'
                    : 'プロフィール',
                image:
                  extension?.value === 'image'
                    ? Number(
                        result.image + (isNaN(Number(item.file_size)) ? 0 : Number(item.file_size))
                      )
                    : Number(result.image) || 0,
                video:
                  extension?.value === 'video'
                    ? Number(
                        result.video + (isNaN(Number(item.file_size)) ? 0 : Number(item.file_size))
                      )
                    : Number(result.video) || 0,
                excel:
                  extension?.value === 'excel'
                    ? Number(
                        result.excel + (isNaN(Number(item.file_size)) ? 0 : Number(item.file_size))
                      )
                    : Number(result.excel) || 0,
                word:
                  extension?.value === 'word'
                    ? Number(
                        result.word + (isNaN(Number(item.file_size)) ? 0 : Number(item.file_size))
                      )
                    : Number(result.word) || 0,
                pdf:
                  extension?.value === 'pdf'
                    ? Number(
                        result.pdf + (isNaN(Number(item.file_size)) ? 0 : Number(item.file_size))
                      )
                    : Number(result.pdf) || 0,
                power_point:
                  extension?.value === 'power-point'
                    ? Number(
                        result.power_point +
                          (isNaN(Number(item.file_size)) ? 0 : Number(item.file_size))
                      )
                    : Number(result.power_point || 0),
                other: !extension
                  ? Number(
                      result.other + (isNaN(Number(item.file_size)) ? 0 : Number(item.file_size))
                    )
                  : Number(result.other) || 0,
              };
            },
            {
              location: '',
              image: 0,
              video: 0,
              excel: 0,
              word: 0,
              pdf: 0,
              power_point: 0,
              other: 0,
            }
          );
        });

    const totalByLocation = dataByLocation(groupByLocation).map((e) => ({
      ...e,
      total: e.image + e.video + e.excel + e.word + e.pdf + e.power_point + e.other,
    }));
    const totalByLocationNormalCompany = dataByLocation(groupByLocationNormalCompany).map((e) => ({
      ...e,
      totalNormalCompany: e.image + e.video + e.excel + e.word + e.pdf + e.power_point + e.other,
    }));

    const total = totalByLocation.reduce(
      (result, item) => {
        return {
          location: result.location,
          image: Number(result.image + item.image),
          video: Number(result.video + item.video),
          excel: Number(result.excel + item.excel),
          word: Number(result.word + item.word),
          pdf: Number(result.pdf + item.pdf),
          power_point: Number(result.power_point + item.power_point),
          other: Number(result.other + item.other),
        };
      },
      {
        location: 'Total',
        image: 0,
        video: 0,
        excel: 0,
        word: 0,
        pdf: 0,
        power_point: 0,
        other: 0,
      }
    );

    const totalNormalCompany = totalByLocationNormalCompany.reduce(
      (result, item) => {
        return {
          location: result.location,
          image: Number(result.image + item.image),
          video: Number(result.video + item.video),
          excel: Number(result.excel + item.excel),
          word: Number(result.word + item.word),
          pdf: Number(result.pdf + item.pdf),
          power_point: Number(result.power_point + item.power_point),
          other: Number(result.other + item.other),
        };
      },
      {
        location: 'Total',
        image: 0,
        video: 0,
        excel: 0,
        word: 0,
        pdf: 0,
        power_point: 0,
        other: 0,
      }
    );

    const dataSelect = {
      filename: [...data.report_results, ...dataPartner.data.report_results].map((e) => e.filename),
      file_extension: [...data.report_results, ...dataPartner.data.report_results].map(
        (e) => e.file_extension
      ),
      file_location: [...data.report_results, ...dataPartner.data.report_results].map(
        (e) => e.file_location
      ),
      dataTableFile: [
        ...totalByLocation,
        {
          ...total,
          total:
            total.image +
            total.video +
            total.excel +
            total.word +
            total.pdf +
            total.power_point +
            total.other,
          totalNormalCompany:
            totalNormalCompany.image +
            totalNormalCompany.video +
            totalNormalCompany.excel +
            totalNormalCompany.word +
            totalNormalCompany.pdf +
            totalNormalCompany.power_point +
            totalNormalCompany.other,
        },
      ],
    };
    dispatch(stopLoading());
    return { ...data, report_results: [dataSelect] };
  } catch (error) {
    return rejectWithValue(error);
  }
});
