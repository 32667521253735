import './style.css';
import styled from 'styled-components';

interface Props {
  'data-height'?: string;
  'data-isexport'?: boolean;
  'data-columnindex'?: number;
  'data-item-move-copy-selected'?: boolean;
}

export const Wrapper = styled.div<Props>`
  .ant-popover-inner-content {
    width: auto !important;
  }
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .box-hover {
    position: absolute;
    right: 8px;
    .icon {
      color: #c4c4c4;
    }
  }
  .rst__node {
    user-select: none;
  }
  .rst_tree {
    width: 1184px;
  }

  .rst__nodeContent {
    width: 100%;
    height: 80%;
    cursor: pointer;
  }
`;
