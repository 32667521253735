import styled from 'styled-components';

export const Wrapper = styled.div`
  font-family: Lato;
  font-size: 14px;
  line-height: 11px;
  color: #424242;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;

  .text-static {
    font-size: 11px;
    padding-left: 3px;
    margin-top: 1px;
    line-height: 11px;
    display: flex;
    align-items: center;
  }

  .label {
    display: flex;
    align-items: center;
  }
`;
