import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { maxBy } from 'lodash';
import { Tabs } from 'antd';

import { createLinkQuestionAssignLevel, deleteQuestion } from 'containers/CreateEditQuestion/thunk';
import { getDataCurriculum, updateCurriculum } from 'pages/Curriculum/thunk';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import {
  removeNodeLevel4Selected,
  setFilterByCurriculum,
  setFilterByStatus,
} from 'pages/Curriculum/slice';
import UnrelatedQuestionSearch from './UnrelatedQuestionSearch';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { curriculumSelector } from 'pages/Curriculum/selectors';
import DeleteCompleted from 'components/Modal/DeleteCompleted';
import { authSelector } from 'containers/Auth/selectors';
import QuestionSearch from './QuestionSearch';
import { useAppDispatch } from 'hooks';
import StyledSearch from './styles';
import * as Types from 'types';

type Props = {
  pageYOffset: number;
  setOpenModalCreateQuestion: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      type: 'create' | 'edit';
      onSubmit?: () => void;
    }>
  >;
};

const { TabPane } = Tabs;

const SearchCurriculum: React.FC<Props> = ({ pageYOffset, setOpenModalCreateQuestion }) => {
  const [openDeleteQuestion, setOpenDeleteQuestion] = useState<boolean>(false);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const [openNotDelete, setOpenNotDelete] = useState<boolean>(false);
  const [tabKey, setTabKey] = useState<string>('1');
  const [selectedQuestion, setSelectedQuestion] = useState<Array<Types.Questions.ResponseType>>([]);

  const dispatch = useAppDispatch();

  const { unrelatedQuestionsFilled, questionSearchFilled, nodeLevel4Selected, conditions } =
    useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);

  const handleSelectTabKey = (activeKey: string) => {
    if (selectedQuestion.length) {
      setSelectedQuestion([]);
    }
    setTabKey(activeKey);
  };

  const handleDeleteQuestions = async () => {
    if (selectedQuestion.length) {
      dispatch(startLoading());

      await Promise.all(selectedQuestion.map((i) => dispatch(deleteQuestion({ id: i.i_id }))));
      await dispatch(
        getDataCurriculum({
          conditions: [
            {
              id: 'provider_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      await dispatch(
        setFilterByStatus({
          status: conditions?.status,
        })
      );
      dispatch(
        setFilterByCurriculum({
          name: conditions?.name,
        })
      );
      setOpenDeleteQuestion(false);
      setOpenNotDelete(false);

      await dispatch(stopLoading());
      setDeleteSuccess(true);
    }
  };

  const showModalCreateEditQuestion = async () => {
    setOpenModalCreateQuestion({
      visible: true,
      type: 'create',
    });
  };

  const handleLinkQuestion = async (type?: 'all') => {
    dispatch(startLoading());

    const questions =
      type === 'all'
        ? tabKey === '1'
          ? questionSearchFilled
          : unrelatedQuestionsFilled
        : selectedQuestion;

    const resultActions = await Promise.all(
      questions.map(
        (item, i) =>
          !nodeLevel4Selected?.children?.some(({ code }) => code === item.code) &&
          dispatch(
            createLinkQuestionAssignLevel({
              item: {
                provider_id: userInfo?.company_id,
                level4_code: nodeLevel4Selected?.code,
                code: item.code,
                sort_order:
                  Number(maxBy(nodeLevel4Selected?.children || [], 'sort_order')?.sort_order || 0) +
                  (selectedQuestion.length === 1 ? 1 : i + 1),
                createdat: new Date(),
                createdby: userInfo?.login_id,
              },
            })
          )
      )
    );

    await dispatch(
      updateCurriculum({
        id: nodeLevel4Selected?.curriculum_id!,
        data: {
          item: {
            probs_count:
              (nodeLevel4Selected?.problems_count || 0) +
              resultActions.filter((r) => r && createLinkQuestionAssignLevel.fulfilled.match(r))
                .length,
            updated_at: new Date().toDateString(),
            updatedat: new Date(),
          },
          return_item_result: true,
          is_force_update: true,
        },
      })
    );

    setSelectedQuestion([]);

    await Promise.all([
      dispatch(removeNodeLevel4Selected()),
      dispatch(
        getDataCurriculum({
          conditions: [
            {
              id: 'provider_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
    ]);

    dispatch(
      setFilterByCurriculum({
        name: conditions?.name,
      })
    );

    dispatch(stopLoading());
  };

  return (
    <StyledSearch tabKey={tabKey} pageYOffset={pageYOffset} screenHeight={window.innerHeight}>
      <Tabs type="card" onChange={handleSelectTabKey}>
        <TabPane tab="条件検索" key="1" className="card-tab-pane">
          <QuestionSearch
            showModalCreateEditQuestion={showModalCreateEditQuestion}
            setOpenDeleteQuestion={setOpenDeleteQuestion}
            setSelectedQuestion={setSelectedQuestion}
            handleLinkQuestion={handleLinkQuestion}
            setOpenNotDelete={setOpenNotDelete}
            selectedQuestion={selectedQuestion}
          />
        </TabPane>
        <TabPane tab="未設定設問一覧" key="2" className="card-tab-pane">
          <UnrelatedQuestionSearch
            showModalCreateEditQuestion={showModalCreateEditQuestion}
            setOpenDeleteQuestion={setOpenDeleteQuestion}
            setSelectedQuestion={setSelectedQuestion}
            handleLinkQuestion={handleLinkQuestion}
            setOpenNotDelete={setOpenNotDelete}
            selectedQuestion={selectedQuestion}
          />
        </TabPane>
      </Tabs>
      <ConfirmDeleteModal
        title="設問削除"
        subTitle="選択している設問を削除します。"
        description="削除を実行すると、復元できませんのでご注意ください。"
        setVisible={setOpenDeleteQuestion}
        visible={openDeleteQuestion}
        onSubmit={handleDeleteQuestions}
      />
      <ConfirmDeleteModal
        title="削除確認"
        subTitle="設問の削除は実行できません。"
        description="選択した設問はカリキュラムまたはスキルチェックに設定されているため、削除できません。"
        setVisible={setOpenNotDelete}
        visible={openNotDelete}
      />
      <DeleteCompleted visible={deleteSuccess} setVisible={setDeleteSuccess} />
    </StyledSearch>
  );
};

export default SearchCurriculum;
