import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const pdfGenerator = async (
  content: Array<{ node: HTMLElement; height: number }>,
  name: string
) => {
  if (!content.length) return;

  const pdf = new jsPDF('l', 'px', 'a4');

  for await (const contents of content.map(async (item, index) => {
    return await html2canvas(item.node, {
      useCORS: true,
      scale: 2.5,
      allowTaint: true,
      foreignObjectRendering: false,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      index && pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      if (index === content.length - 1) {
        pdf.save(`${name}.pdf`);
      }
    });
  })) {
  }

  // return await Promise.all(
  //   content.forEach((pageContent, index) => {
  //     html2canvas(pageContent.node, {
  //       useCORS: true,
  //       scale: 2.5,
  //       allowTaint: true,
  //       foreignObjectRendering: false,
  //     }).then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');
  //       const imgWidth = pdf.internal.pageSize.getWidth();
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //       index && pdf.addPage();
  //       pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

  //       if (index === content.length - 1) {
  //         pdf.save(`${name}.pdf`);
  //       }
  //     });
  //   })
  // );
};

export const asyncPDFGenerator = async (
  content: Array<{ node: HTMLElement; height: number }>,
  name: string
) => {
  if (content.length) {
    const pdf = new jsPDF('l', 'px', 'a4');
    const handleItemAsync = async (pageContent: any, index: number) => {
      return await html2canvas(pageContent.node, {
        useCORS: true,
        scale: 2.5,
        allowTaint: true,
        foreignObjectRendering: false,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        index && pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

        if (index === content.length - 1) {
          pdf.save(`${name}.pdf`);
        }
      });
    };

    const handleAsync = async (items: any) => {
      for (let index = 0; index < items.length; index++) {
        const item = items[index];
        await handleItemAsync(item, index);
      }
    };
    return await handleAsync(content);
  }
};

const exportPDF = (
  ref: React.RefObject<HTMLElement>,
  fileName?: string,
  orientation?: 'p' | 'l',
  allowTaint?: boolean
) => {
  if (!ref.current) return;

  const name = fileName || '所属マスタ.pdf';

  html2canvas(ref.current, {
    useCORS: true,
    scale: 2.5,
    allowTaint: !allowTaint,
    foreignObjectRendering: false,
  }).then(async (canvas) => {
    const contentDataURL = await canvas.toDataURL('image/png');
    const pdf = await new jsPDF(orientation === 'p' ? 'p' : 'l', 'px', 'a4');
    let position = 0;

    const imgWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    heightLeft -= pageHeight;
    pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight + 25);
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight + 25);
      heightLeft -= pageHeight;
    }
    pdf.save(name);
  });
};

export default exportPDF;

export const exportPDF2 = (
  ref: React.RefObject<HTMLElement>,
  fileName?: string,
  orientation?: 'p' | 'l',
  allowTaint?: boolean,
  callback?: () => void,
  isPlus = true
) => {
  if (!ref.current) return;

  const name = fileName || '所属マスタ.pdf';

  return html2canvas(ref.current, {
    useCORS: true,
    scale: 2.1,
    allowTaint: !allowTaint,
    foreignObjectRendering: false,
  }).then(async (canvas) => {
    const contentDataURL = await canvas.toDataURL('image/png');

    const pdf = await new jsPDF(orientation === 'p' ? 'p' : 'l', 'px', 'a4');
    let position = 0;
    const imgWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    const tolerance = 10;

    heightLeft -= pageHeight;
    const extraHeight = isPlus ? 25 : 0;
    pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight + extraHeight);
    while (heightLeft > tolerance) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight + extraHeight);
      pdf.setFillColor(255, 255, 255);
      pdf.rect(0, 0, imgWidth, 6, 'F');
      heightLeft -= pageHeight;
    }
    pdf.save(name);
    if (callback) callback();
  });
};

export const exportPDFFitOnePage = (
  ref: React.RefObject<HTMLElement>,
  fileName?: string,
  orientation?: 'p' | 'l',
  allowTaint?: boolean
) => {
  if (!ref.current) return;

  const name = fileName || '所属マスタ.pdf';

  html2canvas(ref.current, {
    useCORS: true,
    scale: 2.1,
    allowTaint: !allowTaint,
    foreignObjectRendering: false,
  }).then(async (canvas) => {
    const contentDataURL = await canvas.toDataURL('image/png');

    const pdf = await new jsPDF(orientation === 'p' ? 'p' : 'l', 'px', 'a4');
    pdf.setFontSize(12);
    pdf.setTextColor('#424242');
    const imgWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    // pdf.setFillColor('#f2f2f2');
    // pdf.rect(0, 145, imgWidth, pageHeight, 'F');
    const height = imgHeight > pageHeight ? pageHeight : imgHeight;

    const horizontalPos = imgWidth / 2;
    const verticalPos = pageHeight - 8;
    pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, height);
    let page = 1;
    pdf.text(`- ${page} -`, horizontalPos, verticalPos, { align: 'center' });
    pdf.save(name);
  });
};
