import React from 'react';
import { SelectOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Input, Radio, Row, Col, Tooltip, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { SectionStyled } from '../../Modal/CreateQuestion/styles';
import * as Types from 'types';

interface Props {
  type: 'create' | 'edit';
  imageAttach: (
    formikValues: Types.CreateEditQuestionFormik,
    problemKey: number
  ) => React.JSX.Element;
  renderProblem: (
    formikValues: Types.CreateEditQuestionFormik,
    problemKey: number
  ) => React.JSX.Element;
  data: Types.CreateEditQuestionFormik;
  imageComment: (
    formikValues: Types.CreateEditQuestionFormik,
    problemKey: number
  ) => React.JSX.Element;
  timeLimit: Types.TimeLimitType[];
}

const QuestionDetailPDF: React.FC<Props> = ({
  type,
  imageAttach,
  renderProblem,
  data,
  imageComment,
  timeLimit,
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #e5e5e5',
          padding: '10px 20px',
        }}
        className="header-pdf"
      >
        <span
          style={{
            fontSize: 18,
            color: '#424242',
          }}
        >
          設問マスタ
        </span>
      </div>
      <SectionStyled>
        <div className="wrapper">
          <Form colon={false} layout="vertical" className="form">
            <p className="label-note">
              <span className="number">1</span>設問内容
            </p>
            <Form.Item
              label={
                <span className="text-label">
                  設問名
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                className="input"
                type="text"
                showCount
                maxLength={30}
                placeholder="最大30文字"
                value={data.name}
              />
            </Form.Item>
            <Form.Item
              label={
                <span className="text-label">
                  設問内容
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <TextArea
                className="input-textarea"
                showCount
                maxLength={480}
                placeholder="最大480文字"
                value={data.description}
              />
            </Form.Item>
            <Form.Item className="form-input">
              <div className="wrap-flex">
                <p className="text-label">設問画像・動画 </p>
                <button className="btn_primary" type="button">
                  画像・動画を選ぶ
                </button>
                <p className="text-note">※画像・動画は設問の下に挿入されます</p>
              </div>
            </Form.Item>
            <div className="wrap-show-image">
              <div className="flex-image">
                <p className="text-label">設定状況：</p>
                {imageAttach}
              </div>
              <div className={`text-clear${data?.attach ? '' : '__disabled'}`}>クリア</div>
            </div>
            <div className="mr-30" />
            <p className="label-note">
              <span className="number">2</span>解答選択肢
            </p>
            <Form.Item
              label={
                <span className="text-label">
                  選択肢A
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <TextArea
                className="input-textarea-small"
                showCount
                maxLength={60}
                placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                value={data.problems1}
              />
            </Form.Item>
            <Form.Item
              label={
                <span className="text-label">
                  選択肢B
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <TextArea
                className="input-textarea-small"
                showCount
                maxLength={60}
                placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                value={data.problems2}
              />
            </Form.Item>
            <Form.Item
              label={
                <span className="text-label">
                  選択肢C
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <TextArea
                className="input-textarea-small"
                showCount
                maxLength={60}
                placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                value={data.problems3}
              />
            </Form.Item>
            <Form.Item name="problems" className="form-input">
              <div className="wrap-flex">
                <p className="text-label">解答選択肢画像・動画</p>
                <button className="btn_primary" type="button">
                  画像・動画を選ぶ
                </button>
                <p className="text-note">※画像・動画は設問の下に挿入されます</p>
              </div>
            </Form.Item>
            <div className="wrap-show-image">
              <div className="flex-image">
                <p className="text-label">設定状況：</p>
                {[1, 2, 3].map((problemKey) => (
                  <div key={problemKey}>{renderProblem(data, problemKey)}</div>
                ))}
              </div>
              <div
                className={`text-clear${
                  data.problems1_attach || data.problems2_attach || data.problems3_attach
                    ? ''
                    : '__disabled'
                }`}
              >
                クリア
              </div>
            </div>
            <Form.Item
              name="answer"
              label={
                <span className="text-label">
                  解答
                  <span className="require">*</span>
                </span>
              }
              className="form-input form-input-radio"
            >
              <Radio.Group className="radio_group" value={data.answer}>
                <Radio value="A" className="radio-text">
                  選択肢A
                </Radio>
                <Radio value="B" className="radio-text">
                  選択肢B
                </Radio>
                <Radio value="C" className="radio-text">
                  選択肢C
                </Radio>
              </Radio.Group>
            </Form.Item>
            <div className="mr-30" />
            <p className="label-note">
              <span className="number">3</span>解説・その他設定
            </p>
            <Form.Item
              label={
                <div className="wrap-label-form-input">
                  <span className="text-label">
                    解説
                    <span className="require">*</span>
                  </span>
                  <span className="text-enlarge">
                    <SelectOutlined className="icon" />
                    入力欄を拡大
                  </span>
                </div>
              }
              className="form-input"
            >
              <TextArea
                className="input-textarea"
                showCount
                maxLength={1050}
                placeholder="全角：最大1050文字"
                value={data.comment}
              />
            </Form.Item>
            <Form.Item name="comment_attach" className="form-input">
              <div className="wrap-flex">
                <p className="text-label">解説画像・動画</p>
                <button className="btn_primary" type="button">
                  画像・動画を選ぶ
                </button>
                <p className="text-note">※画像・動画は設問の下に挿入されます</p>
              </div>
            </Form.Item>
            <div className="wrap-show-image">
              <div className="flex-image">
                <p className="text-label">設定状況：</p>
                {imageComment}
              </div>
              <div className={`text-clear ${data.comment_attach ? '' : '__disabled'}`}>クリア</div>
            </div>
            <Form.Item
              label={
                <span className="text-label">
                  制限時間
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Select
                className="time_limit"
                value={(timeLimit || []).find((e) => e.option_id === data.time_limit)?.value || ''}
              ></Select>
            </Form.Item>
            <Row>
              <Col span={24}>
                <div className="form-input-radio">
                  <span className="text-label">
                    スコア設定（設問難易度の設定）
                    <span className="require">*</span>
                    <Tooltip
                      trigger={['click']}
                      overlayClassName="question-tooltip"
                      title={
                        <>
                          <h6
                            style={{
                              fontFamily: 'Noto Sans Javanese',
                              fontWeight: '400',
                              fontSize: 16,
                              color: '#2A2A2A',
                            }}
                          >
                            スコア設定（設問難易度の設定）について
                          </h6>
                          <p>
                            スコアは設問の難易度を設定する項目です。
                            <br />
                            設定の際は「１・２・３」の３段階からスコアを選択します。
                            <br />
                            <br />
                            スコアは設問の難易度を表すと共に、獲得スコアとして得点も表します。
                            <br />
                            スキルチェック実施の際に、正解した設問に設定されているスコアを獲得できます。
                            <br />
                            <br />
                            設定したスコアの情報や獲得したスコアの結果はレポートから確認することができます。
                            <br />
                            <br />
                            獲得したスコアから、スキルチェックの結果が難易度の高い設問を多く正解できているか、難易度の低い設問を多く
                            <br />
                            間違えているので、基礎ができていないか、ケアレスミスで間違えている等の分析ができる仕組みです。
                            <br />
                            <br />
                            正解した数や割合だけでなく、質をはかる指標です。
                            <br />
                            <br />
                            スキルチェック実施後は、ぜひスコアを活用してみてください。
                          </p>
                        </>
                      }
                    >
                      <QuestionCircleOutlined className="question-mark-icon" />
                    </Tooltip>
                  </span>
                  <Radio.Group className="radio_group" value={data.score}>
                    <Radio value={1} className="radio-text">
                      1
                    </Radio>
                    <Radio value={2} className="radio-text">
                      2
                    </Radio>
                    <Radio value={3} className="radio-text">
                      3
                    </Radio>
                  </Radio.Group>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </SectionStyled>
    </>
  );
};

export default QuestionDetailPDF;
