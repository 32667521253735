import { createSlice } from '@reduxjs/toolkit';
import { filter, orderBy, unionBy, groupBy, reduce } from 'lodash';
import * as Types from 'types';
import {
  getBillingDataDetailExport,
  getDataBillingManagement,
  getBillingDataDetail,
  getDataSkillCheckFeeDetail,
  getCompaniesUsageStatus,
  getSelectBillingDataDetail,
} from './thunk';

export type InitialState = {
  dataSelectProviderDate: Array<Types.BillingDataManagement.ResponseType>;
  dataBillingManagement: Array<Types.BillingDataManagement.ResponseType>;
  dataSelectBilling: Array<Types.BillingDataManagement.ResponseType>;
  dataSelectUserBilling: Array<Types.BillingDataDetail.ResponseType>;
  dataSelectUserBillingUniq: Array<Types.BillingDataDetail.ResponseType>;
  dataSelectDateDetail: Array<Types.BillingDataDetail.ResponseType>;
  dataBillingDetail: Array<Types.BillingDataDetail.ResponseType>;
  dataErr: Array<Types.BillingDataDetail.ResponseType>;
  dataSelectOrderID: Array<Types.BillingDataDetail.ResponseType>;
  dataSelectError: Array<Types.Selects.SelectError>;
  dataError: Array<string>;
  dataNumError: number;
  loading: boolean;
  total: number;
  dataSkillCheckFeeDetail: Array<Types.SkillCheckFeeDetails.ResponseType>;
  dataSalesInformationBreakdown: Array<Types.SalesInformationBreakDown.ResponseType>;
  dataHistoryOfUsing: Array<Types.HistoryOfUsing.ResponseType>;
  dataCompanyUsageStatus?: Types.Companies.ResponseType;
};

const initialState: InitialState = {
  dataSelectProviderDate: [],
  dataBillingManagement: [],
  dataSelectBilling: [],
  dataSelectDateDetail: [],
  dataSelectOrderID: [],
  dataSelectUserBilling: [],
  dataSelectUserBillingUniq: [],
  dataBillingDetail: [],
  dataErr: [],
  dataSelectError: [],
  dataError: [],
  loading: false,
  total: 0,
  dataNumError: 0,
  dataSkillCheckFeeDetail: [],
  dataSalesInformationBreakdown: [],
  dataHistoryOfUsing: [],
};

export const billingDataManagementSlide = createSlice({
  name: 'billingDataManagement-page',
  initialState,
  reducers: {
    filterErrorCode: (state, action) => {
      if (action.payload === 'fail') {
        state.dataBillingDetail = state.dataBillingDetail.filter((d) => d.error_code);
      } else {
        state.dataBillingDetail = state.dataBillingDetail.filter((d) => !d.error_code);
      }
    },
    resetInitialState: (state) => {
      state.dataSelectOrderID = [];
      state.dataSelectUserBilling = [];
      state.dataSelectUserBillingUniq = [];
      state.dataBillingDetail = [];
      state.dataSelectError = [];
      state.dataSelectDateDetail = [];
      state.dataErr = [];
    },
  },
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder.addCase(getBillingDataDetailExport.pending, startLoading);
    builder.addCase(getDataBillingManagement.pending, startLoading);
    builder.addCase(getBillingDataDetail.pending, startLoading);
    builder.addCase(getBillingDataDetailExport.fulfilled, stopLoading);

    builder.addCase(getDataBillingManagement.fulfilled, (state, action) => {
      state.dataBillingManagement = action.payload.report_results;

      if (!state.dataSelectBilling.length) {
        state.dataSelectBilling = orderBy(
          unionBy(action.payload.report_results, 'billing_id'),
          ['billing_id'],
          ['asc']
        );
      }

      if (!state.dataSelectProviderDate.length) {
        state.dataSelectProviderDate = orderBy(
          unionBy(action.payload.report_results, 'provided_date'),
          ['provided_date'],
          ['asc']
        );
      }

      const dataError = action.payload.report_results
        .filter((data) => data.num_of_errors > 0)
        .map((id) => id.billing_id);
      const dataNumError = action.payload.report_results
        .filter((data) => data.num_of_errors > 0)
        .reduce((prev, next) => prev + (Number(next.num_of_errors) || 0), 0);
      state.dataError = dataError;
      state.dataNumError = dataNumError;

      state.total = action.payload.totalItems;
      stopLoading(state);
    });

    builder.addCase(getSelectBillingDataDetail.fulfilled, (state, action) => {
      state.dataSelectDateDetail = orderBy(
        unionBy(
          filter(
            action.payload.report_results.filter((e) => e.error_code),
            (item) => item.provided_date !== ''
          ),
          'provided_date'
        ),
        ['provided_date'],
        ['asc']
      );
      state.dataSelectUserBilling = orderBy(action.payload.report_results, ['company_id'], ['asc']);
      state.dataSelectUserBillingUniq = orderBy(
        unionBy(action.payload.report_results, 'company_id'),
        ['company_id'],
        ['asc']
      );
      state.dataSelectOrderID = orderBy(
        unionBy(
          filter(action.payload.report_results, (item) => item.order_id !== ''),
          'order_id'
        ),
        ['order_id'],
        ['asc']
      );
      state.dataSelectError = orderBy(
        unionBy(
          filter(
            action.payload.report_results.filter((e) => e.error_code),
            (item) => item.error_code !== ''
          ),
          'error_code'
        ),
        ['error_code'],
        ['asc']
      );
      stopLoading(state);
    });

    builder.addCase(getBillingDataDetail.fulfilled, (state, action) => {
      state.dataBillingDetail = action.payload.report_results;
      state.dataErr = action.payload.report_results.filter((e) => e.error_code);
      stopLoading(state);
    });

    builder.addCase(getDataSkillCheckFeeDetail.fulfilled, (state, action) => {
      const array = groupBy(action.payload.report_results, (e) => e.skill_check_code);
      const resultAction = Object.keys(array).map((key) =>
        reduce(
          array[key],
          (result, item) => {
            return {
              ...result,
              company_id: item.company_id,
              skill_check_code: item.skill_check_code,
              skill_check_name: item.skill_check_name,
              skillcheck_tid: item.skillcheck_tid,
              skill_check_unit_price: item.skill_check_unit_price || 0,
              count_skill_check: array[key].length,
              skill_check_user_fee: (item.skill_check_unit_price || 0) * array[key].length,
            };
          },
          {
            company_id: '',
            count_skill_check: 0,
            skill_check_unit_price: 0,
            skill_check_user_fee: 0,
            skill_check_code: '',
            skill_check_name: '',
            skillcheck_tid: '',
          }
        )
      );
      state.dataSkillCheckFeeDetail = resultAction;
    });
    builder.addCase(getCompaniesUsageStatus.fulfilled, (state, action) => {
      state.dataCompanyUsageStatus = action.payload.items[0];
    });
    builder.addCase(getBillingDataDetailExport.rejected, stopLoading);
    builder.addCase(getDataBillingManagement.rejected, stopLoading);
    builder.addCase(getBillingDataDetail.rejected, stopLoading);
  },
});

export const { filterErrorCode, resetInitialState } = billingDataManagementSlide.actions;

export default billingDataManagementSlide.reducer;
