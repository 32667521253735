import React, { useEffect, useMemo, useState } from 'react';
import { DualAxes, G2 } from '@ant-design/plots';
import { Select } from 'antd';

import Styled from './styles';
import * as Types from 'types';
import { AnyObject } from 'types';
import { uniq } from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { reportUserInformationSelector } from 'pages/Report/UserInformation/selectors';
import { EXPORT_TARGET_MONTH_COUNT } from 'constant';
import { formatComma } from 'libs/utils/format';
import { useAppDispatch } from 'hooks';
import { setChartBusinessType } from 'pages/Report/UserInformation/slice';

const { Option } = Select;

interface Props {
  valueSelect: number;
  exportPdf?: boolean;
}

const COMPANY_TYPE: AnyObject = {
  companies: {
    key: 'companies',
    label: '一般企業',
    title: '一般企業',
    color: '#4365BD',
  },
  corporateUsers: {
    key: 'corporateUsers',
    label: '法人ユーザー',
    title: '法人ユー',
    color: '#4365BD',
  },
  personalUser: {
    key: 'personalUser',
    label: '個人ユーザー',
    title: '個人ユー',
    color: '#E5C82D',
  },
  partners: {
    key: 'partners',
    label: 'パートナーユーザー',
    title: 'パートナー',
    color: '#F0995A',
  },
};

const GraphExpansion = ({ valueSelect, exportPdf }: Props) => {
  const G = G2.getEngine('canvas');
  const [type, setType] = useState(COMPANY_TYPE.companies.key);
  const {
    chartBusinessType,
    dateRanger,
    companyReports,
    calculatedCompanyReports,
    calculatedIndividualUsers,
    calculatedCorporateUsers,
    calculatedPartnerReports,
  } = useSelector(reportUserInformationSelector);

  const dispatch = useAppDispatch();

  const companyType = useMemo(() => {
    if (valueSelect === 0) {
      setType(COMPANY_TYPE.companies.key);
      return [COMPANY_TYPE.companies, COMPANY_TYPE.partners];
    }
    if (valueSelect === 1) {
      setType(COMPANY_TYPE.corporateUsers.key);
      return [COMPANY_TYPE.corporateUsers, COMPANY_TYPE.personalUser, COMPANY_TYPE.partners];
    }
    return [];
  }, [valueSelect]);

  useEffect(() => {
    if (exportPdf) {
      setType(chartBusinessType || COMPANY_TYPE.companies.key);
    }
  }, [exportPdf, chartBusinessType]);

  useEffect(() => {
    if (exportPdf) {
      dispatch(setChartBusinessType(companyType[0]?.key || COMPANY_TYPE.companies.key));
    }
  }, [dispatch, exportPdf, companyType]);

  const dates = useMemo(() => {
    let dateData =
      dateRanger && dateRanger.length
        ? dateRanger
        : uniq(companyReports.map((obj) => obj.target_month));
    if (exportPdf) {
      dateData = dateData.slice(0, EXPORT_TARGET_MONTH_COUNT);
    }
    return dateData;
  }, [companyReports, dateRanger, exportPdf]);

  const dataColumn = useMemo(() => {
    let res: AnyObject[] = [];
    const getResponse = (calculatedData: Types.UserReportInformation.CalculatedReport) => {
      const empAdded = calculatedData.emp_user_added || {};
      const empDeleted = calculatedData.emp_user_deleted || {};
      const monthEndUser = calculatedData.month_end_users || {};
      dates.forEach((date) => {
        res = [
          ...res,
          {
            time: dayjs(date, 'YYYYMM').format('YYYY/MM'),
            type: '新規登録企業数',
            value: empAdded[date] || 0,
          },
          {
            time: dayjs(date, 'YYYYMM').format('YYYY/MM'),
            type: '退会企業数',
            value: empDeleted[date] || 0,
          },
          {
            time: dayjs(date, 'YYYYMM').format('YYYY/MM'),
            type: '月末登録企業数',
            value: monthEndUser[date] || 0,
          },
        ];
      });
      return res;
    };
    if (type === COMPANY_TYPE.companies.key && calculatedCompanyReports) {
      return getResponse(calculatedCompanyReports);
    }
    if (type === COMPANY_TYPE.corporateUsers.key && calculatedCorporateUsers) {
      return getResponse(calculatedCorporateUsers);
    }
    if (type === COMPANY_TYPE.personalUser.key && calculatedIndividualUsers) {
      return getResponse(calculatedIndividualUsers);
    }
    if (type === COMPANY_TYPE.partners.key && calculatedPartnerReports) {
      return getResponse(calculatedPartnerReports);
    }
    return [];
  }, [
    type,
    calculatedCompanyReports,
    calculatedPartnerReports,
    calculatedCorporateUsers,
    calculatedIndividualUsers,
    dates,
  ]);

  const lineData = useMemo(() => {
    let res: AnyObject[] = [];
    const getResponse = (calculatedData: Types.UserReportInformation.CalculatedReport) => {
      const churnRate = calculatedData.churn_rate || {};
      dates.forEach((date) => {
        res = [
          ...res,
          {
            time: dayjs(date, 'YYYYMM').format('YYYY/MM'),
            name: 'Churn Rate',
            count: churnRate[date] || 0,
          },
        ];
      });
      return res;
    };
    if (type === COMPANY_TYPE.companies.key && calculatedCompanyReports) {
      return getResponse(calculatedCompanyReports);
    }
    if (type === COMPANY_TYPE.corporateUsers.key && calculatedCorporateUsers) {
      return getResponse(calculatedCorporateUsers);
    }
    if (type === COMPANY_TYPE.personalUser.key && calculatedIndividualUsers) {
      return getResponse(calculatedIndividualUsers);
    }
    if (type === COMPANY_TYPE.partners.key && calculatedPartnerReports) {
      return getResponse(calculatedPartnerReports);
    }
    return [];
  }, [
    type,
    calculatedCompanyReports,
    calculatedCorporateUsers,
    calculatedIndividualUsers,
    calculatedPartnerReports,
    dates,
  ]);

  const config = {
    isGroup: true,
    data: [dataColumn, lineData],
    xField: 'time',
    yField: ['value', 'count'],
    appendPadding: [40, 0, 0, 0],
    meta: {
      count: {
        formatter: (v: number) => {
          return `${Number((v || 0).toFixed(1))}%`;
        },
      },
    },
  };

  return (
    <Styled>
      <div className="user-info">
        <div className="body-info">
          <div className="right-side">
            <div className="wrap-header">
              <div className="title">登録企業数推移</div>
              <div className="wrap-action">
                <div className="wrap-select">
                  <label>企業種類 : </label>
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    onChange={(v) => {
                      setType(v);
                      dispatch(setChartBusinessType(v));
                    }}
                    value={type}
                  >
                    {companyType.map((item) => (
                      <Option key={item.key} value={item.key}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter purple">
                      <div className="circle" />
                      <div className="line" />
                    </div>
                    <span className="legend-name">Churn Rate</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter blue" />
                    <span className="legend-name">新規登録企業数</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter yellow" />
                    <span className="legend-name">退会企業数</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter green" />
                    <span className="legend-name">月末登録企業数</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="chart">
              <span className="unit">単位：社</span>
              <DualAxes
                {...config}
                yAxis={{
                  grid: {
                    line: {
                      style: {
                        lineDash: [2, 2],
                        stroke: '#ddd',
                      },
                    },
                  },
                }}
                xAxis={{
                  tickLine: null,
                }}
                label={{
                  position: 'top',
                  formatter: (v) => formatComma(v.value),
                }}
                legend={false}
                height={550}
                geometryOptions={[
                  {
                    geometry: 'column',
                    isGroup: true,
                    seriesField: 'type',
                    columnWidthRatio: 0.4,
                    marginRatio: 0.2,
                    color: ['#08A3A5', '#F6AC00', '#C3DC7B'],
                    label: {
                      content: (v) => {
                        const group = new G.Group({});
                        group.addShape({
                          type: 'text',
                          attrs: {
                            x: 0,
                            y: -10,
                            text: formatComma(v.value),
                            textAlign: 'left',
                            textBaseline: 'top',
                            fill:
                              v?.type === '新規登録企業数'
                                ? '#08A3A5'
                                : v?.type === '退会企業数'
                                ? '#F6AC00'
                                : '#C3DC7B',
                          },
                        });
                        return group;
                      },
                      position: 'top',
                      formatter: (v) => formatComma(v.value),
                    },
                  },
                  {
                    geometry: 'line',
                    seriesField: 'name',
                    lineStyle: {
                      lineWidth: 2,
                    },
                    isStack: true,
                    point: true,
                    color: '#669CCE',
                    label: {
                      style: {
                        fill: '#669CCE',
                      },
                      position: 'top',
                    },
                  },
                ]}
                scrollbar={{
                  type: 'horizontal',
                  width: 300,
                  categorySize: 70,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default GraphExpansion;
