import React, { useMemo } from 'react';
import { useDragLayer } from 'react-dnd';

import { IconCopy, IconMove, ArrowMove } from 'assets';
import { Wrapper } from './styles';

type Props = {
  nodeHeight: number;
};

export const CustomDragLayer: React.FC<Props> = ({ nodeHeight }) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const { angle, cx, cy, length } = useMemo(() => {
    if (!initialOffset || !currentOffset || !item?.height) {
      return {
        length: 0,
        cx: 0,
        cy: 0,
        angle: 0,
      };
    }
    const l =
      Math.sqrt(
        (currentOffset.x - initialOffset.x) * (currentOffset.x - initialOffset.x) +
          (currentOffset.y - initialOffset.y) * (currentOffset.y - initialOffset.y)
      ) - item.height;
    const x = (initialOffset.x + currentOffset.x) / 2 - l / 2;
    const y = (initialOffset.y + currentOffset.y) / 2;

    const a =
      Math.atan2(initialOffset.y - currentOffset.y, initialOffset.x - currentOffset.x) *
      (180 / Math.PI);
    return {
      length: l,
      cx: x,
      cy: y,
      angle: a,
    };
  }, [currentOffset, initialOffset, item?.height]);

  if (!isDragging || !itemType || !item.node) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          width: item.width,
          transform: `translate(${currentOffset?.x || 0}px, ${currentOffset?.y || 0}px)`,
        }}
      >
        <Wrapper style={{ height: nodeHeight }} className="rst__node">
          <div className="rst__nodeContent">
            <div className="rst__lineBlock">
              <div className="rowWrapper">
                <div className="row moving">
                  <div className="rowContents">
                    <span
                      className="icon-dragging title"
                      style={
                        item.node.columnIndex === 5
                          ? {
                              color: '#159193',
                            }
                          : {
                              fontSize: 11,
                            }
                      }
                    >
                      {item.node.node.name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          left: cx + item.width / 2,
          top: cy + item.height / 2,
          width: length,
          transform: `rotate(${angle}deg)`,
        }}
      >
        {Array.from({
          length: Math.floor(length / 15),
        }).map((_, i) => (
          <img
            key={i}
            src={ArrowMove}
            style={{
              width: 15,
              transform: 'rotate(60deg)',
            }}
            alt="arrow-icon"
          />
        ))}
      </div>
    </div>
  );
};

export const MemorizeCustomDragLayer = React.memo(CustomDragLayer);
