import React, { useEffect, useState } from 'react';
import { Button, Table, Select as SelectAntd } from 'antd';
import { Form, Select, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import { some, unionBy } from 'lodash';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import {
  CloudDownloadOutlined,
  CaretLeftOutlined,
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { Modal, PagingNumber, PerPageSelect, SelectField } from 'components';
import { HEADER_ARCHIVE_DATA_CSV } from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import DeleteCompleted from 'components/Modal/DeleteCompleted';
import { filterCreatedBy } from 'pages/PublicManagement/slice';
import { authSelector } from 'containers/Auth/selectors';
import { publicManagerSelector } from '../../selectors';
import DisabledActionModal from '../DisableAction';
import Completed from 'components/Modal/Completed';
import PDFArchiveList from './PDFExportDocument';
import { useAppDispatch, usePermission } from 'hooks';
import { StopPublic } from 'assets';
import * as Types from 'types';
import Styled from './styles';
import {
  deleteOfficialCurriculumMaster,
  getArchiveList,
  getArchiveSelect,
  getDataPublishManagement,
  updateOfficialCurriculumMaster,
} from '../../thunk';
import ActionErrorModal from 'components/Modal/ActionError';
import { SELECT_RECORD } from 'constant/select.constants';
import { exportCsv } from 'libs/utils/exportCsv';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const { Option } = Select;

const ArchiveList: React.FC<Props> = ({ visible, setVisible }) => {
  const [itemUpdate, setItemUpdate] =
    useState<Types.OfficialCurriculumPublishingSettings.ResponseType>();
  const [visibleDeleteComplete, setVisibleDeleteComplete] = useState<boolean>(false);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [visibleReturn, setVisibleReturn] = useState<boolean>(false);
  const [visibleExport, setVisibleExport] = useState<boolean>(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<Types.ArchiveListSearchFormik>({
    official_curriculum_code: '',
    name: '',
    publish: '',
    createdby: '',
  });
  const [idDelete, setIdDelete] = useState<string>('');

  const [selectedRow, setSelectedRow] = useState<
    Types.OfficialCurriculumPublishingSettings.ResponseType[]
  >([]);

  const [completed, setCompleted] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(100);

  const [page, setPage] = useState<number>(1);

  const { permissionNumber } = usePermission();

  const { archiveList, archiveSelect } = useSelector(publicManagerSelector);
  const { userInfo } = useSelector(authSelector);

  const formik = useFormik<Types.ArchiveListSearchFormik>({
    initialValues: {
      official_curriculum_code: '',
      name: '',
      publish: '',
      createdby: '',
    },
    onSubmit: async (values) => {
      setFormValue(values);
      if (userInfo) {
        dispatch(startLoading());

        const conditions: Types.ConditionsType[] = [];
        Object.keys(values).forEach((key) => {
          if (values[key as keyof typeof values] && key !== 'createdby') {
            conditions.push({
              id: key,
              search_value: [values[key as keyof typeof values]],
            });
          }
        });
        await getArchives(conditions);
        if (values.createdby) {
          dispatch(filterCreatedBy({ type: 'archive', value: values.createdby }));
        }
        setPage(1);
        dispatch(stopLoading());
      }
    },
    onReset: () => {
      getArchives();
      setPage(1);
    },
  });
  const dispatch = useAppDispatch();

  const getArchives = async (conditions?: Types.ConditionsType[]) => {
    await dispatch(
      getArchiveList({
        conditions: [
          {
            id: 'archive_flag',
            search_value: ['1'],
            exact_match: true,
          },
          ...(conditions || []),
        ],
        page: 1,
        per_page: 0,
        omit_total_items: false,
      })
    );
  };

  const handleFetchData = async () => {
    if (!userInfo) {
      return;
    }

    await Promise.all([
      getArchives(),
      dispatch(
        getDataPublishManagement({
          conditions: [
            {
              id: 'archive_flg',
              search_value: ['0'],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
          omit_total_items: false,
          include_item_ref: true,
        })
      ),
    ]);
    setVisibleReturn(false);
  };

  const handleUpdateArchive = async () => {
    if (!userInfo) return;

    if (selectedRow.length) {
      const actionResult = await Promise.all(
        selectedRow.map((e) =>
          dispatch(
            updateOfficialCurriculumMaster({
              id: e.i_id_curriculum_master,
              data: {
                item: {
                  archive_flag: 0,
                },
                is_force_update: true,
                realtime_auto_link: true,
              },
            })
          )
        )
      );

      if (some(actionResult, (action) => updateOfficialCurriculumMaster.fulfilled.match(action))) {
        await handleFetchData();
      }
      setSelectedRow([]);
    }
    if (itemUpdate) {
      const updateResult = await dispatch(
        updateOfficialCurriculumMaster({
          id: itemUpdate.i_id_curriculum_master,
          data: {
            item: {
              archive_flag: 0,
            },
            is_force_update: true,
            realtime_auto_link: true,
          },
        })
      );
      if (updateOfficialCurriculumMaster.fulfilled.match(updateResult)) {
        await handleFetchData();
      }
    }

    setCompleted(true);
  };

  const handleToggleModal = () => {
    formik.resetForm();
    setVisible(false);
  };

  const handleDelete = async () => {
    if (!userInfo) {
      return;
    }

    if (idDelete) {
      const resultRemove = await dispatch(
        deleteOfficialCurriculumMaster({
          id: idDelete,
        })
      );
      if (deleteOfficialCurriculumMaster.fulfilled.match(resultRemove)) {
        await dispatch(
          getArchiveList({
            conditions: [
              {
                id: 'archive_flag',
                search_value: ['1'],
                exact_match: true,
              },
            ],
            sort_fields: [
              {
                id: 'official_curriculum_code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
            include_item_ref: true,
            include_lookups: true,
          })
        );
        setVisibleDeleteComplete(true);
      }
    }

    if (selectedRow.length) {
      const actionResult = await Promise.all(
        selectedRow.map((e) =>
          dispatch(
            deleteOfficialCurriculumMaster({
              id: e.i_id_curriculum_master,
            })
          )
        )
      );
      if (some(actionResult, (action) => deleteOfficialCurriculumMaster.fulfilled.match(action))) {
        await dispatch(
          getArchiveList({
            conditions: [
              {
                id: 'archive_flag',
                search_value: ['1'],
                exact_match: true,
              },
            ],
            sort_fields: [
              {
                id: 'official_curriculum_code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
            include_item_ref: true,
            include_lookups: true,
          })
        );
        setVisibleDeleteComplete(true);
      }
      setSelectedRow([]);
    }

    setVisibleDelete(false);
  };

  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  const columns: ColumnsType<Types.OfficialCurriculumPublishingSettings.ResponseType> = [
    {
      title: 'ID',
      dataIndex: 'official_curriculum_code',
      key: 'official_curriculum_code',
      width: '10%',
    },
    {
      title: 'OFFICIALカリキュラム名',
      dataIndex: 'name',
      key: 'name',
      width: '27%',
    },
    {
      title: '公開ステータス',
      dataIndex: 'public',
      key: 'public',
      align: 'center',
      render: (record: string) => (
        <div className="wrap-status">
          <img src={StopPublic} alt="" />
          <span className="status">公開停止中</span>
        </div>
      ),
    },
    {
      title: '前回公開日',
      dataIndex: 'publish_start_date',
      key: 'publish_start_date',
      render: (_, item) => dayjs(item.publish_start_date).format('YYYY/MM/DD'),
    },
    {
      title: '最終更新日',
      dataIndex: 'updatedat',
      key: 'updatedat',
      render: (_, item) => dayjs(item.updatedat).format('YYYY/MM/DD'),
    },
    {
      title: '作成者',
      dataIndex: 'creator',
      key: 'creator',
      align: 'center',
    },
    {
      title: '公開管理に戻す',
      dataIndex: '',
      key: 'action',
      width: '10%',
      align: 'center',
      render: (_, item) => (
        <Button
          disabled={permissionNumber !== 2}
          className={`button-return ${permissionNumber !== 2 ? 'btn-disabled' : ''}`}
          onClick={() => {
            console.log('items:  ', item);
            setItemUpdate(item);
            setVisibleReturn(true);
          }}
        >
          <CaretLeftOutlined
            style={{
              color: '#ffffff',
            }}
          />
          <span>戻す</span>
        </Button>
      ),
    },
    {
      title: '削除',
      dataIndex: '',
      key: 'delete',
      width: '5%',
      align: 'center',
      render: (_: string, record) => (
        <DeleteOutlined
          disabled={permissionNumber !== 2}
          style={{
            fontSize: 20,
            color: '#c4c4c4',
          }}
          onClick={() => {
            if (permissionNumber === 2) {
              setIdDelete(record.i_id_curriculum_master || '');
              setVisibleDelete(true);
            }
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    if (visible && userInfo) {
      Promise.all([
        getArchives(),
        dispatch(
          getArchiveSelect({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'archive_flag',
                search_value: ['1'],
                exact_match: true,
              },
            ],
            sort_fields: [
              {
                id: 'official_curriculum_code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
            include_item_ref: true,
            include_lookups: true,
          })
        ),
      ]);
    }
  }, [visible, dispatch]);

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const listCsv = selectedRow.map((item) => ({
        id: item.official_curriculum_code,
        official_curriculum_name: item.name,
        publish: '公開停止中',
        publish_start_date: item.publish_start_date
          ? `${dayjs(item.publish_start_date).format('YYYY/MM/DD hh:mm')}`
          : '',
        updatedat: item.updatedat
          ? `${dayjs(item.updatedat).format('YYYY/MM/DD hh:mm')}`
          : `${dayjs(item['updated_at']).format('YYYY/MM/DD hh:mm')}`,
        createdby: item.creator || '',
      }));
      // const csvString = [
      //   HEADER_ARCHIVE_DATA_CSV.map(({ label }) => label),
      //   ...listCsv.map((item) => Object.values(item).map((v) => `"${v}"`)),
      // ]
      //   .map((e) => e.join(','))
      //   .join('\n');
      // const bom = '\uFEFF';
      // const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      // saveAs(file, 'OFFICIALカリキュラム公開管理_アーカイブリスト.csv');
      exportCsv(
        listCsv,
        HEADER_ARCHIVE_DATA_CSV,
        'OFFICIALカリキュラム公開管理_アーカイブリスト.csv'
      );
    } else {
      const blob = await pdf(
        <PDFArchiveList formValue={formValue} dataSource={selectedRow} />
      ).toBlob();
      saveAs(blob, 'OFFICIALカリキュラム公開管理_アーカイブリスト.pdf');
    }
    setVisibleExport(false);
    dispatch(stopLoading());
  };

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerPage(archiveList.length);
    }
  }, [archiveList]);

  return (
    <Modal
      title="アーカイブリスト"
      visible={visible}
      width={1500}
      onCancel={handleToggleModal}
      headerStyle={{
        borderBottom: '1px solid #EAEAEA',
      }}
      bodyStyle={{
        padding: 0,
      }}
    >
      <Styled isEmptyData={!archiveList.length}>
        <div className="container">
          <p className="description">アーカイブしたOFFICIALカリキュラムの管理を行う画面です。</p>
          <div className="line" />
          <FormikProvider value={formik}>
            <Form layout="vertical">
              <div className="form-search">
                <Form.Item
                  name="official_curriculum_code"
                  className="item"
                  label="OFFICIALカリキュラムID"
                >
                  <SelectField
                    name="official_curriculum_code"
                    placeholder="指定なし"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children)
                        ?.toLowerCase()
                        ?.indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {archiveSelect?.map(({ official_curriculum_code }) => (
                      <Option value={official_curriculum_code} key={official_curriculum_code}>
                        {official_curriculum_code}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item name="name" className="item" label="OFFICIALカリキュラム ">
                  <SelectField
                    name="name"
                    placeholder="指定なし"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children)
                        ?.toLowerCase()
                        ?.indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {archiveSelect?.map(({ name }) => (
                      <Option value={name} key={name}>
                        {name}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item name="createdby" className="item" label="作成者">
                  <SelectField
                    name="createdby"
                    placeholder="指定なし"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children)
                        ?.toLowerCase()
                        ?.indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {unionBy(archiveSelect, 'creator').map(
                      ({ creator }) =>
                        creator && (
                          <Option value={creator} key={creator}>
                            {creator}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <div className="button-search" style={{ marginLeft: 'auto' }}>
                  <SubmitButton className="btn-search" loading={false}>
                    <SearchOutlined className="icon-search" />
                    検索
                  </SubmitButton>
                  <span className="label-reset" onClick={() => formik.resetForm()}>
                    リセット
                  </span>
                </div>
              </div>
            </Form>
            <div className="wrap-table">
              <div
                className="wrap-button"
                style={
                  !archiveList.length
                    ? {
                        position: 'relative',
                        textAlign: 'right',
                        right: '-41px',
                      }
                    : undefined
                }
              >
                <Button
                  className="btn btn-active"
                  icon={<CloudDownloadOutlined className="icon" />}
                  onClick={() => {
                    if (selectedRow.length) {
                      setVisibleExport(true);
                    } else {
                      setVisiblePopupError(true);
                    }
                  }}
                >
                  エクスポート
                </Button>
              </div>
              <Table
                key="index"
                rowKey="i_id"
                className="table"
                dataSource={archiveList.map((item, index) => ({ ...item, index }))}
                columns={columns}
                pagination={{
                  onChange: setPage,
                  pageSize: perPage,
                  total: archiveList.length,
                  showTotal: () => (
                    <div className="text-count">
                      <PagingNumber
                        startItem={archiveList.length ? `${(page - 1) * perPage + 1}` : ''}
                        endItem={
                          page * perPage > archiveList.length ? archiveList.length : page * perPage
                        }
                        totalItem={archiveList.length}
                      />
                      <PerPageSelect
                        data={archiveList}
                        perPage={perPage}
                        setPage={setPage}
                        setPerPage={setPerPage}
                      />
                    </div>
                  ),
                  showSizeChanger: false,
                  position: ['topCenter'],
                }}
                scroll={{
                  y: '35vh',
                }}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: selectedRow.map(({ i_id }) => i_id),
                  onChange: (_, selectedRows) => {
                    setSelectedRow(selectedRows);
                  },
                }}
              />
            </div>
          </FormikProvider>
        </div>
        <div className="wrap-bottom">
          <div className="text-label">
            選択したOFFICIALカリキュラムを処理：
            <div className="wrap-bottom-button">
              <Button
                disabled={permissionNumber !== 2}
                className={`btn btn-outline btn-filled ${
                  permissionNumber !== 2 ? 'btn-disabled' : ''
                }`}
                onClick={() => (selectedRow.length ? setVisibleReturn(true) : setDisabled(true))}
              >
                公開管理に戻す
              </Button>
              <Button
                disabled={permissionNumber !== 2}
                className={`btn btn-outline ${permissionNumber !== 2 ? 'btn-disabled' : ''}`}
                onClick={() => (selectedRow.length ? setVisibleDelete(true) : setDisabled(true))}
              >
                削除
              </Button>
            </div>
          </div>
          <Button className="btn-cancle" onClick={() => handleToggleModal()}>
            閉じる
          </Button>
        </div>
        <ConfirmDeleteModal
          itemDeleted
          title="OFFICIALカリキュラム削除"
          subTitle="選択したOFFICIALカリキュラムを削除します"
          description={
            <span style={{ color: '#777777' }}>
              ※削除を実行すると、利用中のユーザーのカリキュラムマスタ・設問マスタも削除されます。
              <br />
              事前にリリースノートにて告知後に実行してください。
            </span>
          }
          visible={visibleDelete}
          spaceCustom
          setVisible={setVisibleDelete}
          onCancel={() => {
            setVisibleDelete(false);
            setIdDelete('');
          }}
          onSubmit={() => handleDelete()}
        />
        <ConfirmDeleteModal
          itemDeleted
          title="公開管理に戻す"
          subTitle="選択したOFFICIALカリキュラムを公開管理に戻します。"
          visible={visibleReturn}
          setVisible={setVisibleReturn}
          onCancel={() => setVisibleReturn(false)}
          onSubmit={handleUpdateArchive}
        />
        <Completed
          title="公開管理に戻しました。"
          visible={completed}
          setVisible={setCompleted}
          onSubmit={() => setCompleted(false)}
        />
        <PopupConfirmExportFile
          visible={visibleExport}
          setVisible={setVisibleExport}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="請求データが選択されていません"
          description={
            <>
              エクスポートを実行する請求データを選択して、
              <br />
              再度実行してください。
            </>
          }
        />
        <DeleteCompleted visible={visibleDeleteComplete} setVisible={setVisibleDeleteComplete} />
        <DisabledActionModal open={disabled} setOpen={setDisabled} />
      </Styled>
    </Modal>
  );
};

export default ArchiveList;
