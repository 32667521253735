import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ATTACH,
  DISPLAY_ID_KEY,
  PARTNER_DETAIL,
  PARTNER_MANAGEMENT,
  PROVIDER_INFORMATION,
  SELECT_COMPANY,
} from 'configs';
import { browserLogger } from 'libs/logger';
import { services } from 'services';
import * as Types from 'types';

export const getPartnerManagement = createAsyncThunk<
  Types.ReportsItemResponseType<Types.DataPartnerManagementType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/getManagement', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.DataPartnerManagementType>(
      PARTNER_MANAGEMENT.name,
      req
    );
    browserLogger.info('PartnerManagement/thunk/getManagement', PARTNER_MANAGEMENT.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPartnerDetail = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/getPartnerDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(PARTNER_DETAIL.name, req);
    browserLogger.info('PartnerManagement/thunk/getPartnerDetail', PARTNER_DETAIL.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectCompany = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/getSelectCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_COMPANY.name, req);
    browserLogger.info('PartnerManagement/thunk/getSelectCompany', SELECT_COMPANY.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getProviderInformation = createAsyncThunk<
  Types.GetItemResponseType<Types.InformationType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/getProviderInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.InformationType>(PROVIDER_INFORMATION.name, req);
    browserLogger.info(
      'PartnerManagement/thunk/getProviderInformation',
      PROVIDER_INFORMATION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateProviderInformation = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.DataPartnerManagementType>,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/UpdateProviderInformationDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(PROVIDER_INFORMATION.name, req);
    browserLogger.info(
      'PartnerManagement/thunk/UpdateProviderInformationDetail',
      PROVIDER_INFORMATION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadFileProvider = createAsyncThunk<
  Types.UploadFileToS3ResponseType,
  Types.UploadFileToS3RequestType,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/uploadFileProvider', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFileToS3(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeActionUploadFile = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.ExecuteActionRequestType,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/executeActionUploadFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeAction(
      DISPLAY_ID_KEY.executeActionUploadFile.name,
      PROVIDER_INFORMATION.name,
      req
    );
    browserLogger.info(
      'PartnerManagement/thunk/executeActionUploadFile',
      PROVIDER_INFORMATION.name,
      data
    );
    browserLogger.info(
      'PartnerManagement/thunk/executeActionUploadFile executeActionUploadFile',
      DISPLAY_ID_KEY.executeActionUploadFile.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFilePartnerManagement = createAsyncThunk<
  ArrayBuffer,
  Types.GetFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/getFilePartnerManagement', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.getFile(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserPartner = createAsyncThunk<
  Types.ReportsItemResponseType<Types.DataPartnerManagementType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PartnerManagement/thunk/getUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.DataPartnerManagementType>(
      PARTNER_MANAGEMENT.name,
      req
    );
    browserLogger.info('PartnerManagement/thunk/getUser', PARTNER_MANAGEMENT.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createFileAttach = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/createFileAttach', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    const { authContainer } = getState() as Types.RootState;

    const { data } = await services.create(ATTACH.name, {
      ...req,
    });
    browserLogger.info('skillCheck/thunk/createFileAttach', ATTACH.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
