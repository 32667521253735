import React, { useCallback } from 'react';

export interface ReceivedProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useHasNotQuestionError = (props: ReceivedProps) => {
  const { setOpen } = props;

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return {
    ...props,
    handleClose,
  };
};

export type Props = ReturnType<typeof useHasNotQuestionError>;

export default useHasNotQuestionError;
