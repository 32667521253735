import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';

import styles from './styles';
import _ from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import { DownIconPdf } from 'assets/svg/svgPDF';
import dayjs from 'dayjs';
import { SEARCH_PARTNER, SEARCH_PUBLISH_OPTION } from 'constant/select.constants';
import { OfficialCurriculumTypes } from 'types';
import { UnPublishedPdf } from 'assets/svgPDF';
import PublicIcon from 'assets/svgPDF/PublicIcon';
import IconLocked from 'assets/svgPDF/IconLocked';
import IconClose from 'assets/svgPDF/IconClose';
import SelectBox from 'components/ReactPDF/SelectBox';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface Props {
  filter: { [key: string]: any };
  curriculumList: OfficialCurriculumTypes[];
}

const FIRST_PAGE_ROW_COUNT = 13;
const PAGE_ROW_COUNT = 18;

const BillingDataTable = ({ dataSource }: { dataSource: OfficialCurriculumTypes[] }) => {
  const columns: { title: string | string[]; width: string | number; key: string }[] = [
    {
      title: ['ID'],
      width: 60,
      key: 'official_curriculum_code',
    },
    {
      title: 'カリキュラム名',
      width: 150,
      key: 'curriculum_name',
    },
    {
      title: '公開ステータス',
      width: 170,
      key: 'publish',
    },
    {
      title: 'ID',
      width: 60,
      key: 'provider_id',
    },
    {
      title: 'パートナー',
      width: 60,
      key: 'provider_name',
    },
    {
      title: '契約状況',
      width: 60,
      key: 'provider_name',
    },
    {
      title: ['利用企業数', '利用ユーザー数'],
      width: 100,
      key: 'user_type',
    },
    {
      title: ['前回公開日時', '最終更新日時'],
      width: 150,
      key: 'user_type',
    },
  ];

  const isStringArray = (val: any) => {
    if (Array.isArray(val)) {
      return true;
    } else {
      return false;
    }
  };
  const formatDateData = (val: string, format = 'YYYY/MM') => {
    if (val) {
      return dayjs(val).format(format);
    }
    return '-';
  };

  const showIcon = (i: number) => {
    switch (i) {
      case 1: {
        return <UnPublishedPdf />;
      }
      case 2: {
        return <PublicIcon />;
      }
      case 3: {
        return <IconClose />;
      }
      default: {
        return <IconLocked />;
      }
    }
  };

  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow, { height: '26.728px' }]}>
        {columns.map((column, index) =>
          isStringArray(column.title) ? (
            column.width === 100 ? (
              <View
                key={index}
                style={[
                  styles.wrapCell,
                  {
                    width: column.width,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  },
                ]}
              >
                <Text style={(styles.tableCell, styles.headerCell)}>{column.title[0]}</Text>
                <Text style={(styles.tableCell, styles.headerCell)}>{column.title[1]}</Text>
              </View>
            ) : (
              <View
                key={index}
                style={[
                  styles.wrapCell,
                  {
                    width: column.width,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  },
                ]}
              >
                <Text style={(styles.tableCell, styles.headerCell)}>{column.title[0]}</Text>
                <Text style={(styles.tableCell, styles.headerCell)}>{column.title[1]}</Text>
              </View>
            )
          ) : column.title === '契約状況' ? (
            <View
              key={index}
              style={[styles.wrapCell, { width: column.width, textAlign: 'center' }]}
            >
              <Text style={(styles.tableCenterCell, styles.headerCell)}>{column.title}</Text>
            </View>
          ) : (
            <View key={index} style={[styles.wrapCell, { width: column.width }]}>
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
            </View>
          )
        )}
      </View>

      {dataSource.map(({ values: row }, rowIndex) => {
        const text =
          row.publish === 1
            ? '未公開'
            : row.publish === 2
            ? '公開中'
            : row.publish === 3
            ? '公開停止中'
            : '編集中';

        const showText =
          row.contract_status === 1
            ? '契約中'
            : row.contract_status === 3
            ? '契約終了予定'
            : row.contract_status === 5
            ? '契約終了'
            : '-';

        return (
          <View
            key={rowIndex}
            style={[
              styles.tableRow,
              {
                borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5',
              },
            ]}
          >
            <View style={[styles.wrapCell, { width: columns[0].width }]}>
              <Text style={[styles.tableCell, styles.IdText]}>{row.official_curriculum_code}</Text>
            </View>
            <View style={[styles.wrapCell, { width: columns[1].width }]}>
              <Text style={styles.tableCell}>{row.curriculum_name}</Text>
            </View>
            <View style={[styles.wrapCell, styles.flex, { width: columns[2].width }]}>
              {showIcon(row.publish)}
              <Text style={[styles.tableCell, styles.tableCellHaveIcon]}>{text}</Text>
            </View>
            <View style={[styles.wrapCell, { width: columns[3].width }]}>
              <Text style={[styles.tableCell, styles.IdText]}>{row.provider_id}</Text>
            </View>
            <View style={[styles.wrapCell, { width: columns[4].width }]}>
              <Text style={styles.tableCell}>{row.provider_name}</Text>
            </View>
            <View style={[styles.wrapCell, { width: columns[5].width, textAlign: 'center' }]}>
              <Text style={styles.tableCenterCell}>{showText}</Text>
            </View>
            <View
              style={[
                styles.wrapCell,
                {
                  width: columns[6].width,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                },
              ]}
            >
              <Text style={styles.tableCell}>{row.num_of_companies || '-'}</Text>
              <Text style={styles.tableCell}>{row.num_of_user || '-'}</Text>
            </View>
            <View
              style={[
                styles.wrapCell,
                {
                  width: columns[7].width,
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                },
              ]}
            >
              <Text style={styles.tableCell}>
                {formatDateData(row.publish_start_date, 'YYYY/MM/DD HH:mm')}
              </Text>
              <Text style={styles.tableCell}>
                {formatDateData(row.updatedat, 'YYYY/MM/DD HH:mm')}
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

const PDFOfficalCurriculumList = ({ filter, curriculumList }: Props) => {
  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap>
        <FullPage>
          <View style={styles.header}>
            <Text style={styles.textHeader}>パートナー　OFFICIALカリキュラム一覧</Text>
          </View>
          <View style={styles.subscription}>
            <View>
              <Text style={styles.textSubscription}>
                パートナーが作成したOFFICIALカリキュラムの確認と管理を行う画面です。
              </Text>
            </View>
          </View>
          <View style={styles.divider}></View>
          <View style={styles.searchPanel}>
            <SelectBox label="契約状況" value={filter.contract_status} options={SEARCH_PARTNER} />
            <SelectBox label="ID" value={filter.provider_id} />
            <SelectBox label="パートナー" value={filter.provider_name} />
            <SelectBox label="カリキュラムID" value={filter.official_curriculum_code} />
            <SelectBox label="カリキュラム" value={filter.curriculum_name} />
            <SelectBox
              label="公開ステータス"
              value={filter.publish}
              options={SEARCH_PUBLISH_OPTION}
            />
          </View>
          <View style={styles.body}>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>1</Text>
              <Text style={styles.textCountNumber}>-</Text>
              <Text style={styles.textCountNumber}>{curriculumList.length}</Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{curriculumList.length}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
            <BillingDataTable dataSource={curriculumList.slice(0, FIRST_PAGE_ROW_COUNT)} />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {curriculumList.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(
          curriculumList.slice(FIRST_PAGE_ROW_COUNT, curriculumList.length),
          PAGE_ROW_COUNT
        ).map((page, index) => (
          <Page
            key={index}
            size={pageSize}
            orientation={pageOrientation}
            style={[styles.page, styles.pagePadding]}
            wrap={false}
          >
            <FullPage>
              <BillingDataTable dataSource={page} />
            </FullPage>
            <PageNumber />
          </Page>
        ))}
    </Document>
  );
};

export default PDFOfficalCurriculumList;
