import React, { memo, useEffect, useMemo } from 'react';
import { ReadOutlined } from '@ant-design/icons';
import { DualAxes, G2 } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { ALL_COMPANY_TYPE_ID, COORDINATELINEPATH } from 'constant';
import { formatNumberNoDecimal } from 'libs/numberFormatter';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { authSelector } from 'containers/Auth/selectors';
import { dashboardSelector } from '../selectors';
import Styled from './styles';
import {
  getDataChangesInUsageOfOfficialCurriculum,
  getDataNumOfficialCurriculumUsers,
  getDataNumOfficialCurriculumUsersActive,
} from '../thunk';
import { browserLogger } from 'libs/logger';

const OfficialCurriculum: React.FC<{ isRenderPdf?: boolean }> = ({ isRenderPdf }) => {
  const { userInfo } = useSelector(authSelector);
  const { loading } = useSelector(settingSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const G = G2.getEngine('canvas');

  const {
    numOfficialCurriculumUsers,
    numOfficialCurriculumSuspendedUsers,
    numOfficialCurriculumUsersActive,
    dataChangesInUsageOfOfficialCurriculumColumn,
    dataChangesInUsageOfOfficialCurriculumLine,
  } = useSelector(dashboardSelector);

  const config = {
    data: [
      dataChangesInUsageOfOfficialCurriculumLine,
      dataChangesInUsageOfOfficialCurriculumColumn,
    ],
    xField: 'target_month',
    yField: ['value', 'value'],
    geometryOptions: [
      {
        geometry: 'line',
        seriesField: 'name',
        lineStyle: {
          lineWidth: 2,
        },
      },
      {
        geometry: 'column',
        isGroup: true,
        seriesField: 'name',
      },
    ],
  };

  const getDateThreeMonthsAgo = (dateString: string) => {
    const date = dayjs(dateString);
    const dateThreeMonthsAgo = date.subtract(3, 'month');
    return dateThreeMonthsAgo.toISOString();
  };

  const dispatch = useAppDispatch();

  const { numOfNewUsersMonth, numOfficialCurriculumCountOfStops } = useMemo(() => {
    const numOfNewUsersMonth = dataChangesInUsageOfOfficialCurriculumColumn.find(
      ({ target_month, name }) => target_month === dayjs().format('YYYYMM') && name === '新規利用'
    );
    const numOfficialCurriculumCountOfStops = dataChangesInUsageOfOfficialCurriculumColumn.find(
      ({ target_month, name }) => target_month === dayjs().format('YYYYMM') && name === '利用停止'
    );
    return {
      numOfNewUsersMonth: numOfNewUsersMonth?.value ?? 0,
      numOfficialCurriculumCountOfStops: numOfficialCurriculumCountOfStops?.value ?? 0,
    };
  }, [dataChangesInUsageOfOfficialCurriculumColumn]);

  useEffect(() => {
    if (isRenderPdf) return;
    if (isUserInfoChanged && userInfo) {
      dispatch(
        getDataNumOfficialCurriculumUsers({
          page: 1,
          per_page: 0,
          return_count_only: true,
          omit_total_items: false,
        })
      );
    }
  }, [isUserInfoChanged, dispatch, userInfo, isRenderPdf]);

  useEffect(() => {
    if (isRenderPdf) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getDataNumOfficialCurriculumUsersActive({
            conditions: [
              {
                id: 'implementation_date',
                search_value: [getDateThreeMonthsAgo(dayjs().toISOString()), null],
              },
              {
                id: 'implementation_date',
                search_value: [null, dayjs().toISOString()],
              },
            ],
            page: 1,
            per_page: 0,
            return_count_only: true,
            omit_total_items: false,
          })
        ),
        dispatch(
          getDataChangesInUsageOfOfficialCurriculum({
            conditions: [
              {
                id: 'company_type',
                search_value: [ALL_COMPANY_TYPE_ID],
              },
            ],
            page: 1,
            per_page: 0,
            sort_fields: [
              {
                id: 'target_month',
                order: 'asc',
              },
            ],
          })
        ),
      ]);
      browserLogger.info(
        'dashboard/OfficialCurriculum/index',
        ALL_COMPANY_TYPE_ID,
        dataChangesInUsageOfOfficialCurriculumColumn
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, isRenderPdf]);

  return (
    <Styled>
      <div className="user-info">
        <div className="wrap-icon">
          <ReadOutlined className="icon" /> OFFICIALカリキュラム情報
        </div>
        <div className="body-info">
          <div className="left-side">
            <span className="title">リアルタイム利用ユーザー数</span>
            <div className="info">
              <div className="body-detail">
                <span className="title-info">利用ユーザー数</span>
                <div className="wrap-number">
                  <span className="number">
                    {formatNumberNoDecimal(
                      numOfficialCurriculumUsers - numOfficialCurriculumSuspendedUsers
                    )}
                  </span>
                  <span className="unit">人</span>
                </div>
              </div>
              <div className="body-detail">
                <span className="title-info">アクティブ利用ユーザー数</span>
                <div className="wrap-number">
                  <span className="number">
                    {formatNumberNoDecimal(numOfficialCurriculumUsersActive)}
                  </span>
                  <span className="unit">人</span>
                </div>
              </div>
              <div className="body-detail">
                <div className="detail-child">
                  <span className="title-info">当月新規利用ユーザー数</span>
                  <div className="wrap-number">
                    <span className="number">{formatNumberNoDecimal(numOfNewUsersMonth)}</span>
                    <span className="unit"> 人</span>
                  </div>
                </div>
                <div className="line" />
                <div className="detail-child">
                  <span className="title-info">当月利用停止ユーザー数</span>
                  <div className="wrap-number">
                    <span className="number">
                      {formatNumberNoDecimal(numOfficialCurriculumCountOfStops)}
                    </span>
                    <span className="unit"> 人</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column" />
          <div className="right-side">
            <div className="wrap-header">
              <span className="title">利用ユーザー数推移</span>
            </div>
            <div className="chart">
              <span className="unit">単位：人</span>
              <DualAxes
                className="dual-chart"
                {...config}
                tooltip={{
                  title: (text: string) => {
                    return text.slice(0, 4) + '/' + text.slice(4, 6);
                  },
                }}
                legend={{
                  position: 'top-right',
                  itemHeight: 10,
                  itemName: {
                    style(item) {
                      return {
                        fill: '#424242',
                        fontSize: 12,
                        fontWeight: 400,
                        fontFamily: 'Noto Sans Javanese',
                        x: item?.id === 'Churn Rate' || item?.id === 'アクティブ比' ? 23 : 22,
                      };
                    },
                  },
                }}
                yAxis={[
                  {
                    position: 'right',
                    field: 'value',
                    max: 200,
                    min: 0,
                    label: {
                      formatter: (val: string) => `${val}%`,
                    },
                    grid: {
                      line: {
                        style: {
                          lineDash: [0, 0],
                          stroke: 'transparent',
                        },
                      },
                    },
                  },
                  {
                    grid: {
                      line: {
                        style: (_item, index, items) => {
                          const midIndex = Math.floor(items.length / 2);
                          if (midIndex === index) {
                            return {
                              stroke: '#ddd',
                            };
                          }
                          return {
                            lineDash: [4, 4],
                            stroke: '#ddd',
                          };
                        },
                      },
                    },
                    nice: true,
                    label: {
                      formatter: (text: string) => formatNumberNoDecimal(Number(text)),
                    },
                    position: 'left',
                    tickCount: 7,
                    maxTickCount: 10,
                  },
                ]}
                xAxis={{
                  tickLine: null,
                  label: {
                    formatter: (text: string) => dayjs(text).format('YYYY/MM'),
                  },
                }}
                label={{
                  position: 'top',
                }}
                height={550}
                geometryOptions={[
                  {
                    geometry: 'line',
                    seriesField: 'name',
                    lineStyle: {
                      lineWidth: 2,
                    },
                    isStack: true,
                    point: {},
                    color: ['#F0CA45', '#5EDFDF'],
                    legend: {
                      marker: {
                        symbol: () => COORDINATELINEPATH,
                        style(style) {
                          return {
                            ...style,
                            fill: style.stroke,
                            y: 70,
                            lineWidth: 0.75,
                          };
                        },
                      },
                    },
                    label: {
                      position: 'top',
                      content: (v) => {
                        const group = new G.Group({});
                        group.addShape({
                          type: 'text',
                          attrs: {
                            x: 0,
                            y: 0,
                            text: formatNumberNoDecimal(Number(v?.value || 0)),
                            textAlign: 'left',
                            textBaseline: 'top',
                            fill: v?.name === 'Churn Rate' ? '#F0CA45' : '#5EDFDF',
                          },
                        });
                        return group;
                      },
                    },
                  },
                  {
                    geometry: 'column',
                    isGroup: true,
                    seriesField: 'name',
                    columnWidthRatio: 0.4,
                    marginRatio: 0.2,
                    color: ['#EC4D7C', '#C38BC2', '#20A7D1', '#1AC199'],
                    label: {
                      position: 'top',
                      content: (v) => {
                        const group = new G.Group({});
                        group.addShape({
                          type: 'text',
                          attrs: {
                            x: 0,
                            y: 0,
                            text: formatNumberNoDecimal(Number(v?.value || 0)),
                            textAlign: 'left',
                            textBaseline: 'top',
                            fill:
                              v?.name === '新規利用'
                                ? '#EC4D7C'
                                : v?.name === '利用停止'
                                ? '#C38BC2'
                                : v?.name === '月間利用数' || v?.name === '利用ユーザー数'
                                ? '#20A7D1'
                                : '#1AC199',
                          },
                        });
                        return group;
                      },
                    },
                    legend: {
                      marker: () => {
                        return {
                          symbol: 'square',
                          style(style) {
                            return {
                              ...style,
                              r: 7,
                              // y: 36,
                            };
                          },
                        };
                      },
                      radio: {
                        style: {
                          fill: '#ffffff',
                          stroke: '#ffffff',
                        },
                      },
                    },
                  },
                ]}
                animation={!loading}
              />
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default memo(OfficialCurriculum);
