import { Rect, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const CheckOff = ({ style }: { style?: Style | Style[] }) => (
  <Svg width="12" height="12" viewBox="0 0 17 16" style={style}>
    <Rect x="0.746094" y="0.5" width="15" height="15" rx="0.5" fill="white" stroke="#D9D9D9" />
  </Svg>
);

export default CheckOff;
