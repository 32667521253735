import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { getEmptyImage } from 'react-dnd-html5-backend';
import { findIndex, get, maxBy } from 'lodash';
import { useDrag, useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import { Popover, Tooltip } from 'antd';

import { StopPublic, TickComplete, Unpublished, WhiteEditing } from 'assets';
import { curriculumSelector } from 'pages/Curriculum/selectors';
import DeleteCompleted from 'components/Modal/DeleteCompleted';
import TooltipRowContent from '../TooltipRowContent';
import { Wrapper } from './styles';
import * as Types from 'types';
import { resizeText } from 'libs/utils/calculate';
import PartnerTooltipRowContent from 'pages/CurriculumTree/TooltipRowContent';

const NodeRenderer: React.FC<{
  index: number;
  accept: string;
  tabActive: number;
  nodeHeight: number;
  isIndex?: number;
  isPublish?: boolean;
  treeViewIndex: number;
  maxSortOrder?: number;
  isPartners?: boolean;
  node: Types.TreeItem<Types.CurriculumItemType>;
  rootNode: Types.TreeItem<Types.CurriculumItemType>;
  itemMoveCopySelected?: Types.ItemMoveCopySelectedType;
  setItemMoveCopySelected?: React.Dispatch<
    React.SetStateAction<Types.ItemMoveCopySelectedType | undefined>
  >;
  onDrop?: (
    type: 'move' | 'copy',
    currentItem: Types.TreeItem<Types.CurriculumItemType>,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => void;
  onDropQuestion?: (
    currentItem: Types.DropQuestionType,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => void;
  onDropNode?: (currentItem: Types.TreeItem<Types.CurriculumItemType>) => void;
  isExport?: boolean;
}> = ({
  node,
  index,
  onDrop,
  isPublish,
  isIndex,
  tabActive,
  nodeHeight,
  maxSortOrder,
  treeViewIndex,
  onDropQuestion,
  itemMoveCopySelected,
  setItemMoveCopySelected,
  onDropNode,
  isExport,
  isPartners,
  rootNode,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLElement>(null);

  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [width, setWidth] = useState<number>();

  const { nodeLevel4Selected } = useSelector(curriculumSelector);

  const height = useMemo(() => {
    const h = (node.lineIndex! - (node.parentNode?.lineIndex || 1)) * 125;
    if (node?.columnIndex === 5) {
      return `${h}%`;
    }
    const children = get(node, 'parentNode.children', []);
    if (
      !node ||
      findIndex(
        children,
        (e: Types.TreeItem<Types.CurriculumItemType>) => e.i_id === node!.node!.i_id
      ) !==
        children.length - 1
    ) {
      return '0%';
    }
    return `${h}%`;
  }, [node]);

  const nodeContentWith = useMemo(() => {
    return !node || !node.parentNode ? '100%' : node.columnIndex === 5 ? '92.325%' : '93.455%';
  }, [node]);

  const lineClass = useMemo(() => {
    if (node.columnIndex === 1) {
      if (node.lineIndex === 1) {
        return 'rst__lineHalfHorizontalLeftParentNode rst__lineHalfVerticalTopParentNode';
      }
      return 'rst__lineHalfHorizontalLeftVerticalTop rst__lineHalfVerticalTop';
    }
    if (node.columnIndex! > 1) {
      if (node.lineIndex === node.parentNode?.lineIndex) {
        return 'rst__lineHalfHorizontalLeft';
      }
      return 'rst__lineHalfHorizontalLeftVerticalTop rst__lineHalfVerticalTop';
    }
    return '';
  }, [node.columnIndex, node.lineIndex, node.parentNode?.lineIndex]);

  const childWrapper = useMemo(
    () =>
      (itemMoveCopySelected?.node &&
      ((itemMoveCopySelected.node.columnIndex! < 5 &&
        itemMoveCopySelected.node.node?.i_id === node.node?.i_id) ||
        (itemMoveCopySelected.node.columnIndex! === 5 &&
          itemMoveCopySelected.node.node?.question_assign_level_i_id ===
            node.node?.question_assign_level_i_id))
        ? ' moving'
        : '') +
      (itemMoveCopySelected?.node &&
      itemMoveCopySelected.node.columnIndex! - 1 === node.columnIndex &&
      visible
        ? ' select'
        : ''),
    [
      itemMoveCopySelected?.node,
      node?.columnIndex,
      node.node?.i_id,
      node.node?.question_assign_level_i_id,
      visible,
    ]
  );

  const [, dropQuestion] = useDrop<Types.DropQuestionType, void, void>({
    accept: 'move-question',
    canDrop: () =>
      !isPublish &&
      tabActive === 0 &&
      node.columnIndex === 4 &&
      nodeLevel4Selected?.i_id === node.node?.i_id,
    drop: (item) => {
      if (item && node.node && onDropQuestion) {
        onDropQuestion(item, node.node);
      }
    },
  });

  const [, drop] = useDrop<
    Types.TreeItem<Types.CurriculumItemType> & { type: string; mode: 'move' | 'copy' },
    void,
    void
  >({
    accept: 'copy-item',
    canDrop: (item) => {
      return Boolean(
        item.node &&
          node &&
          onDrop &&
          item.mode &&
          item.node!.columnIndex! - 1 === node.node?.columnIndex
      );
    },
    drop: (item) => {
      if (item.node && node.node && onDrop && node.node!.publish !== 2) {
        if (ref.current) {
          ref.current.click();
        }
      }
    },
  });

  const [, drag, preview] = useDrag({
    item: {
      node,
      index,
      type: 'copy-item',
      width: width,
      mode: itemMoveCopySelected?.type,
      height: 40,
    },
    canDrag:
      itemMoveCopySelected !== undefined
        ? tabActive === 1 && itemMoveCopySelected.node.node!.i_id === node.node?.i_id
        : false,
    begin: () => {
      if (visible) {
        setVisible(false);
      }
    },
  });

  dropQuestion(ref);
  drag(drop(ref));

  const handleSelectModeDrag = (
    currentNode: Types.TreeItem<Types.CurriculumItemType>,
    type: 'move' | 'copy'
  ) => {
    setItemMoveCopySelected &&
      setItemMoveCopySelected({
        type,
        node: currentNode,
        treeViewIndex: treeViewIndex,
      });
    setVisible(false);
  };

  useEffect(() => {
    if (tabActive === 0) return;
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview, tabActive]);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
    return () => {
      setWidth(undefined);
    };
  }, [ref]);
  useEffect(() => {
    if (titleRef.current && ref.current) {
      resizeText(titleRef.current, ref.current);
    }
  }, [titleRef, ref.current, ref.current?.offsetWidth]);
  const onOpenChange = useCallback(
    (tooltipVisible: boolean) => {
      const centerOffset = document.querySelector(`.tooltip-group-${isIndex}`);
      if (tooltipVisible) {
        window.onmousemove = (e) => {
          const parentElement = document.getElementById(`tooltip-hover-${isIndex}`);

          const offset = parentElement?.getBoundingClientRect();

          if (!centerOffset?.parentElement || !offset) return;

          const left = Math.floor(
            e.pageX - (centerOffset?.getBoundingClientRect()?.width ?? 0) / 2
          );

          if (e.pageX >= offset.left + window.scrollX && e.pageX <= offset.right + window.scrollX) {
            centerOffset.parentElement.style.left = `${left}px`;
            centerOffset.parentElement.style.visibility = 'visible';
          } else {
            centerOffset.parentElement.style.left = '-9999px';
            centerOffset.parentElement.style.visibility = 'hidden';
          }
        };
      } else {
        if (centerOffset?.parentElement) {
          centerOffset.parentElement.style.left = '-9999px';
          centerOffset.parentElement.style.visibility = 'hidden';
        }
        window.onmousemove = () => {};
      }
    },
    [isIndex]
  );

  return (
    <Wrapper
      className="rst__node"
      style={{ height: nodeHeight }}
      data-height={height}
      data-isexport={isExport}
      data-columnindex={node?.columnIndex}
      data-item-move-copy-selected={!!itemMoveCopySelected}
    >
      {isExport && node.node!.columnIndex !== 0 && get(node, 'node.hidden', false) === false && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: '53%',
            height: '2px',
            width: '14px',
            backgroundImage: 'linear-gradient(to right, #8b8b8b 40%, transparent 0%)',
            backgroundPosition: 'bottom',
            backgroundSize: '3px 1px',
            backgroundRepeat: 'repeat-x',
          }}
        />
      )}
      <div
        className="rst__nodeContent"
        style={{
          display: isExport
            ? get(node, 'node.hidden', false) === false
              ? 'flex'
              : 'none'
            : 'flex',
          width: nodeContentWith,
        }}
      >
        <div className={'rst__lineBlock ' + lineClass}>
          <div className="rowWrapper">
            <div className={'row ' + childWrapper}>
              <Popover
                overlayClassName={
                  tabActive === 1 && node.columnIndex! > 0 ? 'popover-tab-1' : 'popover-tab-custom'
                }
                overlayStyle={{
                  width: !node.parentNode ? 260 : 240,
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                placement="bottomLeft"
                destroyTooltipOnHide
                content={() => {
                  if (!visible) {
                    return null;
                  }
                  if (isPartners) {
                    return PartnerTooltipRowContent({ node });
                  }
                  return TooltipRowContent({
                    node,
                    onDrop,
                    onDropNode,
                    tabActive,
                    setVisible,
                    maxSortOrder,
                    itemMoveCopySelected,
                    handleSelectModeDrag,
                    visible,
                    setDeleteSuccess,
                    rootNode,
                  });
                }}
                trigger="click"
                onOpenChange={setVisible}
                open={visible}
              >
                <Tooltip
                  title={node.node?.description}
                  overlayClassName={`tooltip-group-${isIndex}`}
                  destroyTooltipOnHide
                  onOpenChange={onOpenChange}
                >
                  <div
                    ref={ref}
                    className={
                      'rowContents' +
                      (!node.parentNode ? ' rowContentsDragDisabled ' : '') +
                      (!node.parentNode && node.node!.publish !== 2 ? ' rowContentsActive ' : '') +
                      (nodeLevel4Selected && node.node && nodeLevel4Selected.i_id === node.node.i_id
                        ? ' node-selected'
                        : '')
                    }
                    id={`tooltip-hover-${isIndex}`}
                  >
                    {!node.parentNode ? (
                      node.node!.publish === 1 ? (
                        <img src={Unpublished} className="icon" alt="publish-icon" />
                      ) : node.node!.publish === 2 ? (
                        <img src={TickComplete} className="icon" alt="edit-icon" />
                      ) : node.node!.publish === 3 ? (
                        <img src={StopPublic} className="icon" alt="edit-icon" />
                      ) : (
                        <img src={WhiteEditing} className="icon" alt="edit-icon" />
                      )
                    ) : null}
                    <span
                      ref={titleRef}
                      className={`rowTitle ${node.node!.name ? '' : ' empty'}`}
                      style={{
                        overflow: isExport ? 'visible' : 'hidden',
                        color:
                          nodeLevel4Selected &&
                          node.node &&
                          nodeLevel4Selected.i_id === node.node.i_id
                            ? '#fff'
                            : 'inherit',
                      }}
                    >
                      {node.node!.name || '（空白）'}
                    </span>
                  </div>
                </Tooltip>
              </Popover>
              <DeleteCompleted visible={deleteSuccess} setVisible={setDeleteSuccess} />
            </div>
          </div>
        </div>
      </div>
      {isExport &&
        get(node, 'node.hidden', false) === false &&
        node.node!.columnIndex !== 5 &&
        node.node!.columnIndex !== 0 &&
        get(node, 'node.children', []).length > 0 && (
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: '53%',
              height: '2px',
              width: '14px',
              backgroundImage: 'linear-gradient(to right, #8b8b8b 40%, transparent 0%)',
              backgroundPosition: 'bottom',
              backgroundSize: '3px 1px',
              backgroundRepeat: 'repeat-x',
            }}
          />
        )}

      {isExport &&
        (node.node!.columnIndex === 1 ||
          node.node!.columnIndex === 5 ||
          findIndex(
            get(node, 'parentNode.children', []),
            (e: Types.TreeItem<Types.CurriculumItemType>) => e.i_id === node.node!.i_id
          ) ===
            get(node, 'parentNode.children', []).length - 1) && (
          <div
            style={{
              position: 'absolute',
              left: -2,
              bottom: '44%',
              height: `${((props) => {
                if (
                  props.columnIndex === 1 &&
                  props.lineIndex === 1 &&
                  get(props.node, 'hidden', false) == false
                ) {
                  return '70%';
                }
                const linePosisition =
                  node.node!.columnIndex !== 5 &&
                  get(props.parentNode, 'hidden') == true &&
                  get(props.node, 'hidden', false) == false
                    ? get(props.parentNode, 'linePosition', 0) + 5.65
                    : 0;
                return `${
                  !props.node
                    ? 0
                    : findIndex(
                        get(props, 'parentNode.children', []),
                        (e: Types.TreeItem<Types.CurriculumItemType>) =>
                          e.i_id === props!.node!.i_id
                      ) !==
                      get(props, 'parentNode.children', []).length - 1
                    ? 0
                    : Math.abs(
                        linePosisition - (props.lineIndex! - (props.parentNode?.lineIndex || 1))
                      ) * 100
                }%`;
              })(node)}`,
              width: '2px',
              backgroundImage: 'linear-gradient(to bottom, #8b8b8b 40%, transparent 0%)',
              backgroundPosition: 'right',
              backgroundSize: '1px 3px',
              backgroundRepeat: 'repeat-y',
            }}
          />
        )}
    </Wrapper>
  );
};

export default NodeRenderer;
