import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CaretLeftOutlined, CaretRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button, Select, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { find, unionBy } from 'lodash';
import { Form } from 'formik-antd';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { publicManagerSelector } from '../../selectors';
import { getReleaseNoteDetail } from '../../thunk';
import Completed from 'components/Modal/Completed';
import useImagePreview from 'hooks/usePreview';
import { Input, TextArea } from 'components';
import { routes } from 'navigations/routes';
import { createMarkup } from 'libs';

import ReleaseNoteStyled from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import { convertBreakTwoColumn } from 'libs/utils/convertBreakTwoColumn';

interface Props {
  selected?: Types.OfficialCurriculumMaster.ResponseType | undefined;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  dataOfficialCurriculum: Array<Types.OfficialCurriculumMaster.ResponseType>;
  providerInformation: Types.ProviderInformation.ResponseType | undefined;
}

const { Option } = Select;

const InformationReleaseNote: React.FC<Props> = ({ visible, setVisible, selected }) => {
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [version, setVersion] = useState<string>();
  const [indexSection, setIndexSection] = useState<number>(0);
  const [allReleaseNotes, setAllReleaseNotes] = useState<Types.ReleaseNoteDetail.ResponseType[]>(
    []
  );

  const { releaseNoteDetail, releaseNotes } = useSelector(publicManagerSelector);
  const { loading } = useSelector(settingSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { handlePreviewImage, ImagePreview } = useImagePreview();

  const dataReleaseNote = useMemo(() => {
    if (version) {
      const data = find(releaseNotes, (val) => val.release_note_version === version);

      return {
        i_id: data?.i_id,
        title: data?.release_note_title || '',
        release_note_id: data?.release_note_id || '',
        official_curriculum_code: data?.official_curriculum_code || '',
        description: data?.description || '',
        version: Number(data?.release_note_version),
        creator: data?.creator || '',
        sections: releaseNotes
          .filter((e) => e.release_note_version === version)
          .map((manual) => ({
            i_id: manual.item_ref?.section_id?.i_id,
            section_name: manual?.section_name || '',
            text: manual?.section_text || '',
          })),
      };
    }
  }, [releaseNotes, version]);
  const formik = useFormik<Types.RelaseNoteFormik>({
    initialValues: releaseNoteDetail ??
      dataReleaseNote ?? {
        title: '',
        description: '',
        version: 1.0,
        official_curriculum_code: '',
        release_note_id: '',
        creator: '',
        sections: [
          {
            section_name: '',
            text: '',
          },
        ],
      },
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const handleToggleModal = () => {
    setVisible(false);
  };
  const fetchDataReleaseNote = useCallback(async () => {
    if (visible && selected?.official_curriculum_code) {
      dispatch(startLoading());

      const result = await dispatch(
        getReleaseNoteDetail({
          conditions: [
            {
              id: 'official_curriculum_code',
              search_value: [selected?.official_curriculum_code],
            },
          ],
          page: 1,
          per_page: 0,
          sort_fields: [
            {
              id: 'release_note_id',
              order: 'desc',
            },
          ],
        })
      );
      const payload = result.payload as { report_results: Types.ReleaseNoteDetail.ResponseType[] };

      if (payload?.report_results) {
        setAllReleaseNotes(payload.report_results);
      }
      dispatch(stopLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, visible]);

  useEffect(() => {
    fetchDataReleaseNote();
  }, [fetchDataReleaseNote]);

  useEffect(() => {
    if (visible && releaseNoteDetail?.version) {
      setVersion(releaseNoteDetail.version.toString());
    }
  }, [releaseNoteDetail?.version, visible]);

  useEffect(() => {
    if (allReleaseNotes.length > 0) {
      const firstVersion = allReleaseNotes[0].release_note_version;
      setVersion(firstVersion);
    }
  }, [allReleaseNotes]);
  useEffect(() => {
    dispatch(
      getReleaseNoteDetail({
        conditions: [
          {
            id: 'official_curriculum_code',
            search_value: [selected?.official_curriculum_code],
          },
          {
            id: 'release_note_version',
            search_value: [version],
          },
        ],
        page: 1,
        per_page: 0,
        sort_fields: [
          {
            id: 'release_note_id',
            order: 'desc',
          },
        ],
      })
    );
  }, [version]);
  return (
    <>
      {visible && (
        <>
          <ReleaseNoteStyled
            isEmptyData={!releaseNoteDetail?.sections.length}
            title={<span className="title">リリースノート </span>}
            headerStyle={{
              borderBottom: '1px solid #CCCCCC',
            }}
            width={1400}
            visible={visible}
            onCancel={handleToggleModal}
          >
            <Spin spinning={loading} indicator={<LoadingOutlined />}>
              <div className="information">
                <div>
                  <span className="content">
                    {(dataReleaseNote?.title ?? releaseNoteDetail?.title) || ''}
                  </span>
                </div>
                <div className="wrap-right-title">
                  <p className="creator">制作者：{releaseNoteDetail?.creator || ''}</p>
                  <div className="version">
                    <span className="label">バージョン</span>
                    <Select
                      className="select-version"
                      onChange={async (e) => {
                        setVersion(e.toString());
                      }}
                      value={version ? version : ''}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    >
                      {unionBy(allReleaseNotes, 'release_note_id').map((e) => (
                        <Option value={e.release_note_version}>{e.release_note_version}</Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
              <FormikProvider value={formik}>
                <p className="title-infor">
                  {dataReleaseNote?.title && dataReleaseNote.title !== ''
                    ? dataReleaseNote.title
                    : releaseNoteDetail?.title && releaseNoteDetail.title !== ''
                    ? releaseNoteDetail.title
                    : ''}
                </p>
                <Form>
                  <div className="wrap-editor">
                    <div className="left-side">
                      <div className="title-editor">
                        <div className="no-editor">{indexSection + 1}</div>
                        <Form.Item name="section_name" className="item input-title">
                          <TextArea
                            disabled
                            name={`sections.${indexSection}.section_name`}
                            placeholder="セクション名を入力..."
                            className="input-title-section_name"
                            value={convertBreakTwoColumn(
                              formik.values.sections[indexSection]?.section_name ?? '',
                              30
                            ).slice(0, 61)}
                            autoSize={{ minRows: 1, maxRows: 2 }}
                            maxLength={61}
                          />
                        </Form.Item>
                      </div>
                      <div className="wrap-view-jodit">
                        <div
                          className="content"
                          dangerouslySetInnerHTML={createMarkup(
                            formik.values.sections[indexSection]?.text
                          )}
                          onClick={handlePreviewImage}
                        />
                      </div>

                      <ImagePreview />

                      <div className="wrap-bottom-editor">
                        <div className="wrap-button-editor">
                          <Button
                            className="btn button-prev"
                            disabled={indexSection === 0}
                            onClick={() => setIndexSection((prevState) => prevState - 1)}
                          >
                            <CaretLeftOutlined />
                            <span>前のセクション</span>
                          </Button>
                          <Button
                            className="btn button-next"
                            disabled={indexSection === formik.values.sections.length - 1}
                            onClick={() => setIndexSection(indexSection + 1)}
                          >
                            <span>次のセクション</span>
                            <CaretRightOutlined />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="right-side">
                      <Form.Item name="section_name" label="概要：" className="text-area">
                        <TextArea
                          disabled
                          value={formik.values?.description}
                          name="section_name"
                          rows={5}
                          placeholder="最大480文字"
                        />
                      </Form.Item>
                      <span className="title-right">セクション</span>
                      <div className="dashed-line"></div>
                      <div className="wrap-radio">
                        {formik.values.sections.map(({ section_name }, index) => (
                          <div
                            key={index}
                            onClick={() => setIndexSection(index)}
                            className={`section ${index === indexSection && 'section-checked'}`}
                          >
                            <span className="no-number">{index + 1}</span>
                            <span className="section-name">{section_name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="wrap-submit">
                    <div className="wrap-submit">
                      <div className="wrap-button">
                        <Button className="btn btn_close" onClick={() => setVisible(false)}>
                          閉じる
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
                <Completed
                  title="リリースノートを保存しました。"
                  visible={visibleComplete}
                  setVisible={setVisibleComplete}
                  onSubmit={() => navigate(routes.PublicManagement.path)}
                />
              </FormikProvider>
            </Spin>
          </ReleaseNoteStyled>
        </>
      )}
    </>
  );
};

export default InformationReleaseNote;

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
];

const defaultConfig = {
  license: process.env.REACT_APP_JODIT_EDITOR_KEY ?? '',
  placeholder: '本文を入力...',
  readonly: true,
  enableDragAndDropFileToEditor: true,
  buttons,
  buttonsMD: buttons,
  buttonsSM: buttons,
  buttonsXS: buttons,
  uploader: {
    url: 'https://xdsoft.net/jodit/finder/files/',
    baseUrl: 'sdadafaf',
    insertImageAsBase64URI: true,
    withCredentials: false,
    format: 'json',
    data: {
      dir: '',
    },
    filesVariableName: function (t: any) {
      return 'files[' + t + ']';
    },

    process: function (resp: any) {
      //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
      let files = [];
      files.unshift(resp.data);
      return { files: resp.data, error: resp.msg, msg: resp.msg };
    },

    isSuccess: () => {
      return true;
    },
    getMessage: function (e: any) {
      return void 0 !== e.data.messages && Array.isArray(e.data.messages)
        ? e.data.messages.join('')
        : '';
    },
    pathVariableName: 'path',
  },
  filebrowser: {
    ajax: {
      url: 'url',
    },
  },
};
