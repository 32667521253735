import { Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const IconDoc = ({ style, fill }: { style?: Style | Style[]; fill?: string }) => (
  <Svg width="18" height="18" viewBox="0 0 18 18" style={style}>
    <Path
      d="M15.8837 4.51205L11.5605 0.188839C11.44 0.0683036 11.2772 0 11.1065 0H2.57254C2.21696 0 1.92969 0.287277 1.92969 0.642857V17.3571C1.92969 17.7127 2.21696 18 2.57254 18H15.4297C15.7853 18 16.0725 17.7127 16.0725 17.3571V4.96808C16.0725 4.79732 16.0042 4.63259 15.8837 4.51205ZM14.59 5.26339H10.8092V1.48259L14.59 5.26339ZM14.6261 16.5536H3.37612V1.44643H9.44308V5.78571C9.44308 6.00949 9.53198 6.2241 9.69021 6.38234C9.84844 6.54057 10.0631 6.62946 10.2868 6.62946H14.6261V16.5536ZM8.8404 11.1295H5.14397C5.05558 11.1295 4.98326 11.2018 4.98326 11.2902V12.2545C4.98326 12.3429 5.05558 12.4152 5.14397 12.4152H8.8404C8.9288 12.4152 9.00112 12.3429 9.00112 12.2545V11.2902C9.00112 11.2018 8.9288 11.1295 8.8404 11.1295ZM4.98326 8.55804V9.52232C4.98326 9.61071 5.05558 9.68304 5.14397 9.68304H12.8583C12.9467 9.68304 13.019 9.61071 13.019 9.52232V8.55804C13.019 8.46964 12.9467 8.39732 12.8583 8.39732H5.14397C5.05558 8.39732 4.98326 8.46964 4.98326 8.55804Z"
      fill="#C4C4C4"
    />
  </Svg>
);

export default IconDoc;
