import React from 'react';
import { FormikProps, FormikProvider } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { settingSelector } from 'containers/AppSettings/selectors';
import AuthorityMasterFormStyled from './styles';
import { routes } from 'navigations/routes';
import { usePermission } from 'hooks';
import { TextArea } from 'components';
import * as Types from 'types';
import Item from './Item';

interface Prop {
  formik: FormikProps<Types.CreateRolesMasterFormik>;
  isFormEdit?: boolean;
}

const AuthorityMasterForm: React.FC<Prop> = ({ formik, isFormEdit }) => {
  const navigate = useNavigate();

  const { collapsedMenu } = useSelector(settingSelector);

  const { permissionName } = usePermission();

  const handleBack = () => {
    navigate(routes.AuthorityMaster.path);
  };

  const { isSubmitting } = formik;

  return (
    <AuthorityMasterFormStyled collapsedMenu={collapsedMenu}>
      <FormikProvider value={formik}>
        <Form colon={false} className="form-setting">
          <div className="block">
            <Form.Item name="name">
              <label className="text-label">
                権限名称
                <span className="require">*</span>
              </label>
              <TextArea
                name="name"
                className="input-textarea-group"
                showCount
                maxLength={100}
                placeholder="最大100文字"
              />
            </Form.Item>
          </div>

          <div className="block">
            <label className="text-label">Home</label>
            <div className="waper-setting">
              <Item name="admin_dashboard_permission" label="ダッシュボード" disabled />
            </div>
          </div>
          <div className="block">
            <label className="text-label">レポート</label>
            <div className="waper-setting">
              <Item name="sales_report_permission" label="売上情報" disabled />
              <Item name="user_report_permission" label="ユーザー情報" disabled />
              <Item
                name="official_curriculum_report_permission"
                label="OFFICIALカリキュラム情報"
                disabled
              />
              <Item
                name="skill_check_implementation_report_permission"
                label="スキルチェック実施情報"
                disabled
              />
            </div>
          </div>

          <div className="block">
            <label className="text-label">請求データ管理</label>
            <div className="waper-setting">
              <Item name="billing_management_permission" label="請求データ管理" />
            </div>
          </div>
          <div className="block">
            <label className="text-label">OFFICIALカリキュラム公開管理</label>
            <div className="waper-setting">
              <Item
                name="official_curriculum_publish_permission"
                label="OFFICIALカリキュラム公開管理"
              />
            </div>
          </div>
          <div className="block">
            <label className="text-label">カリキュラム管理</label>
            <div className="waper-setting">
              <Item name="curricullum_master_permission" label="カリキュラムマスタ" />
              <Item name="question_master_permission" label="設問マスタ" />
            </div>
          </div>
          <div className="block">
            <label className="text-label">ユーザー管理</label>
            <div className="waper-setting">
              <Item name="users_master_permission" label="ユーザーマスタ" />
              <Item name="roles_master_permission" label="権限マスタ" />
              <Item name="departments_master_permission" label="所属マスタ" />
              <Item name="positions_master_permission" label="役職マスタ" />
              <Item name="admin_master_permission" label="管理者マスタ" />
            </div>
          </div>
          <div className="block">
            <label className="text-label">ストレージ管理</label>
            <div className="waper-setting">
              <Item name="admin_storage_permission" disabled={true} label="ストレージ管理" />
            </div>
          </div>
          <div className="block">
            <label className="text-label">パートナー管理</label>
            <div className="waper-setting">
              <Item name="partner_management_permission" label="パートナー管理" />
              <Item name="curriculum_tree_permission" label="カリキュラム一覧・ツリー" disabled />
            </div>
          </div>
          <div className="block">
            <label className="text-label">法人ユーザー管理</label>
            <div className="waper-setting">
              <Item name="corporate_user_permission" label="法人ユーザー情報一覧" />
              <Item name="invoice_correspondence_permission" label="請求書対応" />
            </div>
          </div>
          <div className="block">
            <label className="text-label">個人ユーザー管理</label>
            <div className="waper-setting">
              <Item name="individual_user_management_permission" label="個人ユーザー管理" />
            </div>
          </div>
          <div className="wrap-submit">
            <div className="wrap-button">
              <SubmitButton className="btn btn_submit" loading={isSubmitting}>
                {isFormEdit ? '更新' : '登録'}
              </SubmitButton>
              <button className="btn btn_close" onClick={handleBack}>
                戻る
              </button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </AuthorityMasterFormStyled>
  );
};

export default AuthorityMasterForm;
