import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { SectionStyled } from './styles';
import Modal from '..';

interface Props {
  visible: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  okButtonDisabled?: boolean;
  description?: React.ReactNode;
  itemDeleted?: boolean;
  spaceCustom?: boolean;
  onSubmit?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmDeleteModal: React.FC<Props> = ({
  title,
  visible,
  subTitle,
  itemDeleted,
  onCancel,
  onSubmit,
  setVisible,
  description,
  spaceCustom,
  okButtonDisabled,
}) => {
  const [, setLoading] = useState<boolean>(false);

  const handleCloseModal = async () => {
    setLoading(true);
    setLoading(false);
    setVisible(false);
  };

  const handleSubmitModal = async () => {
    setLoading(true);
    onSubmit && (await onSubmit());
    setLoading(false);
  };

  useEffect(() => {
    if (!visible) {
      onCancel && onCancel();
    }
  }, [visible]);

  return (
    <Modal
      title={title}
      visible={visible}
      width={720}
      onCancel={handleCloseModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
        paddingBottom: spaceCustom ? '7px' : '20px',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
        paddingTop: spaceCustom ? '0px' : '20px',
      }}
      okButton={
        onSubmit
          ? {
              text: 'OK',
              onClick: handleSubmitModal,
              disabled: okButtonDisabled,
            }
          : undefined
      }
      cancelButton={{
        text: itemDeleted ? 'キャンセル' : '閉じる',
        onClick: handleCloseModal,
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          <ExclamationCircleOutlined className="icon" />
          {subTitle}
        </p>
        <p className="text-content">{description}</p>
      </SectionStyled>
    </Modal>
  );
};

export default ConfirmDeleteModal;
