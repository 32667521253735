import React, { useEffect, useRef, useState } from 'react';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { Image as ImageAntd, Upload } from 'antd';
import { includes, isEqual, remove, slice } from 'lodash';
import ReactPlayer from 'react-player';
import {
  CloudUploadOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';

import { UploadFileFieldType } from 'types/services/curriculum';
import { extractFileName } from 'libs/utils/format';
import { FileType } from 'constant/enum.constant';
import { SectionStyled } from './styles';
import { Modal } from 'components';
import { NoImage2 } from 'assets';

interface Props {
  visible: {
    show: boolean;
    data: Array<UploadFile<File>>;
    dataProblem: Array<{
      content: string;
      indexProblem: number;
      file: UploadFile<File>;
      fileID: string;
    }>;
    dataName: Array<string>;
    type: UploadFileFieldType;
    fistIndex?: number;
  };
  setVisible: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      data: Array<UploadFile<File>>;
      dataProblem: Array<{
        content: string;
        indexProblem: number;
        file: UploadFile<File>;
        fileID: string;
      }>;
      dataName: Array<string>;
      type: UploadFileFieldType;
      fistIndex?: number;
    }>
  >;
  onSubmit: (
    data: Array<UploadFile<File> | undefined>,
    fieldType: UploadFileFieldType,
    dataProblem: Array<{
      content: string;
      indexProblem: number;
      file: UploadFile<File> | undefined;
    }>,
    dataName: Array<string>,
    fistIndex: number
  ) => void;
}

const typeVisibleArr = [
  'attach',
  'problems',
  'comment_attach',
  'problems1_attach',
  'problems2_attach',
  'problems3_attach',
];
const { Dragger } = Upload;

const UploadImageVideoQuestion: React.FC<Props> = ({ visible, setVisible, onSubmit }) => {
  const [fileOrigin, setFileOrigin] = useState<Array<UploadFile<File>>>([]);
  const [files, setFiles] = useState<Array<UploadFile<File>>>([]);
  const [isShowImage, setIsShowImage] = useState<boolean>(false);
  const [fileName, setFileName] = useState<Array<string>>([]);
  const [index, setIndex] = useState<number>(0);
  const [filesProblem, setFilesProblem] = useState<
    Array<{ content: string; indexProblem: number; file: UploadFile<File>; fileID: string }>
  >([]);

  const vidRef = useRef<HTMLVideoElement>(null);

  const handleClose = () => {
    setVisible({ ...visible, show: false });
    vidRef.current?.pause();
  };

  const handleDelete = () => {
    setFiles((prevState) => prevState.filter((_, i) => i !== index));
    setFilesProblem((prevState) => prevState.filter((item, i) => item.indexProblem - 1 !== index));
    setFileName((prevState) => prevState.filter((item, i) => i !== index));
  };

  const handleSubmit = () => {
    handleClose();
    onSubmit(files, visible.type, filesProblem, fileName, index);
  };

  const onChange = async (info: UploadChangeParam<UploadFile<File>>) => {
    const fileNumber = !files[index] && info.fileList.length > index + 1 ? 3 - index : 1;
    const lastThreeFiles =
      info.fileList.length > fileNumber ? slice(info.fileList, -fileNumber) : info.fileList;

    let fileList: Array<UploadFile<File>> = [...files];
    const fileProblemList: Array<{
      content: string;
      indexProblem: number;
      file: UploadFile<File>;
      fileID: string;
    }> = [];
    const currentContentList = ['problems1_attach', 'problems2_attach', 'problems3_attach'];
    let currentContentIndex = 0;

    for (const element of lastThreeFiles) {
      const file = element;
      const isJpgOrPngOrMp4 =
        file.type === FileType.JPEG ||
        file.type === FileType.PNG ||
        file.type === FileType.MP4 ||
        file.type === FileType.GIF;
      const isAccept = await new Promise<boolean>((resolve) => {
        if (!file.originFileObj) return resolve(false);
        const src = URL.createObjectURL(new Blob([file.originFileObj]));

        if (file.originFileObj.type === FileType.PNG || file.originFileObj.type === FileType.JPEG) {
          const image = new Image();
          image.src = src;
          image.onload = function () {
            URL.revokeObjectURL(src);
            resolve(true);
          };
        } else {
          const video = document.createElement('video');
          video.onloadedmetadata = () => {
            URL.revokeObjectURL(src);
            resolve(true);
          };
          video.src = src;
          video.load();
        }
      });

      if (!isJpgOrPngOrMp4 || !isAccept) {
        fileList.push(file);
      } else {
        const currentContent = currentContentList[currentContentIndex];
        currentContentIndex++;

        if (!files[index] && fileList.length < 3) {
          fileList.push(file);
        } else if (fileList.length >= 3) {
          fileList[index] = file;
        } else {
          fileList[index] = file;
        }

        if (lastThreeFiles.length === 3 || lastThreeFiles.length === 2) {
          fileProblemList.push({
            content: currentContent,
            file: file,
            indexProblem: currentContentIndex,
            fileID: '',
          });
        } else {
          fileProblemList.push({
            content:
              index === 0
                ? 'problems1_attach'
                : index === 1
                ? 'problems2_attach'
                : 'problems3_attach',
            file: file,
            indexProblem: index + 1,
            fileID: '',
          });
        }
      }
    }

    fileList = slice(fileList, 0, 3);

    remove(fileList, (file) => !file);

    setFiles(fileList);

    const updatedFilesProblem = [...filesProblem, ...fileProblemList];
    const uniqueFilesProblemMap = new Map<number, (typeof updatedFilesProblem)[0]>();

    updatedFilesProblem.forEach((fileProblem) => {
      uniqueFilesProblemMap.set(fileProblem.indexProblem, fileProblem);
    });

    const uniqueFilesProblem = Array.from(uniqueFilesProblemMap.values()).sort(
      (a, b) => a.indexProblem - b.indexProblem
    );

    setFilesProblem(uniqueFilesProblem);
  };

  useEffect(() => {
    if (visible.show && typeVisibleArr.includes(visible.type)) {
      setFiles(visible.data);
      setFilesProblem(visible.dataProblem);
      setFileName(visible.dataName);
      setIndex(visible.type === 'problems' ? visible.fistIndex || 0 : 0);
      setFileOrigin(visible.data);
    }
    return () => {
      setFiles([]);
      setFilesProblem([]);
      setFileName([]);
      setIsShowImage(false);
      setFileOrigin([]);
      setIndex(0);
    };
  }, [visible]);

  return (
    <Modal
      title="画像・動画アップロード"
      width={720}
      open={visible.show && typeVisibleArr.includes(visible.type)}
      onCancel={handleClose}
      okButton={{
        text: '登録',
        onClick: handleSubmit,
        disabled: !files.length || isEqual(fileOrigin, files),
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleClose,
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        <div className="form-upload">
          <>
            <LeftOutlined
              className="icon-prev"
              onClick={() => {
                setIndex((prevState) => {
                  if (prevState === 1) {
                    return 0;
                  } else if (prevState === 2) {
                    return 1;
                  } else {
                    return prevState + 2;
                  }
                });
                vidRef.current?.pause();
              }}
            />
            <RightOutlined
              className="icon-next"
              onClick={() => {
                setIndex((prevState) => {
                  if (prevState === 0) {
                    return 1;
                  } else if (prevState === 1) {
                    return 2;
                  } else {
                    return prevState - 2;
                  }
                });
                vidRef.current?.pause();
              }}
            />
          </>
          <div className="file-upload">
            {files[0] && (
              <span className="label-question">
                {visible.type === 'problems'
                  ? `選択肢${index === 0 ? 'A' : index === 1 ? 'B' : 'C'}`
                  : `画像・動画${index + 1}`}
              </span>
            )}

            <div className="wrap-drag">
              <Dragger
                name="file"
                accept=".jpeg, .png, .mp4"
                multiple
                beforeUpload={() => false}
                onChange={onChange}
                fileList={files}
              >
                {filesProblem.find(
                  (item) =>
                    item.content === `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                ) ? (
                  <div className="info-file" onClick={(e) => e.stopPropagation()}>
                    <p className="name-image">
                      {filesProblem
                        .filter(
                          (item) =>
                            item.content ===
                            `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                        )
                        .find((e) => e.file.originFileObj)
                        ? filesProblem
                            .filter(
                              (item) =>
                                item.content ===
                                `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                            )
                            .find((e) => e.file.originFileObj)?.file.name
                        : extractFileName(
                            filesProblem.find(
                              (item) =>
                                item.content ===
                                `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                            )?.fileID
                          )}
                    </p>
                    {filesProblem.find(
                      (item) =>
                        item.content ===
                        `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                    )?.file?.type === FileType.MP4 ||
                    filesProblem.find(
                      (item) =>
                        item.content ===
                        `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                    )?.file?.originFileObj?.type === FileType.MP4 ||
                    includes(
                      filesProblem.find(
                        (item) =>
                          item.content ===
                          `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                      )?.file as unknown as string,
                      'mp4'
                    ) ? (
                      <ReactPlayer
                        key={index}
                        width={'100%'}
                        height={'330px'}
                        url={
                          filesProblem
                            .filter(
                              (item) =>
                                item.content ===
                                `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                            )
                            .find((e) => e.file.originFileObj)
                            ? URL.createObjectURL(
                                new Blob([
                                  filesProblem
                                    .filter(
                                      (item) =>
                                        item.content ===
                                        `problems${
                                          index === 0 ? '1' : index === 1 ? '2' : '3'
                                        }_attach`
                                    )
                                    .find((e) => e.file.originFileObj)?.file?.originFileObj as Blob,
                                ])
                              )
                            : (filesProblem.find(
                                (item) =>
                                  item.content ===
                                  `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                              )?.file as unknown as string)
                        }
                        controls
                        config={{
                          file: {
                            attributes: {
                              disablePictureInPicture: true,
                              controlsList: 'nodownload noplaybackrate',
                            },
                          },
                        }}
                      />
                    ) : (
                      <ImageAntd
                        onClick={(e) => e.stopPropagation()}
                        className="image-file"
                        src={
                          filesProblem
                            .filter(
                              (item) =>
                                item.content ===
                                `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                            )
                            .find((e) => e.file.originFileObj)
                            ? URL.createObjectURL(
                                new Blob([
                                  filesProblem
                                    .filter(
                                      (item) =>
                                        item.content ===
                                        `problems${
                                          index === 0 ? '1' : index === 1 ? '2' : '3'
                                        }_attach`
                                    )
                                    .find((e) => e.file.originFileObj)?.file?.originFileObj as Blob,
                                ])
                              )
                            : (filesProblem.find(
                                (item) =>
                                  item.content ===
                                  `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                              )?.file as unknown as string)
                        }
                        alt={
                          filesProblem.find(
                            (item) =>
                              item.content ===
                              `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                          )?.file?.name
                        }
                        preview={{
                          visible: isShowImage,
                          src: filesProblem.find(
                            (item) =>
                              item.content ===
                              `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                          )?.file?.originFileObj
                            ? URL.createObjectURL(
                                new Blob([
                                  filesProblem.find(
                                    (item) =>
                                      item.content ===
                                      `problems${
                                        index === 0 ? '1' : index === 1 ? '2' : '3'
                                      }_attach`
                                  )?.file?.originFileObj as Blob,
                                ])
                              )
                            : (filesProblem.find(
                                (item) =>
                                  item.content ===
                                  `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                              )?.file as unknown as string),

                          onVisibleChange: (value) => {
                            setIsShowImage(value);
                          },
                          mask: (
                            <ZoomInOutlined
                              className="zoom-image"
                              onClick={() => setIsShowImage(!isShowImage)}
                            />
                          ),
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    <CloudUploadOutlined className="icon" />
                    <p className="ant-upload-text">
                      アップロードするファイルをここにドロップ
                      <br />
                      または
                    </p>
                  </>
                )}
                <div className="wrap-button-upload">
                  <button type="button" className="btn-upload">
                    {filesProblem.find(
                      (item) =>
                        item.content ===
                        `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                    )
                      ? 'ファイルを変更'
                      : 'ファイルを選択'}
                  </button>
                  {filesProblem.find(
                    (item) =>
                      item.content ===
                      `problems${index === 0 ? '1' : index === 1 ? '2' : '3'}_attach`
                  ) ? (
                    <button
                      className="btn-delete"
                      type="button"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDelete();
                      }}
                    >
                      <DeleteOutlined className="icon-delete-outlined" />
                      <span className="text-delete-outlined">ファイルを削除</span>
                    </button>
                  ) : null}
                </div>
              </Dragger>
            </div>
          </div>
        </div>
        <div className="flex-image-small">
          <div>
            <>
              <div
                className={`image ${index === 0 ? 'image_active' : ''}`}
                onClick={() => setIndex(0)}
              >
                {filesProblem.find((item) => item.content === 'problems1_attach')?.file?.type ===
                  FileType.MP4 ||
                filesProblem.find((item) => item.content === 'problems1_attach')?.file
                  ?.originFileObj?.type === FileType.MP4 ||
                includes(
                  filesProblem.find((item) => item.content === 'problems1_attach')
                    ?.file as unknown as string,
                  'mp4'
                ) ? (
                  <ReactPlayer
                    width={'100%'}
                    height={'100%'}
                    url={
                      filesProblem.find((item) => item.content === 'problems1_attach')?.file
                        ?.originFileObj
                        ? URL.createObjectURL(
                            new Blob([
                              filesProblem.find((item) => item.content === 'problems1_attach')?.file
                                ?.originFileObj as Blob,
                            ])
                          )
                        : filesProblem.find((item) => item.content === 'problems1_attach')?.file
                        ? (filesProblem.find((item) => item.content === 'problems1_attach')
                            ?.file as unknown as string)
                        : NoImage2
                    }
                  />
                ) : (
                  <img
                    src={
                      filesProblem.find((item) => item.content === 'problems1_attach')?.file
                        ?.originFileObj
                        ? URL.createObjectURL(
                            new Blob([
                              filesProblem.find((item) => item.content === 'problems1_attach')?.file
                                ?.originFileObj as Blob,
                            ])
                          )
                        : filesProblem.find((item) => item.content === 'problems1_attach')?.file
                        ? (filesProblem.find((item) => item.content === 'problems1_attach')
                            ?.file as unknown as string)
                        : NoImage2
                    }
                    className="image-question"
                    alt={
                      filesProblem.find((item) => item.content === 'problems1_attach')?.file
                        ?.name || ''
                    }
                  />
                )}
              </div>
              <p className="label">{visible.type === 'problems' ? '選択肢A' : '画像・動画１'}</p>
            </>
          </div>
          <div>
            <div
              className={`image ${index === 1 ? 'image_active' : ''}`}
              onClick={() => setIndex(1)}
            >
              {filesProblem.find((item) => item.content === 'problems2_attach')?.file?.type ===
                FileType.MP4 ||
              filesProblem.find((item) => item.content === 'problems2_attach')?.file?.originFileObj
                ?.type === FileType.MP4 ||
              includes(
                filesProblem.find((item) => item.content === 'problems2_attach')
                  ?.file as unknown as string,
                'mp4'
              ) ? (
                <ReactPlayer
                  width={'100%'}
                  height={'100%'}
                  url={
                    filesProblem.find((item) => item.content === 'problems2_attach')?.file
                      ?.originFileObj
                      ? URL.createObjectURL(
                          new Blob([
                            filesProblem.find((item) => item.content === 'problems2_attach')?.file
                              ?.originFileObj as Blob,
                          ])
                        )
                      : filesProblem.find((item) => item.content === 'problems2_attach')?.file
                      ? (filesProblem.find((item) => item.content === 'problems2_attach')
                          ?.file as unknown as string)
                      : NoImage2
                  }
                />
              ) : (
                <img
                  src={
                    filesProblem.find((item) => item.content === 'problems2_attach')?.file
                      ?.originFileObj
                      ? URL.createObjectURL(
                          new Blob([
                            filesProblem.find((item) => item.content === 'problems2_attach')?.file
                              ?.originFileObj as Blob,
                          ])
                        )
                      : filesProblem.find((item) => item.content === 'problems2_attach')?.file
                      ? (filesProblem.find((item) => item.content === 'problems2_attach')
                          ?.file as unknown as string)
                      : NoImage2
                  }
                  className="image-question"
                  alt={
                    filesProblem.find((item) => item.content === 'problems2_attach')?.file?.name ||
                    ''
                  }
                />
              )}
            </div>
            <p className="label">{visible.type === 'problems' ? '選択肢B' : '画像・動画2'}</p>
          </div>
          <div>
            <div
              className={`image ${index === 2 ? 'image_active' : ''}`}
              onClick={() => setIndex(2)}
            >
              {filesProblem.find((item) => item.content === 'problems3_attach')?.file?.type ===
                FileType.MP4 ||
              filesProblem.find((item) => item.content === 'problems3_attach')?.file?.originFileObj
                ?.type === FileType.MP4 ||
              includes(
                filesProblem.find((item) => item.content === 'problems3_attach')
                  ?.file as unknown as string,
                'mp4'
              ) ? (
                <ReactPlayer
                  width={'100%'}
                  height={'100%'}
                  url={
                    filesProblem.find((item) => item.content === 'problems3_attach')?.file
                      ?.originFileObj
                      ? URL.createObjectURL(
                          new Blob([
                            filesProblem.find((item) => item.content === 'problems3_attach')?.file
                              ?.originFileObj as Blob,
                          ])
                        )
                      : filesProblem.find((item) => item.content === 'problems3_attach')?.file
                      ? (filesProblem.find((item) => item.content === 'problems3_attach')
                          ?.file as unknown as string)
                      : NoImage2
                  }
                />
              ) : (
                <img
                  src={
                    filesProblem.find((item) => item.content === 'problems3_attach')?.file
                      ?.originFileObj
                      ? URL.createObjectURL(
                          new Blob([
                            filesProblem.find((item) => item.content === 'problems3_attach')?.file
                              ?.originFileObj as Blob,
                          ])
                        )
                      : filesProblem.find((item) => item.content === 'problems3_attach')?.file
                      ? (filesProblem.find((item) => item.content === 'problems3_attach')
                          ?.file as unknown as string)
                      : NoImage2
                  }
                  className="image-question"
                  alt={
                    filesProblem.find((item) => item.content === 'problems3_attach')?.file?.name ||
                    ''
                  }
                />
              )}
            </div>
            <p className="label">{visible.type === 'problems' ? '選択肢C' : '画像・動画3'}</p>
          </div>
        </div>

        <p className="text-content">
          ※ファイルはまとめて３つまでアップロード可能です。
          <br />
          ※画像は16:9でjpegまたはpngファイルがアップロードできます。
          <br />
          ※動画は16:9でmp4ファイルがアップロードできます。
          <br />
        </p>
      </SectionStyled>
    </Modal>
  );
};

export default UploadImageVideoQuestion;
