import styled from 'styled-components';

import { HEADER_HEIGHT } from 'constant';

interface Props {
  pageYOffset: number;
  tabKey: string;
  screenHeight: number;
}

export default styled.div<Props>`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  background: #ffffff;
  max-height: calc(100% - ${HEADER_HEIGHT}px);
  position: sticky;
  top: ${HEADER_HEIGHT}px;
  margin-top: -190px;
  right: 0;
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
    margin: 0;
  }
  @media (max-width: 1440px) {
    .card-tab-pane {
      .form {
        padding: 0 20px !important;
        .form-input {
          .ant-select {
            width: 202px !important;
          }
          .ant-input-affix-wrapper {
            padding: 0 11px !important;
            height: 32px;
            width: 202px !important;
          }
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }
        }
      }
      .wrap-center {
        .btn-search {
          width: 100px;
        }
      }
    }
    .result-search {
      .right-side {
        .result {
          margin-top: 12px;
        }
      }
    }
  }
  .ant-tabs .ant-tabs-nav .ant-tabs-nav-more {
    display: none;
  }
  .ant-tabs-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    border: none;
    .ant-tabs-tab-btn {
      font-weight: bold;
      font-size: 12px;
    }
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #00a3a5;
  }
  .ant-tabs-content-holder .ant-tabs-content {
    height: 100%;
  }
  .ant-form-item-label {
    text-align: left;
  }
  .card-tab-pane {
    .form {
      padding: 0 18px;
      .title {
        font-size: 12px;
        text-align: center;
        color: #424242;
        margin-bottom: 18px;
      }
      .form-input {
        margin-bottom: 5px;
        .label {
          font-size: 12px;
          color: #888888;
        }
        .select-input {
          font-family: Lato;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
      }
    }
    .wrap-center {
      display: flex;
      justify-content: center;
      margin: 20px 0;
      .label-reset {
        font-size: 12px;
        cursor: pointer;
        text-decoration-line: underline;
        color: #00a3a5;
        margin-left: 12px;
        line-height: 30px;
        background: none;
      }
      .label-reset-2 {
        font-size: 12px;
        cursor: pointer;
        color: #00a3a5;
        padding: 5px 16px;
        background: none;
        text-decoration: underline;
      }
      .btn-search {
        width: 100px;
        padding: 0 14px;
        background: #ffffff;
        border: 1px solid #00a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        font-size: 14px;
        color: #00a3a5;
        cursor: pointer;
        display: inline-flex;
        justify-content: flex-start;
        column-gap: 10px;
        align-items: center;
        .icon-search {
          font-size: 18px;
        }
        span {
          margin: 0;
        }
      }
    }
  }
  .result-search {
    display: flex;
    flex-direction: row;
    height: ${(props) => `calc(
      100vh - ${props.tabKey === '1' ? 480 : 300}px 
    )`};
    .left-side {
      width: 12%;
      text-align: center;
      border-right: 1px solid #e5e5e5;
      .imageQA {
        margin-top: 24px;
        font-size: 26px;
        color: #c4c4c4;
        cursor: pointer;
      }
    }
    .right-side {
      width: 88%;
      background: #f5f5f5;
      border-top: 1px solid #e5e5e5;
      .result {
        height: calc(100% - 55px);
        overflow-y: auto;
        .text-result {
          color: #bbbbbb;
          text-align: center;
          z-index: -1;
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        height: 55px;
        .btn {
          border-radius: 5px;
          font-weight: bold;
          font-size: 12px;
          color: #ffffff;
          cursor: pointer;
          width: 120px;
          height: 32px;
          .icon {
            font-size: 15px;
            margin-right: 10px;
          }
        }
      }
    }
  }
`;

interface ItemProps {
  isDragging: boolean;
  isChecked: boolean;
}

export const ItemWrapper = styled.div<ItemProps>`
  background: ${(props) => (props.isChecked ? '#F1FEFE' : '#ffffff')};
  border: 1px solid ${(props) => (props.isChecked ? '#7ED6D7' : '#ffffff')};
  height: 36px;
  box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
  border-radius: 2px;
  font-size: 11px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  margin: 6px;
  cursor: pointer;
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
  .text-bold {
    margin-left: 5px;
    font-weight: ${(props) => (props.isChecked ? 700 : 'normal')};
    color: ${(props) => (props.isChecked ? '#00A3A5' : '#424242')};
  }
  .icon {
    color: ${(props) => (props.isChecked ? '#00A3A5' : 'unset')};
    width: 26px;
  }
`;

interface ButtonProps {
  active: boolean;
}

export const Button = styled.button<ButtonProps>`
  background: ${(props) => (props.active ? '#F6AC00' : '#d9d9d9')};
  border-radius: 4px;
  border: none;
  margin: 4px auto;
  cursor: ${(props) => (props.active ? 'pointer' : 'not-allowed')};
  .label-icon {
    color: ${(props) => (props.active ? '#FFFFFF' : '#666666')};
  }
`;

export const StylesContent = styled.div`
  padding: 6px 15px;
  .text-content {
    display: flex;
    align-items: center;
    font-family: Lato;
    font-size: 13px;
    color: #424242;
    margin: 0;
    .btn {
      background: #f6ac00;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: none;
      margin: 0 5px;
      .label-icon {
        color: #ffffff;
      }
    }
  }
`;
