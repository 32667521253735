import { exportCsv } from 'libs/utils/exportCsv';

interface LogEntry {
  timestamp: string;
  level: 'INFO' | 'WARN' | 'ERROR';
  fileName: string;
  functionName: string;
  data?: any;
}

class BrowserLogger {
  private logs: LogEntry[] = [];

  private formatLogEntry(
    level: LogEntry['level'],
    fileName: string,
    functionName: string,
    data?: any
  ): LogEntry {
    return {
      timestamp: new Date().toISOString(),
      level,
      fileName,
      functionName,
      data,
    };
  }

  info(fileName: string, functionName: string, data?: any): void {
    this.logs.push(this.formatLogEntry('INFO', fileName, functionName, data));
  }

  warn(fileName: string, functionName: string, data?: any): void {
    this.logs.push(this.formatLogEntry('WARN', fileName, functionName, data));
  }

  error(fileName: string, functionName: string, data?: any): void {
    this.logs.push(this.formatLogEntry('ERROR', fileName, functionName, data));
  }

  downloadLogs(fileLogName = 'app.csv'): void {
    const headerCsv = [
      {
        label: 'timestamp',
        key: 'timestamp',
      },
      {
        label: 'fileName',
        key: 'fileName',
      },
      {
        label: 'functionName',
        key: 'functionName',
      },
      {
        label: 'data',
        key: 'data',
      },
    ];
    const data = this.logs.map((obj) => ({ ...obj, data: JSON.stringify(obj.data) }));
    exportCsv(data, headerCsv, fileLogName);
  }
}

export const browserLogger = new BrowserLogger();
