import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Column, Line, LineConfig, G2, ChartRefConfig } from '@ant-design/plots';
import { TeamOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Checkbox, Select } from 'antd';
import dayjs from 'dayjs';

import { authSelector } from 'containers/Auth/selectors';
import { dashboardSelector } from '../selectors';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { formatNumberNoDecimal } from 'libs/numberFormatter';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import Styled from './styles';
import {
  getDataNumberOfCorporateUsers,
  getDataNumberOfIndividualUsers,
  getDataNumberOfPartnerCompanies,
  getDataUserRegistrationHistory,
} from '../thunk';
import { sortBy, uniqBy } from 'lodash';
import { formatNumber } from 'libs/utils/format';

const { Option } = Select;

const UserInfomation: React.FC<{
  isRenderPdf?: boolean;
  visibleChart: boolean;
  setVisibleChart: React.Dispatch<React.SetStateAction<boolean>>;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isRenderPdf, visibleChart, setVisibleChart, checked, setChecked }) => {
  const { userInfo } = useSelector(authSelector);
  const { loading } = useSelector(settingSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const [dataConfigColumn, setDataConfigColumn] = useState<
    { target_month: number; name: string; value: number }[]
  >([]);
  const [listHideDataChartColumnUserTrendsColumn, setListHideDataChartColumnUserTrendsColumn] =
    useState<string[]>([]);
  const {
    numOfCorporateUsers,
    numOfIndividualUsers,
    numOfPartnerCompanies,
    dataUserTransitionColumnChart,
    dataUserTransitionLineChart,
    dataUserTransitionColumnChartActive,
    dataUserTransitionLineChartActive,
    dataEndOfMonthUsersColumnChart,
    dataEndOfMonthUsersActiveColumnChart,
  } = useSelector(dashboardSelector);

  const dispatch = useAppDispatch();
  const dataConfigColumnBlue = useMemo(() => {
    return checked ? dataEndOfMonthUsersActiveColumnChart : dataEndOfMonthUsersColumnChart;
  }, [checked, dataEndOfMonthUsersActiveColumnChart, dataEndOfMonthUsersColumnChart]);
  const configColumnBlue = {
    data: dataConfigColumnBlue,
    isGroup: true,
    xField: 'target_month',
    yField: 'value',
    padding: 40,
    legend: {
      position: 'top-left',
      offsetY: -5,
    },
    tooltip: {
      customContent: (title: any, items: any) => {
        return `<div style="padding: 10px;">
                  <h4>${title}</h4>
                  ${items
                    .map((item: any) => `<div>${item.data.name}: ${item.value}</div>`)
                    .join('')}
                </div>`;
      },
    },
  };

  const G = G2.getEngine('canvas');
  const dataInitConfigColumn = useMemo(() => {
    return !checked
      ? sortBy(
          dataUserTransitionColumnChart.map((item) => {
            return {
              ...item,
              target_month: parseFloat(item.target_month),
            };
          }),
          'target_month'
        )
      : sortBy(
          dataUserTransitionColumnChartActive.map((item) => {
            return {
              ...item,
              target_month: parseFloat(item.target_month),
            };
          }),
          'target_month'
        );
  }, [checked, dataUserTransitionColumnChart, dataUserTransitionColumnChartActive]);

  useEffect(() => {
    return dataInitConfigColumn && setDataConfigColumn(dataInitConfigColumn);
  }, [dataInitConfigColumn]);

  const configColumn = {
    data: dataConfigColumn,
    isGroup: true,
    legend: true,
    xField: 'target_month',
    yField: 'value',
    seriesField: 'name',
  };
  const config: LineConfig = {
    data: !checked ? dataUserTransitionLineChart : dataUserTransitionLineChartActive,
    xField: 'target_month',
    yField: 'value',
    height: 200,
    point: {
      size: 5,
      shape: 'circle',
      style: {
        fill: '#BAC831',
        stroke: '#BAC831',
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  };

  useEffect(() => {
    if (isRenderPdf) return;
    if (userInfo && isUserInfoChanged) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getDataNumberOfCorporateUsers({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                  not_match: true,
                },
                {
                  id: 'company_id',
                  search_value: ['000000000'],
                  not_match: true,
                },
                {
                  id: 'deleteat',
                  search_value: [''],
                },
                {
                  id: 'deleteat',
                  search_value: [null],
                },
                {
                  id: 'cancellationYYYYMM',
                  search_value: [''],
                },
                {
                  id: 'cancellationYYYYMM',
                  search_value: [null],
                },
              ],
              page: 1,
              per_page: 0,
              return_count_only: true,
            })
          ),
          dispatch(
            getDataNumberOfIndividualUsers({
              conditions: [
                {
                  id: 'company_id',
                  search_value: ['000000000'],
                },
                {
                  id: 'deleteat',
                  search_value: [''],
                },
                {
                  id: 'deleteat',
                  search_value: [null],
                },
                {
                  id: 'cancellationYYYYMM',
                  search_value: [''],
                },
                {
                  id: 'cancellationYYYYMM',
                  search_value: [null],
                },
              ],
              page: 1,
              per_page: 0,
              return_count_only: true,
            })
          ),
          dispatch(
            getDataNumberOfPartnerCompanies({
              conditions: [
                {
                  id: 'forced_withdrawal_flg',
                  search_value: ['1'],
                  not_match: true,
                },
                {
                  id: 'deleteat',
                  search_value: [''],
                },
                {
                  id: 'deleteat',
                  search_value: [null],
                },
              ],
              page: 1,
              per_page: 0,
              return_count_only: true,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [isRenderPdf, dispatch, userInfo]);

  useEffect(() => {
    if (isRenderPdf) return;
    dispatch(
      getDataUserRegistrationHistory({
        conditions: [],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, isRenderPdf]);
  const chartRefConfigColumn = useRef<any>(null);

  useEffect(() => {
    const chart = chartRefConfigColumn?.current?.getChart();

    if (chart) {
      const newData =
        listHideDataChartColumnUserTrendsColumn.length === 2
          ? []
          : dataInitConfigColumn?.filter(
              (data) => !listHideDataChartColumnUserTrendsColumn.includes(data?.name)
            );
      chart.changeData(!listHideDataChartColumnUserTrendsColumn ? dataInitConfigColumn : newData);
    }
  }, [dataInitConfigColumn, listHideDataChartColumnUserTrendsColumn]);
  return (
    <Styled>
      <div className="user-info">
        <div className="wrap-icon">
          <TeamOutlined className="icon" /> ユーザー情報
        </div>
        <div className="body-info">
          <div className="left-side">
            <span className="title">リアルタイムユーザー数</span>
            <div className="info">
              <div className="body-detail">
                <span className="title-info">法人ユーザー数</span>
                <div className="wrap-number">
                  <span className="number">{formatNumberNoDecimal(numOfCorporateUsers)}</span>
                  <span className="unit">人</span>
                </div>
              </div>
              <div className="body-detail">
                <span className="title-info">個人ユーザー数</span>
                <div className="wrap-number">
                  <span className="number"> {formatNumberNoDecimal(numOfIndividualUsers)}</span>
                  <span className="unit">人</span>
                </div>
              </div>
              <div className="body-detail">
                <span className="title-info">パートナー企業数</span>
                <div className="wrap-number">
                  <span className="number">{formatNumberNoDecimal(numOfPartnerCompanies)}</span>
                  <span className="unit"> 社</span>
                </div>
              </div>
            </div>
          </div>
          <div className="column" />
          <div className="right-side">
            <div className="wrap-header">
              <span className="title">ユーザー推移 </span>
              <div className="wrap-action">
                <div className="wrap-select">
                  <label>グラフ種類：</label>
                  <Select
                    value={visibleChart}
                    onChange={(value: boolean) => setVisibleChart(value)}
                    className="select-user"
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  >
                    <Option value={false}> 退会率＆入退会者数推移</Option>
                    <Option value={true}>月末ユーザー数推移</Option>
                  </Select>
                </div>
                <div className="checkbox">
                  <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />
                  <span className="text">アクティブユーザーのみ</span>
                </div>
              </div>
            </div>
            {!visibleChart ? (
              <div className="chart">
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter blue">
                      <div className="circle" />
                      <div className="line" />
                    </div>
                    <span className="legend-name">Churn Rate</span>
                  </div>
                  {uniqBy(dataInitConfigColumn, 'name').map((item) => (
                    <div
                      className={`legent-item ${
                        listHideDataChartColumnUserTrendsColumn.includes(item.name) && 'unChecked'
                      } ${item.name === '入会数' ? 'green' : 'orange'}`}
                      onClick={() => {
                        setListHideDataChartColumnUserTrendsColumn((prevState) => {
                          if (prevState.includes(item.name)) {
                            prevState = prevState.filter((i) => i !== item.name);
                          } else {
                            if (item.name === '入会数') {
                              prevState = [item.name, ...prevState];
                            }
                            if (item.name === '退会数') {
                              prevState = [...prevState, item.name];
                            }
                          }
                          return prevState;
                        });
                      }}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
                <Line
                  className="line-chart"
                  {...config}
                  color="#BAC831"
                  xAxis={{
                    label: null,
                  }}
                  tooltip={{
                    formatter: (datum) => {
                      return { name: 'Churn Rate', value: formatNumber(datum.value, 1) };
                    },
                  }}
                  legend={{
                    position: 'top-right',
                    offsetY: -15,
                    itemHeight: 30,
                  }}
                  yAxis={{
                    label: {
                      formatter: (text: string) => `${text} %`,
                    },
                    grid: {
                      line: {
                        style: {
                          stroke: '#ddd',
                          lineDash: [2, 2],
                        },
                      },
                    },
                    nice: true,
                  }}
                  animation={!loading}
                />
                <div className="item-chart">
                  <div className="unit">単位：人 </div>
                  <Column
                    className="column-chart"
                    {...configColumn}
                    ref={chartRefConfigColumn}
                    padding={[20, 0, 40, 30]}
                    legend={false}
                    yAxis={{
                      grid: {
                        line: {
                          style: (_item, index, items) => {
                            const midIndex = Math.floor(items.length / 2);
                            if (midIndex === index) {
                              return {
                                stroke: '#ddd',
                              };
                            }
                            return {
                              lineDash: [4, 4],
                              stroke: '#ddd',
                            };
                          },
                        },
                      },
                      label: {
                        formatter: (text: string) => formatNumberNoDecimal(Number(text)),
                      },
                      tickCount: 7,
                    }}
                    columnStyle={{
                      fillOpacity: listHideDataChartColumnUserTrendsColumn.length === 2 ? 0 : 1,
                    }}
                    interactions={[{ type: 'legend-active', enable: false }]}
                    xAxis={{
                      tickLine: null,

                      label: {
                        formatter: (text: string) => `${dayjs(text).format('YYYY/MM')}`,
                      },
                    }}
                    maxColumnWidth={25}
                    dodgePadding={4}
                    color={(datum) => {
                      if (datum.name === '退会数') {
                        return '#F6AC00';
                      }
                      return '#08A3A5';
                    }}
                    label={{
                      position: 'top',
                      formatter: (data) => formatNumberNoDecimal(Number(data.value)),
                      content: (v) => {
                        if (listHideDataChartColumnUserTrendsColumn.includes(v.name)) {
                          return '';
                        }
                        const group = new G.Group({});
                        group.addShape({
                          type: 'text',
                          attrs: {
                            x: 0,
                            y: 0,
                            text: v.value !== 0 ? formatNumberNoDecimal(Number(v.value)) : '',
                            textAlign: 'left',
                            textBaseline: 'top',
                            fill: v?.name === '退会数' ? '#F6AC00' : '#08A3A5',
                          },
                        });
                        return group;
                      },
                    }}
                    animation={!loading}
                  />
                </div>
              </div>
            ) : (
              <div className="chart">
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter green" />
                    <span className="legend-name">月末ユーザー数</span>
                  </div>
                </div>
                <div className="item-chart">
                  <span className="unit">単位：人</span>
                  <Column
                    className="column-chart"
                    {...configColumnBlue}
                    legend={{
                      position: 'top-right',
                    }}
                    yAxis={{
                      grid: {
                        line: {
                          style: (_item, index, items) => {
                            const midIndex = Math.floor(items.length / 2);
                            if (midIndex === index) {
                              return {
                                stroke: '#ddd',
                              };
                            }
                            return {
                              lineDash: [4, 4],
                              stroke: '#ddd',
                            };
                          },
                        },
                      },
                      label: {
                        formatter: (text: string) => formatNumberNoDecimal(Number(text)),
                      },
                    }}
                    xAxis={{
                      tickLine: null,
                      label: {
                        formatter: (text: string) => dayjs(text).format('YYYY/MM'),
                      },
                    }}
                    interactions={[{ type: 'legend-active', enable: false }]}
                    maxColumnWidth={25}
                    dodgePadding={4}
                    color="#1D89C6"
                    label={{
                      position: 'top',
                      formatter: (data) => formatNumberNoDecimal(Number(data.value)),
                    }}
                    animation={!loading}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default memo(UserInfomation);
