import React from 'react';
import { Wrapper } from './style';

interface Props {
  startItem?: string | number;
  endItem?: string | number;
  totalItem?: string | number;
  className?: string;
  rightText?: string;
  leftText?: string;
  charDivine?: string;
}

const PagingNumber: React.FC<Props> = ({
  startItem,
  endItem,
  totalItem,
  className,
  rightText,
  leftText,
  charDivine,
}) => {
  return (
    <Wrapper className={className ?? ''}>
      {startItem ? `${startItem}${charDivine ? charDivine : ' - '}` : ''}
      {endItem}
      <span className="text-static">{leftText ? leftText : ''}</span>&nbsp;/&nbsp;{totalItem}
      <span className="text-static label"> {rightText ? rightText : '件'}</span>
    </Wrapper>
  );
};

export default PagingNumber;
