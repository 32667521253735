import useConfirm, { Props, ReceivedProps } from './hook';
import { Modal } from 'components';
import Styled from './styles';

const ConfirmLayout = (props: Props) => {
  const { open, handleClose, handleSubmit } = props;

  return (
    <Modal
      title="リリースノートを一時保存します"
      open={open}
      width={720}
      okButton={{
        text: '一時保存',
        onClick: handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleClose,
      }}
      headerStyle={{
        borderBottom: '1px solid #cccccc',
      }}
      bodyStyle={{
        padding: '38px 0',
        background: '#f9f8f8',
      }}
      footerStyle={{
        paddingTop: 0,
        background: '#f9f8f8',
      }}
    >
      <Styled>
        <span className="content">一時保存のリリースノートは一般ユーザーに公開されません。</span>
      </Styled>
    </Modal>
  );
};

const ConfirmCreate = (props: ReceivedProps) => {
  return <ConfirmLayout {...useConfirm(props)} />;
};

export default ConfirmCreate;
