import {
  DATE_FORMAT,
  REGEX_FULL_WIDTH_ALL,
  REGEX_HALF_WIDTH_LATIN,
  REGEX_HALF_WIDTH_NUMBER,
  REGEX_HALF_WIDTH_NUMBER_AND_HYPHEN,
  REGEX_KANA_KANJI,
  REGEX_KANJI,
  REGEX_KATAKANA,
  REGEX_KATAKANA_FULL_WIDTH,
  YEAR_MONTH_FORMAT,
} from 'constant';
import yup from '../yup';
import dayjs from 'dayjs';

interface TestContextExtended {
  originalValue?: string;
}

export const CreateAuthorityMasterSchema = yup.object().shape({
  name: yup.string().required('値を入力してください').max(100),
});

export const CreateEditJobTitleSchema = yup.object().shape({
  name: yup.string().required('※役職名称を入力してください').max(100),
});

export const CreateEditAffiliationSchema = yup.object().shape({
  name: yup.string().required('所属名称を入力してください').max(100),
});

export const createUserMasterSchema = yup.object().shape({
  employee_code: yup
    .string()
    .required('社員番号は必須入力項目です。')
    .max(10, '最大10文字で入力してください。')
    .matches(REGEX_HALF_WIDTH_NUMBER, '管理番号は数字で入力してください。'),
  name: yup
    .string()
    .required('氏名は必須入力項目です。')
    .max(100, '最大100文字で入力してください。'),
  name_furigana: yup
    .string()
    .required('氏名（フリガナ）は必須入力項目です。')
    .max(100, '最大100文字で入力してください。')
    .matches(REGEX_KATAKANA, '氏名（フリガナ）はカタカナで入力してください。'),
  email: yup
    .string()
    .email('正しいメールアドレスを入力してください。')
    .required('メールアドレスは必須入力項目です。')
    .max(254, '＠を含む半角英数字：最大254文字で入力してください。'),
  email_confirm: yup
    .string()
    .email()
    .required('メールアドレス（確認）は必須入力項目です。')
    .max(254, '＠を含む半角英数字：最大254文字で入力してください。')
    .oneOf([yup.ref('email'), null], 'メールアドレスが一致しません。'),
  password: yup
    .string()
    .matches(REGEX_HALF_WIDTH_LATIN, {
      message: 'パスワードは半角英数字、記号のみ入力してください。',
    })
    .required('パスワードは必須入力項目です。')
    .max(30, '半角英数字、記号4～30文字で入力してください。')
    .min(4, '半角英数字、記号4～30文字で入力してください。'),
  password_confirm: yup
    .string()
    .required('パスワード（確認）は必須入力項目です。')
    .matches(REGEX_HALF_WIDTH_LATIN, {
      message: 'パスワード（確認）は半角英数字、記号で入力してください。',
    })
    .oneOf([yup.ref('password'), null], 'パスワードが一致しません。')
    .max(30, '半角英数字、記号4～30文字で入力してください。')
    .min(4, '半角英数字、記号4～30文字で入力してください。'),
  role_code: yup.string().required('権限は必須入力項目です '),
  main_position: yup.object({
    affiliation_id: yup.string().required(),
    positions_id: yup.string().required(),
  }),
  sub_position: yup.array().of(
    yup.object().shape({
      affiliation_id: yup
        .string()
        .test(
          'required',
          '値を入力してください',
          (value, ctx) =>
            (ctx.parent.positions_id && value) ||
            (!value && !ctx.parent.positions_id) ||
            (value && !ctx.parent.positions_id)
        ),
      positions_id: yup
        .string()
        .test(
          'required',
          '値を入力してください',
          (value, ctx) =>
            (ctx.parent.affiliation_id && value) ||
            (!ctx.parent.affiliation_id && !value) ||
            (value && !ctx.parent.affiliation_id)
        ),
    })
  ),
});

export const updateUserSchema = yup.object().shape({
  employee_code: yup
    .string()
    .required('社員番号は必須入力項目です。')
    .max(10, '最大10文字で入力してください。')
    .matches(REGEX_HALF_WIDTH_NUMBER, '管理番号は数字で入力してください。'),
  name: yup
    .string()
    .required('氏名は必須入力項目です。')
    .max(100, '最大100文字で入力してください。'),
  name_furigana: yup
    .string()
    .required('氏名（フリガナ）は必須入力項目です。')
    .max(100, '最大100文字で入力してください。')
    .matches(REGEX_KATAKANA, '氏名（フリガナ）はカタカナで入力してください。'),
  role_code: yup.string().required(),
  dob: yup.string(),
  doj: yup.string(),
  main_position: yup.object().shape({
    position_id: yup.string().required(),
    affiliation_id: yup.string().required(),
    positions_code: yup.string().required(),
  }),
});

export const administratorMasterSchema = yup.object().shape({
  name: yup
    .string()
    .required('法人名は必須入力項目です。')
    .max(100, '最大100文字で入力してください。'),
  name_furigana: yup
    .string()
    .required('法人名（フリガナ）は必須入力項目です。')
    .max(100, '最大100文字で入力してください。')
    .matches(REGEX_KATAKANA, '法人名（フリガナ）はカタカナで入力してください。'),
  postal_code: yup
    .string()
    .required('郵便番号は必須入力項目です。')
    .matches(REGEX_HALF_WIDTH_NUMBER, '郵便番号は数字で入力してください。')
    .min(7, '7文字で入力してください。')
    .max(7, '7文字で入力してください。'),
  prefecture: yup
    .string()
    .required('都道府県は必須入力項目です。')
    .matches(REGEX_KANJI, '都道府県は漢字で入力してください。')
    .min(3, '3文字以上4文字以下で入力してください。')
    .max(4, '3文字以上4文字以下で入力してください。'),
  address: yup
    .string()
    .required('市区町村は必須入力項目です。')
    .max(100, '最大100文字で入力してください。'),
  plot_number: yup
    .string()
    .required('番地は必須入力項目です。')
    .max(100, '最大100文字で入力してください。'),
  building_name: yup.string().max(100, '最大100文字で入力してください。'),
  admin_name: yup
    .string()
    .required('最大100文字で入力してください。')
    .max(100, '最大100文字で入力してください。'),
  admin_name_furigana: yup
    .string()
    .required('最大100文字で入力してください。')
    .max(100, '最大100文字で入力してください。'),
  admin_department: yup
    .string()
    .required('最大100文字で入力してください。')
    .max(100, '最大100文字で入力してください。'),
  admin_position: yup
    .string()
    .required('最大100文字で入力してください。')
    .max(100, '最大100文字で入力してください。'),
  admin_phone: yup
    .string()
    .required('電話番号は必須入力項目です。')
    .matches(REGEX_HALF_WIDTH_NUMBER, '電話番号は数字で入力してください。')
    .min(10, '10文字以上15文字以下（ハイフン無し）で入力してください。')
    .max(15, '10文字以上15文字以下（ハイフン無し）で入力してください。'),
  admin_email: yup
    .string()
    .email('メールアドレスを正しく入力してください（半角英数字のみ）')
    .required('最大100文字で入力してください。')
    .max(100, '最大100文字で入力してください。'),
  fax: yup
    .string()
    .required('FAXは必須入力項目です。')
    .test('matches-regex', 'FAXは数字で入力してください。', function (value, context) {
      const { originalValue } = context as yup.TestContext & TestContextExtended;
      return REGEX_HALF_WIDTH_NUMBER_AND_HYPHEN.test(originalValue ?? value ?? '');
    })
    .transform(function (value) {
      if (value && value.toString().includes('-')) {
        return value.replace(/-/g, '');
      }
      return value;
    })
    .min(10, '10文字以上20文字以下（ハイフンあり・無しどちらでも可）で入力してください。')
    .max(20, '10文字以上20文字以下（ハイフンあり・無しどちらでも可）で入力してください。'),
  business_registration_number: yup
    .string()
    .required('登録番号は必須入力項目です。')
    .max(14, '最大14文字（「T」+「法人番号（13文字）」）で入力してください。')
    .matches(/^T\d{13}$/, '最大14文字（「T」+「法人番号（13文字）」）で入力してください。'),
  bank_name: yup
    .string()
    .required('銀行名（請求書記載振込先）は必須入力項目です。')
    .max(100, '最大100文字で入力してください。'),
  bank_code: yup
    .string()
    .required('銀行コード（請求書記載振込先）は必須入力項目です。')
    .matches(REGEX_HALF_WIDTH_NUMBER, '銀行コード（請求書記載振込先）は数字で入力してください。')
    .max(4, '最大4文字で入力してください。'),
  branch_name: yup
    .string()
    .required('支店名（請求書記載振込先）は必須入力項目です。')
    .max(100, '最大100文字で入力してください。'),
  branch_code: yup
    .string()
    .required('支店コード（請求書記載振込先）は必須入力項目です。')
    .matches(REGEX_HALF_WIDTH_NUMBER, '支店コード（請求書記載振込先）は数字で入力してください。')
    .max(3, '最大3文字で入力してください。'),
  bank_account_type: yup
    .string()
    .required('口座種別（請求書記載振込先）は必須入力項目です。')
    .max(100, '最大100文字で入力してください。'),
  bank_account_number: yup
    .string()
    .required('口座番号（請求書記載振込先）は必須入力項目です。')
    .matches(REGEX_HALF_WIDTH_NUMBER, '口座番号（請求書記載振込先）は数字で入力してください。')
    .min(7, '7文字で入力してください。'),
});

export const importEmployeeCsvSchema = yup.array().of(
  yup.object().shape({
    employee_code: yup
      .string()
      .required('社員番号は必須項目です。')
      .max(10, '社員番号は10以内の値を入力してください。')
      .matches(REGEX_HALF_WIDTH_LATIN, {
        message: '社員番号は半角英数字、記号で入力してください。',
      }),
    name: yup
      .string()
      .required('氏名は必須項目です。')
      .max(100, '氏名は100以内の値を入力してください。'),
    name_furigana: yup
      .string()
      .required('氏名（フリガナ）は必須項目です。')
      .matches(REGEX_KATAKANA_FULL_WIDTH, {
        message: '氏名（フリガナ）が不正です。',
      })
      .max(100, '氏名（フリガナ）は100以内の値を入力してください。'),
    affiliation_id: yup
      .string()
      .required('所属コードは必須項目です。')
      .max(10, '所属コードは10以内の値を入力してください。')
      .matches(REGEX_HALF_WIDTH_LATIN, {
        message: '所属コードは半角英数字、記号で入力してください。',
      }),
    position_code: yup
      .string()
      .required('役職コードは必須項目です。')
      .max(10, '役職コードは10以内の値を入力してください。')
      .matches(REGEX_HALF_WIDTH_LATIN, {
        message: '役職コードは半角英数字、記号で入力してください。',
      }),
    birthday: yup
      .string()
      .max(10, '生年月日は10以内の値を入力してください。')
      .test(
        '生年月日',
        '生年月日が不正です。',
        (value) => !value || dayjs(value, DATE_FORMAT, true).isValid()
      ),
    hire_date: yup
      .string()
      .max(7, '入社年月は7以内の値を入力してください。')
      .test(
        '入社年月',
        '入社年月が不正です。',
        (value) => !value || dayjs(value, YEAR_MONTH_FORMAT, true).isValid()
      ),
    role_code: yup
      .string()
      .required('権限コードは必須項目です。')
      .max(9, '権限コードは9以内の値を入力してください。')
      .matches(REGEX_HALF_WIDTH_LATIN, {
        message: '権限コードは半角英数字、記号で入力してください。',
      }),
    email: yup
      .string()
      .email('メールアドレスが不正です。')
      .required('メールアドレスは必須項目です。')
      .max(254, 'メールアドレスは254以内の値を入力してください。'),
    password: yup
      .string()
      .required('パスワードは必須項目です。')
      .max(30, 'パスワードは30以内の値を入力してください。')
      .min(4, 'パスワードの最小文字数は4です。')
      .matches(REGEX_HALF_WIDTH_LATIN, {
        message: 'パスワードは半角英数字、記号で入力してください。',
      }),
  })
);
