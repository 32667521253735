import React from 'react';

import { UploadFile, RcFile } from 'antd/lib/upload/interface';
import { findKey, flatMap, isString } from 'lodash';
import { FormikProps } from 'formik';

import { getQuestionAssignedLevel, getQuestionAssignedToCurriculum } from 'pages/Curriculum/thunk';
import { deleteFileInR2, getPresignedUrlByFileID, uploadFileToR2 } from 'services/r2Service';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { FileType, FileUploadStatus } from 'constant/enum.constant';
import { config, DISPLAY_ID_KEY, QUESTIONS } from 'configs';
import * as Types from 'types';
import {
  executeActionUploadFile,
  getQuestionDetail,
  deleteFileAttach,
  createQuestion,
  editAttachment,
  getOptionLimit,
  createNewFile,
  editQuestion,
  uploadFile,
  getQuestionDetailPartner,
} from 'containers/CreateEditQuestion/thunk';
import { QUESTION_ATTACH_MAPPING } from 'constant';
import { browserLogger } from 'libs/logger';

const handleOnProgress = ({
  file,
  progressEvent,
  setUploading,
}: {
  progressEvent: any;
  file: UploadFile;
  setUploading: React.Dispatch<React.SetStateAction<Types.UploadFileToServerModel>>;
}) => {
  const completed: number = parseInt(
    String(Math.round((progressEvent.loaded * 100) / progressEvent.total))
  );
  setUploading((prevState: Types.UploadFileToServerModel) => {
    if (prevState.files.length === 0) {
      return {
        show: true,
        files: [
          {
            uid: file.uid,
            name: file.name,
            percentage: completed,
            status: FileUploadStatus.active,
          },
        ],
      };
    }
    let flag = false;
    const tempUploadFiles: Types.FileToUpload[] = prevState.files.map(
      (uploadFileToServer: Types.FileToUpload) => {
        if (uploadFileToServer.uid === file.uid) {
          flag = true;
          return { ...uploadFileToServer, percentage: completed - 1 };
        }
        return { ...uploadFileToServer };
      }
    );
    if (!flag) {
      return {
        ...prevState,
        files: [
          ...prevState.files,
          {
            uid: file.uid,
            name: file.name,
            percentage: completed,
            status: FileUploadStatus.active,
          },
        ],
      };
    }
    return { ...prevState, files: tempUploadFiles };
  });
};

const uploadFileFn = async ({
  item,
  files,
  changes,
  fieldId,
  userInfo,
  dispatch,
  fieldName,
  setUploading,
  filesInServer,
}: {
  fieldName:
    | 'attach'
    | 'attach1'
    | 'attach2'
    | 'attach3'
    | 'comment_attach'
    | 'comment_attach1'
    | 'comment_attach2'
    | 'comment_attach3'
    | 'problems1_attach'
    | 'problems2_attach'
    | 'problems3_attach';
  fieldId: string;
  dispatch: Types.AppDispatch;
  filesInServer: string[];
  files: UploadFile<File>[];
  userInfo: Types.Users.ResponseType | undefined;
  item: { [key: string]: string };
  changes: Array<Types.ChangesExecuteActionType>;
  setUploading: React.Dispatch<React.SetStateAction<Types.UploadFileToServerModel>>;
}) => {
  dispatch(startLoading());
  if (files.length > 0) {
    const resultActionUploadFile = await Promise.all(
      files.map(async (file, index) => {
        if (isString(file)) return;
        const data = {
          item: {
            filename: file.name,
            file_location: '2',
            file_extension: file.type,
            file_size: `${file.size}`,
            company_id: userInfo?.company_id,
            createdat: new Date(),
            createdby: userInfo?.login_id,
          },
          return_item_result: true,
          access_key_updates: {
            roles_to_publish: ['MEMBER'],
          },
        };
        const resultCreateNewQuestionAction = await dispatch(createNewFile(data));
        if (createNewFile.fulfilled.match(resultCreateNewQuestionAction)) {
          const formData = new FormData();
          formData.append('application_id', config.APP_ID);
          formData.append('datastore_id', QUESTIONS.name);
          formData.append('filename', file.name);
          formData.append('file', new Blob([file.originFileObj as Blob]));
          await dispatch(
            uploadFile({
              itemId: resultCreateNewQuestionAction.payload.item_id,
              fieldId,
              data: formData,
              uid: file.uid,
              onProgress: (progressEvent: any) =>
                handleOnProgress({ progressEvent, file, setUploading }),
            })
          );
          browserLogger.info('utils/question  uploadFileFn', fieldId, file);
          const uploadToMinIo = await uploadFileToR2(file.originFileObj as UploadFile<File>);
          const resultEditAttachment = await dispatch(
            editAttachment({
              id: resultCreateNewQuestionAction.payload.item_id,
              data: {
                item: { fileID: uploadToMinIo },
                return_item_result: true,
                is_force_update: true,
              },
            })
          );
          const updateState = editAttachment.rejected.match(resultEditAttachment)
            ? { status: FileUploadStatus.exception }
            : { percentage: 100, status: FileUploadStatus.active };
          setUploading((prevState) => {
            const tempUploadFiles: Types.FileToUpload[] = prevState.files.map(
              (uploadingFile: Types.FileToUpload) => {
                if (uploadingFile.uid === file.uid) {
                  return { ...uploadingFile, ...updateState };
                }
                return { ...uploadingFile };
              }
            );
            return { ...prevState, files: tempUploadFiles };
          });
          return uploadToMinIo;
        }
      })
    );
    resultActionUploadFile.forEach((result, index) => {
      if (result) {
        const changeIndex = changes.findIndex(
          (change) => change.id === `${fieldName}${index + 1}` || change.id === fieldName
        );
        if (
          fieldName === 'attach1' ||
          fieldName === 'attach2' ||
          fieldName === 'attach3' ||
          fieldName === 'comment_attach1' ||
          fieldName === 'comment_attach2' ||
          fieldName === 'comment_attach3'
        ) {
          if (changeIndex > -1) {
            changes[changeIndex].value.push(result);
          } else {
            changes.push({
              id: `${fieldName}`,
              value: [result],
            });
          }
          const number = fieldName.match(/\d+/);

          if (number) {
            const fieldIDWithNumber = `${fieldName.replace(/\d+$/, '')}_fileID${number[0]}`;
            const fieldNameWithNumber = `${fieldName.replace(/\d+$/, '')}_filename${number[0]}`;
            item[fieldIDWithNumber] = '';
            item[fieldNameWithNumber] = '';
            item[fieldIDWithNumber] = [item[fieldIDWithNumber], result].filter(Boolean).toString();
            item[fieldNameWithNumber] = [item[fieldNameWithNumber], files[index].name]
              .filter(Boolean)
              .toString();
          }
        } else if (
          fieldName === 'problems1_attach' ||
          fieldName === 'problems2_attach' ||
          fieldName === 'problems3_attach'
        ) {
          if (changeIndex > -1) {
            changes[changeIndex].value.push(result);
          } else {
            changes.push({
              id: fieldName,
              value: [result],
            });
          }
          item[`${fieldName}_fileID`] = [item[`${fieldName}_fileID`], result]
            .filter(Boolean)
            .toString();
          item[`${fieldName}_filename`] = [item[`${fieldName}_filename`], files[index].name]
            .filter(Boolean)
            .toString();
        }
      }
    });
  } else {
    if (
      fieldName === 'attach1' ||
      fieldName === 'attach2' ||
      fieldName === 'attach3' ||
      fieldName === 'comment_attach1' ||
      fieldName === 'comment_attach2' ||
      fieldName === 'comment_attach3'
    ) {
      changes.push({
        id: fieldName,
        value: [''],
      });
      const number = fieldName.match(/\d+/);
      if (number) {
        const fieldIDWithNumber = `${fieldName.replace(/\d+$/, '')}_fileID${number[0]}`;
        const fieldNameWithNumber = `${fieldName.replace(/\d+$/, '')}_filename${number[0]}`;
        item[fieldIDWithNumber] = '';
        item[fieldNameWithNumber] = '';
      }
    } else if (
      fieldName === 'problems1_attach' ||
      fieldName === 'problems2_attach' ||
      fieldName === 'problems3_attach'
    ) {
      changes.push({
        id: fieldName,
        value: [''],
      });
      item[`${fieldName}_fileID`] = '';
      item[`${fieldName}_filename`] = '';
    }
  }
  dispatch(stopLoading());
};

export const getTimeLimitOption = (payload: Types.GetDatastoreFieldsResponseType) => {
  const timeLimitResponse = Object.values(payload.fields || {}).find(
    (obj) => obj.display_id === DISPLAY_ID_KEY.TIME_LIMIT.name
  );
  browserLogger.info('utils/thunk/question', DISPLAY_ID_KEY.TIME_LIMIT.name, timeLimitResponse);
  if (timeLimitResponse && timeLimitResponse.options) {
    return (
      timeLimitResponse.options
        ?.filter((o: Types.TimeLimitType) => o.enabled)
        .sort((a: Types.TimeLimitType, b: Types.TimeLimitType) => a.sort_id - b.sort_id) || []
    );
  }
  return [];
};

export const getListChangesAndUpdateItem = async ({
  itemId,
  values,
  dispatch,
  setUploading,
  filesInServer,
  userInfo,
}: {
  itemId: string;
  dispatch: Types.AppDispatch;
  filesInServer: string[];
  values: Types.CreateEditQuestionFormik;
  userInfo: Types.Users.ResponseType | undefined;
  setUploading: React.Dispatch<React.SetStateAction<Types.UploadFileToServerModel>>;
}): Promise<{
  item: { [key: string]: string };
}> => {
  dispatch(startLoading());
  const item: { [key: string]: string } = {};
  const changes: Array<Types.ChangesExecuteActionType> = [];
  await Promise.all([
    uploadFileFn({
      fieldId: DISPLAY_ID_KEY.problems1_attach.name,
      dispatch,
      filesInServer,
      setUploading,
      files: values.problems1_attach,
      fieldName: 'problems1_attach',
      changes,
      item,
      userInfo,
    }),
    uploadFileFn({
      fieldId: DISPLAY_ID_KEY.problems2_attach.name,
      dispatch,
      filesInServer,
      setUploading,
      files: values.problems2_attach,
      fieldName: 'problems2_attach',
      changes,
      item,
      userInfo,
    }),
    uploadFileFn({
      fieldId: DISPLAY_ID_KEY.problems3_attach.name,
      dispatch,
      filesInServer,
      setUploading,
      files: values.problems3_attach,
      fieldName: 'problems3_attach',
      changes,
      item,
      userInfo,
    }),
    uploadFileFn({
      fieldId: DISPLAY_ID_KEY.attach1.name,
      dispatch,
      filesInServer,
      setUploading,
      files: values.attach1,
      fieldName: 'attach1',
      changes,
      item,
      userInfo,
    }),
    uploadFileFn({
      fieldId: DISPLAY_ID_KEY.attach2.name,
      dispatch,
      filesInServer,
      setUploading,
      files: values.attach2,
      fieldName: 'attach2',
      changes,
      item,
      userInfo,
    }),
    uploadFileFn({
      fieldId: DISPLAY_ID_KEY.attach3.name,
      dispatch,
      filesInServer,
      setUploading,
      files: values.attach3,
      fieldName: 'attach3',
      changes,
      item,
      userInfo,
    }),
    uploadFileFn({
      fieldId: DISPLAY_ID_KEY.comment_attach1.name,
      dispatch,
      filesInServer,
      setUploading,
      files: values.comment_attach1,
      fieldName: 'comment_attach1',
      changes,
      item,
      userInfo,
    }),
    uploadFileFn({
      fieldId: DISPLAY_ID_KEY.comment_attach2.name,
      dispatch,
      filesInServer,
      setUploading,
      files: values.comment_attach2,
      fieldName: 'comment_attach2',
      changes,
      item,
      userInfo,
    }),
    uploadFileFn({
      fieldId: DISPLAY_ID_KEY.comment_attach3.name,
      dispatch,
      filesInServer,
      setUploading,
      files: values.comment_attach3,
      fieldName: 'comment_attach3',
      changes,
      item,
      userInfo,
    }),
  ]);
  if (!changes.every((i) => !i.value[0])) {
    await dispatch(
      executeActionUploadFile({
        itemId,
        data: {
          changes,
          is_force_update: true,
        },
      })
    );
  }
  dispatch(stopLoading());

  return { item };
};

export const handleSubmit = async ({
  type,
  values,
  i_id,
  dispatch,
  userInfo,
  setUploading,
  handleCancel,
  filesInServer,
  fileIdsToDelete,
  handleFetchData,
  setFilesInServer,
  setFileIdsToDelete,
  setShowActionErrorModal,
}: {
  i_id?: string;
  dispatch: Types.AppDispatch;
  handleCancel: Function;
  filesInServer: string[];
  type: 'create' | 'edit';
  fileIdsToDelete: string[];
  values: Types.CreateEditQuestionFormik;
  handleFetchData?: () => Promise<void> | void;
  userInfo: Types.Users.ResponseType | undefined;
  setFilesInServer: React.Dispatch<React.SetStateAction<string[]>>;
  setFileIdsToDelete: React.Dispatch<React.SetStateAction<string[]>>;
  setUploading: React.Dispatch<React.SetStateAction<Types.UploadFileToServerModel>>;
  setShowActionErrorModal: React.Dispatch<React.SetStateAction<Types.ErrorModelToOpen>>;
}) => {
  dispatch(startLoading());
  if (type === 'create') {
    const resultAction = await dispatch(
      createQuestion({
        item: {
          name: values.name,
          description: values.description,
          problems1: values.problems1,
          problems2: values.problems2,
          problems3: values.problems3,
          answer: values.answer,
          comment: values.comment,
          time_limit: values.time_limit,
          creator: userInfo?.name,
          company_id: userInfo?.company_id,
          score: values.score,
          createdat: new Date(),
          createdby: userInfo?.login_id,
        },
        return_item_result: true,
        access_key_updates: {
          roles_to_publish: ['ADMIN', 'MEMBER'],
        },
      })
    );
    if (createQuestion.fulfilled.match(resultAction)) {
      const { item } = await getListChangesAndUpdateItem({
        itemId: resultAction.payload.item_id,
        filesInServer,
        values,
        userInfo,
        dispatch,
        setUploading,
      });
      if (!Object.values(item).every((i) => !i[0])) {
        const resultEditQuestionAction = await dispatch(
          editQuestion({
            id: resultAction.payload.item_id,
            data: {
              item,
              return_item_result: true,
              is_force_update: true,
            },
          })
        );
        if (editQuestion.fulfilled.match(resultEditQuestionAction)) {
          setFilesInServer([]);
          setFileIdsToDelete([]);
          setUploading({ show: false, files: [] });
        } else {
          setShowActionErrorModal({
            errorVisible: true,
            description: '設問情報の更新に失敗しました。 再度入力し、お試しください。',
            subTitle: '更新に失敗しました',
          });
        }
      }
      handleFetchData && (await handleFetchData());
      handleCancel();
    } else {
      setShowActionErrorModal({
        errorVisible: true,
        description: '設問情報の更新に失敗しました。 再度入力し、お試しください。',
        subTitle: '更新に失敗しました',
      });
    }
  } else {
    if (i_id) {
      for (const fileId of fileIdsToDelete) {
        await Promise.all([
          deleteFileInR2(fileId),
          dispatch(
            deleteFileAttach({
              conditions: [{ id: 'fileID', search_value: [fileId], exact_match: true }],
            })
          ),
        ]);
      }
      const { item } = await getListChangesAndUpdateItem({
        itemId: i_id,
        values,
        dispatch,
        filesInServer,
        userInfo,
        setUploading,
      });
      const actionResultEditQuestion = await dispatch(
        editQuestion({
          id: i_id,
          data: {
            item: {
              name: values.name,
              description: values.description,
              problems1: values.problems1,
              problems2: values.problems2,
              problems3: values.problems3,
              answer: values.answer,
              comment: values.comment,
              time_limit: values.time_limit,
              score: values.score,
              ...item,
            },
            return_item_result: true,
            is_force_update: true,
          },
        })
      );
      if (editQuestion.fulfilled.match(actionResultEditQuestion)) {
        handleFetchData && (await handleFetchData());
        setFilesInServer([]);
        setFileIdsToDelete([]);
        setUploading({ show: false, files: [] });
        handleCancel();
      } else {
        setShowActionErrorModal({
          errorVisible: true,
          description: '設問情報の更新に失敗しました。 再度入力し、お試しください。',
          subTitle: '更新に失敗しました',
        });
      }
    }
  }
  dispatch(stopLoading());
};

export const handleGetQuestionDetail = async ({
  type,
  i_id,
  formik,
  dispatch,
  handleCancel,
  setFilesInServer,
  setQuestionDetail,
  setResponseQuestionDetail,
}: {
  i_id?: string;
  dispatch: Types.AppDispatch;
  handleCancel: Function;
  type: 'edit' | 'create';
  formik: FormikProps<Types.CreateEditQuestionFormik>;
  setFilesInServer?: React.Dispatch<React.SetStateAction<string[]>>;
  setQuestionDetail?: React.Dispatch<
    React.SetStateAction<Types.CreateEditQuestionFormik | undefined>
  >;
  setResponseQuestionDetail?: React.Dispatch<
    React.SetStateAction<Types.Questions.ResponseType | undefined>
  >;
}) => {
  dispatch(startLoading());
  const resultActionGetOptionLimit = await dispatch(getOptionLimit());
  if (type === 'edit') {
    const resultActionGetQuestionDetail = await dispatch(
      getQuestionDetail({
        page: 1,
        per_page: 1,
        conditions: [
          {
            id: 'i_id',
            search_value: [i_id],
            exact_match: true,
          },
        ],
      })
    );
    if (getQuestionDetail.rejected.match(resultActionGetQuestionDetail)) {
      dispatch(stopLoading());
      handleCancel();
      return;
    }
    const item = resultActionGetQuestionDetail.payload.items[0];
    if (!item) {
      dispatch(stopLoading());
      handleCancel();
      return;
    }
    setResponseQuestionDetail && setResponseQuestionDetail(item);

    setFilesInServer &&
      setFilesInServer(
        [
          ...(item.attach_fileID1 ? item.attach_fileID1.split(',') : []),
          ...(item.attach_fileID2 ? item.attach_fileID2.split(',') : []),
          ...(item.attach_fileID3 ? item.attach_fileID3.split(',') : []),
          ...(item.problems1_attach_fileID ? item.problems1_attach_fileID.split(',') : []),
          ...(item.problems2_attach_fileID ? item.problems2_attach_fileID.split(',') : []),
          ...(item.problems3_attach_fileID ? item.problems3_attach_fileID.split(',') : []),
          ...(item.comment_attach_fileID1 ? item.comment_attach_fileID1.split(',') : []),
          ...(item.comment_attach_fileID2 ? item.comment_attach_fileID2.split(',') : []),
          ...(item.comment_attach_fileID3 ? item.comment_attach_fileID3.split(',') : []),
        ].filter(Boolean)
      );

    const questionDetail = {
      name: item.name || '',
      description: item.description || '',
      problems1: item.problems1 || '',
      problems2: item.problems2 || '',
      problems3: item.problems3 || '',
      answer: item.answer || '',
      time_limit: getOptionLimit.fulfilled.match(resultActionGetOptionLimit)
        ? getTimeLimitOption(resultActionGetOptionLimit.payload).find(
            (v: Types.TimeLimitType) => v.value === item.time_limit
          )?.option_id || ''
        : '',
      comment: item.comment || '',
      score: Number(item.score || 1),
      attach: [],
      comment_attach: [],
      problems1_attach: [],
      problems2_attach: [],
      problems3_attach: [],
      attachFileID: [],
      commentAttachFileID: [],
      problems1AttachFileID: [],
      problems2AttachFileID: [],
      problems3AttachFileID: [],
      commentFileID1: [],
      commentFileID2: [],
      commentFileID3: [],
      attachFileID1: [],
      attachFileID2: [],
      attachFileID3: [],
      comment_attach1: [],
      comment_attach2: [],
      comment_attach3: [],
      attach1: [],
      attach2: [],
      attach3: [],
    };

    formik.setValues(questionDetail);
    setQuestionDetail && setQuestionDetail(questionDetail);
  }

  dispatch(stopLoading());
};

export const handleGetFileQuestionDetail = async ({
  type,
  i_id,
  formik,
  dispatch,
  handleCancel,
  setFilesInServer,
  setQuestionDetail,
  setResponseQuestionDetail,
}: {
  i_id?: string;
  dispatch: Types.AppDispatch;
  handleCancel: Function;
  type?: 'edit' | 'create';
  formik: FormikProps<Types.CreateEditQuestionFormik>;
  setFilesInServer?: React.Dispatch<React.SetStateAction<string[]>>;
  setQuestionDetail?: React.Dispatch<
    React.SetStateAction<Types.CreateEditQuestionFormik | undefined>
  >;
  setResponseQuestionDetail?: React.Dispatch<
    React.SetStateAction<Types.Questions.ResponseType | undefined>
  >;
}) => {
  dispatch(startLoading());
  const resultActionGetOptionLimit = await dispatch(getOptionLimit());
  if (type === 'edit') {
    const resultActionGetQuestionDetail = await dispatch(
      getQuestionDetail({
        page: 1,
        per_page: 1,
        conditions: [
          {
            id: 'i_id',
            search_value: [i_id],
            exact_match: true,
          },
        ],
      })
    );
    if (getQuestionDetail.rejected.match(resultActionGetQuestionDetail)) {
      dispatch(stopLoading());
      handleCancel();
      return;
    }
    const item = resultActionGetQuestionDetail.payload.items[0];
    if (!item) {
      dispatch(stopLoading());
      handleCancel();
      return;
    }

    setResponseQuestionDetail && setResponseQuestionDetail(item);

    const [
      resultActionGetFilesAttach1,
      resultActionGetFilesAttach2,
      resultActionGetFilesAttach3,
      resultActionGetFilesProblems1Attach,
      resultActionGetFilesProblems2Attach,
      resultActionGetFilesProblems3Attach,
      resultActionGetFilesCommentAttach1,
      resultActionGetFilesCommentAttach2,
      resultActionGetFilesCommentAttach3,
    ] = await Promise.all([
      item.attach_fileID1 && getPresignedUrlByFileID(item.attach_fileID1),
      item.attach_fileID2 && getPresignedUrlByFileID(item.attach_fileID2),
      item.attach_fileID3 && getPresignedUrlByFileID(item.attach_fileID3),
      item.problems1_attach_fileID && getPresignedUrlByFileID(item.problems1_attach_fileID),
      item.problems2_attach_fileID && getPresignedUrlByFileID(item.problems2_attach_fileID),
      item.problems3_attach_fileID && getPresignedUrlByFileID(item.problems3_attach_fileID),
      item.comment_attach_fileID1 && getPresignedUrlByFileID(item.comment_attach_fileID1),
      item.comment_attach_fileID2 && getPresignedUrlByFileID(item.comment_attach_fileID2),
      item.comment_attach_fileID3 && getPresignedUrlByFileID(item.comment_attach_fileID3),
    ]);

    setFilesInServer &&
      setFilesInServer(
        [
          ...(item.attach_fileID1 ? item.attach_fileID1.split(',') : []),
          ...(item.attach_fileID2 ? item.attach_fileID2.split(',') : []),
          ...(item.attach_fileID3 ? item.attach_fileID3.split(',') : []),
          ...(item.problems1_attach_fileID ? item.problems1_attach_fileID.split(',') : []),
          ...(item.problems2_attach_fileID ? item.problems2_attach_fileID.split(',') : []),
          ...(item.problems3_attach_fileID ? item.problems3_attach_fileID.split(',') : []),
          ...(item.comment_attach_fileID1 ? item.comment_attach_fileID1.split(',') : []),
          ...(item.comment_attach_fileID2 ? item.comment_attach_fileID2.split(',') : []),
          ...(item.comment_attach_fileID3 ? item.comment_attach_fileID3.split(',') : []),
        ].filter(Boolean)
      );
    const attach: Array<string> = [];
    const attach1: Array<string> = [];
    const attach2: Array<string> = [];
    const attach3: Array<string> = [];
    const attachFileID: Array<string> = [];
    const attachFileID1: Array<string> = [];
    const attachFileID2: Array<string> = [];
    const attachFileID3: Array<string> = [];
    const problems1_attach: Array<string> = [];
    const problems2_attach: Array<string> = [];
    const problems3_attach: Array<string> = [];
    const comment_attach: Array<string> = [];
    const comment_attach1: Array<string> = [];
    const comment_attach2: Array<string> = [];
    const comment_attach3: Array<string> = [];
    const commentAttachFileID: Array<string> = [];
    const commentFileID1: Array<string> = [];
    const commentFileID2: Array<string> = [];
    const commentFileID3: Array<string> = [];
    const problems1AttachFileID: Array<string> = [];
    const problems2AttachFileID: Array<string> = [];
    const problems3AttachFileID: Array<string> = [];

    if (resultActionGetFilesAttach1) {
      attach1.push(resultActionGetFilesAttach1);
      attachFileID1.push(item.attach_fileID1);
    }

    if (resultActionGetFilesAttach2) {
      attach2.push(resultActionGetFilesAttach2);
      attachFileID2.push(item.attach_fileID2);
    }

    if (resultActionGetFilesAttach3) {
      attach3.push(resultActionGetFilesAttach3);
      attachFileID3.push(item.attach_fileID3);
    }

    if (resultActionGetFilesProblems1Attach) {
      problems1_attach.push(resultActionGetFilesProblems1Attach);
      problems1AttachFileID.push(item.problems1_attach_fileID);
    }

    if (resultActionGetFilesProblems2Attach) {
      problems2_attach.push(resultActionGetFilesProblems2Attach);
      problems2AttachFileID.push(item.problems2_attach_fileID);
    }

    if (resultActionGetFilesProblems3Attach) {
      problems3_attach.push(resultActionGetFilesProblems3Attach);
      problems3AttachFileID.push(item.problems3_attach_fileID);
    }

    if (resultActionGetFilesCommentAttach1) {
      comment_attach1.push(resultActionGetFilesCommentAttach1);
      commentFileID2.push(item.comment_attach_fileID1);
    }

    if (resultActionGetFilesCommentAttach2) {
      comment_attach2.push(resultActionGetFilesCommentAttach2);
      commentFileID2.push(item.comment_attach_fileID2);
    }

    if (resultActionGetFilesCommentAttach3) {
      comment_attach3.push(resultActionGetFilesCommentAttach3);
      commentFileID3.push(item.comment_attach_fileID3);
    }

    const questionDetail = {
      name: item.name || '',
      description: item.description || '',
      problems1: item.problems1 || '',
      problems2: item.problems2 || '',
      problems3: item.problems3 || '',
      answer: item.answer || '',
      time_limit: getOptionLimit.fulfilled.match(resultActionGetOptionLimit)
        ? getTimeLimitOption(resultActionGetOptionLimit.payload).find(
            (v: Types.TimeLimitType) => v.value === item.time_limit
          )?.option_id || ''
        : '',
      comment: item.comment || '',
      score: Number(item.score || 1),
      attachFileID,
      commentAttachFileID,
      problems1AttachFileID,
      problems2AttachFileID,
      problems3AttachFileID,
      attach: attach.filter(Boolean) as unknown as UploadFile<File>[],
      comment_attach: comment_attach.filter(Boolean) as unknown as UploadFile<File>[],
      problems1_attach: problems1_attach.filter(Boolean) as unknown as UploadFile<File>[],
      problems2_attach: problems2_attach.filter(Boolean) as unknown as UploadFile<File>[],
      problems3_attach: problems3_attach.filter(Boolean) as unknown as UploadFile<File>[],
      commentFileID1,
      commentFileID2,
      commentFileID3,
      attachFileID1,
      attachFileID2,
      attachFileID3,
      comment_attach1: comment_attach1.filter(Boolean) as unknown as UploadFile<File>[],
      comment_attach2: comment_attach2.filter(Boolean) as unknown as UploadFile<File>[],
      comment_attach3: comment_attach3.filter(Boolean) as unknown as UploadFile<File>[],
      attach1: attach1.filter(Boolean) as unknown as UploadFile<File>[],
      attach2: attach2.filter(Boolean) as unknown as UploadFile<File>[],
      attach3: attach3.filter(Boolean) as unknown as UploadFile<File>[],
    };

    formik.setValues(questionDetail);
    setQuestionDetail && setQuestionDetail(questionDetail);
  }

  dispatch(stopLoading());
};

export const handleGetQuestionDetailPartner = async ({
  type,
  i_id,
  formik,
  dispatch,
  handleCancel,
  setFilesInServer,
  setQuestionDetail,
  setResponseQuestionDetail,
}: {
  i_id?: string;
  dispatch: Types.AppDispatch;
  handleCancel: Function;
  type: 'edit' | 'create';
  formik: FormikProps<Types.CreateEditQuestionFormik>;
  setFilesInServer?: React.Dispatch<React.SetStateAction<string[]>>;
  setQuestionDetail?: React.Dispatch<
    React.SetStateAction<Types.CreateEditQuestionFormik | undefined>
  >;
  setResponseQuestionDetail?: React.Dispatch<
    React.SetStateAction<Types.Questions.ResponseType | undefined>
  >;
}) => {
  dispatch(startLoading());
  const resultActionGetOptionLimit = await dispatch(getOptionLimit());
  if (type === 'edit') {
    const resultActionGetQuestionDetail = await dispatch(
      getQuestionDetailPartner({
        page: 1,
        per_page: 1,
        conditions: [
          {
            id: 'i_id',
            search_value: [i_id],
            exact_match: true,
          },
        ],
      })
    );
    if (getQuestionDetail.rejected.match(resultActionGetQuestionDetail)) {
      dispatch(stopLoading());
      handleCancel();
      return;
    }
    const item = resultActionGetQuestionDetail.payload.items[0];
    if (!item) {
      dispatch(stopLoading());
      handleCancel();
      return;
    }
    setResponseQuestionDetail && setResponseQuestionDetail(item);

    setFilesInServer &&
      setFilesInServer(
        [
          ...(item.attach_fileID1 ? item.attach_fileID1.split(',') : []),
          ...(item.attach_fileID2 ? item.attach_fileID2.split(',') : []),
          ...(item.attach_fileID3 ? item.attach_fileID3.split(',') : []),
          ...(item.problems1_attach_fileID ? item.problems1_attach_fileID.split(',') : []),
          ...(item.problems2_attach_fileID ? item.problems2_attach_fileID.split(',') : []),
          ...(item.problems3_attach_fileID ? item.problems3_attach_fileID.split(',') : []),
          ...(item.comment_attach_fileID1 ? item.comment_attach_fileID1.split(',') : []),
          ...(item.comment_attach_fileID2 ? item.comment_attach_fileID2.split(',') : []),
          ...(item.comment_attach_fileID3 ? item.comment_attach_fileID3.split(',') : []),
        ].filter(Boolean)
      );

    const questionDetail = {
      name: item.name || '',
      description: item.description || '',
      problems1: item.problems1 || '',
      problems2: item.problems2 || '',
      problems3: item.problems3 || '',
      answer: item.answer || '',
      time_limit: getOptionLimit.fulfilled.match(resultActionGetOptionLimit)
        ? getTimeLimitOption(resultActionGetOptionLimit.payload).find(
            (v: Types.TimeLimitType) => v.value === item.time_limit
          )?.option_id || ''
        : '',
      comment: item.comment || '',
      score: Number(item.score || 1),
      attach: [],
      comment_attach: [],
      problems1_attach: [],
      problems2_attach: [],
      problems3_attach: [],
      attachFileID: [],
      commentAttachFileID: [],
      problems1AttachFileID: [],
      problems2AttachFileID: [],
      problems3AttachFileID: [],
      commentFileID1: [],
      commentFileID2: [],
      commentFileID3: [],
      attachFileID1: [],
      attachFileID2: [],
      attachFileID3: [],
      comment_attach1: [],
      comment_attach2: [],
      comment_attach3: [],
      attach1: [],
      attach2: [],
      attach3: [],
    };

    formik.setValues(questionDetail);
    setQuestionDetail && setQuestionDetail(questionDetail);
  }

  dispatch(stopLoading());
};

export const handleGetFileQuestionDetailPartner = async ({
  type,
  i_id,
  formik,
  dispatch,
  handleCancel,
  setFilesInServer,
  setQuestionDetail,
  setResponseQuestionDetail,
}: {
  i_id?: string;
  dispatch: Types.AppDispatch;
  handleCancel: Function;
  type?: 'edit' | 'create';
  formik: FormikProps<Types.CreateEditQuestionFormik>;
  setFilesInServer?: React.Dispatch<React.SetStateAction<string[]>>;
  setQuestionDetail?: React.Dispatch<
    React.SetStateAction<Types.CreateEditQuestionFormik | undefined>
  >;
  setResponseQuestionDetail?: React.Dispatch<
    React.SetStateAction<Types.Questions.ResponseType | undefined>
  >;
}) => {
  dispatch(startLoading());
  const resultActionGetOptionLimit = await dispatch(getOptionLimit());
  if (type === 'edit') {
    const resultActionGetQuestionDetail = await dispatch(
      getQuestionDetailPartner({
        page: 1,
        per_page: 1,
        conditions: [
          {
            id: 'i_id',
            search_value: [i_id],
            exact_match: true,
          },
        ],
      })
    );
    if (getQuestionDetail.rejected.match(resultActionGetQuestionDetail)) {
      dispatch(stopLoading());
      handleCancel();
      return;
    }
    const item = resultActionGetQuestionDetail.payload.items[0];
    if (!item) {
      dispatch(stopLoading());
      handleCancel();
      return;
    }

    setResponseQuestionDetail && setResponseQuestionDetail(item);

    const [
      resultActionGetFilesAttach1,
      resultActionGetFilesAttach2,
      resultActionGetFilesAttach3,
      resultActionGetFilesProblems1Attach,
      resultActionGetFilesProblems2Attach,
      resultActionGetFilesProblems3Attach,
      resultActionGetFilesCommentAttach1,
      resultActionGetFilesCommentAttach2,
      resultActionGetFilesCommentAttach3,
    ] = await Promise.all([
      item.attach_fileID1 && getPresignedUrlByFileID(item.attach_fileID1),
      item.attach_fileID2 && getPresignedUrlByFileID(item.attach_fileID2),
      item.attach_fileID3 && getPresignedUrlByFileID(item.attach_fileID3),
      item.problems1_attach_fileID && getPresignedUrlByFileID(item.problems1_attach_fileID),
      item.problems2_attach_fileID && getPresignedUrlByFileID(item.problems2_attach_fileID),
      item.problems3_attach_fileID && getPresignedUrlByFileID(item.problems3_attach_fileID),
      item.comment_attach_fileID1 && getPresignedUrlByFileID(item.comment_attach_fileID1),
      item.comment_attach_fileID2 && getPresignedUrlByFileID(item.comment_attach_fileID2),
      item.comment_attach_fileID3 && getPresignedUrlByFileID(item.comment_attach_fileID3),
    ]);

    setFilesInServer &&
      setFilesInServer(
        [
          ...(item.attach_fileID1 ? item.attach_fileID1.split(',') : []),
          ...(item.attach_fileID2 ? item.attach_fileID2.split(',') : []),
          ...(item.attach_fileID3 ? item.attach_fileID3.split(',') : []),
          ...(item.problems1_attach_fileID ? item.problems1_attach_fileID.split(',') : []),
          ...(item.problems2_attach_fileID ? item.problems2_attach_fileID.split(',') : []),
          ...(item.problems3_attach_fileID ? item.problems3_attach_fileID.split(',') : []),
          ...(item.comment_attach_fileID1 ? item.comment_attach_fileID1.split(',') : []),
          ...(item.comment_attach_fileID2 ? item.comment_attach_fileID2.split(',') : []),
          ...(item.comment_attach_fileID3 ? item.comment_attach_fileID3.split(',') : []),
        ].filter(Boolean)
      );
    const attach: Array<string> = [];
    const attach1: Array<string> = [];
    const attach2: Array<string> = [];
    const attach3: Array<string> = [];
    const attachFileID: Array<string> = [];
    const attachFileID1: Array<string> = [];
    const attachFileID2: Array<string> = [];
    const attachFileID3: Array<string> = [];
    const problems1_attach: Array<string> = [];
    const problems2_attach: Array<string> = [];
    const problems3_attach: Array<string> = [];
    const comment_attach: Array<string> = [];
    const comment_attach1: Array<string> = [];
    const comment_attach2: Array<string> = [];
    const comment_attach3: Array<string> = [];
    const commentAttachFileID: Array<string> = [];
    const commentFileID1: Array<string> = [];
    const commentFileID2: Array<string> = [];
    const commentFileID3: Array<string> = [];
    const problems1AttachFileID: Array<string> = [];
    const problems2AttachFileID: Array<string> = [];
    const problems3AttachFileID: Array<string> = [];

    if (resultActionGetFilesAttach1) {
      attach1.push(resultActionGetFilesAttach1);
      attachFileID1.push(item.attach_fileID1);
    }

    if (resultActionGetFilesAttach2) {
      attach2.push(resultActionGetFilesAttach2);
      attachFileID2.push(item.attach_fileID2);
    }

    if (resultActionGetFilesAttach3) {
      attach3.push(resultActionGetFilesAttach3);
      attachFileID3.push(item.attach_fileID3);
    }

    if (resultActionGetFilesProblems1Attach) {
      problems1_attach.push(resultActionGetFilesProblems1Attach);
      problems1AttachFileID.push(item.problems1_attach_fileID);
    }

    if (resultActionGetFilesProblems2Attach) {
      problems2_attach.push(resultActionGetFilesProblems2Attach);
      problems2AttachFileID.push(item.problems2_attach_fileID);
    }

    if (resultActionGetFilesProblems3Attach) {
      problems3_attach.push(resultActionGetFilesProblems3Attach);
      problems3AttachFileID.push(item.problems3_attach_fileID);
    }

    if (resultActionGetFilesCommentAttach1) {
      comment_attach1.push(resultActionGetFilesCommentAttach1);
      commentFileID2.push(item.comment_attach_fileID1);
    }

    if (resultActionGetFilesCommentAttach2) {
      comment_attach2.push(resultActionGetFilesCommentAttach2);
      commentFileID2.push(item.comment_attach_fileID2);
    }

    if (resultActionGetFilesCommentAttach3) {
      comment_attach3.push(resultActionGetFilesCommentAttach3);
      commentFileID3.push(item.comment_attach_fileID3);
    }

    const questionDetail = {
      name: item.name || '',
      description: item.description || '',
      problems1: item.problems1 || '',
      problems2: item.problems2 || '',
      problems3: item.problems3 || '',
      answer: item.answer || '',
      time_limit: getOptionLimit.fulfilled.match(resultActionGetOptionLimit)
        ? getTimeLimitOption(resultActionGetOptionLimit.payload).find(
            (v: Types.TimeLimitType) => v.value === item.time_limit
          )?.option_id || ''
        : '',
      comment: item.comment || '',
      score: Number(item.score || 1),
      attachFileID,
      commentAttachFileID,
      problems1AttachFileID,
      problems2AttachFileID,
      problems3AttachFileID,
      attach: attach.filter(Boolean) as unknown as UploadFile<File>[],
      comment_attach: comment_attach.filter(Boolean) as unknown as UploadFile<File>[],
      problems1_attach: problems1_attach.filter(Boolean) as unknown as UploadFile<File>[],
      problems2_attach: problems2_attach.filter(Boolean) as unknown as UploadFile<File>[],
      problems3_attach: problems3_attach.filter(Boolean) as unknown as UploadFile<File>[],
      commentFileID1,
      commentFileID2,
      commentFileID3,
      attachFileID1,
      attachFileID2,
      attachFileID3,
      comment_attach1: comment_attach1.filter(Boolean) as unknown as UploadFile<File>[],
      comment_attach2: comment_attach2.filter(Boolean) as unknown as UploadFile<File>[],
      comment_attach3: comment_attach3.filter(Boolean) as unknown as UploadFile<File>[],
      attach1: attach1.filter(Boolean) as unknown as UploadFile<File>[],
      attach2: attach2.filter(Boolean) as unknown as UploadFile<File>[],
      attach3: attach3.filter(Boolean) as unknown as UploadFile<File>[],
    };

    formik.setValues(questionDetail);
    setQuestionDetail && setQuestionDetail(questionDetail);
  }

  dispatch(stopLoading());
};

export const convertFileResponse = ({
  file,
  fileID,
  fileName,
}: {
  fileID: string;
  fileName: string;
  file: ArrayBuffer | File;
}): UploadFile<File> => ({
  uid: fileID,
  name: fileName,
  originFileObj: new File([file], fileName, {
    type: fileName?.includes('mp4')
      ? FileType.MP4
      : fileName?.includes('png')
      ? FileType.PNG
      : FileType.JPEG,
  }) as unknown as RcFile,
});

interface PropsCheckExist {
  selectedQuestion: Array<{
    i_id: string;
    name: string;
    code: string;
  }>;
  dispatch: Types.AppDispatch;
  setOpenNotDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDeleteQuestion: React.Dispatch<React.SetStateAction<boolean>>;
}

export const handleCheckExistQuestion = async ({
  selectedQuestion,
  dispatch,
  setOpenDeleteQuestion,
  setOpenNotDelete,
}: PropsCheckExist) => {
  dispatch(startLoading());

  const actionResult = await Promise.all([
    dispatch(
      getQuestionAssignedLevel({
        conditions: [
          {
            id: 'code',
            search_value: [selectedQuestion.map((e) => e.code).join('|')],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
        use_display_id: true,
      })
    ),
    dispatch(
      getQuestionAssignedToCurriculum({
        conditions: [
          {
            id: 'code',
            search_value: [selectedQuestion.map((e) => e.code).join('|')],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
        use_display_id: true,
      })
    ),
  ]);
  const questionAssigned = [];
  if (
    getQuestionAssignedLevel.fulfilled.match(actionResult[0]) &&
    getQuestionAssignedToCurriculum.fulfilled.match(actionResult[1])
  ) {
    questionAssigned.push([...actionResult[0].payload.items, ...actionResult[1].payload.items]);
  }
  if (flatMap(questionAssigned).length) {
    setOpenNotDelete(true);
    await dispatch(stopLoading());
    return;
  }
  await dispatch(stopLoading());
  setOpenDeleteQuestion(true);
};

export const convertAttachFileId = (
  _: string | undefined,
  questionCode: string,
  index: number,
  fieldId: string
) => `${questionCode}${index}${QUESTION_ATTACH_MAPPING[fieldId]}`;

export const convertQuestionAttachTextToCode = (text: string) =>
  findKey(QUESTION_ATTACH_MAPPING, (item) => item === text);
