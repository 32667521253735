export enum Locale {
  en = 'en',
  ja = 'ja',
  zh = 'zh',
}

export enum CurriculumType {
  all = 'ALL',
  curriculum = 'カリキュラム',
  required = '必修カリキュラム',
  officialCurriculum = 'OFFICIALカリキュラム',
}

export enum CurriculumStatus {
  all = 'ALL',
  publish = 'カリキュラム',
  edit = '必修カリキュラム',
  unPublished = '未公開',
  suspended = '公開停止中',
}

export enum FileType {
  MP4 = 'video/mp4',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  GIF = 'image/gif',
}

export enum FileUploadStatus {
  active = 0,
  exception = 1,
}

export enum HomeCardColour {
  green = 0,
  blue = 1,
  purple = 2,
}

export enum SALES_TYPE {
  ALL = '65598563baeaf8d6328c9140',
  '月額利用料' = '',
  'ストレージ利用料' = '',
}

export enum USER_TYPE {
  '全ユーザー' = '65598563baeaf8d6328c913c',
}

export enum EnumUserRegistrationHistory {
  individual = '691ec525-f703-4c83-8099-a1f3bbfeac3a', // 個人
  corporation = 'f61bce12-8747-47fa-9dc1-a6c2975fb3f4', // 法人
}

export enum RoleToPublish {
  admin = 'ADMIN',
  member = 'MEMBER',
}
