import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  labelSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 3,
  },
  label: {
    fontSize: 7,
    color: '#222222',
  },
  required: {
    color: '#F0330A',
    fontSize: 9,
    marginTop: -1,
    marginLeft: 1,
  },
});
