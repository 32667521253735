import React from 'react';
import UserInformationStyled from './styles';
import { FormikProvider } from 'formik';
import { Form } from 'formik-antd';
import { Checkbox, Select, Tabs } from 'antd';
import { DatePicker, Header, SelectField } from 'components';
import { USER_INFORMATION_COMPANY_TYPE } from 'constant/report';
import { BarChartOutlined, TableOutlined } from '@ant-design/icons';
import Table from 'pages/Report/UserInformation/Table';
import Chart from 'pages/Report/UserInformation/Chart';

interface IUserInformationProps {
  formik: any;
  visibleExpansionOfSale: boolean;
  visibleUserDetail: boolean;
  segmented: number;
  valueSelect: number | undefined;
  selectedDates: any;
  userFilterOptions: (string | undefined)[];
}

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const PDFUserInformation: React.FC<IUserInformationProps> = (props) => {
  const {
    formik,
    valueSelect,
    selectedDates,
    visibleUserDetail,
    visibleExpansionOfSale,
    segmented,
    userFilterOptions,
  } = props;

  return (
    <UserInformationStyled>
      <Header title="レポート" exportPDF />
      <div className="wrap-tab">
        <Tabs className="tab-container" activeKey="1">
          <TabPane tab="売上情報" key="0" />
          <TabPane tab="ユーザー情報" key="1" />
          <TabPane tab="OFFICIALカリキュラム情報" key="2" />
          <TabPane tab="スキルチェック実施情報" key="3" />
        </Tabs>
      </div>
      <div className="container">
        <div className="wrap-filter">
          <span className="label">集計条件</span>
          <div className="aggregation-conditions">
            <FormikProvider value={formik}>
              <Form layout="vertical">
                <div className="form-search">
                  <Form.Item
                    name="aggregation_method"
                    className="item"
                    label={
                      <span className="text-label">
                        集計方法選択&nbsp;<span className="required">*</span>
                      </span>
                    }
                  >
                    <Select
                      value={formik.values.aggregation_method}
                      showSearch
                      allowClear
                      placeholder="指定なし"
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    >
                      <Option value={0}>登録企業数推移</Option>
                      <Option value={1}>ユーザー数推移</Option>
                      <Option value={2}>月間最大登録ユーザー数推移</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="target_month"
                    className="item"
                    label={
                      <span className="text-label">
                        集計期間&nbsp;<span className="required">*</span>
                      </span>
                    }
                  >
                    <RangePicker
                      allowClear
                      className="date"
                      name="date-item"
                      format="YYYY/MM"
                      picker="month"
                      placeholder={['集計開始日', '集計終了日']}
                      value={selectedDates}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement!}
                    />
                  </Form.Item>
                  {valueSelect === 1 && (
                    <Form.Item
                      name="companyType"
                      className="item"
                      label={<span className="text-label">ユーザー種類</span>}
                    >
                      <SelectField
                        name="companyType"
                        showSearch
                        allowClear
                        placeholder="指定なし"
                        filterOption={(input, option) =>
                          JSON.stringify(option?.children)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value={''}>ALL</Option>
                        {Object.values(USER_INFORMATION_COMPANY_TYPE).map((item) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </SelectField>
                    </Form.Item>
                  )}
                  {!!valueSelect && [1, 2].includes(valueSelect) && (
                    <Form.Item
                      name="companyName"
                      className="item"
                      label={<span className="text-label">ユーザー検索</span>}
                    >
                      <SelectField
                        name="companyName"
                        showSearch
                        allowClear
                        placeholder="指定なし"
                        filterOption={(input, option) =>
                          JSON.stringify(option?.children)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {userFilterOptions.map((item) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </SelectField>
                    </Form.Item>
                  )}
                </div>
              </Form>
            </FormikProvider>
          </div>
        </div>
        <div className="sub-container">
          <div className="wrap-segmented">
            <div className="left-side">
              <span className="label">レポートタイプ：</span>
              <div className="segmented">
                <div
                  className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                >
                  <TableOutlined className="icon" />
                  <span>表</span>
                </div>
                <div
                  className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                >
                  <BarChartOutlined className="icon" />
                  <span>グラフ</span>
                </div>
              </div>
              {valueSelect !== 2 ? (
                <>
                  <div className="item-checkbox">
                    <Checkbox checked={visibleExpansionOfSale}>登録情報内訳展開</Checkbox>
                  </div>
                  {valueSelect != null && [1].includes(valueSelect) && (
                    <div className="item-checkbox">
                      <Checkbox checked={visibleUserDetail}>ユーザー展開</Checkbox>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </div>
          {segmented === 0 && (
            <Table
              visibleExpansionOfSale={visibleExpansionOfSale}
              visibleUserDetail={visibleUserDetail}
              valueSelect={valueSelect}
              exportPdf
            />
          )}
          {segmented !== 0 && (
            <Chart
              visibleExpansionOfSale={visibleExpansionOfSale}
              valueSelect={valueSelect}
              exportPdf
            />
          )}
        </div>
      </div>
    </UserInformationStyled>
  );
};

export default PDFUserInformation;
