import { Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const DownIcon = ({ style }: { style?: Style | Style[] }) => (
  <Svg style={style} width="8" height="4" viewBox="0 0 8 4">
    <Path
      d="M7.0317 0H6.36586C6.32058 0 6.27797 0.0195313 6.25134 0.0515625L3.72915 3.11094L1.20696 0.0515625C1.18033 0.0195313 1.13772 0 1.09244 0H0.426604C0.368898 0 0.335162 0.0578126 0.368898 0.0992188L3.49921 3.89687C3.61285 4.03437 3.84545 4.03437 3.9582 3.89687L7.08851 0.0992188C7.12314 0.0578126 7.0894 0 7.0317 0Z"
      fill="#999999"
    />
  </Svg>
);

export default DownIcon;
