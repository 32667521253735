import { Line, Rect, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const CheckOn = ({ style }: { style?: Style | Style[] }) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" style={style}>
    <Rect x="0.5" y="0.5" width="15" height="15" rx="0.5" fill="#1890FF" stroke="#1890FF" />
    <Line x1="3.70711" y1="7.29289" x2="7.70711" y2="11.2929" stroke="white" stroke-width="2" />
    <Line x1="6.29289" y1="11.2929" x2="13.2929" y2="4.29289" stroke="white" stroke-width="2" />
  </Svg>
);

export default CheckOn;
