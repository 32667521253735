import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Document } from 'react-pdf';

import { initialPDFState } from '../../state/reducer';
import { setNumPages, setZoomLevel } from '../../state/actions';
import { PDFAllPages } from './PDFAllPages';
import { PDFContext } from '../../state';

const PDFPages: React.FC = () => {
  const pdfRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1.0);

  const {
    state: { mainState },
    dispatch,
  } = useContext(PDFContext);

  const currentDocument = mainState?.currentDocument || null;
  useEffect(() => {
    const handleWheelZoom = (e: WheelEvent) => {
      const rect = pdfRef.current?.getBoundingClientRect();
      const isMouseInside =
        rect &&
        e.clientX >= rect.left &&
        e.clientX <= rect.right &&
        e.clientY >= rect.top &&
        e.clientY <= rect.bottom;

      if (!isMouseInside) {
        return;
      }
      if (e.ctrlKey) {
        e.preventDefault();

        const zoomStep = 0.1;
        let newScale = scale + (e.deltaY < 0 ? zoomStep : -zoomStep);
        newScale = Math.min(Math.max(newScale, 0.5), 3);

        setScale(newScale);
        dispatch(setZoomLevel(newScale));

        if (pdfRef.current) {
          const offsetX = e.clientX - rect.left;
          const offsetY = e.clientY - rect.top;

          const scrollLeft = pdfRef.current.scrollLeft + offsetX * (newScale / scale - 1);
          const scrollTop = pdfRef.current.scrollTop + offsetY * (newScale / scale - 1);

          pdfRef.current.scrollTo(scrollLeft, scrollTop);
        }
      } else {
        return;
      }
    };

    document.addEventListener('wheel', handleWheelZoom, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleWheelZoom);
    };
  }, [scale]);

  useEffect(() => {
    dispatch(setNumPages(initialPDFState.numPages));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentDocument]);

  if (!currentDocument || currentDocument.fileData === undefined) return null;

  return (
    <PDFContainer ref={pdfRef}>
      <DocumentPDF
        file={currentDocument.fileData}
        onLoadSuccess={({ numPages }) => dispatch(setNumPages(numPages))}
        loading={<span>Loading...</span>}
      >
        <PDFAllPages />
      </DocumentPDF>
    </PDFContainer>
  );
};

const PDFContainer = styled.div`
  position: relative;
  overflow: auto;
  height: 100vh;
  width: 100%;
`;

const DocumentPDF = styled(Document)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export default PDFPages;
