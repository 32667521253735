import React, { useCallback, useEffect, useState } from 'react';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { Formik, FormikProvider, useFormik } from 'formik';
import { Button, Select, Table } from 'antd';
import { useSelector } from 'react-redux';
import {
  CloudDownloadOutlined,
  SearchOutlined,
  DeleteOutlined,
  FormOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { Header, Input, PagingNumber, SelectField, TextArea } from 'components';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import DeleteCompleted from 'components/Modal/DeleteCompleted';
import ActionErrorModal from 'components/Modal/ActionError';
import { CreateEditJobTitleSchema } from 'libs/validations';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { useAppDispatch, usePermission } from 'hooks';
import { jobTitleMasterSelector } from './selectors';
import { loadingRef } from 'components/Loading';
import JobTitleMasterStyled from './styles';
import * as Types from 'types';
import {
  getSelectAdminPositions,
  getDataAdminPositions,
  deleteAdminPositions,
  updateAdminPosition,
  createAdminPosition,
  getAffiliationAssignRole,
} from './thunk';
import { pdf } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import { HEADER_JOB_TITLE_MASTER_CSV } from 'constant/header.export.constant';
import PDFJobTitleDocument from './PDFJobTitleDocument';
import { setModified } from 'slice/slice';
import PerPageSelect from 'components/PerPageSelect';
import { SELECT_RECORD } from 'constant/select.constants';
import { exportCsv } from 'libs/utils/exportCsv';

const { Option } = Select;

const JobTitleMaster: React.FC = () => {
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<Types.AdminPositions.ResponseType>();
  const [itemDeleted, setItemDeleted] = useState<Types.AdminPositions.ResponseType>();
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [deleteComplete, setDeleteComplete] = useState<boolean>(false);
  const [updateComplete, setUpdateComplete] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [actionModalState, setActionModalState] = useState<{
    subTitle: string;
    description: React.ReactNode;
  }>({
    subTitle: '',
    description: '',
  });

  const { dataSelectAdminPositions, dataAdminPositions, loading, affiliationAssignRole } =
    useSelector(jobTitleMasterSelector);
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const fetchDataAdminPositions = useCallback(() => {
    if (!userInfo) return;
    dispatch(
      getDataAdminPositions({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        include_lookups: true,
        use_display_id: true,
        include_item_ref: true,
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo]);

  const handleDeleteItems = (item: Types.AdminPositions.ResponseType) => {
    const index = affiliationAssignRole.findIndex(
      (value: Types.AffiliationAssignRole.ResponseType) =>
        value?.lookup_items?.positions_code?.i_id === item?.i_id
    );
    if (permissionNumber !== 1) {
      setItemDeleted(index <= -1 ? item : undefined);
      setOpenModalConfirmDeleteItem(true);
    }
  };

  const handleChange = (check: boolean, type: string) => {
    const form = {
      tableInput: false,
      textArea: false,
    };
    if (type === 'textArea') {
      form.textArea = check;
    } else if (type === 'tableInput') {
      form.tableInput = check;
    }

    dispatch(setModified(form.textArea || form.tableInput));
  };

  const formikSearch = useFormik<Types.SearchAuthorityMasterFormik>({
    initialValues: {
      name: '',
    },
    onSubmit: async ({ name }) => {
      if (!name) {
        setActionModalState({
          subTitle: '検索するマスタが未選択です',
          description: (
            <p className="text-content">
              検索する役職を選択後、
              <br />
              「検索」をクリックしてください。
            </p>
          ),
        });
        setShowActionErrorModal(true);
      } else {
        await dispatch(
          getDataAdminPositions({
            conditions: [
              {
                id: 'name',
                search_value: [name],
                exact_match: true,
              },
            ],
            include_lookups: true,
            use_display_id: true,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        );
        dispatch(setModified(false));
        setPage(1);
      }
    },
    onReset: async () => {
      await dispatch(
        getDataAdminPositions({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          include_lookups: true,
          use_display_id: true,
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      );
      setPage(1);
    },
  });

  const isEditing = (record: Types.AdminPositions.ResponseType) =>
    record.i_id === itemSelected?.i_id;

  const columns: any = [
    {
      key: 'code',
      title: 'コード',
      dataIndex: 'code',
      width: '13%',
      align: 'center',
      sorter: (a: Types.AdminPositions.ResponseType, b: Types.AdminPositions.ResponseType) =>
        Number(a.code) - Number(b.code),
    },
    {
      key: 'name',
      title: '名称',
      dataIndex: 'name',
      width: '60%',
      editable: true,
      render: (text: string) => (
        <>
          <div>{text.slice(0, 50)}</div>
          <div>{text.slice(50)}</div>
        </>
      ),
    },
    {
      title: '編集',
      dataIndex: 'operation',
      align: 'center',
      width: '7%',
      render: (_: string, record: Types.AdminPositions.ResponseType) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="wrap-edit-submit">
            <SubmitButton className="btn btn_submit">
              <PlusOutlined className="size-icon" />
              更新
            </SubmitButton>
            <button
              type="button"
              className="btn btn_close"
              onClick={() => setItemSelected(undefined)}
            >
              キャンセル
            </button>
          </div>
        ) : (
          <FormOutlined className="icon" onClick={() => setItemSelected(record)} />
        );
      },
    },
    {
      title: '削除',
      width: '7%',
      dataIndex: 'i_id',
      align: 'left',
      render: (_: string, record: Types.AdminPositions.ResponseType) =>
        itemSelected?.i_id !== record.i_id ? (
          <DeleteOutlined className="icon" onClick={() => handleDeleteItems(record)} />
        ) : null,
    },
  ];

  const EditableRow: React.FC<Types.TypeRow> = ({ children, ...restProps }) => (
    <Formik<Types.CreateEditAnalysisGroupFormik>
      initialValues={{
        name: itemSelected?.name || '',
      }}
      enableReinitialize
      validationSchema={CreateEditJobTitleSchema}
      onSubmit={async (values) => {
        if (!itemSelected) return;
        const resultAction = await dispatch(
          updateAdminPosition({
            id: itemSelected.i_id,
            data: {
              item: {
                name: values.name,
              },
              is_force_update: true,
            },
          })
        );
        setItemSelected(undefined);
        if (updateAdminPosition.fulfilled.match(resultAction)) {
          fetchDataAdminPositions();
          fetchDataSelectAdminPositions();
          setUpdateComplete(true);
        } else {
          setActionModalState({
            subTitle: '役職の更新に失敗しました',
            description: (
              <p className="text-content">
                役職の更新に失敗しました。
                <br />
                もう一度情報を入力し、再度お試しください。
              </p>
            ),
          });
          setShowActionErrorModal(true);
        }
      }}
    >
      <tr {...restProps}>{children}</tr>
    </Formik>
  );

  const EditableCell: React.FC<Types.TypeCell<Types.AdminAffiliationHierarchy.ResponseType>> = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        <FormEdit
          children={children}
          restProps={restProps}
          handleChange={handleChange}
          editing={editing}
          record={record}
          dataIndex={dataIndex}
        />
      </td>
    );
  };

  const mergedColumns = columns.map((col: Types.TypeCell<Types.AdminPositions.ResponseType>) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: Types.AdminPositions.ResponseType) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleSubmitDelete = async () => {
    const resultAction = await dispatch(
      deleteAdminPositions({
        id: itemDeleted!.i_id,
      })
    );
    if (deleteAdminPositions.fulfilled.match(resultAction)) {
      await fetchDataSelectAdminPositions();
      await fetchDataAdminPositions();
      setOpenModalConfirmDeleteItem(false);
      setDeleteComplete(true);
    }
  };

  const handleExportCSV = async (value: string) => {
    setShowConfirmExportFileModal(false);
    if (value === 'pdf') {
      const blob = await pdf(<PDFJobTitleDocument dataSource={dataAdminPositions} />).toBlob();
      saveAs(blob, '役職マスタ.pdf');
    } else {
      const listCsv = dataAdminPositions.map((item) => ({
        code: item.code || '',
        name: item.name || '',
      }));

      // const csvString = [
      //   HEADER_JOB_TITLE_MASTER_CSV.map(({ label }) => label),
      //   ...listCsv.map((item) => Object.values(item).map((v) => `"${v}"`)),
      // ]
      //   .map((e) => e.join(','))
      //   .join('\n');
      // const bom = '\uFEFF';
      // const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      // saveAs(file, '役職マスタ.csv');
      exportCsv(listCsv, HEADER_JOB_TITLE_MASTER_CSV, '役職マスタ.csv');
    }
  };

  const fetchAffiliationAssignRole = useCallback(() => {
    if (!userInfo) {
      return;
    }

    dispatch(
      getAffiliationAssignRole({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        sort_fields: [
          {
            id: 'display_order',
            order: 'asc',
          },
        ],
        page: 1,
        per_page: 0,
        include_lookups: true,
        use_display_id: true,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const fetchDataSelectAdminPositions = useCallback(() => {
    if (!userInfo) return;
    dispatch(
      getSelectAdminPositions({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        include_lookups: true,
        use_display_id: true,
        include_item_ref: true,
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo]);

  useEffect(fetchDataAdminPositions, [fetchDataAdminPositions]);
  useEffect(fetchDataSelectAdminPositions, [fetchDataSelectAdminPositions]);
  useEffect(fetchAffiliationAssignRole, [fetchAffiliationAssignRole]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerpage(dataAdminPositions.length);
    }
  }, [dataAdminPositions]);

  return (
    <JobTitleMasterStyled collapsedMenu={collapsedMenu} isEmptyData={!dataAdminPositions.length}>
      <Header title={headerTitle} className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            役職マスタの作成・管理を行う画面です。
            <br />
            作成した役職マスタはユーザーに設定します。
          </p>
          <div className="border" />
          <FormikProvider value={formikSearch}>
            <Form
              layout="vertical"
              labelCol={{
                flex: '10%',
              }}
              colon={false}
              className="form-search"
            >
              <Form.Item
                name="name"
                label={<span className="label">役職検索</span>}
                className="form-input"
              >
                <SelectField
                  showSearch
                  className="select-input"
                  placeholder="選択してください"
                  filterOption={(input, option) =>
                    option!.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  name="name"
                >
                  {dataSelectAdminPositions?.map(
                    ({ name }, i) =>
                      name && (
                        <Option value={name} key={i}>
                          {name}
                        </Option>
                      )
                  )}
                </SelectField>
              </Form.Item>
              <div className="wrap-btn">
                <SubmitButton className="btn-search">
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <ResetButton className="btn-reset">
                  <span className="label-reset">リセット</span>
                </ResetButton>
              </div>
            </Form>
          </FormikProvider>

          <div className="wrap-table">
            <Button className="btn-active" onClick={() => setShowConfirmExportFileModal(true)}>
              <CloudDownloadOutlined className="size-icon" />
              エクスポート
            </Button>

            <Table
              data-testid="table-job-title-master"
              dataSource={dataAdminPositions.map((item, index) => ({ ...item, index }))}
              columns={mergedColumns}
              components={{
                body: {
                  row: EditableRow,
                  cell: EditableCell,
                },
              }}
              className="table"
              pagination={{
                showSizeChanger: false,
                current: page,
                pageSize: perPage,
                total: dataAdminPositions.length,
                onChange: setPage,
                position: ['topCenter'],
                showTotal: () => (
                  <div className="wrap-select-record">
                    <PagingNumber
                      startItem={dataAdminPositions.length ? `${(page - 1) * perPage + 1}` : ''}
                      endItem={
                        page * perPage > dataAdminPositions.length
                          ? dataAdminPositions.length
                          : page * perPage
                      }
                      totalItem={dataAdminPositions.length}
                    />
                    <PerPageSelect
                      data={dataAdminPositions}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerpage}
                    />
                  </div>
                ),
              }}
              rowKey="index"
            />
          </div>
        </div>
      </div>
      <div className="wrap-create">
        <p className="title">役職新規登録</p>
        <Formik<Types.CreateEditJobTitleFormik>
          initialValues={{
            name: '',
          }}
          enableReinitialize
          validationSchema={CreateEditJobTitleSchema}
          onSubmit={async (values, { resetForm }) => {
            const resultAction = await dispatch(
              createAdminPosition({
                item: {
                  company_id: userInfo?.company_id,
                  name: values.name,
                },
              })
            );
            if (createAdminPosition.fulfilled.match(resultAction)) {
              setShowCompleteModal(true);
            } else {
              setActionModalState({
                subTitle: '新規役職の登録に失敗しました',
                description: (
                  <p className="text-content">
                    新規役職の登録に失敗しました。
                    <br />
                    もう一度情報を入力し、再度お試しください。
                  </p>
                ),
              });
              setShowActionErrorModal(true);
            }

            fetchDataSelectAdminPositions();
            fetchDataAdminPositions();
            resetForm();
          }}
        >
          <Form colon={false} layout="vertical" className="flex">
            <Form.Item
              name="name"
              label={
                <span className="text-label">
                  役職名称
                  <span className="require">*</span>
                </span>
              }
              className="form-text-area"
            >
              <TextArea name="name" showCount maxLength={100} placeholder="最大100文字" />
            </Form.Item>
            <SubmitButton className="btn btn_submit">
              <PlusOutlined className="size-icon" />
              新規登録
            </SubmitButton>
          </Form>
        </Formik>
      </div>
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle={actionModalState.subTitle}
        description={actionModalState.description}
      />
      <CompletedModal
        title="登録が完了しました"
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
      />
      <CompletedModal
        title="更新が完了しました"
        visible={updateComplete}
        setVisible={setUpdateComplete}
      />
      <DeleteCompleted visible={deleteComplete} setVisible={setDeleteComplete} />
      <ConfirmDeleteModal
        title="削除確認"
        subTitle={itemDeleted ? 'データの削除を実行します' : 'データの削除は実行できません。'}
        description={
          itemDeleted
            ? 'データの削除を実行すると、復元できませんのでご注意ください。'
            : '選択したマスタは社内ユーザーに設定されているため、削除できません。'
        }
        itemDeleted={!!itemDeleted}
        visible={openModalConfirmDeleteItem}
        onSubmit={itemDeleted ? handleSubmitDelete : undefined}
        setVisible={setOpenModalConfirmDeleteItem}
      />
    </JobTitleMasterStyled>
  );
};

export default JobTitleMaster;

const FormEdit = ({ children, record, editing, restProps, handleChange, dataIndex }: any) => (
  <Form {...restProps}>
    {editing ? (
      <Form.Item name={dataIndex}>
        <TextArea
          name="name"
          autoSize={{ minRows: 3 }}
          maxLength={100}
          showCount
          onChange={(e) => {
            handleChange(e.target.value !== record.name, 'tableInput');
          }}
        />
      </Form.Item>
    ) : (
      children
    )}
  </Form>
);
