import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Button, Select, Table } from 'antd';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import {
  FileDoneOutlined,
  FileTextOutlined,
  MailOutlined,
  PlusOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { intersectionBy, uniqBy } from 'lodash';

import { setHeaderTitle, startLoading, stopLoading } from 'containers/AppSettings/slice';
import { HEADER_PARTNER_MANAGEMENT_CSV } from 'constant/header.export.constant';
import { Header, PagingNumber, SelectField } from 'components';
import { settingSelector } from 'containers/AppSettings/selectors';
import ActionErrorModal from 'components/Modal/ActionError';
import { SEARCH_PARTNER, SELECT_RECORD } from 'constant/select.constants';
import { getHeaderTitle } from 'libs/utils/getHeaderTitle';
import LoginSuccess from 'components/Modal/LoginSuccess';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import * as Types from 'types';
import { DataPartnerManagementType } from 'types';
import { informationSelector, managementSelector } from './selectors';
import ModalContract from './ModalViewerPdf';
import ModalRegister from './ModalRegister';
import { routes } from 'navigations/routes';
import UploadPDF from './ModalImportPdf';
import { PartnerStyled } from './styles';
import ModalDetail from './ModalDetail';
import { useAppDispatch } from 'hooks';
import {
  getPartnerDetail,
  getPartnerManagement,
  getProviderInformation,
  getSelectCompany,
  updateProviderInformation,
  uploadFileProvider,
} from './thunk';

import { pdf } from '@react-pdf/renderer';
import PDFPartnerManagement from './PDFExportDocument';
import ModalConfirmExport from './ModalConfirmExport';
import PerPageSelect from 'components/PerPageSelect';
import { ColumnsType } from 'antd/es/table';
import { getDataUserPartner } from 'pages/Dashboard/thunk';
import { setCompanyId } from 'pages/OfficialCurriculumList/slice';
import { config } from 'configs';
import { uploadFileToR2 } from 'services/r2Service';
import { createFileAttach } from 'pages/PublicManagement/thunk';
import { exportCsv } from 'libs/utils/exportCsv';

const { Option } = Select;

const DEFAULT_FILTERS = {
  search_status: '',
  provider_id: '',
  provider_name: '',
};

const PartnerManagement = () => {
  const [selectedRow, setSelectedRow] = useState<Array<DataPartnerManagementType>>([]);
  const [visibleModalRegister, setVisibleModalRegister] = useState<boolean>(false);
  const [openModalComplete, setOpenModalComplete] = useState<boolean>(false);
  const [visibleNoPartner, setVisibleNoPartner] = useState<boolean>(false);
  const [visibleContract, setVisibleContract] = useState<boolean>(false);
  const [visibleModalCsv, setVisibleModalCsv] = useState<boolean>(false);
  const [statusSelect, setStatusSelect] = useState<Types.StatusType>();
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);
  const [visibleDetail, setVisibleDetail] = useState<boolean>(false);
  const [contractFileId, setContractFileId] = useState<string>('');
  const [visibleCsv, setVisibleCsv] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const { management, searchSelect } = useSelector(managementSelector);
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const dataInfo = useSelector(informationSelector);
  const { userInfo } = useSelector(authSelector);
  const [users, setUsers] = useState<any[]>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: { ...DEFAULT_FILTERS },
    validateOnBlur: false,
    onSubmit: (values) => {
      setFilters(values);
      setPage(1);
    },
    onReset: () => {
      setFilters(DEFAULT_FILTERS);
      setPage(1);
    },
  });

  const dataSource = useMemo(() => {
    dispatch(startLoading());
    let res = management || [];
    if (filters.search_status === '契約中') {
      res = res.filter((obj) => !obj.contract_start_date && !obj.deletedat);
    }
    if (filters.search_status === '契約終了予定') {
      res = res.filter(
        (item) =>
          item.contract_end_date &&
          (dayjs().isBefore(dayjs(item.contract_end_date)) ||
            dayjs().isSame(dayjs(item.contract_end_date)))
      );
    }
    if (filters.search_status === '契約終了') {
      res = res.filter((item) => item.deletedat || dayjs().isAfter(dayjs(item.contract_end_date)));
    }

    if (filters.provider_id) {
      res = res.filter((item) => item.provider_id === filters.provider_id);
    }
    if (filters.provider_name) {
      res = res.filter((item) => item.provider_name === filters.provider_name);
    }
    setTimeout(() => {
      dispatch(stopLoading());
    }, 500);
    return res;
  }, [filters, management]);

  const providerIdList = management.map((item) => item.provider_id);

  const getLastLoginField = (companyId: string) => {
    if (!users || !companyId) return '-';
    const obj = dataInfo.find((item) => item.company_id === companyId);
    if (!obj) return '-';
    const user = users.find((user) => user.company_id === obj?.p_related_id);
    if (!user) return '-';
    return user.last_login_at || '-';
  };

  const getCurriculumNumsField = (providerId: string) => {
    const obj = dataInfo.find((item) => item.company_id === providerId);
    if (obj) {
      return obj.curriculum_nums;
    } else {
      return undefined;
    }
  };

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisibleNoPartner(true);
    } else {
      setVisibleCsv(true);
    }
  };

  const detailSubmit = async () => {
    await dispatch(
      getPartnerManagement({
        page: 1,
        per_page: 0,
        include_lookups: true,
        include_item_ref: true,
        omit_total_items: false,
      })
    );
    setVisibleDetail(false);
    setVisibleSuccess(true);
  };

  const handleUploadCSV = async (
    file: File,
    setUploadSuccessfully: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!userInfo) return;
    const formData = new FormData();
    formData.append('filename', file?.name);
    formData.append('file', new Blob([file as Blob]));
    const uploadFile = await uploadFileToR2(file);

    console.log('upload fiel : ', uploadFile);
    if (uploadFile) {
      const resultAction = await Promise.all([
        // dispatch(
        //   executeActionUploadFile({
        //     itemId: selectedRow[0]?.provider_id || selectedRow[0]?.items[0]?.i_id!,
        //     data: {
        //       changes: [
        //         {
        //           id: 'contract_file',
        //           value: [uploadFile.payload.file_id],
        //         },
        //       ],
        //       is_force_update: true,
        //     },
        //   })
        // ),
        dispatch(
          createFileAttach({
            item: {
              fileID: uploadFile,
              filename: file?.name,
              file_location: '4',
              file_extension: file?.type,
              file_size: `${file?.size}`,
              company_id: userInfo?.company_id,
              createdat: new Date(),
              createdby: userInfo?.login_id,
            },
          })
        ),
        dispatch(
          updateProviderInformation({
            id: selectedRow[0]?.i_id || selectedRow[0]?.items[0]?.i_id!,
            data: {
              item: {
                contract_file_id: uploadFile,
                contract_file_name: file?.name,
                url_issue_date: new Date(),
                account_registration_status: 0,
                updatedby: userInfo.login_id,
                updatedat: new Date(),
              },
              is_force_update: true,
            },
          })
        ),
      ]);
      if (updateProviderInformation.fulfilled.match(resultAction[1])) {
        await fetchManagement();
        await fetchDataInformation();
        await dispatch(stopLoading());
        setUploadSuccessfully(true);
        setSelectedRow([]);
        setContractFileId('');
      }
    }
  };

  const handleNavigate = (path: string, companyId: string) => {
    if (!companyId) return;
    if (companyId) {
      dispatch(setCompanyId(companyId));
      navigate(path);
    }
  };

  const handleExportCSV = async (value: string) => {
    const getFieldName = (contract_end_date: string | null, deletedat: Date | null) => {
      if (!contract_end_date && !deletedat) {
        return '契約中';
      } else if (contract_end_date && dayjs().isBefore(dayjs(contract_end_date))) {
        return '契約終了予定';
      } else {
        return '契約終了';
      }
    };
    if (value === 'csv') {
      const listCsv = management?.map((item) => ({
        name: getFieldName(item?.contract_end_date ?? '', item?.deletedat ?? null),
        provider_id: `${item.provider_id}`,
        provider_name: item.provider_name,
        contract_start_date: item.contract_start_date
          ? dayjs(item.contract_start_date).format('YYYY/MM/DD')
          : '-',
        contract_end_date: item.contract_end_date
          ? dayjs(item.contract_end_date).format('YYYY/MM/DD')
          : '-',
        curriculum_nums: getCurriculumNumsField(item.provider_id) || '-',
        admin_department: item.admin_department,
        admin_position: item.admin_position,
        admin_name: item.admin_name,
        last_login_date:
          getLastLoginField(item.provider_id) !== '-'
            ? dayjs(getLastLoginField(item.provider_id)).format('YYYY/MM/DD HH:mm')
            : '-',
      }));

      const cleanProviderId = (arr: Array<any>) =>
        arr.map((item) => ({
          ...item,
          provider_id: item.provider_id.trim().replace(/[\u200B-\u200D\uFEFF]/g, ''),
        }));

      let dataCsv = [];
      if (selectedRow.length) {
        dataCsv = intersectionBy(cleanProviderId(listCsv), selectedRow, 'provider_id');
      } else {
        dataCsv = intersectionBy(
          cleanProviderId(listCsv),
          cleanProviderId(dataSource),
          'provider_id'
        );
      }

      // const csvString = [
      //   HEADER_PARTNER_MANAGEMENT_CSV.map(({ label }) => label),
      //   ...dataCsv.map((item: { [s: string]: unknown } | ArrayLike<unknown>) =>
      //     Object.values(item)
      //   ),
      // ]
      //   .map((e) => e.join(','))
      //   .join('\n');
      // const bom = '\uFEFF';
      // const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      // saveAs(file, 'パートナー管理.csv');
      exportCsv(dataCsv, HEADER_PARTNER_MANAGEMENT_CSV, 'パートナー管理.csv');
    } else {
      const listPDF = management?.map((item) => ({
        provider_id: item.provider_id,
        provider_name: item.provider_name,
        contract_start_date: item.contract_start_date,
        contract_end_date: item.contract_end_date,
        deletedat: item.deletedat,
        curriculum_nums: getCurriculumNumsField(item.provider_id) || '-',
        admin_department: item.admin_department,
        admin_position: item.admin_position,
        admin_name: item.admin_name,
        last_login_date:
          getLastLoginField(item.provider_id) !== '-'
            ? dayjs(getLastLoginField(item.provider_id)).format('YYYY/MM/DD HH:mm')
            : '-',
      }));
      let dataPDF = [];
      if (selectedRow.length) {
        dataPDF = intersectionBy(listPDF, selectedRow, 'provider_id');
      } else {
        dataPDF = intersectionBy(listPDF, dataSource, 'provider_id');
      }
      const filters = formik.values;
      const blob = await pdf(
        <PDFPartnerManagement
          search_status={filters.search_status}
          provider_name={filters.provider_name}
          provider_id={filters.provider_id}
          dataSource={dataPDF}
        />
      ).toBlob();
      saveAs(blob, 'パートナー管理.pdf');
    }
    setVisibleModalCsv(false);
  };
  const emailLogin = dataSource
    .map((data) => data.admin_email)
    .filter((admin_email) => admin_email !== undefined && admin_email !== '')
    .reduce<string[]>((uniqueEmails, email) => {
      if (!uniqueEmails.includes(email!)) {
        uniqueEmails.push(email!);
      }
      return uniqueEmails;
    }, []);

  const columns: ColumnsType<DataPartnerManagementType> = [
    {
      title: <div className="title-table">契約状況</div>,
      dataIndex: 'name',
      key: 'name',
      className: 'name',
      width: '10%',
      align: 'left',
      render: (_: string, item: DataPartnerManagementType) => (
        <span
          className={`${
            !item.contract_end_date && !item.deletedat
              ? 'star-button-green'
              : item.contract_end_date &&
                (dayjs().isBefore(dayjs(item.contract_end_date)) ||
                  dayjs().isSame(dayjs(item.contract_end_date)))
              ? 'star-button-orange'
              : (item.deletedat || dayjs().isAfter(dayjs(item.contract_end_date))) &&
                'star-button-grey'
          }`}
        >
          {!item.contract_end_date && !item.deletedat
            ? '契約中'
            : item.contract_end_date &&
              (dayjs().isBefore(dayjs(item.contract_end_date)) ||
                dayjs().isSame(dayjs(item.contract_end_date)))
            ? '契約終了予定'
            : (item.deletedat || dayjs().isAfter(dayjs(item.contract_end_date))) && '契約終了'}
        </span>
      ),
    },
    {
      title: () => (
        <div>
          <span>ID</span>
          <br />
          <span>パートナー名</span>
        </div>
      ),
      dataIndex: 'question_code',
      className: 'code',
      key: 'question_code',
      width: '12%',
      align: 'left',
      render: (_: string, item: DataPartnerManagementType) => (
        <div>
          <div className="question_code_table">{item.provider_id}</div>
          <div>{item.provider_name}</div>
        </div>
      ),
    },
    {
      title: '契約開始日',
      dataIndex: 'contract_start_date',
      key: 'contract_start_date',
      width: '10%',
      align: 'left',
      render: (contract_start_date: string) => (
        <div>{contract_start_date ? dayjs(contract_start_date).format('YYYY/MM/DD') : '-'}</div>
      ),
    },
    {
      title: () => (
        <div>
          <span>契約終了日</span>
          <br />
          <span>（予定）</span>
        </div>
      ),
      dataIndex: 'contract_end_date',
      key: 'contract_end_date',
      width: '13%',
      render: (contract_end_date: string, item: DataPartnerManagementType) => (
        // <div>{contract_end_date ? dayjs(contract_end_date).format('YYYY/MM/DD') : '-'}</div>
        <div>
          {item.contract_end_date && dayjs().isBefore(dayjs(item.contract_end_date))
            ? contract_end_date
              ? dayjs(contract_end_date).format('YYYY/MM/DD')
              : '-'
            : item.deletedat
            ? dayjs(item.deletedat).format('YYYY/MM/DD')
            : '-'}
        </div>
      ),
    },
    {
      title: () => (
        <div>
          <span>カリキュラム</span>
          <br />
          <span>制作数</span>
        </div>
      ),
      dataIndex: 'curriculum_nums',
      key: 'curriculum_nums',
      width: '10%',
      render: (text: string, record: DataPartnerManagementType) => (
        <div
          style={{
            textDecorationLine: getCurriculumNumsField(record.provider_id) ? 'underline' : 'none',
            cursor: getCurriculumNumsField(record.provider_id) ? 'pointer' : 'default',
          }}
          onClick={() => handleNavigate(routes.OfficialCurriculumList.path, record.provider_id)}
          className="score-style"
        >
          {getCurriculumNumsField(record.provider_id) || '-'}
        </div>
      ),
    },
    {
      title: () => (
        <div className="text-align-left">
          <span>所属 / 役職 </span>
          <br />
          <span>管理者氏名</span>
        </div>
      ),
      dataIndex: 'admin_name',
      key: 'admin_name',
      className: 'admin_name',
      width: '16%',
      align: 'left',
      render: (_: string, item: DataPartnerManagementType) => (
        <div className="text-align-left">
          <div className="minister-style">
            {item.admin_department && item.admin_position
              ? `${item.admin_department} / ${item.admin_position}`
              : '-'}
          </div>
          <div>{item.admin_name ? `${item.admin_name}` : '-'}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div>
          <span>メール </span>
          <br />
          <span>送信</span>
        </div>
      ),
      dataIndex: 'operation',
      width: '7%',
      key: 'admin_email',
      render: (_: string, item: DataPartnerManagementType) => (
        <div
          className="background-color-mail"
          onClick={() => {
            const emailTo = item.admin_email;
            const emailCC = '';
            const emailSubject = '';
            const newUrl =
              'https://mail.google.com/mail?view=cm&tf=0' +
              (emailTo ? '&to=' + emailTo : '') +
              (emailCC ? '&cc=' + emailCC : '') +
              (emailSubject ? '&su=' + emailSubject : '');

            window.open(newUrl, '_blank');
          }}
        >
          <MailOutlined />
        </div>
      ),
    },
    {
      title: '最終ログイン',
      dataIndex: 'last_login_date',
      width: '10%',
      render: (text: string, record: DataPartnerManagementType) =>
        getLastLoginField(record.provider_id) !== '-'
          ? dayjs(getLastLoginField(record.provider_id)).format('YYYY/MM/DD HH:mm')
          : '-',
    },
    {
      title: '詳細',
      dataIndex: 'question_description',
      width: '5%',
      render: (_: string, item: DataPartnerManagementType) => (
        <div className="background-color-mail">
          <FileTextOutlined
            onClick={() => {
              if (item.provider_id) {
                fetchPartnerDetail(item.provider_id);
              }
              if (!item.contract_end_date && !item.deletedat) {
                setStatusSelect({
                  status: '0',
                  contract_start_date: item.contract_start_date,
                  contract_end_date: item?.contract_end_date,
                });
              } else if (
                item.contract_end_date &&
                dayjs().isBefore(dayjs(item.contract_end_date))
              ) {
                setStatusSelect({
                  status: '1',
                  contract_start_date: item.contract_start_date,
                  contract_end_date: item.contract_end_date,
                });
              } else if (item.deletedat || dayjs().isAfter(dayjs(item.contract_end_date))) {
                setStatusSelect({
                  status: '2',
                  contract_start_date: item.contract_start_date,
                  contract_end_date: item.contract_end_date,
                });
              } else {
                setStatusSelect(undefined);
              }
              setVisibleDetail(true);
            }}
          />
        </div>
      ),
    },
    {
      title: '契約書',
      dataIndex: 'operation',
      width: '5%',
      render: (_: string, item: DataPartnerManagementType) => {
        if (!item.contract_file_id) {
          return null;
        }
        return (
          <div className="background-color-mail">
            <FileDoneOutlined
              onClick={() => {
                setContractFileId(item.contract_file_id);
                setVisibleContract(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  const fetchManagement = useCallback(
    async (conditions?: Types.ConditionsType[]) => {
      if (userInfo) {
        dispatch(startLoading());
        await dispatch(
          getPartnerManagement({
            conditions,
            page: 1,
            per_page: 0,
            include_lookups: true,
            include_item_ref: true,
            omit_total_items: false,
          })
        );
        dispatch(stopLoading());
      }
    },
    [dispatch, userInfo]
  );

  const fetchPartnerDetail = useCallback(
    (id) => {
      if (!id) return;
      dispatch(
        getPartnerDetail({
          conditions: [
            {
              id: 'provider_id',
              search_value: [id],
            },
          ],
          page: 1,
          per_page: 0,
          include_lookups: true,
          use_display_id: true,
          include_item_ref: true,
        })
      );
    },
    [dispatch]
  );

  const fetchDataInformation = () => {
    dispatch(
      getProviderInformation({
        page: 1,
        per_page: 0,
        include_lookups: true,
        include_item_ref: true,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getSelectCompany({
        sort_fields: [{ id: 'company_id', order: 'asc' }],
        page,
        per_page: 0,
      })
    );
  }, [dispatch, page]);

  useEffect(() => {
    fetchDataInformation();
  }, []);

  // useEffect(() => {
  //   if (openModalComplete && dataSource.length === management.length) {
  //     setTimeout(() => {
  //       fetchManagement();
  //     }, 500);
  //   }
  // }, [fetchManagement, openModalComplete]);

  useEffect(() => {
    fetchManagement();
  }, [userInfo?.i_id]);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerpage(dataSource.length);
    }
  }, [dataSource]);

  useEffect(() => {
    (async () => {
      const result = await dispatch(
        getDataUserPartner({
          conditions: [
            {
              id: config.APP_ID_PARTNER,
              search_value: [],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      if (getDataUserPartner.fulfilled.match(result)) {
        setUsers(result.payload.items);
      } else {
        console.log('error');
      }
    })();
  }, []);

  return (
    <PartnerStyled collapsedMenu={collapsedMenu}>
      <Header title={headerTitle} className="header" />
      <div className="partner-styled testing" style={{ position: 'relative' }}>
        <div className="title-partner">
          <p>パートナーの新規登録・登録情報の確認・管理を行う画面です。</p>
          <div>
            <button
              className="btn-list"
              onClick={() => {
                dispatch(setHeaderTitle(getHeaderTitle(routes.OfficialCurriculumList.path)));
                navigate(generatePath(routes.OfficialCurriculumList.path));
              }}
            >
              カリキュラム一覧 <RightOutlined />
            </button>
            <button
              className="btn-tree"
              onClick={() => {
                dispatch(setHeaderTitle(getHeaderTitle(routes.CurriculumTree.path)));
                navigate(generatePath(routes.CurriculumTree.path));
              }}
            >
              カリキュラムツリー <RightOutlined />
            </button>
          </div>
        </div>
        <div className="content" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="search_status"
                className="item"
                label={<span className="text-label">契約状況</span>}
              >
                <SelectField
                  data-testid="pm-search-status"
                  name="search_status"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children || '')
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {SEARCH_PARTNER.map((item, index) => (
                    <Option value={item.label} key={index}>
                      {item.label}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="provider_id"
                className="item"
                label={<span className="text-label">ID</span>}
              >
                <SelectField
                  data-testid="pm-provider-id"
                  name="provider_id"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children || '')
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {providerIdList.map((item, index) => (
                    <Option value={item} key={index}>
                      {item}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="
                provider_name"
                className="item"
                label={<span className="text-label">パートナー名</span>}
              >
                <SelectField
                  data-testid="pm-provider-name"
                  name="provider_name"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children || '')
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {uniqBy(searchSelect, 'company_name').map((item, index) => (
                    <Option value={item.company_name} key={index}>
                      {item.company_name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        {/* <SpinLoading loading={loading}> */}
        <div className={`${management.length > 0 ? 'wrap-button' : 'wrap-button-no-data'}`}>
          <Button className="btn btn-active" onClick={() => setVisibleModalCsv(true)}>
            エクスポート
          </Button>
          <Button
            className="btn btn-active"
            icon={<PlusOutlined className="icon" />}
            onClick={() => setVisibleModalRegister(true)}
          >
            新規登録
          </Button>
        </div>
        <Table
          rowKey="provider_id"
          className="table"
          dataSource={dataSource}
          columns={columns}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: selectedRow.map(({ provider_id }) => provider_id),
            onChange: (_, data) => setSelectedRow(data),
          }}
          pagination={{
            pageSize: perPage,
            current: page,
            onChange: setPage,
            showSizeChanger: false,
            position: ['topCenter'],
            showTotal: () => (
              <div className="wrap-select-record">
                <PagingNumber
                  startItem={dataSource.length ? `${(page - 1) * perPage + 1}` : ''}
                  endItem={page * perPage > dataSource.length ? dataSource.length : page * perPage}
                  totalItem={dataSource.length}
                />
                <PerPageSelect
                  data={dataSource}
                  perPage={perPage}
                  setPage={setPage}
                  setPerPage={setPerpage}
                />
              </div>
            ),
          }}
        />
        {/* </SpinLoading> */}
      </div>
      <div className="wrap-bottom">
        <div className="flex">
          <div className="text-label">
            選択したパートナーを処理：
            <Button className="btn btn-active" onClick={() => handleButtonExport()}>
              契約書インポート
            </Button>
          </div>
        </div>
      </div>
      <ModalDetail
        visibleDetail={visibleDetail}
        setVisible={setVisibleDetail}
        detailSubmit={detailSubmit}
        statusSelect={statusSelect}
      />
      <ModalContract
        visibleContract={visibleContract}
        setVisibleContract={setVisibleContract}
        contractFileId={contractFileId}
        setContractFileId={setContractFileId}
      />
      <ModalConfirmExport
        visible={visibleModalCsv}
        setVisible={setVisibleModalCsv}
        onSubmit={handleExportCSV}
      />
      <LoginSuccess
        visible={visibleSuccess}
        setVisible={() => setVisibleSuccess(false)}
        subTitle="更新が完了しました"
        handleResetForm={() => formik.resetForm()}
      />
      <ActionErrorModal
        setVisible={setVisibleNoPartner}
        visible={visibleNoPartner}
        subTitle="パートナーが選択されていません"
        description={
          <span>
            インポートを実行するパートナーを選択して、
            <br />
            再度実行してください。
          </span>
        }
      />
      <UploadPDF setVisible={setVisibleCsv} visible={visibleCsv} onSubmit={handleUploadCSV} />
      <ModalRegister
        loginEmailList={emailLogin}
        visibleDetail={visibleModalRegister}
        setVisible={setVisibleModalRegister}
        setOpenModalComplete={setOpenModalComplete}
        success={fetchManagement}
      />
      <CompletedModal
        title="送信が完了しました"
        visible={openModalComplete}
        setVisible={setOpenModalComplete}
        onSubmit={() => {
          setOpenModalComplete(!openModalComplete);
          fetchManagement();
        }}
      />
    </PartnerStyled>
  );
};

export default PartnerManagement;
