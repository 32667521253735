import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { setHeaderTitle, startLoading, stopLoading } from 'containers/AppSettings/slice';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { authSelector } from 'containers/Auth/selectors';
import { getDataCurriculum } from './thunk';
import CurriculumMasterTable from './Table';
import CurriculumTreeview from './Treeview';

const Curriculum: React.FC = () => {
  const [openCurriculumMaster, setOpenCurriculumMaster] = useState<boolean>(false);

  const { userInfo } = useSelector(authSelector);

  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setHeaderTitle(openCurriculumMaster ? 'カリキュラムマスタ 階層リスト' : 'カリキュラムツリー')
    );
  }, [openCurriculumMaster, dispatch]);

  const fetchDataCurriculum = async () => {
    if (!userInfo) return;
    dispatch(startLoading());

    await dispatch(
      getDataCurriculum({
        conditions: [
          {
            id: 'provider_id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );

    dispatch(stopLoading());
  };

  useEffect(() => {
    userInfo && isUserInfoChanged && fetchDataCurriculum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isUserInfoChanged]);

  return openCurriculumMaster ? (
    <CurriculumMasterTable
      fetchDataCurriculum={fetchDataCurriculum}
      setOpenCurriculumMaster={setOpenCurriculumMaster}
    />
  ) : (
    <CurriculumTreeview
      fetchDataCurriculum={fetchDataCurriculum}
      setOpenCurriculumMaster={setOpenCurriculumMaster}
    />
  );
};

export default Curriculum;
