import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { Formik, FormikProvider, useFormik } from 'formik';
import { Button, Select, Table } from 'antd';
import { pdf } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import { filter, uniqBy } from 'lodash';
import saveAs from 'file-saver';
import {
  CloudDownloadOutlined,
  SearchOutlined,
  DeleteOutlined,
  FormOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { HEADER_ADMIN_AFFILIATION_HIERARCHY_EXPORT_CSV } from 'constant/header.export.constant';
import { Header, Input, PagingNumber, SelectField, TextArea } from 'components';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import PDFAffiliationMasterDocument from './PDFAffiliationMaster';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import DeleteCompleted from 'components/Modal/DeleteCompleted';
import { CreateEditAffiliationSchema } from 'libs/validations';
import ActionErrorModal from 'components/Modal/ActionError';
import { SELECT_RECORD } from 'constant/select.constants';
import AffiliationMasterPDF from './AffiliationMasterPDF';
import { authSelector } from 'containers/Auth/selectors';
import { affiliationMasterSelector } from './selectors';
import CompletedModal from 'components/Modal/Completed';
import { setDataAdminAffiliationFiled } from './slice';
import { useAppDispatch, usePermission } from 'hooks';
import AffiliationMasterStyled from './styles';
import * as Types from 'types';
import {
  getDataAdminAffiliationLevel,
  deleteAdminAffiliationLevel,
  createAdminAffiliationLevel,
  updateAdminAffiliationLevel,
  getAffiliationAssignRole,
} from './thunk';
import useCheckModifiedPage from 'hooks/useCheckModifiedPage';
import yup from 'libs/yup';
import { setModified } from 'slice/slice';
import PerPageSelect from 'components/PerPageSelect';
import { exportCsv } from 'libs/utils/exportCsv';

const { Option } = Select;
export const TableCellRequired = yup.object().shape({
  name: yup.string().required('権限名称必須入力項目です。').max(100),
});
const AffiliationMaster: React.FC = () => {
  const [itemSelected, setItemSelected] = useState<Types.AdminAffiliationHierarchy.ResponseType>();
  const [itemDeleted, setItemDeleted] = useState<Types.AdminAffiliationHierarchy.ResponseType>();
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [deleteComplete, setDeleteComplete] = useState<boolean>(false);
  const [updateComplete, setUpdateComplete] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [actionModalState, setActionModalState] = useState<{
    subTitle: string;
    description: React.ReactNode;
  }>({
    subTitle: '',
    description: '',
  });

  const handleChange = (check: boolean, type: string) => {
    const form = {
      tableInput: false,
      textArea: false,
    };
    if (type === 'textArea') {
      form.textArea = check;
    } else if (type === 'tableInput') {
      form.tableInput = check;
    }

    dispatch(setModified(form.textArea || form.tableInput));
  };

  const { permissionNumber } = usePermission();
  const ref = useRef(null);

  const { dataAdminAffiliationFiled, dataAdminAffiliation, affiliationAssignRoles } =
    useSelector(affiliationMasterSelector);
  const [sortedData, setSortedData] = useState<Types.AffiliationLevel.ResponseType[]>([]);
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const adminAffiliationSelect = useMemo(
    () => uniqBy(dataAdminAffiliation, 'name'),
    [dataAdminAffiliation]
  );

  const dispatch = useAppDispatch();

  const formikSearch = useFormik<Types.SearchAuthorityMasterFormik>({
    initialValues: {
      name: '',
    },
    onSubmit: ({ name }) => {
      if (!name) {
        setActionModalState({
          subTitle: '検索するマスタが未選択です',
          description: (
            <p className="text-content">
              検索する所属を選択後、
              <br />
              「検索」をクリックしてください。
            </p>
          ),
        });
        setShowActionErrorModal(true);
      } else {
        dispatch(
          setDataAdminAffiliationFiled({ items: filter(dataAdminAffiliation, { name: name }) })
        );
        setPage(1);
      }
    },
    onReset: async () => {
      dispatch(setDataAdminAffiliationFiled({ items: dataAdminAffiliation }));
      dispatch(setModified(false));
      setPage(1);
    },
  });

  const formik = useFormik<Types.CreateEditJobTitleFormik>({
    initialValues: {
      name: '',
    },
    enableReinitialize: true,
    validationSchema: CreateEditAffiliationSchema,
    onSubmit: async (values, { resetForm }) => {
      dispatch(startLoading());
      const resultAction = await dispatch(
        createAdminAffiliationLevel({
          item: {
            company_id: userInfo?.company_id,
            name: values.name,
          },
          return_item_result: true,
        })
      );

      if (createAdminAffiliationLevel.fulfilled.match(resultAction)) {
        formikSearch.resetForm();
        await fetchDataAdminAffiliation();
        setShowCompleteModal(true);
      } else {
        setActionModalState({
          subTitle: '新規役職の登録に失敗しました',
          description: (
            <p className="text-content">
              新規役職の登録に失敗しました。
              <br />
              もう一度情報を入力し、再度お試しください。
            </p>
          ),
        });
        setShowActionErrorModal(true);
      }

      dispatch(stopLoading());
      resetForm();
    },
    onReset: async () => {
      dispatch(setDataAdminAffiliationFiled({ items: dataAdminAffiliation }));
    },
  });

  const isEditing = (record: Types.AdminAffiliationHierarchy.ResponseType) =>
    record.i_id === itemSelected?.i_id;

  const handleDeleteItem = (item: Types.AdminAffiliationHierarchy.ResponseType) => {
    const index = affiliationAssignRoles.findIndex(
      (value: Types.AffiliationAssignRole.ResponseType) =>
        value?.lookup_items?.affiliation_id?.affiliation_id === item?.affiliation_id
    );
    if (permissionNumber !== 1) {
      setItemDeleted(index <= -1 ? item : undefined);
      setOpenModalConfirmDeleteItem(true);
    }
  };

  const handleSubmitDelete = async () => {
    setActionModalState({
      subTitle: '削除に失敗しました',
      description: (
        <p className="text-content">マスタの削除に失敗しました。 再度お試しください。</p>
      ),
    });
    if (!itemDeleted) return;
    dispatch(startLoading());
    const resultAction = await dispatch(deleteAdminAffiliationLevel({ id: itemDeleted.i_id }));
    if (deleteAdminAffiliationLevel.fulfilled.match(resultAction)) {
      formikSearch.resetForm();
      await fetchDataAdminAffiliation();
      setOpenModalConfirmDeleteItem(false);
      setDeleteComplete(true);
    } else {
      setShowActionErrorModal(true);
    }

    setItemDeleted(undefined);
    dispatch(stopLoading());
  };
  const wrapEmojiWithSpan = (text: string) => {
    const emojiRegex = /([\uD800-\uDBFF][\uDC00-\uDFFF])/g;
    return text.split(emojiRegex).map((part, index) => {
      if (emojiRegex.test(part)) {
        return (
          <span key={index} className="emoji">
            {part}
          </span>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };
  const columns: any = [
    {
      key: 'affiliation_id',
      title: 'コード',
      dataIndex: 'affiliation_id',
      width: '13%',
      align: 'center',
      sorter: (
        a: Types.AdminAffiliationHierarchy.ResponseType,
        b: Types.AdminAffiliationHierarchy.ResponseType
      ) => Number(a.affiliation_id) - Number(b.affiliation_id),
    },
    {
      key: 'name',
      title: '所属名称',
      dataIndex: 'name',
      width: '60%',
      editable: true,
      render: (text: string) => (
        <>
          <div>{wrapEmojiWithSpan(text.slice(0, 50))}</div>
          <div>{wrapEmojiWithSpan(text.slice(50))}</div>
        </>
      ),
    },
    {
      title: '編集',
      dataIndex: 'operation',
      align: 'center',
      width: '7%',
      render: (_: string, record: Types.AdminAffiliationHierarchy.ResponseType) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="wrap-edit-submit">
            <SubmitButton className="btn btn_submit" loading={false}>
              <PlusOutlined className="size-icon" />
              更新
            </SubmitButton>
            <button
              type="button"
              className="btn btn_close"
              onClick={() => setItemSelected(undefined)}
            >
              キャンセル
            </button>
          </div>
        ) : (
          <FormOutlined className="icon" onClick={() => setItemSelected(record)} />
        );
      },
    },
    {
      title: '削除',
      width: '7%',
      dataIndex: 'i_id',
      align: 'left',
      render: (_: string, record: Types.AdminAffiliationHierarchy.ResponseType) =>
        itemSelected?.i_id !== record.i_id ? (
          <DeleteOutlined className="icon" onClick={() => handleDeleteItem(record)} />
        ) : null,
    },
  ];

  const fetchDataAdminAffiliation = useCallback(async () => {
    if (userInfo) {
      dispatch(startLoading());
      await dispatch(
        getDataAdminAffiliationLevel({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          include_lookups: true,
          use_display_id: true,
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    }
  }, [dispatch, userInfo]);

  const EditableRow: React.FC<Types.TypeRow> = ({ children, ...restProps }) => (
    <Formik<Types.CreateEditAnalysisGroupFormik>
      initialValues={{
        name: itemSelected?.name || '',
      }}
      enableReinitialize
      validationSchema={CreateEditAffiliationSchema}
      onSubmit={async (values) => {
        if (!itemSelected) return;
        dispatch(startLoading());
        const resultAction = await dispatch(
          updateAdminAffiliationLevel({
            id: itemSelected.i_id,
            data: {
              item: {
                name: values.name,
              },
              is_force_update: true,
            },
          })
        );
        setItemSelected(undefined);
        if (updateAdminAffiliationLevel.fulfilled.match(resultAction)) {
          formikSearch.resetForm();
          await fetchDataAdminAffiliation();
          setUpdateComplete(true);
        } else {
          setActionModalState({
            subTitle: '役職の更新に失敗しました',
            description: (
              <p className="text-content">
                役職の更新に失敗しました。
                <br />
                もう一度情報を入力し、再度お試しください。
              </p>
            ),
          });
          setShowActionErrorModal(true);
        }
        dispatch(stopLoading());
      }}
    >
      <tr {...restProps}>{children}</tr>
    </Formik>
  );

  const EditableCell: React.FC<Types.TypeCell<Types.AdminAffiliationHierarchy.ResponseType>> = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        <FormEdit
          children={children}
          restProps={restProps}
          handleChange={handleChange}
          editing={editing}
          record={record}
          dataIndex={dataIndex}
        />
      </td>
    );
  };

  const mergedColumns = columns.map(
    (col: Types.TypeCell<Types.AdminAffiliationHierarchy.ResponseType>) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record: Types.AdminAffiliationHierarchy.ResponseType) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    }
  );

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const listCsv = dataAdminAffiliationFiled.map((item) => ({
        code: item.affiliation_id || '',
        name: item.name || '',
      }));
      // const csvString = [
      //   HEADER_ADMIN_AFFILIATION_HIERARCHY_EXPORT_CSV.map(({ label }) => label),
      //   ...listCsv.map((item) => Object.values(item).map((v) => `"${v}"`)),
      // ]
      //   .map((e) => e.join(','))
      //   .join('\n');
      // const bom = '\uFEFF';
      // const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      // saveAs(file, '所属マスタ.csv');
      exportCsv(listCsv, HEADER_ADMIN_AFFILIATION_HIERARCHY_EXPORT_CSV, '所属マスタ.csv');
    } else {
      const blob = await pdf(<PDFAffiliationMasterDocument dataSource={sortedData} />).toBlob();
      saveAs(blob, '所属マスタ.pdf');
    }
    setShowConfirmExportFileModal(false);
  };
  const component = useMemo(() => {
    return (
      <div
        ref={ref}
        style={{
          position: 'absolute',
          right: '9999px',
          width: '100%',
        }}
      >
        <AffiliationMasterPDF dataAdminAffiliation={dataAdminAffiliationFiled} page={page} />
      </div>
    );
  }, [dataAdminAffiliationFiled, page]);

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await fetchDataAdminAffiliation();

      await dispatch(
        getAffiliationAssignRole({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
          sort_fields: [{ id: 'display_order', order: 'asc' }],
          include_lookups: true,
          use_display_id: true,
        })
      );

      dispatch(stopLoading());
    })();
  }, [dispatch, fetchDataAdminAffiliation, userInfo]);

  useEffect(() => {
    setSortedData(dataAdminAffiliationFiled);
  }, [dataAdminAffiliationFiled]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { order, field } = sorter;
    if (order && field) {
      const sorted = [...dataAdminAffiliationFiled].sort((a, b) => {
        if (order === 'ascend') {
          return Number(a.affiliation_id) - Number(b.affiliation_id);
        }
        return Number(b.affiliation_id) - Number(a.affiliation_id);
      });
      setSortedData(sorted);
    } else {
      setSortedData(dataAdminAffiliationFiled);
    }
  };

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerPage(dataAdminAffiliationFiled.length);
    }
  }, [dataAdminAffiliationFiled]);

  return (
    <AffiliationMasterStyled
      collapsedMenu={collapsedMenu}
      isEmptyData={!dataAdminAffiliationFiled.length}
    >
      {component}
      <Header title={headerTitle} className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            所属マスタの作成・管理を行う画面です。
            <br />
            作成した所属マスタはユーザーに設定します。
          </p>
          <div className="border" />
          <FormikProvider value={formikSearch}>
            <Form
              layout="vertical"
              labelCol={{
                flex: '10%',
              }}
              colon={false}
              className="form-search"
            >
              <Form.Item
                name="name"
                label={<span className="label">所属検索</span>}
                className="form-input"
              >
                <SelectField
                  showSearch
                  className="select-input"
                  placeholder="選択してください"
                  filterOption={(input, option) =>
                    option!.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  name="name"
                >
                  {adminAffiliationSelect.map(
                    ({ name }, i) =>
                      name && (
                        <Option value={name} key={i}>
                          {name}
                        </Option>
                      )
                  )}
                </SelectField>
              </Form.Item>
              <div className="wrap-btn">
                <SubmitButton className="btn-search" loading={false}>
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <ResetButton className="btn-reset">
                  <span className="label-reset">リセット</span>
                </ResetButton>
              </div>
            </Form>
          </FormikProvider>

          <div className="wrap-table">
            <Button className="btn-active" onClick={() => setShowConfirmExportFileModal(true)}>
              <CloudDownloadOutlined className="size-icon" />
              エクスポート
            </Button>

            <Table
              data-testid="table-affiliation-master"
              dataSource={dataAdminAffiliationFiled.map((item, index) => ({ ...item, index }))}
              columns={mergedColumns}
              components={{
                body: {
                  row: EditableRow,
                  cell: EditableCell,
                },
              }}
              className="table"
              pagination={{
                showSizeChanger: false,
                current: page,
                pageSize: perPage,
                onChange: setPage,
                position: ['topCenter'],
                showTotal: () => (
                  <div className="wrap-select-record">
                    <PagingNumber
                      startItem={
                        dataAdminAffiliationFiled.length ? `${(page - 1) * perPage + 1}` : ''
                      }
                      endItem={
                        page * perPage > dataAdminAffiliationFiled.length
                          ? dataAdminAffiliationFiled.length
                          : page * perPage
                      }
                      totalItem={dataAdminAffiliationFiled.length}
                    />
                    <PerPageSelect
                      data={dataAdminAffiliationFiled}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerPage}
                    />
                  </div>
                ),
              }}
              rowKey="index"
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
      <div className="wrap-create">
        <p className="title">所属新規登録</p>
        <Formik<Types.CreateEditJobTitleFormik>
          initialValues={{
            name: '',
          }}
          enableReinitialize
          validationSchema={CreateEditAffiliationSchema}
          onSubmit={async (values, { resetForm }) => {
            dispatch(startLoading());
            const resultAction = await dispatch(
              createAdminAffiliationLevel({
                item: {
                  company_id: userInfo?.company_id,
                  name: values.name,
                },
                return_item_result: true,
              })
            );
            if (createAdminAffiliationLevel.fulfilled.match(resultAction)) {
              formikSearch.resetForm();
              await fetchDataAdminAffiliation();
              setShowCompleteModal(true);
            } else {
              setActionModalState({
                subTitle: '新規役職の登録に失敗しました',
                description: (
                  <p className="text-content">
                    新規役職の登録に失敗しました。
                    <br />
                    もう一度情報を入力し、再度お試しください。
                  </p>
                ),
              });
              setShowActionErrorModal(true);
            }

            dispatch(stopLoading());
            resetForm();
          }}
        >
          <Form colon={false} layout="vertical" className="flex">
            <Form.Item
              name="name"
              label={
                <span className="text-label">
                  所属名称
                  <span className="require">*</span>
                </span>
              }
              className="form-text-area"
            >
              <TextArea
                onChange={(e) => {
                  handleChange(!!e.target.value, 'textArea');
                }}
                name="name"
                showCount
                maxLength={100}
                placeholder="最大100文字"
              />
            </Form.Item>
            <SubmitButton className="btn btn_submit">
              <PlusOutlined className="size-icon" />
              新規登録
            </SubmitButton>
          </Form>
        </Formik>
      </div>
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
        title="所属マスタをエクスポートします。"
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle={actionModalState.subTitle}
        description={actionModalState.description}
      />
      <CompletedModal
        title="登録が完了しました"
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        onSubmit={() => {
          if (SELECT_RECORD.includes(perPage)) return;
          setPerPage(dataAdminAffiliationFiled.length);
          setPage(1);
        }}
      />
      <CompletedModal
        title="更新が完了しました"
        visible={updateComplete}
        setVisible={setUpdateComplete}
      />
      <DeleteCompleted visible={deleteComplete} setVisible={setDeleteComplete} />
      <ConfirmDeleteModal
        itemDeleted={!!itemDeleted}
        title="削除確認"
        subTitle={itemDeleted ? 'データの削除を実行します' : 'データの削除は実行できません。'}
        description={
          itemDeleted
            ? 'データの削除を実行すると、復元できませんのでご注意ください。'
            : '選択したマスタは社内ユーザーに設定されているため、削除できません。'
        }
        visible={openModalConfirmDeleteItem}
        onSubmit={itemDeleted ? handleSubmitDelete : undefined}
        setVisible={setOpenModalConfirmDeleteItem}
      />
    </AffiliationMasterStyled>
  );
};

export default AffiliationMaster;

const FormEdit = ({ children, record, editing, restProps, handleChange, dataIndex }: any) => {
  return (
    <Form {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex}>
          <TextArea
            style={{ height: 70 }}
            autoSize={{ minRows: 3 }}
            onChange={(e) => {
              handleChange(!(e.target.value === record.name), 'tableInput');
            }}
            maxLength={100}
            showCount
            name="name"
          />
        </Form.Item>
      ) : (
        children
      )}
    </Form>
  );
};
