import React, { useMemo } from 'react';
import { maxBy, findLast, padEnd, split, get } from 'lodash';
import { useSelector } from 'react-redux';

import { downloadFileFromR2, uploadFileToR2 } from 'services/r2Service';
import { Wrapper as WrapperColumnRoot } from './TreeNodeRenderer/styles';
import { startLoading, stopLoading } from '../../AppSettings/slice';
import { curriculumSelector } from 'pages/Curriculum/selectors';
import { authSelector } from 'containers/Auth/selectors';
import ColumnRenderer from './TreeNodeRenderer';
import NodeRenderer from './NodeRenderer';
import { useAppDispatch } from 'hooks';
import { Wrapper } from './styles';
import * as Types from 'types';
import {
  memoizedGetChildrenItemIDFromTree,
  memoizedGetFlatDataFromTree,
} from 'libs/utils/curriculum/memoized-tree-data-utils';
import {
  createLinkQuestionAssignLevel,
  deleteLinkQuestion,
} from 'containers/CreateEditQuestion/thunk';
import {
  createLevelCurriculum,
  deleteLevelCurriculum,
  getDataCurriculum,
  updateCurriculum,
  createCurriculum,
  createFileAttach,
} from 'pages/Curriculum/thunk';
import { setFilterByCurriculum, setFilterByStatus } from 'pages/Curriculum/slice';

const NODE_HEIGHT = 50;

type Props = {
  setShowCompleteModal?: React.Dispatch<React.SetStateAction<boolean>>;
  itemMoveCopySelected?: Types.ItemMoveCopySelectedType;
  treeData: Types.TreeItem<Types.CurriculumItemType>;
  columnClosed?: number;
  treeViewIndex: number;
  isShowRoot?: boolean;
  isLastPage?: boolean;
  tabActive?: number;
  isExport?: boolean;
  isIndex: number;
  isPartners?: boolean;
  setItemMoveCopySelected?: React.Dispatch<
    React.SetStateAction<Types.ItemMoveCopySelectedType | undefined>
  >;
};

const TreeView: React.FC<Props> = ({
  setItemMoveCopySelected,
  itemMoveCopySelected,
  setShowCompleteModal,
  isShowRoot = true,
  tabActive = 0,
  treeViewIndex,
  columnClosed,
  isLastPage,
  treeData,
  isExport,
  isIndex,
  isPartners,
}) => {
  const { dataCurriculumTree, nodeLevel4Selected, conditions } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const data = useMemo<{ [key: string]: Types.TreeItem<Types.CurriculumItemType>[] }>(() => {
    const column: { [key: string]: Types.TreeItem<Types.CurriculumItemType>[] } = {};
    const dataPrimitive = memoizedGetFlatDataFromTree({
      treeData,
    });
    dataPrimitive.forEach((item: Types.TreeItem<Types.CurriculumItemType>) => {
      column[item.columnIndex!] = [...(column[item.columnIndex!] || []), item];
    });
    return column;
  }, [treeData]);

  const items = useMemo(() => {
    return Array.from({
      length: columnClosed !== undefined ? columnClosed + 1 : 6,
    });
  }, [columnClosed]);

  const maxCurriculumCode = useMemo(() => {
    const itemMaxCode = maxBy(dataCurriculumTree, (e) => Number(split(e.code, 'A').join(''))) ?? '';

    if (itemMaxCode) {
      const number = Number(split(itemMaxCode.code, 'A').join(''));
      const subString = Array.from({
        length: 8 - (number + 1).toString().length,
      })
        .map(() => '0')
        .concat((number + 1).toString())
        .join('');

      return padEnd('A', 9, subString.toString());
    }
    return 'A00000001';
  }, [dataCurriculumTree]);

  const onDrop = async (
    type: 'move' | 'copy',
    currentItem: Types.TreeItem<Types.CurriculumItemType>,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => {
    dispatch(startLoading());
    const listChildItemID: Types.FlatChildrenItemID<Types.CurriculumItemType>[] =
      memoizedGetChildrenItemIDFromTree({
        treeData: currentItem.node,
      });
    for (let index = 0; index < listChildItemID.length; index++) {
      const item = listChildItemID[index];
      if (item.columnIndex < 5) {
        const resultAction = await dispatch(
          createLevelCurriculum({
            level: item.columnIndex,
            item: {
              official_curriculum_code: targetItem.code,
              provider_id: userInfo?.company_id,
              name: item.name,
              sort_order:
                Number(maxBy(targetItem.children || [], 'sort_order')?.sort_order || 0) + 1,
              [`level${item.columnIndex - 1}_code`]:
                index === 0
                  ? targetItem.code
                  : findLast(
                      listChildItemID.slice(0, index),
                      (i) => i.columnIndex === item.columnIndex - 1
                    )?.code,
            },
            return_item_result: true,
            return_display_id: true,
          })
        );
        if (createLevelCurriculum.fulfilled.match(resultAction)) {
          switch (item.columnIndex) {
            case 1:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            case 2:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            case 3:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            case 4:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            default:
              break;
          }
          if (type === 'move') {
            await dispatch(
              deleteLevelCurriculum({
                id: item.i_id!,
                level: item.columnIndex,
              })
            );
          }
        }
      } else {
        const resultAction = await dispatch(
          createLinkQuestionAssignLevel({
            item: {
              provider_id: userInfo?.company_id,
              level4_code:
                findLast(
                  listChildItemID.slice(0, index),
                  (i) => i.columnIndex === item.columnIndex - 1
                )?.code || targetItem.code,
              code: item.code,
              sort_order:
                Number(maxBy(targetItem.children || [], 'sort_order')?.sort_order || 0) + 1,
              createdat: new Date(),
              createdby: userInfo?.login_id,
            },
          })
        );
        if (createLinkQuestionAssignLevel.fulfilled.match(resultAction) && type === 'move') {
          await dispatch(
            deleteLinkQuestion({
              id: item.question_assign_level_i_id!,
            })
          );
        }
      }
      setItemMoveCopySelected && setItemMoveCopySelected(undefined);
    }

    const question_copy_length = listChildItemID.filter((item) => item.columnIndex === 5).length;
    if (type === 'copy' && question_copy_length) {
      const curriculum = listChildItemID.find((curr) => curr.curriculum_id);
      if (curriculum) {
        await dispatch(
          updateCurriculum({
            id: curriculum.curriculum_id!,
            data: {
              item: {
                probs_count: (curriculum?.problems_count || 0) + question_copy_length,
                updatedat: new Date(),
              },
              return_item_result: true,
              is_force_update: true,
            },
          })
        );
      }
    }

    const result = await dispatch(
      getDataCurriculum({
        conditions: [
          {
            id: 'provider_id',
            search_value: [userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    if (getDataCurriculum.fulfilled.match(result)) {
      dispatch(
        setFilterByStatus({
          status: conditions?.status,
        })
      );

      dispatch(
        setFilterByCurriculum({
          name: conditions?.name,
        })
      );
    }

    dispatch(stopLoading());
  };

  const onDropNode = async (currentNode: Types.TreeItem<Types.CurriculumItemType>) => {
    if (!userInfo) return;
    dispatch(startLoading());
    const listChildItemID: Types.FlatChildrenItemID<Types.CurriculumItemType>[] =
      memoizedGetChildrenItemIDFromTree({
        treeData: currentNode.node,
      });

    let fileId = '';
    if (currentNode.node?.fileID) {
      const nodeFile = await downloadFileFromR2(currentNode.node?.fileID);
      if (nodeFile) {
        fileId = await uploadFileToR2(nodeFile);
        await dispatch(
          createFileAttach({
            item: {
              fileID: fileId,
              filename: nodeFile.name,
              file_location: '1',
              file_extension: nodeFile.type,
              file_size: `${nodeFile.size}`,
              company_id: userInfo?.company_id,
              createdat: new Date(),
              createdby: userInfo?.login_id,
            },
            access_key_updates: {
              roles_to_publish: ['MEMBER'],
            },
          })
        );
      }
    }

    const createCurriculumResult = await dispatch(
      createCurriculum({
        item: {
          company_id: userInfo.company_id,
          name: `${currentNode?.node?.name} コピー`,
          description: currentNode.node?.description,
          sort_order: 1,
          required_curriculum: currentNode.node?.required_curriculum,
          fileID: fileId,
          publish: 1,
          probs_count: currentNode.node?.problems_count,
          official_curriculum_code: maxCurriculumCode,
          createdat: new Date(),
          createdby: userInfo?.login_id,
          creator: userInfo?.login_id,
          official_curriculum: 0,
          provider_id: userInfo?.company_id,
          archive_flag: 0,
        },
        return_item_result: true,
        return_display_id: true,
      })
    );

    if (createCurriculum.fulfilled.match(createCurriculumResult)) {
      for (let index = 1; index < listChildItemID.length; index++) {
        const item = listChildItemID[index];
        if (item.columnIndex < 5) {
          const resultAction = await dispatch(
            createLevelCurriculum({
              level: item.columnIndex,
              item: {
                company_id: userInfo.company_id,
                name: item.name,
                sort_order: item.sort_order,
                official_curriculum_code:
                  createCurriculumResult.payload?.item?.official_curriculum_code,
                [`level${item.columnIndex - 1}_code`]:
                  index === 0
                    ? createCurriculumResult.payload?.item?.official_curriculum_code
                    : findLast(
                        listChildItemID.slice(0, index),
                        (i) => i.columnIndex === item.columnIndex - 1
                      )?.code || '',
              },
              return_item_result: true,
              return_display_id: true,
            })
          );
          if (createLevelCurriculum.fulfilled.match(resultAction)) {
            switch (item.columnIndex) {
              case 1:
                listChildItemID[index].code = resultAction.payload.item['code'];
                break;
              case 2:
                listChildItemID[index].code = resultAction.payload.item['code'];
                break;
              case 3:
                listChildItemID[index].code = resultAction.payload.item['code'];
                break;
              case 4:
                listChildItemID[index].code = resultAction.payload.item['code'];
                break;
              default:
                break;
            }
          }
        } else {
          await dispatch(
            createLinkQuestionAssignLevel({
              item: {
                company_id: userInfo?.company_id,
                level4_code:
                  findLast(
                    listChildItemID.slice(0, index),
                    (i) => i.columnIndex === item.columnIndex - 1
                  )?.code || '',
                code: item.code,
                sort_order:
                  Number(item.sort_order) +
                  Number(maxBy(listChildItemID || [], 'sort_order')?.sort_order || 0) +
                  1,
                createdat: new Date(),
                createdby: userInfo?.login_id,
              },
              realtime_auto_link: true,
            })
          );
        }
      }
      const result = await dispatch(
        getDataCurriculum({
          conditions: [
            {
              id: 'provider_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      if (getDataCurriculum.fulfilled.match(result)) {
        await dispatch(
          setFilterByStatus({
            status: conditions?.status,
          })
        );

        await dispatch(
          setFilterByCurriculum({
            name: conditions?.name,
          })
        );

        setShowCompleteModal && setShowCompleteModal(true);
      }
    }
    dispatch(stopLoading());
  };

  const onDropQuestion = async (
    currentItem: Types.DropQuestionType,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => {
    if (currentItem.type === 'move-question') {
      dispatch(startLoading());
      const resultActions = await dispatch(
        createLinkQuestionAssignLevel({
          item: {
            provider_id: userInfo?.company_id,
            level4_code: targetItem.code,
            code: currentItem.item.code,
            sort_order: targetItem.sort_order,
          },
        })
      );

      if (createLinkQuestionAssignLevel.fulfilled.match(resultActions)) {
        await dispatch(
          updateCurriculum({
            id: nodeLevel4Selected?.curriculum_id!,
            data: {
              item: {
                probs_count:
                  ((nodeLevel4Selected?.children?.length! > nodeLevel4Selected?.problems_count!
                    ? nodeLevel4Selected?.children?.length!
                    : nodeLevel4Selected?.problems_count!) || 0) + 1,
                updatedat: new Date(),
              },
              return_item_result: true,
              is_force_update: true,
            },
          })
        );
      }

      await Promise.all([
        currentItem.onSubmit(),
        dispatch(
          getDataCurriculum({
            conditions: [
              {
                id: 'provider_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);

      dispatch(stopLoading());
    }
  };

  if (!data[0]) {
    return null;
  }

  return (
    <Wrapper className="rst__tree">
      {isShowRoot && (
        <WrapperColumnRoot style={{ height: NODE_HEIGHT }} className="rst__node">
          <div className="rst__nodeContent">
            <NodeRenderer
              index={0}
              onDrop={onDrop}
              node={data[0][0]}
              rootNode={data[0][0]}
              tabActive={tabActive}
              nodeHeight={NODE_HEIGHT}
              treeViewIndex={treeViewIndex}
              accept={data[0][0].node!.i_id!}
              maxSortOrder={(maxBy(data[1], (o) => o.node?.sort_order)?.node?.sort_order || 0) + 1}
              onDropNode={onDropNode}
              isIndex={isIndex}
              isExport={isExport}
              itemMoveCopySelected={itemMoveCopySelected}
              isPartners={isPartners}
            />
          </div>
        </WrapperColumnRoot>
      )}
      <div className="wrap_node_tree">
        {isExport && !isLastPage && get(data[0][0].node, 'hidden', false) && (
          <div
            style={{
              position: 'absolute',
              top: 28,
              left: 47,
              height: '140%',
              width: '2px',

              backgroundImage: 'linear-gradient(to bottom, #8b8b8b 40%, transparent 0%)',
              backgroundPosition: 'right',
              backgroundSize: '1px 3px',
              backgroundRepeat: 'repeat-y',
            }}
          />
        )}
        {items.map((_, index) =>
          index > 0 ? (
            <ColumnRenderer
              rootNode={data[0][0]}
              key={index}
              onDrop={onDrop}
              columnIndex={index}
              column={data[index]}
              isIndex={isIndex}
              tabActive={tabActive}
              nodeHeight={NODE_HEIGHT}
              treeViewIndex={treeViewIndex}
              onDropQuestion={onDropQuestion}
              accept={data[0][0].node!.i_id!}
              isPublish={data[0][0].node?.publish === 2}
              itemMoveCopySelected={itemMoveCopySelected}
              setItemMoveCopySelected={setItemMoveCopySelected}
              maxSortOrder={
                (maxBy(data[index + 1], (o) => o.node?.sort_order)?.node?.sort_order || 0) + 1
              }
              isExport={isExport}
              isPartners={isPartners}
            />
          ) : (
            <div style={{ width: '3.328%', flexGrow: 0 }} className="column" key={index} />
          )
        )}
      </div>
    </Wrapper>
  );
};

export default TreeView;

export const MemorizedTreview = React.memo(TreeView);
