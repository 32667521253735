import { Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const BarChartIcon = ({ style, fill }: { style?: Style | Style[]; fill?: string }) => (
  <Svg width="18" height="16" viewBox="0 0 18 16" style={style}>
    <Path
      d="M15.554 13.6261H1.73256V1.0904C1.73256 1.00201 1.66024 0.929688 1.57185 0.929688H0.446847C0.358454 0.929688 0.286133 1.00201 0.286133 1.0904V14.9118C0.286133 15.0002 0.358454 15.0725 0.446847 15.0725H15.554C15.6424 15.0725 15.7147 15.0002 15.7147 14.9118V13.7868C15.7147 13.6984 15.6424 13.6261 15.554 13.6261ZM3.50042 12.019H4.62542C4.71381 12.019 4.78613 11.9467 4.78613 11.8583V8.9654C4.78613 8.87701 4.71381 8.80469 4.62542 8.80469H3.50042C3.41203 8.80469 3.3397 8.87701 3.3397 8.9654V11.8583C3.3397 11.9467 3.41203 12.019 3.50042 12.019ZM6.55399 12.019H7.67899C7.76738 12.019 7.8397 11.9467 7.8397 11.8583V5.42969C7.8397 5.34129 7.76738 5.26897 7.67899 5.26897H6.55399C6.4656 5.26897 6.39328 5.34129 6.39328 5.42969V11.8583C6.39328 11.9467 6.4656 12.019 6.55399 12.019ZM9.60756 12.019H10.7326C10.821 12.019 10.8933 11.9467 10.8933 11.8583V6.99665C10.8933 6.90826 10.821 6.83594 10.7326 6.83594H9.60756C9.51917 6.83594 9.44685 6.90826 9.44685 6.99665V11.8583C9.44685 11.9467 9.51917 12.019 9.60756 12.019ZM12.6611 12.019H13.7861C13.8745 12.019 13.9468 11.9467 13.9468 11.8583V3.82254C13.9468 3.73415 13.8745 3.66183 13.7861 3.66183H12.6611C12.5727 3.66183 12.5004 3.73415 12.5004 3.82254V11.8583C12.5004 11.9467 12.5727 12.019 12.6611 12.019Z"
      fill={!fill ? '#C4C4C4' : ''}
    />
  </Svg>
);

export default BarChartIcon;
