import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  config,
  CURRICULUM_HIERARCHY,
  LEVEL1S_OFFICIAL_CURRICULUM_HIERARCHY,
  LEVEL2S_OFFICIAL_CURRICULUM_HIERARCHY,
  LEVEL3S_OFFICIAL_CURRICULUM_HIERARCHY,
  LEVEL4S_OFFICIAL_CURRICULUM_HIERARCHY,
  OFFICIAL_CURRICULUM_EXPORT,
  OFFICIAL_CURRICULUM_HIERARCHY,
  QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM_HIERARCHY,
  QUESTIONS_HIERARCHY,
} from 'configs';
import { services } from 'services';
import * as Types from 'types';
import { chain, filter, find, map, uniq, uniqBy } from 'lodash';
import { memoizedConvertFlatDataFromTree } from 'libs/utils/curriculum/memoized-tree-data-utils';
import {
  getTotalQuestionAtLevel,
  getTotalQuestionCurriculum,
} from 'libs/utils/curriculum/tree-data-utils';
import { browserLogger } from 'libs/logger';

export const getOfficialCurriculumHierarchyList = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('CurriculumTree/thunk/getOfficialCurriculumHierarchyList', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(
      CURRICULUM_HIERARCHY.name,
      {
        ...req,
        include_item_ref: true,
        sort_fields: [
          { id: 'curriculum_sort_order', order: 'asc' },
          { id: 'level1_sort_order', order: 'asc' },
          { id: 'level2_sort_order', order: 'asc' },
          { id: 'level3_sort_order', order: 'asc' },
          { id: 'level4_sort_order', order: 'asc' },
          { id: 'question_sort_order', order: 'asc' },
        ],
      },
      config.APP_ID_PARTNER
    );
    browserLogger.info(
      'CurriculumTree/thunk/getOfficialCurriculumHierarchyList',
      CURRICULUM_HIERARCHY.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataOfficialCurriculumExportCSV = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('CurriculumTree/thunk/getDataOfficialCurriculumExportCSV', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(
      OFFICIAL_CURRICULUM_EXPORT.name,
      req,
      config.APP_ID_PARTNER
    );
    browserLogger.info(
      'CurriculumTree/thunk/getDataOfficialCurriculumExportCSV',
      OFFICIAL_CURRICULUM_EXPORT.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel1 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('CurriculumTree/thunk/getDataLevel1', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.searchPartner<Types.Level.ResponseType>(
      LEVEL1S_OFFICIAL_CURRICULUM_HIERARCHY.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info(
      'CurriculumTree/thunk/getDataLevel1',
      LEVEL1S_OFFICIAL_CURRICULUM_HIERARCHY.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel2 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('CurriculumTree/thunk/getDataLevel2', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.searchPartner<Types.Level.ResponseType>(
      LEVEL2S_OFFICIAL_CURRICULUM_HIERARCHY.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info(
      'CurriculumTree/thunk/getDataLevel2',
      LEVEL2S_OFFICIAL_CURRICULUM_HIERARCHY.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel3 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('CurriculumTree/thunk/getDataLevel3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.searchPartner<Types.Level.ResponseType>(
      LEVEL3S_OFFICIAL_CURRICULUM_HIERARCHY.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info(
      'CurriculumTree/thunk/getDataLevel3',
      LEVEL3S_OFFICIAL_CURRICULUM_HIERARCHY.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel4 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('CurriculumTree/thunk/getDataLevel4', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.searchPartner<Types.Level.ResponseType>(
      LEVEL4S_OFFICIAL_CURRICULUM_HIERARCHY.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info(
      'CurriculumTree/thunk/getDataLevel4',
      LEVEL4S_OFFICIAL_CURRICULUM_HIERARCHY.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQuesLevel = createAsyncThunk<
  Types.GetItemResponseType<Types.QuestionAssignLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('CurriculumTree/thunk/getDataQuesLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.searchPartner<Types.QuestionAssignLevel.ResponseType>(
      QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM_HIERARCHY.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info(
      'CurriculumTree/thunk/getDataQuesLevel',
      QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM_HIERARCHY.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQues = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('CurriculumTree/thunk/getDataQues', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.searchPartner<Types.Questions.ResponseType>(
      QUESTIONS_HIERARCHY.name,
      req
    );
    browserLogger.info('CurriculumTree/thunk/getDataQues', QUESTIONS_HIERARCHY.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumSelect = createAsyncThunk<
  Types.GetItemResponseType<Types.Curriculum.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('CurriculumTree/thunk/getCurriculumSelect', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.searchPartner<Types.Curriculum.ResponseType>(
      OFFICIAL_CURRICULUM_HIERARCHY.name,
      req
    );
    browserLogger.info(
      'CurriculumTree/thunk/getCurriculumSelect',
      OFFICIAL_CURRICULUM_HIERARCHY.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataCurriculum = createAsyncThunk<
  Types.GetItemResponseType<Types.CurriculumTable.ResponseType> & {
    dataCurriculumTree: Array<Types.TreeItem<Types.CurriculumItemType>>;
    dataCurriculums: Types.Curriculum.ResponseType[];
    dataQuestions: Types.Questions.ResponseType[];
    dataLevel1s: Types.Level.ResponseType[];
    dataLevel2s: Types.Level.ResponseType[];
    dataLevel3s: Types.Level.ResponseType[];
    dataLevel4s: Types.Level.ResponseType[];
    dataKeyDontHaveQuestion: string[];
    questionKeys: string[];
  },
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'CurriculumTree/thunk/getDataCurriculum',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await services.searchPartner<Types.Curriculum.ResponseType>(
        OFFICIAL_CURRICULUM_HIERARCHY.name,
        {
          ...req,
          sort_fields: [{ id: 'curricullum_code', order: 'asc' }],
        }
      );
      browserLogger.info(
        'CurriculumTree/thunk/getDataCurriculum',
        OFFICIAL_CURRICULUM_HIERARCHY.name,
        data
      );
      const conditions: never[] = [];

      const responseStatus = await Promise.all([
        dispatch(
          getDataLevel1({
            conditions,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataLevel2({
            conditions,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataLevel3({
            conditions,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataLevel4({
            conditions,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataQuesLevel({
            conditions,
            include_item_ref: true,
            include_links: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataQues({
            conditions: [],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
      ]);

      const resultArray: Array<Types.CurriculumTable.ResponseType> = [];
      // let dataUserFilter: Array<any> = [];

      if (
        getDataLevel1.fulfilled.match(responseStatus[0]) &&
        getDataLevel2.fulfilled.match(responseStatus[1]) &&
        getDataLevel3.fulfilled.match(responseStatus[2]) &&
        getDataLevel4.fulfilled.match(responseStatus[3]) &&
        getDataQuesLevel.fulfilled.match(responseStatus[4]) &&
        getDataQues.fulfilled.match(responseStatus[5])
      ) {
        const dataLevel1 = responseStatus[0].payload.items;
        const dataLevel2 = responseStatus[1].payload.items;
        const dataLevel3 = responseStatus[2].payload.items;
        const dataLevel4 = responseStatus[3].payload.items;
        const dataAssignLevel = responseStatus[4].payload.items;
        const dataQues = responseStatus[5].payload.items;

        const dataNewItems = data.items;

        dataNewItems.flatMap((item) => {
          const matchedLevel1Items = filter(
            dataLevel1,
            (level1) => !!item.code && level1.curricullum_code === item.code
          );

          const baseObject = {
            company_id: item.company_id,
            rowKey: item.code,
            curriculum_id: item.i_id,
            i_id: item.i_id,
            curriculum_code: item.code,
            curriculum_name: item.name,
            curriculum_description: item.description,
            curriculum_fileID: item.fileID,
            curriculum_publish: Number(item.publish ?? 0),
            curriculum_probs_count: Number(item.probs_count ?? 0),
            curriculum_sort_order: Number(item.sort_order ?? 0),
            required_curriculum: Number(item.required_curriculum ?? 0),
            official_curriculum: Number(item.official_curriculum ?? 0),
          };

          if (matchedLevel1Items.length) {
            matchedLevel1Items.flatMap((level1Item) => {
              const baseObject1 = {
                ...baseObject,
                rowKey: `${item.code}_${level1Item.code}`,
                level1_i_id: level1Item.i_id,
                level1_name: level1Item.name ?? '',
                level1_code: level1Item.code,
                level1_sort_order: Number(level1Item.sort_order ?? 0),
              };
              const matchedLevel2Items = filter(dataLevel2, { level1_code: level1Item.code });

              if (matchedLevel2Items.length) {
                matchedLevel2Items.flatMap((level2Item) => {
                  const baseObject2 = {
                    ...baseObject1,
                    rowKey: `${item.code}_${level1Item.code}_${level2Item.code}`,
                    level2_i_id: level2Item.i_id,
                    level2_name: level2Item.name ?? '',
                    level2_code: level2Item.code,
                    level2_sort_order: Number(level2Item.sort_order ?? 0),
                  };
                  const matchedLevel3Items = filter(dataLevel3, { level2_code: level2Item.code });

                  if (matchedLevel3Items.length) {
                    matchedLevel3Items.flatMap((level3Item) => {
                      const baseObject3 = {
                        ...baseObject2,
                        rowKey: `${item.code}_${level1Item.code}_${level2Item.code}_${level3Item.code}`,
                        level3_i_id: level3Item.i_id,
                        level3_name: level3Item.name ?? '',
                        level3_code: level3Item.code,
                        level3_sort_order: Number(level3Item.sort_order ?? 0),
                      };
                      const matchedLevel4Items = filter(dataLevel4, {
                        level3_code: level3Item.code,
                      });

                      if (matchedLevel4Items.length) {
                        matchedLevel4Items.flatMap((level4Item) => {
                          const baseObject4 = {
                            ...baseObject3,
                            rowKey: `${item.code}_${level1Item.code}_${level2Item.code}_${level3Item.code}_${level4Item.code}`,
                            level4_i_id: level4Item.i_id,
                            level4_name: level4Item.name ?? '',
                            level4_code: level4Item.code,
                            level4_sort_order: Number(level4Item.sort_order ?? 0),
                          };

                          const matchedQuestionItems = filter(dataAssignLevel, {
                            level4_code: level4Item.code,
                          });

                          if (matchedQuestionItems.length) {
                            matchedQuestionItems.flatMap((quesAssign) => {
                              const questionFounded = find(dataQues, { code: quesAssign.code });
                              if (questionFounded) {
                                resultArray.push({
                                  ...baseObject4,
                                  rowKey: `${item.code}_${level1Item.code}_${level2Item.code}_${level3Item.code}_${level4Item.code}_${questionFounded.code}`,
                                  question_code_i_id: questionFounded.i_id,
                                  question_code: questionFounded.code,
                                  question_name: questionFounded.name,
                                  question_creator: questionFounded.createdby,
                                  question_sort_order: Number(quesAssign.sort_order),
                                  question_assign_level_i_id: quesAssign.i_id,
                                });
                              } else {
                                resultArray.push(baseObject4);
                              }
                            });
                          } else {
                            resultArray.push(baseObject4);
                          }
                        });
                      } else {
                        resultArray.push(baseObject3);
                      }
                    });
                  } else {
                    resultArray.push(baseObject2);
                  }
                });
              } else {
                resultArray.push(baseObject1);
              }
            });
          } else {
            resultArray.push(baseObject);
          }
        });
      }

      const flattenedResultArray = chain(uniqBy(resultArray, 'rowKey'))
        .flatten()
        .orderBy(
          [
            'curriculum_code',
            'level1_sort_order',
            'level1_code',
            'level2_sort_order',
            'level2_code',
            'level3_sort_order',
            'level3_code',
            'level4_sort_order',
            'level4_code',
            'question_sort_order',
            'question_code',
          ],
          ['asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc']
        )
        .value();

      const dataCurriculumTree: Array<Types.TreeItem<Types.CurriculumItemType>> = [];
      let strings: string[] = [];
      flattenedResultArray.forEach((item) => {
        const itemIndex = dataCurriculumTree.findIndex((c) => c.i_id === item.curriculum_id);
        if (itemIndex >= 0) {
          dataCurriculumTree[itemIndex] = memoizedConvertFlatDataFromTree({
            treeData: dataCurriculumTree[itemIndex],
            item,
            items: flattenedResultArray,
            strings,
          });
        } else {
          const func = (item: Types.CurriculumTable.ResponseType) => {
            strings = [...strings, item.curriculum_id];
            return [
              {
                i_id: item.question_code_i_id,
                name: item.question_name,
                code: item.question_code,
                curriculum_id: item.curriculum_id,
                probs_count: item.curriculum_probs_count,
                question_assign_level_i_id: item.question_assign_level_i_id,
                creator: item.question_creator,
                sort_order: Number(item.question_sort_order),
                publish: Number(item.curriculum_publish),
                required_curriculum: Number(item.required_curriculum),
                columnIndex: 5,
              },
            ];
          };
          const totalCurriculumQuestions = getTotalQuestionCurriculum(flattenedResultArray, item);

          dataCurriculumTree.push({
            i_id: item.curriculum_id,
            curriculum_id: item.curriculum_id,
            probs_count: item.curriculum_probs_count,
            code: item.curriculum_code,
            name: item.curriculum_name,
            description: item.curriculum_description,
            sort_order: Number(item.curriculum_sort_order),
            publish: Number(item.curriculum_publish),
            required_curriculum: Number(item.required_curriculum),
            columnIndex: 0,
            fileID: item.curriculum_fileID,
            company_id: item.company_id,
            totalQuestions: totalCurriculumQuestions,
            totalLevelQuestions: totalCurriculumQuestions,
            children: item.level1_i_id
              ? [
                  {
                    i_id: item.level1_i_id,
                    name: item.level1_name,
                    code: item.level1_code,
                    curriculum_id: item.curriculum_id,
                    probs_count: item.curriculum_probs_count,
                    sort_order: Number(item.level1_sort_order),
                    publish: Number(item.curriculum_publish),
                    required_curriculum: Number(item.required_curriculum),
                    columnIndex: 1,
                    totalQuestions: totalCurriculumQuestions,
                    totalLevelQuestions: getTotalQuestionAtLevel(flattenedResultArray, item, 1),
                    children: item.level2_i_id
                      ? [
                          {
                            i_id: item.level2_i_id,
                            name: item.level2_name,
                            code: item.level2_code,
                            probs_count: item.curriculum_probs_count,
                            curriculum_id: item.curriculum_id,
                            sort_order: Number(item.level2_sort_order),
                            publish: Number(item.curriculum_publish),
                            required_curriculum: Number(item.required_curriculum),
                            columnIndex: 2,
                            totalQuestions: totalCurriculumQuestions,
                            totalLevelQuestions: getTotalQuestionAtLevel(
                              flattenedResultArray,
                              item,
                              2
                            ),
                            children: item.level3_i_id
                              ? [
                                  {
                                    i_id: item.level3_i_id,
                                    name: item.level3_name,
                                    code: item.level3_code,
                                    probs_count: item.curriculum_probs_count,
                                    curriculum_id: item.curriculum_id,
                                    sort_order: Number(item.level3_sort_order),
                                    publish: Number(item.curriculum_publish),
                                    required_curriculum: Number(item.required_curriculum),
                                    columnIndex: 3,
                                    totalQuestions: totalCurriculumQuestions,
                                    totalLevelQuestions: getTotalQuestionAtLevel(
                                      flattenedResultArray,
                                      item,
                                      3
                                    ),
                                    children: item.level4_i_id
                                      ? [
                                          {
                                            i_id: item.level4_i_id,
                                            name: item.level4_name,
                                            code: item.level4_code,
                                            probs_count: item.curriculum_probs_count,
                                            curriculum_id: item.curriculum_id,
                                            sort_order: Number(item.level4_sort_order),
                                            publish: Number(item.curriculum_publish),
                                            required_curriculum: Number(item.required_curriculum),
                                            columnIndex: 4,
                                            totalQuestions: totalCurriculumQuestions,
                                            totalLevelQuestions: getTotalQuestionAtLevel(
                                              flattenedResultArray,
                                              item,
                                              4
                                            ),
                                            children: item.question_code_i_id ? func(item) : [],
                                          },
                                        ]
                                      : [],
                                  },
                                ]
                              : [],
                          },
                        ]
                      : [],
                  },
                ]
              : [],
          });
        }
      });

      const array = dataCurriculumTree.filter((item) => {
        if (!item?.children?.length) {
          return true;
        } else {
          const ver1 = item.children;
          const result = ver1.every((itemVer1) => {
            if (!itemVer1?.children?.length) {
              return true;
            } else {
              const chilrenVer2 = itemVer1.children;
              if (!chilrenVer2.length) {
                return true;
              } else {
                return chilrenVer2.some((itemVer2) => {
                  const children2 = itemVer2.children;
                  if (!children2?.length) {
                    return true;
                  } else {
                    return children2.some((itemVer3, index) => {
                      const children3 = itemVer3.children;
                      if (!children3?.length) {
                        return true;
                      } else {
                        return children3.some((itemVer4, index) => {
                          const children4 = itemVer4.children;
                          if (!children4?.length) {
                            return true;
                          } else {
                            return false;
                          }
                        });
                      }
                    });
                  }
                });
              }
            }
          });
          return result;
        }
      });

      return {
        ...data,
        questionKeys: uniq(strings),
        dataKeyDontHaveQuestion: uniq(array.map((item) => item.code ?? '')),
        items: [...flattenedResultArray],
        dataCurriculumTree: dataCurriculumTree,
        dataLevel1s: getDataLevel1.fulfilled.match(responseStatus[0])
          ? responseStatus[0].payload.items
          : [],
        dataLevel2s: getDataLevel2.fulfilled.match(responseStatus[1])
          ? responseStatus[1].payload.items
          : [],
        dataLevel3s: getDataLevel3.fulfilled.match(responseStatus[2])
          ? responseStatus[2].payload.items
          : [],
        dataLevel4s: getDataLevel4.fulfilled.match(responseStatus[3])
          ? responseStatus[3].payload.items
          : [],
        dataQuestions: getDataQues.fulfilled.match(responseStatus[5])
          ? map(responseStatus[5].payload.items, (item) => ({
              ...item,
              // creator:
              //   dataUserFilter.find((item1) => item1.login_id === item.createdby)?.name ??
              //   item.creator,
            }))
          : [],
        dataCurriculums: data.items,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
