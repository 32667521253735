import styled from 'styled-components';
import { DocRenderer } from '../../models';
import { useMemo, useState } from 'react';
import { ZoomInPDFIcon, ZoomOutPDFIcon } from '../pdf/components/icons';
import { Button } from 'components/PartnerViewer/components/common';
import { useSelector } from 'react-redux';

const MSDocRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  const [zoom, setZoom] = useState(100);

  const isPowerPointFile = useMemo(() => {
    if (!currentDocument || !currentDocument.fileType) return;

    const validateFileType = [
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-powerpoint',
    ];

    return validateFileType.includes(currentDocument.fileType);
  }, [currentDocument]);

  if (!currentDocument) return null;

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 10, 250));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 10, 50));
  };

  return (
    <Container id="msdoc-renderer">
      <ZoomControls>
        <div className="name">{currentDocument.fileName}</div>
        {!isPowerPointFile ? (
          <div className="button">
            <span>{`${zoom}%`}</span>
            <ControlButton onClick={handleZoomOut}>
              <ZoomOutPDFIcon color="#000" size="80%" />
            </ControlButton>
            <ControlButton onClick={handleZoomIn}>
              <ZoomInPDFIcon color="#000" size="80%" />
            </ControlButton>
          </div>
        ) : (
          <div></div>
        )}
      </ZoomControls>

      <div style={{ flex: 1 }}>
        <IFrame
          id="msdoc-iframe"
          title="msdoc-iframe"
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${
            (currentDocument.fileData as string) || encodeURIComponent(currentDocument.uri)
          }`}
          frameBorder="0"
          style={{ zoom: `${zoom}%`, transition: 'zoom 0.3s ease-in-out' }}
        />
      </div>
    </Container>
  );
};

export default MSDocRenderer;

const MSDocFTMaps = {
  doc: ['doc', 'application/msword'],
  docx: [
    'docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/octet-stream',
  ],
  xls: ['xls', 'application/vnd.ms-excel'],
  xlsx: ['xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  ppt: ['ppt', 'application/vnd.ms-powerpoint'],
  pptx: ['pptx', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
};

MSDocRenderer.fileTypes = [
  ...MSDocFTMaps.doc,
  ...MSDocFTMaps.docx,
  ...MSDocFTMaps.xls,
  ...MSDocFTMaps.xlsx,
  ...MSDocFTMaps.ppt,
  ...MSDocFTMaps.pptx,
];
MSDocRenderer.weight = 0;
MSDocRenderer.fileLoader = ({ fileLoaderComplete }) => fileLoaderComplete();

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

const ZoomControls = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  align-items: center;
  gap: 10px;
  background: #f2f1f1;
  .name {
    color: #777777;
    font-size: 12px;
    margin-left: 12px;
    height: 30px;
    display: inline-flex;
    align-items: center;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .version {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
    .text-version {
      min-width: 230px;
      color: #777777;
      font-size: 10px;
    }
  }
`;
const ControlButton = styled(Button)`
  width: 30px;
  height: 30px;
  border: none;
  background-color: #f1f1f1;
  box-shadow: none;
  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
  }
`;
