import dayjs from 'dayjs';

export const calculationMinutesSeconds = (time1: string, time2: string): string => {
  const [minutes2, secondes2] = time2.split(':');
  const [minutes1, secondes1] = time1.split(':');
  const time =
    Number(minutes2) * 60 + Number(secondes2) - (Number(minutes1) * 60 + Number(secondes1));
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;

  return `${minutes.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${seconds.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;
};

interface Props {
  value: string;
  unit: string;
}

export const getFileSize = (size: string): Props => {
  const fileSize = Number(size) / 1024 || 0;
  if (fileSize < 1024) {
    return {
      value: fileSize.toFixed(2),
      unit: 'KB',
    };
  }
  if (fileSize >= 1024 && fileSize < 1048576) {
    return {
      value: (fileSize / 1024).toFixed(2),
      unit: 'MB',
    };
  }
  if (fileSize >= 1048576) {
    return {
      value: (fileSize / 1048576).toFixed(2),
      unit: 'GB',
    };
  }
  return {
    value: '0',
    unit: 'KB',
  };
};

export const getMonthListFromDateRanger = (start: string, end: string) => {
  if (!start || !end) {
    return [];
  }

  const result = [];
  const startDate = dayjs(start, 'YYYYMM');
  const endDate = dayjs(end, 'YYYYMM');
  let current = startDate;
  while (current.isBefore(endDate) || current.isSame(endDate)) {
    result.push(current.format('YYYYMM'));
    current = current.add(1, 'months');
  }
  return result;
};
export const isOverflown = ({ clientHeight, scrollHeight }: HTMLDivElement) =>
  scrollHeight > clientHeight;

export const resizeText = (element: HTMLElement, parent: HTMLDivElement) => {
  let i = 7;
  let overflow = false;
  const maxSize = 11;

  while (!overflow && i < maxSize) {
    element.style.fontSize = `${i}px`;
    overflow = isOverflown(parent);
    if (!overflow) i++;
  }
  element.style.fontSize = `${i - 1}px`;
};
