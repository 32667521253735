import { useEffect } from 'react';

const handleMutations = (mutations: MutationRecord[]) => {
  mutations.forEach((mutation) => {
    if (mutation.addedNodes.length) {
      mutation.addedNodes.forEach((node) => {
        if (node.nodeType === 1) {
          const element = node as HTMLElement;
          const iframeDialogTitle = element.querySelector(
            '.jodit-dialog__header-title'
          ) as HTMLElement;
          if (iframeDialogTitle) {
            if (iframeDialogTitle.textContent === 'Iframe Properties') {
              iframeDialogTitle.textContent = 'Iframe エディタ';
            }
            if (
              ['Iframe Properties', 'Iframe エディタ'].includes(iframeDialogTitle.textContent || '')
            ) {
              // Hide Name and Title input fields specifically for Iframe Properties dialog
              const nameField = Array.from(element.querySelectorAll('.jodit-ui-input')).find(
                (el) =>
                  (el.querySelector('span.jodit-ui-input__label') as HTMLElement)?.textContent ===
                  'Name'
              ) as HTMLElement;
              const titleField = Array.from(element.querySelectorAll('.jodit-ui-input')).find(
                (el) =>
                  ['タイトル', 'Title'].includes(
                    (el.querySelector('span.jodit-ui-input__label') as HTMLElement)?.textContent ??
                      ''
                  )
              ) as HTMLElement;
              const panel = element.querySelector('.jodit-dialog__panel') as HTMLElement;
              const checkboxLabel = element.querySelector(
                '.jodit-ui-checkbox__label'
              ) as HTMLElement;
              if (panel) panel.style.height = '270px';
              if (nameField) nameField.style.display = 'none';
              if (titleField) titleField.style.display = 'none';
              if (checkboxLabel && checkboxLabel.textContent === 'Show frame border') {
                checkboxLabel.textContent = '枠線を表示する';
              }
            }
          }

          const imageDrop = element.querySelector('.jodit-drag-and-drop__file-box');
          if (imageDrop) {
            const textUploadImage = imageDrop.querySelector('strong');
            if (textUploadImage && textUploadImage.textContent === '画像をドロップ') {
              const spanElement = imageDrop.querySelector('span');
              if (spanElement && spanElement?.innerHTML === '<br>or クリック') {
                spanElement.innerHTML = '<br>or<br>クリック';
              }
              const newSpanElement = document.createElement('span');
              newSpanElement.textContent = textUploadImage.textContent;
              textUploadImage.replaceWith(newSpanElement);
            }
          }

          const tabButtons = element.querySelector('.jodit-tabs__buttons');
          if (tabButtons) {
            const uploadImageText = Array.from(
              tabButtons.querySelectorAll('.jodit-ui-button__text')
            ).find((r) => r.textContent === 'アップロード');

            if (uploadImageText) {
              tabButtons.querySelectorAll('button').forEach((b) => (b.style.minWidth = '120px'));
            }
          }
        }
      });
    }
  });
};

const observeMutations = (callback: (mutations: MutationRecord[]) => void): MutationObserver => {
  const observer = new MutationObserver(callback);
  observer.observe(document.body, { childList: true, subtree: true });
  return observer;
};

const useJoditTranslateMutationObserver = () => {
  useEffect(() => {
    const observer = observeMutations(handleMutations);

    return () => {
      observer.disconnect();
    };
  }, []);
};

const JODIT_CUSTOM_LOCALIZATION = {
  ja: {
    'Iframe Editor': 'Iframe エディタ',
    'Drop image': '画像をドロップ',
    'or click': 'or クリック',
  },
};

export { useJoditTranslateMutationObserver, JODIT_CUSTOM_LOCALIZATION };
