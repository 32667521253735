import styled from 'styled-components';

type Props = {
  collapsedMenu: boolean;
  isEmptyData: boolean;
};

export default styled.section<Props>`
  .billing-wrapper {
    position: relative;
    background-color: #f9f8f8;
    height: 100%;
    width: 100%;
    padding: 30px;
    min-height: calc(100vh - 151px);
  }

  .wrap-select-record {
    display: flex;
    .label-select {
      font-size: 11px;
      color: #424242;
    }
  }
  .select-option {
    width: 70px;
    height: 28px;
    font-size: 11px !important;
    .ant-select-arrow {
      top: 55%;
    }
    .ant-select-selector {
      border-radius: 3px;
    }
  }
  @media (max-width: 1440px) {
    .border-line {
      margin: 30px 0 20px 0 !important;
    }
    .form-search {
      justify-content: flex-start;
      .item {
        width: 190px !important;
        margin-right: 8px;
      }
      .btn-search {
        margin-left: 18px;
        width: 100px;
      }
      .label-reset {
        margin-left: 8px;
      }
    }
    .table {
      .ant-table .ant-table-container::before {
        margin-top: 5px !important;
      }
      .ant-table-thead {
        .ant-table-cell {
          padding: 6px 8px;
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          padding: 6px 8px;
          height: 44px;
        }
      }
    }
    .wrap-bottom {
      .btn {
        width: 180px !important;
        height: 40px;
      }
    }
  }
  .ant-table {
    border: 1px solid #e5e5e5;
    overflow: hidden;
    border-radius: 3px;
    .ant-table-row:last-child {
      td {
        border: none;
      }
    }
    .ant-table-placeholder {
      .ant-table-cell {
        border: none;
      }
    }
  }
  .not-requested {
    background: #f0330a;
    border-radius: 3px;
    width: 56px;
    height: 22px;
    font-weight: 700;
    font-size: 12px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-active {
    font-size: 13px;
    color: #f0330a;
  }
  .resend-style {
    text-align: end !important;
  }
  .billing {
    color: #777777;
  }
  .number-active {
    font-size: 12px;
    text-decoration-line: underline;
    color: #08a3a5;
    cursor: pointer;
  }
  .text-note {
    font-size: 12px;
    color: #424242;
  }
  .border-line {
    border-top: 1px solid #dddddd;
    width: 40px;
    margin: 30px 0;
  }
  .form-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item {
      width: 17%;
      .text-label {
        font-size: 12px;
      }
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
      .icon-search {
        font-size: 18px;
        margin-right: 12px;
      }
    }
  }
  .table {
    margin-top: ${(props) => (props.isEmptyData ? 64 : 0)}px;
    .text-count {
      font-family: 'Lato';
      font-size: 14px;
      color: #424242;
    }
    .ant-table .ant-table-container::before {
      content: 'ALL';
      margin-left: 7px;
      margin-top: 9px;
      font-size: 11px;
    }
    .ant-table-thead .ant-table-cell.name-left {
      text-align: left;
    }
    .ant-table-thead .ant-table-cell.name-right {
      text-align: right;
    }
    .ant-table-thead .ant-checkbox-wrapper {
      padding-top: 10px;
    }
    .ant-table-thead {
      border-radius: 2px 2px 0px 0px;
      .ant-table-cell {
        background-color: #ebebeb;
        text-align: center;
        font-weight: 700;
        font-size: 11px;
        color: #2a2a2a;
        margin: 0 3px;
        &:before {
          display: none;
        }
      }
    }
    .ant-table-tbody .ant-table-cell.name-left {
      text-align: left;
    }
    .ant-table-tbody .ant-table-cell.name-center {
      text-align: center;
    }
    .ant-table-tbody .ant-table-cell.name-right {
      text-align: right;
    }
    .ant-table-tbody {
      .ant-table-cell {
        font-family: 'Lato';
        font-size: 11px;
        text-align: center;
        color: #424242;
        word-wrap: break-word;
      }
      .icon {
        color: #c4c4c4;
        font-size: 18.32px;
        cursor: pointer;
      }
      & > tr.ant-table-row-selected > td {
        background-color: #fdf7ea;
      }
    }
  }
  .wrap-button {
    position: absolute;
    right: 0;
    margin: 20px 30px;
    z-index: 9;
    cursor: pointer;
    .count-error {
      font-size: 12px;
      color: #424242;
      margin: 0;
    }
  }
  .wrap-bottom {
    height: 95px;
    width: 100%;
    transition: width 0.3s;
    background-color: #ffffff;
    position: sticky;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
    .flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .text-label {
      font-size: 13px;
      color: #777777;
      display: flex;
      align-items: center;
    }
    .btn {
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
    }
    .btn-outline {
      background-color: #ffffff;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #f1a900;
      .icon {
        color: #f6ac00;
        transform: rotate(-90deg);
      }
    }
  }
`;
