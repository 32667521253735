import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F9F8F8',
    fontFamily: 'NotosanJP',
  },

  header: {
    padding: 11.7,
    paddingBottom: 10,
    borderBottom: '1px solid #dddddd',
  },

  textHeader: {
    fontSize: 10.525,
    color: '#424242',
  },

  disabled: {
    color: '#cccccc',
  },

  subscription: {
    paddingVertical: 8,
    paddingHorizontal: 11.11,
  },

  textSubscription: {
    fontSize: 7,
    color: '#424242',
  },

  lineBorder: {
    width: 23.4,
    height: 1,
    backgroundColor: '#dddddd',
    marginLeft: 11.7,
  },

  body: {
    paddingVertical: 10,
    paddingHorizontal: 75,
    flexDirection: 'column',
    gap: 11.7,
  },

  title: {
    fontSize: 7.6,
    color: '#777',
    marginBottom: 7.6,
  },

  title_2: {
    fontSize: 7.6,
    color: '#222222',
  },

  title_sub: {
    fontSize: 7.6,
    color: '#F0330A',
    marginBottom: 5,
  },

  name: {
    color: '#2A2A2A',
    fontSize: 7,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  wrapItem_2: {
    flexDirection: 'column',
    gap: 4,
    backgroundColor: '#fff',
    padding: 7,
    border: '1px solid #E5E5E5',
    borderRadius: '3px',
  },

  wrapItem: {
    flexDirection: 'column',
    gap: 2,
    backgroundColor: '#fff',
    padding: 7,
  },

  item: {
    backgroundColor: '#ebebeb',
    padding: 2.34,
    flexDirection: 'row',
  },

  wrapLabel: {
    width: 200,
    padding: 7,
    flexDirection: 'row',
    alignItems: 'center',
  },

  label: {
    fontSize: 8.2,
    color: '#2a2a2a',
    width: 126,
  },

  wrapRadio: {
    width: '100%',
    flexDirection: 'row',
    gap: 1,
  },

  radioItem: {
    width: 305,
    backgroundColor: '#fff',
    paddingHorizontal: 11.7,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  textItem: {
    fontSize: 9.356,
    color: '#424242',
  },

  radioButton: {
    width: 8.2,
    height: 8.2,
    borderRadius: 10,
    borderWidth: 1,
  },

  check: {
    fontWeight: 'bold',
  },

  radioSelected: {
    width: 4,
    height: 4,
    borderRadius: 10,
    backgroundColor: '#1890ff',
    position: 'absolute',
    top: 1,
    left: 1,
    opacity: 1,
  },
});
