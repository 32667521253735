import { ReactNode } from 'react';
import SectionStyled from './styles';

const Label = ({ children, required }: { children: ReactNode; required?: boolean }) => {
  return (
    <SectionStyled>
      {children}
      {required && <span className="required">*</span>}
    </SectionStyled>
  );
};

export default Label;
