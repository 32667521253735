import { Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import React from 'react';

const QAIcon = ({ style }: { style?: Style | Style[] }) => (
  <Svg style={style} width="16" height="16" viewBox="0 0 16 16">
    <Path
      d="M8 0C3.58214 0 0 3.58214 0 8C0 12.4179 3.58214 16 8 16C12.4179 16 16 12.4179 16 8C16 3.58214 12.4179 0 8 0ZM8 14.6429C4.33214 14.6429 1.35714 11.6679 1.35714 8C1.35714 4.33214 4.33214 1.35714 8 1.35714C11.6679 1.35714 14.6429 4.33214 14.6429 8C14.6429 11.6679 11.6679 14.6429 8 14.6429Z"
      fill="#08A3A5"
    />
    <Path
      d="M9.99449 4.51194C9.45878 4.0423 8.75164 3.78516 8.00165 3.78516C7.25166 3.78516 6.54452 4.04408 6.00881 4.51194C5.45167 4.99944 5.14453 5.6548 5.14453 6.35658V6.4923C5.14453 6.57087 5.20882 6.63516 5.28739 6.63516H6.14452C6.22309 6.63516 6.28738 6.57087 6.28738 6.4923V6.35658C6.28738 5.56908 7.05701 4.92801 8.00165 4.92801C8.94628 4.92801 9.71592 5.56908 9.71592 6.35658C9.71592 6.91194 9.32307 7.42087 8.71414 7.6548C8.33557 7.79944 8.01415 8.05301 7.78379 8.38516C7.54987 8.72444 7.42844 9.13158 7.42844 9.54408V9.92801C7.42844 10.0066 7.49272 10.0709 7.5713 10.0709H8.42843C8.507 10.0709 8.57129 10.0066 8.57129 9.92801V9.52266C8.57221 9.3493 8.62535 9.18025 8.72378 9.03754C8.82221 8.89484 8.96135 8.7851 9.12307 8.72266C10.1766 8.3173 10.857 7.38873 10.857 6.35658C10.8588 5.6548 10.5516 4.99944 9.99449 4.51194ZM7.28737 11.928C7.28737 12.1175 7.36262 12.2991 7.49658 12.4331C7.63053 12.567 7.81221 12.6423 8.00165 12.6423C8.19109 12.6423 8.37277 12.567 8.50672 12.4331C8.64067 12.2991 8.71593 12.1175 8.71593 11.928C8.71593 11.7386 8.64067 11.5569 8.50672 11.4229C8.37277 11.289 8.19109 11.2137 8.00165 11.2137C7.81221 11.2137 7.63053 11.289 7.49658 11.4229C7.36262 11.5569 7.28737 11.7386 7.28737 11.928Z"
      fill="#08A3A5"
    />
  </Svg>
);

export default QAIcon;
