import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';

import styles from './styles';
import _ from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import { DownIconPdf } from 'assets/svg/svgPDF';
import dayjs from 'dayjs';
import * as Types from 'types';
import SelectBox from 'components/ReactPDF/SelectBox';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface Props {
  dataSource: Types.InvoiceCorrespondenceType[];
  account_registration_status?: string;
  company_name?: string;
  manager_name?: string;
  email?: string;
}

const FIRST_PAGE_ROW_COUNT = 12;
const PAGE_ROW_COUNT = 18;

const InvoiceCorrespondenceTable = ({
  dataSource,
}: {
  dataSource: Types.InvoiceCorrespondenceType[];
}) => {
  const columns: { title: string | string[]; width: string | number; key: string }[] = [
    {
      title: '法人名',
      width: 130,
      key: 'company_name',
    },
    {
      title: 'ご担当者氏名',
      width: 70,
      key: 'manager_name',
    },
    {
      title: 'メールアドレス',
      width: 130,
      key: 'email',
    },
    {
      title: ['アカウント', '登録状況'],
      width: 60,
      key: 'account_registration_status',
    },
    {
      title: 'URL発行日',
      width: 90,
      key: 'url_issue_date',
    },
    {
      title: 'memo',
      width: 350,
      key: 'memo',
    },
  ];

  const isStringArray = (val: any) => {
    if (Array.isArray(val)) {
      return true;
    } else {
      return false;
    }
  };
  const formatDateData = (val: string) => {
    if (val) {
      return dayjs(val).format('YYYY/MM/DD HH:mm');
    }
    return '-';
  };

  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow, { height: '26.728px' }]}>
        {columns.map((column, index) =>
          isStringArray(column.title) ? (
            <View
              key={index}
              style={[
                styles.wrapCell,
                { width: column.width, display: 'flex', flexDirection: 'column' },
              ]}
            >
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title[0]}</Text>
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title[1]}</Text>
            </View>
          ) : (
            <View key={index} style={[styles.wrapCell, { width: column.width }]}>
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
            </View>
          )
        )}
      </View>

      {dataSource.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5',
            },
          ]}
        >
          <View style={[styles.wrapCell, { width: columns[0].width }]}>
            <Text style={[styles.tableCell, { marginLeft: '6px' }]}>{row.company_name}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[1].width }]}>
            <Text style={styles.tableCell}>{row.manager_name}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[2].width }]}>
            <Text style={[styles.tableCell, { color: '#00989A', textDecoration: 'underline' }]}>
              {row.email}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[3].width }]}>
            <Text
              style={[
                styles.tableCell,
                { color: !row.account_registration_status ? '#F0330A' : '#424242' },
              ]}
            >
              {!row.account_registration_status ? '未登録' : '登録済'}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[4].width }]}>
            <Text style={styles.tableCell}>
              {dayjs(row.url_issue_date).format('YYYY/MM/DD HH:mm')}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[5].width }]}>
            <Text style={styles.tableCell}>{row.memo}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const InvoiceCorrespondenceDocument = (props: Props) => {
  const { dataSource, account_registration_status, company_name, manager_name, email } = props;
  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap>
        <FullPage>
          <View style={styles.header}>
            <Text style={styles.textHeader}>請求書対応管理</Text>
          </View>
          <View style={styles.subscription}>
            <View>
              <Text style={styles.textSubscription}>
                法人ユーザーで請求方法を請求書送付ご希望の方への専用アカウント登録用URL発行と
              </Text>
              <Text style={styles.textSubscription}>発行後の登録状況の管理画面</Text>
            </View>
          </View>
          <View style={styles.divider}></View>
          <View style={styles.searchPanel}>
            <SelectBox
              label={'アカウント登録状況'}
              selectBoxStyle={{ marginRight: 8 }}
              value={
                account_registration_status
                  ? account_registration_status === '1'
                    ? '登録済'
                    : '未登録'
                  : '指定なし'
              }
            />
            <SelectBox
              label={'法人名'}
              selectBoxStyle={{ marginRight: 8 }}
              value={company_name ? company_name : '指定なし'}
            />
            <SelectBox
              label={'ご担当者氏名'}
              selectBoxStyle={{ marginRight: 8 }}
              value={manager_name ? manager_name : '指定なし'}
            />
            <SelectBox label={'メールアドレス'} value={email ? email : '指定なし'} />
          </View>
          <View style={styles.body}>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>1</Text>
              <Text style={styles.textCountNumber}>-</Text>
              <Text style={styles.textCountNumber}>{dataSource.length}</Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{dataSource.length}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
            <InvoiceCorrespondenceTable dataSource={dataSource.slice(0, FIRST_PAGE_ROW_COUNT)} />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {dataSource.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(dataSource.slice(FIRST_PAGE_ROW_COUNT, dataSource.length), PAGE_ROW_COUNT).map(
          (page, index) => (
            <Page
              key={index}
              size={pageSize}
              orientation={pageOrientation}
              style={[styles.page, styles.pagePadding]}
              wrap={false}
            >
              <FullPage>
                <InvoiceCorrespondenceTable dataSource={page} />
              </FullPage>
              <PageNumber />
            </Page>
          )
        )}
    </Document>
  );
};

export default InvoiceCorrespondenceDocument;
