import { Button, ButtonProps } from 'antd';
import React from 'react';
import { CloudUploadOutlined } from '@ant-design/icons';
import ImportButtonStyles from './styles';
import { useCheckImportPermission } from 'hooks';

export const ImportButton: React.FC<ButtonProps> = (props) => {
  const { children, disabled, ...rest } = props;
  const { disableImport } = useCheckImportPermission();
  const isDisabled = disabled || disableImport;
  return (
    <ImportButtonStyles>
      <Button
        className={`btn ${!isDisabled ? 'btn-active' : 'disabled'} customizeIcon`}
        disabled={isDisabled}
        icon={<CloudUploadOutlined className="size-icon mr-6" />}
        {...rest}
      >
        {children || 'インポート'}
      </Button>
    </ImportButtonStyles>
  );
};

export default ImportButton;
