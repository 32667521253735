import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';

import Styled from './styles';
import Modal from '../index';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  description?: string | React.ReactNode;
  onSubmit?: () => void | Promise<void>;
}

const DeleteCompleted: React.FC<Props> = ({ visible, setVisible, onSubmit, description }) => {
  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleSubmit = async () => {
    onSubmit && (await onSubmit());
    setVisible(false);
  };

  return (
    <Modal
      width={416}
      open={visible}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
      footerStyle={{
        background: '#ffffff',
      }}
      onCancel={handleToggleModal}
    >
      <Styled>
        <DeleteOutlined className="icon-delete" />
        <span className="text">{description ?? '削除が完了しました'}</span>
      </Styled>
    </Modal>
  );
};

export default DeleteCompleted;
