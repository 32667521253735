import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F9F8F8',
    fontFamily: 'NotosanJP',
    position: 'relative',
    paddingBottom: 24,
  },

  header: {
    padding: 11.7,
    paddingBottom: 12,
    borderBottom: '1px solid #dddddd',
  },

  textHeader: {
    fontSize: 10.5,
    color: '#424242',
  },

  body: {
    paddingHorizontal: 11.7,
    paddingVertical: 5.6,
    flexDirection: 'column',
    gap: 10,
  },

  pagePadding: {
    paddingHorizontal: 11.7,
    paddingTop: 11.7,
    paddingBottom: 24,
  },

  searchPanel: {
    display: 'flex',
    flexDirection: 'row',
    marginHorizontal: 11.7,
    marginBottom: 11.7,
    backgroundColor: '#FFFFFF',
    padding: 8,
    gap: 8,
  },

  conditionText: {
    paddingTop: 10,
    paddingBottom: 2,
    marginLeft: 11.7,
    color: '#777777',
    fontSize: 6,
  },

  textSubscription: {
    fontSize: 7,
    color: '#424242',
  },

  lineBorder: {
    width: 24,
    height: 1,
    backgroundColor: '#dddddd',
    marginLeft: 11.7,
  },

  textCountGeneral: {
    flexDirection: 'row',
    gap: 2,
    alignItems: 'center',
  },

  textCountNumber: {
    fontSize: 8.186,
    color: '#424242',
  },

  textCountDescription: {
    fontSize: 6.432,
    color: '#424242',
  },

  table: {
    flexDirection: 'column',
    textAlign: 'left',
    width: '100%',
    backgroundColor: '#fff',
  },

  tableHeaderRow: {
    backgroundColor: '#ebebeb',
  },

  tableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 25.728,
    borderLeft: '1px solid #e5e5e5',
    borderRight: '1px solid #e5e5e5',
  },

  wrapCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxLines: 1,
  },

  tableCell: {
    textAlign: 'left',
    fontSize: 7.6,
    color: '#424242',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderLeft: '1px solid #e5e5e5',
    height: 25.728,
    padding: 6,
  },

  name: {
    textAlign: 'left',
    fontSize: 7.6,
    color: '#424242',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  code: {
    textAlign: 'right',
    fontSize: 6,
    color: '#b1b1b1',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  textRight: {
    textAlign: 'right',
  },

  textCenter: {
    textAlign: 'center',
  },

  headerCell: {
    color: '#2a2a2a',
    fontSize: 7.017,
    fontWeight: 'bold',
    fontFamily: 'NotosanJP',
    borderLeft: '1px solid #e5e5e5',
  },

  reportTypeGroups: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 11.7,
  },

  reportTypeGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  reportTypeLabel: {
    color: '#424242',
    fontSize: 7,
  },

  reportTable: {
    border: 1,
    borderColor: '#08A3A5',
    padding: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    backgroundColor: '#FFFFFF',
  },
  reportTableText: {
    color: '#08A3A5',
    fontSize: 7,
  },

  reportChart: {
    border: 1,
    borderColor: '#CCCCCC',
    padding: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    marginLeft: -1,
  },
  reportChartText: {
    color: '#CCCCCC',
    fontSize: 7,
  },

  checkboxGroups: {
    marginLeft: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
  },
  checkboxGroup: {
    marginLeft: 8,
    padding: 2,
    backgroundColor: '#FFFFFF',
    border: 1,
    borderColor: '#CCCCCC',
  },

  cellTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  cellTitleValue: {
    flex: 1,
  },

  itemName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  level: {
    height: 25.7,
  },

  rowTotalAll: {
    backgroundColor: '#F4FBFB',
    borderBottom: '1px solid #e5e5e5',
  },

  rowTotal: {
    backgroundColor: '#F9F9F9',
    borderBottom: '1px solid #e5e5e5',
    borderTop: '1px solid #e5e5e5',
  },

  companyLevel: {
    width: 20,
    borderRight: '1px solid #e5e5e5',
  },

  borderBottomDash: {
    borderBottom: '1px dashed #e5e5e5',
  },

  borderBottomSolid: {
    borderBottom: '1px solid #e5e5e5',
  },

  rowTotalExpand: {
    backgroundColor: '#F9F9F9',
    borderBottom: '1px dashed #e5e5e5',
  },

  secondChildLevel: {
    paddingLeft: 60,
    borderRight: 0.5,
    borderStyle: 'solid',
    borderColor: '#e5e5e5',
  },
  companyChildLevel: {
    paddingLeft: 40,
    marginLeft: 19,
    borderRight: 0.5,
    borderLeft: 1,
    borderStyle: 'solid',
    borderColor: '#e5e5e5',
  },

  noBorderLeft: {
    borderLeft: 0,
  },

  backgroundGray: {
    backgroundColor: '#F9F9F9',
  },
});
