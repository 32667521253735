import { Text, View } from '@react-pdf/renderer';
import styles from './styles';
import React from 'react';

interface IProps {
  title?: string;
  no?: string;
}

const Section = ({ title, no }: IProps) => (
  <View style={styles.section} wrap={false}>
    <View style={styles.noSection}>
      <Text style={styles.no}>{no}</Text>
    </View>
    <Text style={styles.textHeader}>{title}</Text>
  </View>
);

export default Section;
