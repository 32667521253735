import { createAsyncThunk } from '@reduxjs/toolkit';

import { report, servicesMock } from 'services';
import * as Types from 'types';
import {
  config,
  NUMBER_REGISTERED_COMPANIES,
  NUMBER_TREND_COMPANIES,
  OFFICIAL_CURRICULUM_HIERARCHY,
  OFFICIAL_CURRICULUM_MASTER,
  OFFICIAL_CURRICULUM_USER_AGGREGATION,
} from 'configs';
import { browserLogger } from 'libs/logger';

export const getDataInformationUser = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getDataInformationUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await servicesMock.filter('reportUserInformation', req);
    browserLogger.info('report/thunk/getDataInformationUser', 'reportUserInformation', data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataOfficialCurriculumUserAggregation = createAsyncThunk<
  Types.ReportItemsReponse<Types.OfficialCurriculumnAggregationData>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getDataOfficialCurriculumUserAggregation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await report.search(OFFICIAL_CURRICULUM_USER_AGGREGATION.name, req);
    browserLogger.info(
      'report/thunk/getDataOfficialCurriculumUserAggregation',
      OFFICIAL_CURRICULUM_USER_AGGREGATION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheckInformation = createAsyncThunk<
  Types.ReportItemsReponse<Types.SkillCheckInformationData>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getDataSkillCheckInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await report.search('skill_check_sales_tally', req);
    browserLogger.info(
      'report/thunk/getDataSkillCheckInformation',
      'skill_check_sales_tally',
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataOfficialCurriculumUserAggregationPartner = createAsyncThunk<
  Types.ReportItemsReponse<Types.dataOfficialCurriculumUserAggregationPartner>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'report/thunk/getDataOfficialCurriculumUserAggregationPartner',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await report.searchBy(
        config.APP_ID_PARTNER,
        OFFICIAL_CURRICULUM_HIERARCHY.name,
        req
      );
      browserLogger.info(
        'dreport/thunk/getDataOfficialCurriculumUserAggregationPartner',
        OFFICIAL_CURRICULUM_HIERARCHY.name,
        data
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataOfficialCurriculumUserAggregationAdmin = createAsyncThunk<
  Types.ReportItemsReponse<Types.dataOfficialCurriculumUserAggregationAdmin>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'report/thunk/getDataOfficialCurriculumUserAggregationAdmin',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await report.searchBy(config.APP_ID, OFFICIAL_CURRICULUM_MASTER.name, req);
      browserLogger.info(
        'report/thunk/getDataOfficialCurriculumUserAggregationAdmin',
        OFFICIAL_CURRICULUM_MASTER.name,
        data
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getNumberTrendCompanies = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportNumberTrendCompanies>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getNumberTrendCompanies', async (req, { rejectWithValue }) => {
  try {
    const { data } = await report.filter(NUMBER_TREND_COMPANIES.name, req);
    browserLogger.info('report/thunk/getNumberTrendCompanies', NUMBER_TREND_COMPANIES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getNumberRegistedCompanies = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportNumberRegistedCompanies>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getNumberRegistedCompanies', async (req, { rejectWithValue }) => {
  try {
    const { data } = await report.filter(NUMBER_REGISTERED_COMPANIES.name, req);
    browserLogger.info(
      'eport/thunk/getNumberRegistedCompanies',
      NUMBER_REGISTERED_COMPANIES.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
