import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  SELECT_PROVIDER,
  SELECT_OFFICIAL_CURRICULUM_NAME,
  PARTNER_CURRICULUM_NUM_OF_USER_USING,
  PARTNER_CURRICULUM_NUM_OF_COMPANIES_USING,
  config,
  OFFICIAL_CURRICULUM_LIST,
  PARTNER_CURRICULUM_CONTRACT_STATUS,
} from 'configs';
import { browserLogger } from 'libs/logger';

export const getOfficialCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.OfficialCurriculumType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getOfficialCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.OfficialCurriculumType>(
      OFFICIAL_CURRICULUM_LIST.name,
      req,
      config.APP_ID_PARTNER
    );
    browserLogger.info(
      'OfficialCurriculum/thunk/getOfficialCurriculum',
      OFFICIAL_CURRICULUM_LIST.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPartnerCompanies = createAsyncThunk<
  Types.ReportsItemResponseType<Types.PartnerCurriculumCompaniesType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getPartnerCompanies', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.PartnerCurriculumCompaniesType>(
      PARTNER_CURRICULUM_NUM_OF_COMPANIES_USING.name,
      req
    );
    browserLogger.info(
      'OfficialCurriculum/thunk/getPartnerCompanies',
      PARTNER_CURRICULUM_NUM_OF_COMPANIES_USING.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPartnerUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.PartnerCurriculumUserType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getPartnerUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.PartnerCurriculumUserType>(
      PARTNER_CURRICULUM_NUM_OF_USER_USING.name,
      req
    );
    browserLogger.info(
      'OfficialCurriculum/thunk/getPartnerUser',
      PARTNER_CURRICULUM_NUM_OF_USER_USING.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectProvider = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectProviderType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/selectProvider', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectProviderType>(SELECT_PROVIDER.name, req);
    browserLogger.info('OfficialCurriculum/thunk/selectProvider', SELECT_PROVIDER.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getOfficialCurriculumName = createAsyncThunk<
  Types.ReportsItemResponseType<Types.OfficialCurriculumNameType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/selectOfficialCurriculumName', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.OfficialCurriculumNameType>(
      SELECT_OFFICIAL_CURRICULUM_NAME.name,
      req
    );
    browserLogger.info(
      'OfficialCurriculum/thunk/selectOfficialCurriculumName',
      SELECT_OFFICIAL_CURRICULUM_NAME.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getContractStatus = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumContractStatus>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getContractStatuss', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.CurriculumContractStatus>(
      PARTNER_CURRICULUM_CONTRACT_STATUS.name,
      req
    );
    browserLogger.info(
      'OfficialCurriculum/thunk/getContractStatuss',
      PARTNER_CURRICULUM_CONTRACT_STATUS.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
