import { Document, Page, View, Text, Font } from '@react-pdf/renderer';
import { map } from 'lodash';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';

import * as Types from 'types';
import styles from './styles';
import { useMemo } from 'react';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import _ from 'lodash';

Font.register({ family: 'NotosanJP', src: NotosanJP as string });
Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});
const pageSize = 'A4';
const pageOrientation = 'landscape';
const FIRST_PAGE_ROW_COUNT = 17;
const PAGE_ROW_COUNT = 20;

interface Props {
  roles: Types.Roles.ResponseType[];
}

const RolesMasterTable = ({ roles }: { roles: Types.Roles.ResponseType[] }) => {
  const columns: { title: string; width: string | number }[] = [
    {
      title: 'コード',
      width: 180,
    },
    {
      title: '権限名称',
      width: 546,
    },
  ];
  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow, { height: '26.728px' }]}>
        {columns.map((column, index) => (
          <View key={index} style={[styles.wrapCell, { width: column.width }]}>
            <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
          </View>
        ))}
      </View>

      {roles.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: rowIndex !== roles.length - 1 ? 'none' : '1px solid #e5e5e5',
            },
          ]}
        >
          <View style={[styles.wrapCell, { width: columns[0].width }]}>
            <Text style={styles.tableCell}>{row.code}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[1].width }]}>
            <Text style={styles.tableCell}>{row.name}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const PDFRolesMasterDocument = (props: Props) => {
  const { roles } = props;
  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap>
        <FullPage>
          <View style={styles.header}>
            <Text style={styles.textHeader}>権限マスタ</Text>
          </View>
          <View style={styles.subscription}>
            <View>
              <Text style={styles.textSubscription}>
                権限マスタの作成・管理を行う画面です。 権限マスタはSKILL
                FAMILIAR上でユーザーが使用できる機能の範囲を設定します。
              </Text>
              <Text style={styles.textSubscription}>
                作成した権限マスタをユーザーに設定することで、機能ごとの権限がユーザーに設定されます。
              </Text>
            </View>
          </View>
          <View style={styles.divider}></View>
          <View style={styles.body}>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>1</Text>
              <Text style={styles.textCountNumber}>-</Text>
              <Text style={styles.textCountNumber}>{roles.length}</Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{roles.length}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
            <RolesMasterTable roles={roles.slice(0, FIRST_PAGE_ROW_COUNT)} />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {roles.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(roles.slice(FIRST_PAGE_ROW_COUNT, roles.length), PAGE_ROW_COUNT).map(
          (page, index) => (
            <Page
              key={index}
              size={pageSize}
              orientation={pageOrientation}
              style={[styles.page, styles.pagePadding]}
              wrap={false}
            >
              <FullPage>
                <RolesMasterTable roles={page} />
              </FullPage>
              <PageNumber />
            </Page>
          )
        )}
    </Document>
  );
};

export default PDFRolesMasterDocument;
