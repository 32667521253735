import * as Types from 'types';
import { AnyObject } from 'types';
import { toObject } from 'libs/index';
import _ from 'lodash';

const getValue = (value?: string | number) =>
  typeof value === 'string' ? Number(value) : typeof value === 'number' ? value : 0;

export const calculateDataReport = (
  items: Types.UserReportInformation.UserReportInformationResponse[],
  companies: Types.CorporateUserInformationType[],
  dates: string[]
): Types.UserReportInformation.CalculatedReport => {
  const defaultDateData = dates.reduce((temp: AnyObject, item) => {
    temp[item] = 0;
    return temp;
  }, {});

  const defaultCompanyData = {
    emp_user_deleted: { ...defaultDateData },
    emp_user_added: { ...defaultDateData },
    month_end_users: { ...defaultDateData },
    max_users: { ...defaultDateData },
    churn_rate: { ...defaultDateData },
  };
  let result: Types.UserReportInformation.CalculatedReport = {
    ...defaultCompanyData,
  };
  const companiesObj = toObject(companies, 'id');
  const companyObj = items.reduce((rest: Types.UserReportInformation.ReportChildren, company) => {
    const deleted = getValue(company.emp_user_deleted);
    const added = getValue(company.emp_user_added);
    const endUser = getValue(company.month_end_users);
    const maxUser = getValue(company.max_users);

    const totalDeleted = result.emp_user_deleted[company.target_month] + deleted;
    const totalAdded = result.emp_user_added[company.target_month] + added;
    result = {
      ...result,
      emp_user_deleted: {
        ...result.emp_user_deleted,
        [company.target_month]: result.emp_user_deleted[company.target_month] + deleted,
      },
      emp_user_added: {
        ...result.emp_user_added,
        [company.target_month]: result.emp_user_added[company.target_month] + added,
      },
      month_end_users: {
        ...result.month_end_users,
        [company.target_month]: result.month_end_users[company.target_month] + endUser,
      },
      max_users: {
        ...result.max_users,
        [company.target_month]: result.max_users[company.target_month] + maxUser,
      },
      churn_rate: {
        ...result.churn_rate,
        [company.target_month]: (totalDeleted / (totalAdded || 1)) * 100,
      },
    };
    const companyId = company.company_id;
    if (rest[companyId]) {
      rest[companyId] = {
        ...rest[companyId],
        emp_user_deleted: {
          ...rest[companyId].emp_user_deleted,
          [company.target_month]: deleted,
        },
        emp_user_added: {
          ...rest[companyId].emp_user_added,
          [company.target_month]: added,
        },
        month_end_users: {
          ...rest[companyId].month_end_users,
          [company.target_month]: endUser,
        },
        max_users: { ...rest[companyId].max_users, [company.target_month]: maxUser },
      };
    } else {
      rest[companyId] = {
        name: companiesObj[companyId]?.name || companiesObj[companyId]?.company_name || '',
        emp_user_deleted: { ...defaultDateData, [company.target_month]: deleted },
        emp_user_added: { ...defaultDateData, [company.target_month]: added },
        month_end_users: { ...defaultDateData, [company.target_month]: endUser },
        max_users: { ...defaultDateData, [company.target_month]: maxUser },
      };
    }
    return rest;
  }, {});
  result = { ...result, children: companyObj };
  return result;
};

export const calculateCountTotalCompany = (
  items: Types.UserReportInformation.UserReportInformationResponse[],
  dates: string[]
) => {
  const reportCompanyByMonth = dates.reduce((temp: AnyObject, item: string) => {
    temp[item] = items.filter((obj) => obj.target_month === item).map((obj) => obj.company_id);
    return temp;
  }, {});

  const companyAdded: AnyObject = {};
  const monthEndCompany: AnyObject = {};
  const companyDeleted: AnyObject = {};

  Object.keys(reportCompanyByMonth).forEach((item, index) => {
    const currentCompanies = reportCompanyByMonth[item];
    const prevCompanies = reportCompanyByMonth[dates[index - 1]] || [];
    const companyDeletedArr = _.pullAll(prevCompanies, currentCompanies);
    const companyAddedArr = _.pullAll(currentCompanies, prevCompanies);
    monthEndCompany[item] = currentCompanies.length;
    companyDeleted[item] = companyDeletedArr.length;
    companyAdded[item] = companyAddedArr.length;
  });

  return {
    company_deleted: companyDeleted,
    company_added: companyAdded,
    month_end_company: monthEndCompany,
  };
};
