import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import InformationUserStyled from './styles';
import { ColumnDef } from '@tanstack/react-table';
import { AnyObject } from 'types';
import { reportUserInformationSelector } from 'pages/Report/UserInformation/selectors';
import VirtualizedTable from 'components/VirtualizedTable';
import { uniq } from 'lodash';
import dayjs from 'dayjs';

interface Props {
  visibleExpansionOfSale: boolean;
}

const DEFAULT_COLMUMNS: ColumnDef<AnyObject>[] = [
  {
    header: 'ID / ユーザー名',
    accessorKey: 'name',
    size: 250,
    cell: (cell) => {
      const rowData = cell.row.original;
      return (
        <div className="company-detail">
          {rowData.companyId && <div className="company-id">{rowData.companyId}</div>}
          <div className="company-name">{rowData.name}</div>
        </div>
      );
    },
  },
];

const InformationUser: React.FC<Props> = ({ visibleExpansionOfSale }) => {
  const { dateRanger, companyReports, calculatedCorporateUsers, filterCompanyName } = useSelector(
    reportUserInformationSelector
  );

  console.log('InformationUser');

  const dates = useMemo(() => {
    let dateData =
      dateRanger && dateRanger.length
        ? dateRanger
        : uniq(companyReports.map((obj) => obj.target_month));
    return dateData;
  }, [companyReports, dateRanger]);

  const columns = useMemo<ColumnDef<AnyObject>[]>(
    () => [
      ...DEFAULT_COLMUMNS,
      ...dates.map((date, index) => ({
        header: dayjs(date, 'YYYYMM').format('YYYY/MM'),
        accessorKey: date,
        size: 100,
        className: 'text-center',
      })),
    ],
    [dateRanger, companyReports, dates]
  );

  const dataSource = useMemo<AnyObject[]>(
    () =>
      calculatedCorporateUsers
        ? [
            {
              name: '法人ユーザー',
              ...calculatedCorporateUsers.max_users,
              children: calculatedCorporateUsers.children
                ? Object.keys(calculatedCorporateUsers.children)
                    .map((companyId) => {
                      const company: AnyObject = calculatedCorporateUsers.children
                        ? calculatedCorporateUsers.children[companyId] || {}
                        : {};
                      if (
                        !filterCompanyName ||
                        filterCompanyName === company.name ||
                        (company.name || '').includes(filterCompanyName)
                      ) {
                        return {
                          companyId,
                          name: company.name || companyId,
                          ...(company.max_users || {}),
                        };
                      }
                      return undefined;
                    })
                    .filter((obj) => !!obj)
                : [],
            },
          ]
        : [],
    [calculatedCorporateUsers]
  );

  return (
    <InformationUserStyled visibleExpansionOfSale={visibleExpansionOfSale}>
      <VirtualizedTable data={dataSource} columns={columns} />
    </InformationUserStyled>
  );
};

export default InformationUser;
