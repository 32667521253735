import React from 'react';
import { useNavigate } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';

import { routes } from 'navigations/routes';
import { Modal } from 'components/index';
import Styled from './styles';
import * as Types from 'types';

type Props = {
  selectedRow: Types.OfficialCurriculumPublishingSettings.ResponseType[];
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  content: string;
};
const Info: React.FC<Props> = ({ title, visible, setVisible, content, selectedRow }) => {
  const navigate = useNavigate();

  return (
    <Modal
      title={title}
      width={720}
      open={visible}
      onCancel={() => setVisible(false)}
      okButton={{
        text: 'OK',
        onClick: () => {
          navigate(routes.CurriculumMaster.path, { state: { selectedRow } });
        },
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: () => setVisible(false),
      }}
      footerStyle={{
        background: '#ffffff',
      }}
    >
      <Styled>
        <div className="wrap-content">
          <InfoCircleOutlined className="icon" />
          <p className="content">{content}</p>
        </div>
      </Styled>
    </Modal>
  );
};

export default Info;
