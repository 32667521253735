import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .wrap-sub-title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
    .icon {
      color: #bbbbbb;
      font-size: 28px;
    }
    .sub-title {
      font-size: 15px;
    }
  }
  .wrap-name {
    width: 100%;
    height: 56px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #eeeeee;
    background: #ffffff;
    border-radius: 3px;
    margin-bottom: 12px;
    .label {
      font-size: 11px;
      color: #999999;
      margin-bottom: 4px;
    }
    .name {
      text-align: center;
      margin-bottom: 0px;
      transform: translateY(-8px);
    }
  }
  .note {
    color: #999999;
  }
`;
