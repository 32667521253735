import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import * as Types from 'types';
import {
  createAdminAffiliationAssignRole,
  createAdminUsers,
  deleteAdminAffiliationRole,
  deleteAdminUsers,
  getAdminRoleList,
  getDataAllGmail,
  getListUserAdmin,
  getSelectAdminDepartment,
  getSelectAdminPosition,
  getSelectAdminRole,
  getSelectAdminUsers,
  getSelectUserAdmin,
  updateAdminAffiliationAssignRole,
  updateAdminUsers,
} from './thunk';

export type InitialState = {
  total: number;
  loading: boolean;
  dataUserMater: Array<Types.UserMaster>;
  dataSelectDepartment: Array<Types.AffiliationLevel.ResponseType>;
  dataSelectAdminUser: Array<Types.Selects.SelectAdminUsersType>;
  dataSelectPosition: Array<Types.AdminPositions.ResponseType>;
  dataSelectAdminRole: Array<Types.Roles.ResponseType>;
  dataSelectAdminAllRole: Array<Types.Roles.ResponseType>;
  dataAdminUserList: Array<Types.EmployeeUser.ResponseType>;
  dataListUser: Array<Types.EmployeeUser.ResponseType>;
  dataSelectListUser: Array<Types.EmployeeUser.ResponseType>;
  dataAllGmail: Array<{
    email: string;
    employee_code: string;
  }>;
};

const initialState: InitialState = {
  total: 0,
  loading: false,
  dataUserMater: [],
  dataSelectDepartment: [],
  dataSelectAdminUser: [],
  dataSelectPosition: [],
  dataSelectAdminRole: [],
  dataAdminUserList: [],
  dataAllGmail: [],
  dataSelectAdminAllRole: [],
  dataSelectListUser: [],
  dataListUser: [],
};

export const userMasterSlice = createSlice({
  name: 'userMaster-page',
  initialState,
  reducers: {
    filterListUser: (
      state,
      action: PayloadAction<{
        value: string;
        type: string;
      }>
    ) => {
      if (action.payload.type === 'employee_code') {
        state.dataListUser = state.dataListUser.filter(
          (e) => e.employee_code === action.payload.value
        );
      }
      if (action.payload.type === 'name') {
        state.dataListUser = state.dataListUser.filter((e) => e.name === action.payload.value);
      }
      if (action.payload.type === 'position_code') {
        state.dataListUser = state.dataListUser.filter(
          (e) => e.position_code === action.payload.value
        );
      }
      if (action.payload.type === 'department_name') {
        state.dataListUser = state.dataListUser.filter(
          (e) => e.department_name === action.payload.value
        );
      }
    },
  },
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(createAdminUsers.pending, startLoading)
      .addCase(updateAdminUsers.pending, startLoading)
      .addCase(deleteAdminUsers.pending, startLoading)
      .addCase(createAdminAffiliationAssignRole.pending, startLoading)
      .addCase(updateAdminAffiliationAssignRole.pending, startLoading)
      .addCase(deleteAdminAffiliationRole.pending, startLoading)
      .addCase(getSelectAdminRole.pending, startLoading)
      .addCase(getSelectAdminDepartment.pending, startLoading)
      .addCase(getSelectAdminPosition.pending, startLoading)
      .addCase(getDataAllGmail.pending, startLoading)
      .addCase(getSelectAdminUsers.pending, startLoading);

    builder.addCase(getListUserAdmin.fulfilled, (state, action) => {
      state.dataListUser = action.payload.items;
    });

    builder.addCase(getSelectUserAdmin.fulfilled, (state, action) => {
      state.dataSelectListUser = action.payload.items;
    });

    builder.addCase(getSelectAdminUsers.fulfilled, (state, action) => {
      state.dataSelectAdminUser = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getSelectAdminDepartment.fulfilled, (state, action) => {
      state.dataSelectDepartment = action.payload.items;
      stopLoading(state);
    });
    builder.addCase(getSelectAdminPosition.fulfilled, (state, action) => {
      state.dataSelectPosition = action.payload.items;
      stopLoading(state);
    });
    builder.addCase(getSelectAdminRole.fulfilled, (state, action) => {
      state.dataSelectAdminRole = action.payload.items;
      stopLoading(state);
    });
    builder.addCase(getAdminRoleList.fulfilled, (state, action) => {
      state.dataSelectAdminAllRole = action.payload.items;
      stopLoading(state);
    });
    builder.addCase(getDataAllGmail.fulfilled, (state, action) => {
      state.dataAllGmail = action.payload.items.map((item) => ({
        email: item.email,
        employee_code: item.employee_code,
      }));
      stopLoading(state);
    });
    builder
      .addCase(createAdminUsers.fulfilled, stopLoading)
      .addCase(updateAdminUsers.fulfilled, stopLoading)
      .addCase(createAdminAffiliationAssignRole.fulfilled, stopLoading)
      .addCase(updateAdminAffiliationAssignRole.fulfilled, stopLoading)
      .addCase(deleteAdminAffiliationRole.fulfilled, stopLoading)
      .addCase(deleteAdminUsers.fulfilled, stopLoading);

    builder
      .addCase(createAdminUsers.rejected, startLoading)
      .addCase(updateAdminUsers.rejected, startLoading)
      .addCase(deleteAdminUsers.rejected, startLoading)
      .addCase(createAdminAffiliationAssignRole.rejected, startLoading)
      .addCase(updateAdminAffiliationAssignRole.rejected, startLoading)
      .addCase(deleteAdminAffiliationRole.rejected, startLoading)
      .addCase(getSelectAdminRole.rejected, startLoading)
      .addCase(getSelectAdminDepartment.rejected, startLoading)
      .addCase(getSelectAdminPosition.rejected, startLoading)
      .addCase(getDataAllGmail.rejected, startLoading)
      .addCase(getSelectAdminUsers.rejected, startLoading);
  },
});

export const { filterListUser } = userMasterSlice.actions;

export default userMasterSlice.reducer;
