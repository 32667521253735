import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  attachmentContainer: {
    width: '100%',
    marginBottom: '6px',
  },
  valueContainer: {
    border: '1px solid #DDDDDD',
    borderRadius: 3,
    backgroundColor: '#fff',
    paddingHorizontal: 5,
    paddingVertical: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 3,
  },
  subLabel: {
    fontSize: 7,
    color: '#424242',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 3,
    position: 'relative',
  },
  image: {
    border: '1px solid #EEEEEE',
    width: 60,
    height: 40,
    objectFit: 'cover',
  },
  imageLabel: {
    fontSize: 7,
    color: '#777777',
    textAlign: 'center',
  },
  playIcon: {
    position: 'absolute',
    top: 15,
    left: 25,
    width: 10,
    height: 10,
  },
});
