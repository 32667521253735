import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  selectGroup: {
    marginBottom: '6px',
  },
  selectBox: {
    textAlign: 'left',
    borderRadius: 3,
    border: 1,
    borderColor: '#CCCCCC',
    paddingVertical: 5,
    paddingLeft: 8,
    paddingRight: 16,
    backgroundColor: '#FFFFFF',
    minHeight: 24,
    maxHeight: 24,
    position: 'relative',
    width: 130,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  selectBoxSelected: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    alignItems: 'center',
  },
  selectText: {
    fontSize: 7,
    color: '#424242',
    textAlign: 'left',
    width: '100%',
    textOverflow: 'ellipsis',
  },

  selectedIcon: {
    width: 8,
    height: 8,
    marginRight: 5,
  },

  placeholder: {
    color: '#CCCCCC',
  },

  selectIcon: {
    width: 6.75,
    height: 4,
    position: 'absolute',
    zIndex: 10,
    top: 8,
    right: 4,
    objectFit: 'cover',
  },
});
