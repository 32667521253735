import React, { useEffect, useRef, useState } from 'react';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { FormikProps, FormikProvider, useFormik } from 'formik';
import { generatePath, useNavigate } from 'react-router-dom';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import dayjs from 'dayjs';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { Header, Input, InputPassword, SelectField } from 'components';
import { createUserRegistrationHistory } from 'containers/Auth/thunk';
import { EnumUserRegistrationHistory } from 'constant/enum.constant';
import { settingSelector } from 'containers/AppSettings/selectors';
import useCheckModifiedPage from 'hooks/useCheckModifiedPage';
import { createUserMasterSchema } from 'libs/validations';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import useModifiedPage from 'hooks/useModifiedPage';
import { userMasterSelector } from '../selectors';
import CreateUserMasterStyled from './styles';
import { CREDIT_CARD_MONTH } from 'constant';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  createAdminAffiliationAssignRole,
  getSelectAdminDepartment,
  getSelectAdminPosition,
  getSelectAdminRole,
  createAdminUsers,
  getDataAllGmail,
  addUserAdmin,
  sendUserMail,
} from '../thunk';
import { config } from 'configs';

const { Option } = Select;

interface Props {
  handleCancle?: (formik: FormikProps<Types.CreateManagementUserMasterFormFormik>) => void;
  setEmail?: React.Dispatch<React.SetStateAction<string>>;
  isUseImport?: boolean;
  fetchEmployee?: () => void | Promise<void>;
}

const CreateUserMaster: React.FC<Props> = ({
  handleCancle,
  setEmail,
  isUseImport,
  fetchEmployee,
}) => {
  const [confirmStatus, setConfirmStatus] = useState<boolean>(false);
  const [visibleCompleted, setVisibleCompleted] = useState(false);
  const [birthDay, setBirthDay] = useState<{
    day: string;
    month: string;
    year: string;
  }>({
    day: '',
    month: '',
    year: '',
  });
  const [dateJoinedCompany, setDateJoinedCompany] = useState<{
    month: string;
    year: string;
  }>({
    month: '',
    year: '',
  });
  const { setModifiedPage } = useModifiedPage();
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);

  const { userInfo, userCompany } = useSelector(authSelector);
  const { dataSelectDepartment, dataSelectPosition, dataSelectAdminRole, dataAllGmail } =
    useSelector(userMasterSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const topRef = useRef<HTMLDivElement>(null);

  const handleSelectDateJoinedCompany = (type: 'month' | 'year') => (value: string) => {
    if (type === 'month') {
      setDateJoinedCompany((prevState) => ({ ...prevState, month: value }));
      formik.setFieldValue('doj', `${dateJoinedCompany.year}${value}`);
    } else {
      setDateJoinedCompany((prevState) => ({ ...prevState, year: value }));
      formik.setFieldValue('doj', `${value}${dateJoinedCompany.month}`);
    }
  };

  const handleSelectBirthDay = (type: 'month' | 'year' | 'day') => (value: string) => {
    if (type === 'month') {
      setBirthDay((prevState) => ({ ...prevState, month: value, day: '' }));
      formik.setFieldValue('dob', `${birthDay.year}${value}`);
    } else if (type === 'year') {
      setBirthDay((prevState) => ({ ...prevState, year: value }));
      formik.setFieldValue('dob', `${value}${birthDay.month}${birthDay.day}`);
    } else {
      setBirthDay((prevState) => ({ ...prevState, day: value }));
      formik.setFieldValue('dob', `${birthDay.year}${birthDay.month}${value}`);
    }
  };

  const formik = useFormik<Types.CreateManagementUserMasterFormFormik>({
    initialValues: {
      employee_code: '',
      name: '',
      name_furigana: '',
      email: '',
      email_confirm: '',
      password: '',
      password_confirm: '',
      role_code: '',
      dob: '',
      doj: '',
      main_position: {
        affiliation_id: '',
        affiliation_code: '',
        positions_id: '',
        positions_code: '',
      },
    },
    validationSchema: createUserMasterSchema,
    validateOnBlur: false,
    enableReinitialize: true,
    validate: (values) => {
      const errors: Partial<Types.CreateManagementUserMasterFormFormik> = {};
      if (isEmailDuplicate(values.email, dataAllGmail)) {
        errors.email =
          '入力したメールアドレスは既に登録されています。別のメールアドレスを入力してください。';
      }
      if (checkExistEmployeeCode(values.employee_code, dataAllGmail)) {
        errors.employee_code =
          '入力した社員番号は既に登録されています。別の社員番号を入力してください。';
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (confirmStatus) {
        dispatch(startLoading());

        const object = {} as Types.AdministratorMasterFormik &
          Types.AffiliationAssignRole.ResponseType;

        const resultAction = await Promise.all([
          dispatch(
            addUserAdmin({
              user_code: values.email,
              email: values.email,
              username: values.name,
              passowrd: values.password,
              send_password_to_email: false,
              email_template_id: config.CONFIRM_TEMPLATES_ID,
            })
          ),
          dispatch(
            createAdminUsers({
              item: {
                company_id: userInfo?.company_id,
                login_id: values.email,
                employee_code: values.employee_code,
                name: values.name,
                name_furigana: values.name_furigana,
                email: values.email,
                user_type: 'member',
                dob: values.dob ? dayjs(values.dob).toDate() : undefined,
                doj: values.doj ? dayjs(values.doj).toDate() : undefined,
                role_code: values.role_code,
                password: values.password,
              },
              return_display_id: true,
              return_item_result: true,
            })
          ),
          await dispatch(
            sendUserMail({
              company: userCompany?.name || '',
              email: values.email,
              password: values.password,
              url: 'https://stg-rsweb-admin.hexabase.com/',
            })
          ),
          dispatch(
            createAdminAffiliationAssignRole({
              item: {
                company_id: userInfo?.company_id,
                login_id: values.email,
                affiliation_id: values.main_position.affiliation_id,
                positions_code: values.main_position.positions_id,
                sort_order: 1,
                main_role: 'main',
              },
              return_display_id: true,
              return_item_result: true,
              realtime_auto_link: true,
            })
          ),
        ]);

        if (createAdminAffiliationAssignRole.fulfilled.match(resultAction[2])) {
          Object.assign(object, resultAction[2]?.payload.item);
        }
        if (createAdminUsers.fulfilled.match(resultAction[1])) {
          Object.assign(object, resultAction[1]?.payload.item);
          setEmail && setEmail(object.email);
          fetchEmployee && (await fetchEmployee());

          dispatch(
            createUserRegistrationHistory({
              item: {
                id: resultAction[1].payload.item_id,
                name: values.name,
                email: values.email,
                createdat: dayjs().format('YYYYMM'),
                user_type: EnumUserRegistrationHistory.individual,
              },
            })
          );
          setVisibleCompleted(true);
        }

        dispatch(stopLoading());
      } else {
        setConfirmStatus(true);
      }
      setSubmitting(false);
    },
  });

  const isEmailDuplicate = (email: string, allEmails: typeof dataAllGmail) => {
    return allEmails.filter((e) => e.email === email).length;
  };
  const checkExistEmployeeCode = (employee_code: string, allUser: typeof dataAllGmail) =>
    allUser.filter((e) => e.employee_code === employee_code).length;

  useEffect(() => {
    if (userInfo) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getSelectAdminDepartment({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectAdminPosition({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectAdminRole({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataAllGmail({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (confirmStatus && topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [confirmStatus]);
  useCheckModifiedPage(formik);
  return (
    <div ref={topRef}>
      <Header
        title={confirmStatus ? '社内ユーザーマスタ新規登録 確認画面' : '社内ユーザーマスタ新規登録'}
      />
      <CreateUserMasterStyled collapsedMenu={collapsedMenu} isUseImport={isUseImport}>
        <p className="text-sub-title">
          {confirmStatus ? (
            <span>
              この内容でよろしければ「登録」ボタンをクリックしてください。 <br />
              入力したメールアドレス宛に登録完了のメールが送信されます。
            </span>
          ) : (
            'ユーザーの新規登録を行う画面です。'
          )}
        </p>
        <FormikProvider value={formik}>
          <Form layout="vertical" autoComplete="off">
            <div className="wrap-basic-information">
              <div className="header">基本情報</div>
              <div className="body">
                <div className="form-left">
                  <Form.Item
                    name="employee_code"
                    label={
                      <span className="text-label">
                        社員番号<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="employee_code"
                      className="text-input"
                      placeholder="最大10文字"
                      readOnly={confirmStatus}
                      maxLength={10}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label={
                      <span className="text-label">
                        氏名<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="name"
                      className="text-input"
                      placeholder="最大100文字"
                      readOnly={confirmStatus}
                      maxLength={100}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name_furigana"
                    label={
                      <span className="text-label">
                        氏名（フリガナ）<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="name_furigana"
                      className="text-input"
                      placeholder="最大100文字"
                      readOnly={confirmStatus}
                      maxLength={100}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label={
                      <span className="text-label">
                        メールアドレス <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="email"
                      type="email"
                      className="text-input"
                      placeholder="＠を含む半角英数字：最大254文字"
                      autoComplete="off"
                      readOnly={confirmStatus}
                      maxLength={254}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email_confirm"
                    label={
                      <span className="text-label">
                        メールアドレス（確認）<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="email_confirm"
                      className="text-input"
                      placeholder="＠を含む半角英数字：最大254文字"
                      readOnly={confirmStatus}
                      maxLength={254}
                    />
                  </Form.Item>
                </div>
                <div className="form-right">
                  <Form.Item
                    name="dob"
                    label={
                      <span className="text-label">
                        生年月日 <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      placeholder="----"
                      className="pull_down input_small"
                      onChange={handleSelectBirthDay('year')}
                      allowClear
                      value={birthDay.year || undefined}
                      disabled={confirmStatus}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    >
                      {Array.from(
                        {
                          length: 100,
                        },
                        (_, i) => (i + (new Date().getFullYear() - 100)).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      className="pull_down input-month-day"
                      onChange={handleSelectBirthDay('month')}
                      placeholder="--"
                      allowClear
                      value={birthDay.month || undefined}
                      disabled={confirmStatus}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月</span>
                    <Select
                      className="pull_down input-month-day"
                      onChange={handleSelectBirthDay('day')}
                      placeholder="--"
                      allowClear
                      value={birthDay.day || undefined}
                      disabled={confirmStatus}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    >
                      {Array.from({
                        length: dayjs(`${birthDay.year}-${birthDay.month}`).daysInMonth(),
                      }).map((_item, index) => (
                        <Option
                          key={index}
                          value={(index + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        >
                          {(index + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">日</span>
                  </Form.Item>
                  <Form.Item
                    name="doj"
                    label={
                      <span className="text-label not-require">
                        入社年月 <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      onChange={handleSelectDateJoinedCompany('year')}
                      className="pull_down input_small"
                      placeholder="----"
                      allowClear
                      value={dateJoinedCompany.year || undefined}
                      disabled={confirmStatus}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    >
                      {Array.from(
                        {
                          length: 21,
                        },
                        (_, i) => (i + new Date().getFullYear() - 20).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      onChange={handleSelectDateJoinedCompany('month')}
                      className="pull_down input-month-day"
                      placeholder="--"
                      allowClear
                      value={dateJoinedCompany.month || undefined}
                      disabled={confirmStatus}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月 </span>
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label={
                      <span className="text-label">
                        パスワード <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <InputPassword
                      autoComplete="new-password"
                      iconRender={(visible) =>
                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                      }
                      name="password"
                      className="text-input"
                      placeholder="半角英数字、記号：４〜30文字"
                      readOnly={confirmStatus}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password_confirm"
                    label={
                      <span className="text-label">
                        パスワード（確認）<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <InputPassword
                      name="password_confirm"
                      iconRender={(visible) =>
                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                      }
                      className="text-input"
                      placeholder="半角英数字、記号：４〜30文字"
                      readOnly={confirmStatus}
                    />
                  </Form.Item>
                  <Form.Item
                    name="role_code"
                    className="pull_down"
                    label={
                      <span className="text-label">
                        権限 <span className="require">*</span>
                      </span>
                    }
                  >
                    <SelectField
                      name="role_code"
                      className="pull_down"
                      placeholder="選択してください"
                      allowClear
                      disabled={confirmStatus}
                    >
                      {dataSelectAdminRole?.map(({ i_id, name }) => (
                        <Option value={i_id} key={i_id}>
                          {name}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="wrap-department">
              <div className="header">所属・役職情報</div>
              <div className="body">
                <p className="description">所属・役職情報を設定・編集することができます。</p>
                <span className="label">所属・役職</span>
                <div className="wrap-main-position">
                  <div className="form-row">
                    <div className="item">
                      <Form.Item
                        name="main_position.affiliation_id"
                        label={
                          <span className="text-label">
                            所属 <span className="require">*</span>
                          </span>
                        }
                        className="form-input"
                      >
                        <SelectField
                          name="main_position.affiliation_id"
                          className="pull_down"
                          placeholder="選択してください"
                          disabled={confirmStatus}
                          onChange={(value: string) => {
                            formik.setFieldValue(
                              'main_position.affiliation_code',
                              dataSelectDepartment.find((item) => item.i_id === value)
                                ?.affiliation_id
                            );
                            formik.setFieldValue('main_position.affiliation_id', value);
                          }}
                          getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        >
                          {dataSelectDepartment?.map(({ i_id, name }) => (
                            <Option value={i_id} key={i_id}>
                              {name}
                            </Option>
                          ))}
                        </SelectField>
                      </Form.Item>
                    </div>
                    <div className="item">
                      <Form.Item
                        name="main_position.positions_id"
                        label={
                          <span className="text-label">
                            役職 <span className="require">*</span>
                          </span>
                        }
                        className="form-input"
                      >
                        <SelectField
                          name="main_position.positions_id"
                          className="pull_down"
                          placeholder="選択してください"
                          disabled={confirmStatus}
                          onChange={(value: string) => {
                            formik.setFieldValue(
                              'main_position.positions_code',
                              dataSelectPosition.find((item) => item.i_id === value)?.code
                            );
                            formik.setFieldValue('main_position.positions_id', value);
                          }}
                          getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        >
                          {dataSelectPosition.map(({ i_id, name }) => (
                            <Option value={i_id} key={i_id}>
                              {name}
                            </Option>
                          ))}
                        </SelectField>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrap-submit">
              <div className="wrap-button">
                <SubmitButton
                  disabled={!(formik.dirty && formik.isValid)}
                  className="btn btn_submit"
                >
                  {confirmStatus ? '登録' : '確認画面へ'}
                </SubmitButton>
                <button
                  className="btn btn_close"
                  type="button"
                  onClick={() => {
                    setModifiedPage(false);
                    confirmStatus
                      ? setConfirmStatus(false)
                      : handleCancle
                      ? handleCancle(formik)
                      : navigate(routes.UserMaster.path);
                  }}
                >
                  {confirmStatus ? '戻る' : 'キャンセル'}
                </button>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <CompletedModal
          title="登録が完了しました"
          visible={visibleCompleted}
          setVisible={setVisibleCompleted}
          onSubmit={() => {
            handleCancle
              ? handleCancle(formik)
              : navigate(generatePath(routes.UserMaster.path, { entity: 'receiving' }));
            setConfirmStatus(false);
          }}
        />
      </CreateUserMasterStyled>
    </div>
  );
};

export default CreateUserMaster;
