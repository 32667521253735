import { UploadFile } from 'antd';
import { services } from 'services';
import { extractFileName } from '../libs/utils/format';

export const copyFileInR2 = async (sourceObjectName: string) => {
  try {
    const { data } = await services.copyFileInR2({ sourceObjectName });
    return data.data;
  } catch (error) {
    console.error('Error copying file in R2:', error);
    return '';
  }
};

export const uploadFileToR2 = (file: UploadFile<File> | File): Promise<string> => {
  return services.uploadFileToR2(file as File);
};

export const deleteFileInR2 = async (fileName: string) => {
  await services.deleteFileInR2({ fileName });
};

/**
 * @deprecated Use `getPresignedUrlByFileID` instead.
 */
export const presignedGetObjectR2 = async (fileName: string) => {
  const { data } = await services.presignedGetObjectR2({ fileName });
  return data.data;
};

export const downloadFileFromR2 = async (fileName: string) => {
  const { data } = await services.presignedGetObjectR2({ fileName });
  const response = await fetch(data.data as string);
  const blob = await response.blob();
  return new File([blob], extractFileName(fileName));
};

export const getPresignedUrlByFileID = async (fileId: string) => {
  const { data } = await services.getPresignedUrlByFileID(fileId);
  return data.data;
};
