import React, { useMemo, useState } from 'react';
import { Input } from 'antd';

import ReleaseNewNote from '../ReleaseNewNote';
import Modal from 'components/Modal';
import { ArrowRight } from 'assets';
import * as Types from 'types';
import Styled from './styles';
import { usePermission } from 'hooks';

interface Props {
  status: string;
  title: string;
  from: string;
  to: string | React.ReactNode;
  selected: Array<Types.OfficialCurriculumPublishingSettings.ResponseType>;
  noteText?: React.ReactNode;
  description: React.ReactNode;
  visible: boolean;
  onSubmit?: (arg: 0 | undefined | number | string) => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  dataReleaseNoteID?: Array<Types.ReleaseNote.ResponseType>;
  openReleaseNoteModal?: () => void;
}

const CreateCurriculum: React.FC<Props> = ({
  status,
  visible,
  title,
  setVisible,
  onSubmit,
  description,
  noteText,
  from,
  to,
  selected,
  dataReleaseNoteID,
  openReleaseNoteModal,
}) => {
  const [, setVersion] = useState<string>('');
  const [visibleNewNote, setVisibleNewNote] = useState<boolean>(false);
  const handleToggleModal = () => {
    setVisible(false);
  };

  const { permissionNumber } = usePermission();

  const dataReleaseNote = useMemo(() => {
    return (dataReleaseNoteID || []).length > 1
      ? dataReleaseNoteID?.find((e) => !e.curriculum_publish_history_id)
      : null;
  }, [dataReleaseNoteID]);

  const handleSubmit = async () => {
    await handleToggleModal();
    onSubmit &&
      (await onSubmit(
        dataReleaseNoteID && dataReleaseNoteID.length > 1
          ? String(dataReleaseNoteID.slice(-1)[0].version)
          : dataReleaseNoteID
          ? String(dataReleaseNoteID[0]?.version)
          : ''
      ));
  };

  return (
    <Modal
      open={visible}
      width={740}
      okButton={{
        text: '公開する',
        onClick: handleSubmit,
        disabled:
          !(dataReleaseNoteID && dataReleaseNoteID?.length > 0) ||
          !dataReleaseNoteID ||
          (dataReleaseNoteID[0]?.curriculum_publish_history_id && dataReleaseNoteID.length === 1) ||
          (dataReleaseNote && Number(dataReleaseNote?.publish_status || 0) !== 2) ||
          (dataReleaseNoteID.length > 0 &&
            dataReleaseNoteID.every((item) =>
              item.hasOwnProperty('curriculum_publish_history_id')
            )),
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleToggleModal,
      }}
      onCancel={handleToggleModal}
      title={title}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        background: '#f9f8f8',
        paddingBottom: '0px !important',
      }}
      footerStyle={{
        background: '#f9f8f8',
      }}
    >
      <Styled>
        <div className="wrap-image">
          <div className="left-side">
            <span
              className={`name-status ${
                selected[0]?.publish === 0
                  ? 'violet'
                  : selected[0]?.publish === 2
                  ? 'green'
                  : selected[0]?.publish === 1
                  ? 'blue'
                  : ''
              }`}
            >
              {selected[0]?.publish === 0
                ? '編集中'
                : selected[0]?.publish === 2
                ? '公開中'
                : selected[0]?.publish === 3
                ? '公開停止中'
                : selected[0]?.publish === 1
                ? '未公開'
                : ''}
            </span>
            <img className="icon-update" src={from} alt="" />
          </div>
          <img className="arrow-right" src={ArrowRight} alt="" />
          <div className="right-side">
            {typeof to === 'string' ? <img className="icon-update large" src={to} alt="" /> : to}
            <span
              className={`name-status large-text ${
                status === 'publish'
                  ? 'green'
                  : status === 'editing'
                  ? 'violet'
                  : status === 'publishWarning'
                  ? 'grey'
                  : ''
              }`}
            >
              {status === 'editing'
                ? '編集中'
                : status === 'publish'
                ? '公開中'
                : status === 'publishWarning'
                ? '公開停止中'
                : status === 'unPublished'
                ? '未公開'
                : ''}
            </span>
          </div>
        </div>
        <p className={status === 'publish' ? 'description2' : 'description'}>{description}</p>
        {noteText && <p className="note">{noteText}</p>}
        {!dataReleaseNoteID ||
        (dataReleaseNoteID[0]?.curriculum_publish_history_id && dataReleaseNoteID.length === 1) ||
        (dataReleaseNoteID.length > 0 &&
          dataReleaseNoteID.every((item) =>
            item.hasOwnProperty('curriculum_publish_history_id')
          )) ? (
          <div className="wrap-input">
            <div
              style={{
                width: '65%',
              }}
            >
              <div className="wrap-label">
                <label className="label">公開バージョン</label>
                {dataReleaseNoteID &&
                  dataReleaseNoteID?.length > 0 &&
                  dataReleaseNoteID[0]?.curriculum_publish_history_id && (
                    <span className="version-label">
                      前回のバージョン：
                      <span className="version">
                        {dataReleaseNoteID?.length
                          ? dataReleaseNoteID.slice(-1)[0].version
                          : selected && selected[0]?.version}
                      </span>
                    </span>
                  )}
              </div>
              <Input
                readOnly={true}
                className={'input ant-color'}
                name="version"
                value={'未設定'}
                onChange={(e) => setVersion(e.target.value)}
              />
              {/* {Number(dataReleaseNote?.publish_status || 0) !== 2 && dataReleaseNote && (
                <span className="validate-publish">
                  リリースノートはまだ公開されていないです。
                  <br />
                  リリースノートを公開してから再度試してください。
                </span>
              )} */}
              <div>
                <p className="bottom-style" style={{ fontSize: 11, color: '#898585' }}>
                  ※公開バージョンはリリースノート作成時に設定します。
                </p>
                <div className="sub-title-active">
                  <span>
                    リリースノート：<span className={`ant-color`}>未作成</span>
                  </span>
                  <span className="label-link-active" onClick={() => setVisibleNewNote(true)}>
                    リリースノートを作成する
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="wrap-input">
            <div
              style={{
                width: '65%',
              }}
            >
              <div className="wrap-label">
                <label className="label">
                  公開バージョン <span className="required"> *</span>
                </label>
                {dataReleaseNoteID && dataReleaseNoteID[0]?.curriculum_publish_history_id && (
                  <span className="version-label">
                    前回のバージョン：
                    <span className="version">
                      {dataReleaseNoteID?.length > 1
                        ? dataReleaseNoteID
                            .filter(
                              (item) =>
                                item.publish_status == 2 &&
                                item.curriculum_publish_history_id != null
                            )
                            .slice(-1)[0]?.version
                        : dataReleaseNoteID && dataReleaseNoteID[0]?.version}
                    </span>
                  </span>
                )}
              </div>
              <Input
                readOnly={true}
                className={`input ${
                  !(dataReleaseNoteID && dataReleaseNoteID[0]?.version) ? 'ant-color' : ''
                }`}
                name="version"
                value={
                  dataReleaseNoteID && dataReleaseNoteID?.length > 1
                    ? dataReleaseNoteID.slice(-1)[0].version
                    : dataReleaseNoteID && dataReleaseNoteID?.length === 1
                    ? dataReleaseNoteID[0]?.version
                    : '未設定'
                }
                onChange={(e) => setVersion(e.target.value)}
              />
              {Number(dataReleaseNote?.publish_status || 0) !== 2 && dataReleaseNote && (
                <span className="validate-publish">
                  リリースノートはまだ公開されていないです。
                  <br />
                  リリースノートを公開してから再度試してください。
                </span>
              )}
              {!(dataReleaseNoteID && dataReleaseNoteID?.length > 0) ? (
                <div className="sub-title">
                  <span>
                    ※リリースノートから公開バージョンを設定してください。
                    <br />
                    新規公開時はリリースノートが自動作成されています。
                    <br />
                    カリキュラムの説明が初期表示されていますので、必要に応じて編集してください。
                  </span>
                  <div
                    className="label-link"
                    onClick={() => permissionNumber === 2 && setVisibleNewNote(true)}
                  >
                    リリースノートを編集する
                  </div>
                </div>
              ) : (
                <div className="sub-title-active">
                  <span>リリースノート：</span>
                  <span>作成済み</span>
                  <span onClick={openReleaseNoteModal} className="label-link-active">
                    リリースノートを確認する
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </Styled>
      <ReleaseNewNote
        publish={selected[0]?.publish}
        visible={visibleNewNote}
        setVisible={setVisibleNewNote}
        selectedOfficialSetting={selected[0]}
      />
    </Modal>
  );
};

export default CreateCurriculum;
