import { createSlice } from '@reduxjs/toolkit';

import * as Types from 'types';
import {
  createAdminPosition,
  deleteAdminPositions,
  getAffiliationAssignRole,
  getDataAdminPositions,
  getSelectAdminPositions,
  updateAdminPosition,
} from './thunk';

export type InitialState = {
  dataSelectAdminPositions: Array<Types.AdminPositions.ResponseType>;
  affiliationAssignRole: Types.AffiliationAssignRole.ResponseType[];
  dataAdminPositions: Array<Types.AdminPositions.ResponseType>;
  loading: boolean;
  total: number;
};

const initialState: InitialState = {
  dataSelectAdminPositions: [],
  affiliationAssignRole: [],
  dataAdminPositions: [],
  loading: false,
  total: 0,
};

export const jobTitleMasterMasterSlice = createSlice({
  name: 'jobTitleMasterMasterSlice-page',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getDataAdminPositions.pending, startLoading)
      .addCase(deleteAdminPositions.pending, startLoading)
      .addCase(createAdminPosition.pending, startLoading)
      .addCase(updateAdminPosition.pending, startLoading)
      .addCase(getSelectAdminPositions.pending, startLoading);

    builder.addCase(getDataAdminPositions.fulfilled, (state, action) => {
      state.dataAdminPositions = action.payload.items;
      stopLoading(state);
    });
    builder.addCase(getSelectAdminPositions.fulfilled, (state, action) => {
      state.dataSelectAdminPositions = action.payload.items;
      stopLoading(state);
    });
    builder.addCase(getAffiliationAssignRole.fulfilled, (state, action) => {
      state.affiliationAssignRole = action.payload.items;
    });
    builder
      .addCase(updateAdminPosition.fulfilled, stopLoading)
      .addCase(deleteAdminPositions.fulfilled, stopLoading)
      .addCase(createAdminPosition.fulfilled, stopLoading);

    builder
      .addCase(getDataAdminPositions.rejected, stopLoading)
      .addCase(deleteAdminPositions.rejected, stopLoading)
      .addCase(updateAdminPosition.rejected, stopLoading)
      .addCase(createAdminPosition.rejected, stopLoading)
      .addCase(getSelectAdminPositions.rejected, stopLoading);
  },
});

// export const {} = userMasterSlice.actions;

export default jobTitleMasterMasterSlice.reducer;
