import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  currentPage: {
    position: 'absolute',
    bottom: 2,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#424242',
    fontSize: 10,
  },
});
