import { Document, Font, Page, Text, View } from '@react-pdf/renderer';
import FullPage from 'components/ReactPDF/FullPage';
import PageNumber from 'components/ReactPDF/PageNumber';
import styles from './style';
import { nanoid } from '@reduxjs/toolkit';
import { chunk } from 'lodash';
import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';
import SelectBox from 'components/ReactPDF/SelectBox';
import Header from 'components/ReactPDF/Header';
import BarChartIcon from 'assets/svgPDF/BarChart';
import TableIcon from 'assets/svgPDF/Table';
import CheckBox from 'components/ReactPDF/CheckBox';
import { Pagination } from 'pages/Report/slice';
import { Conditions } from '..';
import DateRangerPicker from 'components/ReactPDF/DateRangerpicker';
import { COMPANY_TYPES_OBJECT } from 'constant';

const pageSize = 'A4';
const pageOrientation = 'landscape';
interface HeaderType {
  label: string;
  key: string;
}

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

interface Props {
  header: Array<HeaderType>;
  result: Array<Array<string>>;
  visibleProducerExpand: boolean;
  activeTable: boolean;
  visibleCurriculumDevelopment: boolean;
  Conditions: Conditions;
}

const FIRST_PAGE_ROW_COUNT = 6;
const PAGE_ROW_COUNT = 10;

const OfficialCurriculumInformationDocument = ({
  header,
  result,
  visibleProducerExpand,
  visibleCurriculumDevelopment,
  activeTable,
  Conditions,
}: Props) => {
  const value = () => {
    if (Conditions.company_type === 'ALL') {
      return '';
    }
    if (Conditions.company_type === COMPANY_TYPES_OBJECT.all_company.id) {
      return '全企業';
    }
    if (Conditions.company_type === COMPANY_TYPES_OBJECT.rstandard.id) {
      return 'RSTANDARD';
    }
    if (Conditions.company_type === COMPANY_TYPES_OBJECT.partner.id) {
      return 'パートナー企業';
    }
    return '';
  };

  return (
    <Document style={{ backgroundColor: '#F9F8F8' }}>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} break>
        <FullPage>
          <Header title="レポート" />
          <View style={styles.body}>
            <View style={styles.navigation}>
              <View>
                <Text style={styles.navigationItem}>売上情報</Text>
              </View>
              <View>
                <Text style={styles.navigationItem}>ユーザー情報</Text>
              </View>
              <View>
                <Text style={[styles.navigationItem, styles.navigationActiveItem]}>
                  OFFICIALカリキュラム情報
                </Text>
              </View>
              <View>
                <Text style={styles.navigationItem}>スキルチェック実施情報</Text>
              </View>
            </View>

            <Text
              style={{
                fontSize: '11px',
                marginBottom: 4,
                marginTop: 19,
                marginLeft: 11.7,
              }}
            >
              集計条件
            </Text>
            <View style={styles.searchPanel}>
              <View style={styles.searchItem}>
                <Text style={styles.label}>集計方法選択</Text>
                <SelectBox
                  placeholder="指定なし"
                  value={
                    Conditions.active_number_of_usage ? 'OFFICIALカリキュラム利用者数推移' : ''
                  }
                  selectBoxStyle={{ width: '150' }}
                />
              </View>
              <View style={styles.searchItem}>
                <Text style={styles.label}>集計期間</Text>
                <DateRangerPicker
                  placeholder="指定なし"
                  value={[
                    Conditions.start_period ? new Date(Conditions.start_period).toString() : '',
                    Conditions.end_period ? new Date(Conditions.end_period).toString() : '',
                  ]}
                  selectBoxStyle={{ width: '150' }}
                />
              </View>
              <View style={styles.searchItem}>
                <Text style={styles.label}>企業種類選択</Text>
                <SelectBox
                  value={value()}
                  placeholder="指定なし"
                  selectBoxStyle={{ width: '150' }}
                />
              </View>
              <View style={styles.searchItem}>
                <Text style={styles.label}>制作者検索</Text>
                <SelectBox
                  value={Conditions.company_name}
                  placeholder="指定なし"
                  selectBoxStyle={{ width: '150' }}
                />
              </View>
            </View>

            <View style={styles.config}>
              <View style={[styles.flexItem, styles.configLeft]}>
                <Text style={styles.layoutText}>レポートタイプ：</Text>
                <View
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <View
                    style={[
                      styles.layout,
                      styles.layoutActive,
                      {
                        paddingTop: '4px',
                        paddingBottom: '4px',
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                      },
                    ]}
                  >
                    <TableIcon
                      style={{
                        width: '13px',
                        height: '13px',
                      }}
                    />
                    <Text
                      style={[
                        styles.layoutText,
                        styles.layoutTextActive,
                        {
                          marginLeft: '5px',
                        },
                      ]}
                    >
                      表
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.layout,
                      {
                        paddingTop: '4px',
                        paddingBottom: '4px',
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        borderBottomLeftRadius: 0,
                        borderTopLeftRadius: 0,
                        marginLeft: '0px',
                        borderLeft: 'none',
                      },
                    ]}
                  >
                    <BarChartIcon style={styles.icon} />
                    <Text
                      style={[
                        styles.layoutText,
                        {
                          marginLeft: '5px',
                        },
                      ]}
                    >
                      グラフ
                    </Text>
                  </View>
                </View>

                <View
                  style={[
                    styles.check,
                    {
                      marginLeft: 17.5,
                    },
                  ]}
                >
                  <CheckBox
                    style={styles.checkbox}
                    checked={visibleProducerExpand}
                    label="制作者展開"
                  />
                </View>
                <View style={visibleProducerExpand ? styles.check : styles.checkdisable}>
                  <CheckBox
                    style={styles.checkbox}
                    checked={visibleCurriculumDevelopment && visibleProducerExpand}
                    label="カリキュラム展開"
                  />
                </View>
                <View style={styles.check}>
                  <CheckBox style={styles.checkbox} checked={activeTable} label="アクティブ" />
                </View>
              </View>
            </View>

            <View
              style={{
                marginLeft: 11.7,
                marginRight: 11.7,
              }}
            >
              <View style={styles.table}>
                <View
                  style={[
                    styles.textCountGeneral,
                    styles.tableHeaderRow,
                    {
                      borderBottom: '1px solid rgba(221, 221, 221, 1)',
                    },
                  ]}
                >
                  {header.slice(0, 14).map((item: HeaderType, index) => {
                    if (index === 0) {
                      return (
                        <Text
                          key={nanoid()}
                          style={[
                            styles.headerCell,
                            styles.wrapCell,
                            {
                              textAlign: 'left',
                              width: visibleProducerExpand
                                ? visibleCurriculumDevelopment
                                  ? 100
                                  : 100
                                : 100,
                              height: '100%',
                              flexDirection: 'column',
                            },
                          ]}
                        >
                          {item.label}
                        </Text>
                      );
                    } else if (index === 1) {
                      return (
                        <View
                          key={nanoid()}
                          style={[
                            styles.headerCell,
                            styles.wrapCell,
                            {
                              width: 80,
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start',
                            },
                          ]}
                        >
                          <Text>公開中</Text>
                          <Text>カリキュラム数</Text>
                        </View>
                      );
                    } else {
                      return (
                        <Text
                          key={nanoid()}
                          style={[
                            styles.headerCell,
                            styles.wrapCell,
                            {
                              flex: 1,
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '100%',
                            },
                          ]}
                        >
                          {item.label}
                        </Text>
                      );
                    }
                  })}
                </View>
                {result.slice(0, FIRST_PAGE_ROW_COUNT).map((column, index) => {
                  return (
                    <View
                      key={nanoid()}
                      style={[
                        styles.tableRow,
                        {
                          borderBottom:
                            index - 1 !== FIRST_PAGE_ROW_COUNT
                              ? '1px solid rgba(221, 221, 221, 1)'
                              : '',
                        },
                      ]}
                    >
                      {column.slice(0, 14).map((i, index) => {
                        if (index === 0 || index === 1) {
                          if (index === 0) {
                            if (i) {
                              const checkChildren = i.indexOf('</children>');
                              const checkGrandChildren = i.indexOf('</grandChildren>');
                              if (checkChildren !== -1) {
                                let string = i.slice(0, checkChildren);
                                return (
                                  <View
                                    key={nanoid()}
                                    style={[
                                      styles.wrapCell,
                                      {
                                        width: visibleProducerExpand
                                          ? visibleCurriculumDevelopment
                                            ? 100
                                            : 100
                                          : 100,
                                        flexDirection: 'column',
                                      },
                                    ]}
                                  >
                                    <View
                                      style={[
                                        styles.tableCell,
                                        styles.ContentCell,
                                        styles.ContentLeft,
                                        {
                                          marginLeft: 20,
                                        },
                                      ]}
                                    >
                                      <View
                                        style={[
                                          styles.ContentCellValue,
                                          {
                                            textAlign: 'left',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'flex-end',
                                            // maxLines: 1,
                                            alignContent: 'flex-end',
                                          },
                                        ]}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 8,
                                            color: '#AAAAAA',
                                            marginRight: 5,
                                          }}
                                        >
                                          {string}
                                        </Text>
                                        <Text
                                          style={{
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100px',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            // maxLines: 1,
                                          }}
                                        >
                                          {i.slice(checkChildren + 11).trim()}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                );
                              }
                              if (checkGrandChildren !== -1) {
                                let string = i.slice(0, checkGrandChildren);
                                return (
                                  <View
                                    key={nanoid()}
                                    style={[
                                      styles.wrapCell,
                                      {
                                        width: visibleProducerExpand
                                          ? visibleCurriculumDevelopment
                                            ? 100
                                            : 100
                                          : 100,
                                        flexDirection: 'column',
                                      },
                                    ]}
                                  >
                                    <View
                                      style={[
                                        styles.tableCell,
                                        styles.ContentCell,
                                        styles.ContentLeft,
                                        {
                                          marginLeft: 40,
                                        },
                                      ]}
                                    >
                                      <View
                                        style={[
                                          styles.ContentCellValue,
                                          {
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'flex-end',
                                            // maxLines: 1,
                                            textAlign: 'left',
                                            alignContent: 'flex-end',
                                          },
                                        ]}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 8,
                                            color: '#AAAAAA',
                                            marginRight: 5,
                                          }}
                                        >
                                          {string}
                                        </Text>
                                        <Text
                                          style={{
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100px',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            // maxLines: 1,
                                          }}
                                        >
                                          {i.slice(checkGrandChildren + 16).trim()}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                );
                              }
                            }

                            return (
                              <View
                                key={nanoid()}
                                style={[
                                  styles.wrapCell,
                                  {
                                    width: visibleProducerExpand
                                      ? visibleCurriculumDevelopment
                                        ? 100
                                        : 100
                                      : 100,
                                    flexDirection: 'column',
                                  },
                                ]}
                              >
                                <View
                                  style={[styles.tableCell, styles.ContentCell, styles.ContentLeft]}
                                >
                                  <Text
                                    style={[
                                      styles.ContentCellValue,
                                      {
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100px',
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        textAlign: 'left',
                                      },
                                    ]}
                                  >
                                    {i}
                                  </Text>
                                </View>
                              </View>
                            );
                          }

                          return (
                            <View
                              key={nanoid()}
                              style={[
                                styles.wrapCell,
                                {
                                  width: 80,
                                  flexDirection: 'column',
                                },
                              ]}
                            >
                              <View
                                style={[styles.tableCell, styles.ContentCell, styles.ContentLeft]}
                              >
                                <Text
                                  style={[
                                    styles.ContentCellValue,
                                    {
                                      textAlign: 'right',
                                    },
                                  ]}
                                >
                                  {i}
                                </Text>
                              </View>
                            </View>
                          );
                        } else {
                          return (
                            <View
                              key={nanoid()}
                              style={[
                                styles.wrapCell,
                                {
                                  flex: 1,
                                  flexDirection: 'column',
                                },
                              ]}
                            >
                              <View
                                style={[styles.tableCell, styles.ContentCell, styles.ContentRight]}
                              >
                                <Text style={[styles.ContentCellValue]}>{i}</Text>
                              </View>
                            </View>
                          );
                        }
                      })}
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {result.length > FIRST_PAGE_ROW_COUNT &&
        chunk(result.slice(FIRST_PAGE_ROW_COUNT, result.length), PAGE_ROW_COUNT).map(
          (page, index) => (
            <Page
              key={index}
              size={pageSize}
              orientation={pageOrientation}
              style={[
                styles.page,
                {
                  paddingHorizontal: 11.7,
                  paddingTop: 11.7,
                  paddingBottom: 35.7,
                },
              ]}
              wrap={false}
            >
              <FullPage>
                <View>
                  <View style={styles.table}>
                    <View style={[styles.textCountGeneral, styles.tableHeaderRow]}>
                      {header.slice(0, 14).map((item: HeaderType, index) => {
                        if (index === 0) {
                          return (
                            <Text
                              key={nanoid()}
                              style={[
                                styles.headerCell,
                                styles.wrapCell,
                                {
                                  textAlign: 'left',
                                  width: visibleProducerExpand
                                    ? visibleCurriculumDevelopment
                                      ? 100
                                      : 100
                                    : 100,
                                  height: '100%',
                                  flexDirection: 'column',
                                },
                              ]}
                            >
                              {item.label}
                            </Text>
                          );
                        } else if (index === 1) {
                          return (
                            <View
                              key={nanoid()}
                              style={[
                                styles.headerCell,
                                styles.wrapCell,
                                {
                                  width: 80,
                                  flexDirection: 'column',
                                  justifyContent: 'flex-start',
                                  alignItems: 'flex-start',
                                },
                              ]}
                            >
                              <Text>公開中</Text>
                              <Text>カリキュラム数</Text>
                            </View>
                          );
                        } else {
                          return (
                            <Text
                              key={nanoid()}
                              style={[
                                styles.headerCell,
                                styles.wrapCell,
                                {
                                  flex: 1,
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                },
                              ]}
                            >
                              {item.label}
                            </Text>
                          );
                        }
                      })}
                    </View>
                    {page.map((column) => {
                      return (
                        <View
                          key={nanoid()}
                          style={[
                            styles.tableRow,
                            {
                              borderBottom: '1px solid rgba(221, 221, 221, 1)',
                            },
                          ]}
                        >
                          {column.slice(0, 14).map((i, index) => {
                            if (index === 0 || index === 1) {
                              if (index === 0) {
                                if (i) {
                                  const checkChildren = i.indexOf('</children>');
                                  const checkGrandChildren = i.indexOf('</grandChildren>');
                                  if (checkChildren !== -1) {
                                    let string = i.slice(0, checkChildren);
                                    return (
                                      <View
                                        style={[
                                          styles.wrapCell,
                                          {
                                            width: visibleProducerExpand
                                              ? visibleCurriculumDevelopment
                                                ? 100
                                                : 100
                                              : 100,
                                            flexDirection: 'column',
                                          },
                                        ]}
                                      >
                                        <View
                                          style={[
                                            styles.tableCell,
                                            styles.ContentCell,
                                            styles.ContentLeft,
                                            {
                                              marginLeft: 20,
                                            },
                                          ]}
                                        >
                                          <View
                                            style={[
                                              styles.ContentCellValue,
                                              {
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignContent: 'flex-end',
                                                // maxLines: 1,
                                                alignItems: 'flex-end',
                                              },
                                            ]}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 8,
                                                color: '#AAAAAA',
                                                marginRight: 5,
                                              }}
                                            >
                                              {string}
                                            </Text>
                                            <Text
                                              style={{
                                                textOverflow: 'ellipsis',
                                                maxWidth: '100px',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                // maxLines: 1,
                                              }}
                                            >
                                              {i.slice(checkChildren + 11).trim()}
                                            </Text>
                                          </View>
                                        </View>
                                      </View>
                                    );
                                  }
                                  if (checkGrandChildren !== -1) {
                                    let string = i.slice(0, checkGrandChildren);
                                    return (
                                      <View
                                        style={[
                                          styles.wrapCell,
                                          {
                                            width: visibleProducerExpand
                                              ? visibleCurriculumDevelopment
                                                ? 100
                                                : 100
                                              : 100,
                                            flexDirection: 'column',
                                          },
                                        ]}
                                      >
                                        <View
                                          style={[
                                            styles.tableCell,
                                            styles.ContentCell,
                                            styles.ContentLeft,
                                            {
                                              marginLeft: 40,
                                            },
                                          ]}
                                        >
                                          <View
                                            style={[
                                              styles.ContentCellValue,
                                              {
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignContent: 'flex-end',
                                                // maxLines: 1,
                                                alignItems: 'flex-end',
                                              },
                                            ]}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 8,
                                                color: '#AAAAAA',
                                                marginRight: 5,
                                              }}
                                            >
                                              {string}
                                            </Text>
                                            <Text
                                              style={{
                                                textOverflow: 'ellipsis',
                                                maxWidth: '100px',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                // maxLines: 1,
                                              }}
                                            >
                                              {i.slice(checkGrandChildren + 16).trim()}
                                            </Text>
                                          </View>
                                        </View>
                                      </View>
                                    );
                                  }
                                }

                                return (
                                  <View
                                    style={[
                                      styles.wrapCell,
                                      {
                                        width: visibleProducerExpand
                                          ? visibleCurriculumDevelopment
                                            ? 100
                                            : 100
                                          : 100,
                                        flexDirection: 'column',
                                      },
                                    ]}
                                  >
                                    <View
                                      style={[
                                        styles.tableCell,
                                        styles.ContentCell,
                                        styles.ContentLeft,
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          styles.ContentCellValue,
                                          {
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100px',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textAlign: 'left',
                                          },
                                        ]}
                                      >
                                        {i}
                                      </Text>
                                    </View>
                                  </View>
                                );
                              }

                              return (
                                <View
                                  style={[
                                    styles.wrapCell,
                                    {
                                      width: 80,
                                      flexDirection: 'column',
                                      height: '100%',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    },
                                  ]}
                                >
                                  <View
                                    style={[
                                      styles.tableCell,
                                      styles.ContentCell,
                                      styles.ContentLeft,
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.ContentCellValue,
                                        {
                                          textAlign: 'left',
                                        },
                                      ]}
                                    >
                                      {i}
                                    </Text>
                                  </View>
                                </View>
                              );
                            } else {
                              return (
                                <View
                                  style={[
                                    styles.wrapCell,
                                    {
                                      flex: 1,
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    },
                                  ]}
                                >
                                  <View
                                    style={[
                                      styles.tableCell,
                                      styles.ContentCell,
                                      styles.ContentLeft,
                                    ]}
                                  >
                                    <Text style={[styles.ContentCellValue]}>{i}</Text>
                                  </View>
                                </View>
                              );
                            }
                          })}
                        </View>
                      );
                    })}
                  </View>
                </View>
                <PageNumber />
              </FullPage>
            </Page>
          )
        )}
    </Document>
  );
};

export default OfficialCurriculumInformationDocument;
