import { Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
const IconCheckSquareBold = ({
  style,
  isInvoice,
}: {
  style?: Style | Style[];
  isInvoice?: boolean;
}) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" style={style}>
    <Path
      d="M14.5698 0.857422H1.4269C1.11083 0.857422 0.855469 1.11278 0.855469 1.42885V14.5717C0.855469 14.8878 1.11083 15.1431 1.4269 15.1431H14.5698C14.8858 15.1431 15.1412 14.8878 15.1412 14.5717V1.42885C15.1412 1.11278 14.8858 0.857422 14.5698 0.857422ZM11.2751 5.38778L7.5144 10.6021C7.46184 10.6754 7.39254 10.7352 7.31227 10.7764C7.23199 10.8177 7.14304 10.8392 7.05279 10.8392C6.96254 10.8392 6.87359 10.8177 6.79331 10.7764C6.71304 10.7352 6.64375 10.6754 6.59118 10.6021L4.3644 7.51635C4.29654 7.42171 4.3644 7.28957 4.48047 7.28957H5.31797C5.50011 7.28957 5.67333 7.37707 5.78047 7.52707L7.0519 9.29135L9.85904 5.39849C9.96618 5.25028 10.1376 5.16099 10.3215 5.16099H11.159C11.2751 5.16099 11.343 5.29314 11.2751 5.38778Z"
      fill={isInvoice ? '#4B83D7' : '#8B62E2'}
    />
  </Svg>
);

export default IconCheckSquareBold;
