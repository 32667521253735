import React from 'react';
import { Select } from 'antd';
import { SELECT_RECORD } from 'constant/select.constants';
import useSelectPerPage, { Props, ReceivedProps } from './hook';
import Styled from './styles';

const { Option } = Select;

const PerPageSelectLayout = (props: Props) => {
  const { onChange, perPage, data } = props;

  return (
    <Styled>
      <div className="select-perpage">
        <div className="label">
          <span>表示件数</span>：
        </div>
        <Select
          defaultValue={perPage}
          onChange={onChange}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          value={perPage}
        >
          {SELECT_RECORD.map((value, index) => (
            <Option key={index} value={value !== '全て' ? value : data.length}>
              {value}
            </Option>
          ))}
        </Select>
      </div>
    </Styled>
  );
};

const PerPageSelect = (props: ReceivedProps) => {
  return <PerPageSelectLayout {...useSelectPerPage(props)} />;
};

export default PerPageSelect;
