import { useCallback, useEffect } from 'react';
import { FormikProps } from 'formik';
import useModifiedPage from './useModifiedPage';

const useCheckModifiedPage = (formik: FormikProps<any>) => {
  const { resetForm, setModifiedPage, setResetFormState } = useModifiedPage();

  const handleResetForm = useCallback(() => {
    setResetFormState(false);
    setModifiedPage(false);
    formik.resetForm();
  }, [setResetFormState, setModifiedPage, formik]);

  const checkIfModified = useCallback(() => {
    setModifiedPage(formik.dirty);
  }, [setModifiedPage, formik]);

  useEffect(() => {
    if (resetForm) {
      handleResetForm();
    } else {
      checkIfModified();
    }
  }, [resetForm, handleResetForm, checkIfModified]);
};

export default useCheckModifiedPage;
