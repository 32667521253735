import React, { useState } from 'react';
import { CheckCircleOutlined, MailOutlined } from '@ant-design/icons';
import { generatePath, Link, useLocation } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';

import { resetPasswordSchema } from 'libs/validations';
import { resetPassword } from 'containers/Auth/thunk';
import AuthLayout from 'containers/Auth/Layout';
import { routes } from 'navigations/routes';
import { ResetPasswordFormik } from 'types';
import { useAppDispatch } from 'hooks';
import SectionStyled from './styles';
import { Input } from 'components';
import { LogoAdmin } from 'assets';
import { config, DISPLAY_ID_KEY } from 'configs';
import { browserLogger } from 'libs/logger';

const ResetPassword: React.FC = () => {
  const [isSuccessfully, setIsSuccessfully] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const location = useLocation();

  const formik = useFormik<ResetPasswordFormik>({
    initialValues: {
      email: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      const resultAction = await dispatch(
        resetPassword({
          email: values.email,
          host: window.location.protocol + '//' + window.location.host,
          root_path: location.pathname.replace('/', ''),
          exclusive_w_id: DISPLAY_ID_KEY.exclusive_w_id.name,
          email_templates_id: config.TEMPLATES_ID,
        })
      );
      browserLogger.info(
        'pages/ResetPassword/index.tsx resetPassword',
        DISPLAY_ID_KEY.exclusive_w_id.name,
        resultAction
      );
      if (resetPassword.fulfilled.match(resultAction) && resultAction.payload.valid_email) {
        setIsSuccessfully(true);
      }
    },
  });

  return (
    <AuthLayout>
      <SectionStyled>
        <img src={LogoAdmin} alt="logo" className="logo" />
        <p className="title">パスワード再設定</p>
        {isSuccessfully ? (
          <>
            <CheckCircleOutlined className="check-circle-icon" size={66} />
            <p className="text-content">
              パスワード再設定通知をメール送信しました。
              <br />
              メールに記載されているURLにアクセスして、
              <br />
              新しいパスワードを設定してください。
            </p>
            <Link
              className="text-link"
              to={generatePath(routes.Login.path, { entity: 'receiving' })}
            >
              ログイン画面へ
            </Link>
          </>
        ) : (
          <>
            <p className="text-content">
              メールアドレスを入力し、
              <br />
              パスワードを再設定するボタンをクリックしてください。
            </p>
            <FormikProvider value={formik}>
              <Form className="form">
                <Form.Item name="email">
                  <Input
                    name="email"
                    type="email"
                    placeholder="メールアドレス"
                    className="input"
                    prefix={<MailOutlined className="icon" />}
                  />
                </Form.Item>
                <SubmitButton className="btn-submit">パスワードを再設定する</SubmitButton>
              </Form>
            </FormikProvider>
            <p className="text-content-error">
              ※メールが届かない場合は、メールアドレスが正しく入力されているか、
              迷惑メールフォルダに入っていないかをご確認ください。
            </p>
            <Link
              className="text-link"
              to={generatePath(routes.Login.path, { entity: 'receiving' })}
            >
              戻る
            </Link>
          </>
        )}
      </SectionStyled>
    </AuthLayout>
  );
};

export default ResetPassword;
