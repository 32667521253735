import React from 'react';

import RegisteredInformation from './RegisteredInformation';
import BreakdownOfCompanies from './BreakdownOfCompanies';
import InformationUser from './InformationUser';
import styled from 'styled-components';

interface Props {
  visibleExpansionOfSale: boolean;
  visibleUserDetail: boolean;
  exportPdf?: boolean;
  valueSelect?: number;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dddddd;
  background: #ffffff;
  width: 100%;
  height: 250px;
  color: #777777;
  font-size: 16px;
  border-radius: 3px;
`;

const Table: React.FC<Props> = ({
  visibleExpansionOfSale,
  valueSelect,
  visibleUserDetail,
  exportPdf,
}) => {
  return valueSelect === 0 ? (
    <BreakdownOfCompanies visibleExpansionOfSale={visibleExpansionOfSale} exportPdf={exportPdf} />
  ) : valueSelect === 1 ? (
    <RegisteredInformation
      visibleExpansionOfSale={visibleExpansionOfSale}
      visibleUserDetail={visibleUserDetail}
      exportPdf={exportPdf}
    />
  ) : valueSelect === 2 ? (
    <InformationUser visibleExpansionOfSale={visibleExpansionOfSale} />
  ) : (
    <Container>集計条件を選択してください</Container>
  );
};

export default Table;
