import styled from 'styled-components';

export default styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .icon-changed {
    height: 28px;
  }
  .changed-content {
    margin-left: 12px;
    font-size: 18px;
    text-align: center;
  }
`;
