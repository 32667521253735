import React, { CSSProperties } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';

import { SectionStyled } from './styles';
import Modal from '../index';
import { ModalProps } from 'antd';

type Props = ModalProps & {
  title?: string;
  contentStyle?: CSSProperties;
  visible: boolean;
  subTitle?: string | React.ReactNode;
  onSubmit?: () => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const CompletedModal: React.FC<Props> = ({
  title,
  subTitle,
  visible,
  setVisible,
  onSubmit,
  ...props
}) => {
  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleSubmit = async () => {
    handleToggleModal();
    onSubmit && (await onSubmit());
  };

  return (
    <Modal
      open={visible}
      width={450}
      okButton={{ text: 'OK', onClick: handleSubmit }}
      footerStyle={{ background: '#ffffff' }}
      {...props}
    >
      <SectionStyled>
        {title && (
          <p className="title">
            <CheckCircleOutlined className="icon" />
            {title}
          </p>
        )}
        {subTitle && <div className="sub-title">{subTitle}</div>}
      </SectionStyled>
    </Modal>
  );
};

export default CompletedModal;
