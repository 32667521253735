import React, { useCallback } from 'react';

export interface ReceivedProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useErrorSignUp = (props: ReceivedProps) => {
  const { setOpen } = props;
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return { ...props, handleClose };
};

export default useErrorSignUp;

export type Props = ReturnType<typeof useErrorSignUp>;
