import React, { useEffect, useMemo, useState } from 'react';
import { Column, G2 } from '@ant-design/plots';
import { Select } from 'antd';

import Styled from './styles';
import * as Types from 'types';
import { SALE_TYPE, USER_TYPE } from 'constant/report';
import dayjs from 'dayjs';
import { formatComma } from 'libs/utils/format';
import { setChartSaleType, setChartUserType } from 'pages/Report/SaleInformation/slice';
import { useSelector } from 'react-redux';
import { reportSaleInformationSelector } from 'pages/Report/SaleInformation/selectors';
import { useAppDispatch } from 'hooks';
import { uniq } from 'lodash';
import { EXPORT_TARGET_MONTH_COUNT } from 'constant';
import { AnyObject } from 'types';

const { Option } = Select;
type Props = {
  data: Array<Types.SaleReportInformation.SaleReportInformation>;
  exportPdf?: boolean;
};

const USER_TYPE_COLOR = {
  [USER_TYPE.CORPORATE]: '#4365BD',
  [USER_TYPE.PERSONAL]: '#E5C82D',
  [USER_TYPE.ALL]: '#6DC786',
};

const GraphNonExpansion: React.FC<Props> = (props) => {
  const { data, exportPdf } = props;
  const { chartUserType, dateRanger } = useSelector(reportSaleInformationSelector);
  const [userType, setUserType] = useState('all');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (exportPdf) {
      setUserType(chartUserType || 'all');
    }
  }, [exportPdf, chartUserType]);

  useEffect(() => {
    if (exportPdf) {
      dispatch(setChartUserType(''));
      dispatch(setChartSaleType(''));
    }
  }, [dispatch, exportPdf]);

  const dataChart = useMemo(() => {
    let dates =
      dateRanger && dateRanger.length ? dateRanger : uniq(data.map((obj) => obj.target_month));
    if (exportPdf) {
      dates = dates.slice(0, EXPORT_TARGET_MONTH_COUNT);
    }
    let res: AnyObject[] = [];

    const dataObjects: AnyObject = data
      .filter((obj) => obj.sales_type === SALE_TYPE.ALL)
      .reduce((temp, item) => ({ ...temp, [`${item.target_month}_${item.user_type}`]: item }), {});

    dates.forEach((date) => {
      res = [
        ...res,
        {
          name: USER_TYPE.CORPORATE,
          month: dayjs(date, 'YYYYMM').format('YYYY/MM'),
          value: dataObjects[`${date}_${USER_TYPE.CORPORATE}`]?.monthly_sales || 0,
        },
        {
          name: USER_TYPE.PERSONAL,
          month: dayjs(date, 'YYYYMM').format('YYYY/MM'),
          value: dataObjects[`${date}_${USER_TYPE.PERSONAL}`]?.monthly_sales || 0,
        },
        {
          name: USER_TYPE.ALL,
          month: dayjs(date, 'YYYYMM').format('YYYY/MM'),
          value: dataObjects[`${date}_${USER_TYPE.ALL}`]?.monthly_sales || 0,
        },
      ];
    });
    if (Object.keys(USER_TYPE).includes(userType)) {
      return res.filter((obj) => obj.name === USER_TYPE[userType as keyof typeof USER_TYPE]);
    }
    return res;
  }, [data, userType]);

  const colors = useMemo(() => {
    if (Object.keys(USER_TYPE).includes(userType)) {
      return [USER_TYPE_COLOR[USER_TYPE[userType as keyof typeof USER_TYPE]]];
    }
    return Object.values(USER_TYPE_COLOR);
  }, [userType]);

  const onSelectUserType = (value: string) => {
    setUserType(value);
    dispatch(setChartUserType(value));
  };

  return (
    <Styled>
      <div className="user-info">
        <div className="body-info">
          <div className="right-side">
            <div className="wrap-header">
              <span className="title">売上推移</span>
              <div className="wrap-action">
                <div className="wrap-select">
                  <label>ユーザー種類選択：</label>
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    value={userType}
                    onChange={onSelectUserType}
                  >
                    <Option value="all"> ALL</Option>
                    {Object.keys(USER_TYPE).map((type: any) => (
                      <Option key={type} value={type}>
                        {USER_TYPE[type as keyof typeof USER_TYPE]}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter blue" />
                    <span className="legend-name">法人ユーザー</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter yellow" />
                    <span className="legend-name">個人ユーザー</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter green" />
                    <span className="legend-name">全ユーザー</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="chart">
              <span className="unit">単位：千円</span>
              <Column
                {...config}
                data={dataChart}
                color={colors}
                legend={false}
                yAxis={{
                  grid: {
                    line: {
                      style: {
                        lineDash: [2, 2],
                        stroke: '#ddd',
                      },
                    },
                  },
                  label: {
                    formatter: (text: string) => formatComma(text),
                  },
                }}
                scrollbar={{
                  type: 'horizontal',
                  width: 300,
                  categorySize: 70,
                }}
                xAxis={{
                  tickLine: null,
                  label: { autoRotate: false },
                }}
                tooltip={{
                  formatter: (datum) => ({
                    name: datum.name,
                    value: formatComma(datum.value),
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default GraphNonExpansion;

const config = {
  isGroup: true,
  xField: 'month',
  yField: 'value',
  seriesField: 'name',
  maxColumnWidth: 15,
  dodgePadding: 4,
  color: ['#4365BD', '#E5C82D', '#6DC786'],
};
