import { AnyObject } from 'types';
import { BLANK_NAME } from 'constant';
import { VideoThumbnailGenerator } from 'browser-video-thumbnail-generator';
import { RcFile } from 'antd/lib/upload/interface';
import dayjs, { Dayjs } from 'dayjs';

export * from './axios';

export const toObject = (fields: AnyObject[], key: string): AnyObject => {
  return fields.reduce((acc: any, obj) => {
    acc[obj[key]] = obj;
    return acc;
  }, {});
};

export const extractYupErrorPath = (path: string) => {
  const arr = path.split('.');
  const index = arr[0]?.replace(/[\\[\]']+/g, '');
  return {
    index: Number(index),
    key: arr[1],
  };
};

export const getNameCurriculum = (name?: string) => (name === BLANK_NAME ? '' : name);

export const getVideoThumbnail = async (fileUrl: string | RcFile) => {
  try {
    const videoSrc =
      typeof fileUrl === 'string' ? fileUrl : URL.createObjectURL(new Blob([fileUrl as Blob]));
    const generator = new VideoThumbnailGenerator(videoSrc);
    const res = await generator.getThumbnail();
    return res.thumbnail;
  } catch (_) {
    return null;
  }
};

export const createMarkup = (html: string) => ({ __html: html });

export const parseVersion = (version: string) => version.split('.').map(Number);

export const compareVersion = (oldVer: string, newVer: string) => {
  const oldVersion = parseVersion(oldVer);
  const newVersion = parseVersion(newVer);

  for (let i = 0; i < Math.max(oldVersion.length, newVersion.length); i++) {
    const ver1 = oldVersion[i] || 0;
    const ver2 = newVersion[i] || 0;

    if (ver1 < ver2) {
      return 1;
    } else if (ver1 > ver2) {
      return -1;
    }
  }
  return 0;
};

export const getMonthBetween = (startDate: Dayjs, endDate: Dayjs) => {
  const months = [];

  let currentMonth = startDate;

  while (currentMonth <= endDate) {
    months.push(currentMonth);

    currentMonth = dayjs(currentMonth).add(1, 'months');
  }

  return months;
};
