import React, { useCallback, useEffect, useState } from 'react';
import { filter, unionBy, intersectionBy } from 'lodash';
import { FormikProvider, useFormik } from 'formik';
import { SubmitButton, Form } from 'formik-antd';
import { useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { pdf } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import { Table, Select } from 'antd';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

import { FileTextOutlined, SearchOutlined, RightOutlined, MailOutlined } from '@ant-design/icons';

import PDFFreeTrialManagementTable from 'pages/FreeTrialManagement/PDFFreeTrialManagementTable';
import { HEADER_EXPORT_FREE_TRIAL_MANAGEMENT_CSV } from 'constant/header.export.constant';
import { getCompany, getCompanyExportCSV, updateMemoCompany } from './thunk';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { PagingNumber, PerPageSelect, SelectField } from 'components';
import { settingSelector } from 'containers/AppSettings/selectors';
import CorporateUserInformationListStyled from './styles';
import { freeTrialManagementSelector } from './selector';
import { authSelector } from 'containers/Auth/selectors';
import ModalFreeTrialManagementDetail from './Modal';
import Completed from 'components/Modal/Completed';
import { exportCsv } from 'libs/utils/exportCsv';
import { loadingRef } from 'components/Loading';
import HeaderDashboard from 'components/Header';
import { formatDate } from 'libs/utils/format';
import { filterDataFreeTrial } from './slice';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import { config } from 'configs';
import * as Types from 'types';
import { SELECT_RECORD } from 'constant/select.constants';

const { Option } = Select;

const DEFAULT_FILTER = {
  company_status: '',
  company_id: '',
  company_name: '',
  contract_status: '',
};

const FreeTrialManagement: React.FC = () => {
  const [dataCompanyDetail, setDataCompanyDetail] = useState<Types.Companies.ResponseType>();
  const [visibleExportFile, setVisibleExportFile] = useState(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [openMail, setOpenMail] = useState<number>(-1);
  const [perPage, setPerPage] = useState<number>(100);
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState(DEFAULT_FILTER);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { dataFreeTrialCompany, loading, dataFilterFreeTrialCompany } = useSelector(
    freeTrialManagementSelector
  );
  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const formik = useFormik({
    initialValues: { ...DEFAULT_FILTER },
    onSubmit: (values) => {
      setFilters(values);
      const { company_id, company_name, company_status, contract_status } = values;
      const objFilter = {};

      if (company_id) {
        Object.assign(objFilter, { id: company_id });
      }
      if (company_name) {
        Object.assign(objFilter, { name: company_name });
      }

      let dataFounded: Types.Companies.ResponseType[] = filter(dataFreeTrialCompany, {
        ...objFilter,
      });

      if (company_status && company_status !== 'ALL') {
        dataFounded = dataFounded.filter((data) =>
          company_status === '1'
            ? dayjs().isBefore(dayjs(data.trial_end_date))
            : !dayjs().isBefore(dayjs(data.trial_end_date))
        );
      }

      if (contract_status && contract_status !== 'ALL') {
        if (contract_status === '2') {
          dataFounded = dataFounded.filter((data) => data.trial_completed_flag);
        } else if (contract_status === '1') {
          dataFounded = dataFounded.filter(
            (data) => dayjs().isBefore(dayjs(data.trial_end_date)) && !data.trial_completed_flag
          );
        } else {
          dataFounded = dataFounded.filter(
            (data) => !dayjs().isBefore(dayjs(data.trial_end_date)) && !data.trial_completed_flag
          );
        }
      }

      dispatch(filterDataFreeTrial(dataFounded));
      setPage(1);
    },
    onReset: () => {
      setFilters(DEFAULT_FILTER);
      dispatch(filterDataFreeTrial(dataFreeTrialCompany));
      setPage(1);
    },
  });

  const formikDetail = useFormik<{ memo: string }>({
    initialValues: {
      memo: '',
    },
    onSubmit: () => {},
  });

  const fetchCompanyUser = useCallback(() => {
    if (!userInfo) return;
    dispatch(
      getCompany({
        conditions: [
          {
            id: 'plan_id',
            search_value: [config.TRIAL_PLAN_ID],
            exact_match: true,
          },
          {
            id: 'trial_completed_flag',
            search_value: ['1'],
            exact_match: true,
          },
        ],
        sort_fields: [
          {
            id: 'id',
            order: 'asc',
          },
        ],
        use_display_id: true,
        include_item_ref: true,
        use_or_condition: true,
        page: 1,
        per_page: 0,
      })
    );
  }, [userInfo, dispatch]);

  const handleExportCSV = async (value: string) => {
    const resultAction = await dispatch(
      getCompanyExportCSV({
        page: 1,
        per_page: 0,
      })
    );
    if (getCompanyExportCSV.fulfilled.match(resultAction)) {
      if (value === 'csv') {
        const listCsv = dataFilterFreeTrialCompany.map((item) => ({
          company_status: dayjs().isBefore(dayjs(item.trial_end_date)) ? 'トライアル中' : '終了',
          id: item.id,
          name: item.name,
          remaining_days:
            dayjs(item.trial_end_date).diff(dayjs(), 'day') > 0
              ? dayjs(item.trial_end_date).diff(dayjs(), 'day')
              : 0,
          trial_start_date: formatDate(item.trial_start_date),
          trial_end_date: formatDate(item.trial_end_date),
          contract_status: item.trial_completed_flag
            ? '契約済み'
            : dayjs().isBefore(dayjs(item.trial_end_date))
            ? 'トライアル中'
            : '未契約',
          data_retention_days:
            !item.trial_completed_flag &&
            dayjs(item.trial_end_date).add(10, 'day').diff(dayjs(), 'day') > 0 &&
            dayjs(item.trial_end_date).add(10, 'day').diff(dayjs(), 'day') < 11
              ? dayjs(item.trial_end_date).add(10, 'day').diff(dayjs(), 'day')
              : 0,
          admin_department: item.admin_department,
          admin_position: item.admin_position,
          admin_name: item.admin_name,
        }));
        exportCsv(
          intersectionBy(listCsv, dataFilterFreeTrialCompany, 'id'),
          HEADER_EXPORT_FREE_TRIAL_MANAGEMENT_CSV,
          '無料トライアル管理.csv'
        );
      } else {
        const blob = await pdf(
          <PDFFreeTrialManagementTable dataSource={dataFilterFreeTrialCompany} filters={filters} />
        ).toBlob();
        saveAs(blob, '無料トライアル管理.pdf');
      }
      setVisibleExportFile(false);
    }
  };

  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  const handleUpdateMemoCompany = async () => {
    dispatch(startLoading());
    const actionResult = await dispatch(
      updateMemoCompany({
        id: dataCompanyDetail?.i_id ?? '',
        data: {
          item: {
            memo: formikDetail.values.memo,
          },
          is_force_update: true,
        },
      })
    );

    if (updateMemoCompany.fulfilled.match(actionResult)) {
      setCompleted(true);
    }
    dispatch(stopLoading());
  };

  const columns: ColumnsType<Types.Companies.ResponseType> = [
    {
      title: 'ステータス',
      dataIndex: 'companyStatus',
      key: 'companyStatus',
      width: '10%',
      align: 'left',
      render: (_: string, item: Types.Companies.ResponseType) => (
        <div className="wrap-contract-status">
          <span>{dayjs().isBefore(dayjs(item.trial_end_date)) ? 'トライアル中' : '終了'}</span>
        </div>
      ),
    },
    {
      title: (
        <>
          <span>法人ID</span>
          <br />
          <span>法人名</span>
        </>
      ),
      dataIndex: 'companyIdCompanyName',
      key: 'company_id_company_name',
      className: 'name',
      width: '20%',
      ellipsis: true,
      align: 'left',
      render: (_: string, item: Types.Companies.ResponseType) => (
        <span className="wrap-content-id">
          <span className="fax-id">{item.id}</span>
          <br />
          <span className="content-id">{item.name}</span>
        </span>
      ),
    },
    {
      title: '残日数',
      dataIndex: 'remaining_days',
      key: 'remaining_days',
      width: '5%',
      align: 'center',
      render: (_: string, item: Types.Companies.ResponseType) => (
        <span>
          {dayjs(item.trial_end_date).diff(dayjs(), 'day') > 0
            ? dayjs(item.trial_end_date).diff(dayjs(), 'day')
            : 0}
        </span>
      ),
    },
    {
      title: (
        <>
          <span>開始日時</span>
          <br />
          <span>終了日時</span>
        </>
      ),
      dataIndex: 'trial_start_end',
      key: 'trial_start_end',
      align: 'left',
      width: '12%',
      render: (_: string, item: Types.Companies.ResponseType) => (
        <span className="wrap-content-id">
          <span>{dayjs(item.trial_start_date).format('YYYY/MM/DD HH:mm')}</span>
          <br />
          <span>{dayjs(item.trial_end_date).format('YYYY/MM/DD HH:mm')}</span>
        </span>
      ),
    },
    {
      title: '契約状況',
      dataIndex: 'contract_status',
      key: 'contract_status',
      width: '10%',
      align: 'left',
      render: (_: string, item: Types.Companies.ResponseType) => (
        <span>
          {item.trial_completed_flag
            ? '契約済み'
            : dayjs().isBefore(dayjs(item.trial_end_date))
            ? 'トライアル中'
            : '未契約'}
        </span>
      ),
    },
    {
      title: 'データ保持日数',
      dataIndex: 'data_retention_days',
      key: 'data_retention_days',
      width: '10%',
      align: 'center',
      render: (_: string, item: Types.Companies.ResponseType) => (
        <span>
          {!item.trial_completed_flag &&
          dayjs(item.trial_end_date).add(10, 'day').diff(dayjs(), 'day') > 0 &&
          dayjs(item.trial_end_date).add(10, 'day').diff(dayjs(), 'day') < 11
            ? dayjs(item.trial_end_date).add(10, 'day').diff(dayjs(), 'day') + 1
            : 0}
        </span>
      ),
    },
    {
      title: (
        <>
          <span>所属 / 役職</span>
          <br />
          <span>管理者氏名</span>
        </>
      ),
      dataIndex: 'positionManagerName',
      key: 'positionManagerName',
      className: 'position-name',
      width: '22%',
      ellipsis: true,
      align: 'left',
      render: (_: string, item: Types.Companies.ResponseType) => (
        <span className="wrap-content-id">
          <span className="fax-id">
            {item.admin_department} / {item.admin_position}
          </span>
          <br />
          <span className="content-id">{item.admin_name}</span>
        </span>
      ),
    },
    {
      title: (
        <>
          <span>メール</span>
          <br />
          <span>送信</span>
        </>
      ),
      dataIndex: 'sendEmail',
      key: 'sendEmail',
      width: '7%',
      align: 'center',
      render: (_: any, item: any, index: number) => (
        <div className="wrap-icon-row">
          <MailOutlined
            onClick={(v) => {
              window.location.href = `mailto:${item.admin_email}`;
              setOpenMail((prevState) => (v && prevState !== index ? index : -1));
            }}
            className="icon"
            style={{ color: '#c4c4c4' }}
          />
        </div>
      ),
    },
    {
      title: '詳細',
      dataIndex: 'detailed',
      key: 'detailed',
      width: '4%',
      render: (_: string, record) => (
        <div className="wrap-icon-row">
          <FileTextOutlined
            onClick={() => {
              setVisible(true);
              setDataCompanyDetail(record);
              formikDetail.setFieldValue('memo', record.memo);
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(fetchCompanyUser, [fetchCompanyUser]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerPage(dataFilterFreeTrialCompany.length);
    }
  }, [dataFilterFreeTrialCompany]);

  return (
    <>
      <HeaderDashboard title={headerTitle} />
      <CorporateUserInformationListStyled checkTableNoData={!dataFilterFreeTrialCompany.length}>
        <div className="wrap-nav">
          <p className="text-note">無料トライアル中の法人ユーザーの状況を確認する画面です。</p>
          <div className="wrap-button">
            <button onClick={() => navigate(routes.CorporateUserInformationList.path)}>
              法人ユーザー情報一覧 <RightOutlined className="icon-right-outline" />
            </button>
            <button onClick={() => navigate(routes.InvoiceCorrespondence.path)}>
              請求書対応管理 <RightOutlined className="icon-right-outline" />
            </button>
          </div>
        </div>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical" colon={false}>
            <div className="form-search">
              <Form.Item
                name="company_status"
                className="item"
                label={<span className="text-label">ステータス</span>}
              >
                <SelectField
                  data-testid="ftm-registration-status"
                  name="company_status"
                  showSearch
                  allowClear
                  placeholder="指定なし"
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="ALL">ALL</Option>
                  <Option value="0">終了</Option>
                  <Option value="1">トライアル中</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="company_id"
                className="item"
                label={<span className="text-label">法人ID</span>}
              >
                <SelectField
                  data-testid="ftm-company-id"
                  name="company_id"
                  showSearch
                  allowClear
                  placeholder="指定なし"
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataFreeTrialCompany.map(({ id }) => (
                    <Option key={id} value={id}>
                      {id}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="company_name"
                className="item"
                label={<span className="text-label">法人名</span>}
              >
                <SelectField
                  data-testid="ftm-company-name"
                  name="company_name"
                  showSearch
                  allowClear
                  placeholder="指定なし"
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {unionBy(dataFreeTrialCompany, 'name').map(({ name }, index) => (
                    <Option key={index} value={name}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="contract_status"
                className="item"
                label={<span className="text-label">契約状況</span>}
              >
                <SelectField
                  data-testid="ftm-contract-status"
                  name="contract_status"
                  showSearch
                  allowClear
                  placeholder="指定なし"
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="ALL">ALL</Option>
                  <Option value="0">未契約</Option>
                  <Option value="1">トライアル中</Option>
                  <Option value="2">契約済み</Option>
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="wrap-body">
          <div className="btn-div">
            <button
              className="btn-control-number"
              onClick={() => setVisibleExportFile(!visibleExportFile)}
            >
              <span className="text">エクスポート</span>
            </button>
          </div>
          <div className="wrap-table">
            <Table
              className="table"
              rowClassName="border-hight-light"
              dataSource={dataFilterFreeTrialCompany}
              columns={columns}
              pagination={{
                pageSize: perPage,
                current: page,
                onChange: setPage,
                showSizeChanger: false,
                position: ['topCenter', 'bottomCenter'],
                showTotal: () => (
                  <div className="text-count">
                    <PagingNumber
                      startItem={
                        dataFilterFreeTrialCompany.length ? `${(page - 1) * perPage + 1}` : ''
                      }
                      endItem={
                        page * perPage > dataFilterFreeTrialCompany.length
                          ? dataFilterFreeTrialCompany.length
                          : page * perPage
                      }
                      totalItem={dataFilterFreeTrialCompany.length}
                    />
                    <PerPageSelect
                      data={dataFilterFreeTrialCompany}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerPage}
                    />
                  </div>
                ),
              }}
              rowKey={(record) => JSON.stringify(record)}
              onRow={(data) => ({
                className:
                  dayjs().isAfter(dayjs(data.trial_end_date)) && !data.trial_completed_flag
                    ? 'row-table-deleted'
                    : 'row-table',
              })}
            />
          </div>
        </div>
        <ModalFreeTrialManagementDetail
          visible={visible}
          setVisible={setVisible}
          dataCompanyDetail={dataCompanyDetail}
          onSubmit={handleUpdateMemoCompany}
          formik={formikDetail}
        />
        <PopupConfirmExportFile
          title="エクスポートを実行します。"
          visible={visibleExportFile}
          setVisible={setVisibleExportFile}
          onSubmit={handleExportCSV}
        />
        <Completed
          title="更新が完了しました"
          visible={completed}
          setVisible={setCompleted}
          onSubmit={fetchCompanyUser}
        />
      </CorporateUserInformationListStyled>
    </>
  );
};

export default FreeTrialManagement;
