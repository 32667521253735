/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form, SubmitButton } from 'formik-antd';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloudDownloadOutlined,
  FilterOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Select, Table } from 'antd';
import localeJa from 'dayjs/locale/ja';
import {
  getCurriculumSelect,
  getDataCurriculum,
  getOfficialCurriculumHierarchyList,
} from './thunk';
import { HEADER_OFFICIAL_CURRICULUM_CSV } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import NodeTreeView from 'containers/Curriculum/NodeTreeView';
import { CURRICULUM_TYPE, SEARCH_PARTNER, SEARCH_PUBLISH_OPTION } from 'constant/select.constants';
import { curriculumTreeSelector } from './selectors';
import { loadingRef } from 'components/Loading';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import { LIST_LABEL } from 'constant';
import { Header, SelectField } from 'components';
import { IconClosed, IconLocked, IconPublish, SortByDesc, Unpublished } from 'assets';
import Styled from './styles';
import FileExportPDF from './FileExportPDF';
import { exportPDF2 } from 'libs/utils/exportPDF';
import { exportCsv } from 'libs/utils/exportCsv';
import { FormikProvider, useFormik } from 'formik';
import { ConditionsType, CurriculumItemType, OfficialCurriculumTypes, TreeItem } from 'types';
import dayjs from 'dayjs';
import Modal from 'components/Modal';
import { cloneDeep, keyBy, omit, unionBy } from 'lodash';
import { getContractStatus, getOfficialCurriculumName } from 'pages/OfficialCurriculumList/thunk';
import { curriculumListSelector } from 'pages/OfficialCurriculumList/selectors';

const { Option } = Select;

const CurriculumTree: React.FC = () => {
  const location = useLocation();

  const [columnClosed, setColumnClosed] = useState<number | undefined>();
  const [visibleExportFile, setVisibleExportFile] = useState(false);
  const [visibleConfirmSuccess, setVisibleConfirmSuccess] = useState(false);

  const { dataCurriculumTree, loading, curriculumList, dataCurriculumTable } =
    useSelector(curriculumTreeSelector);
  const { curriculumContractStatus } = useSelector(curriculumListSelector);
  const headerTitle = 'パートナー カリキュラムツリー';

  const curriculumContractStatusKeyByCode = keyBy(curriculumContractStatus, 'p_related_id');

  const [filter, setFilter] = useState<any>({});

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleExportCSV = async (value: string) => {
    const filename = 'パートナ管理_カリキュラムツリー';
    if (value === 'csv') {
      const listCsv = (dataCurriculumTable || [])
        .filter((i) => i.curriculum_name === location.state?.values.curriculum_name)
        .map((item) => {
          const contractStatus = curriculumContractStatusKeyByCode[item.company_id!];
          const publish = SEARCH_PUBLISH_OPTION.find(
            (obj) => obj.value === `${item.curriculum_publish}`
          );
          return {
            i_id: 1,
            partner_code: item.company_id,
            partner_name: contractStatus.company_name || '（空白）',
            curriculum_code: item.curriculum_code,
            curriculum_name: item.curriculum_name,
            curriculum_status: publish?.label || '公開中',
            level1_code: item.level1_code,
            level1_name: item.level1_name || '（空白）',
            level2_code: item.level2_code,
            level2_name: item.level2_name || '（空白）',
            level3_code: item.level3_code,
            level3_name: item.level3_name || '（空白）',
            level4_code: item.level4_code,
            level4_name: item.level4_name || '（空白）',
            question_code: item.question_code,
            question_name: item.question_name || '（空白）',
            curriculum_description: item.curriculum_description || '（空白）',
          };
        });
      exportCsv(listCsv, HEADER_OFFICIAL_CURRICULUM_CSV, `${filename}.csv`);
      setVisibleExportFile(false);
    } else {
      exportPDF2(ref, `${filename}.pdf`, 'l', true, undefined, false);
    }
    setVisibleExportFile(false);
  };

  const [renewCurriculumTree, setRenewCurriculumTree] = useState<TreeItem<CurriculumItemType>[]>(
    []
  );

  const loadByFilter = (filters?: any) => {
    setRenewCurriculumTree(
      dataCurriculumTree.reduce((prev, current: TreeItem<CurriculumItemType>) => {
        if (location.state) {
          if (current.code === location.state?.official_curriculum_code) {
            prev = [current!, ...prev];
          }
        } else {
          const contractStatus = curriculumContractStatusKeyByCode[current.company_id!];

          const contractStatusValue =
            !contractStatus?.contract_end_date && !contractStatus?.deletedat
              ? null
              : contractStatus?.contract_end_date &&
                dayjs().isBefore(dayjs(contractStatus?.contract_end_date))
              ? 3
              : contractStatus?.deletedat &&
                dayjs().isAfter(dayjs(contractStatus?.contract_end_date))
              ? 5
              : 1;

          if (filters) {
            const isValidateProviderName = filters.provider_name
              ? contractStatus?.company_name === filters.provider_name
              : true;
            const isValidateContractStatus = filters.contract_status
              ? contractStatusValue == filters.contract_status
              : true;
            if (isValidateContractStatus && isValidateProviderName) {
              prev.push({
                ...current,
                provider_name: contractStatus?.company_name,
                contract_status: contractStatusValue,
              });
            }
          } else {
            prev.push({
              ...current,
              provider_name: contractStatus?.company_name,
              contract_status: contractStatusValue,
            });
          }
        }
        return prev;
      }, [] as TreeItem<CurriculumItemType>[])
    );
  };

  useEffect(() => {
    loadByFilter(filter);
  }, [dataCurriculumTree, filter]);

  const handleSearch = async () => {
    if (filter) {
      const conditions: ConditionsType[] = [];
      Object.keys(filter).forEach((key) => {
        if (filter[key] && !['contract_status', 'provider_name'].includes(key)) {
          conditions.push({
            id: key,
            search_value: [filter[key]],
            exact_match: true,
          });
        }
      });
      await dispatch(
        getDataCurriculum({
          conditions,
          page: 1,
          per_page: 0,
        })
      );
      if (
        Object.keys(filter).includes('provider_name') ||
        Object.keys(filter).includes('contract_status')
      ) {
        loadByFilter(filter);
      }
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'values',
      key: 'values',
      width: '1%',
      render: (_: string, item: OfficialCurriculumTypes) => (
        <span className="curriculum_code">{item?.values.official_curriculum_code}</span>
      ),
    },
    {
      title: 'カリキュラム名',
      dataIndex: 'values',
      key: 'values',
      className: 'description',
      width: '5%',
      render: (_: string, item: OfficialCurriculumTypes) => (
        <span>{item?.values.curriculum_name}</span>
      ),
    },
    {
      title: '公開ステータス',
      dataIndex: 'values',
      key: 'values',
      className: 'publish',
      width: '3%',
      render: (_: string, item: OfficialCurriculumTypes) => (
        <div className="question_code_table" style={{ display: 'flex', justifyContent: 'start' }}>
          {item?.values.publish === 1 ? (
            <div className="row-content">
              <img src={Unpublished} className="icon" alt="employee-icon" />
              <span className="spanText">未公開</span>
            </div>
          ) : item?.values.publish === 2 ? (
            <div className="row-content">
              <img src={IconPublish} className="icon" alt="employee-icon" />
              <span className="spanText">公開中</span>
            </div>
          ) : item?.values.publish === 3 ? (
            <div className="row-content">
              <img src={IconClosed} className="icon" alt="employee-icon" />
              <span className="spanText">公開停止中</span>
            </div>
          ) : (
            <div className="row-content">
              <img src={IconLocked} className="icon" alt="employee-icon" />
              <span className="spanText">編集中</span>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'values',
      key: 'values',
      width: '2%',
      render: (_: string, item: OfficialCurriculumTypes) =>
        item?.values.provider_id ? <span>{item?.values.provider_id}</span> : <span>-</span>,
    },
    {
      title: 'パートナー',
      dataIndex: 'values',
      key: 'values',
      className: 'description',
      width: '5%',
      render: (_: string, item: OfficialCurriculumTypes) =>
        item?.values.provider_name ? <span>{item?.values.provider_name}</span> : <span>-</span>,
    },
    {
      title: '契約状況',
      dataIndex: 'values',
      key: 'values',
      className: 'description',
      width: '2%',
      render: (_: string, item: OfficialCurriculumTypes) => (
        <span>
          {item.values.contract_status === 1
            ? '契約中'
            : item.values.contract_status === 3
            ? '契約終了予定'
            : item.values.contract_status === 5
            ? '契約終了'
            : '-'}
        </span>
      ),
    },
    {
      title: () => {
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>利用企業数</span>
              <span>利用ユーザー数</span>
            </div>
          </>
        );
      },
      dataIndex: 'values',
      key: 'values',
      width: '5%',
      render: (_: string, item: OfficialCurriculumTypes) => {
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                {item?.values.num_of_companies ? (
                  <span>{item?.values.num_of_companies}</span>
                ) : (
                  <span>-</span>
                )}
              </div>
              <div>
                {item?.values.num_of_user ? (
                  <span>{item?.values.num_of_user}</span>
                ) : (
                  <span>-</span>
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: () => {
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>前回公開日時</span>
              <span>最終更新日時</span>
            </div>
          </>
        );
      },
      dataIndex: 'values',
      key: 'values',
      width: '5%',
      render: (_: string, item: OfficialCurriculumTypes) => {
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                {item?.values.publish_start_date ? (
                  dayjs(item?.values.publish_start_date).locale(localeJa).format('YYYY/MM/DD HH:mm')
                ) : (
                  <span>-</span>
                )}
              </div>
              <div>
                {item?.values.updatedat ? (
                  dayjs(item?.values.updatedat).locale(localeJa).format('YYYY/MM/DD HH:mm')
                ) : (
                  <span>-</span>
                )}
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const ref = useRef(null);
  const component = useMemo(() => {
    return (
      <div
        ref={ref}
        style={{
          position: 'absolute',
          width: 1512,
          right: 9999,
        }}
      >
        <FileExportPDF
          data={renewCurriculumTree}
          tabActive={0}
          columnClosed={columnClosed}
          headerTitle={headerTitle}
          itemMoveCopySelected={undefined}
          collapsedMenu={false}
          filter_conditions={undefined}
          columns={columns}
        />
      </div>
    );
  }, [renewCurriculumTree, columnClosed]);

  useEffect(() => {
    Promise.all([
      dispatch(
        getDataCurriculum({
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(getContractStatus({ page: 1, per_page: 0 })),
      dispatch(getOfficialCurriculumName({ page: 1, per_page: 0 })),
      dispatch(getCurriculumSelect({ page: 1, per_page: 0 })),
    ]);
  }, [dispatch]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  const formik = useFormik({
    initialValues: {
      deletedat: '',
      official_curriculum_code: '',
      provider_id: '',
      provider_name: '',
      curriculum_name: '',
      publish: '',
    },
    validateOnBlur: false,
    onSubmit: () => {},
    onReset: () => {},
  });

  const handleChange = (value: any, field: string) => {
    if (value === 'all' && !value) {
      setFilter(omit(cloneDeep(filter), [field]));
    } else {
      setFilter({ ...filter, [field]: value });
    }
  };

  return (
    <>
      {component}
      <Styled>
        <Header title={headerTitle} className="header">
          <form className="form" style={{ display: 'none' }}>
            <FilterOutlined className="filter-icon" />
            <div className="form-input">
              <Select
                className="select-input"
                placeholder="ステータス"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
              >
                {CURRICULUM_TYPE.map((crr, i) => (
                  <Option key={i} value={crr.value}>
                    {crr.label}
                  </Option>
                ))}
              </Select>
            </div>
            <img src={SortByDesc} className="sortByDesc-icon" alt="sort-by-desc-icon" />
            <div className="form-input">
              <Select
                showSearch
                className="select-input"
                placeholder="指定のカリキュラムを上位表示"
                filterOption={(input, option) =>
                  JSON.stringify(option?.children || '')
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
              >
                {dataCurriculumTree.map((curr, index) => (
                  <Option key={index} value={curr.i_id}>
                    {curr.name}
                  </Option>
                ))}
              </Select>
            </div>
            <button type="button" className="text-reset">
              クリア
            </button>
          </form>
        </Header>
        <div className="flex">
          <div className="dashboard">
            <div className="wrap-title">
              <div className="wrap-button">
                <span>パートナーが作成したOFFICIALカリキュラムツリーの確認を行う画面です。</span>
                <div className="button-function" style={{ display: 'flex' }}>
                  <button
                    className="btn btn-outline"
                    style={{
                      width: '160px',
                      height: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '12px',
                      justifyContent: 'center',
                    }}
                    onClick={() => navigate(routes.PartnerManagement.path)}
                  >
                    <span>パートナー管理</span>
                    <RightOutlined className="size-icon-down-outline" />
                  </button>
                  <button
                    className="btn btn-outline"
                    style={{
                      width: '160px',
                      height: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '12px',
                      justifyContent: 'center',
                    }}
                    onClick={() => navigate(routes.OfficialCurriculumList.path)}
                  >
                    <span>カリキュラム一覧</span>
                    <RightOutlined className="size-icon-down-outline" />
                  </button>
                  <button
                    onClick={() =>
                      location.state ? setVisibleExportFile(true) : setVisibleConfirmSuccess(true)
                    }
                    className="btn btn-active"
                    style={{
                      width: '160px',
                      height: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '13px',
                    }}
                  >
                    <CloudDownloadOutlined className="size-icon" />
                    エクスポート
                  </button>
                </div>
              </div>
              <div>
                {location.state ? (
                  <div className="table-partner" style={{ padding: '0px 24px' }}>
                    選択中カリキュラム情報
                    <br></br>
                    <Table
                      rowKey="i_id"
                      className="table"
                      dataSource={[location.state]}
                      columns={columns}
                      pagination={false}
                    />
                  </div>
                ) : (
                  <FormikProvider value={formik}>
                    <Form layout="vertical">
                      <div className="form-search">
                        <div className="item-select">
                          <Form.Item
                            name="deletedat"
                            className="item contract-status"
                            label={<span className="text-label">契約状況</span>}
                          >
                            <SelectField
                              data-testid="curr-tree-contract-status"
                              placeholder="指定なし"
                              name="deletedat"
                              showSearch
                              allowClear
                              filterOption={(input, option) =>
                                JSON.stringify(option?.children || '')
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(v) => handleChange(v, 'contract_status')}
                            >
                              {SEARCH_PARTNER.map((item) => (
                                <Option value={item.value} key={item.value}>
                                  {item.label}
                                </Option>
                              ))}
                            </SelectField>
                          </Form.Item>
                          <Form.Item
                            name="company_id"
                            className="item provider-id"
                            label={<span className="text-label">ID</span>}
                          >
                            <SelectField
                              data-testid="curr-tree-company-id"
                              placeholder="指定なし"
                              name="company_id"
                              showSearch
                              allowClear
                              filterOption={(input, option) =>
                                JSON.stringify(option?.children || '')
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(v) => handleChange(v, 'company_id')}
                            >
                              {unionBy(dataCurriculumTree, 'company_id').map((item, index) => (
                                <Option value={item.company_id} key={index}>
                                  {item.company_id}
                                </Option>
                              ))}
                            </SelectField>
                          </Form.Item>
                          <Form.Item
                            name="provider_name"
                            className="item partner-name"
                            label={<span className="text-label">パートナー</span>}
                          >
                            <SelectField
                              data-testid="curr-tree-provider-name"
                              placeholder="指定なし"
                              name="provider_name"
                              showSearch
                              allowClear
                              filterOption={(input, option) =>
                                JSON.stringify(option?.children || '')
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(v) => handleChange(v, 'provider_name')}
                            >
                              {unionBy(curriculumContractStatus, 'company_name').map(
                                (item, index) => (
                                  <Option value={item.company_name} key={index}>
                                    {item.company_name}
                                  </Option>
                                )
                              )}
                            </SelectField>
                          </Form.Item>
                          <Form.Item
                            name="curriculum_code"
                            className="item contract-status"
                            label={<span className="text-label">カリキュラムID</span>}
                          >
                            <SelectField
                              data-testid="curr-tree-curriculum-code"
                              placeholder="指定なし"
                              name="code"
                              showSearch
                              allowClear
                              filterOption={(input, option) =>
                                JSON.stringify(option?.children || '')
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(v) => handleChange(v, 'code')}
                            >
                              {unionBy(curriculumList, 'code')
                                .filter((i) => !!i.code)
                                .map((item, index) => (
                                  <Option value={item.code} key={index}>
                                    {item.code}
                                  </Option>
                                ))}
                            </SelectField>
                          </Form.Item>
                          <Form.Item
                            name="name"
                            className="item curriculum-name"
                            label={<span className="text-label">カリキュラム</span>}
                          >
                            <SelectField
                              data-testid="curr-tree-curriculum-name"
                              placeholder="指定なし"
                              name="name"
                              showSearch
                              allowClear
                              filterOption={(input, option) =>
                                JSON.stringify(option?.children || '')
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(v) => handleChange(v, 'name')}
                            >
                              {unionBy(curriculumList, 'name')
                                .filter((i) => !!i.name)
                                .map((item, index) => (
                                  <Option value={item.name} key={index}>
                                    {item.name}
                                  </Option>
                                ))}
                            </SelectField>
                          </Form.Item>
                          <Form.Item
                            name="publish"
                            className="item"
                            label={<span className="text-label">公開ステータス</span>}
                          >
                            <SelectField
                              data-testid="curr-tree-publish-status"
                              placeholder="指定なし"
                              name="publish"
                              showSearch
                              allowClear
                              filterOption={(input, option) =>
                                JSON.stringify(option?.children || '')
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(v) => handleChange(v, 'publish')}
                            >
                              {SEARCH_PUBLISH_OPTION.map((item) => (
                                <Option value={item.value} key={item.value}>
                                  {item.label}
                                </Option>
                              ))}
                            </SelectField>
                          </Form.Item>
                        </div>
                        <div className="group-btn">
                          <SubmitButton
                            className="btn-search"
                            loading={false}
                            onClick={handleSearch}
                          >
                            <SearchOutlined className="icon-search" />
                            検索
                          </SubmitButton>
                          <span
                            className="label-reset"
                            onClick={() => {
                              if (Object.keys(filter).length === 0) return;
                              formik.resetForm();
                              setFilter({});
                              dispatch(
                                getOfficialCurriculumHierarchyList({
                                  page: 1,
                                  per_page: 0,
                                })
                              );
                            }}
                          >
                            リセット
                          </span>
                        </div>
                      </div>
                    </Form>
                  </FormikProvider>
                )}
              </div>

              <div className="flex-label">
                {LIST_LABEL.map((item, index) => (
                  <p
                    key={index}
                    className={`label-text${index === columnClosed ? ' active' : ''}`}
                    onClick={() =>
                      setColumnClosed((prevState) =>
                        prevState === index || index > 4 ? undefined : index
                      )
                    }
                  >
                    {index < 5 ? (
                      index === columnClosed ? (
                        <CaretUpOutlined className="icon-label" />
                      ) : (
                        <CaretDownOutlined className="icon-label" />
                      )
                    ) : null}
                    {item}
                  </p>
                ))}
              </div>
            </div>
            <div className="wrap-body">
              {renewCurriculumTree.map((c, index) => (
                <div
                  key={index}
                  className={`wrap-tree ${
                    index < renewCurriculumTree?.length - 1 ? 'bordered' : ''
                  }`}
                >
                  <NodeTreeView
                    treeData={c}
                    treeViewIndex={index}
                    tabActive={0}
                    columnClosed={columnClosed}
                    isIndex={index}
                    isPartners
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <PopupConfirmExportFile
          visible={visibleExportFile}
          setVisible={setVisibleExportFile}
          onSubmit={handleExportCSV}
          customTitle={
            <>
              エクスポートを実行します。
              <br />
              出力形式を選択して、OKボタンをクリックしてください。
            </>
          }
        />
        <Modal
          open={visibleConfirmSuccess}
          okButton={{
            text: 'OK',
            onClick: () => setVisibleConfirmSuccess(false),
            style: {
              backgroundColor: '#ffffff',
              color: '#777777',
            },
          }}
          title={'エクスポート'}
        >
          <div style={{ textAlign: 'center' }}>
            カリキュラムが指定されていません。
            <br></br>
            条件検索でカリキュラムを指定してから、再度実行してください。
          </div>
        </Modal>
      </Styled>
    </>
  );
};

export default CurriculumTree;
