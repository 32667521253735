import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
const StopPublic = ({ style }: { style?: Style | Style[] }) => (
  <Svg width="18" height="18" viewBox="0 0 18 18" style={style}>
    <Rect width="18" height="18" rx="2" fill="#989898" />
    <G clip-path="url(#clip0_71_97845)">
      <Path
        d="M15.9332 14.375L9.43321 3.125C9.33633 2.95781 9.16915 2.875 9.0004 2.875C8.83165 2.875 8.6629 2.95781 8.56758 3.125L2.06758 14.375C1.8754 14.7094 2.11602 15.125 2.5004 15.125H15.5004C15.8848 15.125 16.1254 14.7094 15.9332 14.375ZM8.5004 7.5C8.5004 7.43125 8.55665 7.375 8.6254 7.375H9.3754C9.44415 7.375 9.5004 7.43125 9.5004 7.5V10.375C9.5004 10.4437 9.44415 10.5 9.3754 10.5H8.6254C8.55665 10.5 8.5004 10.4437 8.5004 10.375V7.5ZM9.0004 13C8.80413 12.996 8.61726 12.9152 8.47988 12.775C8.3425 12.6348 8.26555 12.4463 8.26555 12.25C8.26555 12.0537 8.3425 11.8652 8.47988 11.725C8.61726 11.5848 8.80413 11.504 9.0004 11.5C9.19666 11.504 9.38353 11.5848 9.52091 11.725C9.6583 11.8652 9.73524 12.0537 9.73524 12.25C9.73524 12.4463 9.6583 12.6348 9.52091 12.775C9.38353 12.9152 9.19666 12.996 9.0004 13Z"
        fill="white"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_71_97845">
        <Rect width="14" height="14" fill="white" transform="translate(2 2)" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default StopPublic;
