import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import TableDevelopmentStyled from './styles';
import * as Types from 'types';
import { useSelector } from 'react-redux';
import { reportSaleInformationSelector } from 'pages/Report/SaleInformation/selectors';
import { get, uniq } from 'lodash';
import dayjs from 'dayjs';
import { formatComma } from 'libs/utils/format';
import { groupSaleReportBreakdownData } from 'libs/utils/report';
import { SALE_TYPE, USER_TYPE } from 'constant/report';
import { AnyObject } from 'types';
import { ColumnDef } from '@tanstack/react-table';
import VirtualizedTable from 'components/VirtualizedTable';

interface Props {
  visibleExpansionOfSale: boolean;
  visibleCountUsage: boolean;
  data: Array<Types.SaleReportInformation.SaleReportInformation>;
}

const DEFAULT_COLUMNS: ColumnDef<AnyObject> = {
  header: 'ユーザー種類　/　ユーザー名・ID',
  accessorKey: 'name',
  size: 350,
  cell: (cell) => {
    const rowData = cell.row.original;
    return (
      <>
        <span className="company-level-space"></span>
        <div className="company-detail">
          <div className={`company-name ${rowData.companyClassName || ''}`}>{rowData.name}</div>
          {rowData.code && <div className="company-id">{rowData.code}</div>}
        </div>
      </>
    );
  },
};
const TableMonthlyByUser: React.FC<Props> = ({ visibleExpansionOfSale, visibleCountUsage }) => {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const {
    reportSaleInformationBreakdownList: data,
    reportSaleInformationBreakdownDetailList,
    dateRanger,
    userType,
    saleType,
    filterUser,
  } = useSelector(reportSaleInformationSelector);

  useEffect(() => {
    setCurrent(1);
  }, [visibleCountUsage, visibleExpansionOfSale]);

  const filterUserTypes = useMemo(() => {
    if (!userType || userType === USER_TYPE.ALL) {
      return Object.values(USER_TYPE);
    }
    return Object.values(USER_TYPE).filter((user) => user === userType);
  }, [userType]);

  const dates = useMemo(
    () =>
      dateRanger && dateRanger.length ? dateRanger : uniq(data.map((obj) => obj.target_month)),
    [data, dateRanger]
  );

  const columns = useMemo<ColumnDef<AnyObject>[]>(() => {
    const dateColumns: ColumnDef<AnyObject>[] = dates.map((item, index) => ({
      header: dayjs(item, 'YYYYMM').format('YYYY/MM'),
      accessorKey: item,
      size: 100,
      className: 'text-center',
      cell: (cell) => {
        const rowData = cell.row.original;
        return rowData[item] ? formatComma(rowData[item]) : '-';
      },
    }));
    return [
      {
        ...DEFAULT_COLUMNS,
        header: !visibleExpansionOfSale
          ? 'ユーザー種類　/　ユーザー名・ID'
          : visibleExpansionOfSale && !visibleCountUsage
          ? 'ユーザー種類　/　ユーザー名・ID　/　売上種類'
          : 'ユーザー種類　/　ユーザー名・ID　/　売上種類　/　件数',
      },
      ...dateColumns,
    ];
  }, [data, dates, visibleExpansionOfSale, visibleCountUsage]);

  const groupData = useMemo(
    () => groupSaleReportBreakdownData(data, reportSaleInformationBreakdownDetailList, dates),
    [data, dates, reportSaleInformationBreakdownDetailList]
  );

  const getCompaniesData = (userType: string, companyData: AnyObject) => {
    const filterSaleType = saleType || SALE_TYPE.ALL;
    const showUserFee = [SALE_TYPE.ALL, SALE_TYPE.USER_FEE].includes(filterSaleType);
    const showSkillCheckFee = [SALE_TYPE.ALL, SALE_TYPE.SKILL_CHECK_USAGE_FEE].includes(
      filterSaleType
    );
    const showSkillStorageFee =
      [SALE_TYPE.ALL, SALE_TYPE.STORAGE_USAGE_FEE].includes(filterSaleType) &&
      userType === USER_TYPE.CORPORATE;

    const rowTotal = {
      _virtualRowData: { className: 'row-total' },
      name: userType,
      ...companyData.total[SALE_TYPE.ALL].data,
      children: [],
    };

    const children: AnyObject[] = [];

    if (showUserFee) {
      if (visibleExpansionOfSale) {
        children.push({
          _virtualRowData: {
            className: showSkillCheckFee ? 'row-total-child' : 'row-total-child-last',
          },
          name: SALE_TYPE.USER_FEE,
          ...companyData.total[SALE_TYPE.USER_FEE].data,
        });
      }
      if (visibleCountUsage) {
        children.push({
          _virtualRowData: {
            className: showSkillCheckFee ? 'row-total-child-detail' : 'row-total-child-detail-last',
          },
          name: 'ユーザー数',
          ...companyData.total[SALE_TYPE.USER_FEE].child,
        });
      }
    }
    if (showSkillCheckFee) {
      if (visibleExpansionOfSale) {
        children.push({
          _virtualRowData: {
            className: showSkillStorageFee ? 'row-total-child' : 'row-total-child-last',
          },
          name: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
          ...companyData.total[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
        });
      }
      if (visibleCountUsage) {
        children.push({
          _virtualRowData: {
            className: showSkillStorageFee
              ? 'row-total-child-detail'
              : 'row-total-child-detail-last',
          },
          name: 'スキルチェック利用数',
          ...companyData.total[SALE_TYPE.SKILL_CHECK_USAGE_FEE].child,
        });
      }
    }
    if (showSkillStorageFee) {
      if (visibleExpansionOfSale) {
        children.push({
          _virtualRowData: { className: 'row-total-child-last' },
          name: SALE_TYPE.STORAGE_USAGE_FEE,
          ...companyData.total[SALE_TYPE.STORAGE_USAGE_FEE].data,
        });
      }
      if (visibleCountUsage) {
        children.push({
          _virtualRowData: { className: 'row-total-child-detail-last' },
          name: 'ストレージ利用量',
          ...companyData.total[SALE_TYPE.STORAGE_USAGE_FEE].child,
        });
      }
    }

    if (companyData.items) {
      Object.keys(companyData.items).forEach((companyId) => {
        const company = companyData.items[companyId];
        if (company && (!filterUser || (company.name || '').includes(filterUser))) {
          children.push({
            code: company.code,
            name: company.name,
            ...company[SALE_TYPE.ALL].data,
          });
          if (showUserFee) {
            if (visibleExpansionOfSale) {
              children.push({
                _virtualRowData: {
                  className: showSkillCheckFee ? 'company-row-child' : 'company-row-child-last',
                },
                name: SALE_TYPE.USER_FEE,
                ...company[SALE_TYPE.USER_FEE].data,
              });
            }
            if (visibleCountUsage) {
              children.push({
                _virtualRowData: {
                  className: showSkillCheckFee
                    ? 'company-row-child-detail'
                    : 'company-row-child-detail-last',
                },
                name: 'ユーザー数',
                ...company[SALE_TYPE.USER_FEE].child,
              });
            }
          }
          if (showSkillCheckFee) {
            if (visibleExpansionOfSale) {
              children.push({
                _virtualRowData: {
                  className: showSkillStorageFee ? 'company-row-child' : 'company-row-child-last',
                },
                name: SALE_TYPE.SKILL_CHECK_USAGE_FEE,
                ...company[SALE_TYPE.SKILL_CHECK_USAGE_FEE].data,
              });
            }
            if (visibleCountUsage) {
              children.push({
                _virtualRowData: {
                  className: showSkillStorageFee
                    ? 'company-row-child-detail'
                    : 'company-row-child-detail-last',
                },
                name: 'スキルチェック利用数',
                ...company[SALE_TYPE.SKILL_CHECK_USAGE_FEE].child,
              });
            }
          }
          if (showSkillStorageFee) {
            if (visibleExpansionOfSale) {
              children.push({
                _virtualRowData: { className: 'company-row-child-last' },
                name: SALE_TYPE.STORAGE_USAGE_FEE,
                ...company[SALE_TYPE.STORAGE_USAGE_FEE].data,
              });
            }
            if (visibleCountUsage) {
              children.push({
                _virtualRowData: { className: 'company-row-child-detail-last' },
                name: 'ストレージ利用量',
                ...company[SALE_TYPE.STORAGE_USAGE_FEE].child,
              });
            }
          }
        }
      });
    }
    return [{ ...rowTotal, children }];
  };

  const dataTable = useMemo(() => {
    let res: AnyObject[] = [];

    if (filterUserTypes.includes(USER_TYPE.ALL)) {
      res = [
        {
          name: USER_TYPE.ALL,
          _virtualRowData: { className: 'total-all' },
          ...groupData[USER_TYPE.ALL][SALE_TYPE.ALL],
        },
      ];
    }

    if (filterUserTypes.includes(USER_TYPE.CORPORATE)) {
      res = [...res, ...getCompaniesData(USER_TYPE.CORPORATE, groupData[USER_TYPE.CORPORATE])];
    }

    if (filterUserTypes.includes(USER_TYPE.PERSONAL)) {
      res = [...res, ...getCompaniesData(USER_TYPE.PERSONAL, groupData[USER_TYPE.PERSONAL])];
    }

    return res;
  }, [groupData, visibleExpansionOfSale, visibleCountUsage, filterUserTypes, saleType, filterUser]);

  return (
    <TableDevelopmentStyled
      visibleExpansionOfSale={visibleExpansionOfSale}
      visibleCountUsage={visibleCountUsage}
    >
      <VirtualizedTable data={dataTable} columns={columns} />
    </TableDevelopmentStyled>
  );
};

export default TableMonthlyByUser;
