import styled from 'styled-components';

export default styled.div`
  .select-perpage {
    display: flex;
    margin-left: 60px;
    .label {
      display: flex;
      align-items: center;
      span {
        font-size: 11px;
        font-family: Noto Sans Javanese;
      }
    }
    .ant-select-selection-item {
      font-size: 11px;
      font-family: Noto Sans Javanese;
    }
    .ant-select {
      width: 64px;
    }
  }
`;
