import React from 'react';

import GraphNonExpansion from './GraphNonExpansion';
import GraphExpansion from './GraphExpansion';
import Development from './Development';
import * as Types from 'types';
import styled from 'styled-components';

interface Props {
  visibleExpansionOfSale: boolean;
  visibleCountUsage: boolean;
  data: Array<Types.SaleReportInformation.SaleReportInformation>;
  exportPdf?: boolean;
}

const Chart: React.FC<Props> = ({ visibleExpansionOfSale, visibleCountUsage, data, exportPdf }) => {
  return visibleExpansionOfSale && visibleCountUsage ? (
    <Development data={data} exportPdf={exportPdf} />
  ) : visibleExpansionOfSale ? (
    <GraphExpansion data={data} exportPdf={exportPdf} />
  ) : (
    <GraphNonExpansion data={data} exportPdf={exportPdf} />
  );
};

export default Chart;
