import React, { memo, useEffect, useMemo } from 'react';
import { EditFilled } from '@ant-design/icons';
import { Column, G2 } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { settingSelector } from 'containers/AppSettings/selectors';
import { formatNumberNoDecimal } from 'libs/numberFormatter';
import { dashboardSelector } from '../selectors';
import { useAppDispatch } from 'hooks';
import Styled from './styles';
import { getDataAdminDashbordSalesInformation } from '../thunk';

const SkillCheckImplement: React.FC<{ isRenderPdf?: boolean }> = ({ isRenderPdf }) => {
  const { loading } = useSelector(settingSelector);
  const dispatch = useAppDispatch();

  const G = G2.getEngine('canvas');

  const { dataNumOfImplementations } = useSelector(dashboardSelector);

  const { numOfImplementations, numOfInternalImplementations, numOfInterviewsConducted } =
    useMemo(() => {
      const numOfImplementations = dataNumOfImplementations.find(
        ({ implementation, type }) =>
          implementation === dayjs().format('YYYYMM') && type === '実施合計'
      );
      const numOfInternalImplementations = dataNumOfImplementations.find(
        ({ implementation, type }) => implementation === dayjs().format('YYYYMM') && type === '社内'
      );
      const numOfInterviewsConducted = dataNumOfImplementations.find(
        ({ implementation, type }) => implementation === dayjs().format('YYYYMM') && type === '面接'
      );
      return {
        numOfImplementations: numOfImplementations?.value ?? 0,
        numOfInternalImplementations: numOfInternalImplementations?.value ?? 0,
        numOfInterviewsConducted: numOfInterviewsConducted?.value ?? 0,
      };
    }, [dataNumOfImplementations]);

  const config = {
    data: dataNumOfImplementations,
    isGroup: true,
    xField: 'implementation',
    yField: 'value',
    seriesField: 'type',
  };

  useEffect(() => {
    if (isRenderPdf) return;
    dispatch(
      getDataAdminDashbordSalesInformation({
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, isRenderPdf]);

  return (
    <Styled>
      <div className="user-info">
        <div className="wrap-icon">
          <EditFilled className="icon" /> スキルチェック実施情報
        </div>
        <div className="body-info">
          <div className="left-side">
            <span className="title">リアルタイム実施件数</span>
            <div className="info">
              <div className="body-detail">
                <span className="title-info">総実施回数</span>
                <div className="wrap-number">
                  <span className="number">{formatNumberNoDecimal(numOfImplementations)}</span>
                  <span className="unit">回</span>
                </div>
              </div>
              <div className="body-detail">
                <span className="title-info">社内実施回数</span>
                <div className="wrap-number">
                  <span className="number">
                    {formatNumberNoDecimal(numOfInternalImplementations)}
                  </span>
                  <span className="unit">回</span>
                </div>
              </div>
              <div className="body-detail">
                <span className="title-info">面接実施回数</span>
                <div className="wrap-number">
                  <span className="number">{formatNumberNoDecimal(numOfInterviewsConducted)}</span>
                  <span className="unit"> 回</span>
                </div>
              </div>
            </div>
          </div>
          <div className="column" />
          <div className="right-side">
            <div className="wrap-header">
              <span className="title">実施件数推移</span>
            </div>
            <div className="chart">
              <p className="unit">単位：回</p>
              <Column
                className="column-chart"
                {...config}
                legend={{
                  position: 'top-right',
                  offsetY: -15,
                  offsetX: 30,
                  itemHeight: 30,
                  itemSpacing: -10,
                  itemName: {
                    style: {
                      fill: '#424242',
                      fontSize: 12,
                      fontWeight: 400,
                      fontFamily: 'Noto Sans Javanese',
                    },
                  },
                  pageNavigator: {
                    marker: {
                      style: {
                        fill: 'red',
                        opacity: 1,
                      },
                    },
                  },
                  marker: () => {
                    return {
                      symbol: 'square',
                      style(style) {
                        return {
                          ...style,
                          r: 7,
                          y: 16,
                        };
                      },
                    };
                  },
                  radio: {
                    style: {
                      fill: '#ffffff',
                      stroke: '#ffffff',
                    },
                  },
                }}
                interactions={[{ type: 'legend-active', enable: false }]}
                yAxis={{
                  grid: {
                    line: {
                      style: (_item, index, items) => {
                        const midIndex = Math.floor(items.length / 2);
                        if (midIndex === index) {
                          return {
                            stroke: '#ddd',
                          };
                        }
                        return {
                          lineDash: [4, 4],
                          stroke: '#ddd',
                        };
                      },
                    },
                  },
                  label: {
                    formatter: (text: string) => formatNumberNoDecimal(Number(text)),
                  },
                  position: 'left',
                  tickCount: 7,
                  maxTickCount: 10,
                }}
                xAxis={{
                  tickLine: null,
                  label: {
                    formatter: (text: string) => dayjs(text).format('YYYY/MM'),
                  },
                }}
                maxColumnWidth={15}
                dodgePadding={4}
                color={(datum) => {
                  return datum.type === '社内'
                    ? '#4E83D3'
                    : datum.type === '面接'
                    ? '#F08B84'
                    : '#C38BC2';
                }}
                label={{
                  position: 'top',
                  content: (v) => {
                    const group = new G.Group({});
                    group.addShape({
                      type: 'text',
                      attrs: {
                        x: 0,
                        y: -10,
                        text: formatNumberNoDecimal(Number(v?.value || 0)),
                        textAlign: 'left',
                        textBaseline: 'top',
                        fill:
                          v?.type === '社内'
                            ? '#4E83D3'
                            : v?.type === '面接'
                            ? '#F08B84'
                            : '#C38BC2',
                      },
                    });
                    return group;
                  },
                }}
                animation={!loading}
              />
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default memo(SkillCheckImplement);
