import styled from 'styled-components';

export default styled.section`
  margin-bottom: 45px;
  .ant-select-item-option-content {
    flex: none !important;
  }
  .ant-select-selection-item {
    text-align: left !important;
  }
  .body-info {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    display: flex;
    .title {
      font-size: 16px;
      color: #2a2a2a;
    }

    .right-side {
      padding: 20px;
      width: 100%;
      .wrap-header {
        .wrap-action {
          display: flex;
          width: 100%;
          justify-content: end;
        }
        .wrap-select {
          display: flex;
          align-items: center;
          width: 20%;
          justify-content: end;
          .ant-select-selector {
            height: 36px;
          }
          .ant-select {
            width: 70%;
          }
          label {
            color: #777777;
            font-size: 12px;
            width: 110px;
          }
        }
        .legend {
          display: flex;
          align-items: center;

          .sort-column {
            display: flex;
            align-items: center;
            padding: 0 10px;
            margin-left: 20px;
            .legend-filter {
              width: 14px;
              height: 14px;
              display: flex;
              align-items: center;
            }
            .blue {
              position: relative;
              .circle {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #08a3a5;
              }
              .line {
                width: 20px;
                height: 2px;
                background: #08a3a5;
                position: absolute;
                top: 45%;
                right: 10%;
              }
            }
            .yellow {
              background: #f6ac00;
            }

            .legend-name {
              font-size: 12px;
              color: #424242;
              margin: 0 5px;
            }
          }
        }
        .checkbox {
          display: flex;
          height: 36px;
          align-items: center;
          padding: 0 8px;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 3px;
          .text {
            padding-left: 12px;
          }
        }
      }
      .chart {
        width: 98%;
        margin-top: 20px;
        .line,
        .column {
          position: relative;
          .unit {
            position: absolute;
            right: 0px;
            top: -30px;
            font-size: 11px;
            color: #999999;
          }
          .line-chart {
            // height: 20vh !important;
            margin-bottom: 25px;
          }
        }
        .column-chart {
          margin-top: 25px;
        }
      }
    }
  }
`;
