import styled from 'styled-components';

interface Props {
  visibleExpansionOfSale: boolean;
  visibleCountUsage: boolean;
}

export default styled.div<Props>`
  position: relative;

  .virtual-table {
    .virtual-table-head {
      .virtual-table-header-cell {
        :first-child {
          text-align: left;
        }
      }
    }
    .virtual-table-body {
      .total-all {
        background-color: #f4fbfb;
        .virtual-table-cell {
          background: #f4fbfb !important;
        }
      }
      .row-total {
        border-bottom: ${(props) =>
          !props.visibleExpansionOfSale
            ? '1px solid #dddddd !important'
            : '1px dashed #dddddd !important'};
        .virtual-table-cell {
          background: #f9f9f9 !important;
        }
      }
      .row-total-child {
        .virtual-table-cell {
          background: ${(props) => (!props.visibleCountUsage ? '#f9f9f9' : '#FFFFFF')} !important;
          .level-space {
            padding-left: 80px !important;
            background: ${(props) => (!props.visibleCountUsage ? '#f9f9f9' : '#FFFFFF')} !important;
          }
        }
      }
      .row-total-child-last {
        border-bottom: ${(props) =>
          props.visibleCountUsage ? 'none' : '1px solid #dddddd'}!important;
        .virtual-table-cell {
          background: ${(props) => (!props.visibleCountUsage ? '#f9f9f9' : '#FFFFFF')} !important;
          :not(:first-child) {
            border-bottom: ${(props) =>
              !props.visibleCountUsage ? 'none' : '1px dashed #dddddd'}!important;
          }
          .level-space {
            padding-left: 80px !important;
            background: ${(props) => (!props.visibleCountUsage ? '#f9f9f9' : '#FFFFFF')} !important;
          }
          .virtual-table-first-cell-content {
            border-bottom: ${(props) =>
              !props.visibleCountUsage ? 'none' : '1px dashed #dddddd'}!important;
          }
        }
      }
      .row-total-child-detail {
        .virtual-table-cell {
          background: #f9f9f9 !important;
          :not(:first-child) {
            border-bottom: 1px solid #dddddd !important;
          }
          .level-space {
            padding-left: 80px !important;
            background: ${(props) => (!props.visibleCountUsage ? '#f9f9f9' : '#FFFFFF')} !important;
          }
          .virtual-table-first-cell-content {
            border-bottom: 1px solid #dddddd !important;
          }
        }
      }
      .row-total-child-detail-last {
        .virtual-table-cell {
          background: #f9f9f9 !important;
          border-bottom: 1px solid #dddddd !important;
          .level-space {
            padding-left: 80px !important;
            background: ${(props) => (!props.visibleCountUsage ? '#f9f9f9' : '#FFFFFF')} !important;
          }
          .virtual-table-first-cell-content {
            border-bottom: none;
          }
        }
      }
      .last-child-company-row {
        .virtual-table-cell {
          :not(:first-child) {
            border-bottom: 1px solid #dddddd !important;
          }
          .virtual-table-first-cell-content {
            border-bottom: 1px solid #dddddd !important;
          }
        }
      }
      .company-row-child {
        .virtual-table-first-cell-content {
          padding: 0 !important;
          display: flex;
          border-bottom: none !important;
          .company-level-space {
            padding-left: 63px;
            border-right: 1px solid #dddddd !important;
          }
          .company-detail {
            padding: 10px 14px;
            border-bottom: 1px dashed #dddddd !important;
          }
        }
      }
      .company-row-child-last {
        .virtual-table-cell {
          :not(:first-child) {
            border-bottom: ${(props) =>
              !props.visibleCountUsage ? '1px solid #dddddd' : '1px dashed #dddddd'}!important;
          }
        }
        .virtual-table-first-cell-content {
          padding: 0 !important;
          display: flex;
          border-bottom: none !important;
          .company-level-space {
            padding-left: 63px;
            border-right: 1px solid #dddddd !important;
            border-bottom: ${(props) =>
              !props.visibleCountUsage ? '1px solid #dddddd' : 'none'}!important;
          }
          .company-detail {
            padding: 10px 14px;
            border-bottom: ${(props) =>
              !props.visibleCountUsage ? '1px solid #dddddd' : '1px dashed #dddddd'}!important;
          }
        }
      }
      .company-row-child-detail {
        .virtual-table-cell {
          :not(:first-child) {
            background: #f9f9f9 !important;
            border-bottom: 1px solid #dddddd !important;
          }
        }
        .virtual-table-first-cell-content {
          padding: 0 !important;
          display: flex;
          border-bottom: none !important;
          .company-level-space {
            padding-left: 63px;
            border-right: 1px solid #dddddd !important;
          }
          .company-detail {
            padding: 10px 14px;
            border-bottom: 1px solid #dddddd !important;
            background: #f9f9f9 !important;
          }
        }
      }
      .company-row-child-detail-last {
        .virtual-table-cell {
          :not(:first-child) {
            background: #f9f9f9 !important;
            border-bottom: 1px solid #dddddd !important;
          }
        }
        .virtual-table-first-cell-content {
          padding: 0 !important;
          display: flex;
          border-bottom: none !important;
          .company-level-space {
            padding-left: 63px;
            border-right: 1px solid #dddddd !important;
            border-bottom: 1px solid #dddddd !important;
          }
          .company-detail {
            padding: 10px 14px;
            background: #f9f9f9 !important;
            border-bottom: 1px solid #dddddd !important;
          }
        }
      }
      .company-level-space {
        height: 100%;
      }
      .virtual-table-row {
        .virtual-table-cell {
          :not(:first-child) {
            text-align: right;
          }

          .company-detail {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            .company-id {
              font-size: 11px;
              color: #999999;
            }
            .company-name {
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
`;
