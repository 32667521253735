import styled from 'styled-components';

interface Props {
  visibleExpansionOfSale: boolean;
}

export default styled.section<Props>`
  position: relative;
  .text-count {
    position: absolute;
    top: -30px;
    right: 0;
  }
  .title {
    display: flex;
    justify-content: space-between;
    padding: 0 50px 0 20px;
    .code {
      padding-left: 30px;
    }
  }
  .item-name {
    display: flex;
    justify-content: space-around;
    .code-number {
      font-size: 11px;
      color: #999999;
    }
  }
  .virtual-table {
    .virtual-table-head {
      .virtual-table-header-cell {
        :first-child {
          text-align: left;
        }
      }
    }

    .virtual-table-body {
      .total-all {
        background-color: #f4fbfb;
        .virtual-table-cell {
          background: #f4fbfb !important;
        }
      }
      .virtual-table-row {
        .virtual-table-cell {
          :not(:first-child) {
            text-align: right;
          }

          .company-detail {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            .company-id {
              font-size: 11px;
              color: #999999;
            }
            .company-name {
              //text-align: right;
              font-size: 12px;
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
`;
