import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';

import styles from './styles';
import _ from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import { DownIconPdf } from 'assets/svg/svgPDF';
import dayjs from 'dayjs';
import * as Types from 'types';
import { StopPublicPdf } from 'assets/svgPDF';
import SelectBox from 'components/ReactPDF/SelectBox';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface Props {
  formValue: Types.ArchiveListSearchFormik;
  dataSource: Types.OfficialCurriculumPublishingSettings.ResponseType[];
  official_curriculum_code?: string;
  name?: string;
  publish?: string;
  createdby?: string;
}

const FIRST_PAGE_ROW_COUNT = 12;
const PAGE_ROW_COUNT = 17;

const ArchiveTable = ({
  dataSource,
}: {
  dataSource: Types.OfficialCurriculumPublishingSettings.ResponseType[];
}) => {
  const columns: { title: string | string[]; width: string | number; key: string }[] = [
    {
      title: 'ID',
      width: 50,
      key: 'id',
    },
    {
      title: 'OFFICIALカリキュラム名',
      width: 400,
      key: 'name',
    },
    {
      title: '公開ステータス',
      width: 80,
      key: 'public',
    },
    {
      title: '前回公開日',
      width: 60,
      key: 'publish_start_date',
    },
    {
      title: '最終更新日',
      width: 60,
      key: 'updatedat',
    },
    {
      title: '作成者',
      width: 120,
      key: 'creator',
    },
  ];

  const formatDateData = (val: Date | undefined) => {
    if (val) {
      return dayjs(val).format('YYYY/MM/DD');
    }
    return '-';
  };

  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow, { height: '26.728px' }]}>
        {columns.map((column, index) => (
          <View key={index} style={[styles.wrapCell, { width: column.width }]}>
            <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
          </View>
        ))}
      </View>

      {dataSource.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5',
            },
          ]}
        >
          <View style={[styles.wrapCell, { width: columns[0].width }]}>
            <Text style={styles.tableCell}>{row.official_curriculum_code}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[1].width }]}>
            <Text style={styles.tableCell}>{row.name}</Text>
          </View>
          <View
            style={[
              styles.wrapCell,
              {
                width: columns[2].width,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              },
            ]}
          >
            <View>
              <StopPublicPdf style={{ transform: 'scale(0.6)' }} />
            </View>
            <Text style={styles.tableCell}>公開停止中</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[3].width }]}>
            <Text style={styles.tableCell}>{formatDateData(row.publish_start_date)}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[4].width }]}>
            <Text style={styles.tableCell}>
              {formatDateData(new Date(row.updated_at ? row.updated_at : row.updatedat))}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[5].width }]}>
            <Text style={styles.tableCell}>{row.creator}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const PDFArchiveList = (props: Props) => {
  const { dataSource, formValue, official_curriculum_code, name, publish, createdby } = props;
  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap>
        <FullPage>
          <View style={styles.header}>
            <Text style={styles.textHeader}>アーカイブリスト</Text>
          </View>
          <View style={styles.subscription}>
            <View>
              <Text style={styles.textSubscription}>
                アーカイブしたOFFICIALカリキュラムの管理を行う画面です。
              </Text>
            </View>
          </View>
          <View style={styles.divider}></View>
          <View style={styles.searchPanel}>
            <View style={styles.searchItem}>
              <Text style={styles.label}>OFFICIALカリキュラムID</Text>
              <SelectBox
                placeholder="指定なし"
                value={formValue.official_curriculum_code}
                selectBoxStyle={{ width: '201' }}
              />
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>OFFICIALカリキュラム</Text>
              <SelectBox
                placeholder="指定なし"
                value={formValue.name}
                selectBoxStyle={{ width: '201' }}
              />
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>作成者</Text>
              <SelectBox
                placeholder="指定なし"
                value={formValue.createdby}
                selectBoxStyle={{ width: '201' }}
              />
            </View>
          </View>
          <View style={styles.body}>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>1</Text>
              <Text style={styles.textCountNumber}>-</Text>
              <Text style={styles.textCountNumber}>{dataSource.length}</Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{dataSource.length}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
            <ArchiveTable dataSource={dataSource.slice(0, FIRST_PAGE_ROW_COUNT)} />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {dataSource.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(dataSource.slice(FIRST_PAGE_ROW_COUNT, dataSource.length), PAGE_ROW_COUNT).map(
          (page, index) => (
            <Page
              key={index}
              size={pageSize}
              orientation={pageOrientation}
              style={[styles.page, styles.pagePadding]}
              wrap={false}
            >
              <FullPage>
                <ArchiveTable dataSource={page} />
              </FullPage>
              <PageNumber />
            </Page>
          )
        )}
    </Document>
  );
};

export default PDFArchiveList;
