import styled from 'styled-components';

export default styled.section`
  @media (max-width: 1440px) {
    .dual-chart {
      height: 383px !important;
    }
  }
  margin-bottom: 45px;
  .wrap-icon {
    display: flex;
    align-items: center;
    color: #08a3a5;
    font-size: 16px;
    margin-bottom: 14px;
    .icon {
      font-size: 22px;
      margin-right: 10px;
    }
  }
  .body-info {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    .title {
      font-size: 16px;
      color: #2a2a2a;
    }
    .info {
      width: 100%;
      margin-top: 40px;
    }
    .top-info {
      display: flex;
    }
    .wrap-column-1 {
      width: 25%;
      .number {
        font-size: 36px;
      }
    }
    .wrap-column-2 {
      width: 37%;
      .content-column-2 {
        display: flex;
      }
      .title-column-2 {
        font-size: 14px;
        color: #424242;
        margin-bottom: 39px;
      }
      .left-side-2 {
        width: 50%;
      }
      .right-side-2 {
        width: 50%;
      }
      .divide {
        height: 50%;
        width: 1px;
        background: #e0e0e0;
        align-self: center;
        margin: 0 30px;
      }
      .number {
        font-size: 20px;
      }
    }
    .wrap-column-3 {
      width: 21%;
      display: flex;
      flex-direction: column;
      .top-detail {
        border-bottom: 1px solid #e0e0e0;

        .number {
          font-size: 28px;
        }
      }
    }
    .wrap-column-4 {
      width: 17%;
      border-right: none !important;
      justify-content: flex-start !important;
      row-gap: 20%;
      .wrap-number {
        text-align: center !important;
      }
      .number {
        font-size: 36px;
      }
    }
    .top-detail {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #ffffff;
      padding: 20px 20px 20px 20px;
      border-right: 1px solid #cccccc;

      .note-title {
        font-size: 11px;
        color: #777777;
      }
      .wrap-bottom-detail {
        display: flex;
        justify-content: space-between;
        .label {
          font-size: 13px;
          color: #777777;
        }
        .percent {
          color: #08a3a5;
          font-size: 20px;
        }
      }
      .line {
        width: 100%;
        height: 1px;
        background: #eeeeee;
        margin: 20px 0;
      }
      .title-info {
        color: #424242;
        font-size: 14px;
      }
      .number {
        color: #08a3a5;
      }
      .unit {
        color: #08a3a5;
      }
      .wrap-number {
        text-align: right;
      }
    }
    .column {
      width: 100%;
      height: 4px;
      background: #efefef;
      border: 1px solid #e8e8e8;
    }
    .right-side {
      padding: 20px;
      position: relative;
      .wrap-header {
        width: 62%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: 50px;
        left: 20px;
        z-index: 1;
        .wrap-action {
          display: flex;
          width: 40%;
          margin-right: 145px;
        }
        .wrap-select {
          display: flex;
          align-items: center;
          width: 100%;
          .ant-select-selector {
            height: 36px;
          }
          .ant-select {
            width: 78%;
          }
          label {
            color: #777777;
            font-size: 12px;
          }
        }
        .checkbox {
          display: flex;
          height: 36px;
          align-items: center;
          padding: 0 8px;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 3px;
          .text {
            padding-left: 12px;
          }
        }
      }
      .chart {
        width: 98%;
        margin-top: 40px;
        position: relative;
        .unit {
          position: absolute;
          right: 40px;
          top: 70px;
          font-size: 11px;
          color: #999999;
        }
        .line-chart {
          height: 20vh !important;
        }
      }
    }
  }
  @media (max-width: 1440px) {
    .body-info {
      .right-side {
        .wrap-header {
          width: 55%;
          .wrap-action {
            width: 52%;
          }
        }
        .chart {
          .unit {
            top: 70px;
          }
        }
      }
    }
  }
`;
