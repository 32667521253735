import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Button, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

import { billingDataManagementSelector } from 'pages/BillingDataManagement/selectors';
import { HEADER_BILLING_DATA_DETAIL } from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { resetInitialState } from 'pages/BillingDataManagement/slice';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { Modal, PagingNumber, SelectField } from 'components';
import ActionErrorModal from 'components/Modal/ActionError';
import CompletedModal from 'components/Modal/Completed';
import PerPageSelect from 'components/PerPageSelect';
import { loadingRef } from 'components/Loading';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  getBillingDataDetailExport,
  getBillingDataDetail,
  getSelectBillingDataDetail,
} from 'pages/BillingDataManagement/thunk';
import { SELECT_RECORD } from 'constant/select.constants';
import { exportCsv } from 'libs/utils/exportCsv';

interface Props {
  visible: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  onSubmit?: () => Promise<void> | void;
  dataRecord?: Types.BillingDataManagement.ResponseType;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Option } = Select;

const ModalAppBilling: React.FC<Props> = ({ visible, setVisible, dataRecord }) => {
  const [selectedRow, setSelectedRow] = useState<Types.BillingDataDetail.ResponseType[]>([]);
  const [openModalDeleteComplete, setOpenModalDeleteComplete] = useState<boolean>(false);
  const [visiblePopupConfirm, setVisiblePopupConfirm] = useState<boolean>(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const { dataSelectUserBillingUniq, dataSelectUserBilling, dataBillingDetail, loading, total } =
    useSelector(billingDataManagementSelector);

  const dispatch = useAppDispatch();

  const columns: ColumnsType<Types.BillingDataDetail.ResponseType> = [
    {
      title: () => {
        return (
          <div>
            役務提供年月・ 請求データ番号
            <br />
            請求データ名称
          </div>
        );
      },
      dataIndex: 'provided_date',
      className: 'name-left',
      key: 'provided_date',
      width: '30%',
      render: (text: string, item) =>
        text ? (
          <div className="item-date">
            <span className="date">{dayjs(text).format('YYYY/MM')}</span>{' '}
            <span className="date">{item.billing_id}</span>
            <br />
            <span className="item-billing">{item.billing_data_name}</span>
          </div>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: () => {
        return (
          <div>
            ユーザーID
            <br />
            ユーザー名
          </div>
        );
      },
      dataIndex: 'company_id',
      key: 'company_id',
      className: 'name-left',
      width: '17%',
      render: (text: string, item) =>
        text ? (
          <div className="item-date">
            <span className="date">{text}</span>
            <br />
            <span className="item-billing">{item.company_name}</span>
          </div>
        ) : (
          <span>-</span>
        ),
    },

    {
      title: '決済手段',
      dataIndex: 'payment_method',
      key: 'payment_method',
      width: '12%',
      render: () =>
        dataRecord?.payment_method ? (
          <span className="text-active">App内課金</span>
        ) : (
          <span>-</span>
        ),
    },

    {
      title: '注文日',
      dataIndex: 'order_date',
      key: 'order_date',
      width: '11%',
      render: (text: string) =>
        text ? (
          <span className="item-billing">{dayjs(text).format('YYYY/MM/DD')}</span>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: '請求日',
      dataIndex: 'billing_date',
      key: 'billing_date',
      width: '11%',
      render: (text: string) =>
        text ? (
          <span className="item-billing">{dayjs(text).format('YYYY/MM/DD')}</span>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: 'OS',
      dataIndex: 'os',
      key: 'os',
      width: '11%',
      render: (os: number) =>
        os === 1 ? (
          <span className="item-billing">iOS</span>
        ) : (
          <span className="item-billing">Android</span>
        ),
    },
    {
      title: '金額',
      dataIndex: 'num_of_accounts',
      key: 'num_of_accounts',
      width: '10%',
      className: 'number',
      align: 'right',
      render: (num_of_accounts: number) => (
        <span className="item-billing">
          {(Number(num_of_accounts || 0) * 200).toLocaleString()}
        </span>
      ),
    },
  ];

  const formik = useFormik({
    initialValues: {
      company_id: '',
      company_name: '',
      os: undefined,
    },
    onSubmit: async (values) => {
      dispatch(startLoading());
      const conditions: Array<Types.ConditionsType> = [];
      Object.keys(values).forEach((key) => {
        if (values[key as keyof typeof values]) {
          conditions.push({
            id: key,
            search_value: [String(values[key as keyof typeof values])],
            exact_match: true,
          });
        }
      });
      await dispatch(
        getBillingDataDetail({
          conditions: [
            {
              id: 'billing_id',
              search_value: [dataRecord?.billing_id],
              exact_match: true,
            },
            ...conditions,
          ],
          page: 1,
          per_page: 0,
          use_display_id: true,
          include_item_ref: true,
        })
      );
      setPage(1);
      dispatch(stopLoading());
    },
    onReset: () => {
      dispatch(
        getBillingDataDetail({
          conditions: [
            {
              id: 'billing_id',
              search_value: [dataRecord?.billing_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      setPage(1);
    },
  });

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      const resultAction = await dispatch(
        getBillingDataDetailExport({
          conditions: [
            {
              id: 'billing_id',
              search_value: [selectedRow.map((row) => row.billing_id).join('|')],
            },
          ],
          page: 1,
          per_page: 0,
          use_display_id: true,
          include_item_ref: true,
          use_or_condition: true,
        })
      );
      if (getBillingDataDetailExport.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          provided_date: item.provided_date,
          billing_detail_id: item.billing_detail_id,
          billing_data_name: item.billing_data_name,
          order_id: item.order_id,
          user_id: item.user_id,
          company_id: item.company_id,
          user_name: item.user_name,
          billing_type: item.billing_type,
          payment_result: item.payment_method,
          billing_date: item.billing_date,
          num_of_accounts: item.num_of_accounts,
          amount: item.amount,
          error_code: item.error_code,
          error_title: item.error_title,

          // i_id: item.i_id,
          // billing_id: item.billing_id,
          // company_name: item.company_name,
          // order_date: item.order_date,
          // os: item.os,
          // gmo_resend: item.gmo_resend,
        }));

        // const csvString = [
        //   HEADER_BILLING_DATA_DETAIL.map(({ label }) => label),
        //   ...listCsv.map((item) => Object.values(item)),
        // ]
        //   .map((e) => e.join(','))
        //   .join('\n');
        // const bom = '\uFEFF';
        // const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        // saveAs(file, '請求データ管理画面 ●未送信エラー全明細モーダル.csv');
        exportCsv(
          listCsv,
          HEADER_BILLING_DATA_DETAIL,
          '請求データ管理画面 ●未送信エラー全明細モーダル.csv'
        );
      }
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const handleToggleModal = () => {
    formik.resetForm();
    setVisible(false);
    dispatch(resetInitialState());
  };

  useEffect(() => {
    if (!visible) return;
    dispatch(startLoading());
    dispatch(
      getBillingDataDetail({
        conditions: [
          {
            id: 'billing_id',
            search_value: [dataRecord?.billing_id],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(
      getSelectBillingDataDetail({
        conditions: [
          {
            id: 'billing_id',
            search_value: [dataRecord?.billing_id],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
  }, [dataRecord, dispatch, visible]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerPage(dataBillingDetail.length);
    }
  }, [dataBillingDetail]);

  return (
    <Modal
      title="請求データ明細【個人・App内課金】"
      visible={visible}
      width={1300}
      onCancel={handleToggleModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
        padding: 0,
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled isEmptyData={!dataBillingDetail.length}>
        <div className="container">
          <FormikProvider value={formik}>
            <Form layout="vertical">
              <div className="form-search">
                <Form.Item
                  name="company_id"
                  className="item"
                  label={<span className="text-label">ユーザーID</span>}
                >
                  <SelectField
                    name="company_id"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectUserBillingUniq?.map(
                      (item, index) =>
                        item.company_id && (
                          <Option key={index} value={item.company_id}>
                            {item.company_id}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="company_name"
                  className="item"
                  label={<span className="text-label">ユーザー名</span>}
                >
                  <SelectField
                    name="company_name"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectUserBilling?.map(
                      (item, index) =>
                        item.company_name && (
                          <Option key={index} value={item.company_name}>
                            {item.company_name}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="os"
                  className="item"
                  label={<span className="text-label">OS</span>}
                >
                  <SelectField
                    name="os"
                    showSearch
                    allowClear
                    placeholder="指定なし"
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    <Option value={1}>iOS</Option>
                    <Option value={2}>Android</Option>
                  </SelectField>
                </Form.Item>
                <SubmitButton className="btn-search" loading={false}>
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <span className="label-reset" onClick={() => formik.resetForm()}>
                  リセット
                </span>
              </div>
            </Form>
          </FormikProvider>
          <Table
            rowKey="index"
            className="table"
            dataSource={dataBillingDetail.map((item, index) => ({ ...item, index }))}
            columns={columns}
            scroll={{ y: 400 }}
            rowSelection={{
              onChange: (_, selectedRows: Types.BillingDataDetail.ResponseType[]) =>
                setSelectedRow(selectedRows),
            }}
            pagination={{
              pageSize: 20,
              total: dataBillingDetail.length,
              current: page,
              onChange: setPage,
              showSizeChanger: false,
              position: ['topRight'],
              showTotal: () => (
                <div className="wrap-select-record">
                  <PagingNumber
                    startItem={dataBillingDetail.length ? `${(page - 1) * perPage + 1}` : ''}
                    endItem={
                      page * perPage > dataBillingDetail.length
                        ? dataBillingDetail.length
                        : page * perPage
                    }
                    totalItem={dataBillingDetail.length}
                  />
                  <PerPageSelect
                    data={dataBillingDetail}
                    perPage={perPage}
                    setPage={setPage}
                    setPerPage={setPerPage}
                  />
                </div>
              ),
            }}
          />
        </div>
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択した請求データを処理：
              <Button className="btn btn-active" onClick={handleButtonExport}>
                エクスポート
              </Button>
            </div>
            <Button className="btn btn-outline" onClick={handleToggleModal}>
              閉じる
            </Button>
          </div>
        </div>
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="ユーザーが選択されていません"
          description={
            <>
              エクスポートを実行する請求データを選択して、
              <br />
              再度実行してください。
            </>
          }
        />
        <ConfirmDeleteModal
          visible={visiblePopupConfirm}
          title="削除確認"
          subTitle="ユーザーの削除を実行します"
          description="データの削除を実行すると、復元できませんのでご注意ください。"
          onSubmit={() => setOpenModalDeleteComplete(true)}
          setVisible={setVisiblePopupConfirm}
        />
        <CompletedModal
          title="再送信が完了しました"
          visible={openModalDeleteComplete}
          setVisible={setOpenModalDeleteComplete}
          onSubmit={() => {}}
        />
      </SectionStyled>
    </Modal>
  );
};

export default ModalAppBilling;
