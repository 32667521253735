import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';

import { SectionStyled } from './styles';
import Modal from '..';

interface Props {
  visible: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  okButtonDisabled?: boolean;
  description?: React.ReactNode;
  onSubmit?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompletedDeleteModal: React.FC<Props> = ({
  title,
  visible,
  onCancel,
  onSubmit,
  setVisible,
}) => {
  const handleCloseModal = async () => {
    onCancel && (await onCancel());
    setVisible(false);
  };

  const handleSubmitModal = async () => {
    onSubmit && (await onSubmit());
    setVisible(false);
  };

  return (
    <Modal
      title={title ? <h3 className="title">{title}</h3> : null}
      open={visible}
      width={416}
      onCancel={handleCloseModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
        borderRadius: 5,
        overflow: 'hidden',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      okButton={{
        text: 'OK',
        onClick: handleSubmitModal,
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          <DeleteOutlined className="icon" />
          削除が完了しました
        </p>
      </SectionStyled>
    </Modal>
  );
};

export default CompletedDeleteModal;
