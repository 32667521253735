import React, { useState } from 'react';
import { Column, G2 } from '@ant-design/plots';
import { Select } from 'antd';

import Styled from './styles';
import { formatComma } from 'libs/utils/format';
import { SpinLoading } from 'components';
import { useSelector } from 'react-redux';
import { reportSelector } from 'pages/Report/selectors';
import { sortBy, uniq } from 'lodash';
import * as Types from 'types';
import {
  convertToFormattedDate,
  convertToFormattedDateForField,
  Group,
  tableObject,
} from '../Table';
const { Option } = Select;

interface Props {
  activeTable: boolean;
  openConfirmExport: boolean;
  data: tableObject;
}

interface ReportField {
  name: string;
  month: string;
  value: number;
}

const ChartOfficialCurriculum = (props: Props) => {
  const G = G2.getEngine('canvas');
  const { loading, dataOfficialCurriculumUserAggregation, listMonths } =
    useSelector(reportSelector);
  const [selectedValue, setSelectedValue] = useState('');

  const dataColumn = sortBy(
    dataOfficialCurriculumUserAggregation.map((item) => {
      return {
        name: item.company_type,
        month: convertToFormattedDate(item.target_month),
        value: props.activeTable ? item.active_number_of_usage : item.number_of_usage,
      };
    }),
    (i) => {
      return parseFloat(i.month);
    }
  ).reduce((acc: Array<ReportField>, item: ReportField) => {
    if (acc.length) {
      const checkMonth = acc.find((i) => i.month);
      if (checkMonth) {
        return [
          ...acc,
          {
            ...item,
            value: item.value + checkMonth.value,
          },
        ];
      } else {
        return [...acc, item];
      }
    } else {
      return [...acc, item];
    }
  }, []);

  let monthExist: string[] = [];
  const totalValue = [...props.data.RSTANDARD, ...props.data.パートナー企業];
  const caculator = totalValue
    .filter((i) => {
      const check = monthExist.includes(i.target_month);
      if (check) {
        return false;
      } else {
        monthExist = [...monthExist, i.target_month];
        return true;
      }
    })
    .flatMap((item) => {
      const check = totalValue.filter((i) => i.target_month === item.target_month);
      if (check.length) {
        return {
          ...item,
          company_type: '全企業',
          active_number_of_usage: check.reduce((acc, item) => acc + item.active_number_of_usage, 0),
          number_of_usage: check.reduce((acc, item) => acc + item.number_of_usage, 0),
        };
      } else {
        return item;
      }
    });

  const data: ReportField[] = [...props.data.RSTANDARD, ...props.data.RSTANDARD, ...caculator]
    .reduce(
      (
        acc: Types.OfficialCurriculumnAggregationData[],
        item: Types.OfficialCurriculumnAggregationData
      ) => {
        if (!acc.length) {
          return [...acc, item];
        } else {
          const check = acc.find(
            (i) => item.company_type === i.company_type && item.target_month === i.target_month
          );

          if (check) {
            return acc.map((i) => {
              if (check.company_type === i.company_type && check.target_month === i.target_month) {
                return {
                  ...i,
                  active_number_of_usage: i.active_number_of_usage + item.active_number_of_usage,
                  number_of_usage: i.number_of_usage + item.number_of_usage,
                };
              } else {
                return i;
              }
            });
          } else {
            return [...acc, item];
          }
        }
      },
      []
    )
    .map((item) => {
      return {
        name: item.company_type,
        month: convertToFormattedDate(item.target_month),
        value: props.activeTable ? item.active_number_of_usage : item.number_of_usage,
      };
    });

  const getDateOfDataColumn = uniq(
    dataColumn.map((item) => {
      return item.month;
    })
  );

  const newList = [...listMonths]
    .filter((item) => {
      const check = getDateOfDataColumn.every((i) => {
        return i !== item.title;
      });
      return !!check;
    })
    .map((item) => {
      return [
        {
          name: 'RSTANDARD',
          month: item.title,
          value: 0,
        },
        {
          name: 'パートナー企業',
          month: item.title,
          value: 0,
        },
        {
          name: '全企業',
          month: item.title,
          value: 0,
        },
      ];
    })
    .reduce((acc, item) => {
      return [...acc, ...item];
    }, []);

  const result = sortBy([...newList, ...data], (i) => {
    return parseFloat(convertToFormattedDateForField(i.month));
  });

  const dataConfig = sortBy(
    result.filter((i) => i.name.includes(selectedValue)),
    (i) => {
      return parseFloat(i.month.replace('/', ''));
    }
  );

  const config = {
    data: dataConfig,
    isGroup: true,
    xField: 'month',
    yField: 'value',
    seriesField: 'name',
    dodgePadding: 4,
    color: ['#4473B1', '#F0995A', '#C6DDA9'].filter((i) => {
      switch (selectedValue) {
        case 'RSTANDARD':
          return i === '#4473B1';
        case 'パートナー企業':
          return i === '#F0995A';
        case '全企業':
          return i === '#C6DDA9';
        default:
          return true;
      }
    }),
  };

  return (
    <SpinLoading loading={loading}>
      <Styled>
        <div className="user-info">
          <div className="body-info">
            <div className="right-side">
              <div className="wrap-header">
                <span className="title">OFFICIALカリキュラム利用数推移</span>
                <div className="wrap-action">
                  <div className="wrap-select">
                    <label>企業種類選択：</label>
                    <Select
                      defaultValue=""
                      onChange={(e) => {
                        setSelectedValue(e);
                      }}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    >
                      <Option value="">ALL</Option>
                      <Option value="RSTANDARD">RSTANDARD</Option>
                      <Option value="パートナー企業">パートナー企業</Option>
                      <Option value="全企業">全企業</Option>
                    </Select>
                  </div>
                  <div className="legend">
                    <div className="sort-column">
                      <div className="legend-filter blue" />
                      <span className="legend-name">RSTANDARD</span>
                    </div>
                    <div className="sort-column">
                      <div className="legend-filter yellow" />
                      <span className="legend-name">パートナー企業</span>
                    </div>
                    <div className="sort-column">
                      <div className="legend-filter green" />
                      <span className="legend-name">全企業</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chart">
                <span className="unit">単位：人</span>
                <Column
                  {...config}
                  legend={false}
                  height={props.openConfirmExport ? 600 : 335}
                  columnWidthRatio={0.4}
                  marginRatio={0.2}
                  yAxis={{
                    grid: {
                      line: {
                        style: {
                          lineDash: [2, 2],
                          stroke: '#ddd',
                        },
                      },
                    },
                    label: {
                      formatter: formatComma,
                    },
                  }}
                  columnStyle={{
                    width: 300,
                  }}
                  xAxis={{
                    tickLine: null,
                  }}
                  label={{
                    position: 'top',
                    content: (v, mappingData) => {
                      const group = new G.Group({});
                      group.addShape({
                        type: 'text',
                        attrs: {
                          x: 0,
                          y: 0,
                          text: formatComma(v.value),
                          textAlign: 'left',
                          textBaseline: 'top',
                          fill: mappingData.color,
                          shadowOffsetX: 1,
                          shadowOffsetY: 1,
                          shadowColor: '#FFFFFF',
                        },
                      });
                      return group;
                    },
                    style: {
                      shadowOffsetX: 1,
                      shadowOffsetY: 1,
                      shadowColor: '#FFFFFF',
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Styled>
    </SpinLoading>
  );
};

export default ChartOfficialCurriculum;
