import styled from 'styled-components';

export default styled.section`
  display: grid;
  place-items: center;
  @media (max-width: 1440px) {
    .content {
      margin: 0;
    }
  }
  .wrap-content {
    display: flex;
    margin-top: 12px;
    align-item: center;
  }
  .content {
    font-size: 15px;
    margin-left: 15px;
    margin-bottom: 0;
  }
  .icon {
    color: #bbbbbb;
    font-size: 28px;
  }
`;
