import styled from 'styled-components';

export const SectionStyled = styled.div`
  margin: 8px 16px;
  .text-content {
    font-size: 11px;
    color: #777777;
  }
  .icon {
    font-size: 40px;
    color: #ffffff;
    justify-content: center;
  }
  .ant-upload-text {
    font-size: 13px;
    text-align: center;
    color: #424242;
  }
  .form-upload {
    .form-upload-border {
      border: 2px solid #ffffff;
    }
    .ant-upload-list {
      display: none;
    }
    .file-upload {
      display: flex;
      flex-direction: column;
      min-height: 160px;
      border: 2px dashed #d9d9d9;
      background-color: #efefef;
      justify-content: center;
      padding: 20px 0;
      .info-file {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 15px 30px;
        padding: 10px;
        position: relative;
        background-color: #ffffff;
        .file-name {
          font-size: 13px;
          text-align: center;
          color: #424242;
          margin: 0;
        }
      }
    }
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-delete {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 20px;
    .icon-delete-outlined {
      color: #00a3a5;
      font-size: 15px;
      margin-right: 5px;
    }
    .label-delete {
      font-size: 12px;
      text-decoration: underline;
      color: #00989a;
    }
  }
  .btn-upload {
    background: #ffffff;
    border: 1px solid #00a3a5;
    box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00a3a5;
    padding: 6px 36px;
    cursor: pointer;
  }
  .text-successful {
    font-size: 16px;
    color: #424242;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      font-size: 28px;
      color: #08a3a5;
      margin-right: 10px;
    }
  }
  .note {
    margin-top: 12px;
    font-size: 11px;
    color: #777777;
  }
  .upload-image {
    .wrap-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .text-label {
        font-size: 13px;
        color: #424242;
        margin: 0;
        .text-value {
          color: #f0330a;
        }
        .border {
          color: rgba(204, 204, 204, 1);
          font-weight: 700;
        }
      }
      .btn-outline {
        background-color: #ffffff;
        border: 1px solid #00a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        font-weight: 700;
        font-size: 12px;
        color: #00a3a5;
      }
    }
    .body {
      height: 445px;
      background-color: #ffffff;
      border: 1px solid #cccccc;
      box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
      border-radius: 3px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      overflow-y: auto;
      padding: 12px;
      .item-image {
        width: 20%;
        padding: 4px;
        position: relative;
        .image {
          background: #e1dcdc;
          width: 100%;
          height: 100px;
          object-fit: contain;
        }
        .wrap-video {
          width: 100%;
          height: 100px;
          display: flex;
          justify-content: center;
          background: #e1dcdc;
        }
        .name {
          font-size: 11px;
          color: #424242;
          margin: 0;
          word-break: break-all;
        }
        .status {
          width: 17px;
          height: 17px;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          border-radius: 0 0 0 2px;
          top: 4px;
          right: 4px;
          z-index: 9;
          .icon-successful {
            color: #08a3a5;
          }
          .icon-warning {
            color: #f0330a;
          }
        }
      }
      .error {
        .name {
          font-weight: 700;
          color: #f0330a;
          .icon {
            font-size: 10px;
            font-weight: 700;
            color: #f0330a;
            margin-right: 2px;
          }
        }
        &:after {
          content: '';
          background: rgba(255, 255, 255, 0.7);
          width: calc(100% - 8px);
          height: 100px;
          position: absolute;
          top: 4px;
          left: 4px;
          z-index: 8;
        }
      }
    }
  }
`;
