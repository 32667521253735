import { Path, Rect, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
const TickComplete = ({ style }: { style?: Style | Style[] }) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" style={style}>
    <Rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="#71C131" stroke="#71C131" />
    <Path
      d="M8.08265 2.5C5.02966 2.5 2.5542 4.96272 2.5542 8C2.5542 11.0373 5.02966 13.5 8.08265 13.5C11.1356 13.5 13.6111 11.0373 13.6111 8C13.6111 4.96272 11.1356 2.5 8.08265 2.5ZM10.4705 6.20391L7.87163 9.78873C7.83531 9.83916 7.78742 9.88026 7.73195 9.90861C7.67647 9.93696 7.615 9.95175 7.55263 9.95175C7.49026 9.95175 7.4288 9.93696 7.37332 9.90861C7.31784 9.88026 7.26996 9.83916 7.23364 9.78873L5.6948 7.6673C5.64791 7.60223 5.6948 7.51138 5.77501 7.51138H6.35377C6.47964 7.51138 6.59935 7.57154 6.67339 7.67466L7.55202 8.88761L9.49191 6.21127C9.56595 6.10937 9.68442 6.04799 9.81152 6.04799H10.3903C10.4705 6.04799 10.5174 6.13884 10.4705 6.20391Z"
      fill="white"
    />
  </Svg>
);

export default TickComplete;
