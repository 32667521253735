import React, { useEffect, useMemo } from 'react';
import { DualAxes } from '@ant-design/plots';

import Styled from './styles';
import { useSelector } from 'react-redux';
import { reportUserInformationSelector } from 'pages/Report/UserInformation/selectors';
import { uniq } from 'lodash';
import { AnyObject } from 'types';
import dayjs from 'dayjs';
import { formatComma } from 'libs/utils/format';
import { Select } from 'antd';
import { EXPORT_TARGET_MONTH_COUNT } from 'constant';
import { setChartSelectedCompany } from 'pages/Report/UserInformation/slice';
import { useAppDispatch } from 'hooks';

interface IProps {
  exportPdf?: boolean;
}

const RegisteredUsersPerMonth: React.FC<IProps> = ({ exportPdf }) => {
  const { chartSelectedCompany, dateRanger, companyReports, calculatedCorporateUsers } =
    useSelector(reportUserInformationSelector);

  const [selectedCompany, setSelectedCompany] = React.useState<string>();
  const dispatch = useAppDispatch();

  const dates = useMemo(() => {
    let dateData =
      dateRanger && dateRanger.length
        ? dateRanger
        : uniq(companyReports.map((obj) => obj.target_month));
    if (exportPdf) {
      dateData = dateData.slice(0, EXPORT_TARGET_MONTH_COUNT);
    }
    return dateData;
  }, [companyReports, dateRanger, exportPdf]);

  const companies = useMemo(() => {
    const children = calculatedCorporateUsers?.children || {};
    const result = Object.keys(children).map((companyId) => ({
      companyId,
      name: children[companyId].name,
    }));
    setSelectedCompany(result.length ? result[0].companyId : undefined);
    return result;
  }, [calculatedCorporateUsers]);

  const selectedCompanyName = useMemo(
    () => companies.find((obj) => obj.companyId === selectedCompany)?.name || selectedCompany,
    [selectedCompany, companies]
  );

  useEffect(() => {
    if (exportPdf) {
      setSelectedCompany(chartSelectedCompany);
    }
  }, [exportPdf, chartSelectedCompany]);

  useEffect(() => {
    if (exportPdf) {
      dispatch(setChartSelectedCompany(companies[0]?.companyId));
    }
  }, [dispatch, exportPdf, companies]);

  const companyDataColumn = useMemo(() => {
    let res: AnyObject[] = [];
    const maxUser = calculatedCorporateUsers?.max_users || {};
    dates.forEach((date) => {
      res = [
        ...res,
        {
          time: dayjs(date, 'YYYYMM').format('YYYY/MM'),
          value: maxUser[date] || 0,
          type: '全ユーザー',
        },
      ];
    });
    return res;
  }, [dates, calculatedCorporateUsers]);

  const companyDataLine = useMemo(() => {
    let res: AnyObject[] = [];
    const churnRate = calculatedCorporateUsers?.churn_rate || {};
    dates.forEach((date) => {
      res = [
        ...res,
        {
          time: dayjs(date, 'YYYYMM').format('YYYY/MM'),
          count: churnRate[date] || 0,
          name: '増加率',
        },
      ];
    });
    return res;
  }, [dates, calculatedCorporateUsers]);

  const selectedCompanyDataColumn = useMemo(() => {
    if (!selectedCompany || !calculatedCorporateUsers?.children) {
      return [];
    }
    let res: AnyObject[] = [];
    const maxUser = calculatedCorporateUsers?.children[selectedCompany].max_users || {};
    dates.forEach((date) => {
      res = [
        ...res,
        {
          time: dayjs(date, 'YYYYMM').format('YYYY/MM'),
          value: maxUser[date] || 0,
          type: '全ユーザー',
        },
      ];
    });
    return res;
  }, [selectedCompany, dates, calculatedCorporateUsers]);

  const selectedCompanyDataLine = useMemo(() => {
    if (!selectedCompany || !calculatedCorporateUsers?.children) {
      return [];
    }
    let res: AnyObject[] = [];
    const churnRate = calculatedCorporateUsers?.children[selectedCompany].churn_rate || {};
    dates.forEach((date) => {
      res = [
        ...res,
        {
          time: dayjs(date, 'YYYYMM').format('YYYY/MM'),
          count: churnRate[date] || 0,
          name: '増加率',
        },
      ];
    });
    return res;
  }, [selectedCompany, dates, calculatedCorporateUsers]);

  return (
    <Styled>
      <div className="user-info">
        <div className="body-info">
          <div className="right-side">
            <div className="wrap-header">
              <div className="title">全ユーザー月間最大登録ユーザー数推移</div>
              <div className="wrap-action">
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter yellow">
                      <div className="circle" />
                      <div className="line" />
                    </div>
                    <span className="legend-name">増加率</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter blue" />
                    <span className="legend-name">全ユーザー</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="chart">
              <div className="column">
                <span className="unit">単位：人</span>
                <DualAxes
                  {...config}
                  data={[companyDataColumn, companyDataLine]}
                  yAxis={{
                    grid: {
                      line: {
                        style: {
                          lineDash: [2, 2],
                          stroke: '#ddd',
                        },
                      },
                    },
                  }}
                  xAxis={{
                    tickLine: null,
                  }}
                  label={{
                    position: 'top',
                    formatter: (v) => formatComma(v.value),
                  }}
                  legend={false}
                  height={400}
                  geometryOptions={[
                    {
                      geometry: 'column',
                      seriesField: 'type',
                      columnStyle: {
                        width: 35,
                      },
                      color: '#08A3A5',
                      label: {
                        position: 'top',
                        style: {
                          fill: '#08A3A5',
                        },
                        formatter: (v) => formatComma(v.value),
                      },
                    },
                    {
                      geometry: 'line',
                      seriesField: 'name',
                      lineStyle: {
                        lineWidth: 2,
                      },
                      isStack: true,
                      point: true,
                      color: '#F6AC00',
                      label: {
                        position: 'top',
                        style: {
                          fill: '#F6AC00',
                          style: {
                            fill: '#F6AC00',
                          },
                        },
                      },
                    },
                  ]}
                  scrollbar={{
                    type: 'horizontal',
                    width: 300,
                    categorySize: 100,
                  }}
                />
              </div>
              <div className="wrap-header">
                <div className="title">ユーザー別月間最大登録ユーザー数推移</div>
                <div>
                  <div className="wrap-action">
                    <div className="wrap-select">
                      <label>企業種類 : </label>
                      <Select
                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        onChange={(value) => {
                          setSelectedCompany(value);
                          dispatch(setChartSelectedCompany(value));
                        }}
                        value={selectedCompany}
                      >
                        {companies.map((item) => (
                          <Select.Option key={item.companyId} value={item.companyId}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="legend">
                      <div className="sort-column">
                        <div className="legend-filter green">
                          <div className="circle" />
                          <div className="line" />
                        </div>
                        <span className="legend-name">増加率</span>
                      </div>
                      <div className="sort-column">
                        <div className="legend-filter skyblue" />
                        <span className="legend-name">{selectedCompanyName}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chart">
                <div className="column">
                  <span className="unit">単位：人</span>
                  <DualAxes
                    {...config}
                    data={[selectedCompanyDataColumn, selectedCompanyDataLine]}
                    yAxis={{
                      grid: {
                        line: {
                          style: {
                            lineDash: [2, 2],
                            stroke: '#ddd',
                          },
                        },
                      },
                    }}
                    xAxis={{
                      tickLine: null,
                    }}
                    label={{
                      position: 'top',
                    }}
                    legend={false}
                    height={400}
                    geometryOptions={[
                      {
                        geometry: 'column',
                        seriesField: 'type',
                        columnWidthRatio: 0.2,
                        marginRatio: 0.2,
                        color: '#20A7D1',
                        label: {
                          position: 'top',
                          style: {
                            fill: '#20A7D1',
                          },
                        },
                      },
                      {
                        geometry: 'line',
                        seriesField: 'name',
                        lineStyle: {
                          lineWidth: 2,
                        },
                        isStack: true,
                        point: true,
                        color: '#8DC968',
                        label: {
                          position: 'top',
                          style: {
                            fill: '#8DC968',
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default RegisteredUsersPerMonth;

const config = {
  xField: 'time',
  yField: ['value', 'count'],
  appendPadding: [40, 0, 0, 0],
  meta: {
    count: {
      formatter: (v: number) => {
        return `${Number(v.toFixed(1))}%`;
      },
    },
  },
  geometryOptions: [
    {
      geometry: 'line',
      seriesField: 'name',
      lineStyle: {
        lineWidth: 2,
      },
    },
    {
      geometry: 'column',
    },
  ],
};
