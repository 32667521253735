import styled from 'styled-components';
import { Layout } from 'antd';

const { Header } = Layout;

export const HeaderStyled = styled(Header)`
  width: 100%;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  background-color: #f9f8f8;
  height: 56px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  padding: 0 20px;
  line-height: unset;
  .header__left {
    .page__title {
      font-size: 18px;
      color: #424242;
      margin: -3px 0 0;
    }
  }
  .header__right {
    display: flex;
    align-items: center;
    .imageQA {
      font-size: 26px;
      color: #c4c4c4;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #999;
      }
    }
    .drop-down {
      margin: 0 10px;
      cursor: pointer;
      font-size: 13px;
      color: #666666;
      .anticon {
        margin-left: 4px;
        color: #bbb;
        font-size: 9px;
      }
      small {
        font-size: 11px;
      }
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 85px;
        margin: 0;
      }
    }
    .border {
      margin: 0 20px;
      width: 1px;
      height: 20px;
      background-color: rgba(0, 0, 0, 0.1);
    }
    .avatar {
      border: none;
      background: none;
      padding: 0;
      border-radius: 50%;
      cursor: pointer;
    }
    .flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ModalContent = styled.div`
  .text-content {
    font-size: 13px;
    color: #424242;
    text-align: center;
  }

  .form {
    .ant-col.ant-form-item-label {
      background-color: white !important;
    }
    .ant-form-item {
      margin-bottom: 20px;
      .ant-input-affix-wrapper {
        margin: 0;
      }
      .ant-form-item-explain {
        min-height: 0;
      }
    }
    .input {
      background: #f5f5f5;
      box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
      margin-bottom: 24px;
      &.ant-input-affix-wrapper-focused {
        background: #fff;
        input {
          background: #fff;
          :focus {
            background: #fff;
          }
          :-webkit-autofill:focus,
          :-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px #fff inset !important;
          }
        }
      }
      input {
        background: #f5f5f5;
        ::placeholder {
          color: #cccccc;
          font-size: 13px;
        }
        :-webkit-autofill,
        :-webkit-autofill:hover {
          -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
        }
      }
    }
    .ant-input-affix-wrapper-status-error {
      input {
        :-webkit-autofill,
        :-webkit-autofill:hover {
          -webkit-box-shadow: 0 0 0 30px #fffaf9 inset !important;
        }
      }
    }
    .checkbox {
      font-size: 12px;
      color: #888888;
      margin-top: 8px;
      margin-left: 10px;
      .ant-checkbox-inner {
        width: 16px;
        height: 16px;
        border: 1px solid #d9d9d9;
        border-radius: 1px;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        border-color: #2666bf;
      }
    }
    .ant-input-prefix {
      margin-right: 8px;
    }
    .icon,
    .anticon {
      color: #bbbbbb;
      font-size: 18px;
    }
    .btn-submit {
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;

      font-size: 15px;
      font-weight: bold;
      border: none;
      margin-top: -10px;
      width: 360px;
      height: 48px;
      &:not([disabled]):hover {
        background: #f8b824;
      }
      &[disabled] {
        background: #eee;
        color: #bbb;
      }
    }
  }
`;
