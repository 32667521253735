import React, { useCallback } from 'react';

export interface ReceivedProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => void | Promise<void>;
}

const useConfirm = (props: ReceivedProps) => {
  const { setOpen, onSubmit } = props;

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback(() => {
    onSubmit && onSubmit();
  }, [onSubmit]);

  return { ...props, handleClose, handleSubmit };
};

export type Props = ReturnType<typeof useConfirm>;

export default useConfirm;
