import { Text, View } from '@react-pdf/renderer';
import styles from './styles';
import React from 'react';

interface IProps {
  title?: string;
}

const Header = ({ title }: IProps) => (
  <View style={styles.header} wrap={false}>
    <Text style={styles.textHeader}>{title}</Text>
  </View>
);

export default Header;
