import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'formik-antd';
import { useSelector } from 'react-redux';
import { unionBy } from 'lodash';
import { Select } from 'antd';
// import zipcodeJa from 'zipcode-ja';

import { getDataSearchUsers, updateInformation, updateUser } from './thunk';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { administratorMasterSchema } from 'libs/validations';
import ActionErrorModal from 'components/Modal/ActionError';
import { administratorMasterSelector } from './selectors';
import CompletedModal from 'components/Modal/Completed';
import { AdministratorMasterFormik } from 'types';
import { setInformationRegister } from './slice';
import { Input, SelectField } from 'components';
import { useAppDispatch } from 'hooks';

interface Props {
  goBack: () => void;
  checkData: boolean;
  method?: string;
  setIsModifiedValue: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ComponentHandle {
  handleSubmit: () => void;
}

const { Option } = Select;

const ConfirmAdministratorMaster = forwardRef<ComponentHandle, Props>(
  ({ goBack, method, setIsModifiedValue }, ref) => {
    const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
    const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);

    const { information, employees } = useSelector(administratorMasterSelector);

    const dispatch = useAppDispatch();

    const formik = useFormik<AdministratorMasterFormik>({
      initialValues: information,
      validationSchema: administratorMasterSchema,
      validateOnBlur: false,
      onSubmit: async (values) => {
        if (!information.i_id) return;

        dispatch(startLoading());
        const i_id_old_admin = employees.find((e) => e.user_type === 'admin')?.i_id;
        if (i_id_old_admin) {
          await dispatch(
            updateUser({
              id: i_id_old_admin,
              data: {
                item: {
                  user_type: 'member',
                },
                return_item_result: true,
                is_force_update: true,
              },
            })
          );
        }

        const i_id_new_admin = employees.find((e) => e.email === information.admin_email)?.i_id;
        if (i_id_new_admin) {
          await dispatch(
            updateUser({
              id: i_id_new_admin,
              data: {
                item: {
                  user_type: 'admin',
                },
                is_force_update: true,
              },
            })
          );
        }

        const resultAction = await dispatch(
          updateInformation({
            id: information.i_id,
            data: {
              item: values,
              return_item_result: true,
              is_force_update: true,
            },
          })
        );
        if (updateInformation.fulfilled.match(resultAction)) {
          dispatch(setInformationRegister(values));
          setShowCompleteModal(true);
        } else {
          setShowActionErrorModal(true);
        }
        dispatch(stopLoading());
        setIsModifiedValue(false);
      },
    });

    useEffect(() => {
      dispatch(
        getDataSearchUsers({
          conditions: [
            {
              id: 'company_id',
              search_value: ['000000001'],
            },
            {
              id: 'login_id',
              search_value: [information.admin_email],
            },
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
        })
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useImperativeHandle(
      ref,
      () => ({
        handleSubmit() {
          formik.handleSubmit();
        },
      }),
      [formik]
    );

    return (
      <div className="container confirm">
        <div className="description">
          <p>編集内容に問題がなければ、更新ボタンをクリックしてください。</p>
        </div>
        <FormikProvider value={formik}>
          <Form
            layout="vertical"
            labelCol={{
              flex: '22%',
            }}
            colon={false}
          >
            <div className="form">
              <Form.Item
                name="name"
                label={
                  <span className="text-label">
                    法人名
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="name"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                  maxLength={100}
                />
              </Form.Item>
              <Form.Item
                name="name_furigana"
                label={
                  <span className="text-label">
                    法人名（フリガナ）
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="name_furigana"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                  maxLength={100}
                />
              </Form.Item>
              <Form.Item
                name="postal_code"
                label={
                  <span className="text-label">
                    郵便番号
                    <span className="require">*</span>
                  </span>
                }
                className={`form-input wrap-input-zip-code ${
                  formik.touched.postal_code && formik.errors.postal_code
                    ? 'error-input-zip-code'
                    : ''
                }`}
              >
                <Input
                  disabled
                  name="postal_code"
                  className="input"
                  type="text"
                  placeholder="半角数字：7文字"
                  onKeyPress={(e) => {
                    if (
                      isNaN(parseInt(e.key)) ||
                      formik.values.postal_code!.toString().length > 6
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="prefecture"
                label={
                  <span className="text-label">
                    都道府県
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="prefecture"
                  type="text"
                  placeholder="3文字以上4文字以下"
                  className="input"
                  maxLength={100}
                />
              </Form.Item>
              <Form.Item
                name="address"
                label={
                  <span className="text-label">
                    市区町村
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="address"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                  maxLength={100}
                />
              </Form.Item>
              <Form.Item
                name="plot_number"
                label={
                  <span className="text-label">
                    番地
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="plot_number"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                  maxLength={100}
                />
              </Form.Item>

              <Form.Item
                name="building_name"
                label={<span className="text-label">建物名・部屋番号</span>}
                className="form-input"
              >
                <Input
                  disabled
                  name="building_name"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                  maxLength={100}
                />
              </Form.Item>
              <Form.Item
                name="method_selection"
                label={<span className="text-label">管理者変更方法選択</span>}
                className="form-input method-selection"
              >
                <SelectField value={method} name="method_selection" disabled showArrow={false}>
                  <Option key={1} value={'1'}>
                    社内ユーザーマスタから選択
                  </Option>
                  <Option key={2} value={'2'}>
                    社内ユーザーを新規作成
                  </Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="admin_email"
                label={
                  <span className="text-label">
                    管理者メールアドレス
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <SelectField disabled name="admin_email" className="input" showArrow={false}>
                  {unionBy(employees, 'email').map((e, index) => (
                    <Option key={index} value={e.email}>
                      {e.email}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="admin_name"
                label={
                  <span className="text-label">
                    管理者氏名
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="admin_name"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                  maxLength={100}
                />
              </Form.Item>
              <Form.Item
                name="admin_name_furigana"
                label={
                  <span className="text-label">
                    管理者氏名 （フリガナ）
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="admin_name_furigana"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                  maxLength={100}
                />
              </Form.Item>
              <Form.Item
                name="admin_department"
                label={
                  <span className="text-label">
                    所属
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="admin_department"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                  maxLength={100}
                />
              </Form.Item>
              <Form.Item
                name="admin_position"
                label={
                  <span className="text-label">
                    役職
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="admin_position"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                  maxLength={100}
                />
              </Form.Item>
              <Form.Item
                name="admin_phone"
                label={
                  <span className="text-label">
                    電話番号
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="admin_phone"
                  className="input"
                  type="text"
                  placeholder="半角数字：最大11文字（ハイフン無し）"
                />
              </Form.Item>
              <Form.Item
                name="fax"
                label={
                  <span className="text-label">
                    FAX
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="fax"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                />
              </Form.Item>
              <Form.Item
                name="business_registration_number"
                label={
                  <span className="text-label">
                    登録番号（インボイス）
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="business_registration_number"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                />
              </Form.Item>
              <Form.Item
                name="bank_name"
                label={
                  <span className="text-label">
                    銀行名（請求書記載振込先）
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="bank_name"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                />
              </Form.Item>
              <Form.Item
                name="bank_code"
                label={
                  <span className="text-label">
                    銀行コード（請求書記載振込先）
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="bank_code"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                />
              </Form.Item>
              <Form.Item
                name="branch_name"
                label={
                  <span className="text-label">
                    支店名（請求書記載振込先）
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="branch_name"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                />
              </Form.Item>
              <Form.Item
                name="branch_code"
                label={
                  <span className="text-label">
                    支店コード（請求書記載振込先）
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="branch_code"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                />
              </Form.Item>
              <Form.Item
                name="bank_account_type"
                label={
                  <span className="text-label">
                    口座種別（請求書記載振込先）
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="bank_account_type"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                />
              </Form.Item>
              <Form.Item
                name="bank_account_number"
                label={
                  <span className="text-label">
                    口座番号（請求書記載振込先）
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled
                  name="bank_account_number"
                  className="input"
                  type="text"
                  placeholder="最大100文字"
                />
              </Form.Item>
            </div>
          </Form>
        </FormikProvider>
        <CompletedModal
          visible={showCompleteModal}
          setVisible={setShowCompleteModal}
          title="更新が完了しました"
          onSubmit={goBack}
        />
        <ActionErrorModal
          visible={showActionErrorModal}
          setVisible={setShowActionErrorModal}
          subTitle="カード情報の更新に失敗しました"
          description={
            <p className="text-content">
              新規権限の登録に失敗しました。
              <br />
              もう一度情報を入力し、再度お試しください。
            </p>
          }
        />
      </div>
    );
  }
);

export default ConfirmAdministratorMaster;
