import styled from 'styled-components';

type Props = {
  isEmptyData: boolean;
};

export const SectionStyled = styled.div<Props>`
  @media (max-width: 1440px) {
    .container {
      .form-search {
        justify-content: flex-start !important;
        .item {
          width: 235px !important;
          margin-right: 8px;
        }
      }
      .table {
        .ant-table .ant-table-container::before {
          margin-top: 2px !important;
        }
        .ant-table-body {
          ::-webkit-scrollbar {
            display: none;
          }
        }
        .ant-table-thead {
          .ant-table-cell {
            padding: 4px 8px !important;
            font-size: 11px !important;
            height: 44px;
          }
        }
        .ant-table-thead > tr > th:last-child {
          text-align: center !important;
        }
        .ant-table-tbody {
          .ant-table-cell {
            padding: 6px 8px !important;
            height: 40px !important;
          }
        }
      }
    }
    .wrap-bottom {
      .btn-active,
      .btn-outline {
        width: 180px !important;
        height: 40px;
      }
    }
  }
  .ant-table {
    border: 1px solid #e5e5e5;
    overflow: hidden;
    border-radius: 3px;
    .ant-table-row:last-child {
      td {
        border: none;
      }
    }
    .ant-table-placeholder {
      .ant-table-cell {
        border: none;
      }
    }
  }
  .wrap-select-record {
    display: flex;
    column-gap: 60px;
    .label-select {
      font-size: 11px;
      color: #424242;
    }
  }
  .select-option {
    width: 70px;
    height: 28px;
    font-size: 11px !important;
    .ant-select-arrow {
      top: 55%;
    }
    .ant-select-selector {
      border-radius: 3px;
    }
  }
  .container {
    padding: 60px 40px;
    .item-date {
      .date {
        font-size: 12px;
        color: #777777;
        padding-right: 30px;
      }
    }
    .form-search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item {
        width: 19%;
        .text-label {
          font-size: 12px;
        }
      }
    }
    .wrap-search {
      display: flex;
      align-items: center;
      justify-content: end;
      .text-label {
        font-size: 12px;
      }
      .btn-search {
        margin-left: 12px;
        width: 100px;
      }
      .label-reset {
        margin-left: 8px;
      }
      .label-reset {
        font-size: 12px;
        cursor: pointer;
        text-decoration-line: underline;
        color: #00a3a5;
      }
      .btn-search {
        background: #ffffff;
        border: 1px solid #00a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        font-size: 14px;
        color: #00a3a5;
        cursor: pointer;
        .icon-search {
          font-size: 18px;
          margin-right: 12px;
        }
      }
    }
    .table {
      margin-top: ${(props) => (!props.isEmptyData ? '0px' : '70px')};
      .text-count {
        font-family: 'Lato';
        font-size: 14px;
        color: #424242;
      }
      .ant-table .ant-table-container::before {
        content: 'ALL';
        margin-left: 7px;
        margin-top: 9px;
        font-size: 11px;
      }
      .ant-table-body {
        ::-webkit-scrollbar {
          display: none;
        }
      }
      .ant-table-thead .ant-checkbox-wrapper {
        padding-top: 10px;
      }
      .ant-table-thead {
        border-radius: 2px 2px 0px 0px;
        .ant-table-cell {
          background-color: #ebebeb;
          text-align: left;
          font-weight: 700;
          font-size: 11px;
          color: #2a2a2a;
          margin: 0 3px;
          &:before {
            display: none;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          font-family: 'Lato';
          font-size: 11px;
          text-align: left;
          color: #424242;
          word-wrap: break-word;
        }
        .icon {
          color: #c4c4c4;
          font-size: 20px;
          cursor: pointer;
        }
        & > tr.ant-table-row-selected > td {
          background-color: #fdf7ea;
        }
      }
    }
  }
  .wrap-bottom {
    display: flex;
    height: 95px;
    background-color: #ffffff;
    padding: 0 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
    .flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .text-label {
      font-size: 13px;
      color: #777777;
      display: flex;
      align-items: center;
    }
    .btn {
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
    }
    .btn-outline {
      background-color: #ffffff;
      border: 1px solid #d9d9d9;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 13px;
      color: #777777;
      font-weight: 400;
    }
  }
`;
