import React, { useCallback, useEffect, useState } from 'react';
import { CloudDownloadOutlined } from '@ant-design/icons';
import ManageReleaseStyled from './styles';
import { useSelector } from 'react-redux';
import { Button, Table } from 'antd';
import { Header } from 'components';
import * as Types from 'types';

import { getDataAdminReleaseNoteManagement } from 'pages/PublicManagement/thunk';
import { publicManagerSelector } from 'pages/PublicManagement/selectors';
import { getStatus } from 'pages/PublicManagement';
import { loadingRef } from 'components/Loading';
import { ColumnsType } from 'antd/es/table';
import { useAppDispatch } from 'hooks';
import dayjs from 'dayjs';

const PER_PAGE = 10;

interface Props {
  selectedRows: Array<Types.OfficialCurriculumPublishingSettings.ResponseType>;
  permissionNumber?: number;
  visible: boolean;
}

const ManageReleasePDF: React.FC<Props> = ({ selectedRows, permissionNumber, visible }) => {
  const [page, setPage] = useState<number>(1);
  const { dataAdminReleaseNoteManagement, loading, totalReleaseNote } =
    useSelector(publicManagerSelector);
  const dispatch = useAppDispatch();

  const fetchDataAdminReleaseNoteManagement = useCallback(() => {
    if (visible && selectedRows.length) {
      dispatch(
        getDataAdminReleaseNoteManagement({
          conditions: [
            {
              id: 'provider_id',
              search_value: [selectedRows[0]?.provider_id],
            },
            {
              id: 'official_curriculum_code',
              search_value: [selectedRows[0]?.official_curriculum_code],
            },
          ],
          sort_fields: [
            {
              id: 'publish_start_date',
              order: 'asc',
            },
          ],
          page: page,
          per_page: PER_PAGE,
          omit_total_items: false,
          include_item_ref: true,
        })
      );
    }
  }, [visible, selectedRows, dispatch, page]);

  const columns: ColumnsType<Types.AdminReleaseNoteManagement.ResponseType> = [
    {
      title: 'No.',
      dataIndex: '',
      key: 'No',
      width: '3.5%',
      render: (_value, _record, index) => <span className="no">{index + 1}</span>,
    },
    {
      title: (
        <span>
          OFFICIALカリキュラム
          <br />
          公開日時（更新日時）
        </span>
      ),
      width: '15%',
      dataIndex: 'publish_start_date',
      key: 'publish_start_date',
      render: (record) => dayjs(record).locale('ja').format('YYYY/MM/DD (dddd) HH:mm'),
    },
    {
      title: 'バージョン',
      dataIndex: 'release_version',
      align: 'center',
      width: '7%',
    },
    {
      title: '公開種類',
      dataIndex: 'publish_type',
      key: 'publish_type',
      align: 'center',
      width: '6%',
      render: (record) =>
        record === 0 ? '未更新' : record === 1 ? '初回公開' : record === 2 ? '更新' : '最終更新',
    },
    {
      title: (
        <span>
          リリースノート
          <br />
          作成状況
        </span>
      ),
      dataIndex: 'release_note_id',
      key: 'release_note_id',
      align: 'center',
      width: '8%',
      render: (record) => (!record ? '未作成' : '作成済'),
    },
    {
      title: 'リリースノートタイトル',
      dataIndex: 'release_note_title',
      key: 'release_note_title',
      render: (record) => (!record ? '_' : <div className="release_note_title">{record}</div>),
      align: 'left',
      width: '18%',
    },
    {
      title: '作成・更新日時',
      dataIndex: 'updatedat',
      key: 'updatedat',
      align: 'center',
      render: (record) =>
        !record ? '_' : dayjs(record).locale('ja').format('YYYY/MM/DD (dddd) HH:mm'),
    },
    {
      title: '作成者',
      dataIndex: 'createdby',
      key: 'createdby',
      align: 'center',
      width: '7%',
      render: (record) => (!record ? '_' : record),
    },
    {
      title: (
        <span>
          リリースノート
          <br /> 公開ステータス
        </span>
      ),
      dataIndex: 'release_note_publish_status',
      key: 'release_note_publish_status',
      align: 'center',
      width: '10%',
      render: (record) => (!record ? '_' : getStatus(record)),
    },
    {
      title: 'アクション',
      dataIndex: 'release_note_id',
      key: 'release_note_id',
      align: 'center',
      width: '12.3%',
      render: (_text, record) =>
        record?.release_note_id ? (
          <div className="wrap-button-table">
            <Button className="list-release release-left">閲覧</Button>
            <Button
              disabled={permissionNumber === 1}
              className={`list-release release-right ${permissionNumber === 1 ? 'disabled' : ''}`}
            >
              編集・削除
            </Button>
          </div>
        ) : (
          <Button className="list-release create-new">新規作成</Button>
        ),
    },
  ];

  useEffect(fetchDataAdminReleaseNoteManagement, [fetchDataAdminReleaseNoteManagement]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      <ManageReleaseStyled
        collapsedMenu={true}
        isEmptyData={
          dataAdminReleaseNoteManagement ? !!dataAdminReleaseNoteManagement.length : false
        }
      >
        <Header
          title={'リリースノート管理'}
          exportPDF
          style={{
            justifyContent: 'center',
            display: 'flex',
          }}
        />
        <p className="sub-title">
          公開日時別のリリースノートの作成状況一覧画面です。
          <br />
          作成・編集はリリースノート一覧ボタンをクリックしてください。
        </p>
        <div>
          <label>対象OFFICAILカリキュラム： </label>
          <div className="wrap-targer-official">
            <div className="wrap-left-side">
              <div className="text-left">
                <span>
                  {dataAdminReleaseNoteManagement
                    ? dataAdminReleaseNoteManagement[0]?.official_curriculum_name
                    : null}
                </span>
                <span>
                  （ID：
                  {dataAdminReleaseNoteManagement
                    ? dataAdminReleaseNoteManagement[0]?.official_curriculum_code
                    : null}
                  )
                </span>
              </div>
              <div className="wrap-creator">
                <span className="label-creator">作成者：</span>
                <span>
                  {dataAdminReleaseNoteManagement
                    ? dataAdminReleaseNoteManagement[0]?.createdby
                    : null}
                </span>
              </div>
            </div>
            <div className="wrap-right-side">{getStatus(selectedRows[0]?.publish)}</div>
          </div>
        </div>
        <div className="wrap-button">
          <Button className="btn-active">
            <CloudDownloadOutlined
              style={{
                fontSize: 20,
              }}
            />
            <span>エクスポート</span>
          </Button>
        </div>
        <Table
          key="index"
          pagination={{
            pageSize: PER_PAGE,
            position: ['topCenter'],
            total: totalReleaseNote,
            showSizeChanger: false,
            onChange: setPage,
            showTotal: () => (
              <span className="text-count">
                {page * PER_PAGE > dataAdminReleaseNoteManagement.length
                  ? dataAdminReleaseNoteManagement.length
                  : page * PER_PAGE}
                件表示 /{totalReleaseNote} 名
              </span>
            ),
          }}
          loading={loading}
          className="table"
          columns={columns}
          dataSource={dataAdminReleaseNoteManagement?.map((item, index) => ({ ...item, index }))}
        />
        <div className="modal-footer" />
      </ManageReleaseStyled>
    </>
  );
};

export default ManageReleasePDF;
