import styled from 'styled-components';

type Props = {
  collapsedMenu: boolean;
};

export default styled.section<Props>`
  background-color: #f8f8f8;
  min-height: 100%;
  .ant-tooltip {
    max-width: 737px !important;
    max-height: 314px;
    p {
      line-height: 32px;
    }
    .ant-tooltip-inner {
      border-radius: 10px;
    }
  }
  .wrap-select-record {
    display: flex;
    column-gap: 60px;
    .label-select {
      font-size: 11px;
      color: #424242;
    }
    .ant-select-selection-item {
      font-size: 11px;
      font-family: Noto Sans Javanese;
    }
  }

  .ant-table {
    border: 1px solid #e5e5e5;
    overflow: hidden;
    border-radius: 3px 0px 3px 3px;
    .ant-table-row:last-child {
      td {
        border: none;
      }
    }
    .ant-table-placeholder {
      .ant-table-cell {
        border: none;
      }
    }
  }
  .wrap-status {
    display: inline-flex;
    align-items: center;
  }
  .icon-status {
    height: 20px;
    margin-right: 5px;
  }
  .ant-table-thead > tr > th {
    padding: 16px 8px;
  }
  .icon-small {
    width: 16px;
    height: 16px;
  }
  .select-option {
    width: 70px;
    height: 28px;
    .ant-select-arrow {
      top: 55%;
    }
    .ant-select-selector {
      border-radius: 3px;
    }
  }
  @media (max-width: 1440px) {
    .container {
      .border-line {
        margin: 30px 0 20px 0 !important;
      }
      .form-search {
        .ant-select {
          width: 289px !important;
        }
        .wrap-submit-search {
          margin-top: 16px;
          .ant-btn {
            width: 100px;
          }
        }
        .text-label {
          font-size: 12px;
        }
      }
      .table {
        .ant-table-thead,
        .ant-table-tbody {
          .ant-table-cell {
            height: 48px !important;
            padding: 4px 8px;
            p {
              margin-bottom: 0 !important;
              overflow: unset;
            }
          }
        }

        .ant-table-thead .ant-table-cell .ant-checkbox-wrapper {
          padding-top: 18px;
        }
      }
    }
    .wrap-bottom {
      .flex {
        .btn,
        .btn-outline {
          padding: 19px 0;
          width: 180px !important;
        }
      }
    }
  }
  .container {
    position: relative;
    width: 100%;
    padding: 30px;
    min-height: calc(100vh - 151px);
    .text-note {
      font-size: 12px;
      color: #424242;
    }
    .border-line {
      border-top: 1px solid #dddddd;
      width: 40px;
      margin: 30px 0;
    }
    .form-search {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .item {
        width: 25%;
        padding: 0 4px;
        .input-question-name {
          border-radius: 2px;
          height: 32px;
          padding: 0 11px;
          border: 1px solid #d9d9d9;
        }
      }
      .wrap-submit-search {
        width: 25%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .label-reset {
        font-size: 12px;
        cursor: pointer;
        text-decoration-line: underline;
        color: #00a3a5;
        margin-left: 12px;
      }
      .btn-search {
        background: #ffffff;
        border: 1px solid #00a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        font-size: 14px;
        color: #00a3a5;
        cursor: pointer;
        .icon-search {
          font-size: 18px;
          margin-right: 12px;
        }
      }
    }
    .item-table {
      position: relative;
    }
    .item-checkbox {
      width: 160px;
      padding: 5px;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-bottom: none;
      border-radius: 3px 3px 0 0;
      position: absolute;
      right: 0;
      top: 30px;
    }
    .table {
      .text-count {
        font-family: 'Lato';
        font-size: 14px;
        color: #424242;
        z-index: 9;
        .text-static {
          font-size: 11px;
          padding-left: 3px;
        }
      }
      .ant-table .ant-table-container::before {
        content: 'ALL';
        margin-left: 7px;
        margin-top: 9px;
        font-size: 11px;
      }
      .title-table {
        font-size: 10px;
        color: #777777;
        margin-bottom: 0;
        font-weight: 400;
      }
      .text-code {
        font-family: 'Lato';
        font-size: 11px;
        color: #999999;
        margin-bottom: 0;
      }
      .text-title {
        font-family: 'Lato';
        font-size: 13px;
        color: #424242;
        margin-bottom: 0;
      }
      .ant-table-thead .ant-checkbox-wrapper {
        padding-top: 10px;
      }
      .ant-table-thead {
        border-radius: 2px 2px 0 0;
        .ant-table-cell {
          background-color: #ebebeb;
          font-weight: 700;
          font-size: 11px;
          color: #626262;
          margin: 0 3px;
          &:before {
            display: none;
          }
        }
      }
      .ant-table-thead .ant-table-cell.publish {
        font-size: 10px;
        color: #777777;
      }
      .ant-table-tbody {
        .ant-table-cell {
          font-family: 'Lato';
          font-size: 13px;
          color: #424242;
          word-wrap: break-word;
        }
        .icon {
          color: #c4c4c4;
          font-size: 20px;
          cursor: pointer;
        }
        & > tr.ant-table-row-selected > td {
          background-color: #fdf7ea;
        }
      }
      .ant-table-tbody .ant-table-cell.publish {
        text-align: center;
      }
      .ant-table-tbody .ant-table-cell .ant-checkbox-wrapper {
        padding-top: 4px;
      }
    }
  }
  .wrap-bottom {
    height: 95px;
    width: 100%;
    transition: width 0.3s;
    background-color: #ffffff;
    position: sticky;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    .flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .text-label {
      font-size: 13px;
      color: #777777;
      display: flex;
      align-items: center;
    }
    .btn {
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
    }
    .btn-outline {
      background-color: #ffffff;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #f1a900;
      .icon {
        color: #f6ac00;
        transform: rotate(-90deg);
      }
    }
  }
`;
