import React, { useEffect, useState } from 'react';

import { StopPublic } from 'assets';
import { Modal } from 'components';
import Styled from './styles';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleEdit: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: () => Promise<void> | void;
  fetchData: () => void;
}

const Warning: React.FC<Props> = ({ visible, setVisible, onSubmit, setVisibleEdit, fetchData }) => {
  const [open, setOpen] = useState(visible);
  const handleToggleModal = () => {
    setOpen(false);
    setVisible(false);
  };

  useEffect(() => {
    visible && setOpen(visible);
  }, [visible]);
  const handleSubmit = async () => {
    onSubmit && (await onSubmit());
    handleToggleModal();
    setVisibleEdit(false);
    fetchData();
  };

  return (
    <Modal
      width={420}
      open={open}
      onCancel={handleToggleModal}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
      bodyStyle={{
        paddingBottom: 4,
      }}
    >
      <Styled>
        <div className="wrap-title">
          <img src={StopPublic} alt="complete" />
          <p className="text">公開を停止しました。</p>
        </div>
        <span className="description">公開ステータスは公開停止中として表示されます。</span>
      </Styled>
    </Modal>
  );
};

export default Warning;
