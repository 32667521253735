import { useEffect, useState } from 'react';

import {
  Document,
  IconRequired,
  StopPublic,
  TickComplete,
  Unpublished,
  WhiteEditing,
} from 'assets';
import CurriculumDetail from '../CurriculumDetail';
import { DivCustom, Button } from './styles';
import * as Types from 'types';
import { getPresignedUrlByFileID } from 'services/r2Service';
import { UploadFile } from 'antd';
import { extractFileName, formatComma, getFileFromUrl } from 'libs/utils/format';
import { convertFileResponse } from 'libs/utils/question';

type Props = {
  node: Types.TreeItem<Types.CurriculumItemType>;
};

const PartnerTooltipRowContent = ({ node }: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [code, setCode] = useState<string>();
  const [url, setUrl] = useState<UploadFile<File>>();

  useEffect(() => {
    (async () => {
      if (node.node?.fileID) {
        const fileQuestion: UploadFile<File> = { uid: '', name: '' };
        const fileID = node.node?.fileID;
        const fileName = extractFileName(fileID);
        const nodeFileUrl = (await getPresignedUrlByFileID(fileID)) as string;
        const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
        Object.assign(
          fileQuestion,
          convertFileResponse({
            file: fileFromUrl,
            fileID: fileID,
            fileName: fileName,
          })
        );
        setUrl(fileQuestion);
      }
    })();
  }, []);

  return node.columnIndex === 0 ? (
    <DivCustom columnIndex={0}>
      <div className="rowWrapper" style={{ padding: '0px', width: 256 }}>
        <div className="rowContentHead">
          <div className="spanHead" style={{ padding: '8px' }}>
            {node.node?.publish === 2 ? (
              <>
                <img src={TickComplete} className="icon" alt="publish-icon" />
                <span className="spanText">公開中</span>
              </>
            ) : node.node?.publish === 3 ? (
              <>
                <img src={StopPublic} className="icon" alt="edit-icon" />
                <span className="spanText">公開停止中</span>
              </>
            ) : node.node?.publish === 1 ? (
              <>
                <img src={Unpublished} className="icon" alt="edit-icon" />
                <span className="spanText">未公開</span>
              </>
            ) : (
              <>
                <img src={WhiteEditing} className="icon" alt="edit-icon" />
                <span className="spanText">編集中</span>
              </>
            )}
          </div>
          {!!node.node?.required_curriculum && (
            <div className="spanHead">
              <img src={IconRequired} className="icon" alt="required-icon" />
              <span className="spanText">必修カリキュラム</span>
            </div>
          )}
        </div>
      </div>
      {url && (
        <div
          className="rowWrapper"
          style={{ display: 'flex', justifyContent: 'center', height: 144, padding: 0 }}
        >
          <img
            src={URL.createObjectURL(
              new Blob([(url.originFileObj ? url.originFileObj : url) as unknown as Blob])
            )}
            alt="curriculum"
            className="image"
            style={{ objectFit: 'contain', width: '100%', height: 144 }}
          />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: '#F8F8F8',
          padding: '8px',
        }}
      >
        <img
          src={Document}
          className="icon"
          alt="document"
          style={{ margin: '0px 10px', width: '18px', height: '23px' }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            fontSize: '12px',
            color: '#999999',
            marginRight: '10px',
          }}
        >
          <div>総設問数：</div>
          <div>階層設定設問数：</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            fontSize: '12px',
          }}
        >
          <div>{formatComma(node.node?.totalQuestions || 0)}問</div>
          <div>{formatComma(node.node?.totalLevelQuestions || 0)}問</div>
        </div>
      </div>
    </DivCustom>
  ) : node.columnIndex === 5 ? (
    <DivCustom columnIndex={5}>
      <div className="rowWrapper">
        <p className="content">{node.node?.name}</p>
        <Button
          onClick={() => {
            setVisible(true);
            setCode(node.node?.i_id);
          }}
        >
          設問を確認
        </Button>
      </div>
      <CurriculumDetail visible={visible} setVisible={setVisible} code={code} />
    </DivCustom>
  ) : (
    <DivCustom columnIndex={2}>
      <div className="rowWrapper">
        <p className="content" style={{ color: node.node?.name ? '#2a2a2a' : '#999' }}>
          {node.node?.name || '（空白）'}
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: '#F8F8F8',
          padding: '8px',
        }}
      >
        <img
          src={Document}
          className="icon"
          alt="document"
          style={{ margin: '0px 10px', width: '18px', height: '23px' }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            fontSize: '12px',
            color: '#999999',
            marginRight: '10px',
          }}
        >
          <div>総設問数：</div>
          <div>階層設定設問数：</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            fontSize: '12px',
          }}
        >
          <div>{formatComma(node.node?.totalQuestions || 0)}問</div>
          <div>{formatComma(node.node?.totalLevelQuestions || 0)}問</div>
        </div>
      </div>
    </DivCustom>
  );
};

export default PartnerTooltipRowContent;
