import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { UploadFile as UploadFileAntd } from 'antd/lib/upload/interface';
import { find, includes, isEmpty, isEqual, isString, minBy } from 'lodash';
import { Button, Col, Row, Select, Tooltip } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Form, Radio } from 'formik-antd';
import { pdf } from '@react-pdf/renderer';
import ReactPlayer from 'react-player';
import { UploadFile } from 'antd/es';
import saveAs from 'file-saver';
import {
  QuestionCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  SelectOutlined,
} from '@ant-design/icons';

import { getCompany, getDataTableQuestion, updateTimeLimitCompany } from '../../thunk';
import PDFQuestionMasterDetail from 'pages/QuestionMaster/PDFQuestionMasterDetail';
import { Input, Modal, SelectField, SpinLoading, TextArea } from 'components';
import { ErrorModelToOpen, UploadFileToServerModel } from 'types/components';
import { HEADER_QUESTION_MASTER_CSV } from 'constant/header.export.constant';
import QuestionDetailPDF from 'pages/QuestionMaster/PDF/QuestionDetailPDF';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { questionMasterSelector } from 'pages/QuestionMaster/selectors';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import UploadFileToServer from 'components/Modal/UploadFilesToServer';
import { getOptionLimit } from 'containers/CreateEditQuestion/thunk';
import { createEditQuestionSchema } from 'libs/validations';
import ActionErrorModal from 'components/Modal/ActionError';
import { useAppDispatch, usePermission, useUserInfoChanged } from 'hooks';
import { setFilterChanged } from 'pages/Curriculum/slice';
import { authSelector } from 'containers/Auth/selectors';
import { FileType } from 'constant/enum.constant';
import { exportCsv } from 'libs/utils/exportCsv';
import { UploadImageVideoQuestion } from '../';
import { SectionStyled } from './styles';
import { getVideoThumbnail } from 'libs';
import PopupComment from '../Comment';
import { COMPANIES } from 'configs';
import { NoImage2 } from 'assets';
import * as Types from 'types';
import {
  convertAttachFileId,
  handleGetFileQuestionDetail,
  handleGetQuestionDetail,
  handleSubmit,
} from 'libs/utils/question';
import { browserLogger } from 'libs/logger';

interface Props {
  page: number;
  setVisibleSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  openModalCreateEditQuestion: {
    question_id?: string;
    visible: boolean;
    type: 'create' | 'edit';
  };
  setOpenModalCreateEditQuestion: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      type: 'create' | 'edit';
    }>
  >;
  fetchData?: () => void | Promise<void>;
  setchangeQuestion?: React.Dispatch<React.SetStateAction<boolean>>;
  changeQuestion?: boolean;
}

const { Option } = Select;

const initShowActionErrorModal = {
  errorVisible: false,
  title: '',
  subTitle: '',
  description: '',
};

const CreateEditQuestionMaster: React.FC<Props> = ({
  page,
  setOpenModalCreateEditQuestion,
  openModalCreateEditQuestion: { type, visible, question_id },
  setVisibleSuccess,
  fetchData,
  setchangeQuestion,
  changeQuestion,
}) => {
  const [uploading, setUploading] = useState<UploadFileToServerModel>({ show: false, files: [] });
  const [responseQuestionDetail, setResponseQuestionDetail] =
    useState<Types.Questions.ResponseType>();
  const [questionDetail, setQuestionDetail] = useState<Types.CreateEditQuestionFormik>();
  const [showPopupComment, setShowPopupComment] = useState<boolean>(false);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<string[]>([]);
  const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);
  const [isSettingTime, setIsSettingTime] = useState<boolean>(false);
  const [filesInServer, setFilesInServer] = useState<string[]>([]);
  const [showToolTip, setShowTooltip] = useState<boolean>(false);
  const [fileDelete, setFileDelete] = useState<string[]>([]);
  const [, setIndexTime] = useState<number>(0);
  const [loading] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] =
    useState<ErrorModelToOpen>(initShowActionErrorModal);
  const [showPopupUploadMedia, setShowPopupUploadMedia] = useState<{
    show: boolean;
    data: Array<UploadFileAntd<File>>;
    dataName: Array<string>;
    dataProblem: Array<{
      content: string;
      indexProblem: number;
      file: UploadFileAntd<File>;
      fileID: string;
    }>;

    type: Types.UploadFileFieldType;
    fileAmount?: number;
    fistIndex?: number;
  }>({
    show: false,
    data: [],
    dataName: [],
    dataProblem: [],
    type: 'attach',
    fileAmount: 0,
  });

  const ref = useRef(null);

  const { timeLimit, defaultTimeLimit } = useSelector(questionMasterSelector);
  const { userInfo } = useSelector(authSelector);
  const { permissionNumber } = usePermission();

  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const dispatch = useAppDispatch();

  const formik = useFormik<Types.CreateEditQuestionFormik>({
    initialValues: {
      name: '',
      description: '',
      problems1: '',
      problems2: '',
      problems3: '',
      answer: 'A',
      time_limit:
        type === 'create' && timeLimit
          ? defaultTimeLimit
            ? timeLimit[defaultTimeLimit]?.option_id
            : timeLimit[0]?.option_id
          : '',
      comment: '',
      score: 1,
      attach: [],
      comment_attach: [],
      problems1_attach: [],
      problems2_attach: [],
      problems3_attach: [],
      attachFileID: [],
      commentAttachFileID: [],
      problems1AttachFileID: [],
      problems2AttachFileID: [],
      problems3AttachFileID: [],
      commentFileID1: [],
      commentFileID2: [],
      commentFileID3: [],
      attachFileID1: [],
      attachFileID2: [],
      attachFileID3: [],
      comment_attach1: [],
      comment_attach2: [],
      comment_attach3: [],
      attach1: [],
      attach2: [],
      attach3: [],
    },
    enableReinitialize: type === 'edit',
    validationSchema: createEditQuestionSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (isEqual(values, questionDetail)) return;
      handleSubmit({
        dispatch,
        fileIdsToDelete,
        filesInServer,
        handleCancel,
        setFileIdsToDelete,
        setFilesInServer,
        setShowActionErrorModal,
        setUploading,
        type,
        userInfo,
        values,
        i_id: question_id,
        handleFetchData: async () => {
          dispatch(startLoading());
          dispatch(
            setFilterChanged({
              items: {
                type: 'question_name',
                value: values.name,
              },
            })
          );
          fetchData
            ? await fetchData()
            : await dispatch(
                getDataTableQuestion({
                  conditions: [
                    {
                      id: 'provider_id',
                      search_value: [userInfo?.company_id],
                    },
                  ],
                  page: 1,
                  per_page: 0,
                  include_lookups: true,
                })
              );
          dispatch(stopLoading());
          setVisibleSuccess(true);
          setchangeQuestion?.(!changeQuestion);
        },
      });
    },
  });

  const dirtyEdit = useMemo(
    () => isEqual(formik.values, questionDetail),
    [formik.values, questionDetail]
  );

  const handleOnSetVisible = (isVisible: boolean) => {
    setShowActionErrorModal({ subTitle: '', title: '', description: '', errorVisible: isVisible });
  };

  const handleOnChangeVisibleModel = (visibleUploadFileModel: boolean) => {
    setUploading((prevState: UploadFileToServerModel) => ({
      ...prevState,
      show: visibleUploadFileModel,
    }));
  };

  const handleSubmitUploadFiles = (
    data: Array<UploadFileAntd<File> | undefined>,
    fieldType: Types.UploadFileFieldType,
    dataProblem: Array<{
      content: string;
      file: UploadFileAntd<File> | undefined;
    }>,
    dataName: Array<string>,
    index: number
  ) => {
    const fileChange = [];
    if (fieldType === 'problems') {
      fileChange.push(
        ...(formik.values[
          `problems${index + 1}AttachFileID` as keyof Types.CreateEditQuestionFormik
        ] as string[])
      );
      setFileDelete([...fileDelete, ...fileChange]);
      if (!isEmpty(fileChange)) {
        setFileIdsToDelete([...fileDelete, ...fileChange].map((file) => file));
      }
      formik.setFieldValue(
        'problems1_attach',
        dataProblem.find((item) => item.content === 'problems1_attach')?.file
          ? [dataProblem.find((item) => item.content === 'problems1_attach')?.file]
          : []
      );
      formik.setFieldValue(
        'problems2_attach',
        dataProblem.find((item) => item.content === 'problems2_attach')?.file
          ? [dataProblem.find((item) => item.content === 'problems2_attach')?.file]
          : []
      );
      formik.setFieldValue(
        'problems3_attach',
        dataProblem.find((item) => item.content === 'problems3_attach')?.file
          ? [dataProblem.find((item) => item.content === 'problems3_attach')?.file]
          : []
      );
    } else if (fieldType === 'attach') {
      fileChange.push(
        ...(formik.values[
          `attachFileID${index + 1}` as keyof Types.CreateEditQuestionFormik
        ] as string[])
      );
      setFileDelete([...fileDelete, ...fileChange]);
      if (!isEmpty(fileChange)) {
        setFileIdsToDelete([...fileDelete, ...fileChange].map((file) => file));
      }
      formik.setFieldValue(
        'attach1',
        dataProblem.find((item) => item.content === 'problems1_attach')?.file
          ? [dataProblem.find((item) => item.content === 'problems1_attach')?.file]
          : []
      );
      formik.setFieldValue(
        'attach2',
        dataProblem.find((item) => item.content === 'problems2_attach')?.file
          ? [dataProblem.find((item) => item.content === 'problems2_attach')?.file]
          : []
      );
      formik.setFieldValue(
        'attach3',
        dataProblem.find((item) => item.content === 'problems3_attach')?.file
          ? [dataProblem.find((item) => item.content === 'problems3_attach')?.file]
          : []
      );
    }
    if (fieldType === 'comment_attach') {
      fileChange.push(
        ...(formik.values[
          `commentFileID${index + 1}` as keyof Types.CreateEditQuestionFormik
        ] as string[])
      );
      setFileDelete([...fileDelete, ...fileChange]);

      if (!isEmpty(fileChange)) {
        setFileIdsToDelete([...fileDelete, ...fileChange].map((file) => file));
      }
      formik.setFieldValue(
        'comment_attach1',
        dataProblem.find((item) => item.content === 'problems1_attach')?.file
          ? [dataProblem.find((item) => item.content === 'problems1_attach')?.file]
          : []
      );
      formik.setFieldValue(
        'comment_attach2',
        dataProblem.find((item) => item.content === 'problems2_attach')?.file
          ? [dataProblem.find((item) => item.content === 'problems2_attach')?.file]
          : []
      );
      formik.setFieldValue(
        'comment_attach3',
        dataProblem.find((item) => item.content === 'problems3_attach')?.file
          ? [dataProblem.find((item) => item.content === 'problems3_attach')?.file]
          : []
      );
    }
  };

  const handleCancel = () => {
    setOpenModalCreateEditQuestion({ visible: false, type });
    formik.resetForm();
  };

  const fetchCompany = useCallback(() => {
    if (!userInfo) return;

    dispatch(
      getCompany({
        conditions: [
          {
            id: 'id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo]);

  const handleSubmitSelectTimeLimit = async (time: number | string) => {
    dispatch(startLoading());
    const id = find(userInfo?.item_links?.links, ({ d_id }) => d_id === COMPANIES.name)?.items[0]
      ?.i_id;

    let timeNumber = 0;
    if (isString(time)) {
      const index = timeLimit.findIndex((e) => e.option_id === time);
      timeNumber = index;
    }
    const actionResult = await dispatch(
      updateTimeLimitCompany({
        id: id ?? '',
        data: {
          item: {
            time_limit: timeNumber || Number(time),
          },
          is_force_update: true,
        },
      })
    );
    browserLogger.info(
      'page/QuestionMaster/Modal/CreateQuestion  handleSubmitSelectTimeLimit',
      COMPANIES.name,
      actionResult
    );

    if (updateTimeLimitCompany.fulfilled.match(actionResult)) {
      setIsSettingTime(true);
      fetchCompany();
    }

    setTimeout(async () => {
      await setShowTooltip(false);
    }, 2000);

    setTimeout(() => {
      setIsSettingTime(false);
    }, 3000);
    dispatch(stopLoading());
  };

  const handleDeleteAllAttach = () => {
    const arrUidImage = formik.values.attachFileID.map((item) => item);

    if (arrUidImage.length > 0) {
      setFileIdsToDelete((prevIds) => [...prevIds, ...arrUidImage]);
    }
  };

  const handleDeleteAllProblem = () => {
    const uidProblem1 = formik.values.problems1AttachFileID[0];
    const uidProblem2 = formik.values.problems2AttachFileID[0];
    const uidProblem3 = formik.values.problems3AttachFileID[0];

    const arrUidImage = [uidProblem1, uidProblem2, uidProblem3];

    if (arrUidImage.length > 0) {
      setFileIdsToDelete((prevIds) => [...prevIds, ...arrUidImage]);
    }
  };

  const handleDeleteAllComment = () => {
    const arrUidImage = formik.values.commentAttachFileID.map((item) => item);

    if (arrUidImage.length > 0) {
      setFileIdsToDelete((prevIds) => [...prevIds, ...arrUidImage]);
    }
  };

  const handleShowModalUploadFile = (fieldType: Types.UploadFileFieldType) => {
    const fileAmount = fieldType === 'attach' || fieldType === 'comment_attach' ? 3 : 1;
    const data =
      fieldType === 'problems'
        ? [
            formik.values.problems1_attach[0],
            formik.values.problems2_attach[0],
            formik.values.problems3_attach[0],
          ].filter((file) => file ?? false)
        : fieldType === 'attach'
        ? [formik.values.attach1[0], formik.values.attach3[0], formik.values.attach3[0]].filter(
            (file) => file ?? false
          )
        : fieldType === 'comment_attach'
        ? [
            formik.values.comment_attach1[0],
            formik.values.comment_attach1[0],
            formik.values.comment_attach1[0],
          ].filter((file) => file ?? false)
        : [];

    const dataName =
      fieldType === 'attach'
        ? [
            formik.values.attachFileID1[0],
            formik.values.attachFileID2[0],
            formik.values.attachFileID3[0],
          ].filter((file) => file ?? false)
        : fieldType === 'comment_attach'
        ? [
            formik.values.commentFileID1[0],
            formik.values.commentFileID2[0],
            formik.values.commentFileID3[0],
          ].filter((file) => file ?? false)
        : fieldType === 'problems'
        ? [
            formik.values.problems1AttachFileID[0],
            formik.values.problems2AttachFileID[0],
            formik.values.problems3AttachFileID[0],
          ].filter((file) => file ?? false)
        : [];

    const dataProblem = [
      {
        content: 'problems1_attach',
        indexProblem: 1,
        file:
          fieldType === 'problems'
            ? formik.values.problems1_attach[0]
            : fieldType === 'attach'
            ? formik.values.attach1[0]
            : formik.values.comment_attach1[0],
        fileID:
          fieldType === 'problems'
            ? formik.values.problems1AttachFileID[0]
            : fieldType === 'attach'
            ? formik.values.attachFileID1[0]
            : formik.values.commentFileID1[0],
      },
      {
        content: 'problems2_attach',
        indexProblem: 2,
        file:
          fieldType === 'problems'
            ? formik.values.problems2_attach[0]
            : fieldType === 'attach'
            ? formik.values.attach2[0]
            : formik.values.comment_attach2[0],
        fileID:
          fieldType === 'problems'
            ? formik.values.problems2AttachFileID[0]
            : fieldType === 'attach'
            ? formik.values.attachFileID2[0]
            : formik.values.commentFileID2[0],
      },
      {
        content: 'problems3_attach',
        indexProblem: 3,
        file:
          fieldType === 'problems'
            ? formik.values.problems3_attach[0]
            : fieldType === 'attach'
            ? formik.values.attach3[0]
            : formik.values.comment_attach3[0],
        fileID:
          fieldType === 'problems'
            ? formik.values.problems3AttachFileID[0]
            : fieldType === 'attach'
            ? formik.values.attachFileID3[0]
            : formik.values.commentFileID3[0],
      },
    ].filter((file) => file.file !== undefined);
    const minIndexItem = minBy(dataProblem, 'indexProblem')?.indexProblem || 0;

    setShowPopupUploadMedia({
      show: true,
      data: data,
      dataName: dataName,
      type: fieldType,
      fileAmount,
      dataProblem: dataProblem,
      fistIndex: minIndexItem,
    });
  };

  useEffect(() => {
    if (!visible) return;

    handleGetQuestionDetail({
      dispatch,
      handleCancel,
      setFilesInServer,
      type,
      i_id: question_id,
      formik,
      setQuestionDetail,
      setResponseQuestionDetail,
    });

    handleGetFileQuestionDetail({
      dispatch,
      handleCancel,
      setFilesInServer,
      type,
      i_id: question_id,
      formik,
      setQuestionDetail,
      setResponseQuestionDetail,
    });

    return () => {
      formik.setValues({
        name: '',
        description: '',
        problems1: '',
        problems2: '',
        problems3: '',
        answer: '',
        time_limit: '',
        comment: '',
        score: Number(0),
        attach: [],
        comment_attach: [],
        problems1_attach: [],
        problems2_attach: [],
        problems3_attach: [],
        attachFileID: [],
        commentAttachFileID: [],
        problems1AttachFileID: [],
        problems2AttachFileID: [],
        problems3AttachFileID: [],
        commentFileID1: [],
        commentFileID2: [],
        commentFileID3: [],
        attachFileID1: [],
        attachFileID2: [],
        attachFileID3: [],
        comment_attach1: [],
        comment_attach2: [],
        comment_attach3: [],
        attach1: [],
        attach2: [],
        attach3: [],
      });
      setFilesInServer([]);
      setFileIdsToDelete([]);
      setUploading({ show: false, files: [] });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, type, visible]);

  useEffect(() => {
    if (type === 'create' && timeLimit) {
      const time_limit = defaultTimeLimit
        ? timeLimit[defaultTimeLimit]?.option_id
        : timeLimit[0]?.option_id;
      formik.setFieldValue('time_limit', time_limit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTimeLimit, timeLimit, type]);

  useEffect(() => {
    setIndexTime(defaultTimeLimit);
  }, [defaultTimeLimit]);

  useEffect(() => {
    if (!visible) return;
    if (userInfo && isUserInfoChanged) {
      Promise.all([
        dispatch(
          getCompany({
            conditions: [
              {
                id: 'id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(getOptionLimit()),
      ]);
    }
  }, [dispatch, userInfo, isUserInfoChanged, visible]);

  useEffect(() => {
    if (!visible) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleExportPDF = async (value: string) => {
    if (value === 'pdf') {
      await onExportPdf();
    } else {
      onExportCsv();
    }
    setVisibleConfirm(false);
  };

  const getThumbnail = async (i: number, imageAttach: UploadFileAntd<File>[]) => {
    if (i < imageAttach.length) {
      const attach = imageAttach[i];
      if (attach?.type === FileType.MP4 || includes(attach as unknown as string, 'mp4')) {
        const thumbnail = await getVideoThumbnail(
          attach?.originFileObj ? attach.originFileObj : (attach as unknown as string)
        );
        return { src: thumbnail, isVideo: true };
      } else {
        return {
          src: attach.originFileObj
            ? URL.createObjectURL(new Blob([attach?.originFileObj as Blob]))
            : attach
            ? (attach as unknown as string)
            : NoImage2,
        };
      }
    } else {
      return {
        src: NoImage2,
      };
    }
  };

  const onExportPdf = async () => {
    const defaultImageCount = 3;
    const imageAttach = formik.values.attach;
    const imageCommentAttach = formik.values.comment_attach;
    const imageAttachThumbnails = [];
    const imageProblemThumbnails = [];
    const imageCommentThumbnails = [];
    for (let i = 0; i < defaultImageCount; i++) {
      const imageThumbnail = await getThumbnail(i, imageAttach);
      imageAttachThumbnails.push(imageThumbnail);
      const imageCommentThumbnail = await getThumbnail(i, imageCommentAttach);
      imageCommentThumbnails.push(imageCommentThumbnail);

      const key = `problems${i + 1}_attach` as keyof Types.CreateEditQuestionFormik;
      const problemArray = formik.values[key] as UploadFileAntd<File>[] | undefined;
      const problem = problemArray ? problemArray[0] : undefined;

      const labels = ['A', 'B', 'C'];
      const label = labels[i];

      const isVideo =
        problem?.type === FileType.MP4 || includes(problem as unknown as string, 'mp4');
      if (isVideo) {
        const thumbnail = await getVideoThumbnail(
          problem?.originFileObj ? problem.originFileObj : (problem as unknown as string)
        );
        imageProblemThumbnails.push({
          src: thumbnail,
          isVideo: true,
          label: `選択肢${label}`,
        });
      } else {
        imageProblemThumbnails.push({
          src: problem?.originFileObj
            ? URL.createObjectURL(new Blob([problem?.originFileObj as Blob]))
            : problem
            ? (problem as unknown as string)
            : NoImage2,
          label: `選択肢${label}`,
        });
      }
    }

    const blob = await pdf(
      <PDFQuestionMasterDetail
        values={formik.values}
        timeLimit={timeLimit}
        imageAttachThumbnails={imageAttachThumbnails}
        imageProblemThumbnails={imageProblemThumbnails}
        imageCommentThumbnails={imageCommentThumbnails}
      />
    ).toBlob();
    saveAs(blob, '設問マスタ_詳細.pdf');
  };

  const onExportCsv = () => {
    const itemCsv = {
      name: responseQuestionDetail?.name,
      i_id: responseQuestionDetail?.code,
      description: responseQuestionDetail?.description,
      attach_fileID1: convertAttachFileId(
        responseQuestionDetail?.attach_fileID1!,
        responseQuestionDetail?.code!,
        1,
        'attach1'
      ),
      attach_fileID2: convertAttachFileId(
        responseQuestionDetail?.attach_fileID2!,
        responseQuestionDetail?.code!,
        1,
        'attach2'
      ),
      attach_fileID3: convertAttachFileId(
        responseQuestionDetail?.attach_fileID3!,
        responseQuestionDetail?.code!,
        1,
        'attach3'
      ),
      problems1: responseQuestionDetail?.problems1,
      problems2: responseQuestionDetail?.problems2,
      problems3: responseQuestionDetail?.problems3,
      problems1_attach_fileID: convertAttachFileId(
        responseQuestionDetail?.problems1_attach_fileID!,
        responseQuestionDetail?.code!,
        1,
        'problems1_attach'
      ),
      problems2_attach_fileID: convertAttachFileId(
        responseQuestionDetail?.problems2_attach_fileID!,
        responseQuestionDetail?.code!,
        1,
        'problems2_attach'
      ),
      problems3_attach_fileID: convertAttachFileId(
        responseQuestionDetail?.problems3_attach_fileID!,
        responseQuestionDetail?.code!,
        1,
        'problems3_attach'
      ),
      answer: responseQuestionDetail?.answer,
      comment: responseQuestionDetail?.comment,
      comment_attach_fileID1: convertAttachFileId(
        responseQuestionDetail?.comment_attach_fileID1!,
        responseQuestionDetail?.code!,
        1,
        'comment_attach1'
      ),
      comment_attach_fileID2: convertAttachFileId(
        responseQuestionDetail?.comment_attach_fileID2!,
        responseQuestionDetail?.code!,
        1,
        'comment_attach2'
      ),
      comment_attach_fileID3: convertAttachFileId(
        responseQuestionDetail?.comment_attach_fileID3!,
        responseQuestionDetail?.code!,
        1,
        'comment_attach3'
      ),
      time_limit: responseQuestionDetail?.time_limit,
      score: responseQuestionDetail?.score,
      createdby: responseQuestionDetail?.creator,
    };
    exportCsv([itemCsv], HEADER_QUESTION_MASTER_CSV, '設問マスタ_詳細.csv');
  };

  const CustomHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            margin: 0,
          }}
        >
          設問編集
        </p>
        <Button
          style={{
            width: '160px',
            height: '40px',
            borderRadius: '5px',
            background: '#f6ac00',
            color: '#ffffff',
            right: 13,
            position: 'absolute',
            fontWeight: '700',
          }}
          onClick={() => setVisibleConfirm(true)}
        >
          エクスポート
        </Button>
      </div>
    );
  };

  const RenderAttach = (formikValues: Types.CreateEditQuestionFormik, problemKey: number) => {
    const key = `attach${problemKey}` as keyof Types.CreateEditQuestionFormik;

    const problemArray = formikValues[key] as UploadFile<File>[] | undefined;
    const problem = problemArray ? problemArray[0] : undefined;

    const isVideo = problem?.type === FileType.MP4 || includes(problem as unknown as string, 'mp4');

    return useMemo(
      () => (
        <>
          <div className="image" key={problemKey}>
            {isVideo ? (
              <ReactPlayer
                crossOrigin="anonymous"
                width={'100%'}
                height={'100%'}
                url={
                  problem?.originFileObj
                    ? URL.createObjectURL(new Blob([problem.originFileObj as Blob]))
                    : (problem as unknown as string)
                }
                controls
                config={{
                  file: {
                    attributes: {
                      disablePictureInPicture: true,
                      controlsList: 'nodownload noplaybackrate',
                    },
                  },
                }}
              />
            ) : (
              <img
                crossOrigin="anonymous"
                src={
                  problem?.originFileObj
                    ? URL.createObjectURL(new Blob([problem.originFileObj as Blob]))
                    : problem
                    ? (problem as unknown as string)
                    : NoImage2
                }
                className="image-question"
                alt={problem?.name}
              />
            )}
          </div>
        </>
      ),
      [formik.values.attach1, formik.values.attach2, formik.values.attach3, isVideo, problemKey]
    );
  };

  const RenderComment = (formikValues: Types.CreateEditQuestionFormik, problemKey: number) => {
    const key = `comment_attach${problemKey}` as keyof Types.CreateEditQuestionFormik;

    const problemArray = formikValues[key] as UploadFile<File>[] | undefined;
    const problem = problemArray ? problemArray[0] : undefined;

    const isVideo = problem?.type === FileType.MP4 || includes(problem as unknown as string, 'mp4');

    return useMemo(
      () => (
        <>
          <div className="image" key={problemKey}>
            {isVideo ? (
              <ReactPlayer
                crossOrigin="anonymous"
                width={'100%'}
                height={'100%'}
                url={
                  problem?.originFileObj
                    ? URL.createObjectURL(new Blob([problem.originFileObj as Blob]))
                    : (problem as unknown as string)
                }
                controls
                config={{
                  file: {
                    attributes: {
                      disablePictureInPicture: true,
                      controlsList: 'nodownload noplaybackrate',
                    },
                  },
                }}
              />
            ) : (
              <img
                crossOrigin="anonymous"
                src={
                  problem?.originFileObj
                    ? URL.createObjectURL(new Blob([problem.originFileObj as Blob]))
                    : problem
                    ? (problem as unknown as string)
                    : NoImage2
                }
                className="image-question"
                alt={problem?.name}
              />
            )}
          </div>
        </>
      ),
      [
        formik.values.comment_attach1,
        formik.values.comment_attach2,
        formik.values.comment_attach3,
        isVideo,
        problemKey,
      ]
    );
  };

  const RenderProblem = (formikValues: Types.CreateEditQuestionFormik, problemKey: number) => {
    const key = `problems${problemKey}_attach` as keyof Types.CreateEditQuestionFormik;

    const problemArray = formikValues[key] as UploadFile<File>[] | undefined;
    const problem = problemArray ? problemArray[0] : undefined;

    const labels = ['A', 'B', 'C'];
    const label = labels[problemKey - 1];

    const isVideo = problem?.type === FileType.MP4 || includes(problem as unknown as string, 'mp4');

    return useMemo(
      () => (
        <>
          <div className="image" key={problemKey}>
            {isVideo ? (
              <ReactPlayer
                crossOrigin="anonymous"
                width={'100%'}
                height={'100%'}
                url={
                  problem?.originFileObj
                    ? URL.createObjectURL(new Blob([problem.originFileObj as Blob]))
                    : (problem as unknown as string)
                }
                controls
                config={{
                  file: {
                    attributes: {
                      disablePictureInPicture: true,
                      controlsList: 'nodownload noplaybackrate',
                    },
                  },
                }}
              />
            ) : (
              <img
                crossOrigin="anonymous"
                src={
                  problem?.originFileObj
                    ? URL.createObjectURL(new Blob([problem.originFileObj as Blob]))
                    : problem
                    ? (problem as unknown as string)
                    : NoImage2
                }
                className="image-question"
                alt={problem?.name}
              />
            )}
          </div>
          <p className="label">{`選択肢${label}`}</p>
        </>
      ),
      [
        formik.values.problems1_attach,
        formik.values.problems2_attach,
        formik.values.problems3_attach,
        isVideo,
        problemKey,
      ]
    );
  };

  const component = useMemo(() => {
    return (
      <div
        ref={ref}
        style={{
          width: '100%',
          position: 'absolute',
          right: '9999px',
        }}
      >
        <QuestionDetailPDF
          data={formik.values}
          imageAttach={RenderAttach}
          imageComment={RenderComment}
          renderProblem={RenderProblem}
          type={type}
          timeLimit={timeLimit}
        />
      </div>
    );
  }, [formik.values, RenderAttach, RenderProblem, RenderComment, type, timeLimit]);

  return (
    <Modal
      title={type === 'create' ? '設問新規作成' : <CustomHeader />}
      width={860}
      open={visible}
      okButton={{
        text: type === 'create' ? '登録' : '更新',
        onClick: formik.handleSubmit,
        disabled: !formik.isValid || !formik.dirty || dirtyEdit || permissionNumber !== 2,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: () => setOpenModalCreateEditQuestion({ visible: false, type }),
      }}
      onCancel={() => setOpenModalCreateEditQuestion({ visible: false, type })}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        {component}
        <p className="sub-title">
          {type === 'create'
            ? '設問の新規作成画面です。入力後に登録ボタンをクリックしてください。'
            : '設問の内容確認と編集用の画面です。編集した場合は更新ボタンをクリックしてください。'}
        </p>
        <SpinLoading size="large" loading={loading}>
          <FormikProvider value={formik}>
            <Form colon={false} layout="vertical" className="form">
              <p className="label-note">
                <span className="number">1</span>設問内容
              </p>
              <Form.Item
                name="name"
                label={
                  <span className="text-label">
                    設問名
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  name="name"
                  className="input"
                  type="text"
                  showCount
                  maxLength={30}
                  placeholder="最大30文字"
                />
              </Form.Item>
              <Form.Item
                name="description"
                label={
                  <span className="text-label">
                    設問内容
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="description"
                  className="input-textarea"
                  showCount
                  maxLength={480}
                  placeholder="最大480文字"
                />
              </Form.Item>
              <Form.Item name="attach" className="form-input">
                <div className="wrap-flex">
                  <p className="text-label">設問画像・動画 </p>
                  <button
                    className="btn_primary"
                    type="button"
                    onClick={() => handleShowModalUploadFile('attach')}
                  >
                    画像・動画を設定
                  </button>
                  <p className="text-note">※画像・動画は設問の下に挿入されます</p>
                </div>
              </Form.Item>
              <div className="wrap-show-image">
                <div className="flex-image">
                  <p className="text-label">設定状況：</p>
                  {[1, 2, 3].map((problemKey) => (
                    <div key={problemKey}>{RenderAttach(formik.values, problemKey)}</div>
                  ))}
                </div>
                <div
                  className={`text-clear${
                    formik.values.attach1?.length ||
                    formik.values.attach2?.length ||
                    formik.values.attach3?.length
                      ? ''
                      : '__disabled'
                  }`}
                  onClick={() => {
                    formik.setFieldValue('attach1', []);
                    formik.setFieldValue('attach2', []);
                    formik.setFieldValue('attach3', []);
                    handleDeleteAllAttach();
                  }}
                >
                  クリア
                </div>
              </div>
              <div className="mr-30" />
              <p className="label-note">
                <span className="number">2</span>解答選択肢
              </p>
              <Form.Item
                name="problems1"
                label={
                  <span className="text-label">
                    選択肢A
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="problems1"
                  className="input-textarea-small"
                  showCount
                  maxLength={60}
                  placeholder={'最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                />
              </Form.Item>
              <Form.Item
                name="problems2"
                label={
                  <span className="text-label">
                    選択肢B
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="problems2"
                  className="input-textarea-small"
                  showCount
                  maxLength={60}
                  placeholder={'最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                />
              </Form.Item>
              <Form.Item
                name="problems3"
                label={
                  <span className="text-label">
                    選択肢C
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="problems3"
                  className="input-textarea-small"
                  showCount
                  maxLength={60}
                  placeholder={'最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                />
              </Form.Item>
              <Form.Item name="problems" className="form-input">
                <div className="wrap-flex">
                  <p className="text-label">解答選択肢画像・動画</p>
                  <button
                    className="btn_primary"
                    type="button"
                    onClick={() => handleShowModalUploadFile('problems')}
                  >
                    画像・動画を設定
                  </button>
                  <p className="text-note">※画像・動画は設問の下に挿入されます</p>
                </div>
              </Form.Item>
              <div className="wrap-show-image">
                <div className="flex-image">
                  <p className="text-label">設定状況：</p>
                  {[1, 2, 3].map((problemKey) => (
                    <div key={problemKey}>{RenderProblem(formik.values, problemKey)}</div>
                  ))}
                </div>
                <div
                  className={`text-clear${
                    formik.values.problems1_attach?.length ||
                    formik.values.problems2_attach?.length ||
                    formik.values.problems3_attach?.length
                      ? ''
                      : '__disabled'
                  }`}
                  onClick={() => {
                    formik.setFieldValue('problems1_attach', []);
                    formik.setFieldValue('problems2_attach', []);
                    formik.setFieldValue('problems3_attach', []);
                    handleDeleteAllProblem();
                  }}
                >
                  クリア
                </div>
              </div>
              <Form.Item
                name="answer"
                label={
                  <span className="text-label">
                    解答
                    <span className="require">*</span>
                  </span>
                }
                className="form-input form-input-radio"
              >
                <Radio.Group name="answer" className="radio_group">
                  <Radio name="answer" value="A" className="radio-text">
                    選択肢A
                  </Radio>
                  <Radio name="answer" value="B" className="radio-text">
                    選択肢B
                  </Radio>
                  <Radio name="answer" value="C" className="radio-text">
                    選択肢C
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <div className="mr-30" />
              <p className="label-note">
                <span className="number">3</span>解説・その他設定
              </p>
              <Form.Item
                name="comment"
                label={
                  <div className="wrap-label-form-input">
                    <span className="text-label">
                      解説
                      <span className="require">*</span>
                    </span>
                    <span className="text-enlarge" onClick={() => setShowPopupComment(true)}>
                      <SelectOutlined className="icon" />
                      入力欄を拡大
                    </span>
                  </div>
                }
                className="form-input"
              >
                <TextArea
                  name="comment"
                  className="input-textarea"
                  showCount
                  maxLength={1050}
                  placeholder="最大1050文字"
                />
              </Form.Item>
              <Form.Item name="comment_attach" className="form-input">
                <div className="wrap-flex">
                  <p className="text-label">解説画像・動画</p>
                  <button
                    className="btn_primary"
                    type="button"
                    onClick={() => handleShowModalUploadFile('comment_attach')}
                  >
                    画像・動画を設定
                  </button>
                  <p className="text-note">※画像・動画は設問の下に挿入されます</p>
                </div>
              </Form.Item>
              <div className="wrap-show-image">
                <div className="flex-image">
                  <p className="text-label">設定状況：</p>
                  {[1, 2, 3].map((problemKey) => (
                    <div key={problemKey}>{RenderComment(formik.values, problemKey)}</div>
                  ))}
                </div>
                <div
                  className={`text-clear${
                    formik.values.comment_attach1?.length ||
                    formik.values.comment_attach2?.length ||
                    formik.values.comment_attach3?.length
                      ? ''
                      : '__disabled'
                  }`}
                  onClick={() => {
                    formik.setFieldValue('comment_attach1', []);
                    formik.setFieldValue('comment_attach2', []);
                    formik.setFieldValue('comment_attach3', []);
                    handleDeleteAllComment();
                  }}
                >
                  クリア
                </div>
              </div>
              <Form.Item
                name="time_limit"
                label={
                  <span className="text-label">
                    制限時間
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <SelectField name="time_limit" className="time_limit">
                  {timeLimit.map((item) => (
                    <Option key={item.sort_id} value={item.option_id}>
                      {item.value}
                    </Option>
                  ))}
                </SelectField>
                <Tooltip
                  trigger={['click']}
                  overlayClassName="tooltip-setup-time-limit"
                  open={showToolTip}
                  onOpenChange={setShowTooltip}
                  placement="topLeft"
                  title={
                    <>
                      {isSettingTime ? (
                        <div className="text-content">
                          <CheckCircleOutlined className="icon" />
                          設定しました
                        </div>
                      ) : (
                        <>
                          <div className="text-content">
                            現在設定されている制限時間を設問新規作 <br />
                            成時の制限時間初期値として設定します。
                          </div>
                          <div className="box-center">
                            <Button
                              className="btn-set-time"
                              onClick={() => {
                                handleSubmitSelectTimeLimit(formik.values.time_limit);
                              }}
                            >
                              設定する
                            </Button>
                          </div>
                        </>
                      )}
                    </>
                  }
                >
                  <ClockCircleOutlined className="watch-icon" />
                  <span className="time-setting">制限時間初期値設定</span>
                </Tooltip>
              </Form.Item>
              <Row>
                <Col span={24}>
                  <div className="form-input-radio">
                    <span className="text-label">
                      スコア設定（設問難易度の設定）
                      <span className="require">*</span>
                      <Tooltip
                        trigger={['click']}
                        overlayClassName="question-tooltip"
                        title={
                          <>
                            <h6
                              style={{
                                fontFamily: 'Noto Sans Javanese',
                                fontWeight: '400',
                                fontSize: 16,
                                color: '#2A2A2A',
                              }}
                            >
                              スコア設定（設問難易度の設定）について
                            </h6>
                            <p>
                              スコアは設問の難易度を設定する項目です。
                              <br />
                              設定の際は「１・２・３」の３段階からスコアを選択します。
                              <br />
                              <br />
                              スコアは設問の難易度を表すと共に、獲得スコアとして得点も表します。
                              <br />
                              スキルチェック実施の際に、正解した設問に設定されているスコアを獲得できます。
                              <br />
                              <br />
                              設定したスコアの情報や獲得したスコアの結果はレポートから確認することができます。
                              <br />
                              <br />
                              獲得したスコアから、スキルチェックの結果が難易度の高い設問を多く正解できているか、難易度の低い設問を多く
                              <br />
                              間違えているので、基礎ができていないか、ケアレスミスで間違えている等の分析ができる仕組みです。
                              <br />
                              <br />
                              正解した数や割合だけでなく、質をはかる指標です。
                              <br />
                              <br />
                              スキルチェック実施後は、ぜひスコアを活用してみてください。
                            </p>
                          </>
                        }
                      >
                        <QuestionCircleOutlined className="question-mark-icon" />
                      </Tooltip>
                    </span>
                    <Radio.Group name="score" className="radio_group">
                      <Radio name="score" value={1} className="radio-text">
                        1
                      </Radio>
                      <Radio name="score" value={2} className="radio-text">
                        2
                      </Radio>
                      <Radio name="score" value={3} className="radio-text">
                        3
                      </Radio>
                    </Radio.Group>
                  </div>
                </Col>
              </Row>
            </Form>
          </FormikProvider>
        </SpinLoading>
        {/* <UploadImageVideo
          visible={showPopupUploadMedia}
          setVisible={setShowPopupUploadMedia}
          onSubmit={handleSubmitUploadFiles}
        /> */}
        <UploadImageVideoQuestion
          visible={showPopupUploadMedia}
          setVisible={setShowPopupUploadMedia}
          onSubmit={handleSubmitUploadFiles}
        />
        <ActionErrorModal
          visible={showActionErrorModal.errorVisible}
          description={showActionErrorModal.description}
          subTitle={showActionErrorModal.subTitle}
          title={showActionErrorModal.title}
          setVisible={handleOnSetVisible}
        />
        <PopupComment
          visible={showPopupComment}
          setVisible={setShowPopupComment}
          commentValues={formik.values.comment}
          onSubmit={(values) => {
            setShowPopupComment(false);
            formik.setFieldValue('comment', values);
          }}
        />
        <UploadFileToServer
          uploadingFiles={uploading.files}
          visible={uploading.show}
          setVisible={(isVisible: boolean) => handleOnChangeVisibleModel(isVisible)}
          title={'アップロード中'}
        />
        <PopupConfirmExportFile
          visible={visibleConfirm}
          setVisible={setVisibleConfirm}
          onSubmit={handleExportPDF}
          customTitle="出力形式を選択して、OKボタンをクリックしてください。"
        />
      </SectionStyled>
    </Modal>
  );
};

export default CreateEditQuestionMaster;
