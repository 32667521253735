import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';
import { Document, Font, Page, Text, View } from '@react-pdf/renderer';
import styles from './styles';
import _ from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import { DownIconPdf, FileOutlinedPdf } from 'assets/svg/svgPDF';
import dayjs from 'dayjs';
import * as Types from 'types';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface Props {
  dataBillingDetail: Types.BillingDataDetail.ResponseType[];
  provided_date?: string;
  user_type?: number | undefined;
  payment_method?: string | undefined;
  status?: string;
  billing_type?: number | undefined;
  billing_id?: string;
}

const FIRST_PAGE_ROW_COUNT = 13;
const PAGE_ROW_COUNT = 18;

const BillingDataTable = ({
  dataSource,
  paymentMethod,
}: {
  dataSource: Types.BillingDataDetail.ResponseType[];
  paymentMethod: string | undefined;
}) => {
  const columns: { title: string | string[]; width: string | number; key: string }[] = [
    {
      title: ['役務提供年月・請求データ番号', '請求データ名称'],
      width: 300,
      key: 'provided_date',
    },
    {
      title: ['ユーザーID', 'ユーザー名'],
      width: 150,
      key: 'company_id',
    },
    {
      title: '決済手段',
      width: 80,
      key: 'payment_method',
    },
    {
      title: '請求日',
      width: 100,
      key: 'billing_date',
    },
    {
      title: 'アカウント数',
      width: 60,
      key: 'num_of_accounts',
    },
    {
      title: '金額',
      width: 60,
      key: 'amount',
    },
    {
      title: '請求書',
      width: 40,
      key: 'operation',
    },
  ];

  const isStringArray = (val: any) => {
    return !!Array.isArray(val);
  };

  const formatDateData = (val: string | Date, formatType: 'YYYY/MM' | 'YYYY/MM/DD') => {
    if (val) {
      return dayjs(val).format(formatType);
    }
    return '-';
  };

  return (
    <View style={styles.table}>
      <View
        style={[
          styles.tableRow,
          styles.tableHeaderRow,
          { height: '26.728px', borderBottom: 'none' },
        ]}
      >
        {columns.map((column, index) =>
          isStringArray(column.title) ? (
            <View
              key={index}
              style={[
                styles.wrapCell,
                { width: column.width, display: 'flex', flexDirection: 'column' },
              ]}
            >
              <Text style={[styles.tableCell, styles.headerCell]}>{column.title[0]}</Text>
              <Text style={[styles.tableCell, styles.headerCell]}>{column.title[1]}</Text>
            </View>
          ) : (
            <View key={index} style={[styles.wrapCell, { width: column.width }]}>
              <Text style={[styles.tableCell, styles.headerCell, { textAlign: 'center' }]}>
                {column.title}
              </Text>
            </View>
          )
        )}
      </View>

      {dataSource.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            { borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5' },
          ]}
        >
          <View style={[styles.wrapCell, { width: columns[0].width }]}>
            <View style={{ flexDirection: 'row', gap: '20px', color: '#777777' }}>
              <Text style={styles.tableCell}>{formatDateData(row.provided_date, 'YYYY/MM')}</Text>
              <Text style={styles.tableCell}>{row.billing_id}</Text>
            </View>
            <Text style={styles.tableCell}>{row.billing_data_name}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[1].width }]}>
            <Text style={[styles.tableCell, { color: '#777777' }]}>{row.company_id}</Text>
            <Text style={styles.tableCell}>{row.company_name}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[2].width }]}>
            <Text style={[styles.tableCell, { textAlign: 'center' }]}>{paymentMethod ?? ''}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[3].width }]}>
            <Text style={[styles.tableCell, { textAlign: 'center' }]}>
              {formatDateData(row.billing_date, 'YYYY/MM/DD')}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[4].width }]}>
            <Text style={[styles.tableCell, { textAlign: 'right' }]}>{row.num_of_accounts}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[5].width }]}>
            <Text style={[styles.tableCell, { textAlign: 'center' }]}>{row.amount}</Text>
          </View>
          <View style={[styles.wrapCell, styles.flexCenter, { width: columns[6].width }]}>
            <FileOutlinedPdf style={{ transform: 'scale(0.7)' }} />
          </View>
        </View>
      ))}
    </View>
  );
};

const PDFBillingDataDetail = ({ dataBillingDetail, payment_method }: Props) => {
  const renderHeader = () => (
    <View style={styles.header}>
      <Text style={styles.textHeader}>請求データ明細［法人・請求書］</Text>
    </View>
  );

  const renderSearchPanel = () => (
    <View style={styles.searchPanel}>
      <View style={styles.searchItem}>
        <Text style={styles.label}>ユーザーID</Text>
        <View style={styles.selectBox}>
          <DownIconPdf style={styles.selectIcon} />
        </View>
      </View>
      <View style={styles.searchItem}>
        <Text style={styles.label}>ユーザー名</Text>
        <View style={styles.selectBox}>
          <DownIconPdf style={styles.selectIcon} />
        </View>
      </View>
    </View>
  );

  const renderBody = (data: Types.BillingDataDetail.ResponseType[], pageNumber: number) => {
    const startIndex = (pageNumber - 1) * FIRST_PAGE_ROW_COUNT + 1;
    const endIndex =
      pageNumber * FIRST_PAGE_ROW_COUNT > dataBillingDetail.length
        ? dataBillingDetail.length
        : pageNumber * FIRST_PAGE_ROW_COUNT;

    return (
      <View style={styles.body}>
        <View style={styles.textCountGeneral}>
          <Text style={styles.textCountNumber}>{startIndex}</Text>
          <Text style={styles.textCountNumber}>~</Text>
          <Text style={styles.textCountNumber}>{endIndex}</Text>
          <Text style={styles.textCountNumber}>/</Text>
          <Text style={styles.textCountNumber}>{dataBillingDetail.length}</Text>
          <Text style={styles.textCountDescription}>件</Text>
        </View>
        <BillingDataTable dataSource={data} paymentMethod={payment_method} />
      </View>
    );
  };

  const renderPage = (data: Types.BillingDataDetail.ResponseType[], index: number) => (
    <Page
      key={index}
      size={pageSize}
      orientation={pageOrientation}
      style={styles.page}
      wrap={false}
    >
      <FullPage>{renderBody(data, index)}</FullPage>
      <PageNumber />
    </Page>
  );

  const pages = _.chunk(dataBillingDetail.slice(FIRST_PAGE_ROW_COUNT), PAGE_ROW_COUNT);
  const firstPageData = dataBillingDetail.slice(0, FIRST_PAGE_ROW_COUNT);

  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap>
        <FullPage>
          {renderHeader()}
          {renderSearchPanel()}
          {renderBody(firstPageData, 1)}
        </FullPage>
        <PageNumber />
      </Page>
      {pages.map((page, index) => renderPage(page, index + 2))}
    </Document>
  );
};

export default PDFBillingDataDetail;
