import {
  CloudDownloadOutlined,
  ContainerOutlined,
  FormOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { pdf } from '@react-pdf/renderer';
import { nanoid } from '@reduxjs/toolkit';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Key } from 'antd/lib/table/interface';
import { StopPublic, TickComplete, UnPublished, Unpublished, WhiteEditing } from 'assets';
import { Header, PagingNumber, SelectField } from 'components';
import ArchiveCompleted from 'components/Modal/ArchiveCompleted';
import ConfirmArchive from 'components/Modal/ConfirmArchive';
import Info from 'components/Modal/Info';
import PerPageSelect from 'components/PerPageSelect';
import { HEADER_OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS_EXPORT_CSV } from 'constant/header.export.constant';
import { SEARCH_PUBLISH_OPTION, SELECT_RECORD } from 'constant/select.constants';
import { settingSelector } from 'containers/AppSettings/selectors';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { authSelector } from 'containers/Auth/selectors';
import dayjs from 'dayjs';
import saveAs from 'file-saver';
import { FormikProvider, useFormik } from 'formik';
import { Form, Select, SubmitButton } from 'formik-antd';
import { useAppDispatch, usePermission } from 'hooks';
import { convertBase64ToFile } from 'libs/utils/convertBase64';
import { find, unionBy, uniqBy } from 'lodash';
import { curriculumSelector } from 'pages/Curriculum/selectors';
import { getDataCurriculum } from 'pages/Curriculum/thunk';
import PopupConfirmExportFile from 'pages/UserManagement/AuthorityMaster/ConfirmExportFile';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Types from 'types';
import ArchiveList from './Modal/ArchiveList';
import Changed from './Modal/Changed';
import CreateCurriculum from './Modal/CreateCurriculum';
import DisabledActionModal from './Modal/DisableAction';
import InformationModal from './Modal/Information';
import Index from './Modal/ManageRelease';
import ReleaseNote from './Modal/ReleaseNote';
import UpdateStatus from './Modal/UpdateStatus';
import PDFOfficialCurriculumDocument from './PDFOfficialCurriculum';
import { publicManagerSelector } from './selectors';
import { filterCreatedBy } from './slice';
import PublicStyled from './styles';
import {
  createCurriculumPublishedHistory,
  createFileAttach,
  getDataPublishManagement,
  getDataReleaseNoteID,
  getDataUsers,
  getOfficialPublishSelect,
  getReleaseNoteDetail,
  getReleaseNoteList,
  updateCurriculumPublishedHistory,
  updateOfficialCurriculumMaster,
  updateReleaseNote,
} from './thunk';
import { getPresignedUrlByFileID, uploadFileToR2 } from 'services/r2Service';
import HasNotQuestion from './Modal/HasNotQuestionError';
import { exportCsv } from 'libs/utils/exportCsv';

const { Option } = Select;

const PublicManagement = () => {
  const [archiveItem, setArchiveItem] =
    useState<Types.OfficialCurriculumPublishingSettings.ResponseType>();
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [status, setStatus] = useState<'publish' | 'editing' | 'publishWarning'>('editing');
  const [releaseNoteModalVisible, setReleaseNoteModalVisible] = useState<boolean>(false);
  const [openErrorHasNotQuestion, seteOpenErrorHasNotQuestion] = useState<boolean>(false);
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [visibleChanged, setVisibleChanged] = useState<boolean>(false);
  const [visibleArchive, setVisibleArchive] = useState<boolean>(false);
  const [visibleRelease, setVisibleRelease] = useState<boolean>(false);
  const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
  const [visibleList, setVisibleList] = useState<boolean>(false);
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [selectedRow, setSelectedRow] = useState<
    Array<Types.OfficialCurriculumPublishingSettings.ResponseType>
  >([]);

  const [currentReleaseNote, setCurrentReleaseNote] = useState<
    Types.AdminReleaseNoteManagement.ResponseType | undefined
  >(undefined);
  const {
    officialPublishData,
    dataUsers,
    releaseNoteDetail,
    dataReleaseNoteID,
    officialPublishSelect,
  } = useSelector(publicManagerSelector);

  const { questionKeys, dataKeyDontHaveQuestion } = useSelector(curriculumSelector);

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();
  const formik = useFormik<Types.OfficialPublishSearchFormik>({
    initialValues: {
      publish: '',
      name: '',
      official_curriculum_code: '',
      createdby: '',
    },
    onSubmit: async (values) => {
      dispatch(startLoading());

      const conditions: Types.ConditionsType[] = [];
      Object.keys(values).forEach((key) => {
        if (values[key as keyof typeof values] && key !== 'createdby') {
          conditions.push({
            id: key,
            search_value: [values[key as keyof typeof values]],
            exact_match: true,
          });
        }
      });
      await fetchOfficialPublish(conditions);

      if (values.createdby) {
        dispatch(filterCreatedBy({ type: 'official', value: values.createdby }));
      }

      dispatch(stopLoading());
      setPage(1);
    },
    onReset: () => {
      fetchOfficialPublish();
      setPage(1);
    },
  });

  const handleFormatContent = useCallback(
    async (content: string) => {
      if (!userInfo?.i_id) return content;
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, 'text/html');
      const imgElements = doc.querySelectorAll('img');

      const base64Array = Array.from(imgElements)
        .map((img) => (img.src.startsWith('data:image') ? img.src : null))
        .filter(Boolean) as string[];

      if (base64Array.length) {
        dispatch(startLoading());

        const result = await Promise.all(
          base64Array
            .map(async (item) => {
              const imageSrc = await convertBase64ToFile(item, `file${nanoid()}`);
              const uploadFileToMinio = await uploadFileToR2(imageSrc);
              if (uploadFileToMinio) {
                await dispatch(
                  createFileAttach({
                    item: {
                      fileID: uploadFileToMinio,
                      filename: `file${nanoid()}`,
                      file_location: '4',
                      file_extension: imageSrc.type,
                      file_size: `${imageSrc.size}`,
                      company_id: userInfo.company_id,
                      createdat: new Date(),
                      createdby: userInfo.login_id,
                    },
                  })
                );

                const srcImage = (await getPresignedUrlByFileID(uploadFileToMinio)) as string;
                content = content.replace(item, srcImage);

                return { src: srcImage, base64: item };
              }
            })
            .filter(Boolean)
        );

        for (let index = 0; index < result.length; index++) {
          const element = result[index];
          if (!element) continue;
          const { src, base64 } = element;
          content = content.replace(base64, src);
        }

        dispatch(stopLoading());

        return content;
      } else {
        return content;
      }
    },
    [dispatch, userInfo?.i_id, userInfo?.company_id, userInfo?.login_id]
  );

  const handleCreateCurriculumPublished = async (version: number | undefined | string) => {
    if (userInfo) {
      const resultAction = await Promise.all([
        dispatch(
          createCurriculumPublishedHistory({
            item: {
              company_id: userInfo.company_id,
              official_curriculum_code: selectedRow[0]?.official_curriculum_code,
              publish_start_date: new Date(),
              version: String(version),
            },
            realtime_auto_link: true,
            return_item_result: true,
            return_display_id: true,
          })
        ),
        dispatch(
          updateOfficialCurriculumMaster({
            id: selectedRow[0]?.i_id_curriculum_master,
            data: {
              item: {
                provider_id: userInfo.company_id,
                publish: 2,
                published_version: String(version),
                updated_at: new Date().toDateString(),
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        ),
      ]);
      if (createCurriculumPublishedHistory.fulfilled.match(resultAction[0])) {
        await dispatch(
          updateReleaseNote({
            id:
              dataReleaseNoteID.length > 1
                ? dataReleaseNoteID.slice(-1)[0].i_id
                : dataReleaseNoteID[0].i_id,
            data: {
              item: {
                curriculum_publish_history_id: resultAction[0].payload.item?.history_id,
              },
              is_force_update: true,
              return_item_result: true,
              realtime_auto_link: true,
            },
          })
        );
      }
      if (updateOfficialCurriculumMaster.fulfilled.match(resultAction[1])) {
        fetchOfficialPublish();
        await setSelectedRow([]);
        setVisibleChanged(true);
      }
    }
  };

  const handleUpdateCurriculumPublishedHistory = async () => {
    if (userInfo) {
      const resultAction = await Promise.all([
        dispatch(
          updateCurriculumPublishedHistory({
            id: selectedRow[0]?.i_id_published_history,
            data: {
              item: {
                company_id: userInfo.company_id,
                official_curriculum_code: selectedRow[0]?.official_curriculum_code,
                publish_end_date: new Date(),
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        ),
        dispatch(
          updateOfficialCurriculumMaster({
            id: selectedRow[0]?.i_id_curriculum_master,
            data: {
              item: {
                provider_id: userInfo.company_id,
                publish: 3,
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        ),
      ]);
      if (updateOfficialCurriculumMaster.fulfilled.match(resultAction[1])) {
        fetchOfficialPublish();
        await setSelectedRow([]);
        setVisibleChanged(true);
      }
    }
  };

  const handleUpdateUnPublish = async () => {
    if (userInfo) {
      const resultAction = await Promise.all([
        dispatch(
          updateCurriculumPublishedHistory({
            id: selectedRow[0]?.i_id_published_history,
            data: {
              item: {
                company_id: userInfo.company_id,
                official_curriculum_code: selectedRow[0]?.official_curriculum_code,
                publish_end_date: new Date(),
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        ),
        dispatch(
          updateOfficialCurriculumMaster({
            id: selectedRow[0]?.i_id_curriculum_master,
            data: {
              item: {
                provider_id: userInfo.company_id,
                publish: 0,
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        ),
      ]);
      if (updateOfficialCurriculumMaster.fulfilled.match(resultAction[1])) {
        fetchOfficialPublish();
        await setSelectedRow([]);
        setVisibleChanged(true);
      }
    }
  };

  const mapStatus = (status: number | undefined) => {
    if (status === 0) return '編集中';
    if (status === 1) return '未公開';
    if (status === 2) return '公開中';
    if (status === 3) return '公開停止中';
    return '';
  };
  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      // const resultAction = await dispatch(
      //   getDataOfficialCurriculumPublishingSettingExportCSV({
      //     page: 1,
      //     per_page: 0,
      //   })
      // );
      // if (getDataOfficialCurriculumPublishingSettingExportCSV.fulfilled.match(resultAction)) {
      //   const listCsv = resultAction.payload.report_results.map((item) => ({
      //     i_id: item.i_id,
      //     archive_flg: item.archive_flg,
      //     createdby: item.createdby,
      //     history_id: item.history_id,
      //     official_curriculum_code: item.official_curriculum_code,
      //     official_curriculum_name: item.official_curriculum_name,
      //     provider_id: item.provider_id,
      //     publish: item.publish,
      //     publish_start_date: item.publish_start_date,
      //     updatedat: item.updatedat,
      //     version: item.version,
      //   }));

      //   const csvString = [
      //     HEADER_OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS_EXPORT_CSV.map(({ label }) => label),
      //     ...listCsv.map((item) => Object.values(item)),
      //   ]
      //     .map((e) => e.join(','))
      //     .join('\n');
      //   const bom = '\uFEFF';
      //   const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      //   saveAs(file, `${resultAction.payload.report_title}.csv`);
      // }
      const listCsv = officialPublishData.map((item) => ({
        i_id: item.official_curriculum_code || '',
        official_curriculum_name: item.name || '',
        publish_status: mapStatus(item.publish),
        last_publish_date: item.publish_start_date
          ? dayjs(item.publish_start_date).format('\tYYYY/MM/DD hh:mm')
          : '',
        last_modified: item.updatedat ? dayjs(item.updatedat).format('\tYYYY/MM/DD hh:mm') : '',
        author: item.creator || '',
      }));
      // const csvString = [
      //   HEADER_OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS_EXPORT_CSV.map(({ label }) => label),
      //   ...listCsv.map((item) => Object.values(item).map((v) => `"${v}"`)),
      // ]
      //   .map((e) => e.join(','))
      //   .join('\n');
      // const bom = '\uFEFF';
      // const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      // saveAs(file, 'OFFICIALカリキュラム公開管理.csv');
      exportCsv(
        listCsv,
        HEADER_OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS_EXPORT_CSV,
        'OFFICIALカリキュラム公開管理.csv'
      );
    } else {
      const blob = await pdf(
        <PDFOfficialCurriculumDocument
          dataSource={officialPublishData}
          publish={formik.values.publish}
          createdby={formik.values.createdby}
          official_curriculum_code={formik.values.official_curriculum_code}
          official_curriculum_name={formik.values.name}
        />
      ).toBlob();
      saveAs(blob, 'OFFICIALカリキュラム公開管理.pdf');
    }
    setShowConfirmExportFileModal(false);
  };

  const findUserName = useCallback(
    (loginId?: string) => {
      const user = find(dataUsers, { login_id: loginId });
      return user ? user.name : '';
    },
    [dataUsers]
  );

  const result: Array<Types.Users.ResponseType> = [];
  officialPublishData.forEach((item1) => {
    const matchItem = dataUsers.find((item2) => item1.createdby === item2.createdby);
    if (matchItem) {
      result.push(matchItem);
    }
  });

  const column: ColumnsType<Types.OfficialCurriculumPublishingSettings.ResponseType> = [
    {
      title: 'ID',
      dataIndex: 'official_curriculum_code',
      key: 'official_curriculum_code',
      width: '7%',
      align: 'left',
    },
    {
      title: 'OFFICIALカリキュラム',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
    },
    {
      title: '公開ステータス',
      dataIndex: 'publish',
      key: 'publish',
      width: '10%',
      align: 'left',
      render: (record: number) => getStatus(record),
    },
    {
      title: '前回公開日時',
      dataIndex: 'publish_start_date',
      key: 'publish_start_date',
      width: '15%',
      render: (record) =>
        record ? dayjs(record).locale('ja').format('YYYY/MM/DD （ddd）  HH:mm') : '-',
    },
    {
      title: '最終更新日時',
      dataIndex: 'updatedat',
      key: 'updatedat',
      width: '15%',
      render: (_, record) => {
        return record.createdat || record.updatedat
          ? dayjs(record.updatedat || record.createdat)
              .locale('ja')
              .format('YYYY/MM/DD （ddd）  HH:mm')
          : '-';
      },
    },
    {
      title: '作成者',
      dataIndex: 'createdby',
      key: 'createdby',
      width: '10%',
      render: (record) => <div className="style-createby">{findUserName(record)}</div>,
    },
    {
      title: '閲覧・編集',
      dataIndex: '',
      key: '',
      width: '10%',
      render: () => <FormOutlined className="icon" onClick={() => setVisibleEdit(true)} />,
    },
    {
      title: 'アーカイブ',
      dataIndex: 'publish',
      width: '7%',
      render: (record, item) =>
        record === 3 && (
          <ContainerOutlined
            onClick={() => {
              if (permissionNumber === 2) {
                setArchiveItem(item);
                setVisibleArchive(true);
              }
            }}
            className="icon"
          />
        ),
    },
  ];
  const handleUpdateOfficialCurriculumMaster = async () => {
    if (!archiveItem) return;

    await dispatch(
      updateOfficialCurriculumMaster({
        id: archiveItem.i_id_curriculum_master,
        data: {
          item: {
            archive_flag: 1,
          },
          is_force_update: true,
          realtime_auto_link: true,
        },
      })
    );
    fetchOfficialPublish();
  };

  const fetchOfficialPublish = async (conditions?: Types.ConditionsType[]) => {
    dispatch(startLoading());
    setSelectedRow([]);

    await dispatch(
      getDataCurriculum({
        conditions: [
          {
            id: 'provider_id',
            search_value: [userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );

    await dispatch(
      getDataPublishManagement({
        conditions: [
          ...(conditions || []),
          { id: 'archive_flag', search_value: ['0'], exact_match: true },
        ],
        page: 1,
        per_page: 0,
        include_item_ref: true,
      })
    );

    dispatch(stopLoading());
  };

  const fetchDataUsers = useCallback(() => {
    dispatch(
      getDataUsers({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch]);

  const rowSelection = {
    selectedRowKeys: selectedRow.map(({ i_id }) => i_id),
    onChange: (
      _key: Key[],
      row: Array<Types.OfficialCurriculumPublishingSettings.ResponseType>
    ) => {
      setSelectedRow(row);
      // dispatch(
      //   getDataReleaseNoteID({
      //     conditions: [
      //       {
      //         id: 'company_id',
      //         search_value: [userInfo?.company_id],
      //       },
      //       {
      //         id: 'curriculum_code',
      //         search_value: [row[0]?.official_curriculum_code],
      //         exact_match: true,
      //       },
      //     ],
      //     sort_fields: [
      //       {
      //         id: 'release_version',
      //         order: 'desc',
      //       },
      //     ],
      //     page: 1,
      //     per_page: 0,
      //     include_item_ref: true,
      //     omit_total_items: false,
      //     omit_fields_data: true,
      //     use_display_id: true,
      //   })
      // );
    },
  };

  const openReleaseNoteModal = async () => {
    setVisible(false);
    dispatch(startLoading());
    const checkCurrentReleaseNote = dataReleaseNoteID.find((item) => {
      return !item.curriculum_publish_history_id;
    });
    if (checkCurrentReleaseNote) {
      const releaseNote = await dispatch(
        getReleaseNoteDetail({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'release_note_id',
              search_value: [checkCurrentReleaseNote.release_note_id],
            },
          ],
          omit_fields_data: true,
          omit_total_items: true,
          page: 1,
          per_page: 0,
          use_display_id: true,
        })
      );
      if (getReleaseNoteDetail.fulfilled.match(releaseNote)) {
        setCurrentReleaseNote(releaseNote.payload.report_results[0]);
      }
    }
    dispatch(stopLoading());
    setReleaseNoteModalVisible(true);
  };

  const fetchData = useCallback(() => {
    dispatch(
      getDataReleaseNoteID({
        conditions: [
          {
            id: 'curriculum_code',
            search_value: [selectedRow[0]?.official_curriculum_code],
            exact_match: true,
          },
        ],
        sort_fields: [
          {
            id: 'release_version',
            order: 'desc',
          },
        ],
        page: 1,
        per_page: 0,
        include_item_ref: true,
        omit_total_items: false,
        omit_fields_data: true,
        use_display_id: true,
      })
    );
  }, [dispatch, selectedRow, userInfo?.company_id]);

  useEffect(() => {
    fetchOfficialPublish();
  }, [dispatch, userInfo]);
  useEffect(fetchDataUsers, [fetchDataUsers]);

  useEffect(() => {
    Promise.all([
      dispatch(
        getOfficialPublishSelect({
          conditions: [
            {
              id: 'archive_flg',
              search_value: ['0'],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
        })
      ),
      dispatch(
        getReleaseNoteList({
          page: 1,
          per_page: 0,
        })
      ),
    ]);
  }, [dispatch]);

  const dataSource = useMemo(() => {
    return officialPublishData.map((item) => {
      const check = questionKeys.find((i) => i === item.i_id_curriculum_master);
      const publishPermission = dataKeyDontHaveQuestion.find(
        (i) => i === item.official_curriculum_code
      );
      let result = {
        ...item,
        haveQuestion: false,
        publishPermission: true,
      };

      if (check) {
        result = {
          ...result,
          haveQuestion: true,
        };
      }
      if (publishPermission) {
        result = {
          ...result,
          publishPermission: false,
        };
      }
      return result;
    });
  }, [questionKeys, officialPublishData, dataKeyDontHaveQuestion]);

  useEffect(() => {
    if (!SELECT_RECORD.slice(1).includes(perPage)) {
      setPerpage(dataSource.length);
    }
  }, [dataSource]);

  const handleDetail = () => {
    setVisible(true);
    // currentReleaseNote;
    if (userInfo && visible) {
      dispatch(
        getReleaseNoteDetail({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'release_note_id',
              search_value: [currentReleaseNote?.release_note_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    }
  };

  //  useEffect(() => {
  //   if (userInfo && visible) {
  //     dispatch(
  //       getReleaseNoteDetail({
  //         conditions: [
  //           {
  //             id: 'company_id',
  //             search_value: [userInfo.company_id],
  //           },
  //           {
  //             id: 'release_note_id',
  //             search_value: [selected?.release_note_id],
  //           },
  //         ],
  //         page: 1,
  //         per_page: 0,
  //       })
  //     );
  //   }
  // }, [dispatch, visible, userInfo, selected]);

  return (
    <PublicStyled collapsedMenu={collapsedMenu} isEmptyData={!dataSource.length}>
      <Header title={headerTitle} />
      <div className="public-management-wrapper">
        <div className="wrapper">
          <p className="text-note">作成したOFFICIALカリキュラムの公開管理を行う画面です。</p>
          <Button className="information" onClick={() => setIsOpen(true)}>
            制作者情報
          </Button>
        </div>
        <div className="line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="official_curriculum_code"
                label="OFFICIALカリキュラムID"
                className="item"
              >
                <SelectField
                  data-testId="official-curriculum-code"
                  name="official_curriculum_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children)?.toLowerCase()?.indexOf(input.toLowerCase()) >=
                    0
                  }
                  placeholder="指定なし"
                >
                  {uniqBy(officialPublishSelect, 'official_curriculum_code').map(
                    ({ official_curriculum_code }) => (
                      <Option value={official_curriculum_code} key={official_curriculum_code}>
                        {official_curriculum_code}
                      </Option>
                    )
                  )}
                </SelectField>
              </Form.Item>
              <Form.Item name="name" label="OFFICIALカリキュラム" className="item">
                <SelectField
                  data-testId="official-curriculum-name"
                  name="name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children)?.toLowerCase()?.indexOf(input.toLowerCase()) >=
                    0
                  }
                  placeholder="指定なし"
                >
                  {uniqBy(officialPublishSelect, 'name').map(({ name }) => (
                    <Option value={name} key={name}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item name="publish" label="公開ステータス" className="item">
                <SelectField
                  data-testId="official-curriculum-publish"
                  name="publish"
                  allowClear
                  placeholder="指定なし"
                >
                  {SEARCH_PUBLISH_OPTION.map(({ value }, index) => (
                    <Option value={value} key={index}>
                      {getStatusSearch(+value)}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item name="createdby" label="作成者" className="item">
                <SelectField
                  data-testId="official-curriculum-created-by"
                  name="createdby"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children)?.toLowerCase()?.indexOf(input.toLowerCase()) >=
                    0
                  }
                  placeholder="指定なし"
                >
                  {officialPublishSelect &&
                    unionBy(officialPublishSelect, 'creator').map(
                      ({ creator }) =>
                        creator && (
                          <Option value={creator} key={creator}>
                            {creator}
                          </Option>
                        )
                    )}
                </SelectField>
              </Form.Item>

              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
            <div className="wrap-button">
              <Button
                className="btn btn-active"
                icon={<CloudDownloadOutlined className="icon" />}
                onClick={() => setShowConfirmExportFileModal(true)}
              >
                エクスポート
              </Button>

              <Button className="btn btn-outline" onClick={() => setVisibleList(true)}>
                アーカイブリスト
              </Button>
            </div>
            <Table
              data-testid="table-public-management"
              rowKey="i_id"
              columns={column}
              className="table"
              dataSource={dataSource.map((item, index) => ({ ...item, index }))}
              rowSelection={{
                type: 'radio',
                ...rowSelection,
              }}
              onHeaderRow={() => {
                const onClickHandler = (
                  event: React.MouseEvent<HTMLTableSectionElement, MouseEvent>
                ) => {
                  const target = event.target as HTMLElement;
                  const thElement = target.closest('th');

                  if (thElement && thElement.cellIndex === 0) {
                    setSelectedRow([]);
                  }
                };
                return {
                  onClick: onClickHandler,
                };
              }}
              pagination={{
                pageSize: perPage,
                position: ['topCenter'],
                total: officialPublishData.length,
                showSizeChanger: false,
                onChange: setPage,
                showTotal: () => (
                  <div className="wrap-select-record">
                    <PagingNumber
                      startItem={dataSource.length ? `${(page - 1) * perPage + 1}` : ''}
                      endItem={
                        page * perPage > dataSource.length ? dataSource.length : page * perPage
                      }
                      totalItem={dataSource.length}
                    />
                    <PerPageSelect
                      data={dataSource}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerpage}
                    />
                  </div>
                ),
              }}
            />
          </Form>
        </FormikProvider>
        <Index
          visible={visibleRelease}
          selectedRows={selectedRow}
          setVisible={setVisibleRelease}
          permissionNumber={permissionNumber}
          selectedCurriculum={selectedRow[0]}
        />
        <CreateCurriculum
          title="OFFICIALカリキュラムを公開します"
          from={
            selectedRow[0]?.publish === 0
              ? WhiteEditing
              : selectedRow[0]?.publish === 2
              ? TickComplete
              : selectedRow[0]?.publish === 1
              ? UnPublished
              : StopPublic
          }
          to={
            status === 'publish'
              ? TickComplete
              : status === 'editing'
              ? WhiteEditing
              : status === 'publishWarning'
              ? StopPublic
              : ''
          }
          dataReleaseNoteID={dataReleaseNoteID}
          status={status}
          description={getDescription(status)}
          visible={visible}
          setVisible={setVisible}
          selected={selectedRow}
          onSubmit={handleCreateCurriculumPublished}
          openReleaseNoteModal={openReleaseNoteModal}
        />
        <UpdateStatus
          status={status}
          publish={selectedRow[0]?.publish}
          title={getTitle(status)}
          from={
            selectedRow[0]?.publish === 0
              ? WhiteEditing
              : selectedRow[0]?.publish === 2
              ? TickComplete
              : selectedRow[0]?.publish === 1
              ? UnPublished
              : StopPublic
          }
          to={
            status === 'publish'
              ? TickComplete
              : status === 'editing'
              ? WhiteEditing
              : status === 'publishWarning'
              ? StopPublic
              : ''
          }
          description={getDescription(status)}
          visible={visibleUpdate}
          setVisible={setVisibleUpdate}
          noteText={getNoteText(status)}
          textOkButton={getTextOkButton(status)}
          onSubmit={
            status === 'editing' ? handleUpdateUnPublish : handleUpdateCurriculumPublishedHistory
          }
        />
        <ConfirmArchive
          setVisibleComplete={setVisibleComplete}
          visible={visibleArchive}
          setVisible={setVisibleArchive}
          onSubmit={() => handleUpdateOfficialCurriculumMaster()}
        />
        <ArchiveCompleted visible={visibleComplete} setVisible={setVisibleComplete} />
        <ArchiveList visible={visibleList} setVisible={setVisibleList} />
        <Changed visible={visibleChanged} setVisible={setVisibleChanged} status={status} />
        <Info
          selectedRow={selectedRow}
          visible={visibleEdit}
          setVisible={setVisibleEdit}
          title="OFFICAILカリキュラム閲覧・編集"
          content="OFFICIALカリキュラム閲覧・編集のためにカリキュラムツリーに移動します。"
        />
        <PopupConfirmExportFile
          visible={showConfirmExportFileModal}
          setVisible={setShowConfirmExportFileModal}
          onSubmit={handleExportCSV}
          title=" "
        />
        <InformationModal isOpen={isOpen} setIsOpen={setIsOpen} />
        <ReleaseNote
          releaseNoteDetail={releaseNoteDetail}
          visible={releaseNoteModalVisible}
          publish={selectedRow[0]?.publish}
          setVisible={setReleaseNoteModalVisible}
          selected={currentReleaseNote}
          publishOfficial={selectedRow[0]}
        />
        <DisabledActionModal open={disabled} setOpen={setDisabled} />
        <HasNotQuestion open={openErrorHasNotQuestion} setOpen={seteOpenErrorHasNotQuestion} />
      </div>
      <div className="wrap-bottom">
        <div className="text-label">
          選択したOFFICIALカリキュラムを処理：
          <Button
            className={`btn btn-active ${
              selectedRow[0]?.publish === 2 || !selectedRow.length ? 'disabled' : ''
            }`}
            onClick={() => {
              if (!selectedRow.length) return;

              if (!selectedRow[0]?.publishPermission) {
                seteOpenErrorHasNotQuestion(true);
                return;
              }
              selectedRow.length && fetchData();
              selectedRow.length > 0 ? handleDetail() : setDisabled(true);
              setStatus('publish');
            }}
            disabled={!selectedRow.length || selectedRow[0]?.publish === 2}
          >
            公開する
          </Button>
          <Button
            className={`btn btn-active ${
              permissionNumber !== 2 ||
              selectedRow[0]?.publish === 0 ||
              selectedRow[0]?.publish === 1 ||
              selectedRow[0]?.publish === 3
                ? 'disabled'
                : ''
            }`}
            onClick={() => {
              selectedRow.length > 0 ? setVisibleUpdate(true) : setDisabled(true);
              setStatus('editing');
            }}
            disabled={
              permissionNumber !== 2 ||
              selectedRow[0]?.publish === 0 ||
              selectedRow[0]?.publish === 1 ||
              selectedRow[0]?.publish === 3
            }
          >
            編集する
          </Button>
          <Button
            className={`btn btn-active ${
              permissionNumber !== 2 ||
              selectedRow[0]?.publish === 1 ||
              selectedRow[0]?.publish === 3
                ? 'disabled'
                : ''
            }`}
            onClick={() => {
              selectedRow.length > 0 ? setVisibleUpdate(true) : setDisabled(true);
              setStatus('publishWarning');
            }}
            disabled={
              permissionNumber !== 2 ||
              selectedRow[0]?.publish === 1 ||
              selectedRow[0]?.publish === 3
            }
          >
            公開停止にする
          </Button>
          <Button
            className="btn btn-outline"
            onClick={() => (selectedRow.length > 0 ? setVisibleRelease(true) : setDisabled(true))}
          >
            リリースノートを管理
          </Button>
        </div>
      </div>
    </PublicStyled>
  );
};

export default PublicManagement;

export const getNoteText = (status: string) => {
  switch (status) {
    case 'editing':
      return (
        <span>
          ※編集中は利用者に影響はありません。
          <br />
          再度公開する際にはリリースノートの公開と利用者側でアップデートが必要になります。
        </span>
      );
    case 'publish':
      return (
        <span>
          ※編集中は利用者に影響はありません。
          <br />
          再度公開する際にはリリースノートの公開と利用者側でアップデートが必要になります。
        </span>
      );
    case 'publishWarning':
      return (
        <span>
          ※すでに利用中の一般ユーザーはそのまま利用継続します。
          <br />
          利用中の一般ユーザーに利用を停止して頂く場合は、リリースノートにて通知をお願いいたします。
        </span>
      );
    default:
      return '';
  }
};

const getDescription = (status: string) => {
  switch (status) {
    case 'editing':
      return (
        <span>
          OFFICIALカリキュラムを編集にすると、 <br />
          カリキュラムマスタから内容の修正が可能になります。
        </span>
      );
    case 'publish':
      return (
        <span>
          OFFICIALカリキュラムを公開すると、SKILL FAMILIARで
          <br />
          一般のユーザーが利用できる状態になります。
        </span>
      );
    case 'publishWarning':
      return (
        <span>
          OFFICIALカリキュラムを公開停止にすると、
          <br /> 一般ユーザーの新規の利用は不可になります。
        </span>
      );
    default:
      return '';
  }
};

export const getStatus = (value: number) => {
  switch (Number(value || 0)) {
    case 0:
      return (
        <div className="wrap-status">
          <img className="icon-status" src={WhiteEditing} alt="" />
          <span className="violet">編集中</span>
        </div>
      );
    case 1:
      return (
        <div className="wrap-status">
          <img className="icon-status" src={Unpublished} alt="" />
          <span className="blue">未公開</span>
        </div>
      );
    case 2:
      return (
        <div className="wrap-status">
          <img className="icon-status" src={TickComplete} alt="" />
          <span className="green">公開中</span>
        </div>
      );
    case 3:
      return (
        <div className="wrap-status">
          <img className="icon-status" src={StopPublic} alt="" />
          <span className="grey">公開停止中</span>
        </div>
      );
  }
};
export const getStatusSearch = (value: number) => {
  switch (value) {
    case 0:
      return (
        <div className="wrap-icon">
          <img className="icon-status" src={WhiteEditing} alt="" />
          <span className="violet">編集中</span>
        </div>
      );
    case 1:
      return (
        <div className="wrap-icon">
          <img className="icon-status" src={Unpublished} alt="" />
          <span className="blue">未公開</span>
        </div>
      );
    case 2:
      return (
        <div className="wrap-icon">
          <img className="icon-status" src={TickComplete} alt="" />
          <span className="green">公開中</span>
        </div>
      );
    case 3:
      return (
        <div className="wrap-icon">
          <img className="icon-status" src={StopPublic} alt="" />
          <span className="grey">公開停止中</span>
        </div>
      );
  }
};
export const getTextOkButton = (status: string) => {
  switch (status) {
    case 'editing':
      return '編集する';
    case 'publish':
      return '公開する';
    case 'publishWarning':
      return '公開停止にする';
    default:
      return '公開する';
  }
};

export const getTitle = (status: string) => {
  switch (status) {
    case 'editing':
      return '公開中のOFFICIALカリキュラムを編集します';
    case 'publish':
      return '公開中のOFFICIALカリキュラムを公開停止します';
    case 'publishWarning':
      return '公開中のOFFICIALカリキュラムを公開停止します';
    default:
      return '';
  }
};
