import React, { useEffect, useState } from 'react';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Button, Modal as AntdModal } from 'antd';
import { sumBy } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { billingDataManagementSelector } from 'pages/BillingDataManagement/selectors';
import { administratorMasterSelector } from 'pages/UserManagement/AdministratorMaster/selectors';
import { dashboardSelector } from 'pages/Dashboard/selectors';
import { authSelector } from 'containers/Auth/selectors';
import { useAppDispatch } from 'hooks';
import { formatNumberComma } from 'libs/utils/format';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { loadingRef } from 'components/Loading';
import { LogoAdmin, LogoRStandard } from 'assets';
import { SectionStyled } from './styles';
import { Modal } from 'components';
import * as Types from 'types';
import {
  getDataSkillCheckFeeDetail,
  getCompaniesUsageStatus,
} from 'pages/BillingDataManagement/thunk';

import { getCompanies } from 'pages/UserManagement/AdministratorMaster/thunk';
import { getDataUsageList } from 'pages/Dashboard/thunk';
dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  dataSelected?: Types.BillingDataManagement.ResponseType;
  item: any;
}

const BillingPreview: React.FC<Props> = ({ visible, setVisible, dataSelected, item }) => {
  const { dataSkillCheckFeeDetail, loading, dataCompanyUsageStatus } = useSelector(
    billingDataManagementSelector
  );
  const { dataUsageList } = useSelector(dashboardSelector);
  const { information } = useSelector(administratorMasterSelector);
  const { userInfo } = useSelector(authSelector);
  const dispatch = useAppDispatch();

  const dataTableMock = [
    {
      content: 'ユーザー利用料',
      unitPrice: formatNumberComma(Number(dataUsageList[0]?.unit_price_user) || 0).toLocaleString(),
      quantity: formatNumberComma(Number(dataUsageList[0]?.max_users) || 0).toLocaleString(),
      total: formatNumberComma(Number(dataUsageList[0]?.user_fee) || 0).toLocaleString(),
    },
    {
      content: 'ストレージ利用料',
      unitPrice: formatNumberComma(
        dataUsageList[0]?.usage_storage_unit_price || 0
      ).toLocaleString(),
      quantity: formatNumberComma(Number(dataUsageList[0]?.usage_storage || 0) * 1024).toString(),
      total: formatNumberComma(Number(dataUsageList[0]?.user_fee_storage) || 1000).toLocaleString(),
    },
    {
      content: 'スキルチェック利用料',
      unitPrice: formatNumberComma(Number(dataUsageList[0]?.unit_price) || 0).toLocaleString(),
      quantity: formatNumberComma(
        Number(dataUsageList[0]?.skill_check_usage_count) || 0
      ).toLocaleString(),
      total: formatNumberComma(
        Number(dataUsageList[0]?.skill_check_usage_fee) || 0
      ).toLocaleString(),
    },
  ];

  const handleTotalProduct = () => {
    return sumBy(dataTableMock, (item) => parseFloat(item.total.replace(/,/g, '')));
  };

  const handleTotalProductFinal = () => {
    return handleTotalProduct() + handleTotalProduct() * 0.1;
  };

  const [confirmVisible, setConfirmVisible] = useState(false);

  const exportPDF = () => {
    const originalElement = document.querySelector('.wrap-body .container') as HTMLElement;

    if (originalElement) {
      const clonedElement = originalElement.cloneNode(true) as HTMLElement;

      const copyComputedStyle = (fromElement: HTMLElement, toElement: HTMLElement) => {
        const computedStyle = window.getComputedStyle(fromElement);

        for (let i = 0; i < computedStyle.length; i++) {
          const property = computedStyle.item(i);
          if (property) {
            toElement.style.setProperty(property, computedStyle.getPropertyValue(property));
          }
        }

        for (let i = 0; i < fromElement.children.length; i++) {
          copyComputedStyle(
            fromElement.children[i] as HTMLElement,
            toElement.children[i] as HTMLElement
          );
        }
      };

      copyComputedStyle(originalElement, clonedElement);

      clonedElement.style.position = 'absolute';
      clonedElement.style.top = '-9999px';
      clonedElement.style.left = '-9999px';
      clonedElement.style.width = `${originalElement.scrollWidth}px`;
      clonedElement.style.height = `${originalElement.scrollHeight}px`;
      clonedElement.style.overflow = 'visible';
      clonedElement.style.backgroundColor = '#ffffff';
      document.body.appendChild(clonedElement);

      html2canvas(clonedElement, {
        scrollX: 0,
        scrollY: 0,
        width: clonedElement.scrollWidth,
        height: clonedElement.scrollHeight,
        windowWidth: clonedElement.scrollWidth,
        windowHeight: clonedElement.scrollHeight,
        backgroundColor: 'unset',
        scale: 2,
      }).then((canvas) => {
        document.body.removeChild(clonedElement);

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const pageHeight = pdf.internal.pageSize.getHeight();
        let heightLeft = imgHeight;
        let position = 0;

        while (heightLeft > 0) {
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
          position -= pageHeight;
          if (heightLeft > 0) {
            pdf.addPage();
          }
        }

        pdf.save(
          (item?.billing_date ? dayjs(item.billing_date).format('YYYY年MM月DD日') : '') +
            '& 請求書.pdf'
        );
      });
    }
  };

  const showConfirm = () => {
    setConfirmVisible(true);
  };

  const handleConfirmExport = () => {
    setConfirmVisible(false);
    exportPDF();
  };

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getDataSkillCheckFeeDetail({
            conditions: [
              {
                id: 'company_id',
                search_value: [item?.company_id],
              },
              {
                id: 'start_date',
                search_value: [
                  dayjs
                    .tz(dayjs(item?.provided_date).startOf('month'), 'Asia/Tokyo')
                    .utc()
                    .format(),
                  dayjs.tz(dayjs(item?.provided_date).endOf('month'), 'Asia/Tokyo').utc().format(),
                ],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getCompaniesUsageStatus({
            conditions: [
              {
                id: 'id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataUsageList({
            conditions: [
              {
                id: 'company_id',
                search_value: [item?.company_id],
                exact_match: true,
              },
              {
                id: 'contract_date',
                search_value: [dataSelected?.provided_date.replace('/', '')],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getCompanies({
            conditions: [
              {
                id: 'id',
                search_value: [item?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dataSelected, dispatch, item, visible]);
  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      <Modal
        title="請求書"
        width={860}
        visible={visible}
        cancelButton={{
          text: '閉じる',
          onClick: () => setVisible(false),
        }}
        onCancel={() => setVisible(false)}
        bodyStyle={{
          backgroundColor: '#f9f8f8',
        }}
        footerStyle={{
          backgroundColor: '#f9f8f8',
        }}
        headerStyle={{
          borderBottom: '1px solid #CCCCCC',
        }}
      >
        <SectionStyled>
          <div className="wrap-header">
            <div className="time-label">
              <span className="label">サービス利用年月：</span>
              <span className="time-text">
                {dayjs(dataSelected?.provided_date).format('YYYY年MM月')}
              </span>
            </div>
            <Button className="button-export" onClick={showConfirm}>
              <CloudDownloadOutlined className="download-icon" />
              エクスポート
            </Button>
          </div>
          <div className="wrap-body">
            <div className="container">
              <div className="header-content">
                <div className="image-logo">
                  <img src={LogoAdmin} alt="logo" className="image" />
                </div>
                <p className="title">請求書</p>
                <div className="preview">
                  <div className="label">
                    <span>領収書No.</span> <br />
                    <span>発行日</span>
                  </div>
                  <div className="text-label">
                    <span>{item?.company_id + dataSelected?.provided_date.replace('/', '')}</span>{' '}
                    <br />
                    <span>
                      {' '}
                      {item?.billing_date ? dayjs(item.billing_date).format('YYYY年MM月DD日') : ''}
                    </span>
                  </div>
                </div>
              </div>
              <div className="item-info">
                <div className="item-left">
                  <span className="name">
                    {item?.company_name ? item?.company_name + '　御中' : ''}
                  </span>
                  <br />
                  <br />
                  <span>
                    〒{' '}
                    {information.postal_code
                      ? information?.postal_code?.toLocaleString().substring(0, 3) +
                        '-' +
                        information?.postal_code?.toLocaleString().substring(3)
                      : ''}
                    <br />
                    {information?.prefecture}{' '}
                    {information?.address ? '-' + information?.address : ''}{' '}
                    {information?.plot_number ? '-' + information?.plot_number : ''}
                    {information?.building_name ? '-' + information?.building_name : ''}
                    <br />
                    TEL：{information?.admin_phone}
                    <br />
                    FAX：{information?.fax}
                    <br />
                    登録番号：{information?.business_registration_number}
                    <br />
                  </span>
                </div>
                <div className="item-right">
                  <span className="label-name">{dataCompanyUsageStatus?.name_furigana}</span>
                  <br />
                  <span className="name-company">{dataCompanyUsageStatus?.name}</span>
                  <br />
                  <br />
                  <span>
                    〒{' '}
                    {dataCompanyUsageStatus?.postal_code.slice(0, 3) +
                      '-' +
                      dataCompanyUsageStatus?.postal_code.slice(3)}
                    <br />
                    {dataCompanyUsageStatus?.prefecture}
                    {dataCompanyUsageStatus?.address}
                    {dataCompanyUsageStatus?.plot_number}
                    {dataCompanyUsageStatus?.building_name}
                    <br />
                    TEL：{dataCompanyUsageStatus?.admin_phone}
                    <br />
                    FAX：{dataCompanyUsageStatus?.fax}
                    <br />
                    登録番号：{dataCompanyUsageStatus?.business_registration_number}
                    <br />
                  </span>
                  <img src={LogoRStandard} alt="logoRStandard" className="image-logo" />
                </div>
              </div>
              <div className="total-header">
                <span className="total-header-title">ご請求金額（税込）</span>
                <span className="total-number">
                  {formatNumberComma(handleTotalProductFinal())}
                  <span className="currency-unit">円</span>
                </span>
              </div>
              <div className="wrap-usage-date">
                <span className="title">サービス利用年月 : </span>
                <span className="date">
                  {dayjs(dataSelected?.provided_date).format('YYYY年MM月')}
                </span>{' '}
              </div>
              <div className="content-body">
                <table>
                  <tbody>
                    <tr>
                      <th>内容</th>
                      <th>単価</th>
                      <th>数量</th>
                      <th>合計</th>
                    </tr>
                    {dataTableMock.map((item, index) => (
                      <tr key={index}>
                        <td>{item.content}</td>
                        <td>
                          {item.unitPrice}
                          <span style={{ fontSize: 9 }}>
                            {item?.content == 'ストレージ利用料' ? '/50GB' : ''}
                          </span>
                        </td>
                        <td>
                          {item.quantity}
                          <span style={{ fontSize: 9 }}>
                            {item?.content == 'ユーザー利用料'
                              ? '人'
                              : item?.content == 'スキルチェック利用料'
                              ? '回'
                              : 'MB'}
                          </span>
                        </td>
                        <td>{item.total}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="sub-table">
                  <div className="wrap-sub-item">
                    <div className="sub-item">
                      <span className="sub-title">小計</span>
                      <span className="sub-price">{formatNumberComma(handleTotalProduct())}</span>
                    </div>
                    <div className="sub-item">
                      <span className="sub-title">消費税等（10%）</span>
                      <span className="sub-price">
                        {formatNumberComma(handleTotalProduct() * 0.1)}
                      </span>
                    </div>
                    <div className="sub-item">
                      <span className="sub-title">合計</span>
                      <span className="sub-price">
                        {formatNumberComma(handleTotalProductFinal())}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-footer">
                <div>
                  <p className="title-footer">【お振込先】</p>
                  <div>
                    <span style={{ fontSize: 13 }}>{dataCompanyUsageStatus?.bank_name}&ensp;</span>
                    <span style={{ color: 'rgb(124, 126, 126)' }}>
                      （{dataCompanyUsageStatus?.bank_code}
                    </span>
                    ）&ensp;
                    {dataCompanyUsageStatus?.branch_name}&ensp;
                    <span style={{ color: 'rgb(124, 126, 126)' }}>
                      （{dataCompanyUsageStatus?.branch_code}）&emsp;&emsp;
                    </span>
                    <span>
                      （{dataCompanyUsageStatus?.bank_account_type}）&emsp;
                      {dataCompanyUsageStatus?.bank_account_number} &emsp;
                    </span>
                    <span>{dataCompanyUsageStatus?.name}</span>
                  </div>
                  <p style={{ color: 'rgb(124, 126, 126)', marginTop: '10px' }}>
                    ※お振込手数料は御社負担にてお願いいたします。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </SectionStyled>
      </Modal>
      <AntdModal
        title={
          <h3
            style={{
              display: 'flex',
              backgroundColor: '#ffffff',
              fontFamily: 'Noto Sans Javanese',
              fontSize: 18,
              justifyContent: 'center',
              alignItems: 'center',
              color: '#424242',
              margin: 0,
            }}
          >
            エクスポート
          </h3>
        }
        width={720}
        visible={confirmVisible}
        onCancel={() => setConfirmVisible(false)}
        bodyStyle={{
          backgroundColor: '#f9f8f8',
        }}
        footer={null}
        style={{
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      >
        <SectionStyled>
          <div className="content">
            <p>エクスポートを実行します。</p>
            <p>出力形式：PDF</p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '10px 0',
              backgroundColor: '#f9f8f8',
            }}
          >
            <Button
              onClick={handleConfirmExport}
              type="primary"
              style={{
                backgroundColor: '#f6ac00',
                borderRadius: 5,
                color: '#ffffff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '1px 1px 4px rgb(68 68 68 / 20%)',
                margin: '0 5px',
                width: '137px',
                height: '42px',
                border: '1px solid #F6AC00',
              }}
            >
              OK
            </Button>
            <Button
              onClick={() => setConfirmVisible(false)}
              style={{
                background: '#ffffff',
                border: '1px solid #d9d9d9',
                boxShadow: '1px 1px 4px rgba(68, 68, 68, 0.2)',
                borderRadius: 5,
                margin: '0 5px',
                padding: '9px 35px',
                width: '137px',
                height: '42px',
              }}
            >
              キャンセル
            </Button>
          </div>
        </SectionStyled>
      </AntdModal>
    </>
  );
};

export default BillingPreview;
