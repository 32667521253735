import React, { useState } from 'react';
import { Column, G2 } from '@ant-design/plots';
import { Select } from 'antd';

import Styled from './styles';
import { reportSelector } from 'pages/Report/selectors';
import { useSelector } from 'react-redux';
import { SkillCheckInformationData } from 'types';
import { sortBy, uniq, uniqBy } from 'lodash';
import { formatComma } from 'libs/utils/format';
import { convertToFormattedDateForField } from 'pages/Report/OfficialCurriculumInformation/Table';
const G = G2.getEngine('canvas');
const { Option } = Select;

const convertToFormattedDate = (input: string): string => {
  const year = input.slice(0, 4);
  const month = input.slice(4, 6);
  return `${year}/${month}`;
};

interface Props {
  exportPdf: boolean;
}

const CorrespondingUnChecked: React.FC<Props> = ({ exportPdf }: Props) => {
  const { dataSkillCheckInformation, listMonths } = useSelector(reportSelector);
  const [selectedValue, setSelectedValue] = useState('');

  const dataColumn = sortBy(
    dataSkillCheckInformation.map((i: SkillCheckInformationData) => {
      return {
        name: i.user_type,
        month: convertToFormattedDate(i.target_month),
        value: i.number_of_skill_checks_performed,
        target_month: i.target_month,
      };
    }),
    (i: any) => {
      return parseFloat(i.target_month);
    }
  );

  const getDateOfDataColumn = uniq(
    dataColumn.map((item) => {
      return item.month;
    })
  );

  const newList = [...listMonths]
    .filter((item) => {
      const check = getDateOfDataColumn.every((i) => {
        return i !== item.title;
      });
      return !!check;
    })
    .map((item) => {
      return [
        {
          name: '社内ユーザー',
          month: item.title,
          value: 0,
        },
        {
          name: '面接ユーザー',
          month: item.title,
          value: 0,
        },
        {
          name: '全ユーザー',
          month: item.title,
          value: 0,
        },
      ];
    })
    .reduce((acc, item) => {
      return [...acc, ...item];
    }, []);

  const result = sortBy([...newList, ...dataColumn], (i) => {
    return parseFloat(convertToFormattedDateForField(i.month));
  });
  const dataConfig = sortBy(
    result.filter((i) => i.name.includes(selectedValue)),
    (i) => parseFloat(i.month.replace('/', ''))
  );
  const config = {
    data: dataConfig,
    isGroup: true,
    xField: 'month',
    yField: 'value',
    seriesField: 'name',
    maxColumnWidth: 15,
    dodgePadding: 4,
    color: ['#4E83D3', '#F08B84', '#C38BC2'].filter((i) => {
      switch (selectedValue) {
        case '全ユーザー':
          return i === '#C38BC2';
        case '社内ユーザー':
          return i === '#4E83D3';
        case '面接ユーザー':
          return i === '#F08B84';
        default:
          return true;
      }
    }),
  };

  return (
    <Styled>
      <div className="user-info">
        <div className="body-info">
          <div className="right-side">
            <div className="wrap-header">
              <span className="title">スキルチェック実施回数【単月推移】</span>
              <div className="wrap-action">
                <div className="wrap-select">
                  <label>実施種類選択：</label>
                  <Select
                    defaultValue={selectedValue}
                    onChange={(e) => {
                      setSelectedValue(e);
                    }}
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  >
                    <Option value="">ALL</Option>
                    <Option value="全ユーザー"> 全ユーザー</Option>
                    <Option value="社内ユーザー"> 社内ユーザー</Option>
                    <Option value="面接ユーザー"> 面接ユーザー</Option>
                  </Select>
                </div>
                <div className="legend">
                  <div className="sort-column">
                    <div className="legend-filter blue" />
                    <span className="legend-name">社内</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter red" />
                    <span className="legend-name">面接</span>
                  </div>
                  <div className="sort-column">
                    <div className="legend-filter violet" />
                    <span className="legend-name">全ユーザー</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="chart">
              <span className="unit">単位：人</span>
              <Column
                {...config}
                legend={false}
                height={330}
                yAxis={{
                  grid: {
                    line: {
                      style: {
                        lineDash: [2, 2],
                        stroke: '#ddd',
                      },
                    },
                  },
                }}
                xAxis={{
                  tickLine: null,
                }}
                label={{
                  position: 'top',
                  content: (v, mappingData) => {
                    const group = new G.Group({});
                    group.addShape({
                      type: 'text',
                      attrs: {
                        x: 0,
                        y: 0,
                        text: formatComma(v.value),
                        textAlign: 'left',
                        textBaseline: 'top',
                        fill: mappingData.color,
                        shadowOffsetX: 1,
                        shadowOffsetY: 1,
                        shadowColor: '#FFFFFF',
                      },
                    });
                    return group;
                  },
                  style: {
                    shadowOffsetX: 1,
                    shadowOffsetY: 1,
                    shadowColor: '#FFFFFF',
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default CorrespondingUnChecked;
