import React from 'react';
import * as Types from 'types';
import { Document, Font, Page, Text, View } from '@react-pdf/renderer';
import styles from './styles';
import Divider from 'components/ReactPDF/Divider';
import SelectBox from 'components/ReactPDF/SelectBox';
import Header from 'components/ReactPDF/Header';
import { IOption } from 'types/config';
import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';
import { formatDate } from 'libs/utils/format';
import _ from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import dayjs from 'dayjs';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple@15.0.1/img/apple/64/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';
const FIRST_PAGE_ROW_COUNT = 11;
const PAGE_ROW_COUNT = 20;

interface IProps {
  filters?: any;
  dataSource: Types.Companies.ResponseType[];
  total?: number;
}

const FreeTrialManagementTable = ({
  dataSource,
}: {
  dataSource: Types.Companies.ResponseType[];
}) => {
  const columns: { title: string; width: string | number; key: string }[] = [
    {
      title: 'ステータス',
      width: 100,
      key: 'company_status',
    },
    {
      title: '法人ID\n法人名',
      width: 220,
      key: 'company_name',
    },
    {
      title: '残日数',
      width: 50,
      key: 'remaining_days',
    },
    {
      title: '開始日時\n終了日時',
      width: 120,
      key: 'trial_start_end',
    },
    {
      title: '契約状況',
      width: 100,
      key: 'contract_status',
    },
    {
      title: 'データ保持日数',
      width: 70,
      key: 'data_retention_days',
    },
    {
      title: '所属 / 役職\n管理者氏名',
      width: 180,
      key: 'positionManagerName',
    },
  ];

  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow]}>
        {columns.map((column, index) => (
          <View key={index} style={[styles.wrapCell, { width: column.width }]}>
            <Text
              style={[
                styles.tableCell,
                styles.headerCell,
                { textAlign: index === 2 ? 'center' : 'left' },
              ]}
            >
              {column.title}
            </Text>
          </View>
        ))}
      </View>

      {dataSource.map((item, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5',
            },
          ]}
        >
          <View style={[styles.wrapCell, { width: columns[0].width }]}>
            <View
              style={[
                styles.companyStatus,
                {
                  borderLeftColor:
                    dayjs().isAfter(dayjs(item.trial_end_date)) && !item.trial_completed_flag
                      ? '#c4c4c4'
                      : '#08A3A5',
                },
              ]}
            >
              <Text style={styles.tableCell}>
                {dayjs().isBefore(dayjs(item.trial_end_date)) ? 'トライアル中' : '終了'}
              </Text>
            </View>
          </View>
          <View style={[styles.wrapCell, { width: columns[1].width }]}>
            <Text style={[styles.tableCell, styles.secondaryText]}>{item.id}</Text>
            <Text style={styles.tableCell}>{item.name}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[2].width }]}>
            <Text style={[styles.tableCell, styles.textCenter]}>
              {dayjs(item.trial_end_date).diff(dayjs(), 'day') > 0
                ? dayjs(item.trial_end_date).diff(dayjs(), 'day')
                : 0}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[3].width }]}>
            <Text style={styles.tableCell}>{formatDate(item.trial_start_date)}</Text>
            <Text style={styles.tableCell}>{formatDate(item.trial_end_date)}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[4].width }]}>
            <Text style={styles.tableCell}>
              {item.trial_completed_flag
                ? '契約済み'
                : dayjs().isBefore(dayjs(item.trial_end_date))
                ? 'トライアル中'
                : '未契約'}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[5].width, textAlign: 'center' }]}>
            <Text style={[styles.tableCell, styles.textCenter]}>
              {!item.trial_completed_flag &&
              dayjs(item.trial_end_date).add(10, 'day').diff(dayjs(), 'day') > 0 &&
              dayjs(item.trial_end_date).add(10, 'day').diff(dayjs(), 'day') < 11
                ? dayjs(item.trial_end_date).add(10, 'day').diff(dayjs(), 'day')
                : 0}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[6].width }]}>
            <Text style={[styles.tableCell, styles.secondaryText]}>
              {item.admin_department} / {item.admin_position}
            </Text>
            <Text style={styles.tableCell}>{item.admin_name}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const PDFFreeTrialManagementTable = (props: IProps) => {
  const { dataSource, total, filters } = props;

  const companyStatusOptions: IOption[] = [
    {
      value: 'ALL',
      label: 'ALL',
    },
    {
      value: '0',
      label: '終了',
    },
    {
      value: '1',
      label: 'トライアル中',
    },
  ];

  const contractStatusOptions: IOption[] = [
    {
      value: 'ALL',
      label: 'ALL',
    },
    {
      value: '0',
      label: '未契約',
    },
    {
      value: '1',
      label: 'トライアル中',
    },
    {
      value: '2',
      label: '契約済み',
    },
  ];

  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap>
        <FullPage>
          <Header title="無料トライアル管理" />
          <View style={styles.subscription}>
            <View>
              <Text style={styles.textSubscription}>
                無料トライアル中の法人ユーザーの状況を確認する画面です。
              </Text>
            </View>
          </View>
          <Divider />
          <View style={styles.searchPanel}>
            <SelectBox
              label="ステータス"
              placeholder="指定なし"
              options={companyStatusOptions}
              value={filters.company_status}
              selectBoxStyle={{ width: 200 }}
            />
            <SelectBox
              selectBoxStyle={{ width: 200 }}
              label="法人ID"
              value={filters.company_id}
              placeholder="指定なし"
            />
            <SelectBox
              selectBoxStyle={{ width: 200 }}
              label="法人名"
              value={filters.company_name}
              placeholder="指定なし"
            />
            <SelectBox
              selectBoxStyle={{ width: 200 }}
              label="契約状況"
              options={contractStatusOptions}
              value={filters.contract_status}
              placeholder="指定なし"
            />
          </View>
          <View style={styles.body}>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>1 - {dataSource.length} </Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{total || dataSource.length}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
            <FreeTrialManagementTable dataSource={dataSource.slice(0, FIRST_PAGE_ROW_COUNT)} />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {dataSource.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(dataSource.slice(FIRST_PAGE_ROW_COUNT, dataSource.length), PAGE_ROW_COUNT).map(
          (page, index) => (
            <Page
              key={index}
              size={pageSize}
              orientation={pageOrientation}
              style={[styles.page, styles.pagePadding]}
              wrap={false}
            >
              <FullPage>
                <FreeTrialManagementTable dataSource={page} />
              </FullPage>
              <PageNumber />
            </Page>
          )
        )}
    </Document>
  );
};

export default PDFFreeTrialManagementTable;
