import { createAsyncThunk } from '@reduxjs/toolkit';

import { services, userService } from 'services';
import * as Types from 'types';
import {
  COMPANIES,
  INVOICE_CORRESPONDENCE,
  INVOICE_CORRESPONDENCE_LIST,
  SELECT_COMPANY,
  SELECT_MANAGER,
  SIGNUP,
} from 'configs';
import { browserLogger } from 'libs/logger';

export const createInvoiceCorrespondence = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.InvoiceCorresponsedence.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/createInvoiceCorrespondence', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(INVOICE_CORRESPONDENCE.name, req);
    browserLogger.info(
      'InvoiceCorrespondence/thunk/createInvoiceCorrespondence',
      INVOICE_CORRESPONDENCE.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getInvoiceCorrespondence = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/getInvoiceCorrespondence', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(INVOICE_CORRESPONDENCE_LIST.name, req);
    browserLogger.info(
      'InvoiceCorrespondence/thunk/getInvoiceCorrespondence',
      INVOICE_CORRESPONDENCE_LIST.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelect = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/getDataSelect', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(INVOICE_CORRESPONDENCE_LIST.name, req);
    browserLogger.info(
      'InvoiceCorrespondence/thunk/getDataSelect',
      INVOICE_CORRESPONDENCE_LIST.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataExportCSVInvoiceCorrespondenceList = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'InvoiceCorrespondence/thunk/getDataExportCSVInvoiceCorrespondenceList',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter(INVOICE_CORRESPONDENCE_LIST.name, req);
      browserLogger.info(
        'InvoiceCorrespondence/thunk/getDataExportCSVInvoiceCorrespondenceList',
        INVOICE_CORRESPONDENCE_LIST.name,
        data
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSelectCompany = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/getSelectCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_COMPANY.name, req);
    browserLogger.info('InvoiceCorrespondence/thunk/getSelectCompany', SELECT_COMPANY.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectManager = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/getSelectManager', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_MANAGER.name, req);
    browserLogger.info('InvoiceCorrespondence/thunk/getSelectManager', SELECT_MANAGER.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateInvoiceCorrespondence = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.InvoiceCorresponsedence.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/updateInvoiceCorrespondence', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(INVOICE_CORRESPONDENCE.name, req);
    browserLogger.info(
      'InvoiceCorrespondence/thunk/updateInvoiceCorrespondence',
      INVOICE_CORRESPONDENCE.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const removeInvoiceCorrespondence = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/removeInvoiceCorrespondence', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(INVOICE_CORRESPONDENCE.name, req);
    browserLogger.info(
      'InvoiceCorrespondence/thunk/removeInvoiceCorrespondence',
      INVOICE_CORRESPONDENCE.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const postUnauthorizedCall = createAsyncThunk<
  Types.UnauthorizedCallResponse,
  Types.UnauthorizedCallRequest,
  Types.ThunkAPI<Types.requestError>
>('auth/postUnauthorizedCall', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.postUnauthorizedCall(req);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const inviteUser = createAsyncThunk<
  Types.InviteUserResponse,
  Types.InviteUserRequest,
  Types.ThunkAPI<Types.requestError>
>('auth/postUnauthorizedCall', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.inviteUser(req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getInvoiceCorrespondenceDetail = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'InvoiceCorrespondence/thunk/getInvoiceCorrespondenceDetail',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter(INVOICE_CORRESPONDENCE_LIST.name, req);
      browserLogger.info(
        'InvoiceCorrespondence/thunk/getInvoiceCorrespondenceDetail',
        INVOICE_CORRESPONDENCE_LIST.name,
        data
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCompany = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/getCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(COMPANIES.name, req);
    browserLogger.info('InvoiceCorrespondence/thunk/getCompany', COMPANIES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeSendURL = createAsyncThunk<
  Types.UserInviteResponseType[],
  Types.UserInviteRequestType,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/executeSendURL', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.callUserInvite(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCompany = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Companies.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/updateCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(COMPANIES.name, req);
    browserLogger.info('InvoiceCorrespondence/thunk/updateCompany', COMPANIES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSingUp = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('InvoiceCorrespondence/thunk/getSingup', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(SIGNUP.name, req);
    browserLogger.info('InvoiceCorrespondence/thunk/getSingup', SIGNUP.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
