import React, { useCallback } from 'react';

export interface ReceivedProps {
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  perPage: number;
  data: Array<Object>;
}

const useSelectPerPage = (props: ReceivedProps) => {
  const { setPage, setPerPage } = props;

  const onChange = useCallback(
    (value: number) => {
      setPerPage(value);
      setPage(1);
    },
    [setPerPage, setPage]
  );
  return { onChange, ...props };
};

export type Props = ReturnType<typeof useSelectPerPage>;
export default useSelectPerPage;
