import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  header: {
    padding: 11.7,
    paddingBottom: 12,
    borderBottom: '1px solid #dddddd',
  },

  textHeader: {
    fontSize: 10.5,
    color: '#424242',
  },
});
