import yup from '../yup';
import { REGEX_HALF_WIDTH_LATIN } from 'constant';

export const createEditCurriculumSchema = yup.object().shape({
  name: yup.string().required('最大30文字で入力してください。'),
});

export const createEditQuestionSchema = yup.object().shape({
  name: yup.string().required('設問名は必須入力項目です。'),
  description: yup.string().required('設問内容は必須入力項目です。'),
  problems1: yup.string().required('選択肢Aは必須入力項目です。'),
  problems2: yup.string().required('選択肢Bは必須入力項目です。'),
  problems3: yup.string().required('選択肢Cは必須入力項目です。'),
  answer: yup.string().required(),
  time_limit: yup.string().required(),
  comment: yup.string().required('解説は必須入力項目です。'),
  score: yup
    .number()
    .required()
    .test('val', (value) => Number(value) > 0 && Number(value) < 4),
});

export const uploadCurriculumCSVSchema = yup.array().of(
  yup.object().shape({
    same_curriculum_flag: yup.string().required('同一カリキュラムフラグは必須項目です。'),
    curriculum_name: yup
      .string()
      .max(30, 'カリキュラム名称は30文字以内で入力してください。')
      .required('カリキュラム名称は必須項目です。'),
    curriculum_description: yup
      .string()
      .max(1050, 'カリキュラム説明は1050以内で入力してください。'),
    curriculum_type: yup
      .string()
      .required('カリキュラム種別は必須項目です。')
      .oneOf(['カリキュラム', ''], 'カリキュラム種別が不正です。'),
    level1_name: yup.string().max(30, '第1階層名称は30文字以内で入力してください。'),
    level2_name: yup.string().max(30, '第2階層名称は30文字以内で入力してください。'),
    level3_name: yup.string().max(30, '第3階層名称は30文字以内で入力してください。'),
    level4_name: yup.string().max(30, '第4階層名称は30文字以内で入力してください。'),
    question_code: yup.string().matches(REGEX_HALF_WIDTH_LATIN, {
      message: '設問コードは半角英数字、記号で入力してください。',
    }),
  })
);
