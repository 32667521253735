import React from 'react';
import { ContainerOutlined } from '@ant-design/icons';

import Styled from './styles';
import Modal from '../index';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ArchiveCompleted: React.FC<Props> = ({ visible, setVisible }) => {
  const handleSubmit = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
      width={416}
    >
      <Styled>
        <ContainerOutlined
          style={{
            fontSize: 28,
            color: '#bbbbbb',
            marginRight: 6,
          }}
        />
        <span>
          指定のOFFICIALカリキュラムを
          <br /> アーカイブしました。
        </span>
      </Styled>
    </Modal>
  );
};
export default ArchiveCompleted;
