import { Empty, Table, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import { TableProps } from 'antd/es';
import SectionStyled from './styles';
import { DEFAULT_TABLE_COL_WIDTH } from 'constant';
import { AnyObject } from 'types/config';
import { IconCsvError } from 'assets';
import { Resizable } from 'react-resizable';

const ResizableTitle = (props: AnyObject) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const VirtualTable = <RecordType extends object>(props: TableProps<RecordType>) => {
  const { columns: antColumns, scroll } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const [columns, setColumns] = useState(antColumns || []);

  useEffect(() => {
    setColumns(antColumns || []);
  }, [antColumns]);

  const handleResize =
    (index: number) =>
    (e: AnyObject, { size }: AnyObject) => {
      setColumns((prevColumns) => {
        if (size.width <= 100) {
          return prevColumns;
        }
        let nextColumns = [...prevColumns];
        const preWidth = nextColumns[index].width;
        nextColumns[index] = { ...nextColumns[index], width: size.width };
        const totalWidth = nextColumns.reduce((total, col) => total + Number(col.width || 0), 0);
        const diff = tableWidth - totalWidth;
        if (diff > 0) {
          if (index !== nextColumns.length - 1) {
            const widthAdd = diff / (nextColumns.length - 1 - index);
            nextColumns = nextColumns.map((col, idx) => ({
              ...col,
              width: idx > index ? Number(col.width || 0) + widthAdd : col.width,
            }));
          } else {
            const widthAdd = diff / (nextColumns.length - 2);
            nextColumns = nextColumns.map((col, idx) => ({
              ...col,
              width: idx < index ? Number(col.width || 0) + widthAdd : col.width,
            }));
          }
        }
        if (gridRef && Number(preWidth || 0) < size.width && index === nextColumns.length - 1) {
          const grids = document.getElementsByClassName('virtual-grid');
          if (grids && grids.length) {
            grids[0].scrollLeft = grids[0].scrollWidth;
          }
        }
        return nextColumns;
      });
    };

  const mergedColumns = useMemo(
    () =>
      columns!.map((column, index) => {
        if (column.width) {
          return {
            ...column,
            onHeaderCell: (col: AnyObject) => ({
              width: col.width,
              onResize: handleResize(index),
            }),
          };
        }

        return {
          ...column,
          width: DEFAULT_TABLE_COL_WIDTH,
          onHeaderCell: (col: AnyObject) => ({
            width: col.width,
            onResize: handleResize(index),
          }),
        };
      }),
    [columns]
  );

  const gridRef = useRef<any>();
  const [connectObject] = useState<any>(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollLeft;
        }
        return null;
      },
      set: (scrollLeft: number) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({ scrollLeft });
        }
      },
    });

    return obj;
  });

  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };

  useEffect(() => resetVirtualGrid, [tableWidth, mergedColumns]);

  const renderVirtualList = (rawData: object[], { scrollbarSize, ref, onScroll }: any) => {
    ref.current = connectObject;
    const totalHeight = rawData.length * 54;

    if (!rawData.length) {
      const emptyText = props.locale?.emptyText;
      return (
        <div className="empty-data">
          {emptyText ? emptyText : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </div>
      );
    }

    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={mergedColumns.length}
        columnWidth={(index: number) => {
          const { width } = mergedColumns[index];
          return totalHeight > scroll!.y! && index === mergedColumns.length - 1
            ? (width as number) - scrollbarSize - 1
            : (width as number);
        }}
        height={scroll!.y as number}
        rowCount={rawData.length}
        rowHeight={() => 54}
        width={tableWidth}
        onScroll={({ scrollLeft }: { scrollLeft: number }) => {
          onScroll({ scrollLeft });
        }}
      >
        {({
          columnIndex,
          rowIndex,
          style,
        }: {
          columnIndex: number;
          rowIndex: number;
          style: React.CSSProperties;
        }) => {
          const record: AnyObject = rawData[rowIndex];
          const column: AnyObject = (mergedColumns as any)[columnIndex];
          const dataIndex: string = column.dataIndex;
          const value = record[(mergedColumns as any)[columnIndex].dataIndex];
          if (dataIndex === 'index') {
            return (
              <div
                className={classNames('virtual-table-cell', {
                  'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
                })}
                style={style}
              >
                <div className="rowNumber">
                  {(record.hasError || record.hasWarning) && (
                    <Tooltip
                      title="この行にエラーがあります。"
                      color="#ffffff"
                      placement="bottomLeft"
                      overlayInnerStyle={{
                        backgroundColor: '#FFFFFF',
                        background: '#FFFFFF',
                        color: '#424242',
                        padding: '8px',
                      }}
                    >
                      <img src={IconCsvError} alt="iconError" />
                    </Tooltip>
                  )}
                  <div className="rowIndex">{value}</div>
                </div>
              </div>
            );
          }

          const errorKey = `${dataIndex}_error`;
          const warningKey = `${dataIndex}_warning`;
          const error = record[errorKey] || record[warningKey] || record.maxItemImport_error;

          if (error) {
            return (
              <Tooltip
                title={error}
                color="#ffffff"
                placement="bottomLeft"
                overlayInnerStyle={{
                  backgroundColor: '#FFFFFF',
                  background: '#FFFFFF',
                  color: '#424242',
                  padding: '8px',
                }}
              >
                <div
                  className={classNames('virtual-table-cell', {
                    'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
                  })}
                  style={{ ...style, background: error ? 'rgba(217, 155, 255, 0.4)' : '#FFFFFF' }}
                >
                  <Typography.Text ellipsis>{value}</Typography.Text>
                </div>
              </Tooltip>
            );
          }

          return (
            <div
              className={classNames('virtual-table-cell', {
                'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
              })}
              style={style}
            >
              <Typography.Text ellipsis>{value}</Typography.Text>
            </div>
          );
        }}
      </Grid>
    );
  };

  return (
    <SectionStyled>
      <ResizeObserver
        onResize={({ width }) => {
          setTableWidth(width);
        }}
      >
        <Table
          {...props}
          className="virtual-table"
          columns={mergedColumns}
          pagination={false}
          components={{
            body: renderVirtualList as any,
            header: { cell: ResizableTitle },
          }}
        />
      </ResizeObserver>
    </SectionStyled>
  );
};

export default VirtualTable;
