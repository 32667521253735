import { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { config } from 'configs';
import * as Types from 'types';

class ReportService {
  protected _axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this._axios = axios;
  }

  // /api/v0/applications/skillfamiliar/datastores/official_curriculum_user_aggregation/items/search
  // /api/v0/applications/datastores/skillfamiliar/items/search
  search(
    dataStoreId: string,
    data: Types.OfficialCurriculumnAggregation
  ): AxiosPromise<Types.GetItemResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/skillfamiliar/datastores/${dataStoreId}/items/search`,
      method: 'POST',
      data: {
        ...data,
        use_display_id: data.use_display_id ?? true,
      },
    };

    return this._axios(request);
  }

  searchBy(
    configId: string,
    dataStoreId: string,
    data: Types.OfficialCurriculumnAggregation
  ): AxiosPromise<Types.GetItemResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/${configId}/datastores/${dataStoreId}/items/search`,
      method: 'POST',
      data: {
        ...data,
        use_display_id: data.use_display_id ?? true,
      },
    };

    return this._axios(request);
  }

  filter(
    dataStoreId: string,
    data: Types.OfficialCurriculumnAggregation
  ): AxiosPromise<Types.ReportsItemResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID_PARTNER}/reports/${dataStoreId}/filter`,
      method: 'POST',
      data: {
        ...data,
        use_display_id: data.use_display_id ?? true,
      },
    };

    return this._axios(request);
  }
}

export default ReportService;
