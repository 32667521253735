import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  tabs: {
    paddingHorizontal: 10,
    backgroundColor: '#FFFFFF',
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    flexDirection: 'row',
  },

  tab: {},

  active: {},

  labelText: {
    fontSize: 8,
    color: '#424242',
    paddingVertical: 8,
    paddingHorizontal: 4,
  },

  labelActive: {
    fontWeight: 600,
    color: '#08A3A5',
    borderBottom: 1.5,
    borderBottomColor: '#08A3A5',
  },
});
