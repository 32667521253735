import { Document, Font, Page, Text, View } from '@react-pdf/renderer';
import FullPage from 'components/ReactPDF/FullPage';
import PageNumber from 'components/ReactPDF/PageNumber';
import styles from './style';
import { nanoid } from '@reduxjs/toolkit';
import { chunk } from 'lodash';
import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';
import SelectBox from 'components/ReactPDF/SelectBox';
import Datepicker from 'components/ReactPDF/Datepicker';
import Header from 'components/ReactPDF/Header';
import { useEffect, useRef } from 'react';
import CheckBox from 'components/ReactPDF/CheckBox';
import TableIcon from 'assets/svgPDF/Table';
import BarChartIcon from 'assets/svgPDF/BarChart';
import DateRangerPicker from 'components/ReactPDF/DateRangerpicker';

const pageSize = 'A4';
const pageOrientation = 'landscape';
interface HeaderType {
  label: string;
  key: string;
}

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

interface Props {
  visibleCorrespondingSale: boolean;
  header: Array<HeaderType>;
  result: Array<Array<string>>;
  formValue: {
    aggregation_method: string;
    target_month: string[];
    user_type: string;
    start_period: null;
    end_period: null;
  };
}

const SkillCheckInformationDocument = ({
  visibleCorrespondingSale,
  header,
  result,
  formValue,
}: Props) => {
  const viewRef = useRef(null);
  return (
    <Document style={{ backgroundColor: '#F9F8F8' }}>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} break>
        <FullPage>
          <Header title="レポート" />
          <View style={styles.body}>
            <View style={styles.navigation}>
              <View>
                <Text style={styles.navigationItem}>売上情報</Text>
              </View>
              <View>
                <Text style={styles.navigationItem}>ユーザー情報</Text>
              </View>
              <View>
                <Text style={styles.navigationItem}>OFFICIALカリキュラム情報</Text>
              </View>
              <View>
                <Text style={[styles.navigationItem, styles.navigationActiveItem]}>
                  スキルチェック実施情報
                </Text>
              </View>
            </View>

            <Text
              style={{
                fontSize: '11px',
                marginBottom: 4,
                marginTop: 19,
                marginLeft: 11.7,
              }}
            >
              集計条件
            </Text>
            <View style={styles.searchPanel}>
              <View style={styles.searchItem}>
                <Text style={styles.label}>集計方法選択</Text>
                <SelectBox
                  value={formValue.aggregation_method}
                  placeholder="指定なし"
                  selectBoxStyle={{ width: '150' }}
                />
              </View>
              <View style={styles.searchItem}>
                <Text style={styles.label}>集計期間</Text>
                <DateRangerPicker
                  placeholder="指定なし"
                  value={[
                    formValue.start_period ? new Date(formValue.start_period).toString() : '',
                    formValue.end_period ? new Date(formValue.end_period).toString() : '',
                  ]}
                  selectBoxStyle={{ width: '150' }}
                />
                {/* <Datepicker placeholder="指定なし" value={} selectBoxStyle={{ width: '150' }} /> */}
              </View>
              <View style={styles.searchItem}>
                <Text style={styles.label}>ユーザー種類選択</Text>
                <SelectBox
                  value={formValue.user_type}
                  placeholder="指定なし"
                  selectBoxStyle={{ width: '150' }}
                />
              </View>
            </View>
            <View style={styles.config}>
              <Text style={styles.layoutText}>レポートタイプ：</Text>
              <View
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <View
                  style={[
                    styles.layout,
                    styles.layoutActive,
                    {
                      paddingTop: '4px',
                      paddingBottom: '4px',
                      flexDirection: 'row',
                      display: 'flex',
                      position: 'relative',
                      borderBottomRightRadius: 0,
                      borderTopRightRadius: 0,
                      zIndex: 10,
                      alignItems: 'center',
                    },
                  ]}
                >
                  <TableIcon
                    style={{
                      width: '13px',
                      height: '13px',
                    }}
                  />
                  <Text
                    style={[
                      styles.layoutText,
                      styles.layoutTextActive,
                      {
                        marginLeft: '5px',
                      },
                    ]}
                  >
                    表
                  </Text>
                </View>
                <View
                  style={[
                    styles.layout,
                    {
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      borderBottomLeftRadius: 0,
                      borderTopLeftRadius: 0,
                      flexDirection: 'row',
                      display: 'flex',
                      position: 'relative',
                      zIndex: 1,
                      alignItems: 'center',
                      marginLeft: '0px',
                      borderLeft: 'none',
                    },
                  ]}
                >
                  <BarChartIcon />
                  <Text
                    style={[
                      styles.layoutText,
                      {
                        marginLeft: '5px',
                      },
                    ]}
                  >
                    グラフ
                  </Text>
                </View>
              </View>

              <View style={styles.check}>
                <CheckBox
                  style={styles.checkbox}
                  checked={result.length === 6 ? true : false}
                  label="対応売上表示"
                />
              </View>
            </View>
            <View
              style={{
                marginLeft: 11.7,
                marginRight: 11.7,
              }}
            >
              <View style={styles.table} ref={viewRef}>
                <View
                  style={[
                    styles.textCountGeneral,
                    styles.tableHeaderRow,
                    {
                      borderBottom: '1px solid rgba(221, 221, 221, 1)',
                    },
                  ]}
                >
                  {header.slice(0, 14).map((item: HeaderType, index) => {
                    if (index === 0) {
                      return (
                        <Text
                          style={[
                            styles.headerCell,
                            styles.wrapCell,
                            {
                              textAlign: 'left',
                              width: `100`,
                              height: '100%',
                              flexDirection: 'column',
                            },
                          ]}
                        >
                          {item.label}
                        </Text>
                      );
                    } else if (index === 1 && visibleCorrespondingSale) {
                      return (
                        <View
                          style={[
                            styles.headerCell,
                            styles.wrapCell,
                            {
                              width: '100',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start',
                            },
                          ]}
                        >
                          <Text>{item.label}</Text>
                        </View>
                      );
                    } else {
                      return (
                        <Text
                          style={[
                            styles.headerCell,
                            styles.wrapCell,
                            {
                              flex: 1,
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '100%',
                            },
                          ]}
                        >
                          {item.label}
                        </Text>
                      );
                    }
                  })}
                </View>
                {result.map((column, index) => {
                  const border = visibleCorrespondingSale
                    ? index !== 1 && index !== 3 && index !== 5
                      ? '1px dashed rgba(221, 221, 221, 1)'
                      : '1px solid rgba(221, 221, 221, 1)'
                    : '1px solid rgba(221, 221, 221, 1)';

                  const borderFirst = visibleCorrespondingSale
                    ? index + 1 === 2 || index + 1 === 4 || index + 1 === 6
                      ? '1px solid rgba(221, 221, 221, 1)'
                      : ''
                    : '1px solid rgba(221, 221, 221, 1)';

                  return (
                    <View
                      key={nanoid()}
                      style={[
                        styles.tableRow,
                        {
                          borderBottom:
                            index + 1 !== result.length ? '' : '1px solid rgba(221, 221, 221, 1)',
                        },
                      ]}
                    >
                      {column.slice(0, 14).map((i, ind) => {
                        if (ind === 0) {
                          return (
                            <View
                              style={[
                                styles.wrapCell,
                                {
                                  width: `100`,
                                  flexDirection: 'column',
                                  height: 30,
                                  borderBottom: index + 1 === result.length ? '' : borderFirst,
                                },
                              ]}
                            >
                              <View
                                style={[styles.tableCell, styles.ContentCell, styles.ContentLeft]}
                              >
                                <Text
                                  style={[
                                    styles.ContentCellValue,
                                    {
                                      textAlign: 'left',
                                    },
                                  ]}
                                >
                                  {i}
                                </Text>
                              </View>
                            </View>
                          );
                        } else if (ind === 1 && visibleCorrespondingSale) {
                          return (
                            <View
                              style={[
                                styles.wrapCell,
                                {
                                  width: `100`,
                                  height: 30,
                                  borderBottom: index + 1 === result.length ? '' : border,
                                  flexDirection: 'column',
                                },
                              ]}
                            >
                              <View
                                style={[styles.tableCell, styles.ContentCell, styles.ContentLeft]}
                              >
                                <Text
                                  style={[
                                    styles.ContentCellValue,
                                    {
                                      textAlign: 'left',
                                    },
                                  ]}
                                >
                                  {i}
                                </Text>
                              </View>
                            </View>
                          );
                        } else {
                          return (
                            <View
                              style={[
                                styles.wrapCell,
                                {
                                  flex: 1,
                                  height: 30,
                                  borderBottom: index + 1 === result.length ? '' : border,
                                  flexDirection: 'column',
                                },
                              ]}
                            >
                              <View style={[styles.tableCell, styles.ContentCell]}>
                                <Text style={[styles.ContentCellValue]}>{i}</Text>
                              </View>
                            </View>
                          );
                        }
                      })}
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </FullPage>
        <PageNumber />
      </Page>
    </Document>
  );
};

export default SkillCheckInformationDocument;
