import React from 'react';
import { Modal } from 'components';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Styled from './styles';
import useHasNotQuestionError, { Props, ReceivedProps } from './hook';

const HasNotQuestionLayout = (props: Props) => {
  const { open, handleClose } = props;

  return (
    <Styled
      width={617}
      open={open}
      okButton={{
        text: 'OK',
        onClick: handleClose,
      }}
      centered
      footerStyle={{
        paddingTop: 0,
      }}
    >
      <div className="title">
        <ExclamationCircleOutlined className="icon" />
        <span>カリキュラムに設問が設定されていない階層が存在します。</span>
      </div>
      <p className="subtitle">
        OFFICIALカリキュラム内の階層全てに設問を設定し、再度実行してください。
      </p>
    </Styled>
  );
};

const HasNotQuestion = (props: ReceivedProps) => {
  return <HasNotQuestionLayout {...useHasNotQuestionError(props)} />;
};

export default HasNotQuestion;
