import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { measureTextWidth, Line, Column, ColumnConfig, LineConfig } from '@ant-design/plots';
import { CloudDownloadOutlined, TableOutlined, BarChartOutlined } from '@ant-design/icons';
import { findIndex, forEach, get, now, omit } from 'lodash';
import { Button, Progress, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { getDataTableStorage, getSelectStorage } from './thunk';
import { formatNumberToLocaleString } from 'libs/utils/format';
import { authSelector } from 'containers/Auth/selectors';
import { storageManagementSelector } from './selectors';
import { getFileSize } from 'libs/utils/calculate';
import { EXTENSIONS, FILE_LOCATION } from 'constant';
import { CalendarIcon, CoinStack } from 'assets';
import { DatePicker, Header } from 'components';
import StorageManagementStyled from './styles';
import { ConditionsType } from '../../types';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import saveAs from 'file-saver';
import LatoRegular from 'assets/fonts/Lato-Regular.ttf';
import { Font } from '@react-pdf/renderer';

Font.register({
  family: 'Lato',
  fonts: [{ src: LatoRegular as string }],
});

const FREE_DATA = 500;

const { RangePicker } = DatePicker;
const { Option } = Select;

const StorageManagement: React.FC = () => {
  const [extensionSelected, setExtensionSelected] = useState<string>('ALL');
  const [locationSelected, setLocationSelected] = useState<string>('ALL');

  const [startPeriod, setStartPeriod] = useState<string | Dayjs>(
    dayjs().subtract(1, 'month').startOf('month')
  );
  const [endPeriod, setEndPeriod] = useState<string | Dayjs>(dayjs());
  const [corporateUser, setCorporateUser] = useState<string>('ALL');
  const [segmented, setSegmented] = useState<number>(0);
  const [report, setReport] = useState<number>(1);
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);

  const { dataTableStorage, dataSelect } = useSelector(storageManagementSelector);
  const { headerTitle, collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const dispatch = useAppDispatch();

  const ref = useRef(null);
  const dataFiltered = useMemo(() => {
    return dataTableStorage
      .slice(2)
      .filter((e) =>
        'file_extension' in e
          ? e.file_extension === extensionSelected
          : e.file_location === FILE_LOCATION[Number(locationSelected || 0) - 1]
      );
  }, [dataTableStorage, extensionSelected, locationSelected]);

  // const optionSelect = useMemo(() => {
  //   const options: Array<{
  //     value: string;
  //   }> = [];const optionSelect = useMemo(() => {
  //   //   const options: Array<{
  //   //     value: string;
  //   //   }> = [];
  //   //   if (report === 1) {
  //   //     dataTableStorage.slice(1).forEach(({ file_extension }) => {
  //   //       options.push({ value: file_extension });
  //   //     });
  //   //   }
  //   //
  //   //   if (report === 2) {
  //   //     dataTableStorage.slice(1).forEach(({ file_location }) => {
  //   //       options.push({
  //   //         value: file_location,
  //   //       });
  //   //     });
  //   //   }
  //   //
  //   //   return options;
  //   // }, [report, dataTableStorage]);
  //   if (report === 1) {
  //     dataTableStorage.slice(1).forEach(({ file_extension }) => {
  //       options.push({ value: file_extension });
  //     });
  //   }
  //
  //   if (report === 2) {
  //     dataTableStorage.slice(1).forEach(({ file_location }) => {
  //       options.push({
  //         value: file_location,
  //       });
  //     });
  //   }
  //
  //   return options;
  // }, [report, dataTableStorage]);

  const monthBetween = useMemo(() => {
    let currentMonth = dayjs(startPeriod);
    const endMonth = dayjs(endPeriod);

    const months = [];
    while (currentMonth.isBefore(endMonth) || currentMonth.isSame(endMonth, 'month')) {
      months.push(currentMonth.format('YYYY/MM'));
      currentMonth = currentMonth.add(1, 'month');
    }

    return months;
  }, [startPeriod, endPeriod]);

  const dataLineChart = useMemo(() => {
    const data = monthBetween.map((e) => {
      const dates = Object.keys(omit(dataTableStorage[0], ['file_extension']));
      const index = findIndex(dates, (date) => date === e);
      if (index > -1) {
        return {
          time: e,
          value:
            Math.ceil(Number(handleConvertSize(Number(dataTableStorage[0][e]) || 0)) / 50) * 1000,
        };
      } else {
        return {
          time: e,
          value: 0,
        };
      }
    });
    return data;
  }, [dataTableStorage, monthBetween]);

  const dataColumnChart = useMemo(() => {
    const data = monthBetween.map((e) => {
      const dates = Object.keys(omit(dataTableStorage[1], ['file_extension']));
      const index = findIndex(dates, (date) => date === e);
      if (index > -1) {
        return {
          time: e,
          value: Number(handleConvertSize(Number(dataTableStorage[1][e]) || 0)),
        };
      } else {
        return {
          time: e,
          value: 0,
        };
      }
    });
    return data;
  }, [dataTableStorage, monthBetween]);

  const configLineChart: LineConfig = {
    data: dataLineChart,
    xField: 'time',
    yField: 'value',
    label: {
      style: {
        fill: '#08A3A5',
      },
      offsetY: 0,
    },
    yAxis: {
      grid: {
        line: {
          style: {
            lineDash: [2, 2],
          },
        },
      },
    },
    xAxis: {
      tickLine: null,
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  };

  const configColumnChart: ColumnConfig = {
    data: dataColumnChart,
    xField: 'time',
    yField: 'value',
    color: '#F6AC00',
    yAxis: {
      grid: {
        line: {
          style: {
            stroke: '#ddd',
            lineDash: [2, 2],
          },
        },
      },
      label: {
        formatter(text) {
          return Number(text).toLocaleString();
        },
        style(item) {
          return { ...item, fill: '#2a2a2a', fontFamily: 'Noto Sans Javanese', fontSize: 12 };
        },
      },
    },
    xAxis: {
      tickLine: null,
      label: {
        style(item) {
          return { ...item, fill: '#2a2a2a', fontFamily: 'Noto Sans Javanese', fontSize: 12 };
        },
      },
    },
    appendPadding: 20,
    label: {
      position: 'top',
      style: {
        fill: '#F6AC00',
      },
    },
    maxColumnWidth: 16,
  };

  const fetchDataSelectStorage = useCallback(async () => {
    if (!userInfo) return;
    dispatch(startLoading());

    await dispatch(
      getSelectStorage({
        page: 1,
        per_page: 0,
      })
    );

    dispatch(stopLoading());
  }, [dispatch, userInfo]);

  const fetchDataStorage = useCallback(
    async (conditions: Array<ConditionsType>) => {
      if (!userInfo) return;

      dispatch(startLoading());
      await dispatch(
        getDataTableStorage({
          conditions: [
            ...(extensionSelected && extensionSelected !== 'ALL'
              ? [
                  {
                    id: 'file_extension',
                    search_value: EXTENSIONS.find((ex) => ex.value === extensionSelected)
                      ?.extension,
                    exact_match: true,
                    not_match: false,
                  },
                ]
              : []),
            ...(locationSelected && locationSelected !== 'ALL'
              ? [
                  {
                    id: 'file_location',
                    search_value: [locationSelected.toString()],
                    exact_match: true,
                    not_match: false,
                  },
                ]
              : []),
            ...(corporateUser === 'corporate_users'
              ? [
                  {
                    id: 'company_id',
                    search_value: ['000000000'],
                    not_match: true,
                  },
                  {
                    id: 'company_id',
                    search_value: ['000000001'],
                    not_match: true,
                  },
                ]
              : corporateUser === 'rstandard'
              ? [
                  {
                    id: 'company_id',
                    search_value: ['000000001'],
                    exact_match: true,
                  },
                ]
              : []),
          ],
          start_date: dayjs(startPeriod),
          end_date: dayjs(endPeriod),
          page: 1,
          per_page: 0,
          type: report,
          corporateUser,
          extension: extensionSelected,
        })
      );
      dispatch(stopLoading());
    },
    [
      report,
      dispatch,
      userInfo,
      extensionSelected,
      corporateUser,
      locationSelected,
      startPeriod,
      endPeriod,
    ]
  );

  const date: ColumnsType<Types.StorageManagement.TotalStorage> = monthBetween.map((item) => ({
    title: item,
    dataIndex: item,
    key: item,
    width: 100,
    align: 'right',
    children: [
      {
        title: (
          <div>
            <span className="text-table">
              {formatNumberToLocaleString(
                Math.ceil(
                  Number(handleConvertSize(Number(get(dataTableStorage[1], `${item}`, '')))) / 50 ||
                    0
                ) * 1000
              )}
            </span>
            <p className="text-table">円</p>
          </div>
        ),
        width: 100,
        className: 'sub-child',
        dataIndex: item,
        key: item,
        align: 'right',
        children: [
          {
            title: (
              <div>
                <span className="text-table">
                  {formatNumberToLocaleString(
                    Number(handleConvertSize(Number(get(dataTableStorage[1], `${item}`, ''))) || 0)
                  )}
                </span>
                <p className="text-table">GB</p>
              </div>
            ),
            width: 100,
            className: 'sub-child',
            dataIndex: item,
            key: item,
            align: 'right',
            render: (text: number) => {
              return (
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'end' }}>
                  <span>{formatNumberToLocaleString(Number(handleConvertSize(text) || 0))}</span>
                  <small>GB</small>
                </div>
              );
            },
          },
        ],
      },
    ],
  }));

  const columnTable: ColumnsType<Types.StorageManagement.TotalStorage> = [
    {
      title: report === 2 ? '機能' : report === 3 ? 'ユーザー' : 'ファイル種類',
      dataIndex: report === 1 ? 'file_extension' : report === 2 ? 'file_location' : 'user_type',
      key: report === 1 ? 'file_extension' : report === 2 ? 'file_location' : 'user_type',
      width: 200,
      fixed: 'left',
      children: [
        {
          title: 'ストレージ料金',
          key: report === 1 ? 'file_extension' : report === 2 ? 'file_location' : 'user_type',
          dataIndex: report === 1 ? 'file_extension' : report === 2 ? 'file_location' : 'user_type',
          className: 'sub-child',
          width: 200,
          fixed: 'left',

          children: [
            {
              title: 'ALL',
              key: report === 1 ? 'file_extension' : report === 2 ? 'file_location' : 'user_type',
              dataIndex:
                report === 1 ? 'file_extension' : report === 2 ? 'file_location' : 'user_type',
              className: 'sub-child',
              width: 200,
              fixed: 'left',
            },
          ],
        },
      ],
    },
    ...date,
  ];

  const handleExportCSV = (value: string) => {
    if (value === 'csv') {
      const header = columnTable.map((column, index) => ({
        label: column.title,
        key: index,
      }));

      const keys = columnTable.map((item) => {
        return item.title;
      });

      const list = dataTableStorage.map((item, idx) => {
        let object = {};
        forEach(keys, (i) => {
          if (i) {
            const field = i.toString();
            if (field === 'ファイル種類') {
              object = {
                ...object,
                file_extension: item.file_extension,
              };
            } else {
              if (!idx) {
                object = {
                  ...object,
                  [field]:
                    Math.ceil(Number(handleConvertSize(Number(item?.[field]))) / 50 || 0) * 1000 +
                    '円',
                };
              } else {
                object = {
                  ...object,
                  [field]:
                    formatNumberToLocaleString(
                      Number(handleConvertSize(Number(item?.[field])) || 0)
                    ) + 'GB',
                };
              }
            }
          }
        });

        return object;
      });

      const csvString = [
        header.map(({ label }) => label),
        ...list.map((item, index) => {
          return Object.values(item).map((i) => `"${i}"`);
        }),
      ]
        .map((e) => e.join(','))
        .join('\n');
      const bom = '\uFEFF';
      const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      saveAs(
        file,
        `${
          report === 1
            ? 'ファイル種類別推移'
            : report === 2
            ? '機能別推移'
            : report === 3
            ? 'ユーザー別推移'
            : 'store_management'
        }.csv`
      );
    } else {
      if (!ref.current || value !== 'pdf') return;
      const element = ref.current;
      html2canvas(element, {
        useCORS: true,
        scale: 2.1,
        foreignObjectRendering: false,
      }).then(async (canvas) => {
        const imgData = await canvas.toDataURL('image/png');
        const pdf = new jsPDF('l', 'mm', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const fitWidth = imgWidth * ratio;
        const fitHeight = imgHeight * ratio;
        const posX = (pdfWidth - fitWidth) / 2;
        const posY = (pdfHeight - fitHeight) / 2;
        pdf.addImage(imgData, 'PNG', posX, posY, imgWidth * ratio, imgHeight * ratio);
        pdf.save(
          `${
            report === 1
              ? 'ファイル種類別推移'
              : report === 2
              ? '機能別推移'
              : report === 3
              ? 'ユーザー別推移'
              : 'store_management'
          }.pdf`
        );
      });
    }
    setShowConfirmExportFileModal(false);
  };

  useEffect(() => {
    fetchDataStorage([]);
  }, [fetchDataStorage]);

  useEffect(() => {
    fetchDataSelectStorage();
  }, [fetchDataSelectStorage, userInfo]);

  const dataProgress = useMemo(() => {
    const data = dataSelect.dataTableFile.find((e) => e.location === 'Total');
    const totalUseData = data?.total ? Number((data.total / 1024 / 1048576).toFixed(2)) : 1;
    const percent = parseFloat(((totalUseData / FREE_DATA) * 100).toFixed(2)) ?? 0;

    const usage = data?.totalNormalCompany
      ? Number((data?.totalNormalCompany / 1024 / 1048576).toFixed(2))
      : 1;
    return {
      text:
        usage > 500 && data?.total
          ? formatNumberToLocaleString(Math.ceil((usage - 500 || 0) / 50) * 500)
          : 0,
      totalUseData: data?.totalNormalCompany ? formatNumberToLocaleString(totalUseData) : 1,
      percent: percent,
    };
  }, [dataSelect]);

  return (
    <StorageManagementStyled
      collapsedMenu={collapsedMenu}
      dateLength={dayjs(startPeriod).format('YYYY年M月').length}
    >
      <Header title={headerTitle} className="header">
        <div className="wrap-header">
          <div className="item-storage">
            <Button className="btn btn-active" onClick={() => setShowConfirmExportFileModal(true)}>
              <CloudDownloadOutlined className="size-icon" />
              エクスポート
            </Button>
          </div>
        </div>
      </Header>
      <div ref={ref}>
        <div className="table-chart">
          <div className="item-left">
            <div className="progress-circle">
              <Progress
                type="circle"
                percent={dataProgress.percent}
                width={230}
                strokeColor="#08A3A5"
                strokeWidth={3}
                format={() => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '15px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      lineHeight: 1,
                    }}
                  >
                    <div style={{ fontSize: '11px', color: '#424242', lineHeight: '14px' }}>
                      ストレージ管理
                    </div>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <p
                        style={{
                          fontWeight: 400,
                          margin: 0,
                          fontSize: '30px',
                          color: '#424242',
                          lineHeight: '24px',
                          fontFamily: 'Lato',
                        }}
                      >
                        {dataProgress.text}
                        <small>円</small>
                      </p>
                    </div>
                    <div
                      style={{ width: '180px', height: '2px', backgroundColor: '#DDDDDD' }}
                    ></div>
                    <div
                      style={{
                        fontWeight: 400,
                        fontSize: '30px',
                        color: '#424242',
                        lineHeight: '24px',
                        fontFamily: 'Lato',
                      }}
                    >
                      {dataProgress.totalUseData}
                      <small>GB</small>
                    </div>
                    <div
                      style={{
                        fontWeight: 400,
                        margin: 0,
                        fontSize: '14px',
                        color: '#424242',
                        lineHeight: '14px',
                      }}
                    >
                      総使用量
                    </div>
                  </div>
                )}
              />
              <div className="progress-circle-inner">
                <Progress
                  type="circle"
                  percent={100}
                  width={210}
                  strokeColor="#08A3A5"
                  strokeWidth={1}
                  format={() => ''}
                />
              </div>
            </div>

            <div className="text-note">
              ストレージ料金は法人ユーザーのストレージ利用料の合計
              <br />
              総使用量は全ストレージ利用量の合計
            </div>
          </div>
          <div className="item-right">
            <Table
              className="table"
              dataSource={dataSelect.dataTableFile.map((item, index) => ({ ...item, index }))}
              columns={columns}
              pagination={false}
              rowKey="index"
            />
          </div>
        </div>
        <div className="container">
          <div className="label-content">
            <img src={CoinStack} className="icon" alt="coin-stack" />
            <span>ストレージ推移</span>
          </div>
          <div className="item-select">
            <div className="form-select">
              <div className="item">
                <span className="text-label">レポート</span>
                <Select
                  className="select"
                  defaultValue={report}
                  onSelect={(e) => setReport(e)}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                >
                  <Option value={1}>ファイル種類別推移</Option>
                  <Option value={2}>機能別推移</Option>
                  <Option value={3}>ユーザー別推移</Option>
                </Select>
              </div>
              <div className="item">
                <span className="text-label">期間選択</span>
                <RangePicker
                  allowClear
                  className="date"
                  name="date-item"
                  format="YYYY年M月"
                  placeholder={['集計開始日', '集計終了日']}
                  suffixIcon={<img src={CalendarIcon} />}
                  onChange={(dates) => {
                    if (dates) {
                      setStartPeriod(dayjs(dates[0]).format('YYYY/MM'));
                      setEndPeriod(dayjs(dates[1]).format('YYYY/MM'));
                    } else {
                      setStartPeriod('');
                      setEndPeriod('');
                    }
                  }}
                  defaultValue={[
                    dayjs().subtract(1, 'month').startOf('month'),
                    dayjs().startOf('month'),
                  ]}
                  picker="month"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement!}
                />
              </div>
              {report !== 3 && (
                <div className="item">
                  {report === 1 ? (
                    <>
                      <span className="text-label"> ファイル種類</span>
                      <Select
                        className="select"
                        defaultValue="ALL"
                        onSelect={(e) => setExtensionSelected(e)}
                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                      >
                        <Option value="ALL">ALL</Option>
                        <Option value="image">画像</Option>
                        <Option value="video">動画</Option>
                        <Option value="excel">Excel</Option>
                        <Option value="word">Word</Option>
                        <Option value="pdf">PDF</Option>
                        <Option value="power-point">PowerPoint</Option>
                        <Option value="other">その他</Option>
                      </Select>
                    </>
                  ) : (
                    <>
                      <span className="text-label">機能</span>
                      <Select
                        className="select"
                        defaultValue="ALL"
                        onSelect={(e) => setLocationSelected(e)}
                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                      >
                        <Option value="ALL">ALL</Option>
                        {FILE_LOCATION.map((loc, index) => (
                          <Option value={index + 1} key={index}>
                            {loc}
                          </Option>
                        ))}
                      </Select>
                    </>
                  )}
                </div>
              )}
              <div className="item">
                <span className="text-label">ユーザー種類</span>
                <Select
                  className="select"
                  defaultValue="ALL"
                  onSelect={(e) => setCorporateUser(e)}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                >
                  <Option value="ALL">ALL</Option>
                  <Option value="corporate_users">法人ユーザー</Option>
                  <Option value="partner">パートナー</Option>
                  <Option value="rstandard">RSTANDARD</Option>
                </Select>
              </div>
              <div className="item">
                <span className="text-label">レポートタイプ</span>
                <div className="segmented">
                  <div
                    className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                    onClick={() => {
                      setSegmented(0);
                    }}
                  >
                    <TableOutlined className="icon" />
                    <span>表</span>
                  </div>
                  <div
                    className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                    onClick={() => {
                      setSegmented(1);
                    }}
                  >
                    <BarChartOutlined className="icon" />
                    <span>グラフ</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wrap-table">
            {segmented ? (
              <div className="chart-container">
                <div className="wrap-chart">
                  <div className="wrap-title">
                    <p className="title-fee">ストレージ料金</p>
                    <p className="unit">単位：円</p>
                  </div>
                  <Line
                    {...configLineChart}
                    lineStyle={{
                      stroke: '#08A3A5',
                    }}
                    point={{
                      color: '#08A3A5',
                      size: 5,
                    }}
                    height={240}
                    yAxis={{
                      tickCount: 8,
                      label: {
                        style(item) {
                          return {
                            ...item,
                            fill: '#2a2a2a',
                            fontFamily: 'Noto Sans Javanese',
                            fontSize: 12,
                          };
                        },
                        formatter(text) {
                          return Number(text).toLocaleString();
                        },
                      },
                    }}
                    xAxis={{
                      label: {
                        style(item) {
                          return { ...item, fill: '#2a2a2a', fontFamily: 'Noto Sans Javanese' };
                        },
                      },
                    }}
                  />
                </div>
                <div className="wrap-chart">
                  <div className="wrap-title">
                    <p className="title-fee">ストレージ使用量</p>
                    <p className="unit">単位：GB</p>
                  </div>
                  <Column
                    {...configColumnChart}
                    height={261}
                    // xAxis={{
                    //   tickLine: null,
                    // }}
                  />
                </div>
              </div>
            ) : (
              <Table
                rowKey="code"
                columns={columnTable}
                dataSource={dataFiltered.length ? dataFiltered : dataTableStorage.slice(2)}
                bordered={true}
                pagination={false}
                locale={{
                  emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
                }}
                scroll={{ x: 1400 }}
              />
            )}
          </div>
        </div>
      </div>

      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
    </StorageManagementStyled>
  );
};

const renderStatistic = (
  containerWidth: number,
  text: string | number,
  type: 'title' | 'content',
  style: CSSProperties
) => {
  const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
  const R = containerWidth / 2;
  let scale = 1;

  if (containerWidth < textWidth) {
    scale = Math.min(
      Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))),
      1
    );
  }
  if (type === 'title') {
    return `<div style="display: flex;flex-direction: column;align-items: center;justify-content: center;width:${containerWidth}px;font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };"><div style="font-size: 11px; color: #424242;">ストレージ管理</div><div style="display: flex; align-items: baseline;></div><h style="font-weight: 400;margin: 0;font-size: 30px;color: #424242;"></h><p style="font-weight: 400;margin: 0;font-size: 30px;color: #424242;">${text}<small>円</small> </p></div><div style="width: 180px; height: 2px; background-color: #DDDDDD;" /></div>`;
  } else {
    return `<div style="width:${containerWidth}px;font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    }; padding-top: 15px; display: flex;flex-direction: column; "><span style="font-weight: 400;font-size: 30px;color: #424242;">${text}<small>GB</small></span><span style="font-weight: 400;margin: 0;font-size: 14px;color: #424242;">総使用量</span></div>`;
  }
};

const config = {
  height: 230,
  width: 230,
  autoFit: false,
  percent: 0.6,
  color: ['#EBEBEB', '#08A3A5'],
  innerRadius: 0.92,
  radius: 1,
  cornerRadius: 0.1,
};

export default StorageManagement;

const columns = [
  {
    title: 'ストレージ内訳',
    dataIndex: 'location',
    key: 'location',
    className: 'storage-breakdown',
    width: '20%',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    width: '10%',
    render: (text: string) => {
      const { unit, value } = getFileSize(text);
      return (
        <span>
          {formatNumberToLocaleString(Number(value))}
          <small className="small">{unit}</small>
        </span>
      );
    },
  },
  {
    title: '画像',
    dataIndex: 'image',
    key: 'image',
    width: '10%',
    render: (text: string) => {
      const { unit, value } = getFileSize(text);
      return (
        <span>
          {formatNumberToLocaleString(Number(value))}
          <small className="small">{unit}</small>
        </span>
      );
    },
  },
  {
    title: '動画',
    dataIndex: 'video',
    key: 'movie',
    width: '10%',
    render: (text: string) => {
      const { unit, value } = getFileSize(text);
      return (
        <span>
          {formatNumberToLocaleString(Number(value))}
          <small className="small">{unit}</small>
        </span>
      );
    },
  },
  {
    title: 'Excel',
    dataIndex: 'excel',
    key: 'excel',
    width: '10%',
    render: (text: string) => {
      const { unit, value } = getFileSize(text);
      return (
        <span>
          {formatNumberToLocaleString(Number(value))}
          <small className="small">{unit}</small>
        </span>
      );
    },
  },
  {
    title: 'Word',
    dataIndex: 'word',
    key: 'word',
    width: '10%',
    render: (text: string) => {
      const { unit, value } = getFileSize(text);
      return (
        <span>
          {formatNumberToLocaleString(Number(value))}
          <small className="small">{unit}</small>
        </span>
      );
    },
  },
  {
    title: 'PDF',
    dataIndex: 'pdf',
    key: 'pdf',
    width: '10%',
    render: (text: string) => {
      const { unit, value } = getFileSize(text);
      return (
        <span>
          {formatNumberToLocaleString(Number(value))}
          <small className="small">{unit}</small>
        </span>
      );
    },
  },
  {
    title: 'PowerPoint',
    dataIndex: 'power_point',
    key: 'power_point',
    width: '10%',
    render: (text: string) => {
      const { unit, value } = getFileSize(text);
      return (
        <span>
          {formatNumberToLocaleString(Number(value))}
          <small className="small">{unit}</small>
        </span>
      );
    },
  },
  {
    title: 'その他',
    dataIndex: 'other',
    key: 'other',
    width: '10%',
    render: (text: string) => {
      const { unit, value } = getFileSize(text);
      return (
        <span>
          {formatNumberToLocaleString(Number(value))}
          <small className="small">{unit}</small>
        </span>
      );
    },
  },
];

const handleConvertSize = (size: number) => {
  const fileSize = Number(size) / 1024 || 0;
  return `${(fileSize / 1048576).toFixed(4)}`;
};
