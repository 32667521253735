import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    fontFamily: 'NotosanJP',
    flexDirection: 'column',
    textAlign: 'left',
    width: '100%',
    backgroundColor: '#F9F8F8',
  },

  body: {},

  configLeft: {},
  configRight: {},

  pagination: {
    flexWrap: 'wrap',
  },

  flexItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },

  PagitionText: {
    fontSize: 10.5,
    color: '#424242',
  },
  border: {
    border: '1px solid #D9D9D9',
    borderRadius: 3,
  },

  paginationItemDisabled: {
    border: '1px solid #D9D9D9',
    color: '#666666',
    backgroundColor: 'rgba(251,251,251 , 0.4)',
  },

  paginationItem: {
    border: '1px solid #D9D9D9',
    borderRadius: 2,
    fontSize: 8.5,
    width: '21px',
    height: '21px',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#666666',
    marginLeft: 3,
  },

  paginationItemActive: {
    border: '1px solid #00A3A5',
    color: '#00A3A5',
  },

  table: {
    marginTop: '8px',
    flexDirection: 'column',
    textAlign: 'left',

    width: '100%',
    border: '1px solid rgba(221, 221, 221, 1)',
    borderRadius: 2,
    backgroundColor: '#fff',
    borderBottom: 'none',
  },

  textCountGeneral: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: '100%',
  },

  tableHeaderRow: {
    backgroundColor: '#ebebeb',
  },

  headerCell: {
    fontSize: '9px',
    paddingTop: 14,
    paddingLeft: 8,
    paddingBottom: 6,
    paddingRight: 8,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#2a2a2a',
    textAlign: 'center',
    fontWeight: 700,
  },

  ContentLeft: {
    justifyContent: 'flex-start',
  },

  tableRow: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },

  Bold: {},

  pagePadding: {
    paddingHorizontal: 11.7,
    paddingTop: 11.7,
    paddingBottom: 24,
    marginTop: 11.7,
  },

  searchItem: {
    paddingTop: 10,
    minWidth: 70,
    marginRight: 4,
  },

  navigation: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginLeft: 11.7,
    boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.05)',
    backgroundColor: 'white',
  },

  navigationItem: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    fontSize: 10,
    color: '#424242',
    paddingBottom: 17,
    paddingTop: 17,
    fontWeight: 400,
    marginRight: 30,
    borderBottom: '1px solid white',
  },

  navigationActiveItem: {
    color: '#08A3A5',
    fontWeight: 700,
    borderBottom: '1px solid #08A3A5',
  },

  searchPanel: {
    backgroundColor: 'white',
    borderRadius: 3,
    border: '1px solid #EAEAEA',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 11.7,
    paddingRight: 11.7,
    marginLeft: 11.7,
    marginRight: 11.7,
    marginBottom: 11.7,
  },

  label: {
    fontSize: 8.7,
    color: '#424242',
    paddingVertical: 2,
  },

  wrapCell: {
    whiteSpace: 'nowrap',
    borderRight: '1px solid rgba(221, 221, 221, 1)',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100%',
    textOverflow: 'ellipsis',
    // padding: 6.35,
  },

  tableCell: {
    // textAlign: 'left',
    color: '#424242',
    boder: '1px solid rgba(221, 221, 221, 1)',
  },

  ContentCell: {
    margin: 0,
    fontSize: '10px',
    color: '#424242',
    padding: '16px 12px',
    textAlign: 'center',
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  ContentRight: {
    justifyContent: 'flex-end',
  },

  ContentCellValue: {
    margin: 0,
    fontSize: '9px',
    color: '#424242',
  },

  ContentCellSuffix: {
    margin: 0,
    color: '#999999',
    fontSize: '10px',
  },

  labelButton: {
    color: '#00a3a5',
    textDecoration: 'underline',
  },

  button: {
    backgroundColor: '#08a3a5',
    boxShadow: '1px 1px 4px rgba(68,68,68,0.2)',
    borderRadius: 5,
    justifyContent: 'center',
    textAlign: 'center',
  },

  buttonText: {
    fontSize: 10,
    color: 'white',
  },

  check: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 3,
    marginLeft: 5,
    paddingBottom: 5,
    paddingTop: 5,
    border: '1px solid #cccccc',
    backgroundColor: 'rgb(255,255,255)',
  },

  checkdisable: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 3,
    marginLeft: 5,
    paddingBottom: 5,
    paddingTop: 5,
    border: '1px solid #EBEBEB',
    backgroundColor: 'rgb(251,251,251)',

    label: {
      color: '#AAAAAA',
    },
  },

  checkbox: {},

  icon: {
    height: 12,
    width: 12,
  },

  config: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 11.7,
    width: '100%',
    justifyContent: 'space-between',
    marginRight: 11.7,
    flexDirection: 'row',
  },

  layoutText: {
    color: '#424242',
    fontSize: 9.75,
  },

  layoutTextActive: {
    color: '#08a3a5',
  },

  layoutActive: {
    border: '1px solid #08a3a5',
    borderRadius: 3,
  },

  layout: {
    alignItems: 'center',
    flexDirection: 'row',
    border: '1px solid #cccccc',
    borderRadius: 3,
    padding: '7.5px 6.8px 16.5px',
  },
});
