import { ExclamationCircleOutlined } from '@ant-design/icons';

import useErrorSignUp, { Props, ReceivedProps } from './hook';
import { Modal } from 'components';
import Styled from './styles';

const ErrorSignUpLayout = (props: Props) => {
  const { open, handleClose } = props;

  return (
    <Modal
      open={open}
      width={416}
      okButton={{
        text: 'OK',
        onClick: handleClose,
      }}
      style={{
        borderRadius: 4,
        overflow: 'hidden',
      }}
    >
      <Styled>
        <div className="title">
          <ExclamationCircleOutlined className="icon" />
          <span className="text-title">このメールはすでに利用されました。</span>
        </div>
        <span> 別のメールで試してください。</span>
      </Styled>
    </Modal>
  );
};

const ErrorSignUpModal = (props: ReceivedProps) => {
  return <ErrorSignUpLayout {...useErrorSignUp(props)} />;
};

export default ErrorSignUpModal;
