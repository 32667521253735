import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';

import * as Types from 'types';
import styles from './styles';
import { CheckedIconPdf, CheckOffPdf, DownIconPdf, QAIconPdf } from 'assets/svgPDF';
import React from 'react';
import _ from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple@15.0.1/img/apple/64/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface PDFQuestionMasterTableProps {
  dataQuestion: Types.TreeTraining[];
  statusTime?: boolean;
  timeLimit?: string;
  curriculum_name?: string;
  required_curriculum?: string;
  createdby?: string;
  question_name?: string;
  score?: string;
  total?: number;
  page?: number;
  limit?: number;
}

const FIRST_PAGE_ROW_COUNT = 13;
const PAGE_ROW_COUNT = 20;

const QuestionMasterTable = ({ dataSource }: { dataSource: Types.TreeTraining[] }) => {
  const columns: { title: string; width: string | number; key: string }[] = [
    {
      title: 'No.',
      width: 30,
      key: 'index',
    },
    {
      title: '設問コード',
      width: 70,
      key: 'question_code',
    },
    {
      title: '設問名',
      width: 240,
      key: 'question_name',
    },
    {
      title: '設問内容抜粋',
      width: 220,
      key: 'question_description',
    },
    {
      title: '製作者',
      width: 220,
      key: 'createdby',
    },
    {
      title: 'スコア',
      width: 50,
      key: 'score',
    },
  ];
  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeaderRow]}>
        {columns.map((column, index) => (
          <View
            key={index}
            style={[
              styles.wrapCell,
              { width: column.width, textAlign: [0, 5].includes(index) ? 'center' : 'left' },
            ]}
          >
            <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
          </View>
        ))}
      </View>

      {dataSource.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5',
            },
          ]}
        >
          <View style={[styles.wrapCell, { width: columns[0].width }]}>
            <Text style={[styles.tableCell, { textAlign: 'right' }]}>{row.index}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[1].width }]}>
            <Text style={styles.tableCell}>{row.question_code}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[2].width }]}>
            <Text style={styles.tableCell}>{row.question_name}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[3].width }]}>
            <Text style={styles.tableCell}>{row.question_description}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[4].width }]}>
            <Text style={styles.tableCell}>{row.creator}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[5].width }]}>
            <Text style={[styles.tableCell, { textAlign: 'center' }]}>{row.score}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const PDFQuestionMasterTable = (props: PDFQuestionMasterTableProps) => {
  const {
    dataQuestion,
    statusTime,
    timeLimit,
    createdby,
    curriculum_name,
    question_name,
    score,
    required_curriculum,
    total,
    page,
    limit,
  } = props;
  const dataSource = (dataQuestion || []).map((row, index) => ({ ...row, index: index + 1 }));
  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page}>
        <FullPage>
          <View style={styles.header}>
            <Text style={styles.textHeader}>設問マスタ</Text>
          </View>

          <View style={styles.subscription}>
            <View>
              <Text style={styles.textSubscription}>
                設問の作成・編集、作成した設問の一覧を確認できます。
              </Text>
              <Text style={styles.textSubscription}>
                設問に設定するデフォルトの制限時間も設定可能です。
              </Text>
            </View>
            {!statusTime ? (
              <View style={styles.statusTime}>
                <Text style={styles.statusTimeText}>制限時間の初期値</Text>
                <QAIconPdf style={styles.statusTimeIcon} />
                <Text style={styles.statusTimeText}>:</Text>
                <View style={styles.selectBox}>
                  <Text style={styles.selectText}>{timeLimit || ''}</Text>
                  <DownIconPdf style={styles.selectIcon} />
                </View>
                <View style={styles.button}>
                  <Text>設定</Text>
                </View>
              </View>
            ) : (
              <View style={styles.statusTime}>
                <CheckedIconPdf style={styles.statusTimeIcon} />
                <Text style={styles.statusTimeText}>設定した時間を初期値として登録しました</Text>
              </View>
            )}
          </View>
          <View style={styles.divider}></View>
          <View style={styles.searchPanel}>
            <View style={styles.searchItem}>
              <Text style={styles.label}>カリキュラム名</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>{curriculum_name || ''}</Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>カリキュラム種類</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>{required_curriculum || ''}</Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>製作者</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>{createdby || ''}</Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>設問名</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>{question_name || ''}</Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
            <View style={styles.searchItem}>
              <Text style={styles.label}>スコア（設問難易度）</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>{score || ''}</Text>
                <DownIconPdf style={styles.selectIcon} />
              </View>
            </View>
          </View>
          <View style={styles.body}>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>
                {page && limit ? (page - 1) * limit + 1 : 1}
              </Text>
              <Text style={styles.textCountNumber}>-</Text>
              <Text style={styles.textCountNumber}>
                {page && limit && total && total < (limit ?? 0)
                  ? total
                  : (limit ?? 0) * (page ?? 1)}
              </Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{total || dataQuestion.length}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
            <QuestionMasterTable dataSource={dataSource.slice(0, FIRST_PAGE_ROW_COUNT)} />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {dataSource.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(dataSource.slice(FIRST_PAGE_ROW_COUNT, dataSource.length), PAGE_ROW_COUNT).map(
          (page, index) => (
            <Page
              key={index}
              size={pageSize}
              orientation={pageOrientation}
              style={[styles.page, styles.pagePadding]}
              wrap={false}
            >
              <FullPage>
                <QuestionMasterTable dataSource={page} />
              </FullPage>
              <PageNumber />
            </Page>
          )
        )}
    </Document>
  );
};

export default PDFQuestionMasterTable;
