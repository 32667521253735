import styled from 'styled-components';

import { Setting } from 'assets';

export default styled.section`
  height: 100%;
  background: #f2f2f2;
  .btn-outline {
    width: 160px !important;
    height: 32px !important;
  }
  .form-search {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    .item-select {
      display: flex;
      width: 85% !important;
      margin-bottom: 25px;
      .item {
        width: 12%;
        margin: 0 8px;
      }
    }
  }
  .contract-status {
    margin: 0 8px 0 20px !important;
  }
  .partner-name {
    width: 18% !important;
    margin: 0 40px 0 8px !important;
  }
  .curriculum-name {
    width: 18% !important;
  }
  .group-btn {
    display: flex;
    width: 13%;
    justify-content: end;
    align-items: center;
    .btn-search {
      width: 100px;
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
    }
    .label-reset {
      padding-left: 15px;
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
    }
  }
  @media (max-width: 1440px) {
    .header {
      .form {
        .form-input:nth-child(2) .ant-select-selector {
          width: 106px !important;
          height: 36px;
        }
        .text-reset {
          font-size: 13px;
        }
      }
      .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        width: 236px;
        height: 36px;
      }
    }
    .flex {
      .dashboard {
        background-color: #fff .wrap-title {
          height: 115px;
          .wrap-button {
            padding: 30px 57px 25px 30px !important;
          }
          .button-function {
            .btn {
              padding: 5px 10px !important;
            }
            .size-icon {
              font-size: 16px !important;
            }
            .btn-active {
              width: 160px !important;
              height: 32px !important;
            }
          }
          .flex-label {
            .label-text {
              margin: 0 !important;
            }
          }
        }
        .wrap-body {
          .title .btn-add {
            width: 160px;
          }
        }
      }
    }
  }
  .header {
    .filter-icon {
      color: #c4c4c4;
      margin-right: 5px;
    }
    .sortByDesc-icon {
      margin: 0 5px 0 20px;
    }
    .text-link {
      font-size: 14px;
      text-align: center;
      text-decoration-line: underline;
      color: #00a3a5;
      margin-left: 20px;
    }
    .form {
      display: flex;
      align-items: center;
      .form-input {
        margin-bottom: unset;
        &:nth-child(2) {
          .ant-select-selector {
            width: 154px;
          }
        }
        &:nth-child(3) {
          .ant-select-selector {
            width: 125px;
          }
        }
        &:nth-child(5) {
          .ant-select-selector {
            width: 238px;
          }
        }
      }
      .text-reset {
        border: none;
        background-color: unset;
        text-decoration-line: underline;
        color: #08a3a5;
        cursor: pointer;
      }
    }
  }
  .flex {
    display: flex;
    height: calc(100% - 56px);
    .dashboard {
      width: 100%;
      position: relative;
      .on-top {
        position: fixed;
        top: 0;
        z-index: 999;
      }
      .setting-border {
        .setting {
          position: absolute;
          top: 5px;
          right: 1px;
          z-index: 10;
          cursor: pointer;
          .title {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            width: 40px;
            height: 130px;
            background-image: url(${Setting});
            background-repeat: no-repeat;
            padding: 10px 0;
            font-size: 13px;
            color: #ffffff;
            .opened {
              transform: rotate(180deg);
            }
            p {
              writing-mode: tb-rl;
              font-weight: 700;
              margin: 0;
            }
          }
        }
        .border-green {
          width: 5px;
          height: 152px;
          position: absolute;
          top: 0;
          right: 0;
          background: #00a3a5;
        }
      }
      .wrap-title {
        background: #ffffff;
        border-bottom: 1px solid #ebeaea;
        .table-partner {
          .ant-table-content {
            border: 0.5px solid #d9d9d9 !important;
            boder-radius: 5px;
          }
          .ant-table-thead > tr > th {
            background: #f2f2f2 !important;
            font-size: 12px !important;
            font-weight: 400 !important;
            height: 32px !important;
            padding: 5px 30px !important;
            border: 0.5px solid #d9d9d9;
          }
          .ant-table-thead > tr > th:before {
            width: 0px !important;
          }
          .ant-table-tbody > tr > td {
            height: 38px !important;
            font-size: 11px !important;
            font-weight: 400 !important;
            padding: 5px 30px !important;
            border: 0.5px solid #d9d9d9;
          }
        }
        .wrap-button {
          display: flex;
          justify-content: space-between;
          padding: 25px 40px 2px 25px;
          @media (max-width: 1440px) {
            padding: 10px;
          }
        }
        .button-function {
          .btn {
            padding: 5px 12px;
            width: 140px;
            cursor: pointer;
            font-size: 13px;
            border-radius: 5px;
            color: #f1a900;
            font-weight: bold;
            margin-left: 8px;
          }
          .btn-active {
            background: #f6ac00;
            border: 1px solid #f6ac00;
            color: #ffffff;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            border-radius: 5px;
            text-align: start;
            .size-icon {
              font-size: 20px;
              margin-right: 20px;
              margin-top: 1px;
            }
            .anticon-cloud-upload .anticon {
              font-size: 20px !important;
              line-height: 0px;
            }
          }
          .btn-outline {
            background: #ffffff;
            border: 1px solid #f6ac00;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            border-radius: 5px;
            text-align: center;
            .size-icon-down-outline {
              color: #f6ac00;
              font-size: 10px;
              margin-left: 20px;
            }
          }
        }
        .flex-label {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          .label-text {
            font-size: 13px;
            color: #777777;
            width: 16.67%;
            cursor: pointer;
            text-align: center;
            user-select: none;
            .icon-label {
              margin-right: 7px;
            }
          }
          .active {
            color: #00a3a5;
            font-weight: 700;
          }
        }
      }
      .wrap-body {
        .title {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 20px;
          .btn-add {
            background: #f6ac00;
            font-weight: bold;
            font-size: 12px;
            color: #ffffff;
            border: none;
            cursor: pointer;
            border-radius: 5px;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            padding: 5px 15px;
          }
        }
        .wrap-title-tab-1 {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #eff9fc;
          border: 2px solid #009acb;
          border-radius: 2px;
          margin: 20px;
          .title {
            font-weight: 700;
            font-size: 15px;
            align-items: center;
            color: #009acb;
            margin: 10px;
          }
          .sub-title {
            font-size: 12px;
            font-weight: 400;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .wrap-tree {
          width: 100%;
          background: #f2f2f2;
          border: 1px solid rgba(0, 0, 0, 0.05);
          padding: 20px;
        }
        .bordered {
          border-bottom: 4px solid #eeeeee;
        }
      }
    }
  }
`;

type ButtonProps = { tabActive?: number; index: number };

export const Button = styled.button<ButtonProps>`
  padding: 0 20px;
  height: 32px;
  cursor: pointer;
  font-size: 13px;
  box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
  border-left: none;
  border: 1px solid
    ${(props) =>
      props.tabActive === 0 ? '#00a3a5' : props.tabActive === 1 ? '#009ACB' : '#EB8B55'};
  ${(props) =>
    props.tabActive === props.index &&
    `.size-icon {
        margin-right: 12px;
      }`};
  &:nth-child(3) {
    border-radius: 0 3px 3px 0;
  }
  &:nth-child(1) {
    border-radius: 3px 0 0 3px;
  }
  @media (max-width: 1440px) {
    padding: 0 12px;
    font-size: 11px;
  }
`;
