import { Document, Font, Page, Text, View } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import NotosanJPBold from 'assets/fonts/NotoSansJP-Bold.otf';

import * as Types from 'types';
import styles from './styles';
import _ from 'lodash';
import PageNumber from 'components/ReactPDF/PageNumber';
import FullPage from 'components/ReactPDF/FullPage';
import dayjs from 'dayjs';
import SelectBox from 'components/ReactPDF/SelectBox';
import { FormilkProperty } from '..';

Font.register({
  family: 'NotosanJP',
  fonts: [{ src: NotosanJP as string }, { src: NotosanJPBold as string, fontWeight: 'bold' }],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface Props {
  formValue: FormilkProperty;
  dataErr: Types.BillingDataDetail.ResponseType[];
}

const FIRST_PAGE_ROW_COUNT = 13;
const PAGE_ROW_COUNT = 18;

const BillingDetailCorporateTable = ({
  dataSource,
}: {
  dataSource: Array<Types.BillingDataDetail.ResponseType>;
}) => {
  const columns: { title: string | string[]; width: string | number; key: string }[] = [
    {
      title: ['役務提供年月・請求データ番号', '請求データ名称'],
      width: 200,
      key: 'provided_date',
    },
    {
      title: ['オーダーID', '会員ID'],
      width: 100,
      key: 'billing_id',
    },
    {
      title: ['ユーザーID', 'ユーザー名'],
      width: 150,
      key: 'billing_data_name',
    },
    {
      title: '請求種類',
      width: 75,
      key: 'payment_method',
    },
    {
      title: '決済結果',
      width: 90,
      key: 'billing_type',
    },
    {
      title: '請求日',
      width: 82.5,
      key: 'billing_month',
    },
    {
      title: 'アカウント数',
      width: 70,
      key: 'num_of_billings',
    },
    {
      title: '金額',
      width: 70,
      key: 'num_of_errors',
    },
  ];

  const isStringArray = (val: any) => {
    if (Array.isArray(val)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <View style={styles.table}>
      <View
        style={[
          styles.tableRow,
          styles.tableHeaderRow,
          {
            height: '26.728px',
            borderBottom: 'none',
            borderTopLeftRadius: 2,
            borderTopRightRadius: 2,
          },
        ]}
      >
        {columns.map((column, index) =>
          isStringArray(column.title) ? (
            <View
              key={index}
              style={[
                styles.wrapCell,
                { width: column.width, display: 'flex', flexDirection: 'column' },
              ]}
            >
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title[0]}</Text>
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title[1]}</Text>
            </View>
          ) : (
            <View key={index} style={[styles.wrapCell, { width: column.width }]}>
              <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
            </View>
          )
        )}
      </View>

      {dataSource.map((row, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.tableRow,
            {
              borderBottom: rowIndex !== dataSource.length - 1 ? 'none' : '1px solid #e5e5e5',
              borderBottomLeftRadius: rowIndex === dataSource.length - 1 ? 2 : 0,
              borderBottomRightRadius: rowIndex === dataSource.length - 1 ? 2 : 0,
            },
          ]}
        >
          <View style={[styles.wrapCell, { width: columns[0].width }]}>
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
            >
              <Text style={styles.tableCell}>{dayjs(row.provided_date).format('YYYY/MM')}</Text>
              <Text
                style={[
                  styles.tableCell,
                  {
                    marginLeft: 5,
                  },
                ]}
              >
                {row.billing_id}
              </Text>
            </View>
            <Text style={styles.tableCell}>{row.billing_data_name}</Text>
          </View>
          <View
            style={[
              styles.wrapCell,
              { width: columns[1].width, display: 'flex', flexDirection: 'column' },
            ]}
          >
            <Text style={styles.tableCell}>{row.order_id}</Text>
            <Text style={styles.tableCell}>{row.company_id}</Text>
          </View>
          <View
            style={[
              styles.wrapCell,
              { width: columns[2].width, display: 'flex', flexDirection: 'column' },
            ]}
          >
            <Text style={[styles.tableCell]}>{row.company_id}</Text>
            <Text style={[styles.tableCell]}>{row.company_name}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[3].width }]}>
            <Text style={styles.tableCell}>
              {row.billing_type === 1 ? '通常請求' : 'エラー請求'}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[4].width }]}>
            <Text style={styles.tableCell}>{row.error_code ? '失敗' : '成功'}</Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[5].width }]}>
            <Text style={styles.tableCell}>
              {row.billing_date ? dayjs(row.billing_date).format('YYYY/MM/DD') : '-'}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[6].width }]}>
            <Text style={styles.tableCell}>
              {Number(row.num_of_accounts || 0).toLocaleString()}
            </Text>
          </View>
          <View style={[styles.wrapCell, { width: columns[7].width }]}>
            <Text style={styles.tableCell}>{row.amount}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const PDFDetailCorporate = (props: Props) => {
  const { dataErr, formValue } = props;
  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page} wrap>
        <FullPage>
          <View style={styles.header}>
            <Text style={styles.textHeader}>請求データエラー明細［法人・クレジット］</Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 10,
            }}
          >
            <View style={styles.searchPanel}>
              <SelectBox
                label="オーダーID"
                placeholder="指定なし"
                style={{
                  width: 130,
                }}
                selectBoxStyle={{
                  minWidth: 130,
                }}
                value={formValue.order_id}
              ></SelectBox>
              <SelectBox
                label="会員ID"
                placeholder="指定なし"
                style={{
                  marginLeft: 4,
                  width: 130,
                }}
                selectBoxStyle={{
                  minWidth: 130,
                }}
                value={formValue.company_id}
              ></SelectBox>
              <SelectBox
                label="ユーザーID"
                placeholder="指定なし"
                style={{
                  marginLeft: 4,
                  width: 130,
                }}
                selectBoxStyle={{
                  minWidth: 130,
                }}
                value={formValue.company_id}
              ></SelectBox>
              <SelectBox
                label="ユーザー名"
                placeholder="指定なし"
                style={{
                  marginLeft: 4,
                  width: 130,
                }}
                selectBoxStyle={{
                  minWidth: 130,
                }}
                value={formValue.company_name}
              ></SelectBox>
              <SelectBox
                label="決済結果"
                placeholder="指定なし"
                style={{
                  marginLeft: 4,
                  width: 130,
                }}
                selectBoxStyle={{
                  minWidth: 130,
                }}
                value={
                  formValue.error_code ? (formValue.error_code === 'fail' ? '失敗' : '成功') : ''
                }
              ></SelectBox>
            </View>
          </View>
          <View style={styles.body}>
            <View style={styles.textCountGeneral}>
              <Text style={styles.textCountNumber}>1</Text>
              <Text style={styles.textCountNumber}>~</Text>
              <Text style={styles.textCountNumber}>{dataErr.length}</Text>
              <Text style={styles.textCountNumber}>/</Text>
              <Text style={styles.textCountNumber}>{dataErr.length}</Text>
              <Text style={styles.textCountDescription}>件</Text>
            </View>
            <BillingDetailCorporateTable dataSource={dataErr.slice(0, FIRST_PAGE_ROW_COUNT)} />
          </View>
        </FullPage>
        <PageNumber />
      </Page>
      {dataErr.length > FIRST_PAGE_ROW_COUNT &&
        _.chunk(dataErr.slice(FIRST_PAGE_ROW_COUNT, dataErr.length), PAGE_ROW_COUNT).map(
          (page, index) => (
            <Page
              key={index}
              size={pageSize}
              orientation={pageOrientation}
              style={[styles.page, styles.pagePadding]}
              wrap={false}
            >
              <FullPage>
                <BillingDetailCorporateTable dataSource={page} />
              </FullPage>
              <PageNumber />
            </Page>
          )
        )}
    </Document>
  );
};

export default PDFDetailCorporate;
