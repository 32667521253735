import { createAsyncThunk } from '@reduxjs/toolkit';

import { INDIVIDUAL_USER_MANAGERMENT, USERS } from 'configs';
import { browserLogger } from 'libs/logger';
import { services } from 'services';
import * as Types from 'types';

export const getIndividualUserManagement = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Individual/thunk/getIndividualUserManagement', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(INDIVIDUAL_USER_MANAGERMENT.name, req);
    browserLogger.info(
      'Individual/thunk/getIndividualUserManagement',
      INDIVIDUAL_USER_MANAGERMENT.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectIndividual = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Individual/thunk/getSelectIndividual', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(INDIVIDUAL_USER_MANAGERMENT.name, req);
    browserLogger.info(
      'Individual/thunk/getSelectIndividual',
      INDIVIDUAL_USER_MANAGERMENT.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getIndividualUserManagementExport = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Individual/thunk/getIndividualUserManagementExport', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(INDIVIDUAL_USER_MANAGERMENT.name, req);
    browserLogger.info(
      'Individual/thunk/getIndividualUserManagementExport',
      INDIVIDUAL_USER_MANAGERMENT.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateIndividualUser = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.IndividualUserManagement>,
  Types.ThunkAPI<Types.requestError>
>('Individual/thunk/updateIndividualUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.name, req);
    browserLogger.info('Individual/thunk/updateIndividualUser', USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
