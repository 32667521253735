import React, { FC, useContext, useEffect, useMemo } from 'react';
import { Page } from 'react-pdf';

import { setCurrentPage, setZoomLevel } from '../../state/actions';
import { IStyledProps } from '../../../../models';
import { PDFContext } from '../../state';
import styled from 'styled-components';

interface Props {
  pageNum?: number;
  index?: number;
}

const PDFSinglePage: FC<Props> = ({ pageNum, index }) => {
  const {
    state: { mainState, paginated, zoomLevel, numPages, currentPage },
    dispatch,
  } = useContext(PDFContext);

  const rendererRect = mainState?.rendererRect || null;

  const _pageNum = pageNum || currentPage;

  useEffect(() => {
    dispatch(setZoomLevel(zoomLevel));
  }, [dispatch]);

  useEffect(() => {
    const elements = document.querySelectorAll('.page-pdf');
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(() => {
          if (entries[0].isIntersecting) {
            dispatch(setCurrentPage(Number(entries[0]?.target?.getAttribute('id')) + 1 || 0));
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    (elements || []).forEach((e) => {
      observer.observe(e);
    });
  }, [dispatch]);

  const page = useMemo(() => {
    return (
      <Page
        _className="wrap-pdf"
        pageNumber={_pageNum || currentPage}
        scale={zoomLevel}
        height={(rendererRect?.height || 100) - 100}
        width={(rendererRect?.width || 100) - 100}
        loading="Loding"
        className="page"
        renderMode="svg"
      />
    );
  }, [zoomLevel]);

  return (
    <PageWrapper id="pdf-page-wrapper" last={_pageNum >= numPages}>
      {!paginated && (
        <PageTag id="pdf-page-info">
          Page {_pageNum}/{numPages}
        </PageTag>
      )}
      <div id={index?.toString()} className="page-pdf">
        {page}
      </div>
    </PageWrapper>
  );
};

export default PDFSinglePage;

interface PageWrapperProps {
  last?: boolean;
}

const PageWrapper = styled.div<PageWrapperProps>`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  .page {
  }
  .page-pdf {
    width: fit-content;
    height: fit-content;
  }
  . wrap-pdf {
    display: flex;
    justify-content: center;
  }
`;

const PageTag = styled.div`
  padding: 0 0 10px 10px;
  color: ${(props: IStyledProps) => props.theme.textTertiary};
  font-size: 14px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;
