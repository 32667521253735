import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { unionBy } from 'lodash';

import * as Types from 'types';
import {
  getDataAdminAffiliationLevel,
  createAdminAffiliationLevel,
  deleteAdminAffiliationLevel,
  updateAdminAffiliationLevel,
  getAffiliationAssignRole,
} from './thunk';

export type InitialState = {
  affiliationAssignRoles: Array<Types.AffiliationAssignRole.ResponseType>;
  dataAdminAffiliationFiled: Array<Types.AffiliationLevel.ResponseType>;
  dataAdminAffiliation: Array<Types.AffiliationLevel.ResponseType>;
  loading: boolean;
  total: number;
};

const initialState: InitialState = {
  dataAdminAffiliationFiled: [],
  affiliationAssignRoles: [],
  dataAdminAffiliation: [],
  loading: false,
  total: 0,
};

export const affiliationMasterSlice = createSlice({
  name: 'affiliationMasterSlice-page',
  initialState,
  reducers: {
    setDataAdminAffiliationFiled: (
      state,
      action: PayloadAction<{
        items: Array<Types.AffiliationLevel.ResponseType>;
      }>
    ) => {
      state.dataAdminAffiliationFiled = action.payload.items;
    },
  },
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(createAdminAffiliationLevel.pending, startLoading)
      .addCase(updateAdminAffiliationLevel.pending, startLoading)
      .addCase(deleteAdminAffiliationLevel.pending, startLoading);

    builder.addCase(getDataAdminAffiliationLevel.fulfilled, (state, action) => {
      state.dataAdminAffiliation = unionBy(action.payload.items, 'affiliation_id');
      state.dataAdminAffiliationFiled = unionBy(action.payload.items, 'affiliation_id');
    });

    builder.addCase(getAffiliationAssignRole.fulfilled, (state, action) => {
      state.affiliationAssignRoles = action.payload.items;
    });

    builder
      .addCase(createAdminAffiliationLevel.fulfilled, stopLoading)
      .addCase(updateAdminAffiliationLevel.fulfilled, stopLoading)
      .addCase(deleteAdminAffiliationLevel.fulfilled, stopLoading);

    builder
      .addCase(createAdminAffiliationLevel.rejected, stopLoading)
      .addCase(updateAdminAffiliationLevel.rejected, stopLoading)
      .addCase(deleteAdminAffiliationLevel.rejected, stopLoading);
  },
});

export const { setDataAdminAffiliationFiled } = affiliationMasterSlice.actions;

export default affiliationMasterSlice.reducer;
