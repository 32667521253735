import styled from 'styled-components';

interface Props {}

export const Wrapper = styled.div<Props>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .rst__nodeContent {
    width: 100%;
  }
`;
