import { CopyOutlined } from '@ant-design/icons';

import useCopyCurriculum, { Props, ReceivedProps } from './hook';
import { Modal } from 'components';
import Styled from './styles';

const CopyCurriculumLayout = (props: Props) => {
  const { open, handleClose, handleSubmit, node } = props;

  return (
    <Modal
      width={720}
      title="カリキュラム コピー追加"
      open={open}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleClose,
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        background: '#f9f8f8',
      }}
      footerStyle={{
        background: '#f9f8f8',
      }}
    >
      <Styled>
        <div className="wrap-sub-title">
          <CopyOutlined className="icon" />
          <span className="sub-title">　カリキュラムのコピー追加を実行します。</span>
        </div>
        <div className="wrap-name">
          <p className="label">コピー元：</p>
          <p className="name">{node?.node?.name}</p>
        </div>
      </Styled>
    </Modal>
  );
};

const CopyCurriculmModal = (props: ReceivedProps) => {
  return <CopyCurriculumLayout {...useCopyCurriculum(props)} />;
};

export default CopyCurriculmModal;
