import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { publicManagerSelector } from 'pages/PublicManagement/selectors';
import { informationSchema } from 'libs/validations';
import { useAppDispatch, usePermission } from 'hooks';
import * as Types from 'types';
import {
  getOfficialCurriculumMaster,
  getProviderInformation,
  updateProviderInformation,
} from 'pages/PublicManagement/thunk';

export interface ReceivedProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useInformation = (props: ReceivedProps) => {
  const { setIsOpen, isOpen } = props;

  const [selected, setSelected] = useState<Types.OfficialCurriculumMaster.ResponseType>();
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);

  const { providerInformation, officialCurriculumMaster } = useSelector(publicManagerSelector);

  const { permissionNumber } = usePermission();

  const formik = useFormik({
    initialValues: {
      creator: providerInformation?.creator || '',
      basic_info: providerInformation?.basic_info || '',
    },
    enableReinitialize: true,
    validationSchema: informationSchema,
    onSubmit: () => {
      handleSubmit();
    },
  });

  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleSubmit = useCallback(() => {
    if (providerInformation?.i_id) {
      dispatch(
        updateProviderInformation({
          id: providerInformation?.i_id,
          data: {
            item: {
              creator: formik.values.creator,
              basic_info: formik.values.basic_info,
            },
            is_force_update: true,
          },
        })
      );
      dispatch(
        getProviderInformation({
          conditions: [
            {
              id: 'company_id',
              search_value: ['000000001'],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    }

    handleClose();
  }, [handleClose, dispatch, providerInformation?.i_id, formik]);

  useEffect(() => {
    if (!isOpen) return;

    Promise.all([
      dispatch(
        getProviderInformation({
          conditions: [
            {
              id: 'company_id',
              search_value: ['000000001'],
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getOfficialCurriculumMaster({
          conditions: [
            {
              id: 'provider_id',
              search_value: ['000000001'],
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
    ]);
  }, [isOpen, dispatch]);

  useEffect(() => {
    if (!isOpen) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return {
    ...props,
    handleSubmit,
    handleClose,
    formik,
    providerInformation,
    officialCurriculumMaster,
    setIsOpenEdit,
    isOpenEdit,
    isOpenDetail,
    setIsOpenDetail,
    setSelected,
    selected,
    permissionNumber,
  };
};

export type Props = ReturnType<typeof useInformation>;

export default useInformation;
