import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  ADMIN_DASHBORD_SALE_INFORMATION,
  AGGREGATION_CHURN_RATE,
  COMPANIES_PARTNER,
  DISPLAY_ID_KEY,
  NUMBER_OF_SKILL_CHECKS_PERFORMED,
  NUMBER_OF_SKILL_CHECKS_PERFORMED_MONTH,
  OFFICIAL_CURRICULUM_HIERARCHY,
  OFFICIAL_CURRICULUM_USER_AGGREGATION,
  OFFICIAL_CURRICULUM_USERS,
  SALES_INFORMATION,
  SALES_TRENDS,
  SKILL_CHECK_TRANS2,
  USAGE_LIST,
  USER_PARTNER,
  USER_REGISTRATION_HISTORY,
  USERS,
} from 'configs';
import { browserLogger } from 'libs/logger';

export const getDataNumberOfCorporateUsers = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumberOfCorporateUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.name, req);
    browserLogger.info('Dashboard/thunk/getDataNumberOfCorporateUsers', USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumberOfIndividualUsers = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumberOfIndividualUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.name, req);
    browserLogger.info('Dashboard/thunk/getDataNumberOfIndividualUsers', USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumberOfPartnerCompanies = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumberOfPartnerCompanies', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.searchPartner(COMPANIES_PARTNER.name, req);
    browserLogger.info(
      'Dashboard/thunk/getDataNumberOfPartnerCompanies',
      COMPANIES_PARTNER.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUserPartner = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataUserPartner', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.searchPartner(USER_PARTNER.name, req);
    browserLogger.info('Dashboard/thunk/getDataUserPartner', USER_PARTNER.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataOfficialCurriculum = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataOfficialCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.searchPartner(OFFICIAL_CURRICULUM_HIERARCHY.name, req);
    browserLogger.info(
      'Dashboard/thunk/getDataOfficialCurriculum',
      OFFICIAL_CURRICULUM_HIERARCHY.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAggregationChurnRate = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataAggregationChurnRate', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(AGGREGATION_CHURN_RATE.name, {
      ...req,
      conditions: [
        {
          id: 'aggregate_user_type',
          search_value: [DISPLAY_ID_KEY.aggregate_user_type.name],
        },
      ],
      sort_fields: [{ id: 'target_month', order: 'asc' }],
    });
    browserLogger.info(
      'Dashboard/thunk/getDataAggregationChurnRate',
      AGGREGATION_CHURN_RATE.name,
      data
    );
    browserLogger.info(
      'Dashboard/thunk/getDataAggregationChurnRate getDataAggregationChurnRate',
      DISPLAY_ID_KEY.aggregate_user_type.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUserRegistrationHistory = createAsyncThunk<
  Types.GetItemResponseTypeNew<Types.UserRegistrationHistory.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataUserRegistrationHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.UserRegistrationHistory.ResponseType>(
      USER_REGISTRATION_HISTORY.name,
      req
    );
    browserLogger.info(
      'Dashboard/thunk/getDataUserRegistrationHistory',
      USER_REGISTRATION_HISTORY.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAdminDashbordSalesInformation = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataAdminDashbordSalesInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(ADMIN_DASHBORD_SALE_INFORMATION.name, req);
    browserLogger.info(
      'Dashboard/thunk/getDataAdminDashbordSalesInformation',
      ADMIN_DASHBORD_SALE_INFORMATION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheckTrans = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataSkillCheckTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(SKILL_CHECK_TRANS2.name, req);
    browserLogger.info('Dashboard/thunk/getDataSkillCheckTrans', SKILL_CHECK_TRANS2.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumOfSkillChecksPerformed = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumOfSkillChecksPerformed', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(NUMBER_OF_SKILL_CHECKS_PERFORMED.name, req);
    browserLogger.info(
      'Dashboard/thunk/getDataNumOfSkillChecksPerformed',
      NUMBER_OF_SKILL_CHECKS_PERFORMED.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getNumberOfInterviewsConducted = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getNumberOfInterviewsConducted', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(NUMBER_OF_SKILL_CHECKS_PERFORMED.name, req);
    browserLogger.info(
      'Dashboard/thunk/getNumberOfInterviewsConducted',
      NUMBER_OF_SKILL_CHECKS_PERFORMED.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumOfSkillChecksPerformedMonth = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumOfSkillChecksPerformedMonth', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(NUMBER_OF_SKILL_CHECKS_PERFORMED_MONTH.name, req);
    browserLogger.info(
      'Dashboard/thunk/getDataNumOfSkillChecksPerformedMonth',
      NUMBER_OF_SKILL_CHECKS_PERFORMED_MONTH.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumOfficialCurriculumUsers = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumOfficialCurriculumUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICIAL_CURRICULUM_USERS.name, req);
    browserLogger.info(
      'Dashboard/thunk/getDataNumOfficialCurriculumUsers',
      OFFICIAL_CURRICULUM_USERS.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumOfficialCurriculumUsersActive = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumOfficialCurriculumUsersActive', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICIAL_CURRICULUM_USERS.name, req);
    browserLogger.info(
      'Dashboard/thunk/getDataNumOfficialCurriculumUsersActive',
      OFFICIAL_CURRICULUM_USERS.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumOfNewUsersMonth = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumOfNewUsersMonth', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICIAL_CURRICULUM_USERS.name, req);
    browserLogger.info(
      'Dashboard/thunk/getDataNumOfNewUsersMonth',
      OFFICIAL_CURRICULUM_USERS.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumOfficialCurriculumCountOfStops = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataNumOfficialCurriculumCountOfStops', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(OFFICIAL_CURRICULUM_USER_AGGREGATION.name, req);
    browserLogger.info(
      'Dashboard/thunk/getDataNumOfficialCurriculumCountOfStops',
      OFFICIAL_CURRICULUM_USER_AGGREGATION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataChangesInUsageOfOfficialCurriculum = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataChangesInUsageOfOfficialCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(OFFICIAL_CURRICULUM_USER_AGGREGATION.name, req);
    browserLogger.info(
      'Dashboard/thunk/getDataChangesInUsageOfOfficialCurriculum',
      OFFICIAL_CURRICULUM_USER_AGGREGATION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataChangesInUsageOfOfficialCurriculumChurnRate = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'Dashboard/thunk/getDataChangesInUsageOfOfficialCurriculumChurnRate',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter(AGGREGATION_CHURN_RATE.name, req);
      browserLogger.info(
        'Dashboard/thunk/getDataChangesInUsageOfOfficialCurriculumChurnRate',
        AGGREGATION_CHURN_RATE.name,
        data
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataAggregationChurnRateExport = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataAggregationChurnRateExport', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(AGGREGATION_CHURN_RATE.name, req);
    browserLogger.info(
      'Dashboard/thunk/getDataAggregationChurnRateExport',
      AGGREGATION_CHURN_RATE.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSalesTrends = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataSalesTrends', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(SALES_TRENDS.name, {
      ...req,
      sort_fields: [{ id: 'target_month', order: 'asc' }],
    });
    browserLogger.info('Dashboard/thunk/getDataSalesTrends', SALES_TRENDS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSalesInformation = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataSalesInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(SALES_INFORMATION.name, {
      ...req,
      sort_fields: [{ id: 'target_month', order: 'asc' }],
    });
    browserLogger.info('Dashboard/thunk/getDataSalesInformation', SALES_INFORMATION.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUsageList = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getDataUsageList', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(USAGE_LIST.name, req);
    browserLogger.info('Dashboard/thunk/getDataUsageList', USAGE_LIST.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserCount = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Dashboard/thunk/getUserCount', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.name, req);
    browserLogger.info('Dashboard/thunk/getUserCount', USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
