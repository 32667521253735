import React from 'react';

import { TickComplete } from 'assets';
import { Modal } from 'components';
import Styled from './styles';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: () => Promise<void> | void;
  fetchData?: () => void;
  setVisibleCreated?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Published: React.FC<Props> = ({
  visible,
  setVisible,
  onSubmit,
  setVisibleEdit,
  fetchData,
  setVisibleCreated,
}) => {
  const handleToggleModal = () => {
    setVisible(false);
    setVisibleCreated && setVisibleCreated(false);
  };
  const handleSubmit = async () => {
    onSubmit && (await onSubmit());
    handleToggleModal();
    setVisibleEdit && setVisibleEdit(false);
    fetchData && fetchData();
  };

  return (
    <Modal
      width={420}
      visible={visible}
      onCancel={handleToggleModal}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
      footerStyle={{
        backgroundColor: '#ffffff',
      }}
      bodyStyle={{
        background: '#ffffff',
        padding: 0,
        paddingTop: 32,
        borderRadius: 10,
      }}
    >
      <Styled>
        <div className="title">
          <img src={TickComplete} alt="complete" />
          <span className="text">リリースノートを公開しました。</span>
        </div>
        <p className="description">公開ステータスは公開中として表示されます。</p>
      </Styled>
    </Modal>
  );
};

export default Published;
