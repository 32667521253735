import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Tabs } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import OfficialCurriculumInformation from './OfficialCurriculumInformation';
import { settingSelector } from 'containers/AppSettings/selectors';
import SkillCheckInformation from './SkillCheckInformation';
import UserInformation from './UserInformation';
import SaleInformation from './SaleInformation';
import ReportStyled from './styles';
import { Header } from 'components';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { setListMonths, setValueCurriculumn } from './slice';
import { OfficialCurriculumnAggregationData } from 'types';
import { getDataOfficialCurriculumUserAggregation } from './thunk';
import { useAppDispatch, usePermission } from 'hooks';
import { loadingRef } from 'components/Loading';
import {
  REPORT_PERMISSION,
  ROUTER_WITH_SCREEN_PERMISSIONS,
} from 'constant/screenPermission.constant';
import { exportPDFFitOnePage } from 'libs/utils/exportPDF';

const { TabPane } = Tabs;

const Report: React.FC = () => {
  const [valueTab, setValueTab] = useState('0');
  const [openConfirmExport, setOpenConfirmExport] = useState(false);
  const { headerTitle } = useSelector(settingSelector);
  const dispatch = useAppDispatch();
  let ref = useRef<HTMLDivElement>(null);
  const onChange = (key: string) => {
    setValueTab(key);
  };
  const [listCompany, setListCompany] = useState<Array<OfficialCurriculumnAggregationData>>([]);
  const { loading } = useSelector(settingSelector);
  const { roleCode } = usePermission();

  useEffect(() => {}, [valueTab]);

  const fetching = async () => {
    const payload = {
      conditions: [],
      use_display_id: true,
      return_number_value: true,
      page: 1,
      per_page: 0,
    };
    dispatch(startLoading());
    Promise.all([dispatch(getDataOfficialCurriculumUserAggregation(payload))]).then((r: any) => {
      if (r.length) {
        dispatch(
          setValueCurriculumn({
            items: [],
            totalItems: 0,
          })
        );
        dispatch(setListMonths([]));
        setListCompany(
          r[0].payload?.items?.filter((i: OfficialCurriculumnAggregationData) => i.company_name) ??
            []
        );
      }
    });
    dispatch(stopLoading());
  };

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  useEffect(() => {
    fetching();
  }, []);

  const onExport = () => {
    setOpenConfirmExport(true);
  };

  return (
    <ReportStyled>
      <div ref={ref} id="report_id">
        <Header exportPDF={openConfirmExport} title={headerTitle} />
        <div className="wrap-tab">
          <Tabs className="tab-container" activeKey={valueTab} onChange={onChange}>
            {roleCode &&
              Number(
                roleCode[REPORT_PERMISSION[0] as keyof typeof ROUTER_WITH_SCREEN_PERMISSIONS]
              ) && <TabPane tab="売上情報" key="0" />}
            {roleCode &&
              Number(
                roleCode[REPORT_PERMISSION[1] as keyof typeof ROUTER_WITH_SCREEN_PERMISSIONS]
              ) && <TabPane tab="ユーザー情報" key="1" />}
            {roleCode &&
              Number(
                roleCode[REPORT_PERMISSION[2] as keyof typeof ROUTER_WITH_SCREEN_PERMISSIONS]
              ) && <TabPane tab="OFFICIALカリキュラム情報" key="2" />}
            {roleCode &&
              Number(
                roleCode[REPORT_PERMISSION[3] as keyof typeof ROUTER_WITH_SCREEN_PERMISSIONS]
              ) && <TabPane tab="スキルチェック実施情報" key="3" />}
          </Tabs>
          {!openConfirmExport && (
            <div className="group-btn">
              <Button
                className="btn btn-active"
                onClick={onExport}
                icon={<CloudDownloadOutlined className="icon" />}
              >
                エクスポート
              </Button>
            </div>
          )}
        </div>
        {valueTab === '0' ? (
          <SaleInformation
            openConfirmExport={openConfirmExport}
            setOpenConfirmExport={setOpenConfirmExport}
          />
        ) : valueTab === '1' ? (
          <UserInformation
            openConfirmExport={openConfirmExport}
            setOpenConfirmExport={setOpenConfirmExport}
          />
        ) : valueTab === '2' ? (
          <OfficialCurriculumInformation
            openConfirmExport={openConfirmExport}
            setOpenConfirmExport={setOpenConfirmExport}
            listCompany={listCompany}
          />
        ) : (
          <SkillCheckInformation
            openConfirmExport={openConfirmExport}
            setOpenConfirmExport={setOpenConfirmExport}
          />
        )}
      </div>
    </ReportStyled>
  );
};

export default Report;
