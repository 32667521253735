import React, { useEffect, useMemo, useState } from 'react';

import { CloudUploadOutlined, DeleteOutlined, ZoomInOutlined } from '@ant-design/icons';
import { isEmpty, maxBy, padEnd, split } from 'lodash';
import { UploadFile } from 'antd/lib/upload/interface';
import { FormikProvider, useFormik } from 'formik';
import { MessageFormatElement } from 'react-intl';
import { Button, Upload, Image } from 'antd';
import { useSelector } from 'react-redux';
import { Form } from 'formik-antd';

import { deleteFileInR2, uploadFileToR2 } from 'services/r2Service';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { createEditCurriculumSchema } from 'libs/validations';
import { setFilterByCurriculum, setFilterByStatus, setFilterChanged } from 'pages/Curriculum/slice';
import { authSelector } from 'containers/Auth/selectors';
import { curriculumSelector } from '../../selectors';
import { Input, Modal, TextArea } from 'components';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  createLevelCurriculum,
  getDataCurriculum,
  createCurriculum,
  updateCurriculum,
  deleteFileAttach,
  createFileAttach,
} from '../../thunk';

interface Props {
  setVisibleSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  textSubmit?: string | MessageFormatElement[] | undefined;
  fileCurriculum?: UploadFile<File>;
  data?: Types.CurriculumItemType;
  selectedCurriculum?: string;
  subTitle?: React.ReactNode;
  title?: React.ReactNode;
  type: 'create' | 'edit';
  maxSortOrder?: number;
  description?: string;
  visible: boolean;
  name?: string;
  id?: string;
}

const CreateEditCurriculum: React.FC<Props> = ({
  setVisibleSuccess,
  fileCurriculum,
  maxSortOrder,
  description,
  setVisible,
  textSubmit,
  subTitle,
  visible,
  title,
  type,
  name,
  data,
  id,
}) => {
  const dispatch = useAppDispatch();

  const { dataCurriculumTree, conditions } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);
  const { loading } = useSelector(settingSelector);
  const [isShowImage, setIsShowImage] = useState<boolean>(false);
  const [isFileChange, setIsFileChange] = useState<boolean>(false);
  const [nameChanged, setNameChanged] = useState<boolean>(false);
  const [descriptionChanged, setDescriptionChanged] = useState<boolean>(false);
  const maxCurriculumCode = useMemo(() => {
    const itemMaxCode = maxBy(dataCurriculumTree, (e) => Number(split(e.code, 'A').join(''))) ?? '';

    if (itemMaxCode) {
      const number = Number(split(itemMaxCode.code, 'A').join(''));
      const subString = Array.from({
        length: 8 - (number + 1).toString().length,
      })
        .map(() => '0')
        .concat((number + 1).toString())
        .join('');

      return padEnd('A', 9, subString.toString());
    }
    return 'A00000001';
  }, [dataCurriculumTree]);

  const initialValues = {
    name: name || '',
    description: description || '',
    file: fileCurriculum,
  };

  const formik = useFormik<Types.AddCurriculumFormik>({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: createEditCurriculumSchema,
    onSubmit: async (values) => {
      if (!values.name || !userInfo) return;
      let fileID = '';

      dispatch(startLoading());

      if (!isEmpty(values.file) && values.file.uid !== fileCurriculum?.uid) {
        if (data?.fileID) {
          await deleteFileInR2(data.fileID);
          dispatch(
            deleteFileAttach({
              conditions: [
                {
                  id: 'fileID',
                  search_value: [data.fileID],
                },
              ],
              use_display_id: true,
            })
          );
        }
        const uploadToMinIo = await uploadFileToR2(values.file);
        await dispatch(
          createFileAttach({
            item: {
              fileID: uploadToMinIo,
              filename: values.file?.name,
              file_location: '1',
              file_extension: values.file?.type,
              file_size: `${values.file?.size}`,
              company_id: userInfo?.company_id,
              createdat: new Date(),
              createdby: userInfo?.login_id,
            },
            access_key_updates: {
              roles_to_publish: ['MEMBER'],
            },
          })
        );
        fileID = uploadToMinIo;
      }
      if (isEmpty(values.file)) {
        if (data?.fileID) {
          await deleteFileInR2(data.fileID);
          dispatch(
            deleteFileAttach({
              conditions: [
                {
                  id: 'fileID',
                  search_value: [data.fileID],
                },
              ],
              use_display_id: true,
            })
          );
        }
      }
      if (type === 'create') {
        const resultAction = await dispatch(
          createCurriculum({
            item: {
              provider_id: userInfo?.company_id,
              name: values.name,
              official_curriculum_code: maxCurriculumCode,
              description: values.description,
              publish: 1,
              required_curriculum: 0,
              sort_order: maxSortOrder,
              fileID: fileID,
              createdby: userInfo.login_id,
              archive_flag: 0,
              created_at: new Date().toDateString(),
            },
            access_key_updates: {
              roles_to_publish: ['MEMBER'],
            },
            return_item_result: true,
            return_display_id: true,
            realtime_auto_link: true,
          })
        );
        if (
          createCurriculum.fulfilled.match(resultAction) &&
          resultAction.payload.item.official_curriculum_code
        ) {
          const resultLevel1 = await dispatch(
            createLevelCurriculum({
              level: 1,
              item: {
                provider_id: userInfo?.company_id,
                name: '',
                sort_order: 1,
                official_curriculum_code: resultAction.payload.item.official_curriculum_code,
              },
              access_key_updates: {
                roles_to_publish: ['MEMBER'],
              },
              return_item_result: true,
              return_display_id: true,
              realtime_auto_link: true,
            })
          );
          if (
            createLevelCurriculum.fulfilled.match(resultLevel1) &&
            resultLevel1.payload.item.official_curriculum_code
          ) {
            const resultLevel2 = await dispatch(
              createLevelCurriculum({
                level: 2,
                item: {
                  provider_id: userInfo?.company_id,
                  level1_code: resultLevel1.payload.item.code,
                  name: '',
                  sort_order: 1,
                  official_curriculum_code: resultAction.payload.item.official_curriculum_code,
                },
                access_key_updates: {
                  roles_to_publish: ['MEMBER'],
                },
                return_item_result: true,
                return_display_id: true,
                realtime_auto_link: true,
              })
            );
            if (
              createLevelCurriculum.fulfilled.match(resultLevel2) &&
              resultLevel2.payload.item.official_curriculum_code
            ) {
              const resultLevel3 = await dispatch(
                createLevelCurriculum({
                  level: 3,
                  item: {
                    provider_id: userInfo?.company_id,
                    level2_code: resultLevel2.payload.item.code,
                    name: '',
                    sort_order: 1,
                    official_curriculum_code: resultAction.payload.item.official_curriculum_code,
                  },
                  access_key_updates: {
                    roles_to_publish: ['MEMBER'],
                  },
                  return_item_result: true,
                  return_display_id: true,
                  realtime_auto_link: true,
                })
              );
              if (
                createLevelCurriculum.fulfilled.match(resultLevel3) &&
                resultLevel3.payload.item.code
              ) {
                const resultLevel4 = await dispatch(
                  createLevelCurriculum({
                    level: 4,
                    item: {
                      provider_id: userInfo?.company_id,
                      level3_code: resultLevel3.payload.item.code,
                      name: '',
                      sort_order: 1,
                      official_curriculum_code: resultAction.payload.item.official_curriculum_code,
                    },
                    access_key_updates: {
                      roles_to_publish: ['MEMBER'],
                    },
                    return_item_result: true,
                    return_display_id: true,
                    realtime_auto_link: true,
                  })
                );
                if (createLevelCurriculum.fulfilled.match(resultLevel4)) {
                  setVisible(false);
                  // setVisibleSuccess(true);
                }
              }
            }
          }
        }
      } else {
        if (id) {
          await dispatch(
            updateCurriculum({
              id,
              data: {
                item: {
                  name: values.name,
                  description: values.description,
                  ...(fileID ? { fileID: fileID } : !values.file ? { fileID: '' } : null),
                  updated_at: new Date().toDateString(),
                  updatedat: new Date(),
                },
                return_item_result: true,
                use_display_id: true,
                is_force_update: true,
              },
            })
          );
        }
      }
      await Promise.all([
        dispatch(
          setFilterChanged({
            items: {
              type: 'curriculum_name',
              value: values.name,
            },
          })
        ),
        dispatch(
          getDataCurriculum({
            conditions: [
              {
                id: 'provider_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      await dispatch(
        setFilterByStatus({
          status: conditions?.status,
        })
      );
      dispatch(
        setFilterByCurriculum({
          name: conditions?.name,
        })
      );
      if (type === 'create') {
        setVisibleSuccess(true);
      }
      dispatch(stopLoading());
      onClose();
    },
  });

  useEffect(() => {
    const initFile = initialValues.file;
    const initName = initialValues.name;
    const initDescription = initialValues.description;
    if (initFile) {
      if (formik.values.file) {
        if (
          initFile?.name !== formik.values.file.name &&
          initFile?.size !== formik.values.file.size
        ) {
          setIsFileChange(true);
        } else {
          setIsFileChange(false);
        }
      } else {
        setIsFileChange(true);
      }
    } else {
      if (formik.values.file) {
        setIsFileChange(true);
      } else {
        setIsFileChange(false);
      }
    }
    if (initName !== formik.values.name) {
      setNameChanged(true);
    } else {
      setNameChanged(false);
    }

    if (initDescription !== formik.values.description) {
      setDescriptionChanged(true);
    } else {
      setDescriptionChanged(false);
    }
  }, [
    formik.values.file,
    initialValues.file,
    formik.values.name,
    initialValues.name,
    formik.values.description,
    initialValues.description,
  ]);

  const onClose = () => {
    setVisible(false);
    formik.resetForm();
  };

  return (
    <Modal
      title={title}
      keyboard={!formik.dirty}
      open={visible}
      width={720}
      okButton={{
        text: textSubmit,
        onClick: formik.handleSubmit,
        disabled:
          !formik.isValid || (!loading && !nameChanged && !descriptionChanged && !isFileChange),
      }}
      cancelButton={{
        text: '閉じる',
        onClick: !formik.isSubmitting ? onClose : undefined,
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled hasImage={!!formik.values.file}>
        <p className="sub-title">{subTitle}</p>
        <FormikProvider value={formik}>
          <Form
            layout="vertical"
            labelCol={{
              flex: '22%',
            }}
            colon={false}
            className="form"
          >
            <Form.Item
              name="name"
              label={
                <span className="text-label">
                  カリキュラム名
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="name"
                className="input"
                type="text"
                placeholder="最大30文字"
                showCount
                maxLength={30}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label={<span className="text-label"> 説明</span>}
              className="form-input"
            >
              <TextArea
                name="description"
                className="input-TextArea"
                rows={4}
                showCount
                maxLength={100}
                placeholder="最大100文字"
              />
            </Form.Item>
            <Form.Item name="file" label="表紙画像">
              <div className="form-upload">
                <Upload
                  beforeUpload={() => false}
                  onChange={(e) => {
                    formik.setFieldValue('file', e.file);
                  }}
                  showUploadList={false}
                  accept=".jpeg, .jpg, .png"
                  className="upload"
                  name="file"
                >
                  <div className="wrap-upload">
                    {!formik.values.file ? (
                      <div className="no-image">
                        <CloudUploadOutlined className="icon" />
                        <p>
                          アップロードするファイルをここにドロップ <br /> または
                        </p>
                      </div>
                    ) : (
                      <div className="image-preview">
                        <p>{formik.values?.file?.name}</p>
                        <Image
                          onClick={(e) => e.stopPropagation()}
                          src={URL.createObjectURL(
                            new Blob([
                              (formik.values.file.originFileObj
                                ? formik.values.file.originFileObj
                                : formik.values.file) as unknown as Blob,
                            ])
                          )}
                          preview={{
                            visible: isShowImage,
                            src: URL.createObjectURL(
                              new Blob([
                                (formik.values.file.originFileObj ?? formik.values.file) as Blob,
                              ])
                            ),
                            onVisibleChange: (value) => {
                              setIsShowImage(value);
                            },
                            mask: (
                              <ZoomInOutlined
                                className="preview-icon"
                                onClick={() => setIsShowImage(!isShowImage)}
                              />
                            ),
                          }}
                        />
                      </div>
                    )}

                    <div className="flex">
                      <Button className="btn">ファイルを選択</Button>

                      {formik.values.file && (
                        <Button
                          className="btn btn-delete"
                          onClick={(e) => {
                            e.stopPropagation();

                            formik.setFieldValue('file', undefined);
                          }}
                        >
                          <DeleteOutlined
                            style={{
                              fontSize: 18,
                            }}
                          />
                          <span>ファイルを削除</span>
                        </Button>
                      )}
                    </div>
                  </div>
                </Upload>
                <span className="note">
                  ※画像は16:9でjpegまたはpngファイルがアップロードできます。
                  <br />
                  ※画像はユーザがOFFICIALカリキュラムを管理する際に表紙として表示されます。
                </span>
              </div>
            </Form.Item>
          </Form>
        </FormikProvider>
      </SectionStyled>
    </Modal>
  );
};

export default CreateEditCurriculum;
