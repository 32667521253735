import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Form } from 'formik-antd';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { createLevelCurriculum, getDataCurriculum, updateCurriculum } from '../../thunk';
import { curriculumSelector } from 'pages/Curriculum/selectors';
import { setFilterByCurriculum } from 'pages/Curriculum/slice';
import { authSelector } from 'containers/Auth/selectors';
import { Input, Modal } from 'components';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';

interface Props {
  node: Types.TreeItem<Types.CurriculumItemType>;
  maxSortOrder?: number;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleTooltip?: React.Dispatch<React.SetStateAction<boolean>>;
  rootNode: Types.TreeItem<Types.CurriculumItemType>;
}

const CreateLevelCurriculum: React.FC<Props> = ({
  node,
  maxSortOrder,
  visible,
  setVisible,
  setVisibleTooltip,
  rootNode,
}) => {
  const dispatch = useAppDispatch();

  const { conditions } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);

  let apiCallCount = maxSortOrder;
  const createCurriculumHandler = async (
    level: number,
    name: string,
    code: string | undefined
  ): Promise<boolean> => {
    const resultAction = await dispatch(
      createLevelCurriculum({
        level,
        item: {
          provider_id: userInfo?.company_id,
          name,
          sort_order: apiCallCount && apiCallCount++,
          ...(level - 1 === 0
            ? { official_curriculum_code: node.node?.code }
            : { [`level${level - 1}_code`]: code }),
        },
        return_item_result: true,
        return_display_id: true,
      })
    );

    if (level < 4 && createLevelCurriculum.fulfilled.match(resultAction)) {
      return createCurriculumHandler(level + 1, '', resultAction.payload.item.code);
    } else {
      return true;
    }
  };

  const formik = useFormik<Types.AddLowerHierarchyFormik>({
    initialValues: {
      curriculumName: '',
    },
    onSubmit: async (values, { resetForm }) => {
      if (!node || node.columnIndex === undefined || !rootNode.node?.i_id) return;
      dispatch(startLoading());

      const resultAction = await createCurriculumHandler(
        node.columnIndex + 1,
        values.curriculumName,
        node.node?.code
      );
      if (resultAction) {
        await dispatch(
          updateCurriculum({
            id: rootNode.node.i_id || rootNode.i_id!,
            data: {
              item: {
                updated_at: new Date().toDateString(),
                updatedat: new Date(),
              },
            },
          })
        );
        await dispatch(
          getDataCurriculum({
            conditions: [
              {
                id: 'provider_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        );
      }

      await dispatch(
        setFilterByCurriculum({
          name: conditions?.name,
        })
      );
      setVisible(false);
      setVisibleTooltip && setVisibleTooltip(false);
      dispatch(stopLoading());
      resetForm();
    },
  });

  return (
    <Modal
      title={`第${node.columnIndex! + 1}階層 新規作成`}
      width={720}
      visible={visible}
      okButton={{
        text: '新規作成',
        onClick: formik.handleSubmit,
        disabled: formik.isSubmitting,
      }}
      cancelButton={{
        text: '閉じる',
        onClick: () => setVisible(false),
      }}
      onCancel={() => setVisible(false)}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #cccccc',
      }}
    >
      <SectionStyled>
        <div className="content">
          <p className="sub-title">
            第{node.columnIndex! + 1}階層の新規作成が可能です。
            <br />第{node.columnIndex! + 1}階層名称を入力し、新規作成ボタンをクリックしてください。
          </p>
          <FormikProvider value={formik}>
            <Form layout="vertical" colon={false} className="form">
              <Form.Item
                name="curriculumName"
                label={<span className="text-label">第{node.columnIndex! + 1}階層名</span>}
                className="form-input"
              >
                <Input
                  name="curriculumName"
                  className="input"
                  type="text"
                  placeholder="最大30文字"
                  showCount
                  maxLength={30}
                />
              </Form.Item>
            </Form>
          </FormikProvider>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default CreateLevelCurriculum;
