import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  divider: {
    width: 40,
    height: 1,
    marginLeft: 11.7,
    marginBottom: 11.7,
    backgroundColor: '#DDDDDD',
  },
});
