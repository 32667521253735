import styled from 'styled-components';

export default styled.span`
  font-size: 13px;
  font-weight: 400;
  padding-top: 10px;
  .required {
    color: #f0330a;
    padding-left: 2px;
  }

  @media (max-width: 1440px) {
    font-size: 12px;
  }
`;
