import { createAsyncThunk } from '@reduxjs/toolkit';

import { services, servicesMock } from 'services';
import * as Types from 'types';
import {
  ADMIN_REPORT_SALE_INFORMATION,
  ADMIN_REPORT_SALE_INFORMATION_BREAKDOWN,
  ADMIN_REPORT_SALE_INFORMATION_BREAKDOWN_DETAILS,
  SELECT_USERS,
} from 'configs';
import { browserLogger } from 'libs/logger';

export const getDataReportSaleInformation = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getDataReportSaleInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(ADMIN_REPORT_SALE_INFORMATION.name, req);
    browserLogger.info(
      'report/thunk/getDataReportSaleInformation',
      ADMIN_REPORT_SALE_INFORMATION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataReportSaleInformationBreakdown = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getDataReportSaleInformationBreakdown', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(ADMIN_REPORT_SALE_INFORMATION_BREAKDOWN.name, req);
    browserLogger.info(
      'report/thunk/getDataReportSaleInformationBreakdown',
      ADMIN_REPORT_SALE_INFORMATION_BREAKDOWN.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataReportSaleInformationBreakdownDetail = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getDataReportSaleInformationBreakdownDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(
      ADMIN_REPORT_SALE_INFORMATION_BREAKDOWN_DETAILS.name,
      req
    );
    browserLogger.info(
      'report/thunk/getDataReportSaleInformationBreakdownDetail',
      ADMIN_REPORT_SALE_INFORMATION_BREAKDOWN_DETAILS.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.Selects.SelectUserResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('report/thunk/getSelectUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.Selects.SelectUserResponseType>(
      SELECT_USERS.name,
      req
    );
    browserLogger.info('report/thunk/getSelectUser', SELECT_USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
