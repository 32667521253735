import React, { useState } from 'react';
import { Spin } from 'antd';

import { Modal } from 'components';
import Styled from './styles';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => Promise<void> | void;
};

const TemporarilySave: React.FC<Props> = ({ visible, setVisible, onSubmit }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleToggle = () => {
    setVisible(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit();
    setLoading(false);
  };
  return (
    <Spin spinning={loading}>
      <Modal
        title="リリースノートを一時保存します"
        width={720}
        visible={visible}
        okButton={{
          text: '一時保存',
          onClick: handleSubmit,
        }}
        cancelButton={{
          text: 'キャンセル',
          onClick: handleToggle,
        }}
        headerStyle={{
          borderBottom: '1px solid #cccccc',
        }}
        bodyStyle={{
          paddingTop: '32px !important',
        }}
        footerStyle={{
          paddingTop: '4px !important',
        }}
      >
        <Styled>
          <div className="content">
            <span>リリースノートを編集し、一時保存しても一般のユーザーは</span>
            <br />
            <span>前回公開時の状態で閲覧することができます。</span>
          </div>
        </Styled>
      </Modal>
    </Spin>
  );
};

export default TemporarilySave;
