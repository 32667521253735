import styled from 'styled-components';

export default styled.span`
  .btn-active {
    background-color: #f6ac00;
    border: 1px solid #f6ac00;
    box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
    border-radius: 5px;
    color: #ffffff;
    .anticon {
      margin-right: 6px;
      font-size: 18px;
      height: 18px;
      transform: translateY(2px);
    }
  }

  .disabled {
    background: #cccccc !important;
    border-radius: 5px !important;
    color: #777777 !important;
  }
  .ant-btn[disabled] {
    background: #cccccc !important;
    border-radius: 5px !important;
    color: #ffffff;
    font-weight: 700 !important;
    .anticon {
      margin-right: 6px;
      font-size: 18px;
      height: 18px;
      transform: translateY(2px);
    }
  }

  .customizeIcon {
    span {
      line-height: 18px;
    }
  }
`;
