import { createAsyncThunk } from '@reduxjs/toolkit';

import { COMPANIES } from 'configs';
import { browserLogger } from 'libs/logger';
import { services } from 'services';
import * as Types from 'types';

export const getCompany = createAsyncThunk<
  Types.GetItemResponseType<Types.Companies.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('FreeTrialManagement/thunk/getCompany', async (req, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await services.search<Types.Companies.ResponseType>(COMPANIES.name, req);
    browserLogger.info('FreeTrialManagement/thunk/getCompany', COMPANIES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCompanyExportCSV = createAsyncThunk<
  Types.GetItemResponseType<Types.Companies.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('FreeTrialManagement/thunk/getCompanyExportCSV', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Companies.ResponseType>(COMPANIES.name, req);
    browserLogger.info('FreeTrialManagement/thunk/getCompanyExportCSV', COMPANIES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateMemoCompany = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Companies.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('FreeTrialManagement/thunk/updateMemoCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(COMPANIES.name, req);
    browserLogger.info('FreeTrialManagement/thunk/updateMemoCompany', COMPANIES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
