import { FormikProps } from 'formik';
import { useAppDispatch } from 'hooks';
import React, { useCallback, useState } from 'react';

import { CreateManagementUserMasterFormFormik } from 'types';
import { getEmployeeUser } from '../thunk';
import { useSelector } from 'react-redux';
import { authSelector } from 'containers/Auth/selectors';

export interface ReceivedProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMethod: React.Dispatch<React.SetStateAction<string | undefined>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const useCreateUser = (props: ReceivedProps) => {
  const { setOpen, setMethod } = props;

  const [confirmStatus, setConfirmStatus] = useState<boolean>(false);

  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const fetchEmployeeUser = useCallback(async () => {
    if (!userInfo) return;

    await dispatch(
      getEmployeeUser({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
          {
            id: 'position_company_code',
            search_value: [userInfo?.company_id],
          },
        ],
        sort_fields: [
          {
            id: 'employee_code',
            order: 'asc',
          },
        ],
        page: 1,
        per_page: 0,
        include_lookups: true,
        include_item_ref: true,
        omit_total_items: false,
      })
    );
  }, [userInfo, dispatch]);

  const handleCancle = useCallback(
    (formik?: FormikProps<CreateManagementUserMasterFormFormik>) => {
      formik && formik.resetForm();
      setOpen(false);
    },
    [setMethod, setOpen]
  );

  return {
    ...props,
    handleClose,
    confirmStatus,
    setConfirmStatus,
    handleCancle,
    fetchEmployeeUser,
  };
};

export type Props = ReturnType<typeof useCreateUser>;

export default useCreateUser;
