import styled from 'styled-components';

type Props = {
  collapseMenu: boolean;
};

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  min-width: 1440px;
  overflow-x: auto;
  .ant-layout {
    background: none;
    max-height: 100vh;
    overflow-y: auto;
  }
  .ant-layout-sider {
    position: relative;
    min-width: 0;
    position: sticky;
    transition: all 0.2s;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-y: auto;
  }
  .sider {
    .ant-menu-inline-collapsed .ant-menu-item-selected .anticon {
      color: #ffffff !important;
    }
    .ant-menu-inline-collapsed .ant-menu-submenu-selected .ant-menu-submenu-title .anticon {
      color: #ffffff !important;
    }
    .ant-menu-light .ant-menu-submenu-title:hover {
      color: #ffffff !important;
    }
    min-height: 100vh;
    background-color: #46433c;
    border-right: 1px solid #e5e5e5;
    font-family: unset;
    ::-webkit-scrollbar {
      display: none;
    }
    .wrap-logo {
      display: grid;
      justify-content: center;
      user-select: none;
      background: #ffffff;
      .item-logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .text {
          font-size: 10px;
          font-weight: 700;
          margin: 12px 0 19px 0;
          color: #f6ac00;
        }
        .image-logo {
          margin: 10px 0 0px 0;
          cursor: pointer;
          display: grid;
        }
        .image-logo-large {
          width: 100px;
          margin-top: 8px;
          transition: width 0.3s;
          cursor: pointer;
        }
      }
      .icon-text {
        font-size: 9px;
        text-align: center;
        margin: 14px 0 31px 0;
        line-height: 14px;
        font-weight: 800;
        color: rgba(246, 172, 0, 1);
      }
    }
    .site-layout-background {
      display: flex;
      justify-content: flex-end;
      padding: 10px 10px 0 10px;
      color: rgba(0, 0, 0, 0.85);
      background-color: #ffffff;
      color: #cccccc;
      .trigger {
        font-size: 20px;
      }
      .anticon-menu-unfold.trigger {
        display: block;
        margin: 0 auto;
      }
    }
    .menu {
      padding-top: 16px;
    }
    .ant-menu {
      font-size: 12px;
      border-right: unset;
      background-color: #46433c;
      .ant-menu-item {
        margin-bottom: 8px !important;
      }
      .ant-menu-item,
      .ant-menu-submenu {
        margin: 0;
        margin-bottom: 8px;
        color: #ffffff;
        font-weight: 400;
        .anticon,
        .ant-menu-submenu-arrow {
          color: rgba(255, 255, 255, 0.5);
        }
        .title-submenu {
          width: 100%;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg {
            width: 10px;
            height: 10px;
          }
        }
        .official-text {
          width: 80%;
          display: grid;
          line-height: 20px;
        }
        &:hover {
          font-weight: 700;
          .anticon {
            color: #ffffff;
          }
        }
        .ant-menu-item-icon {
          width: 15%;
          font-size: 18px !important;
        }
        .item-icon-bottom {
          margin-bottom: ${(props) => (!props.collapseMenu ? '7' : '0')}px;
          height: ${(props) => (props.collapseMenu ? '35px' : 'initial')};
          display: ${(props) => (props.collapseMenu ? 'inline-grid' : 'block')};
        }
        .icon-sidebar-home > svg {
          width: 19.07px;
          height: 18px;
        }
      }

      .ant-menu-item-active,
      .ant-menu-submenu-active {
        .ant-menu-submenu-title {
          color: #ffffff;
          font-weight: 700;
        }
        .anticon {
          color: #ffffff;
        }
        .ant-menu-submenu-arrow {
          color: #ffffff;
        }
      }
      .ant-menu-item {
        height: 35px !important;
      }
      .ant-menu-item:hover {
        .item-icon {
          color: #fff;
        }
      }
      .ant-menu-submenu-title {
        margin: 0 !important;
        height: 35px !important;
      }
      .ant-menu-item-selected {
        background-color: rgba(233, 224, 203, 0.15);
        font-weight: 700;
        height: 35px !important;
        .anticon,
        .ant-menu-submenu-arrow {
          color: #ffffff;
        }
        &::after {
          border-left: 4px solid #08a3a5;
          border-right: unset;
          left: 0;
        }
      }

      .ant-menu-submenu-selected {
        font-weight: 700;
        .ant-menu-submenu-title {
          .anticon,
          .ant-menu-submenu-arrow {
            color: #ffffff;
          }
          &:after {
            border-left: 4px solid #08a3a5;
            border-right: unset;
            left: 0;
          }
        }
      }

      .sub-menu-setting {
        .ant-menu-item-group-title {
          padding: 0 34px 0 24px;
          font-weight: 700;
          color: #ffffff;
          font-size: 12px;
          .anticon {
            font-size: 18px;
            vertical-align: middle;
            margin-top: -2px;
          }
        }
      }

      .collapse {
        .ant-menu-item-group-title {
          text-align: center;
          padding: 0;
        }
        .sub-menu {
          color: #aaaaaa;
        }
      }
    }
    .ant-menu-inline-collapsed {
      .ant-menu-item,
      .ant-menu-submenu {
        .anticon,
        .ant-menu-submenu-arrow {
          color: rgba(255, 255, 255, 0.5);
        }
        &:hover {
          .anticon {
            color: #ffffff;
          }
        }
      }

      .ant-menu-item-active,
      .ant-menu-submenu-active {
        .anticon {
          color: #424242;
        }
      }

      .ant-menu-item-selected {
        .anticon,
        .ant-menu-submenu-arrow {
          color: #424242;
        }
        &::after {
          transform: scaleY(1);
          opacity: 1;
        }
      }

      .ant-menu-submenu-selected {
        .ant-menu-submenu-title::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
            opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
          content: '';
        }
        .ant-menu-submenu-title {
          .anticon,
          .ant-menu-submenu-arrow {
            color: #424242;
          }
          &::after {
            transform: scaleY(1);
            opacity: 1;
          }
        }
      }
    }
  }
`;

export const StyleMenuTooltip = styled.div`
  display: grid;
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
  padding-top: 4px;
  padding-right: 18px;
  .title {
    padding: 0 0 3px 3px;
    font-weight: 700;
    color: #fff;
    line-height: 14px;
  }
  .sub-menu {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
    line-height: 14px;
    &:last-child {
      margin-bottom: 6px;
    }
  }
  .sub-menu:hover {
    color: #fff;
  }
  .sub-menu-active {
    color: #fff;
  }
  .sub-menu-active-training {
    padding-left: 16px;
    cursor: pointer;
    font-weight: 400;
    color: #fff;
  }
  .sub-menu-training {
    padding-left: 16px;
    cursor: pointer;
    font-weight: 400;
    color: #ccc;
  }
  .sub-menu-training:hover {
    color: #fff;
  }
  .training {
    .title-training {
      padding-top: 8px;
    }
  }
  .dropdown-content {
    display: none;
  }
  .training:hover .dropdown-content {
    display: block;
  }
`;

export const MenuTooltip = styled.div`
  display: grid;
  width: auto;
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
  padding-top: 4px;
  cursor: pointer;
  font-weight: 600;
`;
