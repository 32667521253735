import React, { useCallback, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { values } from 'lodash';

import {
  REPORT_PERMISSION,
  ROUTER_WITH_SCREEN_PERMISSIONS,
} from 'constant/screenPermission.constant';
import { settingSelector } from 'containers/AppSettings/selectors';
import { privateRoutes, publicRoutes, routes } from './routes';
import { Header, SideBar, SpinLoading } from 'components';
import { authSelector } from 'containers/Auth/selectors';
import { getUserInfo } from 'containers/Auth/thunk';
import AccessDeniedStyled from './styles';
import { useAppDispatch } from 'hooks';
import { Roles } from 'types';

type GuardRouteProps = {
  isLogin: boolean;
  isFirstLogin: boolean;
  isPrivate: boolean;
  redirectPath: string;
  children: JSX.Element;
  role: Roles.ResponseType | undefined;
  title: string;
};

export const Navigations: React.FC = () => {
  const { isLogin, loading, isFirstLogin, userInfo } = useSelector(authSelector);
  const renderRoute = useCallback(
    (route, isPrivate?: boolean) => {
      if (!route || !values(route)) {
        return undefined;
      }
      return values(route)?.map(({ route: subRoute, element, title, ...props }, index) => (
        <Route
          key={index}
          {...props}
          element={
            <GuardRoute
              isLogin={isLogin}
              isFirstLogin={isFirstLogin}
              isPrivate={!!isPrivate}
              role={userInfo?.lookup_items?.role_code}
              redirectPath={isPrivate ? routes.Login.path : '/'}
              title={title}
            >
              {element}
            </GuardRoute>
          }
          children={renderRoute(subRoute)}
        />
      ));
    },
    [isLogin, isFirstLogin, userInfo?.lookup_items?.role_code]
  );

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
        }}
      >
        <SpinLoading size="large" loading />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          {renderRoute(publicRoutes)}
          {renderRoute(privateRoutes, true)}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const GuardRoute = ({
  role,
  children,
  isLogin,
  isPrivate,
  redirectPath,
  title,
}: GuardRouteProps) => {
  const { headerTitle } = useSelector(settingSelector);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    const email = localStorage.getItem('email');

    dispatch(
      getUserInfo({
        conditions: [
          {
            id: 'email',
            search_value: [email],
            exact_match: true,
          },
        ],
        include_lookups: true,
        include_links: true,
        page: 1,
        per_page: 1,
      })
    );
  }, [dispatch, window.location.pathname]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  if (isPrivate && isLogin) {
    const permissionName = Object.keys(ROUTER_WITH_SCREEN_PERMISSIONS).find((key) =>
      ROUTER_WITH_SCREEN_PERMISSIONS[key as keyof typeof ROUTER_WITH_SCREEN_PERMISSIONS]?.includes(
        `/${location.pathname.split('/')[1]}`
      )
    );

    if (location.pathname.split(':')[0] === routes.Report.path) {
      const roles =
        role &&
        REPORT_PERMISSION.map((r) =>
          Number(role[r as keyof typeof ROUTER_WITH_SCREEN_PERMISSIONS])
        );
      if (roles?.reduce((a, b) => a + b) === 0) {
        return (
          <SideBar title={title} accessDenied>
            <Header title={headerTitle} />
            <AccessDeniedStyled>
              <div className="access-denied">
                <LockOutlined className="icon-lock" />
                <span className="content">
                  権限がないためページを表示できません。管理者にお問い合わせください。
                </span>
              </div>
            </AccessDeniedStyled>
          </SideBar>
        );
      }
    }

    if (
      permissionName &&
      role &&
      !Number(role[permissionName as keyof typeof ROUTER_WITH_SCREEN_PERMISSIONS])
    ) {
      return (
        <SideBar title={title} accessDenied>
          <Header title={headerTitle} />
          <AccessDeniedStyled>
            <div className="access-denied">
              <LockOutlined className="icon-lock" />
              <span className="content">
                権限がないためページを表示できません。管理者にお問い合わせください。
              </span>
            </div>
          </AccessDeniedStyled>
        </SideBar>
      );
    }
    return <SideBar title={title}>{children}</SideBar>;
  } else if (!isPrivate && !isLogin) {
    return children;
  } else {
    return <Navigate to={redirectPath} state={{ from: location }} replace={true} />;
  }
};
