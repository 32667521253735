import { Text, View } from '@react-pdf/renderer';
import styles from './styles';
import React from 'react';
import Label from 'components/ReactPDF/Label';

interface ITextBoxProps {
  label?: string;
  required?: boolean;
  value?: string;
  maxLength?: number;
}

const TextBox = (props: ITextBoxProps) => {
  const { label, required, value, maxLength } = props;
  return (
    <View style={styles.textBox} wrap={false}>
      <Label label={label} required={required} />
      <Text style={styles.valueBox} wrap={false}>
        {value}
      </Text>
      {maxLength && <Text style={styles.textCount}>{`${(value || '').length}/${maxLength}`}</Text>}
    </View>
  );
};

export default TextBox;
