import { Path, Rect, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
const PublicIcon = ({ style }: { style?: Style | Style[] }) => (
  <Svg width="18" height="19" viewBox="0 0 18 19" style={style}>
    <Rect x="0.5" y="0.898438" width="17" height="17" rx="1.5" fill="#71C131" stroke="#71C131" />
    <Path
      d="M9 2.82031C5.58306 2.82031 2.8125 5.51392 2.8125 8.83594C2.8125 12.158 5.58306 14.8516 9 14.8516C12.4169 14.8516 15.1875 12.158 15.1875 8.83594C15.1875 5.51392 12.4169 2.82031 9 2.82031ZM11.6725 6.87146L8.76383 10.7924C8.72317 10.8475 8.66958 10.8925 8.60749 10.9235C8.5454 10.9545 8.4766 10.9707 8.4068 10.9707C8.337 10.9707 8.2682 10.9545 8.20611 10.9235C8.14402 10.8925 8.09043 10.8475 8.04978 10.7924L6.3275 8.47205C6.27501 8.40088 6.3275 8.30151 6.41727 8.30151H7.06503C7.2059 8.30151 7.33987 8.36731 7.42274 8.4801L8.40611 9.80676L10.5773 6.87952C10.6601 6.76807 10.7927 6.70093 10.935 6.70093H11.5827C11.6725 6.70093 11.725 6.80029 11.6725 6.87146Z"
      fill="white"
    />
  </Svg>
);

export default PublicIcon;
