import styled from 'styled-components';

export const SectionStyled = styled.div`
  font-size: 13px;
  color: #2a2a2a;
  padding-top: 20px;
  text-align: center;
  .sub-title {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2a2a2a;
    .icon {
      color: #bbbbbb;
      font-size: 28px;
      margin-right: 10px;
    }
  }
  .text-content {
    font-size: 13px;
    text-align: center;
    color: #424242;
  }
`;
