import { createAsyncThunk } from '@reduxjs/toolkit';
import { find, flatMap } from 'lodash';
import { browserLogger } from 'libs/logger';
import { HISTORY_OF_USING, PAYMENT_METHOD, COMPANIES, config } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getDataPayMentMethod = createAsyncThunk<
  Types.GetItemResponseType<Types.PaymentMethod.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('corporateUserInformation/thunk/getDataPayMentMethod', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.PaymentMethod.ResponseType>(
      PAYMENT_METHOD.name,
      req
    );
    browserLogger.info(
      'corporateUserInformation/thunk/getDataPayMentMethod',
      PAYMENT_METHOD.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCorporateUserInformation = createAsyncThunk<
  Types.GetItemResponseType<Types.CorporateUserInformationType> & {
    dataPaymentMethod: Array<Types.PaymentMethod.ResponseType>;
  },
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'corporateUserInformation/thunk/getCorporateUserInformation',
  async (req, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await services.search<Types.Companies.ResponseType>(COMPANIES.name, {
        ...req,
        conditions: [
          {
            id: 'plan_id',
            search_value: [config.PREMIUM_PLAN_ID],
          },
        ],
      });
      browserLogger.info(
        'corporateUserInformation/thunk/getCorporateUserInformation',
        COMPANIES.name,
        data
      );
      const responseStatus = await dispatch(getDataPayMentMethod(req));

      const resultArray: Types.CorporateUserInformationType[] = [];

      if (getDataPayMentMethod.fulfilled.match(responseStatus)) {
        const dataPaymentMethod = responseStatus.payload.items;

        flatMap(data.items, (item) => {
          const paymentMethod = item.payment_method_cd
            ? find(dataPaymentMethod, {
                payment_method_cd: item.payment_method_cd.toString(),
              })
            : undefined;

          resultArray.push({
            i_id: item.i_id,
            admin_department: item.admin_department,
            admin_name: item.admin_name,
            admin_email: item.admin_email,
            admin_position: item.admin_position,
            company_id: item.id,
            company_name: item.name,
            payment_method_cd: Number(item.payment_method_cd || 1),
            invoice_exception: Number(item.invoice_exception || 0),
            deletedat: item.deletedat,
            contract_status: item.deletedat ? 1 : 0,
            forced_withdrawal_flg: Number(item.forced_withdrawal_flg),
            memo: item.memo,
            plan_id: item.plan_id,
            postal_code: item.postal_code,
            building_name: item.building_name,
            admin_phone: item.admin_phone,
            address: item.address,
            company_name_furigana: item.name_furigana,
            plan_start_at: item.plan_start_at,
            unpaid_alert_flg: Number(item.unpaid_alert_flg || 0),
            suspension_flg: Number(item.suspension_flg || 0),
            unpaid_alert_date: item.unpaid_alert_date,
            suspension_date: item.suspension_date,
            forced_withdrawal_date: item.forced_withdrawal_date,
            forced_withdrawal_mail_send_date: item.forced_withdrawal_mail_send_date,
            suspension_mail_send_date: item.suspension_mail_send_date,
            unpaid_mail_send_date: item.unpaid_mail_send_date,
            forced_withdrawal_mail_send_flg: Number(item.forced_withdrawal_mail_send_flg || 0),
            suspension_mail_send_flg: Number(item.suspension_mail_send_flg || 0),
            unpaid_mail_send_flg: Number(item.unpaid_mail_send_flg || 0),
            unpaid_mail_batch_id: item.unpaid_mail_batch_id,
            suspension_mail_batch_id: item.suspension_mail_batch_id,
            forced_withdrawal_mail_batch_id: item.forced_withdrawal_mail_batch_id,
            admin_last_login_at: item?.admin_last_login_at || '',
            payment_method_name: paymentMethod?.payment_method_name || '',
            skillcheck_trans_nums: Number(item?.skillcheck_trans_nums || 0),
            month_end_users: Number(item?.month_end_users || 0),
          });
        });
      }

      return {
        ...data,
        items: resultArray,
        dataPaymentMethod: getDataPayMentMethod.fulfilled.match(responseStatus)
          ? responseStatus.payload.items
          : [],
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCompanyUser = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Companies.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('corporateUser/thunk/updateCompanyUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(COMPANIES.name, req);
    browserLogger.info('orporateUser/thunk/updateCompanyUser', COMPANIES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeFunctionCreateBatchID = createAsyncThunk<
  Types.ExecuteFunctionResponseType,
  Types.ExecuteFunctionRequestType,
  Types.ThunkAPI<Types.requestError>
>('corporateUser/thunk/executeFunctionCreateBatchID', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeFunction('createBatchId', req);
    browserLogger.info('corporateUser/thunk/executeFunctionCreateBatchID', 'createBatchId', data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeFunctionDeleteMailBatch = createAsyncThunk<
  Types.ExecuteFunctionResponseType,
  Types.ExecuteFunctionRequestType,
  Types.ThunkAPI<Types.requestError>
>('corporateUser/thunk/executeFunctionDeleteMailBatch', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeFunction('deleteMailBatch', req);
    browserLogger.info(
      'corporateUser/thunk/executeFunctionDeleteMailBatch',
      'deleteMailBatch',
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeAction = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.ExecuteActionRequestType & { actionId: string },
  Types.ThunkAPI<Types.requestError>
>('corporateUser/thunk/executeAction', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeAction(req.actionId, COMPANIES.name, req);
    browserLogger.info('corporateUser/thunk/executeAction', COMPANIES.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getHistoryOfUsing = createAsyncThunk<
  Types.GetItemResponseType<Types.HistoryOfUsing.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('corporateUserInformation/thunk/getHistoryOfUsing', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.HistoryOfUsing.ResponseType>(
      HISTORY_OF_USING.name,
      {
        ...req,
        sort_fields: [
          {
            id: 'created_at',
            order: 'asc',
          },
        ],
      }
    );
    browserLogger.info(
      'corporateUserInformation/thunk/getHistoryOfUsing',
      HISTORY_OF_USING.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateHistoryOfUsing = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.HistoryOfUsing.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('corporateUserInformation/thunk/updateHistoryOfUsing', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(HISTORY_OF_USING.name, req);
    browserLogger.info(
      'corporateUserInformation/thunk/updateHistoryOfUsing',
      HISTORY_OF_USING.name,
      data
    );
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
