import { Text, View } from '@react-pdf/renderer';
import styles from './styles';
import React, { useMemo } from 'react';
import Label from 'components/ReactPDF/Label';
import { DatepickerIconPdf, VectorIconPdf } from 'assets/svgPDF';
import { formatDate } from 'libs/utils/format';
import { YEAR_MONTH_FORMAT } from 'constant';
import { Style } from '@react-pdf/types';

interface IProps {
  label?: string;
  required?: boolean;
  value?: string[];
  placeholder?: string;
  format?: string;
  selectBoxStyle?: Style;
  style?: Style;
}

const DateRangerPicker = (props: IProps) => {
  const { label, required, value, placeholder, format, style, selectBoxStyle } = props;
  const placeholderText = useMemo(
    () => (placeholder ? placeholder : `${label || ''}で検索`),
    [label, placeholder]
  );
  const first = formatDate(value?.[0] || '', format || YEAR_MONTH_FORMAT);
  const second = formatDate(value?.[1] || '', format || YEAR_MONTH_FORMAT);
  return (
    <View style={[styles.selectGroup, style ? style : {}]} wrap={false}>
      {label && <Label label={label} required={required} />}
      <View style={[styles.selectBox, selectBoxStyle ? selectBoxStyle : {}]}>
        {value && (
          <View style={styles.valueGroup}>
            <Text style={styles.selectText}>{first}</Text>
            <VectorIconPdf />
            <Text style={styles.selectText}>{second}</Text>
          </View>
        )}
        {!value && (
          <Text style={[styles.selectText, styles.placeholder]}>{placeholderText || ''}</Text>
        )}
        <DatepickerIconPdf style={styles.selectIcon} />
      </View>
    </View>
  );
};

export default DateRangerPicker;
