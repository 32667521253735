import styled from 'styled-components';

export const ListCurriculumStyled = styled.div`
  .row-content {
    display: flex;
    justify-content: center;
  }
  .pub3 {
    padding-left: 25px !important;
  }

  @media (max-width: 1440px) {
    .curriculum-styled {
      .curriculum-partner {
        .btn-list,
        .btn-tree {
          width: 160px;
          padding: 8px;
          height: 40px;
        }
      }
      .form-search {
        justify-content: flex-start !important;
        .item-select {
          width: 85% !important;
          .item {
            width: 200px !important;
            margin-right: 8px !important;
          }
          .contract-status {
            width: 120px !important;
            margin-right: 8px !important;
          }
          .partner-name {
            margin-right: 40px !important;
          }
          .text-label {
            font-size: 12px !important;
          }
        }
        .group-btn {
          width: 15% !important;
          justify-content: start;
          .btn-search {
            width: 100px;
          }
        }
      }
      .wrap-button {
        .btn {
          width: 160px;
        }
      }
      .table {
        .ant-table-thead {
          .ant-table-cell {
            padding: 4px 10px;
            height: 48px;
          }
        }
        .ant-table-tbody {
          .ant-table-cell {
            padding: 4px 10px;
            height: 44px;
          }
        }
      }
    }
  }
  .ant-table {
    border: 1px solid #e5e5e5;
    overflow: hidden;
    border-radius: 3px;
    .ant-table-row:last-child {
      td {
        border: none;
      }
    }
    .ant-table-placeholder {
      .ant-table-cell {
        border: none;
      }
    }
  }
  .wrap-table {
    position: relative;
  }
  .wrap-select-record {
    display: flex;
    .label-select {
      font-size: 11px;
      color: #424242;
    }
  }
  .curriculum-styled {
    padding: 24px;
    background: #f9f8f8;
    .curriculum-partner {
      display: flex;
      justify-content: space-between;
      position: relative;
      .curriculum-title {
        font-size: 12px;
        color: #424242;
      }
      ::before {
        position: absolute;
        content: '';
        width: 60px;
        height: 2px;
        border-radius: 2px;
        top: 32px;
        background: #dddddd;
      }
    }
    .btn-list {
      margin-right: 4px;
      padding: 8px 16px;
      background: #ffffff;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 20px;
      color: #f1a900;
      cursor: pointer;
    }
    .btn-tree {
      padding: 8px 16px;
      background: #ffffff;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 20px;
      color: #f1a900;
      cursor: pointer;
    }
    .form-search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 50px;
      .item-select {
        display: flex;
        align-items: center;
        width: 87%;
        .item {
          width: 18%;
          margin-right: 20px;
        }
        .contract-status {
          width: 12%;
        }
        .partner-name {
          margin-right: 50px;
        }
      }
      .group-btn {
        width: 13%;
        display: flex;
        align-items: center;
        justify-content: end;
        .label-reset {
          padding-left: 15px;
          font-size: 12px;
          cursor: pointer;
          text-decoration-line: underline;
          color: #00a3a5;
        }
        .btn-search {
          background: #ffffff;
          border: 1px solid #00a3a5;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
          font-size: 14px;
          color: #00a3a5;
          cursor: pointer;
          .icon-search {
            font-size: 18px;
            margin-right: 12px;
          }
        }
      }
    }
    .wrap-button {
      position: absolute;
      right: 0;
      margin: 12px 0 12px 30px;
      z-index: 9;
      .btn {
        padding: 0 40px;
        height: 40px;
        cursor: pointer;
        font-size: 13px;
        font-weight: 700;
        margin-left: 8px;
      }
      .btn-active {
        background-color: #f6ac00;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: #ffffff;
      }
    }
    .no-data {
      right: 0;
      padding: 16px 0;
      z-index: 9;
      text-align: end;
      .btn {
        padding: 0 40px;
        height: 40px;
        cursor: pointer;
        font-size: 13px;
        font-weight: 700;
        margin-left: 8px;
      }
      .btn-active {
        background-color: #f6ac00;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: #ffffff;
      }
    }
    .apartment-style {
      text-align: center;
      color: #c4c4c4;
      font-size: 20px;
      cursor: pointer;
    }
    .curriculum_code {
      font-size: 11px;
      color: #999999;
    }
    .text-count {
      position: absolute;
      margin-top: 22px;
      font-family: Lato;
      font-size: 14px;
      color: #424242;
      z-index: 9;
      .text-static {
        font-size: 11px;
        padding-left: 3px;
      }
    }
    .style-list-no-data {
      display: flex;
      justify-content: space-between;
      direction: rtl;
    }
    .text-count-no-data {
      right: 0;
      padding-top: 24px;
      z-index: 9;
      text-align: right;
      .text-static {
        font-size: 11px;
        padding-left: 3px;
      }
    }
    .icon {
      width: 20px;
      color: #ffffff;
    }
    .spanText {
      margin-left: 8px;
    }
    .table {
      .ant-table-thead {
        border-radius: 2px 2px 0 0;
        .ant-table-cell {
          background-color: #ebebeb;
          font-weight: 700;
          font-size: 12px;
          color: #424242;
          margin: 0 3px;
          &:before {
            display: none;
          }
        }
      }
      .ant-table-cell {
        text-align: center;
        font-size: 13px;
      }
      .ant-table-cell.description,
      .public-status {
        text-align: left;
      }
    }
  }
`;
