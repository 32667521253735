import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import React from 'react';

const CheckedIcon = ({ style }: { style?: Style | Style[] }) => (
  <Svg style={style} width="28" height="28" viewBox="0 0 28 28">
    <G clip-path="url(#clip0_10_23146)">
      <Path
        d="M19.8441 9.03125H18.3785C18.0598 9.03125 17.7566 9.18437 17.5691 9.44687L12.6566 16.2594L10.4316 13.1719C10.2441 12.9125 9.94415 12.7563 9.62227 12.7563H8.15665C7.95352 12.7563 7.83477 12.9875 7.95352 13.1531L11.8473 18.5531C11.9393 18.6815 12.0605 18.7861 12.201 18.8583C12.3415 18.9305 12.4971 18.9681 12.6551 18.9681C12.813 18.9681 12.9687 18.9305 13.1092 18.8583C13.2497 18.7861 13.3709 18.6815 13.4629 18.5531L20.0441 9.42813C20.166 9.2625 20.0473 9.03125 19.8441 9.03125Z"
        fill="#08A3A5"
      />
      <Path
        d="M14 0C6.26875 0 0 6.26875 0 14C0 21.7313 6.26875 28 14 28C21.7313 28 28 21.7313 28 14C28 6.26875 21.7313 0 14 0ZM14 25.625C7.58125 25.625 2.375 20.4188 2.375 14C2.375 7.58125 7.58125 2.375 14 2.375C20.4188 2.375 25.625 7.58125 25.625 14C25.625 20.4188 20.4188 25.625 14 25.625Z"
        fill="#08A3A5"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_10_23146">
        <Rect width="28" height="28" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default CheckedIcon;
