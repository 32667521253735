import styled from 'styled-components';

export default styled.section`
  margin-bottom: 45px;

  .ant-select-selection-item {
    text-align: left !important;
  }

  .ant-select-item-option-content {
    flex: none !important;
  }

  .text-right {
    text-align: right !important;
  }

  .body-info {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    display: flex;
    .title {
      font-size: 16px;
      color: #2a2a2a;
    }

    .right-side {
      padding: 20px;
      width: 100%;
      .wrap-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .wrap-action {
          display: flex;
          width: 60%;
          justify-content: end;
        }
        .wrap-select {
          display: flex;
          align-items: center;
          width: 50%;
          justify-content: end;
          .ant-select-selector {
            height: 36px;
          }
          .ant-select {
            width: 60%;
          }
          label {
            color: #777777;
            font-size: 12px;
          }
        }
        .legend {
          display: flex;
          align-items: center;

          .sort-column {
            display: flex;
            align-items: center;
            padding: 0 10px;
            margin-left: 20px;
            .legend-filter {
              width: 14px;
              height: 14px;
            }
            .blue {
              background: #4473b1;
            }
            .yellow {
              background: #f0995a;
            }
            .green {
              background: #c6dda9;
            }
            .legend-name {
              font-size: 12px;
              color: #424242;
              margin: 0 5px;
            }
          }
        }
        .checkbox {
          display: flex;
          height: 36px;
          align-items: center;
          padding: 0 8px;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 3px;
          .text {
            padding-left: 12px;
          }
        }
      }
      .chart {
        width: 98%;
        margin-top: 60px;
        position: relative;
        .unit {
          position: absolute;
          right: 0px;
          top: -30px;
          font-size: 11px;
          color: #999999;
        }
        .line-chart {
          height: 20vh !important;
        }
      }
    }
  }
`;
