import React, { useCallback, useMemo, useState } from 'react';
import SectionStyled from './styles';
import { AnyObject } from 'types';
import { ColumnDef, flexRender, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import { defaultRangeExtractor, useVirtualizer } from '@tanstack/react-virtual';
import { v4 as uuidv4 } from 'uuid';
import type { Range } from '@tanstack/react-virtual';
import { ArrowDownDouble } from 'assets';
import { DEFAULT_TABLE_COL_WIDTH } from 'constant';
import ResizeObserver from 'rc-resize-observer';
import { intersection } from 'lodash';

interface VirtualizedTableProps {
  data: AnyObject[];
  columns: ColumnDef<AnyObject>[];
}

const VirtualizedTable: React.FC<VirtualizedTableProps> = (props) => {
  const { data, columns } = props;
  const [collapsed, setCollapsed] = React.useState<string[]>([]);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const activeStickyIndexRef = React.useRef(0);
  const [tableWidth, setTableWidth] = useState(0);

  const mergedColumns = useMemo<ColumnDef<AnyObject>[]>(() => {
    const tempColumns: ColumnDef<AnyObject>[] = columns.map((obj) => {
      if (!obj.size) {
        return {
          ...obj,
          size: DEFAULT_TABLE_COL_WIDTH,
        };
      }
      return obj;
    });
    const totalColumnWidth = tempColumns.reduce((width, col) => width + (col.size || 0), 0);
    if (totalColumnWidth >= tableWidth) {
      return tempColumns;
    }
    const diff = tableWidth - totalColumnWidth;
    return tempColumns.map((col) => ({
      ...col,
      size:
        (col.size || DEFAULT_TABLE_COL_WIDTH) +
        Math.ceil(((col.size || DEFAULT_TABLE_COL_WIDTH) * diff) / totalColumnWidth),
    }));
  }, [columns, tableWidth]);

  const dataSource = useMemo(() => {
    const results: AnyObject[] = [];
    const getData = (
      items: AnyObject[],
      parentPath?: string[],
      parentId?: string,
      parentLevel?: number
    ) => {
      if (items && items.length) {
        items.forEach((item) => {
          const hasChildren = !!item.children;
          const data = {
            ...item,
            children: undefined,
            _virtualRowData: {
              ...(item._virtualRowData || {}),
              id: uuidv4(),
              parentId: parentId,
              path: parentId ? [...(parentPath || []), parentId] : [],
              level: parentLevel || parentLevel === 0 ? parentLevel + 1 : 0,
              hasChildren: hasChildren,
            },
          };
          results.push(data);
          if (item.children && item.children.length) {
            getData(
              item.children,
              data._virtualRowData.path,
              data._virtualRowData.id,
              data._virtualRowData.level
            );
          }
        });
      }
    };
    getData(data);
    return results;
  }, [data]);

  const onExpand = useCallback((rowId: string) => {
    setCollapsed((prevState) =>
      prevState.includes(rowId) ? prevState.filter((key) => key !== rowId) : [...prevState, rowId]
    );
  }, []);

  const tableData = useMemo(() => {
    if (!collapsed.length) {
      return dataSource;
    }
    return dataSource.filter((obj) => !intersection(collapsed, obj._virtualRowData.path).length);
  }, [collapsed, dataSource]);

  const stickyIndexes = React.useMemo(
    () =>
      tableData
        .map((obj, idx) => ({ ...obj, _index: idx }))
        .filter(
          (obj: AnyObject) => obj._virtualRowData.level === 0 && obj._virtualRowData.hasChildren
        )
        .map((obj) => obj._index),
    [tableData]
  );

  const isSticky = (index: number) => stickyIndexes.includes(index);

  const isActiveSticky = (index: number) => activeStickyIndexRef.current === index;

  const table = useReactTable({
    data: tableData,
    columns: mergedColumns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 40,
    getScrollElement: () => tableContainerRef.current,
    rangeExtractor: React.useCallback(
      (range: Range) => {
        activeStickyIndexRef.current =
          [...stickyIndexes].reverse().find((index) => range.startIndex >= index) ?? 0;

        const next = new Set([activeStickyIndexRef.current, ...defaultRangeExtractor(range)]);
        // @ts-ignore
        return [...next].sort((a, b) => a - b);
      },
      [stickyIndexes]
    ),
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <SectionStyled ref={tableContainerRef}>
        <table className="virtual-table">
          <thead className="virtual-table-head">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className="virtual-table-header-row" key={headerGroup.id}>
                {headerGroup.headers.map((header, headerIndex) => {
                  return (
                    <th
                      className="virtual-table-header-cell"
                      key={header.id}
                      style={{
                        // display: 'flex',
                        width: headerIndex === 0 ? header.getSize() - 1 : header.getSize(),
                      }}
                    >
                      {header.column.columnDef.header}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody
            className="virtual-table-body"
            style={{
              display: 'grid',
              height: `${rowVirtualizer.getTotalSize()}px`,
              position: 'relative',
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<AnyObject>;
              const _virtualRowData = row.original?._virtualRowData;
              const rowClassName = _virtualRowData.className || '';
              const expandable = _virtualRowData?.hasChildren;
              const rowId = _virtualRowData?.id;
              const level = _virtualRowData?.level || 0;
              const nextRow =
                virtualRow.index + 1 === rows.length
                  ? null
                  : (rows[virtualRow.index + 1] as Row<AnyObject>);
              const isLastChild =
                _virtualRowData.parentId &&
                (!nextRow ||
                  _virtualRowData.parentId !== nextRow.original?._virtualRowData.parentId);
              return (
                <tr
                  onClick={() => expandable && onExpand(rowId)}
                  className={`virtual-table-row virtual-table-row-level-${level} ${
                    isLastChild ? 'virtual-table-row-last-child' : ''
                  } ${rowClassName}`}
                  data-index={virtualRow.index} //needed for dynamic row height measurement
                  ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                  key={row.id}
                  style={{
                    ...(isSticky(virtualRow.index)
                      ? {
                          zIndex: 1,
                          background: '#F9F9F9',
                        }
                      : {}),
                    ...(isActiveSticky(virtualRow.index)
                      ? {
                          position: 'sticky',
                          top: '40px',
                          transform: `translateY(0px)`,
                        }
                      : {
                          position: 'absolute',
                          top: 0,
                          transform: `translateY(${virtualRow.start}px)`,
                        }),
                    left: 0,
                    display: 'flex',
                    width: '100%',
                    height: `${virtualRow.size}px`,
                  }}
                >
                  {row.getVisibleCells().map((cell, index) => {
                    const renderExpand = expandable && index === 0;
                    const isFirstCell = index === 0;
                    return (
                      <td
                        className={`virtual-table-cell`}
                        key={cell.id}
                        style={{
                          display: index === 0 ? 'flex' : undefined,
                          width: cell.column.getSize(),
                        }}
                      >
                        {isFirstCell && <span className="level-space"></span>}
                        {renderExpand && !collapsed.includes(rowId) && (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginRight: 8,
                            }}
                          >
                            <img alt="" height={17} width={17} src={ArrowDownDouble} />
                          </div>
                        )}
                        {renderExpand && collapsed.includes(row.original?._virtualRowData?.id) && (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginRight: 8,
                              transform: 'rotate(180deg)',
                            }}
                          >
                            <img alt="" height={17} width={17} src={ArrowDownDouble} />
                          </div>
                        )}
                        {isFirstCell && (
                          <div className="virtual-table-first-cell-content">
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </div>
                        )}
                        {!isFirstCell && flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </SectionStyled>
    </ResizeObserver>
  );
};

export default VirtualizedTable;
