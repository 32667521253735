import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getIndividualUserManagement,
  getIndividualUserManagementExport,
  getSelectIndividual,
} from './thunk';
import * as Types from 'types';

export type InitialState = {
  total: number;
  loading: boolean;
  dataIndividualUserManagement: Array<Types.IndividualUserManagement>;
  dataSelectInvidual: Array<Types.IndividualUserManagement>;
};

const initialState: InitialState = {
  total: 0,
  loading: false,
  dataIndividualUserManagement: [],
  dataSelectInvidual: [],
};

export const individualUserManagementSlice = createSlice({
  name: 'IndividualUserManagement-page',
  initialState,
  reducers: {
    filterIndividualByDeleted: (state, action: PayloadAction<string>) => {
      state.dataIndividualUserManagement = state.dataIndividualUserManagement.filter(
        ({ deletedat }) => !(Number(action.payload) ? !!deletedat : !deletedat)
      );
    },
  },
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };
    builder
      .addCase(getIndividualUserManagementExport.pending, startLoading)
      .addCase(getIndividualUserManagement.pending, startLoading);

    builder.addCase(getIndividualUserManagement.fulfilled, (state, action) => {
      state.dataIndividualUserManagement = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getIndividualUserManagementExport.fulfilled, stopLoading);

    builder.addCase(getSelectIndividual.fulfilled, (state, action) => {
      state.dataSelectInvidual = action.payload.report_results;
    });
    builder
      .addCase(getIndividualUserManagementExport.rejected, stopLoading)
      .addCase(getIndividualUserManagement.rejected, stopLoading);
  },
});

export const { filterIndividualByDeleted } = individualUserManagementSlice.actions;
export default individualUserManagementSlice.reducer;
