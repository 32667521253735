import { RadioChangeEvent, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { SectionStyled } from './styles';
import Modal from 'components/Modal';
import { useAppDispatch } from 'hooks';
import { loadingRef } from 'components/Loading';

interface Props {
  visible: boolean;
  disablePdf?: boolean;
  disableCsv?: boolean;
  onSubmit?: (value: string) => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  customTitle?: React.ReactNode;
}

const PopupConfirmExportFile: React.FC<Props> = ({
  visible,
  disablePdf,
  disableCsv,
  setVisible,
  onSubmit,
  title,
  customTitle,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [value, setValue] = useState<'csv' | 'pdf'>(disableCsv ? 'pdf' : 'csv');
  const handleToggleModal = () => {
    setVisible((prevState) => !prevState);
  };
  const dispatch = useAppDispatch();

  const onChange = (e: RadioChangeEvent): void => {
    setValue(e.target.value);
  };

  useEffect(() => {
    loadingRef.current?.isLoading(submitting);
  }, [submitting]);

  return (
    <Modal
      maskClosable={false}
      title={
        <h3
          style={{
            display: 'flex',
            backgroundColor: '#ffffff',
            fontFamily: 'Noto Sans Javanese',
            fontSize: 18,
            justifyContent: 'center',
            alignItems: 'center',
            color: '#2a2a2a',
            margin: '0',
          }}
        >
          エクスポート
        </h3>
      }
      open={visible}
      width={720}
      onCancel={handleToggleModal}
      okButton={{
        text: 'OK',
        onClick: async () => {
          // setSubmitting(true);
          onSubmit && (await onSubmit(value));
          // setSubmitting(false);
        },
        style: {
          width: 140,
          height: 40,
        },
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleToggleModal,
        style: {
          width: 140,
          height: 40,
        },
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <div className="content">
          <p className="text-content">
            {customTitle ? (
              customTitle
            ) : (
              <>
                {title}
                <br />
                出力形式を選択して、OKボタンをクリックしてください。
              </>
            )}
          </p>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default PopupConfirmExportFile;
