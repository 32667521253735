import { createSlice } from '@reduxjs/toolkit';
import { chain, differenceBy, filter, flatMap, orderBy } from 'lodash';
import dayjs from 'dayjs';

import * as Types from 'types';

import {
  getDataAggregationChurnRate,
  getDataAggregationChurnRateExport,
  getDataChangesInUsageOfOfficialCurriculum,
  getDataChangesInUsageOfOfficialCurriculumChurnRate,
  getDataNumberOfCorporateUsers,
  getDataNumberOfIndividualUsers,
  getDataNumberOfPartnerCompanies,
  getDataNumOfficialCurriculumCountOfStops,
  getDataNumOfficialCurriculumUsers,
  getDataNumOfficialCurriculumUsersActive,
  getDataNumOfNewUsersMonth,
  getDataNumOfSkillChecksPerformed,
  getDataNumOfSkillChecksPerformedMonth,
  getDataSalesTrends,
  getDataSkillCheckTrans,
  getNumberOfInterviewsConducted,
  getUserCount,
  getDataUserRegistrationHistory,
  getDataAdminDashbordSalesInformation,
  getDataSalesInformation,
  getDataUsageList,
} from './thunk';
import { toNumber } from 'libs/utils/format';

export type InitialState = {
  total: number;
  loading: boolean;
  numOfCorporateUsers: number;
  numOfIndividualUsers: number;
  numOfPartnerCompanies: number;
  numOfImplementations: number;
  numOfInternalImplementations: number;
  numOfInterviewsConducted: number;
  numOfficialCurriculumUsers: number;
  numOfficialCurriculumSuspendedUsers: number;
  numOfficialCurriculumUsersActive: number;
  numOfNewUsersMonth: number;
  numOfficialCurriculumCountOfStops: number;
  dataAggregationChurnRate: Array<Types.AggregationChurnRate.ResponseType>;
  dataUserRegistrationHistory: Array<Types.UserRegistrationHistory.ResponseType>;
  dataUserTransitionColumnChart: Array<Types.UserTransitionColumnChart>;
  dataEndOfMonthUsersActiveColumnChart: Array<Types.UserTransitionColumnChart>;
  dataEndOfMonthUsersColumnChart: Array<Types.UserTransitionColumnChart>;
  dataUserTransitionColumnChartActive: Array<Types.UserTransitionColumnChart>;
  dataUserTransitionLineChart: Array<Types.UserTransitionColumnChart>;
  dataUserTransitionLineChartActive: Array<Types.UserTransitionLineChart>;
  dataNumOfImplementations: Array<Types.NumOfSkillChecksPerformedMonth>;
  dataChangesInUsageOfOfficialCurriculumColumn: Array<Types.UserTransitionColumnChart>;
  dataChangesInUsageOfOfficialCurriculumLine: Array<Types.UserTransitionColumnChart>;
  dataSalesTrendsDual: Array<Types.DualAxesData>;
  dataSalesTrendsLine: Array<Types.LineData>;
  dataPreviosMonthSale: number;
  dataSaleForSameMonthLastYear: number;
  dataMonthlyUsageFee: number;
  dataStorageUsageFee: number;
  dataSkillCheckUsageFee: number;
  dataUsage: number;
  dataCountUser: number;
  dataUsageList: Array<Types.UsageList>;
};

const initialState: InitialState = {
  total: 0,
  loading: false,
  numOfCorporateUsers: 0,
  numOfIndividualUsers: 0,
  numOfPartnerCompanies: 0,
  numOfImplementations: 0,
  numOfInternalImplementations: 0,
  numOfInterviewsConducted: 0,
  numOfficialCurriculumUsers: 0,
  numOfficialCurriculumSuspendedUsers: 0,
  numOfficialCurriculumUsersActive: 0,
  numOfNewUsersMonth: 0,
  numOfficialCurriculumCountOfStops: 0,
  dataAggregationChurnRate: [],
  dataUserRegistrationHistory: [],
  dataUserTransitionColumnChart: [],
  dataUserTransitionColumnChartActive: [],
  dataUserTransitionLineChart: [],
  dataUserTransitionLineChartActive: [],
  dataNumOfImplementations: [],
  dataEndOfMonthUsersActiveColumnChart: [],
  dataEndOfMonthUsersColumnChart: [],
  dataChangesInUsageOfOfficialCurriculumColumn: [],
  dataChangesInUsageOfOfficialCurriculumLine: [],
  dataSalesTrendsDual: [],
  dataSalesTrendsLine: [],
  dataPreviosMonthSale: 0,
  dataSaleForSameMonthLastYear: 0,
  dataMonthlyUsageFee: 0,
  dataSkillCheckUsageFee: 0,
  dataStorageUsageFee: 0,
  dataUsage: 0,
  dataCountUser: 0,
  dataUsageList: [],
};

export const dashboardSlice = createSlice({
  name: 'dashboard-page',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getDataChangesInUsageOfOfficialCurriculumChurnRate.pending, startLoading)
      .addCase(getDataChangesInUsageOfOfficialCurriculum.pending, startLoading)
      .addCase(getDataNumOfficialCurriculumCountOfStops.pending, startLoading)
      .addCase(getDataNumOfNewUsersMonth.pending, startLoading)
      .addCase(getDataNumOfficialCurriculumUsersActive.pending, startLoading)
      .addCase(getDataNumOfficialCurriculumUsers.pending, startLoading)
      .addCase(getDataNumOfSkillChecksPerformedMonth.pending, startLoading)
      .addCase(getNumberOfInterviewsConducted.pending, startLoading)
      .addCase(getDataNumOfSkillChecksPerformed.pending, startLoading)
      .addCase(getDataSkillCheckTrans.pending, startLoading)
      .addCase(getDataAggregationChurnRate.pending, startLoading)
      .addCase(getDataUserRegistrationHistory.pending, startLoading)
      .addCase(getDataAdminDashbordSalesInformation.pending, startLoading)
      .addCase(getDataSalesInformation.pending, startLoading)
      .addCase(getDataNumberOfPartnerCompanies.pending, startLoading)
      .addCase(getDataNumberOfCorporateUsers.pending, startLoading)
      .addCase(getDataAggregationChurnRateExport.pending, startLoading)
      .addCase(getDataNumberOfIndividualUsers.pending, startLoading)
      .addCase(getDataSalesTrends.pending, startLoading)
      .addCase(getUserCount.pending, startLoading);

    builder.addCase(getDataNumberOfCorporateUsers.fulfilled, (state, action) => {
      state.numOfCorporateUsers = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataNumberOfIndividualUsers.fulfilled, (state, action) => {
      state.numOfIndividualUsers = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataNumberOfPartnerCompanies.fulfilled, (state, action) => {
      state.numOfPartnerCompanies = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataUserRegistrationHistory.fulfilled, (state, action) => {
      state.dataUserRegistrationHistory = action.payload.items;
      const dataMembership = chain(action.payload.items.filter((item) => item.createdat))
        .groupBy('createdat')
        .map((value, key) => ({
          target_month: key,
          name: '入会数',
          value: value.filter((item) => item.createdat),
        }))
        .value();
      const dataNumOfWithdrawals = chain(
        action.payload.items.filter((item) => item.createdat && item.deletedat)
      )
        .groupBy('deletedat')
        .map((value, key) => ({
          target_month: key,
          name: '退会数',
          value: value.filter((item) => item.deletedat),
        }))
        .value();

      const dataMembershipActive = dataMembership.map((data) => ({
        ...data,
        value: data.value.filter(
          (item) => dayjs(item.last_login_at).unix() > dayjs().subtract(3, 'month').unix()
        ).length,
      }));
      const dataNumOfWithdrawalsActive = dataNumOfWithdrawals.map((data) => ({
        ...data,
        value: data.value.filter(
          (item) => dayjs(item.last_login_at).unix() > dayjs().subtract(3, 'month').unix()
        ).length,
      }));

      const dataMembershipColumns = dataMembership.map((data) => ({
        ...data,
        value: data.value.length,
      }));
      const dataNumOfWithdrawalColumns = dataNumOfWithdrawals.map((data) => ({
        ...data,
        value: data.value.length,
      }));

      state.dataUserTransitionColumnChart = [
        ...dataMembershipColumns,
        ...differenceBy(dataNumOfWithdrawalColumns, dataMembershipColumns, 'target_month').map(
          (data) => ({ ...data, name: '入会数', value: 0 })
        ),
        ...dataNumOfWithdrawalColumns,
        ...differenceBy(dataMembershipColumns, dataNumOfWithdrawalColumns, 'target_month').map(
          (data) => ({ ...data, name: '退会数', value: 0 })
        ),
      ];
      state.dataUserTransitionColumnChartActive = [
        ...dataMembershipActive,
        ...differenceBy(dataNumOfWithdrawalsActive, dataMembershipActive, 'target_month').map(
          (data) => ({ ...data, name: '入会数', value: 0 })
        ),
        ...dataNumOfWithdrawalsActive,
        ...differenceBy(dataMembershipActive, dataNumOfWithdrawalsActive, 'target_month').map(
          (data) => ({ ...data, name: '退会数', value: 0 })
        ),
      ];

      state.dataUserTransitionLineChart = chain(state.dataUserTransitionColumnChart)
        .groupBy('target_month')
        .map((value, key) => ({
          target_month: key,
          name: 'Churn Rate',
          value:
            value
              .filter((item) => item.name === '退会数')
              .reduce((sum, item) => sum + item.value, 0) /
            (value
              .filter((item) => item.name === '入会数')
              .reduce((sum, item) => sum + item.value, 0) || 1),
        }))
        .value();

      state.dataUserTransitionLineChartActive = chain(state.dataUserTransitionColumnChartActive)
        .groupBy('target_month')
        .map((value, key) => ({
          target_month: key,
          name: 'Churn Rate',
          value:
            value
              .filter((item) => item.name === '退会数')
              .reduce((sum, item) => sum + item.value, 0) /
            (value
              .filter((item) => item.name === '入会数')
              .reduce((sum, item) => sum + item.value, 0) || 1),
        }))
        .value();

      const recentLogins = filter(action.payload.items, (item) => {
        if (item.last_login_at) {
          return dayjs(item.last_login_at).isAfter(dayjs().subtract(3, 'month'));
        }
        return false;
      });

      const recentMonths = recentLogins.reduce((acc, item) => {
        if (item.createdat) acc.add(dayjs(item.createdat).format('YYYYMM'));
        if (item.deletedat) acc.add(dayjs(item.deletedat).format('YYYYMM'));
        return acc;
      }, new Set<string>());

      const months = action.payload.items.reduce((acc, item) => {
        if (item.createdat) acc.add(dayjs(item.createdat).format('YYYYMM'));
        if (item.deletedat) acc.add(dayjs(item.deletedat).format('YYYYMM'));
        return acc;
      }, new Set<string>());

      const uniqueMonths = Array.from(months);
      const uniqueRecentMonths = Array.from(recentMonths);

      const resultEndOfMonthUsersColumnChart = uniqueMonths.map((month) => {
        const dataCreated = filter(
          action.payload.items,
          (item) => !!item.createdat && dayjs(item.createdat).format('YYYYMM') <= month
        );
        const dataDeleted = filter(
          action.payload.items,
          (item) => !!item.deletedat && dayjs(item.deletedat).format('YYYYMM') <= month
        );

        return {
          target_month: month,
          name: '月末ユーザー数',
          value: dataCreated.length - dataDeleted.length,
        };
      });

      const resultEndOfMonthUsersActiveColumnChart = uniqueRecentMonths.map((month) => {
        const dataCreated = filter(
          recentLogins,
          (item) => dayjs(item.createdat).format('YYYYMM') <= month
        );
        const dataDeleted = filter(
          recentLogins,
          (item) => dayjs(item.deletedat).format('YYYYMM') <= month
        );

        return {
          target_month: month,
          name: '月末ユーザー数',
          value: dataCreated.length - dataDeleted.length,
        };
      });

      state.dataEndOfMonthUsersColumnChart = orderBy(
        resultEndOfMonthUsersColumnChart,
        ['target_month'],
        ['asc']
      );
      state.dataEndOfMonthUsersActiveColumnChart = orderBy(
        resultEndOfMonthUsersActiveColumnChart,
        ['target_month'],
        ['asc']
      );
      stopLoading(state);
    });
    builder.addCase(getDataSkillCheckTrans.fulfilled, (state, action) => {
      state.numOfImplementations = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataNumOfSkillChecksPerformed.fulfilled, (state, action) => {
      state.numOfInternalImplementations = (action.payload.report_results || []).reduce(
        (acc, curr) => acc + parseInt(curr.number_of_performed),
        0
      );
      stopLoading(state);
    });
    builder.addCase(getNumberOfInterviewsConducted.fulfilled, (state, action) => {
      state.numOfInterviewsConducted = (action.payload.report_results || []).reduce(
        (acc, curr) => acc + parseInt(curr.number_of_performed),
        0
      );
      stopLoading(state);
    });
    builder.addCase(getDataAdminDashbordSalesInformation.fulfilled, (state, action) => {
      const groupArray = chain(
        action.payload.report_results.filter((data) =>
          dayjs(data.target_month, 'YYYYMM', true).isValid()
        )
      )
        .groupBy('target_month')
        .map((value, key) => ({
          target_month: key,
          ...value.reduce(
            (sum, val) => {
              sum.emp_skill_check_count += val.emp_skill_check_count;
              sum.inter_skill_check_count += val.inter_skill_check_count;
              return sum;
            },
            { emp_skill_check_count: 0, inter_skill_check_count: 0 }
          ),
        }))
        .orderBy('target_month')
        .value();

      state.dataNumOfImplementations = flatMap(
        groupArray.map((item) => [
          { implementation: item.target_month, type: '社内', value: item.emp_skill_check_count },
          { implementation: item.target_month, type: '面接', value: item.inter_skill_check_count },
          {
            implementation: item.target_month,
            type: '実施合計',
            value: item.emp_skill_check_count + item.inter_skill_check_count,
          },
        ])
      );
      stopLoading(state);
    });
    builder.addCase(getDataNumOfficialCurriculumUsers.fulfilled, (state, action) => {
      state.numOfficialCurriculumUsers = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataNumOfficialCurriculumUsersActive.fulfilled, (state, action) => {
      state.numOfficialCurriculumUsersActive = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataNumOfNewUsersMonth.fulfilled, (state, action) => {
      state.numOfNewUsersMonth = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataNumOfficialCurriculumCountOfStops.fulfilled, (state, action) => {
      state.numOfficialCurriculumCountOfStops = action.payload.totalItems;
      stopLoading(state);
    });

    builder.addCase(getDataChangesInUsageOfOfficialCurriculum.fulfilled, (state, action) => {
      const groupArray = chain(action.payload.items)
        .groupBy('target_month')
        .map((value, key) => ({
          target_month: key,
          ...value.reduce(
            (sum, val) => ({
              active_number_of_usage:
                sum.active_number_of_usage + toNumber(val.active_number_of_usage),
              number_of_usage: sum.number_of_usage + toNumber(val.number_of_usage),
              count_of_new_users: sum.count_of_new_users + toNumber(val.count_of_new_users),
              count_of_stops: sum.count_of_stops + toNumber(val.count_of_stops),
              activation_rate: sum.activation_rate + toNumber(val.activation_rate),
            }),
            {
              active_number_of_usage: 0,
              number_of_usage: 0,
              count_of_new_users: 0,
              count_of_stops: 0,
              activation_rate: 0,
            }
          ),
        }))
        .value();

      state.dataChangesInUsageOfOfficialCurriculumColumn = flatMap(
        groupArray.map((data) => [
          { target_month: data.target_month, value: data.count_of_new_users, name: '新規利用' },
          { target_month: data.target_month, value: data.count_of_stops, name: '利用停止' },
          { target_month: data.target_month, value: data.number_of_usage, name: '利用ユーザー数' },
          {
            target_month: data.target_month,
            value: data.active_number_of_usage,
            name: 'アクティブ',
          },
        ])
      );

      state.dataChangesInUsageOfOfficialCurriculumLine = flatMap(
        groupArray.map((data) => [
          {
            target_month: data.target_month,
            value: parseFloat((data.count_of_stops / (data.count_of_new_users || 1)).toFixed(1)), // Round to the first decimal point task SF-2884 Bug 3516
            name: 'Churn Rate',
          },
          {
            target_month: data.target_month,
            value: data.activation_rate,
            name: 'アクティブ比',
          },
        ])
      );

      state.numOfficialCurriculumSuspendedUsers = state.dataChangesInUsageOfOfficialCurriculumColumn
        .filter(({ name }) => name === '利用停止')
        .reduce((sum, val) => sum + val.value, 0);
      stopLoading(state);
    });

    builder.addCase(getDataSalesInformation.fulfilled, (state, action) => {
      const twelveMonthsAgo = dayjs().subtract(11, 'months').format('YYYYMM');
      const currentMonth = dayjs().format('YYYYMM');

      // Filter data from to 12 months ago to current month
      const filteredItems = action.payload.items.filter(
        (item) => item.target_month >= twelveMonthsAgo && item.target_month <= currentMonth
      );

      const groupArray = chain(action.payload.items)
        .groupBy('target_month')
        .map((value, key) => [
          {
            time: key,
            value: value
              .filter(({ sales_type }) => sales_type === '月額利用料')
              .reduce((sum, value) => sum + toNumber(value.monthly_sales), 0),
            type: 'ユーザー利用料',
          },
          {
            time: key,
            value: value
              .filter(({ sales_type }) => sales_type === 'ストレージ利用料')
              .reduce((sum, value) => sum + toNumber(value.monthly_sales), 0),
            type: 'ストレージ利用料',
          },
          {
            time: key,
            value: value
              .filter(({ sales_type }) => sales_type === 'スキルチェック利用料')
              .reduce((sum, value) => sum + toNumber(value.monthly_sales), 0),
            type: 'スキルチェック利用料',
          },
          {
            time: key,
            value: value
              .filter(({ sales_type }) => sales_type === 'ALL')
              .reduce((sum, value) => sum + toNumber(value.monthly_sales), 0),
            type: '合計売上',
          },
        ])
        .orderBy('target_month')
        .value();

      state.dataSalesTrendsDual = flatMap(groupArray);
      state.dataCountUser =
        chain(
          action.payload.items.filter(
            (item) => item.target_month === currentMonth && item.sales_type === 'ALL'
          )
        )
          .groupBy('target_month')
          .map((value) => ({
            value: value.reduce((sum, value) => sum + toNumber(value.monthly_users), 0),
          }))
          .value()?.[0]?.value || 0;

      const groupSalesTrendsLine = flatMap(groupArray).filter(({ type }) => type === '合計売上');

      state.dataSalesTrendsLine = groupSalesTrendsLine.map(({ time, value }) => {
        const lastYear = groupSalesTrendsLine.find(
          (item) => item.time === dayjs(time, 'YYYYMM').subtract(1, 'year').format('YYYYMM')
        );
        return {
          time: time,
          count: lastYear ? Math.ceil((value / (lastYear?.value || 1)) * 10000) / 100 : 0,
          name: '前年比',
        };
      });
    });
    builder.addCase(getDataUsageList.fulfilled, (state, action) => {
      state.dataUsageList = action.payload.report_results.map((item, index) => {
        const usage_storage = (item.usage_storage || 10000) / Math.pow(1024, 3);
        const usage_storage_unit_price = item.usage_storage_unit_price || 0;
        const user_fee_storage = usage_storage
          ? Math.max(50, Math.ceil(usage_storage / 50) * usage_storage_unit_price)
          : 0;
        const user_fee = Number(item.max_users || 0) * Number(item.user_monthly_fee || 0);
        const inter_skill_check_count = item.inter_skill_check_count || 0;
        const emp_skill_check_count = item.emp_skill_check_count || 0;
        const unit_price = item.skill_check_unit_price || 0;

        return {
          index,
          i_id: item.i_id,
          contract_date: item.contract_date,
          max_users: item.max_users,
          unit_price_user: item.user_monthly_fee,
          usage_storage,
          user_fee_storage,
          user_fee,
          skill_check_usage_count: emp_skill_check_count + inter_skill_check_count,
          unit_price,
          skill_check_usage_fee: (emp_skill_check_count + inter_skill_check_count) * unit_price,
          total_usage_fee:
            user_fee +
            (emp_skill_check_count + inter_skill_check_count) * unit_price +
            user_fee_storage,
          inter_skill_check_count,
          usage_storage_unit_price,
        };
      });
    });
    builder
      .addCase(getDataChangesInUsageOfOfficialCurriculumChurnRate.rejected, stopLoading)
      .addCase(getDataChangesInUsageOfOfficialCurriculum.rejected, stopLoading)
      .addCase(getDataNumOfficialCurriculumCountOfStops.rejected, stopLoading)
      .addCase(getDataNumOfNewUsersMonth.rejected, stopLoading)
      .addCase(getDataNumOfficialCurriculumUsersActive.rejected, stopLoading)
      .addCase(getDataNumOfficialCurriculumUsers.rejected, stopLoading)
      .addCase(getDataNumOfSkillChecksPerformedMonth.rejected, stopLoading)
      .addCase(getNumberOfInterviewsConducted.rejected, stopLoading)
      .addCase(getDataNumOfSkillChecksPerformed.rejected, stopLoading)
      .addCase(getDataSkillCheckTrans.rejected, stopLoading)
      .addCase(getDataAggregationChurnRate.rejected, stopLoading)
      .addCase(getDataNumberOfPartnerCompanies.rejected, stopLoading)
      .addCase(getDataNumberOfCorporateUsers.rejected, stopLoading)
      .addCase(getDataAggregationChurnRateExport.rejected, stopLoading)
      .addCase(getDataNumberOfIndividualUsers.rejected, stopLoading)
      .addCase(getUserCount.rejected, stopLoading);
  },
});

// export const {} = dashboardSlice.actions;

export default dashboardSlice.reducer;
