import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F9F8F8',
    fontFamily: 'NotosanJP',
  },

  header: {
    position: 'absolute',
    top: 10,
    width: '100%',
    borderBottom: '1px solid #dddddd',
  },

  textHeader: {
    paddingLeft: 11.7,
    fontSize: 10.5,
    color: '#424242',
    paddingBottom: 10,
  },

  body: {
    paddingHorizontal: 11.7,
    paddingVertical: 5.6,
    flexDirection: 'column',
    gap: 10,
  },

  subscription: {
    marginTop: 30,
    paddingVertical: 17.54,
    paddingHorizontal: 11.7,
  },

  textSubscription: {
    fontSize: 7,
    color: '#424242',
  },

  lineBorder: {
    width: 24,
    height: 1,
    backgroundColor: '#dddddd',
    marginLeft: 11.7,
  },

  textCountGeneral: {
    flexDirection: 'row',
    gap: 2,
    alignItems: 'flex-end',
  },

  textCountNumber: {
    fontSize: 8.186,
    color: '#424242',
  },

  textCountDescription: {
    fontSize: 6.432,
    color: '#424242',
  },

  table: {
    flexDirection: 'column',
    textAlign: 'left',
    width: '100%',
    backgroundColor: '#F9F8F8',
  },

  tableHeaderRow: {
    backgroundColor: '#ebebeb',
  },

  tableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderLeft: '1px solid #e5e5e5',
    borderRight: '1px solid #e5e5e5',
    backgroundColor: '#fff',
  },

  wrapCell: {
    padding: 5,
    flexGrow: 1,
    flexBasis: 'auto',
  },

  tableCell: {
    textAlign: 'left',
    fontSize: 7.6,
    fontColor: '#424242',
  },

  headerCell: {
    color: '#2a2a2a',
    fontSize: 7.017,
    fontWeight: 'bold',
  },
  pagePadding: {
    paddingHorizontal: 11.7,
    paddingTop: 11.7,
    paddingBottom: 24,
  },
  divider: {
    width: 35,
    height: 1,
    marginLeft: 11.7,
    marginBottom: 11.7,
    backgroundColor: '#DDDDDD',
  },
});
