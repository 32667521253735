import React, { useEffect } from 'react';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { generatePath, Link } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import dayjs from 'dayjs';

import { isFirstLogin, signIn, updateLoginTime } from 'containers/Auth/thunk';
import { Input, InputPassword, Checkbox } from 'components';
import AuthLayout from 'containers/Auth/Layout';
import { logout } from 'containers/Auth/slice';
import { loginSchema } from 'libs/validations';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import SectionStyled from './styles';
import { LoginFormik } from 'types';
import { LogoAdmin } from 'assets';
import { DISPLAY_ID_KEY } from 'configs';
import { browserLogger } from 'libs/logger';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();

  const formik = useFormik<LoginFormik>({
    initialValues: {
      email: '',
      password: '',
      save: true,
    },
    validationSchema: loginSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const resultAction = await dispatch(
        signIn({
          user_code: values.email,
          password: values.password,
          exclusive_w_id: DISPLAY_ID_KEY.exclusive_w_id.name,
        })
      );
      browserLogger.info(
        'pages/Login/index.tsx Login',
        DISPLAY_ID_KEY.exclusive_w_id.name,
        resultAction
      );
      if (signIn.fulfilled.match(resultAction)) {
        localStorage.setItem('email', values.email);
        localStorage.setItem('remember_me', values.save ? 'saved' : 'off');
        const resultActionIsFirstLogin = await dispatch(
          isFirstLogin({
            conditions: [
              {
                id: 'email',
                search_value: [values.email],
                exact_match: true,
              },
            ],
            include_lookups: true,
            include_links: true,
            page: 1,
            per_page: 1,
          })
        );
        if (
          isFirstLogin.fulfilled.match(resultActionIsFirstLogin) &&
          resultActionIsFirstLogin.payload.totalItems
        ) {
          if (resultActionIsFirstLogin.payload.items[0].company_id !== '000000001') {
            dispatch(logout());
            return;
          }

          await dispatch(
            updateLoginTime({
              id: resultActionIsFirstLogin.payload.items[0].i_id,
              data: {
                item: {
                  last_login_at: dayjs().toISOString(),
                },
                is_force_update: true,
              },
            })
          );
        }
      } else {
        formik.setErrors({
          email: '',
          password: 'メールアドレスまたはパスワードが正しくありません',
        });
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue('email', localStorage.getItem('email') || '');
    formik.setFieldValue('save', localStorage.getItem('remember_me') !== 'off');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout>
      <SectionStyled>
        <img src={LogoAdmin} alt="logo" className="logo" />
        <FormikProvider value={formik}>
          <Form className="form" autoComplete={formik.values.save ? 'on' : 'new-email'}>
            <Form.Item name="email">
              <Input
                name="email"
                type="email"
                placeholder="メールアドレス"
                className="input"
                autoComplete={formik.values.save ? 'on' : 'new-email'}
                prefix={<MailOutlined className="icon" />}
              />
            </Form.Item>
            <Form.Item name="password">
              <InputPassword
                className="input"
                name="password"
                placeholder="パスワード"
                autoComplete={formik.values.save ? 'on' : 'new-password'}
                prefix={<LockOutlined className="icon" />}
              />
            </Form.Item>
            <Form.Item name="save">
              <Checkbox name="save" className="checkbox" checked={formik.values.save}>
                ログイン情報を保存する
              </Checkbox>
            </Form.Item>
            <SubmitButton className="btn-submit">ログイン</SubmitButton>
          </Form>
        </FormikProvider>
        <div className="wrap-bottom">
          <Link
            className="text-link"
            to={generatePath(routes.ResetPassword.path, { entity: 'receiving' })}
          >
            パスワードの再発行はこちら
          </Link>
        </div>
      </SectionStyled>
    </AuthLayout>
  );
};

export default Login;
