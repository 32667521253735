import { createAsyncThunk } from '@reduxjs/toolkit';

import { AFFILIATION_LEVEL, AFFILIATION_ASSIGN_ROLE } from 'configs';
import { browserLogger } from 'libs/logger';
import { services } from 'services';
import * as Types from 'types';

export const createAdminAffiliationLevel = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AffiliationLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AffiliationMaster/thunk/createAdminAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(AFFILIATION_LEVEL.name, req);
    browserLogger.info(
      'AffiliationMaster/thunk/createAdminAffiliationLevel',
      AFFILIATION_LEVEL.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAdminAffiliationLevel = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AffiliationMaster/thunk/thunk/deleteAdminAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(AFFILIATION_LEVEL.name, req);
    browserLogger.info(
      'AffiliationMaster/thunk/thunk/deleteAdminAffiliationLevel',
      AFFILIATION_LEVEL.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAdminAffiliationLevel = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AffiliationLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AffiliationMaster/thunk/updateAdminAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(AFFILIATION_LEVEL.name, req);
    browserLogger.info(
      'AffiliationMaster/thunk/updateAdminAffiliationLevel',
      AFFILIATION_LEVEL.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAdminAffiliationLevel = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AffiliationMaster/thunk/getDataAdminAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(AFFILIATION_LEVEL.name, req);
    browserLogger.info(
      'AffiliationMaster/thunk/getDataAdminAffiliationLevel',
      AFFILIATION_LEVEL.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAffiliationAssignRole = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AffiliationMaster/thunk/getAffiliationAssignRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(AFFILIATION_ASSIGN_ROLE.name, req);
    browserLogger.info(
      'AffiliationMaster/thunk/getAffiliationAssignRole',
      AFFILIATION_ASSIGN_ROLE.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
