import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { publicManagerSelector } from 'pages/PublicManagement/selectors';
import { settingSelector } from 'containers/AppSettings/selectors';
import { getPresignedUrlByFileID } from 'services/r2Service';
import * as Types from 'types';

export interface ReceivedProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detail: Types.OfficialCurriculumMaster.ResponseType;
  creator: string;
}

const useCurriculumContent = (props: ReceivedProps) => {
  const { setIsOpen, detail, creator } = props;

  const [isOpenReleaseNote, setIsOpenReleaseNote] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  const { officialCurriculumMaster, providerInformation } = useSelector(publicManagerSelector);
  const { loading } = useSelector(settingSelector);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const getImageUrl = useCallback(async () => {
    if (!detail?.fileID) {
      setUrl('');
      return;
    }

    const urlDetail = (await getPresignedUrlByFileID(detail?.fileID)) as string;
    setUrl(urlDetail);
  }, [detail]);

  useEffect(() => {
    getImageUrl();
    return () => setUrl('');
  }, [getImageUrl]);

  return {
    ...props,
    handleClose,
    dataDetail: { ...detail, fileID: url },
    creator,
    isOpenReleaseNote,
    setIsOpenReleaseNote,
    officialCurriculumMaster,
    providerInformation,
    loading,
  };
};

export type Props = ReturnType<typeof useCurriculumContent>;

export default useCurriculumContent;
