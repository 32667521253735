import React, { useEffect, useMemo, useRef, useState } from 'react';
import type { SubMenuType } from 'rc-menu/lib/interface';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useBeforeUnload, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  MenuFoldOutlined,
  PayCircleOutlined,
  ReadOutlined,
  UserOutlined,
  GlobalOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import MenuUnfoldOutlined from 'components/SideBar/MenuUnfoldOutlined';
import { CoporateIcon, IconStorage, LogoIcon, LogoSmall, PartnerIcon, PersonalIcon } from 'assets';
import { setCollapsed, setHeaderTitle } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import AlertCustom, { alertCustomRef } from 'components/Alert';
import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';
import Loading, { loadingRef } from 'components/Loading';
import { authSelector } from 'containers/Auth/selectors';
import { Wrapper, StyleMenuTooltip, MenuTooltip } from './styles';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import ModalAlert from 'components/Modal/Alert';
import useModifiedPage from 'hooks/useModifiedPage';

const { Sider } = Layout;

type Props = {
  accessDenied?: boolean;
  title: string;
};

const SideBar: React.FC<Props> = ({ children, accessDenied, title }) => {
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const l = useLocation();
  const { collapsedMenu, hiddenSidebar, loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const [visible, setVisible] = useState<boolean>(false);
  const [currentPath, setCurrentPath] = useState<string | null>(null);
  const { isModified, setModifiedPage } = useModifiedPage();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const topRef = useRef<HTMLDivElement>(null);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };
  const location = window.location.pathname;
  const toggle = () => dispatch(setCollapsed(!collapsedMenu));

  const roleCode = useMemo(() => userInfo?.lookup_items?.role_code, [userInfo]);

  const checkPermission = (permission: number | string | undefined) =>
    permission && permission.toString() !== '0';
  const navigateAndDispatchReset = (path: string, checkChange = true) => {
    if (isModified && checkChange) {
      setVisible(true);
      setCurrentPath(path);
      return;
    }
    navigate(path);
  };

  useEffect(() => {
    setModifiedPage(false);
  }, [l]);

  useBeforeUnload((event) => {
    if (isModified) {
      event.preventDefault();
      return '';
    }
  });

  const handleNavigate = () => {
    if (currentPath) {
      setCurrentPath(null);
      setModifiedPage(false);
      navigateAndDispatchReset(currentPath, false);
    }
  };

  const menuTooltipCurriculum = (
    <StyleMenuTooltip>
      <p className="title">カリキュラム管理</p>
      <p
        className={`${
          [routes.CurriculumMaster.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.CurriculumMaster.path)}
      >
        - カリキュラムマスタ
      </p>
      <p
        className={`${
          [routes.QuestionMasterPage.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.QuestionMasterPage.path)}
      >
        - 設問マスタ
      </p>
    </StyleMenuTooltip>
  );

  const menuTooltipUser = (
    <StyleMenuTooltip>
      <p className="title">ユーザー管理</p>
      <p
        className={`${
          [routes.UserMaster.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.UserMaster.path)}
      >
        - 社内ユーザーマスタ
      </p>
      <p
        className={`${
          [routes.AuthorityMaster.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.AuthorityMaster.path)}
      >
        - 権限マスタ
      </p>
      <p
        className={`${
          [routes.AffiliationMaster.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.AffiliationMaster.path)}
      >
        - 所属マスタ
      </p>
      <p
        className={`${
          [routes.JobTitleMaster.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.JobTitleMaster.path)}
      >
        - 役職マスタ
      </p>
      <p
        className={`${
          [routes.AdministratorMaster.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.AdministratorMaster.path)}
      >
        - 管理者マスタ
      </p>
    </StyleMenuTooltip>
  );

  const menuTooltipTraining = (
    <StyleMenuTooltip>
      <p className="title">パートナー管理</p>
      <p
        className={`${
          [routes.PartnerManagement.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.PartnerManagement.path)}
      >
        - パートナー管理
      </p>
      <p
        className={`${
          [routes.OfficialCurriculumList.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.OfficialCurriculumList.path)}
      >
        - カリキュラム一覧
      </p>
      <p
        className={`${
          [routes.CurriculumTree.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.CurriculumTree.path)}
      >
        - カリキュラムツリー
      </p>
    </StyleMenuTooltip>
  );

  const menuTooltipSkill = (
    <StyleMenuTooltip>
      <p className="title">法人ユーザー管理</p>
      <p
        className={`${
          [routes.CorporateUserInformationList.path].includes(location)
            ? 'sub-menu-active'
            : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.CorporateUserInformationList.path)}
      >
        - 法人ユーザー情報一覧
      </p>
      <p
        className={`${
          [routes.InvoiceCorrespondence.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.InvoiceCorrespondence.path)}
      >
        - 請求書対応
      </p>
      <p
        className={`${
          [routes.FreeTrialManagement.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.FreeTrialManagement.path)}
      >
        - 無料トライアル管理
      </p>
    </StyleMenuTooltip>
  );

  const trainingCurriculumItems = [
    ...(checkPermission(roleCode?.curricullum_master_permission)
      ? [
          {
            key: routes.CurriculumMaster.path,
            label: '- 　カリキュラムマスタ',
            onClick: () => navigateAndDispatchReset(routes.CurriculumMaster.path),
          },
        ]
      : []),
    ...(checkPermission(roleCode?.question_master_permission)
      ? [
          {
            key: routes.QuestionMasterPage.path,
            label: '- 　設問マスタ',
            onClick: () => navigateAndDispatchReset(routes.QuestionMasterPage.path),
          },
        ]
      : []),
  ];
  const userItems = [
    ...(checkPermission(roleCode?.users_master_permission)
      ? [
          {
            key: routes.UserMaster.path,
            label: '-   社内ユーザーマスタ',
            onClick: () => navigateAndDispatchReset(routes.UserMaster.path),
          },
        ]
      : []),
    ...(checkPermission(roleCode?.roles_master_permission)
      ? [
          {
            key: routes.AuthorityMaster.path,
            label: '-   権限マスタ',
            onClick: () => navigateAndDispatchReset(routes.AuthorityMaster.path),
          },
        ]
      : []),
    ...(checkPermission(roleCode?.departments_master_permission)
      ? [
          {
            key: routes.AffiliationMaster.path,
            label: '-   所属マスタ',
            onClick: () => navigateAndDispatchReset(routes.AffiliationMaster.path),
          },
        ]
      : []),
    ...(checkPermission(roleCode?.positions_master_permission)
      ? [
          {
            key: routes.JobTitleMaster.path,
            label: '-   役職マスタ',
            onClick: () => navigateAndDispatchReset(routes.JobTitleMaster.path),
          },
        ]
      : []),
    ...(checkPermission(roleCode?.admin_master_permission)
      ? [
          {
            key: routes.AdministratorMaster.path,
            label: '-   管理者マスタ',
            onClick: () => navigateAndDispatchReset(routes.AdministratorMaster.path),
          },
        ]
      : []),
  ];
  const trainingItems = [
    ...(checkPermission(roleCode?.partner_management_permission)
      ? [
          {
            label: '-   　パートナー管理',
            key: routes.PartnerManagement.path,
            onClick: () => navigateAndDispatchReset(routes.PartnerManagement.path),
          },
        ]
      : []),
    ...(checkPermission(roleCode?.curriculum_tree_permission)
      ? [
          {
            label: '-   　カリキュラム一覧',
            key: routes.OfficialCurriculumList.path,
            onClick: () => navigateAndDispatchReset(routes.OfficialCurriculumList.path),
          },
        ]
      : []),
    ...(checkPermission(roleCode?.curriculum_tree_permission)
      ? [
          {
            label: '-   　カリキュラムツリー',
            key: routes.CurriculumTree.path,
            onClick: () => navigateAndDispatchReset(routes.CurriculumTree.path),
          },
        ]
      : []),
  ];
  const skillCheckItems = [
    ...(checkPermission(roleCode?.corporate_user_permission)
      ? [
          {
            label: '- 法人ユーザー情報一覧',
            key: routes.CorporateUserInformationList.path,
            onClick: () => navigateAndDispatchReset(routes.CorporateUserInformationList.path),
          },
        ]
      : []),
    ...(checkPermission(roleCode?.invoice_correspondence_permission)
      ? [
          {
            label: '- 請求書対応管理',
            key: routes.InvoiceCorrespondence.path,
            onClick: () => navigateAndDispatchReset(routes.InvoiceCorrespondence.path),
          },
        ]
      : []),
    ...(checkPermission(roleCode?.corporate_user_permission)
      ? [
          {
            label: '- 無料トライアル管理',
            key: routes.FreeTrialManagement.path,
            onClick: () => navigateAndDispatchReset(routes.FreeTrialManagement.path),
          },
        ]
      : []),
  ];

  const menuTooltipHome = (titleHome: string, routesTitle: string) => (
    <MenuTooltip>
      <div onClick={() => navigateAndDispatchReset(routesTitle)}>{titleHome}</div>
    </MenuTooltip>
  );

  const items: ItemType[] = [
    ...(checkPermission(roleCode?.admin_dashboard_permission)
      ? [
          {
            key: routes.Dashboard.path,
            icon: <HomeOutlined className="icon-sidebar-home" />,
            label: !collapsedMenu ? (
              <span className="title-submenu">HOME</span>
            ) : (
              menuTooltipHome('HOME', routes.Dashboard.path)
            ),
            onClick: () => navigateAndDispatchReset(routes.Dashboard.path),
          },
        ]
      : []),
    ...(checkPermission(roleCode?.official_curriculum_report_permission) ||
    checkPermission(roleCode?.sales_report_permission) ||
    checkPermission(roleCode?.skill_check_implementation_report_permission) ||
    checkPermission(roleCode?.user_report_permission)
      ? [
          {
            key: routes.Report.path,
            icon: <BarChartOutlined />,
            label: !collapsedMenu ? (
              <span className="title-submenu">レポート</span>
            ) : (
              menuTooltipHome('レポート', routes.Report.path)
            ),
            onClick: () => navigateAndDispatchReset(routes.Report.path),
          },
        ]
      : []),
    ...(checkPermission(roleCode?.billing_management_permission)
      ? [
          {
            key: routes.BillingDataManagement.path,
            icon: <PayCircleOutlined />,
            label: !collapsedMenu ? (
              <span className="title-submenu">請求データ管理</span>
            ) : (
              menuTooltipHome('請求データ管理', routes.BillingDataManagement.path)
            ),
            onClick: () => navigateAndDispatchReset(routes.BillingDataManagement.path),
          },
        ]
      : []),
    ...(checkPermission(roleCode?.official_curriculum_publish_permission)
      ? [
          {
            key: routes.PublicManagement.path,
            icon: <GlobalOutlined />,
            label: !collapsedMenu ? (
              <span className="title-submenu">公開管理</span>
            ) : (
              menuTooltipHome('公開管理', routes.PublicManagement.path)
            ),
            onClick: () => navigateAndDispatchReset(routes.PublicManagement.path),
          },
        ]
      : []),
    ...(trainingCurriculumItems.length
      ? [
          {
            key: 'TrainingCurriculum',
            icon: <ReadOutlined />,
            className: collapsedMenu
              ? [routes.CurriculumMaster.path, routes.QuestionMasterPage.path].includes(location)
                ? 'ant-menu-item-selected'
                : ''
              : '',
            label: collapsedMenu ? (
              menuTooltipCurriculum
            ) : (
              <span className="title-submenu">カリキュラム管理</span>
            ),
            children: !collapsedMenu ? trainingCurriculumItems : undefined,
          },
        ]
      : []),
    ...(userItems.length
      ? [
          {
            key: 'User',
            icon: <UserOutlined />,
            label: collapsedMenu ? menuTooltipUser : 'ユーザー管理',
            className: collapsedMenu
              ? [
                  routes.UserMaster.path,
                  routes.AuthorityMaster.path,
                  routes.AffiliationMaster.path,
                  routes.JobTitleMaster.path,
                  routes.AdministratorMaster.path,
                ].includes(location)
                ? 'ant-menu-item-selected'
                : ''
              : '',
            children: !collapsedMenu ? userItems : undefined,
          },
        ]
      : []),
    ...(checkPermission(roleCode?.admin_storage_permission)
      ? [
          {
            key: routes.StorageManagement.path,
            icon: (
              <div className="item-icon item-icon-bottom">
                <img src={IconStorage} className="icon" alt="storage" />
              </div>
            ),
            label: !collapsedMenu ? (
              <span className="title-submenu">ストレージ管理</span>
            ) : (
              menuTooltipHome('ストレージ管理', routes.StorageManagement.path)
            ),
            onClick: () => navigateAndDispatchReset(routes.StorageManagement.path),
          },
        ]
      : []),
    ...(trainingItems.length ||
    skillCheckItems.length ||
    checkPermission(roleCode?.individual_user_management_permission)
      ? [
          {
            type: 'divider',
            style: {
              marginTop: 20,
              marginBottom: 20,
              borderWidth: '1px 0 0',
              borderColor: 'rgba(255, 255, 255, 0.25)',
              transition: 'margin-bottom 0.3s',
            },
          },
        ]
      : []),
    ...(trainingItems.length
      ? [
          {
            key: 'training',
            label: collapsedMenu ? menuTooltipTraining : 'パートナー管理',
            icon: (
              <div className="item-icon item-icon-bottom">
                <img src={PartnerIcon} className="icon" alt="storage" />
              </div>
            ),
            className: collapsedMenu
              ? [
                  routes.PartnerManagement.path,
                  routes.OfficialCurriculumList.path,
                  routes.CurriculumTree.path,
                ].includes(location)
                ? 'ant-menu-item-selected'
                : ''
              : '',
            children: !collapsedMenu ? trainingItems : undefined,
          },
        ]
      : []),
    ...(skillCheckItems.length
      ? [
          {
            key: 'skill-check',
            label: collapsedMenu ? menuTooltipSkill : '法人ユーザー管理',
            icon: (
              <div className="item-icon item-icon-bottom">
                <img src={CoporateIcon} className="icon" alt="personal" />
              </div>
            ),
            className: collapsedMenu
              ? [
                  routes.CorporateUserInformationList.path,
                  routes.InvoiceCorrespondence.path,
                  routes.FreeTrialManagement.path,
                ].includes(location)
                ? 'ant-menu-item-selected'
                : ''
              : '',
            children: !collapsedMenu ? skillCheckItems : undefined,
          },
        ]
      : []),
    ...(checkPermission(roleCode?.individual_user_management_permission)
      ? [
          {
            key: routes.IndividualUserManagement.path,
            label: !collapsedMenu ? (
              <span className="title-submenu">個人ユーザー管理</span>
            ) : (
              menuTooltipHome('個人ユーザー管理', routes.IndividualUserManagement.path)
            ),
            icon: (
              <div className="item-icon item-icon-bottom">
                <img src={PersonalIcon} className="icon" alt="personal" />
              </div>
            ),
            onClick: () => navigateAndDispatchReset(routes.IndividualUserManagement.path),
          },
        ]
      : []),
  ] as ItemType[];

  useEffect(() => {
    const openKey = items.find((item) =>
      (item as SubMenuType).children?.some(
        (c) => (c as SubMenuType).key === `/${window.location.pathname.split('/')[1]}`
      )
    )?.key as string;
    setOpenKeys(openKey ? [openKey] : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsedMenu]);

  useEffect(() => {
    dispatch(setHeaderTitle(title));
  }, [dispatch, title]);

  useEffect(() => {
    loadingRef.current?.isLoading(accessDenied ? false : loading);
  }, [accessDenied, loading]);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView();
    }
  }, [location, topRef.current]);

  return (
    <Wrapper collapseMenu={collapsedMenu}>
      <Sider
        width={MENU_WIDTH}
        className="sider"
        trigger={null}
        collapsedWidth={MENU_COLLAPSED_WIDTH}
        collapsible
        collapsed={collapsedMenu}
        hidden={hiddenSidebar}
      >
        <div className="site-layout-background">
          {collapsedMenu ? (
            <MenuUnfoldOutlined toggle={toggle} />
          ) : (
            <MenuFoldOutlined className="trigger" onClick={toggle} />
          )}
        </div>
        <div className="wrap-logo">
          <div className="item-logo">
            <img
              src={collapsedMenu ? LogoSmall : LogoIcon}
              className={collapsedMenu ? 'image-logo' : 'image-logo-large'}
              onClick={() => navigateAndDispatchReset(routes.Dashboard.path)}
              alt="skill-familiar"
            />
            {!collapsedMenu && <span className="text">管理コンソール</span>}
          </div>
          {collapsedMenu && (
            <div className="icon-text">
              管理 <br />
              コンソール
            </div>
          )}
        </div>
        <Menu
          mode="inline"
          openKeys={openKeys}
          selectedKeys={[window.location.pathname, `/${window.location.pathname.split('/')[1]}`]}
          onOpenChange={onOpenChange}
          items={items}
          inlineIndent={18}
          className="menu"
        />
      </Sider>
      <Loading ref={loadingRef} />
      <Layout>
        <div ref={topRef}>{children}</div>
        <AlertCustom ref={alertCustomRef} />
        <ModalAlert visible={visible} setVisible={setVisible} onSubmit={handleNavigate} />
      </Layout>
    </Wrapper>
  );
};

export default SideBar;
