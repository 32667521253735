import styled from 'styled-components';

export default styled('div')`
  text-align: center;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    height: 28px;
  }
  .wrap-title {
    display: flex;
  }
  .text {
    font-size: 18px;
    margin-left: 12px;
  }
  .description {
    font-size: 13px;
    color: #424242;
  }
`;
