import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  chunk,
  filter,
  isEmpty,
  isNil,
  keyBy,
  maxBy,
  omitBy,
  padEnd,
  split,
  uniq,
  uniqBy,
} from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Select } from 'antd';
import {
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretUpOutlined,
  CheckOutlined,
  CloudDownloadOutlined,
  FilterOutlined,
  PlusOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

import { MemorizeCustomDragLayer } from 'containers/Curriculum/NodeTreeView/NodeRenderer/CustomDragLayer';
import { createLinkQuestionAssignLevel } from 'containers/CreateEditQuestion/thunk';
import { LIST_LABEL, LIST_TAB_BAR, MAX_REQUESTS_PER_TIMES } from 'constant';
import { CurriculumStatus } from 'constant/enum.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import CreateQuestion from 'pages/QuestionMaster/Modal/CreateQuestion';
import { MemorizedTreview } from 'containers/Curriculum/NodeTreeView';
import { ItemMoveCopySelectedType } from 'types/services/curriculum';
import { settingSelector } from 'containers/AppSettings/selectors';
import { uploadCurriculumCSVSchema } from 'libs/validations';
import SearchCurriculum from 'containers/Curriculum/Search';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { ErrorBoundary, Header } from 'components';
import { curriculumSelector } from '../selectors';
import { exportPDF2 } from 'libs/utils/exportPDF';
import { exportCsv } from 'libs/utils/exportCsv';
import SpinLoading from 'components/SpinLoading';
import { CreateEditCurriculum } from '../Modal';
import ImportButton from 'components/Buttons';
import UploadCSV from 'components/UploadCSV';
import FileExportPDF from '../FileExportPDF';
import { routes } from 'navigations/routes';
import Wrapper, { Button } from './styles';
import { getNameCurriculum } from 'libs';
import { useAppDispatch, usePermission } from 'hooks';
import { usePagination } from './hook';
import { SortByDesc } from 'assets';
import * as Types from 'types';
import { AnyObject } from 'types';
import {
  clearFilterConditions,
  removeNodeLevel4Selected,
  setConditions,
  setFilterByCurriculum,
  setFilterByStatus,
} from '../slice';
import {
  createCurriculum,
  createLevelCurriculum,
  curriculumExportDataCSV,
  curriculumExportDataCSV2,
  getDataCurriculum,
  getDataQues,
} from '../thunk';
import { setCollapsed } from 'containers/AppSettings/slice';
import {
  HEADER_CURRICULUM_CSV,
  HEADER_IMPORT_CURRICULUM_CSV,
} from 'constant/header.export.constant';
import {
  memoizedConvertFlatDataFromImportFile,
  memoizedGetFlatDataFromTree,
} from 'libs/utils/curriculum/memoized-tree-data-utils';

const { Option } = Select;

type Props = {
  setOpenCurriculumMaster: React.Dispatch<React.SetStateAction<boolean>>;
  fetchDataCurriculum: () => Promise<void>;
};

const Treeview: React.FC<Props> = ({ fetchDataCurriculum, setOpenCurriculumMaster }) => {
  const [itemMoveCopySelected, setItemMoveCopySelected] = useState<ItemMoveCopySelectedType>();
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [showConfirmImportFileModal, setShowConfirmImportFileModal] = useState<boolean>(false);
  const [openModalCreateCurriculum, setOpenModalCreateCurriculum] = useState<boolean>(false);
  const [statusSelected, setStatusSelected] = useState<keyof typeof CurriculumStatus>();
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [curriculumSelected, setCurriculumSelected] = useState<string>();
  const [columnClosed, setColumnClosed] = useState<number | undefined>();
  const [isOpenMenuRight, setOpenMenuRight] = useState<boolean>(false);
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);
  const [pageYOffset, setPageYOffset] = useState<number>(0);
  const [tabActive, setTabActive] = useState<number>(0);
  const [openModalCreateQuestion, setOpenModalCreateQuestion] = useState<{
    type: 'create' | 'edit';
    visible: boolean;
  }>({ visible: false, type: 'create' });

  const dashboardRef = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);

  const { dataCurriculumTree, dataCurriculumTreeFilleted, nodeLevel4Selected, conditions } =
    useSelector(curriculumSelector);
  const { headerTitle, collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const ref = useRef(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { permissionNumber } = usePermission();

  const { items, hasMore, handleLoadMore } = usePagination({ data: dataCurriculumTreeFilleted });
  const location = useLocation();

  const selectedRowName = useMemo(() => {
    const name = location?.state?.selectedRow[0]?.name ?? '';

    return name as string;
  }, [location?.state?.selectedRow]);

  const handleScroll = () => {
    if (window.pageYOffset >= 56) {
      setPageYOffset(56);
    } else {
      setPageYOffset(window.pageYOffset);
    }
  };

  const handleExportCSV = async (value: string) => {
    const filename = 'カリキュラムマスタ（通常モード）';
    if (value === 'csv') {
      const newcurricullum = dataCurriculumTree.map((item, index) => ({
        ...item,
        serial: index + 1,
      }));
      const resultAction = await dispatch(
        curriculumExportDataCSV2({
          conditions: [
            {
              id: 'curriculum_name',
              search_value: [items[0].name],
            },
          ],
          include_lookups: true,
          page: 1,
          per_page: 0,
        })
      );
      if (curriculumExportDataCSV2.fulfilled.match(resultAction)) {
        // const dataPrimitive = memoizedGetFlatDataFromTree({
        //   treeData: newcurricullum[0],
        // }).filter((i: any) => i.columnIndex === 5);
        const keyByCurriculum = keyBy(newcurricullum, 'i_id');
        // const dataPrimitiveKyByCode = keyBy(
        //   dataPrimitive,
        //   (v) => `${v.node.code}-${newcurricullum[0].name}`
        // );
        const listCsv = resultAction.payload.report_results
          .filter((i) => i.i_id === items[0].i_id)
          .map((item: Types.ItemExport) => ({
            flag: item.rev_no,
            curriculum_code: `${item.question_code ?? ''}`,
            curriculum_name: `${item.curriculum_name ?? ''}`,
            curriculum_type:
              keyByCurriculum[item.i_id!]?.required_curriculum === 1
                ? '必修カリキュラム'
                : 'カリキュラム',
            curriculum_status: keyByCurriculum[item.i_id!]?.publish === 1 ? '公開中' : '編集中',
            level1_name: item.level1_name || '(空白）',
            level1_code: `${item.level1_code}`,
            level2_name: item.level2_name || '(空白）',
            level2_code: `${item.level2_code}`,
            level3_name: item.level3_name || '(空白）',
            level3_code: `${item.level3_code}`,
            level4_name: item.level4_name || '(空白）',
            level4_code: `${item.level4_code}`,
            question_name: `${item.question_name ?? ''}`,
            question_code: `${item.question_code ?? ''}`,
            description: `${item.curriculum_description ?? ''}`,
          }));
        exportCsv(listCsv, HEADER_CURRICULUM_CSV, `${filename}.csv`);
      }
    } else {
      exportPDF2(ref, `${filename}.pdf`, 'l', true, undefined, false);
    }
    setShowConfirmExportFileModal(false);
  };

  useEffect(() => {
    if (selectedRowName) {
      setCurriculumSelected(selectedRowName);
      dispatch(
        setFilterByCurriculum({
          name: selectedRowName,
        })
      );
    }
  }, [dispatch, selectedRowName, dataCurriculumTree]);

  useEffect(() => {
    return () => {
      setStatusSelected(undefined);
      setCurriculumSelected(undefined);
    };
  }, []);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  }, [curriculumSelected, statusSelected]);

  useEffect(() => {
    if (nodeLevel4Selected) {
      setOpenMenuRight(true);
    }
  }, [nodeLevel4Selected]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      dispatch(removeNodeLevel4Selected());
      window.removeEventListener('scroll', handleScroll);
      dispatch(clearFilterConditions());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isOpenMenuRight) {
      dispatch(setCollapsed(true));
    }
  }, [dispatch, isOpenMenuRight]);

  useEffect(() => {
    if (!collapsedMenu) {
      setOpenMenuRight(false);
    }
  }, [collapsedMenu]);

  const getCurriculumCode = (index: number) => {
    const subString = Array.from({
      length: 8 - index.toString().length,
    })
      .map(() => '0')
      .concat(index.toString())
      .join('');

    return padEnd('A', 9, subString.toString());
  };

  const hasErrorSameFlag = (item: AnyObject, checkSameFlags: AnyObject) => {
    const errorObj = checkSameFlags[item.same_curriculum_flag];
    return (
      errorObj &&
      (errorObj.hasErrorCurriculumName ||
        errorObj.hasErrorCurriculumType ||
        errorObj.hasErrorCurriculumDescription)
    );
  };

  const handleExtValidateCsv = async (dataImport: AnyObject[]) => {
    const questionCodes = dataImport.map((obj) => obj.question_code).filter((obj) => !!obj);
    const sameFlags = uniq(dataImport.map((obj) => obj.same_curriculum_flag));
    const checkSameFlags: AnyObject = {};
    sameFlags.forEach((flag) => {
      const curriculumList = dataImport.filter((obj) => obj.same_curriculum_flag === flag);
      const hasErrorCurriculumName =
        uniq(curriculumList.map((obj) => obj.curriculum_name)).length > 1;
      const hasErrorCurriculumType =
        uniq(curriculumList.map((obj) => obj.curriculum_type)).length > 1;
      const hasErrorCurriculumDescription =
        uniq(curriculumList.map((obj) => obj.curriculum_description)).length > 1;
      checkSameFlags[flag] = {
        hasErrorCurriculumName,
        hasErrorCurriculumType,
        hasErrorCurriculumDescription,
      };
    });

    let existedQuestionCode: string[] = [];
    if (questionCodes.length) {
      const validateActionResult = await dispatch(
        getDataQues({
          conditions: [
            { id: 'company_id', search_value: [userInfo?.company_id] },
            { id: 'code', search_value: [...questionCodes], exact_match: true },
          ],
          page: 1,
          per_page: 0,
        })
      );
      if (getDataQues.fulfilled.match(validateActionResult) && validateActionResult.payload.items) {
        existedQuestionCode = (validateActionResult.payload.items || []).map((item) => item.code);
      }
    }
    const dataValidate = dataImport.map((obj, idx) => ({ ...obj, idx }));
    const errors = dataValidate
      .filter(
        (obj: AnyObject) =>
          (!!obj.question_code && !existedQuestionCode.includes(obj.question_code)) ||
          hasErrorSameFlag(obj, checkSameFlags)
      )
      .reduce((obj, item: AnyObject) => {
        const errorObj = {
          question_code_error:
            !!item.question_code && !existedQuestionCode.includes(item.question_code)
              ? '入力されたコードは設問マスタに存在しません。'
              : undefined,
          curriculum_name_error:
            checkSameFlags[item.same_curriculum_flag] &&
            checkSameFlags[item.same_curriculum_flag].hasErrorCurriculumName
              ? '同一カリキュラムフラグで設定した数値に対応するカリキュラム名称は同一である必要があります。'
              : undefined,
          curriculum_type_error:
            checkSameFlags[item.same_curriculum_flag] &&
            checkSameFlags[item.same_curriculum_flag].hasErrorCurriculumType
              ? '同一カリキュラムフラグで設定した数値に対応するカリキュラム種別は同一である必要があります。'
              : undefined,
          curriculum_description_error:
            checkSameFlags[item.same_curriculum_flag] &&
            checkSameFlags[item.same_curriculum_flag].hasErrorCurriculumDescription
              ? '同一カリキュラムフラグで設定した数値に対応する説明は同一である必要があります。'
              : undefined,
        };
        const resultError = omitBy(errorObj, isNil);
        return isEmpty(resultError)
          ? { ...obj }
          : {
              ...obj,
              [item.idx]: { ...resultError },
            };
      }, {});
    const warnings = dataValidate
      .filter((obj: AnyObject) => !obj.question_code)
      .reduce(
        (obj, item: AnyObject) => ({
          ...obj,
          [item.idx]: { question_code_warning: '入力されたコードは設問マスタに存在しません。' },
        }),
        {}
      );
    return {
      errors,
      warnings,
    };
  };

  const handleUploadData = (dataImport: AnyObject[]) => {
    const curriculumMaxOrder = maxBy(dataCurriculumTree, 'sort_order')?.sort_order || 0;
    const itemMaxCode = maxBy(dataCurriculumTree, (e) => Number(split(e.code, 'A').join('')));
    const currentCodeIndex = itemMaxCode ? Number(split(itemMaxCode.code, 'A').join('')) : 1;
    const newCurriculum: AnyObject[] = [];
    dataImport.forEach((item, index) => {
      const itemIndex = newCurriculum.findIndex(
        (c) => c.same_curriculum_flag === item.same_curriculum_flag
      );
      if (itemIndex >= 0) {
        newCurriculum[itemIndex] = memoizedConvertFlatDataFromImportFile({
          treeData: newCurriculum[itemIndex],
          item,
        });
      } else {
        newCurriculum.push({
          id: uuidv4(),
          official_curriculum_code: getCurriculumCode(currentCodeIndex + index + 1),
          required_curriculum: item.curriculum_type === '必修カリキュラム' ? '1' : '0',
          same_curriculum_flag: item.same_curriculum_flag,
          name: item.curriculum_name,
          description: item.curriculum_description,
          level: 0,
          fileID: item.fileID,
          path: [],
          sort_order: curriculumMaxOrder ? curriculumMaxOrder + index + 1 : index,
          children: [
            {
              id: uuidv4(),
              name: item.level1_name,
              level: 1,
              path: [item.curriculum_name],
              same_curriculum_flag: item.same_curriculum_flag,
              children: [
                {
                  id: uuidv4(),
                  name: item.level2_name,
                  level: 2,
                  path: [item.curriculum_name, item.level1_name],
                  same_curriculum_flag: item.same_curriculum_flag,
                  children: [
                    {
                      id: uuidv4(),
                      name: item.level3_name,
                      level: 3,
                      path: [item.curriculum_name, item.level1_name, item.level2_name],
                      same_curriculum_flag: item.same_curriculum_flag,
                      children: [
                        {
                          id: uuidv4(),
                          name: item.level4_name,
                          level: 4,
                          path: [
                            item.curriculum_name,
                            item.level1_name,
                            item.level2_name,
                            item.level3_name,
                          ],
                          same_curriculum_flag: item.same_curriculum_flag,
                          children: item.question_code
                            ? [
                                {
                                  code: item.question_code,
                                },
                              ]
                            : [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        });
      }
    });
    const res: AnyObject[] = [];
    newCurriculum.forEach((curr) => {
      if (!curr.children || !curr.children.length) {
        res.push({ ...curr });
      } else {
        curr.children.forEach((lev1: AnyObject) => {
          if (!lev1.children || !lev1.children.length) {
            res.push({ ...curr, children: [{ ...lev1 }] });
          } else {
            lev1.children.forEach((lev2: AnyObject) => {
              if (!lev2.children || !lev2.children.length) {
                res.push({ ...curr, children: [{ ...lev1, children: [{ ...lev2 }] }] });
              } else {
                lev2.children.forEach((lev3: AnyObject) => {
                  if (!lev3.children || !lev3.children.length) {
                    res.push({
                      ...curr,
                      children: [{ ...lev1, children: [{ ...lev2, children: [{ ...lev3 }] }] }],
                    });
                  } else {
                    lev3.children.forEach((lev4: AnyObject) => {
                      const chunks = chunk(lev4.children || [], MAX_REQUESTS_PER_TIMES);
                      if (chunks.length) {
                        chunks.forEach((quests) => {
                          res.push({
                            ...curr,
                            children: [
                              {
                                ...lev1,
                                children: [
                                  {
                                    ...lev2,
                                    children: [
                                      {
                                        ...lev3,
                                        children: [
                                          {
                                            ...lev4,
                                            children: quests,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          });
                        });
                      } else {
                        res.push({
                          ...curr,
                          children: [
                            {
                              ...lev1,
                              children: [
                                {
                                  ...lev2,
                                  children: [
                                    {
                                      ...lev3,
                                      children: [
                                        {
                                          ...lev4,
                                          children: null,
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
    return res;
  };

  const curriculumOption = useMemo(
    () =>
      uniqBy(
        filter(dataCurriculumTreeFilleted, (item) => !!item.name),
        'name'
      ),
    [dataCurriculumTreeFilleted]
  );

  const onSubmitUploadCsv = async (curriculum: AnyObject, extraData?: AnyObject) => {
    const result = extraData ? { ...extraData } : {};
    const curriculumCode = extraData && extraData[curriculum.id];
    let resultActionCreateCurriculum: any = undefined;
    if (!curriculumCode) {
      resultActionCreateCurriculum = await dispatch(
        createCurriculum({
          item: {
            company_id: userInfo?.company_id,
            provider_id: userInfo?.company_id,
            official_curriculum_code: curriculum.official_curriculum_code,
            name: curriculum.name,
            description: curriculum.description,
            sort_order: curriculum.sort_order,
            required_curriculum: curriculum.required_curriculum || 0,
            publish: 1,
            probs_count: 0,
            createdat: new Date(),
            createdby: userInfo?.login_id,
            creator: userInfo?.login_id,
            fileID: curriculum?.fileID,
            archive_flag: 0,
          },
          access_key_updates: {
            roles_to_publish: ['MEMBER'],
          },
          return_item_result: true,
          return_display_id: true,
          realtime_auto_link: true,
        })
      );
    }
    if (
      curriculum.children &&
      (curriculumCode ||
        (resultActionCreateCurriculum &&
          createCurriculum.fulfilled.match(resultActionCreateCurriculum) &&
          (resultActionCreateCurriculum.payload.item['code'] ||
            resultActionCreateCurriculum.payload.item['official_curriculum_code'])))
    ) {
      result[curriculum.id] =
        curriculumCode ||
        resultActionCreateCurriculum?.payload.item['code'] ||
        resultActionCreateCurriculum?.payload.item['official_curriculum_code'];

      await Promise.all(
        curriculum.children.map(async (level_1: AnyObject, level1_index: number) => {
          const level1Code = extraData && extraData[level_1.id];
          let resultActionLevel1: any = undefined;
          if (!level1Code) {
            resultActionLevel1 = await dispatch(
              createLevelCurriculum({
                level: 1,
                item: {
                  company_id: userInfo?.company_id,
                  provider_id: userInfo?.company_id,
                  name: getNameCurriculum(level_1.name),
                  sort_order: level1_index,
                  curricullum_code:
                    curriculumCode || resultActionCreateCurriculum.payload.item['code'],
                  official_curriculum_code:
                    curriculumCode ||
                    resultActionCreateCurriculum.payload.item['official_curriculum_code'],
                },
                access_key_updates: {
                  roles_to_publish: ['MEMBER'],
                },
                return_item_result: true,
                return_display_id: true,
                realtime_auto_link: true,
              })
            );
          }

          if (
            level_1.children &&
            (level1Code ||
              (resultActionLevel1 &&
                createLevelCurriculum.fulfilled.match(resultActionLevel1) &&
                resultActionLevel1.payload.item['code']))
          ) {
            result[level_1.id] = level1Code || resultActionLevel1?.payload.item['code'];
            await Promise.all(
              level_1.children.map(async (level_2: AnyObject, level2_index: number) => {
                const level2Code = extraData && extraData[level_2.id];
                let resultActionLevel2: any = undefined;
                if (!level2Code) {
                  resultActionLevel2 = await dispatch(
                    createLevelCurriculum({
                      level: 2,
                      item: {
                        company_id: userInfo?.company_id,
                        provider_id: userInfo?.company_id,
                        name: getNameCurriculum(level_2.name),
                        sort_order: level2_index,
                        level1_code: level1Code || resultActionLevel1.payload.item['code'],
                        official_curriculum_code:
                          curriculumCode ||
                          resultActionCreateCurriculum.payload.item['official_curriculum_code'],
                      },
                      access_key_updates: {
                        roles_to_publish: ['MEMBER'],
                      },
                      return_item_result: true,
                      return_display_id: true,
                      realtime_auto_link: true,
                    })
                  );
                }
                if (
                  level_2.children &&
                  (level2Code ||
                    (resultActionLevel2 &&
                      createLevelCurriculum.fulfilled.match(resultActionLevel2) &&
                      resultActionLevel2.payload.item['code']))
                ) {
                  result[level_2.id] = level2Code || resultActionLevel2?.payload.item['code'];
                  await Promise.all(
                    level_2.children.map(async (level_3: AnyObject, level3_index: number) => {
                      const level3Code = extraData && extraData[level_3.id];
                      let resultActionLevel3: any = undefined;
                      if (!level3Code) {
                        resultActionLevel3 = await dispatch(
                          createLevelCurriculum({
                            level: 3,
                            item: {
                              company_id: userInfo?.company_id,
                              provider_id: userInfo?.company_id,
                              name: getNameCurriculum(level_3.name),
                              sort_order: level3_index,
                              level2_code: level2Code || resultActionLevel2.payload.item['code'],
                              official_curriculum_code:
                                curriculumCode ||
                                resultActionCreateCurriculum.payload.item[
                                  'official_curriculum_code'
                                ],
                            },
                            access_key_updates: {
                              roles_to_publish: ['MEMBER'],
                            },
                            return_item_result: true,
                            return_display_id: true,
                            realtime_auto_link: true,
                          })
                        );
                      }

                      if (
                        level_3.children &&
                        (level3Code ||
                          (createLevelCurriculum.fulfilled.match(resultActionLevel3) &&
                            resultActionLevel3 &&
                            resultActionLevel3.payload.item['code']))
                      ) {
                        result[level_3.id] = level3Code || resultActionLevel3?.payload.item['code'];
                        await Promise.all(
                          level_3.children.map(async (level_4: AnyObject, level4_index: number) => {
                            const level4Code = extraData && extraData[level_4.id];
                            let resultActionLevel4: any = undefined;
                            if (!level4Code) {
                              resultActionLevel4 = await dispatch(
                                createLevelCurriculum({
                                  level: 4,
                                  item: {
                                    company_id: userInfo?.company_id,
                                    provider_id: userInfo?.company_id,
                                    name: getNameCurriculum(level_4.name),
                                    sort_order: level4_index,
                                    level3_code:
                                      level3Code || resultActionLevel3.payload.item['code'],
                                    official_curriculum_code:
                                      curriculumCode ||
                                      resultActionCreateCurriculum.payload.item[
                                        'official_curriculum_code'
                                      ],
                                  },
                                  access_key_updates: {
                                    roles_to_publish: ['MEMBER'],
                                  },
                                  return_item_result: true,
                                  return_display_id: true,
                                  realtime_auto_link: true,
                                })
                              );
                            }
                            if (
                              level_4.children &&
                              (level4Code ||
                                createLevelCurriculum.fulfilled.match(resultActionLevel4))
                            ) {
                              result[level_4.id] =
                                level4Code || resultActionLevel4?.payload.item['code'];
                              await Promise.all(
                                level_4.children.map((question: AnyObject) =>
                                  dispatch(
                                    createLinkQuestionAssignLevel({
                                      item: {
                                        company_id: userInfo?.company_id,
                                        level4_code:
                                          level4Code || resultActionLevel4.payload.item['code'],
                                        code: question.code,
                                        createdat: new Date(),
                                        createdby: userInfo?.login_id,
                                      },
                                    })
                                  )
                                )
                              );
                            }
                          })
                        );
                      }
                    })
                  );
                }
              })
            );
          }
        })
      );
    }
    return result;
  };

  const onImportSuccess = async () => {
    await fetchDataCurriculum();
  };

  return (
    <>
      <Wrapper
        isOpenMenuRight={tabActive !== 1 && isOpenMenuRight}
        itemMoveCopySelected={!!itemMoveCopySelected}
        collapsedMenu={collapsedMenu}
        tabActive={tabActive}
      >
        <Header title={headerTitle} className="header">
          <form className="form">
            <FilterOutlined className="filter-icon" />
            <div className="form-input">
              <Select
                data-testid="header-curriculum-treeview-select-status"
                className="select-input"
                placeholder="ステータス"
                value={statusSelected}
                onChange={(value: keyof typeof CurriculumStatus) => {
                  setStatusSelected(value);
                  dispatch(
                    setConditions({
                      ...conditions,
                      status: value,
                    })
                  );
                  value &&
                    dispatch(
                      setFilterByStatus({
                        status: value,
                      })
                    );
                  dispatch(
                    setFilterByCurriculum({
                      name: curriculumSelected,
                    })
                  );
                }}
              >
                <Option value="publish">公開中</Option>
                <Option value="unPublished">未公開</Option>
                <Option value="edit">編集中</Option>
                <Option value="suspended">公開停止中 </Option>
              </Select>
            </div>
            <img src={SortByDesc} className="sortByDesc-icon" alt="sort-by-desc-icon" />
            <div className="form-input">
              <Select
                data-testid="header-curriculum-treeview-select-curriculum"
                showSearch
                className="select-input"
                placeholder="指定のカリキュラムを上位表示"
                value={curriculumSelected}
                filterOption={(input, option) =>
                  JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => {
                  setCurriculumSelected(value);

                  dispatch(
                    setConditions({
                      ...conditions,
                      name: value,
                    })
                  );
                  value &&
                    dispatch(
                      setFilterByCurriculum({
                        name: value,
                      })
                    );
                }}
              >
                {curriculumOption.map((curr, index) => (
                  <Option key={index} value={curr.name}>
                    {curr.name}
                  </Option>
                ))}
              </Select>
            </div>
            <button
              data-testid="header-curriculum-treeview-button-clear"
              type="button"
              className="text-reset"
              onClick={() => {
                setStatusSelected(undefined);
                setCurriculumSelected(undefined);

                dispatch(clearFilterConditions());
                dispatch(
                  setConditions({
                    ...conditions,
                    status: undefined,
                    name: undefined,
                  })
                );
              }}
            >
              クリア
            </button>
          </form>
        </Header>
        <div className="wrap-title">
          <div className="wrap-button" data-testid="sort-wrapper">
            <div className="tab-button">
              {LIST_TAB_BAR.map((item, index) => (
                <Button
                  key={index}
                  tabActive={tabActive}
                  index={index}
                  onClick={() => {
                    setTabActive(index);
                    setOpenMenuRight(false);
                    setColumnClosed(undefined);
                    dispatch(removeNodeLevel4Selected());
                    if (itemMoveCopySelected) {
                      setItemMoveCopySelected(undefined);
                    }
                  }}
                >
                  {tabActive === index ? <CheckOutlined className="size-icon" /> : null}
                  {item}
                </Button>
              ))}
            </div>
            <div className="button-function">
              <button
                className="btn btn-outline"
                onClick={() => navigate(routes.PublicManagement.path)}
              >
                公開管理
                <RightOutlined className="size-icon-down-outline" />
              </button>
              <button className="btn btn-outline" onClick={() => setOpenCurriculumMaster(true)}>
                階層リスト
                <RightOutlined className="size-icon-down-outline icon" />
              </button>
              <ImportButton onClick={() => setShowConfirmImportFileModal(true)}>
                インポート
              </ImportButton>
              <button
                className="btn btn-active"
                onClick={() => setShowConfirmExportFileModal(true)}
              >
                <CloudDownloadOutlined className="size-icon" />
                <p>エクスポート</p>
              </button>
            </div>
          </div>
          <div className="flex-label" data-testid="sort-wrapper">
            {tabActive !== 2 ? (
              LIST_LABEL.map((item, index) => (
                <p
                  key={index}
                  className={`label-text${index === columnClosed ? ' active' : ''}`}
                  onClick={() =>
                    setColumnClosed((prevState) =>
                      prevState === index || index > 4 ? undefined : index
                    )
                  }
                >
                  {index < 5 ? (
                    index === columnClosed ? (
                      <CaretUpOutlined className="icon-label" />
                    ) : (
                      <CaretDownOutlined className="icon-label" />
                    )
                  ) : null}
                  {item}
                </p>
              ))
            ) : (
              <p
                className="label-text"
                onClick={() => setColumnClosed((prevState) => (prevState === 0 ? undefined : 0))}
              >
                {columnClosed === 0 ? (
                  <CaretUpOutlined className="icon-label" />
                ) : (
                  <CaretDownOutlined className="icon-label" />
                )}
                必修カリキュラム
              </p>
            )}
          </div>
          {tabActive !== 1 && (
            <div className={`setting-border ${pageYOffset >= 56 ? 'on-top' : ''}`}>
              <div className="border-green" />
              <div className="setting" onClick={() => setOpenMenuRight(!isOpenMenuRight)}>
                <div className="title">
                  <CaretLeftOutlined className={isOpenMenuRight ? 'opened' : ''} />
                  <p>設 問 リ ス ト</p>
                </div>
              </div>
            </div>
          )}
          {tabActive === 0 && (
            <p className="title-add">
              {dataCurriculumTreeFilleted?.length === 0 && 'カリキュラムがまだ登録されていません。'}
              <button
                disabled={permissionNumber !== 2}
                className={`btn-add ${permissionNumber !== 2 ? 'btn-disabled' : ''}`}
                onClick={() => setOpenModalCreateCurriculum(true)}
              >
                <PlusOutlined className="size-icon" />
                カリキュラム追加
              </button>
            </p>
          )}
          {tabActive === 1 && (
            <div className="wrap-title-tab-1">
              <p className="title">
                {itemMoveCopySelected ? (
                  <>
                    Step.2　ペースト先の階層を選択してください
                    <p className="sub-title" onClick={() => setItemMoveCopySelected(undefined)}>
                      やり直す
                    </p>
                  </>
                ) : (
                  'Step.1　移動 or コピーしたいアイテムを選択してください。'
                )}
              </p>
            </div>
          )}
        </div>
        <div className="flex">
          <div className="dashboard" ref={dashboardRef}>
            <div className="wrap-body" ref={topRef}>
              <div className={`wrap-body-tree ${tabActive == 1 ? 'moving' : ''}`}>
                <InfiniteScroll
                  pageStart={1}
                  useWindow={false}
                  hasMore={hasMore}
                  loadMore={handleLoadMore}
                  loader={
                    <div className="loader">
                      <SpinLoading loading size="large" />
                    </div>
                  }
                  getScrollParent={() => dashboardRef.current}
                >
                  <div />
                  {items.map((c, index) => (
                    <div
                      key={c.i_id}
                      className={`wrap-tree ${index < items?.length - 1 ? 'bordered' : ''} ${
                        !index ? 'first' : ''
                      }`}
                    >
                      <MemorizedTreview
                        treeData={c}
                        treeViewIndex={index}
                        tabActive={tabActive}
                        columnClosed={columnClosed}
                        itemMoveCopySelected={itemMoveCopySelected}
                        setItemMoveCopySelected={setItemMoveCopySelected}
                        setShowCompleteModal={setShowCompleteModal}
                        isIndex={index}
                      />
                    </div>
                  ))}
                </InfiniteScroll>
                <MemorizeCustomDragLayer nodeHeight={45} />
              </div>
            </div>
          </div>
          {isOpenMenuRight ? (
            <SearchCurriculum
              pageYOffset={pageYOffset}
              setOpenModalCreateQuestion={setOpenModalCreateQuestion}
            />
          ) : null}
        </div>
        <CreateEditCurriculum
          title="カリキュラム新規作成"
          setVisibleSuccess={setVisibleSuccess}
          maxSortOrder={(maxBy(dataCurriculumTree, (o) => o.sort_order)?.sort_order || 0) + 1}
          subTitle={
            <>
              カリキュラム名・説明の新規作成が可能です。
              <br />
              入力後、新規作成ボタンをクリックしてください。
            </>
          }
          textSubmit="新規作成"
          type="create"
          visible={openModalCreateCurriculum}
          setVisible={setOpenModalCreateCurriculum}
        />
        <ErrorBoundary>
          <CreateQuestion
            page={1}
            setVisibleSuccess={setVisibleSuccess}
            openModalCreateEditQuestion={openModalCreateQuestion}
            setOpenModalCreateEditQuestion={setOpenModalCreateQuestion}
            fetchData={() => {
              dispatch(
                getDataCurriculum({
                  conditions: [
                    {
                      id: 'provider_id',
                      search_value: [userInfo?.company_id],
                    },
                  ],
                  page: 1,
                  per_page: 0,
                })
              );
            }}
          />
        </ErrorBoundary>
        <CompletedModal
          visible={visibleSuccess}
          setVisible={setVisibleSuccess}
          title="登録が完了しました"
          onSubmit={() => {
            setVisibleSuccess(!visibleSuccess);
          }}
        />
        <CompletedModal
          visible={showCompleteModal}
          setVisible={setShowCompleteModal}
          title="コピー追加が完了しました"
          onSubmit={() => setShowCompleteModal(false)}
        />
        <PopupConfirmExportFile
          customTitle={
            <>
              上位表示されているカリキュラムをエクスポートします。
              <br />
              （カリキュラムマスタ画面上部より上位表示するカリキュラムを指定できます。）
              <br />
              <br />
              出力形式を選択して、OKボタンをクリックしてください。
            </>
          }
          visible={showConfirmExportFileModal}
          setVisible={setShowConfirmExportFileModal}
          onSubmit={handleExportCSV}
        />
        <UploadCSV
          visible={showConfirmImportFileModal}
          setVisible={setShowConfirmImportFileModal}
          dataSchema={uploadCurriculumCSVSchema}
          headerCSVs={HEADER_IMPORT_CURRICULUM_CSV}
          handleUploadPerItem={onSubmitUploadCsv}
          title={'カリキュラムマスタ インポート'}
          handleDataImport={handleUploadData}
          onSuccess={onImportSuccess}
          defaultUploadTimePerItem={4500}
          extValidate={handleExtValidateCsv}
        />
      </Wrapper>

      {showConfirmExportFileModal && (
        <div
          ref={ref}
          style={{
            position: 'absolute',
            width: 1512,
            right: 9999,
          }}
        >
          <FileExportPDF
            data={dataCurriculumTreeFilleted}
            tabActive={tabActive}
            columnClosed={columnClosed}
            itemMoveCopySelected={itemMoveCopySelected}
            headerTitle={headerTitle}
            filter_conditions={{
              name: curriculumSelected,
              status: statusSelected,
            }}
            collapsedMenu={collapsedMenu}
          />
        </div>
      )}
    </>
  );
};

export default Treeview;
