import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';

const UnPublished = () => (
  <Svg width="16" height="16" viewBox="0 0 16 16">
    <Rect width="16" height="16" rx="2" ry="2" fill="#6A6ECD" />
    <G clip-path="url(#clip0_12389_40614)">
      <Path
        d="M8 2C4.68661 2 2 4.68661 2 8C2 11.3134 4.68661 14 8 14C11.3134 14 14 11.3134 14 8C14 4.68661 11.3134 2 8 2ZM11.1446 11.8638L4.13616 4.85536C4.35045 4.59152 4.59152 4.35045 4.85536 4.13616L11.8638 11.1446C11.6496 11.4071 11.4085 11.6496 11.1446 11.8638Z"
        fill="white"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_12389_40614">
        <Rect width="12" height="12" fill="white" transform="translate(2 2)" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default UnPublished;
