import { createAsyncThunk } from '@reduxjs/toolkit';
import { chain, filter, find, join, map, maxBy, uniqBy } from 'lodash';

import { services } from 'services';
import * as Types from 'types';
import {
  QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM,
  OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS,
  PUBLISHED_RELEASE_NOTE_SECTION,
  ADMIN_RELEASE_NOTE_MANAGEMENT,
  CURRICULUM_PUBLISHED_HISTORY,
  LEVEL1S_OFFICIAL_CURRICULUM,
  LEVEL2S_OFFICIAL_CURRICULUM,
  LEVEL3S_OFFICIAL_CURRICULUM,
  LEVEL4S_OFFICIAL_CURRICULUM,
  OFFICIAL_CURRICULUM_MASTER,
  PUBLISHED_RELEASE_NOTE,
  RELEASE_NOTE_SECTION,
  PROVIDER_INFORMATION,
  RELEASE_NOTE_DETAIL,
  RELEASE_NOTE,
  QUESTIONS,
  ATTACH,
  USERS,
} from 'configs';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { browserLogger } from 'libs/logger';

export const getDataAdminReleaseNoteManagement = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getDataAdminReleaseNoteManagement', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(ADMIN_RELEASE_NOTE_MANAGEMENT.name, req);
    browserLogger.info(
      'public/thunk/getDataAdminReleaseNoteManagement',
      ADMIN_RELEASE_NOTE_MANAGEMENT.name,
      data
    );
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getArchiveList = createAsyncThunk<
  Types.GetItemResponseType<Types.OfficialCurriculumPublishingSettings.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getArchiveList', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    const { authContainer } = getState() as Types.RootState;
    dispatch(startLoading());
    const data = await getDataOfficialPublish({ req, dispatch });

    const resultAction = await dispatch(
      getDataUsers({
        conditions: [
          {
            id: 'company_id',
            search_value: [authContainer.userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );

    if (data) {
      const items: typeof data.items = [];

      if (getDataUsers.fulfilled.match(resultAction)) {
        data.items.forEach((e) => {
          const index = resultAction.payload.items.findIndex((o) => e.createdby === o.email);
          if (index > -1) {
            items.push({ ...e, creator: resultAction.payload.items[index]?.name });
          } else {
            items.push({
              ...e,
              creator: '',
            });
          }
        });
      }
      dispatch(stopLoading());
      return { ...getDataOfficialPublish, items };
    }
    return {} as any;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createCurriculumPublishedHistory = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.CurriculumPublishedHistory.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/CreateCurriculumPublishedHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(CURRICULUM_PUBLISHED_HISTORY.name, req);
    browserLogger.info(
      'public/thunk/CreateCurriculumPublishedHistory',
      CURRICULUM_PUBLISHED_HISTORY.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateOfficialCurriculumMaster = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.OfficialCurriculumMaster.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/updateOfficialCurriculumMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(OFFICIAL_CURRICULUM_MASTER.name, req);
    browserLogger.info(
      'public/thunk/updateOfficialCurriculumMaster',
      OFFICIAL_CURRICULUM_MASTER.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCurriculumPublishedHistory = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.CurriculumPublishedHistory.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/updateCurriculumPublishedHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(CURRICULUM_PUBLISHED_HISTORY.name, req);
    browserLogger.info(
      'public/thunk/updateCurriculumPublishedHistory',
      CURRICULUM_PUBLISHED_HISTORY.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getReleaseNoteDetail = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getReleaseNoteDetail', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    const { authContainer } = getState() as Types.RootState;

    const { data } = await services.filter<Types.ReleaseNoteDetail.ResponseType>(
      RELEASE_NOTE_DETAIL.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info('public/thunk/getReleaseNoteDetail', RELEASE_NOTE_DETAIL.name, data);
    const resultAction = await dispatch(
      getDataUsers({
        conditions: [
          {
            id: 'company_id',
            search_value: [authContainer.userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );

    const report_results: typeof data.report_results = [];

    if (getDataUsers.fulfilled.match(resultAction)) {
      data.report_results.forEach((e) => {
        const index = resultAction.payload.items.findIndex((o) => e.createdBy === o.email);
        if (index > -1) {
          report_results.push({ ...e, creator: resultAction.payload.items[index]?.name });
        } else {
          report_results.push({
            ...e,
            creator: '',
          });
        }
      });
    }
    return { ...data, report_results };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getReleaseNoteDetailExport = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getReleaseNoteDetailExport', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(RELEASE_NOTE_DETAIL.name, req);
    browserLogger.info('public/thunk/getReleaseNoteDetailExport', RELEASE_NOTE_DETAIL.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createReleaseNote = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.ReleaseNote.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/createReleaseNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(RELEASE_NOTE.name, req);
    browserLogger.info('public/thunk/createReleaseNote', RELEASE_NOTE.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createReleaseNoteSection = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.ReleaseNoteSecton.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/createReleaseNoteSection', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(RELEASE_NOTE_SECTION.name, req);
    browserLogger.info('public/thunk/createReleaseNoteSection', RELEASE_NOTE_SECTION.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const removeReleaseNote = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/removeReleaseNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(RELEASE_NOTE.name, req);
    browserLogger.info('public/thunk/removeReleaseNote', RELEASE_NOTE.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const removeReleaseSectionNote = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/removeReleaseSectionNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(RELEASE_NOTE_SECTION.name, req);
    browserLogger.info('public/thunk/removeReleaseSectionNote', RELEASE_NOTE_SECTION.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateReleaseNote = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.ReleaseNote.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/updateReleaseNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(RELEASE_NOTE.name, req);
    browserLogger.info('public/thunk/updateReleaseNote', RELEASE_NOTE.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateReleaseNoteSection = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.ReleaseNoteSecton.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/updateReleaseNoteSection', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(RELEASE_NOTE_SECTION.name, req);
    browserLogger.info('public/thunk/updateReleaseNoteSection', RELEASE_NOTE_SECTION.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataReleaseNoteID = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getDataReleaseNoteID', async (req, { rejectWithValue, getState, dispatch }) => {
  try {
    const { authContainer } = getState() as Types.RootState;

    const { data } = await services.search<Types.ReleaseNote.ResponseType>(RELEASE_NOTE.name, req);
    browserLogger.info('public/thunk/getDataReleaseNoteID', RELEASE_NOTE.name, data);
    const resultAction = await dispatch(
      getDataUsers({
        conditions: [
          {
            id: 'company_id',
            search_value: [authContainer.userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );

    const items: typeof data.items = [];

    if (getDataUsers.fulfilled.match(resultAction)) {
      data.items.forEach((e) => {
        const index = resultAction.payload.items.findIndex((o) => e.createdby === o.email);
        if (index > -1) {
          items.push({ ...e, createdby: resultAction.payload.items[index]?.name });
        } else {
          items.push({
            ...e,
            createdby: '',
          });
        }
      });
    }
    return { ...data, items };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDataOfficialCurriculumPublishingSettingExportCSV = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'public/thunk/getDataOfficialCurriculumPublishingSettingExportCSV',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter(OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS.name, req);
      browserLogger.info(
        'public/thunk/getDataOfficialCurriculumPublishingSettingExportCSV',
        OFFICIAL_CURRICULUM_PUBLISHING_SETTINGS.name,
        data
      );
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDataAdminReleaseNoteManagementExport = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getDataAdminReleaseNoteManagementExport', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(ADMIN_RELEASE_NOTE_MANAGEMENT.name, req);
    browserLogger.info(
      'public/thunk/getDataAdminReleaseNoteManagementExport',
      ADMIN_RELEASE_NOTE_MANAGEMENT.name,
      data
    );
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createFileAttach = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/createFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);
    browserLogger.info('manual/thunk/createFileAttach', ATTACH.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUsers = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getDataUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.name, req);
    browserLogger.info('public/thunk/getDataUsers', USERS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getProviderInformation = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getProviderInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(PROVIDER_INFORMATION.name, req);
    browserLogger.info('public/thunk/getProviderInformation', PROVIDER_INFORMATION.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel1 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getDataLevel1', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level.ResponseType>(
      LEVEL1S_OFFICIAL_CURRICULUM.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info('public/thunk/getDataLevel1', LEVEL1S_OFFICIAL_CURRICULUM.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel2 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getDataLevel2', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level.ResponseType>(
      LEVEL2S_OFFICIAL_CURRICULUM.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info('public/thunk/getDataLevel2', LEVEL2S_OFFICIAL_CURRICULUM.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel3 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getDataLevel3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level.ResponseType>(
      LEVEL3S_OFFICIAL_CURRICULUM.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info('public/thunk/getDataLevel3', LEVEL3S_OFFICIAL_CURRICULUM.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel4 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getDataLevel4', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level.ResponseType>(
      LEVEL4S_OFFICIAL_CURRICULUM.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info('public/thunk/getDataLevel4', LEVEL4S_OFFICIAL_CURRICULUM.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQuesLevel = createAsyncThunk<
  Types.GetItemResponseType<Types.QuestionAssignLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getDataQuesLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.QuestionAssignLevel.ResponseType>(
      QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM.name,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );
    browserLogger.info(
      'public/thunk/getDataQuesLevel',
      QUESTION_ASSIGN_LEVEL_OFFICIAL_CURRICULUM.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQues = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getDataQues', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Questions.ResponseType>(QUESTIONS.name, req);
    browserLogger.info('public/thunk/getDataQues', QUESTIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getOfficialCurriculumMaster = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'public/thunk/getOfficialCurriculumMaster',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      const { authContainer } = getState() as Types.RootState;

      const { data } = await services.search<Types.OfficialCurriculumMaster.ResponseType>(
        OFFICIAL_CURRICULUM_MASTER.name,
        req
      );
      browserLogger.info(
        'public/thunk/getOfficialCurriculumMaster',
        OFFICIAL_CURRICULUM_MASTER.name,
        data
      );
      const conditions = [
        {
          id: 'company_id',
          search_value: [authContainer?.userInfo?.company_id],
        },
      ];

      const responseStatus = await Promise.all([
        dispatch(
          getDataLevel1({
            conditions,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataLevel2({
            conditions,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataLevel3({
            conditions,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataLevel4({
            conditions,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataQuesLevel({
            conditions,
            include_item_ref: true,
            include_links: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataQues({
            conditions: [
              {
                id: 'company_id',
                search_value: [join([authContainer?.userInfo?.company_id, '000000001'], '|')],
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataUsers({
            conditions,
            page: 1,
            per_page: 0,
          })
        ),
      ]);

      const resultArray: Array<Types.CurriculumTable.ResponseType> = [];
      let resultUser: any = [];

      if (
        getDataLevel1.fulfilled.match(responseStatus[0]) &&
        getDataLevel2.fulfilled.match(responseStatus[1]) &&
        getDataLevel3.fulfilled.match(responseStatus[2]) &&
        getDataLevel4.fulfilled.match(responseStatus[3]) &&
        getDataQuesLevel.fulfilled.match(responseStatus[4]) &&
        getDataQues.fulfilled.match(responseStatus[5]) &&
        getDataUsers.fulfilled.match(responseStatus[6])
      ) {
        const dataLevel1 = responseStatus[0].payload.items;
        const dataLevel2 = responseStatus[1].payload.items;
        const dataLevel3 = responseStatus[2].payload.items;
        const dataLevel4 = responseStatus[3].payload.items;
        const dataAssignLevel = responseStatus[4].payload.items;
        const dataQues = responseStatus[5].payload.items;
        const dataUser = responseStatus[6].payload.items;
        resultUser = responseStatus[6].payload.items;

        const dataNewItems = filter(
          data.items,
          (item) =>
            !!item.createdby &&
            !!find(dataUser, {
              login_id: item.createdby,
            })
        );

        dataNewItems.flatMap((item) => {
          const matchedLevel1Items = filter(
            dataLevel1,
            (level1) =>
              !!item.official_curriculum_code &&
              level1.official_curriculum_code === item.official_curriculum_code
          );

          const baseObject = {
            rowKey: item.official_curriculum_code,
            curriculum_id: item.i_id,
            curriculum_code: item.official_curriculum_code,
            curriculum_name: item.name,
            curriculum_description: item.description,
            curriculum_fileID: item.fileID,
            curriculum_publish: Number(item.publish ?? 0),
            curriculum_probs_count: Number(item.probs_count ?? 0),
            curriculum_sort_order: Number(item.sort_order ?? 0),
            required_curriculum: Number(item.required_curriculum ?? 0),
            official_curriculum: Number(item.official_curriculum ?? 0),
          };

          if (matchedLevel1Items.length) {
            matchedLevel1Items.flatMap((level1Item) => {
              const baseObject1 = {
                ...baseObject,
                rowKey: `${item.official_curriculum_code}_${level1Item.code}`,
                level1_i_id: level1Item.i_id,
                level1_name: level1Item.name ?? '',
                level1_code: level1Item.code,
                level1_sort_order: Number(level1Item.sort_order ?? 0),
              };
              const matchedLevel2Items = filter(dataLevel2, { level1_code: level1Item.code });

              if (matchedLevel2Items.length) {
                matchedLevel2Items.flatMap((level2Item) => {
                  const baseObject2 = {
                    ...baseObject1,
                    rowKey: `${item.official_curriculum_code}_${level1Item.code}_${level2Item.code}`,
                    level2_i_id: level2Item.i_id,
                    level2_name: level2Item.name ?? '',
                    level2_code: level2Item.code,
                    level2_sort_order: Number(level2Item.sort_order ?? 0),
                  };
                  const matchedLevel3Items = filter(dataLevel3, {
                    level2_code: level2Item.code,
                  });

                  if (matchedLevel3Items.length) {
                    matchedLevel3Items.flatMap((level3Item) => {
                      const baseObject3 = {
                        ...baseObject2,
                        rowKey: `${item.official_curriculum_code}_${level1Item.code}_${level2Item.code}_${level3Item.code}`,
                        level3_i_id: level3Item.i_id,
                        level3_name: level3Item.name ?? '',
                        level3_code: level3Item.code,
                        level3_sort_order: Number(level3Item.sort_order ?? 0),
                      };
                      const matchedLevel4Items = filter(dataLevel4, {
                        level3_code: level3Item.code,
                      });

                      if (matchedLevel4Items.length) {
                        matchedLevel4Items.flatMap((level4Item) => {
                          const baseObject4 = {
                            ...baseObject3,
                            rowKey: `${item.official_curriculum_code}_${level1Item.code}_${level2Item.code}_${level3Item.code}_${level4Item.code}`,
                            level4_i_id: level4Item.i_id,
                            level4_name: level4Item.name ?? '',
                            level4_code: level4Item.code,
                            level4_sort_order: Number(level4Item.sort_order ?? 0),
                          };

                          const matchedQuestionItems = filter(dataAssignLevel, {
                            level4_code: level4Item.code,
                          });

                          if (matchedQuestionItems.length) {
                            matchedQuestionItems.flatMap((quesAssign) => {
                              const questionFounded = find(dataQues, { code: quesAssign.code });
                              if (questionFounded) {
                                resultArray.push({
                                  ...baseObject4,
                                  rowKey: `${item.official_curriculum_code}_${level1Item.code}_${level2Item.code}_${level3Item.code}_${level4Item.code}_${questionFounded.code}`,
                                  question_code_i_id: questionFounded.i_id,
                                  question_code: questionFounded.code,
                                  question_name: questionFounded.name,
                                  question_creator: questionFounded.createdby,
                                  question_sort_order: Number(quesAssign.sort_order),
                                  question_assign_level_i_id: quesAssign.i_id,
                                });
                              } else {
                                resultArray.push(baseObject4);
                              }
                            });
                          } else {
                            resultArray.push(baseObject4);
                          }
                        });
                      } else {
                        resultArray.push(baseObject3);
                      }
                    });
                  } else {
                    resultArray.push(baseObject2);
                  }
                });
              } else {
                resultArray.push(baseObject1);
              }
            });
          } else {
            resultArray.push(baseObject);
          }
        });
      }

      const flattenedResultArray = chain(uniqBy(resultArray, 'rowKey'))
        .flatten()
        .orderBy(
          [
            'curriculum_code',
            'level1_sort_order',
            'level1_code',
            'level2_sort_order',
            'level2_code',
            'level3_sort_order',
            'level3_code',
            'level4_sort_order',
            'level4_code',
            'question_sort_order',
            'question_code',
          ],
          ['asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc']
        )
        .value();

      const items = map(data.items, (item) => ({
        ...item,
        probs_count:
          filter(
            flattenedResultArray,
            (item2) =>
              item2.curriculum_code === item.official_curriculum_code && !!item2.question_code
          ).length ?? 0,
        creator: find(resultUser, { login_id: item.createdby })?.name ?? '',
      }));

      return { ...data, items };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProviderInformation = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.ProviderInformation.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/updateProviderInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(PROVIDER_INFORMATION.name, req);
    browserLogger.info('public/thunk/updateProviderInformation', PROVIDER_INFORMATION.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumPublishedHistory = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getCurriculumPublishedHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(CURRICULUM_PUBLISHED_HISTORY.name, req);
    browserLogger.info(
      'public/thunk/getCurriculumPublishedHistory',
      CURRICULUM_PUBLISHED_HISTORY.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteOfficialCurriculumMaster = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getCurriculumPublishedHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(OFFICIAL_CURRICULUM_MASTER.name, req);
    browserLogger.info(
      'public/thunk/getCurriculumPublishedHistory',
      OFFICIAL_CURRICULUM_MASTER.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getReleaseNoteList = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getReleaseNoteList', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.ReleaseNote.ResponseType>(RELEASE_NOTE.name, req);
    browserLogger.info('public/thunk/getReleaseNoteList', RELEASE_NOTE.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getArchiveSelect = createAsyncThunk<
  Types.GetItemResponseType<Types.OfficialCurriculumPublishingSettings.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getArchiveSelect', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    const { authContainer } = getState() as Types.RootState;

    const data = await getDataOfficialPublish({ req, dispatch });
    const resultAction = await dispatch(
      getDataUsers({
        conditions: [
          {
            id: 'company_id',
            search_value: [authContainer.userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );

    if (data) {
      const items: typeof data.items = [];

      if (getDataUsers.fulfilled.match(resultAction)) {
        data.items.forEach((e) => {
          const index = resultAction.payload.items.findIndex((o) => e.createdby === o.email);
          if (index > -1) {
            items.push({ ...e, creator: resultAction.payload.items[index]?.name });
          } else {
            items.push({
              ...e,
              creator: '',
            });
          }
        });
      }
      return { ...data, items };
    }
    return {} as any;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getOfficialPublishSelect = createAsyncThunk<
  Types.GetItemResponseType<Types.OfficialCurriculumPublishingSettings.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getOfficialPublishSelect', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    const { authContainer } = getState() as Types.RootState;

    const data = await getDataOfficialPublish({ req, dispatch });
    const resultAction = await dispatch(
      getDataUsers({
        conditions: [
          {
            id: 'company_id',
            search_value: [authContainer.userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );

    if (data) {
      const items: typeof data.items = [];

      if (getDataUsers.fulfilled.match(resultAction)) {
        data.items.forEach((e) => {
          const index = resultAction.payload.items.findIndex((o) => e.createdby === o.email);
          if (index > -1) {
            items.push({ ...e, creator: resultAction.payload.items[index]?.name });
          } else {
            items.push({
              ...e,
              creator: '',
            });
          }
        });
      }
      return { ...data, items };
    }
    return {} as any;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createPublishedReleaseNoteSection = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.ReleaseNoteSecton.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/createPublishedReleaseNoteSection', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(PUBLISHED_RELEASE_NOTE_SECTION.name, {
      ...req,
      access_key_updates: {
        roles_to_publish: ['MEMBER'],
      },
    });
    browserLogger.info(
      'public/thunk/createPublishedReleaseNoteSection',
      PUBLISHED_RELEASE_NOTE_SECTION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createPublishedReleaseNote = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.ReleaseNote.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/createPublishedReleaseNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(PUBLISHED_RELEASE_NOTE.name, {
      ...req,
      access_key_updates: {
        roles_to_publish: ['MEMBER'],
      },
    });
    browserLogger.info(
      'public/thunk/createPublishedReleaseNote',
      PUBLISHED_RELEASE_NOTE.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteReleaseNotePublished = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/deleteReleaseNotePublished', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(PUBLISHED_RELEASE_NOTE.name, req);
    browserLogger.info(
      'public/thunk/deleteReleaseNotePublished',
      PUBLISHED_RELEASE_NOTE.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteReleaseNoteSectionPublished = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/deleteReleaseNoteSectionPublished', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(
      PUBLISHED_RELEASE_NOTE_SECTION.name,
      req
    );
    browserLogger.info(
      'public/thunk/deleteReleaseNoteSectionPublished',
      PUBLISHED_RELEASE_NOTE_SECTION.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeBatchAction = createAsyncThunk<
  Types.ExecuteBatchActionResponse,
  Types.ExecuteBatchActionRequest<Types.ReleaseNoteSecton.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/executeBatchAction', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeBatchAction(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeBatchCreateReleaseNote = createAsyncThunk<
  Types.ExecuteBatchActionResponse,
  Types.ExecuteBatchActionRequest<Types.ReleaseNote.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/executeBatchAction', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeBatchAction(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getReleaseNoteSection = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getReleaseNoteSection', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(RELEASE_NOTE_SECTION.name, req);
    browserLogger.info('public/thunk/getReleaseNoteSection', RELEASE_NOTE_SECTION.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataPublishManagement = createAsyncThunk<
  Types.GetItemResponseType<Types.OfficialCurriculumPublishingSettings.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getDataPublishManagement', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    const { authContainer } = getState() as Types.RootState;
    const data = await getDataOfficialPublish({ req, dispatch });
    const resultAction = await dispatch(
      getDataUsers({
        conditions: [
          {
            id: 'company_id',
            search_value: [authContainer?.userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    if (data) {
      const items: typeof data.items = [];

      if (getDataUsers.fulfilled.match(resultAction)) {
        data.items.forEach((e) => {
          const index = resultAction.payload.items.findIndex((o) => e.createdby === o.email);
          if (index > -1) {
            items.push({ ...e, creator: resultAction.payload.items[index]?.name });
          } else {
            items.push({
              ...e,
              creator: '',
            });
          }
        });
      }
      return { ...data, items };
    }
    return {} as any;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataOfficialPublish = async ({
  req,
  dispatch,
}: {
  req: Types.GetItemRequestType;
  dispatch: Types.AppDispatch;
}) => {
  const { data } = await services.search<Types.OfficialCurriculumPublishingSettings.ResponseType>(
    OFFICIAL_CURRICULUM_MASTER.name,
    {
      ...req,
      conditions: [...(req.conditions || []), { id: 'provider_id', search_value: ['000000001'] }],
    }
  );
  browserLogger.info('public/thunk/getDataOfficialPublish', OFFICIAL_CURRICULUM_MASTER.name, data);
  const dataMapping: typeof data.items = [];

  const dataCurriculumPublishedHistory = await dispatch(
    getCurriculumPublishedHistory({
      conditions: [
        {
          id: 'company_id',
          search_value: ['000000001'],
        },
      ],
      page: 1,
      per_page: 0,
    })
  );
  if (getCurriculumPublishedHistory.fulfilled.match(dataCurriculumPublishedHistory)) {
    data.items.forEach((e) => {
      const publishedCurriculum = dataCurriculumPublishedHistory.payload.items.filter(
        (o) => o.official_curriculum_code === e.official_curriculum_code
      );

      const curriculumHistory = maxBy(publishedCurriculum, (published) =>
        new Date(published.publish_start_date).getTime()
      );
      if (curriculumHistory) {
        dataMapping.push({
          ...e,
          ...curriculumHistory,
          publish: Number(e.publish || 0),
          i_id_curriculum_master: e.i_id,
          i_id_published_history: curriculumHistory.i_id,
        });
      } else {
        dataMapping.push({
          ...e,
          publish: Number(e.publish || 0),
          i_id_curriculum_master: e.i_id,
          i_id_published_history: '',
        });
      }
    });
  }

  return { ...data, items: dataMapping };
};
