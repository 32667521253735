import { Document, Page, View, Text, Font } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import * as Types from 'types';
import styles from './styles';

Font.register({ family: 'NotosanJP', src: NotosanJP as string });

interface IItem {
  label: string;
  checked: number;
  disabled: boolean;
}

interface IDataPage {
  title: string;
  items: IItem[];
}

const textItems = [
  { text: '編集権限', value: 2 },
  { text: '閲覧権限', value: 1 },
  { text: '非表示', value: 0 },
];

const pageSize = 'A4';
const orientation = 'landscape';

const PDFDocument = ({ role }: { role: Types.Roles.ResponseType }) => {
  const dataFirstPage: IDataPage[] = [
    {
      title: 'Home',
      items: [
        {
          label: 'ダッシュボード',
          checked: role.admin_dashboard_permission || -1,
          disabled: true,
        },
      ],
    },
    {
      title: 'レポート',
      items: [
        {
          label: '売上情報',
          checked: role.sales_report_permission || -1,
          disabled: true,
        },
        {
          label: 'ユーザー情報',
          disabled: true,
          checked: role.user_report_permission || -1,
        },
        {
          label: 'OFFICIALカリキュラム情報',
          disabled: true,
          checked: role.official_curriculum_report_permission || -1,
        },
        {
          label: 'スキルチェック実施情報',
          disabled: true,
          checked: role.skill_check_implementation_report_permission || -1,
        },
      ],
    },
    {
      title: '請求データ管理',
      items: [
        {
          label: '請求データ管理',
          checked: role.billing_management_permission || -1,
          disabled: false,
        },
      ],
    },
    {
      title: 'OFFICIALカリキュラム公開管理',
      items: [
        {
          label: 'OFFICIALカリキュラム公開管理',
          checked: role.official_curriculum_publish_permission || -1,
          disabled: false,
        },
      ],
    },
  ];

  const dataSecondPage: IDataPage[] = [
    {
      title: 'カリキュラム管理',
      items: [
        {
          label: 'カリキュラムマスタ',
          disabled: false,
          checked: role.curricullum_master_permission || -1,
        },
        {
          label: '設問マスタ',
          disabled: false,
          checked: role.question_master_permission || -1,
        },
      ],
    },
    {
      title: 'ユーザー管理',
      items: [
        {
          label: 'ユーザーマスタ',
          checked: role.users_master_permission || -1,
          disabled: false,
        },
        {
          label: '権限マスタ',
          checked: role.roles_master_permission || -1,
          disabled: false,
        },
        {
          label: '所属マスタ',
          checked: role.departments_master_permission || -1,
          disabled: false,
        },
        {
          label: '役職マスタ',
          checked: role.positions_master_permission || -1,
          disabled: false,
        },
        {
          label: '管理者マスタ',
          checked: role.admin_master_permission || -1,
          disabled: false,
        },
      ],
    },
    {
      title: 'ストレージ管理',
      items: [
        {
          label: 'ストレージ管理',
          checked: role.admin_storage_permission || -1,
          disabled: true,
        },
      ],
    },
    {
      title: 'パートナー管理',
      items: [
        {
          label: 'パートナー管理',
          checked: role.partner_management_permission || -1,
          disabled: false,
        },
        {
          label: 'カリキュラム一覧・ツリー',
          checked: role.curriculum_tree_permission || -1,
          disabled: true,
        },
      ],
    },
  ];

  const dataThirdPage: IDataPage[] = [
    {
      title: '法人ユーザー管理',
      items: [
        {
          label: '法人ユーザー情報一覧',
          checked: role.corporate_user_permission || -1,
          disabled: false,
        },
        {
          label: '請求書対応',
          checked: role.invoice_correspondence_permission || -1,
          disabled: false,
        },
      ],
    },
    {
      title: '個人ユーザー管理',
      items: [
        {
          label: '個人ユーザー管理',
          checked: role.individual_user_management_permission || -1,
          disabled: false,
        },
      ],
    },
  ];

  const renderItems = (items: IItem[]) => {
    return items.map((item, index) => (
      <View style={styles.item} key={index}>
        <View style={styles.wrapLabel}>
          <Text style={styles.label}>{item.label}</Text>
        </View>
        <View style={styles.wrapRadio}>
          {textItems.map((textItem, textIndex) => (
            <View style={styles.radioItem} key={textIndex}>
              <View
                style={[
                  styles.radioButton,
                  {
                    borderColor: Number(item.checked) === textItem.value ? '#1890ff' : '#ebebeb',
                    backgroundColor:
                      textItem.value === 2 && item.disabled ? '#F6F6F6' : 'transparent',
                  },
                ]}
              >
                {Number(item.checked) === textItem.value ? (
                  <View style={styles.radioSelected} />
                ) : null}
              </View>
              {Number(item.checked) === textItem.value ? (
                <Text style={[styles.textItem, styles.check]}>{textItem.text}</Text>
              ) : textItem.value === 2 && item.disabled ? (
                <Text style={[styles.textItem, styles.disabled]}>{textItem.text}</Text>
              ) : (
                <Text style={[styles.textItem]}>{textItem.text}</Text>
              )}

              <View />
            </View>
          ))}
        </View>
      </View>
    ));
  };

  const renderDataPage = (dataPages: IDataPage[]) => {
    return dataPages.map((data, index) => (
      <View key={index}>
        <Text style={styles.title}>{data.title}</Text>
        <View style={styles.wrapItem}>{renderItems(data.items)}</View>
      </View>
    ));
  };

  return (
    <Document>
      <Page size={pageSize} orientation={orientation} style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.textHeader}>権限マスタ</Text>
        </View>

        <View style={styles.subscription}>
          <Text style={styles.textSubscription}>権限マスタの編集を行う画面です。</Text>
          <Text style={styles.textSubscription}>
            編集完了後は更新ボタンをクリックしてください。
          </Text>
        </View>
        <View style={styles.lineBorder} />

        <View style={styles.body}>
          <View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 7.6,
              }}
            >
              <Text style={styles.title_2}>権限名称</Text>
              <Text style={styles.title_sub}>*</Text>
            </View>
            <View style={styles.wrapItem_2}>
              <Text style={styles.name}>{role.name}</Text>
            </View>
          </View>

          {renderDataPage(dataFirstPage)}
        </View>
      </Page>

      <Page size={pageSize} orientation={orientation} style={styles.page}>
        <View style={[styles.body, { paddingVertical: 55 }]}>{renderDataPage(dataSecondPage)}</View>
      </Page>

      <Page size={pageSize} orientation={orientation} style={styles.page}>
        <View style={[styles.body, { paddingVertical: 55 }]}>{renderDataPage(dataThirdPage)}</View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
