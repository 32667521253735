import React, { useState } from 'react';

import { SectionStyled, ModalStyled } from './styles';
import useModifiedPage from '../../../hooks/useModifiedPage';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface Props {
  visible: boolean;
  onSubmit: () => void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalAlert: React.FC<Props> = ({ visible, setVisible, onSubmit }) => {
  const { setResetFormState } = useModifiedPage();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleToggleModal = () => {
    setVisible((prevState) => !prevState);
  };

  return (
    <ModalStyled
      visible={visible}
      width={478}
      onCancel={handleToggleModal}
      okButton={{
        text: 'このページから移動する',
        onClick: () => {
          setSubmitting(true);
          handleToggleModal();
          setResetFormState(true);
          onSubmit && onSubmit();
          setSubmitting(false);
        },
        loading: submitting,
        style: {
          padding: 0,
          backgroundColor: 'rgba(68, 72, 161, 1)',
          color: '#fff',
          width: '162px',
          height: '40px',
        },
      }}
      cancelButton={{
        text: 'このページに留まる',
        onClick: handleToggleModal,
        style: {
          padding: 0,
          width: '162px',
          height: '40px',
        },
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          <ExclamationCircleOutlined className="icon" />
          このページから移動しますか
        </p>
        <p className="text-content">入力した情報は保存されません</p>
      </SectionStyled>
    </ModalStyled>
  );
};

export default ModalAlert;
