import React, { useEffect } from 'react';

import { groupBy, keyBy, omit, uniq } from 'lodash';
import { useIntl } from 'react-intl';
import { Select } from 'antd';

import { CaretDownOutlined, CaretUpOutlined, FilterOutlined } from '@ant-design/icons';

import { CurriculumItemType, ItemMoveCopySelectedType } from 'types/services/curriculum';
import { MemorizedTreview } from 'containers/Curriculum/NodeTreeView';
import { CURRICULUM_STATUS } from 'constant/select.constants';
import { CurriculumStatus } from 'constant/enum.constant';
import { LIST_LABEL } from 'constant';
import { Header } from 'components';
import { SortByDesc } from 'assets';
import { TreeItem } from 'types';
import Wrapper from './styles';

const { Option } = Select;
const firstLimit = 18;
const limit = 20;

interface Props {
  tabActive: number;
  columnClosed: number | undefined;
  data: TreeItem<CurriculumItemType>[];
  itemMoveCopySelected: ItemMoveCopySelectedType | undefined;
  collapsedMenu: boolean;
  filter_conditions: {
    name?: string;
    status?: keyof typeof CurriculumStatus;
  };
  headerTitle: string;
}

const Treeview: React.FC<Props> = ({
  data,
  tabActive,
  columnClosed,
  itemMoveCopySelected,
  collapsedMenu,
  filter_conditions,
  headerTitle,
}) => {
  const { messages } = useIntl();
  const [breakPage, setBreakPage] = React.useState<TreeItem<CurriculumItemType>[]>([]);

  const genArr: any = Array.from({ length: data[0]?.children?.length || 0 }, (v, i) => []);
  const genArrAllLv: any = [];
  const flatChildren2 = (
    b: TreeItem<CurriculumItemType>,
    i: number,
    pos: number = 0,
    ref?: string
  ) => {
    if (tabActive === 0) {
      if (b) {
        if (b.children && b.children?.length > 0) {
          if (!genArrAllLv[i]) {
            genArrAllLv[i] = [{ ...omit(b, 'children'), ref: b.i_id }];
          }
          genArrAllLv[i].push(
            ...b.children.map((i, index) => ({
              ...omit(i, ['children']),
              ref: b.i_id,
              sort_order: i.columnIndex === 5 ? pos + 1 : index + 1,
            }))
          );
          for (let index = 0; index < b.children.length; index++) {
            const element = b.children[index];
            flatChildren2(element, i, index, b.i_id);
          }
        } else {
          genArr[i].push({ ...b, ref });
        }
      }
    }
  };

  const breakPageMultiInCurriculumV2 = (curriculum: any, endLineCurriculum: any) => {
    const keybyId = keyBy(curriculum, 'i_id');
    const numberPage = Math.ceil((endLineCurriculum.length - firstLimit) / limit);
    const pages: any = [];
    const defaultSortId = uniq(curriculum.map((i: any) => i.i_id));

    let lastNodePrev;
    let firstNodeCurrent;
    for (let index = 0; index < numberPage + 1; index++) {
      const start = (index - 1) * limit + firstLimit;
      const end = index * limit + firstLimit;
      const page =
        index === 0 ? endLineCurriculum.slice(0, firstLimit) : endLineCurriculum.slice(start, end);
      firstNodeCurrent = page[0];
      const nodeHidden: string[] = [];
      if (firstNodeCurrent && lastNodePrev) {
        if (firstNodeCurrent.ref === lastNodePrev.ref) {
          let prevNode = firstNodeCurrent;
          for (let i = firstNodeCurrent.columnIndex; i >= 1; i--) {
            const node = curriculum.find((i: any) => i.i_id === prevNode.ref);
            prevNode = node;
            nodeHidden.push(node.i_id);
          }
        }
      }
      lastNodePrev = page[page.length - 1];
      const keyByLevel = groupBy(page, 'columnIndex');
      let newData: any = [];
      for (let lvl = 5; lvl >= 2; lvl--) {
        const merge = keyBy([...(keyByLevel[lvl] || []), ...newData], 'i_id');
        const refByPosition: any =
          lvl === 5
            ? [...(keyByLevel[lvl] || []), ...newData]
            : defaultSortId.reduce((prev: any, current: any) => {
                if (merge[current]) {
                  prev.push(merge[current]);
                }
                return prev;
              }, []);
        const groupRef = groupBy(refByPosition, 'ref');
        newData = [];
        for (const key in groupRef) {
          const data = groupRef[key].map((i: any) => ({
            ...i,
            hidden: nodeHidden.includes(i.i_id) ? true : false,
          }));
          newData.push({ ...keybyId[key], children: data });
        }
      }
      pages.push({ ...newData[0], hidden: index > 0 ? true : false });
    }
    return pages;
  };

  useEffect(() => {
    if (data[0] && data[0].children) {
      for (let index = 0; index < data[0]!.children.length; index++) {
        const element = data[0].children[index];
        flatChildren2(element, index);
      }
    }

    const breakPage =
      genArr.length > 0
        ? genArr.reduce((acc: any, curr: any, index: number) => {
            if (acc.length === 0) {
              if (curr.length > limit) {
                const pages = breakPageMultiInCurriculumV2(genArrAllLv[index], curr);
                acc.push(
                  ...pages.map((i: any, pos: number) => ({
                    ...data[0],
                    children: [i],
                    count:
                      pos === pages.length - 1
                        ? curr.length - (firstLimit + (pages.length - 2) * limit)
                        : pos === 0
                        ? firstLimit
                        : limit,
                    totalFirst: pos === pages.length - 1 ? curr.length : 0,
                  }))
                );
              } else {
                acc.push({
                  ...data[0],
                  children: [data[0]?.children![index]],
                  count: curr.length,
                });
              }
            } else {
              if (acc[acc.length - 1].count + curr.length > limit) {
                acc.push({
                  ...data[0],
                  children: [data[0]?.children![index]],
                  count: curr.length,
                });
              } else {
                acc[acc.length - 1].children.push(data[0]?.children![index]);
                acc[acc.length - 1].count += curr.length;
              }
            }
            return acc;
          }, [])
        : [{ ...data[0], count: 1 }];

    setBreakPage(breakPage);
  }, [data[0]]);

  return (
    <>
      {breakPage.map((_page: any, _index: any) => (
        <Wrapper
          isOpenMenuRight={false}
          collapsedMenu={collapsedMenu}
          tabActive={tabActive}
          style={{ position: 'relative', background: '#F9F8F8' }}
          countLine={_page.count}
          index={_index}
        >
          {_index === 0 && (
            <Header title={headerTitle} className="header" exportPDF={true}>
              <form className="form" style={{ paddingRight: '36rem' }}>
                <FilterOutlined className="filter-icon" />
                <div className="form-input">
                  <Select
                    className="select-input"
                    placeholder={messages['M-21-4']}
                    value={filter_conditions.status}
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  >
                    {CURRICULUM_STATUS.map((crr, i) => (
                      <Option key={i} value={crr.value}>
                        {crr.label}
                      </Option>
                    ))}
                  </Select>
                </div>
                <img src={SortByDesc} className="sortByDesc-icon" alt="sort-by-desc-icon" />
                <div className="form-input">
                  <Select
                    showSearch
                    className="select-input"
                    placeholder={messages['M-21-5']}
                    value={filter_conditions.name}
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  >
                    {data.map((curr, index) => (
                      <Option key={index} value={curr.i_id}>
                        {curr.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </form>
            </Header>
          )}
          <div className="wrap-title">
            <div className="flex-label" style={{ paddingRight: '100px', paddingTop: '10px' }}>
              {LIST_LABEL.map((item, index) => (
                <p key={index} className={`label-text${index === columnClosed ? ' active' : ''}`}>
                  {index < 5 ? (
                    index === columnClosed ? (
                      <CaretUpOutlined className="icon-label" />
                    ) : (
                      <CaretDownOutlined className="icon-label" />
                    )
                  ) : null}
                  {item}
                </p>
              ))}
            </div>
          </div>
          <div className="flex">
            <div className="dashboard">
              <div className="wrap-body">
                {[_page].map((c, index) => (
                  <div
                    key={index}
                    className={`wrap-tree ${index < data?.length - 1 ? 'bordered' : ''}`}
                  >
                    <MemorizedTreview
                      treeData={c}
                      treeViewIndex={index}
                      tabActive={tabActive}
                      columnClosed={columnClosed}
                      itemMoveCopySelected={itemMoveCopySelected}
                      setItemMoveCopySelected={() => {}}
                      isIndex={0}
                      isExport={true}
                      isShowRoot={_index === 0}
                      isLastPage={_index === breakPage.length - 1}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div style={{ position: 'absolute', left: '50%', bottom: '0%' }}>- {_index + 1} -</div>
        </Wrapper>
      ))}
    </>
  );
};

export default Treeview;
