import { View, Text } from '@react-pdf/renderer';
import styles from './styles';
import React from 'react';
import { CheckOffPdf, CheckOnIconPdf } from 'assets/svgPDF';
import { Style } from '@react-pdf/types';

interface ICheckBoxProps {
  label?: string;
  checked?: boolean;
  style?: Style;
}

const CheckBox = (props: ICheckBoxProps) => {
  const { label, checked, style } = props;
  return (
    <View style={[styles.checkBox, style ? style : {}]} wrap={false}>
      {checked ? <CheckOnIconPdf style={styles.icon} /> : <CheckOffPdf style={styles.icon} />}
      <Text style={styles.label}>{label}</Text>
    </View>
  );
};

export default CheckBox;
