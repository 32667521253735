import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F9F8F8',
    fontFamily: 'NotosanJP',
    position: 'relative',
    paddingBottom: 24,
  },

  header: {
    backgroundColor: '#F9F8F8',
    padding: 9.7,
    // paddingBottom: 8,
    borderBottom: '1px solid #dddddd',
  },

  textHeader: {
    fontSize: 10.5,
    textAlign: 'center',
    color: '#424242',
  },

  body: {
    paddingHorizontal: 11.7,
    paddingVertical: 5.6,
    flexDirection: 'column',
    gap: 10,
  },

  pagePadding: {
    paddingHorizontal: 11.7,
    paddingTop: 11.7,
    paddingBottom: 24,
  },

  subscription: {
    backgroundColor: '#F9F8F8',
    paddingVertical: 17.54,
    paddingHorizontal: 11.7,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  wrapTab: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    marginTop: 8,
  },
  tab: {
    display: 'flex',
    flexDirection: 'row',
    color: '#00a3a5',
    textAlign: 'center',
    borderRadius: 1.5,
    border: 1,
    borderColor: '#00a3a5',
    paddingVertical: 5,
    // paddingLeft: 8,
    // paddingRight: 8,
    backgroundColor: '#FFFFFF',
    minHeight: 20,
    maxHeight: 60,
    position: 'relative',
    width: 115,
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    justifyContent: 'center',
  },

  selectBox: {
    textAlign: 'center',
    borderRadius: 2,
    border: 1,
    borderColor: '#CCCCCC',
    paddingVertical: 5,
    paddingLeft: 8,
    paddingRight: 16,
    backgroundColor: '#FFFFFF',
    minHeight: 19,
    maxHeight: 19,
    position: 'relative',
    width: 160,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  selectText: {
    fontSize: 7,
    color: '#424242',
    textAlign: 'center',
    maxWidth: 130,
  },

  selectIcon: {
    width: 6.75,
    height: 4,
    position: 'absolute',
    zIndex: 10,
    top: 8,
    right: 4,
    objectFit: 'cover',
  },

  button: {
    borderRadius: 5,
    border: 1,
    borderColor: '#00A3A5',
    backgroundColor: '#FFFFFF',
    padding: 5,
    color: '#00A3A5',
    fontSize: 7,
    marginLeft: 5,
  },

  divider: {
    width: 35,
    height: 1,
    marginLeft: 11.7,
    marginBottom: 11.7,
    backgroundColor: '#DDDDDD',
  },

  searchPanel: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 11.7,
    marginBottom: 11.7,
    justifyContent: 'flex-start',
  },

  label: {
    fontSize: 7,
    color: '#424242',
    paddingVertical: 2,
  },

  searchItem: {
    minWidth: 70,
    marginRight: 4,
  },

  textSubscription: {
    fontSize: 7,
    color: '#424242',
  },

  lineBorder: {
    width: 24,
    height: 1,
    backgroundColor: '#dddddd',
    marginLeft: 11.7,
  },

  textCountGeneral: {
    flexDirection: 'row',
    gap: 2,
    alignItems: 'center',
  },

  textCountNumber: {
    fontSize: 8.186,
    color: '#424242',
  },

  textCountDescription: {
    fontSize: 6.432,
    color: '#424242',
  },

  table: {
    flexDirection: 'column',
    textAlign: 'left',
    width: '100%',
    backgroundColor: '#fff',
  },

  tableHeaderRow: {
    backgroundColor: '#ebebeb',
  },

  tableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // height: 25.728,
    border: '1px solid #e5e5e5',
  },

  box: {
    borderRadius: 2,
    border: '1px solid #e5e5e5',
    backgroundColor: '#EEEEEE',
    textAlign: 'center',
    fontSize: 7,
    color: '#BBBBBB',
    paddingTop: 2,
    paddingBottom: 2,
  },

  boxActive: {
    backgroundColor: '#F6000F',
  },

  textBox: {
    textAlign: 'center',
    fontSize: 7,
    color: '#BBBBBB',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  textBoxActive: {
    color: '#fff',
  },

  wrapCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: 6.35,
    maxLines: 1,
  },

  tableCell: {
    textAlign: 'left',
    fontSize: 7,
    fontColor: '#424242',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconPdf: {
    transform: 'scale(0.7)',
  },

  headerCell: {
    color: '#2a2a2a',
    fontSize: 7.017,
    fontWeight: 'bold',
    fontFamily: 'NotosanJP',
  },

  officialWrap: {
    borderColor: 'rgba(31, 185, 219)',
    borderWidth: 1,
    borderRadius: '2px',
    width: '40px',
    backgroundColor: 'rgba(31, 185, 219, 0.1)',
  },

  official: {
    color: '#0A9FBF',
    fontSize: 5,
    textAlign: 'center',
  },
});
