import React, { useCallback } from 'react';

import * as Types from 'types';

export interface ReceivedProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => void | Promise<void>;
  node: Types.TreeItem<Types.CurriculumItemType>;
}

const useCopyCurriculum = (props: ReceivedProps) => {
  const { onSubmit, setOpen } = props;

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = async () => {
    await onSubmit();
    handleClose();
  };

  return { ...props, handleClose, handleSubmit };
};

export type Props = ReturnType<typeof useCopyCurriculum>;

export default useCopyCurriculum;
