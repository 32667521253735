import React, { useEffect, useState, memo } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';

import { Modal } from 'components';
import * as Types from 'types';
import Styled from './styles';
import { Dot } from 'assets';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  formikValue: {
    basic_info: string;
    creator: string;
  };
  officialCurriculumMaster: any;
  column: ColumnsType<Types.OfficialCurriculumMaster.ResponseType>;
}

const InformationModalViewOnly: React.FC<Props> = (props) => {
  const { isOpen, setIsOpen, formikValue, officialCurriculumMaster, column } = props;

  const [dataBasicInfo, setDataBasicInfo] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen && formikValue.basic_info) {
      const dataBasicInfoMap = formikValue.basic_info.split('\n');
      setDataBasicInfo(dataBasicInfoMap);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const renderTextWithLink = (text: string) => {
    const textMap = text.split(' ');
    return (
      <>
        {textMap.map((item, index, arr) => {
          if (item.includes('http')) {
            arr[index + 1] = ' ' + arr[index + 1];
            return (
              <a href={item} target="_blank" key={item.length * index}>
                {item}
              </a>
            );
          }
          return item + ' ';
        })}
      </>
    );
  };

  return (
    <Modal
      title="制作者情報"
      width={860}
      open={isOpen}
      cancelButton={{
        text: '閉じる',
        onClick: handleClose,
      }}
      style={{ maxHeight: 'calc(100vh - 50px)' }}
      headerStyle={{ height: '55px' }}
      bodyStyle={{
        padding: '40px',
        paddingBottom: 0,
        maxHeight: 'calc(100vh - 220px)',
        overflow: 'auto',
      }}
      footerStyle={{ paddingTop: 40, paddingBottom: '34px', height: '115px' }}
    >
      <Styled>
        <div className="creator">
          <label>制作者：</label>
          <div className="content">
            <p>{renderTextWithLink(formikValue.creator)}</p>
          </div>
        </div>
        <div className="basic_info">
          <label>基本情報：</label>
          <div className="content">
            {dataBasicInfo.map((item, index) => {
              if (item.includes('http')) {
                return renderTextWithLink(item);
              }
              if (!item) {
                return (
                  <p>
                    <br />
                  </p>
                );
              }
              return <p key={index}>{item}</p>;
            })}
          </div>
        </div>
        <div className="wrap-title-table">
          <img src={Dot} alt="" className="dot" />
          <span className="text-table">制作OFFICIALカリキュラム</span>
        </div>
        <Table
          dataSource={officialCurriculumMaster}
          columns={column}
          pagination={false}
          className="table"
        />
      </Styled>
    </Modal>
  );
};

export default memo(InformationModalViewOnly);
