import { createAsyncThunk } from '@reduxjs/toolkit';

import { AFFILIATION_ASSIGN_ROLE, POSITIONS } from 'configs';
import { browserLogger } from 'libs/logger';
import { services } from 'services';
import * as Types from 'types';

export const getDataAdminPositions = createAsyncThunk<
  Types.GetItemResponseTypeNew<Types.AdminPositions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMasterMaster/thunk/getDataAdminPositions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AdminPositions.ResponseType>(POSITIONS.name, req);
    browserLogger.info('JobTitleMasterMaster/thunk/getDataAdminPositions', POSITIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectAdminPositions = createAsyncThunk<
  Types.GetItemResponseTypeNew<Types.AdminPositions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMasterMaster/thunk/getSelectAdminPositions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AdminPositions.ResponseType>(POSITIONS.name, req);
    browserLogger.info('JobTitleMasterMaster/thunk/getSelectAdminPositions', POSITIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAdminPositions = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMasterMaster/thunk/thunk/deleteAdminPositions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(POSITIONS.name, req);
    browserLogger.info(
      'JobTitleMasterMaster/thunk/thunk/deleteAdminPositions',
      POSITIONS.name,
      data
    );
    return { ...data, item: { i_id: req.id } };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAdminPosition = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AdminPositions.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMasterMaster/thunk/createAdminPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(POSITIONS.name, req);
    browserLogger.info('JobTitleMasterMaster/thunk/createAdminPosition', POSITIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAdminPosition = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AdminPositions.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMasterMaster/thunk/updateAdminPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(POSITIONS.name, req);
    browserLogger.info('JobTitleMasterMaster/thunk/updateAdminPosition', POSITIONS.name, data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAffiliationAssignRole = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationAssignRole.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMasterMaster/thunk/getAffiliationAssignRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignRole.ResponseType>(
      AFFILIATION_ASSIGN_ROLE.name,
      req
    );
    browserLogger.info(
      'JobTitleMasterMaster/thunk/getAffiliationAssignRole',
      AFFILIATION_ASSIGN_ROLE.name,
      data
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
