import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F9F8F8',
    fontFamily: 'NotosanJP',
    position: 'relative',
    paddingBottom: 24,
  },
  subscription: {
    paddingVertical: 17.54,
    paddingHorizontal: 11.7,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textSubscription: {
    fontSize: 7,
    color: '#424242',
  },

  searchPanel: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 11.7,
    marginBottom: 8,
    gap: 4,
  },

  body: {
    paddingHorizontal: 11.7,
    paddingVertical: 5.6,
    flexDirection: 'column',
    gap: 10,
  },

  pagePadding: {
    paddingHorizontal: 11.7,
    paddingTop: 11.7,
    paddingBottom: 24,
  },

  textCountGeneral: {
    flexDirection: 'row',
    gap: 2,
    alignItems: 'center',
  },

  textCountNumber: {
    fontSize: 8.186,
    color: '#424242',
  },

  textCountDescription: {
    fontSize: 6.432,
    color: '#424242',
  },

  table: {
    flexDirection: 'column',
    textAlign: 'left',
    width: '100%',
    backgroundColor: '#fff',
  },

  tableHeaderRow: {
    backgroundColor: '#ebebeb',
  },

  tableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #e5e5e5',
    minHeight: 35,
  },

  wrapCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: 6.35,
    maxLines: 1,
  },

  wrapCellWrap: {
    height: 'auto',
    padding: 6.35,
  },

  tableCell: {
    textAlign: 'left',
    fontSize: 7.6,
    fontColor: '#424242',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  tableCellWrap: {
    textAlign: 'left',
    fontSize: 7.6,
    fontColor: '#424242',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxLines: 1,
  },

  headerCell: {
    color: '#2a2a2a',
    fontSize: 7.017,
    fontWeight: 'bold',
    fontFamily: 'NotosanJP',
  },

  status: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    alignItems: 'center',
  },

  statusIcon: {
    width: 8,
    height: 8,
  },

  statusText: {
    fontSize: 7.017,
    color: '#424242',
  },

  companyStatus: {
    borderLeft: 2,
    paddingLeft: 3,
    paddingVertical: 3,
  },

  secondaryText: {
    color: '#777777',
  },

  textCenter: {
    textAlign: 'center',
  },

  email: {
    color: '#159193',
    textDecoration: 'underline',
  },
});
