import styled from 'styled-components';

export const SectionStyled = styled.div`
  .title {
    background-color: #ffffff;
    font-size: 18px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #cccccc;
    align-items: center;
    color: #424242;
    padding: 20px 0;
    margin: 0;
  }
  .content {
    background-color: #f9f8f8;
    font-size: 13px;
    color: #424242;
    text-align: center;
    .sub-title {
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #424242;
      .icon {
        color: #bbbbbb;
        font-size: 28px;
        margin-right: 10px;
      }
    }
    .border {
      border-right: 0.5px solid #d9d9d9;
      height: 40px;
    }
    .radio-group {
      height: 40px;
      width: 400px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background: #ffffff;
      margin: auto;
      border: 1px solid #d9d9d9;
      box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
      border-radius: 3px;
      .radio-item {
        color: #424242;
        font-size: 15px;
      }
    }
    .text-content {
      font-size: 13px;
      text-align: center;
      color: #777777;
    }
  }
  .ant-radio-checked {
    margin-right: 35px;
  }
  .ant-radio {
    margin-right: 35px;
  }
`;
