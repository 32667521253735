import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  getDataInformationUser,
  getDataOfficialCurriculumUserAggregation,
  getDataOfficialCurriculumUserAggregationAdmin,
  getDataOfficialCurriculumUserAggregationPartner,
  getDataSkillCheckInformation,
  getNumberRegistedCompanies,
  getNumberTrendCompanies,
} from './thunk';
import * as Types from 'types';
import { dataDate } from './OfficialCurriculumInformation/Table';

export interface Pagination {
  current: number;
  pageSize: number;
  total: number;
}

export interface InitialState {
  loading: boolean;
  dataInformationUser: Array<Types.InformationUser>;
  total: number;
  visibleCorrespondingSale: boolean;
  listMonths: Array<dataDate>;
  dataOfficialCurriculumUserAggregation: Array<Types.OfficialCurriculumnAggregationData>;
  dataSkillCheckInformation: Array<Types.SkillCheckInformationData>;
  dataFilterSkillCheckInformation: Array<Types.SkillCheckInformationData>;
  columnsCurriculumns: Array<any>;
  activeTable: boolean;
  pagination: Pagination;
  visibleProducerExpand: boolean;
  segmented: number;
  dataOfficialCurriculumUserAggregationPartner: Array<Types.dataOfficialCurriculumUserAggregationPartner>;
  dataOfficialCurriculumUserAggregationAdmin: Array<Types.dataOfficialCurriculumUserAggregationAdmin>;
  visibleCurriculumDevelopment: boolean;
  dataNumberRegisteredCompanies: Array<Types.ReportNumberRegistedCompanies>;
  dataNumberTrendCompanies: Array<Types.ReportNumberTrendCompanies>;
}

interface ValueCurriculumn {
  items: Array<Types.OfficialCurriculumnAggregationData>;
  totalItems: number;
}

interface ValueSkillCheckInformation {
  items: Array<Types.SkillCheckInformationData>;
  totalItems: number;
}

const initialState: InitialState = {
  dataInformationUser: [],
  loading: false,
  total: 0,
  listMonths: [],
  segmented: 0,
  dataFilterSkillCheckInformation: [],
  dataOfficialCurriculumUserAggregation: [],
  dataOfficialCurriculumUserAggregationPartner: [],
  dataOfficialCurriculumUserAggregationAdmin: [],
  dataSkillCheckInformation: [],
  visibleCorrespondingSale: false,
  columnsCurriculumns: [],
  activeTable: false,
  visibleProducerExpand: false,
  pagination: {
    current: 1,
    pageSize: 100,
    total: 0,
  },
  visibleCurriculumDevelopment: false,
  dataNumberRegisteredCompanies: [],
  dataNumberTrendCompanies: [],
};
export const reportSlide = createSlice({
  name: 'report-slice',
  initialState,
  reducers: {
    setColumnCurriculumns: (state, action: PayloadAction<Array<any>>) => {
      state.columnsCurriculumns = action.payload;
    },
    setPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = action.payload;
    },
    setSegmented(state, action: PayloadAction<number>) {
      state.segmented = action.payload;
    },
    setListMonths: (state, action: PayloadAction<Array<dataDate>>) => {
      state.listMonths = action.payload;
    },
    setVisibleCorrespondingSaleSlice: (state, action: PayloadAction<boolean>) => {
      state.visibleCorrespondingSale = action.payload;
    },
    setActiveTableSlice: (state, action: PayloadAction<boolean>) => {
      state.activeTable = action.payload;
    },
    setVisibleProducerExpandSlice: (state, action: PayloadAction<boolean>) => {
      state.visibleProducerExpand = action.payload;
    },
    setVisibleCurriculumDevelopmentSlice: (state, action: PayloadAction<boolean>) => {
      state.visibleCurriculumDevelopment = action.payload;
    },

    setValueCurriculumn: (state, action: PayloadAction<ValueCurriculumn>) => {
      state.dataOfficialCurriculumUserAggregation = action.payload.items;
      state.total = action.payload.totalItems;
    },
    setValueSkillCheckInformation: (state, action: PayloadAction<ValueSkillCheckInformation>) => {
      state.dataSkillCheckInformation = action.payload.items;
      state.total = action.payload.totalItems;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getDataInformationUser.pending, startLoading)
      .addCase(getDataOfficialCurriculumUserAggregation.pending, startLoading)
      .addCase(getDataOfficialCurriculumUserAggregationPartner.pending, startLoading)
      .addCase(getDataOfficialCurriculumUserAggregationAdmin.pending, startLoading)
      .addCase(getDataSkillCheckInformation.pending, startLoading)
      .addCase(getNumberRegistedCompanies.pending, startLoading);

    builder.addCase(getDataInformationUser.fulfilled, (state, action) => {});

    builder.addCase(getDataOfficialCurriculumUserAggregation.fulfilled, (state, action) => {
      state.dataOfficialCurriculumUserAggregation = action.payload.items || [];
      state.total = action.payload.totalItems;

      stopLoading(state);
    });

    builder.addCase(getDataSkillCheckInformation.fulfilled, (state, action) => {
      state.dataSkillCheckInformation = action.payload.items;
      state.total = action.payload.totalItems;

      stopLoading(state);
    });

    builder.addCase(getNumberRegistedCompanies.fulfilled, (state, action) => {
      state.dataNumberRegisteredCompanies = action.payload.report_results;
      state.total = action.payload.totalItems;

      stopLoading(state);
    });
    builder.addCase(getNumberTrendCompanies.fulfilled, (state, action) => {
      state.dataNumberTrendCompanies = action.payload.report_results;
      state.total = action.payload.totalItems;

      stopLoading(state);
    });

    builder.addCase(getDataOfficialCurriculumUserAggregationPartner.fulfilled, (state, action) => {
      state.dataOfficialCurriculumUserAggregationPartner = action.payload.items;
      stopLoading(state);
    });

    builder.addCase(getDataOfficialCurriculumUserAggregationAdmin.fulfilled, (state, action) => {
      state.dataOfficialCurriculumUserAggregationAdmin = action.payload.items;
      stopLoading(state);
    });

    builder
      .addCase(getDataInformationUser.rejected, stopLoading)
      .addCase(getDataOfficialCurriculumUserAggregation.rejected, stopLoading)
      .addCase(getDataOfficialCurriculumUserAggregationPartner.rejected, stopLoading)
      .addCase(getDataOfficialCurriculumUserAggregationAdmin.rejected, stopLoading)
      .addCase(getDataSkillCheckInformation.rejected, stopLoading)
      .addCase(getNumberRegistedCompanies.rejected, stopLoading);
  },
});

export const {
  setColumnCurriculumns,
  setVisibleCorrespondingSaleSlice,
  setActiveTableSlice,
  setVisibleProducerExpandSlice,
  setVisibleCurriculumDevelopmentSlice,
  setValueCurriculumn,
  setListMonths,
  setSegmented,
  setValueSkillCheckInformation,
  setPagination,
  setLoading,
} = reportSlide.actions;
export default reportSlide.reducer;
