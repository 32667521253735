import styled from 'styled-components';

interface Props {
  collapsedMenu: boolean;
}

export const PartnerStyled = styled.div<Props>`
  @media (max-width: 1440px) {
    .wrap-nav {
      button {
        width: 180px;
        height: 40px;
      }
    }
    .title-partner {
      .btn-list {
        margin-right: 8px !important;
      }
      .btn-list,
      .btn-tree {
        width: 160px;
        padding: 8px !important;
        height: 40px;
      }
    }
    .form-search {
      justify-content: flex-start !important;
      .btn-search {
        width: 100px;
      }
    }
    .item {
      width: 240px !important;
      margin-right: 8px;
    }
    .btn-search,
    .label-reset {
      margin-left: 12px;
    }
    .wrap-body {
      .btn-div {
        .btn-control-number {
          width: 160px;
        }
      }
      .wrap-table {
        .table {
          .ant-table-thead {
            .ant-table-cell {
              padding: 8px 10px;
            }
          }
          .ant-table-tbody {
            .ant-table-cell {
              height: 44px;
            }
          }
        }
      }
    }
    .wrap-button {
      .btn {
        width: 160px;
      }
    }
    .table {
      .ant-table-thead {
        .ant-table-cell {
          font-size: 12px !important;
          padding: 3px 8px !important;
          height: 44px !important;
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          padding: 11px !important;
        }
      }
    }
    .wrap-bottom {
      .btn {
        width: 180px !important;
      }
    }
  }
  .ant-table {
    border: 1px solid #e5e5e5;
    overflow: hidden;
    border-radius: 3px;
    .ant-table-row:last-child {
      td {
        border: none;
      }
    }
    .ant-table-placeholder {
      .ant-table-cell {
        border: none;
      }
    }
  }
  .partner-styled {
    padding: 24px;
    background: #f9f8f8;
    min-height: calc(100vh - 151px);
  }
  .wrap-select-record {
    display: flex;
    .label-select {
      font-size: 11px;
      color: #424242;
    }
  }
  .select-option {
    width: 70px;
    height: 28px;
    font-size: 11px !important;
    .ant-select-arrow {
      top: 55%;
    }
    .ant-select-selector {
      border-radius: 3px;
    }
  }
  .title-partner {
    display: flex;
    justify-content: space-between;
    position: relative;
    ::before {
      position: absolute;
      content: '';
      width: 60px;
      height: 2px;
      border-radius: 2px;
      top: 32px;
      background: #dddddd;
    }
    .btn-list {
      margin-right: 4px;
      padding: 8px 16px;
      background: #ffffff;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 20px;
      color: #f1a900;
      cursor: pointer;
    }
    .btn-tree {
      padding: 8px 16px;
      background: #ffffff;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 20px;
      color: #f1a900;
      cursor: pointer;
    }
  }
  .form-search {
    display: flex;
    align-items: center;
    padding: 25px 0;
    .item {
      width: 20%;
      margin-right: 8px;
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
      margin-right: 12px;
      display: block;
      width: 60px;
      margin-left: 10px;
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
      .icon-search {
        font-size: 18px;
        margin-right: 8px;
      }
    }
  }
  .wrap-button {
    position: absolute;
    right: 0;
    margin: 12px 0;
    z-index: 9;
    margin-right: 42px;
    .btn {
      padding: 0 16px;
      height: 40px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      margin-left: 8px;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
    }
  }
  .wrap-button-no-data {
    right: 0;
    padding-bottom: 24px;
    z-index: 9;
    text-align: end;
    .btn {
      padding: 0 16px;
      height: 40px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      margin-left: 8px;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
    }
  }
  .wrap-bottom {
    height: 95px;
    width: 100%;
    transition: width 0.3s;
    background-color: #ffffff;
    position: sticky;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    .flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .text-label {
      font-size: 13px;
      color: #777777;
      display: flex;
      align-items: center;
    }
    .btn {
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      width: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      margin-right: 8px;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
    }
  }
  .background-color-mail {
    text-align: center;
    .anticon-mail {
      font-size: 20px;
      color: #c4c4c4;
      cursor: pointer;
    }
    .anticon-mail:hover {
      color: #00a3a5;
    }
    .anticon-file-text {
      font-size: 20px;
      color: #c4c4c4;
    }
    .anticon-file-done {
      font-size: 20px;
      color: #c4c4c4;
    }
  }
  .minister-style {
    color: #777777;
    font-style: normal;
    font-weight: 400;
  }
  .score-style {
    color: #159193;
    text-align: center;
    text-decoration-line: underline;
  }
  .question_code_table {
    color: #777777;
  }
  .title-table {
    text-align: left;
  }
  .star-button-green {
    position: relative;
    ::before {
      position: absolute;
      content: '';
      width: 3px;
      height: 38px;
      border-radius: 2px;
      top: -8px;
      background: #08a3a5;
      left: -10px;
    }
  }
  .star-button-orange {
    position: relative;
    ::before {
      position: absolute;
      content: '';
      width: 3px;
      height: 60px;
      border-radius: 2px;
      top: -19px;
      background: #f6ac00;
      left: -10px;
    }
  }
  .star-button-grey {
    position: relative;
    ::before {
      position: absolute;
      content: '';
      width: 3px;
      height: 45px;
      border-radius: 2px;
      background: #c4c4c4;
      top: -12px;
      left: -10px;
    }
  }
  .table {
    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      .ant-table-cell {
        background-color: #ebebeb;
        font-weight: 700;
        font-size: 13px;
        color: #424242;
        margin: 0 3px;
        &:before {
          display: none;
        }
      }
    }
    .ant-table-cell.name {
      text-align: left;
    }
    .ant-table-cell.author {
      text-align: left;
    }
    .ant-table-cell.code {
      text-align: left;
    }
    .ant-table-cell {
      text-align: center;
    }
  }
`;
