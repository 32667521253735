import React, { useMemo } from 'react';
import { Table } from 'antd';

import RegisteredInformationStyled from './styles';
import { ColumnType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { reportUserInformationSelector } from 'pages/Report/UserInformation/selectors';
import { get, uniq } from 'lodash';
import { EXPORT_TARGET_MONTH_COUNT } from 'constant';
import dayjs from 'dayjs';
import { formatComma } from 'libs/utils/format';
import { v4 as uuidv4 } from 'uuid';
import { AnyObject } from 'types';
import VirtualizedTable from 'components/VirtualizedTable';
import { ColumnDef } from '@tanstack/react-table';
import { CalculatedReport } from 'types/datastores/user_report_information';
import { USER_INFORMATION_COMPANY_TYPE } from 'constant/report';

interface Props {
  visibleExpansionOfSale: boolean;
  visibleUserDetail: boolean;
  exportPdf?: boolean;
}

const ANT_DEFAULT_COLUMNS: ColumnType<any>[] = [
  {
    title: '企業種類',
    dataIndex: 'company_type',
    key: 'company_type',
    fixed: 'left',
    align: 'left',
    width: 150,
  },
  {
    title: '登録情報',
    dataIndex: 'registration_information',
    key: 'registration_information',
    align: 'left',
    fixed: 'left',
    width: 150,
  },
];

const DEFAULT_COLUMNS: ColumnDef<AnyObject>[] = [
  {
    header: 'ユーザー種類 / ユーザー名・ID / 登録情報',
    accessorKey: 'name',
    size: 300,
    cell: (cell) => {
      const rowData = cell.row.original;
      return (
        <div className="company-detail">
          <div className={`company-name ${rowData.companyClassName || ''}`}>{rowData.name}</div>
          {rowData.companyId && <div className="company-id">{rowData.companyId}</div>}
        </div>
      );
    },
  },
];

const RegisteredInformation: React.FC<Props> = ({
  visibleExpansionOfSale,
  visibleUserDetail,
  exportPdf,
}) => {
  const {
    dateRanger,
    companyReports,
    calculatedIndividualUsers,
    calculatedCorporateUsers,
    calculatedPartnerReports,
    totalAll,
    filterCompanyType,
    filterCompanyName,
  } = useSelector(reportUserInformationSelector);

  const dates = useMemo(() => {
    let dateData =
      dateRanger && dateRanger.length
        ? dateRanger
        : uniq(companyReports.map((obj) => obj.target_month));
    if (exportPdf) {
      dateData = dateData.slice(0, EXPORT_TARGET_MONTH_COUNT);
    }
    return dateData;
  }, [companyReports, dateRanger, exportPdf]);

  const antColumns = useMemo(() => {
    const dateColumns = dates.map((item, index) => ({
      title: dayjs(item, 'YYYYMM').format('YYYY/MM'),
      dataIndex: item,
      key: item,
      width: 95,
      className: 'text-center',
      render: (text: string | number) =>
        text || `${text}` === '0' ? <span>{formatComma(text)}</span> : <span>-</span>,
      onCell: () => ({
        className: 'text-right',
      }),
    }));
    return [...ANT_DEFAULT_COLUMNS, ...dateColumns];
  }, [ANT_DEFAULT_COLUMNS, dates, visibleExpansionOfSale]);

  const antDataSource = useMemo(() => {
    const hasCorporate =
      !filterCompanyType || filterCompanyType === USER_INFORMATION_COMPANY_TYPE.corporateUsers;
    const hasPersonal =
      !filterCompanyType || filterCompanyType === USER_INFORMATION_COMPANY_TYPE.personalUser;
    const hasPartners =
      !filterCompanyType || filterCompanyType === USER_INFORMATION_COMPANY_TYPE.partners;
    const res = [];
    if (visibleExpansionOfSale) {
      if (hasCorporate) {
        res.push({
          id: uuidv4(),
          company_type: '法人ユーザー',
          registration_information: '新規登録企業数',
          ...(calculatedCorporateUsers || {}).company_added,
          children: [
            {
              id: uuidv4(),
              registration_information: '退会企業数',
              ...(calculatedCorporateUsers || {}).company_deleted,
            },
            {
              id: uuidv4(),
              registration_information: '月末登録ユーザー数',
              ...(calculatedCorporateUsers || {}).month_end_company,
            },
          ],
        });
      }
      if (hasPersonal) {
        res.push({
          id: uuidv4(),
          company_type: '個人ユーザー',
          registration_information: '新規登録企業数',
          ...(calculatedIndividualUsers || {}).company_added,
          children: [
            {
              id: uuidv4(),
              registration_information: '退会企業数',
              ...(calculatedIndividualUsers || {}).company_deleted,
            },
            {
              id: uuidv4(),
              registration_information: '月末登録ユーザー数',
              ...(calculatedIndividualUsers || {}).month_end_company,
            },
          ],
        });
      }
      if (hasPartners) {
        res.push({
          id: uuidv4(),
          company_type: 'パートナー企業',
          registration_information: '新規登録企業数',
          ...(calculatedPartnerReports || {}).company_added,
          children: [
            {
              id: uuidv4(),
              registration_information: '退会企業数',
              ...(calculatedPartnerReports || {}).company_deleted,
            },
            {
              id: uuidv4(),
              registration_information: '月末登録ユーザー数',
              ...(calculatedPartnerReports || {}).month_end_company,
            },
          ],
        });
      }
      return res;
    }
    if (hasCorporate) {
      res.push({
        id: uuidv4(),
        company_type: '法人ユーザー',
        registration_information: '月末登録ユーザー数',
        ...(calculatedCorporateUsers || {}).month_end_company,
      });
    }
    if (hasPersonal) {
      res.push({
        id: uuidv4(),
        company_type: '個人ユーザー',
        registration_information: '月末登録ユーザー数',
        ...(calculatedIndividualUsers || {}).month_end_company,
      });
    }
    if (hasPartners) {
      res.push({
        id: uuidv4(),
        company_type: 'パートナー企業',
        registration_information: '月末登録ユーザー数',
        ...(calculatedPartnerReports || {}).month_end_company,
      });
    }
    return res;
  }, [
    calculatedCorporateUsers,
    calculatedIndividualUsers,
    calculatedPartnerReports,
    visibleExpansionOfSale,
    filterCompanyType,
  ]);

  const columns = useMemo<ColumnDef<AnyObject>[]>(
    () => [
      ...DEFAULT_COLUMNS,
      ...dates.map((date, index) => ({
        header: dayjs(date, 'YYYYMM').format('YYYY/MM'),
        accessorKey: date,
        size: 100,
        className: 'text-center',
      })),
    ],
    [dateRanger, companyReports, dates]
  );

  const expandRowKeys = useMemo(() => {
    const rowKeys: string[] = [];
    const getRowKeys = (items: AnyObject[]) => {
      for (const item of items) {
        if (item.children) {
          rowKeys.push(item.id);
          if (item.children.length) getRowKeys(item.children);
        }
      }
    };
    getRowKeys(antDataSource);

    return rowKeys;
  }, [antDataSource]);

  const defaultDateData = useMemo(
    () =>
      dates.reduce((temp: AnyObject, item) => {
        temp[item] = '';
        return temp;
      }, {}),
    [dates]
  );

  const getCompanyChildrenData = (
    calculatedData: CalculatedReport | undefined,
    visibleExpansionOfSale: boolean
  ) => {
    let res: AnyObject[] = [];
    if (calculatedData && calculatedData.children) {
      Object.keys(calculatedData.children).forEach((companyId) => {
        const company: CalculatedReport = calculatedData.children
          ? calculatedData.children[companyId]
          : ({} as CalculatedReport);
        if (
          !filterCompanyName ||
          filterCompanyName === company.name ||
          (company.name || '').includes(filterCompanyName)
        ) {
          res.push({
            companyId: companyId,
            name: company.name,
            ...defaultDateData,
          });

          if (visibleExpansionOfSale) {
            res.push({
              name: '新規登録ユーザー数',
              companyClassName: 'text-right',
              ...company.emp_user_added,
            });
            res.push({
              name: '退会ユーザー数',
              companyClassName: 'text-right',
              ...company.emp_user_deleted,
            });
          }

          res.push({
            name: '月末登録ユーザー数',
            companyClassName: 'text-right',
            ...company.month_end_users,
            _virtualRowData: { className: 'last-child-company-row' },
          });
        }
      });
    }
    return res;
  };

  const dataSource = useMemo(() => {
    const hasCorporate =
      !filterCompanyType || filterCompanyType === USER_INFORMATION_COMPANY_TYPE.corporateUsers;
    const hasPersonal =
      !filterCompanyType || filterCompanyType === USER_INFORMATION_COMPANY_TYPE.personalUser;
    const hasPartners =
      !filterCompanyType || filterCompanyType === USER_INFORMATION_COMPANY_TYPE.partners;
    const results: AnyObject[] = [
      {
        name: '全ユーザー',
        ...(totalAll?.month_end_users || {}),
        _virtualRowData: { className: 'total-all' },
      },
    ];

    const companies = getCompanyChildrenData(calculatedCorporateUsers, visibleExpansionOfSale);
    const partners = getCompanyChildrenData(calculatedPartnerReports, visibleExpansionOfSale);

    if (hasCorporate) {
      results.push({
        name: '法人ユーザー',
        ...(calculatedCorporateUsers?.month_end_users || {}),
        children: companies,
      });
    }

    if (hasPersonal) {
      results.push({
        name: '個人ユーザー',
        ...(calculatedIndividualUsers?.month_end_users || {}),
        children: [
          {
            name: '個人ユーザー 合計',
            companyId: '',
            ...defaultDateData,
          },
          ...(visibleExpansionOfSale
            ? [
                {
                  name: '新規登録ユーザー数',
                  companyClassName: 'text-right',
                  ...(calculatedIndividualUsers?.emp_user_added || {}),
                },
                {
                  name: '退会ユーザー数',
                  companyClassName: 'text-right',
                  ...(calculatedIndividualUsers?.emp_user_deleted || {}),
                },
              ]
            : []),
          {
            name: '月末登録ユーザー数',
            companyClassName: 'text-right',
            ...(calculatedIndividualUsers?.month_end_users || {}),
            _virtualRowData: { className: 'last-child-company-row' },
          },
        ],
      });
    }

    if (hasPartners) {
      results.push({
        name: 'パートナーユーザー',
        ...(calculatedPartnerReports?.month_end_users || {}),
        children: partners,
      });
    }
    return results;
  }, [
    calculatedCorporateUsers,
    calculatedIndividualUsers,
    calculatedPartnerReports,
    visibleExpansionOfSale,
    filterCompanyType,
    filterCompanyName,
  ]);

  return (
    <RegisteredInformationStyled visibleExpansionOfSale={visibleExpansionOfSale}>
      {!visibleUserDetail && (
        <Table
          rowClassName={(record, index) => (record.sales_type === '月額利用料' ? 'child' : '')}
          className="table"
          rowKey="id"
          columns={antColumns}
          dataSource={antDataSource}
          expandable={{
            defaultExpandAllRows: true,
            expandedRowKeys: expandRowKeys,
          }}
          locale={{
            emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
          }}
          pagination={false}
          scroll={{
            x: 1440,
          }}
        />
      )}
      {visibleUserDetail && <VirtualizedTable data={dataSource} columns={columns} />}
    </RegisteredInformationStyled>
  );
};
export default RegisteredInformation;
