import React from 'react';

import CorrespondingUnChecked from './CorrespondingUnChecked';
import Corresponding from './Corresponding';

interface Props {
  visibleCorrespondingSale: boolean;
  exportPdf: boolean;
}

const Chart: React.FC<Props> = ({ visibleCorrespondingSale, exportPdf }) => {
  return visibleCorrespondingSale ? (
    <Corresponding exportPdf={exportPdf} />
  ) : (
    <CorrespondingUnChecked exportPdf={exportPdf} />
  );
};

export default Chart;
