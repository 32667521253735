import { omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import * as Types from 'types';
import { AnyObject } from 'types';

const walkDescendants = ({
  callback,
  isPseudoRoot = false,
  node,
  parentNode = null,
  currentIndex,
  path = [],
  currentLineIndex,
  columnIndex = 0,
}: Types.WalkDescendantsType<Types.CurriculumItemType>): {
  childIndex: number | false;
  childLineIndex: number | false;
} => {
  const selfPath = isPseudoRoot ? [] : [...path, Number(currentIndex)];
  const selfInfo = isPseudoRoot
    ? null
    : {
        node,
        parentNode,
        path: selfPath,
        lineIndex: currentLineIndex,
        columnIndex,
      };

  if (!isPseudoRoot) {
    const callbackResult = callback(selfInfo);

    // Cut walk short if the callback returned false
    if (callbackResult === false) {
      return { childIndex: false, childLineIndex: false };
    }
  }

  // Return self on nodes with no children or hidden children
  if (!node.children) {
    return { childIndex: currentIndex, childLineIndex: currentLineIndex };
  }

  // Get all descendants
  let childIndex = currentIndex;
  let childLineIndex = currentLineIndex;

  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    const result = walkDescendants({
      callback,
      node: node.children[i],
      parentNode: isPseudoRoot
        ? null
        : ({
            ...node,
            lineIndex: currentLineIndex,
          } as Types.TreeItem<Types.CurriculumItemType>),
      currentIndex: +childIndex + 1,
      path: selfPath,
      columnIndex: isPseudoRoot ? 0 : columnIndex + 1,
      currentLineIndex:
        selfPath[selfPath.length - 1] !== +childIndex || i > 0 || isPseudoRoot
          ? +childLineIndex + 1
          : childLineIndex,
    });

    childIndex = result.childIndex;
    childLineIndex = result.childLineIndex;
    // Cut walk short if the callback returned false
    if (childIndex === false || childLineIndex === false) {
      return { childIndex: false, childLineIndex: false };
    }
  }

  return { childIndex, childLineIndex };
};

export const walk = ({ treeData, callback }: Types.WalkType<Types.CurriculumItemType>) => {
  if (!treeData) {
    return;
  }

  walkDescendants({
    callback,
    isPseudoRoot: true,
    node: { children: [treeData] },
    currentIndex: -1,
    currentLineIndex: 0,
    path: [],
    columnIndex: 0,
  });
};

export const getFlatDataFromTree = ({
  treeData,
}: Types.FlatDataFromTreeType<Types.CurriculumItemType>): Array<
  Types.FlatDataItem<Types.CurriculumItemType>
> => {
  if (!treeData) {
    return [];
  }

  const flattened: Array<Types.FlatDataItem<Types.CurriculumItemType>> = [];
  walk({
    treeData,
    callback: (nodeInfo: Types.FlatDataItem<Types.CurriculumItemType>) => {
      flattened.push(nodeInfo);
    },
  });

  return flattened;
};

const convertData = ({
  node,
  item,
  items,
  strings,
}: {
  node: Types.TreeItem<Types.CurriculumItemType>;
  item: any;
  items: any[];
  strings: string[];
}) => {
  if (!node.children || node.columnIndex === 5) {
    return;
  }

  const currentItem = node.children.find((i) =>
    Object.keys(item).some((key) => item[key] === i.code)
  );
  if (!currentItem && node.columnIndex !== undefined) {
    if (
      node.columnIndex < 4 &&
      item[`level${node.columnIndex + 1}_i_id`] &&
      (item[`level${node.columnIndex}_code`] === node.code || item.curriculum_code === node.code)
    ) {
      node.children.push({
        i_id: item[`level${node.columnIndex + 1}_i_id`],
        code: item[`level${node.columnIndex + 1}_code`],
        columnIndex: node.columnIndex + 1,
        sort_order: Number(item[`level${node.columnIndex + 1}_sort_order`]),
        name: item[`level${node.columnIndex + 1}_name`],
        publish: Number(node.publish),
        required_curriculum: Number(node.required_curriculum),
        totalQuestions: getTotalQuestionCurriculum(items, item),
        totalLevelQuestions: getTotalQuestionAtLevel(items, item, node.columnIndex + 1),
        children: [],
      });
    } else if (item.level4_code === node.code && item.question_code_i_id) {
      strings.push(item.curriculum_id);
      node.children.push({
        i_id: item.question_code_i_id,
        code: item.question_code,
        columnIndex: 5,
        question_assign_level_i_id: item.question_assign_level_i_id,
        sort_order: Number(item.question_sort_order),
        publish: Number(node.publish),
        required_curriculum: Number(node.required_curriculum),
        name: item.question_name,
      });
    }
  }

  // Get all descendants
  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    convertData({
      node: node.children[i],
      item,
      items,
      strings,
    });
  }

  return;
};

export const convertDataFromTree = ({
  treeData,
  item,
  items,
  strings,
}: {
  treeData: Types.TreeItem<Types.CurriculumItemType>;
  item: any;
  items: any[];
  strings: string[];
}) => {
  if (!treeData) {
    return {};
  }

  convertData({
    item,
    node: { children: [treeData] },
    items,
    strings,
  });
  return treeData;
};

const childrenItemID = ({
  node,
  callback,
}: {
  node: Types.TreeItem<Types.CurriculumItemType>;
  callback: Function;
}) => {
  if (node.i_id) {
    callback(omit(node, ['children']));
  }

  if (!node.children || node.columnIndex === 5) {
    return;
  }

  // Get all descendants
  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    childrenItemID({
      node: node.children[i],
      callback,
    });
  }

  return;
};

export const getChildrenItemID = ({
  treeData,
  callback,
}: {
  treeData: Types.TreeItem<Types.CurriculumItemType>;
  callback: Function;
}) => {
  if (!treeData) {
    return;
  }

  childrenItemID({
    callback,
    node: { children: [treeData] },
  });
};

export const getChildrenItemIDFromTree = ({
  treeData,
}: {
  treeData: Types.TreeItem<Types.CurriculumItemType>;
}): Array<Types.FlatChildrenItemID<Types.CurriculumItemType>> => {
  if (!treeData) {
    return [];
  }

  const flattened: Array<Types.FlatChildrenItemID<Types.CurriculumItemType>> = [];
  getChildrenItemID({
    treeData,
    callback: (nodeInfo: Types.FlatChildrenItemID<Types.CurriculumItemType>) => {
      flattened.push(nodeInfo);
    },
  });

  return flattened;
};

export const convertDataFromImportFile = ({
  treeData,
  item,
}: {
  treeData: Types.ImportCurriculumType;
  item: any;
}) => {
  if (!treeData) {
    return {};
  }

  convertDataImportFile({
    item,
    node: { children: [treeData] },
  });

  return treeData;
};

const checkParentPath = (item: AnyObject, node: Types.ImportCurriculumType) => {
  return node.path?.reduce((res, i, idx) => {
    if (idx === 0) {
      return (
        res &&
        (i === item.curriculum_name || item.same_curriculum_flag === node.same_curriculum_flag)
      );
    }
    return res && i === item[`level${idx}_name`];
  }, true);
};

const checkExisted = (item: AnyObject, node: Types.ImportCurriculumType) => {
  return (
    checkParentPath(item, node) &&
    (((node.level || 0) < 5 && node.name === item[`level${node.level || 0}_name`]) ||
      (node.level === undefined && node.code === item.question_code))
  );
};

const convertDataImportFile = ({ node, item }: { node: Types.ImportCurriculumType; item: any }) => {
  if (!node.children || node.level === 5) {
    return;
  }

  const currentItem = node.children.find((i) => checkExisted(item, i));

  if (!currentItem && node.level !== undefined && checkParentPath(item, node)) {
    if (
      node.level < 4 &&
      (item[`level${node.level}_name`] === node.name ||
        (node.level === 0 && item.same_curriculum_flag === node.same_curriculum_flag))
    ) {
      node.children.push({
        id: uuidv4(),
        level: node.level + 1,
        name: item[`level${node.level + 1}_name`],
        path: [...(node.path || []), node.name],
        same_curriculum_flag: item.same_curriculum_flag,
        children: [],
      });
    } else if (item.question_code && item.level4_name === node.name) {
      node.children.push({
        code: item.question_code,
      });
    }
  }

  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    convertDataImportFile({
      node: node.children[i],
      item,
    });
  }

  return;
};

export const getTotalQuestionAtLevel = (items: any[], item: any, level: number) => {
  const key = `level${level}_i_id`;
  return items && item[key]
    ? items
        .filter(
          (obj) => obj.i_id === item.i_id && item[key] === obj[key] && !!obj.question_code_i_id
        )
        .map((obj) => obj.question_code_i_id).length
    : 0;
};

export const getTotalQuestionCurriculum = (items: any[], item: any) =>
  items && item
    ? items
        .filter((obj) => obj.i_id === item.i_id && !!obj.question_code_i_id)
        .map((obj) => obj.question_code_i_id).length
    : 0;

export const findSomeNodeNotAssignQuestion = (
  nodes: Array<Types.TreeItem<Types.CurriculumItemType>>,
  hierarchy: Array<{ i_id: string; name: string }> = []
): Array<{ i_id: string; name: string }> => {
  for (let index = 0; index < nodes.length; index++) {
    if (
      nodes[index].columnIndex !== 5 &&
      ((nodes[index].columnIndex !== 4 && !nodes[index].children?.length) ||
        (nodes[index].columnIndex === 4 && !nodes[index].children?.length))
    ) {
      hierarchy.push({ i_id: nodes[index].i_id!, name: nodes[index]?.name! });
    }

    if (nodes[index].children && nodes[index].children?.length) {
      const nodesFinded = findSomeNodeNotAssignQuestion(nodes[index].children || [], [
        ...hierarchy,
      ]);
      if (nodesFinded) {
        hierarchy = nodesFinded;
      }
    }
  }
  return hierarchy;
};
