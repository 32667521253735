import ModalPreviewImage from 'components/Modal/ModalPreviewImage';
import React, { useState } from 'react';

const useImagePreview = () => {
  const [visible, setVisible] = useState<number>();
  const [imgPreview, setImgPreview] = useState('');

  const handlePreviewImage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const targetElement = e.target as HTMLImageElement;
    if (targetElement.tagName === 'IMG') {
      setImgPreview(targetElement.src);
      setVisible(1);
    }
  };

  const ImagePreview: React.FC = () => {
    return (
      <ModalPreviewImage
        index={1}
        isPreviewVisible={visible}
        item={imgPreview}
        setPreviewImageAnswer={setVisible}
      />
    );
  };

  return { handlePreviewImage, ImagePreview };
};

export default useImagePreview;
