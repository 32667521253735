import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BarChartOutlined, TableOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Checkbox, Select } from 'antd';

import { DatePicker, SelectField } from 'components';
import UserInformationStyled from './styles';
import Chart from './Chart';
import Table from './Table';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks';
import { reportUserInformationSelector } from 'pages/Report/UserInformation/selectors';
import { getCompanySelect, getReportUserInformation } from 'pages/Report/UserInformation/thunk';
import * as Types from 'types';
import { AnyObject } from 'types';
import { getMonthListFromDateRanger } from 'libs/utils/calculate';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import {
  setDateRanger,
  setFilterCompanyName,
  setFilterCompanyType,
} from 'pages/Report/UserInformation/slice';
import { uniq } from 'lodash';
import { USER_INFORMATION_COMPANY_TYPE } from 'constant/report';
import ModalConfirmExport from 'components/Modal/ConfirmExportFile';
import dayjs from 'dayjs';
import { exportCsv } from 'libs/utils/exportCsv';
import {
  formatObjectData,
  getUserReportCompanyData,
  getUserReportCompanyDataDetail,
} from 'libs/utils/report';
import { CalculatedReport } from 'types/datastores/user_report_information';
import PDFUserInformation from 'pages/Report/UserInformation/PDFUserInformation';
import { exportPDFFitOnePage } from 'libs/utils/exportPDF';
import { pdf } from '@react-pdf/renderer';
import PDFInformationUser from 'pages/Report/UserInformation/Table/PDFInformationUser';
import saveAs from 'file-saver';
import PDFRegisteredInformation from 'pages/Report/UserInformation/Table/PDFRegisteredInformation';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface UserInformationProp {
  openConfirmExport: boolean;
  setOpenConfirmExport: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserInformation: React.FC<UserInformationProp> = (props) => {
  const { openConfirmExport, setOpenConfirmExport } = props;

  const [visibleExpansionOfSale, setVisibleExpansionOfSale] = useState<boolean>(false);
  const [visibleUserDetail, setVisibleUserDetail] = useState<boolean>(false);
  const [segmented, setSegmented] = useState<number>(0);
  const [selectedDates, setSelectedDates] = useState<any>([]);
  const [valueSelect, setValueSelect] = useState<number | undefined>();
  const [tempValueSelect, setTempValueSelect] = useState<number | undefined>();

  const ref = useRef(null);

  const {
    dateRanger,
    calculatedCorporateUsers,
    calculatedPartnerReports,
    calculatedIndividualUsers,
    calculatedCompanyReports,
    totalAll,
    filterCompanyType,
    filterCompanyName,
    companies,
    partners,
  } = useSelector(reportUserInformationSelector);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      aggregation_method: undefined,
      target_month: [],
      companyName: undefined,
      companyType: undefined,
    },
    onSubmit: async (values) => {
      setValueSelect(values.aggregation_method);
      dispatch(setDateRanger(values.target_month));
      dispatch(setFilterCompanyType(values.companyType));
      dispatch(setFilterCompanyName(values.companyName));
      await fetchData();
    },
    onReset: () => {
      setValueSelect(undefined);
      setSelectedDates([]);
      dispatch(setFilterCompanyType(undefined));
      dispatch(setFilterCompanyName(undefined));
      formik.setFieldValue('aggregation_method', undefined);
      formik.setFieldValue('target_month', []);
      setTempValueSelect(undefined);
    },
  });

  useEffect(() => {
    dispatch(getCompanySelect({ page: 1, per_page: 0 }));
  }, []);

  const fetchData = async () => {
    if (!selectedDates || !selectedDates.length || !selectedDates[0] || !selectedDates[1]) {
      return;
    }
    dispatch(startLoading());
    await dispatch(
      getReportUserInformation({ page: 1, per_page: 0, conditions: getConditions(formik.values) })
    );
    dispatch(stopLoading());
  };

  const userFilterOptions = useMemo(() => {
    if (!valueSelect) {
      return [];
    }
    const companyNames = companies.flatMap((obj) =>
      obj.name || obj.company_name ? [obj.name || obj.company_name] : []
    );

    if (valueSelect === 1) {
      const partnerNames = partners.map((obj) => obj.name || obj.company_name);
      return uniq([...companyNames, ...partnerNames]);
    }
    if (valueSelect === 2) {
      return uniq([...companyNames]);
    }
    return [];
  }, [calculatedCorporateUsers, calculatedPartnerReports, calculatedIndividualUsers, valueSelect]);

  const getConditions = (values: AnyObject) => {
    const conditions: Array<Types.ConditionsType> = [];
    Object.keys(values).forEach((key) => {
      if (values[key as keyof typeof values] && !['user_type', 'sale_type'].includes(key)) {
        const value = values[key as keyof typeof values];
        conditions.push({
          id: key,
          search_value:
            key === 'target_month'
              ? getMonthListFromDateRanger(value?.[0], value?.[1])
              : [String(value)],
          exact_match: true,
        });
      }
    });
    return conditions;
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setVisibleExpansionOfSale(e.target.checked);
  };

  const requiredCondition = valueSelect === undefined || !selectedDates || !selectedDates.length;

  const onChangeReportType = (value: number) => {
    formik.setFieldValue('aggregation_method', value);
    formik.setFieldValue('companyType', undefined);
    formik.setFieldValue('companyName', undefined);
    setTempValueSelect(value);
  };

  const handleExport = async (value: string) => {
    if (value === 'pdf') {
      await handleExportPdf();
    } else {
      handleExportCsv();
    }
    setOpenConfirmExport(false);
  };

  const handleExportPdf = async () => {
    if (valueSelect === 2 && !segmented) {
      const blob = await pdf(
        <PDFInformationUser
          filters={formik.values}
          reportType={valueSelect}
          rangerDate={selectedDates}
          dateRanger={dateRanger}
          listSelectUsers={userFilterOptions}
          calculatedData={calculatedCorporateUsers}
        ></PDFInformationUser>
      ).toBlob();
      saveAs(blob, 'ユーザー情報.pdf');
      return true;
    }
    if (valueSelect === 1 && visibleUserDetail && !segmented) {
      const blob = await pdf(
        <PDFRegisteredInformation
          filters={formik.values}
          reportType={valueSelect}
          rangerDate={selectedDates}
          dateRanger={dateRanger}
          listSelectUsers={userFilterOptions}
          calculatedCorporateUsers={calculatedCorporateUsers}
          totalAll={totalAll}
          calculatedPartnerReports={calculatedPartnerReports}
          calculatedIndividualUsers={calculatedIndividualUsers}
          visibleUserDetail={visibleUserDetail}
          visibleExpansionOfSale={visibleExpansionOfSale}
        ></PDFRegisteredInformation>
      ).toBlob();
      saveAs(blob, 'ユーザー情報.pdf');
      return true;
    }
    exportPDFFitOnePage(ref, 'ユーザー情報.pdf', 'l', true);
  };

  const handleExportCsv = () => {
    if (valueSelect === 0) {
      exportCsvNumberOfRegisteredCompanies();
    }
    if (valueSelect === 1 && !visibleUserDetail) {
      exportCsvNumberOfUser();
    }

    if (valueSelect === 1 && visibleUserDetail) {
      exportCsvNumberOfUserDetail();
    }

    if (valueSelect === 2) {
      exportCsvMaxUserRegistered();
    }
  };

  const exportCsvNumberOfRegisteredCompanies = () => {
    const headers = [
      { label: '企業種類', key: 'business_type' },
      { label: '登録情報', key: 'registration_information' },
      ...(dateRanger || []).map((date) => ({
        label: dayjs(date, 'YYYYMM').format('YYYY/MM'),
        key: date,
      })),
    ];
    let listCsv: AnyObject[] = [
      ...getUserReportCompanyData('一般企業', visibleExpansionOfSale, calculatedCompanyReports),
      ...getUserReportCompanyData(
        'パートナー企業',
        visibleExpansionOfSale,
        calculatedPartnerReports
      ),
    ];
    exportCsv(listCsv, headers, 'ユーザー情報.csv');
  };

  const exportCsvNumberOfUser = () => {
    const headers = [
      { label: '企業種類', key: 'business_type' },
      { label: '登録情報', key: 'registration_information' },
      ...(dateRanger || []).map((date) => ({
        label: dayjs(date, 'YYYYMM').format('YYYY/MM'),
        key: date,
      })),
    ];
    const hasCorporate =
      !filterCompanyType || filterCompanyType === USER_INFORMATION_COMPANY_TYPE.corporateUsers;
    const hasPersonal =
      !filterCompanyType || filterCompanyType === USER_INFORMATION_COMPANY_TYPE.personalUser;
    const hasPartners =
      !filterCompanyType || filterCompanyType === USER_INFORMATION_COMPANY_TYPE.partners;

    let listCsv: AnyObject[] = [
      ...(hasCorporate
        ? getUserReportCompanyData('法人ユーザー', visibleExpansionOfSale, calculatedCorporateUsers)
        : []),
      ...(hasPersonal
        ? getUserReportCompanyData(
            '個人ユーザー',
            visibleExpansionOfSale,
            calculatedIndividualUsers
          )
        : []),
      ...(hasPartners
        ? getUserReportCompanyData(
            'パートナー企業',
            visibleExpansionOfSale,
            calculatedPartnerReports
          )
        : []),
    ];
    exportCsv(listCsv, headers, 'ユーザー情報.csv');
  };

  const exportCsvNumberOfUserDetail = () => {
    const headers = [
      { label: '企業種類', key: 'business_type' },
      { label: 'ユーザー名', key: 'company_name' },
      { label: 'ユーザーID', key: 'company_id' },
      { label: '登録情報', key: 'registration_information' },
      ...(dateRanger || []).map((date) => ({
        label: dayjs(date, 'YYYYMM').format('YYYY/MM'),
        key: date,
      })),
    ];
    const hasCorporate =
      !filterCompanyType || filterCompanyType === USER_INFORMATION_COMPANY_TYPE.corporateUsers;
    const hasPersonal =
      !filterCompanyType || filterCompanyType === USER_INFORMATION_COMPANY_TYPE.personalUser;
    const hasPartners =
      !filterCompanyType || filterCompanyType === USER_INFORMATION_COMPANY_TYPE.partners;

    let listCsv: AnyObject[] = [
      {
        business_type: '全ユーザー',
        company_name: '-',
        company_id: '-',
        registration_information: '-',
        ...formatObjectData((totalAll || {}).emp_user_added),
      },
      ...(hasCorporate
        ? getUserReportCompanyDataDetail(
            '法人ユーザー',
            visibleExpansionOfSale,
            filterCompanyName,
            calculatedCorporateUsers
          )
        : []),
      ...(hasPersonal
        ? getUserReportCompanyDataDetail(
            '個人ユーザー',
            visibleExpansionOfSale,
            filterCompanyName,
            calculatedIndividualUsers
          )
        : []),
      ...(hasPartners
        ? getUserReportCompanyDataDetail(
            'パートナー企業',
            visibleExpansionOfSale,
            filterCompanyName,
            calculatedPartnerReports
          )
        : []),
    ];
    exportCsv(listCsv, headers, 'ユーザー情報.csv');
  };

  const exportCsvMaxUserRegistered = () => {
    const headers = [
      { label: 'ID', key: 'company_id' },
      { label: 'ユーザー名', key: 'company_name' },
      ...(dateRanger || []).map((date) => ({
        label: dayjs(date, 'YYYYMM').format('YYYY/MM'),
        key: date,
      })),
    ];
    let listCsv: AnyObject[] = [
      {
        company_name: '-',
        company_id: '法人ユーザー',
        ...formatObjectData((calculatedCorporateUsers || {}).max_users),
      },
    ];
    if (calculatedCorporateUsers && calculatedCorporateUsers.children) {
      Object.keys(calculatedCorporateUsers.children).forEach((companyId) => {
        const company = calculatedCorporateUsers.children
          ? calculatedCorporateUsers.children[companyId]
          : ({} as CalculatedReport);
        if (!filterCompanyName || (company.name || '').includes(filterCompanyName)) {
          listCsv.push({
            company_name: company.name,
            company_id: companyId,
            ...formatObjectData((company || {}).max_users),
          });
        }
      });
    }
    exportCsv(listCsv, headers, 'ユーザー情報.csv');
  };

  return (
    <>
      {openConfirmExport && (
        <div
          ref={ref}
          style={{
            position: 'absolute',
            width: 1512,
            right: 9999,
          }}
        >
          <PDFUserInformation
            formik={formik}
            visibleExpansionOfSale={visibleExpansionOfSale}
            visibleUserDetail={visibleUserDetail}
            segmented={segmented}
            valueSelect={valueSelect}
            selectedDates={selectedDates}
            userFilterOptions={userFilterOptions}
          />
        </div>
      )}
      <UserInformationStyled>
        <div className="container">
          <div className="wrap-filter">
            <span className="label">集計条件</span>
            <div className="aggregation-conditions">
              <FormikProvider value={formik}>
                <Form layout="vertical">
                  <div className="form-search">
                    <Form.Item
                      name="aggregation_method"
                      className="item"
                      label={
                        <span className="text-label">
                          集計方法選択&nbsp;<span className="required">*</span>
                        </span>
                      }
                    >
                      <Select
                        onChange={onChangeReportType}
                        value={tempValueSelect}
                        showSearch
                        allowClear
                        placeholder="指定なし"
                        filterOption={(input, option) =>
                          JSON.stringify(option?.children)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                      >
                        <Option value={0}>登録企業数推移</Option>
                        <Option value={1}>ユーザー数推移</Option>
                        <Option value={2}>月間最大登録ユーザー数推移</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="target_month"
                      className="item"
                      label={
                        <span className="text-label">
                          集計期間&nbsp;<span className="required">*</span>
                        </span>
                      }
                    >
                      <RangePicker
                        allowClear
                        className="date"
                        name="date-item"
                        format="YYYY/MM"
                        picker="month"
                        placeholder={['集計開始日', '集計終了日']}
                        value={selectedDates}
                        onChange={(dates) => {
                          setSelectedDates(dates);
                          if (dates) {
                            const start = dates[0] ? dates[0].format('YYYYMM') : '';
                            const end = dates[1] ? dates[1].format('YYYYMM') : '';
                            formik.setFieldValue('target_month', [start, end]);
                          } else {
                            formik.setFieldValue('target_month', [null, null]);
                          }
                        }}
                        getPopupContainer={(triggerNode) => triggerNode.parentElement!}
                      />
                    </Form.Item>
                    {tempValueSelect === 1 && (
                      <Form.Item
                        name="companyType"
                        className="item"
                        label={<span className="text-label">ユーザー種類</span>}
                      >
                        <SelectField
                          name="companyType"
                          showSearch
                          allowClear
                          placeholder="指定なし"
                          filterOption={(input, option) =>
                            JSON.stringify(option?.children)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value={''}>ALL</Option>
                          {Object.values(USER_INFORMATION_COMPANY_TYPE).map((item) => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                        </SelectField>
                      </Form.Item>
                    )}
                    {!!tempValueSelect && [1, 2].includes(tempValueSelect) && (
                      <Form.Item
                        name="companyName"
                        className="item"
                        label={<span className="text-label">ユーザー検索</span>}
                      >
                        <SelectField
                          name="companyName"
                          showSearch
                          allowClear
                          placeholder="指定なし"
                          filterOption={(input, option) =>
                            JSON.stringify(option?.children)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {userFilterOptions.map((item) => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                        </SelectField>
                      </Form.Item>
                    )}
                    <div className="group-btn" style={{ marginLeft: 'auto' }}>
                      <span className="label-reset" onClick={() => formik.resetForm()}>
                        リセット
                      </span>
                      <SubmitButton className="btn-search" loading={false}>
                        表示
                      </SubmitButton>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
          <div className="sub-container">
            <div className="wrap-segmented">
              <div className="left-side">
                <span className="label">レポートタイプ：</span>
                <div className="segmented">
                  <div
                    className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                    onClick={() => {
                      setSegmented(0);
                    }}
                  >
                    <TableOutlined className="icon" />
                    <span>表</span>
                  </div>
                  <div
                    className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                    onClick={() => {
                      setSegmented(1);
                    }}
                  >
                    <BarChartOutlined className="icon" />
                    <span>グラフ</span>
                  </div>
                </div>
                {valueSelect !== 2 ? (
                  <>
                    <div className="item-checkbox">
                      <Checkbox checked={visibleExpansionOfSale} onChange={onChange}>
                        登録情報内訳展開
                      </Checkbox>
                    </div>
                    {valueSelect != null && [1].includes(valueSelect) && (
                      <div className="item-checkbox">
                        <Checkbox
                          checked={visibleUserDetail}
                          onChange={(e) => setVisibleUserDetail(e.target.checked)}
                        >
                          ユーザー展開
                        </Checkbox>
                      </div>
                    )}
                  </>
                ) : null}
              </div>
            </div>
            {requiredCondition && (
              <div className="errorPanel">
                {valueSelect === undefined
                  ? '集計条件を選択してください'
                  : '集計期間を選択してください'}
              </div>
            )}
            {!requiredCondition && segmented === 0 && (
              <Table
                visibleExpansionOfSale={visibleExpansionOfSale}
                visibleUserDetail={visibleUserDetail}
                valueSelect={valueSelect}
              />
            )}
            {!requiredCondition && segmented !== 0 && (
              <Chart visibleExpansionOfSale={visibleExpansionOfSale} valueSelect={valueSelect} />
            )}
          </div>
        </div>
      </UserInformationStyled>
      <ModalConfirmExport
        visible={openConfirmExport}
        setVisible={setOpenConfirmExport}
        onSubmit={handleExport}
        exportPdfDescription="エクスポートは開始月から12か月間のみ実行されます。"
      />
    </>
  );
};

export default UserInformation;
