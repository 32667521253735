import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CaretLeftOutlined, CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { generatePath, useNavigate } from 'react-router-dom';
import { differenceBy, every } from 'lodash';
import { Button, Radio, Modal } from 'antd';
import JoditEditor from 'jodit-pro-react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Form } from 'formik-antd';

import {
  useJoditTranslateMutationObserver,
  JODIT_CUSTOM_LOCALIZATION,
} from 'libs/utils/translateJodit';
import {
  PUBLISHED_RELEASE_NOTE,
  PUBLISHED_RELEASE_NOTE_SECTION,
  RELEASE_NOTE_SECTION,
} from 'configs';
import { StopPublic, TickComplete, UnPublished, WhiteEditing } from 'assets';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import DeleteCompleted from 'components/Modal/DeleteCompleted';
import { convertBase64ToFile } from 'libs/utils/convertBase64';
import { authSelector } from 'containers/Auth/selectors';
import { getPresignedUrlByFileID, uploadFileToR2 } from 'services/r2Service';
import { publicManagerSelector } from '../../selectors';
import TemporarilySave from '../TemporarilySave';
import ExecuteDelete from '../ExecuteDelete';
import { Input, TextArea } from 'components';
import { routes } from 'navigations/routes';
import UpdateStatus from '../UpdateStatus';
import ManageReleaseStyled from './styles';
import { useAppDispatch } from 'hooks';
import Published from '../Published';
import Warning from '../Warning';
import * as Types from 'types';
import { publicManagement } from 'libs/validations';
import {
  createFileAttach,
  createPublishedReleaseNote,
  deleteReleaseNotePublished,
  deleteReleaseNoteSectionPublished,
  executeBatchAction,
  executeBatchCreateReleaseNote,
  getDataAdminReleaseNoteManagement,
  getDataReleaseNoteID,
  getReleaseNoteDetail,
  removeReleaseNote,
  removeReleaseSectionNote,
  updateOfficialCurriculumMaster,
  updateReleaseNote,
} from '../../thunk';
import VideoPopup from '../UploadVideo';
import { convertBreakTwoColumn } from '../../../../libs/utils/convertBreakTwoColumn';
import { browserLogger } from 'libs/logger';

interface Props {
  selected?: Types.AdminReleaseNoteManagement.ResponseType;
  publish: number;
  visible: boolean;
  page: number;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleComplete: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCurriculum: Types.OfficialCurriculumPublishingSettings.ResponseType;
}

type Section = {
  section_name: string;
  text: string;
  index?: number;
  i_id?: string;
};

const NoteReleaseNew: React.FC<Props> = ({
  visible,
  setVisible,
  publish,
  selected,
  setVisibleComplete,
  selectedCurriculum,
}) => {
  const [visiblePublishWarning, setVisiblePublishWarning] = useState<boolean>(false);
  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState<boolean>(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [visiblePublished, setVisiblePublished] = useState<boolean>(false);
  const [visibleTemporaly, setVisibleTemporaly] = useState<boolean>(false);
  const [visibleDeleted, setVisibleDeleted] = useState<boolean>(false);
  const [visibleWarning, setVisibleWarning] = useState<boolean>(false);
  const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const [indexSection, setIndexSection] = useState<number>(0);
  const [showPopup, setShowPopup] = useState(false);

  const { releaseNoteDetail } = useSelector(publicManagerSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const updateOfficialCurriculum = async () => {
    if (!selectedCurriculum?.i_id_curriculum_master) return;

    await dispatch(
      updateOfficialCurriculumMaster({
        id: selectedCurriculum?.i_id_curriculum_master,
        data: {
          item: {
            updatedat: new Date().toDateString(),
          },
        },
      })
    );
  };

  const formik = useFormik<Types.RelaseNoteFormik>({
    initialValues: releaseNoteDetail ?? {
      title: '',
      description: '',
      version: 1.0,
      official_curriculum_code: '',
      release_note_id: '',
      sections: [
        {
          section_name: '',
          text: '',
        },
      ],
      release_schedule_information: '',
    },
    validationSchema: publicManagement,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      if (userInfo && selected && values) {
        dispatch(startLoading());

        const createSections: Array<Section> = [];
        const updateSections: Array<Section> = [];

        const resultAction = await dispatch(
          updateReleaseNote({
            id: selected?.i_id,
            data: {
              item: {
                release_note_title: values.title,
                publish_status:
                  !selected.publish || selected.publish === 2 || selected.publish === 3 ? 0 : 1,
                description: values.description,
                version: values.version,
                updatedby: userInfo.login_id,
                updatedat: new Date(),
                release_schedule_information: values.release_schedule_information,
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        );

        if (updateReleaseNote.fulfilled.match(resultAction)) {
          await updateOfficialCurriculum();

          const listSectionDeleted = differenceBy(
            releaseNoteDetail?.sections,
            values.sections,
            'i_id'
          );

          const formattedSections = await Promise.all(
            values.sections.map(async (value, index) => {
              const newContent = await handleFormatContent(value.text);
              if (!value.i_id) {
                return {
                  operation: 1,
                  item: {
                    company_id: userInfo.company_id,
                    section_name: value.section_name,
                    release_note_id: formik.values.release_note_id,
                    text: newContent,
                    display_order: index,
                    createdby: userInfo.login_id,
                    createdat: new Date(),
                  },
                  access_key_updates: {
                    roles_to_publish: ['MEMBER' as const],
                  },
                  realtime_auto_link: true,
                };
              }
              return {
                operation: 2,
                i_id: value.i_id,
                item: {
                  section_name: value.section_name,
                  display_order: index,
                  text: newContent,
                  updatedby: userInfo.login_id,
                  updatedat: new Date(),
                },
                is_force_update: true,
                access_key_updates: {
                  roles_to_publish: ['MEMBER' as const],
                },
                realtime_auto_link: true,
              };
            })
          );

          const formattedSectionsCreated =
            createSections.length &&
            (await Promise.all(
              createSections.map(async (value, index) => {
                const newContent = await handleFormatContent(value.text);
                return {
                  operation: 1,
                  i_id: value.i_id,
                  item: {
                    section_name: value.section_name,
                    display_order: index,
                    text: newContent,
                    updatedby: userInfo.login_id,
                    updatedat: new Date(),
                  },
                  is_force_update: true,
                  access_key_updates: {
                    roles_to_publish: ['MEMBER' as const],
                  },
                  realtime_auto_link: true,
                };
              })
            ));

          const resultAction2 = await Promise.all([
            dispatch(
              executeBatchAction({
                ensure_transaction: true,
                item_actions: {
                  [RELEASE_NOTE_SECTION.name]: [
                    ...formattedSections,
                    ...((createSections.length ? formattedSectionsCreated : []) as Array<
                      Types.ItemAction<Types.ReleaseNoteSecton.ResponseType>
                    >),
                    ...((listSectionDeleted.length
                      ? listSectionDeleted.map((e) => ({
                          operation: 3,
                          i_id: e.i_id || '',
                          access_key_updates: {
                            roles_to_publish: ['MEMBER'],
                          },
                          realtime_auto_link: true,
                        }))
                      : []) as Array<Types.ItemAction<Types.ReleaseNoteSecton.ResponseType>>),
                  ],
                },
                realtime_auto_link: true,
              })
            ),
          ]);
          browserLogger.info(
            'pages/PublicManageMent/Modal/ReleaseNoteEditing',
            RELEASE_NOTE_SECTION.name,
            resultAction2
          );
          if (
            resultAction2.some((result) => !!result && executeBatchAction.fulfilled.match(result))
          ) {
            dispatch(stopLoading());
            setVisibleComplete(true);
            setVisibleTemporaly(false);
            return;
          }
          dispatch(stopLoading());
        }
      }
    },
  });

  const handleExtraButtonClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const base64Array: Array<string> = useMemo(() => {
    if (!formik.values.sections || indexSection >= formik.values.sections.length) return [];

    const parser = new DOMParser();
    const doc = parser.parseFromString(formik.values.sections[indexSection].text, 'text/html');
    const imgElements = doc.querySelectorAll('img');

    return Array.from(imgElements)
      .map((img) => (img.src.startsWith('data:image') ? img.src : null))
      .filter(Boolean) as string[];
  }, [formik.values.sections, indexSection]);

  const fetchDataReleaseNoteManagement = () => {
    if (!selected) return;
    dispatch(
      getDataAdminReleaseNoteManagement({
        conditions: [
          {
            id: 'provider_id',
            search_value: [selected.provider_id],
          },
          {
            id: 'official_curriculum_code',
            search_value: [selected.official_curriculum_code],
          },
        ],
        page: 1,
        per_page: 0,
        omit_total_items: false,
        include_item_ref: true,
      })
    );
  };

  const updateStatus = async (type: 'publish' | 'publishWarning') => {
    if (!userInfo || !selected) return;

    dispatch(startLoading());

    const createSections: Array<Section> = [];
    const updateSections: Array<Section> = [];

    await updateOfficialCurriculum();

    const resultAction = await dispatch(
      updateReleaseNote({
        id: selected?.i_id,
        data: {
          item: {
            release_note_title: formik.values.title,
            publish_status: type === 'publish' ? 2 : 3,
            description: formik.values.description,
            version: formik.values.version,
            updatedby: userInfo.login_id,
            updatedat: new Date(),
          },
          is_force_update: true,
          realtime_auto_link: true,
        },
      })
    );

    if (updateReleaseNote.fulfilled.match(resultAction)) {
      const listSectionDeleted = differenceBy(
        releaseNoteDetail?.sections,
        formik.values.sections,
        'i_id'
      );

      formik.values.sections.forEach((e, index) => {
        e.i_id ? updateSections.push({ ...e, index }) : createSections.push({ ...e, index });
      });

      Promise.all([
        dispatch(
          deleteReleaseNotePublished({
            conditions: [{ id: 'release_note_id', search_value: [formik.values?.release_note_id] }],
          })
        ),
        dispatch(
          createPublishedReleaseNote({
            item: {
              release_note_id: formik.values.release_note_id,
              release_note_title: formik.values.title,
              curriculum_code: formik.values.official_curriculum_code,
              company_id: userInfo?.company_id,
              publish_status: type === 'publish' ? 2 : 3,
              description: formik.values.description,
              version: formik.values.version,
              updatedby: userInfo.login_id,
              updatedat: new Date(),
              createdat: new Date(),
              createdby: userInfo?.login_id,
            },
            realtime_auto_link: true,
            access_key_updates: {
              roles_to_publish: ['MEMBER'],
            },
          })
        ),
      ]);

      const formattedSections = await Promise.all(
        updateSections.map(async (value, index) => {
          const newContent = await handleFormatContent(value.text);
          return {
            operation: 2,
            i_id: value.i_id,
            item: {
              section_name: value.section_name,
              display_order: index,
              text: newContent,
              updatedby: userInfo.login_id,
              updatedat: new Date(),
            },
            is_force_update: true,
            access_key_updates: {
              roles_to_publish: ['MEMBER' as const],
            },
            realtime_auto_link: true,
          };
        })
      );

      const formattedSectionsCreated =
        createSections.length &&
        (await Promise.all(
          createSections.map(async (value, index) => {
            const newContent = await handleFormatContent(value.text);
            return {
              operation: 1,
              i_id: value.i_id,
              item: {
                section_name: value.section_name,
                display_order: index,
                text: newContent,
                updatedby: userInfo.login_id,
                updatedat: new Date(),
              },
              is_force_update: true,
              access_key_updates: {
                roles_to_publish: ['MEMBER' as const],
              },
              realtime_auto_link: true,
            };
          })
        ));

      const resultAction2 = await Promise.all([
        dispatch(
          executeBatchAction({
            ensure_transaction: true,
            item_actions: {
              [RELEASE_NOTE_SECTION.name]: [
                ...formattedSections,
                ...((createSections.length ? formattedSectionsCreated : []) as Array<
                  Types.ItemAction<Types.ReleaseNoteSecton.ResponseType>
                >),
                ...((listSectionDeleted.length
                  ? listSectionDeleted.map((e) => ({
                      operation: 3,
                      i_id: e.i_id || '',
                      access_key_updates: {
                        roles_to_publish: ['MEMBER'],
                      },
                      realtime_auto_link: true,
                    }))
                  : []) as Array<Types.ItemAction<Types.ReleaseNoteSecton.ResponseType>>),
              ],
            },
            realtime_auto_link: true,
          })
        ),
      ]);

      if (formik.values.release_note_id) {
        dispatch(
          deleteReleaseNoteSectionPublished({
            conditions: [{ id: 'release_note_id', search_value: [formik.values?.release_note_id] }],
          })
        );
      }

      if (formik.values.sections.length) {
        const formattedSections = await Promise.all(
          formik.values.sections.map(async (value, index) => {
            const newContent = await handleFormatContent(value.text);
            return {
              operation: 1,
              i_id: value.i_id,
              item: {
                section_name: value.section_name,
                display_order: index,
                text: newContent,
                updatedby: userInfo.login_id,
                updatedat: new Date(),
              },
              is_force_update: true,
              access_key_updates: {
                roles_to_publish: ['MEMBER' as const],
              },
              realtime_auto_link: true,
            };
          })
        );
        dispatch(
          executeBatchAction({
            ensure_transaction: true,
            item_actions: {
              [PUBLISHED_RELEASE_NOTE_SECTION.name]: formattedSections,
            },
            realtime_auto_link: true,
          })
        );
      }
      if (resultAction2.some((result) => !!result && executeBatchAction.fulfilled.match(result))) {
        await dispatch(
          getDataReleaseNoteID({
            conditions: [
              {
                id: 'curriculum_code',
                search_value: [selectedCurriculum.official_curriculum_code],
              },
            ],
            sort_fields: [
              {
                id: 'version',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
            include_item_ref: true,
            omit_fields_data: true,
            use_display_id: true,
          })
        );
        type === 'publish' ? setVisiblePublished(true) : setVisibleWarning(true);
      }
    }

    const sectionToCreate = formik.values.sections.find((section) => !section.i_id);

    if (sectionToCreate) {
      const newContent = await handleFormatContent(sectionToCreate.text);

      await dispatch(
        executeBatchAction({
          ensure_transaction: true,
          item_actions: {
            [RELEASE_NOTE_SECTION.name]: [
              {
                operation: 1, // 1 nghĩa là tạo mới
                item: {
                  company_id: userInfo.company_id,
                  section_name: sectionToCreate.section_name,
                  release_note_id: formik.values.release_note_id,
                  text: newContent,
                  display_order: formik.values.sections.indexOf(sectionToCreate),
                  createdby: userInfo.login_id,
                  createdat: new Date(),
                },
                access_key_updates: {
                  roles_to_publish: ['MEMBER'],
                },
                realtime_auto_link: true,
              },
            ],
          },
          realtime_auto_link: true,
        })
      );
    }

    dispatch(stopLoading());
  };
  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleDeleteReleaseNote = async () => {
    if (releaseNoteDetail?.i_id) {
      dispatch(startLoading());

      const deleteReleaseNoteResult = await dispatch(
        removeReleaseNote({
          id: releaseNoteDetail.i_id,
        })
      );
      if (removeReleaseNote.fulfilled.match(deleteReleaseNoteResult)) {
        const sections = releaseNoteDetail.sections.filter((e) => !!e.i_id);
        await updateOfficialCurriculum();

        dispatch(
          executeBatchCreateReleaseNote({
            ensure_transaction: true,
            item_actions: {
              [PUBLISHED_RELEASE_NOTE.name]: [
                {
                  operation: 3,
                  i_id: releaseNoteDetail.i_id || '',
                  access_key_updates: {
                    roles_to_publish: ['MEMBER'],
                  },
                  realtime_auto_link: true,
                } as Types.ItemAction<Types.ReleaseNote.ResponseType>,
              ],
            },
            realtime_auto_link: true,
          })
        );
        browserLogger.info(
          'pages/PublicManagement/Modal',
          PUBLISHED_RELEASE_NOTE.name,
          executeBatchCreateReleaseNote
        );
        const deleteSectionResult = await Promise.all(
          sections.map((e) =>
            dispatch(
              removeReleaseSectionNote({
                id: e.i_id!,
              })
            )
          )
        );

        await dispatch(
          executeBatchAction({
            ensure_transaction: true,
            item_actions: {
              [PUBLISHED_RELEASE_NOTE_SECTION.name]: sections.length
                ? sections.map((sec) => ({
                    operation: 3,
                    i_id: sec.i_id || '',
                    access_key_updates: {
                      roles_to_publish: ['MEMBER'],
                    },
                    realtime_auto_link: true,
                  }))
                : [],
            },
            realtime_auto_link: true,
          })
        );
        browserLogger.info(
          'pages/PublicManagement/Modal',
          PUBLISHED_RELEASE_NOTE_SECTION.name,
          executeBatchAction
        );
        if (
          every(deleteSectionResult.filter(Boolean), (res) =>
            removeReleaseSectionNote.fulfilled.match(res)
          )
        ) {
          setOpenConfirmDelete(false);
          setDeleteSuccess(true);
        }
      }
    }
  };

  const handleDeleteSuccess = async () => {
    await setDeleteSuccess(false);
    setVisible(false);
    dispatch(startLoading());

    await dispatch(
      getDataReleaseNoteID({
        conditions: [
          {
            id: 'curriculum_code',
            search_value: [selectedCurriculum?.official_curriculum_code],
          },
        ],
        sort_fields: [
          {
            id: 'version',
            order: 'asc',
          },
        ],
        page: 1,
        per_page: 0,
        omit_total_items: false,
        include_item_ref: true,
        omit_fields_data: true,
        use_display_id: true,
      })
    );

    dispatch(stopLoading());
  };

  const handleFormatContent = useCallback(
    async (content: string) => {
      if (!userInfo?.i_id) return content;
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, 'text/html');
      const imgElements = doc.querySelectorAll('img');

      const base64Array = Array.from(imgElements)
        .map((img) => (img.src.startsWith('data:image') ? img.src : null))
        .filter(Boolean) as string[];

      if (base64Array.length) {
        dispatch(startLoading());

        const result = await Promise.all(
          base64Array
            .map(async (item) => {
              const imageSrc = await convertBase64ToFile(item, `file${uuidv4()}`);
              const uploadFileToMinio = await uploadFileToR2(imageSrc);
              if (uploadFileToMinio) {
                await dispatch(
                  createFileAttach({
                    item: {
                      fileID: uploadFileToMinio,
                      filename: `file${uuidv4()}`,
                      file_location: '6',
                      file_extension: imageSrc.type,
                      file_size: `${imageSrc.size}`,
                      company_id: userInfo.company_id,
                      createdat: new Date(),
                      createdby: userInfo.login_id,
                    },
                  })
                );

                const srcImage = (await getPresignedUrlByFileID(uploadFileToMinio)) as string;
                content = content.replace(item, srcImage);

                return { src: srcImage, base64: item };
              }
            })
            .filter(Boolean)
        );

        for (let index = 0; index < result.length; index++) {
          const element = result[index];
          if (!element) continue;
          const { src, base64 } = element;
          content = content.replace(base64, src);
        }
        dispatch(stopLoading());
        return content;
      } else {
        return content;
      }
    },
    [dispatch, userInfo?.i_id, userInfo?.company_id, userInfo?.login_id]
  );

  useEffect(() => {
    if (userInfo && visible) {
      dispatch(
        getReleaseNoteDetail({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'release_note_id',
              search_value: [selected?.release_note_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
          omit_total_items: false,
        })
      );
    }
  }, [dispatch, visible, userInfo, selected]);

  useEffect(() => {
    if (!visible) {
      formik.resetForm();
      setIndexSection(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const defaultConfig = useMemo(
    () => ({
      limitChars: 99900,
      // limitHTML: true,
      showCharsCounter: true,
      license: process.env.REACT_APP_JODIT_EDITOR_KEY ?? '',
      language: 'ja',
      i18n: { ja: JODIT_CUSTOM_LOCALIZATION.ja },
      placeholder: '本文を入力...',
      height: 550,
      statusbar: false,
      addNewLine: false,
      allowResizeX: false,
      allowResizeY: false,
      defaultLineHeight: 1,
      disablePlugins: ['tune-block'],
      buttons,
      buttonsMD: buttons,
      buttonsSM: buttons,
      buttonsXS: buttons,
      uploader: {
        insertImageAsBase64URI: true,
        insertVideoAsBase64URI: true,
      },
      preview: true,
      readonly: false,
      extraButtons: {
        name: 'insertVideo',
        icon: 'video',
        tooltip: '動画挿入',
        exec: handleExtraButtonClick,
      },
    }),
    []
  );

  useJoditTranslateMutationObserver();

  return (
    <ManageReleaseStyled
      isEmptyData={!formik.values.sections.length}
      title={<span className="title">リリースノート 編集</span>}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      width={1400}
      visible={visible}
      onCancel={handleToggleModal}
    >
      <FormikProvider value={formik}>
        <Form>
          <div className="information">
            <div>
              <span className="content">{selectedCurriculum?.name}</span>
              <span className="id">（ID：{selectedCurriculum?.official_curriculum_code})</span>
            </div>
            <div className="version">
              <div className="wrap-label">
                <label className="label">
                  バーション
                  <span className="required"> *</span> ：
                </label>
                <Form.Item name="version" className="version-required">
                  <Input maxLength={30} name="version" required />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="while-editing">
            <img
              src={
                !publish
                  ? WhiteEditing
                  : publish === 2
                  ? TickComplete
                  : publish === 1
                  ? UnPublished
                  : StopPublic
              }
              alt=""
            />
            <div className="wrap-input">
              <div className="wrap-input-version">
                <Form.Item className="item" name="title">
                  <Input
                    value={formik.values.title}
                    name="title"
                    placeholder="【タイトル】最大60文字"
                    maxLength={60}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <FieldArray
            name="sections"
            render={({ remove, push }) => (
              <div className="wrap-editor">
                <div className="left-side">
                  <div className="title-editor">
                    <div className="no-editor">{indexSection + 1}</div>
                    <Form.Item name="section_name" className="item input-title">
                      <TextArea
                        className="input-textArea"
                        name={`sections.${indexSection}.section_name`}
                        value={(
                          formik.values.sections[indexSection]?.section_name ?? ''
                        ).replaceAll('\n', '')}
                        autoSize={{ minRows: 1, maxRows: 2 }}
                        placeholder="セクション名を入力..."
                        maxLength={61}
                      />
                    </Form.Item>
                  </div>
                  <JoditEditor
                    value={formik.values.sections[indexSection]?.text}
                    config={defaultConfig}
                    onBlur={(newContent) =>
                      formik.setFieldValue(`sections.${indexSection}.text`, newContent)
                    }
                    onChange={(newValue) => {
                      formik.setFieldValue(`sections.${indexSection}.text`, newValue);
                    }}
                  />
                  <div className="wrap-bottom-editor">
                    <div className="wrap-button-editor">
                      <Button
                        className="btn button-prev"
                        disabled={indexSection === 0}
                        onClick={() => setIndexSection((prevState) => prevState - 1)}
                      >
                        <CaretLeftOutlined />
                        <span>前のセクション</span>
                      </Button>
                      <Button
                        className="btn button-next"
                        disabled={indexSection === formik.values.sections.length - 1}
                        onClick={() => setIndexSection(indexSection + 1)}
                      >
                        <span>次のセクション</span>
                        <CaretRightOutlined />
                      </Button>
                    </div>
                    <div
                      className={`delete-editor ${
                        formik.values.sections.length < 2 ? 'delete-disabled' : ''
                      }`}
                      onClick={() => setVisibleConfirmDelete(true)}
                    >
                      <DeleteOutlined
                        disabled={formik.values.sections.length < 2}
                        className="icon-delete"
                        style={{
                          color: formik.values.sections.length < 2 ? '#dddddd' : '#00989A',
                        }}
                      />
                      <span>このセクションを削除</span>
                    </div>
                    <DeleteCompleted
                      onSubmit={() => {
                        setIndexSection((prevState) => (prevState > 0 ? prevState - 1 : 0));
                      }}
                      visible={visibleDeleted}
                      setVisible={setVisibleDeleted}
                    />
                    <ExecuteDelete
                      visible={visibleConfirmDelete}
                      setVisible={setVisibleConfirmDelete}
                      onSubmit={() => {
                        setVisibleDeleted(true);
                        remove(indexSection);
                        if (indexSection > formik.values.sections.length - 2) {
                          setIndexSection(formik.values.sections.length - 2);
                        } else {
                          setIndexSection(indexSection);
                        }
                      }}
                    />
                  </div>
                </div>
                {showPopup && (
                  <Modal
                    open={showPopup}
                    closable={false}
                    onOk={handlePopupClose}
                    onCancel={handlePopupClose}
                    footer={null}
                  >
                    <VideoPopup
                      indexSection={indexSection}
                      formik={formik}
                      setOpen={setShowPopup}
                    />
                  </Modal>
                )}
                <div className="right-side">
                  <Form.Item name="description" label="概要：" className="text-area">
                    <TextArea
                      name="description"
                      rows={5}
                      placeholder="最大480文字"
                      maxLength={480}
                    />
                  </Form.Item>
                  <span className="title-right">セクション</span>
                  <div className="dashed-line"></div>
                  <div className="wrap-radio">
                    <Radio.Group
                      value={indexSection}
                      onChange={(e) => setIndexSection(e.target.value)}
                    >
                      {formik.values.sections.map((val, index) => (
                        <div
                          className={`input-radio ${index === indexSection && 'section-checked'}`}
                          key={index}
                          onClick={() => setIndexSection(index)}
                        >
                          <div className="label-radio">{index + 1}</div>
                          <TextArea
                            name={`sections.${index}.section_name`}
                            className="input-textArea"
                            placeholder="セクション名を入力..."
                            value={formik.values.sections[index]?.section_name
                              .replaceAll('\n', '')
                              .match(/.{1,20}/g)
                              ?.join('\n')
                              .slice(0, 62)}
                            autoSize={{ minRows: 1, maxRows: 5 }}
                            onChange={undefined}
                            maxLength={62}
                            onPressEnter={(e) => {
                              e.preventDefault();
                            }}
                          />
                          <Radio value={index} />
                        </div>
                      ))}
                    </Radio.Group>
                  </div>
                  <div className="wrap-button-add">
                    {formik.values.sections.length < 6 && (
                      <Button
                        className="add-section"
                        onClick={() =>
                          push({
                            section_name: '',
                            text: '',
                          })
                        }
                      >
                        ＋ セクションの追加
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          />
          <div className="wrap-submit">
            <div className="wrap-submit">
              <div className="wrap-button">
                <Button
                  className={`btn-outline ${!formik.isValid ? 'btn-disable disable' : ''}`}
                  onClick={() => setVisibleTemporaly(true)}
                  disabled={!formik.isValid}
                >
                  一時保存
                </Button>
                <Button
                  className={`${
                    !formik.isValid ? 'btn-outline btn-disable disable' : 'btn btn_submit'
                  }`}
                  onClick={() => setVisibleUpdate(true)}
                  disabled={!formik.isValid}
                >
                  公開
                </Button>
                {selected?.publish !== 1 && (
                  <Button
                    className={`btn-outline ${!formik.isValid ? 'btn-disable disable' : ''}`}
                    disabled={publish === 3 || !formik.isValid}
                    onClick={() => setVisiblePublishWarning(true)}
                  >
                    公開停止
                  </Button>
                )}
                <Button className="btn btn_close" onClick={() => setVisible(false)}>
                  キャンセル
                </Button>
                {!releaseNoteDetail?.history_id && publish === 3 && (
                  <div className="wrap-delete-bottom" onClick={() => setOpenConfirmDelete(true)}>
                    <DeleteOutlined className="icon" />
                    <span className="text-delete">リリースノートを削除</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
        <UpdateStatus
          publish={publish}
          status="publish"
          title="リリースノートを公開します"
          formValue={formik.values}
          from={
            publish === 0
              ? WhiteEditing
              : publish === 2
              ? TickComplete
              : publish === 1
              ? UnPublished
              : StopPublic
          }
          to={TickComplete}
          description={
            <span>
              リリースノートを公開すると、一般のユーザーが
              <br /> 修正後のリリースノートを閲覧できる状態になります。
            </span>
          }
          visible={visibleUpdate}
          setVisible={setVisibleUpdate}
          onSubmit={() => updateStatus('publish')}
        >
          {publish === 1 && (
            <div className="wrap-form">
              <Form.Item className="form-release" name="release_schedule_information">
                <span className="lable">
                  アップデート公開予定情報 <span className="required">*</span>
                </span>
                <Input
                  name="release_schedule_information"
                  placeholder="最大30文字"
                  maxLength={30}
                  required
                />
              </Form.Item>
            </div>
          )}
        </UpdateStatus>
        <UpdateStatus
          publish={publish}
          status="publishWarning"
          title="リリースノートを公開停止します"
          from={
            publish === 0
              ? WhiteEditing
              : publish === 2
              ? TickComplete
              : publish === 1
              ? UnPublished
              : StopPublic
          }
          to={StopPublic}
          description={
            <span>
              リリースノートを公開停止にすると、
              <br /> 一般のユーザーが閲覧できない状態になります。
            </span>
          }
          // noteText={getNoteText('publishWarning')}
          visible={visiblePublishWarning}
          setVisible={setVisiblePublishWarning}
          onSubmit={() => updateStatus('publishWarning')}
          textOkButton="公開停止にする"
        />
        <Published
          visible={visiblePublished}
          setVisible={setVisiblePublished}
          onSubmit={() => navigate(generatePath(routes.PublicManagement.path))}
          setVisibleEdit={setVisible}
          fetchData={() => fetchDataReleaseNoteManagement}
        />

        <DeleteCompleted visible={visibleDeleted} setVisible={setVisibleDeleted} />
        <Warning
          visible={visibleWarning}
          setVisible={setVisibleWarning}
          setVisibleEdit={setVisible}
          fetchData={() => fetchDataReleaseNoteManagement()}
        />
        <TemporarilySave
          visible={visibleTemporaly}
          setVisible={setVisibleTemporaly}
          onSubmit={formik.handleSubmit}
        />
        <ConfirmDeleteModal
          title="削除確認"
          subTitle="リリースノートを削除します。"
          description={
            <span>リリースノートの削除を実行すると復元できませんのでご注意ください。</span>
          }
          visible={openConfirmDelete}
          setVisible={setOpenConfirmDelete}
          onSubmit={handleDeleteReleaseNote}
          itemDeleted={!!releaseNoteDetail}
        />
        <DeleteCompleted
          visible={deleteSuccess}
          setVisible={setDeleteSuccess}
          description="リリースノートの削除が完了しました"
          onSubmit={handleDeleteSuccess}
        />
      </FormikProvider>
    </ManageReleaseStyled>
  );
};

export default NoteReleaseNew;

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
];
